import React from 'react'
import { IndexViewOptions, Page } from '~/models'
import { memo } from '~/ui/component'
import { DetailIndicator, PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { usePagesContext } from '../PagesContext'

export interface Props {
  page:      Page
  available: IndexViewOptions
}

const ViewOptionsIndicator = memo('ViewOptionsIndicator', (props: Props) => {

  const {page, available} = props
  const {updatePage} = usePagesContext()

  const {fieldCaption} = useResourceTranslation('pages')

  const toggleViewOption = React.useCallback((name: string) => {
    const viewOptions = {...page.viewOptions}
    const currentValue = viewOptions[name] ?? available[name]
    updatePage(page.id, {viewOptions: {...page.viewOptions, [name]: !currentValue}})
  }, [page.id, page.viewOptions, updatePage, available])

  const menuItems = React.useMemo(
    () => Object.entries(available).map(([name, value]): PopupMenuItem => ({
      value: name,
      caption: fieldCaption(`view_options.${name}`),
      icon: (page.viewOptions[name] ?? value) === true ? 'eye' : 'eye-off',
    }),
  ), [page, available, fieldCaption])

  const filled = React.useMemo(() => {
    for (const [key, value] of Object.entries(page.viewOptions)) {
      if (available[key] !== value) { return true }
    }
  }, [page.viewOptions, available])

  //------
  // Rendering

  function render() {
    return(
      <DetailIndicator
        icon='eye'
        filled={filled}
        menuItems={menuItems}
        menuHeader={fieldCaption('view_options')}
        onValueSelect={toggleViewOption}
      />
    )
  }

  return render()

})

export default ViewOptionsIndicator