import React from 'react'
import { upperFirst } from 'lodash'
import { Group, Participant } from '~/models'
import { projectStore } from '~/stores'
import { memo } from '~/ui/component'
import { Choice } from '~/ui/components/fields/SelectField'
import { useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import ResourceImportDialog, { Props as ResourceImportDialogProps } from '~/ui/resources/import/ResourceImportDialog'
import ParticipantImportModel from './ParticipantImportModel'

export interface Props extends ResourceImportDialogProps<Participant> {

}

const ParticipantImportDialog = memo('ParticipantImportDialog', (props: Props) => {

  const moduleID = projectStore.mainModule?.id
  const [groups, {fetch: fetchGroups}] = useModelEndpointData(Group, {fetch: false})
  const i18n = useResourceTranslation('participants')

  const importModel = React.useMemo(
    () => moduleID == null ? null : new ParticipantImportModel(moduleID, groups, i18n),
    [groups, i18n, moduleID],
  )

  const importFieldCaption = React.useCallback((key: string): string => {
    if (/^groups:(.*)$/.test(key)) {
      return RegExp.$1
    } else {
      return i18n.fieldCaption(key, {defaultValue: upperFirst(key)})
    }
  }, [i18n])

  const importFieldChoice = React.useCallback((key: string): Omit<Choice<any>, 'value'> => {
    if (/^groups:(.*)$/.test(key)) {
      const tag = RegExp.$1
      return {
        valueCaption: `${i18n.fieldCaption('groups')}: ${tag}`,
        icon:         'tag',
        mono:         true,
      }
    } else {
      return {}
    }
  }, [i18n])

  //------
  // Rendering

  function render() {
    if (importModel == null) { return null }

    return (
      <ResourceImportDialog
        {...props}
        onWillOpen={fetchGroups}
        importModel={importModel}
        fieldCaption={importFieldCaption}
        fieldChoice={importFieldChoice}
      />
    )
  }

  return render()

})

export default ParticipantImportDialog