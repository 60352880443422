import React from 'react'
import { useHistory } from 'react-router-dom'
import Toast from 'react-toast'
import I18next from 'i18next'
import { isArray } from 'lodash'
import { SocketError } from 'socket.io-react'
import { Model, ModelClass } from '~/models'
import { observer } from '~/ui/component'
import { ConfirmBox, EmptyOrFetching, HBox, PopupMenuItem, Scroller, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { resourceListPath } from '~/ui/resources/routes'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useResourceDetailBreadcrumbs } from '../hooks/breadcrumbs'
import ReferentialIntegrityNotice, { Reference } from './ReferentialIntegrityNotice'
import ResourceDetailSummaryPanel from './ResourceDetailSummaryPanel'

export interface Props<M extends Model> {
  Model: ModelClass<M>
  id: string
  include?: string[]

  breadcrumbs?: Breadcrumb[]
  BreadcrumbsActionsComponent?: React.ComponentType<{model: M}>

  icon?:                (model: M) => SVGName
  renderCaption?:       (model: M) => React.ReactNode
  renderType?:          (model: M) => React.ReactNode
  renderTags?:          (model: M) => React.ReactNode
  renderInfo?:          (model: M) => React.ReactNode
  renderSummaryFooter?: (model: M) => React.ReactNode
  renderActions?:       (model: M) => React.ReactNode

  allowRemove?: boolean
  removeConfirmText?: (model: M) => string

  EditFormComponent?: React.ComponentType<EditFormProps<M>> | CustomEditFormComponent<M>[]
  afterSubmitEdit?:   (result: SubmitResult) => any

  renderBody?: (model: M) => React.ReactNode
}

export interface CustomEditFormComponent<M extends Model> {
  name: string
  icon?: SVGName
  caption: string
  Form: React.ComponentType<EditFormProps<M>>
}

export interface EditFormProps<M extends Model> {
  open:         boolean
  requestClose: () => any
  afterSubmit:  (result: SubmitResult) => any
  model:        M
}

const _ResourceDetailScreen = <M extends Model>(props: Props<M>) => {

  const {
    Model,
    id,
    include,

    icon: props_icon,
    renderCaption: props_renderCaption,
    renderType: props_renderType,
    renderTags: props_renderTags,
    renderInfo: props_renderInfo,
    renderSummaryFooter: props_renderSummaryFooter,
    renderActions: props_renderActions,

    BreadcrumbsActionsComponent,

    allowRemove = true,
    removeConfirmText,

    EditFormComponent,
    afterSubmitEdit,

    renderBody,
  } = props

  const key = `${Model.name}::${id}`

  const [model, {document, fetchStatus}] = useModelDocumentData(Model, id, {
    include,
    fetch: 'always',
  })

  const {t, singular, plural, actionCaption, actionConfirm} = useResourceTranslation(Model.resourceType)
  const breadcrumbs = useResourceDetailBreadcrumbs(Model, model, props.breadcrumbs)

  const {colors} = useStyling()
  const history = useHistory()

  //------
  // Callbacks

  const processRemoveError = React.useCallback((error: Error) => {
    const status = 'status' in error && typeof error.status === 'number' ? error.status : 500

    let children: React.ReactNode = null
    if (error instanceof SocketError && error.status === 409) {
      const conflicts = error.extra.conflicts as Reference[]
      children = (
        <ResourceTypeProvider resourceType={Model.resourceType}>
          <ReferentialIntegrityNotice conflicts={conflicts}/>
        </ResourceTypeProvider>
      )
    }

    const translate = (key: string) => t(`actions.remove.errors.${status}.${key}`, {
      singular:     singular(),
      plural:       plural(),
      defaultValue: I18next.t([`errors:${status}.${key}`, `errors:unknown.${key}`]),
    })

    Toast.show({
      type:   'error',
      title:  translate('title'),
      children,
    })
  }, [Model.resourceType, plural, singular, t])

  const remove = React.useCallback(async () => {
    if (model == null) { return }
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove'),
      confirmText: removeConfirmText?.(model),
    })
    if (!confirmed) { return }

    const result = await document.delete()
    if (result.status === 'ok') {
      history.replace(resourceListPath(Model.resourceType))
    } else if (result.status === 'error') {
      processRemoveError(result.error)
    }
  }, [Model.resourceType, actionConfirm, document, history, model, processRemoveError, removeConfirmText])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider key={key} resourceType={Model.resourceType}>
        {breadcrumbs.length > 0 && (
          <AppLayoutConfig
            configKey={`${Model.resourceType}:${id}`}
            breadcrumbs={breadcrumbs}
            ActionsComponent={renderBreadcrumbsActions}
          />
        )}

        {renderContent()}
        {renderEditForm()}
      </ResourceTypeProvider>
    )
  }

  const renderBreadcrumbsActions = React.useCallback(() => {
    if (model == null) { return null }
    if (BreadcrumbsActionsComponent == null) { return null }

    return (
      <BreadcrumbsActionsComponent
        model={model}
      />
    )
  }, [BreadcrumbsActionsComponent, model])

  function renderContent() {
    if (model == null) {
      return (
        <EmptyOrFetching
          status={fetchStatus}
          flex
        />
      )
    } else {
      return (
        <HBox flex align='stretch' gap={layout.padding.m} classNames={$.container}>
          <VBox flex={3}>
            {renderBody?.(model)}
          </VBox>
          {renderRight()}
        </HBox>
      )
    }
  }

  function renderRight() {
    return (
      <VBox flex={1} classNames={$.right} gap={layout.padding.m}>
        {renderResourceDetailSummaryPanel()}
        <Scroller classNames={$.actionsScroller} contentClassNames={$.actionsScrollerContent}>
          {renderActions()}
        </Scroller>
      </VBox>
    )
  }

  function renderResourceDetailSummaryPanel() {
    if (model == null) { return null }

    const kebabMenuItems: PopupMenuItem[] = [];

    if (isArray(EditFormComponent)) {
      EditFormComponent.forEach((action) => {
        kebabMenuItems.push({
          icon: action.icon,
          caption: action.caption,
          onSelect: () => editWithAction(action.name),
        })
      })
    }

    else if (EditFormComponent != null) {
      kebabMenuItems.push({
        icon: 'pencil',
        caption: actionCaption('edit'),
        onSelect: () => editWithAction('edit'),
      });
    }

    if (EditFormComponent != null && allowRemove) {
      kebabMenuItems.push({section: '-'})
    }

    if (allowRemove) {
      kebabMenuItems.push({
        icon: 'trash',
        color: colors.semantic.negative,
        caption: actionCaption('remove'),
        onSelect: remove,
      })
    }

    return (
      <ResourceDetailSummaryPanel
        Model={Model}
        model={model}
        icon={props_icon?.(model)}
        caption={props_renderCaption?.(model)}
        type={props_renderType?.(model)}
        tags={props_renderTags?.(model)}
        info={props_renderInfo?.(model)}
        footer={props_renderSummaryFooter?.(model)}
        kebabMenuItems={kebabMenuItems}
      />
    )
  }

  function renderActions() {
    if (model == null) { return null }

    const actions = props_renderActions?.(model)
    if (actions == null) { return null }

    return (
      <VBox>
        {actions}
      </VBox>
    )
  }

  //------
  // Edit form

  const [editFormOpen, openEditForm, closeEditForm] = useBoolean()
  const [editAction, setEditAction] = React.useState<string>('edit')

  const editWithAction = React.useCallback((action: string) => {
    setEditAction(action)

    openEditForm()
  }, [openEditForm])

  function renderEditForm() {
    if (model == null) { return null }

    const EditForm = isArray(EditFormComponent)
      ? EditFormComponent.find(comp => comp.name === editAction)?.Form
      : EditFormComponent

    if (EditForm == null) { return null }

    return (
      <EditForm
        open={editFormOpen}
        requestClose={closeEditForm}
        afterSubmit={afterSubmitEdit ?? emptyAfterSubmitEdit}
        model={model}
      />
    )
  }

  return render()

}

const emptyAfterSubmitEdit = () => null

export const rightMinWidth = 320

const useStyles = createUseStyles({

  container: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },

  right: {
    minWidth: rightMinWidth,
  },

  removeAction: {
    padding: [0, layout.padding.inline.l],
  },

  actionsScroller: {
    margin:      -4,
    marginRight: -16,
  },

  actionsScrollerContent: {
    padding:      4,
    paddingRight: 16,
  },
})

const ResourceDetailScreen = observer('ResourceDetailScreen', _ResourceDetailScreen) as typeof _ResourceDetailScreen
export default ResourceDetailScreen
