import { isFunction } from 'lodash'

export interface AssistantFormModel<S> {
  mayContinueFromStep?(step: S): boolean
}

export function isAssistantFormModel<S>(formModel: any): formModel is AssistantFormModel<S> {
  if (formModel == null || typeof formModel !== 'object') { return false }
  if (!isFunction(formModel.mayContinueFromStep)) { return false }
  return true
}
