import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { TextField, TextFieldProps, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {
  value:        string | null
  onChange?:    (value: string) => any
  fieldProps?:  EmailSenderFieldProps
}

export interface EmailSenderFieldProps {
  name?:    EmailSenderSubFieldProps
  address?: EmailSenderSubFieldProps
}

export type EmailSenderSubFieldProps = Omit<TextFieldProps, 'value' | 'onChange'>

const EmailSenderField = memo('EmailSenderField', (props: Props) => {

  const {onChange, fieldProps, value} = props

  const [t] = useTranslation('email_sender_field')

  const [name, setName]   = React.useState<string>('')
  const [address, setAddress] = React.useState<string>('')

  React.useEffect(() => {
    if (value == null) {
      setName('')
      setAddress('')
    } else {
      const {name, address} = parseEmailSender(value)
      setName(name ?? '')
      setAddress(address ?? '')
    }
  }, [value, setAddress, setName])

  const handleNameChange = React.useCallback((name: string) => {
    onChange?.(buildEmailSender(name, address))
  }, [onChange, address])

  const handleAddressChange = React.useCallback((address: string) => {
    onChange?.(buildEmailSender(name, address))
  }, [onChange, name])

  //------
  // Rendering

  function render() {
    return(
      <VBox gap={layout.padding.xs}>
        {renderSubField('name')}
        {renderSubField('address')}
      </VBox>
    )
  }

  function renderSubField(type: 'name' | 'address') {
    return(
      <VBox gap={layout.padding.xs}>
        <FormFieldHeader caption={t(type)} />
        <TextField
          {...fieldProps?.[type]}
          value={type === 'name' ? name : address}
          onChange={type === 'name' ? handleNameChange : handleAddressChange}
        />
      </VBox>
    )
  }

  return render()

})

export default EmailSenderField

export function parseEmailSender(sender: string | null) {
  if (sender == null) {
    return {}
  } else if (/<(.*?)>/.test(sender)) {
    const address     = RegExp.$1
    const quotedNamed = sender.replace(`<${address}>`, '').trim()
    const name        = quotedNamed.substring(1, quotedNamed.length - 1)

    return {
      name:    name !== '' ? name : undefined,
      address: address !== '' ? address : undefined,
    }
  }
  else {
    return {address: sender}
  }
}

export function buildEmailSender(name: string, address: string) {
  if (name === '') { return address }
  return `"${name}" <${address}>`
}