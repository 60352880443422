import React from 'react'
import ScoringForm, { Props as ScoringFormProps } from '~/ui/app/competitions/scoring/ScoringForm'
import { memo } from '~/ui/component'
import ScriptMessageScoringFormModel from './ScriptMessageScoringFormModel'

export interface Props extends Omit<ScoringFormProps, 'model'> {
  formModel: ScriptMessageScoringFormModel
}

const ScriptMessageScoringForm = memo('ScriptMessageScoringForm', (props: Props) => {

  const {formModel, ...rest} = props

  return (
    <ScoringForm
      {...rest}
      model={formModel}
    />
  )

})

export default ScriptMessageScoringForm