import React from 'react'
import { RegistrationTrigger } from '~/models'
import { memo } from '~/ui/component'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: RegistrationTrigger
}

const RegistrationTriggerNodeView = memo('RegistrationTriggerNodeView', (props: Props) => {
  return (
    <TriggerNodeView
      {...props}
      icon='create'
    />
  )
})

register('registration', RegistrationTriggerNodeView)