import React from 'react'
import { memo } from '~/ui/component'
import { TimeIntervalField } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { TimeIntervalField as TimeIntervalFieldModel } from '../types'

export interface Props {
  field: TimeIntervalFieldModel
  bind:  FormFieldBindProps<string | null>
}

const TimeIntervalAllowance = memo('TimeIntervalAllowance', (props: Props) => {

  const {bind} = props

  //------
  // Rendering

  function render() {
    return (
      <TimeIntervalField
        {...bind as any}
      />
    )
  }

  return render()

})

export default TimeIntervalAllowance