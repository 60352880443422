import React from 'react'
import { reaction } from 'mobx'
import { PositionLocator } from '~/stores'
import { observer } from '~/ui/component'
import { isSuccessResult } from '~/ui/form'
import InsightsDialog from '../insights/InsightsDialog'
import { useFlowPlanner } from './FlowPlannerContext'

export interface Props {
  open:         boolean
  requestClose: () => any

  position: PositionLocator
}

const FlowPlannerInsightsDialog = observer('FlowPlannerInsightsDialog', (props: Props) => {

  const {open, requestClose, position} = props

  const {planner} = useFlowPlanner()
  const service = planner.service

  const endpoint = React.useMemo(
    () => service.positionsInsightsEndpoint(position),
    [position, service],
  )

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  React.useEffect(() => {
    if (!open) { return }
    return reaction(() => service?.insights, fetch)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, open])

  const abandon = React.useCallback(async (uuids: string[]) => {
    const result = await service.abandonPositions({uuid: uuids})
    if (isSuccessResult(result)) {
      endpoint.remove(uuids)
    }
  }, [endpoint, service])

  function render() {
    if (endpoint == null) { return null }

    return (
      <InsightsDialog
        open={open}
        requestClose={requestClose}
        endpoint={endpoint}
        requestAbandon={abandon}
      />
    )
  }

  return render()

})

export default FlowPlannerInsightsDialog