import React from 'react'
import { observer } from '~/ui/component'
import { ClearButton, Label, List, HBox, SVG, Tappable, Tooltip } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { colors, createUseStyles, layout } from '~/ui/styling'
import ComponentBrandingTree, { ComponentBrandingTreeListItem } from './ComponentBrandingTree'

export interface Props {
  tree: ComponentBrandingTree

  selectedComponentName: string | null
  onSelectComponent:     (componentName: string) => any
}

const ComponentBrandingTreeView = observer('ComponentBrandingTreeView', (props: Props) => {

  const {
    tree,
    selectedComponentName,
    onSelectComponent,
  } = props

  //------
  // Rendering

  function render() {
    return (
      <List
        data={tree.treeList}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        contentPadding={layout.padding.s}
        scrollable
      />
    )
  }

  const keyExtractor = React.useCallback(
    (item: ComponentBrandingTreeListItem) => item.key,
    [],
  )

  const renderItem = React.useCallback((item: ComponentBrandingTreeListItem) => {
    return (
      <ComponentBrandingTreeListItemView
        item={item}
        tree={tree}
        selected={selectedComponentName != null && item.componentName === selectedComponentName}
        onSelectComponent={onSelectComponent}
      />
    )
  }, [tree, selectedComponentName, onSelectComponent])

  return render()

})

export default ComponentBrandingTreeView

interface ComponentBrandingTreeListItemViewProps {
  item: ComponentBrandingTreeListItem
  tree: ComponentBrandingTree

  selected:          boolean
  onSelectComponent: (name: string) => any
}

const ComponentBrandingTreeListItemView = observer('ComponentBrandingTreeListItemView', (props: ComponentBrandingTreeListItemViewProps) => {

  const {item, tree, selected, onSelectComponent} = props

  const select = React.useCallback(() => {
    if (item.componentName == null) { return }
    onSelectComponent(item.componentName)
  }, [item.componentName, onSelectComponent])

  const toggle = React.useCallback(() => {
    tree.togglePath(item.path)
  }, [tree, item.path])

  const selectable = item.componentName != null
  const inset      = item.level * (layout.icon.s.width + layout.padding.inline.m)

  const {t} = useResourceTranslation('brandings')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable
        classNames={[$.componentBrandingTreeListItem, {selected, selectable}]}
        style={{paddingLeft: layout.padding.inline.m + inset}}
        onTap={select}
        enabled={selectable}
      >
        <HBox flex='grow' gap={layout.padding.inline.m}>
          {item.status !== 'nochildren' ? (
            renderToggleButton()
          ) : (
            <SVG name='empty' size={layout.icon.s}/>
          )}
          <Label flex mono bold={selectable} small>
            {item.caption}
          </Label>
          <Tooltip renderTooltip={item.app ? t('components.app') : null}>
            <SVG name={item.app ? 'iphone' : 'empty'} size={layout.icon.s} secondary/>
          </Tooltip>
          <Tooltip renderTooltip={item.app ? t('components.app')  : null}>
            <SVG name={item.web ? 'web' : 'empty'} size={layout.icon.s} secondary/>
          </Tooltip>
        </HBox>
      </Tappable>
    )
  }

  function renderToggleButton() {
    return (
      <ClearButton
        icon={item.status === 'expanded' ? 'minus-circle' : 'plus-circle'}
        onTap={toggle}
        padding='vertical'
        small
      />
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  componentBrandingTreeListItem: {
    height:       layout.barHeight.s,
    borderRadius: layout.radius.s,
    paddingRight: layout.padding.inline.m,

    '&.selectable:hover': {
      background: theme.bg.hover,
    },
    '&.selectable:active': {
      background: theme.bg.hover,
    },
    '&.selected': {
      ...colors.overrideBackground(theme.semantic.primary),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.primary)),
    },
  },
}))