import React from 'react'
import { AutofocusProvider } from 'react-autofocus'
import { isFunction } from 'lodash'
import { memo } from '~/ui/component'
import { Transition, VBox } from '~/ui/components'
import { RefMap } from '~/ui/hooks'
import { createUseStyles } from '~/ui/styling'

import type { Props as AssistantFormDialogProps  } from './AssistantFormDialog'

interface Props<S extends string> extends AssistantFormDialogProps<any, S> {
  stepRefs:   RefMap<S, HTMLDivElement>
}

const _AssistantFormBody = <S extends string>(props: Props<S>) => {

  const {steps, currentStep, renderStep, stepRefs} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.AssistantFormBody}>
        <Transition currentScene={currentStep}>
          {steps.map(renderStepScene)}
        </Transition>
      </VBox>
    )
  }

  function renderStepScene(step: S) {
    const stepRef = stepRefs.getter(step)

    return (
      <Transition.Scene name={step} key={step}>
        <AutofocusProvider
          enabled={step === currentStep}
          containerRef={stepRef}
          defaultFocus={{buttons: false}}
          trap='exclude'
        >
          <VBox flex='grow' ref={stepRefs.for(step)}>
            {renderStep(step)}
          </VBox>
        </AutofocusProvider>
      </Transition.Scene>
    )
  }

  return render()

}

const AssistantFormBody = memo('AssistantFormBody', _AssistantFormBody) as typeof _AssistantFormBody
export default AssistantFormBody

export interface AssistantFormModel<S> {
  mayContinueFromStep?(step: S): boolean
}

export function isAssistantFormModel<S>(formModel: any): formModel is AssistantFormModel<S> {
  if (formModel == null || typeof formModel !== 'object') { return false }
  if (!isFunction(formModel.mayContinueFromStep)) { return false }
  return true
}

export const shadowOverflow = 6
export const minBodyHeight = 160

const useStyles = createUseStyles(theme => ({
  AssistantFormBody: {
    minHeight: minBodyHeight,
  },
}))