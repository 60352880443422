import React from 'react'
import { ClientApp, Organisation } from '~/models'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { observer } from '~/ui/component'
import { HBox, TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { ResourceFormDialog, ResourceFormDialogProps } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export type Props = Omit<ResourceFormDialogProps<Organisation>, 'Model' | 'children'>

const OrganisationForm = observer('OrganisationForm', (props: Props) => {

  const {t} = useResourceTranslation('organisations')

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        {...props}
        Model={Organisation}
        title={t('create.title')}
        children={renderFields()}
        semi
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        <HBox gap={layout.padding.m} align='top'>
          <VBox flex gap={layout.padding.s}>
            <FormField name='name' required>
              {bind => <TextField {...bind}/>}
            </FormField>
            <FormField name='defaultApp' required>
              {bind => <ResourceField Model={ClientApp} {...bind}/>}
            </FormField>
          </VBox>
          <VBox>
            <FormField name='logo' renderAsLabel={false}>
              {bind => (
                <CustomImageField
                  {...bind}
                  allowedTypes={['remote']}
                  size={{width: 116, height: 116}}
                  objectFit='contain'
                />
              )}
            </FormField>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default OrganisationForm