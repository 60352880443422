import React from 'react'
import { makeObservable, observable, reaction } from 'mobx'
import { Challenge, Competition, Ref } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, Label, NumberField, VBox } from '~/ui/components'
import {
  FormDialog,
  FormDialogProps,
  FormField,
  FormFieldHeader,
  FormModel,
  SubmitResult,
} from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props extends Omit<FormDialogProps<ChallengeScoringFormModel>, 'model' | 'children'> {
  challenge: Challenge
}

const ChallengeScoringForm = observer('ChallengeScoringForm', (props: Props) => {

  const {challenge, ...rest} = props
  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new ChallengeScoringFormModel(challenge),
    [challenge],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDialog model={formModel} icon='trophy' title={t('scoring_form.title')} {...rest}>
        <FormField name='competitions'>
          {bind => <ResourceField Model={Competition} multi {...bind}/>}
        </FormField>

        <VBox gap={layout.padding.inline.s}>
          <FormFieldHeader name='passingScore'/>
          <HBox gap={layout.padding.inline.m}>
            <VBox flex>
              <FormField name='passingScore' caption={false} instruction={false}>
                {bind => <NumberField {...bind} minimum={null} step={10}/>}
              </FormField>
            </VBox>
            <Label small dim markup>
              {t('fields.passing_score.suffix', {maximum: challenge.maximumScore})}
            </Label>
          </HBox>
        </VBox>
      </FormDialog>
    )
  }

  return render()

})

export default ChallengeScoringForm

class ChallengeScoringFormModel implements FormModel {

  constructor(
    public readonly challenge: Challenge,
  ) {
    makeObservable(this)
    reaction(() => this.competitions, competitions => {
      if (competitions.length === 0) {
        this.passingScore = null
      }
    })
  }

  @observable
  public competitions: Ref<Competition>[] = this.challenge.competitions ?? []

  @observable
  public passingScore: number | null = this.challenge.passingScore

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Challenge, this.challenge.id, {
      competitions: this.competitions,
      passingScore: this.passingScore,
    })
  }

}