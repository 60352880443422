import React from 'react'
import { CollectionFetchOptions, FetchStatus } from 'mobx-document'
import { Model } from '~/models'
import { dataStore, FetchOptions, ModelDocument, ShowParams } from '~/stores'
import { useWithCustomDeps } from '~/ui/hooks'

export function useModelDocumentData<M extends Model>(Model: Constructor<M>, id: string, options?: ModelDocumentOptions): UseDocumentDataHook<M>
export function useModelDocumentData<M extends Model>(Model: Constructor<M>, id: null, options?: ModelDocumentOptions): UseDocumentDataNullHook
export function useModelDocumentData(Model: null, id: string, options?: ModelDocumentOptions): UseDocumentDataNullHook
export function useModelDocumentData(Model: null, id: null, options?: ModelDocumentOptions): UseDocumentDataNullHook
export function useModelDocumentData<M extends Model>(Model: Constructor<M> | null, id: string | null, options?: ModelDocumentOptions): UseDocumentDataHook<M> | UseDocumentDataNullHook
export function useModelDocumentData<M extends Model>(Model: Constructor<M> | null, id: string | null, options: ModelDocumentOptions = {}): UseDocumentDataHook<M> | UseDocumentDataNullHook {
  const document    = useModelDocument<M>(Model, id, options)
  const data        = document?.data ?? null
  const fetchStatus = document?.fetchStatus ?? 'done'

  const fetch: ModelDocumentFetch = React.useCallback(params => {
    document?.fetch(params ?? {})
  }, [document])

  return [data, {data, fetch, fetchStatus, document} as any]
}

export function useModelDocument<M extends Model>(Model: Constructor<M>, id: string, options?: ModelDocumentOptions): ModelDocument<M>
export function useModelDocument<M extends Model>(Model: Constructor<M>, id: null, options?: ModelDocumentOptions): null
export function useModelDocument(Model: null, id: string, options?: ModelDocumentOptions): null
export function useModelDocument(Model: null, id: null, options?: ModelDocumentOptions): null
export function useModelDocument<M extends Model>(Model: Constructor<M> | null, id: string | null, options?: ModelDocumentOptions): ModelDocument<M> | null
export function useModelDocument<M extends Model>(Model: Constructor<M> | null, id: string | null, options: ModelDocumentOptions = {}): ModelDocument<M> | null {
  const {
    detail = true,
    include: options_include,
    fetch: shouldFetch = 'notfound',
    label,
  } = options

  const include = useWithCustomDeps(
    options_include,
    include => [[...include??[]].sort().join(',')],
  )

  const document = Model != null && id != null
    ? dataStore.document(Model, id, false)
    : null

  React.useEffect(() => {
    if (Model == null || id == null) { return }
    if (shouldFetch === 'never') { return }

    const document = dataStore.document(Model, id)
    if (shouldFetch === 'notfound') {
      if (!detail && document?.data != null) { return }
      if (detail && document?.data?.$hasDetail) { return }
    }

    document?.fetch({detail, include, label})
  }, [Model, id, detail, shouldFetch, document, include, label])

  return document
}

export interface ModelDocumentOptions extends FetchOptions {
  label?: string
  fetch?: 'always' | 'never' | 'notfound'
}

export type UseDocumentDataHook<M extends Model> = [M | null, UseDocumentDataHookRest<M>]
export type UseDocumentDataNullHook = [null, UseDocumentDataNullHookRest]

export interface UseDocumentDataHookRest<M extends Model> {
  data:        M | null
  fetch:       ModelDocumentFetch
  fetchStatus: FetchStatus
  document:    ModelDocument<M>
}

export interface UseDocumentDataNullHookRest {
  data:        null
  fetch:       ModelDocumentFetch
  fetchStatus: 'done'
  document:    null
}

export type ModelDocumentFetch = (params?: ShowParams, options?: CollectionFetchOptions) => void