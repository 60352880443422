import React from 'react'
import { useTranslation } from 'react-i18next'
import { Participant } from '~/models'
import { authenticationStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'

export type Props = Omit<ResourceFieldProps<Participant>, 'Model' | 'shortcutsCaption' | 'shortcutIDs'>

const ParticipantField = observer('ParticipantField', (props: Props) => {

  const [t] = useTranslation('participant_field')

  const linkedParticipantIDs = authenticationStore.linkedParticipantIDs

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...props as any}
        Model={Participant}
        renderPropsForModel={renderPropsForModel}
        placeholder={props.placeholder ?? t('placeholder')}
        shortcutsCaption={linkedParticipantIDs.length === 0 ? undefined : t('linked')}
        shortcutIDs={linkedParticipantIDs.length === 0 ? undefined : linkedParticipantIDs}
      />
    )
  }

  const renderAvatar = React.useCallback((participant: Participant) => {
    return (
      <Avatar
        source={participant.photoURL}
        {...participant}
        size={avatarSize}
      />
    )
  }, [])

  const renderPropsForModel = React.useCallback((participant: Participant) => {
    return {
      image: renderAvatar(participant),
    }
  }, [renderAvatar])

  return render()

})

export default ParticipantField

export const avatarSize = {
  width:  32,
  height: 32,
}