import React from 'react'
import { ModelOfType } from '~/models'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { TagField } from '../types'

export interface Props {
  field: TagField
  bind:  FormFieldBindProps<string | string[] | null>
}

const TagAllowance = memo('TagAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    return (
      <TagsField
        {...bind as any}
        Model={ModelOfType(field.resourceType)}
        multi={field.multi}
      />
    )
  }

  return render()

})

export default TagAllowance