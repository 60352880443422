import React from 'react'
import { observer } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import BrandingColorField from '../../assets/BrandingColorField'
import ComponentBrandingFields from '../ComponentBrandingFields'
import { registerComponentBrandingFields } from '../registry'

export interface Props {}

const WebTabBarBrandingFields = observer('WebTabBarBrandingFields', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.l}>
        <ComponentBrandingFields/>

        <HBox gap={layout.padding.s} align='top'>
          <VBox flex>
            <FormField name='iconColor'>
              {bind => <BrandingColorField {...bind}/>}
            </FormField>
          </VBox>
          <VBox flex>
            <FormField name='activeIconColor'>
              {bind => <BrandingColorField {...bind}/>}
            </FormField>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  return render()

})

registerComponentBrandingFields('web.tabBar', WebTabBarBrandingFields)
export default WebTabBarBrandingFields