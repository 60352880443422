import React from 'react'
import { Project } from '~/models'
import { ModelEndpoint, projectStore } from '~/stores'
import { memo } from '~/ui/component'
import { Chip, DataGrid, HBox, Label } from '~/ui/components'
import { ResourceList } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'
import ProjectLogo from './ProjectLogo'

export interface Props {
  endpoint: ModelEndpoint<Project>
}

const ProjectList = memo('ProjectList', (props: Props) => {

  const {endpoint} = props

  const switchToProject = React.useCallback((project: Project) => {
    projectStore.switchProject(project.id)
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <ResourceList
        endpoint={endpoint}
        allowRemove={false}
        onItemTap={switchToProject}
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={1}
        />
        <DataGrid.Column
          name='participantCount'
          width={160}
          renderCell={renderParticipantCount}
        />
      </ResourceList>
    )
  }

  function renderName(project: Project) {
    return (
      <HBox gap={layout.padding.s}>
        <ProjectLogo
          logo={project.logo}
        />
        <Label bold>
          {project.name}
        </Label>
      </HBox>
    )
  }

  function renderParticipantCount(project: Project) {
    return (
      <HBox>
        <Chip flex='shrink'>
          {project.participantCount}
        </Chip>
      </HBox>
    )
  }

  return render()

})

export default ProjectList