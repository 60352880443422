import { TimeInterval } from '~/models'

export function interval(format: 'short' | 'long' = 'long') {
  return (value: any) => {
    if (typeof value === 'string') {
      value = TimeInterval.parse(value)
    }

    if (value instanceof TimeInterval) {
      return format === 'long' ? value.toLongString() : value.toString()
    } else {
      return value
    }
  }
}