import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImportSession } from 'sheet-importer'
import { memo } from '~/ui/component'
import { Center, Dimple, HBox, Label, Panel, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, fonts, layout, useStyling } from '~/ui/styling'

export interface Props {
  session: ImportSession
}

const ImportResultSummaryPanel = memo('ImportResultSummaryPanel', (props: Props) => {

  const {session} = props

  const result        = session.lastRun?.result
  const issues        = result?.status === 'completed' ? result.issues : []
  const nonInfoIssues = issues.filter(issue => issue.level !== 'info')
  const hasIssues     = nonInfoIssues.length > 0

  const nImported = result?.status === 'completed' ? result.nImported : 0
  const nCreated  = result?.status === 'completed' ? result.nCreated : 0
  const nUpdated  = result?.status === 'completed' ? result.nUpdated : 0

  const [t] = useTranslation('importer')

  //------
  // Rendering

  const $ = useStyles()
  const {colors} = useStyling()

  function render() {
    return (
      <Panel>
        <VBox align='center' classNames={$.icon}>
          {hasIssues ? (
            <SVG name='warning-full' color={colors.semantic.warning} size={layout.icon.xxxl}/>
          ) : (
            <SVG name='check-circle' color={colors.semantic.positive} size={layout.icon.xxxl}/>
          )}
        </VBox>
        <VBox gap={layout.padding.inline.m} classNames={$.section}>
          <Label caption truncate={false} align='center'>
            {t(hasIssues ? 'result.with_issues.title' : 'result.ok.title')}
          </Label>
          <Label small dim align='center'>
            {t(hasIssues ? 'result.with_issues.detail' : 'result.ok.detail')}
          </Label>
        </VBox>
        <Dimple horizontal/>
        {renderCounts()}
      </Panel>
    )
  }

  function renderCounts() {
    if (result == null) { return null }

    return (
      <HBox align='stretch'>
        <Center flex padding={layout.padding.inline.l}>
          <CountSwatch caption={t('result.count.imported')} count={nImported}/>
        </Center>
        <Dimple vertical/>
        <Center flex padding={layout.padding.inline.l}>
          <CountSwatch caption={t('result.count.created')} count={nCreated}/>
        </Center>
        <Dimple vertical/>
        <Center flex padding={layout.padding.inline.l}>
          <CountSwatch caption={t('result.count.updated')} count={nUpdated}/>
        </Center>
      </HBox>
    )
  }

  return render()

})

export default ImportResultSummaryPanel

interface CountSwatchProps {
  icon?:   SVGName
  count:   number
  caption: string
}

const CountSwatch = memo('CountSwatch', (props: CountSwatchProps) => {

  const {icon, count, caption} = props

  const $ = useStyles()

  function render() {
    return (
      <VBox align='center' gap={layout.padding.inline.m}>
        {icon != null && (
          <Center padding={layout.padding.inline.s}>
            <SVG
              name={icon}
              size={layout.icon.m}
            />
          </Center>
        )}
        <Label classNames={$.count}>
          {count}
        </Label>
        <Label caption small dim>
          {caption}
        </Label>
      </VBox>
    )
  }

  return render()

})

export const issueBarHeight = layout.barHeight.m

const useStyles = createUseStyles(theme => ({
  count: {
    ...fonts.responsiveFontStyle(theme.fonts.caption),

    fontSize: 32,
  },

  icon: {
    padding:       layout.padding.inline.l,
    paddingBottom: 0,
  },

  section: {
    padding: [layout.padding.inline.l, layout.padding.inline.l],
  },
}))