import { makeObservable, observable } from 'mobx'
import { Feedback, MessageFeedback, ScriptMessage } from '~/models'
import { FeedbackFormModel } from '~/ui/app/feedback'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ScriptMessageFeedbackFormModel extends FeedbackFormModel {

  constructor(
    private readonly saveMessage: (uuid: string, data: AnyObject) => Promise<SubmitResult | undefined>,
    private readonly message: ScriptMessage,
    type: Feedback['type'],
  ) {
    super(message.feedback, type)

    this.variable     = this.message.feedback?.variable ?? null
    this.skippable    = this.message.feedback?.skippable ?? false
    this.expiresAfter = this.message.feedback?.expiresAfter ?? null

    makeObservable(this)
  }

  @observable
  public variable: string | null

  @observable
  public skippable: boolean

  @observable
  public expiresAfter: number | null

  protected async submitFeedback(): Promise<SubmitResult | undefined> {
    const feedback = this.buildMessageFeedback()
    if (feedback == null) { return }

    const result = await this.saveMessage(this.message.uuid, {feedback})
    return translateFormErrorPaths(result, path => path.replace(/^messages\.\d+\.feedback\./, ''))
  }

  public buildMessageFeedback(): MessageFeedback | null {
    if (this.resolvedFeedback == null) { return null }

    return {
      ...this.message.feedback,
      ...this.resolvedFeedback,
      variable:     this.variable,
      skippable:    this.skippable,
      expiresAfter: this.expiresAfter,
      scorings:     this.resolvedFeedback.type !== this.message.feedback?.type ? [] : (this.message.feedback?.scorings ?? []),
      review:       false,
    }
  }

}