import React from 'react'
import { Location, Participant } from '~/models'
import { BulkSelector, dataStore, ModelEndpoint } from '~/stores'
import ParticipantList from '~/ui/app/participants/list/ParticipantList'
import { memo } from '~/ui/component'
import { ConfirmBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction } from '~/ui/resources/collection'

export interface Props {
  location: Location
  endpoint: ModelEndpoint<Participant>
}

const LocationParticipantList = memo('LocationParticipantList', (props: Props) => {

  const {location, endpoint} = props
  const {actionCaption, actionConfirm} = useResourceTranslation('locations')

  const removeParticipantsFromLocation = React.useCallback(async (_, selector: BulkSelector) => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove-from-location'),
    })
    if (!confirmed) { return }

    const document = dataStore.document(Location, location.id)
    const result = await document.callBulkAction('participants:remove', selector)
    if (result.status === 'ok') {
      endpoint.fetch()
    }
  }, [actionConfirm, endpoint, location.id])

  const bulkActions = React.useMemo((): BulkAction[] => [{
    name:    'exit',
    icon:    'cross-circle',
    caption: count => actionCaption('remove_from_location', {count}),
    execute: removeParticipantsFromLocation,
  }], [actionCaption, removeParticipantsFromLocation])

  //------
  // Rendering

  function render() {
    return (
      <ParticipantList
        endpoint={endpoint}
        bulkActions={bulkActions}
      />
    )
  }

  return render()

})

export default LocationParticipantList