import React from 'react'
import Toast from 'react-toast'
import DevBuddy from 'devbuddy'
import config from '~/config'
import { Participant } from '~/models'
import { appLinksStore, authenticationStore, dataStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, HBox, PushButton, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import MagicLoginDialog from './MagicLoginDialog'

export interface Props {
  participant: Participant
}

const ParticipantLoginActions = observer('ParticipantLoginActions', (props: Props) => {

  const {participant} = props

  const authToken = authenticationStore.authToken
  const projectID = projectStore.projectID
  const target    = appLinksStore.defaultTarget

  const {t} = useResourceTranslation()

  const [magicLoginDialogOpen, openMagicLoginDialog, closeMagicLoginDialog] = useBoolean()
  const [magicLoginToken, setMagicLoginToken] = React.useState<string | null>()

  const getMagicLoginToken = React.useCallback(async () => {
    const document = dataStore.document(Participant, participant.id)
    const response = await document.callAction('login-token')
    if (response.status !== 'ok') { return null }

    return response.data as string
  }, [participant.id])

  const magicLoginHref = React.useMemo(() => {
    const url = `${config.urls.api}/mission/auth/auto-login/${participant.id}`
    const query = new URLSearchParams()
    query.set('url', config.urls.webLogin('{{token}}', projectStore.preferredProjectDomain))

    if (authToken != null) {
      query.set('auth', authToken)
    }
    if (projectID != null) {
      query.set('project', projectID)
    }

    return `${url}?${query}`
  }, [authToken, participant.id, projectID])

  const showMagicLoginLink = React.useCallback(async () => {
    const token = await getMagicLoginToken()
    if (token == null) { return }

    setMagicLoginToken(token)
    openMagicLoginDialog()
  }, [getMagicLoginToken, openMagicLoginDialog])

  const logInInAppIfAvailable = React.useCallback(async (event: React.SyntheticEvent) => {
    if (!DevBuddy.available()) { return }
    if (!appLinksStore.isDeviceTarget(target)) { return }

    event.preventDefault()

    const devices = await DevBuddy.listDevices()
    if (devices.length === 0) { return }

    try {
      const token = await getMagicLoginToken()
      if (token == null) { return }

      const href = config.urls.appLogin(token, 'groundcontrol')
      await appLinksStore.openAppLinkOnDevice(href, target)
    } catch (err: any) {
      Toast.show({
        type: 'error',
        title: "Error while executing command",
        detail: err?.message ?? err ?? "Unknown error",
      })
    }
  }, [getMagicLoginToken, target])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.l}>
        <ClearButton
          icon='link'
          caption={t('magic_login.link')}
          onTap={showMagicLoginLink}
          align='left'
          small
        />
        <VBox flex='grow'>
          <PushButton
            icon='login'
            caption={t('magic_login.log-in-now')}
            href={magicLoginHref}
            onTap={logInInAppIfAvailable}
            small
          />
        </VBox>

        {magicLoginToken != null && (
          <MagicLoginDialog
            open={magicLoginDialogOpen}
            requestClose={closeMagicLoginDialog}

            token={magicLoginToken}
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default ParticipantLoginActions