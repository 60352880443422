import { MessageFeedback } from '~/models'
import ScoringFormModel from '~/ui/app/competitions/scoring/ScoringFormModel'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ScriptMessageScoringFormModel extends ScoringFormModel {

  constructor(
    private readonly saveMessage: (data: AnyObject) => Promise<SubmitResult | undefined>,
    feedback: MessageFeedback,
  ) {
    super(feedback, feedback.scorings ?? [])
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const result = await this.saveMessage({
      feedback: {
        ...this.feedback,
        scorings: this.scorings,
      },
    })
    return translateFormErrorPaths(result, path => path.replace(/^feedback\./, ''))
  }

}