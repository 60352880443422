import React from 'react'
import { v4 as uuidV4 } from 'uuid'

export function useQualifiedIDNamespace() {
  return React.useMemo(
    () => uuidV4().slice(0, 8),
    [],
  )
}

export function useQualifiedIDFactory() {
  const namespace = useQualifiedIDNamespace()
  return (id: string) => `${namespace}:${id}`
}

export function useQualifiedID(id: string) {
  const namespace = useQualifiedIDNamespace()
  return `${namespace}:${id}`
}