import React from 'react'
import { FetchStatus } from 'mobx-document'
import { Challenge, ModelClass, ModelOfType, QuestionRef, Script } from '~/models'
import { deriveQuestionsFromModel, QuestionDescriptor } from '~/stores'
import { useModelDocumentData, useModelEndpointData } from '~/ui/hooks/data'
import { AnswerScopeLinkage } from './explore/AnswerScopeField'

export function useQuestion(ref: QuestionRef): [QuestionDescriptor | null, FetchStatus] {
  const Model   = ModelOfType(ref.model.model === 'Script' ? 'scripts' : 'challenges') as ModelClass<Script | Challenge>
  const [model, endpoint] = useModelDocumentData<Script | Challenge>(Model, ref.model.id, {detail: true})

  const question = React.useMemo(() => {
    if (model == null) { return null }
    return deriveQuestionsFromModel(model).find(it => it.uuid === ref.uuid)
  }, [model, ref.uuid])

  if (question == null) { return [null, 'idle'] }
  return [question, endpoint.fetchStatus]
}

export function useQuestions(scope: AnswerScopeLinkage): [QuestionDescriptor[], FetchStatus] {

  const fetchOptions = React.useMemo(()  => ({
    include: ['messages', 'tasks'],
    filters: {
      id: scope === '$all' ? undefined : scope.id,
    },
  }), [scope])

  const [scriptQuestionModels, {fetchStatus: scriptsFetchStatus}] = useModelEndpointData(Script, fetchOptions)
  const [challengeQuestionModels, {fetchStatus: challengesFetchStatus}] = useModelEndpointData(Challenge, fetchOptions)

  const questions = React.useMemo(() => {
    const questions: QuestionDescriptor[] = []

    scriptQuestionModels.forEach(model => questions.push(...deriveQuestionsFromModel(model)))
    challengeQuestionModels.forEach(model => questions.push(...deriveQuestionsFromModel(model)))

    return questions

  }, [scriptQuestionModels, challengeQuestionModels])

  const fetchStatus = React.useMemo(
    () => scriptsFetchStatus === challengesFetchStatus ? scriptsFetchStatus : 'fetching', [scriptsFetchStatus, challengesFetchStatus],
  )

  return [questions, fetchStatus]
}