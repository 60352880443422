import { DraftDecorator } from 'draft-js'
import Variable from './Variable'

const variableDecorator: DraftDecorator = {
	component: Variable,
	strategy:  (contentBlock, callback, contentState) => {
    const text = contentBlock.getText()

    for (const match of text.matchAll(REGEXP)) {
      if (match.index == null) { continue }
      callback(match.index, match.index + match[0].length)
    }
  },
}

const REGEXP = /\{\{.+?\}\}/g

export default variableDecorator