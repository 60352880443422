import React from 'react'
import { memo } from '~/ui/component'
import { useContinuousRef } from '~/ui/hooks'
import { AppLayoutContext } from './AppLayoutContext'
import { Breadcrumb } from './types'

export interface Props {
  configKey:         string
  breadcrumbs:       Breadcrumb[]
  ActionsComponent?: React.ComponentType<{}>
}

const AppLayoutConfig = memo('AppLayoutConfig', (props: Props) => {

  const {configKey, breadcrumbs, ActionsComponent} = props
  const {configure, removeConfiguration} = React.useContext(AppLayoutContext)

  const breadcrumbsRef      = useContinuousRef(breadcrumbs)
  const ActionsComponentRef = useContinuousRef(ActionsComponent)

  React.useEffect(() => {
    const breadcrumbs      = breadcrumbsRef.current
    const ActionsComponent = ActionsComponentRef.current

    configure(configKey, {breadcrumbs, ActionsComponent})
    return () => {
      removeConfiguration(configKey)
    }
  }, [configure, removeConfiguration, configKey, breadcrumbsRef, ActionsComponentRef])

  return null

})

export default AppLayoutConfig