import React from 'react'
import { useTranslation } from 'react-i18next'
import * as UUID from 'uuid'
import config from '~/config'
import { Module, Ref } from '~/models'
import { FlowPlanner } from '~/stores'
import { memo } from '~/ui/component'
import { TextBlock } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import ModuleForm from '../../modules/ModuleForm'
import ModuleFormModel from '../../modules/ModuleFormModel'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props<M> {
  open:         boolean
  requestClose: () => any
  model:        M
  initialData?: AnyObject
}

const ModuleNodeForm = memo('ModuleNodeForm', <M extends ModuleNodeFormModel>(props: Props<M>) => {

  const {open, requestClose, model, initialData} = props
  const {planner} = useFlowPlanner()

  const [t] = useTranslation('flow_planner')

  function render() {
    return (
      <ModuleForm<ModuleNodeFormModel>
        open={open}
        requestClose={requestClose}
        model={model}
        owningModule={planner.plan?.module}
        commonFields={renderPreamble()}
        initialData={initialData}
      />
    )
  }

  function renderPreamble() {
    return (
      <TextBlock small dim>
        {t('module_form.instructions')}
      </TextBlock>
    )
  }

  return render()

})

export default ModuleNodeForm

export class ModuleNodeFormModel extends ModuleFormModel {

  constructor(
    private readonly planner: FlowPlanner,
    private readonly operation: ModuleNodeFormOperation,
  ) {
    super(null)
  }

  public async submitWithModule(module: Ref<Module>): Promise<SubmitResult | undefined> {
    if ('create' in this.operation) {
      const size = config.planner.defaultComponentSize('module')
      return await this.planner.createNode({
        uuid:   UUID.v4(),
        type:   'module',
        module: module,
        name:   null,
        params: {},
        bounds: {left: this.operation.create.x, top: this.operation.create.y, ...size},
      })
    } else {
      return await this.planner.updateComponents([this.operation.update], node => ({
        ...node,
        module: module,
      }))
    }
  }

}

export type ModuleNodeFormOperation = {
  create: Point
} | {
  update: string
}