import React from 'react'
import { memo } from '~/ui/component'
import { HBox, InfoIcon, Label, SVG } from '~/ui/components'
import { height as pushButtonHeight } from '~/ui/components/PushButton'
import { SVGName } from '~/ui/components/SVG'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import ActionButton, { Props as ActionButtonProps } from './ActionButton'

export interface Props {
  icon?:  SVGName | null
  label:  React.ReactNode
  action: ActionButtonProps
}

const ActionStateLabel = memo('ActionStateLabel', (props: Props) => {

  const {icon, label, action: {name, instruction, ...action}} = props

  const {t} = useResourceTranslation()

  const resolvedLabel       = label ?? t(`actions.${name}.state_label`)
  const resolvedInstruction = instruction === false ? null : instruction ?? t(`actions.${name}.instruction`, {defaultValue: null})

  //------
  // Rendering

  const {colors} = useStyling()
  const $        = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.s}>
        <HBox flex='shrink' gap={layout.padding.s} classNames={$.actionStateLabel}>
          <HBox flex='shrink' gap={layout.padding.inline.m}>
            {renderIcon()}
            {renderLabel()}
          </HBox>

          {action != null && (
            <ActionButton
              {...action}
              name={name}
              instruction={false}
              small
            />
          )}
        </HBox>

        {resolvedInstruction != null && (
          <InfoIcon
            renderTooltip={resolvedInstruction}
          />
        )}
      </HBox>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }

    return (
      <SVG
        name={icon}
        size={layout.icon.s}
        color={colors.semantic.secondary}
      />
    )
  }

  function renderLabel() {
    if (!isReactText(resolvedLabel)) { return resolvedLabel }

    return (
      <Label small bold color={colors.semantic.secondary} flex='shrink'>
        {resolvedLabel}
      </Label>
    )
  }

  return render()

})

export default ActionStateLabel

const useStyles = createUseStyles(theme => ({
  actionStateLabel: {
    background:   theme.bg.semi,

    minHeight:    pushButtonHeight.normal,
    borderRadius: pushButtonHeight.normal / 2,

    ...layout.responsive(size => ({
      padding:      [layout.padding.inline.s, layout.padding.m[size]],
      paddingRight: layout.padding.inline.s,
    })),
  },
}))