import React from 'react'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { observer } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import ComponentBrandingFields from '../ComponentBrandingFields'
import { registerComponentBrandingFields } from '../registry'

export interface Props {}

const AppHeaderBrandingFields = observer('AppHeaderBrandingFields', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.l}>
        <HBox justify='center'>
          <VBox width={logoSize.width}>
            <FormField name='logo' renderAsLabel={false}>
              {bind => <CustomImageField {...bind} size={logoSize} objectFit='contain'/>}
            </FormField>
          </VBox>
        </HBox>

        <ComponentBrandingFields
          shapeField={false}
        />
      </VBox>
    )
  }

  return render()

})

const logoSize = {
  width:  120,
  height: 120,
}

registerComponentBrandingFields('app.header', AppHeaderBrandingFields)
export default AppHeaderBrandingFields