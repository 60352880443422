import React from 'react'
import { Feedback } from '~/models'
import ButtonFeedbackActions from './ButtonFeedbackActions'
import ChoiceFeedbackActions from './ChoiceFeedbackActions'

export default function renderFeedbackActions(type: Feedback['type'], availableTypes?: Array<Feedback['type']>) {
  switch (type) {
    case 'button': return (
      <ButtonFeedbackActions
        availableTypes={availableTypes}
      />
    )
    case 'choice': return (
      <ChoiceFeedbackActions
        availableTypes={availableTypes}
      />
    )
  }
}