import React from 'react'
import { VBoxProps } from '~/ui/components'

export interface Props<T> {
  name:         string
  caption?:     React.ReactNode
  instruction?: string | null

  renderHeaderCell?:          () => React.ReactNode
  renderHeaderAccessoryCell?: () => React.ReactNode
  renderCell?:                (item: T, index: number) => React.ReactNode

  sort?:   string | boolean

  flex?:     boolean | number
  width?:    number
  minWidth?: number
  padded?:   boolean
  align?:    VBoxProps['align']
}

const DataGridColumn = <T extends {}>(props: Props<T>) => {
  // This is a data component, not an actual rendered component.
  return null
}

export default DataGridColumn