import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import Toast, { ToastItemProps } from 'react-toast'
import { isFunction, isPlainObject } from 'lodash'
import { Model } from '~/models'
import { BulkSelector, ModelEndpoint } from '~/stores'
import { ConfirmBox, ConfirmBoxProps } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useContinuousRef, useWithCustomDeps } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, BulkActionFormProps } from '../types'
import wellKnownActions, { WellKnownActionFlags } from './wellknown'

export function useBulkActions<M extends Model>(
  endpoint:          ModelEndpoint<M>,
  actions:           BulkAction<M>[],
  wellknownFlags:    WellKnownActionFlags,
  onActionComplete?: ActionCompleteCallback,
): UseBulkActionsHook<M> {

  const {actionCaption, actionConfirm, actionSuccess} = useResourceTranslation()

  const manager = useSelectionManager()
  const total   = endpoint.meta?.total ?? 0
  const count   = manager?.allSelected ? total : manager?.selectedKeys.length ?? 0

  const [form, setForm] = React.useState<React.ReactElement<BulkActionFormProps<M>> | null>(null)
  const formRef = useContinuousRef(form)

  const getConfirm = React.useCallback((action: BulkAction<M>): Pick<ConfirmBoxProps, 'title' | 'message' | 'destructive'> | null => {
    if (isPlainObject(action.confirm)) {
      return {
        title:       isFunction(action.confirm.title) ? action.confirm.title(count) : action.confirm.title,
        message:     isFunction(action.confirm.message) ? action.confirm.message(count) : action.confirm.message,
        destructive: action.style === 'destructive',
      }
    } else if (action.confirm != null) {
      return actionConfirm(action.name, {count, defaultValue: null})
    } else {
      return null
    }
  }, [actionConfirm, count])

  const getSuccess = React.useCallback((action: BulkAction<M>): Pick<ToastItemProps, 'title' | 'detail'> | null => {
    if (isPlainObject(action.success)) {
      return {
        title:  isFunction(action.success.title) ? action.success.title(count) : action.success.title,
        detail: isFunction(action.success.detail) ? action.success.detail(count) : action.success.detail,
      }
    } else if (action.success !== null) {
      return actionSuccess(action.name, {count, defaultValue: null})
    } else {
      return null
    }
  }, [actionSuccess, count])

  const closeForm = React.useCallback(() => {
    const form = formRef.current
    if (form == null) { return }

    setForm(React.cloneElement(form, {open: false}))
  }, [formRef])

  const postProcessAction = React.useCallback((action: BulkAction<M>, selector: BulkSelector, result: SubmitResult) => {
    const success = getSuccess(action)
    if (success != null && result?.status === 'ok') {
      Toast.show({
        type: 'success',
        ...success,
      })
    }

    if (action.refetch && result.status === 'ok') {
      endpoint.fetch()
    }

    onActionComplete?.(action, selector, result)
  }, [endpoint, getSuccess, onActionComplete])

  const openForm = React.useCallback((action: BulkAction<M>, selector: BulkSelector) => {
    if (action.Form == null) { return }

    const form  = React.createElement(action.Form, {
      open:         true,
      requestClose: closeForm,
      endpoint:     endpoint,
      action:       action,
      selector:     selector,
      count:        count,
      afterSubmit:  postProcessAction.bind(null, action, selector),
    })
    setForm(form)
  }, [postProcessAction, closeForm, count, endpoint])

  const captionForAction = React.useCallback((action: BulkAction<M>) => {
    if (isFunction(action.caption)) {
      return action.caption(count)
    } else if (action.caption == null && action.name === 'remove') {
      return actionCaption('remove_bulk', {count})
    } else if (action.caption == null) {
      return actionCaption(action.name, {count})
    } else {
      return action.caption
    }
  }, [actionCaption, count])

  const executeAction = React.useCallback(async (action: BulkAction<M>, selector: BulkSelector) => {
    const confirm = getConfirm(action)

    const confirmed = !confirm || await ConfirmBox.show(confirm)
    if (!confirmed) { return false }

    let result: SubmitResult | undefined
    if (action.Form != null) {
      openForm(action, selector)
      result = await action.execute?.(endpoint, selector)
    } else if (action.execute != null) {
      result = await action.execute(endpoint, selector)
    } else {
      result = await endpoint.callBulkAction(action.name, selector)
    }

    if (result != null) {
      postProcessAction(action, selector, result)
    }

    return true
  }, [endpoint, getConfirm, openForm, postProcessAction])

  const wellKnownFlags = useWithCustomDeps(
    wellknownFlags,
    flags => Object.entries(flags).filter(it => !!it[1]).map(it => it[0]).sort(),
  )

  const resolveAction = React.useCallback((action: BulkAction<M>): ResolvedBulkAction => {
    return {
      ...action,
      caption: captionForAction(action),
      execute: executeAction.bind(null, action),
    }
  }, [captionForAction, executeAction])

  const resolvedActions = React.useMemo(() => {
    const allActions = [
      ...actions,
      ...wellKnownActions(wellKnownFlags),
    ]

    return allActions.map(resolveAction)
  }, [actions, resolveAction, wellKnownFlags])

  return [resolvedActions, form]

}

export type UseBulkActionsHook<M extends Model> = [
  ResolvedBulkAction[],
  React.ReactElement<BulkActionFormProps<M>> | null,
]

export type ActionCompleteCallback   = (action: BulkAction, selector: BulkSelector, result: SubmitResult) => any

export interface ResolvedBulkAction extends Omit<BulkAction, 'caption' | 'execute'> {
  caption: string
  execute: (selector: BulkSelector) => Promise<boolean>
}

