
import React from 'react'
import { createTheming, createUseStyles as ReactJSS_createUseStyles } from 'react-jss'
import Color from 'color'
import { isFunction, merge } from 'lodash'
import { useStyling } from './StylingContext'
import { Theme } from './Theme'

const ThemingContext = React.createContext<Theme>(Theme.default)
const theming        = createTheming(ThemingContext)

export interface ThemeProviderProps {
  dark?:      boolean
  light?:     boolean
  dim?:       boolean

  primary?:   boolean
  secondary?: boolean
  fgNormal?:  Color | ((theme: Theme) => Color)

  contrast?:  Color | 'primary' | 'secondary'
  debugName?: string

  overrides?: DeepPartial<Theme>
  children?:  React.ReactNode
}

export function ThemeProvider(props: ThemeProviderProps) {
  const {light, dark, contrast, dim, primary, secondary, fgNormal, overrides} = props

  const {guide} = useStyling()

  let parent  = useTheme()
  if (parent.guide !== guide) {
    parent = Theme.create(guide, 'light')
  }

  const theme = React.useMemo(() => {
    let theme = parent

    if (light) {
      theme = Theme.create(guide, 'light')
    }
    if (dark) {
      theme = Theme.create(guide, 'dark')
    }
    if (contrast) {
      const contrastColor =
        contrast === 'primary' ? guide.colors.semantic.primary :
        contrast === 'secondary' ? guide.colors.semantic.secondary :
        contrast

      theme = Theme.create(guide, guide.colors.isDark(contrastColor) ? 'dark' : 'light')
    }
    if (dim) {
      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: theme.fg.dim,
          link:   theme.fg.dim,
        },
      }
    }
    if (primary) {
      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: theme.semantic.primary,
          dim:    theme.semantic.primary.alpha(0.6),
          dimmer: theme.semantic.primary.alpha(0.3),
        },
      }
    }
    if (secondary) {
      theme = {
        ...theme,
        fg: {
          ...theme.fg,
          normal: theme.semantic.secondary,
          dim:    theme.semantic.secondary.alpha(0.6),
          dimmer: theme.semantic.secondary.alpha(0.3),
        },
      }
    }
    if (fgNormal != null) {
      theme = merge({}, theme, {
        fg: {
          normal: isFunction(fgNormal) ? fgNormal(theme) : fgNormal,
        },
      })
    }
    if (overrides != null) {
      theme = merge({}, theme, overrides)
    }

    return theme
  }, [guide, parent, contrast, dark, dim, light, overrides, primary, secondary, fgNormal])

  return (
    <theming.ThemeProvider theme={theme}>
      {props.children}
    </theming.ThemeProvider>
  )
}

export const useTheme = theming.useTheme

type CreateUseStylesFunc =
  <C extends string = string>(
    styles: Record<C, AnyObject> | ((theme: Theme) => Record<C, AnyObject>)
  ) => (data?: unknown) => Record<C, string>

export const createUseStyles: CreateUseStylesFunc =
  styles => ReactJSS_createUseStyles(styles, {theming: theming})
