import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import AppFeatureList from './AppFeatureList'
import ClientTabs from './client-tabs/ClientTabs'

const FeaturesSettings = memo('FeaturesSettings', () => {

  const {t} = useResourceTranslation('projects')

  //------
  // Rendering

  return (
    <VBox flex>
      <HBox align='stretch' flex gap={layout.padding.l}>
        <VBox flex gap={layout.padding.s}>
          <Label h3>{t('features.tabs')}</Label>
          <ClientTabs />
        </VBox>
        <VBox flex gap={layout.padding.s}>
          <Label h3>{t('features.other_features')}</Label>
          <AppFeatureList/>
        </VBox>
      </HBox>
    </VBox>
  )

})

export default FeaturesSettings