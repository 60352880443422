import React from 'react'
import { Channel, ChatBot } from '~/models'
import { dataStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import {
  ClearButton,
  DataGrid,
  Dimple,
  HBox,
  Label,
  ModalDialog,
  ModalDialogProps,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceList } from '~/ui/resources/collection'
import { ResourceChipRow } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import ChatBotForm from './ChatBotForm'

export interface Props extends Omit<ModalDialogProps, 'children'> {

}

const ChatBotsDialog = memo('ChatBotsDialog', (props: Props) => {

  const {t} = useResourceTranslation('chat-bots')
  const endpoint = useModelEndpoint(ChatBot, {include: ['channels']})

  const [editingChatBot, setEditingChatBot] = React.useState<ChatBot | null>(null)
  const [chatBotFormOpen, openChatBotForm, closeChatBotForm] = useBoolean()

  const editChatBot = React.useCallback((chatBot: ChatBot) => {
    setEditingChatBot(chatBot)
    openChatBotForm()
  }, [openChatBotForm])

  const createChatBot = React.useCallback(() => {
    setEditingChatBot(null)
    openChatBotForm()
  }, [openChatBotForm])

  const reload = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='chat-bots'>
        <ModalDialog
          {...props}
          title={t('manage')}
          children={renderBody()}
          width={640}
          height={480}
          contentPadding={true}
          headerRight={renderHeaderRight}
          onWillOpen={reload}
        />
        <ChatBotForm
          open={chatBotFormOpen}
          requestClose={closeChatBotForm}
          chatBot={editingChatBot}
          afterSubmit={reload}
        />
      </ResourceTypeProvider>
    )
  }

  function renderHeaderRight(renderClose: () => any) {
    return (
      <HBox gap={layout.padding.s}>
        {renderAddButton()}
        <Dimple vertical/>
        {renderClose()}
      </HBox>
    )
  }

  function renderAddButton() {
    return (
      <ClearButton
        icon='plus'
        caption={t('new')}
        onTap={createChatBot}
      />
    )
  }

  function renderBody() {
    return (
      <ResourceList<ChatBot> endpoint={endpoint} onItemTap={editChatBot}>
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={1}
        />
        <DataGrid.Column
          name='channels'
          sort={true}
          renderCell={renderChannels}
          flex={2}
        />
      </ResourceList>
    )
  }

  function renderName(chatBot: ChatBot) {
    return (
      <HBox gap={layout.padding.s}>
        <Avatar
          source={chatBot.avatar}
          firstName={chatBot.name}
          size={avatarSize}
        />
        <Label flex='shrink' bold>
          {chatBot.name}
        </Label>
      </HBox>
    )
  }

  function renderChannels(chatBot: ChatBot) {
    const document = dataStore.document(ChatBot, chatBot.id, false)
    const ids      = document?.relationshipData('channels', true).map(l => l.id) ?? []

    return (
      <ResourceChipRow
        Model={Channel}
        ids={ids}
        relationship='included-in'
      />
    )
  }

  return render()

})

export default ChatBotsDialog

export const avatarSize = {
  width: 32,
  height: 32,
}