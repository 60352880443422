import React from 'react'
import { BrandedComponentSpec } from '~/models'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Empty,
  HBox,
  Panel,
  PanelHeader,
  SearchField,
  shrinkFrom,
  Tooltip,
  VBox,
} from '~/ui/components'
import { useContinuousRef } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { useBranding } from '../BrandingContext'
import ComponentBrandingAutoSizedTile from './ComponentBrandingAutoSizedTile'
import ComponentBrandingTree from './ComponentBrandingTree'
import ComponentBrandingTreeView from './ComponentBrandingTreeView'

export interface Props {
  focusedComponentName:    string | null
  focusedComponentVariant: string | null
  focusedComponentSpec?:   BrandedComponentSpec
  requestFocusComponent:   (name: string) => any
}

const ComponentBrandingFinder = observer('ComponentBrandingFinder', (props: Props) => {

  const {
    focusedComponentName,
    focusedComponentVariant,
    focusedComponentSpec,
    requestFocusComponent,
  } = props

  const {t} = useResourceTranslation('brandings')
  const {guide} = useBranding()

  // We only need the guide once to get the component tree.
  const guideRef = useContinuousRef(guide)

  const tree = React.useMemo(
    () => new ComponentBrandingTree(guideRef.current),
    [guideRef],
  )

  const expandAll   = React.useMemo(() => tree.expandAll.bind(tree), [tree])
  const collapseAll = React.useMemo(() => tree.collapseAll.bind(tree), [tree])
  const toggleApp   = React.useMemo(() => tree.toggleApp.bind(tree), [tree])
  const toggleWeb   = React.useMemo(() => tree.toggleWeb.bind(tree), [tree])

  const setSearch = React.useCallback((search: string | null) => {
    tree.setFilter(search)
  }, [tree])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex align='stretch' gap={layout.padding.m}>
        {renderTreeView()}
        {renderFocusedComponent()}
      </HBox>
    )
  }

  function renderTreeView() {
    return (
      <VBox flex={shrinkFrom(treeViewBaseWidth)}>
        <Panel flex header={renderTreeHeader()}>
          <ComponentBrandingTreeView
            tree={tree}
            selectedComponentName={focusedComponentName}
            onSelectComponent={requestFocusComponent}
          />
          {renderSearch()}
        </Panel>
      </VBox>
    )
  }

  function renderTreeHeader() {
    return (
      <PanelHeader
        caption={t('finder.tree.title')}
        right={renderTreeHeaderRight()}
      />
    )
  }

  function renderTreeHeaderRight() {
    return (
      <HBox gap={layout.padding.inline.m + layout.padding.inline.s}>
        <Tooltip renderTooltip={t('finder.tree.expand_all')}>
          <ClearButton
            icon='plus-circle-stack'
            onTap={expandAll}
          />
        </Tooltip>
        <Tooltip renderTooltip={t('finder.tree.collapse_all')}>
          <ClearButton
            icon='minus-circle-stack'
            onTap={collapseAll}
          />
        </Tooltip>
      </HBox>
    )
  }

  function renderSearch() {
    return (
      <HBox padding={layout.padding.s} gap={layout.padding.inline.m + layout.padding.inline.s}>
        <VBox flex>
          <SearchField
            onSearch={setSearch}
            inputStyle='light'
          />
        </VBox>
        {renderAppWebFilters()}
      </HBox>
    )
  }

  function renderAppWebFilters() {
    return (
      <HBox gap={layout.padding.inline.m + layout.padding.inline.s}>
        <Tooltip renderTooltip={t(`finder.tree.show_app.${tree.hideAppOnly ? 'off' : 'on'}`)}>
          <ClearButton
            icon='iphone'
            dim={tree.hideAppOnly}
            onTap={toggleApp}
          />
        </Tooltip>
        <Tooltip renderTooltip={t(`finder.tree.show_web.${tree.hideWebOnly ? 'off' : 'on'}`)}>
          <ClearButton
            icon='web'
            dim={tree.hideWebOnly}
            onTap={toggleWeb}
          />
        </Tooltip>
      </HBox>
    )
  }

  function renderFocusedComponent() {
    if (focusedComponentName == null) {
      return (
        <Empty
          {...t('finder.no_focused_component')}
          flex
        />
      )
    } else {
      return (
        <VBox flex classNames={$.focusedComponent}>
          <ComponentBrandingAutoSizedTile
            componentName={focusedComponentName}
            variant={focusedComponentVariant}
            spec={focusedComponentSpec}
            flex='both'
          />
        </VBox>
      )
    }
  }

  return render()

})

export default ComponentBrandingFinder

export const treeViewBaseWidth = 320
export const treeViewMinWidth = 240
export const instructionsMaxWidth = 480

const useStyles = createUseStyles({
  focusedComponent: {
    overflow: 'hidden',
    padding:  2,
  },

  instructions: {
    maxWidth: instructionsMaxWidth,
  },
})