import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Challenge, ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import { Empty, HBox, PushButton } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout, useStyling } from '~/ui/styling'
import ChallengeForm from '../ChallengeForm'
import ChallengeTaskForm from '../tasks/ChallengeTaskForm'
import ChallengeTasksBulkEditor from '../tasks/ChallengeTasksBulkEditor'
import ChallengeTasksEditorContextProvider from '../tasks/ChallengeTasksEditorContext'
import ChallengeDeadlineForm from './ChallengeDeadlineForm'
import ChallengeScoring from './ChallengeScoring'
import ChallengeTargeting from './ChallengeTargeting'
import ChallengeTaskCarousel from './ChallengeTaskCarousel'
import PublicationPanel from './PublicationPanel'

export type Props = RouteComponentProps<ResourceDetailParams>

const ChallengeScreen = memo('ChallengeScreen', (props: Props) => {

  const {t, actionCaption} = useResourceTranslation('challenges')

  const {colors} = useStyling()

  //------
  // Editing tasks

  const [editingTask, setEditingTask] = React.useState<ChallengeTask | null>(null)
  const [newTaskIndex, setNewTaskIndex] = React.useState<number | null>(null)
  const [taskFormOpen, openTaskForm, closeTaskForm] = useBoolean(false)
  const [tasksBulkEditorIsOpen, openTasksBulkEditor, closeTasksBulkEditor] = useBoolean(false)

  const editTask = React.useCallback((task: ChallengeTask) => {
    setEditingTask(task)
    openTaskForm()
  }, [openTaskForm])

  const addTask = React.useCallback((atIndex?: number) => {
    setEditingTask(null)
    openTaskForm()
    setNewTaskIndex(atIndex ?? null)
  }, [openTaskForm, setNewTaskIndex])

  const create = React.useCallback(() => {
    addTask()
  }, [addTask])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<Challenge>
        Model={Challenge}
        id={props.match.params.id}
        include={['competitions', 'groups', 'teams']}

        EditFormComponent={[{
          name:    'edit',
          icon:    'pencil',
          caption: actionCaption('edit'),
          Form:    ChallengeForm,
        }, {
          name:    'deadline',
          icon:    'clock',
          caption: actionCaption('edit_deadline'),
          Form:    ChallengeDeadlineForm,
        }]}

        renderInfo={renderInfo}
        renderBody={renderBody}
        renderSummaryFooter={renderSummaryFooter}
        BreadcrumbsActionsComponent={renderBreadcrumbsActions}
        renderActions={renderActions}
      />
    )
  }

  function renderInfo(challenge: Challenge) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item
          markup
          summary={t('created', {when: challenge.createdAt})}
        />
        {challenge.deadline != null && (
          <SummaryInfo.Item
            summary={t('info.deadline', {when: challenge.deadline.toFormat('DDD, HH:mm')})}
            markup
          />
        )}
        {challenge.lifetime != null && (
          <SummaryInfo.Item
            summary={t('info.lifetime', {when: challenge.lifetime.toString()})}
            markup
          />
        )}
        <SummaryInfo.Item
          markup
          summary={t(`allow_redo.${challenge.allowRedo ? 'on' : 'off'}`)}
          bold={challenge.allowRedo}
          color={challenge.allowRedo ? colors.semantic.warning : undefined}
        />
        <SummaryInfo.Item
          summary={challenge.taskCount === 0 ? t('task_count_zero') : t('task_count', {count: challenge.taskCount})}
          markup
          icon={challenge.taskCount === 0 ? 'cross' : 'check'} color={challenge.taskCount === 0 ? colors.semantic.negative : colors.semantic.positive}
        />
      </SummaryInfo>
    )
  }

  function renderSummaryFooter(challenge: Challenge) {
    return [
      <ChallengeScoring key='scoring' challenge={challenge}/>,
      <ChallengeTargeting key='targeting' challenge={challenge}/>,
    ]
  }

  function renderActions(challenge: Challenge) {
    return (
      <PublicationPanel
        challenge={challenge}
      />
    )
  }

  function renderBody(challenge: Challenge) {
    return (
      <ChallengeTasksEditorContextProvider challenge={challenge}>
        <ChallengeTaskCarousel
          renderEmpty={renderEmpty.bind(null, challenge)}
          requestEditTask={editTask}
          requestCreateTask={addTask}
        />
        {renderTaskForm(challenge)}
        {renderTasksBulkEditor(challenge)}
      </ChallengeTasksEditorContextProvider>
    )
  }

  function renderEmpty() {
    return (
      <Empty
        flex='both'
        icon='challenge'
        title={t('empty.title')}
        detail={t('empty.detail_create')}
        children={renderAddTask(false)}
      />
    )
  }

  function renderTaskForm(challenge: Challenge) {
    return (
      <ChallengeTaskForm
        open={taskFormOpen}
        requestClose={closeTaskForm}
        challenge={challenge}
        task={editingTask}
        index={newTaskIndex}
      />
    )
  }

  function renderTasksBulkEditor(challenge: Challenge) {
    return (
      <ChallengeTasksBulkEditor
        open={tasksBulkEditorIsOpen}
        requestClose={closeTasksBulkEditor}
      />
    )
  }

  const renderAddTask = React.useCallback((small: boolean) => {
    return (
      <PushButton
        icon='plus'
        caption={actionCaption('add_task')}
        onTap={create}
        small={small}
      />
    )
  }, [actionCaption, create])

  const renderManageTasks = React.useCallback(() => {
    return (
      <PushButton
        icon='pencil'
        caption={actionCaption('manage_tasks')}
        onTap={openTasksBulkEditor}
        small
      />
    )
  }, [actionCaption, openTasksBulkEditor])

  const renderBreadcrumbsActions = React.useCallback(() => {
    return(
      <HBox gap={layout.padding.s}>
        {renderAddTask(true)}
        {renderManageTasks()}
      </HBox>
    )
  }, [renderAddTask, renderManageTasks])

  return render()

})

export default ChallengeScreen