import socket from 'socket.io-react'
import { AnswerScopeLinkage } from '~/ui/app/answers/explore/AnswerScopeField'
import AnswersService, { Filters as AnswersQueryFilters } from './answers/AnswersService'
import { register } from './support'

export interface Filters extends AnswersQueryFilters {
  questionUUIDs?: string[]
}

export class AnswersStore {
  public getAnswersService(scope: AnswerScopeLinkage, filters?: Filters) {
    return new AnswersService(socket, scope, filters)
  }
}

export default register(new AnswersStore())