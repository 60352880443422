import { makeObservable } from 'mobx'
import { FormModel, SubmitResult } from '~/ui/form'

export default abstract class CollectionFieldItemFormModel<T> implements FormModel<T> {

  constructor(
    protected readonly item: T | null,
    private readonly onSubmit: (item: T) => any,
  ) {
    makeObservable(this)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const result = this.validate()

    if (result.status === 'ok') {
      this.onSubmit?.(this.buildItem())
    }
    return result
  }

  protected validate(): SubmitResult {
    return {status: 'ok'}
  }

  protected abstract buildItem(): T

}