import React from 'react'
import { operatorStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, Scroller, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import OperatorPanel from './OperatorPanel'

const OperatorCarousel = observer('OperatorCarousel', () => {

  const service      = operatorStore.service
  const participants = Array.from(service?.participants.keys() ?? [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Scroller horizontal classNames={$.operatorCarousel} contentClassNames={$.scrollerContent}>
        <HBox gap={layout.padding.s} align='stretch'>
          {participants.map(renderPanel)}
        </HBox>
      </Scroller>
    )
  }

  function renderPanel(participantID: string) {
    return (
      <VBox key={participantID}>
        <OperatorPanel
          participantID={participantID}
        />
      </VBox>
    )
  }

  return render()

})

export default OperatorCarousel

const useStyles = createUseStyles({
  operatorCarousel: {
    margin: -4,
  },

  scrollerContent: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size] + 4,
      paddingLeft: 4,
    })),
  },
})