import React from 'react'
import { startCase, upperFirst } from 'lodash'
import { NotificationAction } from '~/notifications'
import { memo } from '~/ui/component'
import { ClearButton, ClearButtonProps, PushButton, PushButtonProps } from '~/ui/components'

export interface Props {
  type:   'clear' | 'push'
  action: NotificationAction
}

const NotificationActionButton = memo('NotificationActionButton', (props: Props) => {

  const {type, action} = props

  const buttonProps = React.useMemo((): PushButtonProps & ClearButtonProps => ({
    caption: action.caption ?? upperFirst(startCase(action.name).toLowerCase()),
    href:    action.href,
    onTap:   action.onSelect,
    small:   true,
  }), [action.caption, action.href, action.name, action.onSelect])

  if (type === 'push') {
    return <PushButton {...buttonProps}/>
  } else {
    return <ClearButton {...buttonProps}/>
  }

})

export default NotificationActionButton