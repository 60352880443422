import React from 'react'
import { PlanTextAnnotation } from '~/models'
import { observer } from '~/ui/component'
import { TextBlock, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useSelection } from '../canvas/SelectionContext'
import { useFlowPlanner } from '../FlowPlannerContext'
import { useTextAnnotationStyle } from './styles'
import TextAnnotationEditor from './TextAnnotationEditor'

export interface Props {
  annotation: PlanTextAnnotation
}

const TextAnnotationView = observer('TextAnnotationView', (props: Props) => {

  const {annotation} = props

  const {planner} = useFlowPlanner()
  const editing   = planner.editingComponent?.uuid === annotation.uuid

  const {manager} = useSelection()

  React.useEffect(() => {
    manager?.setSelectionHidden(editing)
  }, [editing, manager])

  const labelStyle = useTextAnnotationStyle(annotation)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.textAnnotationView}>
        {editing ? (
          renderEditing()
        ) : (
          renderViewing()
        )}
      </VBox>
    )
  }

  function renderViewing() {
    return (
      <VBox flex justify='middle' onDoubleClick={edit}>
        <TextBlock classNames={$.label} style={labelStyle} hand dim markup={false}>
          {annotation.text}
        </TextBlock>
      </VBox>
    )
  }

  function renderEditing() {
    return (
      <TextAnnotationEditor
        annotation={annotation}
      />
    )
  }

  const edit = React.useCallback(() => {
    planner.editComponent({
      type: 'annotation',
      uuid: annotation.uuid,
      annotation: annotation,
    })
  }, [annotation, planner])

  return render()

})

export default TextAnnotationView

const useStyles = createUseStyles(theme => ({
  textAnnotationView: {
    position:  'relative',
    minHeight: 32,
  },

  label: {
    flex:     [0, 1, 'auto'],
    overflow: 'hidden',
    padding:  layout.padding.inline.s,
  },
}))