import React from 'react'
import { omit } from 'lodash'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import clipboard from 'rich-clipboard'
import { arrayMove } from 'ytil'
import { ClipboardType } from '~/clipboard'
import { Script, ScriptMessage } from '~/models'
import { dataStore, languagesStore, ModelDocument } from '~/stores'
import { ScriptEditingProvider } from '~/ui/app/scripts/editor/ScriptEditingContext'
import { observer } from '~/ui/component'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { messageDescription } from './data'

export class ScriptEditor {

  constructor(
    public readonly scriptID: string,
    initialLanguage: string,
  ) {
    this.language = initialLanguage
    this.document = dataStore.document(Script, this.scriptID)

    makeObservable(this)
  }

  public readonly document: ModelDocument<Script>

  @observable
  public language: string

  @computed
  public get fetchStatus() {
    return this.document.fetchStatus
  }

  @computed
  public get script() {
    return this.document.data
  }

  //------
  // Saving

  @observable
  public readOnly: boolean = false

  @observable
  public saving: boolean = false

  @action
  public async saveCurrentVersion(update: AnyObject): Promise<SubmitResult | undefined> {
    try {
      this.saving = true
      const result = await this.document.update(update)
      return translateFormErrorPaths(result, path => path.replace(/^versions\.\d+\./, ''))
    } finally {
      runInAction(() => {
        this.saving = false
      })
    }
  }

}

interface ScriptEditorContext {
  editor: ScriptEditor | null
  script: Script | null
  language: string
  loading: boolean

  updateMessages: (uuids: string[], update: (message: ScriptMessage) => DeepPartial<ScriptMessage>) => Promise<SubmitResult | undefined>
  addMessages:    (messages: DeepPartial<ScriptMessage>[]) => Promise<SubmitResult | undefined>
  saveMessage:    (uuid: string, data: AnyObject) => Promise<SubmitResult | undefined>
  removeMessages: (uuids: string[]) => Promise<SubmitResult | undefined>

  copyMessages:  (uuids: string[]) => void
  moveMessage:   (uuid: string, toIndex: number) => Promise<SubmitResult | undefined>
}

const ScriptEditorContext = React.createContext<ScriptEditorContext>({
  script: null,
  editor: null,
  language: languagesStore.defaultLanguage,
  loading: false,

  updateMessages: () => Promise.resolve(void 0),
  addMessages:    () => Promise.resolve(void 0),
  saveMessage:    () => Promise.resolve(void 0),
  removeMessages: () => Promise.resolve(void 0),
  copyMessages:   () => void 0,
  moveMessage:    () => Promise.resolve(void 0),
})

export interface ScriptEditorContextProviderProps {
  scriptID:  string
  children?: React.ReactNode
}

export const ScriptEditorContainer = observer('ScriptEditorContainer', (props: ScriptEditorContextProviderProps) => {

  const {scriptID, children} = props
  const language = languagesStore.defaultLanguage

  const editor = React.useMemo(
    () => new ScriptEditor(scriptID, language),
    [language, scriptID],
  )

  React.useEffect(() => {
    editor.document.fetch()
  }, [editor.document])

  const {script, fetchStatus} = editor
  const messages = React.useMemo(
    () => script?.messages({language, fallback: false}) ?? [],
    [language, script],
  )

  const updateMessages = React.useCallback(async (uuids: string[], update: (message: ScriptMessage) => any) => {
    if (script == null) { return }

    const messages = script.messages({language, fallback: false})
      .map(msg => uuids.includes(msg.uuid) ? {...msg, ...update(msg)} : msg)

    const result = await editor.saveCurrentVersion?.({
      messagesByLanguage: {[language]: messages},
    })
    return translateFormErrorPaths(result, path => path.replace(/^messages\.\d+\./, ''))
  }, [editor, language, script])

  const addMessages = React.useCallback(async (newMessages: DeepPartial<ScriptMessage>[]) => {
    if (script == null) { return }

    const messages = [...script.messages({language, fallback: false}), ...newMessages]
    const result = await editor.saveCurrentVersion?.({
      messagesByLanguage: {[language]: messages},
    })
    return translateFormErrorPaths(result, path => path.replace(/^messages\.\d+\./, ''))
  }, [editor, language, script])

  const context = React.useMemo((): ScriptEditorContext => ({
    script,
    editor,
    language,
    loading: fetchStatus === 'fetching',

    updateMessages,
    addMessages,

    saveMessage: async (uuid, message) => {
      if (uuid === '+new') {
        return addMessages([message])
      } else {
        return updateMessages([uuid], () => message)
      }
    },

    removeMessages: async uuids => {
      if (script == null) { return }

      const nextMessages = script.messages({language}).filter(msg => !uuids.includes(msg.uuid))
      return await editor.saveCurrentVersion?.({
        messagesByLanguage: {[language]: nextMessages},
      })
    },

    copyMessages: uuids => {
      if (script == null) { return }

      const messages = script.messages({language}).filter(msg => uuids.includes(msg.uuid)) ?? []
      if (messages.length === 0) { return [] }

      const text      = messages.map(messageDescription).join('\n\n')
      const templates = messages.map(message => omit(message, 'uuid'))

      clipboard.write([
        {type: 'text/plain', data: text},
        {type: ClipboardType.SCRIPT_MESSAGES, data: templates},
      ])
    },

    moveMessage: async (uuid, toIndex) => {
      if (script == null) { return }

      const messages = [...script.messages({language})]
      const fromIndex = messages.findIndex(msg => msg.uuid === uuid)
      if (fromIndex < 0) { return }

      const nextMessages = arrayMove(messages, fromIndex, toIndex)
      return await editor.saveCurrentVersion?.({
        messagesByLanguage: {[language]: nextMessages},
      })
    },
  }), [script, editor, fetchStatus, updateMessages, addMessages, language])

  return (
    <ScriptEditorContext.Provider value={context}>
      <ScriptEditingProvider messages={messages}>
        {children}
      </ScriptEditingProvider>
    </ScriptEditorContext.Provider>
  )
})

export function useScriptEditor() {
  return React.useContext(ScriptEditorContext)
}