import React from 'react'
import { observer } from '~/ui/component'
import { Center, Empty, Panel, Spinner, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useRegistrationWidgetModel } from './RegistrationWidgetContext'
import RegistrationWidgetLogo from './RegistrationWidgetLogo'
import { RegistrationTranslationKey } from './types'

export interface Props {
  renderDefaultLogo:       (size: Size) => React.ReactNode
  renderLanguageSwitcher?: () => React.ReactNode

  renderFields:       () => React.ReactNode
  renderConfirmation: () => React.ReactNode
}

const RegistrationPanel = observer('RegistrationPanel', (props: Props) => {

  const {
    renderDefaultLogo,
    renderLanguageSwitcher,
    renderFields,
    renderConfirmation,
  } = props

  const {guide} = useStyling()
  const model   = useRegistrationWidgetModel()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.RegistrationPanel} branding={guide.web.registrationPanel}>
        <VBox flex padding={layout.padding.l} gap={layout.padding.m}>
          {renderLogo()}
          {renderBody()}
        </VBox>
        {renderLanguageSwitcher != null && (
          <VBox classNames={$.languageSwitcher}>
            {renderLanguageSwitcher()}
          </VBox>
        )}
      </Panel>
    )
  }

  function renderLogo() {
    return (
      <RegistrationWidgetLogo
        renderDefaultLogo={renderDefaultLogo}
      />
    )
  }

  function renderBody() {
    switch (model.status) {
      case 'preflight':
        return renderLoading()
      case 'code': case 'register':
        return renderFields()
      case 'registered':
        return renderConfirmation()
      default:
        return renderError(model.errorReason)
      }
  }

  function renderLoading() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  function renderError(reason: string) {
    const title  = model.translate(`errors.${reason}.title` as RegistrationTranslationKey)
    const detail = model.translate(`errors.${reason}.detail` as RegistrationTranslationKey)

    return (
      <Empty
        title={title}
        detail={detail ?? ''}
        flex
      />
    )
  }

  return render()

})

export default RegistrationPanel

export const registrationWidgetSize = {
  width:  360,
  height: 576,
}

const useStyles = createUseStyles({
  RegistrationPanel: {
    ...registrationWidgetSize,
    textAlign: 'center',

    position: 'relative',
  },

  languageSwitcher: {
    position: 'absolute',
    top:      0,
    right:    0,
    padding:  layout.padding.inline.l,
  },
})