import I18next from 'i18next'
import { authenticationStore } from '~/stores'
import { BulkAction } from '~/ui/resources/collection'
import { saveAs } from '~/ui/util'
import CopyToProjectForm from '../copy-to-project/CopyToProjectForm'

export interface WellKnownActionFlags {
  export?: boolean
  remove?: boolean
  copy?:   boolean
}

export function wellKnownActions(flags: WellKnownActionFlags) {
  const actions: BulkAction[] = []

  if (flags.export) {
    actions.push({
      name: 'export',
      icon: 'download',
      execute: async (endpoint, selector) => {
        const blob = await endpoint.exportData(selector)
        if (blob == null) { return }

        const plural = I18next.t(`resources.${endpoint.Model.resourceType}.plural`)
        saveAs(blob, `${plural}.xlsx`)
      },
    })
  }

  if (flags.copy) {
    actions.push({
      name:    'duplicate',
      icon:    'copy',
      refetch: true,
      execute: (endpoint, selector) => endpoint.duplicate(selector),
    })
  }

  if (flags.copy && authenticationStore.user?.isController) {
    actions.push({
      name:    'copy-to-project',
      icon:    'external',
      Form:    CopyToProjectForm,
      success: null,
    })
  }

  if (flags.remove) {
    actions.push({
      name:    'remove_bulk',
      style:   'destructive',
      icon:    'trash',
      confirm: true,
      execute: (endpoint, selector) => endpoint.delete(selector),
    })
  }

  return actions
}

export default wellKnownActions