import React from 'react'
import { Challenge, Targeting } from '~/models'
import { dataStore } from '~/stores'
import { TargetingForm, TargetingFormModel, TargetingFormProps } from '~/ui/app/targeting'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

export interface Props extends Omit<TargetingFormProps, 'model'> {
  challenge: Challenge
}

const ChallengeTargetingForm = observer('ChallengeTargetingForm', (props: Props) => {

  const {challenge, ...rest} = props

  const formModel = React.useMemo(
    () => new ChallengeTargetingFormModel(challenge),
    [challenge],
  )

  //------
  // Rendering

  function render() {
    return (
      <TargetingForm
        model={formModel}
        {...rest}
      />
    )
  }

  return render()

})

export default ChallengeTargetingForm

class ChallengeTargetingFormModel extends TargetingFormModel {

  constructor(
    public readonly challenge: Challenge,
  ) {
    super(challenge.targeting ?? Targeting.empty())
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Challenge, this.challenge.id, {
      targeting: this.buildTargeting(),
    })
  }

}