import React from 'react'
import { useContinuousRef } from '~/ui/hooks'
import { closest, isInteractiveElement } from '~/ui/util'
import { useCanvas } from '../FlowPlannerCanvasContext'
import { CanvasMode } from '../types'

export function useModeToggleKeys() {

  const {mode, setMode} = useCanvas.unoptim()
  const modeRef = useContinuousRef(mode)

  const changeModeTo = React.useCallback((mode: CanvasMode) => {
    if (modeRef.current === mode) { return }
    setMode(mode)
  }, [modeRef, setMode])

  const handleKeyDown = React.useCallback((event: KeyboardEvent) => {
    const input = closest(event.target as Element, isInteractiveElement)
    if (input != null) { return }

    if (event.key === ' ' && modeRef.current !== 'pan') {
      changeModeTo('pan')
      event.preventDefault()
    }
  }, [changeModeTo, modeRef])

  const handleKeyUp = React.useCallback((event: KeyboardEvent) => {
    const input = closest(event.target as Element, isInteractiveElement)
    if (input != null) { return }

    if (event.key === ' ' && modeRef.current !== 'select') {
      event.preventDefault()
      changeModeTo('select')
    }
  }, [changeModeTo, modeRef])

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

}