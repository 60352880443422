import React from 'react'
import Toast from 'react-toast'
import { Participant } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  ConfirmBox,
  Dimple,
  HBox,
  Label,
  LinkLabel,
  TextField,
  VBox,
} from '~/ui/components'
import { InlineFormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { InlineResourceForm } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import ParticipantLoginActions from './ParticipantLoginActions'

export interface Props {
  participant: Participant
}

const ParticipantAuthForm = observer('ParticipantAuthForm', (props: Props) => {

  const {participant} = props

  const {t, actionCaption} = useResourceTranslation()

  //------
  // Callbacks

  const resetPin = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      title:   t('actions.reset_pin.confirm.title'),
      message: t('actions.reset_pin.confirm.message', {participant}),
    })
    if (!confirmed) { return }

    const endpoint = dataStore.endpoint(Participant)
    await endpoint.callBulkAction('auth-reset:request', {
      data: [{type: 'participants', id: participant.id}],
      meta: {},
    })

    Toast.show({
      type:   'success',
      title:  t('actions.reset_pin.success.title'),
      detail: t('actions.reset_pin.success.detail', {participant}),
    })
  }, [participant, t])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderForm()}
        <Dimple horizontal/>
        <ParticipantLoginActions participant={participant}/>
      </VBox>
    )
  }

  function renderForm() {
    return (
      <InlineResourceForm Model={Participant} model={participant} fieldGap={layout.padding.s}>
        <InlineFormField
          name='email'
          renderView={email => <LinkLabel href={`mailto:${email}`}>{email}</LinkLabel>}
          renderEdit={bind => <TextField {...bind} autoComplete='email'/>}
        />
        <InlineFormField
          name='pin'
          renderView={() => <Label mono>*****</Label>}
          renderEdit={bind => <TextField {...bind}/>}
          renderActions={editButton => (
            <HBox gap={layout.padding.inline.m}>
              {editButton}
              <Dimple vertical/>
              <ClearButton
                icon='reset'
                caption={actionCaption('reset-pin')}
                onTap={resetPin}
                padding='none'
                small
              />
            </HBox>
          )}
        />
      </InlineResourceForm>
    )
  }

  return render()

})

export default ParticipantAuthForm