import I18next from 'i18next'

export function formatOrdinal(value: number) {
  const str = value.toString()

  const suffixes: Record<string, string> = I18next.t('ordinal:suffixes')
  for (const [pattern, suffix] of Object.entries(suffixes)) {
    const regexp = new RegExp(`^${pattern}$`)
    if (regexp.test(str)) {
      return `${str}${suffix}`
    }
  }

  return str
}

export function formatAmount(value: number, options: Intl.NumberFormatOptions = {}) {
  const format = new Intl.NumberFormat(undefined, {
    style:    'currency',
    currency: 'EUR',
    ...options,
  })
  return format.format(value)
}