import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { SystemNotification } from '~/models'
import { observer } from '~/ui/component'
import { Empty, HBox, panelBorderRadius, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import NotificationDetail from './detail/NotificationDetail'
import NotificationList from './NotificationList'

export interface Params {
  id?: string
}

export type Props = RouteComponentProps<Params>

const NotificationsScreen = observer('NotificationsScreen', (props: Props) => {

  const {id} = props.match.params
  const {t} = useResourceTranslation('notifications')

  const [notification] = useModelDocumentData(SystemNotification, id ?? null)

  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({
      icon:    'satellite',
      caption: t('title'),
      href:   '/notifications',
    })

    if (notification != null) {
      breadcrumbs.push({
        caption: notification.title,
      })
    }

    return breadcrumbs
  }, [notification, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex align='stretch' gap={layout.padding.l} padding={layout.padding.m}>
        <AppLayoutConfig
          configKey='activity'
          breadcrumbs={breadcrumbs}
        />

        <VBox flex={2}>
          <NotificationList/>
        </VBox>
        <VBox flex={3}>
          {id == null ? (
            <Empty
              {...t('select_log')}
              classNames={$.empty}
              flex
            />
          ) : (
            <NotificationDetail
              key={id}
              id={id}
            />
          )}
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default NotificationsScreen

const useStyles = createUseStyles(theme => ({
  empty: {
    borderRadius: panelBorderRadius(theme),
    border:       [1, 'solid', theme.fg.dimmer],
  },
}))