import { flatMap, slugify } from 'ytil'
import ImportSession from './ImportSession'
import { ImportFieldDescriptor, ImportFieldGroup, ImportOptions, ImportResult } from './types'

export default abstract class ImportModel {

  public abstract type: string

  private _fieldGroups?: ImportFieldGroup[]
  public get fieldGroups(): ImportFieldGroup[] {
    if (this._fieldGroups == null) {
      this._fieldGroups = this.buildFields()
    }
    return this._fieldGroups
  }

  public get fields(): ImportFieldDescriptor[] {
    return flatMap(this.fieldGroups, it => it.fields)
  }

  protected abstract buildFields(): ImportFieldGroup[]

  public abstract defaults: Record<string, any>

  public fieldSuggestion(column: string) {
    return this.fields.find(it => slugify(it.key) === slugify(column))
  }

  public abstract import(session: ImportSession, options: ImportOptions): Promise<ImportResult | undefined>

}