import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Empty, SearchField, VBox, VBoxProps } from '~/ui/components'
import { assignRef, releaseRef, useContinuousRef } from '~/ui/hooks'
import { animation, colors, createUseStyles, layout, shadows } from '~/ui/styling'
import Circle from './Circle'
import { GoogleMapContainer } from './GoogleMapContext'
import GoogleMapManager, { GoogleMapState } from './GoogleMapManager'
import Marker from './Marker'
import { LatLong } from './types'

export interface Props {
  zoom?:        number
  center?:      LatLong
  bounds?:      [LatLong, LatLong]

  initialZoom?:        number
  initialCenter?:      LatLong
  initialBounds?:      [LatLong, LatLong]

  options?:            google.maps.MapOptions

  spanFeatures?:       boolean
  maximumInitialZoom?: number
  centerOnClick?:      boolean
  precision?:          number

  showSearch?:    boolean
  initialSearch?: string

  flex?:        VBoxProps['flex']
  classNames?:  React.ClassNamesProp
  transparent?: boolean
  children?:    React.ClassNamesProp

  onReady?:          () => any
  onClick?:          (coordinate: LatLong, event: google.maps.MapMouseEvent) => any
  onCenterChange?:   (coordinate: LatLong) => any
  onReverseGeocode?: (result: google.maps.GeocoderResult | null) => any
  onBoundsChange?:   (bounds: [LatLong, LatLong]) => any
  onCalculateZoom?:  (zoom: number) => any
  onSearch?:         (search: string) => any

  managerRef?: React.Ref<GoogleMapManager>
}

function googleMapsAvailable() {
  return 'google' in window
}

const GoogleMap = memo('GoogleMap', (props: Props) => {

  const {
    managerRef,
    initialSearch: props_initialSearch,
    flex,
  } = props

  const [t] = useTranslation('google-maps')

  const elementRef      = React.useRef<HTMLDivElement>(null)
  const initialPropsRef = useContinuousRef(props)

  const [state, setState] = React.useState<GoogleMapState>(GoogleMapState.default())
  const {searchState} = state

  const [searchQuery, setSearchQuery] = React.useState<string | null>(null)
  const initialSearch = props_initialSearch ?? searchQuery

  const manager = React.useMemo(() => new GoogleMapManager(
    elementRef,
    setState,
    initialPropsRef.current, // Props contain all options
    {
      ...initialPropsRef.current, // As well as all callbacks
      onSearch: setSearchQuery,
    },
  ), [initialPropsRef])

  React.useEffect(() => {
    if (!googleMapsAvailable()) { return }
    return manager.createMap()
  }, [manager])

  React.useEffect(() => {
    assignRef(managerRef, manager)
    return () => { releaseRef(managerRef) }
  }, [manager, managerRef])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const {showSearch, classNames, transparent} = props
    if (!googleMapsAvailable()) {
      return (
        <VBox classNames={[$.container, 'unavailable']} flex={flex}>
          <Empty {...t('unavailable')}/>
        </VBox>
      )
    } else {
      return (
        <VBox classNames={[$.container, {transparent}, classNames]} flex={flex}>
          {showSearch && renderSearch()}
          {renderNotFound()}

          <GoogleMapContainer manager={manager}>
            <VBox
              ref={elementRef}
              classNames={$.map}
              flex='both'
            />
            {props.children}
          </GoogleMapContainer>
        </VBox>
      )
    }
  }

  const search = React.useCallback((search: string | null) => {
    manager.search(search)
  }, [manager])

  function renderSearch() {
    return (
      <VBox classNames={$.search}>
        <SearchField
          initialSearch={initialSearch}
          searching={searchState === 'searching'}
          onSearch={search}
          minimumQueryLength={3}
          searchWhileTyping={false}
          inputStyle='light'
        />
      </VBox>
    )
  }

  function renderNotFound() {
    const visible = searchState === 'notfound'

    return (
      <div classNames={[$.notFound, {visible}]}>
        <div classNames={$.notFoundContent}>
          <span>Location not found</span>
        </div>
      </div>
    )
  }

  return render()

})

Object.assign(GoogleMap, {
  Marker,
  Circle,
})

export default GoogleMap as typeof GoogleMap & {
  Marker: typeof Marker
  Circle: typeof Circle
}

const useStyles = createUseStyles(theme => ({
  container: {
    position: 'relative',
    overflow: 'hidden',

    flex:     [1, 1, 'auto'],
    ...layout.flex.column,

    '& .gm-bundled-control': {
      zIndex: 20,
    },
  },

  opaqueContainer: {
    background: colors,
  },

  search: {
    position:   'absolute',
    zIndex:     20,
    background: theme.bg.alt,
    boxShadow:  shadows.depth(2),

    top:      layout.padding.inline.m,
    left:     layout.padding.inline.m,
    width:    240,
  },

  map: {
    ...layout.overlay,
  },

  notFound: {
    ...layout.overlay,
    top:        'auto',
    zIndex:     30,
    transition: animation.transitions.short('height'),

    '&:not(.visible)': {
      height: 0,
    },
    '&:visible': {
      height: layout.barHeight.xs,
    },
  },

  notFoundContent: {
    ...layout.overlay,
    bottom:    'auto',
    height: 32,

    background: theme.semantic.primary,
    color:      theme.colors.contrast(theme.semantic.primary),

    ...layout.flex.center,
    padding:   layout.padding.s,
    textAlign: 'center',
  },

}))