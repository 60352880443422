import React from 'react'
import { useTranslation } from 'react-i18next'
import I18next from 'i18next'
import { isArray, startCase, uniq } from 'lodash'
import { combinedFetchStatus, FetchStatus } from 'mobx-document'
import { sparse } from 'ytil'
import { Model, ModelClass, ModelOfType, ResourceFilter as ResourceFilterModel } from '~/models'
import { Linkage } from '~/stores'
import { memo, observer } from '~/ui/component'
import {
  Center,
  Checkbox,
  ClearButton,
  Dimple,
  HBox,
  Label,
  List,
  SearchableList,
  SegmentedButton,
  Spinner,
  VBox,
} from '~/ui/components'
import { useContinuousRef } from '~/ui/hooks'
import { useFiniteModelSetData, useModelEndpoint } from '~/ui/hooks/data'
import { ResourceChipRow, ResourceListItem } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useWidgetFilterValues } from '../WidgetFilterValuesContext'
import { registerDashboardFilter } from './registry'
import WidgetFilter from './WidgetFilter'

import type { WidgetFilterComponentProps } from './types'
export type Props = WidgetFilterComponentProps<ResourceFilterModel, Linkage<any>[]>

const ResourceFilter = memo('ResourceFilter', (props: Props) => {

  const {alotment, widget, filter, value} = props

  const filterRef = React.useRef<WidgetFilter>(null)
  const [t] = useTranslation('analytics')

  //------
  // Resources

  const resources = React.useMemo(
    () => isArray(filter.resource) ? filter.resource : [filter.resource],
    [filter.resource],
  )

  const [currentResource, setCurrentResource] = React.useState<string>(resources[0])

  const buttonSegments = React.useMemo(
    () => resources.map(resource => ({
      value:   resource,
      caption: startCase(I18next.t(`${resource}:plural`)),
    })),
    [resources],
  )

  //------
  // Selected models / fetching

  const [fetchStatuses, setFetchStatuses] = React.useState<Record<string, FetchStatus>>(
    resources.reduce((all, resource) => ({...all, [resource]: 'idle'}), {}),
  )

  const fetchStatusesRef = useContinuousRef(fetchStatuses)

  const setFetchStatus = React.useCallback((resource: string, fetchStatus: FetchStatus) => {
    setFetchStatuses({
      ...fetchStatusesRef.current,
      [resource]: fetchStatus,
    })
  }, [fetchStatusesRef])

  const fetchStatus = React.useMemo(
    () => combinedFetchStatus(...Object.values(fetchStatuses)),
    [fetchStatuses],
  )

  const selectedLinkages = React.useMemo(
    () => isArray(value) ? value : sparse([value]),
    [value],
  )

  const selectedModelClasses = React.useMemo(
    () => uniq(selectedLinkages.map(it => it.type)).map(ModelOfType),
    [selectedLinkages],
  )

  const idsForModel = React.useCallback((Model: ModelClass<any>) => {
    return selectedLinkages
      .filter(it => it.type === Model.resourceType)
      .map(it => it.id)
  }, [selectedLinkages])


  //------
  // Searching

  const [search, setSearch] = React.useState<string | null>(null)

  const onDidClose = React.useCallback(() => {
    setSearch(null)
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <WidgetFilter
        alotment={alotment}
        widget={widget}
        filter={filter}
        value={value}
        renderValue={renderValue}
        renderPopup={renderPopup}
        onDidClose={onDidClose}
        ref={filterRef}
      />
    )
  }

  const renderValue = React.useCallback(() => {
    if (selectedLinkages.length > 0) {
      return (
        <HBox classNames={$.valueRow} flex='shrink' gap={layout.padding.inline.s}>
          {selectedModelClasses.map(Model => (
            <ResourceChipRow
              key={Model.name}
              Model={Model}
              ids={idsForModel(Model)}
              collapseFrom={2}
              tooltip={t('filters.resource.tooltip')}
              small
            />
          ))}
        </HBox>
      )
    } else if (fetchStatus === 'fetching') {
      return <HBox><Spinner size={8}/></HBox>
    } else {
      return (
        <Label dim italic>
          {t(`filters.resource.${filter.empty}`)}
        </Label>
      )
    }
  }, [$.valueRow, fetchStatus, filter.empty, idsForModel, selectedLinkages.length, selectedModelClasses, t])

  const renderTypeSwitcher = React.useCallback(() => {
    if (resources.length === 1) { return null }

    return (
      <Center classNames={$.typeSwitcher}>
        <SegmentedButton
          segments={buttonSegments}
          selectedValue={currentResource}
          onChange={setCurrentResource}
          small
        />
      </Center>
    )
  }, [$.typeSwitcher, buttonSegments, currentResource, resources.length])

  const renderPopup = React.useCallback(() => {
    return (
      <VBox classNames={$.popup} gap={layout.padding.inline.m}>
        {renderTypeSwitcher()}
        <ResourceFilterTab
          {...props}
          search={search}
          onSearch={setSearch}
          resource={currentResource}
          setFetchStatus={setFetchStatus}
          filterRef={filterRef}
        />
      </VBox>
    )
  }, [$.popup, currentResource, props, renderTypeSwitcher, search, setFetchStatus])

  return render()

})

interface ResourceFilterTabProps extends Props {
  resource:       string
  search:         string | null
  onSearch:       (search: string | null) => any
  setFetchStatus: (resource: string, fetchStatus: FetchStatus) => any
  filterRef:      React.RefObject<WidgetFilter>
}

const ResourceFilterTab = observer('ResourceFilterTab', (props: ResourceFilterTabProps) => {

  const values = useWidgetFilterValues()

  const {
    resource,
    filter,
    value,
    search,
    onSearch,
    setFetchStatus,
    filterRef,
  } = props

  const [t] = useTranslation('analytics')

  const Model    = ModelOfType(resource)
  const endpoint = useModelEndpoint(Model, {search, detached: true})

  const selectedIDs = React.useMemo(() => {
    const linkages = isArray(value) ? value : sparse([value])
    return linkages.filter(it => it.type === resource).map(it => it.id)
  }, [resource, value])

  const [current, {fetchStatus}] = useFiniteModelSetData(Model, selectedIDs)

  React.useEffect(() => {
    setFetchStatus(resource, fetchStatus)
  }, [fetchStatus, resource, setFetchStatus])

  const findModel = React.useCallback((id: string) => {
    return current.find(it => it.id === id) ?? endpoint.data.find(it => it.id === id)
  }, [current, endpoint.data])

  const selectedModels = React.useMemo(() => {
    return sparse(selectedIDs.map(findModel))
  }, [findModel, selectedIDs])

  const availableModels = React.useMemo(() => {
    return endpoint.data.filter(it => !selectedIDs.includes(it.id))
  }, [endpoint.data, selectedIDs])

  const select = React.useCallback((model: Model) => {
    let nextIDs: string[]
    if (filter.multi) {
      nextIDs = selectedIDs.filter(it => it !== model.id)
      if (!selectedIDs.includes(model.id)) {
        nextIDs.push(model.id)
      }
    } else {
      nextIDs = [model.id]
    }

    values?.setValue(filter, nextIDs.map(it => ({type: resource, id: it})))
    if (!filter.multi) {
      filterRef.current?.close()
    }
  }, [filter, values, filterRef, selectedIDs, resource])

  const clear = React.useCallback(() => {
    values?.setToDefault(filter)
    filterRef.current?.close()
  }, [filter, filterRef, values])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='shrink'>
        <SearchableList
          contentClassNames={$.listContent}
          data={availableModels}
          search={search}
          onSearch={onSearch}
          fetchStatus={endpoint.fetchStatus}
          HeaderComponent={renderHeader}
          renderItem={renderItem}
          flex={false}
        />
        {renderActions()}
      </VBox>
    )
  }

  const renderItem = React.useCallback((model: Model) => {
    return (
      <ResourceListItem
        model={model}
        onSelect={select}
        accessoryLeft={<Checkbox checked={selectedIDs.includes(model.id)}/>}
      />
    )
  }, [select, selectedIDs])

  const renderSelectedModels = React.useCallback(() => {
    if (selectedModels.length === 0) { return null }

    return (
      <VBox>
        <Label small caption dim classNames={$.headerLabel}>
          {t('filters.resource.selected')}
        </Label>
        <List
          data={selectedModels}
          renderItem={renderItem}
          flex={false}
        />
      </VBox>
    )
  }, [$.headerLabel, renderItem, selectedModels, t])

  const renderHeader = React.useCallback(() => {
    const selectedModels = renderSelectedModels()
    const hasAvailable   = availableModels.length > 0

    return (
      <VBox gap={layout.padding.inline.m}>
        {selectedModels}
        {selectedModels != null && hasAvailable && <Dimple horizontal/>}
        {hasAvailable && (
          <Label small caption dim classNames={$.headerLabel}>
            {t('filters.resource.available')}
          </Label>
        )}
      </VBox>
    )
  }, [$.headerLabel, availableModels.length, renderSelectedModels, t])

  const renderActions = React.useCallback(() => {
    return (
      <VBox>
        <ClearButton
          caption={t('filters.resource.clear')}
          onTap={clear}
          align='center'
          padding='both'
        />
      </VBox>
    )
  }, [clear, t])

  return render()

})

registerDashboardFilter('resource', ResourceFilter)

export default ResourceFilter

const useStyles = createUseStyles(theme => ({
  headerLabel: {
    padding: [0, layout.padding.inline.m],
  },

  valueRow: {
    overflow: 'hidden',
  },

  typeSwitcher: {
    paddingTop: layout.padding.inline.m,
  },

  listContent: {
    paddingBottom: layout.padding.inline.m,
  },

  popup: {
    background: theme.bg.semi,
  },
}))