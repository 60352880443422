import React from 'react'
import I18next from 'i18next'
import { cloneDeep, get, set } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { Extension, Project } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { deriveDynamicFormSchema, DynamicFieldset } from '~/ui/app/dynamic-form'
import { memo } from '~/ui/component'
import { FormDialog, ProxyFormModel, SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'

export interface Props {
  open:         boolean
  requestClose: () => any

  extension: Extension
  config:    AnyObject
}

const IntegrationConfigurationForm = memo('IntegrationConfigurationForm', (props: Props) => {

  const {open, requestClose, extension, config} = props

  const {t} = useResourceTranslation('extensions')

  const formModel = React.useMemo(
    () => new IntegrationConfigurationFormModel(extension, config),
    [config, extension],
  )

  const name = extension.translate('name')

  const configFormSchema = React.useMemo(() => {
    const translations = extension.translations[I18next.language]?.config ?? extension.translations.en?.config ?? {}
    return deriveDynamicFormSchema(extension.configSchema, translations)
  }, [extension.configSchema, extension.translations])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog open={open} requestClose={requestClose} model={formModel} semi={false} icon='hexagons' title={t('configuration.title', {name})}>
        <DynamicFieldset schema={configFormSchema}/>
      </FormDialog>
    )
  }

  return render()

})

export default IntegrationConfigurationForm

class IntegrationConfigurationFormModel implements ProxyFormModel<AnyObject> {

  constructor(
    public readonly extension: Extension,
    config: AnyObject,
  ) {
    this.config = config
    makeObservable(this)
  }

  @observable
  private config: AnyObject

  public getValue(path: string) {
    return get(this.config, path)
  }

  @action
  public assign(data: AnyObject) {
    const config = cloneDeep(this.config)
    for (const [path, value] of Object.entries(data)) {
      set(config, path, value)
    }
    this.config = config
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const project = projectStore.project
    if (project == null) { return }

    const extensions = project.extensions.map(extension => {
      if (extension.name === this.extension.name) {
        return {...extension, config: this.config}
      } else {
        return extension
      }
    })

    const result = await dataStore.update(Project, project.id, {extensions})
    return translateFormErrorPaths(result, path => path.replace(/^extensions\.[^.]+\./, ''))
  }

}