import React from 'react'
import { Post } from '~/models'
import { ModelEndpoint } from '~/stores'
import { memo } from '~/ui/component'
import { EmptyOrFetching, List } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceCollection, ResourceCollectionChildrenProps } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import CommentView from './CommentView'

export interface Props {
  endpoint:     ModelEndpoint<Post>
  requestEdit?: (comment: Post) => any
}

const CommentList = memo('CommentList', (props: Props) => {

  const {endpoint, requestEdit} = props
  const {t} = useResourceTranslation('feeds')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceCollection
        endpoint={endpoint}
        i18nResourceType='comments'
        renderContent={renderList}
      />
    )
  }

  function renderList(props: ResourceCollectionChildrenProps<Post>) {
    return (
      <List<Post>
        data={props.data}
        renderItem={renderComment}
        classNames={$.listView}
        contentClassNames={$.listViewContent}
        EmptyComponent={renderEmpty}
        itemGap={layout.padding.inline.s}
        scrollable
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        flex
        fetchStatus={endpoint.fetchStatus}
        {...t('empty')}
      />
    )
  }, [endpoint.fetchStatus, t])

  const renderComment = React.useCallback((comment: Post, index: number) => {
    return (
      <CommentView
        key={comment.id}
        comment={comment}
        requestEdit={requestEdit}
      />
    )
  }, [requestEdit])

  return render()

})

export default CommentList

const shadowPadding = 10

const useStyles = createUseStyles({
  listView: {
    flex: 1,
    margin: -shadowPadding,
  },

  listViewContent: {
    padding: shadowPadding,
  },
})