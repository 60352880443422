import { makeObservable, observable } from 'mobx'
import { Condition } from '~/models'
import { SubmitResult } from '~/ui/form'

export default abstract class ConditionsFormModel {

  constructor(conditions: Condition[]) {
    this.conditions = conditions
    makeObservable(this)
  }

  @observable.shallow
  public conditions: Condition[]

  public addCondition(condition: Condition) {
    this.conditions.push(condition)
  }

  public replaceConditionAt(index: number, condition: Condition) {
    this.conditions.splice(index, 1, condition)
  }

  public removeConditionAt(index: number) {
    this.conditions.splice(index, 1)
  }

  @observable.shallow
  public context: ConditionsFormModelContext = {}

  protected setContext(context: ConditionsFormModelContext) {
    this.context = {...this.context, ...context}
  }

  //-------
  // Submit

  public abstract submit(): Promise<SubmitResult | undefined>

}

export interface ConditionsFormModelContext {
  recentScriptIDs?:    string[]
  wellKnownVariables?: WellKnownVariable[]
}

export interface WellKnownVariable {
  name:        string
  description: string
}