import React from 'react'
import { snakeCase } from 'lodash'
import { FeedbackMediaType } from '~/models'
import { Dimple, Label, Panel, SwitchField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { BindProps } from '~/ui/form/FormField'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export default function MediaFeedbackFields() {

  const {t} = useResourceTranslation()

  function render() {
    return (
      <FormField name='mediaTypes' required>
        {bind => (
          <Panel>
            <VBox padding={layout.padding.s}>
              {renderFeedbackMediaTypeToggle('image', bind)}
            </VBox>
            <Dimple horizontal/>
            <VBox padding={layout.padding.s}>
              {renderFeedbackMediaTypeToggle('video', bind)}
            </VBox>
          </Panel>
        )}
      </FormField>
    )
  }

  function renderFeedbackMediaTypeToggle(type: FeedbackMediaType, bind: BindProps<FeedbackMediaType[]>) {
    const value = bind.value ?? []
    const isOn  = value.includes(type)

    return (
      <SwitchField
        value={isOn}
        onChange={isOn => bind.onChange(toggle(value, type, isOn ?? false))}
        label={on => (
          <VBox>
            <Label bold dim={!isOn}>
              {t(`media.types.${snakeCase(type)}.title`)}
            </Label>
            <Label small dim>
              {t(`media.types.${snakeCase(type)}.detail.${on ? 'on' : 'off'}`)}
            </Label>
          </VBox>
        )}
      />
    )
  }

  return render()

}

function toggle<T>(list: T[], item: T, include: boolean) {
  const result = list.filter(it => it !== item)
  if (include) {
    result.push(item)
  }
  return result
}