import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Metric } from '~/models'
import { observer } from '~/ui/component'
import { useResourceTranslation } from '~/ui/resources'
import { EditFormProps, ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import MetricForm from '../MetricForm'
import MetricDataGrid from './MetricDataGrid'

export type Props = RouteComponentProps<ResourceDetailParams>

const MetricScreen = observer('MetricScreen', (props: Props) => {

  const {t} = useResourceTranslation('metrics')

  const metricID = props.match.params.id

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={Metric}
        id={metricID}
        include={['bots', 'group']}

        renderType={renderType}
        renderBody={renderBody}
        renderInfo={renderInfo}

        EditFormComponent={EditForm}
      />
    )
  }

  const renderType = React.useCallback((metric: Metric) => {
    return t(`types.${metric.type}.caption`)
  }, [t])

  const EditForm = React.useMemo(() => (props: EditFormProps<Metric>) => {
    return (
      <MetricForm
        {...props}
        type={props.model.type}
      />
    )
  }, [])

  //------
  // Body

  function renderBody(metric: Metric) {
    return (
      <MetricDataGrid
        metric={metric}
      />
    )
  }

  //------
  // Right bar

  function renderInfo(metric: Metric) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: metric.createdAt})}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  return render()

})

export default React.memo(MetricScreen)