import React from 'react'
import { useTranslation } from 'react-i18next'
import { Module } from '~/models'
import { memo, observer } from '~/ui/component'
import { SearchableList, Tappable, VBox } from '~/ui/components'
import { useFocusOnActivate } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { ResourceTypeProvider } from '~/ui/resources'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import ModuleBar, { moduleBarBorderRadius } from '../../modules/ModuleBar'
import { CreateButton } from './TriggerableComponentCatalog'
import { CreateComponentType } from './types'

export interface Props {
  requestCreate: (type: CreateComponentType) => any
  active:        boolean
}

const ModuleComponentCatalog = observer('ModuleComponentCatalog', (props: Props) => {

  const {active, requestCreate} = props

  const endpoint = useModelEndpoint(Module, {
    fetch:   false,
    label:   'linked',
    include: ['project'],
  })
  const data        = endpoint.data
  const fetchStatus = endpoint.fetchStatus

  const [t] = useTranslation('flow_planner')

  const searchFieldRef = useFocusOnActivate(active, {timeout: 0})

  React.useEffect(() => {
    if (active) {
      endpoint.fetchIfNeeded()
    }
  }, [active, endpoint])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchMore()
  }, [endpoint])

  const buildComponentType = React.useCallback((item?: Module, nameSuggestion?: string | null): CreateComponentType => ({
    type:  'module',
    model: item ?? undefined,
    data:  {name: nameSuggestion},
  }), [])

  const search = React.useCallback((search: string | null) => {
    endpoint.setParams({search})
  }, [endpoint])

  const select = React.useCallback((item: Module | null) => {
    const type = item == null
      ? buildComponentType(undefined, endpoint.param('search') ?? null)
      : buildComponentType(item, undefined)

    requestCreate(type)
  }, [buildComponentType, endpoint, requestCreate])

  const create = React.useCallback(() => {
    const type = buildComponentType(undefined, endpoint.param('search') ?? null)
    requestCreate(type)
  }, [buildComponentType, endpoint, requestCreate])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='modules'>
        <VBox flex>
          <SearchableList
            data={data}
            flex
            fetchStatus={fetchStatus}
            onSearch={search}
            searchDebounce={300}
            onKeyboardSelect={select}
            onEndReached={fetchMore}
            renderItem={renderItem}
            emptyLabel={t('create_component.modules.empty')}
            contentClassNames={$.content}
            itemGap={layout.padding.xs}
            searchFieldRef={searchFieldRef}
          />
          <CreateButton
            onCreate={create}
            nameSuggestion={endpoint.param('search')}
          />
        </VBox>
      </ResourceTypeProvider>
    )
  }

  const renderItem = React.useCallback((item: Module, index: number, selected: boolean) => {
    return(
      <ModuleComponentCatalogItem
        item={item}
        onSelect={select}
        selected={selected}
      />
    )
  }, [select])

  return render()
})

export default ModuleComponentCatalog

export interface ModuleComponentCatalogItemProps {
  item:     Module
  selected: boolean
  onSelect: (item: Module) => any
}

const ModuleComponentCatalogItem = memo('ModuleComponentCatalogItem', (props: ModuleComponentCatalogItemProps) => {

  const {item, selected, onSelect} = props

  const tap = React.useCallback(() => {
    onSelect(item)
  }, [item, onSelect])

  const $ = useStyles()

  function render() {
    return (
      <VBox paddingHorizontal={layout.padding.s}>
        <Tappable classNames={[$.moduleButton, {selected}]} onTap={tap}>
          <ThemeProvider dark>
            <ModuleBar moduleID={item.id} />
          </ThemeProvider>
        </Tappable>
      </VBox>
    )
  }

  return render()
})

const useStyles = createUseStyles(theme => ({
  content: {
    ...layout.responsive(size => ({
      paddingTop: layout.padding.xs[size],
      paddingBottom: layout.padding.s[size],
    })),
  },

  moduleButton: {
    borderRadius: moduleBarBorderRadius.normal,
    '&.selected': {
      boxShadow: shadows.selected(theme),
    },
  },
}))