import React from 'react'
import { Widget, WidgetAlotment } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, Dimple, HBox, InfoIcon, Label, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useAnalyticsService } from './AnalyticsServiceContext'
import { useDashboardContext } from './DashboardContext'

export interface Props {
  widget:    Widget
  alotment?: WidgetAlotment

  padded?:  boolean
  actions?: boolean
}

const WidgetHeader = memo('WidgetHeader', (props: Props) => {

  const {
    widget,
    alotment,
    padded = false,
    actions = true,
  } = props

  const {service} = useAnalyticsService()
  const {requestReport} = useDashboardContext()

  const refresh = React.useCallback(() => {
    if (alotment == null) { return null }
    service?.refreshWidget(alotment.uuid)
  }, [alotment, service])

  const caption     = widget.translate('caption')
  const instruction = widget.translate('instruction', null)

  const report = React.useCallback(() => {
    if (alotment == null) { return }
    requestReport(alotment.uuid)
  }, [alotment, requestReport])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox>
        <HBox classNames={[$.widgetHeader, {padded}]} gap={layout.padding.inline.m} justify='space-between'>
          {renderLeft()}
          {renderActions()}
        </HBox>
        <Dimple horizontal/>
      </VBox>
    )
  }

  function renderLeft() {
    return (
      <HBox flex gap={layout.padding.inline.m}>
        <Label caption flex='shrink'>
          {caption}
        </Label>
        {instruction && (
          <InfoIcon renderTooltip={instruction}/>
        )}
      </HBox>
    )

  }

  function renderActions() {
    if (!actions) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        {alotment != null && (
          <ClearButton
            icon='page'
            onTap={report}
          />
        )}
        <ClearButton
          icon='reload'
          onTap={refresh}
        />
      </HBox>
    )
  }

  return render()

})

export default WidgetHeader

const useStyles = createUseStyles({
  widgetHeader: {
    paddingTop:    layout.padding.inline.m,
    paddingBottom: layout.padding.inline.s,

    '&.padded': {
      paddingLeft:  layout.padding.inline.l,
      paddingRight: layout.padding.inline.l,
    },
  },
})