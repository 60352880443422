import { CalendarItem, Link, PlanComponent, PlanSegue } from '~/models'

export enum ClipboardType {
  BULK_SELECTOR = 'application/x-groundcontrol-bulk-selector',

  SCRIPT_MESSAGES  = 'application/x-groundcontrol-script-messages',
  MESSAGE_FEEDBACK = 'application/x-groundcontrol-message-feedback',

  PLANNER_COMPONENTS = 'application/x-groundcontrol-planner-components',
  CALENDAR_ITEMS     = 'application/x-groundcontrol-calendar-items',

  CHALLENGE_TASKS = 'application/x-groundcontrol-challenge-tasks',

  TARGETING  = 'application/x-groundcontrol-targeting',
  CONDITIONS = 'application/x-groundcontrol-conditions',

  RICH_LINK = 'application/x-ctrl-rich-link',
}

export type ClipboardPlanComponent = Exclude<PlanComponent, PlanSegue> | ClipboardPlanSegue

export interface ClipboardPlanSegue extends Omit<PlanSegue, 'delay'> {
  delay: string | null
}

export interface CalendarPlanClipboardItem {
  day:  string
  item: Omit<CalendarItem, 'uuid'>
}

export interface RichLinkClipboardItem {
  caption: string
  link:    Link
}