export type CanvasMode = 'select' | 'pan' | 'connect'

export interface CanvasViewport {
  /**
   * The pixel offset within the client rectangle that represents the (0, 0) point of the canvas.
   */
  origin: Point

  /**
   * The zoom factor.
   */
  zoom: number
}

export const CanvasViewport: {default: CanvasViewport, equals(left: CanvasViewport, right: CanvasViewport): boolean} = {
  default: {
    origin:     {x: 0, y: 0},
    zoom:       1,
  },

  equals: (left, right) => {
    if (left.origin.x !== right.origin.x) { return false }
    if (left.origin.y !== right.origin.y) { return false }
    if (left.zoom !== right.zoom) { return false }

    return true
  },
}

export enum Direction {
  UP    = 1,
  DOWN  = 2,
  LEFT  = 3,
  RIGHT = 4,
}

export function directionToString(direction: Direction) {
  switch (direction) {
    case Direction.UP:    return 'up'
    case Direction.DOWN:  return 'down'
    case Direction.LEFT:  return 'left'
    case Direction.RIGHT: return 'right'
  }
}