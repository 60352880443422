import { isArray } from 'lodash'
import { fieldTransform } from '~/ui/form'

export function filterTransform<T>(stringify: (value: T) => string | null, parse: (raw: string | null) => T) {
  return fieldTransform<T, string | null>(stringify, parse)
}

export function parseFilter(raw: string): any {
  if (raw === 'true') {
    return true
  } else if (raw === 'false') {
    return false
  } else if (raw.endsWith(',')) {
    return raw.split(',').slice(0, -1).map(parseFilter)
  } else if (/^-?[0-9]+$/.test(raw)) {
    return parseInt(raw, 10)
  } else {
    return raw
  }
}

export function stringifyFilter(value: any): string | null {
  if (value == null || value === false || value === true) {
    return value
  } else if (isArray(value)) {
    if (value.length === 0) { return null }
    return value.map(stringifyFilter).join(',') + ',' // Always append a comma to identify this as an array
  } else {
    return value.toString()
  }
}