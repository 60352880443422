import React from 'react'
import { memo } from '~/ui/component'
import { useRichTextContext } from '../RichTextFieldContext'

export interface Props {
  modals?:   React.ReactNode
  children?: React.ReactNode
}

/**
 * If a decorator needs to render a modal, it can use this component, and render its modals in the
 * {@param props.modals} prop. The modals are rendered outside of the contenteditable area, so that
 * any key events won't be handled by DraftJS.
 */
const DraftJSDecorator = memo('DraftJSDecorator', (props: Props) => {

  const {modals, children} = props

  const handle = React.useMemo(() => ({}), [])
  const {setModals} = useRichTextContext()

  React.useEffect(
    () => setModals(handle, modals),
    [handle, modals, setModals],
  )

  return <>{children}</>

})

export default DraftJSDecorator