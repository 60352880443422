import React from 'react'
import { Channel, ChannelType, ChatBot, Group } from '~/models'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG, Tooltip } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { CreateFormComponentMap, ResourceListScreen, TypeCell } from '~/ui/resources/collection'
import { ResourceChipRow } from '~/ui/resources/components'
import { layout, useStyling } from '~/ui/styling'
import ChannelForm from '../ChannelForm'

const ChannelsScreen = memo('ChannelsScreen', () => {

  const {t} = useResourceTranslation('channels')
  const {colors} = useStyling()

  const createFormComponentChoices = React.useMemo(() => {
    const choices: CreateFormComponentMap = {}
    for (const type of ChannelType.all) {
      choices[type] = {
        caption:   t(`types.${type}.caption`),
        detail:    t(`types.${type}.detail`),
        Component: props => <ChannelForm {...props} type={type}/>,
      }
    }

    return choices
  }, [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Channel}
        include={['bots', 'group']}
        CreateFormComponent={createFormComponentChoices}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={1}
        />
        <DataGrid.Column<Channel>
          name='type'
          sort={true}
          renderCell={channel => <TypeCell type={channel.type}/>}
          width={140}
        />
        <DataGrid.Column
          name='bots_group'
          renderCell={renderBotsOrGroup}
          flex={2}
        />
      </ResourceListScreen>
    )
  }

  function renderName(channel: Channel) {
    return (
      <HBox gap={layout.padding.s}>
        <Avatar
          source={channel.image}
          firstName={channel.name}
          size={avatarSize}
        />
        <Label flex='shrink' bold>
          {channel.name}
        </Label>
        <HBox gap={layout.padding.inline.s}>
          {channel.pinIndex != null && (
            <Tooltip renderTooltip={t('pinned')}>
              <SVG name='pin' size={layout.icon.s} dim/>
            </Tooltip>
          )}
          {channel.app && (
            <Tooltip renderTooltip={t('app')}>
              <SVG name='iphone' size={layout.icon.s} color={colors.semantic.secondary}/>
            </Tooltip>
          )}
          {channel.web && (
            <Tooltip renderTooltip={t('web')}>
              <SVG name='web' size={layout.icon.s} color={colors.semantic.secondary}/>
            </Tooltip>
          )}
        </HBox>
      </HBox>
    )
  }

  function renderBotsOrGroup(channel: Channel) {
    return (
      <HBox gap={layout.padding.inline.s}>
        {channel.type === 'group' && (
          <ResourceChipRow
            Model={Group}
            ids={channel.group == null ? [] : [channel.group]}
          />
        )}
        <ResourceChipRow
          Model={ChatBot}
          ids={channel.bots}
          interactive={false}
        />
      </HBox>
    )
  }

  return render()

})

export const avatarSize = {
  width:  32,
  height: 32,
}

export default ChannelsScreen