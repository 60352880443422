import I18n from 'i18next'
import { BooleanField, DataField } from 'sheet-importer'
import { Condition } from './Condition'
import { Variable } from './custom-data'
import { CustomImage } from './CustomImage'
import { importable, importField } from './importing'
import { Model, resource } from './Model'
import { Module } from './Module'
import { Project } from './Project'
import { Ref } from './types'

@resource<Group>('groups', {
  icon:    'group',
  caption: group => group.name,
  details: group => [
    I18n.t('groups:participant_count', {count: group.participantCount}),
  ],
})
@importField('chat', {
  field: () => new BooleanField('chat', {type: 'groupchat'}),
})
export class Group extends Model {

  public project!: Ref<Project>
  public module!:  Ref<Module>

  @importable()
  public name!: string

  @importable()
  public type!: GroupType

  @importable({type: 'tags'})
  public tags!: string[]

  public image!: CustomImage | null

  public capacity!: number | null

  public participantCount!: number

  @importable({type: 'conditions'})
  public conditions?: Condition[]

  @importable({
    field: column => new DataField('data', column.name),
  })
  public data?: Variable[]

}

export type GroupType = 'list' | 'segment'