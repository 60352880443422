import { Model, ModelClass } from '../Model'
import { ensureImportMeta } from './registry'
import { ImportFieldMeta } from './types'

export function importable(spec?: ImportFieldMeta | false) {
  return (target: Model, name: string) => {
    const meta = ensureImportMeta(target.$ModelClass)
    if (meta == null) {
      throw new Error(`${target.$ModelClass.name} is not importable, add @importable to the class first`)
    }

    meta.fields[name] = spec ?? {}
  }
}

export function importField(name: string, spec?: ImportFieldMeta) {
  return (target: ModelClass<any>) => {
    const meta = ensureImportMeta(target)
    if (meta == null) {
      throw new Error(`${target.name} is not importable, add @importable to the class first`)
    }

    meta.fields[name] = spec ?? {}
  }

}