import I18next from 'i18next'
import { isPlainObject, some, upperFirst } from 'lodash'
import { DateTime } from 'luxon'
import { serialize } from '../lib/ymodel/src/decorators/serialize'
import { Challenge } from './Challenge'
import { Condition } from './Condition'
import { Group } from './Group'
import { Location } from './Location'
import { Model, ModelClass, resource } from './Model'
import { Module } from './Module'
import { Script } from './Script'
import { Targeting } from './Targeting'
import { TimeInterval } from './TimeInterval'
import { TimeOfDay } from './TimeOfDay'
import { Ref } from './types'

@resource<FlowPlan>('flow-plans', {
  icon:    'planner',
  caption: () => upperFirst(I18next.t('plans:singular')),
})
export class FlowPlan extends Model {

  public module!: Ref<Module>

  public modified!:   boolean
  public collective!: boolean

  @serialize(DateTime, {path: 'trigger.date'})
  public nodes!: PlanNode[]

  @serialize(TimeInterval, {path: 'delay.interval'})
  @serialize(TimeOfDay, {path: 'delay.time'})
  public segues!: PlanSegue[]

  public annotations!: PlanAnnotation[]
  public zStack!:      string[]

  //------
  // Finders

  public findNode(uuid: string): PlanNode | null {
    for (const node of this.nodes) {
      if (node.uuid === uuid) { return node }
    }

    return null
  }

  public findSegue(uuid: string): PlanSegue | null {
    for (const segue of this.segues) {
      if (segue.uuid === uuid) { return segue }
    }

    return null
  }

  public findSeguesTo(nodeUUID: string) {
    return this.segues.filter(segue => segue.to.node === nodeUUID)
  }

  public findSeguesFrom(nodeUUID: string) {
    return this.segues.filter(segue => segue.from.node === nodeUUID)
  }

  public findAnnotation(uuid: string): PlanAnnotation | null {
    for (const annotation of this.annotations) {
      if (annotation.uuid === uuid) { return annotation }
    }

    return null
  }

  public findComponent(uuid: string): PlanComponent | null {
    return null
      ?? this.findNode(uuid)
      ?? this.findSegue(uuid)
      ?? this.findAnnotation(uuid)
      ?? null
  }

  public findTriggerable(uuid: string): Triggerable | null {
    for (const node of this.nodes) {
      if (node.type !== 'triggerable') { continue }

      for (const triggerable of node.triggerables) {
        if (getTriggerableID(triggerable) === uuid) {
          return triggerable
        }
      }
    }

    return null
  }

}

export type PlanComponent           = PlanNode | PlanSegue | PlanAnnotation
export type PlanComponentWithBounds = PlanNode | PlanAnnotation

export interface ComponentBounds {
  left:   number
  top:    number
  width:  number
  height: number
}

//------
// Nodes

export type PlanNode =
  | TriggerNode
  | TriggerableNode
  | ModuleNode
  | EntryNode
  | ExitNode
  | RoutingNode
  | CircuitBreakerNode
  | SplitterNode
  | FirstNode
  | SampleNode

export interface PlanNodeCommon {
  uuid:   string
  name:   string | null
  bounds: ComponentBounds
}

export interface TriggerNode extends PlanNodeCommon {
  type:    'trigger'
  trigger: Trigger
}

export interface TriggerableNode extends PlanNodeCommon {
  type:        'triggerable'
  triggerables: Triggerable[]
}

export interface ModuleNode extends PlanNodeCommon {
  type:   'module'
  module: Ref<Module>
  name:   string | null
  params: Record<string, any>
}

export interface EntryNode extends PlanNodeCommon {
  type: 'entry'
}

export interface ExitNode extends PlanNodeCommon {
  type:   'exit'
  outlet: string | null
}

export interface GroupNode extends PlanNodeCommon {
  type:         'group'
  triggerables: Triggerable[]
}

export interface RoutingNode extends PlanNodeCommon {
  type: 'routing'
}

export interface CircuitBreakerNode extends PlanNodeCommon {
  type: 'circuit-breaker'
}

export interface SplitterNode extends PlanNodeCommon {
  type: 'splitter'
}

export interface FirstNode extends PlanNodeCommon {
  type:  'first'
  count: number
}

export interface SampleNode extends PlanNodeCommon {
  type:  'sample'
  count: number
}

//------
// PlanSegue

export interface PlanSegue {
  uuid: string

  from:   PlanSegueConnection
  to:     PlanSegueConnection
  outlet: string | null

  targeting:  Targeting
  conditions: Condition[]
  delay:      PlanSegueDelay | null
}

export interface PlanSegueConnection {
  node:      string
  connector: PlanNodeConnector
}

/**
 * Bitmask:
 *
 * | 8 | 7 | 6 | 5 | 4 | 3 | 2 | 1 |
 * | 0 |      SIDE     |    ALT    |
 *
 * SIDE:
 * - North: 0001
 * - East:  0010
 * - South: 0100
 * - West:  1000
 *
 * ALT:
 * - Leading:  001
 * - Middle:   010
 * - Trailing: 100
 */
export enum PlanNodeConnector {
  NNW = 0b00001001,
  N   = 0b00001010,
  NNE = 0b00001100,

  ENE = 0b00010001,
  E   = 0b00010010,
  ESE = 0b00010100,

  SSE = 0b00100001,
  S   = 0b00100010,
  SSW = 0b00100100,

  WSW = 0b01000001,
  W   = 0b01000010,
  WNW = 0b01000100,
}

export enum PlanNodeConnectorMask {
  NORTH = 0b00001111,
  EAST  = 0b00010111,
  SOUTH = 0b00100111,
  WEST  = 0b01000111,

  LEADING  = 0b01111001,
  CENTER   = 0b01111010,
  TRAILING = 0b01111100,

  VERTICAL   = 0b00101111,
  HORIZONTAL = 0b01010111,
  ALL        = 0b01111111,
  NONE       = 0b00000000,
}

export type PlanSegueDelay = SegueDelay & PlanSegueDelayOptions

export interface PlanSegueDelayOptions {
  reminder: boolean
}

//------
// Delays

export type SegueDelay =
  | FixedTimeDelay
  | DayDelay

export interface FixedTimeDelay {
  type:     'fixed'
  interval: TimeInterval
}

export interface DayDelay {
  type: 'day'
  day:  DaySelector
  time: TimeOfDay
}

export type DaySelector = OffsetDaySelector | WeekdayDaySelector

export interface OffsetDaySelector {
  type:   'offset'
  offset: number
}

export interface WeekdayDaySelector {
  type:    'weekday'
  week:    number
  weekday: number
}

export const SegueDelay: {
  serialize:   (delay: SegueDelay | null) => any
  deserialize: (raw: any) => SegueDelay | null
} = {
  serialize: (delay: SegueDelay | null) => {
    if (delay == null) { return null }
    if (delay.type === 'fixed') {
      return {...delay, interval: delay.interval.toString()}
    } else {
      return {...delay, time: delay.time.toString()}
    }
  },
  deserialize: (raw: any) => {
    if (!isPlainObject(raw)) { return null }
    if (raw.type === 'fixed') {
      return {...raw, interval: TimeInterval.parse(raw.interval)} as SegueDelay
    } else if (raw.type === 'day') {
      return {...raw, time: new TimeOfDay(raw.time)} as SegueDelay
    } else {
      return null
    }
  },
}

export type DelayInterrupt = 'cancel' | 'keep' | 'execute'
export const DelayInterrupt: {
  all: DelayInterrupt[]
} = {
  all: ['execute', 'cancel', 'keep'],
}

//------
// Annotations

export type PlanAnnotation = PlanTextAnnotation

export interface PlanAnnotationCommon {
  uuid:   string
  bounds: LayoutRect
}

export interface PlanTextAnnotation extends PlanAnnotationCommon {
  type:  'text'
  text:  string
  style: PlanTextAnnotationStyle
}

export interface PlanTextAnnotationStyle {
  textSize: PlanTextAnnotationTextSize
  bold:     boolean
  italic:   boolean
  align:    PlanTextAnnotationAlignment
}

export enum PlanTextAnnotationTextSize {
  SMALL  = 14,
  NORMAL = 16,
  LARGE  = 18,
}

export const PlanTextAnnotationStyle: {empty: () => PlanTextAnnotationStyle} = {
  empty: () => ({
    textSize: PlanTextAnnotationTextSize.NORMAL,
    bold:     false,
    italic:   false,
    align:    'center',
  }),
}

export type PlanTextAnnotationAlignment = 'left' | 'center' | 'right'

//------
// Triggers

export type Trigger =
  | ManualTrigger
  | QRCodeTrigger
  | OnboardingTrigger
  | RegistrationTrigger
  | DateTrigger
  | GroupJoinTrigger
  | GroupLeaveTrigger
  | LocationEnterTrigger
  | LocationExitTrigger
  | ScriptEndTrigger
  | ChallengePublishedTrigger
  | ChallengeAbandonedTrigger
  | ChallengeCompletedTrigger
  | ChallengeReviewedTrigger
  | ReviewCreatedTrigger
  | WebcastStartTrigger
  | WebcastEndTrigger
  | ConnectionCreatedTrigger
  | WebhookTrigger

//------
// Triggers

export interface ManualTrigger {
  type: 'manual'
}

export interface QRCodeTrigger {
  type: 'qrcode'
}

//------
// Onboarding & checkin triggers

export interface OnboardingTrigger {
  type: 'onboarding'
}

export interface RegistrationTrigger {
  type: 'registration'
}

//------
// Date trigger

export interface DateTrigger {
  type: 'date'
  date: DateTime
}

//------
// Location triggers

export interface LocationEnterTrigger {
  type:     'location:enter'
  location: Ref<Location>
}

export interface LocationExitTrigger {
  type:     'location:exit'
  location: Ref<Location>
}

//------
// Group triggers

export interface GroupJoinTrigger {
  type:  'group:join'
  group: Ref<Group> | null
  tags:  string[]
}

export interface GroupLeaveTrigger {
  type:  'group:leave'
  group: Ref<Group> | null
  tags:  string[]
}

//------
// Script triggers

export interface ScriptEndTrigger {
  type:   'script:end'
  script: Ref<Script> | null
}

//------
// Challenge triggers

export interface ChallengePublishedTrigger {
  type:     'challenge:published'
  challenge: Ref<Challenge> | null
}

export interface ChallengeAbandonedTrigger {
  type:      'challenge:abandoned'
  challenge: Ref<Challenge> | null
}

export interface ChallengeCompletedTrigger {
  type:      'challenge:completed'
  challenge: Ref<Challenge> | null
}

export interface ChallengeReviewedTrigger {
  type:      'challenge:reviewed'
  challenge: Ref<Challenge> | null
}

//------
// Review triggers

export interface ReviewCreatedTrigger {
  type: 'review:created'
}

//------
// Webcast triggers

export interface WebcastStartTrigger {
  type:    'webcast:start'
  webcast: string
}

export interface WebcastEndTrigger {
  type:    'webcast:end'
  webcast: string
}

//------
// Connection trigger

export interface ConnectionCreatedTrigger {
  type:           'connection:created'
  minConnections: number | null
  maxConnections: number | null
}

//------
// Webhook trigger

export interface WebhookTrigger {
  type:       'webhook'
  collective: boolean
}

//------
// Triggerables

export type Triggerable =
  | ScriptTriggerable
  | ChallengeTriggerable
  | ActionTriggerable

export interface ScriptTriggerable  {
  type:  'script'
  model: Ref<Script>
}

export interface ChallengeTriggerable  {
  type:         'challenge'
  model:        Ref<Challenge>
  publishAgain: boolean
}

export interface ActionTriggerable  {
  type:   'action'
  uuid:   string
  action: string
  params: AnyObject
}

export type TriggerableModel = Script | Challenge

//-------
// Utilites
export function isNode(component: PlanComponent): component is PlanNode {
  if (!('type' in component)) { return false }
  return isNodeType(component.type)
}

export function isTriggerNode(component: PlanComponent): component is TriggerNode {
  if (!('type' in component)) { return false }
  return component.type === 'trigger'
}

export function isTriggerableNode(component: PlanComponent): component is TriggerableNode {
  if (!('type' in component)) { return false }
  return component.type === 'triggerable'
}

export function nodeHasTriggerable(node: TriggerableNode, triggerable: Triggerable) {
  return some(node.triggerables, it => getTriggerableID(it) === getTriggerableID(triggerable))
}

export function isModuleBoundaryNode(component: PlanComponent): component is EntryNode | ExitNode {
  if (!('type' in component)) { return false }
  return isModuleBoundaryNodeType(component.type)
}

export function isRoutingNode(component: PlanComponent): component is EntryNode | ExitNode | RoutingNode | SplitterNode | FirstNode | SampleNode {
  if (!('type' in component)) { return false }
  return isRoutingNodeType(component.type)
}

export function isNodeType(type: string): type is PlanNode['type'] {
  return ['trigger', 'triggerable', 'module'].includes(type) || isRoutingNodeType(type) || isModuleBoundaryNodeType(type)
}

export function isModuleBoundaryNodeType(type: string): type is PlanNode['type'] {
  return ['entry', 'exit'].includes(type)
}

export function isRoutingNodeType(type: string): type is PlanNode['type'] {
  return ['routing', 'circuit-breaker', 'splitter', 'first', 'sample'].includes(type)
}

export function isSegue(component: PlanComponent): component is PlanSegue {
  return 'from' in component && 'to' in component
}

export function isAnnotation(component: PlanComponent): component is PlanAnnotation {
  if (!('type' in component)) { return false }
  return isAnnotationType(component.type)
}

export function isAnnotationType(type: string): type is PlanAnnotation['type'] {
  return ['text'].includes(type)
}

export function isCollectiveTrigger(trigger: Trigger) {
  switch (trigger.type) {
    case 'date': case 'manual':
    case 'webcast:start': case 'webcast:end':
      return true
    case 'webhook':
      return trigger.collective
    default:
      return false
  }
}

export function isCompleteableTriggerable(triggerable: Triggerable) {
  return ['script', 'challenge'].includes(triggerable.type)
}

export function TriggerableModelClass(type: Exclude<Triggerable['type'], 'action'>): ModelClass<any> {
  switch (type) {
  case 'script':    return Script
  case 'challenge': return Challenge
  }
}

export function getTriggerableID(triggerable: Triggerable) {
  if (triggerable.type === 'action') {
    return triggerable.uuid
  } else {
    return triggerable.model
  }
}
