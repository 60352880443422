import React from 'react'
import { Post } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, HBox, Label } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ReactionCountRow from './ReactionCountRow'

export interface Props {
  post: Post
}

const PostFooter = observer('PostFooter', (props: Props) => {

  const {post} = props

  const {t} = useResourceTranslation('posts')

  const reactionCounts = React.useMemo(
    () => post.countReactions(),
    [post],
  )

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.m} justify='space-between'>
        <HBox gap={layout.padding.inline.m}>
          {Object.entries(reactionCounts).map(([reaction, count]) => (
            <ReactionCountRow
              key={reaction}
              reaction={reaction}
              count={count}
            />
          ))}
          {Object.entries(post.reactions).length > 0 && <Dimple vertical/>}
          <Label small dim>
            {t('comment_count', {count: post.commentCount})}
          </Label>
        </HBox>
      </HBox>
    )
  }

  return render()

})

export default PostFooter