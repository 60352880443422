import React from 'react'
import I18n from 'i18next'
import { sparse, stringContains, wrapArray } from 'ytil'
import { Language, languagesStore } from '~/stores'
import { observer } from '~/ui/component'
import { AutoCompleteField, AutoCompleteFieldProps, HBox, Label, ListItem } from '~/ui/components'
import { AutoCompleteSection } from '~/ui/components/fields/autocomplete'
import { useContinuousRef } from '~/ui/hooks'
import { layout } from '~/ui/styling'

export type Props = Omit<AutoCompleteFieldProps<string, Language>,
  | 'onSearch'
>

const LanguagesField = observer('LanguagesField', (props: Props) => {

  const status = languagesStore.status
  const valueRef = useContinuousRef(props.value)

  const searchLanguages = React.useCallback((query: string) => {
    if (status !== 'done') { return [] }

    const {all, common} = languagesStore
    const current = sparse(wrapArray(valueRef.current))

    const sections: AutoCompleteSection<Language>[] = []
    if (common != null && common.length > 0) {
      sections.push({
        caption: I18n.t('language_field:common'),
        choices: sparse(common.map(code => all.find((it: Language) => it.code === code))),
      })
    }
    sections.push({
      caption: sections.length > 0 ? I18n.t('language_field:all') : null,
      choices: all as Language[],
    })

    for (const section of sections) {
      section.choices = section.choices.filter(it => !current.includes(it.code))
    }

    if (query != null) {
      for (const section of sections) {
        section.choices = section.choices.filter(it => (
          stringContains(it.code, query) ||
          stringContains(it.name_en, query) ||
          stringContains(it.name_loc, query)
        ))
      }
    }

    return sections
  }, [status, valueRef])

  const languageForCode = React.useCallback((value: string) => {
    if (status !== 'done') { return null }
    return languagesStore.getLanguage(value)
  }, [status])

  const codeForLanguage = React.useCallback((choice: Language) => {
    return choice.code
  }, [])

  function render() {
    return (
      <AutoCompleteField<string, Language>
        {...props as any}
        onSearch={searchLanguages}
        choiceForValue={languageForCode}
        valueForChoice={codeForLanguage}
        renderChoice={renderChoice}
        renderChoiceChip={renderChoiceChip}
        minimumSearchQueryLength={3}
        minValues={1}
        multi
      />
    )
  }

  function renderChoice(language: Language) {
    return (
      <ListItem
        image={(
          <Label small mono box>
            {language.code}
          </Label>
        )}
        caption={language.name_loc}
        detail={language.name_en}
      />
    )
  }

  function renderChoiceChip(language: Language) {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Label small box mono>
          {language.code}
        </Label>
        <Label>
          {language.name_loc}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default LanguagesField