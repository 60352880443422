import { action, makeObservable, observable } from 'mobx'

export default abstract class ImportField {

  constructor(
    name:    string,
    options: ImportFieldOptions = {},
  ) {
    this.name = name
    this.exclusive = options.exclusive ?? false
    this.explicitType = options.type ?? null

    makeObservable(this)
  }

  //------
  // Common props

  public readonly name: string

  @observable
  public exclusive: boolean

  @observable
  public explicitType: string | null

  //------
  // Form bind utility

  public bind(prop: keyof this): PropBind {
    const value = this[prop]

    let onChange = this.propAssignFunctions.get(prop)
    if (onChange == null) {
      this.propAssignFunctions.set(prop, onChange = action((value: any) => {
        this[prop] = value
      }))
    }

    return {value, onChange}
  }

  // Memoizes onChange handlers which never change.
  private readonly propAssignFunctions = new Map<any, (value: any) => void>()

  //------
  // Serialization

  public abstract serialize(): AnyObject

  protected serializeWithType(type: string): AnyObject {
    return {
      name: this.name,
      type: this.explicitType ?? type,
    }
  }

  public save(): any {
    return {}
  }
  public load(raw: any) {}

}

export interface ImportFieldOptions {
  type?:      string
  exclusive?: boolean
}

export interface PropBind {
  value:    any
  onChange: (value: any) => void
}