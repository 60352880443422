import React from 'react'
import { Location } from '~/models'
import { LinkField } from '~/ui/app/links'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {}

const LocationCommonFields = memo('LocationCommonFields', () => {

  return (
    <VBox gap={layout.padding.s}>
      <FormField name='tags'>
        {bind => <TagsField Model={Location} {...bind}/>}
      </FormField>
      <FormField name='link'>
        {bind => <LinkField {...bind}/>}
      </FormField>
    </VBox>
  )

})

export default LocationCommonFields