import React from 'react'
import { IndexFilter, IndexSort, IndexViewOptions, Page } from '~/models'
import { memo } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import FilterIndicator from './FilterIndicator'
import SortIndicator from './SortIndicator'
import ViewOptionsIndicator from './ViewOptionsIndicator'

export interface Props {
  page:        Page
  filters:     IndexFilter[]
  sorts:       IndexSort[]
  viewOptions: IndexViewOptions
}

const IndexPageFooter = memo('IndexPageFooter', (props: Props) => {

  const {page, filters, sorts, viewOptions} = props

  //------
  // Rendering

  function render() {
    return (
      <HBox wrap gap={layout.padding.s}>
        {filters.map(renderFilter)}
        <VBox flex>
          <SortIndicator
            page={page}
            sorts={sorts}
          />
        </VBox>
        <ViewOptionsIndicator
          page={page}
          available={viewOptions}
        />
      </HBox>
    )
  }

  function renderFilter(filter: IndexFilter) {
    return (
      <VBox flex key={filter.name}>
        <FilterIndicator
          page={page}
          filter={filter}
        />
      </VBox>
    )
  }

  return render()

})

export default IndexPageFooter