import React from 'react'
import { useTranslation } from 'react-i18next'
import { Challenge, Script } from '~/models'
import { observer } from '~/ui/component'
import { SelectField, TextBlock, TextField, VBox } from '~/ui/components'
import { FormField, FormSwitch, useForm } from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import ConditionFormModel from './ConditionFormModel'

const ConditionSelectorStep = observer('ConditionSelectorStep', () => {

  const {model} = useForm<ConditionFormModel>()

  const [t] = useTranslation('conditions')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('selector.instructions')}
        </TextBlock>
        {renderSelectorSwitch()}
      </VBox>
    )
  }

  function renderSelectorSwitch() {
    return (
      <FormSwitch name='selectorType'>
        <FormSwitch.TypeOption
          type='script-question'
          title={t('selector.types.script-question.caption')}
          detail={t('selector.types.script-question.detail')}
          renderForm={renderScriptQuestionFields}
          autoFocus={false}
        />
        <FormSwitch.TypeOption
          type='challenge-task'
          title={t('selector.types.challenge-task.caption')}
          detail={t('selector.types.challenge-task.detail')}
          renderForm={renderChallengeTaskFields}
          autoFocus={false}
        />
        <FormSwitch.TypeOption
          type='variable'
          title={t('selector.types.variable.caption')}
          detail={t('selector.types.variable.detail')}
          renderForm={renderVariableFields}
          autoFocus={true}
        />
      </FormSwitch>
    )
  }

  //------
  // Script question fields

  function renderScriptQuestionFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='script'>
          {bind => (
            <ResourceField
              {...bind}
              shortcutsCaption={t('fields.script.recent')}
              shortcutIDs={model.context.recentScriptIDs ?? []}
              Model={Script}
              filters={{type: 'linear', hasQuestion: '1'}}
              inputStyle='dark'
            />
          )}
        </FormField>
        <FormField name='scriptMessageUUID'>
          {bind => (
            <SelectField
              {...bind}
              choices={model.scriptMessageChoices}
              placeholder={model.scriptMessageChoices.length === 0 ? t('selector.no_questions') : bind.placeholder}
              inputStyle='dark'
            />
          )}
        </FormField>
      </VBox>
    )
  }

  //------
  // Challenge task fields

  function renderChallengeTaskFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='challenge' caption={false}>
          {bind => <ResourceField inputStyle='dark' Model={Challenge} {...bind}/>}
        </FormField>
        <FormField name='challengeTaskUUID'>
          {bind => (
            <SelectField
              {...bind}
              choices={model.challengeTaskChoices}
              placeholder={model.challengeTaskChoices.length === 0 ? t('selector.no_questions') : bind.placeholder}
              inputStyle='dark'
            />
          )}
        </FormField>
      </VBox>
    )
  }

  //------
  // Variable fields

  function renderVariableFields() {
    return (
      <FormField name='variable' caption={false} instruction={false}>
        {bind => <TextField inputStyle='dark' mono {...bind}/>}
      </FormField>
    )
  }

  return render()

})

export default ConditionSelectorStep