import React from 'react'
import { useTranslation } from 'react-i18next'
import { CalendarItem } from '~/models'
import { observer } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import ModuleBar from '../../modules/ModuleBar'
import { useCalendarPlanner } from '../CalendarPlannerContext'

export interface Props {
  item:             CalendarItem
  moduleID:         string
  commonMenuItems:  PopupMenuItem[]
}

const CalendarPlannerModuleBar = observer('CalendarPlannerModuleBar', (props: Props) => {

  const {item, commonMenuItems} = props
  const {planner} = useCalendarPlanner()
  const [t]       = useTranslation('flow_planner')

  //------
  // Actions

  const configure = React.useCallback(() => {
    planner.editItem(item.uuid, 'content')
  }, [item.uuid, planner])

  const kebabMenuItems = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'cog',
      caption:  t('module.actions.configure'),
      keyHint:  'doubleclick',
      onSelect: configure,
    })

    return [
      ...items,
      ...commonMenuItems,
    ]
  }, [t, configure, commonMenuItems])

  function render() {
    return (
      <ModuleBar
        moduleID={props.moduleID}
        kebabMenuItems={kebabMenuItems}
      />
    )
  }

  return render()

})

export default CalendarPlannerModuleBar