import React from 'react'
import { useTranslation } from 'react-i18next'
import { isPlainObject } from 'lodash'
import config from '~/config'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { BrandedComponent, HBox, Label, SVG, Tappable } from '~/ui/components'
import { createUseStyles, layout, shadows, useStyling } from '~/ui/styling'

const BeWizrLoginButton = observer('BeWizrLoginButton', () => {

  const [t] = useTranslation('bewizr')

  const authWindowRef = React.useRef<Window | null>(null)

  const handleWindowMessage = React.useCallback((event: MessageEvent) => {
    if (!isPlainObject(event.data)) { return }
    if (event.origin !== config.urls.api) { return }
    if (event.source !== authWindowRef.current) { return }

    if (event.data.type === 'oauth:complete') {
      authenticationStore.storeOAuthTokens('bewizr', event.data.payload.oAuthTokens)
      authenticationStore.authenticateWithToken(event.data.payload.authToken)
      authWindowRef.current?.close()
    }
  }, [])

  const startAuthenticationFlow = React.useCallback(async () => {
    const url = new URL(`${config.urls.api}/mission/oauth/bewizr/init`)
    authWindowRef.current = window.open(url, undefined, 'width=640,height=560')
    window.addEventListener('message', handleWindowMessage)

    return () => {
      window.removeEventListener('message', handleWindowMessage)
      authWindowRef.current = null
    }
  }, [handleWindowMessage])

  //------
  // Rendering

  const $ = useStyles()

  const {guide} = useStyling()

  const branding = guide.web.login.oAuthButton
  const height   = branding.resolve('height')

  function render() {
    return (
      <BrandedComponent classNames={$.BeWizrLoginButton} branding={branding} height={height} style={{height}}>
        <Tappable flex onTap={startAuthenticationFlow}>
          <HBox flex padding={layout.padding.inline.l} gap={layout.padding.inline.m} justify='center' align='middle'>
            {renderLogo()}
            {renderCaption()}
          </HBox>
        </Tappable>
      </BrandedComponent>
    )
  }

  function renderLogo() {
    return (
      <SVG
        name='bewizr-logo'
        size={layout.icon.s}
      />
    )
  }

  function renderCaption() {
    return (
      <Label small bold>
        {t('login')}
      </Label>
    )
  }

  return render()

})

export default BeWizrLoginButton

export const logoSize = layout.icon.l

const useStyles = createUseStyles(theme => ({
  BeWizrLoginButton: {
    '&:focus-within > *': {
      outline:      'none',
      borderRadius: 'inherit',
      boxShadow: [
        shadows.depth(theme.guide.web.login.oAuthButton.resolve('depth') ?? 1),
        shadows.focus.bold(theme),
      ],
    },

    '& > ::before': {
      content:       '""',
      pointerEvents: 'none',
      ...layout.overlay,
    },

    '&:hover > ::before': {
      background: theme.colors.bg.hover,
    },

    '&:active > ::before': {
      background: theme.colors.bg.light.active,
    },
  },
}))