import { DateTime } from 'luxon'
import { action, makeObservable, observable } from 'mobx'
import socket from 'socket.io-react'
import { register } from './support'

export class ClockStore {

  constructor() {
    socket.addEventListener('clock:sync', this.sync)
    makeObservable(this)
  }

  public async deinit() {
    socket.removeEventListener('clock:sync', this.sync)
  }

  @observable
  public serverDelta: number | null = null

  private sync = action((timestamp: number) => {
    this.serverDelta = timestamp - Date.now()
  })

  public serverTime(clientTime: DateTime): DateTime | null {
    if (this.serverDelta == null) { return null }
    return clientTime.plus(this.serverDelta)
  }

}

export default register(new ClockStore())