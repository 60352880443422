import { makeObservable, observable } from 'mobx'
import { Branding, CustomImage } from '~/models'
import { AppHeaderBrandingSpec, BrandingGuide } from '~/ui/styling'
import ComponentBrandingFormModel from '../ComponentBrandingFormModel'
import { registerComponentBrandingFormModel } from '../registry'

export default class AppHeaderBrandingFormModel extends ComponentBrandingFormModel<AppHeaderBrandingSpec> {

  constructor(guide: BrandingGuide, branding: Branding, componentName: string, initialVariant: string | null) {
    super(guide, branding, componentName, initialVariant)

    makeObservable(this)
  }

  @observable
  public logo: CustomImage | null = this.getImage('logo', 'app')

  public buildData() {
    return {
      ...super.buildData(),
      images: this.mergeImage('logo', 'app', this.logo),
    }
  }

}

registerComponentBrandingFormModel('app.header', AppHeaderBrandingFormModel)