import React from 'react'
import { Metric } from '~/models'
import { DynamicFieldset } from '~/ui/app/dynamic-form'
import { observer } from '~/ui/component'
import { NumberField, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField } from '~/ui/form'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import { PlannerActionFieldsetProps, register } from './registry'

export type Props = PlannerActionFieldsetProps

const CheckpointFields = observer('CheckpointFields', (props: Props) => {

  const {schema, params} = props
  const [metric] = useModelDocumentData(Metric, params.metric as string | null)

  const {t} = useResourceTranslation('metrics')

  const extraFields = schema.filter(it => !['metric', 'label', 'value'].includes(it.name))
  const needsPhase  = ['duration', 'funnel'].includes(metric?.type ?? '')
  const needsValue  = ['valued', 'funnel'].includes(metric?.type ?? '')

  const metricField = schema.find(it => it.name === 'metric')
  const labelField = schema.find(it => it.name === 'label')
  const valueField = schema.find(it => it.name === 'value')

  const labels = React.useMemo((): string[] => {
    switch (metric?.type) {
      case 'duration': return ['in', 'out']
      case 'funnel':   return metric.steps
      default:         return []
    }
  }, [metric])

  const labelChoices = React.useMemo(() => {
    return labels.map((label): Choice => ({
      value:   label,
      caption: t(`labels.${label}`, {defaultValue: label}),
    }))
  }, [labels, t])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='metric' required {...metricField}>
          {bind => <ResourceField Model={Metric} {...bind}/>}
        </FormField>
        {needsPhase && (
          <FormField name='label' required {...labelField}>
            {bind => <SelectField {...bind} choices={labelChoices}/>}
          </FormField>
        )}
        {needsValue && (
          <FormField name='value' required {...valueField}>
            {bind => <NumberField {...bind} step={1}/>}
          </FormField>
        )}
        {extraFields.length > 0 && (
          <DynamicFieldset
            schema={extraFields}
          />
        )}
      </VBox>
    )
  }

  return render()

})

register('analytics:checkpoint', CheckpointFields)
export default CheckpointFields