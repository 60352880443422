import React from 'react'
import { useRouteMatch } from 'react-router'
import { ClientApp } from '~/models'
import { ModelEndpoint } from '~/stores'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceList } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'

import type { Params } from './ClientAppsScreen'

export interface Props {
  endpoint: ModelEndpoint<ClientApp>
}

const ClientAppsList = memo('ClientAppsList', (props: Props) => {

  const {endpoint} = props

  const match = useRouteMatch<Params>()
  const id    = match.params.id ?? null

  const {fieldCaption} = useResourceTranslation('client-apps')

  //------
  // Rendering

  function render() {
    return (
      <ResourceList<ClientApp>
        endpoint={endpoint}
        itemHref={log => `/client-apps/-/${log.id}`}
        itemIsSelected={log => log.id === id}
        allowSelect={false}
        useLocation={true}
      >
        <DataGrid.Column<ClientApp>
          name='id'
          caption={fieldCaption('id')}
          sort='id'
          renderCell={renderBundleID}
        />
      </ResourceList>
    )
  }

  function renderBundleID(clientApp: ClientApp) {
    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='iphone' size={layout.icon.m}/>
        <Label flex='shrink' bold box mono>
          {clientApp.id}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default ClientAppsList