import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { Challenge, ModelClass, ModelOfType } from '~/models'
import { memo } from '~/ui/component'
import { useModelDocumentData } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { AnswerScopeModel } from './AnswerScopeField'
import ExploreAnswersContainer from './ExploreAnswersContainer'

export interface Params {
  scopeID:   string
  scopeType: string
}

const ExploreAnswersScreen = memo('ExploreAnswersScreen', () => {

  const {params}  = useRouteMatch<Params>()
  const [t] = useTranslation('answers')

  const scopeID   = params.scopeID
  const scopeType = params.scopeType

  const Model      = (scopeType == null || scopeType === 'all' ? Challenge : ModelOfType(scopeType)) as ModelClass<Exclude<AnswerScopeModel, '$all'>>
  const [model]    = useModelDocumentData<Exclude<AnswerScopeModel, '$all'>>(Model, scopeID, {detail: true})

  const breadcrumbs = React.useMemo((): Breadcrumb[] => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({name: 'answers'})

    if (scopeType === 'all') {
      breadcrumbs.push({
        icon:    'star',
        caption: t('scope.all'),
        href:    '/answers/all',
      })
    } else if (model != null) {
      breadcrumbs.push({
        icon:    model.$icon,
        caption: model.$caption,
        href:    `/answers/${scopeType}/${model.id}`,
      })
    }

    return breadcrumbs
  }, [model, t, scopeType])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          configKey='answers'
          breadcrumbs={breadcrumbs}
        />
        <ExploreAnswersContainer
          scopeID={scopeID}
          scopeType={scopeType}/>
      </>
    )
  }

  return render()

})

export default ExploreAnswersScreen