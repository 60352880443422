import React from 'react'
import { QuestionDescriptor } from '~/stores'
import { memo } from '~/ui/component'
import { Checkbox, ListItem } from '~/ui/components'

export interface Props {
  question: QuestionDescriptor
  selected: boolean
  onToggle: (questionUUID: string) => any
}

const QuestionsListItem = memo('QuestionsListItem', (props: Props) => {

  const {question, selected, onToggle} = props

  //------
  // Rendering

  function render() {
    return (
      <ListItem
        caption={question.caption}
        onTap={check}
        accessoryLeft={<Checkbox checked={selected} />}
      />
    )
  }

  const check = React.useCallback(() => {
    onToggle(question.uuid)
  }, [question.uuid, onToggle])

  return render()

})

export default QuestionsListItem