import { computed, makeObservable, observable } from 'mobx'
import { Feedback, Scoring } from '~/models'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormModel, SubmitResult } from '~/ui/form'

export default abstract class ScoringFormModel implements FormModel {

  constructor(
    public readonly feedback: Feedback,
    scorings: Scoring[],
  ) {
    this.scorings = scorings
    makeObservable(this)
  }

  @observable
  public scorings: Scoring[]

  @computed
  public get answerChoices(): Choice<string>[] {
    switch (this.feedback.type) {
      case 'button':
        return this.feedback.buttons.map(it => ({
          value: it.value ?? it.caption, caption: it.caption,
        }))
      case 'choice':
        return this.feedback.choices.map(it => ({
          value: it.value ?? it.caption, caption: it.caption,
        }))
      default: return []
    }
  }

  public abstract submit(): Promise<SubmitResult | undefined>

}