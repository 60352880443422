import React from 'react'
import { Feed, Participant, Post } from '~/models'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import {
  Chip,
  HBox,
  KebabMenu,
  Label,
  LinkLabel,
  PopupMenuItem,
  Spinner,
  VBox,
} from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceAppLink } from '~/ui/resources/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  post:        Post
  requestEdit?: (post: Post) => any
}

const PostHeader = observer('PostHeader', (props: Props) => {

  const {post, requestEdit} = props
  const theme = useTheme()

  const {actionCaption} = useResourceTranslation('posts')

  const [author] = useModelDocumentData(Participant, post.author, {
    fetch: 'notfound',
  })
  const [feed, {fetchStatus: feedFetchStatus}] = useModelDocumentData(Feed, post.feed, {
    fetch: 'notfound',
  })

  const edit = React.useCallback(async () => {
    requestEdit?.(post)
  }, [post, requestEdit])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    if (requestEdit != null) {
      items.push({
        icon:    'pencil',
        caption:  actionCaption('edit'),
        onSelect: edit,
      })
    }


    return items
  }, [actionCaption, edit, requestEdit])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.PostHeader} gap={layout.padding.inline.l} align='top'>
        <VBox flex gap={layout.padding.inline.s}>
          {renderAuthor()}
          {renderMeta()}
        </VBox>
        {kebabMenuItems.length > 0 && (
          <KebabMenu
            items={kebabMenuItems}
            button={{small: true, padding: 'vertical'}}
          />
        )}
      </HBox>
    )
  }

  function renderAuthor() {
    if (author == null) {
      return <Spinner size={8}/>
    }

    return (
      <HBox gap={layout.padding.inline.s}>
        <Avatar
          source={author.photoURL}
          firstName={author.firstName}
          size={avatarSize}
        />
        <LinkLabel h3 href={`/participants/-/${author.id}`} color={theme.fg.normal}>
          {author.name}
        </LinkLabel>
      </HBox>
    )
  }

  function renderMeta() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderFeed()}
        {renderDate()}
        <ResourceAppLink model={post} flex truncate/>
      </HBox>
    )
  }

  function renderFeed() {
    return (
      <Chip
        backgroundColor={feed?.color ?? theme.fg.dimmer}
        loading={feedFetchStatus === 'fetching'}
        children={feed?.title ?? ''}
        small
      />
    )
  }

  function renderDate() {
    if (post.publishedAt == null) { return null }

    return (
      <Label tiny dim>
        {post.publishedAt?.toFormat('MMM d, HH:mm')}
      </Label>
    )
  }

  return render()

})

export default PostHeader

export const avatarSize = {
  width:  24,
  height: 24,
}

const useStyles = createUseStyles(theme => ({
  PostHeader: {},
}))