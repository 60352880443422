import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Competition } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, Panel, SwitchField, TextBlock, TextField, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props extends Omit<FormDialogProps<any>, 'model'> {
  competition: Competition
}

const TeamScoringForm = observer('TeamScoringForm', (props: Props) => {

  const {competition, ...rest} = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new TeamScoringFormModel(competition),
    [competition],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDialog semi={false} model={formModel} {...rest} title={t('scoring_form.title')}>
        <VBox gap={layout.padding.m}>
          <TextBlock small dim markup>
            {t('scoring_form.instructions')}
          </TextBlock>

          <Panel padding={layout.padding.s} gap={layout.padding.s}>
            <FormField name='enabled' i18nKey='normalize'>
              {bind => <SwitchField {...bind}/>}
            </FormField>
            {formModel.enabled && <Dimple horizontal/>}
            {formModel.enabled && (
              <FormField name='variable' i18nKey='normalize_variable'>
                {bind => <TextField {...bind} mono inputStyle='dark'/>}
              </FormField>
            )}
          </Panel>
        </VBox>
      </FormDialog>
    )
  }

  return render()

})

export default TeamScoringForm

class TeamScoringFormModel implements FormModel {

  constructor(
    public readonly competition: Competition,
  ) {
    makeObservable(this)
  }

  @observable
  public enabled: boolean = this.competition.normalize != null

  @observable
  public variable: string | null = this.competition.normalize?.variable ?? null

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Competition, this.competition.id, {
      normalize: this.enabled
        ? {variable: this.variable}
        : null,
    })
  }

}