import React from 'react'
import { isPlainObject, some } from 'lodash'
import { Widget, WidgetAlotment } from '~/models'
import { DataPoint, DataSeries, WidgetState } from '~/stores'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, VBox } from '~/ui/components'
import { Chart, ChartAxis, ColumnSeries, Legend, LineSeries } from '~/ui/components/datavis'
import { createUseStyles, layout } from '~/ui/styling'
import { widget } from './registry'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  state:    WidgetState | null
}

const ChartWidgetView = memo('ChartWidgetView', (props: Props) => {

  const {widget, state} = props

  const data   = React.useMemo(() => state?.data ?? [], [state?.data])
  const config = React.useMemo(() => state?.config ?? {}, [state?.config])
  const series = React.useMemo(() => state?.series ?? [], [state?.series])
  const seriesInLegend = React.useMemo(() => series.filter(it => it.showInLegend !== false), [series])

  const xAxis = React.useMemo(() => {
    const {xAxis = true} = config
    if (xAxis === false) { return false }
    return isPlainObject(xAxis) ? xAxis : {}
  }, [config])

  const y1Axis = React.useMemo(() => {
    const {y1Axis = some(series, it => it.axis !== 'y2')} = config
    if (y1Axis === false) { return false }
    return isPlainObject(y1Axis) ? y1Axis : {}
  }, [series, config])

  const y2Axis = React.useMemo(() => {
    const {y2Axis = some(series, it => it.axis === 'y2')} = config
    if (y2Axis === false) { return false }
    return isPlainObject(y2Axis) ? y2Axis : {}
  }, [series, config])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.chartWidgetView} flex gap={layout.padding.inline.l}>
        {renderChart()}
        {renderLegend()}
      </VBox>
    )
  }

  function renderChart() {
    return (
      <VBox flex>
        <Chart data={data} keyForPoint={point => point.key} axisLabelForPoint={axisLabelForPoint} renderPopup={renderPopup}>
          {xAxis !== false && <ChartAxis axis='x' {...xAxis}/>}
          {y1Axis !== false && <ChartAxis axis='y1' {...y1Axis}/>}
          {y2Axis !== false && <ChartAxis axis='y2' {...y2Axis}/>}
          {series.map(renderSeries)}
        </Chart>
      </VBox>
    )
  }

  function renderSeries(series: DataSeries) {
    const Series = SeriesComponent(series.type)
    return (
      <Series<DataPoint>
        key={series.name}
        name={series.name}
        valueForPoint={point => point[series.valueKey]}
        showValueLabels={series.showValueLabels ?? config.showValueLabels ?? false}
      />
    )
  }

  const SeriesComponent = React.useCallback((type: DataSeries['type']) => {
    switch (type) {
      case 'column': return ColumnSeries
      case 'line': return LineSeries
    }
  }, [])

  function renderLegend() {
    if (seriesInLegend.length === 0) { return null }

    return (
      <HBox justify='center'>
        <Legend
          data={seriesInLegend}
          captionForPoint={captionForSeries}
          horizontal
        />
      </HBox>
    )
  }

  const captionForSeries = React.useCallback((series: DataSeries) => {
    return widget.translate(`series.${series.name}`) ?? series.name
  }, [widget])

  const axisLabelForPoint = React.useCallback((point: DataPoint) => {
    return point.axisLabel ?? widget.translate(`labels.${point.key}`)
  }, [widget])

  const renderPopup = React.useCallback((point: DataPoint) => {
    const items = series.map(it => [
      captionForSeries(it),
      point[it.valueKey],
    ])

    return (
      <VBox gap={layout.padding.inline.s} padding={layout.padding.inline.m}>
        {point.label != null && (
          <Label caption>
            {point.label}
          </Label>
        )}
        <Dimple horizontal/>
        <Legend
          data={items}
          captionForPoint={it => it[0]}
          valueForPoint={it => it[1]}
          dimLabels={false}
        />
      </VBox>
    )
  }, [captionForSeries, series])

  return render()

})

widget('chart')(ChartWidgetView)
export default ChartWidgetView

const useStyles = createUseStyles({
  chartWidgetView: {
    paddingBottom: 4, // For the legend shadows
  },
})