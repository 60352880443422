import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { Center, SegmentedButton, Spinner, VBox } from '~/ui/components'
import { useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import ConditionFormModel, { ConditionTestVariant } from './ConditionFormModel'
import renderTestFields from './tests/index'

const ConditionTestStep = observer('ConditionTestStep', () => {

  const {model} = useForm<ConditionFormModel>()
  const question = model.question
  const fetching = model.isFetchingQuestion

  const [t] = useTranslation('conditions')

  const setTestVariant = React.useCallback((variant: ConditionTestVariant) => {
    model.setTestVariant(variant)
  }, [model])

  //------
  // Rendering

  function render() {
    if (fetching) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }

    return (
      <VBox gap={layout.padding.m}>
        <Center>
          {renderTestSelector()}
        </Center>
        {renderTestFields(model.testVariant, question)}
      </VBox>
    )
  }

  function renderTestSelector() {
    return (
      <SegmentedButton
        selectedValue={model.testVariant}
        onChange={setTestVariant}
        segments={model.testVariants.map(variant => ({
          value:   variant,
          caption: t(`tests.${variant}.caption`),
        }))}
      />
    )
  }

  return render()

})

export default ConditionTestStep