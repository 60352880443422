import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlanSegue } from '~/models'
import { SegueDetail } from '~/stores'
import { ConditionsForm } from '~/ui/app/conditions'
import { memo } from '~/ui/component'
import { useFlowPlanner } from '../FlowPlannerContext'
import SegueConditionsFormModel from './SegueConditionsFormModel'
import SegueDelayForm from './SegueDelayForm'
import SegueTargetingForm, { SegueTargetingFormModel } from './SegueTargetingForm'

export interface Props {
  open:          boolean
  requestClose?: () => any

  segue:  PlanSegue
  detail: SegueDetail
}

const SegueDetailForm = memo('SegueDetailForm', (props: Props) => {

  const {
    open,
    requestClose,
    segue,
    detail,
  } = props

  const {planner, plan} = useFlowPlanner()

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  function render() {
    switch (detail) {
      case 'targeting':  return renderTargetingForm()
      case 'conditions': return renderConditionsForm()
      case 'delay':      return renderDelayForm()
    }
  }

  //------
  // Delay form

  const targetingFormModel = React.useMemo(
    () => planner == null ? null : new SegueTargetingFormModel(planner, segue),
    [planner, segue],
  )

  function renderTargetingForm() {
    if (targetingFormModel == null) { return null }

    return (
      <SegueTargetingForm
        open={open}
        requestClose={requestClose}
        formModel={targetingFormModel}
      />
    )
  }

  //------
  // Conditions form

  const conditionsFormModel = React.useMemo(() => {
    if (planner == null || plan == null || detail !== 'conditions') { return null }
    return new SegueConditionsFormModel(planner, segue)
  }, [detail, plan, planner, segue])

  React.useEffect(() => {
    if (plan == null) { return }
    conditionsFormModel?.deriveContextFromFlowPlan(plan)
  }, [conditionsFormModel, plan])

  function renderConditionsForm() {
    if (conditionsFormModel == null) { return null }

    return (
      <ConditionsForm
        open={open}
        requestClose={requestClose}
        model={conditionsFormModel}
        instructions={t('segues.conditions.instructions')}
      />
    )
  }

  //------
  // Delay form

  function renderDelayForm() {
    return (
      <SegueDelayForm
        open={open}
        requestClose={requestClose}
        segue={segue}
      />
    )
  }

  return render()

})

export default SegueDetailForm