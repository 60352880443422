import { makeObservable, observable } from 'mobx'
import { PlanSegue, SegueDelay, TimeInterval, TimeOfDay } from '~/models'
import { FlowPlanner } from '~/stores'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import DaySelectorFormModel from '../../days/DaySelectorFormModel'

export default class SegueDelayFormModel {

  constructor(
    private readonly planner: FlowPlanner,
    private readonly segue: PlanSegue,
  ) {
    makeObservable(this)
  }

  private get delay(): SegueDelay | null {
    return this.segue.delay
  }

  @observable
  public type: SegueDelay['type'] | null = this.delay?.type ?? 'fixed'

  @observable
  public interval: TimeInterval = this.delay?.type === 'fixed' ? this.delay.interval : TimeInterval.parse('1h')

  public daySelectorModel = new DaySelectorFormModel(this.delay?.type === 'day' ? this.delay.day : {type: 'offset', offset: 1})

  @observable
  public time: TimeOfDay | null = this.delay?.type === 'day' ? this.delay.time : TimeOfDay.now()

  @observable
  public reminder: boolean = this.segue.delay?.reminder ?? false

  public async submit(): Promise<SubmitResult | undefined> {
    const result = await this.planner.updateSegue(this.segue.uuid, segue => ({
      ...segue,
      delay: {
        ...this.buildDelay(),
        reminder: this.reminder,
      },
    }))

    return translateFormErrorPaths(result, it => it.replace(/^delay\.(day\.)?/, ''))
  }

  protected buildDelay(): any {
    switch (this.type) {
      case null: return null

      case 'fixed': {
        if (this.interval.isZero) {
          return null
        } else {
          return {type: 'fixed', interval: this.interval}
        }
      }

      case 'day': {
        return {
          type: 'day',
          day:  this.daySelectorModel.buildSelector(),
          time: this.time,
        }
      }
    }
  }

}