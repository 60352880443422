import React from 'react'
import { createOptimizedContext } from 'react-optimized-context'
import { clamp } from 'lodash'
import { TimeOfDay } from '~/models'
import { useContinuousRef, useViewState } from '~/ui/hooks'
import { dayHeight, hourHeight, padding } from './days/layout'

export interface CalendarLayoutContext {
  origin:    number
  transform: string
}

export interface CalendarLayoutContextOptimized {
  setOrigin:  (origin: Partial<number>) => void
  getOrigin:  () => number
  setDayRect: (rect: LayoutRect) => void

  timeOfDayToScreenY: (time: TimeOfDay) => number
  screenYToTimeOfDay: (screenY: number, options?: PixelConversionOptions) => TimeOfDay
}

export interface PixelConversionOptions {
  roundTo?: number
}

export const CalendarLayoutContext = createOptimizedContext<CalendarLayoutContext, CalendarLayoutContextOptimized>({
  origin:     0,
  transform:  'translateY(0)',

  getOrigin:  () => 0,
  setOrigin:  () => void 0,
  setDayRect: () => void 0,

  timeOfDayToScreenY: () => 0,
  screenYToTimeOfDay: () => TimeOfDay.ZERO,
})

export const useCalendarLayout = CalendarLayoutContext.useHook

export interface CalendarLayoutContextProviderProps {
  children?: React.ReactNode
}

export function CalendarLayoutContextProvider(props: CalendarLayoutContextProviderProps) {
  const {children} = props

  const [storedOrigin, setStoredOriginState] = useViewState<number | null>(VIEWSTATE_KEY, null)
  const dayRectRef = React.useRef<LayoutRect>({left: 0, top: 0, width: 0, height: 0})

  const origin       = storedOrigin ?? 0
  const originRef    = useContinuousRef(origin)

  const setStoredOrigin = React.useCallback((origin: number) => {
    const minOrigin = -(dayHeight() - dayRectRef.current.height)

    setStoredOriginState(clamp(origin, minOrigin, 0))
  }, [dayRectRef, setStoredOriginState])

  //------
  // Context

  const context = React.useMemo((): CalendarLayoutContext => ({
    origin,
    transform: `translateY(${origin}px)`,
  }), [origin])

  const optimizedContext = React.useMemo((): CalendarLayoutContextOptimized => ({
    getOrigin: () => originRef.current,
    setOrigin: origin => {
      setStoredOrigin(origin)
    },
    setDayRect: rect => {
      dayRectRef.current = rect
    },

    timeOfDayToScreenY: time => {
      return (time.minutes / 60) * hourHeight + dayRectRef.current.top + originRef.current + padding
    },

    screenYToTimeOfDay: (screenY, options = {}) => {
      const minutes = (screenY - dayRectRef.current.top - originRef.current - padding) / hourHeight * 60

      const time = new TimeOfDay(minutes)
      if (options.roundTo != null) {
        return time.roundTo(options.roundTo)
      } else {
        return time
      }
    },
  }), [dayRectRef, originRef, setStoredOrigin])

  //------
  // Render

  return (
    <CalendarLayoutContext.Provider value={context} optimizedValue={optimizedContext}>
      {children}
    </CalendarLayoutContext.Provider>
  )
}

const VIEWSTATE_KEY = 'calendar.origin'