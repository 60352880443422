import { Media } from '~/models'

export type ProgressListener = (progress: UploadProgress) => any

export interface UploadProgress {
  sent:  number
  total: number
}

export const UploadProgress: {
  zero: () => UploadProgress
} = {
  zero: () => ({sent: 0, total: 0}),
}

export type MediaUploadResult =
  | {status: 'ok', media: Media}
  | {status: 'invalid', reason: string}
  | {status: 'canceled'}
  | {status: 'error'}