import { isFunction } from 'lodash'
import { reaction, toJS } from 'mobx'
import { allStores } from './registry'

export interface PersistedStore<S> {
  persistenceKey: string
  persist(): S
  rehydrate(state: S): void | Promise<void>
}

export function supportsPersistence<S = any>(store: any): store is PersistedStore<S> {
  if (store == null) { return false }
  if (typeof store.persistenceKey !== 'string') { return false }

  if (!isFunction(store.persist)) { return false }
  if (!isFunction(store.rehydrate)) { return false }

  return true
}

const STORAGE_KEY = 'persist'
const storage: Storage | null = 'localStorage' in global ? (global as any).localStorage : null

export async function restorePersistedState() {
  try {
    const state = loadPersistedState()

    for (const store of allStores()) {
      if (!supportsPersistence(store)) { continue }

      const storeState = state[store.persistenceKey]
      if (storeState == null) { continue }

      await store.rehydrate(storeState)
    }
  } catch (error: any) {
    console.warn("Error while loading persisted state: ", error.stack)
  }
}

export function setUpPersistence() {
  reaction(() => derivePersistedState(), state => {
    storage?.setItem(STORAGE_KEY, JSON.stringify(state))
  })
}

function derivePersistedState() {
  return allStores().reduce((state, store) => {
    if (!supportsPersistence(store)) { return state }

    return {
      ...state,
      [store.persistenceKey]: toJS(store.persist()),
    }
  }, {})
}

function loadPersistedState(): any {
  if (storage == null) { return {} }

  const raw = storage.getItem(STORAGE_KEY)
  if (raw == null) { return {} }

  return JSON.parse(raw)
}