import React from 'react'
import { Participant, Project, RegistrationCode } from '~/models'
import { observer } from '~/ui/component'
import { Label, Spinner, TextBlock, VBox } from '~/ui/components'
import { useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChip } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  item: RegistrationCode
}

const RegistrationCodeStatusSummary = observer('RegistrationCodeStatusSummary', (props: Props) => {

  const {item} = props

  const {t} = useResourceTranslation('registration-codes')

  const [participants, {fetchStatus}] = useModelEndpointData(Participant, {
    filters: {registrationCode: item.id},
    fetch:   true,
    label:   'all',
  })

  const participant = participants[0]

  //------
  // Rendering

  function render() {
    if (fetchStatus === 'fetching') {
      return renderLoading()
    } else if (participant == null) {
      return renderEmpty()
    }
    return (
      <VBox gap={layout.padding.inline.s} align='center'>
        <ResourceChip
          Model={Participant}
          id={participant.id}
          small
        />
        <ResourceChip
          Model={Project}
          id={participant.project}
          small
        />
      </VBox>
    )
  }

  function renderLoading() {
    return (
      <Spinner size={8}/>
    )
  }

  function renderEmpty() {
    return(
      <VBox gap={layout.padding.inline.s}>
        <Label small bold align='center'>
          {t('status.summary.empty.title')}
        </Label>
        <TextBlock dim tiny align='center'>
          {t('status.summary.empty.detail')}
        </TextBlock>
      </VBox>
    )
  }

  return render()

})

export default RegistrationCodeStatusSummary