import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '~/models'
import { LinkTappable } from '~/ui/app/navigation'
import { memo } from '~/ui/component'
import { ClearButton, Dimple, HBox, Label, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props {
  link: Link

  requestEdit?:   () => any
  requestRemove?: () => any
}

const LinkDecoratorPopup = memo('LinkDecoratorPopup', (props: Props) => {

  const {
    link,
    requestEdit,
    requestRemove,
  } = props

  const [t] = useTranslation('rich_text_field')

  const href = link.href.replace(/^https?:\/\//, '')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const editButton   = renderEditButton()
    const removeButton = renderRemoveButton()

    return (
      <HBox classNames={$.LinkDecoratorPopup} gap={layout.padding.inline.m}>
        <ThemeProvider fgNormal={t => t.fg.link}>
          <SVG name='link' size={layout.icon.s}/>
        </ThemeProvider>
        {renderMain()}
        {(editButton || removeButton) && <Dimple vertical/>}
        {editButton}
        {removeButton}
      </HBox>
    )
  }

  function renderMain() {
    return (
      <VBox flex='shrink' classNames={$.body}>
        <LinkTappable link={link}>
          {renderLinkLabel()}
        </LinkTappable>
      </VBox>
    )
  }

  function renderLinkLabel() {
    return (
      <Label link small mono>
        {href}
      </Label>
    )
  }

  function renderEditButton() {
    if (requestEdit == null) { return null }

    return (
      <ClearButton
        icon='pencil'
        caption={t('link.edit')}
        onTap={requestEdit}
        small
      />
    )
  }

  function renderRemoveButton() {
    if (requestRemove == null) { return null }

    return (
      <ClearButton
        icon='cross-circle'
        caption={t('link.remove')}
        onTap={requestRemove}
        small
      />
    )
  }

  return render()

})

export default LinkDecoratorPopup

const useStyles = createUseStyles(theme => ({
  LinkDecoratorPopup: {
    padding:  layout.padding.inline.m,
  },

  body: {
    background:   theme.bg.semi,
    borderRadius: layout.radius.s,
    padding:      layout.padding.inline.s,
  },
}))