import React from 'react'
import { get } from 'lodash'
import { BrandedComponentSpec } from '~/models'
import { BrandingGuide, ComponentBranding, ComponentBrandingBase, useStyling } from '~/ui/styling'
import { useBranding } from '../BrandingContext'

export function useComponentBranding(name: null, variant?: string | null, specOverride?: BrandedComponentSpec): UseComponentBrandingEmptyHook
export function useComponentBranding<B extends ComponentBrandingBase<any> = ComponentBrandingBase<any>>(name: string, variant?: string | null, specOverride?: BrandedComponentSpec): UseComponentBrandingHook<B>
export function useComponentBranding<B extends ComponentBrandingBase<any> = ComponentBrandingBase<any>>(name: string | null, variant?: string | null, specOverride?: BrandedComponentSpec): UseComponentBrandingHook<B> | UseComponentBrandingEmptyHook
export function useComponentBranding<B extends ComponentBrandingBase<any> = ComponentBrandingBase<any>>(name: string | null, variant: string | null = null, specOverride?: BrandedComponentSpec): UseComponentBrandingHook<B> | UseComponentBrandingEmptyHook {
  const {branding, guide} = useBranding()

  const spec = React.useMemo((): SpecOf<B> | null => {
    if (specOverride != null) { return specOverride as SpecOf<B> }
    if (name == null) { return null }

    const key = variant == null ? name : `${name}:${variant}`
    return branding.components[key] as SpecOf<B> ?? null
  }, [branding, name, specOverride, variant])

  const component = React.useMemo((): B => {
    let component: B = get(guide, name ?? '__')
    if (component == null) {
      component = new ComponentBranding(
        guide,
        null,
        spec ?? BrandedComponentSpec.default() as any,
      ) as any
    }

    const copy = component.copy() as B
    if (spec != null) {
      copy.deserializeFrom(spec, variant)
    }
    return copy
  }, [guide, name, spec, variant])

  const size = React.useMemo(() => {
    if (component != null && 'previewSize' in component) {
      return (component as any).previewSize ?? defaultSize
    } else {
      return defaultSize
    }
  }, [component])

  const variantFlags = React.useMemo(
    () => variant == null ? {} : {[variant]: true},
    [variant],
  )

  return [component, variantFlags, size, spec]
}

export function useBrandingGuide() {
  const original   = useStyling().guide
  const {branding} = useBranding()

  return React.useMemo((): BrandingGuide => {
    if (branding == null) {
      return original
    } else {
      return BrandingGuide.deserialize(branding)
    }
  }, [branding, original])
}

export type UseComponentBrandingHook<B extends ComponentBrandingBase<B>> = [
  /* branding: */ B,
  /* variant:  */ VariantFlags,
  /* size:     */ Size,
  /* spec:     */ SpecOf<B> | null,
]
export type UseComponentBrandingEmptyHook = [null, {}, Size, null]

export type VariantFlags = Record<string, boolean>

type SpecOf<B extends ComponentBrandingBase<any>> = B extends ComponentBrandingBase<infer S> ? S : never


export const defaultSize = {
  width:  160,
  height: 120,
}