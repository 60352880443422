import React from 'react'
import { UploadProgress } from '~/stores'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, ProgressBar, Spinner, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  progress:       UploadProgress
  requestCancel?: () => any
}

const ScriptMessageUploadPlaceholder = memo('ScriptMessageUploadPlaceholder', (props: Props) => {

  const {progress, requestCancel} = props

  const {t}      = useResourceTranslation('script-messages')
  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    if (progress.total == null) { return null }

    return (
      <VBox>
        <HBox justify='left' gap={layout.padding.inline.m}>
          <Spinner size={12}/>

          <VBox flex='shrink' gap={layout.padding.inline.xs}>
            <ProgressBar
              progress={progress.sent / progress.total}
              barColor={colors.semantic.primary}
            />
            <Label caption tiny dim>
              {t(progress.sent === progress.total ? 'upload.processing' : 'upload.uploading')}
            </Label>
          </VBox>

          {requestCancel && (
            <ClearButton
              icon='cross-circle'
              onTap={requestCancel}
            />
          )}
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default ScriptMessageUploadPlaceholder