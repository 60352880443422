import React from 'react'
import { colors, layout, createUseStyles, fonts } from '~/ui/styling'
import { Tappable, AutoGrid, Label, Center, Panel } from '~/ui/components'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { yearCellSize, width, height, padding, selectionCircleSize, yearGridCount } from './layout'

interface Props {
  currentYear: number
  goToYear:    (year: number) => any
  enabled:     boolean
}

const YearGrid = memo('YearGrid', (props: Props) => {

  const {currentYear, goToYear, enabled} = props
  const thisYear = DateTime.local().year

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false} classNames={$.yearGrid} align='center' justify='middle'>
        <AutoGrid
          rows={yearGridCount}
          columns={yearGridCount}
          children={renderYearCell}
        />
      </Panel>
    )
  }

  const renderYearCell = React.useCallback((row: number, column: number) => {
    const year    = thisYear + (row - (yearGridCount / 2)) * yearGridCount + (column - (yearGridCount / 2))
    const current = year === currentYear
    const onTap   = goToYear.bind(null, year)

    const classNames = [
      $.cell,
      {current},
    ]

    return (
      <Tappable key={year} classNames={classNames} onTap={onTap} enabled={enabled}>
        <Center flex>
          <Label>{year}</Label>
        </Center>
      </Tappable>

    )
  }, [$.cell, currentYear, enabled, goToYear, thisYear])

  return render()

})

export default YearGrid

const useStyles = createUseStyles(theme => ({
  yearGrid: {
    width,
    height,
    padding,
  },

  cell: {
    position:  'relative',
    width:     yearCellSize,
    height:    yearCellSize,
    textAlign: 'center',

    ...fonts.responsiveFontStyle(theme.fonts.small),

    ...layout.flex.center,

    '& > *': { position: 'relative' },

    borderRadius: yearCellSize / 2,

    '&::before': {
      content: '""',
      display: 'none',

      position:     'absolute',
      width:        selectionCircleSize.year,
      height:       selectionCircleSize.year,
      left:         (yearCellSize - selectionCircleSize.year) / 2,
      top:          (yearCellSize - selectionCircleSize.year) / 2,
      borderRadius: selectionCircleSize.year / 2,
      background:   theme.semantic.primary.alpha(0.2),
    },

    '&:hover::before': {
      display: 'block',
    },
    '&.current': {
      '&::before': {
        display:    'block',
        background: theme.semantic.primary,
      },
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.primary)),
    },
  },
}))