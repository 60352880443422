import React from 'react'
import { ModelOfType } from '~/models'
import { memo } from '~/ui/component'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { ResourceField as ResourceFieldComponent } from '~/ui/resources/components'
import { ResourceField } from '../types'

export interface Props {
  field: ResourceField
  bind:  FormFieldBindProps<string>
}

const ResourceAllowance = memo('ResourceAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceFieldComponent
        {...bind as any}
        Model={ModelOfType(field.resourceType)}
        multi={field.multi}
        filters={field.filters}
      />
    )
  }

  return render()

})

export default ResourceAllowance