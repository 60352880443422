import { produce } from 'immer'
import { clamp, some } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import * as UUID from 'uuid'
import { CalendarPlanClipboardItem } from '~/clipboard'
import { CalendarDay, CalendarItem, CalendarPlan, DaySelector, TimeOfDay } from '~/models'
import { CalendarItemDetail } from '~/stores'
import CalendarPlannerService from './CalendarPlannerService'

export default class CalendarPlanner {

  constructor(
    public readonly service: CalendarPlannerService,
  ) {
    makeObservable(this)
  }

  @observable
  public plan: CalendarPlan | null = null

  @action
  public setPlan(plan: CalendarPlan | null) {
    this.plan = plan
  }

  //------
  // Day management

  public async addDay(selector: DaySelector) {
    if (this.plan == null) { return }

    const uuid = UUID.v4()
    return await this.service.update({
      days: [
        ...this.plan.days,
        {uuid, selector, items: []},
      ],
    })
  }

  public async updateDay(uuid: string, update: (day: CalendarDay) => CalendarDay) {
    if (this.plan == null) { return }

    return await this.service.update({
      days: this.plan.days.map(day => {
        if (day.uuid !== uuid) { return day }
        return update(day)
      }),
    })
  }

  public async removeDay(uuid: string) {
    if (this.plan == null) { return }

    return await this.service.update({
      days: this.plan.days.filter(day => day.uuid !== uuid),
    })
  }

  //------
  // Items

  public itemsBetweenTimes(dayUUID: string, start: TimeOfDay, end: TimeOfDay) {
    if (this.plan == null) { return [] }

    const day = this.plan.findDay(dayUUID)
    if (day == null) { return [] }

    const items = day.items.filter(it => {
      if (it.time.minutes + 60 < start.minutes) { return false }
      if (it.time.minutes > end.minutes) { return false }
      return true
    })

    items.sort((a, b) => a.time.minutes - b.time.minutes)
    return items
  }

  public async addItem(dayUUID: string, item: Partial<CalendarItem>) {
    if (this.plan == null) { return }

    const days = this.plan.days.map(day => {
      if (day.uuid !== dayUUID) { return day }
      return {
        ...day,
        items: [...day.items, item as CalendarItem],
      }
    })
    return await this.service.update({days})
  }

  public async removeItems(uuids: string[]) {
    if (this.plan == null) { return }

    const days = this.plan.days.map(day => {
      const items = day.items.filter(it => !uuids.includes(it.uuid))
      if (items.length === day.items.length) {
        return day
      } else {
        return {...day, items}
      }
    })
    return await this.service.update({days})
  }

  public async updateItem(uuid: string, update: (item: CalendarItem) => CalendarItem) {
    const data = this.modifyItems([uuid], update)
    return await this.service.update(data)
  }

  private modifyItems(uuids: string[], modify: (item: CalendarItem) => CalendarItem) {
    if (this.plan == null) { return {days: []} }

    const days = this.plan.days.map(day => {
      let found = false
      const items = day.items.map(item => {
        if (uuids.includes(item.uuid)) {
          found = true
          return modify(item)
        } else {
          return item
        }
      })

      return found ? {...day, items} : day
    })

    return {days}
  }

  public dayIndexOfItem(uuid: string) {
    if (this.plan == null) { return -1 }
    return this.plan.days.findIndex(day => some(day.items, it => it.uuid === uuid))
  }

  //------
  // Editing item

  @observable
  public editingItemUUID: string | null = null

  @observable
  public itemDetail: CalendarItemDetail = 'time'

  @action
  public editItem(uuid: string, detail: CalendarItemDetail) {
    this.editingItemUUID = uuid
    this.itemDetail = detail
  }

  @action
  public stopEditingItem() {
    this.editingItemUUID = null
  }

  //------
  // Copy / paste

  public getClipboardItems(uuids: string[]): CalendarPlanClipboardItem[] | null {
    const items = uuids.map(uuid => {
      if (this.plan == null) { return null }

      const item     = this.plan?.findItem(uuid)
      const dayIndex = this.dayIndexOfItem(uuid)
      if (item == null || dayIndex < 0) { return null }

      return {
        day:  this.plan.days[dayIndex].uuid,
        item: item,
      }
    }).filter(Boolean) as CalendarPlanClipboardItem[]
    if (items.length === 0) { return null }

    return items
  }

  public async pasteClipboardItems(items: CalendarPlanClipboardItem[], targetDayUUID?: string) {
    const uuids: string[] = []
    const update = produce({
      days: this.plan?.days ?? [],
    }, plan => {
      for (const item of items) {
        const dayUUID = targetDayUUID ?? item.day
        const day     = plan.days.find(day => day.uuid === dayUUID)
        if (day == null) { continue }

        const uuid = UUID.v4()
        day.items.push({...item.item, uuid} as CalendarItem)
        uuids.push(uuid)
      }
    })

    const result = await this.service.update(update)
    return result.status === 'ok' ? uuids : []
  }

  //------
  // Moving

  @observable
  public moveMode: 'copy' | 'move' = 'move'

  @action
  public setMoveMode(mode: 'copy' | 'move') {
    this.moveMode = mode
  }

  @observable
  private movedItems = new Map<string, MovedItem>()

  public isMoving(uuid: string) {
    return this.movedItems.has(uuid)
  }

  /**
   * Gets the currently visible items for the given day, taking into account any move operation currently
   * going on.
   *
   * @param day The day to get items for.
   */
  public visibleItemsForDay(day: CalendarDay): CalendarItem[] {
    if (this.plan == null) { return day.items }

    let items: CalendarItem[] = [...day.items]

    // When moving, remove any items that have been moved to another day.
    if (this.moveMode === 'move') {
      items = items.filter(item => {
        const dayUUID = this.movedItems.get(item.uuid)?.dayUUID
        return dayUUID == null || dayUUID === day.uuid
      })
    }

    // Add items that are currently being moved or copied to this day from another day.
    for (const [uuid, {uuidForCopy, dayUUID}] of this.movedItems) {
      if (dayUUID !== day.uuid) { continue }

      // If the item is moved and its already here, leave it.
      const existingIndex = items.findIndex(it => it.uuid === uuid)
      if (this.moveMode === 'move' && existingIndex >= 0) { continue }

      const copyIndex = items.findIndex(it => it.uuid === uuidForCopy)
      if (this.moveMode === 'copy' && copyIndex >= 0) { continue }

      // Find the item, and add its copy.
      const item = this.plan.findItem(uuid)
      if (item == null) { continue }

      items.push({
        ...item,
        uuid: this.moveMode === 'copy' ? uuidForCopy : uuid,
      })
    }

    // Update the time for any moved items. Be sure to move the *copied* item instead of the original
    // item when copying.
    for (const [originalUUID, {uuidForCopy, time}] of this.movedItems) {
      const uuid  = this.moveMode === 'move' ? originalUUID : uuidForCopy
      const index = items.findIndex(it => it.uuid === uuid)
      if (index < 0) { continue }

      items[index] = {...items[index], time}
    }

    return items
  }

  public moveItemsBy(items: CalendarItem[], deltaMinutes: number, deltaDay: number, options: MoveItemsOptions = {}) {
    if (this.plan == null) { return }

    for (const item of items) {
      let minutes = item.time.minutes + deltaMinutes
      if (options.roundTo != null) {
        minutes = Math.round(minutes / options.roundTo) * options.roundTo
      }

      const dayIndex = clamp(this.dayIndexOfItem(item.uuid) + deltaDay, 0, this.plan.days.length)
      const dayUUID  = this.plan.days[dayIndex].uuid

      const existing    = this.movedItems.get(item.uuid)
      const uuidForCopy = existing?.uuidForCopy ?? UUID.v4()
      const time        = new TimeOfDay(minutes)

      this.movedItems.set(item.uuid, {
        uuidForCopy,
        dayUUID,
        time,
      })
    }
  }

  public async commitMoveOrCopy() {
    const {plan} = this
    if (plan == null) { return }

    const uuids: string[] = []

    const update = produce({
      days: this.plan?.days ?? [],
    }, plan => {
      const removeItem = (uuid: string) => {
        for (const day of plan.days) {
          const index = day.items.findIndex(it => it.uuid === uuid)
          if (index < 0) { continue }

          day.items.splice(index, 1)
        }
      }
      const addItemToDay = (item: CalendarItem, dayUUID: string, nextUUID?: string) => {
        const nextItem = nextUUID == null ? item : {...item, uuid: nextUUID}
        const day      = plan.days.find(day => day.uuid === dayUUID)
        day?.items.push(nextItem)
        return nextItem
      }

      for (const [uuid, {uuidForCopy, dayUUID, time}] of this.movedItems) {
        let item = this.plan?.findItem(uuid)
        if (item == null) { continue }

        if (this.moveMode === 'move') {
          removeItem(uuid)
          addItemToDay(item, dayUUID)
        } else {
          item = addItemToDay(item, dayUUID, uuidForCopy)
        }

        item.time = time
        uuids.push(item.uuid)
      }
    })

    const result = await this.service.update(update)
    if (result.status === 'ok') {
      this.rollbackMove()
      return uuids
    } else {
      return null
    }
  }

  public rollbackMove() {
    this.movedItems.clear()
  }

  //------
  // Who-is-here

  @observable
  public focusedInsightsDayUUID: string | null = null

  @action
  public setFocusedInsightsDayUUID(uuid: string | null) {
    this.focusedInsightsDayUUID = uuid
  }

  //------
  // Actions

  @computed
  public get availableActionCategories() {
    return this.service.actions
  }

  public getAction(name: string) {
    for (const {actions} of this.service.actions) {
      for (const action of actions) {
        if (action.name === name) {
          return action
        }
      }
    }

    return null
  }

  //------
  // Insights & runtime

  @computed
  public get insights() {
    return this.service.insights ?? {
      summaries:    [],
      participants: [],
    }
  }

}

export interface MoveItemsOptions {
  roundTo?: number
}

export interface MovedItem {
  uuidForCopy: string
  dayUUID:     string
  time:        TimeOfDay
}