import React from 'react'
import { Model, ModelClass } from '~/models'
import { FormDialog, FormDialogProps } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import ResourceFormModel from './ResourceFormModel'

export interface Props<M extends Model> extends Omit<FormDialogProps<ResourceFormModel<M>>, 'model' | 'initialData'> {
  Model:        ModelClass<M>
  model?:       M | null
  formModel?:   ResourceFormModel<M>
  initialData?: AnyObject

  children?: React.ReactNode
}

const ResourceFormDialog = <M extends Model>(props: Props<M>) => {

  const {Model, model = null, formModel: props_formModel, children, ...rest} = props

  const formModel = React.useMemo(
    () => props_formModel != null ? props_formModel : new ResourceFormModel(Model, model),
    [Model, model, props_formModel],
  )

  const {t} = useResourceTranslation(Model.resourceType)

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType={Model.resourceType}>
        <FormDialog
          model={formModel}
          icon={formModel?.model?.$icon ?? Model.$icon}
          title={formModel?.model != null ? t('edit') : t('create')}
          children={children}
          {...rest}
        />
      </ResourceTypeProvider>
    )
  }

  return render()

}

export default ResourceFormDialog