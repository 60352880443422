import React from 'react'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { DynamicField } from '../types'
// @index: import ${variable} from ${relpath}
import BooleanAllowance from './BooleanAllowance'
import ChoiceAllowance from './ChoiceAllowance'
import CollectionAllowance from './CollectionAllowance'
import DateAllowance from './DateAllowance'
import NumberAllowance from './NumberAllowance'
import ResourceAllowance from './ResourceAllowance'
import StringAllowance from './StringAllowance'
import TagAllowance from './TagAllowance'
import TimeIntervalAllowance from './TimeIntervalAllowance'
import VariantAllowance from './VariantAllowance'

// /index

export default function renderDynamicFieldAllowance(field: DynamicField, bind: FormFieldBindProps<any>) {
  switch (field.type) {
    case 'string':
      return <StringAllowance field={field} bind={bind}/>
    case 'choice':
      return <ChoiceAllowance field={field} bind={bind}/>
    case 'resource':
      return <ResourceAllowance field={field} bind={bind}/>
    case 'number':
      return <NumberAllowance field={field} bind={bind}/>
    case 'boolean':
      return <BooleanAllowance field={field} bind={bind}/>
    case 'date':
      return <DateAllowance field={field} bind={bind}/>
    case 'time-interval':
      return <TimeIntervalAllowance field={field} bind={bind}/>
    case 'tag':
      return <TagAllowance field={field} bind={bind}/>
    case 'variant':
      return <VariantAllowance field={field} bind={bind}/>
    case 'collection':
      return <CollectionAllowance field={field} bind={bind}/>
    }
}