import React from 'react'
import { startCase, upperFirst } from 'lodash'
import { SystemNotification } from '~/models'
import { memo } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {
  notification: SystemNotification
}

const NotificationInfoList = memo('NotificationInfoList', (props: Props) => {

  const {notification} = props

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {Object.entries(notification.info).map(([key, value]) => (
          renderInfoItem(key, value)),
        )}
      </VBox>
    )
  }

  function renderInfoItem(key: string, value: any) {
    if (notification == null) { return null }

    return (
      <VBox key={key} gap={layout.padding.inline.s}>
        <FormFieldHeader
          caption={upperFirst(startCase(key).toLocaleLowerCase())}
        />
        <Label mono truncate={false}>
          {typeof value === 'string' ? value : JSON.stringify(value)}
        </Label>
      </VBox>
    )
  }


  return render()

})

export default NotificationInfoList