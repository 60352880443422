import React from 'react'
import { useLocation } from 'react-router-dom'
import * as H from 'history'
import { usePrevious } from '~/ui/hooks'

export function useLocationChange(listener: (location: H.Location) => any) {
  const location     = useLocation()
  const prevPath     = usePrevious(location.pathname)
  const prevListener = usePrevious(listener)

  React.useEffect(() => {
    const pathChanged = prevPath !== location.pathname
    const listenerChanged = prevListener !== listener

    if (pathChanged || listenerChanged) {
      listener(location)
    }
  }, [listener, location, prevListener, prevPath])
}