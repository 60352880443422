import { Branding } from './Branding'
import { Model, resource } from './Model'
import { Language } from './Translations'
import { Ref } from './types'

@resource<ClientApp>('client-apps', {
  icon:    () => 'iphone',
  caption: app => app.id,
  scopedToModule: false,
})
export class ClientApp extends Model {

  public android!: {
    applicationID: string
  }

  public ios!: {
    bundleID:  string
  }

  public urlScheme!: string

  public domain?: string | null

  public branding?: Ref<Branding>

  public auth!: {
    emailAndPincode: boolean
    oAuthProviders:  OAuthProvider[]
  }

}

export interface OAuthProvider {
  name:    string
  enabled: boolean
  config:  {
    displayLogo: boolean
    caption:     Record<Language, string> | null
  }
}