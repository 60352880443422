import React from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useSize } from 'react-measure'
import { DraggableItemType, DraggableWidgetItem } from '~/dnd'
import { Widget } from '~/models'
import { observer } from '~/ui/component'
import {
  Dimple,
  Empty,
  EmptyOrFetching,
  GridList,
  HBox,
  Label,
  ListSection,
  SearchField,
  SVG,
  VBox,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { assignRef } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'
import { useDashboardContext } from './DashboardContext'
import WidgetCatalogTile from './WidgetCatalogTile'

export interface Props {
  categories: Record<string, {icon: SVGName, caption: string}>
}

const WidgetCatalog = observer('WidgetCatalog', (props: Props) => {

  const {categories} = props

  const [t]      = useTranslation('analytics')
  const {colors} = useStyling()

  const [search, setSearch] = React.useState<string | null>(null)
  const endpoint  = useModelEndpoint(Widget, {search})

  const catalogRef = React.useRef<HTMLDivElement>(null)
  const [width, setWidth] = React.useState<number | null>(null)

  useSize(catalogRef, size => { setWidth(size.width) })

  const [showRemove, setShowRemove] = React.useState<boolean>(false)

  const {removeWidget} = useDashboardContext()

  const sections = React.useMemo(() => {
    const sections: ListSection<Widget>[] = []

    for (const widget of endpoint.data) {
      const [categoryName] = widget.name.split(':')

      let section = sections.find(it => it.name === categoryName)
      if (section == null) {
        sections.push(section = {
          name:  categoryName,
          items: [],
        })
      }

      section.items.push(widget)
    }

    return sections
  }, [endpoint.data])

  //------
  // Drag & drop

  const dropZoneID = React.useMemo(() => Symbol('WidgetCatalog'), [])
  const [connectDropZone] = useDrop<DraggableWidgetItem>({
    id:     dropZoneID,
    accept: [DraggableItemType.WIDGET],

    enter: item => {
      if (item.alotment != null) {
        setShowRemove(true)
      }
    },
    leave: () => {
      setShowRemove(false)
    },
    end: () => {
      setShowRemove(false)
    },

    drop: item => {
      if (item.alotment == null) { return }
      removeWidget(item.alotment.uuid)
    },
  })

  const connect = React.useCallback((element: HTMLDivElement) => {
    connectDropZone(element)
    assignRef(catalogRef, element)
  }, [connectDropZone])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.widgetCatalog} flex ref={connect}>
        {showRemove ? (
          renderRemoveNotice()
        ) : (
          <GridList
            contentPadding={layout.padding.m}
            columns={width == null || width < twoColumnWidth ? 1 : 2}
            data={sections}
            itemGap={layout.padding.inline.m}
            renderSectionHeader={renderSectionHeader}
            renderSectionFooter={renderSectionFooter}
            renderCell={renderWidgetTile}
            EmptyComponent={renderEmpty}
            scrollable
          />
        )}
        <Dimple horizontal/>
        {renderFooter()}
      </VBox>
    )
  }

  function renderRemoveNotice() {
    return (
      <ThemeProvider contrast={colors.semantic.negative}>
        <Empty
          classNames={$.removeNotice}
          {...t('widget_catalog.remove_notice')}
          icon='trash'
          flex
          dim
        />
      </ThemeProvider>
    )
  }

  function renderFooter() {
    return (
      <VBox classNames={$.footer}>
        <SearchField
          onSearch={setSearch}
          classNames={$.search}
          inputStyle='dark'
          enabled={!showRemove}
        />
      </VBox>
    )
  }

  const renderSectionHeader = React.useCallback((section: ListSection<Widget>) => {
    const category = categories[section.name]

    return (
      <HBox classNames={$.sectionHeader} gap={layout.padding.inline.m}>
        {category?.icon != null && (
          <SVG
            name={category.icon}
            size={layout.icon.s}
            dim
          />
        )}
        <Label flex small dim caption>
          {category?.caption ?? section.name}
        </Label>
      </HBox>
    )
  }, [$.sectionHeader, categories])

  const renderSectionFooter = React.useCallback(() => {
    return <div classNames={$.sectionFooter}/>
  }, [$.sectionFooter])

  const renderWidgetTile = React.useCallback((widget: Widget) => {
    return (
      <WidgetCatalogTile
        widget={widget}
      />
    )
  }, [])

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        status={endpoint.fetchStatus}
        {...t('widget_catalog.empty')}
        flex
      />
    )
  }, [endpoint.fetchStatus, t])

  return render()

})

export default WidgetCatalog

export const twoColumnWidth = 460

const useStyles = createUseStyles(theme => ({
  widgetCatalog: {
    background: theme.bg.semi,
    boxShadow:  [0, -2, 4, 0, shadows.shadowColor],
  },

  sectionHeader: {
    padding: [layout.padding.inline.s, 0],
  },

  sectionFooter: {
    height: layout.padding.inline.l,
  },

  footer: {
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
    })),
  },

  search: {
  },

  removeNotice: {
    background: theme.semantic.negative.alpha(0.4),
  },
}))