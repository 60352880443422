import React from 'react'
import { memo } from '~/ui/component'
import DateTimeComponentsField, { Props as DateTimeComponentsFieldProps, DateTimeComponentsFieldTemplate, DateTimeComponent } from './DateTimeComponentsField'

export interface Props extends Omit<DateTimeComponentsFieldProps, 'template'> {
  order?:     'YMD' | 'DMY' | 'MDY'
  separator?: string
}

const TimeField = memo('TimeField', (props: Props) => {

  const {
    order     = 'DMY',
    separator = '-',
    picker    = true,
    ...rest
  } = props

  const template = React.useMemo((): DateTimeComponentsFieldTemplate => {
    const components = order.split('').map((comp): DateTimeComponent => {
      switch (comp) {
        case 'D': return 'day'
        case 'M': return 'month'
        case 'Y': return 'year'
        default:  return 'year'
      }
    })

    return [
      {component: components[0]},
      {separator},
      {component: components[1]},
      {separator},
      {component: components[2]},
    ]
  }, [order, separator])

  return (
    <DateTimeComponentsField
      {...rest}
      template={template}
      picker={picker}
    />
  )

})

export default TimeField