import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Module, PlannerType } from '~/models'
import { observer } from '~/ui/component'
import { Center, Dimple, Label, Scroller, SelectField, Spinner, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormFieldHeader, InlineForm, InlineFormField } from '~/ui/form'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { createUseStyles, layout } from '~/ui/styling'
import ModuleForm from '../ModuleForm'
import ModuleFormModel from '../ModuleFormModel'
import ModuleFeatureList from './ModuleFeatureList'
import ModuleHeader from './ModuleHeader'

export type Props = RouteComponentProps<ResourceDetailParams>

const ModuleScreen = observer('ModuleScreen', (props: Props) => {

  const {t, field} = useResourceTranslation('modules')

  const moduleID = props.match.params.id
  const [module] = useModelDocumentData(Module, moduleID, {fetch: 'never'})

  const formModel = React.useMemo(
    () => module == null ? null : new ModuleFormModel(module),
    [module],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceDetailScreen
        Model={Module}
        id={moduleID}

        EditFormComponent={ModuleForm}

        renderInfo={renderInfo}
        renderBody={renderBody}
        allowRemove={module != null && !module.main}
        removeConfirmText={module => module.name}
      />
    )
  }

  //------
  // Body

  function renderBody() {
    if (module == null) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }

    return (
      <VBox flex gap={layout.padding.m}>
        <ModuleHeader module={module}/>
        <Dimple horizontal/>
        {renderFeaturesForm()}
      </VBox>
    )
  }

  function renderFeaturesForm() {
    if (formModel == null) { return null }

    return (
      <VBox flex>
        <InlineForm model={formModel} autoSubmit flex>
          <Scroller flex contentClassNames={$.scrollerContent}>
            <VBox flex='grow' gap={layout.padding.m}>
              <InlineFormField
                name='plannerType'
                renderView={type => <Label>{t(`planner_types.${type}.caption`)}</Label>}
                renderEdit={bind => <SelectField {...bind} choices={plannerTypeChoices}/>}
              />
              <VBox flex='grow' gap={layout.padding.inline.s}>
                <FormFieldHeader {...field('features')}/>
                <ModuleFeatureList/>
              </VBox>
            </VBox>
          </Scroller>
        </InlineForm>
      </VBox>
    )
  }

  const plannerTypeChoices = React.useMemo(() => {
    const choices: Choice[] = []

    for (const type of PlannerType.all) {
      choices.push({
        value:   type,
        caption: t(`planner_types.${type}.caption`),
      })
    }

    choices.push({
      value:   '$none',
      caption: t('planner_types.$none.caption'),
    })

    return choices
  }, [t])

  //------
  // Right bar

  function renderInfo(module: Module) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: module.createdAt})}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  return render()

})

export default React.memo(ModuleScreen)

const useStyles = createUseStyles({
  scrollerContent: {
    padding:  2,
    maxWidth: 480,
  },
})