import React from 'react'
import { Model, ModelClass } from '~/models'
import { InlineForm, InlineFormProps } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import ResourceFormModel from './ResourceFormModel'

export interface Props<M extends Model> extends Omit<InlineFormProps<any>, 'model'> {
  Model:      ModelClass<M>
  model?:     M | null
  formModel?: ResourceFormModel<M>
}

const InlineResourceForm = <M extends Model>(props: Props<M>) => {

  const {Model, model = null, formModel: props_formModel, ...rest} = props

  const formModel = React.useMemo(
    () => props_formModel ?? new ResourceFormModel(Model, model),
    [Model, model, props_formModel],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType={Model.resourceType}>
        <InlineForm
          model={formModel}
          {...rest}
        />
      </ResourceTypeProvider>
    )
  }

  return render()

}

export default InlineResourceForm