import React from 'react'
import { ComponentShapeType } from '~/models'
import { observer } from '~/ui/component'
import { HBox, NumberField, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import BrandingBackgroundField from '../assets/BrandingBackgroundField'
import BrandingBorderField from '../assets/BrandingBorderField'

export interface Props {
  borderField?: boolean
  shapeField?:  boolean
  depthField?:  boolean
  prefix?:      string
}

const ComponentBrandingFields = observer('ComponentBrandingFields', (props: Props) => {

  const {borderField = true, shapeField = true, depthField = true, prefix = ''} = props
  const [shapeType]  = useFormField<ComponentShapeType>('shapeType')

  const {t} = useResourceTranslation('brandings')

  //------
  // Data

  const shapeTypeChoices = React.useMemo((): Choice<ComponentShapeType>[] => [
    {value: 'rectangle', caption: t('shapes.rectangle')},
    {value: 'oval', caption: t('shapes.oval')},
    {value: 'rounded', caption: t('shapes.rounded')},
  ], [t])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        {renderBackgroundFields()}
        {renderBorderFields()}
        {renderRestFields()}
      </VBox>
    )
  }

  function renderBackgroundFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name={`${prefix}background`}>
          {bind => <BrandingBackgroundField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderBorderFields() {
    if (borderField === false) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <FormField name={`${prefix}border`}>
          {bind => <BrandingBorderField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderRestFields() {
    return (
      <VBox gap={layout.padding.s}>
        {shapeField && renderShapeField()}
        {depthField && (
          <FormField name={`${prefix}depth`}>
            {bind => <NumberField {...bind} minimum={0} maximum={5} step={1}/>}
          </FormField>
        )}
      </VBox>
    )
  }

  function renderShapeField() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex>
          <FormField name={`${prefix}shapeType`}>
            {bind => <SelectField {...bind} choices={shapeTypeChoices}/>}
          </FormField>
        </VBox>
        {shapeType === 'rounded' && (
          <VBox flex>
            <FormField name={`${prefix}shapeRadius`}>
              {bind => <NumberField {...bind} minimum={0} step={1}/>}
            </FormField>
          </VBox>
        )}
      </HBox>

    )
  }

  return render()

})

export default ComponentBrandingFields

