import { AnswerScopeLinkage } from './explore/AnswerScopeField'

export function pathForScope(scope: AnswerScopeLinkage) {
  let path = '/answers'
  if (scope == null) {return path}

  if (scope === '$all') {
    path += '/all'
  } else {
    path += `/${scope.type}/${scope.id}`
  }

  return path
}

export function parseSelectedQuestionsParam(param: string | null): Array<string> {
  return param?.split(',') ?? []
}

export function translationKeyForResultStyleType(type: string) {
  return type.replace('-', '_')
}