import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { appLinksStore, AppLinkTarget } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  HBox,
  PopupMenu,
  PopupMenuHeader,
  PopupMenuItem,
  Spinner,
  SVG,
  Tappable,
  Tooltip,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, presets } from '~/ui/styling'

export interface Props {}

const AppLinksMenu = observer('AppLinksMenu', (props: Props) => {

  const [t] = useTranslation('app')

  const availableDevices  = appLinksStore.availableDevices
  const selectedTarget    = appLinksStore.defaultTarget

  const iconForTarget = React.useCallback((target: AppLinkTarget): SVGName => {
    switch (target) {
      case '$web':     return 'web'
      case '$preview': return 'eye'
      default:         return 'iphone'
    }
  }, [])

  const items = React.useMemo(() => {
    const items: PopupMenuItem<AppLinkTarget>[] = []

    const addTarget = (target: AppLinkTarget, caption: string) => {
      items.push({
        value:   target,
        caption: caption,
        icon:    iconForTarget(target),
        checked: target === selectedTarget,
      })
    }

    if (availableDevices == null) {
      items.push({
        section: <Center><Spinner dim size={12}/></Center>,
      })

    } else if (availableDevices.length > 0) {
      for (const device of availableDevices) {
        addTarget(`${device.platform}:${device.udid}`, `${device.name} (${device.platform})`)
      }
      items.push({section: '-'})
    }

    addTarget('$web', t(`applinks.web`))
    addTarget('$preview', t(`applinks.preview`))

    return items
  }, [availableDevices, iconForTarget, selectedTarget, t])

  const loadDevices = React.useCallback(() => {
    appLinksStore.loadDevices()
  }, [])

  const selectTarget = React.useCallback((target: AppLinkTarget) => {
    appLinksStore.setDefaultTarget(target)
  }, [])

  //------
  // Most recent app link

  const timer = useTimer()
  const [recentlyOpenedAppLink, setRecentlyOpenedAppLink] = React.useState<{href: string, success: boolean} | null>(null)
  const [tooltipShown, setTooltipShown] = React.useState<boolean>(false)

  React.useEffect(() => {
    return appLinksStore.addAppLinkListener((href, success) => {
      timer.clearAll()
      setRecentlyOpenedAppLink({href, success})
      setTooltipShown(true)

      timer.setTimeout(() => {
        setTooltipShown(false)
      }, 2000)
    })
  }, [timer])


  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tooltip renderTooltip={renderRecentlyOpenedApplinkTooltip} open={tooltipShown}>
        <PopupMenu items={items} onValueSelect={selectTarget} onWillOpen={loadDevices} header={renderHeader()}>
          {open => (
            <Tappable onTap={open} showFocus={true} classNames={$.AppLinksMenu}>
              <HBox gap={layout.padding.inline.m}>
                <SVG
                  name='link'
                  size={layout.icon.m}
                />
                <SVG
                  name={iconForTarget(selectedTarget)}
                  size={layout.icon.xs}
                />
              </HBox>
            </Tappable>
          )}
        </PopupMenu>
      </Tooltip>
    )
  }

  function renderRecentlyOpenedApplinkTooltip() {
    if (recentlyOpenedAppLink == null) { return null }

    const {href, success} = recentlyOpenedAppLink
    if (success) {
      return t('applinks.opened', {href})
    } else {
      return t('applinks.failed', {href})
    }
  }

  function renderHeader() {
    return (
      <PopupMenuHeader
        caption={t('applinks.caption')}
      />
    )
  }

  return render()

})

export default AppLinksMenu

const useStyles = createUseStyles(theme => ({
  AppLinksMenu: {
    position:     'relative',
    background:   theme.bg.hover,
    borderRadius: 1000,

    padding: [
      layout.padding.inline.m,
      layout.padding.inline.m,
    ],

    ...presets.overlayAfter({
      borderRadius: 1000,
    }),

    '&:hover::after': {
      background: theme.bg.hover,
    },
    '&:active::after': {
      background: theme.bg.active,
    },
  },
}))