import React from 'react'
import { range } from 'lodash'
import { DateTime } from 'luxon'
import { observer } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { dayHeight, hourTop } from './layout'

export interface Props {
  transform: string
}

const CalendarDayTimelineLayer = observer('CalendarDayTimelineLayer', (props: Props) => {

  const {transform} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.calendarDayTimelineLayer}>
        <div classNames={$.layer} style={{transform}}>
          {range(0, 25).map(renderHour)}
        </div>
      </div>
    )
  }

  function renderHour(hour: number) {
    const top  = hourTop(hour)
    const text = DateTime.fromObject({hour, minute: 0}).toFormat('H:mm')

    return (
      <HBox key={hour} classNames={$.hour} style={{top}} gap={layout.padding.inline.m}>
        <Label tiny align='right' classNames={$.hourLabel}>
          {text}
        </Label>
        <VBox flex classNames={$.hourLine}/>
      </HBox>
    )
  }

  return render()

})

export default CalendarDayTimelineLayer

export const labelWidth  = 40
export const labelHeight = 12

const useStyles = createUseStyles(theme => ({
  calendarDayTimelineLayer: {
    ...layout.overlay,
    overflow:      'hidden',
    pointerEvents: 'none',
  },

  layer: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      0,
    height:   dayHeight(),

    willChange:      'transform',
    transformOrigin: [0, 0],
  },

  hour: {
    position: 'absolute',
    left:     0,
    right:    0,
    height:   0,
  },

  hourLabel: {
    height: labelHeight,
    width:  labelWidth,
  },

  hourLine: {
    height:     1,
    background: theme.fg.dim.alpha(0.1),
  },
}))