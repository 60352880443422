import Color from 'color'
import { ReviewStatus } from '~/models'
import { Theme } from '~/ui/styling/Theme'

export function colorForStatus(status: ReviewStatus, theme: Theme): Color {
  switch (status) {
    case 'pending':  return theme.colors.fg.dark.dimmer
    case 'review':   return theme.semantic.secondary
    case 'reviewed': return theme.semantic.positive
    case 'done':     return theme.semantic.positive
  }
}