import React from 'react'
import { QAndA } from '~/models'
import { memo } from '~/ui/component'
import { HBox, TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import { CustomImageField } from '../fields'
import TagsField from '../tags/TagsField'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  model?: QAndA | null
}

const QAndAForm = memo('QAndAForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={QAndA}
        model={model}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>

        <HBox align='top' justify='space-between' gap={layout.padding.m}>
          <VBox gap={layout.padding.s} flex>
            <FormField name='name' required>
              {bind => <TextField {...bind} />}
            </FormField>
            <FormField name='tags'>
              {bind => <TagsField Model={QAndA} {...bind}/>}
            </FormField>
          </VBox>

          <FormField name='image' renderAsLabel={false}>
            {bind => (
              <CustomImageField
                {...bind}
                size={{width: 116, height: 116}}
              />
            )}
          </FormField>
        </HBox>

      </VBox>
    )
  }

  return render()

})

export default QAndAForm