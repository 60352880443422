import React from 'react'
import { Role, User } from '~/models'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { SelectField, SwitchField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog, ResourceFormModel } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: (result: SubmitResult) => any

  model: User
}

const UserForm = observer('UserForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model} = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new ResourceFormModel(User, model),
    [model],
  )

  const me = authenticationStore.user
  const roleChoices = React.useMemo((): Choice[] => me == null ? (
    []
  ) : Role.all(me).filter(it => it !== 'super').map(role => ({
    value:   role,
    caption: t(`roles.${role}.title`),
  })), [me, t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<User>
        Model={User}
        formModel={formModel}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    if (me == null) { return null }
    return (
      <VBox gap={layout.padding.m}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='email' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        {model.role !== 'super' && model.id !== me?.id && (
          <FormField name='role' required>
            {bind => <SelectField {...bind} choices={roleChoices}/>}
          </FormField>
        )}
        <FormField name='bewizr' required>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default UserForm