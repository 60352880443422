import React from 'react'
import { useDrop } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useBoundingRectangle } from 'react-measure'
import { omit, range } from 'lodash'
import config from '~/config'
import { DraggableItemType, DraggableWidgetItem } from '~/dnd'
import { Widget, WidgetAlotment } from '~/models'
import { authenticationStore, WidgetState } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Empty,
  EmptyOrFetching,
  Grid,
  Panel,
  SidePanels,
  Spinner,
  VBox,
} from '~/ui/components'
import { assignRef } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import { useAnalyticsService } from './AnalyticsServiceContext'
import DashboardBlock from './DashboardBlock'
import { useDashboardContext } from './DashboardContext'
import { dashboardGap } from './DashboardLayout'
import { useDashboardLayout } from './DashboardLayoutContext'
import WidgetCatalog from './WidgetCatalog'
import WidgetPanel from './WidgetPanel'

const DashboardContainer = observer('DashboardContainer', () => {

  const containerRef = React.useRef<HTMLDivElement>(null)

  const {editing, currentWidgets, addWidget, updateWidget} = useDashboardContext()
  const layout = useDashboardLayout()
  useBoundingRectangle(containerRef, rect => {
    layout.dashboardRect = rect
  })

  const [t] = useTranslation('analytics')

  const {service}  = useAnalyticsService()
  const endpoint   = useModelEndpoint(Widget)
  const widgets    = endpoint.data
  const categories = endpoint.meta?.categories ?? {}
  const status     = service?.status ?? 'starting'

  const dashboard = service?.dashboard
  const states    = service?.states ?? []

  //------
  // Drag & drop

  const dropZoneID = React.useMemo(() => Symbol('dashboard'), [])

  const [connectDropZone] = useDrop<DraggableWidgetItem>({
    id:     dropZoneID,
    accept: [DraggableItemType.WIDGET],

    enter: (item, monitor) => {
      monitor.updateItem(it => ({...it, onDashboard: true}))
    },

    leave: (item, monitor) => {
      monitor.updateItem(it => ({...omit(it,'targetRect'), onDashboard: false}))
      monitor.resetGhostLayout()
    },

    drop: async item => {
      if (item.targetRect == null) { return }

      if (item.alotment != null) {
        updateWidget(item.alotment.uuid, it => ({...it, ...item.targetRect}))
      } else {
        addWidget(item.widget.name, item.targetRect)
      }
    },

    snap: (center, monitor) => {
      const item = monitor.item

      const dashboardCenter = layout.pointToDashboardCell(center)
      const rect = layout.placeWidget(item.widget, dashboardCenter, item.alotment ?? null)

      // Remember this target rect for the drop.
      item.targetRect = rect
      item.fits       = rect != null

      if (rect == null) {
        monitor.resetGhostLayout()
        return null
      } else {
        const layoutRect = layout.dashboardCellRectToRect(rect)
        monitor.setGhostLayout({
          offsetSize: {
            width:  layoutRect.width,
            height: layoutRect.height,
          },
        })

        return {
          center: {
            x: layoutRect.x + layoutRect.width / 2,
            y: layoutRect.y + layoutRect.height / 2,
          },
          guides: [],
        }
      }
    },
  })

  const connect = React.useCallback((element: HTMLDivElement) => {
    connectDropZone(element)
    assignRef(containerRef, element)
  }, [connectDropZone])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <SidePanels<DashboardPanel>
        minPanelWidth={panelWidth.min}
        initialPanelWidth={panelWidth.initial}
        namespace='analytics.dashboard'
        right={editing ? 'catalog' : null}
        renderPanel={renderPanel}
        children={renderBody()}
      />
    )
  }

  function renderPanel(panel: DashboardPanel) {
    if (panel === 'catalog') {
      return renderCatalog()
    }
  }

  function renderCatalog() {
    return (
      <WidgetCatalog
        categories={categories}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex classNames={$.body}>
        {dashboard == null ? (
          renderEmpty()
        ) : (
          renderGrid()
        )}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={status}
        {...t('dashboard_not_found')}
        flex
      />
    )
  }

  function renderGrid() {
    return (
      <Grid
        ref={connect}
        classNames={$.dashboardContainer}
        columns={`repeat(${config.dashboard.columns}, 1fr)`}
        rows={`repeat(${config.dashboard.rows}, 1fr)`}
        gap={dashboardGap}
        flex
      >
        {editing && range(0, config.dashboard.rows).map(y => (
          range(0, config.dashboard.columns).map(x => (
            <DashboardBlock
              key={`${x},${y}`}
              x={x}
              y={y}
              active={editing}
            />
          ))
        ))}

        <DashboardWidgetsContainer
          alotments={currentWidgets}
          states={states}
          widgets={widgets}
        />
      </Grid>
    )

  }

  return render()

})

interface DashboardWidgetsContainerProps {
  alotments: WidgetAlotment[]
  states:    WidgetState[]
  widgets:   Widget[]
}

const DashboardWidgetsContainer = observer('DashboardWidgetsContainer', (props: DashboardWidgetsContainerProps) => {

  const {alotments, states, widgets} = props
  const {removeWidget: context_removeWidget} = useDashboardContext()
  const {service} = useAnalyticsService()
  const {user} = authenticationStore

  const removeWidget = React.useCallback((uuid: string) => {
    context_removeWidget(uuid, true)
  }, [context_removeWidget])

  const [t] = useTranslation('analytics')

  const layoutStyle = React.useCallback((alotment: WidgetAlotment) => {
    return {
      gridColumnStart: alotment.x + 1,
      gridColumnEnd:   alotment.x + alotment.width + 1,
      gridRowStart:    alotment.y + 1,
      gridRowEnd:      alotment.y + alotment.height + 1,
    }
  }, [])

  function render() {
    return (
      <>
        {alotments.map(renderWidgetAlotment)}
      </>
    )
  }

  function renderWidgetAlotment(alotment: WidgetAlotment) {
    const widget = widgets.find(it => it.name === alotment.widget) ?? null
    if (widget == null) { return renderWidgetNotFound(alotment) }

    const state  = states.find(it => it.alotmentUUID === alotment.uuid) ?? null

    return (
      <VBox key={alotment.uuid} style={layoutStyle(alotment)}>
        <WidgetPanel
          widget={widget}
          alotment={alotment}
          state={state}
        />
      </VBox>
    )
  }

  function renderWidgetNotFound(alotment: WidgetAlotment) {
    if (service == null || service.starting) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }

    return (
      <VBox key={alotment.uuid} style={layoutStyle(alotment)}>
        <Panel flex depth={0}>
          <Empty flex {...t('widget.not_found')}>
            {user?.isAdmin() && (
              <ClearButton
                icon='trash'
                caption={t('widget.remove')}
                onTap={removeWidget.bind(null, alotment.uuid)}
              />
            )}
          </Empty>
        </Panel>
      </VBox>
    )
  }

  return render()

})

export default DashboardContainer

export type DashboardPanel = 'catalog'
export const panelWidth = {
  min:     320,
  initial: 360,
}

const useStyles = createUseStyles({
  dashboardContainer: {
  },

  body: {
    ...layout.responsive(size => ({
      padding: layout.padding.m[size],
    })),
  },
})