import React from 'react'
import { getClientPoint, isRightMouse } from 'react-dnd'
import Color from 'color'
import { isModuleBoundaryNode } from '~/models'
import { ComponentBounds } from '~/stores'
import { memo } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'
import { useFlowPlanner } from '../FlowPlannerContext'
import { borderRadiusForPlannerComponent } from './layout'
import { useSelection } from './SelectionContext'

export interface Props {
  selectedBounds: ComponentBounds
  zoom:           number
}

const ResizeHandles = memo('ResizeHandles', (props: Props) => {

  const {selectedBounds, zoom} = props

  const {component, bounds} = selectedBounds
  const {uuid}              = component

  const {horizontal, vertical} = React.useMemo(() => {
    return {
      horizontal: true,
      vertical:   !isModuleBoundaryNode(component),
    }
  }, [component])

  const {planner} = useFlowPlanner()
  const {manager} = useSelection()

  //------
  // Layout

  const handleStyle: React.CSSProperties = {
    transform: `scale(${1 / zoom})`,
  }

  const theme = useTheme()
  const borderRadius = borderRadiusForPlannerComponent(theme, component, bounds)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div key={uuid} classNames={$.resizeHandles} style={{...bounds, borderRadius, borderWidth: `${2 / zoom}px`}}>
        <div
          classNames={[$.resizeHandle, {enabled: horizontal && vertical}, 'top', 'left']}
          style={{...handleStyle}}
          {...handleHandlers(horizontal ? 0 : 0.5, vertical ? 0 : 0.5)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: vertical}, 'top', 'center']}
          style={{...handleStyle}}
          {...handleHandlers(0.5, 0)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: horizontal && vertical}, 'top', 'right']}
          style={{...handleStyle}}
          {...handleHandlers(horizontal ? 1 : 0.5, vertical ? 0 : 0.5)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: horizontal}, 'middle', 'left']}
          style={{...handleStyle}}
          {...handleHandlers(0, 0.5)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: horizontal}, 'middle', 'right']}
          style={{...handleStyle}}
          {...handleHandlers(1, 0.5)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: horizontal && vertical}, 'bottom', 'left']}
          style={{...handleStyle}}
          {...handleHandlers(horizontal ? 0 : 0.5, vertical ? 1 : 0.5)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: vertical}, 'bottom', 'center']}
          style={{...handleStyle}}
          {...handleHandlers(0.5, 1)}
        />
        <div
          classNames={[$.resizeHandle, {enabled: horizontal && vertical}, 'bottom', 'right']}
          style={{...handleStyle}}
          {...handleHandlers(horizontal ? 1 : 0.5, vertical ? 1 : 0.5)}
        />
        {DEV && renderUUID()}
      </div>
    )
  }

  function renderUUID() {
    return (
      <VBox classNames={$.uuid}>
        <ThemeProvider contrast='secondary'>
          <Label tiny mono>
            {component.uuid}
          </Label>
        </ThemeProvider>
      </VBox>
    )
  }

  //------
  // Handlers

  const handlePointRef = React.useRef<Point>({x: 0, y: 0})
  const startPointRef = React.useRef<Point | null>(null)
  const dragActiveRef = React.useRef<boolean>(false)

  const calculateMouseDelta = React.useCallback((point: Point) => {
    if (startPointRef.current == null) { return null }
    return Math.max(
      Math.abs(point.x - startPointRef.current.x),
      Math.abs(point.y - startPointRef.current.y),
    )
  }, [])

  const handleMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const delta = calculateMouseDelta(clientPoint)
    if (startPointRef.current == null || delta == null || delta < 4) { return }

    manager?.selectOnly(uuid)

    planner.resizeComponentBy(uuid, handlePointRef.current, {
      x: (clientPoint.x - startPointRef.current.x) / zoom,
      y: (clientPoint.y - startPointRef.current.y) / zoom,
    })
    dragActiveRef.current = true
  }, [calculateMouseDelta, manager, planner, uuid, zoom])

  const handleEnd = React.useCallback((event: MouseEvent | TouchEvent) => {
    startPointRef.current = null
    document.removeEventListener('mousemove', handleMove)
    document.removeEventListener('touchmove', handleMove)
    document.removeEventListener('mouseup', handleEnd)
    document.removeEventListener('touchend', handleEnd)

    if (!dragActiveRef.current) { return }
    dragActiveRef.current = false

    planner.commitComponentBounds()
  }, [handleMove, planner])

  const handleStart = React.useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (isRightMouse(event.nativeEvent)) { return }
    if (closest(event.target, isInteractiveElement) != null) { return }

    event.preventDefault()

    startPointRef.current = getClientPoint(event.nativeEvent)
    document.addEventListener('mousemove', handleMove)
    document.addEventListener('touchmove', handleMove)
    document.addEventListener('mouseup', handleEnd)
    document.addEventListener('touchend', handleEnd)
  }, [handleEnd, handleMove])

  const handleDoubleClick = React.useCallback(() => {
    planner.requestAutoSize(uuid, handlePointRef.current)
  }, [planner, uuid])

  const handleHandlers = React.useCallback((x: number, y: number) => {
    const handler = (event: React.MouseEvent | React.TouchEvent) => {
      handlePointRef.current = {x, y}
      handleStart(event)
    }

    const doubleClick = (event: React.MouseEvent) => {
      if (closest(event.target, isInteractiveElement) != null) { return }

      handlePointRef.current = {x, y}
      handleDoubleClick()
    }

    return {
      onMouseDown:   handler,
      onTouchStart:  handler,
      onDoubleClick: doubleClick,
    }
  }, [handleDoubleClick, handleStart])

  return render()

})

export const shortSide = 8
export const longSide = 16

const resizeHandleBackground = {
  enabled:  new Color('#ffffff'),
  disabled: new Color('#dddddd'),
}

const useStyles = createUseStyles(theme => ({
  resizeHandles: {
    position:  'absolute',
    border:    [2, 'solid', theme.semantic.primary],
  },

  resizeHandle: {
    position:   'absolute',

    border:       [1, 'solid', theme.colors.fg.dark.dim],
    borderRadius: layout.radius.s,

    width:      shortSide,
    height:     shortSide,

    '&.top':    {top: -shortSide / 2 - 1},
    '&.middle': {top: '50%', marginTop: -longSide / 2 - 0.5, height: longSide},
    '&.bottom': {bottom: -shortSide / 2 - 0.5},
    '&.left':   {left: -shortSide / 2 - 1},
    '&.center': {left: '50%', marginLeft: -longSide / 2 - 0.5, width: longSide},
    '&.right':  {right: -shortSide / 2 - 0.5},

    '&.enabled': {
      background: resizeHandleBackground.enabled,

      '&.top.left, &.bottom.right': {cursor: 'nwse-resize'},
      '&.top.right, &.bottom.left': {cursor: 'nesw-resize'},
      '&.top.center, &.bottom.center': {cursor: 'ns-resize'},
      '&.middle.left, &.middle.right': {cursor: 'ew-resize'},
    },
    '&:not(.enabled)': {
      display: 'none',
    },

    pointerEvents: 'auto',
  },

  uuid: {
    position: 'absolute',
    top:      '100%',
    left:     0,
    right:    0,

    background: theme.semantic.secondary,
    padding:    layout.padding.inline.xs,

    '& > *': {
      fontSize: 8,
    },
    height:       12,
    borderRadius: 6,
  },
}))

export default ResizeHandles