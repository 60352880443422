import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { Challenge, Model, Script } from '~/models'
import { AnswerScopeLinkage } from '~/ui/app/answers/explore/AnswerScopeField'
import { CustomListPack } from '../data'
import dataStore from '../dataStore'
import { AnswersState } from './types'

export default class AnswersService extends OnDemandService {

  constructor(
    socket: Socket,
    public readonly scope: AnswerScopeLinkage,
    public filters:        Filters = {},
  ) {
    super(socket)
    makeObservable(this)
  }

  @observable
  public states: AnswersState[] = []

  @computed
  public get questionModel(): Model | null {
    if (this.scope === '$all') { return null }

    const Model: Constructor<Challenge | Script> = this.scope.type === 'challenges' ? Challenge : Script
    return dataStore.get(Model, this.scope.id)
  }

  public async start() {
    await super.startWithEvent('answers:start', {
      scope:   this.scope,
      filters: this.filters,
    })
  }

  protected onStarted = action((response: StartSuccess<CustomListPack<AnswersState>>) => {
    this.socket.prefix = `answers:${this.uid}:`
    dataStore.storePack(response.data)

    this.states = deserializeData(response.data.data ?? [])

    this.socket.addEventListener('update', this.handleUpdate)
  })

  //------
  // Filters

  @action
  public async updateFilters(filters: Partial<Filters>) {
    const response = await this.socket.send('filters:update', filters)
    if (response.ok) {
      this.filters = filters
      return response.body
    }
    return false
  }

  //------
  // Downloads

  public async downloadAnswers(questionUUIDs: string[] = []) {
    const response = await this.socket.fetch('download', {
      questionUUIDs: questionUUIDs,
    })

    if (response.ok) {
      return new Blob([response.body])
    } else {
      return null
    }
  }

  //------
  // Fetching

  private handleUpdate = action((update: CustomListPack<AnswersState>) => {

    const updates = deserializeData(update.data ?? [])

    const nextStates = [...this.states]
    for (const update of updates) {
      const index = nextStates.findIndex(it => it.question.uuid === update.question.uuid)
      if (index >= 0) {
        nextStates.splice(index, 1, update)
      } else {
        nextStates.push(update)
      }
    }

    this.states = nextStates
  })

  public refreshQuestion = action(async (questionUUID: string): Promise<boolean> => {
    const response = await this.socket.send('question:refresh', questionUUID)
    if (!response.ok) { return false }

    runInAction(() => {
      const receivedState = response.body.data as AnswersState
      this.states = this.states.map(state => {
        if (state.question.uuid !== receivedState.question.uuid) {
          return state
        } else {
          return receivedState
        }
      })
    })
    return true
  })

}

function deserializeData(data: any[]): AnswersState[] {
  return data.map(it => ({
    ...it,
    lastUpdate: DateTime.fromISO(it.lastUpdate),
  }))
}

export interface Filters {
  segments?:      Segment[]
  participantID?: string
}

export type Segment = 'all' | string