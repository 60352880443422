// @index(Filter, !WidgetFilter): import ${relpath}
import './AnswersQuestionFilter'
import './EnumFilter'
import './ResourceFilter'
import './TimeframeFilter'
import React from 'react'
import { Widget, WidgetAlotment, WidgetFilter } from '~/models'
import { getDashboardFilterComponent } from './registry'

// /index

export default function renderWidgetFilter(alotment: WidgetAlotment, widget: Widget, filter: WidgetFilter, value: any) {
  const name = filter.type === 'custom' ? filter.customType : filter.type

  const Component = getDashboardFilterComponent(name)
  if (Component == null) {
    console.warn(`No component registered for filter \`${name}\``)
    return null
  }

  return (
    <Component
      alotment={alotment}
      widget={widget}
      filter={filter}
      value={value}
    />
  )
}