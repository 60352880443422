import React from 'react'
import { Feed, Group } from '~/models'
import { memo } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import FeedModerationForm from './FeedModerationForm'

export interface Props {
  feed: Feed
}

const FeedModeration = memo('FeedModeration', (props: Props) => {

  const {feed} = props
  const [formOpen, openForm, closeForm] = useBoolean()

  const {t} = useResourceTranslation('feeds')

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon='headset'
        bold={feed.moderation != null}
        summary={t(`moderation.summary.${feed.moderation != null ? 'enabled' : 'disabled'}`)}
        body={renderModerationGroup()}
        requestEdit={openForm}
      >
        <FeedModerationForm
          open={formOpen}
          requestClose={closeForm}
          feed={feed}
        />
      </SummaryInfo.Item>
    )
  }

  function renderModerationGroup() {
    if (feed.moderation == null) { return null }

    return (
      <VBox gap={layout.padding.inline.m}>
        <Label small dim>
          {t('moderation.group')}
        </Label>
        <ResourceChipRow
          Model={Group}
          ids={[feed.moderation.moderatorGroup]}
          small
        />
      </VBox>
    )
  }

  return render()

})

export default FeedModeration