import React from 'react'
import { Channel, ChannelType, Group } from '~/models'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { memo, observer } from '~/ui/component'
import { HBox, NumberField, SwitchField, TextField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader, FormProps, useForm } from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'
import { ResourceFormDialog, ResourceFormModel } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  type:     ChannelType
  channel?: Channel | null
}

const ChannelForm = memo('ChannelForm', (props: Props) => {

  const {open, requestClose, afterSubmit, channel = null, type} = props

  const initialData = React.useMemo(() => {
    if (channel != null) { return {} }

    return {
      type,
      web: true,
      app: true,
    }
  }, [channel, type])

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<Channel>
        Model={Channel}
        model={channel}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        initialData={initialData}
        children={<ChannelFormBody/>}
      />
    )
  }

  return render()

})

const ChannelFormBody = observer('ChannelFormBody', () => {

  const {model: formModel} = useForm<ResourceFormModel<Channel>>()

  const type = formModel.getValue('type') as Channel['type']

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <HBox align='top' gap={layout.padding.s}>
          {renderBasicFields()}
          {renderImageField()}
        </HBox>

        {type === 'group' && renderGroupFields()}
        {renderAppWebFields()}
      </VBox>
    )
  }

  function renderBasicFields() {
    return (
      <VBox flex gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='pinIndex'>
          {bind => <NumberField {...bind} step={1}/>}
        </FormField>
      </VBox>
    )
  }

  function renderImageField() {
    return (
      <VBox>
        <FormField name='image' renderAsLabel={false}>
          {bind => (
            <CustomImageField
              {...bind}
              allowedTypes={['remote']}
              size={{width: 116, height: 116}}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  function renderGroupFields() {
    return (
      <FormField name='group'>
        {bind => <ResourceField Model={Group} {...bind}/>}
      </FormField>
    )
  }

  function renderAppWebFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormFieldHeader name='availability'/>
        <FormField name='app' caption={false}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
        <FormField name='web' caption={false}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default ChannelForm