import React from 'react'
import { useTranslation } from 'react-i18next'
import { SystemNotification } from '~/models'
import { notificationsStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, Dimple, HBox, List, Popup, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import NotificationPopupItem, { height as notificationPopupItemHeight } from './NotificationPopupItem'

export interface Props {
  open:                 boolean
  requestClose?:        () => any
  requestOpenSettings?: () => any

  children?:     React.ReactNode
}

const NotificationsPopup = observer('NotificationsPopup', (props: Props) => {

  const {open, requestClose, requestOpenSettings, children} = props

  const [t] = useTranslation('notifications')

  const notifications = notificationsStore.viewerNotifications

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Popup
        open={open}
        requestClose={requestClose}
        children={children}
        renderContent={renderContent}
        flex='shrink'
        gap={-notificationPopupItemHeight}
        matchTargetSize
      />
    )
  }

  function renderContent() {
    return (
      <VBox classNames={$.content}>
        <List
          data={notifications}
          renderItem={renderLog}
          flex='shrink'
          scrollable
        />
        {renderButtons()}
      </VBox>
    )
  }

  const renderLog = React.useCallback((notification: SystemNotification) => {
    return (
      <NotificationPopupItem
        notification={notification}
        onTap={requestClose}
      />
    )
  }, [requestClose])

  function renderButtons() {
    return (
      <HBox classNames={$.buttons} padding={layout.padding.inline.m} justify='right'>
        <ClearButton
          icon='satellite'
          caption={t('popup.view_notifications')}
          padding='both'
          href='/notifications'
          onTap={requestClose}
        />

        {requestOpenSettings != null && <Dimple vertical/>}
        {requestOpenSettings != null && (
          <ClearButton
            icon='cog'
            onTap={requestOpenSettings}
            padding='both'
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default NotificationsPopup

const useStyles = createUseStyles(theme => ({
  content: {
    maxHeight: 460,
    overflow:  'hidden',
  },

  buttons: {
    background: theme.colors.bg.light.semi,
  },
}))