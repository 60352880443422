import React from 'react'
import i18next from 'i18next'
import { TranslationMap } from '~/models'
import { plannerStore, projectStore, triggerableBandColor } from '~/stores'
import { memo, observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { TriggerableBar } from '../../triggerables'
import ComponentCatelog, { CreateComponentGroup, CreateComponentItem } from './ComponentCatalog'
import { CreateComponentType } from './types'

export interface Props {
  requestCreate: (value: CreateComponentType) => any
  active:        boolean
}

const ActionComponentCatalog = observer('ActionComponentCatalog', (props: Props) => {

  const {requestCreate, active} = props

  const categories = plannerStore.availableActionCategories
  const language   = i18next.language

  const getTranslations = React.useCallback(<T extends {}>(container: {translations: TranslationMap<T>}): T | null => {
    return container.translations[language]
      ?? container.translations.en
      ?? null
  }, [language])

  const groups = React.useMemo((): CreateComponentGroup[] => {
    const groups: CreateComponentGroup[] = []

    for (const {category, actions} of categories) {
      if (category.feature != null && !projectStore.isModuleFeatureEnabled(category.feature)) {
        continue
      }

      const availableActions = actions.filter(action => {
        if (action.feature == null) { return true }
        return projectStore.isModuleFeatureEnabled(action.feature)
      })
      if (availableActions.length === 0) { continue }

      const items = actions.map((action): CreateComponentItem => ({
        value:   {type: 'action', name: action.name},
        icon:    action.icon ?? 'star',
        caption: getTranslations(action)?.caption ?? action.name,
        detail:  getTranslations(action)?.description ?? undefined,
      }))

      groups.push({
        name: category.name,
        caption: getTranslations(category)?.caption ?? category.name,
        items: items,
      })
    }

    return groups
  }, [categories, getTranslations])

  //------
  // Rendering

  function render() {
    return(
      <ComponentCatelog
        columns={1}
        renderItem={renderItem}
        groups={groups}
        requestCreate={requestCreate}
        active={active}
      />
    )
  }

  const renderItem = React.useCallback((item: CreateComponentItem, index: number, selected: boolean) => (
    <ActionComponentCatalogItem
      item={item}
      requestCreate={requestCreate}
      selected={selected}
    />
  ), [requestCreate])

  return render()
})

export default ActionComponentCatalog

export interface ActionComponentCatalogItemProps {
  item:          CreateComponentItem
  requestCreate: (type: CreateComponentType) => any
  selected:      boolean
}

export const ActionComponentCatalogItem = memo('ActionComponentCatalogItem', (props: ActionComponentCatalogItemProps) => {

  const {item, requestCreate, selected} = props

  const theme = useTheme()

  const handleTap = React.useCallback(() => {
    requestCreate(item.value)
  }, [requestCreate, item.value])

  return (
    <VBox paddingHorizontal={layout.padding.s}>
      <TriggerableBar
        title={item.caption}
        detail={item.detail}
        icon={item.icon}
        bandColor={triggerableBandColor('action', theme)}
        onTap={handleTap}
        selected={selected}
      />
    </VBox>
  )
})