import React from 'react'
import { Post } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, VBox } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import CommentList from './CommentList'

export interface Props {
  postID: string

  requestAddComment?: () => any
  requestEditComment?: (comment: Post) => any

  requestClose?: () => any
}

const PostDetail = memo('PostDetail', (props: Props) => {

  const {postID, requestAddComment, requestEditComment, requestClose} = props
  const {t} = useResourceTranslation('posts')

  const commentsEndpoint = useModelEndpoint(Post, {
    filters: {
      root: postID,
      publishedAt: {$ne: null},
    },
  })

  //------
  // Rendering

  function render() {
    return (
      <VBox flex gap={layout.padding.s}>
        {renderHeader()}
        {renderList()}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <HBox gap={layout.padding.m}>
        {requestClose != null && (
          <ClearButton
            icon='arrow-left'
            onTap={requestClose}
            small
          />
        )}
        <Label h3 flex>
          {t('comments.caption')}
        </Label>
        <ClearButton
          icon='plus'
          caption={t('comments.add')}
          onTap={requestAddComment}
          small
        />
      </HBox>
    )
  }

  function renderList() {
    return (
      <CommentList
        endpoint={commentsEndpoint}
        requestEdit={requestEditComment}
      />
    )
  }

  return render()

})

export default PostDetail