import React from 'react'
import { memo } from '~/ui/component'
import { RegistrationWidgetModel } from './types'

interface RegistrationWidgetContext {
  model: RegistrationWidgetModel | null
}

const RegistrationWidgetContext = React.createContext<RegistrationWidgetContext>({
  model: null,
})

export interface RegistrationWidgetContainerProps {
  model:     RegistrationWidgetModel
  children?: React.ReactNode
}

export const RegistrationWidgetContainer = memo('RegistrationWidgetContainer', (props: RegistrationWidgetContainerProps) => {

  const {model, children} = props

  const context = React.useMemo((): RegistrationWidgetContext => ({
    model,
  }), [model])

  return (
    <RegistrationWidgetContext.Provider value={context}>
      {children}
    </RegistrationWidgetContext.Provider>
  )

})

export function useRegistrationWidgetModel() {
  const {model} = React.useContext(RegistrationWidgetContext)
  if (model == null) {
    throw new Error("Must be placed within RegistrationWidgetContainer")
  }

  return model
}