import React from 'react'
import { Module } from '~/models'
import { plannerStore } from '~/stores'
import { observer } from '~/ui/component'
import { ActionTriggerableForm, ModelTriggerableForm } from '../../triggerables'
import { useFlowPlanner } from '../FlowPlannerContext'
import ModuleNodeForm, { ModuleNodeFormModel } from '../nodes/ModuleNodeForm'
import FlowTriggerableFormModel from '../triggerables/FlowTriggerableFormModel'
import TriggerForm from '../triggers/TriggerForm'
import { CreateComponentType } from './types'

export interface Props {
  open:           boolean
  requestClose:   () => any
  owningModule:   Module
  component:      CreateComponentType
  operationPoint: Point
}

const CreateComponentForm = observer('CreateComponentForm', (props: Props) => {
  const {open, requestClose, owningModule, component, operationPoint} = props
  const {planner} = useFlowPlanner()

  const operation = React.useMemo(() => ({
    create: operationPoint,
  }), [operationPoint])

  const triggerableFormModel = React.useMemo(() => {
    if (planner == null || owningModule == null || component.type !== 'triggerable') { return null }
    const model = new FlowTriggerableFormModel(
      planner,
      operation,
      null,
      component.name,
      owningModule,
      null,
      null,
    )
    model.modelVariant?.createNew('')
    return model
  }, [planner, owningModule, component, operation])

  const actionFormModel = React.useMemo(() => {
    if (planner == null || module == null || component.type !== 'action') { return null }
    const action = plannerStore.getAction(component.name)
    if (action == null) { return null }
    return new FlowTriggerableFormModel(
      planner,
      operation,
      null,
      'action',
      owningModule,
      null,
      action,
    )
  }, [planner, component, operation, owningModule])

  const moduleFormModel = React.useMemo(() => {
    if (planner == null || component.type !== 'module') { return null }
    const model = new ModuleNodeFormModel(planner, operation)
    model.createNew('')
    return model
  }, [operation, planner, component.type])

  //------
  // Rendering

  function render() {
    if (component.type === 'trigger') {
      return (
        <TriggerForm
          open={open}
          requestClose={requestClose}
          trigger={null}
          triggerType={component.name}
          node={null}
          operation={operation}
        />
      )
    } else if (component.type === 'action' && actionFormModel != null) {
      return (
        <ActionTriggerableForm
          open={open}
          requestClose={requestClose}
          model={actionFormModel}
        />
      )
    } else if (component.type === 'triggerable' && triggerableFormModel != null) {
      return (
        <ModelTriggerableForm
          open={open}
          requestClose={requestClose}
          model={triggerableFormModel}
          initialData={component.data ?? {}}
        />
      )
    } else if (component.type === 'module' && moduleFormModel != null) {
      return (
        <ModuleNodeForm
          open={open}
          requestClose={requestClose}
          model={moduleFormModel}
          initialData={component.data ?? {}}
        />
      )
    }
    return null
  }

  return render()
})

export default CreateComponentForm