import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Script } from '~/models'
import { memo } from '~/ui/component'
import { ResourceDetailScreen } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import ScriptForm from '../ScriptForm'
import RunPanel from './RunPanel'
import ScriptBody from './ScriptBody'

export type Props = RouteComponentProps<ResourceDetailParams>

const ScriptScreen = memo('ScriptScreen', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<Script>
        Model={Script}
        id={props.match.params.id}
        EditFormComponent={ScriptForm}

        renderActions={renderActions}
        renderBody={renderBody}
      />
    )
  }

  function renderBody(script: Script) {
    return (
      <ScriptBody scriptID={script.id}/>
    )
  }

  function renderActions(script: Script) {
    return (
      <RunPanel
        script={script}
      />
    )
  }

  return render()

})

export default ScriptScreen