import React from 'react'
import { Dashboard } from '~/models'
import ModuleField from '~/ui/app/modules/ModuleField'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']
}

const CreateDashboardForm = memo('CreateDashboardForm', (props: Props) => {

  const {open, requestClose, afterSubmit} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Dashboard}
        model={null}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>

        <FormField name='module' required>
          {bind => <ModuleField {...bind} />}
        </FormField>

        <FormField name='name' required>
          {bind => <TextField {...bind} />}
        </FormField>

        <FormField name='tags'>
          {bind => <TagsField Model={Dashboard} {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default CreateDashboardForm