import { action, makeObservable, observable } from 'mobx'
import { OnDemandService, Socket } from 'socket.io-react'

export default class ScheduleService extends OnDemandService {

  constructor(
    socket: Socket,
    private readonly participantID: string | null,
  ) {
    super(socket)
    makeObservable(this)
  }

  public async start() {
    await super.startWithEvent('schedule:start', {
      participantID: this.participantID,
    })
  }

  protected onStarted() {
    this.socket.prefix = `schedule:${this.uid}:`
    this.socket.on('update', this.onUpdate)
  }

  //------
  // Handlers

  @observable
  public schedule: ScheduleEntry[] | null = null

  private onUpdate = action((schedule: ScheduleEntry[]) => {
    this.schedule = schedule
  })

}

export interface ScheduleEntry {}
