import { autorun } from 'mobx'
import socket from 'socket.io-react'
import config from '~/config'
import authenticationStore from './auth/authenticationStore'
import projectStore from './projectStore'
import { callOnLogout, register } from './support'

export class SocketStore {

  public init() {
    autorun(() => {
      const authToken          = authenticationStore.authToken
      const impersonatedUserID = authenticationStore.impersonatedUserID
      const projectID          = projectStore.requestedProjectID

      this.reconnectSocket(authToken, projectID, impersonatedUserID)
    })
  }

  private reconnectSocket(authToken: string | null, requestProjectID: string | null, impersonatedUserID: string | null) {
    if (socket.status !== 'disconnected') {
      socket.disconnect()
    }

    if (authToken != null) {
      this.connectSocket(
        authToken,
        requestProjectID,
        impersonatedUserID,
      )
    }
  }

  private connectSocket(authToken: string, requestProjectID: string | null, impersonatedUserID: string | null) {
    const query: AnyObject = {}
    query.auth = authToken

    if (requestProjectID != null) {
      query.projectID = requestProjectID
    }
    if (impersonatedUserID != null) {
      query.impersonatedUserID = impersonatedUserID
    }

    socket.connect(config.api.socketURI, {
      transports: ['websocket', 'polling'],
      query:      query,
    })
  }

  public async disconnectSocket() {
    await callOnLogout()
    socket.disconnect()
  }

}

const socketStore = register(new SocketStore())
export default socketStore