import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { useOnDemandService } from 'socket.io-react'
import { mapStore } from '~/stores'
import { memo } from '~/ui/component'
import MapContainer from './MapContainer'
import { MapServiceProvider } from './MapServiceContext'

export interface MapParams {
  locationID?: string
}

export type Props = RouteComponentProps<MapParams>

const MapScreen = memo('MapScreen', (props: Props) => {

  const service = React.useMemo(
    () => mapStore.mapService(),
    [],
  )

  useOnDemandService(service)

  //------
  // Rendering

  function render() {
    return (
      <MapServiceProvider service={service}>
        <MapContainer/>
      </MapServiceProvider>
    )
  }

  return render()

})

export default MapScreen