import React from 'react'
import { Review } from '~/models'
import { authenticationStore } from '~/stores'
import { memo } from '~/ui/component'
import { Center, ClearButton, ConfirmBox, Dimple, Panel, PushButton, VBox } from '~/ui/components'
import { useModelDocument } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { createUseStyles, layout, useStyling } from '~/ui/styling'

export interface Props {
  review: Review
}

const ReviewStatusSummary = memo('ReviewStatusSummary', (props: Props) => {

  const {review} = props

  const {colors} = useStyling()
  const {t, actionCaption, actionConfirm} = useResourceTranslation('reviews')

  const document = useModelDocument(Review, review.id)

  const currentUserID   = authenticationStore.userID
  const userIsReviewing = review?.status === 'review' && review?.reviewer === currentUserID

  //------
  // Callbacks

  const complete = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('complete'),
    })
    if (!confirmed) { return }

    await document?.callAction('complete')
  }, [actionConfirm, document])

  const abandon = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('abandon'),
    })
    if (!confirmed) { return }

    await document?.callAction('abandon')
  }, [actionConfirm, document])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (review.status !== 'review') { return null }

    return (
      <Panel classNames={$.reviewStatusSummary} padding={layout.padding.s} gap={layout.padding.s}>
        {renderReviewStatus()}
        <Dimple horizontal counterPadding={layout.padding.s}/>
        <VBox gap={layout.padding.inline.s}>
          {renderCompleteButton()}
          <Center>
            {renderAbandonButton()}
          </Center>
        </VBox>
      </Panel>
    )
  }

  function renderReviewStatus() {
    return (
      <SummaryInfo.Item important={userIsReviewing} color={colors.semantic.primary} icon={null} align='center'>
        {userIsReviewing ? (
          t('statuses.review.summary_you')
        ) : review.status === 'review' ? (
          t('statuses.review.summary_other')
        ) : (
          t(`statuses.${review.status}.summary`)
        )}
      </SummaryInfo.Item>
    )
  }

  function renderCompleteButton() {
    return (
      <PushButton
        icon='check'
        caption={actionCaption('complete')}
        onTap={complete}
        small
      />
    )
  }

  function renderAbandonButton() {
    return (
      <ClearButton
        icon='cross'
        caption={actionCaption('abandon')}
        onTap={abandon}
        small
        dim
      />
    )
  }

  return render()

})

export default ReviewStatusSummary

const useStyles = createUseStyles(theme => ({
  reviewStatusSummary: {
    boxShadow: [0, 0, 4, 0, theme.semantic.primary],
  },
}))