import React from 'react'
import { useTranslation } from 'react-i18next'
import Color from 'color'
import { ImportIssue, ImportIssueLevel, ImportSession } from 'sheet-importer'
import { memo } from '~/ui/component'
import { Center, HBox, Label, Panel, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { ColorGuide, createUseStyles, fonts, layout, useStyling } from '~/ui/styling'

export interface Props {
  session: ImportSession
}

const ImportIssueList = memo('ImportIssueList', (props: Props) => {

  const {session} = props

  const result = session.lastRun?.result
  const issues = result?.status === 'completed' ? result.issues : []

  //------
  // Rendering

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        {renderIssues()}
      </VBox>
    )
  }

  function renderIssues() {
    return (
      <VBox gap={layout.padding.inline.s}>
        {issues.map((issue, index) => (
          <ImportIssueBar
            key={index}
            issue={issue}
          />
        ))}
      </VBox>
    )
  }

  return render()

})

export default ImportIssueList

interface ImportIssueBarProps {
  issue: ImportIssue
}

const ImportIssueBar = memo('ImportIssueBar', (props: ImportIssueBarProps) => {

  const {issue} = props

  const $ = useStyles()
  const {colors} = useStyling()

  const [t] = useTranslation('importer')

  function render() {
    return (
      <Panel>
        <HBox flex gap={layout.padding.s} classNames={$.issueBar} align='stretch'>
          {renderIcon()}
          {renderCellAddress()}
          {renderLabels()}
        </HBox>
      </Panel>
    )
  }

  function renderIcon() {
    return (
      <Center>
        <SVG
          name={iconForLevel(issue.level)}
          color={colorForLevel(issue.level, colors)}
          size={layout.icon.m}
        />
      </Center>
    )
  }

  function renderCellAddress() {
    if (issue.address == null) { return null }

    const isCell = /[A-Z]/.test(issue.address)
    return (
      <VBox align='center' classNames={$.issueCell}>
        <Label tiny dim>
          {t(isCell ? 'result.issue_cell' : 'result.issue_row')}
        </Label>
        <Center flex='grow'>
          <Label h3>
            {issue.address}
          </Label>
        </Center>
      </VBox>
    )
  }

  function renderLabels() {
    return (
      <VBox flex justify='middle' gap={-layout.padding.inline.xs}>
        <Label h3 markup>
          {issue.title}
        </Label>
        {issue.detail != null && (
          <Label small dim markup truncate={false}>
            {issue.detail}
          </Label>
        )}
      </VBox>
    )
  }

  return render()

})

function iconForLevel(level: ImportIssueLevel): SVGName {
  switch (level) {
    case 'info': return 'info'
    case 'error': return 'error-full'
    case 'warning': return 'warning-full'
  }
}

function colorForLevel(level: ImportIssueLevel, colors: ColorGuide): Color {
  switch (level) {
    case 'info': return colors.semantic.secondary
    case 'error': return colors.semantic.negative
    case 'warning': return colors.semantic.warning
  }
}

export const issueBarHeight = layout.barHeight.m

const useStyles = createUseStyles(theme => ({
  count: {
    ...fonts.responsiveFontStyle(theme.fonts.caption),
    fontSize: 32,
  },

  preambleIcon: {
    padding: layout.padding.inline.xl,
  },

  preambleSection: {
    padding: [layout.padding.inline.l, layout.padding.inline.l],
  },

  issueBar: {
    minHeight:   issueBarHeight,
    padding:     [layout.padding.inline.s, layout.padding.inline.l],
  },

  issueCell: {
    background:   theme.bg.subtle,
    borderRadius: layout.radius.m,
    padding:      [layout.padding.inline.xs, layout.padding.inline.m],
    minWidth:     issueBarHeight - 2 * layout.padding.inline.xs,
  },
}))