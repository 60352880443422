import React from 'react'
import { Metric, MetricType } from '~/models'
import { memo } from '~/ui/component'
import { CollectionField, SelectField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, FormProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import { RepeatBehavior } from '../../../models/Metric'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  type?:  MetricType
  model?: Metric | null
}

const MetricForm = memo('MetricForm', (props: Props) => {

  const {open, requestClose, afterSubmit, type, model = null} = props
  const isNew = model == null

  const {t} = useResourceTranslation('metrics')

  const initialData = React.useMemo(
    () => isNew ? {type: type ?? 'valued', steps: ['one', 'two']} : {},
    [isNew, type],
  )

  const repeatBehaviorChoices = React.useMemo(() => (
    RepeatBehavior.all().map((value): Choice => ({
      value:   value,
      caption: t(`fields.repeat_behavior.choices.${value}`),
    }))
  ), [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Metric}
        model={model}
        initialData={initialData}
        semi={type !== 'funnel'}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderForm()}
      />
    )
  }

  function renderForm() {
    return (
      <VBox gap={layout.padding.s}>
        {renderBasicFields()}
        {type === 'valued' && renderValuedFields()}
        {type === 'funnel' && renderFunnelFields()}
      </VBox>
    )
  }

  function renderBasicFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderValuedFields() {
    return (
      <FormField name='repeatBehavior' required>
        {bind => (
          <SelectField
            {...bind}
            choices={repeatBehaviorChoices}
          />
        )}
      </FormField>
    )
  }

  function renderFunnelFields() {
    return (
      <FormField name='steps' required>
        {bind => (
          <CollectionField<string>
            {...bind}
            renderForm={item => <TextField {...item.bind('$')}/>}
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default MetricForm