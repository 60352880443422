import { merge } from 'lodash'

export default merge(
  // @index(f:\.yml$): require(${relpathwithext}),
  require('./access.yml'),
  require('./analytics.yml'),
  require('./answers.yml'),
  require('./app.yml'),
  require('./auth.yml'),
  require('./bewizr.yml'),
  require('./calendar-planner.yml'),
  require('./chat.yml'),
  require('./components.yml'),
  require('./conditions.yml'),
  require('./config.yml'),
  require('./errors.yml'),
  require('./feedback.yml'),
  require('./flow-planner.yml'),
  require('./importer.yml'),
  require('./links.yml'),
  require('./locales.yml'),
  require('./map.yml'),
  require('./messages.yml'),
  require('./misc.yml'),
  require('./navigation.yml'),
  require('./notifications.yml'),
  require('./operator.yml'),
  require('./profile.yml'),
  require('./project-switcher.yml'),
  require('./resource.yml'),
  require('./resources'),
  require('./scoring.yml'),
  require('./targeting.yml'),
  require('./validation.yml'),
  require('./widgets.yml'),
  // /index
  require('./resources').default,
)