import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { Module } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import { Center, PushButton, Spinner, VBox } from '~/ui/components'
import { Breadcrumb } from '~/ui/layouts/app'
import { animation, createUseStyles, layout } from '~/ui/styling'
import ActiveModuleContext from './ActiveModuleContext'
import CalendarPlannerContainer from './calendar/CalendarPlannerContainer'
import FlowPlannerContainer from './flow/FlowPlannerContainer'

export interface Params {
  modules?: string
}

const PlannerScreen = observer('PlannerScreen', () => {

  const {params}  = useRouteMatch<Params>()

  const moduleIDs    = params.modules == null ? [] : params.modules.split('/')
  const allModuleIDs = projectStore.projectID == null ? moduleIDs : [projectStore.projectID, ...moduleIDs]
  const moduleID     = allModuleIDs[allModuleIDs.length - 1]

  const stackLevel        = allModuleIDs.length
  const prevStackLevelRef = React.useRef<number | null>(null)
  const [transition, setTransition] = React.useState<'down' | 'up' | 'none'>('none')

  React.useLayoutEffect(() => {
    const prevStackLevel = prevStackLevelRef.current
    prevStackLevelRef.current = stackLevel

    if (prevStackLevel != null && stackLevel !== prevStackLevel) {
      const transition = stackLevel > prevStackLevel ? 'down' : stackLevel < prevStackLevel ? 'up' : 'none'
      setTransition(transition)
    }
  }, [stackLevel])

  const $ = useStyles()

  function render() {
    return (
      <ActiveModuleContext.Provider value={moduleID}>
        <TransitionGroup classNames={[$.transitionGroup, transition]}>
          <CSSTransition
            key={moduleID}
            classNames='content'
            timeout={animation.durations.medium}
          >
            <VBox classNames={$.content}>
              <PlannerContainer
                moduleIDs={allModuleIDs}
              />
            </VBox>
          </CSSTransition>
        </TransitionGroup>
      </ActiveModuleContext.Provider>
    )
  }

  return render()

})

const PlannerContainer = observer('PlannerContainer', (props: {moduleIDs: string[]}) => {

  const {moduleIDs} = props
  const allModules  = dataStore.db(Module).all()

  const modules      = moduleIDs.map(id => allModules.find(it => it.id === id)).filter(Boolean) as Module[]
  const module       = modules[modules.length - 1] ?? null

  const breadcrumbs = React.useMemo((): Breadcrumb[] => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({name: 'planner'})

    const higherUp: string[] = []
    for (const [index, module] of modules.entries()) {
      breadcrumbs.push({
        icon:    'puzzle',
        caption: module.$caption,
        href:    `/planner${higherUp.join('')}/${module.id}`,
        detail:  index === modules.length - 1 ? 'planner' : undefined,
      })
      higherUp.push(`/${module.id}`)
    }

    return breadcrumbs
  }, [modules])

  function render() {
    return (
      <>
        {renderContent()}

        <BackButton
          modules={modules}
        />
      </>
    )
  }

  function renderContent() {
    if (module == null) {
      return renderLoading()
    }

    if (module.plannerType === 'flow') {
      return (
        <FlowPlannerContainer
          moduleIDs={moduleIDs}
          breadcrumbs={breadcrumbs}
        />
      )
    } else if (module.plannerType === 'calendar') {
      return (
        <CalendarPlannerContainer
          moduleIDs={moduleIDs}
          breadcrumbs={breadcrumbs}
        />
      )
    } else {
      return null
    }
  }

  function renderLoading() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

const BackButton = memo('BackButton', (props: {modules: Module[]}) => {

  const {modules}    = props
  const history      = useHistory()
  const parentModule = modules[modules.length - 2]

  const backToParentModule = React.useCallback(() => {
    const chain = modules.slice(1, -1).map(m => `/${m.id}`)
    history.push(`/planner${chain.join('')}`)
  }, [history, modules])

  const $ = useStyles()


  if (modules.length < 2) { return null }

  return (
    <VBox classNames={$.backButton}>
      <PushButton
        icon='arrow-left'
        caption={parentModule.name}
        onTap={backToParentModule}
      />
    </VBox>
  )
})

export default PlannerScreen

const useStyles = createUseStyles({
  transitionGroup: {
    flex:     [1, 0, 0],
    position: 'relative',
    overflow: 'hidden',
  },

  content: {
    ...layout.overlay,

    '$transitionGroup.up &:first-child.content, $transitionGroup.down &:last-child.content': {
      ...animation.explode(animation.durations.medium),
    },
    '$transitionGroup.down &:first-child.content, $transitionGroup.up &:last-child.content': {
      ...animation.fade(animation.durations.medium),
    },
  },

  backButton: {
    position: 'absolute',
    top:      0,
    left:     0,

    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], 2],
    })),
  },
})