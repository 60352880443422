import React from 'react'
import { Organisation } from '~/models'
import ProjectLogo from '~/ui/app/projects/ProjectLogo'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'

export type Props = Omit<ResourceFieldProps<Organisation>, 'Model' | 'shortcutsCaption' | 'shortcutIDs'>

const OrganisationField = observer('OrganisationField', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...props as any}
        Model={Organisation}
        renderPropsForModel={renderPropsForModel}
      />
    )
  }

  const renderPropsForModel = React.useCallback((organisation: Organisation) => {
    return {
      image: <ProjectLogo logo={organisation.logo}/>,
    }
  }, [])

  return render()

})

export default OrganisationField