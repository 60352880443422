import React from 'react'
import { EntryNode, ExitNode } from '~/models'
import { memo } from '~/ui/component'
import { Center, Label, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { colors, createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import NodeViewKebabMenu from './NodeViewKebabMenu'

export interface Props {
  node:         EntryNode | ExitNode
  variant?:     'canvas' | 'list'
  interactive?: boolean
}

const ModuleBoundaryNodeView = memo('ModuleBoundaryNodeView', (props: Props) => {

  const {node} = props

  const {planner} = useFlowPlanner()
  const {t}       = useResourceTranslation('flow_planner')

  React.useEffect(() => {
    planner.setLoadingComponent(node.uuid, false)
  }, [node.uuid, planner])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider light>
        <VBox
          classNames={[$.moduleBoundaryNodeView, node.type]}
          flex='grow'
        >
          {node.type === 'entry' && renderCaption()}
          {renderBody()}
          {node.type === 'exit' && renderCaption()}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderCaption() {
    return (
      <ThemeProvider contrast='secondary'>
        <VBox justify='space-around' classNames={$.caption} gap={layout.padding.inline.s}>
          <Label caption tiny align='center'>
            {t(`nodes.${node.type}.summary`)}
          </Label>
          {node.type === 'exit' && node.outlet != null && (
            <Label mono small align='center' markup>
              {`[${node.outlet}]`}
            </Label>
          )}
        </VBox>
      </ThemeProvider>
    )
  }

  function renderBody() {
    return (
      <ThemeProvider contrast='primary'>
        <Center flex classNames={$.body}>
          <NodeViewKebabMenu
            node={node}
          />
        </Center>
      </ThemeProvider>
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  moduleBoundaryNodeView: {
    '&.entry, &.entry $body': {
      borderRadius: [0, 0, 1000, 1000],
    },
    '&.exit, &.exit $body': {
      borderRadius: [1000, 1000, 0, 0],
    },
  },

  body: {
    boxShadow: shadows.depth(1),

    backgroundImage: colors.linearGradient(
      'bottom left',
      theme.semantic.primary,
      theme.semantic.secondary,
    ),

    whiteSpace:   'nowrap',
  },

  caption: {
    boxShadow:  shadows.depth(1),
    padding:    [layout.padding.inline.s, layout.padding.inline.m],
    background: theme.semantic.secondary,

    '$moduleBoundaryNodeView.entry &': {
      borderTopLeftRadius:  layout.radius.m,
      borderTopRightRadius: layout.radius.m,
    },
    '$moduleBoundaryNodeView.exit &': {
      borderBottomLeftRadius:  layout.radius.m,
      borderBottomRightRadius: layout.radius.m,
    },
  },
}))

export default ModuleBoundaryNodeView