import React from 'react'
import { useRouteMatch } from 'react-router'
import { SystemNotification } from '~/models'
import { notificationSpec } from '~/notifications'
import { observer } from '~/ui/component'
import { DataGrid, HBox, Label, SVG, VBox } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceList } from '~/ui/resources/collection'
import { layout, useTheme } from '~/ui/styling'

import type { Params } from './NotificationsScreen'
const NotificationList = observer('NotificationList', () => {

  const match    = useRouteMatch<Params>()
  const id       = match.params.id ?? null
  const endpoint = useModelEndpoint(SystemNotification)

  const theme = useTheme()
  const {fieldCaption} = useResourceTranslation('notifications')

  //------
  // Rendering

  function render() {
    return (
      <ResourceList<SystemNotification>
        endpoint={endpoint}
        itemHref={notification => `/notifications/-/${notification.id}`}
        itemIsSelected={notification => notification.id === id}
        useLocation={true}
        showTotals={false}
        allowSelect={false}
        allowRemove={false}
      >
        <DataGrid.Column<SystemNotification>
          name='title'
          caption={fieldCaption('title')}
          sort={true}
          renderCell={renderTitle}
        />
        <DataGrid.Column<SystemNotification>
          name='date'
          caption={fieldCaption('date')}
          width={180}
          sort='createdAt'
          renderCell={renderDate}
        />
      </ResourceList>
    )
  }

  function renderTitle(notification: SystemNotification) {
    const spec  = notificationSpec(notification.type)
    const icon  = spec.icon(notification)
    const color = spec.color(notification, theme)

    return (
      <HBox gap={layout.padding.inline.l}>
        <SVG name={icon} color={color} size={layout.icon.m}/>
        <VBox flex>
          <HBox gap={layout.padding.inline.s}>
            <Label h3 markup>
              {notification.title}
            </Label>
            {notification.priority === 'high' && (
              <SVG
                name='exclamation'
                size={layout.icon.xs}
                color={theme.semantic.negative}
              />
            )}
          </HBox>
          <Label small dim markup>
            {notification.message}
          </Label>
        </VBox>
      </HBox>
    )
  }

  function renderDate(notification: SystemNotification) {
    return (
      <Label dim small>
        {notification.createdAt.toFormat('d MMM, H:mm')}
      </Label>
    )
  }

  return render()

})

export default NotificationList