import React from 'react'
import { createSortableElement, SortableItem } from 'react-dnd-sortable'
import { ClientTab } from '~/models'
import { memo } from '~/ui/component'
import { Dimple, Empty, List, ListProps, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import { useClientTabs } from './ClientTabManagerContext'
import ClientTabsListItem from './ClientTabsListItem'

export const APP_TAB = 'app.groundcontrol.sortable.APP_TAB'

const SortableClientTab = createSortableElement(VBox, {
  axis:     'vertical',
  itemType: APP_TAB,
})

const ClientTabsList = memo('TabManagerList', () => {

  const {t} = useResourceTranslation('client-tabs')
  const {tabs, editingItems, moveTab} = useClientTabs()

  //------
  // Sorting

  const sortEnabled    = editingItems
  const sortableListID = React.useMemo(() => Symbol(), [])

  const handleSortDrop = React.useCallback(async (item: SortableItem, dropIndex: number) => {
    if (editingItems == null) { return }
    moveTab(item.index, dropIndex)
  }, [editingItems, moveTab])

  const sortable = React.useMemo((): ListProps<any>['sortable'] | undefined => {
    if (!sortEnabled) { return undefined }

    return {
      listID:     sortableListID,
      accept:     [APP_TAB],
      onSortDrop: handleSortDrop,
    }
  }, [handleSortDrop, sortEnabled, sortableListID])

  //------
  // Rendering

  function render() {
    if (tabs.length === 0) return renderEmpty()
    return(
      <List
        scrollable
        sortable={sortable}
        keyExtractor={keyExtractor}
        renderItem={sortEnabled ? renderSortableItem : renderItem}
        renderPlaceholder={renderSortPlaceholder}
        data={tabs}
      />
    )
  }

  const renderEmpty = React.useCallback(() => (
    <Empty flex {...t('empty')} />
  ), [t])

  const keyExtractor = React.useCallback(
    (tab) => tab.link.href,
    [],
  )

  const renderItem = React.useCallback((tab: ClientTab, index: number) => (
      <VBox>
        <ClientTabsListItem tab={tab} index={index} />
        {index < tabs.length - 1 && <Dimple horizontal />}
      </VBox>
  ), [tabs])

  const renderSortableItem = React.useCallback((item: ClientTab, index: number) => (
    <SortableClientTab index={index} sourceList={sortableListID} payload={item}>
      {renderItem(item, index)}
    </SortableClientTab>
  ), [renderItem, sortableListID])

  const renderSortPlaceholder = React.useCallback(
    () => <VBox style={{height: sortPlaceholderHeight}}/>,
    [],
  )

  return render()

})

export default ClientTabsList

const sortPlaceholderHeight = layout.barHeight.m + 2 // 2px for the dimple