import React from 'react'
import { EnumUtil } from 'ytil'
import { RegistrationCode, RegistrationCodeStatus, RegistrationPreset } from '~/models'
import { memo } from '~/ui/component'
import { DataGrid, SelectField } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFilter, ResourceListScreen } from '~/ui/resources/collection'
import { ResourceField } from '~/ui/resources/components'
import { CodeCell, PresetCell, ProjectCell, StatusCell } from './cells'

const RegistrationCodesScreen = memo('RegistrationCodesScreen', () => {

  const {t} = useResourceTranslation('registration-codes')

  const statusFilterChoices = React.useMemo(() => [
    {value: null, caption: t(['filters.status.unspecified', 'filters._common.unspecified'])},
    ...EnumUtil.values(RegistrationCodeStatus).map(status => ({
      value:   status,
      caption: t(`status.${status}`),
    }))], [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={RegistrationCode}
        defaultSort='name'
        allowCopy={false}
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={CodeCell.renderer}
          flex={2}
        />
        <DataGrid.Column
          name='preset'
          renderCell={PresetCell.renderer}
          flex={1}
        />
        <DataGrid.Column
          name='project'
          renderCell={ProjectCell.renderer}
          flex={1}
        />
        <DataGrid.Column
          name='status'
          renderCell={StatusCell.renderer}
          width={120}
        />

        <ResourceListScreen.Filters>
          <ResourceFilter name='preset'>
            {bind => <ResourceField Model={RegistrationPreset} {...bind} multi inputStyle='dark' smallChips/>}
          </ResourceFilter>
          <ResourceFilter name='status'>
            {bind => <SelectField {...bind} inputStyle='dark' choices={statusFilterChoices}/>}
          </ResourceFilter>
        </ResourceListScreen.Filters>

      </ResourceListScreen>
    )
  }

  return render()

})

export default RegistrationCodesScreen