import DevBuddy, { DevBuddyDevice } from 'devbuddy'
import { action, makeAutoObservable, reaction } from 'mobx'
import { switchRegExp } from 'ytil'
import config from '~/config'
import projectStore from './projectStore'
import { register } from './support'

export class AppLinksStore {

  constructor() {
    makeAutoObservable(this)

    reaction(() => [this.availableDevices, this.defaultTarget], () => {
      if (!this.targetAvailable(this.defaultTarget)) {
        this.defaultTarget = '$web'
      }
    })
  }

  public availableDevices: DevBuddyDevice[] | null = null

  public defaultTarget: AppLinkTarget = '$web'

  public setDefaultTarget(target: AppLinkTarget) {
    this.defaultTarget = target
  }

  public targetAvailable(target: AppLinkTarget) {
    if (target === '$web') { return true }
    if (target === '$preview') { return true }

    // This is a bit weird, but this is done to prevent the target from being reset to '$web' before the devices
    // have been loaded.
    if (this.availableDevices == null) { return true }

    return this.availableDevices.some(device => target === `${device.platform}:${device.udid}`)
  }

  public isDeviceTarget(target: AppLinkTarget) {
    return target !== '$web' && target !== '$preview'
  }

  public findDevice(target: AppLinkTarget): DevBuddyDevice | null {
    if (!this.isDeviceTarget(target)) { return null }
    return this.availableDevices?.find(it => it.udid === target) ?? null
  }

  //------
  // Loading devices

  private loadDevicesPromise: Promise<void> | null = null

  public loadDevices() {
    if (!DevBuddy.available()) {
      this.availableDevices = []
      return
    }

    return this.loadDevicesPromise ??=
      DevBuddy.listDevices().then(
        action(devices => { this.availableDevices = devices }),
        action(() => { this.availableDevices = [] }),
      ).finally(action(() => {
        this.loadDevicesPromise = null
      }))
  }

  //------
  // Main interface

  public async openAppLink(href: string, target: AppLinkTarget = this.defaultTarget) {
    if (this.isAppLink(href) && this.isDeviceTarget(target)) {
      const appHref = this.resolveAppLink(href)
      if (appHref == null) {
        console.warn("Could not resolve app link: ", href)
        return
      }

      this.openAppLinkOnDevice(appHref, target)
    } else if (target === '$preview') {
      // TODO: Open in preview
    } else {
      window.open(this.resolveWebLink(href), '_blank')
    }
  }

  public async openAppLinkOnDevice(href: string, target: string) {
    const [platform, udid] = target.split(':')
    const result = await DevBuddy.openURLOnDevice(platform, udid, href)

    for (const listener of this.appLinkListeners) {
      listener(href, result)
    }
}

  public resolveAppLink(href: string, target: AppLinkTarget = this.defaultTarget) {
    if (!this.isAppLink(href)) { return null }

    if (target === '$web') {
      return this.resolveWebLink(href)
    } else if (/^\/\//.test(href)) {
      const scheme = projectStore.preferredProjectURLScheme
      return `${scheme}:${href}`
    } else {
      return href
    }
  }

  public resolveWebLink(href: string) {
    if (!this.isAppLink(href)) { return href }

    const params = new URLSearchParams({applink: href})
    return `${config.urls.web(projectStore.preferredProjectDomain)}?${params}`
  }

  public isAppLink(href: string) {
    if (/^\/\//.test(href)) { return true }

    const match  = href.match(URL_SCHEME_REGEXP)
    const scheme = match?.[1]
    if (scheme == null) { return false }
    if (scheme === 'groundcontrol') { return true }
    if (scheme === projectStore.preferredProjectURLScheme) { return true }

    return false
  }

  public resolveMissionAppLink(href: string) {
    return switchRegExp(href, [
      [/^\/\/page\/(.*)$/,        match => `/pages/-/${match[1]}`],
      [/^\/\/challenge\/(.*)$/,   match => `/challenges/-/${match[1]}`],
      [/^\/\/participant\/(.*)$/, match => `/participants/-/${match[1]}`],
      [/^\/\/q-and-a\/(.*)$/,     match => `/q-and-as/-/${match[1]}`],
    ], href)
  }

  //------
  // Listeners

  private appLinkListeners = new Set<AppLinkListener>()

  public addAppLinkListener(listener: AppLinkListener) {
    this.appLinkListeners.add(listener)
    return () => {
      this.appLinkListeners.delete(listener)
    }
  }

  //------
  // Persistence

  public persistenceKey = 'applinks'

  public persist() {
    return {
      defaultTarget: this.defaultTarget,
    }
  }

  public rehydrate(raw: any) {
    if (raw.defaultTarget != null) {
      this.defaultTarget = raw.defaultTarget
    }
  }

}

export type AppLinkTarget = WellKnownAppLinkTarget | string

export type WellKnownAppLinkTarget =
  /** Opens the app link in the web client, in a tab called 'web'. */
  | '$web'

  /** Opens the app link in the preview panel. */
  | '$preview'

export type AppLinkListener = (href: string, success: boolean) => any

const URL_SCHEME_REGEXP = /^(?:([\w\d+.-]+):)?/

const appLinksStore = new AppLinksStore()
register(appLinksStore)

export default appLinksStore