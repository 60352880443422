import React from 'react'
import { Project } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { ActionButton } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import DuplicateProjectForm from '../duplicate/DuplicateProjectForm'

export interface Props {
  project: Project
}

const ProjectActions = memo('ProjectActions', (props: Props) => {

  const {project} = props
  const [duplicateFormOpen, openDuplicateForm, closeDuplicateForm] = useBoolean()

  //------
  // Rendering

  function render() {
    return (
      <VBox align='left' gap={layout.padding.s}>
        <ActionButton
          name='duplicate'
          icon='copy'
          onTap={openDuplicateForm}
        />
        <ActionButton
          name='archive'
          icon='archive'
        />

        <DuplicateProjectForm
          open={duplicateFormOpen}
          requestClose={closeDuplicateForm}
          project={project}
        />
      </VBox>
    )
  }

  return render()

})

export default ProjectActions