import React from 'react'
import { Feed } from '~/models'
import { TargetingSummary } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import FeedTargetingForm from './FeedTargetingForm'

export interface Props {
  feed: Feed
}

const FeedTargeting = memo('FeedTargeting', (props: Props) => {

  const {feed} = props
  const {t} = useResourceTranslation('feeds')

  const BoundTargetingForm = React.useCallback(
    (props: Pick<FormDialogProps<any>, 'open' | 'requestClose'>) => (
      <FeedTargetingForm
        {...props}
        feed={feed}
        instructions={t('targeting_form.instructions')}
      />
    ),
    [feed, t],
  )

  if (feed.targeting == null) {
    return null
  } else {
    return (
      <TargetingSummary
        targeting={feed.targeting}
        FormComponent={BoundTargetingForm}
      />
    )
  }

})

export default FeedTargeting