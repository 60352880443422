import React from 'react'
import { ScriptEditorContainer } from '~/ui/app/scripts/editor/ScriptEditorContext'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ScriptEditor from '../editor/ScriptEditor'
import ScriptToolbar from './ScriptToolbar'

export interface Props {
  scriptID: string
}

const ScriptBody = memo('ScriptBody', (props: Props) => {

  const {scriptID} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ScriptEditorContainer scriptID={scriptID}>
        <VBox flex>
          <ScriptToolbar/>
          <VBox flex classNames={$.content}>
            <ScriptEditor/>
          </VBox>
        </VBox>
      </ScriptEditorContainer>
    )
  }

  return render()

})

export default ScriptBody

const useStyles = createUseStyles({
  content: {
    ...layout.responsive(size => ({
      margin:    -layout.padding.m[size],
      marginTop: 0,
    })),
  },
})