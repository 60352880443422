import React from 'react'
import { action, computed, makeObservable, observable } from 'mobx'
import { Variable } from '~/models'
import { VariantField, VariantLabel } from '~/ui/app/custom-data'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Label, Panel, PushButton, TextField, VBox } from '~/ui/components'
import { FormField, InlineForm, SubmitResult } from '~/ui/form'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  variable:       Variable | null
  onCommit:       (name: string, value: any) => Promise<SubmitResult | undefined>
  requestRemove?: (name: string) => any

  renderAsPanel?: boolean
  horizontal?:    boolean
}

const VariableBar = observer('VariableBar', (props: Props) => {

  const {
    variable,
    onCommit,
    requestRemove,
    renderAsPanel = true,
    horizontal = true,
  } = props

  const [editing, startEditing, stopEditing] = useBoolean()

  const nameFieldRef = React.useRef<HTMLInputElement>(null)
  const valueFieldRef = React.useRef<HTMLInputElement>(null)

  const {name, value} = variable ?? {name: '', value: null}
  const isNew = variable == null


  const formModel = React.useMemo(
    () => new VariableFormModel(variable, onCommit),
    [onCommit, variable],
  )

  const currentName = formModel.name
  const prevName    = usePrevious(currentName)

  const focusOnValue = React.useCallback((_, event: React.SyntheticEvent<any>) => {
    valueFieldRef.current?.focus()
    event.preventDefault()
  }, [])

  React.useEffect(() => {
    // Re-focus on the name field if the name is cleared.
    if (prevName !== '' && currentName === '') {
      setImmediate(() => {
        nameFieldRef.current?.focus()
      })
    }
  }, [currentName, name, prevName])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (renderAsPanel) {
      return (
        <Panel classNames={$.variableBarPanel} semi={isNew}>
          {renderContent()}
        </Panel>
      )
    } else {
      return renderContent()
    }
  }

  function renderContent() {
    return (
      <InlineForm model={formModel} afterSubmit={afterSubmit}>
        {horizontal ? (
          <HBox align={isNew ? 'stretch' : 'middle'} gap={layout.padding.inline.m}>
            <VBox flex={1}>
              {renderName()}
            </VBox>
            <VBox flex={2}>
              {renderValue()}
            </VBox>
            {renderButtons()}
          </HBox>
        ) : (
          <VBox gap={layout.padding.inline.m}>
            {renderName()}
            <HBox gap={layout.padding.inline.m}>
              {renderValue()}
              {renderButtons()}
            </HBox>
          </VBox>
        )}
      </InlineForm>
    )
  }

  function renderName() {
    if (isNew) {
      return (
        <FormField name='name' caption={false}>
          {bind => (
            <TextField
              {...bind}
              ref={nameFieldRef}
              onCommit={focusOnValue}
              mono
            />
          )}
        </FormField>
      )
    } else {
      return (
        <Label mono bold>
          {name}
        </Label>
      )
    }
  }

  function renderValue() {
    if (isNew || editing) {
      return (
        <FormField name='value' caption={false}>
          {bind => (
            <VariantField
              selectOnFocus={true}
              onCancel={stopEditing}
              ref={valueFieldRef}
              {...bind}
            />
          )}
        </FormField>
      )
    } else {
      return (
        <VariantLabel
          value={value}
        />
      )
    }
  }

  function renderButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {isNew || editing ? (
          <>
            <PushButton
              icon={isNew ? 'plus' : 'check'}
              enabled={!formModel.isEmpty}
              submit
              small
            />
            <ClearButton
              icon='cross'
              onTap={editing ? stopEditing : reset}
              small
              dim
            />
          </>
        ) : (
          <>
            {!editing && (
              <ClearButton
                icon='pencil'
                onTap={startEditing}
                small
              />
            )}
            {!editing && requestRemove != null && (
              <ClearButton
                icon='trash'
                onTap={remove}
                small
              />
            )}
          </>
        )}
      </HBox>
    )
  }

  const remove = React.useCallback(() => {
    requestRemove?.(name)
  }, [name, requestRemove])

  const reset = React.useCallback(() => {
    formModel.reset()
  }, [formModel])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
     formModel.reset()
     stopEditing()
    }
  }, [formModel, stopEditing])

  return render()

})

export default VariableBar

class VariableFormModel {

  constructor(
    private readonly variable:    Variable | null,
    private readonly onCommit: (name: string, value: any) => Promise<SubmitResult | undefined>,
  ) {
    makeObservable(this)
  }

  @observable
  public name = this.variable?.name ?? ''

  @observable
  public value = this.variable?.value ?? null

  @computed
  public get isEmpty() {
    return this.name.trim() === ''
  }

  public async submit() {
    return this.onCommit(this.name, this.value)
  }

  @action
  public reset() {
    this.name  = this.variable?.name ?? ''
    this.value = this.variable?.value ?? null
  }

}

const useStyles = createUseStyles({
  variableBarPanel: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },
})