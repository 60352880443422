import { makeObservable, observable } from 'mobx'
import { Access, AccessType, Group, Ref } from '~/models'
import { SubmitResult } from '~/stores'

export default abstract class AccessFormModel {

  constructor(
    private readonly access: Access | null,
  ) {
    makeObservable(this)
  }

  @observable
  public type: AccessType = this.access?.type ?? 'global'

  //------
  // Global access

  @observable
  public value: boolean = this.access?.type === 'global' ? this.access.value : false

  //------
  // Limited access

  @observable
  public group: Ref<Group> | null = this.access?.type === 'limited' ? this.access.group : null

  //-------
  // Submit

  public abstract submit(): Promise<SubmitResult | undefined>

  protected buildAccess() {
    return {
      type:  this.type,
      value: this.type === 'global'  ? this.value : undefined,
      group: this.type === 'limited' ? this.group : undefined,
    }
  }

}
