import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionOperator, NumericFeedback } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, NumberField, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {
  question: NumericFeedback
}

const NumericTestFields = memo('NumericTestFields', (props: Props) => {

  const {question} = props

  const [t] = useTranslation('conditions')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderPreamble()}
        {renderOperandField()}
      </VBox>
    )
  }

  function renderPreamble() {
    return (
      <HBox gap={layout.padding.inline.s} align='baseline'>
        <Label small dim>
          {t('tests.match.preamble.pre')}
        </Label>
        <FormField name='exact_match_operator' caption={false}>
          {bind => <SelectField {...bind} choices={operatorChoices} small inputStyle='dark'/>}
        </FormField>
        <Label small dim>
          {t('tests.match.preamble.post')}
        </Label>
      </HBox>
    )
  }

  function renderOperandField() {
    return (
      <FormField name='operand' caption={false}>
        {bind => <NumberField {...bind} minimum={question.min} maximum={question.max}/>}
      </FormField>
    )
  }

  const operatorChoices = React.useMemo((): Choice<ConditionOperator>[] => {
    return [
      {value: '==', caption: t('tests.match.preamble.operators.==')},
      {value: '!=', caption: t('tests.match.preamble.operators.!=')},
      {value: '>', caption: t('tests.match.preamble.operators.>')},
      {value: '<', caption: t('tests.match.preamble.operators.<')},
      {value: '>=', caption: t('tests.match.preamble.operators.>=')},
      {value: '<=', caption: t('tests.match.preamble.operators.<=')},
    ]
  }, [t])

  return render()

})

export default NumericTestFields