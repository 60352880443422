import React from 'react'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export type Props = VBoxProps

const SummaryPanelSection = memo('SummaryPanelSection', (props: Props) => {

  const {classNames, ...rest} = props
  const $ = useStyles()

  return (
    <VBox
      {...rest}
      classNames={[$.summaryPanelSection, classNames]}
    />
  )

})

export default SummaryPanelSection

const useStyles = createUseStyles({
  summaryPanelSection: {
    padding: layout.padding.inline.l,
  },
})