import { Branding } from './Branding'
import { ClientApp } from './ClientApp'
import { CustomImage } from './CustomImage'
import { Model, resource } from './Model'
import { Ref } from './types'

@resource<Organisation>('organisations', {
  icon:    'building',
  caption: organisation => organisation.name,
  scopedToModule: false,
})
export class Organisation extends Model {

  public name!:            string
  public logo!:            CustomImage | null
  public defaultApp!:      Ref<ClientApp>
  public defaultBranding!: Ref<Branding>

}