import React from 'react'
import { observer } from '~/ui/component'
import { ClearButton, HBox, HBoxProps, PushButton, VBox, VBoxProps } from '~/ui/components'
import { invokeFieldChangeCallback, useFieldChangeCallback } from '~/ui/form'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { FieldTransform } from './fieldTransform'
import { useForm } from './FormContext'
import FormErrors from './FormErrors'
import FormFieldHeader from './FormFieldHeader'
import { useFormField } from './hooks/useFormField'
import { InlineFormContext } from './InlineFormContext'

export interface Props<T> {
  name: string

  required?:    boolean
  clearOnEdit?: boolean

  caption?:       string | false
  renderView:     (value: T) => React.ReactNode
  renderEdit:     (bind: BindProps<T>) => React.ReactNode
  renderActions?: (editButton: React.ReactNode) => React.ReactNode

  flex?:    VBoxProps['flex']
  align?:   VBoxProps['align']

  transform?:  FieldTransform<T, any>
}

export interface BindProps<T> extends FormFieldBindProps<T> {
  onCommit: (value: T, event: React.SyntheticEvent) => any
}

const _InlineFormField = <T extends any = any>(props: Props<T>) => {

  const {
    name,
    required,
    clearOnEdit = false,
    renderView,
    renderEdit,
    renderActions,
    flex,
    align,
    transform,
  } = props

  const [value, onChange, errors, form] = useFormField<T>(name)

  const {fieldPrompt, fieldLabel, fieldPlaceholder, fieldInstruction} = useResourceTranslation()
  const caption     = props.caption === false ? null : (props.caption ?? fieldPrompt(name))
  const label       = fieldLabel(name)
  const placeholder = fieldPlaceholder(name)
  const instruction = fieldInstruction(name)

  const {submit, submitting} = useForm()

  const transformedValue = React.useMemo(() => {
    return transform?.toValue(value) ?? value
  }, [transform, value])

  const handleChangeWithTransform = useFieldChangeCallback(React.useCallback((value: T, partial?: boolean) => {
    if (transform == null) {
      return invokeFieldChangeCallback(onChange, value, partial)
    } else {
      return invokeFieldChangeCallback(onChange, transform.fromValue(value), partial)
    }
  }, [onChange, transform]))

  const bind = React.useMemo((): BindProps<T> => ({
    value:       transform == null ? value : transformedValue,
    onChange:    transform == null ? onChange : handleChangeWithTransform,
    invalid:     errors.length > 0,
    enabled:     form.submitting ? false : undefined,
    label:       label ?? undefined,
    placeholder: placeholder ?? undefined,
    onCommit:    (value, event) => {
      event.preventDefault()
      submit()
    },
    errors,
  }), [errors, form.submitting, handleChangeWithTransform, label, onChange, placeholder, submit, transform, transformedValue, value])

  const inlineForm   = React.useContext(InlineFormContext)

  const [editingState, startEditingState, stopEditingState] = useBoolean()
  const editing = inlineForm
    ? inlineForm.editingField === name
    : editingState

  const startEditing = React.useCallback(() => {
    if (inlineForm) {
      inlineForm.startEditing(name)
    } else {
      startEditingState()
    }
    if (clearOnEdit) {
      invokeFieldChangeCallback(onChange, null as T, true)
    }
  }, [inlineForm, name, onChange, clearOnEdit, startEditingState])

  const stopEditing = React.useCallback(() => {
    if (inlineForm) {
      inlineForm.stopEditing()
    } else {
      stopEditingState()
    }
  }, [inlineForm, stopEditingState])

  const cancel = React.useCallback(() => {
    stopEditing()
  }, [stopEditing])

  const commit = React.useCallback(() => {
    submit()
  }, [submit])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} gap={layout.padding.inline.s}>
        <FormFieldHeader
          caption={caption}
          instruction={instruction}
          required={required}
        />
        {editing ? (
          renderEditing()
        ) : (
          renderViewing()
        )}
      </VBox>
    )
  }

  //------
  // Viewing

  const bodyFlex = React.useMemo(() => {
    return align === 'stretch' ? 'both' : 'shrink'
  }, [align])

  const rowJustify = React.useMemo((): HBoxProps['justify'] => {
    switch (align) {
      case 'left':    return 'left'
      case 'center':  return 'center'
      case 'right':   return 'right'
      case 'stretch': return 'left'
    }
  }, [align])

  function renderViewing() {
    return (
      <HBox flex={flex} justify={rowJustify} gap={layout.padding.inline.m}>
        <VBox flex={bodyFlex}>
          {renderView(transformedValue)}
        </VBox>
        {renderActions != null ? (
          renderActions(renderEditButton())
        ) : (
          renderEditButton()
        )}
      </HBox>
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        onTap={startEditing}
        padding='none'
        small
      />
    )
  }

  //------
  // Editing

  function renderEditing() {
    return (
      <VBox>
        <VBox flex={flex} gap={layout.padding.inline.s} classNames={$.editing}>
          {renderBody()}
          {renderErrors()}
        </VBox>
      </VBox>
    )
  }

  function renderBody() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex>
          {renderEdit(bind)}
        </VBox>
        <HBox gap={layout.padding.inline.m}>
          {renderCommitButton()}
          {renderCancelButton()}
        </HBox>
      </HBox>
    )
  }

  function renderCommitButton() {
    return (
      <PushButton
        icon='check'
        onTap={commit}
        working={submitting}
        small
      />
    )
  }

  function renderCancelButton() {
    return (
      <ClearButton
        icon='cross'
        onTap={cancel}
        enabled={!submitting}
        padding='none'
        small
        dim
      />
    )
  }

  function renderErrors() {
    if (errors.length === 0) { return null }

    return (
      <FormErrors errors={errors}/>
    )
  }

  return render()

}

const InlineFormField = observer('InlineFormField', _InlineFormField) as typeof _InlineFormField
export default InlineFormField

const useStyles = createUseStyles({
  editing: {
    // margin: [
    //   `calc(0.6em - ${presets.fieldHeight.normal / 2}px)`,
    //   -presets.fieldPadding.horizontal
    // ]
  },
})