import React from 'react'
import { Link } from '~/models'
import { DynamicField } from '~/ui/app/dynamic-form'
import { LinkField } from '~/ui/app/links'
import { memo } from '~/ui/component'
import { fieldTransform, FormField } from '~/ui/form'
import { PlannerActionFieldsetProps, register } from './registry'

export type Props = PlannerActionFieldsetProps

const UnlockableFields = memo('UnlockableFields', (props: Props) => {

  const {schema} = props
  const pathField = schema.find(field => field.name === 'path') as DynamicField

  //------
  // Rendering

  function render() {
    return(
      <FormField name='path' caption={pathField.caption} transform={transform}>
        {bind => (
          <LinkField
            {...bind}
            includeTypes={['Page']}
          />
        )}
      </FormField>
    )
  }

  return render()

})

register('gamification:lock', UnlockableFields)
register('gamification:unlock', UnlockableFields)
export default UnlockableFields

const fromValue = (link: Link | null) => (
  link?.href ?? null
)

const toValue = (path: string | null) => {
  if (path == null) { return null }
  return {
    href: path,
    resolve: false,
    inAppBrowser: false,
  }
}

const transform = fieldTransform<Link | null, string | null>(fromValue, toValue)