import { action, makeObservable, observable, reaction } from 'mobx'
import { slugify } from 'ytil'
import { Link, LocalizedString, MenuPageItem, Module, Page, PageType, Ref } from '~/models'
import { FormModel, SubmitResult } from '~/ui/form'
import PagesContext from '../PagesContext'

export default class PageFormModel implements FormModel {

  constructor(
    private readonly pageID: string | null,
    public readonly type: PageType = 'content',
    private readonly context: PagesContext,

    private readonly defaults: Partial<Page> = {},
    private readonly parentMenuPage: Page | null = null,
    private readonly root: boolean = false,
  ) {
    this.defaults = defaults
    this.reset()

    makeObservable(this)
    reaction(() => [this.title, this.autoGenerateSlug], () => {
      if (!this.autoGenerateSlug) { return }
      this.slug = slugify(LocalizedString.translate(this.title))

    })
  }

  @action
  public reset() {
    this.module = null
    this.title = {}
    this.slug = ''
    this.autoGenerateSlug = this.root ? false : this.pageID == null
    Object.assign(this, this.defaults)
  }

  @observable
  public module: Ref<Module> | null = null

  @observable
  public title: LocalizedString = {}

  @observable
  public slug: string = ''

  @observable
  public autoGenerateSlug: boolean = this.root ? false : this.pageID == null

  @action
  public toggleAutoGenerateSlug() {
    this.autoGenerateSlug = !this.autoGenerateSlug
  }

  @observable
  public itemType: Page['itemType'] = 'Participant'

  public async submit(): Promise<SubmitResult | undefined> {
    const data = this.buildData()
    const result = this.pageID == null
      ? await this.context.createPage(data)
      : await this.context.updatePage(this.pageID, data)
    if (result?.status !== 'ok') { return result }

    await this.addToParentMenu()
    return result
  }

  protected buildData() {
    const data: AnyObject = {
      module:   this.module,
      type:     this.type,
      title:    this.title,
      slug:     this.slug,
      itemType: this.type === 'index'? this.itemType : undefined,
    }

    if (this.pageID == null && data.type === 'content') {
      data.body = {}
      for (const [locale, title] of Object.entries(this.title)) {
        data.body[locale] = `# ${title}\n\n`
      }
    }
    return data
  }

  protected async addToParentMenu() {
    const {parentMenuPage} = this
    if (parentMenuPage?.type !== 'menu') { return }

    const item: MenuPageItem = {
      caption: this.title,
      detail:  null,
      image:   null,
      link:    Link.to(`//page/${this.slug}`),
    }

    const items = [...parentMenuPage.items, item]
    await this.context.updatePage(parentMenuPage.id, {items})
  }

}