import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import clipboard from 'rich-clipboard'
import config from '~/config'
import { TriggerNode, WebhookTrigger } from '~/models'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Empty,
  HBox,
  ModalDialog,
  SegmentedButton,
  Spinner,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import webhookTriggerExamples, { WebhookTriggerExampleType } from './webhookTriggerExamples'

export interface Props {
  open:         boolean
  requestClose: () => any

  node: TriggerNode & {trigger: WebhookTrigger}
}

const WebhookTriggerDialog = observer('WebhookTriggerDialog', (props: Props) => {

  const {open, requestClose, node} = props
  const {planner} = useFlowPlanner()

  const [t] = useTranslation('flow_planner')

  const [token, setToken] = React.useState<string | null>(null)
  const [obtainingToken, setObtainingToken] = React.useState<boolean>(false)
  const timer = useTimer()

  React.useEffect(() => {
    if (planner == null) { return }

    setObtainingToken(true)
    timer.await(planner.service.obtainWebhookToken(node.uuid)).then(token => {
      setToken(token)
      setObtainingToken(false)
    })
  }, [node.uuid, planner, timer])

  const triggerURL = React.useMemo(() => {
    return `${config.urls.api}/webhook/v1/${token}`
  }, [token])

  const [exampleType, setExampleType] = React.useState<WebhookTriggerExampleType>('raw')

  const examples = React.useMemo(
    () => token == null ? null : webhookTriggerExamples(node.trigger, triggerURL),
    [node.trigger, token, triggerURL],
  )

  const exampleSegments = React.useMemo(
    () => examples == null ? [] : Object.keys(examples).map(it => ({
      value:   it,
      caption: t(`webhook_trigger_dialog.examples.${it}`),
    })),
    [examples, t],
  )

  //------
  // Actions

  const $ = useStyles()

  const copyToClipboard = React.useCallback(() => {
    clipboard.write([{type: 'text/plain', data: triggerURL}])
    Toast.show({
      ...t('webhook_trigger_dialog.copy_to_clipboard.success'),
      type: 'success',
    })
  }, [t, triggerURL])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        icon='web'
        title={t('webhook_trigger_dialog.title')}
        width={640}
        headerRight='$close'
        closeOnClickOutside={true}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox padding={layout.padding.m} gap={layout.padding.m}>
        {renderLink()}
      </VBox>
    )
  }

  function renderLink() {
    return (
      <VBox gap={layout.padding.s}>
        {renderInstructions()}
        {token == null ? (
          renderEmpty()
        ) : (
          <>
            {renderURLField()}
            {renderBodyExample()}
          </>
        )}
      </VBox>
    )
  }

  function renderInstructions() {
    return (
      <TextBlock small dim markup>
        {t(`webhook_trigger_dialog.instructions.${node.trigger.collective ? 'collective' : 'individual'}`)}
      </TextBlock>
    )
  }

  function renderEmpty() {
    if (obtainingToken) {
      return (
        <Center>
          <Spinner/>
        </Center>
      )
    } else {
      return (
        <Empty
          {...t('webhook_trigger_dialog.error')}
        />
      )
    }
  }

  function renderURLField() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex>
          <TextField
            value={triggerURL}
            classNames={$.link}
            selectOnFocus
            readOnly
            mono
          />
        </VBox>
        <ClearButton
          icon='copy'
          caption={t('webhook_trigger_dialog.copy_to_clipboard.caption')}
          onTap={copyToClipboard}
        />
      </HBox>
    )
  }

  function renderBodyExample() {
    if (examples == null) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <Center>
          <SegmentedButton<WebhookTriggerExampleType>
            segments={exampleSegments}
            selectedValue={exampleType}
            onChange={setExampleType}
            small
          />
        </Center>
        <TextField
          value={examples?.[exampleType] ?? ''}
          multiline
          height={160}
          readOnly
          mono
        />
      </VBox>
    )
  }

  return render()

})

export default WebhookTriggerDialog

const useStyles = createUseStyles({
  link: {},

  qrCodeContainer: {
    width:  128,
    height: 128,
  },

  qrSVG: {},

  qrCanvas: {
    display: 'none',
  },
})