import React from 'react'
import { range } from 'lodash'
import { svgPath } from 'ytil'
import { DataPoint } from '~/stores'
import { memo } from '~/ui/component'
import { AutosizeSVG, AutosizeSVGProps } from '~/ui/components'
import { createUseStyles, layout, PaletteKey, shadows, useStyling } from '~/ui/styling'
import { useQualifiedID } from './chart/hooks'

export interface Props extends AutosizeSVGProps {
  data:    DataPoint[]
  palette: PaletteKey

  valueForPoint: (point: DataPoint) => number

  legendLines?:     'left' | 'right' | false
  onSegmentHeight?: (segmentHeight: number) => any
}

const Funnel = memo('Funnel', (props: Props) => {

  const {
    data,
    palette,
    flex,
    align = 'center',
    valueForPoint,
    onSegmentHeight,
    legendLines = false,
    ...rest
  } = props

  const {colors} = useStyling()

  const [contentSize, setContentSize] = React.useState<Size | null>(null)

  const maskID     = useQualifiedID('mask')
  const gradientID = useQualifiedID('gradient')

  //------
  // Layout

  const plotRect = React.useMemo(() => {
    if (contentSize == null) { return null }

    switch (legendLines) {
      case false:
        return {x: 0, y: 0, ...contentSize}
      case 'left':
        return {
          x:      legendLineOffset,
          y:      0,
          width:  contentSize.width - legendLineOffset,
          height: contentSize.height,
        }
      case 'right':
        return {
          x: 0,
          y: 0,
          width:  contentSize.width - legendLineOffset,
          height: contentSize.height,
        }
    }
  }, [contentSize, legendLines])

  const segmentHeight = React.useMemo(() => {
    if (plotRect == null) { return 0 }
    if (data.length < 2) { return 0 }

    return plotRect.height / (data.length - 1)
  }, [data.length, plotRect])

  const max = React.useMemo(
    () => Math.max(...data.map(it => it.value)) ?? 0,
    [data],
  )

  const segments = React.useMemo((): Array<[Point, Point, Point, Point]> => {
    if (data.length < 2) { return [] }
    if (plotRect == null) { return [] }

    const {width} = plotRect
    const midX    = width / 2

    return range(0, data.length - 1).map(index => {
      const f1 = max === 0 ? 0 : valueForPoint(data[index]) / max
      const f2 = max === 0 ? 0 : valueForPoint(data[index + 1]) / max
      const w1 = Math.max(minWidth, f1 * width)
      const w2 = Math.max(minWidth, f2 * width)
      const y1 = index * segmentHeight
      const y2 = (index + 1) * segmentHeight

      return [
        {x: plotRect.x + Math.round(midX - w1 / 2), y: Math.round(y1)},
        {x: plotRect.x + Math.round(midX + w1 / 2), y: Math.round(y1)},
        {x: plotRect.x + Math.round(midX + w2 / 2), y: Math.round(y2)},
        {x: plotRect.x + Math.round(midX - w2 / 2), y: Math.round(y2)},
      ]
    })
  }, [data, plotRect, max, valueForPoint, segmentHeight])

  const basePoints = React.useMemo((): Point[] => {
    if (contentSize == null) { return [] }
    if (segments.length < 1) { return [] }

    const left: Point[] = [
      {x: contentSize.width / 2, y: 0},
      segments[0][0],
      segments[0][3],
    ]
    const right:  Point[] = [
      segments[0][1],
      segments[0][2],
    ]

    for (let i = 1; i < segments.length; i++) {
      right.push(segments[i][2]) // bottom right
      left.push(segments[i][3]) // bottom left
    }

    return [
      ...left,
      ...right.reverse(),
    ]
  }, [contentSize, segments])

  const basePathData = React.useMemo(
    () => svgPath(basePoints, {close: true, roundCorners: cornerRadius}),
    [basePoints],
  )

  const legendLineLayout = React.useCallback((segment: [Point, Point, Point, Point], top: boolean) => {
    const width = contentSize?.width ?? 0
    const left  = legendLines === 'left'
    const point =
      top && left ? segment[0] :
      top && !left ? segment[1] :
      !top && !left ? segment[2] :
      segment[3]

    // Round and offset by 0.5 to get crisp lines on non-retina displays.
    const y = Math.round(point.y) + 0.5
    const x = legendLines === 'right'
      ? Math.round(point.x + legendLineMargin) + 0.5
      : Math.round(point.x - legendLineMargin) + 0.5

    if (legendLines === 'right') {
      return {x1: x, y1: y, x2: width, y2: y}
    } else if (legendLines === 'left') {
      return {x1: 0, y1: y, x2: x, y2: y}
    } else {
      return {}
    }
  }, [contentSize?.width, legendLines])

  //------
  // Effects

  React.useLayoutEffect(() => {
    onSegmentHeight?.(segmentHeight)
  }, [onSegmentHeight, segmentHeight])

  //------
  // Render

  const $ = useStyles()

  function render() {
    return (
      <AutosizeSVG flex={flex} align={align} {...rest} onContentSize={setContentSize}>
        {renderDefs()}
        {renderBase()}
        {renderSegments()}
        {renderOverlay()}
        {renderLegendLines()}
      </AutosizeSVG>
    )
  }

  //------
  // Outline

  function renderDefs() {
    return (
      <defs>
        <mask id={maskID}>
          <path d={basePathData} fill="white"/>
        </mask>
      </defs>
    )
  }

  function renderBase() {
    return (
      <path
        d={basePathData}
        classNames={$.base}
      />
    )
  }

  function renderOverlay() {
    if (basePathData == null) { return null }

    return (
      <>
        <defs>
          <radialGradient id={gradientID} cx={0.75} cy={0.75} r={1}>
            <stop offset='70%' stopColor='white' stopOpacity={0}/>
            <stop offset='85%' stopColor='white' stopOpacity={0.2}/>
            <stop offset='100%' stopColor='white' stopOpacity={0}/>
          </radialGradient>
        </defs>

        <path
          d={basePathData}
          fill={`url(#${gradientID})`}
        />
      </>
    )
  }

  //------
  // Segments

  function renderSegments() {
    const width = contentSize?.width ?? 0
    return (
      <g mask={`url(#${maskID})`}>
        {segments.map(([topLeft, , bottomRight], index) => (
          <rect
            key={data[index].key}
            x={0}
            y={topLeft.y}
            width={width}
            height={bottomRight.y - topLeft.y}
            classNames={$.segment}
            fill={colors.palette(palette, index).string()}
          />
        ))}
      </g>
    )
  }

  function renderLegendLines() {
    if (segments.length === 0) { return null }

    return (
      <g>
        <line
          {...legendLineLayout(segments[0], true)}
          classNames={$.legendLine}
        />
        {segments.map((segment, index) => (
          <line
            key={data[index].key}
            {...legendLineLayout(segment, false)}
            classNames={$.legendLine}
          />
        ))}
      </g>
    )
  }

  return render()

})

export const minWidth = 4
export const cornerRadius = layout.radius.s
export const minLegendLineWidth = 16
export const legendLineMargin = layout.padding.inline.l
export const legendLineOffset = minLegendLineWidth + legendLineMargin

export default Funnel

const useStyles = createUseStyles(theme => ({

  base: {
    filter: `drop-shadow(1px 1px 2px ${shadows.shadowColor})`,
  },

  segment: {
  },

  legendLine: {
    stroke:      theme.fg.dimmer,
    strokeWidth: 0.5,
  },

}))