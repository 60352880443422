import React from 'react'
import { Review } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, Panel, TextBlock, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { layout } from '~/ui/styling'
import { ReviewQuestionDescriptor } from '../hooks'
import ReviewAnswerView from './ReviewAnswerView'

export interface Props {
  review:   Review
  question: ReviewQuestionDescriptor
}

const ReviewSummaryPanel = observer('ReviewSummaryPanel', (props: Props) => {

  const {review, question} = props

  //------
  // Rendering

  function render() {
    return (
      <Panel padding={layout.padding.m} gap={layout.padding.m}>
        {renderQuestion()}
        <Dimple horizontal/>
        {renderAnswer()}
      </Panel>
    )
  }

  function renderQuestion() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <FormFieldHeader
          name='question'
        />
        <TextBlock>
          {question.text}
        </TextBlock>
      </VBox>
    )
  }

  function renderAnswer() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <FormFieldHeader
          name='answer'
        />
        <ReviewAnswerView
          review={review}
          question={question}
        />
      </VBox>
    )
  }

  return render()

})

export default ReviewSummaryPanel