import React from 'react'
import { isRightMouse } from 'react-dnd'
import { hasBitmask } from 'ytil'
import config from '~/config'
import {
  isTriggerableNode,
  PlanComponent,
  PlanNodeConnector,
  PlanNodeConnectorMask,
  PlanSegueConnection,
} from '~/models'
import { memo } from '~/ui/component'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'
import { groupPadding } from '../triggerables/TriggerableNodeView'
import { borderRadiusForPlannerComponent } from './layout'

export interface Props {
  component:     PlanComponent
  bounds:        LayoutRect
  connectors:    PlanNodeConnector | PlanNodeConnectorMask
  showSelected?: boolean

  zoom:   number

  onStart:   (connection: PlanSegueConnection, event: MouseEvent | TouchEvent) => any
  onPreview: (connection: PlanSegueConnection | null, event: MouseEvent | TouchEvent) => any
  onEnd:     (connection: PlanSegueConnection, event: MouseEvent | TouchEvent) => any
}

const ConnectHandles = memo('ConnectHandles', (props: Props) => {

  const {component, connectors, showSelected, zoom, onStart, onPreview, onEnd} = props
  const {uuid} = component

  const handleStyle: React.CSSProperties = {
    transform: `scale(${1 / zoom})`,
  }

  const theme = useTheme()

  // If this is a group of triggerables, it will have a thick border around it. Make sure to place the connect handles
  // at the very edge of the border.
  const isGroup = isTriggerableNode(component) && component.triggerables.length > 1

  let {bounds} = props
  if (isGroup) {
    bounds = {
      top:    bounds.top - groupPadding,
      left:   bounds.left - groupPadding,
      width:  bounds.width + 2 * groupPadding,
      height: bounds.height + 2 * groupPadding,
    }
  }

  const borderRadius = borderRadiusForPlannerComponent(theme, component, bounds)

  const fitsInLayout = React.useCallback((connector: PlanNodeConnector) => {
    if (hasBitmask(connector, PlanNodeConnectorMask.CENTER)) {
      return true
    } else if (hasBitmask(connector, PlanNodeConnectorMask.HORIZONTAL)) {
      return bounds.height >= config.planner.minSizeForThreeConnectors
    } else if (hasBitmask(connector, PlanNodeConnectorMask.VERTICAL)) {
      return bounds.width >= config.planner.minSizeForThreeConnectors
    } else {
      return true
    }
  }, [bounds.height, bounds.width])

  const connectorsToRender = Object.values(PlanNodeConnector)
    .filter(it => hasBitmask(it, connectors))
    .filter(it => fitsInLayout(it))

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div key={uuid} classNames={$.connectHandles} style={{...bounds, borderRadius, borderWidth: `${1 / zoom}px`}}>
        {connectorsToRender.map(renderHandle)}
      </div>
    )
  }

  function renderHandle(connector: PlanNodeConnector) {
    const classNames = [
      $.connectHandle,
      hasBitmask(connector, PlanNodeConnectorMask.NORTH) && 'north',
      hasBitmask(connector, PlanNodeConnectorMask.EAST) && 'east',
      hasBitmask(connector, PlanNodeConnectorMask.SOUTH) && 'south',
      hasBitmask(connector, PlanNodeConnectorMask.WEST) && 'west',
      hasBitmask(connector, PlanNodeConnectorMask.LEADING) && 'leading',
      hasBitmask(connector, PlanNodeConnectorMask.CENTER) && 'center',
      hasBitmask(connector, PlanNodeConnectorMask.TRAILING) && 'trailing',
      {selected: showSelected},
    ]

    return (
      <div
        key={connector}
        classNames={classNames}
        style={{...handleStyle}}
        {...connectHandlers(connector)}
      />
    )
  }

  //------
  // Handlers

  const connectHandlers = React.useCallback((connector: PlanNodeConnector) => {
    const start = (event: React.MouseEvent | React.TouchEvent) => {
      if (isRightMouse(event.nativeEvent)) { return }
      if (closest(event.target, isInteractiveElement) != null) { return }

      onStart({node: uuid, connector}, event.nativeEvent)
      event.preventDefault()
    }
    const enter = (event: React.MouseEvent | React.TouchEvent) => {
      onPreview({node: uuid, connector}, event.nativeEvent)
      event.preventDefault()
    }
    const leave = (event: React.MouseEvent | React.TouchEvent) => {
      onPreview(null, event.nativeEvent)
      event.preventDefault()
    }
    const end = (event: React.MouseEvent | React.TouchEvent) => {
      onEnd({node: uuid, connector}, event.nativeEvent)
      event.preventDefault()
    }

    return {
      onMouseDown:  start,
      onTouchStart: start,
      onMouseUp:    end,
      onTouchEnd:   end,
      onMouseEnter: enter,
      onMouseLeave: leave,
    }
  }, [onEnd, onPreview, onStart, uuid])

  return render()

})

export const size = {
  short: 8,
  long:  16,
}

const useStyles = createUseStyles(theme => ({
  connectHandles: {
    position:     'absolute',
    background:   theme.semantic.secondary.alpha(0.1),
    borderRadius: layout.radius.s,

    cursor: 'crosshair',
    pointerEvents: 'auto',
  },

  connectHandle: {
    position:   'absolute',

    boxShadow:    [0, 0, 1, 1, theme.semantic.secondary],
    borderRadius: size.short / 2,

    '&:not(.selected)': {
      background: theme.semantic.secondary.alpha(0.6),
      '&:hover': {
        boxShadow:  [0, 0, 1, 1, theme.semantic.primary],
        background: theme.semantic.primary.alpha(0.6),
      },
    },
    '&.selected': {
      boxShadow:    [0, 0, 1, 1, theme.semantic.primary],
      background: theme.semantic.primary.alpha(0.6),
      '&:hover': {
        background: theme.semantic.primary,
      },
    },

    cursor: 'crosshair',

    '& > *': {
      position:   'absolute',
      marginTop:  -size.short / 2,
      marginLeft: -size.short / 2,
    },

    '&.north, &.south': {
      width:      size.long,
      height:     size.short,
      marginLeft: -size.long / 2,
    },

    '&.east, &.west': {
      width:     size.short,
      height:    size.long,
      marginTop: -size.long / 2,
    },

    '&.north':    {
      top: -size.short / 2,
    },
    '&.east':  {
      right: -size.short / 2,
    },
    '&.south': {
      bottom: -size.short / 2,
    },
    '&.west':   {
      left: -size.short / 2,
    },
    '&.north,&.south': {
      '&.leading':  {left: `${100*1/4}%`},
      '&.center':   {left: `${100*2/4}%`},
      '&.trailing': {left: `${100*3/4}%`},
    },
    '&.east,&.west': {
      '&.leading':  {top: `${100*1/4}%`},
      '&.center':   {top: `${100*2/4}%`},
      '&.trailing': {top: `${100*3/4}%`},
    },
  },
}))

export default ConnectHandles