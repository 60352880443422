import React from 'react'
import { createOptimizedContext } from 'react-optimized-context'
import { PlanComponent } from '~/models'
import { ComponentBounds } from '~/stores'
import { observer } from '~/ui/component'
import { useFlowPlanner } from '../FlowPlannerContext'
import { SelectionBounds, SelectionManager } from './SelectionManager'

export interface SelectionContext {
  selectedUUIDs:      string[]
  selectedBounds:     ComponentBounds[]
  selectedComponents: PlanComponent[]
  selectionBounds:    SelectionBounds | null
}

export interface SelectionContextOptimized {
  getSelectedUUIDs:  () => string[]
  getSelectedBounds: () => ComponentBounds[]
  manager:            SelectionManager | null
}

export const SelectionContext = createOptimizedContext<SelectionContext, SelectionContextOptimized>({
  selectedUUIDs:      [],
  selectedComponents: [],
  selectedBounds:     [],
  selectionBounds:    null,

  getSelectedUUIDs:  () => [],
  getSelectedBounds: () => [],
  manager:           null,
})

export const useSelection = SelectionContext.useHook

export interface SelectionContextProviderProps {
  children?: React.ReactNode
}

export const SelectionContextProvider = observer('SelectionContextProvider', (props: SelectionContextProviderProps) => {
  const {children} = props

  const manager = React.useMemo(() => new SelectionManager(), [])

  const {planner} = useFlowPlanner()
  if (planner != null) {
    manager.setPlanner(planner)
  }

  React.useEffect(() => {
    return () => {
      manager?.dispose()
    }
  }, [manager])

  const selectedUUIDs      = manager.selectedUUIDs
  const selectedComponents = manager.selectedComponents
  const selectedBounds     = manager.selectedBounds
  const selectionBounds    = manager.selectionBounds

  const context = React.useMemo((): SelectionContext => ({
    selectedUUIDs,
    selectedComponents,
    selectedBounds,
    selectionBounds,
  }), [selectedBounds, selectedComponents, selectedUUIDs, selectionBounds])

  const optimizedContext = React.useMemo((): SelectionContextOptimized => ({
    getSelectedUUIDs:  () => manager.selectedUUIDs,
    getSelectedBounds: () => manager.selectedBounds,
    manager,
  }), [manager])

  //------
  // Render

  return (
    <SelectionContext.Provider value={context} optimizedValue={optimizedContext}>
      {children}
    </SelectionContext.Provider>
  )
})