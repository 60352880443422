import React from 'react'
import { Organisation } from '~/models'
import ProjectLogo from '~/ui/app/projects/ProjectLogo'
import { observer } from '~/ui/component'
import { DataGrid, HBox, Label } from '~/ui/components'
import { ResourceListScreen } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'
import OrganisationForm from '../OrganisationForm'

const OrganisationsScreen = observer('OrganisationsScreen', () => {

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Organisation}
        include={['projects']}
        defaultSort='name'
        CreateFormComponent={OrganisationForm}
        allowRemove={false}
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
      </ResourceListScreen>
    )
  }

  function renderName(organisation: Organisation) {
    return (
      <HBox gap={layout.padding.s}>
        <ProjectLogo logo={organisation.logo}/>
        <Label bold>
          {organisation.name}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default OrganisationsScreen