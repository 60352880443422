import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Feed, Group, Ref } from '~/models'
import { dataStore } from '~/stores'
import { GroupField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { SwitchField, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField, FormModel, SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  feed: Feed
}

const FeedModerationForm = observer('FeedModerationForm', (props: Props) => {

  const {open, requestClose, feed} = props

  const formModel = React.useMemo(
    () => new FeedModerationFormModel(feed),
    [feed],
  )

  const {t} = useResourceTranslation('feeds')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}

        icon='headset'
        title={t('moderation.title')}
        children={renderForm()}
      />
    )
  }

  function renderForm() {
    return (
      <VBox gap={layout.padding.s}>
        <TextBlock small dim>
          {t('moderation.preamble')}
        </TextBlock>

        <FormField name='enabled' caption={false}>
          {bind => (
            <SwitchField
              {...bind}
              label={{
                on:  t('moderation.summary.enabled'),
                off: t('moderation.summary.disabled'),
              }}
            />
          )}
        </FormField>

        {formModel.enabled && (
          <FormField name='moderatorGroup' caption={t('moderation.group')} required>
            {bind => <GroupField {...bind} multi={false}/>}
          </FormField>
        )}
      </VBox>
    )
  }

  return render()

})

export default FeedModerationForm


class FeedModerationFormModel implements FormModel {

  constructor(
    private readonly feed: Feed,
  ) {
    makeObservable(this)
  }

  @observable
  public enabled: boolean = this.feed.moderation != null

  @observable
  public moderatorGroup: Ref<Group> | null = this.feed.moderation?.moderatorGroup ?? null

  public async submit(): Promise<SubmitResult | undefined> {
    const update = this.buildUpdate()
    const result = await dataStore.update(Feed, this.feed.id, {moderation: update})
    return translateFormErrorPaths(result, path => path.replace(/^moderation\./, ''))
  }

  private buildUpdate() {
    if (!this.enabled) { return null }

    return {
      moderatorGroup: this.moderatorGroup,
    }
  }

}