import React from 'react'
import I18next from 'i18next'
import { some } from 'lodash'
import { Variable } from '~/models'
import VariableBar from '~/ui/app/custom-data/VariableBar'
import { memo } from '~/ui/component'
import { Dimple, List, VBox } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  value:     Variable[]
  onChange?: (data: Variable[]) => any
}

const ParamsList = memo('ParamsList', (props: Props) => {

  const {value: data, onChange, ...rest} = props

  //------
  // Save handlers

  const addParam = React.useCallback((name: string, value: any) => {
    if (some(data, it => it.name === name.toLocaleLowerCase())) {
      return Promise.resolve<SubmitResult>({
        status: 'invalid',
        errors: [{
          field: 'name',
          message: I18next.t('validation:unique'),
        }],
      })
    }

    const nextData = [
      ...data,
      {name, value, updatedAt: new Date()},
    ]

    onChange?.(nextData)
    return Promise.resolve(void 0)
  }, [data, onChange])

  const updateParam = React.useCallback((name: string, value: any) => {
    const nextData = data.map((param): Variable => {
      if (param.name !== name) {
        return param
      } else {
        return {name, value, updatedAt: new Date()}
      }
    })

    onChange?.(nextData)
    return Promise.resolve(void 0)
  }, [data, onChange])

  const removeParam = React.useCallback((name: string) => {
    const nextData = data.filter(param => param.name !== name)

    onChange?.(nextData)
    return Promise.resolve(void 0)
  }, [data, onChange])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='custom-data'>
        <VBox flex>
          {renderList()}
          {renderAddParam()}
        </VBox>
      </ResourceTypeProvider>
    )
  }

  function renderList() {
    return (
      <VBox flex padding={layout.padding.s}>
        <List<Variable>
          data={data}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          itemGap={layout.padding.inline.s}
          classNames={$.list}
          contentClassNames={$.listContent}
          scrollable
          flex
          {...rest}
        />
      </VBox>
    )
  }

  const keyExtractor = React.useCallback(
    (param: Variable) => param.name,
    [],
  )

  const renderItem = React.useCallback((param: Variable) => {
    return (
      <VariableBar
        variable={param}
        onCommit={updateParam}
        requestRemove={removeParam}
      />
    )
  }, [removeParam, updateParam])

  function renderAddParam() {
    return (
      <VBox classNames={$.addParam}>
        <Dimple horizontal/>
        <VBox padding={layout.padding.s}>
          <VariableBar
            variable={null}
            onCommit={addParam}
            renderAsPanel={false}
            horizontal={false}
          />
        </VBox>
      </VBox>
    )
  }

  return render()

})

export default ParamsList

const useStyles = createUseStyles({
  list: {
    margin: -2,
  },

  listContent: {
    padding: 2,
  },

  addParam: {
    paddingTop: layout.padding.inline.l,
  },
})