import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackFormModel } from '~/ui/app/feedback'
import { observer } from '~/ui/component'
import {
  Center,
  NumberField,
  RatingStars,
  SegmentedButton,
  SelectField,
  SwitchField,
  TextField,
  VBox,
} from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'

const NumericFeedbackFields = observer('NumericFeedbackFields', () => {

  const {model} = useForm<FeedbackFormModel>()

  const [t] = useTranslation('feedback')

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <Center>
          {renderStyleSelector()}
        </Center>
        {model.rating_style === 'slider' ? (
          renderSliderFields()
        ) : (
          renderStarsFields()
        )}
      </VBox>
    )
  }

  function renderStyleSelector() {
    return (
      <SegmentedButton<'stars' | 'slider'>
        selectedValue={model.rating_style}
        onChange={value => { model.rating_style = value }}
        segments={[
          {value: 'slider', caption: t('rating.styles.slider')},
          {value: 'stars', caption: t('rating.styles.stars')},
        ]}
      />
    )
  }

  const scaleChoices = t('rating.scales', {default: []}) as any[]

  function renderSliderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='emoji'>
          {bind => <TextField {...bind} maxLength={1} showClearButton='always'/>}
        </FormField>
        <FormField name='scale'>
          {bind => <SelectField {...bind} choices={scaleChoices}/>}
        </FormField>
        {model.scale === '$custom' && (
          <>
            <FormField name='lowerLabel'>
              {bind => <TextField {...bind}/>}
            </FormField>
            <FormField name='upperLabel'>
              {bind => <TextField {...bind}/>}
            </FormField>
          </>
        )}
      </VBox>
    )
  }

  function renderStarsFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='maxRating' required>
          {bind => (
            <NumberField
              {...bind}
              accessoryRight={renderStarsPreview()}
              minimum={2}
              maximum={6}
              step={1}
            />
          )}
        </FormField>
        <FormField name='halfStars'>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderStarsPreview() {
    return (
      <RatingStars
        value={model.maxRating}
        max={Math.min(10, model.maxRating)}
      />
    )
  }

  return render()

})

export default NumericFeedbackFields