import React from 'react'
import Semaphore from 'semaphore'
import { Review } from '~/models'
import { authenticationStore, BulkSelector, dataStore } from '~/stores'
import AnswerScopeField from '~/ui/app/answers/explore/AnswerScopeField'
import { ParticipantField } from '~/ui/app/fields'
import ParticipantCell from '~/ui/app/participants/ParticipantCell'
import UserCell from '~/ui/app/users/UserCell'
import { memo } from '~/ui/component'
import { Chip, DataGrid, HBox, Label } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, filterTransform, ResourceListScreen } from '~/ui/resources/collection'
import { layout, useTheme } from '~/ui/styling'
import ResourceFilter from '../../../resources/collection/filters/ResourceFilter'
import { AnswerScopeLinkage } from '../../answers/explore/AnswerScopeField'
import UserField from '../../users/UserField'
import BulkAssignForm from '../BulkAssignForm'
import { colorForStatus } from '../styles'
import QuestionCell from './QuestionCell'

const ReviewsScreen = memo('ReviewsScreen', () => {

  const {t} = useResourceTranslation('reviews')
  const theme = useTheme()

  //------
  // Bulk actions

  const [bulkAssignFormOpen, openBulkAssignForm, closeBulkAssignForm] = useBoolean()
  const [bulkSelector, setBulkSelector] = React.useState<BulkSelector | null>(null)
  const assignCompleteRef = React.useRef(new Semaphore({autoReset: true}))

  const bulkAssign = React.useCallback(async (_, selector: BulkSelector) => {
    setBulkSelector(selector)
    openBulkAssignForm()
    await assignCompleteRef.current
  }, [openBulkAssignForm])

  const stopBulkAssign = React.useCallback(() => {
    assignCompleteRef.current.signal()
    closeBulkAssignForm()
  }, [closeBulkAssignForm])

  const bulkSetPending = React.useCallback(async (_, selector: BulkSelector) => {
    const endpoint = dataStore.endpoint(Review)
    return endpoint.callBulkAction('set-status', {
      ...selector,
      meta: {...selector.meta, status: 'pending'},
    })
  }, [])

  const bulkComplete = React.useCallback(async (_, selector: BulkSelector) => {
    const endpoint = dataStore.endpoint(Review)
    return endpoint.callBulkAction('set-status', {
      ...selector,
      meta: {...selector.meta, status: 'reviewed'},
    })
  }, [])

  const me = authenticationStore.user

  const bulkActions = React.useMemo(() => {
    const actions: BulkAction[] = []
    if (!me?.isEditor()) { return actions }

    if (me.organisation != null) {
      actions.push({
        name:    'bulk-assign',
        execute: bulkAssign,
      })
    }

    actions.push({
      name:    'bulk-set-pending',
      execute: bulkSetPending,
      refetch: true,
    })
    actions.push({
      name:    'bulk-complete',
      execute: bulkComplete,
      refetch: true,
    })

    return actions
  }, [bulkAssign, bulkComplete, bulkSetPending, me])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Review}
        include={['question', 'participant', 'reviewer', 'participant/groups']}
        labels={['pending', 'review', 'done']}
        allowRemove={false}
        defaultSort='name'
        searchable={false}
        bulkActions={bulkActions}
      >
        <DataGrid.Column<Review>
          name='question'
          sort={true}
          renderCell={review => <QuestionCell review={review}/>}
          flex={5}
        />
        <DataGrid.Column<Review>
          name='participant'
          sort={true}
          renderCell={renderParticipant}
          flex={3}
        />
        <DataGrid.Column<Review>
          name='reviewer'
          sort={true}
          renderCell={renderReviewer}
          flex={3}
        />
        <DataGrid.Column<Review>
          name='status'
          sort={true}
          renderCell={renderStatus}
          width={120}
        />

        <ResourceListScreen.Filters>
          <ResourceFilter name='participant'>
            {bind => <ParticipantField {...bind} inputStyle='dark'/>}
          </ResourceFilter>
          <ResourceFilter<AnswerScopeLinkage | null> name='scope' transform={scopeFilterTransform}>
            {bind => <AnswerScopeField {...bind} inputStyle='dark'/>}
          </ResourceFilter>
          {me?.isEditor() && (
            <ResourceFilter name='reviewer'>
              {bind => <UserField {...bind} inputStyle='dark'/>}
            </ResourceFilter>
          )}
        </ResourceListScreen.Filters>

        {bulkSelector != null && (
          <BulkAssignForm
            open={bulkAssignFormOpen}
            requestClose={stopBulkAssign}
            selector={bulkSelector}
          />
        )}
      </ResourceListScreen>
    )
  }

  function renderStatus(review: Review) {
    return (
      <HBox gap={layout.padding.s}>
        <Chip small backgroundColor={colorForStatus(review.status, theme)}>
          {t(`statuses.${review.status}.caption`)}
        </Chip>
      </HBox>
    )
  }

  function renderParticipant(review: Review) {
    return (
      <HBox gap={layout.padding.s}>
        <ParticipantCell item={review.participant} />
      </HBox>
    )
  }

  function renderReviewer(review: Review) {
    return (
      <HBox>
        {review.reviewer != null ? (
          <UserCell id={review.reviewer}/>
        ) : (
          <Label dim italic>{t('fields.reviewer.unassigned')}</Label>
        )}
      </HBox>
    )
  }

  return render()

})

const scopeFilterTransform = filterTransform<AnswerScopeLinkage | null>(
  linkage => {
    if (linkage == null) { return null }
    if (linkage === '$all') { return '$all' }

    return `${linkage.type}:${linkage.id}`
  },
  raw => {
    const [type, id] = raw == null ? [] : raw.split(':')
    if (type !== 'challenges' && type !== 'scripts') { return null }

    return {type, id}
  },
)

export default ReviewsScreen