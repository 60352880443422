import React from 'react'
import { FlowPlan } from '~/models'
import { FlowPlanner } from '~/stores'
import { observer } from '~/ui/component'

export interface FlowPlannerContext {
  planner: FlowPlanner | null
  plan:    FlowPlan | null
}

export const FlowPlannerContext = React.createContext<FlowPlannerContext>({
  planner: null,
  plan:    null,
})

export interface FlowPlannerContextProviderProps {
  planner:   FlowPlanner
  children?: React.ReactNode
}

export const FlowPlannerContextProvider = observer('FlowPlannerContextProvider', (props: FlowPlannerContextProviderProps) => {
  const {planner, children} = props
  const plan = planner.plan

  const context = React.useMemo(() => ({
    planner: planner,
    plan:    plan,
  }), [plan, planner])

  return (
    <FlowPlannerContext.Provider value={context}>
      {children}
    </FlowPlannerContext.Provider>
  )
})

export function useMaybeFlowPlanner(): FlowPlannerContext {
  return React.useContext(FlowPlannerContext)
}

export function useFlowPlanner(require: false): FlowPlannerContext
export function useFlowPlanner(require?: true): FlowPlannerContext & {planner: FlowPlanner}
export function useFlowPlanner(require: boolean = true): FlowPlannerContext & {planner: FlowPlanner} {
  const context = React.useContext(FlowPlannerContext)
  if (require && context.planner == null) {
    throw new Error('useFlowPlanner: context.planner is null')
  }

  return context as FlowPlannerContext & {planner: FlowPlanner}
}