import { action, makeObservable } from 'mobx'
import config from '~/config'
import { Media } from '~/models'
import ActionTriggerableVariant from '../triggerables/ActionTriggerableVariant'
import TriggerableFormModel from '../triggerables/TriggerableFormModel'

export default class PostActionFormModel {

  constructor(
    private triggerableFormModel: TriggerableFormModel & {variant: ActionTriggerableVariant},
  ) {
    makeObservable(this)
  }

  private get params() {
    return this.triggerableFormModel.variant.params
  }

  public get feed(): string { return this.params.feed ?? null }
  public set feed(value: string) { this.params.feed = value }

  public get author(): string { return this.params.author ?? null }
  public set author(value: string) { this.params.author = value }

  public get body(): string { return this.params.body ?? null }
  public set body(value: string) { this.params.body = value }

  //------
  // Media

  public get media(): string[] { return this.params.media ?? [] }
  public set media(value: string[]) { this.params.media = value }

  public get mayAddMedia() {
    return this.media.length < config.news.maxMediaCount
  }

  @action
  public addMedia(media: Media) {
    if (!this.mayAddMedia) { return }
    this.media.push(media.id)
  }

  @action
  public removeMedia(id: string) {
    this.media = this.media.filter(it => it !== id)
  }

}