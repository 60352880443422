import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { FetchStatus } from 'mobx-document'
import { sparse, stringContains } from 'ytil'
import projectStore from './projectStore'
import { register } from './support'

export class LanguagesStore {

  constructor() {
    makeObservable(this)

    reaction(() => projectStore.project, project => {
      this.defaultLanguage = this.resolveLanguage(this.defaultLanguage)
    })
  }

  @observable
  public all: Language[] = []

  @observable
  public common: string[] = []

  // Note: this is not the UI language (which is always English), but rather the language all the LocalizedTextFields
  // are set to by default.
  @observable
  public defaultLanguage: string = 'nl'

  @action
  public setDefaultLanguage(language: string) {
    this.defaultLanguage = this.resolveLanguage(language)
  }

  @computed
  public get availableLanguages() {
    const languages = projectStore.project?.languages ?? []
    return sparse(languages.map(it => this.getLanguage(it, false)))
  }

  @observable
  public status: FetchStatus = 'idle'

  public async init() {
    await this.loadLanguages()
  }

  public async loadLanguages() {
    const response = await fetch('/languages.json')
    if (response.status !== 200) {
      runInAction(() => {
        this.status = new Error(`Unable to load languages: ${response.status}`)
        this.all = []
        this.common = []
      })
    }

    try {
      const json = await response.json()
      runInAction(() => {
        this.status = 'done'
        this.all = json.all
        this.common = json.common
      })
    } catch (error) {
      runInAction(() => {
        this.status = error instanceof Error ? error : new Error(`${error}`)
        this.all = []
        this.common = []
      })
    }
  }

  private resolveLanguage(language: string) {
    const {project} = projectStore
    if (project == null) { return language }
    if (project.languages.includes(language)) {
      return language
    } else {
      return project.languages[0] ?? language
    }
  }

  public getLanguage(code: string, fakeIfNotFound: true): Language
  public getLanguage(code: string, fakeIfNotFound?: boolean): Language | null
  public getLanguage(code: string, fakeIfNotFound: boolean = false) {
    const language = this.all.find(it => it.code === code)
    if (language != null) {
      return language
    } else if (fakeIfNotFound) {
      return {code, name_en: code, name_loc: code}
    } else {
      return null
    }
  }

  public getLanguageName(code: string, localName: boolean = true) {
    const language = this.getLanguage(code)
    if (language == null) { return null }

    return localName ? language.name_loc : language.name_en
  }

  public searchLanguage(term: string) {
    return this.all.find(language => {
      if (stringContains(language.code, term)) { return true }
      if (stringContains(language.name_en, term)) { return true }
      if (stringContains(language.name_loc, term)) { return true }
      return false
    })
  }

  public persistenceKey = 'languages'

  public persist() {
    return {
      currentLanguage: this.defaultLanguage,
    }
  }

  public rehydrate(raw: any) {
    if (raw.currentLanguage != null) {
      this.defaultLanguage = this.resolveLanguage(raw.currentLanguage)
    }
  }

}

export interface Language {
  code: string
  name_en: string
  name_loc: string
}

const languagesStore = register(new LanguagesStore())
export default languagesStore