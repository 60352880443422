import React from 'react'
import { memo } from '~/ui/component'
import { createUseStyles } from '~/ui/styling'
import { useChart } from './ChartContext'

export interface Props<T> {
  point:   T
  index:   number
  value:   number
  offset?: number
}

const _ValueLabel = <T extends {}>(props: Props<T>) => {

  const {point, value, index, offset = 0.5} = props
  const {keyForPoint, chartLayout} = useChart<T>()
  const key = keyForPoint(point, index)

  //------
  // Rendering

  const $ = useStyles()

  function render() {

    return (
      <text
        classNames={$.valueLabel}
        textAnchor='middle'
        x={chartLayout.xForPointKey(key, offset)}
        y={chartLayout.yForValue(value) - 8}
        children={value}
      />
    )
  }

  return render()

}

const ValueLabel = memo('ValueLabel', _ValueLabel) as typeof _ValueLabel
export default ValueLabel

const useStyles = createUseStyles(theme => ({
  valueLabel: {
    fill:     theme.fg.dim,
    fontSize: 12,
  },
}))