import React, { useState } from 'react'
import { cleanTextValue } from '~/lib/ytil'
import { ClientApp, Extension, Project } from '~/models'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  EmptyOrFetching,
  HBox,
  Label,
  Panel,
  PanelHeader,
  SwitchField,
  TextField,
} from '~/ui/components'
import { FormField, InlineFormField, useForm } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useModelDocumentData, useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { InlineResourceForm } from '~/ui/resources/form'
import { createUseStyles, layout } from '~/ui/styling'
import ExtensionLogo from '../extensions/ExtensionLogo'
import ClientAppFormModel from './ClientAppFormModel'
import OAuthProviderConfigForm from './OAuthProviderConfigForm'

export interface Props {
  id:             string
  oAuthProviders: string[] | null
}

const ClientAppDetail = observer('ClientAppDetail', (props: Props) => {

  const {id, oAuthProviders} = props
  const [clientApp, {fetchStatus}] = useModelDocumentData(ClientApp, id)

  const {t} = useResourceTranslation('client-apps')

  const formModel = React.useMemo(
    () => clientApp == null ? null : new ClientAppFormModel(clientApp),
    [clientApp],
  )

  //------
  // oAuth

  useModelEndpointData(Extension)

  const [oAuthProviderOptionsFormOpen, openOAuthProviderConfigForm, closeOAuthProviderConfigForm] = useBoolean()
  const [configuringOAuthProvider, setConfiguringOAuthprovider] = useState<string | null>(null)

  const configureOAuthProvider = React.useCallback((provider: string) => {
    openOAuthProviderConfigForm()
    setConfiguringOAuthprovider(provider)
  }, [openOAuthProviderConfigForm])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (formModel == null) {
      return renderEmpty()
    }

    return (
      <InlineResourceForm<ClientApp> Model={ClientApp} formModel={formModel} autoSubmit>
        {renderForm()}
      </InlineResourceForm>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        {...t('empty')}
        status={fetchStatus}
        flex
      />
    )
  }

  function renderForm() {
    if (clientApp == null) { return null }

    return (
      <Panel padding={layout.padding.s} gap={layout.padding.s} header={renderHeader()}>
        <InlineFormField
          name='bundleID'
          renderView={() => <Label mono box>{clientApp.ios.bundleID}</Label>}
          renderEdit={bind => <TextField commitOnBlur={false} mono {...bind}/>}
        />
        <InlineFormField
          name='applicationID'
          renderView={() => <Label mono box>{clientApp.android.applicationID}</Label>}
          renderEdit={bind => <TextField commitOnBlur={false} mono {...bind}/>}
        />
        <Dimple horizontal counterPadding={layout.padding.s}/>
        <InlineFormField
          name='urlScheme'
          renderView={() => <Label mono box>{clientApp.urlScheme}</Label>}
          renderEdit={bind => <TextField commitOnBlur={false} mono {...bind}/>}
        />
        <InlineFormField
          name='domain'
          renderView={renderDomain}
          transform={domainTransform}
          renderEdit={bind => <TextField commitOnBlur={false} mono {...bind}/>}
        />
        <Dimple horizontal counterPadding={layout.padding.s}/>
        <FormField name='branding'>
          {bind => (
            <ResourceField
              Model={Project}
              {...bind}
            />
          )}
        </FormField>
        <Dimple horizontal counterPadding={layout.padding.s}/>
        {renderAuth()}
      </Panel>
    )
  }

  function renderHeader() {
    if (clientApp == null) { return null }

    return (
      <PanelHeader
        icon='iphone'
        caption={clientApp.id}
      />
    )
  }

  function renderDomain() {
    if (clientApp == null) { return null }

    return (
      <HBox flex gap={layout.padding.inline.s}>
        {clientApp.domain != null ? (
          <Label flex mono box>
            {clientApp.domain}
          </Label>
        ) : (
          <Label small dim caption>
            {t('fields.domain.none')}
          </Label>
        )}
      </HBox>
    )
  }

  function renderAuth() {
    if (clientApp == null) { return null }
    return (
      <>
        <Label classNames={$.authHeader} small bold dim>
          {t('auth')}
        </Label>
        <FormField name='emailAndPincode' caption={false} label={t(`fields.auth.email_and_pincode`)}>
          {bind => <SwitchField {...bind}/>}
        </FormField>

        {oAuthProviders?.map(renderOAuthProvider)}

        {configuringOAuthProvider != null && (
          <OAuthProviderConfigForm
            open={oAuthProviderOptionsFormOpen}
            clientApp={clientApp}
            name={configuringOAuthProvider}
            title={formModel?.extensionForOAuthProvider(configuringOAuthProvider)?.translate('name') ?? configuringOAuthProvider}
            requestClose={closeOAuthProviderConfigForm}
          />
        )}
      </>
    )
  }

  function renderOAuthProvider(providerName: string) {
    const extension = formModel?.extensionForOAuthProvider(providerName)
    if (extension == null) { return null }

    return (
      <OAuthProviderToggle
        key={providerName}
        name={providerName}
        title={extension.translate('name')}
        logo={<ExtensionLogo extension={extension} size={layout.icon.l}/>}
        requestConfigure={formModel?.oAuthProviderEnabled(providerName) ? configureOAuthProvider : undefined}
      />
    )
  }

  return render()

})

export default ClientAppDetail

export interface OAuthProviderToggleProps {
  name:              string
  title:             string
  logo:              React.ReactNode
  requestConfigure?: (provider: string) => any
}

const OAuthProviderToggle = observer('OAuthProviderToggle', (props: OAuthProviderToggleProps) => {

  const {name, title, logo, requestConfigure} = props

  const {t} = useResourceTranslation('client-apps')

  const {model: formModel, submit} = useForm<ClientAppFormModel>()
  const enabled = formModel.oAuthProviderEnabled(name)

  const toggle = React.useCallback(() => {
    formModel.toggleOAuthProvider(name)
    submit()
  }, [formModel, name, submit])

  const configure = React.useCallback(() => {
    requestConfigure?.(name)
  }, [requestConfigure, name])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.s} justify='space-between'>
        <SwitchField
          label={renderLabel}
          value={enabled}
          onChange={toggle}
        />
        {requestConfigure != null && (
          <ClearButton
            icon='cog'
            onTap={configure}
            small
          />
        )}
      </HBox>
    )
  }

  function renderLabel(value: boolean) {
    return (
      <HBox gap={layout.padding.inline.s}>
        {logo}
        <Label dim={!value} bold={value}>
          {t('fields.auth.oauth.caption', {title})}
        </Label>
      </HBox>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  authHeader: {
    paddingBottom: layout.padding.inline.s,
  },
})

const domainTransform = {
  fromValue: (value: string) => cleanTextValue(value, true),
  toValue:   (raw: string | null) => raw ?? '',
}