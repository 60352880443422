import React from 'react'
import { memo } from '~/ui/component'
import { SVG } from '~/ui/components'
import { useSimpleDrag } from '~/ui/hooks'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { LatLong } from './types'
import { convertMetersToPixels, convertPixelsToMeters } from './util'

export interface Props {
  center: LatLong

  radius:          number
  onChangeRadius?: (radius: number) => any

  minimum?: number
  maximum?: number

  zoom: number

  enabled?: boolean
}

const RadiusCircle = memo('RadiusCircle', (props: Props) => {

  const {
    center,
    radius,
    zoom,

    minimum,
    maximum,

    onChangeRadius,
    enabled = true,
  } = props

  const theme = useTheme()
  const [angle, setAngle] = React.useState<number>(0)

  const handleContainerRef = React.useRef<HTMLDivElement>(null)

  //------
  // Dragging

  const [connectDragHandle] = useSimpleDrag({
    onMove: point => {
      const handleContainer = handleContainerRef.current
      if (handleContainer == null) { return }

      const rect = handleContainer.getBoundingClientRect()
      const x = point.x - rect.left
      const y = point.y - rect.top

      setRadiusWithCoordinates(x, y)
    },
  })

  const connectHandleContainer = connectDragHandle(handleContainerRef)

  //------
  // Layout

  const circleRadiusInPixels = React.useMemo(
    () => convertMetersToPixels(radius, center, zoom),
    [center, radius, zoom],
  )

  const circleLayout = React.useMemo((): React.CSSProperties => {
    const size = circleRadiusInPixels * 2 + 2 * borderWidth

    return {
      width:        size,
      height:       size,
      marginLeft:   -size / 2,
      marginTop:    -size / 2,
      borderRadius: size / 2,
    }
  }, [circleRadiusInPixels])

  const setRadiusWithCoordinates = React.useCallback((x: number, y: number) => {
    const radius = convertPixelsToMeters(Math.sqrt(x * x + y * y), center, zoom)
    const angle  = Math.atan2(y, x)

    let value = Math.round(radius)
    if (minimum) { value = Math.max(value, minimum) }
    if (maximum) { value = Math.min(value, maximum) }

    setAngle(angle)
    onChangeRadius?.(value)
}, [center, maximum, minimum, onChangeRadius, zoom])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.radiusCircle}>
        {renderCircle()}
        {renderMarker()}
        {renderHandle()}
      </div>
    )
  }

  function renderCircle() {
    return (
      <div
        classNames={$.circle}
        style={circleLayout}
      />
    )
  }

  function renderMarker() {
    return (
      <SVG
        classNames={$.marker}
        name='cross'
        size={layout.icon.s}
        color={theme.semantic.secondary}
      />
    )
  }

  function renderHandle() {
    if (!enabled) { return null }

    const lineTransform = `translateY(-0.5px) rotate(${angle}rad)`
    const handlePosition = {
      left: Math.cos(angle) * circleRadiusInPixels,
      top:  Math.sin(angle) * circleRadiusInPixels,
    }

    return (
      <div classNames={$.handleContainer} ref={connectHandleContainer}>
        <div
          classNames={$.line}
          style={{width: circleRadiusInPixels, transform: lineTransform}}
        />
        <div
          classNames={$.handle}
          style={handlePosition}
        />
      </div>
    )
  }

  return render()

})

export default RadiusCircle

export const borderWidth = 2

const useStyles = createUseStyles(theme => ({
  radiusCircle: {
    ...layout.overlay,
    pointerEvents: 'none',
  },

  circle: {
    position: 'absolute',
    top:      '50%',
    left:     '50%',

    background: theme.semantic.secondary.alpha(0.2),
    border:     [borderWidth, 'solid', theme.semantic.secondary],
  },

  marker: {
    position: 'absolute',
    top:      '50%',
    left:     '50%',

    marginTop:  -layout.icon.s.height / 2,
    marginLeft: -layout.icon.s.width / 2,
  },

  handleContainer: {
    position: 'absolute',
    top:      '50%',
    left:     '50%',
  },

  line: {
    position:        'absolute',
    top:             0,
    left:            0,
    borderTop:       [1, 'dashed', theme.semantic.secondary],
    transformOrigin: [0, 0.5],
  },

  handle: {
    position: 'absolute',
    top:      0,

    pointerEvents: 'auto',
    cursor:        'move',

    ...layout.icon.m,
    marginTop:    -layout.icon.m.height / 2,
    marginLeft:   -layout.icon.m.width / 2,
    borderRadius: layout.icon.m.width / 2,
    background:   theme.semantic.primary,
  },
}))