import React from 'react'
import { Project } from '~/models'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'
import ProjectLogo from './ProjectLogo'

export type Props = Omit<ResourceFieldProps<Project>, 'Model' | 'shortcutsCaption' | 'shortcutIDs'>

const ProjectField = observer('ProjectField', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...props as any}
        Model={Project}
        iconFor
        renderPropsForModel={renderPropsForModel}
      />
    )
  }

  const renderPropsForModel = React.useCallback((project: Project) => {
    return {
      image: <ProjectLogo logo={project.logo}/>,
    }
  }, [])

  return render()

})

export default ProjectField