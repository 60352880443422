import { Database } from 'mobx-document'
import { ScopedSocket } from 'socket.io-react'
import { Insight } from '~/models'
import { ModelDocument, ModelEndpoint, Sort } from '../../data'
import { PositionLocator } from '../types'

type InsightDocument = ModelDocument<Insight>

export default class FlowPlanPositionInsightsEndpoint extends ModelEndpoint<Insight> {

  constructor(
    private readonly socket: ScopedSocket,
    public readonly position: PositionLocator,
    database: Database<InsightDocument>,
  ) {
    super(database, Insight, {
      sorts: [{field: 'date', direction: -1}],
    })
  }

  protected async performSocketFetch(options: any) {
    return await this.socket.fetch('insights:position', this.position, options)
  }

}

export interface FlowPlanPositionInsightsParams {
  sort:   Sort | null
  search: string | null
  page:   number | null
}

export interface FlowPlanPositionInsightsMeta {
  total:       number
  searchTotal: number
  count:       number
}