import React from 'react'
import { defaultCoordinate, defaultRadius, defaultZoom } from '~/models'
import { observer } from '~/ui/component'
import { HBox, NumberField, TextFieldProps, VBox } from '~/ui/components'
import { FormField, FormFieldProps } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import GoogleMap, { Props as GoogleMapProps } from './GoogleMap'
import GoogleMapManager from './GoogleMapManager'
import RadiusCircle from './RadiusCircle'
import { LatLong } from './types'
import { boundsWithCenterAndRadius, extractGeocodedName } from './util'

export interface Props extends GoogleMapProps {
  coordinateField?: string
  radiusField?:     string
  hasRadius?:       boolean

  reverseGeocodeName?: boolean
  nameField?:          string

  inputStyle?:    TextFieldProps['inputStyle']
  mapManagerRef?: React.Ref<GoogleMapManager>
}

const GoogleMapFields = observer('GoogleMapFields', (props: Props) => {

  const {
    inputStyle,
    reverseGeocodeName,
    hasRadius = true,
    mapManagerRef,
  } = props

  const [mapZoom, setMapZoom] = React.useState<number>(defaultZoom)

  const [, setName]                 = useFormField<string>('name')
  const [coordinate, setCoordinate] = useFormField<LatLong | null>('coordinate')
  const [radius, setRadius]         = useFormField<number | null>('radius')

  const fieldProps = React.useMemo(() => ({
    inputStyle,
  }), [inputStyle])

  const setCoordinatesIfReady = React.useCallback((value: LatLong | null) => {
    if (!mapReadyRef.current) { return }
    setCoordinate(value)
    if (radius == null) {
      setRadius(defaultRadius)
    }
  }, [radius, setCoordinate, setRadius])

  //------
  // Callbacks

  const mapReadyRef = React.useRef<boolean>(false)

  const onReady = React.useCallback(() => {
    mapReadyRef.current = true
  }, [])

  const onCalculateZoom = React.useCallback((zoom: number) => {
    setMapZoom(zoom)
    props.onCalculateZoom?.(zoom)
  }, [props])

  const onReverseGeocode = React.useCallback((result: google.maps.GeocoderResult | null) => {
    setName(extractGeocodedName(result) ?? '')
  }, [setName])

  const mapProps = React.useMemo(() => {
    const resolvedRadius = hasRadius ? radius : defaultRadius
    if (coordinate != null && resolvedRadius != null) {
      return {
        initialBounds: boundsWithCenterAndRadius(coordinate, radius ?? defaultRadius),
        center:        coordinate,
      }
    } else {
      return {
        center: defaultCoordinate,
        zoom:   defaultZoom,
      }
    }
  }, [coordinate, hasRadius, radius])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderMap()}
        {renderFields()}
      </VBox>
    )
  }

  function renderMap() {
    return (
      <GoogleMap
        classNames={$.map}
        {...props}
        {...mapProps}

        showSearch

        onReady={onReady}
        onCalculateZoom={onCalculateZoom}
        onCenterChange={setCoordinatesIfReady}
        onReverseGeocode={reverseGeocodeName ? onReverseGeocode : undefined}
        children={renderRadiusCircle()}

        managerRef={mapManagerRef}
      />
    )
  }

  function renderRadiusCircle() {
    if (coordinate == null) { return null }

    return (
      <RadiusCircle
        center={coordinate}
        radius={radius ?? defaultRadius}
        onChangeRadius={setRadius}
        enabled={hasRadius}
        zoom={mapZoom}
        minimum={20}
      />
    )
  }

  function renderFields() {
    return (
      <HBox gap={layout.padding.s} align='top'>
        {renderField('coordinate.latitude', bind => (
          <NumberField
            {...bind}
            {...fieldProps}
            maximumFractionDigits={4}
            step={0.0001}
          />
        ))}
        {renderField('coordinate.longitude', bind => (
          <NumberField
            {...bind}
            {...fieldProps}
            maximumFractionDigits={4}
            step={0.0001}
          />
        ))}
        {hasRadius && (
          renderField('radius', bind => (
            <NumberField
              {...bind}
              {...fieldProps}
              maximumFractionDigits={0}
              minimum={20}
              step={5}
            />
          ))
        )}
      </HBox>
    )
  }

  function renderField(name: string, children: FormFieldProps<any>['children']) {
    return (
      <VBox flex>
        <FormField
          name={name}
          children={children}
        />
      </VBox>
    )
  }

  return render()

})

export default GoogleMapFields

const useStyles = createUseStyles({
  map: {
    flex:         [0, 1, '280px'],
    borderRadius: layout.radius.s,
    overflow:     'hidden',
    boxShadow:    shadows.depth(1),
},
})