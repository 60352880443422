import React from 'react'
import { memo } from '~/ui/component'

import type GoogleMapManager from './GoogleMapManager'

const GoogleMapContext = React.createContext<GoogleMapManager | null>(null)

export default GoogleMapContext

export interface GoogleMapContainerProps {
  manager:   GoogleMapManager
  children?: React.ReactNode
}

export const GoogleMapContainer = memo('GoogleMapContainer', (props: GoogleMapContainerProps) => {
  const {manager, children} = props

  return (
    <GoogleMapContext.Provider value={manager}>
      {children}
    </GoogleMapContext.Provider>
  )
})