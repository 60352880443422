import React from 'react'
import { memo } from '~/ui/component'
import { Chip, ChipProps, HBox } from '~/ui/components'
import { layout } from '~/ui/styling'

export type Props = ChipProps

const ChipCell = memo('ChipCell', (props: Props) => {

  return (
    <HBox gap={layout.padding.inline.s}>
      <Chip {...props}/>
    </HBox>
  )

})

export default ChipCell