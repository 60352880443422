import React from 'react'
import { useTranslation } from 'react-i18next'
import { InterpolationOptions } from 'i18next'
import { truncate } from 'ytil'
import { Condition } from '~/models'
import { observer } from '~/ui/component'
import { Center, Label, LabelProps, Spinner, Tooltip } from '~/ui/components'
import { useConditionQuestion } from './hooks'

export interface Props extends Omit<LabelProps, 'children' | 'markup'> {
  condition: Condition
}

const ConditionSummaryLabel = observer('ConditionSummaryLabel', (props: Props) => {

  const {condition} = props

  const [t] = useTranslation('conditions')

  const [question, fetchStatus] = useConditionQuestion(condition.selector.type === 'variable' ? null : condition.selector)

  const text     = question?.text
  const excerpt  = text == null ? null : truncate(text, 20, {anchor: 'middle'})

  const interpolation: InterpolationOptions = {
    skipOnVariables: true,
  }

  const selector = t(`selector_summaries.${condition.selector.type}`, {...condition.selector, excerpt, interpolation})
  const summary  = t(`summaries.${condition.operator}`, {...condition, selector, interpolation})

  const selectorFull = t(`selector_summaries.${condition.selector.type}`, {...condition.selector, excerpt: question?.text, interpolation})
  const summaryFull  = t(`summaries.${condition.operator}`, {...condition, selector: selectorFull, interpolation})

  const needsTooltip = text != null && excerpt != null && excerpt.length < text.length

  //------
  // Rendering

  function render() {
    if (fetchStatus === 'fetching') {
      return (
        <Center flex>
          <Spinner size={8}/>
        </Center>
      )
    } else if (needsTooltip) {
      return (
        <Tooltip renderTooltip={summaryFull}>
          {renderLabel()}
        </Tooltip>
      )
    } else {
      return renderLabel()
    }
  }

  function renderLabel() {
    return (
      <Label {...props} markup={true}>
        {summary}
      </Label>
    )
  }

  return render()

})

export default ConditionSummaryLabel