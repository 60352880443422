import React from 'react'
import { memo } from '~/ui/component'
import TabManagerContextProvider from './ClientTabManagerContext'
import ClientTabsBody from './ClientTabsBody'

const ClientTabs = memo('TabManagement', () => {

  //------
  // Rendering

  function render() {
    return(
      <TabManagerContextProvider>
        <ClientTabsBody />
      </TabManagerContextProvider>
    )
  }

  return render()

})

export default ClientTabs