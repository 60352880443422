import React from 'react'
import { SystemNotification } from '~/models'
import { notificationSpec } from '~/notifications'
import { memo } from '~/ui/component'
import { Center, HBox, Label, Panel, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import NotificationActionsList from './NotificationActionsList'

export interface Props {
  notification: SystemNotification
}

const NotificationDetailHeader = memo('NotificationDetailHeader', (props: Props) => {

  const {notification} = props

  const theme   = useTheme()
  const spec    = notificationSpec(notification?.type ?? '')
  const icon    = spec.icon(notification)
  const color   = spec.color(notification, theme)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel gap={layout.padding.inline.m} padding={layout.padding.inline.l}>
        <HBox gap={layout.padding.m} align='top'>
          {renderIcon()}
          {renderMain()}
          {renderActions()}
        </HBox>
      </Panel>
    )
  }

  function renderIcon() {
    return (
      <Center classNames={$.icon}>
        <SVG
          name={icon}
          color={color}
          size={layout.icon.l}
        />
      </Center>
    )
  }

  function renderMain() {
    return (
      <VBox flex gap={layout.padding.inline.l}>
        <VBox gap={layout.padding.inline.s}>
          {renderTitle()}
          {renderMessage()}
        </VBox>
        {renderDate()}
      </VBox>
    )
  }

  function renderTitle() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Label flex='shrink' h3 markup>
          {notification.title}
        </Label>
        {notification.priority === 'high' && (
          <SVG
            name='exclamation'
            color={theme.semantic.negative}
            size={layout.icon.s}
          />
        )}
      </HBox>
    )
  }

  function renderMessage() {
    return (
      <Label dim markup>
        {notification.message}
      </Label>
    )
  }

  function renderDate() {
    return (
      <Label small>
        {notification.createdAt.toFormat('d-MMM-yyyy H:mm')}
      </Label>
    )
  }

  function renderActions() {
    return (
      <NotificationActionsList
        notification={notification}
      />
    )
  }

  return render()

})

export default NotificationDetailHeader

const useStyles = createUseStyles({
  icon: {
    paddingTop: '0.4em',
  },
})