import I18next from 'i18next'
import { upperFirst } from 'lodash'
import { Challenge, ChallengeTask } from './Challenge'
import { Model, resource } from './Model'
import { Participant } from './Participant'
import { Script, ScriptMessage } from './Script'
import { User } from './User'
import { PolymorphicRef, Ref } from './types'

@resource<Review>('reviews', {
  icon:    () => 'question',
  caption: (review, {stores}) => {
    const Model: Constructor<Challenge | Script> =
      review.question.model.model === 'Challenge' ? Challenge : Script

    const model = stores?.dataStore.get(Model, review.question.model.id)
    if (model == null) { return upperFirst(I18next.t('reviews:singular')) }

    const questions: Array<ScriptMessage | ChallengeTask> | undefined =
      model instanceof Script ? model.messages() :
      model instanceof Challenge ? model.tasks : undefined

    const questionIndex = (questions ?? []).findIndex(it => it.uuid === review.question.uuid)
    if (questionIndex < 0) { return model.$caption }

    return I18next.t('reviews:caption', {model: model.$caption, question: questionIndex + 1})
  },
  include:        ['question'],
  scopedToModule: false,
})
export class Review extends Model {

  public participant!: Ref<Participant>
  public question!:    QuestionRef
  public answer!:      Primitive

  // Reviews.
  public status!:   ReviewStatus
  public reviewer!: Ref<User> | null
  public notes!:    string | null

  public correct!: boolean | null
  public score!:   number

}

export interface QuestionRef {
  model: PolymorphicRef<Script | Challenge>
  uuid:  string
}

export type ReviewStatus = 'pending' | 'review' | 'reviewed' | 'done'
export const ReviewStatus: {
  all: ReviewStatus[]
} = {
  all: ['pending', 'review', 'done'],
}