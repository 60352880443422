import React from 'react'
import { isArray } from 'lodash'
import { Model, ModelOfType, Participant } from '~/models'
import { observer } from '~/ui/component'
import { Center, Panel, Spinner, VBox } from '~/ui/components'
import { useFiniteModelSetData } from '~/ui/hooks/data'
import { ResourceBar } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'

import type { Relationship } from '~/stores'
export interface Props {
  relationship: Relationship
}

const NotificationRelationshipBar = observer('NotificationRelationshipBar', (props: Props) => {

  const {relationship} = props

  const linkage = isArray(relationship.data) ? relationship.data[0] : relationship.data
  const Model   = linkage == null ? null : ModelOfType(linkage.type)
  const ids     =
    isArray(relationship.data) ? relationship.data.map(it => it.id) :
    relationship.data != null ? [relationship.data.id] :
    []

  const [models, {fetchStatus}] = useFiniteModelSetData(Model ?? Participant, ids)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (models.length === 0 && fetchStatus !== 'fetching') { return null }

    if (models.length === 0) {
      return (
        <Panel contentClassNames={$.empty}>
          <Center flex>
            <Spinner size={12}/>
          </Center>
        </Panel>
      )
    } else {
      return (
        <VBox gap={layout.padding.inline.s}>
          {models.map(renderResourceBar)}
        </VBox>
      )
    }
  }

  function renderResourceBar(model: Model) {
    return (
      <ResourceBar
        key={model.id}
        model={model}
      />
    )
  }

  return render()

})

export default NotificationRelationshipBar

const useStyles = createUseStyles({
  empty: {
    height: layout.barHeight.m,
  },
})