import React from 'react'
import { ChatBot } from '~/models'
import { dataStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'

export interface Props extends Omit<ResourceFieldProps<ChatBot>, 'Model' | 'shortcutsCaption' | 'shortcutIDs'> {
  moduleID?:    string | null
  allowCreate?: boolean
}

const ChatBotField = observer('ChatBotField', (props: Props) => {

  const {moduleID, allowCreate = true} = props

  //------
  // Callbacks

  const createChatBot = React.useCallback((name: string) => {
    if (moduleID == null) {
      throw new Error("Cannot create chat bot without module ID")
    }

    return dataStore.create(ChatBot, {
      module: moduleID,
      name,
    })
  }, [moduleID])

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...props as any}
        Model={ChatBot}
        renderPropsForModel={renderPropsForModel}
        requestCreate={allowCreate ? createChatBot : undefined}
      />
    )
  }

  const renderAvatar = React.useCallback((chatBot: ChatBot) => {
    return (
      <Avatar
        source={chatBot.avatar}
        firstName={chatBot.displayName}
        size={avatarSize}
      />
    )
  }, [])

  const renderPropsForModel = React.useCallback((chatBot: ChatBot) => {
    return {
      image: renderAvatar(chatBot),
    }
  }, [renderAvatar])

  return render()

})

export default ChatBotField

export const avatarSize = {
  width:  32,
  height: 32,
}