import React from 'react'
import { Branding } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { ConfirmBox, KebabMenu, PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { useBranding } from './BrandingContext'

export interface Props {}

const BrandingKebabMenu = observer('BrandingKebabMenu', (props: Props) => {

  const {actionCaption, actionConfirm} = useResourceTranslation('brandings')
  const {branding} = useBranding()
  const {colors}   = useStyling()

  //------
  // Actions

  const reset = React.useCallback(async () => {
    if (branding == null) { return null }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('reset'),
      destructive: true,
    })
    if (!confirmed) { return }

    await dataStore.delete(Branding, branding.id)
  }, [actionConfirm, branding])

  const items = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    if (branding != null) {
      items.push({
        icon:     'reset',
        caption:  actionCaption('reset'),
        color:    colors.semantic.negative,
        enabled:  !branding.default,
        onSelect: reset,
      })
    }

    return items
  }, [actionCaption, branding, colors.semantic.negative, reset])

  //------
  // Rendering

  function render() {
    return (
      <KebabMenu items={items}/>
    )
  }

  return render()

})

export default BrandingKebabMenu