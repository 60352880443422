import { FontSpec } from './branding'

export const defaultFamilies = {
  sans:  'Overpass, Helvetica, Arial, sans-serif',
  mono:  'Menlo, Inconsolata, Roboto Mono, Courier New, monospace',
  hand:  'Comic Neue, Marker Felt, serif',
  chat:  'SF UI Display, Helvetica, Arial, sans-serif',
}

export function responsiveFontStyle(spec: FontSpec): Record<string, any> {
  return {
    font: {
      family: spec.family,
      weight: spec.weight,
      size:   spec.size,
    },
    lineHeight: spec.lineHeight,
  }
}

export function browserTextOffset() {
  if (navigator.userAgent.includes('Gecko/')) {
    return '0.1em'
  } else {
    return 0
  }
}
