import React from 'react'
import I18n from 'i18next'
import { createSortableElement, SortableItem } from 'react-dnd-sortable'
import { ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  Empty,
  HBox,
  List,
  ListProps,
  ModalDialog,
  VBox,
} from '~/ui/components'
import { height as listBarHeight } from '~/ui/components/ListBar'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import ChallengeTasksBulkEditorActionBar from './ChallengeTasksBulkEditorActionBar'
import ChallengeTasksBulkEditorItem from './ChallengeTasksBulkEditorItem'
import { useChallengeTasksEditor } from './ChallengeTasksEditorContext'

export interface Props {
  open:          boolean
  requestClose:  () => any
}

export const CHALLENGE_TASK = 'app.groundcontrol.sortable.CHALLENGE_TASK'

const SortableChallengeTask = createSortableElement(VBox, {
  axis:     'vertical',
  itemType: CHALLENGE_TASK,
})

const ChallengeTasksBulkEditor = memo('ChallengeTasksBulkEditor', (props: Props) => {

  const {open, requestClose} = props
  const {tasks, moveTask} = useChallengeTasksEditor()

  const {t, actionCaption} = useResourceTranslation('challenges')

  const sortableListID = React.useMemo(() => Symbol(), [])

  //------
  // Callbacks

  const [selectedTaskUUIDs, setSelectedTaskUUIDs] = React.useState<string[]>([])

  React.useEffect(() => {
    const allUUIDs = tasks.map(t => t.uuid)
    if (selectedTaskUUIDs.find(uuid => !allUUIDs.includes(uuid))) {
      const validUUIDs: string[] = selectedTaskUUIDs.filter(uuid => allUUIDs.includes(uuid))
      setSelectedTaskUUIDs(validUUIDs)
    }
  }, [tasks, selectedTaskUUIDs])

  const handleSortDrop = React.useCallback((item: SortableItem, dropIndex: number) => {
    const uuid = tasks[item.index].uuid
    if (uuid == null) { return }
    moveTask(uuid, dropIndex)
  }, [moveTask, tasks])

  const sortable = React.useMemo((): ListProps<any>['sortable'] | undefined => {
    return {
      listID:     sortableListID,
      accept:     [CHALLENGE_TASK],
      onSortDrop: handleSortDrop,
    }
  }, [handleSortDrop, sortableListID])

  const toggleSelectTask = React.useCallback((uuid: string) => {
    if (selectedTaskUUIDs.includes(uuid)) {
      setSelectedTaskUUIDs(selectedTaskUUIDs.filter(t => t !== uuid))
    } else {
      setSelectedTaskUUIDs([...selectedTaskUUIDs, uuid])
    }
  }, [selectedTaskUUIDs])

  const selectAllTasks = React.useCallback(() => {
    setSelectedTaskUUIDs(tasks.map(task => task.uuid))
  }, [setSelectedTaskUUIDs, tasks])

  const selectNoTasks = React.useCallback(() => {
    setSelectedTaskUUIDs([])
  }, [setSelectedTaskUUIDs])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <ModalDialog
        open={open}
        requestClose={requestClose}
        icon='challenge'
        title={actionCaption('manage_tasks')}
        headerRight={renderHeaderRight()}
        children={renderBody()}
        width={500}
        height={480}
        closeOnClickOutside
        closeOnEscape
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex>
        {renderList()}
        {renderActions()}
      </VBox>
    )
  }

  function renderList() {
    return (
      <List
        scrollable
        sortable={sortable}
        keyExtractor={keyExtractor}
        renderItem={renderTask}
        renderPlaceholder={renderSortPlaceholder}
        EmptyComponent={renderEmpty}
        itemGap={layout.padding.xs}
        contentPadding={layout.padding.s}
        data={tasks}
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty
        flex
        {...t('empty')}
      />
    )
  }, [t])

  const renderTask = React.useCallback((task: ChallengeTask, index: number) => (
    <SortableChallengeTask index={index} sourceList={sortableListID} payload={task}>
      <ChallengeTasksBulkEditorItem
        task={task}
        onToggleSelect={toggleSelectTask}
        selected={selectedTaskUUIDs.includes(task.uuid)}
      />
    </SortableChallengeTask>
  ), [sortableListID, selectedTaskUUIDs, toggleSelectTask])

  const keyExtractor = React.useCallback(
    (task: ChallengeTask) => task.uuid,
    [],
  )

  const renderSortPlaceholder = React.useCallback(
    () => <VBox style={{height: listBarHeight}}/>,
    [],
  )

  const renderHeaderRight = React.useCallback(() => {
    const allTasksSelected = selectedTaskUUIDs.length === tasks.length
    return (
      <HBox gap={layout.padding.m}>
        <ClearButton
          caption={actionCaption('tasks.select_all')}
          onTap={selectAllTasks}
          enabled={!allTasksSelected}
        />
        <ClearButton
          caption={actionCaption('tasks.select_none')}
          onTap={selectNoTasks}
          enabled={allTasksSelected}
        />
        <Dimple vertical />
        <ClearButton
          icon='cross'
          caption={I18n.t('buttons:close')}
          onTap={requestClose}
        />
      </HBox>
    )
  }, [selectedTaskUUIDs, tasks, selectNoTasks, selectAllTasks, actionCaption, requestClose])

  function renderActions() {
    return(
      <VBox classNames={$.actions} gap={layout.padding.s}>
        <Dimple horizontal />
        <ChallengeTasksBulkEditorActionBar
          selectedUUIDs={selectedTaskUUIDs}
        />
      </VBox>
    )
  }

  return render()

})

export default ChallengeTasksBulkEditor

const useStyles = createUseStyles({
  actions: {
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
      paddingTop: 0,
    })),
  },
})