import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionOperator, Feedback, operatorRequiresTextOperand } from '~/models'
import { VariantField } from '~/ui/app/custom-data'
import { memo } from '~/ui/component'
import { HBox, SelectField, TextBlock, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import ConditionFormModel from '../ConditionFormModel'

export interface Props {
  question: Feedback | null
}

const AdvancedTestFields = memo('AdvancedTestFields', (props: Props) => {

  const [t] = useTranslation('conditions')
  const {model} = useForm<ConditionFormModel>()

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('tests.advanced.preamble')}
        </TextBlock>

        <HBox gap={layout.padding.s}>
          <VBox flex={1}>
            {renderOperatorField()}
          </VBox>
          <VBox flex={3}>
            {renderOperandField()}
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderOperatorField() {
    return (
      <FormField name='advanced_operator' caption={t('tests.advanced.operator')!}>
        {bind => <SelectField {...bind} choices={operatorChoices}/>}
      </FormField>
    )
  }

  function renderOperandField() {
    return (
      <FormField name='operand' caption={t('tests.advanced.operand')!}>
        {bind => model.advanced_operator.includes('match') ? (
          <TextField {...bind} mono/>
        ) : operatorRequiresTextOperand(model.advanced_operator) ? (
          <TextField {...bind}/>
        ) : (
          <VariantField {...bind}/>
        )}
      </FormField>
    )
  }

  const operatorChoices = React.useMemo(() => ConditionOperator.all
    .filter(op => op !== 'empty' && op !== '!empty')
    .map((operator): Choice<ConditionOperator> => ({
      value:    operator,
      caption: `${operator}`,
    }),
  ), [])

  return render()

})

export default AdvancedTestFields