import React from 'react'
import { isAnnotation } from '~/models'
import { ComponentBounds } from '~/stores'
import { observer } from '~/ui/component'
import { Center } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import TextAnnotationToolbar, { height as toolbarHeight } from '../annotations/TextAnnotationToolbar'
import { useFlowPlanner } from '../FlowPlannerContext'
import SeguePopupMenu from '../segues/SeguePopupMenu'
import { useCanvas } from './FlowPlannerCanvasContext'
import ResizeHandles from './ResizeHandles'
import { useSelection } from './SelectionContext'
import { selectionBoundsToLayoutRect } from './SelectionManager'

export interface Props {
  transform: string
  zoom:      number
}

const SelectionLayer = observer('SelectionLayer', (props: Props) => {

  const {transform, zoom} = props

  const {planner} = useFlowPlanner()

  //------
  // Layout

  const layerRef = React.useRef<HTMLDivElement>(null)

  const {mode} = useCanvas.unoptim()
  const {selectedBounds, selectionBounds, manager} = useSelection.unoptim()
  const selectionHidden = (manager?.selectionHidden ?? false) || mode !== 'select'

  const toolbar = React.useMemo(() => {
    if (selectionHidden || selectedBounds.length === 0) { return null }

    const {bounds, component} = selectedBounds[selectedBounds.length - 1]

    // Find all selected components of the same type.
    const components = selectedBounds
      .filter(bounds => bounds.component.type === component.type)
      .map(bounds => bounds.component)

    return {
      bounds,
      components,
      focusedComponent: component,
    }
  }, [selectedBounds, selectionHidden])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.selectionLayer} ref={layerRef}>
        <div classNames={$.layer} style={{transform}}>
          {!selectionHidden && selectedBounds.map(renderResizeHandles)}
          {renderToolbar()}
          {renderSelectionRectangle()}
        </div>

        {renderFocusedSegueMenu()}
      </div>
    )
  }

  function renderResizeHandles(selectedBounds: ComponentBounds) {
    return (
      <ResizeHandles
        key={selectedBounds.component.uuid}
        selectedBounds={selectedBounds}
        zoom={zoom}
      />
    )
  }

  function renderToolbar() {
    if (toolbar == null) { return null }

    const {components, focusedComponent, bounds} = toolbar
    const left   = bounds.left + bounds.width / 2
    const top    = bounds.top - layout.padding.inline.s - toolbarHeight
    const height = toolbarHeight

    return (
      <Center classNames={$.toolbar} style={{left, top, height}}>
        {isAnnotation(focusedComponent) ? (
          <TextAnnotationToolbar
            uuids={components.map(comp => comp.uuid)}
            focusedAnnotation={focusedComponent}
          />
        ) : null}
      </Center>
    )
  }

  function renderSelectionRectangle() {
    if (selectionBounds == null) { return null }

    return (
      <div
        classNames={$.selectionRectangle}
        style={{...selectionRectangleStyle, borderWidth: `${1 / zoom}px`}}
      />
    )
  }

  const selectionRectangleStyle = React.useMemo(() => {
    if (selectionBounds == null) { return {} }
    return selectionBoundsToLayoutRect(selectionBounds)
  }, [selectionBounds])

  //------
  // Focused segue menu

  const focusedSegue    = planner.focusedSegue ?? null
  const segueMenuPoint  = planner.segueMenuPoint ?? null
  const segueOutletMenu = planner.segueOutletMenu ?? false

  const blurSegue = React.useCallback(() => {
    planner.closeSegueMenu()
  }, [planner])

  function renderFocusedSegueMenu() {
    if (focusedSegue == null || segueMenuPoint == null) { return null }

    return (
      <SeguePopupMenu
        open={true}
        requestClose={blurSegue}
        segue={focusedSegue}
        clientPoint={segueMenuPoint}
        menu={segueOutletMenu ? 'outlet' : 'root'}
      />
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  selectionLayer: {
    ...layout.overlay,
    pointerEvents: 'none',
  },

  layer: {
    position: 'absolute',
  },

  toolbar: {
    position: 'absolute',
    top:      -toolbarHeight - layout.padding.inline.s,
    right:    '50%',
    width:    0,

    pointerEvents: 'auto',
  },

  selectionRectangle: {
    position:  'absolute',
    border:    [1, 'solid', theme.colors.fg.dark.dim],
  },
}))

export default SelectionLayer