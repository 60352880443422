import React from 'react'
import { Project, RegistrationPreset } from '~/models'
import { memo } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import AllowedProjectsForm from '../forms/AllowedProjectsForm'
import AllowedProjectsFormModel from '../forms/AllowedProjectsFormModel'

export interface Props {
  preset: RegistrationPreset
}

const RegistrationPresetAllowedProjects = memo('RegistrationPresetAllowedProjects', (props: Props) => {

  const {t} = useResourceTranslation()

  const {preset} = props

  const [formOpen, openForm, closeForm] = useBoolean()

  const formModel = React.useMemo(
    () => new AllowedProjectsFormModel(RegistrationPreset, preset),
    [preset],
  )

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon='star'
        summary={t(`allowed_projects.caption`)}
        requestEdit={openForm}
        body={renderBody()}
        bold
      >
        {renderForm()}
      </SummaryInfo.Item>
    )
  }

  function renderBody() {
    if (preset.allowedProjects.length === 0) {
      return (
        <Label small dim truncate={false} markup>
          {t('allowed_projects.disabled')}
        </Label>
      )
    } else {
      return (
        <VBox gap={layout.padding.inline.s}>
          <Label small dim truncate={false} markup>
            {t('allowed_projects.enabled')}
          </Label>
          <ResourceChipRow
            Model={Project}
            ids={preset.allowedProjects}
            small
            wrap
          />
        </VBox>
      )
    }
  }

  function renderForm() {
    return (
      <AllowedProjectsForm
        open={formOpen}
        requestClose={closeForm}
        model={formModel}
      />
    )
  }

  return render()

})

export default RegistrationPresetAllowedProjects