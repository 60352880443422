import React from 'react'
import { Extension } from '~/models'
import { memo } from '~/ui/component'
import { Center, Dimple, Label, Panel, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import ExtensionLogo from './ExtensionLogo'

export interface Props {
  extension: Extension
  onTap?:    () => any

  selected?: boolean
  enabled?:  boolean
  footer?:   React.ReactNode
}

const IntegrationTile = memo('IntegrationTile', (props: Props) => {

  const {extension, selected, onTap, enabled = true, footer} = props

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={[$.extensionTile, {selected, enabled}]} onTap={onTap} flex='both'>
        <VBox gap={layout.padding.inline.m} padding={layout.padding.inline.l}>
          {renderLogo()}
          {renderLabels()}
          {footer && <Dimple horizontal/>}
          {footer}
        </VBox>
      </Panel>
    )
  }

  function renderLogo() {
    return (
      <Center>
        <ExtensionLogo
          extension={extension}
          size={logoSize}
        />
      </Center>
    )
  }

  function renderLabels() {
    return (
      <VBox gap={layout.padding.inline.xs}>
        <Label caption align='center'>
          {extension.translate('name')}
        </Label>
        <Label small dim truncate={false} align='center'>
          {extension.translate('description')}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default IntegrationTile

export const logoSize = {
  width:  120,
  height: 120,
}

const useStyles = createUseStyles(theme => ({
  extensionTile: {
    minWidth: 160,

    '&:not(.enabled)': {
      opacity: 0.7,
    },
    '&.selected': {
      boxShadow: shadows.selected(theme),
    },
    '&.interactive.selected:hover': {
      boxShadow: [
        shadows.selected(theme),
        shadows.depth(2),
      ],
    },
  },
}))