import React from 'react'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { Center, SVG } from '~/ui/components'

const Logo = observer('Logo', () => {

  const isBewizrUser = authenticationStore.actualUser?.bewizr ?? false

  const name = isBewizrUser ? 'bewizr' : 'logo'
  const logoSize = isBewizrUser ? logoSizes.bewizr : logoSizes.default

  function render() {
    return (
      <Center>
        <SVG
          name={name}
          size={logoSize}
        />
      </Center>
    )
  }

  return render()

})

export default Logo

export const logoSizes = {
  default: {
    width: 32,
    height: 32,
  },
  bewizr: {
    width: 134,
    height: 32,
  },
}