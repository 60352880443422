import React from 'react'
import { Feed, Targeting } from '~/models'
import { dataStore } from '~/stores'
import { TargetingForm, TargetingFormModel, TargetingFormProps } from '~/ui/app/targeting'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

export interface Props extends Omit<TargetingFormProps, 'model'> {
  feed: Feed
}

const FeedTargetingForm = observer('FeedTargetingForm', (props: Props) => {

  const {feed, ...rest} = props

  const formModel = React.useMemo(
    () => new FeedTargetingFormModel(feed),
    [feed],
  )

  //------
  // Rendering

  function render() {
    return (
      <TargetingForm
        model={formModel}
        {...rest}
      />
    )
  }

  return render()

})

export default FeedTargetingForm

class FeedTargetingFormModel extends TargetingFormModel {

  constructor(
    public readonly feed: Feed,
  ) {
    super(feed.targeting ?? Targeting.empty())
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Feed, this.feed.id, {
      targeting: this.buildTargeting(),
    })
  }

}