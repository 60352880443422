import React from 'react'
import { createSortableElement, SortableItem } from 'react-dnd-sortable'
import { arrayMove } from 'ytil'
import { Group, LocalizedString, Participant, Profile, ProfileItem } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Dimple,
  HBox,
  Label,
  List,
  ListItem,
  ListProps,
  Panel,
  Spinner,
  SVG,
  Tooltip,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import ProfileItemForm from './ProfileItemForm'
import { iconForProfileItemVisibility } from './util'

export interface Props {
  participant: Participant
}

export const PROFILE_ITEM = 'app.groundcontrol.sortable.PROFILE_ITEM'

const SortableProfileItem = createSortableElement(VBox, {
  axis:     'vertical',
  itemType: PROFILE_ITEM,
})

const ProfileTable = observer('ProfileTable', (props: Props) => {

  const {participant} = props
  const [profiles, {fetchStatus}] = useModelEndpointData(Profile)

  const profile: Profile | null = profiles[0] ?? null
  const items: ProfileItem[] = React.useMemo(() => (
    profile?.items ?? []
  ), [profile])

  const {t} = useResourceTranslation('participants')

  //------
  // Item editing

  const [editingIndex, setEditingIndex] = React.useState<number | '$new'>(0)
  const [itemFormOpen, openItemForm, closeItemForm] = useBoolean()

  const editItem = React.useCallback((index: number) => {
    setEditingIndex(index)
    openItemForm()
  }, [openItemForm])

  const removeItem = React.useCallback((index: number) => {
    const nextItems = items.filter((item, i) => i !== index)
    dataStore.update(Profile, profile.id, {
      items: nextItems,
    })
  }, [items, profile])

  const createItem = React.useCallback(() => {
    setEditingIndex('$new')
    openItemForm()
  }, [openItemForm])

  //------
  // List editing

  const [editingList, startEditingList, stopEditingList] = useBoolean()

  const sortableListID = React.useMemo(() => Symbol(), [])

  const handleSortDrop = React.useCallback(async (item: SortableItem, dropIndex: number) => {
    if (profile == null) { return }

    const items = arrayMove(profile.items, item.index, dropIndex)
    return await dataStore.update(Profile, profile.id, {items})
  }, [profile])

  const sortable = React.useMemo((): ListProps<any>['sortable'] | undefined => {
    if (!editingList) { return undefined }

    return {
      listID:     sortableListID,
      accept:     [PROFILE_ITEM],
      onSortDrop: handleSortDrop,
    }
  }, [editingList, handleSortDrop, sortableListID])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <Panel classNames={$.profileTable}>
          <List
            data={items}
            renderItem={renderItem}
            EmptyComponent={renderEmpty}
            sortable={sortable}
            renderPlaceholder={renderSortPlaceholder}
            flex={false}
          />
        </Panel>
        {renderFooter()}

        <ProfileItemForm
          open={itemFormOpen}
          requestClose={closeItemForm}
          profile={profile}
          index={editingIndex}
        />
      </VBox>
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <Center classNames={$.empty}>
        {fetchStatus === 'fetching' ? (
          <Spinner size={16}/>
        ) : (
          <Label dim italic>
            {t('profile.empty')}
          </Label>
        )}
      </Center>
    )
  }, [$.empty, fetchStatus, t])

  function renderItem(item: ProfileItem, index: number) {
    let element = (
      <ProfileItemRow
        item={item}
        index={index}
        participant={participant}
        editingList={editingList}
        requestEditItem={editItem}
        requestRemoveItem={removeItem}
      />
    )

    if (editingList) {
      element = (
        <SortableProfileItem sourceList={sortableListID} index={index} payload={item}>
          {element}
        </SortableProfileItem>
      )
    }

    return (
      <VBox key={item.name}>
        {index > 0 && <Dimple horizontal/>}
        {element}
      </VBox>
    )
  }

  const renderSortPlaceholder = React.useCallback(
    () => <VBox style={{height: sortPlaceholderHeight}}/>,
    [],
  )

  function renderFooter() {
    return (
      <HBox justify='space-between' gap={layout.padding.s}>
        {!editingList ? (
          <ClearButton
            icon='plus'
            caption={t('profile.add_item')}
            onTap={createItem}
            small
          />
        ) : (
          <VBox/>
        )}
        {!editingList ? (
          <ClearButton
            icon='ordered-list'
            caption={t('profile.edit_list')}
            onTap={startEditingList}
            small
          />
        ) : (
          <ClearButton
            icon='check'
            caption={t('buttons:done')}
            onTap={stopEditingList}
            small
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default ProfileTable

interface ProfileItemRowProps {
  participant: Participant
  item:        ProfileItem
  index:       number

  editingList:     boolean
  requestEditItem: (index: number) => any
  requestRemoveItem: (index: number) => any
}

const ProfileItemRow = observer('ProfileItemRow', (props: ProfileItemRowProps) => {

  const {participant, item, index, editingList, requestEditItem, requestRemoveItem} = props

  const {t} = useResourceTranslation('participants')

  const tag      = item.type === 'group' ? item.tag : null
  const [groups] = useModelEndpointData(Group, {
    filters: {
      tags: tag,
    },
    fetch: tag != null,
  })
  const value    = participant.getProfileValue(item, groups)

  const isMultiline = item.type === 'text' && item.multiline

  const editItem = React.useCallback(() => {
    requestEditItem(index)
  }, [index, requestEditItem])

  const removeItem = React.useCallback(() => {
    requestRemoveItem(index)
  }, [index, requestRemoveItem])

  const $ = useStyles()

  function render() {
    return (
      <ListItem
        classNames={[$.profileItemRow, {editingList}]}
        caption={LocalizedString.translate(item.label)}
        detail={renderDetail()}
        accessory={isMultiline ? renderActions() : renderValueAndActions()}
        children={isMultiline ? renderValue(true) : null}
      />
    )
  }

  function renderDetail() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Tooltip renderTooltip={renderLegend}>
          <SVG
            name={iconForProfileItemVisibility(item.visibility)}
            size={layout.icon.xs}
            dim
          />
        </Tooltip>
        <Label small dim mono>
          {item.name}
        </Label>
      </HBox>
    )
  }

  function renderValueAndActions() {
    return (
      <HBox flex='shrink' gap={layout.padding.inline.s}>
        {renderValue(false)}
        {renderActions()}
      </HBox>
    )
  }

  function renderValue(multiline: boolean) {
    if (editingList) { return null }

    return (
      <VBox flex='shrink' classNames={multiline  && $.multilineDetail}>
        <Label>
          {value}
        </Label>
      </VBox>
    )
  }

  function renderActions() {
    if (!editingList) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG
          name='sort-handle'
          size={layout.icon.m}
        />
        <ClearButton
          icon='pencil'
          small
          onTap={editItem}
        />
        <ClearButton
          icon='trash'
          small
          onTap={removeItem}
        />
      </HBox>
    )
  }

  function renderLegend() {
    return (
      <VBox classNames={$.legend} gap={layout.padding.inline.s} align='center'>
        <Label small>
          {t('profile.legend_preamble')}
        </Label>
        <HBox gap={layout.padding.inline.m}>
          <HBox gap={layout.padding.inline.s}>
            <SVG name='eye' size={layout.icon.s} dim/>
            <Label small>
              {t('profile.visibility.public')}
            </Label>
          </HBox>
          <Dimple vertical/>
          <HBox gap={layout.padding.inline.s}>
            <SVG name='handshake' size={layout.icon.s} dim/>
            <Label small>
              {t('profile.visibility.connections')}
            </Label>
          </HBox>
          <Dimple vertical/>
          <HBox gap={layout.padding.inline.s}>
            <SVG name='eye-off' size={layout.icon.s} dim/>
            <Label small>
              {t('profile.visibility.private')}
            </Label>
          </HBox>
        </HBox>
      </VBox>
    )
  }

  return render()

})

const sortPlaceholderHeight = layout.barHeight.m

const useStyles = createUseStyles({
  profileTable: {
  },

  empty: {
    height:  layout.barHeight.m,
    padding: layout.padding.inline.m,
  },

  profileItemRow: {
    minHeight: layout.barHeight.m,

    '&.editingList': {
      cursor: 'grab',
    },
  },

  multilineDetail: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  legend: {
    // margin: [0, layout.padding.inline.l]
  },
})