import React from 'react'
import { RelatedToggleField } from 'sheet-importer'
import { ModelOfType } from '~/models'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import { register } from './registry'

export interface Props {
  field: RelatedToggleField
}

const RelatedToggleFieldForm = observer('RelatedToggleFieldForm', (props: Props) => {

  const {field} = props
  const Model = ModelOfType(field.resourceType)

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <ResourceField
          Model={Model}
          {...field.bind('relatedID')}
          small
        />
      </VBox>
    )
  }

  return render()

})

export default RelatedToggleFieldForm
register(RelatedToggleField, RelatedToggleFieldForm)