import React from 'react'
import { LocalizedString, Page } from '~/models'
import { ModelEndpoint } from '~/stores'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG } from '~/ui/components'
import { ResourceList } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'

export interface Props {
  endpoint:   ModelEndpoint<Page>
  selectedID: string | null
}

const PagesList = memo('PagesList', (props: Props) => {

  const {endpoint, selectedID} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceList<Page>
        endpoint={endpoint}
        itemHref={page => `${page.id}`}
        itemIsSelected={page => page.id === selectedID}
        useLocation={true}
      >
        <DataGrid.Column<Page>
          name='title'
          sort={true}
          renderCell={renderTitle}
        />
        <DataGrid.Column<Page>
          name='slug'
          sort={true}
          renderCell={page => <Label mono>{page.slug}</Label>}
        />
      </ResourceList>
    )
  }

  function renderTitle(page: Page) {
    return (
      <HBox gap={layout.padding.inline.m}>
        <Label h3>
          {LocalizedString.translate(page.title)}
        </Label>
        {page.locked && <SVG name='lock' size={layout.icon.xs}/>}
      </HBox>
    )
  }

  return render()

})

export default PagesList