import React from 'react'
import { FeedbackResult } from '~/models'
import { Result } from '~/stores'
import { ResultStyle } from '../types'
// @index(!util): import ${variable} from ${relpath}
import BarChartResult from './BarChartResult'
import DataTableResult from './DataTableResult'
import DonutChartResult from './DonutChartResult'
import GalleryResult from './GalleryResult'

// /index

export default function renderResults(results: Result[], style: ResultStyle) {
  const resultsOfType = <T extends FeedbackResult['type']>(type: T) => {
    return results.filter(it => it.type === type) as Array<Result & {type: T}>
  }

  switch (style.type) {
    case 'data-table':
      return <DataTableResult results={resultsOfType('tallied')} />
    case 'bar-chart':
      return <BarChartResult results={resultsOfType('tallied')} />
    case 'donut-chart':
      return <DonutChartResult results={resultsOfType('tallied')} />
    case 'gallery':
      return <GalleryResult result={resultsOfType('sample')[0]} />
    default:
      return null
  }
}