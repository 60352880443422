import React from 'react'
import { isFunction } from 'lodash'
import { Model } from '~/models'
import { observer } from '~/ui/component'
import { DataGrid } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { childrenNotOfType, childrenOfType } from '~/ui/util'
import CheckboxCell, { checkboxCellWidth } from './CheckboxCell'
import ResourceCollectionFilters from './ResourceCollectionFilters'
import ResourceCollectionScreen, {
  Props as ResourceCollectionScreenProps,
  ResourceCollectionScreenState,
} from './ResourceCollectionScreen'

export interface Props<M extends Model> extends ResourceCollectionScreenProps<M> {
  allowSelect?: boolean | ((model: M) => boolean)
}

const _ResourceListScreen = <M extends Model>(props: Props<M>) => {

  const {
    allowSelect = true,
    children,
    ...rest
  } = props

  const {fieldCaption} = useResourceTranslation(props.Model.resourceType)

  const showCheckboxes = allowSelect && ((props.allowRemove ?? true) || (props.bulkActions ?? []).length > 0)
  const checkboxEnabled = React.useCallback((model: M) => {
    return isFunction(allowSelect) ? allowSelect(model) : allowSelect
  }, [allowSelect])

  const columns = React.useMemo(() => {
    const columns = childrenOfType(children, DataGrid.Column)
      .map(col => React.cloneElement(col, {
        caption: col.props.caption ?? fieldCaption(col.props.name),
      }))

    if (showCheckboxes) {
      columns.unshift(
        <DataGrid.Column<M>
          name='$checkbox'
          width={checkboxCellWidth}
          padded={false}
          renderCell={model => checkboxEnabled(model) ? <CheckboxCell id={model.id}/> : null}
          renderHeaderCell={() => <CheckboxCell id={null}/>}
        />,
      )
    }

    return columns
  }, [checkboxEnabled, children, fieldCaption, showCheckboxes])

  const filters = childrenOfType(children, ResourceCollectionFilters)

  //------
  // Rendering


  function render() {
    return (
      <ResourceCollectionScreen {...rest} renderFilters={filters}>
        {state => (
          <>
            {renderDataGrid(state)}
            {childrenNotOfType(children, [DataGrid.Column, ResourceCollectionFilters])}
          </>
        )}
      </ResourceCollectionScreen>
    )
  }

  function renderDataGrid(state: ResourceCollectionScreenState<M>) {
    return (
      <DataGrid<M>
        data={state.data}
        children={columns}
        itemHref={state.itemHref}
        EmptyComponent={state.EmptyComponent}
        sort={state.sort}
        keyForItem={keyForItem}
        requestSort={state.setSort}
      />
    )
  }

  const keyForItem = React.useCallback(
    (item: Model) => item.id,
    [],
  )

  return render()

}

const ResourceListScreen = observer('ResourceListScreen', _ResourceListScreen)
Object.assign(ResourceListScreen, {
  Filters: ResourceCollectionFilters,
})
export default ResourceListScreen as typeof _ResourceListScreen & {
  Filters: typeof ResourceCollectionFilters
}