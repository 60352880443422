import React from 'react'
import { useSize } from 'react-measure'
import { isFunction } from 'lodash'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'

export interface Props {
  onContentSize?: (size: Size) => any

  objectFit?:   'cover' | 'contain'
  aspectRatio?: number

  flex?:    VBoxProps['flex']
  align?:   VBoxProps['align']
  justify?: VBoxProps['justify']

  children?:      React.ReactNode | ((size: Size) => React.ReactNode)
  classNames?:    React.ClassNamesProp
  svgClassNames?: React.ClassNamesProp
}

const AutosizeSVG = memo('AutosizeSVG', (props: Props) => {

  const {
    onContentSize,
    aspectRatio,
    objectFit = 'contain',
    flex,
    align,
    justify,
    children,
    classNames,
    svgClassNames,
  } = props

  //------
  // Layout

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [contentSize, setContentSize] = React.useState<Size | null>(null)

  const fitContent = React.useCallback((containerSize: Size) => {
    const container = containerRef.current
    const flexBasis = container == null ? undefined : window.getComputedStyle(container).flexBasis

    if (aspectRatio == null) {
      return containerSize
    } else if (containerSize.height === 0 && flexBasis === 'auto') {
      return {
        width:  containerSize.width,
        height: containerSize.width / aspectRatio,
      }
    } else {
      const width = objectFit === 'contain'
        ? Math.min(containerSize.width, containerSize.height * aspectRatio)
        : Math.max(containerSize.width, containerSize.height * aspectRatio)

      return {
        width:  width,
        height: width / aspectRatio,
      }
    }
  }, [aspectRatio, objectFit])

  useSize(containerRef, React.useCallback((size: Size) => {
    const contentSize = fitContent(size)

    onContentSize?.(contentSize)
    setContentSize(contentSize)
  }, [fitContent, onContentSize]))

  const contentStyle = React.useMemo((): React.CSSProperties => {
    if (contentSize == null) {
      return {opacity: 0, pointerEvents: 'none'}
    } else {
      return {...contentSize}
    }
  }, [contentSize])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox
        classNames={[$.autosizeSVGContainer, classNames]}
        flex={flex}
        align={align}
        justify={justify}
        ref={containerRef}
        children={renderSVG()}
      />
    )
  }

  function renderSVG() {
    if (contentSize == null) { return null }

    const {width, height} = contentSize
    return (
      <div classNames={[$.svgWrapper, classNames]} style={contentStyle}>
        <svg classNames={svgClassNames} style={contentStyle} viewBox={`0 0 ${width} ${height}`}>
          {isFunction(children) ? children?.(contentSize) : children}
        </svg>
      </div>
    )
  }

  return render()

})

export default AutosizeSVG

const useStyles = createUseStyles({
  autosizeSVGContainer: {
  },

  svgWrapper: {
    position: 'relative',

    '& > svg': {
      position: 'absolute',
      left:     0,
      top:      0,
      overflow: 'visible',
    },
  },
})