import React from 'react'
import { HBox, InfoIcon, Label, LabelProps, SVG } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  name?:        string
  i18nKey?:     string
  icon?:        SVGName
  caption?:     React.ReactNode | false
  instruction?: string | null | false
  right?:       React.ReactNode
  textAlign?:   LabelProps['align']
  required?:    boolean
}

const FormFieldHeader = (props: Props) => {

  const {name, i18nKey = name, icon, required, right, textAlign} = props

  const {fieldPrompt, fieldInstruction} = useResourceTranslation()
  const caption     = props.caption === false ? null : (props.caption ?? (i18nKey != null ? fieldPrompt(i18nKey) : null))
  const instruction = props.instruction === false ? null : (props.instruction ?? (i18nKey != null ? fieldInstruction(i18nKey) : null))

  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderCaption()}
        {renderInstruction()}
        {right}
      </HBox>
    )
  }

  function renderCaption() {
    if (caption == null) { return null }

    return (
      <HBox flex gap={layout.padding.inline.s}>
        {icon && <SVG name={icon} size={layout.icon.xs} dim/>}
        {isReactText(caption) ? (
          <Label flex caption dim truncate={false} align={textAlign}>
            {caption}
          </Label>
        ) : (
          caption
        )}
        {required && (
          <Label small color={colors.semantic.secondary}>
            *
          </Label>
        )}
      </HBox>
    )
  }

  function renderInstruction() {
    if (instruction == null) { return null }

    return (
      <InfoIcon
        renderTooltip={instruction}
      />
    )
  }

  return render()

}

export default FormFieldHeader