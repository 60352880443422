import React from 'react'
import { useTimer } from 'react-timer'
import { DateTime } from 'luxon'
import { Participant } from '~/models'
import { audioStore, operatorStore } from '~/stores'
import { observer } from '~/ui/component'
import { Audio, AvatarRow, Center, HBox, Label, SVG, TimeAgoLabel, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider, useTheme } from '~/ui/styling'

const OperatorAlertQueue = observer('OperatorAlertQueue', () => {

  const service = operatorStore.service
  const alerts = Array.from(service?.alerts.values() ?? [])

  const participants = React.useMemo(
    () => alerts.map(alert => Participant.deserialize(({
      id:        alert.participantID,
      firstName: alert.firstName,
      lastName:  alert.lastName,
      photoURL:  alert.photoURL,
    }))),
    [alerts],
  )

  const addParticipant = React.useCallback((participant: Participant) => {
    service?.addParticipant(participant.id)
  }, [service])

  const bellRef = React.useRef<Audio>(null)
  const bell    = audioStore.get('bell')

  const timer = useTimer()
  const [ring, setRing] = React.useState<boolean>(false)

  const ringBell = React.useCallback(() => {
    bellRef.current?.play()
    setRing(true)
    timer.debounce(() => {
      setRing(false)
    }, ringDuration)
  }, [timer])

  React.useEffect(
    () => service?.addAlertListener(ringBell),
    [ringBell, service],
  )

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (participants.length === 0) { return null }

    return (
      <ThemeProvider contrast={theme.semantic.warning}>
        {participants.length > 0 && (
          <HBox classNames={$.operatorAlertQueue} gap={layout.padding.inline.m}>
            {renderBell()}

            <AvatarRow
              participants={participants}
              onAvatarTap={addParticipant}
              tooltip={renderTooltip}
              size={avatarSize}
              gap={-avatarSize.width / 4}
            />
          </HBox>
        )}

        {bell != null && (
          <Audio
            source={bell}
            ref={bellRef}
          />
        )}
      </ThemeProvider>
    )
  }

  function renderBell() {
    return (
      <Center classNames={[$.bell, {ring}]}>
        <SVG
          name='bell'
          size={bellSize}
        />
      </Center>
    )
  }

  function renderTooltip(participant: Participant) {
    const firstAlertAt = participant.operator?.firstAlertAt ?? DateTime.local()

    return (
      <VBox gap={layout.padding.inline.s}>
        <Label h3 align='center'>
          {participant.name}
        </Label>
        {firstAlertAt != null && (
          <HBox justify='center' gap={layout.padding.inline.s}>
            <SVG name='clock' size={layout.icon.xs} color={theme.colors.fg.light.dim}/>
            <TimeAgoLabel small dim datetime={firstAlertAt}/>
          </HBox>
        )}
      </VBox>
    )
  }

  return render()

})

export default OperatorAlertQueue

export const alertQueueHeight = 40
export const avatarSize = {
  width:  32,
  height: 32,
}

export const bellSize = {
  width:  20,
  height: 20,
}

const ringDuration = 1500

const useStyles = createUseStyles(theme => ({
  operatorAlertQueue: {
    height:       alertQueueHeight,
    background:   theme.semantic.warning,
    padding:      [layout.padding.inline.xs, layout.padding.inline.m],
    borderRadius: layout.radius.m,
    boxShadow:    shadows.depth(1),
  },

  '@keyframes ring': {
    '0%':   {transform: 'rotate(0)', animationTimingFunction: 'ease-out'},
    '5%':   {transform: 'rotate(-20deg)', animationTimingFunction: 'ease-in'},
    '10%':  {transform: 'rotate(0)', animationTimingFunction: 'ease-out'},
    '15%':  {transform: 'rotate(20deg)', animationTimingFunction: 'ease-in'},
    '20%':  {transform: 'rotate(0)'},
    '25%':  {transform: 'rotate(-20deg)', animationTimingFunction: 'ease-in'},
    '30%':  {transform: 'rotate(0)', animationTimingFunction: 'ease-out'},
    '35%':  {transform: 'rotate(20deg)', animationTimingFunction: 'ease-in'},
    '40%':  {transform: 'rotate(0)'},
    '100%': {transform: 'rotate(0)'},
  },

  '@keyframes scale': {
    '0%':   {transform: 'scale(1)', animationTimingFunction: 'ease-out'},
    '20%':  {transform: 'scale(1.5)', animationTimingFunction: 'ease-in'},
    '40%':  {transform: 'scale(1)'},
  },

  bell: {
    '&.ring': {
      animation: `$scale ${ringDuration}ms infinite`,
      '& > *': {
        animation: `$ring ${ringDuration}ms infinite`,
      },
    },
  },
}))