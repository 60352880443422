import React from 'react'
import { useTranslation } from 'react-i18next'
import { isPlainObject } from 'lodash'
import { Timeframe, TimeframeFilter as TimeframeFilterModel, TimeframeLookback } from '~/models'
import { memo } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import { registerDashboardFilter } from './registry'
import WidgetFilter from './WidgetFilter'

import type { WidgetFilterComponentProps } from './types'
export type Props = WidgetFilterComponentProps<TimeframeFilterModel, Timeframe>

const TimeframeFilter = memo('TimeframeFilter', (props: Props) => {

  const {alotment, widget, filter, value} = props

  const [t] = useTranslation('analytics')

  const createMenuItem = React.useCallback((lookback: TimeframeLookback): PopupMenuItem => ({
    value:   {lookback},
    checked: value?.lookback === lookback,
    caption: t(`filters.timeframe.lookback.${lookback}`),
  }), [t, value])

  const menuItems = React.useMemo(() => (
    filter.lookbacks.map(createMenuItem)
  ), [createMenuItem, filter.lookbacks])

  //------
  // Rendering

  function render() {
    return (
      <WidgetFilter
        alotment={alotment}
        widget={widget}
        filter={filter}
        value={value}
        renderValue={renderValue}
        menuItems={menuItems}
      />
    )
  }

  const renderValue = React.useCallback((value: any) => {
    if (!isPlainObject(value)) { return '' }
    return t(`filters.timeframe.lookback.${value.lookback}`)
  }, [t])

  return render()

})

registerDashboardFilter('timeframe', TimeframeFilter)

export default TimeframeFilter