import React from 'react'
import { memo } from '~/ui/component'
import { FormDataSourceProvider, useForm } from '~/ui/form'
import PostFormBody from '../../feeds/posts/PostFormBody'
import ActionTriggerableVariant from '../triggerables/ActionTriggerableVariant'
import TriggerableFormModel from '../triggerables/TriggerableFormModel'
import PostActionFormModel from './PostActionFormModel'
import { PlannerActionFieldsetProps, register } from './registry'

export type Props = PlannerActionFieldsetProps

const PostFields = memo('PostFields', (props: Props) => {

  const {action} = props
  const {model} = useForm<TriggerableFormModel & {variant: ActionTriggerableVariant}>()
  const formModel = React.useMemo(
    () => new PostActionFormModel(model),
    [model],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDataSourceProvider dataSource={formModel}>
        <PostFormBody
          authorField={action.name === 'news:post-on-behalf'}
        />
      </FormDataSourceProvider>
    )
  }


  return render()

})

register('news:post', PostFields)
register('news:post-on-behalf', PostFields)
export default PostFields