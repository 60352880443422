import I18next from 'i18next'
import { omit } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { Branding } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'
import { BrandingAssetType } from './BrandingAssetsContainer'

export default abstract class BrandingAssetFormModel implements FormModel {

  constructor(
    protected readonly branding: Branding,
    public readonly originalName: string | null,
  ) {
    this.name = originalName ?? ''
    makeObservable(this)
  }

  public readonly abstract assetType: BrandingAssetType

  @observable
  public name: string

  public get isWellKnown() {
    if (this.originalName == null) { return false }

    switch (this.assetType) {
      case 'color':
        return Branding.isWellKnownColor(this.originalName)
      case 'border':
        return Branding.isWellKnownBorder(this.originalName)
      case 'background':
        return Branding.isWellKnownBackground(this.originalName)
      default:
        return false
    }
  }

  //------
  // Submission

  public async submit(): Promise<SubmitResult | undefined> {
    const name = this.name.trim()
    if (name !== this.originalName && name in this.list) {
      return {
        status: 'invalid',
        errors: [{field: 'name', message: I18next.t('validation:unique')}],
      }
    }

    return await dataStore.updateOrCreate(
      Branding,
      this.branding.id,
      this.buildData(),
      this.branding.serialized,
    )
  }

  public reset() {
    const name = this.originalName
    this.name  = name ?? ''
  }

  private get listKey() {
    return (
      this.assetType === 'color' ? 'colors' :
      this.assetType === 'border' ? 'borders' :
      'backgrounds'
    )
  }

  private get list() {
    return this.branding[this.listKey]
  }

  protected buildData(): any {
    const name = this.name.trim()
    const base = this.originalName != null && this.originalName !== name
      ? omit(this.list, this.originalName)
      : this.list

    return {
      [this.listKey]: {
        ...base,
        [name]: this.buildSpec(),
      },
    }
  }

  public abstract buildSpec(): any

}