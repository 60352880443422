import React from 'react'
import { Extension } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, ModalDialog, PushButton } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ExtensionCatalog from './ExtensionCatalog'

export interface Props {
  open:         boolean
  requestClose: () => any

  requestAdd: (extension: Extension) => any
}

const AddExtensionForm = memo('AddExtensionForm', (props: Props) => {

  const {open, requestClose, requestAdd} = props

  const {t} = useResourceTranslation('extensions')

  const [selectedExtension, setSelectedExtension] = React.useState<Extension | null>(null)

  const addSelectedExtension = React.useCallback(() => {
    if (selectedExtension == null) { return }
    requestAdd(selectedExtension)
  }, [requestAdd, selectedExtension])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog open={open} requestClose={requestClose} icon='hexagons' title={t('add')} width={800} height={640} footer={renderButtons()}>
        <ExtensionCatalog
          selectedExtension={selectedExtension}
          onSelect={setSelectedExtension}
        />
      </ModalDialog>
    )
  }

  function renderButtons() {
    return (
      <HBox justify='right' gap={layout.padding.s}>
        <PushButton
          icon='plus'
          caption={t('add')}
          onTap={addSelectedExtension}
          enabled={selectedExtension != null}
        />
        <ClearButton
          icon='cross'
          caption={t('buttons:cancel')}
          onTap={requestClose}
          dim
        />
      </HBox>
    )
  }

  return render()

})

export default AddExtensionForm