import React from 'react'
import { LocalizedString, Page } from '~/models'
import { projectStore } from '~/stores'
import ProjectLabel from '~/ui/app/projects/ProjectLabel'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'

export type Props = Omit<ResourceFieldProps<Page>, 'Model' | 'renderPropsForModel'>

const PageField = observer('PageField', (props: Props) => {

  const currentProjectID = projectStore.projectID

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...props as any}
        Model={Page}
        renderPropsForModel={renderPropsForModel}
      />
    )
  }

  const renderPropsForModel = React.useCallback((page: Page) => ({
    caption: LocalizedString.translate(page.title),
    detail:  page.project === currentProjectID ? null : <ProjectLabel projectID={page.project} tiny dim/>,
  }), [currentProjectID])

  return render()

})

export default PageField