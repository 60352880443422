import { Link } from '~/models'
import { Model, resource } from './Model'
import { Targeting } from './Targeting'

@resource<Location>('locations', {
  icon:    location => location?.type === 'beacon' ? 'beacon' : 'map-pin',
  caption: location => location.name,
})
export class Location extends Model {

  public name!: string
  public type!: 'latlong' | 'beacon'
  public tags!: string[]
  public link!: Link

  public locked!:    boolean
  public targeting!: Targeting

  public coordinate!: LatLong
  public radius!:     number
  public placeID!:    string | null

  public beacons!: Beacon[]

  public participantCount!: number
}

export type LatLongLocation = Location & {type: 'latlong'}
export type BeaconLocation  = Location & {type: 'beacon'}

export interface LatLong {
  latitude:  number
  longitude: number
}

export interface Beacon {
  name:          string | null
  proximityUUID: string
  major:         number | null
  minor:         number | null
}

// Coordinate to use when no other coordinate can be inferred. It's Amsterdam.
export const defaultCoordinate = {
  latitude:  52.3676,
  longitude: 4.9041,
}
export const defaultRadius = 100

export const defaultZoom = 9