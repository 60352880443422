import React from 'react'
import { useTranslation } from 'react-i18next'
import { GroupField, ParticipantField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { Dimple, Panel, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import { useAnswers } from '../AnswersContext'
import DownloadAnswersButton from '../DownloadAnswersButton'
import AnswerScopeField from './AnswerScopeField'
import QuestionsFilter from './QuestionsFilter'

const AnswersPanel = observer('AnswersPanel', () => {

  const {
    scope,
    setScope,
    focusedQuestions,
    setFocusedQuestions,
    participantID,
    setParticipantID,
    segmentGroups,
    setSegmentGroups,
  } = useAnswers()

  const [t] = useTranslation('answers')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        {renderScopePanel()}
        {renderQuestions()}
        {renderActions()}
      </VBox>
    )
  }

  function renderScopePanel() {
    return (
      <Panel>
        {renderScopeSelection()}
        {renderFilters()}
      </Panel>
    )
  }

  function renderScopeSelection() {
    return (
      <VBox classNames={$.filter}>
        <FormField name='scope' {...t('fields.scope')}>
          {bind => (
            <AnswerScopeField
              {...bind}
              inputStyle='dark'
              value={scope}
              onChange={setScope}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  function renderFilters() {
    if (scope == null) {return null}
    return (
      <>
        <Dimple horizontal />
        <VBox classNames={$.filter} >
          <FormField name='participant' {...t('fields.participant')}>
            {bind => (
              <ParticipantField
                {...bind}
                inputStyle='dark'
                value={participantID}
                onChange={setParticipantID}
              />
            )}
          </FormField>
        </VBox>
        <Dimple horizontal />
        <VBox classNames={$.filter}>
          <FormField name='segments' {...t('fields.segments')}>
            {bind => (
              <GroupField
                {...bind}
                inputStyle='dark'
                multi
                value={segmentGroups}
                onChange={setSegmentGroups}
              />
            )}
          </FormField>
        </VBox>
      </>
    )
  }

  function renderQuestions() {
    if (scope == null) { return null }

    return (
      <QuestionsFilter
        scope={scope}
        selectedUUIDs={focusedQuestions}
        onChange={setFocusedQuestions}
      />
    )
  }

  function renderActions() {
    return (
      <VBox>
        {renderDownloadButton()}
      </VBox>
    )
  }

  function renderDownloadButton() {
    return (
      <DownloadAnswersButton />
    )
  }

  return render()
})

export default AnswersPanel

const useStyles = createUseStyles({
  filter: {
    padding: layout.padding.inline.l,
  },
})