import React from 'react'
import { useDrag } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { DraggableItemType, DraggableWidgetItem } from '~/dnd'
import { assignGlobal } from '~/global'
import { observer } from '~/ui/component'
import { Empty, EmptyOrFetching, FlipModal, panelBorderRadius, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useAnalyticsService } from './AnalyticsServiceContext'
import { useDashboardContext } from './DashboardContext'
import { dashboardCellPadding } from './DashboardLayout'
import { WidgetComponentProps } from './types'
import WidgetFiltersBar from './WidgetFiltersBar'
import { WidgetFilterValuesProvider } from './WidgetFilterValuesContext'
import WidgetHeader from './WidgetHeader'
import WidgetReportDialog from './WidgetReportDialog'
import WidgetResizeHandles, { thickness as resizeHandleThickness } from './WidgetResizeHandles'
import renderDashboardWidget from './widgets/index'
import WidgetTile from './WidgetTile'

export type Props = WidgetComponentProps

const WidgetPanel = observer('WidgetPanel', (props: Props) => {

  const {widget, alotment, state} = props
  const {editing, status, reportAlotmentUUID, requestCloseReport} = useDashboardContext()

  const data       = state?.data ?? null
  const hasError   = state?.error ?? false

  const {service}    = useAnalyticsService()
  const filterValues = service?.getFilterValues(alotment.uuid)
  const hasFilters   = widget.filters.dashboard.length > 0

  const [dragging, setDragging] = React.useState<boolean>(false)
  const timer = useTimer()

  const [t] = useTranslation('analytics')

  const [connectDraggable, connectDragHandle] = useDrag<DraggableWidgetItem>({
    enabled: editing && widget != null,

    item: React.useMemo((): DraggableWidgetItem => ({
      type:        DraggableItemType.WIDGET,
      widget:      widget!,
      alotment:    alotment,
      onDashboard: true,
    }), [alotment, widget]),

    start: () => {
      setDragging(true)
    },
    end: () => {
      if (!timer.isDisposed) {
        setDragging(false)
      }
    },
  })

  const refresh = React.useCallback(() => {
    if (alotment == null) { return null }
    service?.refreshWidget(alotment.uuid)
  }, [alotment, service])

  if (widget.name === 'answers:breakdown') {
    assignGlobal({refresh})
  }

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (editing) {
      return renderEditing()
    } else {
      return renderViewing()
    }
  }

  function renderEditing() {
    return (
      <VBox flex classNames={[$.widgetEditing, {dragging}]} ref={connectDraggable}>
        <WidgetTile flex widget={widget} truncate/>
        <div classNames={$.moveHandle} ref={connectDragHandle}/>
        <WidgetResizeHandles alotment={alotment}/>
      </VBox>
    )
  }

  function renderViewing() {
    if (filterValues == null) { return null }

    return (
      <FlipModal
        flipped={reportAlotmentUUID === alotment.uuid}
        requestUnflip={requestCloseReport}
        renderFlipSide={renderReportDialog}
        flipBackClassNames={$.flipBack}
        children={renderViewingFront()}
        height='max'
        width='max'
        flex
      />
    )
  }

  function renderViewingFront() {
    if (filterValues == null) { return null }

    return (
      <WidgetFilterValuesProvider values={filterValues}>
        <VBox flex classNames={$.widgetViewing} gap={layout.padding.inline.m}>
          <WidgetHeader {...props}/>
          <VBox flex gap={layout.padding.inline.m}>
            {hasFilters && (
              <WidgetFiltersBar
                alotment={alotment}
                widget={widget}
                output='dashboard'
              />
            )}
            {hasError ? (
              renderError()
            ) : data == null ? (
              renderEmpty()
            ) : (
              renderDashboardWidget(widget, alotment, state)
            )}
          </VBox>
        </VBox>
      </WidgetFilterValuesProvider>
    )
  }

  function renderReportDialog() {
    if (state == null) { return null }

    return (
      <WidgetReportDialog
        state={state}
        widget={widget}
        alotment={alotment}
        requestClose={requestCloseReport}
      />
    )
  }

  function renderError() {
    return (
      <Empty
        {...t('widget.error')}
        flex
      />
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={status}
      />
    )
  }

  return render()

})

export default WidgetPanel

const useStyles = createUseStyles(theme => ({
  widgetEditing: {
    position: 'relative',

    '&.dragging': {
      visibility: 'hidden',
    },
  },

  moveHandle: {
    ...layout.overlay,

    cursor: 'move',
    top:    resizeHandleThickness,
    right:  resizeHandleThickness,
    bottom: resizeHandleThickness,
    left:   resizeHandleThickness,
  },

  widgetViewing: {
    position: 'relative',
    padding:  dashboardCellPadding,
  },

  flipBack: {
    background:   theme.bg.normal,
    borderRadius: panelBorderRadius(theme),
  },
}))