import { flatMap } from 'ytil'
import { serialize } from '../lib/ymodel/src/decorators/serialize'
import { Condition } from './Condition'
import { DaySelector, Triggerable } from './FlowPlan'
import { Model, resource } from './Model'
import { Module } from './Module'
import { Targeting } from './Targeting'
import { TimeOfDay } from './TimeOfDay'
import { Ref } from './types'

@resource<CalendarPlan>('calendar-plans', {
  icon:    () => 'calendar',
  caption: () => "CalendarPlan",
})
export class CalendarPlan extends Model {

  @serialize(TimeOfDay, {path: 'items.time'})
  public days!: CalendarDay[]

  public modified!: boolean

  public findDay(uuid: string) {
    return this.days.find(t => t.uuid === uuid) ?? null
  }

  public getAllItems() {
    return flatMap(this.days, day => day.items)
  }

  public findItem(uuid: string): CalendarItem | null {
    for (const day of this.days) {
      for (const item of day.items) {
        if (item.uuid === uuid) {
          return item
        }
      }
    }

    return null
  }

}

//------
// CalendarDay

export interface CalendarDay {
  uuid:     string
  selector: DaySelector
  items:    CalendarItem[]
}

export type CalendarItem = CalendarDayTriggerableItem | CalendarDayModuleItem

interface CalendarItemCommon {
  uuid:       string
  time:       TimeOfDay
  targeting:  Targeting
  conditions: Condition[]
}

export interface CalendarDayTriggerableItem extends CalendarItemCommon {
  type:        'triggerable'
  triggerable: Triggerable
}

export interface CalendarDayModuleItem extends CalendarItemCommon {
  type:   'module'
  module: Ref<Module>
}