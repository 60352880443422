import { LatLong, Viewport } from './types'

export function centerCoordinate(bounds: [LatLong, LatLong]) {
  return {
    latitude:  (bounds[0].latitude + bounds[1].latitude) / 2,
    longitude: (bounds[0].longitude + bounds[1].longitude) / 2,
  }
}

export function boundsWithCenterAndRadius(center: LatLong, radius: number): [LatLong, LatLong] {
  const latitudeSpan  = radius / 39931000 * 360
  const longitudeSpan = radius / (Math.cos(latitudeToRadians(center.latitude)) * 40070000) * 360

  return [
    {latitude: center.latitude - latitudeSpan, longitude: center.longitude - longitudeSpan},
    {latitude: center.latitude + latitudeSpan, longitude: center.longitude + longitudeSpan},
  ]
}

const WORLD_DIM = {width: 256, height: 256}
const ZOOM_MAX  = 21

export function viewportEquals(vp1: Viewport, vp2: Viewport) {
  if (vp1.zoom !== vp2.zoom) { return false }

  if ((vp1.center == null && vp2.center != null) || (vp1.center != null && vp2.center == null)) { return false }
  if (vp1.center != null && vp2.center != null && !latLongEquals(vp1.center, vp2.center)) { return false }

  if ((vp1.bounds == null && vp2.bounds != null) || (vp1.bounds != null && vp2.bounds == null)) { return false }
  if (
    vp1.bounds != null && vp2.bounds != null && (
      !latLongEquals(vp1.bounds[0], vp2.bounds[0]) ||
      !latLongEquals(vp1.bounds[1], vp2.bounds[1])
    )
  ) { return false }

  return true
}

export function zoomForBounds(bounds: [LatLong, LatLong], size: Size): number {
  // Google's zoom level is defined as follows:
  //
  // - Zoom level 0 displays world map in 256px by 256px viewport.
  // - Every zoom level doubles resolution.

  function zoom(coordFraction: number, pixelFraction: number) {
    return Math.floor(Math.log(pixelFraction / coordFraction) / Math.LN2)
  }

  const latFraction = Math.abs(latitudeToRadians(bounds[1].latitude) - latitudeToRadians(bounds[0].latitude)) / Math.PI

  const lngDiff = bounds[1].longitude - bounds[0].longitude
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  const latZoom = zoom(latFraction, size.height / WORLD_DIM.height)
  const lngZoom = zoom(lngFraction, size.width / WORLD_DIM.width)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export function convertMetersToPixels(meters: number, coordinate: LatLong, zoom: number): number {
  return meters / metersPerPixel(coordinate, zoom)
}

export function convertPixelsToMeters(points: number, coordinate: LatLong, zoom: number): number {
  return points * metersPerPixel(coordinate, zoom)
}

export function convertMetersToLatitudeDelta(meters: number) {
  return meters / EARTH_RADIUS / Math.PI * 180
}

export function convertMetersToLongitudeDelta(meters: number, coordinate: LatLong) {
  return meters / (EARTH_RADIUS * Math.cos(Math.PI * coordinate.latitude / 180))
}

export function metersPerPixel(coordinate: LatLong, zoom: number) {
  const radians = coordinate.latitude * Math.PI / 180
  const scale   = Math.pow(2, zoom)
  return Math.cos(radians) * 2 * Math.PI * EARTH_RADIUS / (256 * scale)
}

export function convertToLatLng(latlong: LatLong): google.maps.LatLng {
  const {latitude, longitude} = latlong
  return new google.maps.LatLng(latitude, longitude)
}

export function convertFromLatLng(latLng: google.maps.LatLng): LatLong {
  return {
    latitude:  latLng.lat(),
    longitude: latLng.lng(),
  }
}

export function convertToLatLngBounds(bounds: [LatLong, LatLong]): google.maps.LatLngBounds {
  return new google.maps.LatLngBounds(
    convertToLatLng(bounds[0]),
    convertToLatLng(bounds[1]),
  )
}

export function convertFromLatLngBounds(bounds: google.maps.LatLngBounds): [LatLong, LatLong] {
  return [
    convertFromLatLng(bounds.getSouthWest()),
    convertFromLatLng(bounds.getNorthEast()),
  ]
}

export function applyLatLongBoundsPadding(bounds: [LatLong, LatLong], meters: number): [LatLong, LatLong] {
  const center    = centerCoordinate(bounds)
  const latDelta  = convertMetersToLatitudeDelta(meters)
  const longDelta = convertMetersToLongitudeDelta(meters, center)

  const northWest: LatLong = {
    latitude:  Math.min(bounds[0].latitude, bounds[1].latitude) - latDelta,
    longitude: Math.min(bounds[0].longitude, bounds[1].longitude) - longDelta,
  }
  const southEast: LatLong = {
    latitude:  Math.max(bounds[0].latitude, bounds[1].latitude) + latDelta,
    longitude: Math.max(bounds[0].longitude, bounds[1].longitude) + longDelta,
  }

  return [northWest, southEast]
}

export function roundCoordinate(coordinate: LatLong, decimals: number = 5): LatLong {
  return {
    latitude:  roundTo(coordinate.latitude, decimals),
    longitude: roundTo(coordinate.longitude, decimals),
  }
}

export function formatLatLong(coordinate: LatLong) {
  const {latitude, longitude} = coordinate

  const lat = `${latitude < 0 ? 'S' : 'N'}\u00A0${formatDegrees(Math.abs(latitude))}`
  const long = `${longitude < 0 ? 'W' : 'E'}\u00A0${formatDegrees(Math.abs(longitude))}`

  return `${lat} ${long}`
}

export function latLongEquals(left: LatLong, right: LatLong) {
  return left.latitude === right.latitude && left.longitude === right.longitude
}

export function formatDegrees(degrees: number) {
  const degs = Math.floor(degrees)
  const minutes = Math.floor((degrees - degs) * 60)
  const seconds = ((degrees - degs) * 60 - minutes) * 60

  return `${degs}°\u00A0${minutes}'\u00A0${seconds.toFixed(3)}"`
}

function latitudeToRadians(latitude: number) {
  const sin   = Math.sin(latitude * Math.PI / 180)
  const radX2 = Math.log((1 + sin) / (1 - sin)) / 2

  return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
}

function roundTo(num: number, decimals: number) {
  const exp = Math.pow(10, decimals)
  return Math.round(num * exp) / exp
}

export const EARTH_RADIUS = 6378137


const reverseGeocodeComponentTypes = [
  'locality',
  'administrative_area_level_1',
  'country',
  'administrative_area_level_2',
]

export function findGeocodeComponent(components: google.maps.GeocoderAddressComponent[]) {
  for (const type of reverseGeocodeComponentTypes) {
    const component = components.find(comp => comp.types.includes(type))
    if (component != null) { return component }
  }

  return null
}

export function extractGeocodedName(result: google.maps.GeocoderResult | null) {
  if (result == null) { return null }

  const component = findGeocodeComponent(result.address_components)
  return component == null ? null : component.short_name
}
