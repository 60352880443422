import React from 'react'
import { forwardRef } from '~/ui/component'
import DateTimeComponentsField, {
  DateTimeComponent,
  DateTimeComponentsFieldTemplate,
  Props as DateTimeComponentsFieldProps,
} from './DateTimeComponentsField'

export interface Props extends Omit<DateTimeComponentsFieldProps, 'template'> {
  dateOrder?:     'YMD' | 'DMY' | 'MDY'
  dateSeparator?: string
  timeSeparator?: string
  inputRef?:      React.Ref<HTMLInputElement>
}

const DateTimeField = forwardRef('DateTimeField', (props: Props, ref: React.Ref<DateTimeComponentsField>) => {

  const {
    dateOrder     = 'DMY',
    dateSeparator = '-',
    timeSeparator = ':',
    picker        = true,
    inputRef,
    ...rest
  } = props

  const template = React.useMemo((): DateTimeComponentsFieldTemplate => {
    const dateComponents = dateOrder.split('').map((comp): DateTimeComponent => {
      switch (comp) {
        case 'D': return 'day'
        case 'M': return 'month'
        case 'Y': return 'year'
        default:  return 'year'
      }
    })

    return [
      {component: dateComponents[0]},
      {separator: dateSeparator},
      {component: dateComponents[1]},
      {separator: dateSeparator},
      {component: dateComponents[2]},
      {separator: ' '},
      {component: 'hour'},
      {separator: timeSeparator},
      {component: 'minute'},
    ]
  }, [dateOrder, dateSeparator, timeSeparator])

  return (
    <DateTimeComponentsField
      {...rest}
      ref={ref}
      inputRef={inputRef}
      template={template}
      picker={picker}
    />
  )

})

export default DateTimeField