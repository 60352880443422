import { DateTime } from 'luxon'
import { DataField, RelatedField } from 'sheet-importer'
import { serialize } from 'ymodel'
import { Group } from './Group'
import { Model, resource } from './Model'
import { Project } from './Project'
import { importable } from './importing'
import { Ref } from './types'

@resource<User>('users', {
  icon:    'user',
  caption: user => user.name,
  scopedToModule: false,
})
export class User extends Model {

  @importable()
  public email!: string

  @importable() public name!:     string
  @importable() public photoURL!: string | null
  @importable() public role!:     Role

  @importable() public notificationEmails!: boolean

  @importable({type: Boolean})
  public needsPasswordChange!: boolean

  @serialize(DateTime)
  public lastLoginAt!: DateTime | null

  @importable({
    field: () => new RelatedField('organisation', 'organisations'),
  })
  public organisation!:     string
  public organisationName!: string

  // Role: admin

  @importable({
    field: () => new RelatedField('projects', 'projects', {multi: true}),
  })
  public projects!: Ref<Project>[]

  // Role: host

  @importable({
    field: () => new RelatedField('groups', 'groups', {multi: true}),
  })
  public groups!: Ref<Group>[]

  @importable({
    field: column => new DataField('data', column.name),
  })

  //------
  // Derived

  public isSuper() {
    return this.role === 'super'
  }

  public isAdmin() {
    if (this.isSuper()) { return true }
    if (this.role !== 'admin') { return false }

    return true
  }

  public isController() {
    if (this.isAdmin()) { return true }
    return this.role === 'controller'
  }

  public isEditor() {
    if (this.isController()) { return true }
    return this.role === 'editor'
  }

  public isHost() {
    if (this.isEditor()) { return true }
    return this.role === 'host'
  }

  public isAnalyst() {
    if (this.isEditor()) { return true }
    return this.role === 'analyst'
  }

  /**
   * Checks whether the user has (at least) the given role.
   *
   * @param role The role to check.
   * @param strict If `true`, the role must match exactly. If `false` (default), a higher role is also OK.
   */
  public hasRole(role: Role, strict: boolean = false) {
    if (strict) {
      return this.role === role
    }

    switch (role) {
      case 'super':
        return this.isSuper()
      case 'admin':
        return this.isAdmin()
      case 'controller':
        return this.isController()
      case 'editor':
        return this.isEditor()
      case 'host':
        return this.isHost()
      case 'analyst':
        return this.isAnalyst()
    }
  }

  public bewizr!: boolean

}

export type Role = 'super' | 'admin' | 'controller' | 'editor' | 'host' | 'analyst'

export interface MajorTomState {
  completed:            string[]
  showWelcomeAtStartup: boolean
}

export const Role: {
  all: (me: User) => Role[]
} = {
  all: (me: User) => [...(me.isSuper() ? ['super'] as Role[] : []), 'admin', 'controller', 'editor', 'host', 'analyst'],
}