import { action, makeObservable, observable } from 'mobx'

export default class ZStack {

  constructor(
    uuids: string[] = [],
  ) {
    this.uuids = uuids
    makeObservable(this)
  }

  @observable
  public uuids: string[]

  public sortByZOrder<T extends {uuid: string}>(items: T[]): T[] {
    return [...items].sort((a, b) => this.uuids.indexOf(a.uuid) - this.uuids.indexOf(b.uuid))
  }

  //------
  // Operations

  @action
  public makeTopMost(uuids: string[]) {
    this.uuids = [
      ...this.uuids.filter(u => !uuids.includes(u)),
      ...uuids,
    ]
  }

  @action
  public remove(uuids: string[]) {
    this.uuids = this.uuids.filter(uuid => !uuids.includes(uuid))
  }

  @action
  public removeUnused(inUse: string[]) {
    const keepUUIDs = new Set(inUse)
    this.uuids = this.uuids.filter(uuid => keepUUIDs.has(uuid))
  }

}