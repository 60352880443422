import React from 'react'
import Timer from 'react-timer'
import { assignRef, useContinuousRef } from '~/ui/hooks'
import { closest, isScrolledElement } from '~/ui/util'

export function useCanvasPan(spec: CanvasPanSpec): [Point, CanvasPanConnect, CanvasPanConnect] {
  const containerRef = React.useRef<HTMLElement | null>()
  const panLayerRef  = React.useRef<HTMLElement | null>()

  const {origin, enabled, zoom} = spec

  // Use a state to allow the client code to update accordingly.
  const [current, setCurrent] = React.useState<Point>(origin)

  // Use refs internally for the handlers.
  const currentRef = React.useRef<Point>(current)
  const originRef  = React.useRef<Point>(origin)

  const zoomRef   = useContinuousRef(zoom)
  const enabledRef = useContinuousRef(enabled)

  // Use a ref to keep track of onPan - it's only used after dragging.
  const onPan = React.useRef(spec.onPan)
  onPan.current = spec.onPan

  // Reset the current pan whenever the origin changes.
  React.useLayoutEffect(() => {
    if (origin === originRef.current) { return }
    originRef.current = origin
    setCurrent(origin)
  }, [origin])

  //------
  // Dragging

  const startRef = React.useRef<Point>({x: 0, y: 0})

  const onMouseMove = React.useCallback((event: MouseEvent) => {
    const zoom = zoomRef.current
    setCurrent(currentRef.current = {
      x: originRef.current.x + (event.clientX - startRef.current.x) / zoom,
      y: originRef.current.y + (event.clientY - startRef.current.y) / zoom,
    })
  }, [zoomRef])

  const onMouseUp = React.useCallback((event: MouseEvent) => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)

    if (enabledRef.current) {
      onPan.current(currentRef.current)
    }
  }, [enabledRef, onMouseMove])

  const onMouseDown = React.useCallback((event: MouseEvent) => {
    event.preventDefault()

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    startRef.current = {x: event.clientX, y: event.clientY}
  }, [onMouseMove, onMouseUp])

  React.useEffect(() => {
    if (enabled) {
      panLayerRef.current?.addEventListener('mousedown', onMouseDown, {capture: true})
    } else {
      panLayerRef.current?.removeEventListener('mousedown', onMouseDown, {capture: true})
    }
  }, [enabled, onMouseDown])

  //------
  // Mouse wheel

  const wheelTimer = React.useRef<Timer | null>(null)

  const onWheel = React.useCallback((event: WheelEvent) => {
    const scrollable = closest(event.target as Element, isScrolledElement)
    if (scrollable != null) { return }

    if (event.metaKey) { return }

    event.preventDefault()

    if (wheelTimer.current == null) {
      setCurrent(currentRef.current = origin)
      wheelTimer.current = new Timer()
    }

    setCurrent(currentRef.current = {
      x: currentRef.current.x - event.deltaX / 2,
      y: currentRef.current.y - event.deltaY / 2,
    })

    wheelTimer.current.clearAll()
    wheelTimer.current.setTimeout(() => {
      wheelTimer.current = null
      onPan.current(currentRef.current)
    }, 200)
  }, [origin])

  //------
  // Connect

  const connectContainer = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (container: HTMLElement | null) => {
      assignRef(ref, container)
      if (container === containerRef.current) { return }

      if (containerRef.current) {
        containerRef.current.removeEventListener('wheel', onWheel)
      }
      if (container)     {
        container.addEventListener('wheel', onWheel)
      }

      containerRef.current = container
    }
  }, [onWheel])

  const connectPanLayer = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (panLayer: HTMLElement | null) => {
      assignRef(ref, panLayer)
      if (panLayer === panLayerRef.current) { return }

      panLayerRef.current = panLayer
    }
  }, [])

  return [current, connectContainer, connectPanLayer]
}

export interface CanvasPanSpec {
  enabled:  boolean

  origin: Point
  zoom:   number

  onPan:  (origin: Point) => any
}

export type CanvasPanConnect = (ref: React.Ref<HTMLElement>) => (element: HTMLElement | null) => void