import React from 'react'
import { AutofocusProvider } from 'react-autofocus'
import { memo } from '~/ui/component'
import { Dimple, Panel, VBox } from '~/ui/components'
import { Form } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import ResourceCollectionFiltersFormModel from './ResourceCollectionFiltersFormModel'
import { useResourceListLocation } from './useResourceListLocation'

export interface Props {
  children?: React.ReactNode
}

const ResourceCollectionFilters = memo('ResourceCollectionFilters', (props: Props) => {

  const {filters, setFilters} = useResourceListLocation()

  const formModel = React.useMemo(
    () => new ResourceCollectionFiltersFormModel(filters ?? {}, setFilters),
    [filters, setFilters],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Form classNames={$.ResourceCollectionFilters} model={formModel} autoSubmit>
        <AutofocusProvider enabled={false}>
          <Panel>
            {React.Children.map(props.children, (child, index) => (
              <VBox classNames={$.filterItem}>
                {index > 0 && <Dimple horizontal/>}
                {child}
              </VBox>
            ))}
          </Panel>
        </AutofocusProvider>
      </Form>
    )
  }

  return render()

})

export default ResourceCollectionFilters

const useStyles = createUseStyles({
  ResourceCollectionFilters: {
  },

  filterItem: {
    padding: [layout.padding.inline.l, layout.padding.inline.l],
  },
})