import React from 'react'
import { PlanPositionInsightsSummary, PositionLocator } from '~/stores'
import { observer } from '~/ui/component'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import FlowPlanInsightsBadgeRow from '../nodes/FlowPlanInsightsBadgeRow'
import { useCanvas } from './FlowPlannerCanvasContext'

export interface Props {
  transform: string
}

const InsightsLayer = observer('InsightsLayer', (props: Props) => {

  const {transform} = props

  const {insightsShown} = useCanvas.unoptim()
  const {planner}       = useFlowPlanner()

  const insights        = planner.insights
  const boundsOverrides = planner.componentBoundsOverrides

  const findNode = React.useCallback(
    (position: PositionLocator) => planner.plan?.findNode(position.node) ?? null,
    [planner.plan],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!insightsShown) { return null }

    return (
      <div classNames={$.insightsLayer}>
        <div classNames={$.container} style={{transform}}>
          {insights.summaries.map(renderItem)}
        </div>
      </div>
    )
  }

  function renderItem(summary: PlanPositionInsightsSummary) {
    const node = findNode(summary.position)
    if (node == null) { return null }

    return (
      <FlowPlanInsightsBadgeRow
        key={`${summary.position.node}::${summary.position.segue}`}
        summary={summary}
        node={node}
        bounds={boundsOverrides?.get(node.uuid) ?? node.bounds}
        insights={insights}
      />
    )
  }

  return render()

})

export default InsightsLayer

export const createIconSize = layout.icon.m

const useStyles = createUseStyles({
  insightsLayer: {
    ...layout.overlay,
    transformStyle: 'preserve-3d',
    pointerEvents:  'none',
  },

  container: {
    position: 'absolute',
    top:      0,
    left:     0,
    width:    0,
    height:   0,

    willChange: ['transform'],
    'html.canvas-anim &': {
      transition: animation.transitions.medium('transform'),
    },
  },
})