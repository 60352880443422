import React from 'react'

interface WidgetsContext {
  startConfiguringWidget?: () => void
  endConfiguringWidget?:   () => void

  setParams: (widget: string, params: Record<string, any>) => void
}

const WidgetsContext = React.createContext<WidgetsContext>({
  startConfiguringWidget: () => void 0,
  endConfiguringWidget:   () => void 0,

  setParams: () => void 0,
})

export default WidgetsContext