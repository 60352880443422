import React from 'react'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import ModelTriggerableForm from '../../triggerables/ModelTriggerableForm'
import CalendarItemCommonFields from './CalendarItemCommonFields'
import CalendarTriggerableItemFormModel from './CalendarTriggerableItemFormModel'

export type Props = FormDialogProps<CalendarTriggerableItemFormModel>

const CalendarModelTriggerableForm = memo('CalendarModelTriggerableForm', (props: Props) => {

  function render() {
    return (
      <ModelTriggerableForm {...props} model={props.model}>
        <CalendarItemCommonFields/>
      </ModelTriggerableForm>
    )
  }

  return render()

})

export default CalendarModelTriggerableForm