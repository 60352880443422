import { emptyScriptMessage, Project, ScriptMessage } from '~/models'
import { NewScriptMessage } from './types'

export function newScriptMessage(type: ScriptMessage['type'], project: Project | null): NewScriptMessage {
  return {
    ...emptyScriptMessage(type, project),
    uuid: '+new',
  }
}

export function isNewScriptMessage(message: ScriptMessage | NewScriptMessage): message is NewScriptMessage {
  return message.uuid === '+new'
}

export function messageDescription(message: ScriptMessage) {
  const processedText = message.type === 'text' || message.type === 'notice'
    ? message.text.replace(/\{\{(.*)\}\}/g, '#{$participant[:$1]}')
    : null

  switch (message.type) {
  case 'text':    return `send ${JSON.stringify(processedText)}`
  case 'notice':  return `send notice: ${JSON.stringify(processedText)}`
  case 'image':   return `send image: ${message.image.name}`
  case 'video':   return `send video: ${message.video.name}`
  }
}