import React from 'react'
import { startCase } from 'lodash'
import { useSocketOperation } from 'socket.io-react'
import { observer } from '~/ui/component'
import { Label, ProgressBar, VBox } from '~/ui/components'
import { useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import CopyToProjectFormModel from './CopyToProjectFormModel'
import CopyToProjectResultList from './CopyToProjectResultList'

export interface Props {
  count: number
}

const CopyStep = observer('CopyStep', (props: Props) => {

  const {model: formModel} = useForm<CopyToProjectFormModel>()

  const [progress, labelKey, modelName, caption] = useSocketOperation(formModel.operation)
  const results = formModel.results

  const {t} = useResourceTranslation()

  const label = labelKey == null ? '' : t(`actions.copy-to-project.progress.${labelKey}`, {
    model:   modelName == null ? null : startCase(modelName).toLowerCase(),
    caption: caption,
  })

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.CopyStep} flex='grow'>
        {results == null && renderProgress()}
        {results != null && renderResults()}
      </VBox>
    )
  }

  function renderProgress() {
    return (
      <VBox flex='grow' justify='middle' gap={layout.padding.s}>
        <ProgressBar
          progress={progress}
        />
        <Label align='center' small dim markup>
          {label}
        </Label>
      </VBox>
    )
  }

  function renderResults() {
    return (
      <CopyToProjectResultList/>
    )

  }

  return render()

})

export default CopyStep

const useStyles = createUseStyles({
  CopyStep: {
    minHeight: 360,
  },

  copyButton: {
    minWidth: 320,
  },
})