import React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import { stringContains } from 'ytil'
import { ModelClass } from '~/models'
import { memo } from '~/ui/component'
import { AutoCompleteField, AutoCompleteFieldProps } from '~/ui/components'
import { useTags } from '~/ui/hooks/data'

export type Props = SingleProps | MultiProps

export interface CommonProps {
  Model: ModelClass<any>

  small?:       boolean
  smallChips?:  boolean
  inputStyle?:  AutoCompleteFieldProps<any, any>['inputStyle']
}

export interface SingleProps extends CommonProps {
  value:    string | null
  onChange: (value: string | null) => any
  multi:    false
}

export interface MultiProps extends CommonProps {
  value:    string[]
  onChange: (value: string[]) => any
  multi?:   true
}

const TagsField = memo('TagsField', (props: Props) => {

  const {Model, multi = true, value, onChange, ...rest} = props

  //------
  // Values

  // TagsField is always rendered as a multi-autocomplete field, since it makes more sense.
  // Here, we convert from multi or single values to always multi and back.

  const fieldValue = React.useMemo(() => {
    if (isArray(value)) {
      return value
    } else if (value == null) {
      return []
    } else {
      return [value]
    }
  }, [value])

  const handleChange = React.useCallback((value: string[]) => {
    if (multi) {
      onChange?.(value as any)
    } else {
      onChange?.(value.length === 0 ? null : value[0] as any)
    }
  }, [multi, onChange])

  const tags = useTags(Model)

  const doesntHaveTag = React.useCallback((tag: string) => {
    if (isArray(value)) {
      return !value.includes(tag)
    } else {
      return value !== tag
    }
  }, [value])

  const search = React.useCallback((query: string | null) => {
    const base = [...tags ?? []]
      .filter(doesntHaveTag)
      .sort()

    if (query != null) {
      return base.filter(tag => stringContains(tag, query))
    } else {
      return base
    }
  }, [doesntHaveTag, tags])

  const [t] = useTranslation('tags_field')

  //------
  // Rendering

  function render() {
    return (
      <AutoCompleteField<string, string>
        multi={true} // Always show as a multi-field.
        maxValues={multi ? undefined : 1} // Use maxValues to actually limit the number of choices.
        value={fieldValue}
        onChange={handleChange as any}

        onSearch={search}
        choiceForValue={tag => tag}
        valueForChoice={tag => tag}
        requestCreate={tag => tag}
        noResultsPlaceholder={t('start_typing')}
        createPlaceholder={name => t('create_named', {name})}
        {...rest}
      />
    )
  }

  return render()

})

export default TagsField