import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import { useQueryParam } from 'use-query-params'
import { Challenge, Module, Script } from '~/models'
import { memo, observer } from '~/ui/component'
import { Dimple, HBox, Label, Popup, Scroller, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import ActionComponentCatalog from './ActionComponentCatalog'
import ModuleComponentCatalog from './ModuleComponentCatalog'
import OtherComponentCatalog from './OtherComponentCatalog'
import TriggerComponentCatalog from './TriggerComponentCatalog'
import TriggerableComponentCatalog from './TriggerableComponentCatalog'
import { CreateComponentType } from './types'

export interface Props {
  open:          boolean
  owningModule:  Module
  requestClose:  () => any
  getTargetRect: () => LayoutRect
  requestCreate: (value: CreateComponentType, model?: Script | Challenge | Module | null) => any
}

const CreateComponentPopup = observer('CreateComponentPopup', (props: Props) => {

  const {open, owningModule, requestClose, getTargetRect, requestCreate} = props

  const [t] = useTranslation('flow_planner')

  const [activeSection, setCurrentSection] = React.useState<CreateComponentSection>('triggers')

  const tabs = React.useMemo(() => CreateComponentSection.all.map((section): CreateComponentTab => ({
    section: section,
    icon:    CreateComponentSection.icon(section),
    ...t(`create_component.${section}`),
  })), [t])

  //------
  // Keyboard navigation

  const move = React.useCallback((delta: number) => {
    const currIndex = tabs.findIndex(it => it.section === activeSection)
    const nextIndex = (currIndex + delta + tabs.length) % tabs.length
    setCurrentSection(tabs[nextIndex].section)
  }, [tabs, activeSection])

  const moveSectionUp   = React.useCallback(() => move(-1), [move])
  const moveSectionDown = React.useCallback(() => move(+1), [move])

  useHotkey(open ? {mac: 'Cmd+ArrowUp', other: 'PageUp'} : null, moveSectionUp, {inputs: 'handle'})
  useHotkey(open ? {mac: 'Cmd+ArrowDown', other: 'PageDown'} : null, moveSectionDown, {inputs: 'handle'})

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <Popup
        open={open}
        requestClose={requestClose}
        getTargetRect={getTargetRect}
        crossAlign='near'
        renderContent={renderContent}
        autoFocus={false} // We use autofocus based on active section.
        classNames={$.popup}
      />
    )
  }

  function renderContent() {
    return (
      <HBox flex='grow' align='stretch' classNames={$.content}>
        <VBox>
          {renderTabs()}
        </VBox>
        <Dimple vertical />
        <VBox classNames={$.sections} flex>
          {renderSections()}
        </VBox>
      </HBox>
    )
  }

  function renderTabs() {
    return(
      <Scroller classNames={$.tabs} touchLike>
        {tabs.map(tab => (
          <CreateComponentTab
            key={tab.section}
            tab={tab}
            onTap={setCurrentSection}
            active={open && activeSection === tab.section}
          />
        ))}
      </Scroller>
    )
  }

  function renderSections() {
    return CreateComponentSection.all.map((section) => {
      const active = section === activeSection

      return (
        <VBox key={section} flex classNames={[$.section, {active}]}>
          {renderSection(section, active)}
        </VBox>
      )
    })
  }

  function renderSection(section: CreateComponentSection, active: boolean) {
    switch (section) {
      case 'triggers':
        return (
          <TriggerComponentCatalog
            requestCreate={requestCreate}
            active={active}
          />
        )
      case 'scripts':
        return (
          <TriggerableComponentCatalog
            triggerableType='script'
            owningModule={owningModule}
            requestCreate={requestCreate}
            active={active}
          />
        )
      case 'challenges':
        return (
          <TriggerableComponentCatalog
            triggerableType='challenge'
            owningModule={owningModule}
            requestCreate={requestCreate}
            active={active}
          />
        )
      case 'actions':
        return (
          <ActionComponentCatalog
            requestCreate={requestCreate}
            active={active}
          />
        )
      case 'modules':
        return (
          <ModuleComponentCatalog
            requestCreate={requestCreate}
            active={active}
          />
        )
      case 'other':
        return (
          <OtherComponentCatalog
            owningModule={owningModule}
            requestCreate={requestCreate}
            active={active}
          />
        )
    }
  }

  return render()
})

export default CreateComponentPopup

export interface CreateComponentTabProps {
  tab:     CreateComponentTab
  onTap:   (value: CreateComponentSection) => any
  active?: boolean
}

export const CreateComponentTab = memo('CreateComponentTab', (props: CreateComponentTabProps) => {

  const {tab, onTap, active = false} = props
  const {section, icon, caption, detail} = tab

  const [hoverMode] = useQueryParam('hoverMode')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <ThemeProvider dark={active}>
        <Tappable onTap={tap} classNames={[$.tab, {active}, {hoverMode}]} onMouseEnter={hoverMode ? tap : undefined}>
          <HBox flex gap={layout.padding.s} align='middle'>
            {renderIcon()}
            {renderLabel()}
          </HBox>
        </Tappable>
      </ThemeProvider>
    )
  }

  function renderIcon() {
    return (
      <SVG
        name={icon}
        size={layout.icon.m}
      />
    )
  }

  function renderLabel() {
    return(
      <VBox gap={layout.padding.inline.xs}>
        <Label small bold>{caption}</Label>
        <Label tiny dim>{detail}</Label>
      </VBox>
    )
  }

  const tap = React.useCallback(() => {
    onTap(section)
  }, [onTap, section])

  return render()
})

const baseHeight = 640
const minHeight  = 320

const sectionsWidth    = 380
const minSectionsWidth = 320

const useStyles = createUseStyles(theme => ({
  popup: {
    flex: [0, 1, `${baseHeight}px`],
  },

  content: {
    minHeight: minHeight,
  },

  tabs: {
    background: theme.colors.bg.alt,
  },

  tab: {
    height: layout.barHeight.m,
    padding: [layout.padding.inline.l, layout.padding.inline.xl],
    '&:hover': {
      background: theme.bg.hover,
    },
    '&:focus-visible': {
      boxShadow: shadows.focus.bold(theme),
    },
    '&.active': {
      background: theme.semantic.primary,
    },
    '&.hoverMode': {
      cursor: 'default',
    },
  },

  sections: {
    position: 'relative',
    flex:     [0, 1, sectionsWidth],
    minWidth: minSectionsWidth,
  },

  section: {
    '&:not(.active)': {
      ...layout.overlay,
      visibility: 'hidden',
    },

    '&.active': {
      position: 'static',
    },
  },
}))

export interface CreateComponentTab {
  section: CreateComponentSection
  caption: string
  detail?: string
  icon:    SVGName
}

export type CreateComponentSection =
  | 'triggers'
  | 'scripts'
  | 'challenges'
  | 'actions'
  | 'modules'
  | 'other'

export const CreateComponentSection: {
  all:  CreateComponentSection[]
  icon: (section: CreateComponentSection) => SVGName
} = {
  all:  ['triggers', 'scripts', 'challenges', 'actions', 'modules', 'other'],
  icon: section => {
    if (section === 'triggers') { return  'flash' }
    else if (section === 'scripts') { return  'chat' }
    else if (section === 'challenges') { return  'challenge' }
    else if (section === 'actions') { return  'star' }
    else if (section === 'modules') { return  'puzzle' }
    else { return 'cog' }
  },
}