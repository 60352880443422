import React from 'react'
import { PlanTextAnnotation, PlanTextAnnotationTextSize } from '~/models'

export function useTextAnnotationStyle(annotation: PlanTextAnnotation) {

  const textSize = React.useMemo(() => annotation.style.textSize ?? PlanTextAnnotationTextSize.NORMAL, [annotation.style.textSize])
  const bold     = React.useMemo(() => annotation.style.bold ?? false, [annotation.style.bold])
  const italic   = React.useMemo(() => annotation.style.italic ?? false, [annotation.style.italic])
  const align    = React.useMemo(() => annotation.style.align ?? 'left', [annotation.style.align])

  return React.useMemo(() => ({
    fontSize:   textSize,
    fontWeight: bold ? 700 : 400,
    fontStyle:  italic ? 'italic' : 'normal',
    textAlign:  align,
  }), [align, bold, italic, textSize])

}