// @index(!registry): import ${relpath}
import './CheckpointFields'
import './EmailFields'
import './HTTPRequestFields'
import './PostFields'
import './UnlockableFields'
import React from 'react'
import { DynamicFieldset } from '~/ui/app/dynamic-form'
import { getPlannerActionFieldsComponent, PlannerActionFieldsetProps } from './registry'

// /index

export default function renderPlannerActionFields(props: PlannerActionFieldsetProps) {
  const Component = getPlannerActionFieldsComponent(props.action.name)

  if (Component != null) {
    return <Component {...props}/>
  } else {
    return <DynamicFieldset schema={props.schema}/>
  }
}