import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Participant } from '~/models'
import { BulkSelector, dataStore } from '~/stores'
import { memo } from '~/ui/component'
import { Dimple, Panel, SwitchField, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  open:          boolean
  requestClose?: () => any

  selector: BulkSelector
}

const ResetParticipantForm = memo('ResetParticipantForm', (props: Props) => {

  const {selector, ...rest} = props

  const formModel = React.useMemo(
    () => new ResetParticipantFormModel(selector),
    [selector],
  )

  const {t, actionCaption} = useResourceTranslation('participants')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        model={formModel}
        {...rest}

        semi={false}
        title={actionCaption('reset')}
        submitButton={{
          icon:   'reset',
          caption: actionCaption('reset'),
        }}
      >
        <TextBlock small dim>
          {t('reset.message')}
        </TextBlock>

        <Panel semi>
          <VBox padding={layout.padding.s}>
            <FormField name='removeAnswers' caption={false} showErrors={false}>
              {bind => <SwitchField {...bind}/>}
            </FormField>
          </VBox>
          <Dimple horizontal/>
          <VBox padding={layout.padding.s}>
            <FormField name='removeData' caption={false} showErrors={false}>
              {bind => <SwitchField {...bind}/>}
            </FormField>
          </VBox>
          <Dimple horizontal/>
          <VBox padding={layout.padding.s}>
            <FormField name='onboard' caption={false} showErrors={false}>
              {bind => <SwitchField {...bind}/>}
            </FormField>
          </VBox>
        </Panel>
      </FormDialog>
    )
  }

  return render()

})

export default ResetParticipantForm

class ResetParticipantFormModel {

  constructor(
    private readonly selector: BulkSelector,
  ) {
    makeObservable(this)
  }

  @observable
  public removeAnswers: boolean = false

  @observable
  public removeData: boolean = false

  @observable
  public onboard: boolean = false

  public async submit() {
    const endpoint = dataStore.endpoint(Participant)
    return await endpoint.callBulkAction('reset', {
      ...this.selector,
      meta: {
        ...this.selector.meta,
        removeAnswers: this.removeAnswers,
        removeData:    this.removeData,
        onboard:       this.onboard,
      },
    }, {
      timeout: 60_000,
    })
  }

}