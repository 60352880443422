import I18next from 'i18next'
import { Model, resource } from './Model'
import { Participant } from './Participant'
import { QAndA } from './QAndA'
import { Ref } from './types'

@resource<Question>('questions', {
  icon:    () => 'question',
  caption: question => question.title,
  details: question => I18next.t('questions:vote_count', {count: question.voteCount}),
  scopedToModule: false,
})
export class Question extends Model {

  public qAndA!:       Ref<QAndA>
  public participant!: Ref<Participant>
  public title!:       string
  public body!:        string
  public voteCount!:   number

}