import React from 'react'
import { Channel, ChatBot } from '~/models'
import { memo } from '~/ui/component'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { SummaryInfo } from '~/ui/resources/detail'
import ChannelBotsForm from './ChannelBotsForm'

export interface Props {
  channel: Channel
}

const ChannelBots = memo('ChannelBots', (props: Props) => {

  const {channel} = props
  const [formOpen, openForm, closeForm] = useBoolean()

  const {t} = useResourceTranslation('channels')

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon='robot'
        bold={channel.bots.length > 0}
        summary={t(`bots.summary.${channel.bots.length === 0 ? 'empty' : 'notempty'}`)}
        body={renderBots()}
        requestEdit={openForm}
      >

        <ChannelBotsForm
          open={formOpen}
          requestClose={closeForm}
          channel={channel}
        />
      </SummaryInfo.Item>
    )
  }

  function renderBots() {
    if (channel.bots.length === 0) { return null }

    return (
      <ResourceChipRow
        Model={ChatBot}
        ids={channel.bots}
        small
      />
    )
  }

  return render()

})

export default ChannelBots