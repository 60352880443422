import { safeParseInt } from 'ytil'
import { formatOrdinal } from '~/formatting'

export function nth() {
  return (value: any) => {
    const num = safeParseInt(value)
    if (num == null) { return value }

    return formatOrdinal(num)
  }
}