import React from 'react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout, presets } from '~/ui/styling'

export interface Props {
  x:      number
  y:      number
  active: boolean
}

const DashboardBlock = memo('DashboardBlock', (props: Props) => {

  const {active, x, y} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const style: React.CSSProperties = {
      gridColumn: x + 1,
      gridRow:    y + 1,
    }

    return (
      <VBox flex classNames={[$.dashboardBlock, {active}]} style={style}>
      </VBox>
    )
  }

  return render()

})

export default DashboardBlock

const useStyles = createUseStyles(theme => ({
  dashboardBlock: {
    position: 'relative',

    '&.active': {
      ...presets.overlayAfter({
        borderRadius: layout.radius.l,
        background:   theme.bg.normal,
        boxShadow: [
          ['inset', -1, -1, 2, 0, theme.bg.normal.darken(0.1)],
          ['inset', 1, 1, 2, 0, theme.bg.normal.lighten(0.6)],
        ],
      }),
    },
  },
}))