import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import I18n from 'i18next'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { useContinuousRef, usePrevious } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { height, width } from './layout'
import MonthCalendar from './MonthCalendar'
import MonthSelector from './MonthSelector'
import ShortcutButton from './ShortcutButton'
import { CalendarAnnotation, CalendarShortcut } from './types'
import YearGrid from './YearGrid'
import YearSelector from './YearSelector'

export interface Props {
  selectedDate?: DateTime | null
  onSelect?:     (date: DateTime | null) => any

  utc?:     boolean
  enabled?: boolean

  weekStart?:   number
  annotations?: CalendarAnnotation[]

  dateShortcuts?: CalendarShortcut[]
  yearShortcuts?: CalendarShortcut[]
}

const Calendar = memo('Calendar', (props: Props) => {

  const {
    utc     = false,
    enabled = true,

    selectedDate = null,
    onSelect,

    weekStart   = 1, // Start on a Monday by default.
    annotations = [],

    dateShortcuts = defaultCalendarShortcuts(),
    yearShortcuts = defaultYearShortcuts(),
  } = props

  const [currentMonth, setCurrentMonth] = React.useState<DateTime>(selectedDate ?? localDateTime(utc))
  const [view, setView]                 = React.useState<'calendar' | 'years'>('calendar')

  const selectedDateRef  = useContinuousRef(selectedDate)
  const prevSelectedDate = usePrevious(selectedDate)

  React.useEffect(() => {
    if (selectedDate == null || prevSelectedDate == null) { return }
    if (selectedDate.hasSame(prevSelectedDate, 'month')) { return }

    setCurrentMonth(selectedDate)
  }, [prevSelectedDate, selectedDate])

  //------
  // Date selection

  const goToYear = React.useCallback((year: number) => {
    setCurrentMonth(currentMonth.set({year}))
    setView('calendar')
  }, [currentMonth])

  const selectDateTime = React.useCallback((dateTime: DateTime) => {
    const prevDate = selectedDateRef.current ?? localDateTime(utc)
    const nextDate = prevDate.set({
      year:   dateTime.year,
      month:  dateTime.month,
      day:    dateTime.day,
    })

    const adjustedNextDate = nextDate.plus({
      minutes: timeZoneAdjustmentMinutes(prevDate, nextDate),
    })
    if (selectedDateRef.current != null && adjustedNextDate.equals(selectedDateRef.current)) {
      return
    }

    onSelect?.(adjustedNextDate)
  }, [onSelect, selectedDateRef, utc])

  //------
  // Callbacks

  const switchToYearsView = React.useCallback(() => {
    setView('years')
  }, [])

  const switchToCalendarView = React.useCallback(() => {
    setView('calendar')
  }, [])

  const handleShortcutTap = React.useCallback((dateTime: DateTime | null) => {
    if (view === 'years') {
      setCurrentMonth(dateTime ?? localDateTime(utc))
      switchToCalendarView()
    } else if (dateTime != null) {
      selectDateTime(dateTime)
    } else {
      onSelect?.(null)
    }
  }, [onSelect, selectDateTime, switchToCalendarView, utc, view])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {view === 'calendar' ? renderMonthSelector() : renderYearSelector()}
        {renderShortcuts(view === 'calendar' ? dateShortcuts : yearShortcuts)}

        <TransitionGroup classNames={$.bodyContainer}>
          <CSSTransition key={view} classNames={view} timeout={animation.durations.medium}>
            <VBox classNames={$.body}>
              {renderBody()}
            </VBox>
          </CSSTransition>
        </TransitionGroup>
      </VBox>
    )
  }

  function renderBody() {
    if (view === 'calendar') {
      return (
        <MonthCalendar
          month={currentMonth}
          selectedDate={selectedDate}
          onSelectDateTime={selectDateTime}
          goToMonth={setCurrentMonth}
          weekStart={weekStart}
          annotations={annotations}
          utc={utc}
          enabled={enabled}
        />
      )
    } else {
      return (
        <YearGrid
          currentYear={currentMonth.year}
          goToYear={goToYear}
          enabled={enabled}
        />
      )
    }
  }

  function renderMonthSelector() {
    return (
      <MonthSelector
        currentMonth={currentMonth}
        goToMonth={setCurrentMonth}
        onCaptionTap={switchToYearsView}
        enabled={enabled}
      />
    )
  }

  function renderYearSelector() {
    return (
      <YearSelector
        currentYear={currentMonth}
        goToYear={setCurrentMonth}
        onCaptionTap={switchToCalendarView}
        enabled={enabled}
      />
    )
  }

  function renderShortcuts(shortcuts: CalendarShortcut[]) {
    return (
      <HBox gap={layout.padding.inline.l} justify='space-between'>
        {shortcuts.map(shortcut => (
          <ShortcutButton
            key={shortcut.caption}
            shortcut={shortcut}
            onTap={handleShortcutTap}
            enabled={enabled}
          />
        ))}
      </HBox>
    )
  }

  return render()

})

export default Calendar

//------
// Shortcuts

function defaultCalendarShortcuts(): CalendarShortcut[] {
  return [{
    caption: I18n.t('calendar:today'),
    date:    () => DateTime.local(),
  }, {
    caption: I18n.t('calendar:tomorrow'),
    date:    () => DateTime.local().plus({days: 1}),
  }]
}

function defaultYearShortcuts(): CalendarShortcut[] {
  return [{
    caption: I18n.t('calendar:this_year'),
    date:    () => DateTime.local(),
  }, {
    caption: I18n.t('calendar:next_year'),
    date:    () => DateTime.local().plus({years: 1}),
  }]
}

function localDateTime(utc: boolean) {
  const dateTime = DateTime.local()
  if (utc) {
    return dateTime.toUTC(undefined, {keepLocalTime: true})
  } else {
    return dateTime
  }
}

function timeZoneAdjustmentMinutes(prevDate: DateTime, nextDate: DateTime) {
  const prevOffset = prevDate.zone.offset(prevDate.toMillis())
  const nextOffset = nextDate.zone.offset(nextDate.toMillis())

  return nextOffset - prevOffset
}

const useStyles = createUseStyles({
  bodyContainer: {
    position: 'relative',
    overflow: 'hidden',
    width:    width + 4,
    height:   height + 4,
  },

  body: {
    ...layout.overlay,
    top:    2,
    left:   2,
    right:  2,
    bottom: 2,

    '&.calendar': {
      ...animation.explode(animation.durations.medium),
    },
    '&.years': {
      ...animation.fade(animation.durations.medium),
    },
  },
})