import React from 'react'
import { CustomImage } from '~/models'
import { memo } from '~/ui/component'
import { InitialsAvatar } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import ImageView from './ImageView'

export interface Props {
  source:      CustomImage | string | null
  firstName:   string
  lastName?:   string | null
  size:        Size
  classNames?: React.ClassNamesProp
}

const Avatar = memo('Avatar', (props: Props) => {

  const {
    source,
    firstName,
    lastName,
    size,
    classNames,
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (source == null) {
      return (
        <InitialsAvatar
          initials={[firstName?.charAt(0), lastName?.charAt(0)].filter(Boolean).join('')}
          size={size}
          classNames={[$.avatar, classNames]}
        />
      )
    } else {
      return (
        <ImageView
          source={source}
          alt={[firstName, lastName].filter(Boolean).join(' ')}
          size={size}
          round={true}
          classNames={[$.avatar, classNames]}
        />
      )
    }
  }

  return render()

})

export default Avatar

const useStyles = createUseStyles(theme => ({
  avatar: {
    border: [2, 'solid', theme.colors.fg.light.normal],
  },
}))