import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import clipboard from 'rich-clipboard'
import { memo } from '~/ui/component'
import {
  ClearButton,
  ClearButtonProps,
  HBox,
  TextField,
  TextFieldProps,
  VBox,
} from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props extends TextFieldProps {
  showValue?: boolean
  button?:    ClearButtonProps
}

const CopyToClipboard = memo('CopyToClipboard', (props: Props) => {

  const {value, showValue = true, button, ...rest} = props
  const [t] = useTranslation('copy_to_clipboard')

  const copyToClipboard = React.useCallback(() => {
    clipboard.write([{
      type: 'text/plain',
      data: value,
    }])

    Toast.show({
      type: 'sucess',
      ...t('success'),
    })
  }, [t, value])

  //------
  // Rendering

  function render() {
    if (showValue) {
      return (
        <HBox gap={layout.padding.inline.m} align='top'>
          {renderValue()}
          {renderButton()}
        </HBox>
      )
    } else {
      return renderButton()
    }
  }

  function renderValue() {
    return (
      <VBox flex='shrink'>
        <TextField
          value={value}
          selectOnFocus
          readOnly
          {...rest}
        />
      </VBox>
    )
  }

  function renderButton() {
    return (
      <ClearButton
        icon='copy'
        caption={t('copy')}
        padding='both'
        {...button}
        onTap={copyToClipboard}
      />
    )
  }

  return render()

})

export default CopyToClipboard