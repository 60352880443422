import React from 'react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { colors, createUseStyles, ThemeProvider } from '~/ui/styling'
import AuthFooter from './AuthFooter'

export interface Props {
  children?: React.ReactNode
}

const AuthLayout = memo('AuthLayout', (props: Props) => {

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <VBox flex='grow' classNames={$.AuthLayout}>
          {renderContent()}
          <AuthFooter/>
        </VBox>
      </ThemeProvider>
    )
  }

  function renderContent() {
    return (
      <VBox flex='grow' justify='middle' classNames={$.content}>
        {props.children}
      </VBox>
    )
  }

  return render()

})

export default AuthLayout

const useStyles = createUseStyles(theme => ({
  '@keyframes bganim': {
    '0%': {
      backgroundPosition: ['50%', '0%'],
    },
    '25%': {
      backgroundPosition: ['100%', '50%'],
    },
    '50%': {
      backgroundPosition: ['50%', '100%'],
    },
    '75%': {
      backgroundPosition: ['0%', '50%'],
    },
    '100%': {
      backgroundPosition: ['50%', '0%'],
    },
  },

  AuthLayout: {
    backgroundColor: theme.colors.named.blue.darken(0.8),
    backgroundImage: [
      colors.linearGradient(
        'bottom left',
        theme.colors.semantic.primary.alpha(0.5),
        theme.colors.semantic.secondary.alpha(0.5),
      ),
    ],
    backgroundSize: ['150%', '150%'],
    animation:      '$bganim 8s ease infinite',
  },

  content: {
    background: theme.colors.named.white.alpha(0.05),
  },
}))