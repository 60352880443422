import React from 'react'
import { Widget } from '~/models'
import { memo } from '~/ui/component'
import { Center, Label, Panel, VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import WidgetHeader from './WidgetHeader'

export interface Props {
  widget:      Widget
  flex?:       VBoxProps['flex']
  truncate?:   boolean
  classNames?: React.ClassNamesProp
}

const WidgetTile = memo('WidgetTile', (props: Props) => {

  const {widget, flex, truncate = false, classNames} = props

  const $ = useStyles()

  function render() {
    return (
      <Panel flex={flex} semi={false} classNames={classNames}>
        {renderHeader()}
        <VBox flex='both' padding={layout.padding.inline.m} gap={layout.padding.inline.m}>
          {renderPreview()}
          {renderDescription()}
        </VBox>
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <WidgetHeader
        widget={widget}
        padded={true}
        actions={false}
      />
    )
  }

  function renderPreview() {
    return (
      <Center flex='both'>
        <img
          src={`/analytics/${widget.preview}.png`}
          alt={widget.preview}
          classNames={$.preview}
        />
      </Center>
    )
  }

  function renderDescription() {
    return (
      <Label tiny dim truncate={truncate} align='center'>
        {widget.translate('description')}
      </Label>
    )
  }

  return render()

})

export default WidgetTile

const useStyles = createUseStyles({
  preview: {
    flex:      [1, 0, '192px'],
    objectFit: 'contain',
    maxWidth:  `calc(min(288px, 80%))`,
  },
})