import React from 'react'
import { QAndA, Question } from '~/models'
import { ParticipantField, RichTextField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { Dimple, TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  qAndA:     QAndA
  question?: Question | null
}

const QuestionForm = memo('QuestionForm', (props: Props) => {

  const {open, requestClose, afterSubmit, qAndA, question = null} = props

  const initialData = React.useMemo(() => question == null ? {qAndA: qAndA.id} : {}, [qAndA.id, question])

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        open={open}
        requestClose={requestClose}
        Model={Question}
        model={question}
        initialData={initialData}
        afterSubmit={afterSubmit}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='participant' required>
          {bind => <ParticipantField {...bind}/>}
        </FormField>
        <Dimple horizontal/>
        <FormField name='title' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='body' renderAsLabel={false}>
          {bind => <RichTextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default QuestionForm