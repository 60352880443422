import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { observer } from '~/ui/component'
import { HBox, Label, PanelHeader, PushButton, Scroller, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { Form } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import ComponentBrandingFormModel from './ComponentBrandingFormModel'
import { useComponentBranding } from './hooks'
import { renderComponentBrandingFields } from './index'

export interface Props {
  model: ComponentBrandingFormModel
}

const ComponentBrandingForm = observer('ComponentBrandingForm', (props: Props) => {

  const {model} = props

  const {t, fieldCaption} = useResourceTranslation()

  const [component] = useComponentBranding(model.componentName)

  const variantChoices = React.useMemo(() => {
    if (component == null) { return [] }

    const choices: Choice<string | null>[] = []
    choices.push({value: null, caption: t('variants.default')})

    for (const name of component.variantNames) {
      choices.push({value: name, caption: name})
    }
    return choices
  }, [component, t])

  const [variant, setVariant] = useQueryParam('variant', StringParam)

  React.useEffect(() => {
    if (model == null) { return }

    const resolved = model.switchVariant(variant ?? null)
    if (resolved !== variant) {
      setVariant(resolved)
    }
  }, [variant, model, setVariant])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (model == null) { return null }

    return (
      <Form flex classNames={$.componentBrandingForm} model={model}>
        {renderHeader()}
        <Scroller flex>
          <VBox flex='grow' padding={layout.padding.s}>
            {renderComponentBrandingFields(model.componentName)}
          </VBox>
        </Scroller>
        <HBox justify='right' classNames={$.buttons}>
          <PushButton
            submit
            icon='check'
            caption={t('buttons:save')}
          />
        </HBox>
      </Form>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={<Label small bold mono>{model.componentName}</Label>}
        right={renderRight()}
        roundTop={false}
      />
    )
  }

  function renderRight() {
    if (variantChoices.length <= 1) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        <Label caption small>{fieldCaption('variant')}</Label>
        {renderVariantSelector()}
      </HBox>
    )
  }

  function renderVariantSelector() {
    return (
      <SelectField
        value={variant ?? null}
        onChange={setVariant}
        choices={variantChoices}
        tiny
      />
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  componentBrandingForm: {
    background: theme.bg.semi,
  },

  buttons: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.inline.l],
    })),
  },
}))

export default ComponentBrandingForm