import { DateTime } from 'luxon'
import { serialize } from 'ymodel'
import { Ref } from '~/models'
import { Model, resource } from './Model'
import { Project } from './Project'

@resource<RegistrationCode>('registration-codes', {
  icon:    'code',
  caption: preset => preset.code,
  scopedToModule: false,
})
export class RegistrationCode extends Model {

  /**
   * A preset that defines the scope and configuration for registration.
   */
  public preset!: Ref<RegistrationCode>

  /**
   * The actual code.
   */
  public code!: string

  /**
   * Optionally, a specific project that this code is valid for. If not set, the prospec participant can
   * choose the target project in a list. Otherwise, the participant will be automatically registered
   * in this project.
   */
  public project!: Ref<Project> | null

  @serialize(DateTime)
  public registeredAt!: DateTime | null

  @serialize(DateTime)
  public revokedAt!: DateTime | null

  public status!: RegistrationCodeStatus

}

export enum RegistrationCodeStatus {
  pending    = 'pending',
  registered = 'registered',
  revoked    = 'revoked',
  expired    = 'expired',
}