import { some } from 'lodash'
import { DateTime } from 'luxon'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { modifyObject } from 'ytil'
import { CalendarPlan, Insight, PlannerActionCategoryDescriptor, TimeOfDay } from '~/models'
import { Pack } from '../../data'
import dataStore from '../../dataStore'
import { submitResultForResponse } from '../../support'
import { CalendarPlanInsights, CalendarPlannerServiceMeta, CalendarPlanRuleResult } from '../types'
import CalendarPlanDayInsightsEndpoint from './CalendarPlanDayInsightsEndpoint'

export default class CalendarPlannerService extends OnDemandService {

  constructor(
    socket: Socket,
    private readonly moduleID: string,
  ) {
    super(socket)
    makeObservable(this)
  }

  @observable
  public planID: string | null = null

  @computed
  public get plan() {
    if (this.planID == null) { return null }
    return dataStore.get<CalendarPlan>(CalendarPlan, this.planID)
  }

  public async start() {
    await super.startWithEvent('calendar-planner:start', {
      moduleID: this.moduleID,
    })
  }

  protected onStarted = (result: StartSuccess<Pack<CalendarPlan>>) => {
    this.socket.prefix = `calendar-planner:${this.uid}:`

    const pack = result.data
    this.planID = dataStore.storePack(pack)?.id ?? null
    this.storeMeta(pack.meta)

    this.socket.on('insights', this.onInsightsUpdate)

    // Now rehydrate.
    this.rehydrate()
  }

  public stop() {
    this.persistDisposer?.()
    super.stop()
  }

  public restart() {
    this.persistDisposer?.()
    this.planID = null
    return super.restart()
  }

  //------
  // Interface

  @observable
  public publishing: boolean = false

  @observable
  public lastPublished: DateTime | null = null

  @computed
  public get mayPublish() {
    return !some(this.rules, result => result.rule.level === 'error')
  }

  @computed
  public get modified() {
    return this.plan?.modified ?? false
  }

  @action
  public async validate() {
    const response = await this.socket.send('validate', {
      data: {
        type: 'calendar-plans',
        id:   this.planID,
      },
    })

    if (response.ok) {
      dataStore.storePack(response.body)
      this.storeMeta(response.body.meta)
    }

    return submitResultForResponse(response)
  }

  @action
  public async update(update: Partial<CalendarPlan>) {
    const response = await this.socket.send('save-draft', {
      data: {
        type:       'calendar-plans',
        id:         this.planID,
        attributes: this.serializeUpdate(update),
      },
    })

    if (response.ok) {
      dataStore.storePack(response.body)
      this.storeMeta(response.body.meta)
    }

    return submitResultForResponse(response)
  }

  private serializeUpdate(update: Partial<CalendarPlan>) {
    update = modifyObject(update, 'days.items.time', (time: TimeOfDay) => time.minutes)
    return update
  }

  @action
  public async publishChanges() {
    if (this.publishing) { return }
    this.publishing = true

    const response = await this.socket.send('publish')
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)

        this.publishing    = false
        this.lastPublished = DateTime.local()
      })
    }

    return submitResultForResponse(response)
  }

  @action
  public async rollbackChanges() {
    const response = await this.socket.send('rollback')
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)
      })
    }

    return submitResultForResponse(response)
  }

  @action
  public async activateCalendar(participantIDs: Array<string | null>, options: ActivateCalendarOptions = {}) {
    const response = await this.socket.send('runtime:activate', participantIDs, options)
    return submitResultForResponse(response)
  }

  //------
  // Metadata

  @action
  private storeMeta(meta: CalendarPlannerServiceMeta) {
    if (meta.rules != null) {
      this.rules = meta.rules
    }
    if (meta.insights != null) {
      this.insights = meta.insights
    }

    // Any silenced rules that don't occur anymore need to be removed, as they need to re-appear when they occur again.
    this.silencedRules = this.silencedRules.filter(hash => some(this.rules, result => hash === result.hash))
  }

  //------
  // CalendarPlanner runtime

  @observable
  public insights: CalendarPlanInsights | null = null

  public dayPositionsEndpoint(dayUUID: string) {
    return new CalendarPlanDayInsightsEndpoint(this.socket, dayUUID, dataStore.db(Insight))
  }

  private onInsightsUpdate = action((insights: CalendarPlanInsights) => {
    this.insights = insights
  })

  @action
  public async abandonStates(stateIDs: string[]) {
    return this.socket.send('runtime:abandon_states', stateIDs)
      .then(response => submitResultForResponse(response))
  }


  //------
  // Actions

  @observable
  public actions: PlannerActionCategoryDescriptor[] = []

  //------
  // Warnings

  @observable
  public rules: CalendarPlanRuleResult[] = []

  @observable
  public silencedRules: string[] = []

  @observable
  public silenceRule(hash: string) {
    this.silencedRules.push(hash)
  }

  //------
  // Persistence

  private persistDisposer?: IReactionDisposer

  public onRequestRehydrate?: (planID: string) => any
  public onRequestPersist?:   (planID: string, state: any) => void

  @action
  private rehydrate() {
    if (this.planID == null) { return }

    const state = this.onRequestRehydrate?.(this.planID)
    if (state?.silencedRules != null) {
      this.silencedRules = state.silencedRules
    }

    this.persistDisposer = reaction(() => this.persistedState, state => {
      if (this.planID == null) { return }
      this.onRequestPersist?.(this.planID, state)
    })
  }

  @computed
  private get persistedState() {
    return {
      silencedRules: [...this.silencedRules],
    }
  }

}

export interface ActivateCalendarOptions {
  dayUUID?: string
  params?:  AnyObject
}