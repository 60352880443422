import React from 'react'
import { Condition } from '~/models'
import { observer } from '~/ui/component'
import { Empty, HBox, PushButton, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, SubmitResult } from '~/ui/form'
import { useFormOpen } from '~/ui/hooks'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ConditionForm from './ConditionForm'
import ConditionFormModel from './ConditionFormModel'
import ConditionsFormModel from './ConditionsFormModel'
import ConditionsList from './ConditionsList'

export interface Props extends Omit<FormDialogProps<ConditionsFormModel>, 'children'> {
  instructions: string
}

const ConditionsForm = observer('ConditionsForm', (props: Props) => {

  const {t} = useResourceTranslation('conditions')

  const {
    instructions,
    model,
    title = t('form.title'),
    ...rest
  } = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='conditions'>
        <FormDialog semi={false} model={model} title={title} onWillOpen={openConditionFormIfEmpty} width={720} height={560} {...rest}>
          {renderBody()}
          {renderConditionForm()}
        </FormDialog>
      </ResourceTypeProvider>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m} style={{height: 320}}>
        <TextBlock small dim>
          {t(instructions)}
        </TextBlock>
        <ConditionsList
          conditions={model.conditions}
          EmptyComponent={renderEmpty}
          flex={model.conditions.length === 0}
          requestEdit={(_, index) => setEditingIndex(index)}
          requestRemove={(_, index) => model.removeConditionAt(index)}
        />
        {model.conditions.length > 0 && (
          <HBox justify='left'>
            {renderAddButton()}
          </HBox>
        )}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <Empty flex {...t('form.empty')}>
        {renderAddButton()}
      </Empty>
    )
  }

  function renderAddButton() {
    return (
      <PushButton
        icon='plus'
        caption={t('form.add_condition')}
        onTap={addCondition}
        small
      />
    )
  }

  //------
  // Add / edit condition

  const [editingIndex, setEditingIndex] = React.useState<number | null>(null)

  const addCondition = React.useCallback(() => {
    setEditingIndex(-1)
  }, [])

  const openConditionFormIfEmpty = React.useCallback(() => {
    if (model.conditions.length === 0) {
      addCondition()
    }
  }, [addCondition, model.conditions.length])

  const saveCondition = React.useCallback((condition: Condition): SubmitResult | undefined => {
    if (editingIndex == null) { return }

    if (editingIndex >= 0) {
      model.replaceConditionAt(editingIndex, condition)
    } else {
      model.addCondition(condition)
    }

    return {status: 'ok'}
  }, [editingIndex, model])

  const [formOpen, currentEditingIndex, closeForm] = useFormOpen(editingIndex, () => {
    setEditingIndex(null)
  })

  const editingCondition = React.useMemo(() => {
    if (currentEditingIndex == null) { return null }
    return model.conditions[currentEditingIndex] ?? null
  }, [currentEditingIndex, model.conditions])

  const [conditionFormModel, setConditionFormModel] = React.useState<ConditionFormModel | null>(null)

  React.useEffect(() => {
    if (!props.open) { return }

    const formModel = new ConditionFormModel(editingCondition, saveCondition, model.context)
    setConditionFormModel(formModel)
    return () => { formModel.dispose() }
  }, [editingCondition, model.context, props.open, saveCondition])


  function renderConditionForm() {
    if (currentEditingIndex == null || conditionFormModel == null) { return null }

    return (
      <ConditionForm
        open={formOpen}
        requestClose={closeForm}
        formModel={conditionFormModel}
      />
    )
  }

  return render()

})

export default ConditionsForm