import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Competition, Group } from '~/models'
import { dataStore } from '~/stores'
import { TagsField } from '~/ui/app/tags'
import { observer } from '~/ui/component'
import { TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props extends Omit<FormDialogProps<any>, 'model'> {
  competition: Competition
}

const CompetitionTeamsForm = observer('CompetitionTeamsForm', (props: Props) => {

  const {competition, ...rest} = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new CompetitionTeamsFormModel(competition),
    [competition],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDialog model={formModel} {...rest} title={t('teams_form.title')}>
        <VBox gap={layout.padding.m}>
          <TextBlock small dim>
            {t('teams_form.instructions')}
          </TextBlock>

          <FormField name='teamTags'>
            {bind => <TagsField Model={Group} {...bind}/>}
          </FormField>
        </VBox>
      </FormDialog>
    )
  }

  return render()

})

export default CompetitionTeamsForm

class CompetitionTeamsFormModel implements FormModel {

  constructor(
    public readonly competition: Competition,
  ) {
    makeObservable(this)
  }

  @observable
  public teamTags: string[] = this.competition.teamTags ?? []

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Competition, this.competition.id, {
      teamTags: this.teamTags,
    })
  }

}