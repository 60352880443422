import React from 'react'
import { range } from 'lodash'
import { Endpoint } from 'mobx-document'
import config from '~/config'
import { memo, observer } from '~/ui/component'
import { ClearButton, HBox, Label, PushButton, Spinner } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'

export interface Props<E extends Endpoint<any, any, PaginationMetaShape>> {
  endpoint: E
  page:     number
  setPage:  (page: number) => any
}

export interface PaginationMetaShape {
  searchTotal?: number
  total:        number | null
}

const Pagination = observer('Pagination', (props: Props<any>) => {

  const {endpoint, page, setPage} = props

  const total      = endpoint.meta?.searchTotal ?? endpoint.meta?.total
  const lastPage   = total == null ? null : Math.ceil(total / config.resource.pageSize)

  return (
    <PaginationButtons
      fetching={endpoint.fetchStatus === 'fetching'}
      currentPage={page}
      lastPage={lastPage}
      setPage={setPage}
    />
  )

})

export default Pagination

interface PaginationButtonsProps {
  fetching:    boolean
  currentPage: number
  lastPage:    number | null
  setPage:     (page: number) => any
}

const PaginationButtons = memo('PaginationButtons', (props: PaginationButtonsProps) => {

  const firstPage = 1
  const showAround = 2

  const {fetching, currentPage, setPage} = props
  const lastPage = props.lastPage ?? currentPage + showAround

  const currentGroupStart = Math.max(firstPage, Math.min(lastPage - 2 * showAround, currentPage - showAround))
  const currentGroupEnd   = Math.min(currentGroupStart + 2 * showAround, lastPage)

  const goToPage = React.useCallback((page: number) => {
    return () => setPage(page)
  }, [setPage])

  const theme = useTheme()

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.m} justify='space-between'>
        {renderPreviousButton()}
        {fetching ? (
          <Spinner size={12}/>
        ) : (
          renderPageButtons()
        )}
        {renderNextButton()}
      </HBox>
    )
  }

  function renderPreviousButton() {
    return (
      <PushButton
        icon='double-chevron-left'
        enabled={!fetching && currentPage > firstPage}
        onTap={goToPage(currentPage - 1)}
        small
      />
    )
  }

  function renderNextButton() {
    return (
      <PushButton
        icon='double-chevron-right'
        enabled={!fetching && currentPage < lastPage}
        onTap={goToPage(currentPage + 1)}
        small
      />
    )
  }

  //------
  // Page buttons

  const start   = renderPageButton(firstPage)
  const current = range(currentGroupStart, currentGroupEnd + 1).map(renderPageButton)
  const end     = renderPageButton(lastPage)

  const left   = currentPage > showAround + 1 ? start : current
  const center = currentPage <= showAround + 1 || currentPage > lastPage - showAround - 1 ? null : current
  const right  = currentPage <= lastPage - showAround - 1 ? end : current

  function renderPageButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {lastPage <= showAround * 2 + 1 && current}
        {lastPage > showAround * 2 + 1 && left && renderButtonGroup(left)}
        {lastPage > showAround * 2 + 1 && left && renderButtonSeparator()}
        {lastPage > showAround * 2 + 1 && center && renderButtonGroup(center)}
        {lastPage > showAround * 2 + 1 && center && renderButtonSeparator()}
        {lastPage > showAround * 2 + 1 && right && renderButtonGroup(right)}
      </HBox>
    )
  }

  function renderButtonGroup(content: React.ReactNode) {
    return (
      <HBox gap={layout.padding.inline.m}>
        {content}
      </HBox>
    )
  }

  function renderPageButton(page: number) {
    return (
      <ClearButton
        key={page}
        enabled={page !== currentPage}
        color={page === currentPage ? theme.fg.normal : theme.semantic.primary}
        caption={page.toString()}
        onTap={goToPage(page)}
        small
      />
    )
  }

  function renderButtonSeparator() {
    return (
      <Label dim>
        …
      </Label>
    )
  }

  return render()

})