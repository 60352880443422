import { CollectionFetchOptions, Database, Endpoint } from 'mobx-document'
import { Sender } from '~/models'
import ChatService from '../ChatService'
import SenderDocument from './SenderDocument'

export default class SendersEndpoint extends Endpoint<SenderDocument, {}, Meta> {

  constructor(
    database: Database<SenderDocument>,
    public readonly service: ChatService,
  ) {
    super(database, {}, {})
    this.service = service
  }

  public async performFetch(params: {}, options: CollectionFetchOptions) {
    const response = await this.service.fetchSenders(options.offset, options.limit)

    if (!response.ok) {
      return response
    }

    const pack = response.body
    const data = pack.data as Sender[]
    const meta = pack.meta

    return {data, meta}
  }

  public async fetchMore() {
    if (this.fetchStatus !== 'done') { return }
    if (this.meta?.nextOffset == null) { return }

    await this.fetch({
      offset: this.meta.nextOffset,
      append: true,
    })
  }

}

export interface Meta {
  count:       number
  nextOffset:  number | null
  offset:      number
  total:       number
}