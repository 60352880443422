import { ChoiceFeedback, GroupFeedback, TextFeedback } from './Feedback'
import { Model, resource } from './Model'
import { Targeting } from './Targeting'
import { LocalizedString } from './Translations'

@resource<Profile>('profiles', {
  icon:    () => 'participant',
  caption: () => '(Profile)',
})
export class Profile extends Model {

  public targeting!: Targeting
  public items!:     ProfileItem[]

}

export interface ProfileItemCommon {
  // The name of the item. For regular items, this is also the variable to store the info in.
  name: string

  // A label for the variable.
  label: LocalizedString

  // A prompt for when this item is edited.
  prompt: LocalizedString

  // Who can see this item in your profile?
  visibility: ProfileItemVisibility

  // Can others view this property in your profile? Provided for backwards compatibility.
  public: boolean
}

export type ProfileItem           = TextProfileItem | ChoiceProfileItem | GroupProfileItem
export type RegularProfileItem    = TextProfileItem | ChoiceProfileItem
export type TextProfileItem       = ProfileItemCommon & TextFeedback
export type ChoiceProfileItem     = ProfileItemCommon & Omit<ChoiceFeedback, 'variable' | 'style'>
export type GroupProfileItem      = ProfileItemCommon & GroupFeedback

export type ProfileItemVisibility = 'private' | 'public' | 'connections'
export const ProfileItemVisibility: {
  all: ProfileItemVisibility[]
} = {
  all: ['public', 'connections', 'private'],
}