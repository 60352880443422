import I18n from 'i18next'
import { isPlainObject, omit } from 'lodash'
import { cleanTextValue } from 'ytil'
import { languagesStore } from '~/stores'

export type Translations<Key extends string = string> = Record<Language, Record<Key, string>>
export type Language = string
export type LocalizedString = Record<Language, string>

export const LocalizedString: {
  isLocalizedString: (value: string | LocalizedString) => value is LocalizedString

  from: {
    (string: string | LocalizedString, language?: Language): LocalizedString
    (string: null | undefined, language?: Language): null
    (string: string | LocalizedString | null | undefined, language?: Language): LocalizedString | null
  }

  translate: (string: string | LocalizedString | null | undefined, options?: TranslateOptions) => string

  clean: {
    (value: LocalizedString): LocalizedString
    (value: string | LocalizedString | null): string | LocalizedString | null
  }

  replace: (value: LocalizedString, language: string, text: string) => LocalizedString

  map: {
    (string: string, fn: (text: string) => string): string
    (string: LocalizedString, fn: (text: string) => string): LocalizedString
    (string: string | LocalizedString, fn: (text: string) => string): string | LocalizedString
  }
} = {
  isLocalizedString: (value): value is LocalizedString => {
    if (!isPlainObject(value)) { return false }
    return Object.values(value).every(it => typeof it === 'string')
  },

  from: (string, language = I18n.language): any => {
    if (string == null) { return null }
    if (LocalizedString.isLocalizedString(string)) {
      return string
    } else {
      return {[language]: string}
    }
  },

  translate: (string, options = {}) => {
    if (string == null) { return '' }
    if (typeof string === 'string') {
      return string
    }
    if (Object.keys(string).length === 0) {
      return ''
    }

    const languages = new Set<Language>()
    if (options.language != null) {
      languages.add(options.language)
    }
    languages.add(languagesStore.defaultLanguage)
    if (options.fallback !== false) {
      if (typeof I18n.options.fallbackLng === 'string') {
        languages.add(I18n.options.fallbackLng)
      }
      languages.add(Object.keys(string)[0])
    }

    for (const language of languages) {
      if (string[language] != null) {
        return string[language]
      }
    }

    return ''
  },

  clean: (value): any => {
    if (value != null && LocalizedString.isLocalizedString(value)) {
      const localizedClean: LocalizedString = {}
      for (const [key, text] of Object.entries(value)) {
        const clean = cleanTextValue(text)
        if (clean != null) {
          localizedClean[key] = clean
        }
      }

      return localizedClean
    } else {
      return cleanTextValue(value)
    }
  },

  replace: (value, language, text) => {
    if (text === '') {
      return omit(value, language)
    } else {
      return {...value, [language]: text}
    }
  },

  map: (value, fn): any => {
    if (typeof value === 'string') {
      return fn(value)
    } else {
      const mapped: LocalizedString = {}
      for (const [key, text] of Object.entries(value)) {
        mapped[key] = fn(text)
      }
      return mapped
    }
  },
}

export interface TranslateOptions {
  language?: string
  fallback?: boolean
}