import React from 'react'
import I18next from 'i18next'
import { some } from 'lodash'
import { Variable } from '~/models'
import { memo } from '~/ui/component'
import { Label, List, ListProps, VBox } from '~/ui/components'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import VariableBar from './VariableBar'

export interface Props extends Pick<ListProps<Variable>, 'scrollable' | 'flex'> {
  value:        Variable[]
  onChange?:    (data: Variable[]) => any
  requestSave?: (data: Variable[]) => Promise<SubmitResult | undefined>
}

const CustomDataList = memo('CustomDataList', (props: Props) => {

  const {value: data, requestSave, onChange, ...rest} = props
  const listData = React.useMemo(
    (): DataListItem[] => [...data, $NEW],
    [data],
  )

  const {t} = useResourceTranslation('custom-data')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='custom-data'>
        <List<DataListItem>
          data={listData}
          keyExtractor={(variable, index) => variable === $NEW ? '$NEW' + index : variable.name}
          renderItem={renderItem}
          itemGap={layout.padding.inline.s}
          contentClassNames={$.listContent}
          {...rest}
        />
      </ResourceTypeProvider>
    )
  }

  function renderItem(variable: Variable | typeof $NEW) {
    if (variable === $NEW) {
      return (
        <VBox classNames={$.addVariable} gap={layout.padding.inline.s}>
          <Label caption dimmer align='center'>
            {t('add_variable')}
          </Label>
          <VariableBar
            variable={null}
            onCommit={saveNewVariable}
          />
        </VBox>
      )
    } else {
      return (
        <VariableBar
          variable={variable}
          onCommit={updateVariable}
          requestRemove={removeVariable}
        />
      )
    }
  }

  //------
  // Save handlers

  const saveNewVariable = React.useCallback((name: string, value: any) => {
    if (some(data, it => it.name === name.toLocaleLowerCase())) {
      return Promise.resolve<SubmitResult>({
        status: 'invalid',
        errors: [{
          field: 'name',
          message: I18next.t('validation:unique'),
        }],
      })
    }

    const nextData = [
      ...data,
      {name, value, updatedAt: new Date()},
    ]

    if (requestSave != null) {
      return requestSave(nextData)
        .then(result => translateFormErrorPaths(result, path => path.replace(/^\d+\./, '')))
    } else {
      onChange?.(nextData)
      return Promise.resolve(void 0)
    }
  }, [data, onChange, requestSave])

  const updateVariable = React.useCallback((name: string, value: any) => {
    const nextData = data.map((variable): Variable => {
      if (variable.name !== name) {
        return variable
      } else {
        return {name, value, updatedAt: new Date()}
      }
    })

    if (requestSave != null) {
      return requestSave(nextData)
        .then(result => translateFormErrorPaths(result, path => path.replace(/^\d+\./, '')))
    } else {
      onChange?.(nextData)
      return Promise.resolve(void 0)
    }
  }, [data, onChange, requestSave])

  const removeVariable = React.useCallback((name: string) => {
    const nextData = data.filter(variable => variable.name !== name)

    if (requestSave != null) {
      return requestSave(nextData)
        .then(result => translateFormErrorPaths(result, path => path.replace(/^\d+\./, '')))
    } else {
      onChange?.(nextData)
      return Promise.resolve(void 0)
    }
  }, [data, onChange, requestSave])

  return render()

})

const $NEW = Symbol('$NEW')

type DataListItem = Variable | typeof $NEW

export default CustomDataList

const useStyles = createUseStyles({
  listContent: {
    padding: 2,
  },

  addVariable: {
    paddingTop: layout.padding.inline.l,
  },
})