import { DateTime } from 'luxon'
import { serialize } from 'ymodel'
import { Challenge } from './Challenge'
import { Model, resource } from './Model'
import { Participant } from './Participant'
import { Ref } from './types'

@resource<ChallengeState>('challenge-states', {
  icon:    () => 'challenge',
  caption: () => '',
})
export class ChallengeState extends Model {

  public challenge!:   Ref<Challenge>
  public participant!: Ref<Participant>

  @serialize(DateTime)
  public publishedAt!: DateTime

  @serialize(DateTime)
  public deadline!:    DateTime | null

  @serialize(DateTime)
  public startedAt!:   DateTime | null

  @serialize(DateTime)
  public abandonedAt!: DateTime | null

  @serialize(DateTime)
  public completedAt!: DateTime | null

  @serialize(DateTime)
  public reviewedAt!:  DateTime | null

  public totalScore!:  number | null
  public passed!:      boolean | null

  public get status(): ChallengeStateStatus {
    if (this.abandonedAt != null) { return 'abandoned' }

    if (this.startedAt == null) { return 'published' }
    if (this.completedAt == null) { return 'started' }
    if (this.reviewedAt == null) { return 'completed' }

    return 'reviewed'
  }

}

export type ChallengeStateStatus =
  | 'published'
  | 'abandoned'
  | 'started'
  | 'completed'
  | 'reviewed'