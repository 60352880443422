import React from 'react'
import { Location } from '~/models'
import { memo } from '~/ui/component'
import { Chip, DataGrid, HBox, Label, SVG } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { CreateFormComponentMap, ResourceListScreen } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import LocationForm, { Props as LocationFormProps } from '../form/LocationForm'

const LocationsScreen = memo('LocationsScreen', () => {

  const {colors} = useStyling()
  const {t} = useResourceTranslation('locations')

  const createFormMap = React.useMemo((): CreateFormComponentMap => ({
    latlong: {
      caption:   t('types.latlong'),
      Component: (props: Omit<LocationFormProps, 'type'>) => <LocationForm type='latlong' {...props}/>,
    },
    beacon: {
      caption:   t('types.beacon'),
      Component: (props: Omit<LocationFormProps, 'type'>) => <LocationForm type='beacon' {...props}/>,
    },
  }), [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Location}
        CreateFormComponent={createFormMap}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column
          name='participantCount'
          renderCell={renderParticipantCount}
          flex={2}
        />
      </ResourceListScreen>
    )
  }

  function renderName(location: Location) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name={location.$icon}
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <Label bold>
          {location.name}
        </Label>
      </HBox>
    )
  }

  function renderParticipantCount(location: Location) {
    return (
      <HBox>
        <Chip>
          {location.participantCount}
        </Chip>
      </HBox>
    )
  }

  return render()

})

export default LocationsScreen