import React from 'react'
import { calendarDaySummary } from '~/stores'
import { TimeOfDayField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { HBox, Label, SelectField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import { useCalendarPlanner } from '../CalendarPlannerContext'

const CalendarItemCommonFields = memo('CalendarItemCommonFields', () => {

  const {t, fieldCaption} = useResourceTranslation('calendar_planner')

  const {planner} = useCalendarPlanner()
  const days      = planner.plan?.days

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <FormFieldHeader
          caption={fieldCaption('time')}
        />
        <HBox align='top' gap={layout.padding.inline.m}>
          <Label style={{alignSelf: 'center'}}>
            {t('fields.time.prefix')}
          </Label>
          <VBox flex>
            <FormField name='day' caption={false}>
              {bind => <SelectField {...bind} choices={dayChoices}/>}
            </FormField>
          </VBox>
          <Label style={{alignSelf: 'center'}}>
            {t('fields.time.infix')}
          </Label>
          <VBox flex>
            <FormField name='time' caption={false}>
              {bind => <TimeOfDayField {...bind}/>}
            </FormField>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  const dayChoices = React.useMemo(() => (days ?? []).map(day => ({
    value:   day.uuid,
    caption: calendarDaySummary(day),
  })), [days])

  return render()

})

export default CalendarItemCommonFields