import React from 'react'
import { Extension } from '~/models'
import { memo } from '~/ui/component'
import { SVG } from '~/ui/components'
import { ImageView } from '../media'

export interface Props {
  extension: Extension
  size:      Size
}

const ExtensionLogo = memo('ExtensionLogo', (props: Props) => {

  const {extension, size} = props

  //------
  // Rendering

  function render() {
    if (extension.logo.type === 'svg') {
      return (
        <SVG
          svg={extension.logo.data}
          size={size}
        />
      )
    } else {
      return (
        <ImageView
          source={`data:image/png;base64,${extension.logo.base64}`}
          size={size}
        />
      )
    }
  }

  return render()

})

export default ExtensionLogo