import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import { arrayEquals } from 'ytil'
import { LatLongLocation, Location } from '~/models'
import { GoogleMap, GoogleMapManager, Marker } from '~/ui/app/google-maps'
import LocationMapPin from '~/ui/app/locations/LocationMapPin'
import { observer } from '~/ui/component'
import { Center, SidePanels, Spinner, Toolbar, VBox } from '~/ui/components'
import { usePrevious, useRefMap } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { colors, createUseStyles, layout } from '~/ui/styling'
import MapSidebar from './MapSidebar'

import type { MapParams } from './MapScreen'
export interface Props {}

const MapContainer = observer('MapContainer', (props: Props) => {

  const [t] = useTranslation('map')

  const locationsEndpoint = useModelEndpoint<LatLongLocation>(Location as any, {
    filters: {
      type: 'latlong',
    },
  })

  const mapManagerRef = React.useRef<GoogleMapManager>(null)
  const markerRefs    = useRefMap<string, Marker>()

  const locations       = locationsEndpoint.data
  const locationsStatus = locationsEndpoint.fetchStatus

  const locationIDs = React.useMemo(
    () => locations.map(it => it.id),
    [locations],
  )
  const prevLocationIDs = usePrevious(locationIDs)

  //------
  // Updates

  const deriveParticipantCounts = React.useCallback(
    (locations: Location[]) => new Map<string, number>(locations.map(loc => [loc.id, loc.participantCount])),
    [],
  )

  const prevLocationCountsRef = React.useRef(deriveParticipantCounts(locations))

  React.useEffect(() => {
    const prevLocationCounts = prevLocationCountsRef.current
    const nextLocationCounts = deriveParticipantCounts(locations)

    for (const id of nextLocationCounts.keys()) {
      const prevCount = prevLocationCounts.get(id)
      const nextCount = nextLocationCounts.get(id)
      if (prevCount === nextCount) { continue }

      const marker = markerRefs.get(id)
      marker?.bounce()
    }
    prevLocationCountsRef.current = nextLocationCounts
  }, [deriveParticipantCounts, locations, markerRefs])

  //------
  // Map actions

  const [canFit, setCanFit] = React.useState<boolean>(false)
  const justFitRef          = React.useRef<boolean>(false)

  React.useEffect(() => {
    if (prevLocationIDs == null) { return }
    if (!arrayEquals(prevLocationIDs, locationIDs)) {
      setCanFit(true)
    }
  }, [locationIDs, prevLocationIDs])

  const boundsChanged = React.useCallback(() => {
    if (justFitRef.current) {
      justFitRef.current = false
    } else {
      setCanFit(true)
    }
  }, [])

  const fit = React.useCallback(() => {
    mapManagerRef.current?.fitFeatures({
      animated: true,
    })
    setCanFit(false)
    justFitRef.current = true
  }, [])

  //------
  // Focused location

  const {params} = useRouteMatch<MapParams>()
  const history  = useHistory()
  const focusedLocationID = params.locationID
  const focusedLocation   = focusedLocationID == null ? null : locations.find(it => it.id === focusedLocationID) ?? null

  const focusOnLocation = React.useCallback((location: Location) => {
    history.replace(`/map/-/${location.id}`)
  }, [history])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <SidePanels
        namespace='map'
        right='side'
        minPanelWidth={sidebarWidth.min}
        initialPanelWidth={sidebarWidth.initial}
        rightShadow
        renderPanel={renderSidebar}
        children={renderMain()}
        flex
      />
    )
  }

  function renderMain() {
    return (
      <VBox flex>
        <VBox flex classNames={$.layerContainer}>
          <GoogleMap
            classNames={$.map}
            children={renderMapPins()}
            onBoundsChange={boundsChanged}
            managerRef={mapManagerRef}
            flex
          />
          {locationsStatus === 'fetching' && (
            <Center flex classNames={$.loading}>
              <Spinner/>
            </Center>
          )}
        </VBox>
        <VBox padding={layout.padding.s}>
          {renderMapToolbar()}
        </VBox>
      </VBox>
    )
  }

  function renderMapPins() {
    return locations.map(loc => (
      <LocationMapPin
        key={loc.id}
        location={loc}
        count={loc.participantCount}
        tint={loc.id === focusedLocationID ? 'primary' : 'secondary'}
        markerRef={markerRefs.for(loc.id)}
        onTap={focusOnLocation}
      />
    ))
  }

  function renderMapToolbar() {
    return (
      <Toolbar style='panel'>
        <Toolbar.Button
          icon='zoom-fit'
          caption={t('toolbar.fit')}
          onTap={fit}
          enabled={canFit}
        />
      </Toolbar>
    )
  }

  function renderSidebar() {
    return (
      <MapSidebar
        focusedLocation={focusedLocation}
      />
    )

  }

  return render()

})

export default MapContainer

const sidebarWidth = {
  min:     320,
  initial: 360,
}

const useStyles = createUseStyles({
  MapContainer: {
  },

  layerContainer: {
    position: 'relative',
  },

  loading: {
    ...layout.overlay,
    background: colors.shim.white,
  },

  map: {
    overflow: 'hidden',
  },
})