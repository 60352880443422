import { makeObservable, observable } from 'mobx'
import { DaySelector } from '~/models'

export default class DaySelectorFormModel {

  constructor(
    private readonly selector: DaySelector,
  ) {
    makeObservable(this)
  }

  @observable
  public type: DaySelector['type'] = this.selector.type ?? 'offset'

  @observable
  public offset: number = this.selector.type === 'offset' ? this.selector.offset : 0

  @observable
  public week: number = this.selector.type === 'weekday' ? this.selector.week : 0

  @observable
  public weekday: number = this.selector.type === 'weekday' ? this.selector.weekday : 1

  public buildSelector(): DaySelector {
    switch (this.type) {
      case 'offset':
        return {type: 'offset', offset: this.offset}
      case 'weekday':
        return {type: 'weekday', week: this.week, weekday: this.weekday}
    }
  }

}
