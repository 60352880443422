import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { Channel, Participant } from '~/models'
import { chatStore } from '~/stores'
import { Chat } from '~/ui/app/chat'
import { observer } from '~/ui/component'
import { Dimple, Empty, HBox, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import ChannelHeader from './ChannelHeader'

export interface Props {
  channel: Channel
}

const ChannelBody = observer('ChannelBody', (props: Props) => {

  const {channel} = props
  const [participant, setParticipant] = React.useState<Participant | null>(null)
  const {t} = useResourceTranslation()

  const chat = React.useMemo(() => {
    if (participant == null) { return null }

    return chatStore.chatService(participant.id, participant, {
      initialChannelID: channel.id,
    })
  }, [channel.id, participant])

  useOnDemandService(chat)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex>
        {renderHeader()}
        <Dimple horizontal/>
        {participant == null ? (
          renderPrompt()
        ) : (
          renderBody()
        )}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <ChannelHeader
        channel={channel}
        participantID={participant?.id ?? null}
        requestParticipant={setParticipant}
      />
    )
  }

  function renderPrompt() {
    return (
      <Empty
        flex
        {...t('prompt')}
      />
    )
  }

  function renderBody() {
    return (
      <HBox classNames={$.body} flex align='stretch' justify='center'>
        <VBox classNames={$.channelPreview} flex={{basis: 480, shrink: true}}>
          {renderChat()}
        </VBox>
      </HBox>
    )
  }

  function renderChat() {
    if (chat == null) { return null }

    return (
      <Chat
        controller={chat}
        header={false}
        noChannel={t('not_in_channel')}
        emptyChannel={t('empty_channel')}
      />
    )
  }

  return render()

})

export default ChannelBody

const useStyles = createUseStyles(theme => ({
  body: {
    ...layout.responsiveProp({padding: layout.padding.m}),
    paddingBottom: 0,
  },

  channelPreview: {
    background:   theme.colors.bg.semi,
    boxShadow:    shadows.depth(2),
    borderRadius: layout.radius.m,
  },
}))