import { layout } from '~/ui/styling'

export const cellSize = 40
export const weekHeaderHeight = 20

// Reserve enough room so that the calendar doesn't jump when navigating through months.
export const padding = layout.padding.inline.m
export const width   = cellSize * 7 + 2 * padding
export const height  = weekHeaderHeight + cellSize * 6 + 2 * padding

export const yearGridCount = 5
export const yearCellSize = Math.min((width - 2 * padding) / yearGridCount, (height - 2 * padding) / yearGridCount)

export const selectionCircleSize = {
  date: cellSize * 0.8,
  year: yearCellSize * 0.8,
}

export const dateCellAnnotationSize = 8