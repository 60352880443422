import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { zones } from 'tzdata'
import { stringContains } from 'ytil'
import { memo } from '~/ui/component'
import { AutoCompleteField } from '~/ui/components'
import { AutoCompleteSection } from './autocomplete/types'

export interface Props {
  value:    string | null
  onChange: (value: string | null) => any
}

const validTimezones = Object.entries(zones)
  .filter(([zoneName, v]) => Array.isArray(v))
  .map(([zoneName, v]) => zoneName)
  .filter(tz => DateTime.local().setZone(tz).isValid)

const TimeZoneField = memo('TimeZoneField', (props: Props) => {

  const {
    value,
    onChange,
  } = props

  const [t] = useTranslation('time_zone_field')

  //------
  // Callbacks

  const search = React.useCallback((search: string | null): AutoCompleteSection<string>[] => {
    return [{
      caption: t('local_zone'),
      choices: [DateTime.local().zoneName],
    }, {
      caption: t('all_zones'),
      choices: validTimezones
                 .filter(tz => search == null ? true : stringContains(tz, search))
                 .slice(0, 20),
    }]
  }, [t])

  //------
  // Rendering

  function render() {
    return (
      <AutoCompleteField
        value={value}
        onChange={onChange}

        onSearch={search}
      />
    )
  }

  return render()

})

export default TimeZoneField