import { isArray, omit } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { ImportResult, ImportSession } from 'sheet-importer'
import socket, { SocketOperation } from 'socket.io-react'
import { Model, ModelClass, ModelOfType } from '~/models'
import { BulkSelector } from '~/stores'
import {
  ListPack,
  ListParams,
  ModelDatabase,
  ModelDocument,
  ModelEndpoint,
  ModelEndpointOptions,
  Pack,
  ResourceOf,
} from './data'
import { ActionOptions, CustomListPack, ImportOptions } from './data/types'
import { isResource, modelToResource, resourceToModel } from './data/util'
import { register, SubmitResult, submitResultForResponse } from './support'

export class DataStore {

  constructor() {
    makeObservable(this)
  }

  @observable
  private databases: WeakMap<ModelClass<any>, ModelDatabase<any>> = new WeakMap()

  /**
   * Retrieves a model base for a given resource Model. If it didn't exist, it's created.
   *
   * @param Model The model class.
   */
  public db<M extends Model>(Model: Constructor<M>): ModelDatabase<M> {
    // Note: the argument is Constructor<M> and not ModelClass<M> because TypeScript can only infer M if a direct constructor
    // argument is used. ModelClass<M> defines additional restrictions, breaking this inference.
    return this.ensureDatabase(Model as ModelClass<M>)
  }

  /**
   * Creates a reuseable model endpoint for the given resource Model, connected to the databases here.
   *
   * @param Model The model class.
   * @param options Initialization options for the endpoint.
   */
  public endpoint<M extends Model>(Model: Constructor<M>, params?: ListParams, options?: ModelEndpointOptions<M>) {
    const database = this.ensureDatabase(Model as ModelClass<M>)

    return new ModelEndpoint<M>(database, Model as ModelClass<M>, params, options)
  }

  /**
   * Creates a reuseable model endpoint for the given resource Model, which is detached from the databases here.
   *
   * @param Model The model class.
   * @param options Initialization options for the endpoint.
   */
  public detachedEndpoint<M extends Model>(Model: Constructor<M>, params?: ListParams, options?: ModelEndpointOptions<M>) {
    const database = this.temporaryDatabase(Model as ModelClass<M>)
    return new ModelEndpoint<M>(database, Model as ModelClass<M>, params, options)
  }

  /**
   * Finds or creates a document in the database for the given resource Model.
   *
   * @param Model The model class.
   * @param id The ID to create a document for.
   * @param create Whether to create the document if it doesn't exist.
   */
  public document<M extends Model>(Model: Constructor<M>, id: string, create?: true | undefined): ModelDocument<M>
  public document<M extends Model>(Model: Constructor<M>, id: string, create: false): ModelDocument<M> | null
  public document<M extends Model>(Model: Constructor<M>, id: string, create?: boolean): ModelDocument<M> | null
  public document<M extends Model>(Model: Constructor<M>, id: string, create: boolean = true) {
    return this.db(Model).document(id, create)
  }

  /**
   * Immediately retrieves a single document.
   *
   * @param Model The model class.
   * @param id The model ID.
   */
  public get<M extends Model>(Model: Constructor<M>, id: string): M | null
  public get<M extends Model>(Model: Constructor<M> | ModelClass<M>, id: string): M | null {
    return this.db(Model).get(id)
  }

  /**
   * Immediately retrieves a list of documents.
   *
   * @param Model The model class.
   * @param ids The model IDs.
   */
  public list<M extends Model>(Model: Constructor<M>, ids: string[]): M[]
  public list<M extends Model>(Model: Constructor<M> | ModelClass<M>, ids: string[]): M[] {
    const db = this.db(Model)
    return ids.map(id => db.get(id)).filter(Boolean) as M[]
  }

  //------
  // Updates

  @action
  public async create<M extends Model>(Model: Constructor<M> | ModelClass<M>, data: AnyObject, meta?: AnyObject, options: ActionOptions = {}): Promise<SubmitResult> {
    const resource = 'id' in data
      ? modelToResource((Model as ModelClass<M>).resourceType, data.id, omit(data, 'id'))
      : modelToResource((Model as ModelClass<M>).resourceType, null, data)

    const response = await socket.send('data:create', {data: resource, meta}, options)
    if (response.ok) {
      const id    = this.storePack(response.body)?.id
      const model = id == null ? null : this.get(Model, id)
      if (model != null) {
        this.emitAfterCreate(model)
      }
    }

    return submitResultForResponse(response)
  }

  @action
  public async update<M extends Model>(Model: Constructor<M> | ModelClass<M>, id: string, data: AnyObject, options: ActionOptions = {}): Promise<SubmitResult | undefined> {
    const document = this.document(Model, id, false)
    if (document == null) { return }

    return document.update(data, options)
  }

  @action
  public async updateOrCreate<M extends Model>(Model: Constructor<M> | ModelClass<M>, id: string, data: AnyObject, defaults: AnyObject, options: ActionOptions = {}): Promise<SubmitResult | undefined> {
    const document = this.document(Model, id, false)
    if (document == null) {
      return await this.create(Model, {id, ...defaults, ...data}, undefined, options)
    } else {
      return await document.update(data, options)
    }
  }

  public async delete<M extends Model>(Model: Constructor<M> | ModelClass<M>, id: string) {
    const document = this.document(Model, id, false)
    if (document == null) { return null }

    return document.delete()
  }

  //------
  // Storing

  /**
   * Stores a single document.
   *
   * @param model The model data.
   */
  @action
  public store<M extends Model>(model: M) {
    const document = this.document(model.constructor as Constructor<M>, model.id)
    document.set(model)
    return document.id
  }

  /**
   * Stores a single document.
   *
   * @param model The model data.
   */
  @action
  public storeRaw<M extends Model>(Model: Constructor<M> | ModelClass<M>, serialized: AnyObject) {
    const document = this.document(Model, serialized.id)
    if (document.data != null) {
      const existing = document.data.serialized
      const model    = (Model as ModelClass<M>).deserialize({...existing, ...serialized})
      document.set(model)
    } else {
      const model = (Model as ModelClass<M>).deserialize(serialized)
      document.set(model)
    }

    return document.id
  }

  /**
   * Stores a single model.
   * @param model The model to store.
   */
  public storeModel(model: Model) {
    const document = this.document(model.$ModelClass, model.id)
    if (document.data != null) {
      const existing = document.data.serialized
      document.set(model.$ModelClass.deserialize({...existing, ...model.serialized}))
    } else {
      document.set(model)
    }

    return document
  }

  /**
   * Stores a single resource.
   *
   * @param resource The resource to store.
   */
  @action
  public storeResource<M extends Model>(resource: ResourceOf<M>) {
    const ModelClass = ModelOfType(resource.type)
    const raw        = resourceToModel(resource)
    const id         = this.storeRaw(ModelClass, raw)

    const document = this.document(ModelClass, id)

    if (resource.relationships != null) {
      document.storeRelationships(resource.relationships)
    }
    if (resource.meta != null) {
      document.setMeta(resource.meta)
    }

    return document
  }

  public storePack<M extends Model>(pack: Pack<M>): ModelDocument<M> | null
  public storePack<M extends Model>(pack: ListPack<M, any>): ModelDocument<M>[]
  public storePack<T>(pack: CustomListPack<T>): Document[]
  public storePack(pack: Pack<any> | ListPack<any> | CustomListPack<any>): ModelDocument<any> | ModelDocument<any>[] | null
  @action
  public storePack(pack: Pack<any> | ListPack<any> | CustomListPack<any>): ModelDocument<any> | ModelDocument<any>[] | Document[] | null {
    this.storeIncluded(pack)

    if (isArray(pack.data)) {
      return pack.data
        .filter(isResource)
        .map(resource => this.storeResource(resource))
    } else if (isResource(pack.data)) {
      return this.storeResource(pack.data)
    } else {
      return null
    }
  }

  @action
  public storeIncluded(pack: Pack<any> | ListPack<any> | CustomListPack<any>) {
    for (const resource of pack.included ?? []) {
      if (isResource(resource)) {
        this.storeResource(resource)
      }
    }
  }

  //------
  // Actions

  @action
  public async import(Model: ModelClass<any>, session: ImportSession, options: ImportOptions = {}): Promise<ImportResult> {
    const {
      timeout = false,
      onStart,
      onEnd,
    } = options

    const operation = new SocketOperation(socket, 'data:import')
    onStart?.(operation)

    const response = await socket.sendWithOptions(
      {timeout, operation},
      'data:import',
      Model.resourceType,
      {
        data: session.importData,
        meta: session.importMeta,
      },
    )

    onEnd?.(operation)

    if (response.ok) {
      return {
        status: 'completed',
        ...response.body.meta,
      }
    } else {
      return {
        status: 'error',
        error:  response.error,
      }
    }
  }

  //------
  // Misc: tags

  @action
  public async getTags(Model: ModelClass<any>) {
    const response = await socket.send('data:tags', Model.resourceType)
    if (!response.ok) { return [] }

    const pack = response.body
    return pack.data as string[]
  }

  //------
  // Listeners

  private createListeners = new Map<ModelClass<any>, Set<(model: Model) => any>>()
  private removeListeners = new Map<ModelClass<any>, Set<(selector: BulkSelector) => any>>()

  public onAfterCreate<M extends Model>(Model: ModelClass<M>, handler: (model: M) => any) {
    let listeners = this.createListeners.get(Model)
    if (listeners == null) {
      this.createListeners.set(Model, listeners = new Set())
    }
    listeners.add(handler as any)

    return () => {
      if (listeners == null) { return }
      listeners.delete(handler as any)
      if (listeners.size === 0) {
        this.createListeners.delete(Model)
      }
    }
  }

  public onAfterRemove<M extends Model>(Model: ModelClass<M>, handler: (selector: BulkSelector) => any) {
    let listeners = this.removeListeners.get(Model)
    if (listeners == null) {
      this.removeListeners.set(Model, listeners = new Set())
    }
    listeners.add(handler as any)

    return () => {
      if (listeners == null) { return }
      listeners.delete(handler as any)
      if (listeners.size === 0) {
        this.removeListeners.delete(Model)
      }
    }
  }

  public emitAfterCreate(model: Model) {
    const listeners = this.createListeners.get(model.$ModelClass) ?? []
    for (const listener of listeners) {
      listener(model)
    }
  }

  public emitAfterRemove(Model: ModelClass<any>, selector: BulkSelector) {
    const listeners = this.removeListeners.get(Model) ?? []
    for (const listener of listeners) {
      listener(selector)
    }
  }

  //------
  // Support

  private ensureDatabase<M extends Model>(Model: ModelClass<M>) {
    const existing = this.databases.get(Model)
    if (existing != null) {
      return existing
    }

    const database = new ModelDatabase<M>(Model)
    this.databases.set(Model, database)
    return database
  }

  private temporaryDatabase<M extends Model>(Model: ModelClass<M>) {
    return new ModelDatabase<M>(Model)
  }

  public isAttachedEndpoint(endpoint: ModelEndpoint<any>) {
    const database = this.databases.get(endpoint.Model)
    return database === endpoint.database
  }

  @action
  public onLogOut() {
    this.databases = new WeakMap()
  }

}

export default register(new DataStore())