import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImportColumn } from 'sheet-importer'
import { observer } from '~/ui/component'
import { Label, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { createUseStyles, layout } from '~/ui/styling'
import { getImportFieldFormComponent } from './fields'
import { Props as ImportPreviewProps } from './ImportPreview'

export interface Props extends ImportPreviewProps {
  column: ImportColumn
}

const ImportFieldSelector = observer('ImportFieldSelector', (props: Props) => {

  const {session, column, fieldChoice, fieldCaption} = props

  const [t] = useTranslation('importer')

  const descriptor = session.fieldDescriptorForColumn(column)
  const field      = session.fieldForColumn(column)

  const mapColumn = React.useCallback((fieldKey: string | null) => {
    if (fieldKey == null) {
      session.mapColumn(column, null)
    } else {
      const field = session.model.fields.find(it => it.key === fieldKey)
      if (field == null) { return }

      session.mapColumn(column, field)
    }
  }, [column, session])

  //------
  // Choices

  const choices = React.useMemo(() => {
    const choices: Choice[] = []

    choices.push({
      value:   null,
      caption: t('preview.map_to.nothing'),
      dim:     true,
    }, {
      section: '-',
    })

    for (const group of session.model.fieldGroups) {
      if (group.fields.length === 0) { continue }

      if (group.fields.length === 1) {
        choices.push({
          caption: fieldCaption(group.fields[0].key),
          value:   group.fields[0].key,
          ...fieldChoice?.(group.fields[0].key),
        })
      } else {
        choices.push({
          ...fieldChoice?.(group.key) as any,
          caption:  fieldCaption(group.fields[0].key),
          children: group.fields.map(field => ({
            value:   field.key,
            caption: fieldCaption(field.key),
            ...fieldChoice?.(field.key),
          })),
        })
      }
    }

    return choices
  }, [fieldCaption, fieldChoice, session.model.fieldGroups, t])

  //------
  // Custom fields

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.fieldSelector} gap={layout.padding.inline.s}>
        <Label caption small dim>
          {t('preview.map_to.caption')}
        </Label>
        <SelectField
          value={descriptor?.key ?? null}
          onChange={mapColumn}
          choices={choices}
        />
        {renderForm()}
      </VBox>
    )
  }

  function renderForm() {
    if (field == null) { return null }

    const Form = getImportFieldFormComponent(field.constructor)
    if (Form == null) { return null }

    return <Form field={field}/>
  }

  return render()

})

export default ImportFieldSelector

const useStyles = createUseStyles({
  fieldSelector: {
    paddingTop:   layout.padding.inline.s,
    paddingRight: layout.padding.inline.m,
  },
})