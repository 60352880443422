import { action, makeObservable, observable } from 'mobx'
import ImportField, { ImportFieldOptions } from './ImportField'

export default class DataField extends ImportField {

  constructor(
    name:     string,
    variable: string,
    options: CustomFieldOptions = {},
  ) {
    super(name, options)
    this.variable = variable

    makeObservable(this)
  }

  @observable
  public variable: string

  @action
  public setVariable(variable: string) {
    this.variable = variable
  }

  public serialize() {
    return {
      ...super.serializeWithType('data'),
      variable: this.variable,
    }
  }

  public save() {
    return {
      ...super.save(),
      variable: this.variable,
    }
  }

  public load(raw: any) {
    if (raw.variable != null) {
      this.variable = raw.variable
    }
    super.load(raw)
  }

}

export type CustomFieldOptions = ImportFieldOptions