import React from 'react'
import { startCase } from 'lodash'
import { GroupSignup } from '~/models'
import { useClock } from '~/socket/useClock'
import { memo, observer } from '~/ui/component'
import {
  Chip,
  ClearButton,
  Empty,
  HBox,
  Label,
  List,
  ListBar,
  ModalDialog,
  PushButton,
  SVG,
  TextBlock,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import GroupSignupForm from './GroupSignupForm'
import GroupSignupWidgetDialog from './GroupSignupWidgetDialog'

export interface Props {
  open:         boolean
  requestClose: () => any
}

const GroupSignupsDialog = observer('GroupSignupsDialog', (props: Props) => {

  const {open, requestClose} = props

  const {t, plural} = useResourceTranslation('group-signups')

  const endpoint = useModelEndpoint(GroupSignup)

  //------
  // Actions

  const [focusedSignup, setFocusedSignup] = React.useState<GroupSignup | null>(null)
  const [signupFormOpen, openSignupForm, closeSignupForm] = useBoolean()
  const [widgetDialogOpen, openWidgetDialog, closeWidgetDialog] = useBoolean()

  const editSignup = React.useCallback((signup: GroupSignup) => {
    setFocusedSignup(signup)
    openSignupForm()
    closeWidgetDialog()
  }, [closeWidgetDialog, openSignupForm])

  const generateWidget = React.useCallback((signup: GroupSignup) => {
    setFocusedSignup(signup)
    closeSignupForm()
    openWidgetDialog()
  }, [closeSignupForm, openWidgetDialog])

  const addSignup = React.useCallback(() => {
    setFocusedSignup(null)
    openSignupForm()
    closeWidgetDialog()
  }, [closeWidgetDialog, openSignupForm])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}

        icon='signup'
        title={startCase(plural())}

        width={820}
        height={640}

        headerRight='$close'
        closeOnClickOutside={true}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <ResourceTypeProvider resourceType='group-signups'>
        <VBox flex padding={layout.padding.m}>
          {renderSignupsList()}

          <GroupSignupForm
            open={signupFormOpen}
            requestClose={closeSignupForm}
            signupID={focusedSignup?.id ?? null}
          />

          {focusedSignup != null && (
            <GroupSignupWidgetDialog
              open={widgetDialogOpen}
              requestClose={closeWidgetDialog}
              signup={focusedSignup}
            />
          )}
        </VBox>
      </ResourceTypeProvider>
    )
  }

  function renderSignupsList() {
    return (
      <VBox flex gap={layout.padding.m}>
        {endpoint.data.length > 0 && (
          <TextBlock small dim>
            {t('preamble')}
          </TextBlock>
        )}
        <List
          data={endpoint.data}
          keyExtractor={keyExtractor}
          EmptyComponent={renderEmpty}
          renderItem={renderSignupBar}
          itemGap={layout.padding.inline.s}
        />
        {endpoint.data.length > 0 && (
          <HBox>
            {renderAddSignupButton()}
          </HBox>
        )}
      </VBox>
    )
  }

  const renderAddSignupButton = React.useCallback(() => {
    return (
      <PushButton
        icon='plus'
        caption={t('new')}
        onTap={addSignup}
      />
    )
  }, [addSignup, t])

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty
        flex
        {...t('empty')}
        children={renderAddSignupButton()}
      />
    )
  }, [renderAddSignupButton, t])

  const keyExtractor = React.useCallback(
    (signup: GroupSignup) => signup.id,
    [],
  )

  const renderSignupBar = React.useCallback((signup: GroupSignup) => {
    return (
      <GroupSignupBar
        signup={signup}
        requestEdit={editSignup}
        requestGenerateWidget={generateWidget}
      />
    )
  }, [editSignup, generateWidget])

  return render()

})

export default GroupSignupsDialog

interface GroupSignupBarProps {
  signup:        GroupSignup
  requestEdit:   (signup: GroupSignup) => any
  requestGenerateWidget: (signup: GroupSignup) => any
}

const GroupSignupBar = memo('GroupSignupBar', (props: GroupSignupBarProps) => {

  const {t} = useResourceTranslation('group-signups')
  const {colors} = useStyling()

  const {signup, requestEdit, requestGenerateWidget} = props

  const {currentTime} = useClock()
  const pastDeadline  = currentTime == null ? false : signup?.isPastDeadline(currentTime)

  const closedBackgroundColor = signup.closed || pastDeadline
    ? colors.semantic.negative
    : colors.semantic.positive

  const {actionCaption} = useResourceTranslation('group-signups')

  const edit = React.useCallback(() => {
    requestEdit(signup)
  }, [signup, requestEdit])

  const generateWidget = React.useCallback(() => {
    requestGenerateWidget(signup)
  }, [signup, requestGenerateWidget])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListBar
        image='signup'
        caption={renderBody()}
        onTap={edit}
        accessory={renderActions()}
      />
    )
  }

  function renderBody() {
    return (
      <HBox flex gap={layout.padding.s}>
        <Label flex={3}>
          {signup.name}
        </Label>
        <HBox flex={2}>
          {signup.tag && <Chip icon='tag' small>{signup.tag}</Chip>}
        </HBox>
        <HBox flex={1.5}>
          <ThemeProvider contrast={closedBackgroundColor}>
            <HBox flex='shrink' classNames={$.closedChip} gap={layout.padding.inline.s} style={{background: closedBackgroundColor.string()}}>
              <SVG
                name={signup.closed ? 'cross-circle' : pastDeadline ? 'calendar' : 'check-circle'}
                size={layout.icon.s}
              />
              <Label flex='shrink' caption small>
                {t(`${signup.closed ? 'closed' : pastDeadline ? 'closed_deadline' : 'open'}.caption`)}
              </Label>
            </HBox>
          </ThemeProvider>
        </HBox>
      </HBox>
    )
  }

  function renderActions() {
    return (
      <HBox>
        <ClearButton
          icon='code'
          caption={actionCaption('generate_widget')}
          onTap={generateWidget}
          small
        />
      </HBox>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  closedChip: {
    padding:      [layout.padding.inline.xs, layout.padding.inline.s],
    borderRadius: layout.radius.s,
  },
})