import { isFunction } from 'lodash'
import { allStores } from './registry'

export async function initStores() {
  for (const store of allStores()) {
    if ('init' in store && isFunction(store.init)) {
      const result = await (store as any).init()
      if (result === false) {
        return false
      }
    }
  }

  return true
}

export async function callOnLogout() {
  for (const store of allStores()) {
    if ('onLogOut' in store && isFunction(store.onLogOut)) {
      const result = await (store as any).onLogOut()
      if (result === false) {
        return false
      }
    }
  }

  return true
}