import React from 'react'
import { BrandedComponentSpec } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const WebHeaderBrandingPreview = memo('WebHeaderBrandingPreview', (props: Props) => {

  const {componentName, variant, spec} = props
  const {guide}       = useBranding()

  const [component, flags, size] = useComponentBranding(componentName, variant, spec)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (component == null) { return null }

    return (
      <BrandedComponent
        classNames={$.brandedHeaderPreview}
        guide={guide ?? undefined}
        variant={flags}
        branding={component}
        height={size.height}
        style={{...size}}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <HBox flex gap={layout.padding.inline.l}>
        {renderLogo()}
      </HBox>
    )
  }

  function renderLogo() {
    return (
      <ImageView
        source={guide.image('logo', 'web') ?? {icon: 'logo'}}
        size={logoSize}
        objectFit='contain'
      />
    )
  }

  return render()

})

registerComponentBrandingPreview('web.header', WebHeaderBrandingPreview)
export default WebHeaderBrandingPreview

export const logoSize = {
  width:  48,
  height: 48,
}

const useStyles = createUseStyles({
  brandedHeaderPreview: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],

    borderTopWidth:   0,
    borderLeftWidth:  0,
    borderRightWidth: 0,
  },
})