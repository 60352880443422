import React from 'react'
import { getClientPoint } from 'react-dnd'
import { useContinuousRef } from '~/ui/hooks'
import { assignRef } from './refs'

export function useSimpleDrag(config: SimpleDragConfig): SimpleDragHook {
  const {axis = 'both', handle} = config

  const horizontal = axis === 'both' || axis === 'horizontal'
  const vertical   = axis === 'both' || axis === 'vertical'

  const targetRef     = React.useRef<HTMLElement>(null)
  const handleRef     = React.useRef<HTMLElement>(null)
  const startPointRef = React.useRef<Point | null>(null)

  const onStart = useContinuousRef(config.onStart)
  const onMove  = useContinuousRef(config.onMove)
  const onEnd   = useContinuousRef(config.onEnd)

  const getDelta = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (startPointRef.current == null) {
      return {x: 0, y: 0}
    }

    const point = getClientPoint(event)
    if (point == null) { return null }

    return {
      x: horizontal ? point.x - startPointRef.current.x : 0,
      y: vertical ? point.y - startPointRef.current.y : 0,
    }
  }, [horizontal, vertical])

  const move = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (startPointRef.current == null) { return }

    const point = getClientPoint(event)
    const delta = getDelta(event)
    if (point == null || delta == null) { return }

    const retval = onMove.current?.(point, delta, event)
    if (retval !== false) {
      event.preventDefault()
    }
  }, [getDelta, onMove])

  const end = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (startPointRef.current == null) { return }

    const point = getClientPoint(event)
    const delta = getDelta(event)
    if (point == null || delta == null) { return }

    const retval = onEnd.current?.(point, delta, event)
    if (retval !== false) {
      event.preventDefault()
    }

    startPointRef.current = null
    window.removeEventListener('mousemove', move)
    window.removeEventListener('touchmove', move)
    window.removeEventListener('mouseup', end)
    window.removeEventListener('touchend', end)
  }, [getDelta, move, onEnd])

  const start = React.useCallback((event: MouseEvent | TouchEvent) => {
    const point = getClientPoint(event)
    if (point == null) { return }

    startPointRef.current = point

    const retval = onStart.current?.(point, event)
    if (retval !== false) {
      event.preventDefault()
    }

    window.addEventListener('mousemove', move)
    window.addEventListener('touchmove', move)
    window.addEventListener('mouseup', end)
    window.addEventListener('touchend', end)
  }, [end, move, onStart])

  const bind = React.useCallback((element: HTMLElement) => {
    element.addEventListener('mousedown', start)
    element.addEventListener('touchstart', start)
  }, [start])

  const unbind = React.useCallback((element: HTMLElement) => {
    element.addEventListener('mousedown', start)
    element.addEventListener('touchstart', start)
  }, [start])

  const findHandle = React.useCallback((element: HTMLElement): HTMLElement | null => {
    return handle != null ? element.querySelector(handle) : element
  }, [handle])

  const connect = React.useCallback((upstream?: React.Ref<HTMLElement>) => {
    return (element: HTMLElement | null) => {
      if (upstream != null) {
        assignRef(upstream, element)
      }
      assignRef(targetRef, element)

      if (handleRef.current != null) {
        unbind(handleRef.current)
      }

      const handle = element == null ? null : findHandle(element)
      if (handle != null) {
        bind(handle)
        assignRef(handleRef, handle)
      }
    }
  }, [bind, findHandle, unbind])

  return [connect]

}

export interface SimpleDragConfig {
  axis?:   'horizontal' | 'vertical' | 'both'
  handle?: string

  onStart?: (point: Point, event: MouseEvent | TouchEvent) => any
  onMove?:  (point: Point, delta: Point, event: MouseEvent | TouchEvent) => any
  onEnd?:   (point: Point, delta: Point, event: MouseEvent | TouchEvent) => any
}

export type SimpleDragHook = [
  /*connect:*/ (upstream?: React.Ref<HTMLElement>) => any,
]