import Color from 'color'

export function overrideBackground(color: Color) {
  return {
    backgroundColor: `${color} !important`,
  }
}

export function overrideForeground(color: Color) {
  return {
    '& :not(.--no-override-fg)': {
      color: `${color} !important`,
    },
  }
}

export function linearGradient(from: string, ...colors: Color[]) {
  return `linear-gradient(${from}, ${colors.map(c => c.string()).join(', ')})`
}

export function verticalGradient(...colors: Color[]) {
  return linearGradient('top', ...colors)
}

export function horizontalGradient(...colors: Color[]) {
  return linearGradient('left', ...colors)
}

export function radialGradient(location: string, ...colors: Color[]) {
  return `radial-gradient(${location}, ${colors.map(c => c.string()).join(', ')})`
}

//------
// Context-free colors

export const white = new Color('white')
export const transparent = new Color('transparent')

export const dimple = {
  dark:  new Color('black').alpha(0.05),
  light: new Color('white').alpha(0.4),
}

export const shim = {
  light:  new Color('black').alpha(0.3),
  medium: new Color('black').alpha(0.5),
  dark:   new Color('black').alpha(0.8),
  white:  new Color('white').alpha(0.6),
}