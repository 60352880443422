import I18next from 'i18next'
import { get, isPlainObject } from 'lodash'

export class AvailableFeature {

  constructor(
    public readonly name: string,
    public readonly dependsOn: string[],
    public readonly optionsSchema: Record<string, any>,
    public readonly translations: Record<string, any>,
  ) { }

  public static parse(raw: any) {
    if (!isPlainObject(raw)) {
      console.warn("Invalid feature definition", raw)
      return null
    }

    const {name, dependsOn, optionsSchema = {}, translations} = raw
    return new AvailableFeature(name, dependsOn, optionsSchema, translations)
  }

  public translate(key: string, language: string = I18next.language) {
    return get(this.translations[language] ?? this.translations.en, key)
  }

}