import { makeObservable, observable } from 'mobx'
import * as UUID from 'uuid'
import { Challenge, Module, PlannerAction, Script, TimeOfDay, Triggerable } from '~/models'
import { CalendarPlanner } from '~/stores'
import { SubmitResult } from '~/ui/form'
import TriggerableFormModel from '../../triggerables/TriggerableFormModel'

export default class CalendarTriggerableItemFormModel extends TriggerableFormModel {

  constructor(
    protected readonly planner: CalendarPlanner,

    dayUUID:     string,
    private readonly itemUUID: string | null,

    triggerable: Triggerable | null,
    type:        Triggerable['type'],
    time:        TimeOfDay,
    module:      Module,
    model:       Script | Challenge | null = null,
    action:      PlannerAction | null = null,
  ) {
    super(triggerable, type, module, model, action)
    this.day  = dayUUID
    this.time = time

    makeObservable(this)
  }

  public get isNew() {
    return this.triggerable == null
  }

  @observable
  public day: string

  @observable
  public time: TimeOfDay

  public getValue(name: string) {
    if (name === 'day' || name === 'time') {
      return (this as any)[name]
    } else {
      return super.getValue(name)
    }
  }

  public assign(data: AnyObject) {
    const {day, time, ...rest} = data
    if (day !== undefined) {
      this.day = day
    }
    if (time !== undefined) {
      this.time = time
    }
    super.assign(rest)
  }

  //------
  // Submission

  protected async submitWithTriggerable(triggerable: AnyObject): Promise<SubmitResult | undefined> {
    if (this.triggerable == null) {
      return this.planner.addItem(this.day, {
        uuid:        UUID.v4(),
        type:        'triggerable',
        time:        this.time,
        triggerable: triggerable as Triggerable,
      })
    } else if (this.itemUUID != null) {
      return this.planner.updateItem(this.itemUUID, item => ({
        ...item,
        time: this.time,
      }))
    }
  }

}