import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { sparse } from 'ytil'
import { PlanNode } from '~/models'
import { observer } from '~/ui/component'
import { Scroller, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ActivateDialog, { ActivateOptions } from '../../activate/ActivateDialog'
import { useFlowPlanner } from '../FlowPlannerContext'
import NodeView from './NodeView'

export interface Props {
  open:          boolean
  requestClose?: () => any

  nodeUUIDs: string[]
}

const ActivateNodesDialog = observer('ActivateNodesDialog', (props: Props) => {

  const {nodeUUIDs, open, requestClose} = props

  const {planner, plan} = useFlowPlanner()
  const [t]             = useTranslation('flow_planner')

  //------
  // Form model & nodes

  const nodes = sparse(nodeUUIDs.map(uuid => plan?.findNode(uuid)))

  //------
  // Callbacks

  const activateNodes = React.useCallback(async (participantIDs: Array<string | null>, options: ActivateOptions) => {
    return await planner.activateNodes(nodeUUIDs, participantIDs, options)
  }, [nodeUUIDs, planner])

  const showNotice = React.useCallback((who: string) => {
    const key = nodes.length === 1
      ? 'activate_nodes.success.singular'
      : 'activate_nodes.success.plural'

    Toast.show({
      type: 'success',
      ...t(key, {who}),
    })
  }, [nodes.length, t])


  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ActivateDialog
        open={open}
        requestClose={requestClose}

        title={t('activate_nodes.title')}
        instruction={t('activate_nodes.instruction')}

        renderSummary={renderNodes}
        requestActivate={activateNodes}
        onDidActivate={showNotice}
      />
    )
  }

  function renderNodes() {
    return (
      <VBox classNames={$.nodes}>
        <Scroller overflow={4} contentClassNames={$.nodesListContent}>
          <VBox gap={layout.padding.inline.m}>
            {nodes.map(renderNode)}
          </VBox>
        </Scroller>
      </VBox>
   )
  }

  function renderNode(node: PlanNode) {
    if (plan == null) { return null }
    return (
      <VBox key={node.uuid}>
        <NodeView
          plan={plan}
          node={node}
          interactive={false}
          variant='list'
        />
      </VBox>
    )
  }

  return render()

})

export default ActivateNodesDialog

const useStyles = createUseStyles({
  nodes: {
    width: 240,
  },

  nodesListContent: {
    ...layout.responsiveProp({
      marginRight: layout.padding.m,
    }),
  },
})