import React from 'react'
import { NavigationGroup, NavigationItem } from '~/navigation.yml'
import {
  filterNavigationGroups,
  isNavigationGroup,
  isStandardNavigationItem,
  navigationItemCaption,
  navigationItemDetail,
  navigationStore,
} from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import NavigationButton from './NavigationButton'

export interface Props {
  search?: string | null
}

const Navigation = observer('Navigation', (props: Props) => {

  const {search} = props
  const groups = navigationStore.availableGroups

  const searchedGroups = React.useMemo(() => {
    if (search == null) { return groups }

    return filterNavigationGroups(groups, item => {
      if (isNavigationGroup(item)) { return true }

      const caption  = navigationItemCaption(item).toLowerCase()
      const detail = navigationItemDetail(item).toLowerCase()
      return caption.includes(search) || detail.includes(search)
    })
  }, [groups, search])

  //------
  // Rendering

  function render() {
    if (searchedGroups.length === 0) { return null }

    return (
      <VBox flex='grow' gap={layout.padding.s}>
        {searchedGroups.map((group, index) => (
          <React.Fragment key={group.group}>
            {index > 0 && <Dimple horizontal/>}
            {renderGroup(group)}
          </React.Fragment>
        ))}
      </VBox>
    )
  }

  function renderGroup(group: NavigationGroup) {
    return (
      <VBox key={group.group}>
        {group.items.map(renderButton)}
      </VBox>
    )
  }

  function renderButton(item: NavigationItem) {
    const key = isStandardNavigationItem(item) ? item.name : item.resource

    return (
      <NavigationButton
        key={key}
        item={item}
      />
    )
  }

  return render()

})

export default Navigation