import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { Participant } from '~/models'
import { OperatorTrigger, runtimeStore } from '~/stores'
import { observer } from '~/ui/component'
import { PushButton, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { layout } from '~/ui/styling'
import OperatorTriggerField from './OperatorTriggerField'

export interface Props {
  participantID: string
}

const OperatorPanelActions = observer('OperatorPanelActions', (props: Props) => {

  const {participantID} = props

  const [participant] = useModelDocumentData(Participant, participantID)

  const [t] = useTranslation('operator')

  const [trigger, setTrigger] = React.useState<OperatorTrigger | null>(null)

  const activateTrigger = React.useCallback(async () => {
    if (trigger == null) { return }

    const success = await runtimeStore.activateNode(
      trigger.planID,
      trigger.uuid,
      participantID,
    )

    if (success) {
      Toast.show({
        type: 'success',
        ...t('actions.activate_trigger.success', {
          trigger:     trigger,
          participant: participant,
        }),
      })
    } else {
      Toast.show({
        type: 'error',
        ...t('actions.activate_trigger.error', {
          trigger:     trigger,
          participant: participant,
        }),
      })
    }
  }, [participant, participantID, t, trigger])

  //------
  // Rendering

  function render() {
    return (
      <VBox padding={layout.padding.s} gap={layout.padding.inline.m}>
        <OperatorTriggerField
          value={trigger}
          onChange={setTrigger}
        />
        <PushButton
          icon='flash'
          caption={t('actions.activate_trigger.caption')}
          onTap={activateTrigger}
          enabled={trigger != null}
        />
      </VBox>
    )
  }

  return render()

})

export default OperatorPanelActions