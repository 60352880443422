import React from 'react'
import { projectStore } from '~/stores'
import { Navigation } from '~/ui/app/navigation'
import { observer } from '~/ui/component'
import { Dimple, Scroller, SearchField, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

const LeftNav = observer('LeftNav', () => {

  const projectID = projectStore.projectID

  //------
  // Search

  const [search, setSearch] = React.useState<string | null>(null)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.leftNav}>
        {renderNavigation()}
        <Dimple horizontal/>
        {renderSearch()}
      </VBox>
    )
  }

  function renderSearch() {
    return (
      <VBox classNames={$.search}>
        <SearchField
          onSearch={setSearch}
          searchWhileTyping
          inputStyle='light'
        />
      </VBox>
    )
  }

  function renderNavigation() {
    return (
      <Scroller
        key={projectID}
        contentClassNames={$.navigationContent}
      >
        <Navigation
          search={search}
        />
      </Scroller>
    )
  }

  return render()

})

export default LeftNav

const useStyles = createUseStyles({
  leftNav: {},

  navigationItems: {
    flex:     [1, 0, 0],
    position: 'relative',
    overflow: 'hidden',
  },

  search: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },

  navigationContent: {
    ...layout.responsive(size => ({
      padding: layout.padding.m[size],
    })),
  },
})