import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { rectsIntersect } from 'ytil'
import { isAnnotation, isNode, PlanComponent } from '~/models'
import { ComponentBounds, FlowPlanner, hasBounds } from '~/stores'

export class SelectionManager {

  constructor() {
    makeObservable(this)

    this.reactionDisposer = reaction(
      () => this.selectionBounds,
      bounds => {
        if (bounds == null) { return }
        this.selectAllInBounds(bounds)
      },
    )
  }

  private reactionDisposer?: IReactionDisposer

  public dispose() {
    this.reactionDisposer?.()
  }

  @observable
  public planner: FlowPlanner | null = null

  @action
  public setPlanner(planner: FlowPlanner | null) {
    this.planner = planner
  }

  private get plan() {
    return this.planner?.plan ?? null
  }

  @observable
  private selectedUUIDSet = new Set<string>()

  @computed
  public get selectedUUIDs() {
    return [...this.selectedUUIDSet]
  }

  @computed
  public get selectedComponents() {
    const selectedComponents: PlanComponent[] = []
    for (const uuid of this.selectedUUIDs) {
      const component = this.plan?.findComponent(uuid)
      if (component != null) {
        selectedComponents.push(component)
      }
    }
    return selectedComponents
  }

  @computed
  public get selectedBounds(): ComponentBounds[] {
    const selectedBounds: ComponentBounds[] = []
    for (const uuid of this.selectedUUIDs) {
      const component = this.plan?.findComponent(uuid)
      if (component == null || !hasBounds(component)) { continue }

      const bounds = this.planner?.componentBounds.find(bounds => bounds.component.uuid === uuid)
      if (bounds == null) { continue }

      selectedBounds.push(bounds)
    }
    return selectedBounds
  }

  @observable
  public selectionBounds: SelectionBounds | null = null

  //------
  // Interface

  @action
  public select(...uuids: string[]) {
    for (const uuid of uuids) {
      this.selectedUUIDSet.add(uuid)
    }
  }

  @action
  public deselect(...uuids: string[]) {
    for (const uuid of uuids) {
      this.selectedUUIDSet.delete(uuid)
    }
  }

  @action
  public selectOnly(...uuids: string[]) {
    this.selectedUUIDSet.clear()
    this.select(...uuids)
  }

  @action
  public toggle(uuid: string) {
    if (this.selectedUUIDSet.has(uuid)) {
      this.selectedUUIDSet.delete(uuid)
    } else {
      this.selectedUUIDSet.add(uuid)
    }
  }

  @action
  public selectAll() {
    this.selectedUUIDSet = new Set(this.planner?.componentBounds.map(it => it.component.uuid))
  }

  @action
  public deselectAll() {
    this.selectedUUIDSet.clear()
  }

  @action
  public deselectSegue() {
    if (this.selectedUUIDs.length !== 1) { return }

    const segue = this.plan?.findComponent(this.selectedUUIDs[0])
    if (segue != null) {
      this.selectedUUIDSet.clear()
      this.planner?.closeSegueMenu()
    }
  }

  @action
  public extendSelectionBounds(extent: Point) {
    if (this.selectionBounds == null) {
      this.selectionBounds = {
        base:   extent,
        extent: extent,
      }
    } else {
      this.selectionBounds = {
        base:   this.selectionBounds.base,
        extent: extent,
      }
    }
  }

  @action
  public clearSelectionBounds() {
    this.selectionBounds = null
  }

  @action
  private selectAllInBounds(bounds: SelectionBounds) {
    const selectionRect = selectionBoundsToLayoutRect(bounds)

    this.selectedUUIDSet.clear()
    for (const {component, bounds} of this.planner?.componentBounds ?? []) {
      if (rectsIntersect(selectionRect, bounds)) {
        this.selectedUUIDSet.add(component.uuid)
      }
    }
  }

  //------
  // Module extraction

  @computed
  public get hasNodesOrAnnotations() {
    const nodes       = this.selectedComponents.filter(isNode)
    const annotations = this.selectedComponents.filter(isAnnotation)
    return nodes.length > 0 || annotations.length > 0
  }

  @computed
  public get canExtractModule() {
    if (this.planner == null) { return false }
    return this.planner?.canExtractModule(this.selectedComponents)
  }

  //------
  // Misc

  @observable
  public selectionHidden: boolean = false

  @action
  public setSelectionHidden(hidden: boolean) {
    this.selectionHidden = hidden
  }

}

export function selectionBoundsToLayoutRect(bounds: SelectionBounds): LayoutRect {
  return {
    top:    Math.min(bounds.base.y, bounds.extent.y),
    left:   Math.min(bounds.base.x, bounds.extent.x),
    height: Math.abs(bounds.base.y - bounds.extent.y),
    width:  Math.abs(bounds.base.x - bounds.extent.x),
  }
}

export interface SelectionBounds {
  base:   Point
  extent: Point
}