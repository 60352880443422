function iterate(val: any): any {
  // For some reason, "val instanceof Color" does not work (anymore).
  if (val instanceof Object && 'color' in val && typeof val.model === 'string') {
    return val.toString()
  } else if (Array.isArray(val)) {
    return val.map(iterate)
  } else if (val instanceof Object) {
    const res: AnyObject = {}
    for (const key of Object.keys(val)) {
      res[key] = iterate(val[key])
    }
    return res
  } else {
    return val
  }
}

export default function colors() {
  function onProcessStyle(style: any, rule: any) {
    if (rule.type !== 'style') {
      return style
    }

    for (const prop of Object.keys(style)) {
      style[prop] = iterate(style[prop])
    }

    return style
  }

  function onChangeValue(value: any, prop: string) {
    return iterate(value)
  }

  return {onProcessStyle, onChangeValue}
}