import React from 'react'
import { operatorStore } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, Panel } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import OperatorChat from './OperatorChat'
import OperatorPanelActions from './OperatorPanelActions'
import OperatorPanelHeader from './OperatorPanelHeader'

export interface Props {
  participantID: string
}

const OperatorPanel = observer('OperatorPanel', (props: Props) => {

  const {participantID} = props

  const service = operatorStore.service
  const entry   = service?.getEntry(participantID) ?? null

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel
        classNames={$.operatorPanel}
        flex
        depth={2}
        header={renderHeader()}
      >
        <OperatorChat
          participantID={participantID}
        />
        <Dimple horizontal/>
        <OperatorPanelActions
          participantID={participantID}
        />
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <OperatorPanelHeader
        participantID={participantID}
        entry={entry}
      />
    )
  }

  return render()

})

export default OperatorPanel

export const width = 360

const useStyles = createUseStyles({
  operatorPanel: {
    width,
  },
})