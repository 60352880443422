import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { Label, PushButton, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import Tooltip from '../../../components/Tooltip'
import { useSelection } from './canvas/SelectionContext'
import ExtractModuleForm from './ExtractModuleForm'

const SelectionSummary = observer('SelectionSummary', () => {

  const {manager, selectedComponents} = useSelection.unoptim()

  const [extractModuleFormOpen, openExtractModuleForm, closeExtractModuleForm] = useBoolean()

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (selectedComponents.length === 0) { return null }

    return (
      <ThemeProvider dark>
        <VBox classNames={$.selectionSummary} padding={layout.padding.inline.l} gap={layout.padding.inline.m}>
          {renderSummary()}
          {renderExtractButton()}
        </VBox>

        <ExtractModuleForm
          open={extractModuleFormOpen}
          requestClose={closeExtractModuleForm}
          components={selectedComponents}
        />
      </ThemeProvider>
    )
  }

  function renderSummary() {
    return (
      <Label caption small align='center'>
        {t('selection.summary', {count: selectedComponents.length})}
      </Label>
    )
  }

  function renderExtractButton() {
    if (!manager?.hasNodesOrAnnotations) { return null }

    const btn = (
      <PushButton
        icon='puzzle'
        caption={t('extract_module.caption')}
        enabled={manager.canExtractModule}
        onTap={openExtractModuleForm}
        small
      />
    )

    if (manager.canExtractModule) {
      return btn
    } else {
      return (
        <Tooltip renderTooltip={t('extract_module.unable_to_extract')}>
          {btn}
        </Tooltip>
      )
    }
  }

  return render()

})

export default SelectionSummary

export const width = 240

const useStyles = createUseStyles({
  selectionSummary: {
    borderRadius: layout.radius.l,
    background:   colors.shim.light,
    width:        width,
  },
})