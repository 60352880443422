import React from 'react'
import { EnumUtil } from 'ytil'
import { Project } from '~/models'
import { observer } from '~/ui/component'
import { AssistantFormDialog, SubmitResult } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import DuplicateProjectFormModel from './DuplicateProjectFormModel'
import DuplicateStep from './DuplicateStep'
import PreflightStep from './PreflightStep'

export interface Props {
  open:          boolean
  requestClose?: () => any

  project: Project

  afterSubmit?: (result: SubmitResult) => any
}

const DuplicateProjectForm = observer('DuplicateProjectForm', (props: Props) => {

  const {
    open,
    requestClose,
    project,
    afterSubmit,
  } = props

  const formModel = React.useMemo(
    () => new DuplicateProjectFormModel(project),
    [project],
  )

  const duplicating = formModel.status === 'duplicating'

  const {t, actionCaption} = useResourceTranslation()

  const steps = React.useMemo(() => EnumUtil.values(DuplicateProjectStep), [])

  const [step, setStep] = React.useState<DuplicateProjectStep>(DuplicateProjectStep.preflight)
  const prevStep = usePrevious(step)

  const captionForStep = React.useCallback((step: DuplicateProjectStep) => {
    return t(`actions.duplicate.steps.${step}`)
  }, [t])

  React.useEffect(() => {
    if (prevStep === 'preflight' && step === 'duplicate') {
      formModel.duplicate()
    }
  }, [formModel, prevStep, step])

  //------
  // Rendering

  function render() {
    return (
      <AssistantFormDialog<DuplicateProjectFormModel, DuplicateProjectStep>
        open={open}
        requestClose={requestClose}
        model={formModel}
        afterSubmit={afterSubmit}
        icon='copy'
        title={actionCaption('duplicate')}

        // This form doesn't submit at the end. The copying is done half way.
        submitButton={{caption: t('buttons:done')}}
        working={duplicating}

        steps={steps}
        currentStep={step}
        requestStep={setStep}
        captionForStep={captionForStep}
        renderStep={renderStep}
        allowSelectStep={duplicating ? 'none' : 'previous'}

        autoFocus={false}
        closeOnSuccess={true}
        showCancelButtonOnLastStep={false}

        width={720}
        semi={false}
      />
    )
  }

  const renderStep = React.useCallback((step: DuplicateProjectStep) => {
    switch (step) {
      case DuplicateProjectStep.preflight:
        return <PreflightStep/>
      case DuplicateProjectStep.duplicate:
        return <DuplicateStep/>
    }
  }, [])

  return render()

})

export default DuplicateProjectForm

export enum DuplicateProjectStep {
  preflight = 'preflight',
  duplicate = 'duplicate',
}