import React from 'react'
import { Participant } from '~/models'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { ResourceBar, ResourceBarProps } from '~/ui/resources/components'

export interface Props extends Omit<ResourceBarProps, 'model' | 'image'> {
  participant: Participant
}

const ParticipantBar = memo('ParticipantBar', (props: Props) => {

  const {participant, ...rest} = props

  //------
  // Rendering

  function render() {
    if (participant == null) { return null }

    return (
      <ResourceBar
        model={participant}
        image={renderAvatar()}
        {...rest}
      />
    )
  }

  function renderAvatar() {
    if (participant == null) { return null }
    return (
      <Avatar
        source={participant.photoURL}
        firstName={participant.firstName}
        lastName={participant.lastName}
        size={avatarSize}
      />
    )
  }

  return render()

})

export default ParticipantBar

export const avatarSize = {
  width:  32,
  height: 32,
}