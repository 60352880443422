import React from 'react'
import { Location } from '~/models'
import { memo } from '~/ui/component'
import { FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import BeaconLocationFields from './BeaconLocationFields'
import LatLongLocationFields from './LatLongLocationFields'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  type?:  Location['type']
  model?: Location | null
}

const LocationForm = memo('LocationForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props

  const type = props.type ?? model?.type ?? null

  const initialData = React.useMemo(() => {
    if (model != null) { return {} }

    const data: AnyObject = {type}
    if (type === 'beacon') {
      data.beacons = []
    }

    return data
  }, [model, type])

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Location}
        model={model}

        open={open}
        requestClose={requestClose}

        initialData={initialData}
        afterSubmit={afterSubmit}
        width={type === 'beacon' ? 880 : 560}
        semi={type !== 'beacon'}

        children={renderFields()}
      />
    )
  }

  function renderFields() {
    switch (type) {
      case 'latlong':
        return <LatLongLocationFields/>
      case 'beacon':
        return <BeaconLocationFields/>
    }
  }

  return render()

})



export default LocationForm