import React from 'react'
import { Challenge } from '~/models'
import { LocalizedTextField } from '~/ui/app/fields'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import ModuleField from '~/ui/app/modules/ModuleField'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { HBox, SwitchField, TextField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  moduleField?: boolean
}

const ChallengeFields = memo('ChallengeFields', (props: Props) => {

  const {moduleField = true} = props

  function render() {
    return (
      <ResourceTypeProvider resourceType='challenges'>
        <VBox gap={layout.padding.m}>
          {moduleField && (
            <FormField name='module'>
              {bind => <ModuleField {...bind}/>}
            </FormField>
          )}

          <HBox align='top' gap={layout.padding.s}>
            {renderBasicFields()}
            {renderImageField()}
          </HBox>

          <FormField name='tags'>
            {bind => <TagsField Model={Challenge} {...bind}/>}
          </FormField>

          <VBox gap={layout.padding.inline.s}>
            <FormFieldHeader name='options'/>
            <FormField name='allowRedo' caption={false}>
              {bind => <SwitchField {...bind}/>}
            </FormField>
          </VBox>
        </VBox>
      </ResourceTypeProvider>
    )
  }

  function renderBasicFields() {
    return (
      <VBox flex gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='title'>
          {bind => <LocalizedTextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderImageField() {
    return (
      <FormField name='image' renderAsLabel={false}>
        {bind => (
          <CustomImageField
            {...bind}
            size={{width: 116, height: 116}}
            objectFit='contain'
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default ChallengeFields