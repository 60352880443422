// @index(d:!,!^use): export { default as ${variable}} from ${relpath}
export { default as ActionButton } from './ActionButton'
export { default as ActionStateLabel } from './ActionStateLabel'
export { default as ResourceDetailScreen } from './ResourceDetailScreen'
export { default as ResourceDetailSummaryPanel } from './ResourceDetailSummaryPanel'
export { default as SummaryInfo } from './SummaryInfo'
export { default as SummaryPanelSection } from './SummaryPanelSection'
// /index

// @index(d:!,!^use): export type {Props as ${variable}Props} from ${relpath}
export type {Props as ActionButtonProps } from './ActionButton'
export type {Props as ActionStateLabelProps } from './ActionStateLabel'
export type {Props as ResourceDetailScreenProps } from './ResourceDetailScreen'
export type {Props as ResourceDetailSummaryPanelProps } from './ResourceDetailSummaryPanel'
export type {Props as SummaryInfoProps } from './SummaryInfo'
export type {Props as SummaryPanelSectionProps } from './SummaryPanelSection'
// /index

export * from './ResourceDetailScreen'
export * from './useResourceDetailLocation'