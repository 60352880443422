import React from 'react'
import { useHistory } from 'react-router-dom'
import config from '~/config'
import { appLinksStore } from '~/stores'
import { observer } from '~/ui/component'
import { TappableContainer } from '~/ui/components/tappable'

export interface Props {
  children?: React.ReactNode
}

const AppNavigationContainer = observer('AppNavigationContainer', (props: Props) => {

  const history = useHistory()

  const resolveHref = React.useCallback((href: string) => {
    // Resolves an HREF for a tappable. If the HREF is an app-link, we need to resolve it.

    // Note that we always resolve it for the '$web' target, even if we've set to open app
    // links somewhere else. The reason for this is that in a browser context, it's always
    // good to have some web based fallback, in this case opening the web client in a separate
    // tab. However, if the link is tapped without any modifier keys, we'll open it in the
    // currently chosen default target instead.

    return appLinksStore.resolveAppLink(href, '$web') ?? href
  }, [])

  const targetForHref = React.useCallback((href: string) => {
    // If the HREF is an app-link, we need to use the 'web' target if the default target is '$web'.
    if (appLinksStore.isAppLink(href) && appLinksStore.defaultTarget === '$web') {
      return '_blank'
    }

    // Any link that doesn't have a protocol is an internal link.
    const match = href.match(/^[\d\w.+-]+:/)
    if (match == null) { return undefined }

    // If it starts with the current mission URL, it's also internal.
    if (href.startsWith(config.urls.mission)) { return undefined }

    // It's external.
    return '_blank'
  }, [])

  const shouldUseDefault = React.useCallback((href: string, target: string | undefined, event: React.SyntheticEvent) => {
    if (target != null && target !== '') { return true }

    // Browsers have a default handler for cmd, or ctrl clicking a link.
    // We need to let the browsers take care of that and not consider this a tap.
    const mouseEvent = event as React.MouseEvent | React.TouchEvent
    if (mouseEvent.metaKey || mouseEvent.altKey || mouseEvent.ctrlKey) {
      return true
    }

    return false
  }, [])

  const navigate = React.useCallback((href: string, target: string | undefined, event: React.SyntheticEvent) => {
    if (shouldUseDefault(href, target, event)) { return }
    event.preventDefault()

    if (appLinksStore.isAppLink(href)) {
      appLinksStore.openAppLink(href)
    } else {
      history.push(href)
    }
  }, [history, shouldUseDefault])

  return (
    <TappableContainer
      targetForHref={targetForHref}
      resolveHref={resolveHref}
      navigate={navigate}
      {...props}
    />
  )

})

export default AppNavigationContainer