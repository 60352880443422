import React from 'react'
import { Review } from '~/models'
import { observer } from '~/ui/component'
import { Label, HBox, Spinner, SVG } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChip } from '~/ui/resources/components'
import { layout, useStyling } from '~/ui/styling'
import { useReviewQuestion } from '../hooks'

export interface Props {
  review: Review
}

const QuestionCell = observer('QuestionCell', (props: Props) => {

  const {review} = props
  const {colors} = useStyling()
  const {t}      = useResourceTranslation()

  const [question, fetchStatus] = useReviewQuestion(review, {detail: false})

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.m}>
        <SVG
          name='question'
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        {question == null && fetchStatus === 'fetching' ? (
          <Spinner size={12}/>
        ) : (
          renderQuestion()
        )}
      </HBox>
    )
  }

  function renderQuestion() {
    if (question == null) { return null }

    return (
      <HBox gap={layout.padding.inline.m}>
        <Label flex='shrink' bold>
          {question?.text ?? t('fields.question.not_found')}
        </Label>
        <ResourceChip
          Model={question.model.$ModelClass}
          id={question.model.id}
          small
        />
      </HBox>
    )
  }

  return render()

})

export default QuestionCell