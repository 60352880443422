import React from 'react'
import { useHotkey } from 'react-hotkeys'
import Toast from 'react-toast'
import clipboard, { ClipboardItem } from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { CalendarDay } from '~/models'
import { observer } from '~/ui/component'
import { Center, Empty, HBox, PushButton, Scroller, Spinner, VBox } from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { CalendarPlanClipboardItem } from '../../../../clipboard'
import { useCalendarLayout } from './CalendarLayoutContext'
import { useCalendarPlanner } from './CalendarPlannerContext'
import { CalendarItemFormModel } from './content/CalendarItemForm'
import CalendarDayPanel from './days/CalendarDayPanel'
import { useCalendarPan } from './hooks/useCalendarPan'
import { useModeToggleKeys } from './hooks/useModeToggleKeys'
import { useSelection } from './SelectionContext'
import SelectionLayer from './SelectionLayer'
import SelectLayer from './SelectLayer'

export interface Props {
  loading:           boolean
  requestAddDay:     () => any
  requestEditDay:    (day: CalendarDay) => any
  requestCreateItem: (model: CalendarItemFormModel) => Promise<void>
}

const CalendarCanvas = observer('CalendarCanvas', (props: Props) => {

  const {loading, requestAddDay, requestEditDay, requestCreateItem} = props
  const {mode, setMode, planner} = useCalendarPlanner()
  const days = planner.plan?.days ?? []

  const {t, actionCaption} = useResourceTranslation('calendar_planner')
  const {origin, setOrigin} = useCalendarLayout.unoptim()

  const {manager} = useSelection()

  //------
  // Panning

  const [connectPanContainer, connectPanPanLayer] = useCalendarPan({
    enabled: mode === 'pan',
    origin:  origin,
    onPan:   setOrigin,
  })

  const bodyRef     = React.useRef<HTMLDivElement>(null)
  const panLayerRef = React.useRef<HTMLDivElement>(null)

  const connectBody     = connectPanContainer(bodyRef)
  const connectPanLayer = connectPanPanLayer(panLayerRef)

  const empty = days.length === 0

  //------
  // Keyboard

  useHotkey('C', React.useCallback(() => {
    setMode(mode === 'create' ? 'select' : 'create')
  }, [mode, setMode]))

  useHotkey('Short+D', React.useCallback(() => {
    const uuids = manager?.selectedUUIDs ?? []
    const items = planner.getClipboardItems(uuids) ?? []
    if (items.length === 0) { return }

    planner.pasteClipboardItems(items).then(uuids => {
      if (uuids != null) {
        manager?.selectOnly(...uuids)
      }
    })
  }, [manager, planner]))

  useHotkey('Short+C', React.useCallback(() => {
    const uuids = manager?.selectedUUIDs ?? []
    const items = planner.getClipboardItems(uuids) ?? []
    if (items.length === 0) { return }

    const clipboardItem: ClipboardItem<CalendarPlanClipboardItem[]> = {
      type: ClipboardType.CALENDAR_ITEMS,
      data: items,
    }
    clipboard.write([clipboardItem])

    Toast.show({
      ...t('actions.copy.success', {count: items.length}),
      type: 'success',
    })
  }, [manager?.selectedUUIDs, planner, t]))

  useHotkey('Short+V', React.useCallback(() => {
    const item = clipboard.getData<CalendarPlanClipboardItem[]>(ClipboardType.CALENDAR_ITEMS)
    if (item == null) { return }

    planner.pasteClipboardItems(item.data).then(uuids => {
      if (uuids != null) {
        manager?.selectOnly(...uuids)
      }
    })
  }, [manager, planner]))

  useHotkey('Backspace | Delete', React.useCallback(() => {
    const uuids = manager?.selectedUUIDs ?? []
    if (uuids.length === 0) { return }

    planner.removeItems(uuids)
  }, [manager?.selectedUUIDs, planner]))

  useHotkey('Escape', React.useCallback(() => {
    setMode('select')
  }, [setMode]))

  useModeToggleKeys()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex ref={connectBody}>
        <Scroller horizontal classNames={$.scroller} contentClassNames={$.scrollerContent}>
          <HBox classNames={$.overlays} gap={layout.padding.s} align='stretch'>
            {!empty && <SelectLayer />}

            {days.map(renderDay)}
            {empty && renderEmpty()}

            {!empty && <SelectionLayer />}

            {!empty && (
              <div
                classNames={[$.panLayer, {active: mode === 'pan'}]}
                ref={connectPanLayer}
              />
            )}
          </HBox>
        </Scroller>
      </VBox>
    )
  }

  function renderDay(day: CalendarDay, index: number) {
    return (
      <CalendarDayPanel
        key={day.uuid}
        day={day}
        first={index === 0}
        requestEdit={requestEditDay}
        requestCreateItem={requestCreateItem}
      />
    )
  }

  function renderEmpty() {
    if (loading) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    } else {
      return (
        <Empty
          flex='grow'
          {...t('empty')}
          children={renderAddDayButton()}
        />
      )
    }
  }

  function renderAddDayButton() {
    return (
      <PushButton
        icon='plus'
        caption={actionCaption('add_day')}
        onTap={requestAddDay}
      />
    )
  }

  return render()

})

export default CalendarCanvas

const useStyles = createUseStyles({
  scroller: {
    marginTop: -badgeSize.normal.height / 2,
  },

  scrollerContent: {
    flex: [1, 0, 'auto'],

    ...layout.responsive(size => ({
      paddingLeft:   2,
      paddingTop:    2 + badgeSize.normal.height / 2,
      paddingBottom: layout.padding.m[size],
      paddingRight:  layout.padding.m[size],
    })),
  },

  overlays: {
    position: 'relative',
  },

  panLayer: {
    ...layout.overlay,
    cursor: 'ns-resize',
    '&:not(.active)': {
      pointerEvents: 'none',
    },
  },
})