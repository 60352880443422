import Timer from 'react-timer'
import { some } from 'lodash'
import Logger from 'logger'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable } from 'mobx'
import { ScopedSocket } from 'socket.io-react'
import { v4 as uuidV4 } from 'uuid'
import config from '~/config'
import {
  Channel,
  isPendingMessage,
  Message,
  MessageList,
  PendingMessage,
  PendingMessageResource,
  PendingMessageTemplate,
  Sender,
} from '~/models'
import chatStore from '../chatStore'
import mediaStore from '../mediaStore'
import ChatService from './ChatService'
import SendersEndpoint from './senders/SendersEndpoint'
import { IncomingMessagesPayload, MessageStatusPayload } from './types'

import type { ChatChannelController, ChannelPreview } from '~/ui/app/chat/types'

export default class ChannelStore implements ChatChannelController {

  constructor(
    private readonly service: ChatService,
    private readonly socket: ScopedSocket,
    channel: Channel,
  ) {
    this.channel = channel
    makeObservable(this)
  }

  private readonly logger = new Logger('ChannelStore')

  //------
  // Loading & metadata

  private get participantID() {
    return this.service.participantID
  }

  @observable
  public loading: boolean = true

  @observable
  public channel: Channel

  @observable
  public senders = new Map<string, Sender>()

  //------
  // Message list

  @observable
  public messageList = new MessageList()

  public nextPageToken: string | null = null

  //------
  // Unread count

  @observable
  public lastReadMessageID?: string | null

  @computed
  public get unreadCount() {
    // If the last read message ID is undefined, we haven't done any read detection at all, just return 0.
    if (this.lastReadMessageID === undefined) { return 0 }
    if (this.messageList == null) { return 0 }

    if (this.lastReadMessageID == null) {
      return this.messageList.count ?? 0
    } else {
      return this.messageList.getDescendingIndex(this.lastReadMessageID) ?? this.messageList.count ?? 0
    }
  }

  //------
  // Preview

  @computed
  public get preview(): ChannelPreview {
    return {
      channelID:         this.channel.id,
      name:              this.channel.name,
      image:             this.channel.image,
      channel:           this.channel,
      mostRecentMessage: this.messageList?.mostRecentMessage ?? null,
      unreadCount:       this.unreadCount,
      pinIndex:          this.channel.pinIndex,
      typingNames:       this.typingNames,
    }
  }

  //------
  // Incoming messages

  private incomingBuffer: IncomingMessagesPayload[] = []

  public handleIncomingMessages(payload: IncomingMessagesPayload): Promise<Message[]> {
    // If we hadn't detected the last read message yet, do so now, before appending the messages.
    if (this.lastReadMessageID === undefined) {
      this.lastReadMessageID = this.messageList?.mostRecentMessage?.id ?? null
    }

    if (this.fetchingNewMessages) {
      // We're currently fetching messages. Queue them for processing after the fetch.
      this.incomingBuffer.push(payload)
      return Promise.resolve([])
    }

    this.updateSenders(payload.senders)
    this.onStopTyping(...payload.senders.map(sender => sender.id))

    const result = this.processIncomingMessages(payload)
    if (!result.contiguous) {
      // There's a gap. Refetch all new messages.
      return this.fetchNewMessages()
    } else {
      return Promise.resolve(result.messages)
    }
  }

  private flushIncomingBuffer() {
    let allOK = true
    while (this.incomingBuffer.length > 0) {
      const payload = this.incomingBuffer.shift()!
      if (!this.processIncomingMessages(payload)) {
        allOK = false
      }
    }
    return allOK
  }

  @action
  private processIncomingMessages(payload: IncomingMessagesPayload): ProcessIncomingMessagesResult {
    const messagesToPrepend = payload.messages.filter(msg => this.messageList?.get(msg.id) == null)
    if (!this.messagesAreContiguous(messagesToPrepend, payload.previousMessageID)) {
      // There's a gap.
      return {contiguous: false}
    } else {
      const messages = payload.messages.map(raw => Message.deserialize(raw))
      this.messageList = this.prependMessages(messages)
      return {contiguous: true, messages}
    }
  }

  private messagesAreContiguous(incoming: AnyObject[], previousMessageID: string | null) {
    if (incoming.length === 0) { return true }

    const mostRecentMessage   = this.messageList?.mostRecentMessage
    const mostRecentMessageID = mostRecentMessage == null ? null : mostRecentMessage.id

    if (DEV) {
      this.logger.debug("Incoming messages. " + (previousMessageID === mostRecentMessageID ? "Contiguous 👍" : "Non-contiguous 😔"), [
        "Channel:      " + this.channel.id + ` (${this.channel.name})`,
        "Last message: " + mostRecentMessageID + ` (${mostRecentMessage && (mostRecentMessage as any).text})`,
        "--------------",
        "Incoming[0]:  " + incoming[0].id + ` (${(incoming[0] as any).text})`,
        "Previous:     " + previousMessageID,
      ])
    }

    return previousMessageID === mostRecentMessageID
  }

  @action
  public updateMessageStatus(payload: MessageStatusPayload) {
    if (this.messageList == null) { return }

    for (const update of payload.statuses) {
      const {messageID, status, answeredAt} = update
      this.messageList = this.messageList.updateMessage(messageID, message => {
        if (isPendingMessage(message)) {
          return message
        } else {
          return message.updateStatus({
            status:     status,
            answeredAt: answeredAt == null ? undefined : DateTime.fromISO(answeredAt),
          })
        }
      })
    }
  }

  private appendMessages(messages: Message[]) {
    if (this.messageList == null) {
      return new MessageList(messages)
    } else {
      return this.messageList.appendMessages(messages)
    }
  }

  private prependMessages(messages: Message[]) {
    if (this.messageList == null) {
      return new MessageList(messages)
    } else {
      return this.messageList.prependMessages(messages)
    }
  }

  private prependPendingMessage(message: PendingMessage) {
    if (this.messageList == null) {
      return new MessageList([message])
    } else {
      return this.messageList.prependMessages([message])
    }
  }

  //------
  // Senders

  @action
  private updateSenders(senders: Sender[]) {
    for (const sender of senders) {
      this.senders.set(sender.id, sender)
    }
  }

  //------
  // Typing (as receiver)

  @observable
  public typingSenders: Sender[] = []

  @computed
  public get typingNames(): string[] {
    return this.typingSenders.map(sender => sender.firstName)
  }

  private autoStopTypingTimers: Map<string, Timer> = new Map()

  public onStartTyping = action((...senders: Sender[]) => {
    const otherSenders = this.participantID != null
      ? senders.filter(sender => sender.id !== this.participantID)
      : senders
    if (otherSenders.length === 0) { return }

    this.typingSenders = [
      ...this.typingSenders.filter(sender => !some(this.typingSenders, existing => existing.id === sender.id)),
      ...otherSenders,
    ]

    for (const sender of otherSenders) {
      this.stopTypingSoon(sender)
    }
  })

  public onStopTyping = action((...senderIDs: string[]) => {
    this.typingSenders = this.typingSenders.filter(sender => !senderIDs.includes(sender.id))

    for (const id of senderIDs) {
      this.autoStopTypingTimers.get(id)?.clearAll()
      this.autoStopTypingTimers.delete(id)
    }
  })

  private stopTypingSoon(sender: Sender) {
    let timer = this.autoStopTypingTimers.get(sender.id)
    timer?.clearAll()

    timer = new Timer()
    this.autoStopTypingTimers.set(sender.id, timer)

    timer.setTimeout(() => {
      this.onStopTyping(sender.id)
    }, config.chat.typingTimeout)
  }

  //------
  // Typing (as sender)

  private typing: boolean = false

  public startTyping() {
    if (this.typing) { return }

    this.typing = true
    this.socket.emit('typing:start', this.channel.id)
  }

  public stopTyping() {
    if (!this.typing) { return }

    this.typing = false
    this.socket.emit('typing:stop', this.channel.id)
  }

  //------
  // Fetching

  private fetchingNewMessages: boolean = false

  @action
  public async fetchNewMessages(): Promise<Message[]> {
    this.fetchingNewMessages = true

    const mostRecentMessage = this.messageList?.mostRecentMessage
    const since = mostRecentMessage == null ? null : mostRecentMessage.timestamp

    const response = await this.socket.fetch('fetch', this.channel.id, {since})
    if (response.ok) {
      return this.onFetchNewMessagesSuccess(response.body, since)
    } else {
      return this.onFetchNewMessagesError()
    }
  }

  private onFetchNewMessagesSuccess = action((data: FetchMessagesData, since: number | null): Message[] => {
    const {senders, nextPageToken} = data
    const messages = data.messages.map(raw => Message.deserialize(raw))

    this.updateSenders(senders)

    if (since != null && nextPageToken == null) {
      // We've received a new batch of messages since the given date.
      // Just prepend the messages. Keep the existing page token.
      this.messageList = this.prependMessages(messages)
    } else {
      // We've either received our first batch, or there's so much new stuff that pagination is required in the
      // new set of messages. In both cases, replace the message list from here. As soon as the participant starts
      // scrolling back, the older messages will be fetched again.
      this.messageList = new MessageList(messages)

      // Also update the next page token.
      this.nextPageToken = nextPageToken
    }

    if (this.flushIncomingBuffer()) {
      this.fetchingNewMessages = false
    } else {
      // When flushing the incoming buffer, new incontiguities were encountered – perform a fresh
      // fetch just to be sure.
      this.fetchNewMessages()
    }

    return messages
  })

  private onFetchNewMessagesError = action(() => {
    this.loading = false
    this.fetchingNewMessages = false
    return []
  })

  //------
  // Pagination

  private fetchingNextPage: boolean = false

  @action
  public fetchNextPage(): Promise<boolean> {
    if (this.fetchingNextPage) {
      return Promise.resolve(false)
    }
    if (this.nextPageToken == null) {
      return Promise.resolve(false)
    }

    this.fetchingNextPage = true

    return this.socket.fetch('fetch', this.channel.id, {
      pageToken: this.nextPageToken,
    }).then(response => {
      if (response.ok) {
        return this.onFetchNextPageSuccess(response.body)
      } else {
        return this.onFetchNextPageError()
      }
    })
  }

  private onFetchNextPageSuccess = action(async (data: FetchMessagesData) => {
    const messages = data.messages.map(it => Message.deserialize(it))
    this.messageList = this.appendMessages(messages)

    this.nextPageToken    = data.nextPageToken
    this.fetchingNextPage = false

    return true
  })

  private onFetchNextPageError = action(() => {
    this.fetchingNextPage = false
    return false
  })

  //------
  // Sending

  @action
  public async sendMessage(template: Partial<PendingMessageTemplate> & {replyTo?: string | null}) {
    // Create a UUID for the message.
    const id = uuidV4()

    if (template.type !== 'skip') {
      // Create a pending message placeholder and prepend it.
      const pending: PendingMessage = {
        replyTo: null,
        ...template as PendingMessageTemplate,

        id:      id,
        channel: this.channel.id,
        status:  'pending',
        sentAt:  DateTime.local(),
      }

      this.lastReadMessageID = id
      this.messageList       = this.prependPendingMessage(pending)
    }

    this.typing = false

    if (template.replyTo != null) {
      this.markAsAnswered(template.replyTo)
    }

    // Now send the message. Only send the template and the ID. The socket will echo the message back.
    const message = await this.prepareMessage(id, template)
    if (message == null) {
      this.updatePendingMessageStatus(id, 'error')
    } else {
      await this.socket.send('message', message)
    }
  }

  private async prepareMessage(id: string, template: Partial<PendingMessageTemplate>): Promise<AnyObject | null> {
    const templateWithMedia: AnyObject = template

    let success: boolean = true
    if (template.type === 'image') {
      success = await this.uploadAndInsertMedia(templateWithMedia, 'image')
    }
    if (template.type === 'video') {
      success = await this.uploadAndInsertMedia(templateWithMedia, 'video')
    }
    if (!success) { return null }

    return {
      id:      id,
      channel: this.channel.id,
      ...templateWithMedia,
    }
  }

  private async uploadAndInsertMedia(template: AnyObject, key: string) {
    const resource = template[key] as PendingMessageResource
    const result   = await mediaStore.storeMedia(resource.filename, resource.binary)
    if (result?.status !== 'ok') { return false }

    template[key] = {
      mediaID:     result.media.id,
      name:        result.media.name,
      contentType: result.media.contentType,
      url:         result.media.url,
    }

    return true
  }

  //------
  // Moderation

  public approveMessage(messageID: string) {
    // STUB
  }

  public redactMessage(messageID: string) {
    // STUB
  }

  //------
  // Message status

  @action
  public markAsRead() {
    if (this.shouldSendReadReceipt) {
      this.socket.emit('status:read', this.channel.id)
    }

    this.lastReadMessageID = this.messageList?.mostRecentMessage?.id ?? null
  }

  private get shouldSendReadReceipt() {
    // There is never a need to send read receipts in a participant channel. The server simply does not care if his messages
    // are read or not.
    if (this.channel.type === 'participant') { return false }

    // Early optimization: the server doesn't process read receipts if there are too many participants in the channel.
    // The client will not know about all senders, but if the client knows there are more than this number, there is no
    // need to send a read receipt.
    if (this.senders.size > config.chat.maxReceipts) { return false }

    return true
  }

  @action
  public markAsAnswered(messageID: string, answeredAt: DateTime = DateTime.local()) {
    if (this.messageList == null) { return null }

    this.messageList = this.messageList.updateMessage(
      messageID,
      message => message instanceof Message ? message.markAsAnswered(answeredAt) : message,
    )
  }

  @action
  private updatePendingMessageStatus(id: string, status: PendingMessage['status']) {
    if (this.messageList == null) { return }
    this.messageList = this.messageList.updatePendingMessage(id, msg => ({...msg, status}))
  }

  //------
  // Reset

  public reset() {
    this.messageList = new MessageList()
    this.loading = true
    this.onStopTyping()
  }

  //------
  // Senders endpoint

  public sendersEndpoint() {
    const database = chatStore.senders
    return new SendersEndpoint(database, this.service)
  }

}

interface FetchMessagesData {
  messages:      Message[]
  senders:       Sender[]
  nextPageToken: string | null
}

type ProcessIncomingMessagesResult =
  | {contiguous: false}
  | {contiguous: true, messages: Message[]}