import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import Toast from 'react-toast'
import { Module } from '~/models'
import { projectStore } from '~/stores'
import ProjectLabel from '~/ui/app/projects/ProjectLabel'
import { observer } from '~/ui/component'
import { HBox, KebabMenu, Label, PopupMenuItem, Spinner, SVG, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { colors, createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { Params as PlannerParams } from '../PlannerScreen'

export interface Props {
  moduleID:         string | null
  kebabMenuItems?:  PopupMenuItem[]

  caption?:     string
  interactive?: boolean
  small?:       boolean
  classNames?:  React.ClassNamesProp
}

const ModuleBar = observer('ModuleBar', (props: Props) => {

  const {moduleID, caption: props_caption, kebabMenuItems, interactive = true, small = false} = props

  const [module, {fetchStatus}] = useModelDocumentData(Module, moduleID, {
    detail:  false,
    include: ['project'],
    label:   'linked',
  })
  const showSpinner  = module == null && fetchStatus === 'fetching'
  const showNotFound = module == null && fetchStatus === 'done'

  const currentProjectID = projectStore.projectID
  const isOwnProject     = module?.project === currentProjectID
  const showProject      = currentProjectID != null && module != null && !isOwnProject

  const icon = module?.plannerType === 'calendar' ? 'calendar' : 'puzzle'

  const [t] = useTranslation('flow_planner')

  const history = useHistory()
  const {params} = useRouteMatch<PlannerParams>()

  const goToModule = React.useCallback(() => {
    const prefix = params.modules == null ? '/' : `/${params.modules}/`
    history.push(`/planner${prefix}${moduleID}`)
  }, [history, moduleID, params.modules])

  const showOtherProjectNotice = React.useCallback(() => {
    Toast.show({
      type: 'error',
      ...t('module.other-project'),
    })
  }, [t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <HBox
          flex='grow'
          justify='center'
          classNames={[$.moduleBar, {small, interactive}]}
          gap={layout.padding.inline.l}
          onDoubleClick={isOwnProject ? goToModule : showOtherProjectNotice}
        >
          <SVG name={icon} size={layout.icon.m}/>
          {showSpinner ? (
            <HBox flex>
              <Spinner size={12}/>
            </HBox>
          ) : showNotFound ? (
            <Label flex h3>
              {t('not_found')}
            </Label>
          ) : (
            renderMain()
          )}

          {renderKebabMenu()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderMain() {
    const caption = props_caption ?? module?.$caption
    if (caption == null) { return null }

    return (
      <VBox flex>
        <Label h3>
          {caption}
        </Label>
        {renderProject()}
      </VBox>
    )
  }

  function renderProject() {
    if (!showProject) { return null }
    return (
      <ProjectLabel projectID={module.project} tiny dim/>
    )
  }

  function renderKebabMenu() {
    if (kebabMenuItems == null || kebabMenuItems.length === 0) { return null }
    if (!interactive) { return null }

    return (
      <KebabMenu
        items={kebabMenuItems}
        small
      />
    )
  }

  return render()

})

export const moduleBarHeight = {
  small:  layout.barHeight.s,
  normal: layout.barHeight.m,
}
export const moduleBarBorderRadius = {
  small:  moduleBarHeight.small / 2,
  normal: moduleBarHeight.normal / 2,
}

const useStyles = createUseStyles(theme => ({
  moduleBar: {
    '&:not(.small)': {
      height:       moduleBarHeight.normal,
      borderRadius: moduleBarBorderRadius.normal,
    },
    '&.small': {
      height:       moduleBarHeight.small,
      borderRadius: moduleBarBorderRadius.small,
    },

    backgroundImage: colors.linearGradient(
      'bottom left',
      theme.semantic.primary,
      theme.semantic.secondary,
    ),
    boxShadow:    shadows.depth(1),
    padding:      [layout.padding.inline.m, layout.padding.inline.xl],
    paddingRight: layout.padding.inline.m,
    whiteSpace:   'nowrap',
  },
}))

export default ModuleBar