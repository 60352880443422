import React from 'react'
import { LocalizedString, Page } from '~/models'
import { RichTextBodyEditor } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { ConfirmBox, PushButton, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { usePagesContext } from './PagesContext'

export interface Props {
  page: Page

  editing: boolean
  requestStartEdit: () => any
  requestStopEdit:  () => any
}

const PageCaptionEditor = memo('PageCaptionEditor', (props: Props) => {

  const {
    page,
    editing,
    requestStartEdit,
    requestStopEdit,
  } = props

  const {updatePage} = usePagesContext()
  const {actionCaption, actionConfirm} = useResourceTranslation('pages')

  const commit = React.useCallback((caption: string | LocalizedString | null) => {
    updatePage(page.id, {caption})
    requestStopEdit()
  }, [page.id, requestStopEdit, updatePage])

  const removeCaption = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove_caption'),
    })
    if (!confirmed) { return }

    await updatePage(page.id, {caption: null})
  }, [actionConfirm, page.id, updatePage])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (page.caption == null && !editing) {
      return renderAddCaptionButton()
    } else {
      return (
        <RichTextBodyEditor
          classNames={[$.editor, {editing}]}
          value={page.caption}
          onCommit={commit}
          localized

          editing={editing}
          requestStartEdit={requestStartEdit}
          requestStopEdit={requestStopEdit}
          flex={editing ? 1 : false}
          editButton={{caption: actionCaption('edit_caption')}}
          extraButtons={renderRemoveCaptionButton()}
        />
      )
    }
  }

  function renderAddCaptionButton() {
    return (
      <VBox align='center' classNames={$.addCaptionButton}>
        <PushButton
          icon='plus'
          caption={actionCaption('add_caption')}
          onTap={requestStartEdit}
          hanging
          small
        />
      </VBox>
    )
  }

  function renderRemoveCaptionButton() {
    return (
      <PushButton
        icon='cross-circle'
        onTap={removeCaption}
        hanging
        small
      />
    )
  }

  return render()

})

export default PageCaptionEditor

const useStyles = createUseStyles({
  editor: {
    '&.editing': {
      position: 'static',

      ...layout.responsive(size => ({
        marginBottom: layout.padding.s[size],
      })),
    },
  },

  addCaptionButton: {
    ...layout.responsive(size => ({
      padding:    layout.padding.s[size],
      paddingTop: 0,
      marginTop:  -1,
    })),
  },
})