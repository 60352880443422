import React from 'react'
import { Group, Model, Participant } from '~/models'
import { ModelEndpoint } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, TimeAgoLabel } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, ResourceList } from '~/ui/resources/collection'
import { ResourceChipRow } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  endpoint:     ModelEndpoint<Participant>
  parentModel?: Model
  bulkActions?: BulkAction[]
  useLocation?: boolean
}

const ParticipantList = memo('ParticipantList', (props: Props) => {

  const {endpoint, parentModel, useLocation, bulkActions} = props
  const {t} = useResourceTranslation('participants')

  //------
  // Rendering

  function render() {
    return (
      <ResourceList
        endpoint={endpoint}
        bulkActions={bulkActions}
        parentModel={parentModel}
        useLocation={useLocation}
        allowRemove={false}
      >
        <DataGrid.Column<Participant>
          name='name'
          sort='firstName'
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column
          name='groups'
          renderCell={renderGroups}
          flex={2}
        />
        <DataGrid.Column
          name='lastLogin'
          sort='lastLoginAt'
          renderCell={renderLastLogin}
          flex={1}
        />
      </ResourceList>
    )
  }

  function renderName(participant: Participant) {
    return (
      <HBox gap={layout.padding.inline.l}>
        <Avatar
          source={participant.photoURL}
          {...participant}
          size={avatarSize}
        />
        <Label flex h3>
          {participant.name}
        </Label>
      </HBox>
    )
  }

  function renderGroups(participant: Participant) {
    return (
      <ResourceChipRow
        Model={Group}
        ids={participant.groups}
      />
    )
  }

  function renderLastLogin(participant: Participant) {
    if (participant.lastLoginAt == null) {
      return (
        <Label light dim italic>
          {t('last_login.never')}
        </Label>
      )
    } else {
      return (
        <TimeAgoLabel
          datetime={participant.lastLoginAt}
        />
      )
    }

  }

  return render()

})

export const avatarSize = {
  width:  40,
  height: 40,
}

export default ParticipantList