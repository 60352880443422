import React from 'react'
import { useTranslation } from 'react-i18next'
import { useClipboardQuery } from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { Condition, PlanSegue } from '~/models'
import { memo } from '~/ui/component'
import { PopupMenu, PopupMenuHeader, PopupMenuItem } from '~/ui/components'
import { useStyling } from '~/ui/styling'
import { useSelection } from '../canvas/SelectionContext'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any

  segue:       PlanSegue
  clientPoint: Point
  menu?:       'outlet' | 'root'
}

const SeguePopupMenu = memo('SeguePopupMenu', (props: Props) => {

  const {open, requestClose, segue, clientPoint, menu = 'root'} = props

  const {planner, plan} = useFlowPlanner()
  const {manager}       = useSelection()

  const fromNode = plan?.findNode(segue.from.node)

  const [clipboardQuery, clipboardItem] = useClipboardQuery<Condition[]>(ClipboardType.CONDITIONS)

  const [t]      = useTranslation('flow_planner')
  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <PopupMenu
        open={open}
        requestClose={requestClose}
        getTargetRect={getTargetRect}
        items={menu === 'root' ? items : outletItems}
        header={renderHeader()}
      />
    )
  }

  function renderHeader() {
    return (
      <PopupMenuHeader
        icon={menu === 'root' ? 'connect' : 'node-exit'}
        caption={t(`segues.popup_menu.${menu}`)}
      />
    )
  }

  const redirect = React.useCallback(() => {
    manager?.selectOnly(segue.uuid)
  }, [manager, segue.uuid])

  const editTargeting = React.useCallback(() => {
    planner.editSegueDetail(segue, 'targeting')
  }, [planner, segue])

  const editConditions = React.useCallback(() => {
    planner.editSegueDetail(segue, 'conditions')
  }, [planner, segue])

  const pasteConditions = React.useCallback(() => {
    const conditions = clipboardQuery.getData()
    if (conditions == null) { return null }

    return planner.updateSegue(segue.uuid, segue => ({...segue, conditions}))
  }, [clipboardQuery, planner, segue.uuid])

  const editDelay = React.useCallback(() => {
    planner.editSegueDetail(segue, 'delay')
  }, [planner, segue])

  const remove = React.useCallback(() => {
    planner.removeComponents([segue.uuid])
  }, [planner, segue.uuid])

  const chooseExit = React.useCallback((outlet: string | null) => {
    return planner.updateSegue(segue.uuid, segue => ({
      ...segue,
      outlet,
    }))
  }, [planner, segue.uuid])

  const outletItems = React.useMemo((): PopupMenuItem<string | null>[] => {
    if (fromNode == null) { return [] }

    const outlets = planner.outletsForNode(fromNode)
    if (outlets == null || outlets.length === 0) { return [] }
    if (outlets.length === 1 && outlets[0] === null) { return [] }

    return outlets.map((outlet): PopupMenuItem => ({
      value:    outlet,
      caption:  outlet == null ? 'null' : outlet,
      mono:     true,
      checked:  outlet === segue.outlet,
      onSelect: chooseExit.bind(null, outlet),
    }))
  }, [chooseExit, fromNode, planner, segue.outlet])

  const items = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    if (outletItems.length > 0) {
      items.push({
        icon:    'node-exit',
        caption: t('segues.actions.outlet'),
        children: outletItems,
      })
    }

    items.push({
      icon:     'target',
      caption:  t('segues.actions.edit_targeting'),
      onSelect: editTargeting,
    })

    items.push({
      icon:     'if',
      caption:  t('segues.actions.edit_conditions'),
      onSelect: editConditions,
    })

    if (clipboardItem != null) {
      items.push({
        icon:     'paste',
        caption:  t('segues.actions.paste_conditions'),
        onSelect: pasteConditions,
      })
    }

    if (segue.delay == null) {
      items.push({
        icon:     'clock',
        caption:  t('segues.actions.add_delay'),
        onSelect: editDelay,
      })
    } else if (segue.delay.reminder) {
      items.push({
        icon:     'clock',
        caption:  t('segues.actions.edit_reminder'),
        onSelect: editDelay,
      })
    } else {
      items.push({
        icon:     'clock',
        caption:  t('segues.actions.edit_delay'),
        onSelect: editDelay,
      })
    }

    items.push({
      section: '-',
    }, {
      icon:     'connect',
      caption:  t('segues.actions.redirect'),
      onSelect: redirect,
    }, {
      icon:     'trash',
      caption:  t('segues.actions.remove'),
      onSelect: remove,
      color:    colors.semantic.negative,
    })

    return items
  }, [clipboardItem, colors.semantic.negative, editConditions, editDelay, editTargeting, outletItems, pasteConditions, redirect, remove, segue.delay, t])

  const getTargetRect = React.useCallback((): LayoutRect => {
    return {
      left:   clientPoint.x,
      top:    clientPoint.y,
      width:  1,
      height: 1,
    }
  }, [clientPoint])

  return render()

})

export default SeguePopupMenu