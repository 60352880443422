export function uppercase() {
  return (value: any, lng: string | undefined) => {
    if (typeof value !== 'string') { return value }

    return value.toLocaleUpperCase(lng)
  }
}

export function lowercase() {
  return (value: any, lng: string | undefined) => {
    if (typeof value !== 'string') { return value }

    return value.toLocaleLowerCase(lng)
  }
}