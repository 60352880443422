import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Review } from '~/models'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ConfirmBox,
  Empty,
  HBox,
  Panel,
  PushButton,
  Scroller,
  Spinner,
  VBox,
} from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout } from '~/ui/styling'
import { useReviewQuestion } from '../hooks'
import ReviewActionsForm from './ReviewActionsForm'
import ReviewParticipant from './ReviewParticipant'
import ReviewStatusSummary from './ReviewStatusSummary'
import ReviewSummaryPanel from './ReviewSummaryPanel'

export type Props = RouteComponentProps<ResourceDetailParams>

const ReviewScreen = observer('ReviewScreen', (props: Props) => {

  const {t, actionCaption, actionConfirm} = useResourceTranslation('reviews')

  const [review, {document}]    = useModelDocumentData(Review, props.match.params.id)
  const [question, fetchStatus] = useReviewQuestion(review)

  const currentUserID   = authenticationStore.userID
  const userIsReviewing = review?.status === 'review' && review?.reviewer === currentUserID

  //------
  // Callbacks

  const startReview = React.useCallback(async () => {
    await document?.callAction('start')
  }, [document])

  const reopenReview = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('reopen'),
    })
    if (!confirmed) { return }
    await document?.callAction('reopen')
  }, [actionConfirm, document])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<Review>
        Model={Review}
        include={['question', 'participant']}
        id={props.match.params.id}

        renderCaption={() => question?.text}
        renderInfo={renderInfo}
        renderBody={renderBody}
        renderActions={renderActions}
      />
    )
  }

  function renderInfo(review: Review) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: review.createdAt?.toFormat('DDD, HH:mm')})}
        </SummaryInfo.Item>
        {review.status !== 'review' && (
          <SummaryInfo.Item markup>
            {t(`statuses.${review.status}.summary`)}
          </SummaryInfo.Item>
        )}
      </SummaryInfo>
    )
  }

  function renderBody(review: Review) {
    if (question == null) {
      return renderEmpty()
    } else {
      return (
        <Scroller contentPadding={2}>
          <VBox flex gap={layout.padding.l}>
            {renderHeader()}
          </VBox>
        </Scroller>
      )
    }
  }

  function renderEmpty() {
    if (fetchStatus === 'fetching') {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }
  }

  function renderHeader() {
    if (review == null || question == null) { return null }
    return (
      <HBox align='top' gap={layout.padding.l}>
        <VBox flex style={{maxWidth: 560}}>
          <ReviewSummaryPanel
            review={review}
            question={question}
          />
        </VBox>
        <VBox align='center' style={{minWidth: 160}}>
          <ReviewParticipant
            review={review}
          />
        </VBox>
      </HBox>
    )
  }

  function renderActions(review: Review) {
    return (
      <VBox flex>
        {renderReview()}
      </VBox>
    )
  }

  function renderReview() {
    if (review == null) { return null }

    if (userIsReviewing) {
      return (
        <HBox align='stretch' flex gap={layout.padding.l}>
          {renderReviewActions()}
          {renderChat()}
        </HBox>
      )
    } else {
      return (
        <Panel>
          <Empty
            title={t(`statuses.${review.status}.caption`)}
            detail={t(`statuses.${review.status}.summary`)}
            children={renderEmptyButton()}
          />
        </Panel>
      )
    }
  }

  function renderReviewActions() {
    if (review == null) { return null }

    return (
      <VBox flex gap={layout.padding.m}>
        <ReviewActionsForm
          review={review}
        />
        <ReviewStatusSummary
          review={review} />
      </VBox>
    )
  }

  function renderChat() {
    return null
  }

  function renderEmptyButton() {
    if (review?.status === 'pending') {
      return renderStartButton()
    } else if (review?.status === 'reviewed') {
      return renderReopenButton()
    } else {
      return null
    }
  }

  function renderStartButton() {
    return (
      <PushButton
        caption={actionCaption('start')}
        onTap={startReview}
      />
    )
  }

  function renderReopenButton() {
    return (
      <PushButton
        caption={actionCaption('reopen')}
        onTap={reopenReview}
      />
    )
  }

  return render()

})

export default ReviewScreen