import React from 'react'
import { createOptimizedContext } from 'react-optimized-context'
import { clamp, merge } from 'lodash'
import { hasBitmask, rectsIntersect } from 'ytil'
import config from '~/config'
import { FlowPlan } from '~/models'
import { useBoolean, useContinuousRef, useViewState } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import { CanvasMode, CanvasViewport } from './types'

export interface FlowPlannerCanvasContext {
  mode:           CanvasMode
  viewport:       CanvasViewport
  insightsShown:  boolean
}

export interface FlowPlannerCanvasContextOptimized {
  getMode:        () => CanvasMode
  setMode:        (mode: CanvasMode) => void
  toggleInsights: () => any

  setCanvasRect: (rect: LayoutRect) => void

  setViewport: (viewport: Partial<CanvasViewport>) => void
  getViewport: () => CanvasViewport

  pointToScreen: (point: Point) => Point
  pointToCanvas: (point: Point, roundToGrid?: boolean) => Point

  resetCanvas: (options?: ResetOptions) => void
  fitCanvas:   (options?: ResetOptions) => void

  zoomIn:  () => void
  zoomOut: () => void
}

export const FlowPlannerCanvasContext = createOptimizedContext<FlowPlannerCanvasContext, FlowPlannerCanvasContextOptimized>({
  mode:          'select',
  getMode:       () => 'select',
  setMode:       () => void 0,

  insightsShown:  true,
  toggleInsights: () => void 0,

  viewport:    CanvasViewport.default,
  getViewport: () => CanvasViewport.default,
  setViewport: () => void 0,

  setCanvasRect: () => void 0,

  pointToScreen:   point => point,
  pointToCanvas:   point => point,

  resetCanvas: () => void 0,
  fitCanvas:   () => void 0,

  zoomIn:  () => void 0,
  zoomOut: () => void 0,
})

export interface ResetOptions {
  if?:       ResetCanvasIf
  animated?: boolean
}

export enum ResetCanvasIf {
  DEFAULT       = 0x1,
  OUT_OF_BOUNDS = 0x2,
}

export const useCanvas = FlowPlannerCanvasContext.useHook

export interface FlowPlannerCanvasContextProviderProps {
  plan:      FlowPlan | null
  children?: React.ReactNode
}

export function FlowPlannerCanvasContextProvider(props: FlowPlannerCanvasContextProviderProps) {
  const {children} = props

  const [mode, setMode] = React.useState<CanvasMode>('select')
  const modeRef              = useContinuousRef(mode)

  const [storedViewport, setStoredViewportState] = useViewState<CanvasViewport | null>(VIEWSTATE_KEY, null)
  const [canvasRect, setCanvasRect]              = React.useState<LayoutRect>({width: 0, height: 0, left: 0, top: 0})

  const [insightsShown, , , toggleInsights] = useBoolean(true)

  const setStoredViewport = React.useCallback((viewport: CanvasViewport) => {
    setStoredViewportState({
      zoom: clamp(viewport.zoom, config.planner.minZoom, config.planner.maxZoom),
      origin: {
        x: Math.round(viewport.origin.x),
        y: Math.round(viewport.origin.y),
      },
    })
  }, [setStoredViewportState])

  const viewport = storedViewport ?? CanvasViewport.default
  const {planner} = useFlowPlanner()

  //------
  // Context

  const context = React.useMemo((): FlowPlannerCanvasContext => ({
    mode,
    viewport,
    insightsShown,
  }), [insightsShown, mode, viewport])

  // Use a different context for the layout, to prevent having to update a whole bunch of components who only depend
  // on the *current* viewport settings when these methods are invoked. There's no need to update them the moment the
  // viewport changes.

  const currentCanvasRectRef = useContinuousRef(canvasRect)
  const viewportRef          = useContinuousRef(viewport)
  const storedViewportRef    = useContinuousRef(storedViewport)

  // This is the screen point of the canvas origin. As we zoom from the center, there's a bit of calculation
  // involved.
  const getViewportOriginPoint = React.useCallback(() => {
    const canvasRect = currentCanvasRectRef.current
    const viewport   = viewportRef.current

    return {
      x: (viewport.origin.x * viewport.zoom) + (1 - viewport.zoom) * canvasRect.width / 2 + canvasRect.left,
      y: (viewport.origin.y * viewport.zoom) + (1 - viewport.zoom) * canvasRect.height / 2 + canvasRect.top,
    }
  }, [currentCanvasRectRef, viewportRef])

  const pointToScreen = React.useCallback((point: Point) => {
    const viewport    = viewportRef.current
    const originPoint = getViewportOriginPoint()

    return {
      x: (point.x * viewport.zoom) + originPoint.x,
      y: (point.y * viewport.zoom) + originPoint.y,
    }
  }, [viewportRef, getViewportOriginPoint])

  const pointToCanvas = React.useCallback((point: Point, roundToGrid: boolean = true) => {
    const viewport    = viewportRef.current
    const originPoint = getViewportOriginPoint()

    const gridSize = roundToGrid ? config.planner.gridSize : 1
    return {
      x: Math.round((point.x - originPoint.x) / viewport.zoom / gridSize) * gridSize,
      y: Math.round((point.y - originPoint.y) / viewport.zoom / gridSize) * gridSize,
    }
  }, [viewportRef, getViewportOriginPoint])

  /**
   * Gets the viewport bounds in canvas coordinates.
   */
  const getViewportBounds = React.useCallback(() => {
    const topLeft = pointToCanvas({
      x: currentCanvasRectRef.current.left,
      y: currentCanvasRectRef.current.top,
    }, false)
    const bottomRight = pointToCanvas({
      x: currentCanvasRectRef.current.left + currentCanvasRectRef.current.width,
      y: currentCanvasRectRef.current.top + currentCanvasRectRef.current.height,
    }, false)

    return {
      left:   topLeft.x,
      top:    topLeft.y,
      width:  bottomRight.x - topLeft.x,
      height: bottomRight.y - topLeft.y,
    }
  }, [currentCanvasRectRef, pointToCanvas])

  const isOutOfBounds = React.useCallback(() => {
    const planBounds = planner.boundingRectangle
    if (planBounds == null) { return false }

    const viewportBounds = getViewportBounds()
    return !rectsIntersect(planBounds, viewportBounds)
  }, [getViewportBounds, planner])

  const shouldResetCanvas = React.useCallback((options: ResetOptions) => {
    if (options.if == null) { return true }

    if (hasBitmask(options.if, ResetCanvasIf.DEFAULT) && storedViewportRef.current == null) { return true }
    if (hasBitmask(options.if, ResetCanvasIf.OUT_OF_BOUNDS) && isOutOfBounds()) { return true }

    return false
  }, [isOutOfBounds, storedViewportRef])

  const getMode = React.useCallback(() => {
    return modeRef.current
  }, [modeRef])

  const getViewport = React.useCallback(() => {
    return viewportRef.current
  }, [viewportRef])

  const setViewport = React.useCallback((update: DeepPartial<CanvasViewport>) => {
    setStoredViewport(merge({}, viewportRef.current, update))
  }, [setStoredViewport, viewportRef])

  const resetCanvas = React.useCallback((options: ResetOptions = {}) => {
    if (!shouldResetCanvas(options)) { return }
    setStoredViewport(CanvasViewport.default)
  }, [setStoredViewport, shouldResetCanvas])

  const fitCanvas = React.useCallback((options: ResetOptions = {}) => {
    if (!shouldResetCanvas(options)) { return }

    const bounds   = planner.boundingRectangle
    const topLeftX = bounds == null ? 0 : bounds.left - layout.padding.m.desktop
    const topLeftY = bounds == null ? 0 : bounds.top - layout.padding.l.desktop

    setStoredViewport({
      origin: {
        x: -topLeftX,
        y: -topLeftY,
      },
      zoom: 1,
    })
  }, [planner, setStoredViewport, shouldResetCanvas])

  const zoomIn = React.useCallback(() => {
    const zoom = clamp(viewportRef.current.zoom * 1.2, config.planner.minZoom, config.planner.maxZoom)
    setStoredViewport({...viewportRef.current, zoom})
  }, [setStoredViewport, viewportRef])

  const zoomOut = React.useCallback(() => {
    const zoom = clamp(viewportRef.current.zoom / 1.2, config.planner.minZoom, config.planner.maxZoom)
    setStoredViewport({...viewportRef.current, zoom})
  }, [setStoredViewport, viewportRef])

  const optimizedContext = React.useMemo((): FlowPlannerCanvasContextOptimized => ({
    toggleInsights,

    setMode,
    getMode,

    pointToScreen,
    pointToCanvas,

    getViewport,
    setViewport,
    setCanvasRect,

    resetCanvas,
    fitCanvas,

    zoomIn,
    zoomOut,
  }), [toggleInsights, getMode, pointToScreen, pointToCanvas, getViewport, setViewport, resetCanvas, fitCanvas, zoomIn, zoomOut])

  //------
  // Render

  return (
    <FlowPlannerCanvasContext.Provider value={context} optimizedValue={optimizedContext}>
      {children}
    </FlowPlannerCanvasContext.Provider>
  )
}

const VIEWSTATE_KEY = 'planner.canvas.viewport'