import React from 'react'
import { memo } from '~/ui/component'
import { ClearButton, HBox } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { useCalendarPlanner } from './CalendarPlannerContext'

const CalendarPlannerControls = memo('CalendarPlannerControls', () => {

  const {mode, setMode} = useCalendarPlanner()

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <HBox
          classNames={$.canvasControls}
          gap={layout.padding.inline.m}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
        >
          {renderButtons()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='pointer'
          onTap={setSelectMode}
          color={mode === 'select' ? colors.semantic.secondary : undefined}
        />
        <ClearButton
          icon='move-ns'
          onTap={setPanMode}
          color={mode === 'pan' ? colors.semantic.secondary : undefined}
        />
        <ClearButton
          icon='create'
          onTap={setCreateMode}
          color={mode === 'create' ? colors.semantic.secondary : undefined}
        />
      </HBox>
    )
  }

  const setSelectMode = React.useCallback(() => {
    setMode('select')
  }, [setMode])

  const setPanMode = React.useCallback(() => {
    setMode('pan')
  }, [setMode])

  const setCreateMode = React.useCallback(() => {
    setMode('create')
  }, [setMode])

  const stopPropagation = React.useCallback((event: React.SyntheticEvent) => {
    event.nativeEvent.stopPropagation()
  }, [])

  return render()

})

export default CalendarPlannerControls

export const height = layout.barHeight.s

const useStyles = createUseStyles({
  canvasControls: {
    background:   colors.shim.light,
    height:       height,
    borderRadius: height / 2,
    padding:      [layout.padding.inline.s, layout.padding.inline.m + layout.padding.inline.m],
  },

  zoomSlider: {
    width: 120,
  },
})