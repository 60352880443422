import React from 'react'
import { getClientPoint, isRightMouse } from 'react-dnd'
import { memo } from '~/ui/component'
import { SVG } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'
import { useCanvas } from './FlowPlannerCanvasContext'
import { useSelection } from './SelectionContext'

export interface Props {
  transform:              string
  requestCreateComponent: ((point: Point) => any) | null
  suspendCreatePoint:     boolean
}

const InteractionLayer = memo('InteractionLayer', (props: Props) => {

  const {transform, requestCreateComponent, suspendCreatePoint} = props

  const {mode, pointToCanvas} = useCanvas.unoptim()
  const {selectedUUIDs, selectionBounds, getSelectedUUIDs, manager}  = useSelection.unoptim()

  const [createComponentPoint, setCreateComponentPoint] = React.useState<Point | null>(null)
  const [visibleCreateComponentPoint, setVisibleCreateComponentPoint] = React.useState<Point | null>(null)

  React.useEffect(() => {
    if (!suspendCreatePoint) {
      setVisibleCreateComponentPoint(createComponentPoint)
    }
  }, [createComponentPoint, suspendCreatePoint])

  const createIconStyle = React.useMemo((): React.CSSProperties => {
    if (mode !== 'select' || visibleCreateComponentPoint == null) {
      return {
        visibility: 'hidden',
      }
    }

    return {
      left: visibleCreateComponentPoint.x - createIconSize.width / 2,
      top:  visibleCreateComponentPoint.y - createIconSize.height / 2,
    }
  }, [visibleCreateComponentPoint, mode])

  const {colors} = useStyling()

  //------
  // Handlers

  const startPointRef = React.useRef<Point | null>(null)
  const dragActiveRef = React.useRef<boolean>(false)

  const calculateMouseDelta = React.useCallback((point: Point) => {
    if (startPointRef.current == null) { return null }
    return Math.max(
      Math.abs(point.x - startPointRef.current.x),
      Math.abs(point.y - startPointRef.current.y),
    )
  }, [])

  const handleMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const delta = calculateMouseDelta(clientPoint)
    if (delta != null && delta > 4) {
      dragActiveRef.current = true
      const canvasPoint = pointToCanvas(clientPoint, false)
      manager?.extendSelectionBounds(canvasPoint)
    }

    if (event.cancelable) {
      event.preventDefault()
    }
  }, [calculateMouseDelta, manager, pointToCanvas])

  const handleEnd = React.useCallback(() => {
    if (dragActiveRef.current) {
      manager?.clearSelectionBounds()
      dragActiveRef.current = false
    } else if (getSelectedUUIDs().length > 0) {
      manager?.deselectAll()
    } else if (createComponentPoint != null) {
      requestCreateComponent?.(createComponentPoint)
    }

    startPointRef.current = null

    document.removeEventListener('mousemove', handleMove)
    document.removeEventListener('touchmove', handleMove)
    document.removeEventListener('mouseup', handleEnd)
    document.removeEventListener('touchend', handleEnd)
  }, [createComponentPoint, getSelectedUUIDs, handleMove, manager, requestCreateComponent])

  const handleMouseLeave = React.useCallback(() => {
    setCreateComponentPoint(null)
  }, [setCreateComponentPoint])

  const handleStart = React.useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (isRightMouse(event.nativeEvent)) { return }
    if (closest(event.target, isInteractiveElement) != null) { return }

    startPointRef.current = getClientPoint(event.nativeEvent)

    document.addEventListener('mousemove', handleMove)
    document.addEventListener('touchmove', handleMove)
    document.addEventListener('mouseup', handleEnd)
    document.addEventListener('touchend', handleEnd)
  }, [handleEnd, handleMove])

  const moveCreatePoint = React.useCallback((event: React.MouseEvent) => {
    if (dragActiveRef.current) { return }

    const clientPoint = getClientPoint(event.nativeEvent)
    if (clientPoint == null) { return }

    const canvasPoint = pointToCanvas(clientPoint)
    setCreateComponentPoint(canvasPoint)
  }, [pointToCanvas, setCreateComponentPoint])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div
        classNames={$.interactionLayer}
        onTouchStart={mode === 'select' ? handleStart : undefined}
        onMouseDown={mode === 'select' ? handleStart : undefined}
        onMouseLeave={mode === 'select' ? handleMouseLeave : undefined}
        onMouseMove={mode === 'select' ? moveCreatePoint : undefined}
      >
        <div classNames={$.layer} style={{transform}}>
          {visibleCreateComponentPoint != null && selectionBounds == null && selectedUUIDs.length === 0 && (
            <SVG
              classNames={$.createIcon}
              name='plus-circle'
              style={createIconStyle}
              color={colors.semantic.primary.alpha(0.4)}
              size={createIconSize}
            />
          )}
        </div>
      </div>
    )
  }

  return render()

})

export default InteractionLayer

const createIconSize = layout.icon.l

const useStyles = createUseStyles({
  interactionLayer: {
    ...layout.overlay,
  },

  layer: {
    position: 'absolute',
  },

  createIcon: {
    position: 'absolute',
  },
})