import { OnDemandService, StartSuccess } from 'socket.io-react'
import { Location } from '~/models'
import { Linkage, ModelEndpoint } from '~/stores'
import { ListPack } from '../data'
import { CustomListPack } from '../data/types'
import { resourceToModel } from '../data/util'
import dataStore from '../dataStore'

export default class MapService extends OnDemandService {

  public readonly locationsEndpoint = new ModelEndpoint<Location>(dataStore.db(Location), Location)

  public async start() {
    return await this.startWithEvent('map:start')
  }

  protected onStarted = (response: StartSuccess<ListPack<Location>>) => {
    const locations = response.data.data.map((raw: any) => Location.deserialize(resourceToModel(raw)))
    this.locationsEndpoint.append(locations)

    this.socket.prefix = `map:${this.uid}:`
    this.socket.addEventListener('locations:updated', this.handleLocationsUpdated)
    this.socket.addEventListener('locations:removed', this.handleLocationsRemoved)
  }

  public onStop() {
  }

  private handleLocationsUpdated = (pack: ListPack<Location>) => {
    for (const resource of pack.data) {
      const location = Location.deserialize(resourceToModel(resource))
      this.locationsEndpoint.updateOrInsert(location)
    }
  }

  private handleLocationsRemoved = (pack: CustomListPack<Linkage<'locations'>>) => {
    this.locationsEndpoint.delete({
      data: pack.data ?? [],
      meta: {},
    })
  }

}