import { isPlainObject } from 'lodash'
import { DateTime } from 'luxon'
import { safeParseFloat } from 'ytil'

export interface Variable {
  name:      string
  value:     Variant
  updatedAt: Date
}

export type Variant = null | string | number | boolean | string[] | VariantDate

export interface VariantDate {
  type:      'date'
  timestamp: number
}

export type VariantType = 'empty' | 'text' | 'number' | 'yesno' | 'list' | 'date'
export const VariantType: {all: VariantType[]} = {
  all: ['empty', 'text', 'number', 'yesno', 'list', 'date'],
}

export const Variant: {
  stringify:        (value: Variant) => string
  parse:            (raw: string, options?: ParseOptions) => Variant
  type:             (value: Variant) => VariantType
  canBeExpressedAs: (text: string, type: VariantType, options?: Omit<ParseOptions, 'type'>) => boolean

  isDate:           (value: Variant) => value is VariantDate
  fromDateTime:     (dateTime: DateTime) => VariantDate

  toDateTime:       (value: VariantDate) => DateTime
} = {
  type:             variantType,
  stringify:        stringifyVariant,
  parse:            parseVariant,
  canBeExpressedAs: variantCanBeExpressedAs,
  isDate:           variantIsDate,
  fromDateTime:     dateTime => ({type: 'date', timestamp: dateTime.toMillis()}),
  toDateTime:       variant =>  DateTime.fromMillis(variant.timestamp, {zone: 'utc'}),
}

function variantType(value: Variant): VariantType {
  if (value == null) {
    return 'empty'
  } else if (typeof value === 'string') {
    return 'text'
  } else if (typeof value === 'number') {
    return 'number'
  } else if (typeof value === 'boolean') {
    return 'yesno'
  } else if (value instanceof Array) {
    return 'list'
  } else {
    return value.type
  }
}

function stringifyVariant(value: Variant, dateFormat: string = 'yyyy-MM-dd HH:mm') {
  if (typeof value === 'string') {
    return value
  } else if (typeof value === 'number') {
    return value.toString()
  } else if (typeof value === 'boolean') {
    return value ? 'yes' : 'no'
  } else if (value instanceof Array) {
    return value.join(', ')
  } else if (Variant.isDate(value)) {
    return Variant.toDateTime(value).toFormat(dateFormat)
  } else {
    return ''
  }
}

/**
 * Tries to parse the given text to a variant. If a type is given, and the text cannot be parsed
 * to the given type, `undefined` is returned.
 *
 * @param text The text to parse.
 * @param options Parse options.
 */
function parseVariant(text: string, options: ParseOptions = {}): Variant {
  const {type} = options

  const clean = text
    .toLocaleLowerCase()
    .replace(/\s+/, ' ')
    .trim()

  switch (type) {
    case 'empty':  return null
    case 'yesno':  return parseBoolean(clean, true)
    case 'number': return parseNumber(clean, true) ?? 0
    case 'date':   return parseDate(clean, options) ?? Variant.fromDateTime(DateTime.local())
    case 'list':   return parseList(text)
    case 'text':   return text
  }

  // Parse heuristically.
  const asBoolean = parseBoolean(clean, false)
  if (asBoolean !== undefined) { return asBoolean }

  const asNumber = parseNumber(clean, false)
  if (asNumber !== undefined) { return asNumber }

  const asDate = parseDate(clean, options)
  if (asDate !== undefined) { return asDate }

  return text
}

function variantCanBeExpressedAs(text: string, type: VariantType, options: Omit<ParseOptions, 'type'> = {}) {
  const clean = text
    .toLocaleLowerCase()
    .replace(/\s+/, ' ')
    .trim()

  switch (type) {
    case 'text':   return true
    case 'number': return parseNumber(clean, false) !== undefined
    case 'yesno':  return parseBoolean(clean, false) !== undefined
    case 'date':   return parseDate(clean, options) !== undefined
    case 'list':   return true
    case 'empty':  return text === ''
  }
}

function parseBoolean(clean: string, force: true): boolean
function parseBoolean(clean: string, force: boolean): boolean | undefined
function parseBoolean(clean: string, force: boolean): boolean | undefined {
  if (clean === 'no' || clean === 'false') {
    return false
  } else if (force || clean === 'yes' || clean === 'true') {
    return true
  }
}

function parseNumber(clean: string, force: true): number
function parseNumber(clean: string, force: boolean): number | undefined
function parseNumber(clean: string, force: boolean): number | undefined {
  if (force) {
    return safeParseFloat(clean) ?? 0
  } else if (/^-?\s*[0-9]+(?:[.,][0-9]*)?$/.test(clean)) {
    return parseFloat(clean)
  }
}

function parseList(text: string): string[] {
  return text.split(',').map(t => t.trim())
}

function parseDate(clean: string, options: ParseOptions): VariantDate | undefined {
  const {dateFormats = defaultParseDateFormats} = options

  const dateTime = DateTime.fromISO(clean, {zone: 'utc'})
  if (dateTime.isValid) {
    return Variant.fromDateTime(dateTime)
  }

  for (const dateFormat of dateFormats) {
    const dateTime = DateTime.fromFormat(clean, dateFormat, {zone: 'utc'})
    if (dateTime.isValid) {
      return Variant.fromDateTime(dateTime)
    }
  }
}

function variantIsDate(variant: Variant): variant is VariantDate {
  return isPlainObject(variant) && (variant as any).type === 'date'
}

export interface ParseOptions {
  type?:        VariantType
  dateFormats?: string[]
}

export const defaultParseDateFormats = [
  'd-M-yyyy',
  'd-MM',
  'd MMM',
  'd MMMM',
  'MMM d',
  'MMMM d',
  'yyyy-M-d',
  'H:mm',
  'd-M-yyyy H:mm',
  'd-MM H:mm',
  'd MMM H:mm',
  'd MMMM H:mm',
  'MMM d H:mm',
  'MMMM d H:mm',
  'yyyy-M-d H:mm',
]