import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime, Duration } from 'luxon'
import { useClock } from '~/socket/useClock'
import { memo } from '~/ui/component'
import { HBox, SVG } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { layout } from '~/ui/styling'
import { Label, LabelProps } from './label'

export interface Props extends Omit<LabelProps, 'children'> {
  datetime: DateTime

  format?:   string
  icon?:     SVGName
  template?: string

  recentlyThreshold?: Duration
  absoluteThreshold?: Duration
}

const TimeAgoLabel = memo('TimeAgoLabel', (props: Props) => {

  const {
    datetime,
    icon,
    template,
    format            = 'dd-MM-yyyy, HH:mm',
    recentlyThreshold = Duration.fromMillis(60_000),
    absoluteThreshold = Duration.fromObject({days: 1}),
    ...rest
  } = props

  const [t] = useTranslation('time_ago_label')

  const [isAbsolute, setIsAbsolute] = React.useState<boolean>(false)

  const {currentTime} = useClock({
    active:   !isAbsolute,
    interval: 'minute',
  })

  const timeAgo = React.useMemo(
    () => currentTime == null ? null : datetime.diff(currentTime).negate(),
    [currentTime, datetime],
  )

  React.useEffect(() => {
    if (isAbsolute) { return }
    if (timeAgo == null || timeAgo > absoluteThreshold) {
      setIsAbsolute(true)
    }
  }, [absoluteThreshold, isAbsolute, timeAgo])

  const formatted = React.useMemo(() => {
    if (timeAgo == null) { return null }

    const when =
      timeAgo < recentlyThreshold ? t('recently') :
      timeAgo < absoluteThreshold ? datetime.toRelative() :
      datetime.toFormat(format)
    if (when == null || template == null) {
      return when ?? ''
    } else {
      return template.replace(/\{\{when\}\}/g, when.toLocaleLowerCase())
    }
  }, [timeAgo, recentlyThreshold, t, absoluteThreshold, datetime, format, template])

  return (
    <HBox gap={layout.padding.inline.s}>
      {icon && <SVG name={icon} size={layout.icon.xs} dim/>}
      <Label {...rest} children={formatted}/>
    </HBox>
  )

})

export default TimeAgoLabel