import { LayoutRect } from 'react-dnd'
import { clamp, pick, some } from 'lodash'
import { objectEquals, rectsIntersect } from 'ytil'
import config from '~/config'
import { Widget, WidgetAlotment } from '~/models'

export default class DashboardLayout {

  public widgets: WidgetAlotment[] = []
  public dashboardRect: LayoutRect = LayoutRect.zero()

  public getBlockSize() {
    return {
      width:  Math.max(0, (this.dashboardRect.width + dashboardGap) / config.dashboard.columns - dashboardGap),
      height: Math.max(0, (this.dashboardRect.height + dashboardGap) / config.dashboard.rows - dashboardGap),
    }
  }

  public pointToDashboardCell(point: Point): Point {
    const blockSize = this.getBlockSize()

    const left = point.x - this.dashboardRect.left
    const top  = point.y - this.dashboardRect.top

    const x = Math.floor((left + dashboardGap) / (blockSize.width + dashboardGap))
    const y = Math.floor((top + dashboardGap) / (blockSize.height + dashboardGap))

    return {
      x: clamp(x, 0, config.dashboard.columns),
      y: clamp(y, 0, config.dashboard.rows),
    }
  }

  public dashboardCellRectToRect(rect: Rect) {
    const blockSize     = this.getBlockSize()
    const dashboardRect = this.dashboardRect

    const left   = dashboardRect.left + rect.x * (blockSize.width + dashboardGap)
    const top    = dashboardRect.top + rect.y * (blockSize.height + dashboardGap)
    const width  = rect.width * (blockSize.width + dashboardGap) - dashboardGap
    const height = rect.height * (blockSize.height + dashboardGap) - dashboardGap

    return {
      x: left,
      y: top,
      width,
      height,
    }
  }

  public widgetIntersects(rect: Rect, exclude?: string) {
    const otherWidgets = this.widgets.filter(it => it.uuid !== exclude)
    return this.widgetIntersectsWith(rect, otherWidgets)
  }

  private widgetIntersectsWith(rect: Rect, widgets: WidgetAlotment[]) {
    if (rect.x < 0) { return true }
    if (rect.y < 0) { return true }
    if (rect.x + rect.width > config.dashboard.columns) { return true }
    if (rect.y + rect.height > config.dashboard.rows) { return true }

    return some(widgets, it => rectsIntersect(rect, it))
  }

  public defaultSizeForWidget(widget: Widget): Size {
    switch (widget.type) {
      case 'bar-chart':
        return {width: 6, height: 12}
      case 'chart':
        return {width: 12, height: 6}
      case 'donut':
        return {width: 4, height: 6}
      case 'gauge':
        return {width: 4, height: 3}
      case 'funnel':
        return {width: 4, height: 6}
    }
  }

  public placeWidget(widget: Widget, center: Point, alotment: WidgetAlotment | null) {
    // First try to fit the minimum size in the top left coordinate.
    const size = {...config.dashboard.minWidgetSize}
    const targetSize = this.defaultSizeForWidget(widget)

    const tryWithSize = (size: Size) => {
      for (const dx of [0, -0.5, 0.5, -1, 1]) {
        for (const dy of [0, -0.5, 0.5, -1, 1]) {
          const rect = {
            x: Math.round(center.x + dx - size.width / 2),
            y: Math.round(center.y + dy - size.height / 2),
            ...size,
          }
          if (!this.widgetIntersects(rect, alotment?.uuid)) {
            return rect
          }
        }
      }

      return null
    }

    if (alotment != null) {
      const size = pick(alotment, 'width', 'height')
      return tryWithSize(size)
    } else {
      let rect: Rect | null = null
      let horizontal: boolean = false
      while (true) {
        const prevRect = rect

        rect = tryWithSize(size)
        if (rect == null) { return prevRect }
        if (objectEquals(size, targetSize)) { return rect }

        // Grow the size to the required size. First expand one horizontal, then vertical, etc.
        if (size.width >= targetSize.width) {
          horizontal = false
        } else if (size.height >= targetSize.height) {
          horizontal = true
        } else {
          horizontal = !horizontal
        }

        if (horizontal) {
          size.width += 1
        } else {
          size.height += 1
        }
      }
    }
  }

}

export const dashboardGap = 12
export const dashboardCellPadding = 8

export interface FitWidgetOptions {
  exclude?:     string
  allowShrink?: boolean
  allowMove?:   boolean
}