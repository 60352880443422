import React from 'react'
import { useTranslation } from 'react-i18next'
import { Variable } from '~/models'
import { ParticipantField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  ModalDialog,
  PanelHeader,
  PushButton,
  TextBlock,
  VBox,
} from '~/ui/components'
import { FormSwitch, isSuccessResult, SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { layout, useStyling } from '~/ui/styling'
import ParamsList from './ParamsList'

export interface Props {
  open:          boolean
  requestClose?: () => any

  title:       string
  instruction: string

  renderSummary?:  () => React.ReactNode
  requestActivate: (participantIDs: Array<string | null>, options: ActivateOptions) => Promise<SubmitResult | undefined>
  onDidActivate?:  (who: string) => any
}

export interface ActivateOptions {
  params: AnyObject
}

const ActivateDialog = observer('ActivateDialog', (props: Props) => {

  const {
    open,
    title,
    instruction,
    requestClose,
    renderSummary,
    requestActivate,
    onDidActivate,
  } = props

  const [t] = useTranslation('flow_planner')

  const [mode, setMode] = React.useState<'individual' | 'collective' | null>(null)
  const [participantIDs, setParticipantIDs] = React.useState<string[]>([])

  const who = React.useMemo(() => {
    if (participantIDs.length === 0) {
      return t('activate_nodes.success.who.collective')
    } else {
      return t('activate_nodes.success.who.individual', {count: participantIDs.length})
    }
  }, [participantIDs.length, t])

  //------
  // Parameters

  const [parametersExpanded, , , toggleParameters] = useBoolean()
  const [parameters, setParameters] = React.useState<Variable[]>([])

  const parametersAsObject = React.useMemo(() => {
    return parameters.reduce((params, it) => ({
      ...params,
      [it.name]: it.value,
    }), {})
  }, [parameters])

  //------
  // Activation

  const activateNodes = React.useCallback(async () => {
    if (mode === 'individual' && participantIDs.length === 0) {
      return
    }

    const actualParticipantIDs = mode === 'individual' ? participantIDs : [null]
    const result = await requestActivate(actualParticipantIDs, {
      params: parametersAsObject,
    })
    if (isSuccessResult(result)) {
      onDidActivate?.(who)
      requestClose?.()
    }
  }, [mode, onDidActivate, parametersAsObject, participantIDs, requestActivate, requestClose, who])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}

        icon='flash'
        title={title}
        drawerExpanded={parametersExpanded}
        drawerSide='left'
        drawerWidth={drawerWidth}
        renderDrawer={renderDrawer}
        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m} padding={layout.padding.m} style={bodySize}>
        <TextBlock dim small>
          {instruction}
        </TextBlock>
        <HBox flex align='stretch' gap={layout.padding.m}>
          {renderSummary && (
            <HBox align='stretch'>
              {renderSummary()}
              {<Dimple vertical/>}
            </HBox>
          )}
          {renderActivationForm()}
        </HBox>
        {renderButtons()}
      </VBox>
    )
  }

  function renderActivationForm() {
    return (
      <VBox flex>
        <FormSwitch>
          <FormSwitch.Option
            {...t('activate_nodes.individual')}
            renderForm={renderIndividualForm}
            selected={mode === 'individual'}
            onTap={() => setMode('individual')}
          />
          <FormSwitch.Option
            {...t('activate_nodes.collective')}
            selected={mode === 'collective'}
            onTap={() => setMode('collective')}
          />
        </FormSwitch>
      </VBox>
    )
  }

  function renderIndividualForm() {
    return (
      <ParticipantField
        value={participantIDs}
        onChange={setParticipantIDs}
        multi
      />
    )
  }

  function renderDrawer() {
    return (
      <VBox flex>
        <PanelHeader
          caption={t('activate_nodes.parameters')}
          small
        />
        <ParamsList
          value={parameters}
          onChange={setParameters}
        />
      </VBox>
    )
  }

  const {colors} = useStyling()

  function renderButtons() {
    const activateButtonEnabled = mode === 'collective' || (mode === 'individual' && participantIDs.length > 0)

    return (
      <HBox justify='space-between' gap={layout.padding.m}>
        <HBox>
          <ClearButton
            icon='code'
            caption={t('activate_nodes.parameters')}
            backgroundColor={parametersExpanded ? colors.semantic.primary : undefined}
            onTap={toggleParameters}
            padding='both'
          />
        </HBox>
        <HBox gap={layout.padding.m}>
          <PushButton
            icon='flash'
            caption={!activateButtonEnabled ? t('activate_nodes.button.disabled') : t(`activate_nodes.button.${mode}`, {count: participantIDs.length})}
            onTap={activateNodes}
            enabled={activateButtonEnabled}
          />
          <ClearButton
            icon='cross'
            caption={t('buttons:cancel')}
            onTap={requestClose}
            dim
          />
        </HBox>
      </HBox>
    )
  }

  return render()

})

export default ActivateDialog

export const drawerWidth = 360
export const bodySize = {
  width:  640,
  height: 480,
}