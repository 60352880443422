import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { some } from 'lodash'
import { sparse } from 'ytil'
import { ParticipantDescriptor } from '~/models'
import { CalendarPlanDayInsightsSummary, PlanPositionInsightsSummary } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Badge, ConfirmBox, PopupMenu, PopupMenuItem, Tappable } from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { useResourceTranslation } from '~/ui/resources'
import { animation, createUseStyles, layout, shadows, useStyling } from '~/ui/styling'

export interface Props {
  summary:      InsightsSummary
  participants: ParticipantDescriptor[]
  align?:        'left' | 'center' | 'right'

  requestFocus:       (summary: InsightsSummary) => any
  requestAbandonAll?: () => any
}

export type InsightsSummary = PlanPositionInsightsSummary | CalendarPlanDayInsightsSummary

const InsightsBadgeRow = observer('InsightsBadgeRow', (props: Props) => {

  const {
    summary,
    participants,
    align = 'left',

    requestFocus,
    requestAbandonAll,
  } = props


  const {colors} = useStyling()
  const {actionCaption, actionConfirm} = useResourceTranslation('insights')

  const hasCollective  = some(summary.presences, it => it.participantID == null)
  const participantIDs = sparse(summary.presences.map(it => it.participantID))

  //------
  // Callbacks

  const focus = React.useCallback(() => {
    requestFocus?.(summary)
  }, [requestFocus, summary])

  const abandonAll = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('abandon_all'),
    })
    if (!confirmed) { return }

    requestAbandonAll?.()
  }, [actionConfirm, requestAbandonAll])

  const popupMenuItems = React.useMemo((): PopupMenuItem[] => sparse([{
    icon:     'eye',
    caption:  actionCaption('focus'),
    onSelect: focus,
    keyHint:  'doubleclick',
  }, requestAbandonAll != null && {
    section: '-',
  }, requestAbandonAll != null && {
    icon:     'cross-circle',
    caption:  actionCaption('abandon_all'),
    onSelect: abandonAll,
    color:    colors.semantic.negative,
  }]), [abandonAll, actionCaption, colors.semantic.negative, focus, requestAbandonAll])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <PopupMenu targetClassNames={$.InsightsBadgeRow} items={popupMenuItems}>
        {renderButton}
      </PopupMenu>
    )
  }

  function renderButton(tap: () => any) {
    return (
      <Tappable onDoubleClick={focus} onTap={tap}>
        <TransitionGroup classNames={[$.content, `align-${align}`]}>
          {hasCollective && renderCollective()}
          {participantIDs.length > 3 ? renderCount() : renderRecents()}
        </TransitionGroup>
      </Tappable>
    )
  }

  function renderCount() {
    return (
      <CSSTransition classNames={$.pop} timeout={popDuration}>
        <Badge classNames={$.badge} value={participantIDs.length}/>
      </CSSTransition>
    )
  }

  function renderRecents() {
    return [...participantIDs].reverse().map(id => {
      const participant = participants.find(it => it.id === id)
      if (participant == null) { return null }

      return (
        <CSSTransition key={id} classNames={$.pop} timeout={popDuration}>
          <Avatar
            classNames={$.badge}
            {...participant}
            source={participant.photoURL}
            size={avatarSize}
          />
        </CSSTransition>
      )
    })
  }

  function renderCollective() {
    return (
      <CSSTransition classNames={$.pop} timeout={popDuration}>
        <Badge
          icon='asterisk'
        />
      </CSSTransition>
    )
  }

  return render()

})

export default InsightsBadgeRow

export const size = badgeSize.normal

const avatarSize = {
  width:  badgeSize.normal.minWidth,
  height: badgeSize.normal.height,
}

const popDuration = animation.durations.short

const useStyles = createUseStyles({
  InsightsBadgeRow: {
    width:  0,
    height: 0,

    ...layout.flex.column,
  },

  content: {
    marginTop:   -size.height / 2,
    marginLeft:  -size.minWidth / 2,
    marginRight: -size.minWidth / 2,

    '&.align-left': {
      alignSelf: 'flex-start',
    },
    '&.align-center': {
      alignSelf: 'center',
    },
    '&.align-right': {
      alignSelf: 'flex-end',
    },

    ...layout.row(-badgeSize.normal.minWidth * 2/3),
  },

  pop: {
    ...animation.pop(popDuration),
  },

  badge: {
    position:   'relative',
    willChange: 'box-shadow',
    transition: animation.transitions.short('box-shadow'),

    boxShadow: shadows.depth(2),
    '$InsightsBadgeRow:hover &': {
      boxShadow: shadows.depth(3, {color: shadows.shadowColor.alpha(0.6)}),
    },
  },
})