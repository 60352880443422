import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { ClientApp, Organisation, Project, Ref } from '~/models'
import ProjectList from '~/ui/app/projects/ProjectList'
import { observer } from '~/ui/component'
import { HBox, Label, Spinner, SVG, VBox } from '~/ui/components'
import { InlineFormField } from '~/ui/form'
import { useModelDocumentData, useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { InlineResourceForm } from '~/ui/resources/form'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout } from '~/ui/styling'

export type Props = RouteComponentProps<ResourceDetailParams>

const OrganisationScreen = observer('OrganisationScreen', (props: Props) => {

  const {t} = useResourceTranslation('organisations')

  const projectsEndpoint = useModelEndpoint(Project, {
    filters: {organisation: props.match.params.id},
  })

  const organisationID = props.match.params.id
  const [organisation] = useModelDocumentData(Organisation, organisationID, {fetch: 'never'})
  const [app, {fetchStatus: defaultAppFetchStatus}] = useModelDocumentData(ClientApp, organisation?.defaultApp ?? null)

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={Organisation}
        id={props.match.params.id}
        include={['projects']}

        renderInfo={renderInfo}
        renderBody={renderBody}
        renderSummaryFooter={renderDefaultAppField}
        removeConfirmText={organisation => organisation.name}
      />
    )
  }

  //------
  // Body

  function renderBody() {
    return (
      <VBox flex gap={layout.padding.m}>
        <ProjectList
          endpoint={projectsEndpoint}
        />
      </VBox>
    )
  }

  //------
  // Right bar

  function renderInfo(organisation: Organisation) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: organisation.createdAt})}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  function renderDefaultAppField() {
    return (
      <InlineResourceForm Model={Organisation} model={organisation} autoSubmit>
        <InlineFormField<Ref<ClientApp> | null>
          name='defaultApp'
          renderView={renderDefaultApp}
          renderEdit={bind => <ResourceField Model={ClientApp} {...bind}/>}
          clearOnEdit
        />
      </InlineResourceForm>
    )
  }

  function renderDefaultApp() {
    if (defaultAppFetchStatus === 'fetching') {
      return <Spinner size={8}/>
    } else if (app == null) {
      return null
    }

    return (
      <HBox flex gap={layout.padding.inline.s}>
        <SVG name='iphone' size={layout.icon.s}/>
        <Label flex mono box>
          {app.$caption}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default React.memo(OrganisationScreen)