import Logger from 'logger'
import { DevBuddyDevice } from './types'

const logger = new Logger('DevBuddy')

export default abstract class DevBuddy {

  public static available() {
    if (typeof window === 'undefined') { return false }
    return 'GroundControl' in window
  }

  public static async listDevices(): Promise<DevBuddyDevice[]> {
    return this.invokeNative('list_devices', {})
  }

  public static async openURLOnDevice(platform: string, udid: string, url: string): Promise<boolean> {
    return this.invokeNative('open_url_on_device', {platform, udid, url})
  }

  private static async invokeNative<R>(method: string, payload: any) {
    return new Promise<R>((resolve, reject) => {
      (window as any).GroundControl.invokeNative(method, payload, (err: Error | null, res: any) => {
        logger.debug({text: `%c${method}`, styles: [{
          display:    'inline-block',
          padding:    '4px 2px',
          background: '#ddd',
          border:     '1px solid #aaa',
        }]}, {payload, res, err})

        if (err) {
          reject(err)
        } else {
          resolve(res)
        }
      })
    })
  }

}