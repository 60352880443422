import { action, makeObservable, observable } from 'mobx'
import { Targeting, TargetingCombinator } from '~/models'
import { SubmitResult } from '~/ui/form'

export default abstract class TargetingFormModel {

  constructor(
    private readonly targeting: Targeting | null,
  ) {
    makeObservable(this)
  }

  @observable
  public groups: string[] = this.targeting?.groups ?? []

  @observable
  public combinator: TargetingCombinator = this.targeting?.combinator ?? 'any'

  @action
  public reset() {
    this.groups = this.targeting?.groups ?? []
    this.combinator = this.targeting?.combinator ?? 'any'
  }

  public clear() {
    this.groups = []
    this.combinator = 'any'
  }

  //-------
  // Submit

  public abstract submit(): Promise<SubmitResult | undefined>

  protected buildTargeting() {
    return {
      groups:     this.groups,
      combinator: this.combinator,
    }
  }

}