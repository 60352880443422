import React from 'react'
import { Module } from '~/models'
import { NavigationItem } from '~/navigation.yml'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { PopupMenu, PopupMenuItem, PopupMenuProps } from '~/ui/components'
import { registerNavigationDetailMenu } from './registry'

export interface Props extends Omit<PopupMenuProps<any>, 'items'> {
  item: NavigationItem
}

export const PlannerNavigationDetailMenu = observer('PlannerNavigationDetailMenu', (props: Props) => {
  const {item, ...rest} = props

  const allModules = dataStore.db(Module).all()

  const modules = allModules
    .filter(mod => !mod.main)
    .filter(mod => mod.plannerType != null)

  const items = modules.map((module): PopupMenuItem => ({
    icon:    module.plannerType === 'calendar' ? 'calendar' : 'puzzle',
    caption: module.name,
    href:    `/planner/${module.id}`,
  }))

  return (
    <PopupMenu
      items={items}
      {...rest}
    />
  )
})

registerNavigationDetailMenu('planner', PlannerNavigationDetailMenu)