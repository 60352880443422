import React from 'react'
import { isFunction, isPlainObject } from 'lodash'
import { Checkbox, HBox, Label, Tappable } from '~/ui/components'
import { createUseStyles, layout, presets } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  value:     boolean
  onChange?: (value: boolean) => any

  label?:      CheckboxFieldLabelProp
  smallLabel?: boolean

  enabled?:  boolean
  readOnly?: boolean
}

export type CheckboxFieldLabelProp =
  | ((value: boolean | null) => React.ReactNode)
  | {on: React.ReactNode, off: React.ReactNode}
  | React.ReactNode

export default function CheckboxField(props: Props) {

  const {
    value,
    onChange,
    enabled = true,
    readOnly = false,
    label,
    smallLabel = false,
    ...rest
  } = props

  const active = value && enabled

  const resolvedLabel = React.useMemo(() => {
    if (isFunction(label)) {
      return label(value)
    } else if (isPlainObject(label) && !React.isValidElement(label)) {
      return value ? (label as any).on : (label as any).off
    } else {
      return label
    }
  }, [label, value])

  const tap = React.useCallback(() => {
    onChange?.(!value)
  }, [onChange, value])

  const $ = useStyles()

  function render() {
    return (
      <Tappable onTap={tap} enabled={enabled && !readOnly}>
        <HBox flex='shrink' justify={resolvedLabel == null ? 'center' : 'left'} gap={layout.padding.s} classNames={$.checkboxField}>
          <Checkbox
            checked={value ?? false}
            enabled={enabled && !readOnly}
            {...rest}
          />

          {renderLabel()}
        </HBox>
      </Tappable>
    )
  }

  function renderLabel() {
    if (!isReactText(resolvedLabel)) {
      return resolvedLabel
    } else {
      return (
        <Label flex='shrink' dim={!active} bold={value} truncate={false} caption={smallLabel} small={smallLabel}>
          {resolvedLabel}
        </Label>
      )
    }
  }

  return render()

}

const useStyles = createUseStyles({
  checkboxField: {
    height: presets.fieldHeight.normal,
  },
})