import React from 'react'
import { focusFirst } from 'react-autofocus'
import {
  createSortableContainer,
  createSortableElement,
  ElementConnect,
  SortableContainerChildProps,
  SortableItem,
} from 'react-dnd-sortable'
import { every, isArray, isFunction, isPlainObject, range } from 'lodash'
import { memo } from '~/ui/component'
import { Dimple, HBox, Panel } from '~/ui/components'
import { width as clearButtonWidth } from '~/ui/components/ClearButton'
import { VBox, VBoxProps } from '~/ui/components/layout'
import { FormDialogProps, FormError } from '~/ui/form'
import { useRefMap } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import CollectionFieldItemFormModel from './CollectionFieldItemFormModel'
import CollectionFieldRow from './CollectionFieldRow'
import { ItemFormProps } from './types'

export interface Props<T> {
  value:        T[]
  onChange?:    (value: T[]) => any
  childErrors?: FormError[]

  ItemFormDialogComponent?: React.ComponentType<CollectionFieldItemFormDialogProps<T>>
  itemFormModel?:           (item: T, save: (item: T) => any) => CollectionFieldItemFormModel<T>

  allowAdd?:    boolean
  allowRemove?: boolean | ((item: T) => boolean)
  minElements?: number
  maxElements?: number | null

  sortable?:       boolean
  pasteTransform?: (line: string) => T[]

  renderHeader?:    () => React.ReactNode
  renderItem?:      (item: T) => React.ReactNode
  renderForm?:      (props: ItemFormProps<T>) => React.ReactNode

  newItemTemplate?: (index: number) => T
  itemIsEmpty?:     (item: T) => boolean

  enabled?:  boolean
  readOnly?: boolean

  flex?: VBoxProps['flex']
}

export type CollectionFieldItemFormDialogProps<T> = Omit<FormDialogProps<CollectionFieldItemFormModel<T>>, 'children'>

const SortableContainer = createSortableContainer<VBoxProps, any>(VBox)
const SortableListRow   = createSortableElement(VBox)

const _CollectionField = <T extends {}>(props: Props<T>) => {

  const {
    value,
    onChange,
    ItemFormDialogComponent,
    itemFormModel,
    childErrors,
    allowAdd    = true,
    allowRemove = true,
    minElements = 0,
    maxElements = null,
    sortable    = true,
    enabled     = true,
    readOnly    = false,
    pasteTransform,
    renderHeader,
    renderItem,
    renderForm,
    newItemTemplate,
    itemIsEmpty = defaultItemIsEmpty,
    flex,
  } = props

  const mayHaveMore = maxElements == null || value.length < maxElements
  const mayHaveLess = value.length > minElements

  const mayRemove = React.useCallback((item: T) => {
    if (isFunction(allowRemove)) {
      return allowRemove(item)
    } else {
      return allowRemove
    }
  }, [allowRemove])

  const newItem = React.useMemo(
    () => newItemTemplate?.(value.length),
    [newItemTemplate, value.length],
  )

  const listID = React.useMemo(() => Symbol('CollectionField.list'), [])

  const [draggingIndex, setDraggingIndex] = React.useState<number | null>(null)
  const [hoverIndex, setHoverIndex]       = React.useState<number | null>(null)

  const startDrag = React.useCallback((index: number) => {
    setDraggingIndex(index)
  }, [])

  const endDrag = React.useCallback((index: number) => {
    if (draggingIndex === index) {
      setDraggingIndex(null)
    }
    setHoverIndex(null)
  }, [draggingIndex])

  const sortHover = React.useCallback((_, index: number) => {
    setHoverIndex(index)
  }, [])

  const getErrors = React.useCallback((index: number, key: string) => {
    return (childErrors ?? []).filter(error => {
      if (error.field == null) { return false }

      const parts = error.field.split('.', 3).slice(1)
      if (parts[0] !== index.toString()) { return false }
      if (parts[1] !== key) { return false }
      return true
    })
  }, [childErrors])

  //------
  // Modal form

  const usesModalForm = ItemFormDialogComponent != null && itemFormModel != null
  const [editingItemIndex, setEditingItemIndex] = React.useState<number>(-1)
  const [editingItem, setEditingItem] = React.useState<T | null>(null)

  const editingItemFormModel = React.useMemo(() => {
    if (editingItem == null) { return null }
    return itemFormModel?.(editingItem, data => {
      const nextValue = [...value ?? []]
      const item = data as T

      if (editingItemIndex < value.length) {
        nextValue.splice(editingItemIndex, 1, item)
      } else {
        nextValue.push(item)
      }

      onChange?.(nextValue)
    })
  }, [editingItem, editingItemIndex, itemFormModel, onChange, value])

  const editItemAt = React.useCallback((index: number) => {
    setEditingItemIndex(index)
    setEditingItem(value?.[index])
  }, [value])

  const closeItemFormDialog = React.useCallback(() => {
    setEditingItemIndex(-1)
  }, [])


  //------
  // Operations

  const addItem = React.useCallback((item: T) => {
    if (!allowAdd) { return }
    if (!mayHaveMore) { return }

    if (usesModalForm) {
      setEditingItem(item)
      setEditingItemIndex(value.length)
    } else {
      onChange?.([...value ?? [], item])
    }
  }, [allowAdd, mayHaveMore, onChange, usesModalForm, value])

  const removeItem = React.useCallback((index: number) => {
    if (!mayRemove(value[index])) { return }
    if (!mayHaveLess) { return }

    const nextValue = [...value ?? []]
    nextValue.splice(index, 1)
    onChange?.(nextValue)
  }, [mayHaveLess, mayRemove, onChange, value])

  const replaceItem = React.useCallback((index: number, item: T) => {
    if (index >= value.length && !itemIsEmpty(item)) {
      // Add a new item instead.
      addItem(item)
    } else if (index === value.length - 1 && itemIsEmpty(item)) {
      // Remove the last empty item.
      removeItem(index)
    } else if (index < value.length) {
      // Replace the item.
      const nextValue = [...value ?? []]
      nextValue.splice(index, 1, item)
      onChange?.(nextValue)
    }
  }, [addItem, itemIsEmpty, onChange, removeItem, value])

  const moveItem = React.useCallback((fromIndex: number, toIndex: number) => {
    const nextValue = [...value]
    const item = nextValue.splice(fromIndex, 1)
    nextValue.splice(toIndex, 0, ...item)
    onChange?.(nextValue)
  }, [onChange, value])

  //------
  // Navigation

  const rowRefs = useRefMap<number, HTMLDivElement>()

  const focusRow = React.useCallback((index: number) => {
    const row = rowRefs.get(index)
    if (row == null) { return false }

    focusFirst(row, {select: true})
    return true
  }, [rowRefs])

  const commitRow = React.useCallback((index: number) => {
    return focusRow(index + 1)
  }, [focusRow])

  //------
  // Clipboard

  const pasteLines = React.useCallback((index: number, lines: string[]) => {
    const nextValue    = [...value ?? []]
    const pastedValues = pasteTransform == null ? lines as any[] : lines.map(pasteTransform)
    nextValue.splice(index, 0, ...pastedValues)
    onChange?.(nextValue)

    setImmediate(() => {
      focusRow(index + lines.length)
    })
  }, [focusRow, onChange, pasteTransform, value])

  //------
  // Sorting

  const handleSortDrop = React.useCallback((item: SortableItem, index: number) => {
    moveItem(item.index, index)
  }, [moveItem])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel flex={flex}>
        {renderHeaderRow()}
        {sortable ? renderSortableList() : renderList()}

        {usesModalForm && editingItemFormModel != null && (
          <ItemFormDialogComponent
            model={editingItemFormModel}
            open={editingItemIndex >= 0}
            requestClose={closeItemFormDialog}
          />
        )}
      </Panel>
    )
  }

  function renderHeaderRow() {
    if (renderHeader == null) { return null }

    let spacerWidth = 0
    if (sortable) {
      spacerWidth += layout.icon.m.width
    }
    if (allowRemove || allowAdd) {
      spacerWidth += clearButtonWidth.normal + layout.padding.inline.s
    }

    return (
      <HBox classNames={$.header} gap={layout.padding.inline.m}>
        <VBox flex>
          {renderHeader()}
        </VBox>
        <VBox
          style={{width: spacerWidth}}
        />
      </HBox>
    )
  }

  function renderList() {
    return (
      <VBox classNames={$.list}>
        {renderListContent()}
      </VBox>
    )
  }

  function renderSortableList() {
    return (
      <SortableContainer classNames={$.list} listID={listID} onSortDrop={handleSortDrop} onSortHover={sortHover}>
        {renderSortableListContent}
      </SortableContainer>
    )
  }

  function renderSortableListContent(sortableProps: SortableContainerChildProps<T>) {
    const data = renderListContent()

    const {hoverIndex, item, connectPlaceholder, isOver, itemHeight} = sortableProps
    const placeholderIndex = hoverIndex == null ? data.length - 1 : Math.min(hoverIndex, data.length - 1)

    if (item != null) {
      data.splice(placeholderIndex, 0, (
        <React.Fragment key={Constants.placeholderKey}>
          {(hoverIndex == null || hoverIndex > 0) && <Dimple horizontal/>}
          {renderSortPlaceholder?.(connectPlaceholder, itemHeight, isOver)}
        </React.Fragment>
      ))
    }

    return data
  }

  function renderSortPlaceholder(connect: ElementConnect, height: number, isOver: boolean) {
    return (
      <div
        classNames={[$.sortPlaceholder, {active: isOver}]}
        style={{height}}
        ref={connect}
      />
    )
  }

  function renderListContent() {
    const upperBound    = allowAdd ? value.length + 1 : value.length
    const apparentIndex = (index: number) => {
      let apparentIndex = index
      if (draggingIndex != null && draggingIndex <= apparentIndex) {
        apparentIndex -= 1
      }
      if (hoverIndex != null && hoverIndex <= apparentIndex) {
        apparentIndex += 1
      }
      return apparentIndex
    }

    return range(0, upperBound).map(index => (
      <VBox key={index} style={index === draggingIndex ? {display: 'none'} : {}}>
        {apparentIndex(index) > 0 && <Dimple horizontal/>}
        {sortable ? renderSortableListRow(index) : renderListRow(index)}
      </VBox>
    ))
  }

  function renderSortableListRow(index: number) {
    const isNewItem = index >= value.length

    return (
      <SortableListRow
        enabled={!isNewItem}
        payload={isNewItem ? (null as any) : value[index]}
        index={index}
        sourceList={listID}
        itemType={Constants.itemType}

        handleSelector={`.${$.sortHandle}`}
        onStartDrag={startDrag}
        onEndDrag={endDrag}

        children={renderListRow(index)}
      />
    )
  }

  function renderListRow(index: number) {
    const isNewItem = index >= value.length
    const item      = isNewItem ? newItem! : value[index]

    return (
      <VBox classNames={$.row} ref={rowRefs.for(index)}>
        <CollectionFieldRow
          item={item}
          index={index}
          renderItem={renderItem}
          renderForm={renderForm}
          getErrors={getErrors}
          requestAdd={isNewItem ? addItem : undefined}
          requestEdit={editItemAt}
          requestReplace={replaceItem}
          requestRemove={isNewItem || !mayRemove(item) ? undefined : removeItem}
          requestFocus={focusRow}
          sortHandleClassName={sortable ? $.sortHandle : undefined}
          onCommit={commitRow}
          enabled={enabled}
          readOnly={readOnly}
          onPasteLines={pasteLines}
        />
      </VBox>
    )
  }

  return render()

}

function defaultItemIsEmpty(item: any): boolean {
  if (item == null) { return true }
  if (item === '') { return true }
  if (isArray(item)) { return item.length === 0 }
  if (isPlainObject(item)) { return every(item, defaultItemIsEmpty) }
  return false
}

const CollectionField = memo('CollectionField', _CollectionField) as typeof _CollectionField
export default CollectionField

const useStyles = createUseStyles({
  collectionField: {

  },

  header: {
    padding: [layout.padding.inline.m, layout.padding.inline.m, 0],
  },

  list: {
    padding: [layout.padding.inline.m / 2, 0],
  },

  row: {
    padding: [layout.padding.inline.m / 2, layout.padding.inline.m],
    paddingLeft: layout.padding.inline.l,
  },

  sortPlaceholder: {
  },

  sortHandle: {
    cursor: 'grab',
  },
})

const Constants = {
  placeholderKey: '$$CollectionField.placeholder',
  itemType:       '$$CollectionField.item',
}