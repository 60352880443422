import React from 'react'
import { useTranslation } from 'react-i18next'
import Color from 'color'
import I18next from 'i18next'
import { upperFirst } from 'lodash'
import { ModelWithName } from '~/models'
import { memo, observer } from '~/ui/component'
import { Center, Dimple, HBox, Label, List, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'
import { LinkType } from './LinkFormModel'

export interface Props {
  availableTypes: LinkType[]
  currentType:    LinkType
  selectType:     (type: LinkType) => any
}

const LinkFormDrawer = observer('LinkFormDrawer', (props: Props) => {

  const {
    availableTypes,
    currentType,
    selectType,
  } = props

  const [t] = useTranslation('links')
  const resourceTypes = React.useMemo(
    () => availableTypes.filter(it => !['url', 'upload'].includes(it)),
    [availableTypes],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.LinkFormDrawer}>
        <VBox flex padding={layout.padding.s} gap={layout.padding.s}>
          {renderWellKnownButtons()}
          <Dimple horizontal/>
          {renderResourcesList()}
        </VBox>
      </VBox>
    )
  }

  function renderResourcesList() {
    return (
      <VBox flex gap={layout.padding.inline.m}>
        <Label caption dim>
          {t('app_links')}
        </Label>
        <List<LinkType>
          data={resourceTypes}
          renderItem={renderResourceListItem}
          scrollable
          scrollerProps={{overflow: 2}}
        />
      </VBox>
    )
  }

  const renderResourceListItem = React.useCallback((type: LinkType) => {
    return (
      <LinkTypeButton
        type={type}
        selected={type === currentType}
        onTap={selectType}
      />
    )
  }, [currentType, selectType])

  function renderWellKnownButtons() {
    return (
      <HBox>
        {availableTypes.includes('url') && renderWellKnownButton('url')}
        {availableTypes.includes('upload') && renderWellKnownButton('upload')}
      </HBox>
    )
  }

  function renderWellKnownButton(type: LinkType) {
    return (
      <VBox flex>
        <LinkTypeButton
          type={type}
          selected={type === currentType}
          onTap={selectType}
          vertical
        />
      </VBox>
    )
  }

  return render()

})

export default LinkFormDrawer

interface LinkTypeButtonProps {
  type:      LinkType
  selected:  boolean
  onTap:     (type: LinkType) => any
  vertical?: boolean
}

const LinkTypeButton = memo('LinkTypeButton', (props: LinkTypeButtonProps) => {

  const {type, selected, onTap, vertical = false} = props

  const tap = React.useCallback(() => {
    onTap(type)
  }, [onTap, type])

  const theme = useTheme()
  const backgroundColor = selected ? theme.semantic.primary : undefined

  const $ = useStyles(backgroundColor)

  function render() {
    return (
      <ThemeProvider contrast={backgroundColor} fgNormal={selected ? undefined : t => t.fg.link}>
        <Tappable classNames={$.LinkTypeButton} showFocus onTap={tap}>
          {vertical ? renderVerticalContent() : renderHorizontalContent()}
        </Tappable>
      </ThemeProvider>
    )
  }

  function renderHorizontalContent() {
    return (
      <HBox padding={layout.padding.inline.m} gap={layout.padding.inline.m}>
        <SVG
          name={iconForLinkType(type)}
          size={layout.icon.m}
        />
        <VBox flex>
          <Label caption>
            {captionForLinkType(type)}
          </Label>
          <Label small dim truncate={false}>
            {detailForLinkType(type)}
          </Label>
        </VBox>
      </HBox>
    )
  }

  function renderVerticalContent() {
    return (
      <VBox padding={layout.padding.inline.m} gap={layout.padding.inline.m}>
        <Center>
          <SVG
            name={iconForLinkType(type)}
            size={layout.icon.l}
          />
        </Center>
        <Label caption align='center'>
          {captionForLinkType(type)}
        </Label>
      </VBox>
    )
  }

  return render()

})

function iconForLinkType(type: LinkType): SVGName {
  switch (type) {
    case 'url':    return 'link'
    case 'upload': return 'upload'
    default:       return ModelWithName(type).$icon
  }
}

function captionForLinkType(type: LinkType): string {
  if (['url', 'upload'].includes(type)) {
    return I18next.t(`links:types.${type}.caption`)
  }

  const Model = ModelWithName(type)
  return I18next.t('links:types.resource.caption', {resource: upperFirst(I18next.t(`${Model.resourceType}:singular`))})
}

function detailForLinkType(type: LinkType): string {
  if (['url', 'upload'].includes(type)) {
    return I18next.t(`links:types.${type}.detail`)
  }

  const Model = ModelWithName(type)
  return I18next.t('links:types.resource.detail', {resource: I18next.t(`${Model.resourceType}:singular`)})
}


const useStyles = createUseStyles(theme => ({
  LinkFormDrawer: {
    background: theme.bg.alt,
  },

  LinkTypeButton: (backgroundColor: Color) => ({
    backgroundColor,
    borderRadius: layout.radius.m,
  }),
}))