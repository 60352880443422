import React from 'react'
import QRCode from 'qrcode.react'
import { Participant } from '~/models'
import { observer } from '~/ui/component'
import { panelBorderRadius } from '~/ui/components'
import { SummaryPanelSection } from '~/ui/resources/detail'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  participant: Participant
}

const ParticipantQRCode = observer('ParticipantQRCode', (props: Props) => {

  const {participant} = props
  const value = participant.id

  //------
  // Rendering

  const $ = useStyles()

  function render() {

    return (
      <SummaryPanelSection align='center' classNames={$.ParticipantQRCode}>
        <QRCode
          size={qrCodeSize}
          value={value}
        />
      </SummaryPanelSection>
    )
  }


  return render()

})

export default ParticipantQRCode

export const qrCodeSize = 80

const useStyles = createUseStyles(theme => ({
  ParticipantQRCode: {
    margin:  -layout.padding.inline.l,
    padding: layout.padding.inline.l,

    background:              theme.bg.alt,
    borderBottomLeftRadius:  panelBorderRadius(theme),
    borderBottomRightRadius: panelBorderRadius(theme),
  },
}))