import React from 'react'
import { useTranslation } from 'react-i18next'
import { Trigger, TriggerNode } from '~/models'
import { width as calendarWidth } from '~/ui/components/calendar/layout'
import { FormDialog } from '~/ui/form'
import { layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import renderTriggerFields from './fields/index'
import TriggerFormModel, { TriggerFormOperation } from './TriggerFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any

  trigger:     Trigger | null
  triggerType: Trigger['type']
  node:        TriggerNode | null
  operation:   TriggerFormOperation
}

export default React.memo(function TriggerForm(props: Props) {

  const {trigger, triggerType, node, operation, ...rest} = props

  const {planner} = useFlowPlanner()
  const model = React.useMemo(() => {
    if (planner == null) { return null }

    return new TriggerFormModel(
      planner,
      trigger ?? {type: triggerType},
      node,
      operation,
    )
  }, [node, operation, planner, trigger, triggerType])

  const autoFocusFirst = 'create' in operation

  const [t] = useTranslation('flow_planner')

  const typeCaption = t(`triggers.${triggerType}.caption`, {nsSeparator: '|'}).toLocaleLowerCase()
  const title = trigger == null
    ? t('triggers.title.new_trigger', {type: typeCaption})
    : t('triggers.title.edit_trigger', {type: typeCaption})

  //------
  // Rendering

  function render() {
    if (model == null) { return null }

    return (
      <FormDialog model={model} title={title} width={width[triggerType]} {...rest} autoFocus={autoFocusFirst}>
        {renderTriggerFields(triggerType)}
      </FormDialog>
    )
  }

  return render()

})

const width: Partial<Record<Trigger['type'], number>> = {
  date: calendarWidth + 2 * layout.padding.m.desktop,
}