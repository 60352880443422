import { WidgetFilter } from '~/models'
import { WidgetFilterComponentType } from './types'

const DashboardFilterComponents = new Map<string, WidgetFilterComponentType<any, any>>()

export function registerDashboardFilter<TFilter = WidgetFilter, TValue = any>(name: string, Component: WidgetFilterComponentType<TFilter, TValue>) {
  DashboardFilterComponents.set(name, Component)
}

export function getDashboardFilterComponent(name: string) {
  return DashboardFilterComponents.get(name) ?? null
}