import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatList } from '~/formatting'
import { Group, Targeting } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { Label, LabelProps, HBox, Spinner } from '~/ui/components'
import { useFiniteModelSetData } from '~/ui/hooks/data'
import { layout } from '~/ui/styling'

export interface Props extends Omit<LabelProps, 'children'> {
  targeting: Targeting
}

const TargetingSummaryLabel = observer('TargetingSummaryLabel', (props: Props) => {

  const {targeting, ...rest} = props

  const [t] = useTranslation('targeting')

  const [, {fetchStatus}] = useFiniteModelSetData(Group, targeting.groups, {
    detail: false,
  })

  const groups        = targeting.groups.map(g => dataStore.get(Group, g)).filter(Boolean) as Group[]
  const groupNames    = groups.map(g => g.name)
  const tailSeparator = ' ' + (targeting.combinator === 'all' ? t('and') : t('or')) + ' '

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {fetchStatus === 'fetching' ? (
          <Spinner size={8}/>
        ) : (
          <Label markup {...rest}>
            {formatList(groupNames.map(name => `[**${name}**]`), {tailSeparator})}
          </Label>
        )}
      </HBox>
    )
  }

  return render()

})

export default TargetingSummaryLabel