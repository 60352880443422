import { action, makeObservable, observable } from 'mobx'
import { Module, PlannerType, ProjectFeatures } from '~/models'
import { ResourceFormModel } from '~/ui/resources/form'

export default class ModuleFormModel extends ResourceFormModel<Module> {

  constructor(
    private readonly module: Module | null,
  ) {
    super(Module, module)
    this.plannerType = this.module?.plannerType ?? '$none'
    this.features = this.module?.features ?? {}

    makeObservable(this)
  }

  @observable
  public plannerType: PlannerType | '$none'

  @observable
  public features: ProjectFeatures

  public override getValue(name: string) {
    if (/^features\.(.+)\.enabled$/.test(name)) {
      return this.features[RegExp.$1]?.enabled ?? false
    } else {
      return super.getValue(name as any)
    }
  }

  public override assign(updates: AnyObject) {
    for (const [name, value] of Object.entries(updates)) {
      if (/^features\.(.+)\.enabled$/.test(name)) {
        this.setFeatureEnabled(RegExp.$1, value)
      } else {
        super.assign({[name]: value})
      }
    }
  }

  @action
  private setFeatureEnabled(name: string, enabled: boolean) {
    if (enabled) {
      this.features[name] = {enabled: true, options: {}}
    } else {
      delete this.features[name]
    }
  }

  protected override buildData() {
    return {
      ...super.buildData(),
      plannerType: this.getValue('plannerType') === '$none' ? null : this.getValue('plannerType'),
      features:    this.features,
    }
  }

}