import { safeParseInt } from 'ytil'
import { formatAmount } from '~/formatting'

export function amount() {
  return (value: any) => {
    const num = safeParseInt(value)
    if (num == null) { return value }

    return formatAmount(num)
  }
}