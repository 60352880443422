import React from 'react'
import { Group } from '~/models'
import { memo } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'

export interface Props extends Omit<ResourceFieldProps<Group>, 'Model'> {
  type?: Group['type']
  tag?:  string
}

const GroupField = memo('GroupField', (props: Props) => {

  const {type, tag, filters: props_filters, ...rest} = props

  const filters = React.useMemo(() => {
    const filters: Record<string, string | null> = {}
    if (type != null) {
      filters.type = type
    }
    if (tag != null) {
      filters.tags = tag
    }
    if (props_filters != null) {
      Object.assign(filters, props_filters)
    }
    return filters
  }, [props_filters, tag, type])

  return (
    <ResourceField
      Model={Group}
      filters={filters}
      {...rest as any}
    />
  )

})

export default GroupField