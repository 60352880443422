import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { PushButton } from '~/ui/components'
import { saveAs } from '~/ui/util'
import { useAnswers } from './AnswersContext'

export interface Props {}

const DownloadAnswersButton = observer('DownloadAnswersButton', () => {

  const {service, focusedQuestions} = useAnswers()
  const [t] = useTranslation('answers')

  const download = React.useCallback(async () => {
    const blob = await service?.downloadAnswers(focusedQuestions)
    if (blob == null) {return }

    saveAs(blob, 'Answers.xlsx')
  }, [service, focusedQuestions])

  //------
  // Rendering

  function render() {
    return (
      <PushButton
        icon='download'
        caption={t('download.button')}
        onTap={download}
      />
    )
  }

  return render()

})

export default DownloadAnswersButton