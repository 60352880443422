// @index(!registry,!^TriggerNodeView): import ${relpath}
import './ChallengeTriggerNodeView'
import './DateTriggerNodeView'
import './GroupTriggerNodeView'
import './LocationTriggerNodeView'
import './ManualTriggerNodeView'
import './OnboardingTriggerNodeView'
import './QRCodeTriggerNodeView'
import './RegistrationTriggerNodeView'
import './ReviewTriggerNodeView'
import './ScriptTriggerNodeView'
import './WebhookTriggerNodeView'
import React from 'react'
import { TriggerNode } from '~/models'
import { getNodeViewForTrigger } from './registry'
import { CommonTriggerNodeViewProps } from './types'

// /index

export default function renderTriggerNode(node: TriggerNode, props: Omit<CommonTriggerNodeViewProps, 'node'>) {
  const NodeView = getNodeViewForTrigger(node.trigger.type)
  return React.createElement(NodeView, {node, trigger: node.trigger, ...props})
}