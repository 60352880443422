import React from 'react'
import { getClientPoint } from 'react-dnd'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { TimeOfDay } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, panelBorderRadius, PopupMenu, PopupMenuItem, VBox } from '~/ui/components'
import { useBoolean, useContinuousRef } from '~/ui/hooks'
import { colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import { useCalendarLayout } from '../CalendarLayoutContext'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import { labelHeight, labelWidth } from './CalendarDayTimelineLayer'
import { hourHeight, hourTop } from './layout'

export interface Props {
  transform:         string
  requestCreateItem: (type: CreateItemType, time: TimeOfDay) => Promise<void>
}

export type CreateItemType = 'script' | 'challenge' | 'action' | 'module'

const CalendarDayCreateLayer = memo('CalendarDayCreateLayer', (props: Props) => {

  const {transform, requestCreateItem} = props

  const {mode, setMode} = useCalendarPlanner()
  const {screenYToTimeOfDay, timeOfDayToScreenY} = useCalendarLayout.unoptim()
  const active = mode === 'create'

  const [t] = useTranslation('calendar_planner')

  const [createTime, setCreateTime] = React.useState<TimeOfDay | null>(null)
  const createTimeRef = useContinuousRef(createTime)

  //------
  // Create popup

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [createPopupOpen, openCreatePopupState, closeCreatePopup] = useBoolean()
  const [creating, startCreating, stopCreating] = useBoolean()
  const [mouseOver, setMouseOver] = React.useState<boolean>(false)

  const timer = useTimer()

  const openCreatePopup = React.useCallback(() => {
    if (creating) { return }
    openCreatePopupState()
  }, [creating, openCreatePopupState])

  const cancelCreate = React.useCallback(() => {
    stopCreating()
    setMouseOver(false)
    closeCreatePopup()
  }, [closeCreatePopup, stopCreating])

  React.useEffect(() => {
    if (creating && mode !== 'create') {
      cancelCreate()
    }
  }, [cancelCreate, creating, mode])

  const create = React.useCallback(async (type: CreateItemType) => {
    if (createTimeRef.current == null ) {
      cancelCreate()
      return
    }

    const promise = requestCreateItem(type, createTimeRef.current)
    await timer.await(promise)
    cancelCreate()
    setMode('select')
  }, [createTimeRef, requestCreateItem, timer, cancelCreate, setMode])

  const createPopupItems = React.useMemo((): PopupMenuItem<CreateItemType>[] => [{
    icon:    'chat',
    value:   'script',
    caption: t('add_item.script'),
  }, {
    icon:    'challenge',
    value:   'challenge',
    caption: t('add_item.challenge'),
  }, {
    icon:    'puzzle',
    value:   'module',
    caption: t('add_item.module'),
  }], [t])

  const getCreatePopupTargetRect = React.useCallback((): LayoutRect => {
    const rect = containerRef.current?.getBoundingClientRect()
    if (createTimeRef.current == null || rect == null) {
      return {left: 0, top: 0, width: 1, height: 1}
    }

    const x = rect.left + rect.width / 2
    const y = timeOfDayToScreenY(createTimeRef.current) + hourHeight

    return {
      left:   x,
      top:    y,
      width:  1,
      height: 1,
    }
  }, [createTimeRef, timeOfDayToScreenY])

  //------
  // Preview

  const movePreview = React.useCallback((event: React.SyntheticEvent<any, MouseEvent | TouchEvent>) => {
    if (creating) { return }
    if (event.target !== event.currentTarget) { return }

    const point = getClientPoint(event.nativeEvent)
    if (point == null) { return }

    const roundTo = event.nativeEvent.shiftKey ? 1 : 15

    setCreateTime(screenYToTimeOfDay(point.y - hourHeight / 2, {roundTo}))
    setMouseOver(true)
  }, [creating, screenYToTimeOfDay])

  const hidePreview = React.useCallback((event: React.SyntheticEvent) => {
    if (creating) { return }
    setMouseOver(false)
  }, [creating])

  const previewHandlers = React.useMemo(() => ({
    onMouseMove:  movePreview,
    onTouchMove:  movePreview,
    onMouseOut:   hidePreview,
    onTouchEnd:   hidePreview,
  }), [hidePreview, movePreview])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={[$.calendarDayCreateLayer, {active}]} ref={containerRef} {...previewHandlers} onClick={openCreatePopup}>
        <div classNames={$.layer} style={{transform}}>
          {renderCreatePreview()}
        </div>
        {renderCreatePopup()}
      </VBox>
    )
  }

  function renderCreatePopup() {
    return (
      <PopupMenu
        open={createPopupOpen}
        requestClose={closeCreatePopup}
        onWillOpen={startCreating}
        onCancel={cancelCreate}
        items={createPopupItems}
        shim={true}
        crossAlign='center'
        getTargetRect={getCreatePopupTargetRect}
        onValueSelect={create}
      />
    )
  }

  function renderCreatePreview() {
    if (!mouseOver) { return null }
    if (createTime == null) { return null }

    const active = creating
    const top    = hourTop(createTime.minutes / 60)

    return (
      <ThemeProvider primary>
        <HBox classNames={[$.createPreview, {active}]} align='top' style={{top}} gap={layout.padding.inline.m}>
          <Label tiny bold align='right' classNames={$.createPreviewLabel}>
            {createTime.toString()}
          </Label>
          <VBox flex classNames={$.createPreviewBox}/>
        </HBox>
      </ThemeProvider>
    )
  }

  return render()

})

export default CalendarDayCreateLayer

export const height = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  calendarDayCreateLayer: {
    ...layout.overlay,
    overflow:     'hidden',
    background:   colors.shim.white.alpha(0.2),
    borderRadius: [0, 0, panelBorderRadius(theme), panelBorderRadius(theme)],

    '&:not(.active)': {
      visibility: 'hidden',
    },
    '&.active': {
      pointerEvents: 'auto',
    },
  },

  layer: {
    position: 'absolute',
    top:      0,
    left:     0,
    right:    0,

    willChange:      'transform',
    transformOrigin: [0, 0],
  },

  prompt: {
    ...layout.overlay,
    pointerEvents: 'none',
    userSelect:    'none',
  },

  createPreview: {
    position: 'absolute',
    left:     0,
    right:    layout.padding.inline.m,

    pointerEvents: 'none',
    userSelect:    'none',
  },

  createPreviewLabel: {
    width:     labelWidth,
    height:    labelHeight,
    marginTop: -labelHeight / 2,
  },

  createPreviewBox: {
    height:       hourHeight,
    background:   theme.semantic.primary.alpha(0.2),
    borderRadius: panelBorderRadius(theme),
  },
}))