import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { EnumUtil } from 'ytil'
import { RegistrationCode, RegistrationPreset } from '~/models'
import { BulkSelector } from '~/stores'
import { observer } from '~/ui/component'
import { TabPanel, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { SubmitResult } from '~/ui/form'
import { useModelDocumentData, useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen, useResourceDetailLocation } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { saveAs } from '~/ui/util'
import RegistrationPresetForm from '../RegistrationPresetForm'
import { PreviewWidgetModel } from './PreviewWidgetModel'
import RegistrationPresetActions from './RegistrationPresetActions'
import RegistrationPresetAllowedProjects from './RegistrationPresetAllowedProjects'
import RegistrationPresetCodesList from './RegistrationPresetCodesList'
import RegistrationPresetInfo from './RegistrationPresetInfo'
import RegistrationPresetLink from './RegistrationPresetLink'
import RegistrationPresetPreview from './RegistrationPresetPreview'
import RegistrationPresetTiming from './RegistrationPresetTiming'
import RegistrationPresetValidation from './RegistrationPresetValidation'

export type Props = RouteComponentProps<ResourceDetailParams<BodyTab>>

const RegistrationPresetScreen = observer('RegistrationPresetScreen', (props: Props) => {

  const [preset] = useModelDocumentData(RegistrationPreset, props.match.params.id)
  const {body = BodyTab.preview, setBody} = useResourceDetailLocation<BodyTab>()

  const {t} = useResourceTranslation('registration-presets')

  const model = React.useMemo(
    () => preset == null ? null : new PreviewWidgetModel(preset),
    [preset],
  )

  const iconForTab = React.useCallback((tab: BodyTab): SVGName => {
    switch (tab) {
      case BodyTab.preview: return 'eye'
      case BodyTab.codes:   return 'code'
    }
  }, [])

  const tabs = React.useMemo(() => EnumUtil.values(BodyTab).map(
    (tab) => ({name: tab, caption: t(`tabs.${tab}`), icon: iconForTab(tab)}),
  ), [iconForTab, t])

  //------
  // Codes

  const codesEndpoint = useModelEndpoint(RegistrationCode, {
    filters: {
      preset: props.match.params.id,
    },
    include: ['preset'],
    fetch: false,
  })

  const afterCreateCodesSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status !== 'ok') { return }

    codesEndpoint.fetch().then(() => {
      const ids = result.data?.map((it: any) => it.id)
      return codesEndpoint.exportData(BulkSelector.list('registration-codes', ids))
    }).then(blob => {
      if (blob == null) { return }
      saveAs(blob, 'generated-codes.xlsx')
    })
  }, [codesEndpoint])

  const onShowTab = React.useCallback((tab: BodyTab) => {
    if (tab === BodyTab.codes) {
      codesEndpoint.fetch()
    }
  }, [codesEndpoint])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<RegistrationPreset>
        Model={RegistrationPreset}
        id={props.match.params.id}
        include={['branding', 'allowedProjects']}

        EditFormComponent={RegistrationPresetForm}
        renderBody={renderBody}
        renderInfo={renderInfo}
        renderSummaryFooter={renderSummaryFooter}
        renderActions={renderActions}
      />
    )
  }

  function renderBody() {
    return (
      <TabPanel<BodyTab>
        tabs={tabs}
        currentTab={body}
        requestTab={setBody}
        onShowTab={onShowTab}
        children={renderTab}
        renderMode='current-only'
        flex
      />
    )
  }

  const renderTab = React.useCallback((tab: BodyTab) => {
    if (model == null) { return null }

    if (tab === BodyTab.codes) {
      return (
        <RegistrationPresetCodesList
          preset={model.preset}
          endpoint={codesEndpoint}
        />
      )
    } else {
      return (
        <VBox flex>
          <RegistrationPresetPreview
            model={model}
          />
        </VBox>
      )
    }
  }, [codesEndpoint, model])

  function renderInfo(preset: RegistrationPreset) {
    return <RegistrationPresetInfo preset={preset}/>
  }

  function renderSummaryFooter(preset: RegistrationPreset) {
    return [
      <RegistrationPresetLink key='link' preset={preset}/>,
      <RegistrationPresetValidation key='validation' preset={preset}/>,
      <RegistrationPresetTiming key='timing' preset={preset}/>,
      <RegistrationPresetAllowedProjects key='allowed-projects' preset={preset}/>,
    ]
  }

  function renderActions(preset: RegistrationPreset) {
    return (
      <RegistrationPresetActions
        preset={preset}
        afterCreateCodesSubmit={afterCreateCodesSubmit}
      />
    )
  }

  return render()

})

enum BodyTab {
  preview = 'preview',
  codes   = 'codes',
}

export default RegistrationPresetScreen