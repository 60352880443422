import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackChoice } from '~/models'
import { memo } from '~/ui/component'
import { CollectionField, CollectionFieldProps, ItemFormProps, TextField } from '~/ui/components'

export type Props = Omit<CollectionFieldProps<FeedbackChoice>, 'renderForm' | 'newItemTemplate'>

const FeedbackChoicesField = memo('FeedbackChoicesField', (props: Props) => {

  const [t] = useTranslation('feedback')

  //------
  // Rendering

  function render() {
    return (
      <CollectionField<FeedbackChoice>
        {...props}
        renderForm={renderChoiceForm}
        newItemTemplate={(): FeedbackChoice => ({value: null, caption: ''})}
        pasteTransform={line => [{value: null, caption: line}]}
      />
    )
  }

  const renderChoiceForm = React.useCallback((props: ItemFormProps<FeedbackChoice>) => {
    return (
      <TextField
        {...props.bind('caption')}
        placeholder={t('fields.buttons.caption_placeholder')}
        multiline={true}
        inputStyle='light'
      />
    )
  }, [t])

  return render()

})

export default FeedbackChoicesField