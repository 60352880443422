import Color from 'color'

// @index
export { default as ColumnSeries } from './ColumnSeries'
export { default as LineSeries } from './LineSeries'
// /index

export interface SeriesProps<T> {
  name:          string
  caption?:      string
  valueForPoint: (point: T) => number | null | undefined

  color?:           Color
  showValueLabels?: boolean
}