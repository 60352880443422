import React from 'react'
import { hasBitmask } from 'ytil'
import { PlanNode, PlanNodeConnectorMask } from '~/models'
import { FlowPlanInsights, PlanPositionInsightsSummary } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import InsightsBadgeRow from '../../insights/InsightsBadgeRow'
import { findConnectionPoint } from '../canvas/layout'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  summary:  PlanPositionInsightsSummary
  node:     PlanNode
  bounds:   LayoutRect
  insights: FlowPlanInsights
}

const FlowPlanInsightsBadgeRow = observer('FlowPlanInsightsBadgeRow', (props: Props) => {

  const {summary, node, bounds, insights} = props
  const {planner} = useFlowPlanner()

  const position = summary.position
  const segue    = position.segue == null ? null : planner.plan?.findSegue(position.segue)

  //------
  // Layout

  const layout = React.useMemo(() => {
    if (segue == null) {
      return {
        top:  bounds.top,
        left: bounds.left + bounds.width,
      }
    } else if (node != null) {
      const connectionPoint = findConnectionPoint(segue.from, node, bounds)
      if (connectionPoint == null) { return {} }

      return {
        top:  connectionPoint.y,
        left: connectionPoint.x,
      }
    }
  }, [bounds, node, segue])

  const align = React.useMemo(() => {
    if (segue == null) {
      return 'right'
    } else if (hasBitmask(segue.from.connector, PlanNodeConnectorMask.WEST)) {
      return 'right'
    } else if (hasBitmask(segue.from.connector, PlanNodeConnectorMask.EAST)) {
      return 'left'
    } else {
      return 'center'
    }

  }, [segue])

  //------
  // Callbacks

  const focus = React.useCallback(() => {
    planner.setFocusedInsightsPosition(position)
  }, [planner, position])

  const abandonAll = React.useCallback(() => {
    planner.service.abandonPositions({
      node:  position.node,
      segue: position.segue,
    })
  }, [planner.service, position.node, position.segue])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (node == null) { return null }
    if (position.segue != null && segue == null) { return null }

    return (
      <VBox classNames={$.FlowPlanInsightsBadgeRow} style={layout}>
        <InsightsBadgeRow
          summary={summary}
          participants={insights.participants}
          align={align}

          requestFocus={focus}
          requestAbandonAll={abandonAll}
        />
      </VBox>
    )
  }

  return render()

})

export default FlowPlanInsightsBadgeRow

const useStyles = createUseStyles({
  FlowPlanInsightsBadgeRow: {
    position:      'absolute',
    pointerEvents: 'auto',
  },
})