import React from 'react'
import { ModelWithName, Page, Participant } from '~/models'
import { dataStore, Sort } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, Scroller, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { ResourceFromLinkedProjectNotice } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import PageCaptionEditor from '../PageCaptionEditor'
import IndexPageFooter from './IndexPageFooter'
import IndexPageItemList from './IndexPageItemList'

export interface Props {
  page:         Page
  interactive?: boolean
}

const IndexPageBody = observer('IndexPageBody', (props: Props) => {

  const {page, interactive} = props

  const Model = ModelWithName(page.itemType)

  const document             = dataStore.document(Page, page.id, false)
  const availableFilters     = document?.meta.filters ?? []
  const availableSorts       = document?.meta.sorts ?? []
  const availableViewOptions = document?.meta.viewOptions ?? {}

  const sorts = React.useMemo((): Sort[] => {
    const sortField     = page.sort?.startsWith('-') ? page.sort.slice(1) : page.sort
    const sortDirection = page.sort?.startsWith('-') ? -1 : 1
    if (sortField == null) { return [] }

    return [{
      field:     sortField,
      direction: sortDirection,
    }]
  }, [page.sort])

  const defaultFilters = React.useMemo(() => {
    if (Model === Participant) {
      return {listed: true}
    } else {
      return {}
    }
  }, [Model])

  const endpoint = useModelEndpoint(Model, {
    filters: {...defaultFilters, ...page.filter},
    sorts:   sorts,
  })

  const [editingCaption, startEditCaption, stopEditCaption] = useBoolean()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='grow'>
        {!interactive && (
          <ResourceFromLinkedProjectNotice
            classNames={$.linkedProjectNotice}
            Model={Page}
          />
        )}

        {interactive && editingCaption && renderCaptionEditor()}

        <Scroller>
          <VBox flex='grow'>
            {interactive && !editingCaption && renderCaptionEditor()}
            <VBox classNames={$.body}>
              <IndexPageItemList
                endpoint={endpoint}
              />
            </VBox>
          </VBox>
        </Scroller>

        {interactive && !editingCaption && (
          <VBox gap={layout.padding.s} classNames={$.footer}>
            <Dimple horizontal/>
            {renderFooter()}
          </VBox>
        )}
      </VBox>
    )
  }

  function renderCaptionEditor() {
    return (
      <PageCaptionEditor
        page={page}
        editing={editingCaption}
        requestStartEdit={startEditCaption}
        requestStopEdit={stopEditCaption}
      />
    )
  }

  function renderFooter() {
    return (
      <IndexPageFooter
        page={page}
        filters={availableFilters}
        sorts={availableSorts}
        viewOptions={availableViewOptions}
      />
    )
  }

  return render()

})

export default IndexPageBody

const useStyles = createUseStyles({
  linkedProjectNotice: {
    ...layout.responsive(size => ({
      margin: layout.padding.s[size],
    })),
  },

  body: {
    ...layout.responsive(size => ({
      padding:    layout.padding.s[size],
      paddingTop: 0,
    })),
  },

  footer: {
    ...layout.responsive(size => ({
      padding:    layout.padding.s[size],
      paddingTop: 0,
    })),
  },
})