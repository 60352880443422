import React from 'react'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { ClearButton, HBox, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'

interface Props {
  currentYear: DateTime
  goToYear:    (dateTime: DateTime) => any
  onCaptionTap: () => any
  enabled:      boolean
}

const YearSelector = memo('YearSelector', (props: Props) => {

  const {currentYear, goToYear, onCaptionTap, enabled} = props
  const caption = currentYear.toFormat('yyyy')

  const goToPreviousYear = React.useCallback(() => {
    goToYear(currentYear.plus({year: -1}))
  }, [currentYear, goToYear])

  const goToNextYear = React.useCallback(() => {
    goToYear(currentYear.plus({year: 1}))
  }, [currentYear, goToYear])

  return (
    <HBox gap={layout.padding.inline.m}>
      <ClearButton
        icon='double-chevron-left'
        onTap={goToPreviousYear}
        enabled={enabled}
        padding='both'
      />
      <VBox flex>
        <ClearButton
          caption={caption}
          onTap={onCaptionTap}
          enabled={enabled}
          padding='both'
          align='center'
        />
      </VBox>
      <ClearButton
        icon='double-chevron-right'
        onTap={goToNextYear}
        enabled={enabled}
        padding='both'
      />
    </HBox>
  )

})

export default YearSelector