import { Challenge, ChallengeTask, LocalizedString, MessageTemplate, Script } from '~/models'
import { QuestionDescriptor } from './types'

export const deriveQuestionsFromModel = (model: Challenge | Script): QuestionDescriptor[] => {
  const questions: QuestionDescriptor[] = []

  if (model instanceof Challenge) {
    const challenge = model as Challenge
    for (const task of challenge?.tasks ?? []) {

      if (task.question == null) { continue }

      questions.push({
        uuid: task.uuid,
        caption: LocalizedString.translate(task.title),
        prompt: deriveChallengeTaskPrompt(task),
        preferredResultStyle: task.resultStyle,
        feedback: task.question,
      })
    }
  }
  else {
    const script = model as Script

    for (const message of script.messages() ?? []) {
      if (message.feedback == null) {continue}

      questions.push({
        uuid: message.uuid,
        caption: LocalizedString.translate(textForMessage(message)),
        prompt: null,
        preferredResultStyle: null,
        feedback: message.feedback,
      })
    }
  }

  return questions
}

export function deriveChallengeTaskPrompt(task: ChallengeTask) {
  const paragraphs = LocalizedString.translate(task.body).split('\n\n')
  return paragraphs[paragraphs.length - 1].slice(0, 512)
}

export const textForMessage = (message: MessageTemplate): string => {
  switch (message.type) {
    case 'text': return message.text
    case 'notice': return message.text
    case 'image': return message.caption ?? "(Image)"
    case 'video': return message.caption ?? "(Video)"
    case 'redact': return '(Redacted)'
    case 'widget': return "(Widget)"
  }
}
