import { createUseStyles, fonts, layout } from '~/ui/styling'

export default createUseStyles(theme => ({
  '@global': {
    'html': {
      height:   '100vh',
    },

    'body': {
      minHeight: '100vh',
      overflow: 'hidden',
      margin: 0,
      ...layout.flex.column,

      ...fonts.responsiveFontStyle(theme.guide.fonts.body),

      background: theme.colors.bg.light.normal,
      color:      theme.colors.fg.dark.normal.string(),

      '-webkit-font-smoothing':  'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
    },

    '#root': {
      flex: [1, 0, 'auto'],
      ...layout.flex.column,
    },

    'h1, h2, h3': {
      fontSize: '100%',
      padding:  0,
      margin:   0,
    },

    'a, button': {
      color: 'inherit',
      textDecoration: 'none',
    },

    'figure': {
      margin: 0,
      marginBlockStart: 0,
      marginBlockEnd: 0,
      marginInlineStart: 0,
      marginInlineEnd: 0,
    },

    'img': {
      display:   'block',
      maxWidth:  '100%',
      maxHeight: '100%',
      overflow:  'hidden',
    },

    '*': {
      boxSizing: 'border-box',
      flex:      '0 0 auto',
      minWidth:  0,
      minHeight: 0,
    },
  },
}))
