import React from 'react'
import { Feed } from '~/models'
import { dataStore, SubmitResult } from '~/stores'
import { AccessFormModel, AccessSummary } from '~/ui/app/access'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  feed:       Feed
  attribute?: 'mayPost' | 'mayComment'
}

const FeedAccess = memo('FeedAccess', (props: Props) => {

  const {
    feed,
    attribute = 'mayPost',
  } = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new FeedAccessFormModel(feed, attribute),
    [feed, attribute],
  )

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <AccessSummary
          access={feed[attribute]}
          title={t(`access.title.${attribute}`)}
          icon={attribute === 'mayPost' ? 'add-post' : 'comments'}
          instructions={t(`access.form.instructions.${attribute}`)}
          action={t(`access.action.${attribute}`)}
          formModel={formModel}
        />
      </VBox>
    )
  }

  return render()

})

export default FeedAccess

class FeedAccessFormModel extends AccessFormModel {

  constructor(
    public readonly feed:      Feed,
    public readonly attribute: 'mayPost' | 'mayComment',
  ) {
    super(feed[attribute])
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Feed, this.feed.id, {
      [this.attribute]: this.buildAccess(),
    })
  }

}