import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSocketStatus } from 'socket.io-react'
import { appStore, authenticationStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  ConfirmBox,
  HBox,
  Label,
  PopupMenu,
  PopupMenuHeader,
  PopupMenuItem,
  Spinner,
  SVG,
  Tappable,
  Tooltip,
  VBox,
} from '~/ui/components'
import { animation, createUseStyles, layout, shadows, useStyling } from '~/ui/styling'

const AuthStatus = observer('AuthStatus', () => {

  const status    = useSocketStatus()
  const connected = status === 'ready'
  const offline   = !appStore.online

  const user          = authenticationStore.user
  const actualUser    = authenticationStore.actualUser
  const impersonating = user !== actualUser
  const showSpinner   = user == null || !connected

  const [t] = useTranslation('auth')

  const {colors} = useStyling()

  //------
  // Menu actions

  const history = useHistory()

  const logOut = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('logout.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    authenticationStore.logOut()
  }, [t])

  const goToAccount = React.useCallback(() => {
    history.push('/account')
  }, [history])

  const stopImpersonation = React.useCallback(() => {
    authenticationStore.stopImpersonation()
  }, [])

  const popupMenuItems = React.useMemo(() => {
    if (user == null) { return [] }

    const items: PopupMenuItem[] = []

    items.push({
      icon:     'user',
      caption:  t('account.caption'),
      onSelect: goToAccount,
    })

    items.push({section: '-'})

    items.push({
      icon:     'logout',
      caption:  t('logout.caption'),
      color:    colors.semantic.negative,
      onSelect: logOut,
    })

    return items
  }, [user, t, goToAccount, colors.semantic.negative, logOut])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (showSpinner) {
      return renderConnecting()
    } else if (user != null) {
      return (
        <HBox gap={layout.padding.m}>
          <PopupMenu items={popupMenuItems} header={renderPopupMenuHeader()}>
            {renderAvatar}
          </PopupMenu>
        </HBox>
      )
    } else {
      return null
    }
  }

  function renderConnecting() {
    if (offline) {
      return (
        <Tooltip renderTooltip={t('app:offline')}>
          <SVG
            name='offline'
            size={layout.icon.m}
            dim
          />
        </Tooltip>
      )
    } else {
      return (
        <Center classNames={$.connecting}>
          <Spinner size={12}/>
        </Center>
      )
    }
  }

  function renderAvatar(toggle: () => any) {
    if (user == null) { return null }

    return (
      <Tappable classNames={[$.avatar, {impersonating}]} onTap={toggle}>
        <Avatar
          classNames={$.currentUserAvatar}
          source={user.photoURL}
          firstName={user.name}
          size={avatarSize}
        />
        {actualUser != null && actualUser !== user && (
          <Avatar
            classNames={$.actualUserAvatar}
            source={actualUser.photoURL}
            firstName={actualUser.name}
            size={actualUserAvatarSize}
          />
        )}
      </Tappable>
    )
  }

  const renderImpersonation = React.useCallback(() => {
    if (!impersonating) { return null }

    return (
      <HBox classNames={$.impersonation} gap={layout.padding.inline.m}>
        <HBox flex>
          <Label flex='shrink' small caption markup>
            {t('impersonating', {actual: actualUser})}
          </Label>
        </HBox>
        <ClearButton
          icon='logout'
          onTap={stopImpersonation}
          caption={t('stop_impersonation')}
          small
        />
      </HBox>
    )
  }, [$.impersonation, actualUser, impersonating, stopImpersonation, t])

  const renderPopupMenuHeader = React.useCallback(() => {
    if (user == null) { return null }
    return (
      <PopupMenuHeader>
        <HBox gap={layout.padding.inline.m}>
          <Avatar
            firstName={user.name}
            source={user.photoURL}
            size={avatarSize}
          />
          <VBox flex gap={layout.padding.inline.xs}>
            <Label bold>
              {user.name}
            </Label>
            <Label small>
              {user.email}
            </Label>
          </VBox>
        </HBox>
        {renderImpersonation()}
      </PopupMenuHeader>
    )
  }, [renderImpersonation, user])

  return render()

})

export default AuthStatus

const avatarSize = {
  width:  40,
  height: 40,
}

const actualUserAvatarSize = {
  width:  20,
  height: 20,
}

const useStyles = createUseStyles(theme => ({
  connecting: {
    ...avatarSize,
  },

  avatar: {
    position: 'relative',

    ...avatarSize,
    background:   'white',
    borderRadius: avatarSize.width / 2,

    willChange: 'box-shadow',
    transition: animation.transitions.medium('box-shadow'),

    boxShadow: shadows.depth(1),
    '&:hover': {
      boxShadow: shadows.depth(4),
    },
  },

  currentUserAvatar: {
    position: 'relative',
  },

  actualUserAvatar: {
    position:    'absolute',
    top:         -actualUserAvatarSize.width / 4,
    right:       -actualUserAvatarSize.height / 4,
    borderColor: theme.semantic.primary,

  },

  impersonation: {
    background: theme.semantic.primary,
    margin:     [0, -layout.padding.inline.l, -layout.padding.inline.m],
    padding:    [layout.padding.inline.xs, layout.padding.inline.m],
  },
}))