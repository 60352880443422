import React from 'react'
import { Competition, Targeting } from '~/models'
import { TargetingSummary } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import CompetitionTargetingForm from './CompetitionTargetingForm'

export interface Props {
  competition: Competition
}

const CompetitionTargeting = memo('CompetitionTargeting', (props: Props) => {

  const {competition} = props
  const {t} = useResourceTranslation('competitions')

  const [,] = useBoolean()

  const BoundTargetingForm = React.useCallback(
    (props: Pick<FormDialogProps<any>, 'open' | 'requestClose'>) => (
      <CompetitionTargetingForm
        {...props}
        competition={competition}
        instructions={t('targeting_form.instructions')}
      />
    ),
    [competition, t],
  )

  return (
    <TargetingSummary
      targeting={competition.targeting ?? Targeting.empty()}
      FormComponent={BoundTargetingForm}
    />
  )

})

export default CompetitionTargeting