import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeObservable, observable } from 'mobx'
import { FirstNode } from '~/models'
import { FlowPlanner } from '~/stores'
import { memo } from '~/ui/component'
import { NumberField, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField, FormModel, SubmitResult } from '~/ui/form'
import { layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  open:         boolean
  requestClose: () => any
  node:         FirstNode
}

const FirstNodeForm = memo('FirstNodeForm', (props: Props) => {

  const {open, requestClose, node} = props
  const {planner} = useFlowPlanner()

  const formModel = React.useMemo(
    () => planner == null ? null : new FirstNodeFormModel(planner, node),
    [node, planner],
  )

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog open={open} requestClose={requestClose} model={formModel} title={t('nodes.first.caption')}>
        <VBox gap={layout.padding.m}>
          <TextBlock markup small>
            {t('nodes.first.detail')}
          </TextBlock>

          {renderFields()}
        </VBox>
      </FormDialog>
    )
  }

  function renderFields() {
    return (
      <FormField name='count' required caption={t('nodes.first.fields.count')!}>
        {bind => <NumberField {...bind} minimum={1} step={1}/>}
      </FormField>
    )
  }

  return render()

})

export default FirstNodeForm

export class FirstNodeFormModel implements FormModel {

  constructor(
    private readonly planner: FlowPlanner,
    private readonly node: FirstNode,
  ) {
    makeObservable(this)
  }

  @observable
  public count: number = this.node.count

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.planner.updateComponents([this.node.uuid], node => ({
      ...node,
      count: this.count,
    }))
  }

}