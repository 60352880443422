import React from 'react'
import { Model, ModelClass, NotFound } from '~/models'
import { observer } from '~/ui/component'
import { Label } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { ResourceBar, ResourceBarProps } from '~/ui/resources/components'
import { isReactText } from '~/ui/util'
import { cell, CellComponentType } from './cell'

export interface Props<M extends Model> extends Omit<ResourceBarProps, 'model'> {
  item:         string | null
  RelatedModel: ModelClass<M>
  renderEmpty?: () => React.ReactNode
}

const _RelatedCell = <M extends Model>(props: Props<M>) => {

  const {
    item: id,
    RelatedModel,
    children,
    renderEmpty: props_renderEmpty,
    ...rest
  } = props

  const [related] = useModelDocumentData(RelatedModel, id, {
    include: ['groups'],
    detail:  false,
  })

  const relatedOrNotFound = React.useMemo(() => {
    if (related != null) { return related }
    if (id == null) { return null }
    return new NotFound(RelatedModel, id)
  }, [related, id, RelatedModel])

  function render() {
    if (relatedOrNotFound == null) {
      return renderEmpty()
    } else {
      return (
        <ResourceBar
          model={relatedOrNotFound}
          {...rest}
        />
      )
    }
  }

  function renderEmpty() {
    const empty = props_renderEmpty?.()
    if (!isReactText(empty)) {
      return <>{empty}</>
    }

    return (
      <Label italic dimmer>
        {empty}
      </Label>
    )
  }

  return render()

}

const RelatedCell = observer('RelatedCell', _RelatedCell) as typeof _RelatedCell

function createRelatedCellOf<M extends Model>(RelatedModel: Constructor<M>, defaults: Omit<Props<M>, 'item' | 'RelatedModel'> = {}): CellComponentType<string | null> {
  const RelatedModelClass = RelatedModel as ModelClass<M>

  return cell(`RelatedCell<${RelatedModel.name}>`, props => {
    return (
      <RelatedCell
        {...defaults}
        {...props}
        RelatedModel={RelatedModelClass}
      />
    )
  })
}

Object.assign(RelatedCell, {
  of: createRelatedCellOf,
})
export default RelatedCell as typeof RelatedCell & {
  of: typeof createRelatedCellOf
}