import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectionManager } from 'react-selection-manager'
import { useTimer } from 'react-timer'
import { Model } from '~/models'
import { BulkSelector, ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { Chip, HBox, Label, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { useModalDialog } from '../ModalDialog'
import CollectionEditorActions from './CollectionEditorActions'
import CollectionEditorList from './CollectionEditorList'

export interface Props<M extends Model> {
  availableEndpoint: ModelEndpoint<M>
  includedEndpoint:  ModelEndpoint<M>

  requestAdd:    (selector: BulkSelector) => any | Promise<any>
  requestRemove: (selector: BulkSelector) => any | Promise<any>

  performInitialFetch?: boolean
}

const _CollectionEditor = <M extends Model>(props: Props<M>) => {

  const {
    availableEndpoint,
    includedEndpoint,
    requestAdd,
    requestRemove,
    performInitialFetch,
  } = props

  const timer = useTimer()

  const [working, startWorking, stopWorking] = useBoolean()

  const modalDialog = useModalDialog()
  React.useEffect(() => {
    modalDialog.showSpinner(working)
  }, [modalDialog, working])

  const fetch = React.useCallback(async () => {
    await Promise.all([
      availableEndpoint.fetch(),
      includedEndpoint.fetch(),
    ])
  }, [availableEndpoint, includedEndpoint])

  React.useEffect(() => {
    if (performInitialFetch) {
      fetch()
    }
  }, [fetch, performInitialFetch])

  const [t] = useTranslation('collection_editor')

  //------
  // Data

  const availableSelectionManager = React.useMemo(
    () => new SelectionManager<string>(),
    [],
  )
  const includedSelectionManager = React.useMemo(
    () => new SelectionManager<string>(),
    [],
  )

  const availableI18n = useResourceTranslation(availableEndpoint.Model.resourceType)
  const includedI18n  = useResourceTranslation(includedEndpoint.Model.resourceType)

  //------
  // Callbacks

  const buildBulkSelector = React.useCallback((manager: SelectionManager<string>, endpoint: ModelEndpoint<M>): BulkSelector | null => {
    if (manager.allAvailableSelected) {
      return endpoint.param('search') != null
        ? BulkSelector.list(endpoint.Model.resourceType, Array.from(manager.available))
        : BulkSelector.all()
    } else if (manager.selectedKeys.length > 0) {
      return BulkSelector.list(endpoint.Model.resourceType, manager.selectedKeys)
    } else {
      return null
    }
  }, [])

  const withWorking = React.useCallback(async (handler: () => Promise<void>) => {
    try {
      startWorking()
      await timer.await(handler())
    } finally {
      stopWorking()
    }
  }, [startWorking, stopWorking, timer])

  const addSelected = React.useCallback(async () => {
    const selector = buildBulkSelector(availableSelectionManager, availableEndpoint)
    if (selector == null) { return }

    await withWorking(async () => {
      await requestAdd(selector)
      await fetch()
    })
  }, [availableSelectionManager, availableEndpoint, buildBulkSelector, fetch, requestAdd, withWorking])

  const addAll = React.useCallback(async () => {
    await withWorking(async () => {
      const selector = availableEndpoint.param('search') != null
      ? BulkSelector.list(availableEndpoint.Model.resourceType, Array.from(availableSelectionManager.available))
      : BulkSelector.all()

      await requestAdd(selector)
      await fetch()
    })
  }, [availableEndpoint, availableSelectionManager.available, fetch, requestAdd, withWorking])

  const removeSelected = React.useCallback(async () => {
    await withWorking(async () => {
      const selector = buildBulkSelector(includedSelectionManager, includedEndpoint)
      if (selector == null) { return }

      await requestRemove(selector)
      await fetch()
    })
  }, [buildBulkSelector, includedSelectionManager, includedEndpoint, fetch, requestRemove, withWorking])

  const removeAll = React.useCallback(async () => {
    await withWorking(async () => {
      const selector = includedEndpoint.param('search') != null
      ? BulkSelector.list(includedEndpoint.Model.resourceType, Array.from(includedSelectionManager.available))
      : BulkSelector.all()

      await requestRemove(selector)
      await fetch()
    })
  }, [withWorking, includedEndpoint, includedSelectionManager.available, requestRemove, fetch])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex gap={layout.padding.l} align='stretch' classNames={$.collectionEditor}>
        {renderAvailableList()}
        {renderActions()}
        {renderIncludedList()}
      </HBox>
    )
  }

  function renderAvailableList() {
    return (
      <VBox flex gap={layout.padding.inline.s}>
        <HBox justify='space-between' gap={layout.padding.inline.m}>
          <Label caption small dim>
            {t('lists.available', {type: availableI18n.plural()})}
          </Label>
          {availableEndpoint.meta != null && (
            <Chip small>
              {`${availableEndpoint.meta?.searchTotal} / ${availableEndpoint.meta?.total}`}
            </Chip>
          )}
        </HBox>
        <CollectionEditorList
          endpoint={availableEndpoint}
          selectionManager={availableSelectionManager}
          onSearchCommit={addAll}
        />
      </VBox>
    )
  }

  function renderActions() {
    return (
      <CollectionEditorActions
        requestAddSelected={addSelected}
        requestAddAll={addAll}
        requestRemoveSelected={removeSelected}
        requestRemoveAll={removeAll}
        availableSelectionManager={availableSelectionManager}
        includedSelectionManager={includedSelectionManager}
        working={working}
      />
    )
  }

  function renderIncludedList() {
    return (
      <VBox flex gap={layout.padding.inline.s}>
        <HBox justify='space-between' gap={layout.padding.inline.m}>
          <Label caption small dim>
            {t('lists.included', {type: includedI18n.plural()})}
          </Label>
          {includedEndpoint.meta != null && (
            <Chip small>
              {`${includedEndpoint.meta?.searchTotal} / ${includedEndpoint.meta?.total}`}
            </Chip>
          )}
        </HBox>
        <CollectionEditorList
          endpoint={includedEndpoint}
          selectionManager={includedSelectionManager}
          onSearchCommit={removeAll}
        />
      </VBox>
    )
  }

  return render()

}

const CollectionEditor = observer('CollectionEditor', _CollectionEditor) as typeof _CollectionEditor
export default CollectionEditor

const useStyles = createUseStyles({
  collectionEditor: {
    position: 'relative',
  },

  working: {
    ...layout.overlay,
    background: colors.shim.white.alpha(0.2),
  },
})