import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { appStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  Label,
  ModalDialog,
  Panel,
  Scroller,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import ChangelogDialog from './ChangelogDialog'

export interface Props {
  open:         boolean
  requestClose: () => any
}

const ConfigDialog = observer('ConfigDialog', (props: Props) => {

  const [t] = useTranslation('config')

  //------
  // Changelog

  const [changelogVersion, setChangelogVersion] = React.useState<string | null>()
  const [changelogWhich, setChangelogWhich] = React.useState<'client' | 'server'>('client')
  const [changelogDialogOpen, openChangelogDialog, closeChangelogDialog] = useBoolean()

  const showClientChangelog = React.useCallback(() => {
    setChangelogWhich('client')
    setChangelogVersion(config.version?.replace(/^(\d+)\.(\d+).*$/, '$1.$2'))
    openChangelogDialog()
  }, [openChangelogDialog])

  const showServerChangelog = React.useCallback(() => {
    setChangelogWhich('server')
    setChangelogVersion(appStore.serverVersion?.replace(/^(\d+)\.(\d+).*$/, '$1.$2'))
    openChangelogDialog()
  }, [openChangelogDialog])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        {...props}
        icon='logo'
        title={t('title')}
        headerRight='$close'
        width={640}
        height={480}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox flex gap={layout.padding.m} padding={layout.padding.m}>
        {renderPreamble()}
        {renderURLs()}
        {renderEnvironment()}

        {changelogVersion != null && (
          <ChangelogDialog
            open={changelogDialogOpen}
            requestClose={closeChangelogDialog}
            which={changelogWhich}
            version={changelogVersion}
          />
        )}
      </VBox>
    )
  }

  function renderPreamble() {
    return (
      <VBox gap={layout.padding.s}>
        <HBox gap={layout.padding.inline.s}>
          <Label flex='shrink' mono markup style={{whiteSpace: 'pre'}}>
            {t(config.stack ? 'client_env.with_stack' : 'client_env.without_stack', {...config})}
          </Label>
          <ClearButton
            caption={t('show_changelog')}
            onTap={showClientChangelog}
            padding='both'
            small
          />
        </HBox>
        {appStore.serverVersion != null && (
          <HBox gap={layout.padding.inline.s}>
            <Label flex='shrink' mono markup style={{whiteSpace: 'pre'}}>
              {t('server_env', {
                environment: appStore.serverEnvironment,
                version:     appStore.serverVersion,
              })}
            </Label>
            <ClearButton
              caption={t('show_changelog')}
              onTap={showServerChangelog}
              padding='both'
              small
            />
          </HBox>
        )}
      </VBox>
    )
  }

  function renderURLs() {
    return (
      <Panel gap={layout.padding.s} padding={layout.padding.s}>
        <HBox gap={layout.padding.s}>
          <Label flex={2} mono markup>
            {t('urls.app')}
          </Label>
          <Label flex={3}>
            {config.urls.mission}
          </Label>
        </HBox>
        <Dimple horizontal/>
        <HBox gap={layout.padding.s}>
          <Label flex={2} mono markup>
            {t('urls.api')}
          </Label>
          <Label flex={3}>
            {config.urls.api}
          </Label>
        </HBox>
      </Panel>
    )
  }

  function renderEnvironment() {
    return (
      <Panel flex>
        <Scroller flex>
          <VBox flex='grow' gap={layout.padding.s} padding={layout.padding.s}>
            {Object.entries(process.env).sort((a, b) => a[0].localeCompare(b[0])).map(([name, value], index) => (
              <React.Fragment key={name}>
                {index > 0 && <Dimple horizontal/>}
                <HBox gap={layout.padding.s}>
                  <Label flex={2} mono markup={false}>
                    {name}
                  </Label>
                  <Label flex={3}>
                    {value}
                  </Label>
                </HBox>
              </React.Fragment>
            ))}
          </VBox>
        </Scroller>
      </Panel>
    )
  }

  return render()

})

export default ConfigDialog