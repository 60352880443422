import React from 'react'
import { getClientPoint, isRightMouse } from 'react-dnd'
import { useTimer } from 'react-timer'
import { clamp, isFunction } from 'lodash'
import { objectEquals } from 'ytil'
import { memo } from '~/ui/component'
import { VBox, VBoxProps } from '~/ui/components'
import { usePrevious, useRefMap, useViewState } from '~/ui/hooks'
import { animation, createUseStyles, layout, shadows } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'

export interface Props<P extends string> {
  left?:  P | null
  right?: P | null

  initialPanelWidth: number | ((side: Side) => number)
  minPanelWidth:     number | ((side: Side) => number)
  maxPanelWidth?:    number | ((side: Side) => number)
  minMainWidth?:     number

  namespace: string

  renderPanel: (panel: P) => React.ReactNode
  children?:   React.ReactNode

  leftShadow?:  boolean
  rightShadow?: boolean

  flex?: VBoxProps['flex']
  sidePanelClassNames?: React.ReactNode
}

const _SidePanels = <P extends string = string>(props: Props<P>) => {

  const {
    left,
    right,
    namespace,
    renderPanel,
    initialPanelWidth: props_initialPanelWidth,
    minPanelWidth: props_minPanelWidth,
    maxPanelWidth: props_maxPanelWidth,
    minMainWidth,
    sidePanelClassNames,
    leftShadow,
    rightShadow,
    flex = true,
    children,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [viewStateWidths, setViewStateWidths] = useViewState<Record<string, number>>(`${namespace}.side-panel.widths`, {})
  const [resizing, setResizing] = React.useState<boolean>(false)

  const minPanelWidth = React.useCallback((side: Side) => {
    if (isFunction(props_minPanelWidth)) {
      return props_minPanelWidth(side)
    } else {
      return props_minPanelWidth
    }
  }, [props_minPanelWidth])

  const maxPanelWidth = React.useCallback((side: Side) => {
    if (isFunction(props_maxPanelWidth)) {
      return props_maxPanelWidth(side)
    } else {
      return props_maxPanelWidth
    }
  }, [props_maxPanelWidth])

  const initialPanelWidth = React.useCallback((side: Side) => {
    const content = side === 'left' ? left : right
    if (content == null) { return 0 }

    return isFunction(props_initialPanelWidth) ? props_initialPanelWidth(side) : props_initialPanelWidth
  }, [left, props_initialPanelWidth, right])

  const clampWidth = React.useCallback((width: number, side: Side) => {
    const minWidth = minPanelWidth(side)
    let maxWidth = maxPanelWidth(side)

    if (minMainWidth != null && containerRef.current != null) {
      const otherWidth     = widthsRef.current[side === 'left' ? 'right' : 'left']
      const remainingWidth = containerRef.current.clientWidth - otherWidth

      if (maxWidth == null) {
        maxWidth = remainingWidth - minMainWidth
      } else {
        maxWidth = Math.min(maxWidth, remainingWidth - minMainWidth)
      }
    }

    if (maxWidth == null) {
      return Math.max(width, minWidth)
    } else if (maxWidth < minWidth) {
      return minWidth // ignore maxWidth
    } else {
      return clamp(width, minWidth, maxWidth)
    }
  }, [maxPanelWidth, minPanelWidth, minMainWidth])

  const panelWidth = React.useCallback((side: Side) => {
    const content = side === 'left' ? left : right
    if (content == null) { return 0 }

    const initialWidth = isFunction(props_initialPanelWidth) ? props_initialPanelWidth(side) : props_initialPanelWidth
    const vsWidth      = viewStateWidths?.[content]
    return clampWidth(vsWidth ?? initialWidth, side)
  }, [clampWidth, left, props_initialPanelWidth, right, viewStateWidths])

  const [widths, setWidths] = React.useState<WidthMap>({
    left:   initialPanelWidth('left'),
    right:  initialPanelWidth('right'),
  })

  React.useLayoutEffect(() => {
    if (resizing) { return }

    const nextWidths = {
      left:  panelWidth('left'),
      right: panelWidth('right'),
    }

    if (!objectEquals(widths, nextWidths)) {
      setWidths(nextWidths)
    }
  }, [initialPanelWidth, left, minPanelWidth, panelWidth, resizing, right, viewStateWidths, widths])

  const widthsRef = React.useRef<WidthMap>(widths)

  const setWidth = React.useCallback((side: Side, width: number) => {
    setResizing(true)
    setWidths(widthsRef.current = {
      ...widthsRef.current,
      [side]: clampWidth(width, side),
    })
  }, [clampWidth])

  const commitWidth = React.useCallback((side: Side, width: number) => {
    const panel = side === 'left' ? left : right
    if (panel == null) { return null }

    setViewStateWidths({
      ...viewStateWidths,
      [panel]: clampWidth(width, side),
    })
    setResizing(false)
  }, [clampWidth, left, right, setViewStateWidths, viewStateWidths])

  //------
  // Animation

  const prevLeft  = usePrevious(left)
  const prevRight = usePrevious(right)

  const leftContentRef  = React.useRef<React.ReactNode>(null)
  const rightContentRef = React.useRef<React.ReactNode>(null)

  const [leftAnimated, setLeftAnimated] = React.useState<boolean>(false)
  const [rightAnimated, setRightAnimated] = React.useState<boolean>(false)

  const animationTimer = useTimer()

  if (left != null) {
    leftContentRef.current = renderPanel(left)
  }
  if (right != null) {
    rightContentRef.current = renderPanel(right)
  }

  React.useLayoutEffect(() => {
    const leftChanged  = (prevLeft == null) !== (left == null)
    const rightChanged = (prevRight == null) !== (right == null)
    if (!leftChanged && !rightChanged) { return }

    animationTimer.clearAll()

    if (leftChanged) {
      setLeftAnimated(true)
      animationTimer.setTimeout(
        () => { setLeftAnimated(false) },
        transitionDuration,
      )
    }
    if (rightChanged) {
      setRightAnimated(true)
      animationTimer.setTimeout(
        () => { setRightAnimated(false) },
        transitionDuration,
      )
    }
  }, [animationTimer, left, prevLeft, prevRight, right])

  const mainStyle = React.useMemo((): React.CSSProperties => ({
    left:  left == null ? 0 : widths.left,
    right: right == null ? 0 : widths.right,
  }), [left, right, widths.left, widths.right])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} classNames={$.sidePanels} ref={containerRef}>
        <VBox flex classNames={$.main} style={mainStyle}>
          {children}
        </VBox>
        {renderSidePanel('left')}
        {renderSidePanel('right')}
      </VBox>
    )
  }

  function renderSidePanel(side: Side) {
    const panel    = side === 'left' ? left : right
    const content  = side === 'left' ? leftContentRef.current : rightContentRef.current
    const animated = side === 'left' ? leftAnimated : rightAnimated
    const shadow   = side === 'left' ? leftShadow : rightShadow
    if (content == null) { return null }

    const closed = panel == null

    return (
      <SidePanel
        side={side}
        width={widths[side]}
        setWidth={!closed ? setWidth : undefined}
        commitWidth={commitWidth}
        classNames={[sidePanelClassNames, {animated, shadow, closed}]}
        children={content}
      />
    )
  }

  return render()

}

interface SidePanelProps {
  side:         Side
  width:        number
  setWidth?:    (side: Side, width: number) => any
  commitWidth?: (side: Side, width: number) => any
  children:     React.ReactNode
  classNames?:  React.ReactNode
}

const SidePanel = memo('SidePanel', (props: SidePanelProps) => {

  const {
    side,
    width,
    setWidth,
    commitWidth,
    children,
    classNames,
  } = props

  //-------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.sidePanelContainer, side, classNames]} style={{width}}>
        {children}
        {setWidth != null && renderResizeHandle(side)}
      </VBox>
    )
  }

  //------
  // Resizing

  const resizeHandleRefs = useRefMap<Side, HTMLDivElement>()

  function renderResizeHandle(side: Side) {
    return (
      <div
        classNames={[$.resizeHandle, side]}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
        ref={resizeHandleRefs.for(side)}
      />
    )
  }

  const startRef      = React.useRef<Point | null>(null)
  const startWidthRef = React.useRef<number | null>(null)

  const widthForPageX = React.useCallback((pageX: number) => {
    const start      = startRef.current
    const startWidth = startWidthRef.current
    if (start == null || startWidth == null) { return null }

    const deltaX = pageX - start.x
    return side === 'left' ? startWidth + deltaX : startWidth - deltaX
  }, [side])

  const handleMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    event.preventDefault()

    const current = getClientPoint(event)
    const width = current == null ? null : widthForPageX(current.x)
    if (width == null) { return }

    setWidth?.(side, width)
  }, [setWidth, side, widthForPageX])

  const handleEnd = React.useCallback((event: MouseEvent | TouchEvent) => {
    event.preventDefault()

    const current = getClientPoint(event)
    const width = current == null ? null : widthForPageX(current.x)
    if (width != null) {
      commitWidth?.(side, width)
    }

    startRef.current = null
    startWidthRef.current = null

    window.removeEventListener('mousemove', handleMove)
    window.removeEventListener('mouseup', handleEnd)
    window.removeEventListener('touchmove', handleMove)
    window.removeEventListener('touchend', handleEnd)
  }, [commitWidth, handleMove, side, widthForPageX])

  const handleStart = React.useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (isRightMouse(event.nativeEvent)) { return }
    if (closest(event.target, isInteractiveElement) != null) { return }

    event.preventDefault()

    startRef.current      = getClientPoint(event.nativeEvent)
    startWidthRef.current = width

    window.addEventListener('mousemove', handleMove)
    window.addEventListener('mouseup', handleEnd)
    window.addEventListener('touchmove', handleMove)
    window.addEventListener('touchend', handleEnd)
  }, [handleEnd, handleMove, width])

  return render()

})

const SidePanels = memo('SidePanels', _SidePanels) as typeof _SidePanels
export default SidePanels

export type Side = 'left' | 'right'
type WidthMap = Record<Side, number>

const transitionDuration = animation.durations.medium

const useStyles = createUseStyles({
  sidePanels: {
    position: 'relative',
  },

  main: {
    ...layout.overlay,
  },

  sidePanelContainer: {
    position: 'absolute',
    top:      0,
    bottom:   0,
    overflow: 'hidden',

    '&.left':  {left: 0},
    '&.right': {right: 0},

    '&.shadow': {
      '&.left': {
        boxShadow:  [1, 0, 4, 0, shadows.shadowColor.alpha(0.2)],
      },
      '&.right': {
        boxShadow:  [-1, 0, 4, 0, shadows.shadowColor.alpha(0.2)],
      },
    },

    '&.animated': {
      willChange: 'transform',
      transition: animation.transition('transform', transitionDuration),
    },

    '&.closed': {
      '&.right': {
        transform: `translateX(calc(100% + ${4}px))`,
      },
    },
  },

  resizeHandle: {
    position: 'absolute',
    top:      0,
    bottom:   0,
    width:    4,
    cursor:   'ew-resize',

    '&.left':  {right: 0},
    '&.right': {left: 0},
  },
})