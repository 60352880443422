import React from 'react'
import { ModuleParameter } from '~/models'
import { observer } from '~/ui/component'
import { HBox, NumberField, TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ParameterFormModel from '../ParameterFormModel'

export interface Props {
  parameter: ModuleParameter
}

const StringParameterFieldset = observer('StringParameterFieldset', (props: Props) => {

  const {t}  = useResourceTranslation('modules')
  const {model: formModel} = useForm<ParameterFormModel>()

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <HBox gap={layout.padding.s} align='top'>
          <VBox flex>
            <FormField name='minLength' {...t('parameters.fields.min_length')}>
              {bind => <NumberField {...bind} minimum={0} maximum={formModel.maxLength} step={1}/>}
            </FormField>
          </VBox>
          <VBox flex>
            <FormField name='maxLength' {...t('parameters.fields.max_length')}>
              {bind => <NumberField {...bind} minimum={formModel.minLength} step={1}/>}
            </FormField>
          </VBox>
        </HBox>
        <FormField name='pattern' {...t('parameters.fields.pattern')}>
          {bind => <TextField {...bind} mono showClearButton='always'/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default StringParameterFieldset