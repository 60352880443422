import React from 'react'
import { AnswersQuery, Challenge, Group, Script } from '~/models'
import { memo } from '~/ui/component'
import { DataGrid, HBox } from '~/ui/components'
import { ResourceListScreen } from '~/ui/resources/collection'
import { ResourceChip, ResourceChipRow } from '~/ui/resources/components'

const AnswersQueriesScreen = memo('AnswersQueriesScreen', () => {

  //------
  // Rendering

  function render() {
    return(
      <ResourceListScreen
        Model={AnswersQuery}
        allowExport={false}
        allowCreate={false}>
        <DataGrid.Column<AnswersQuery>
          name='name'
          sort={true}
        />
        <DataGrid.Column<AnswersQuery>
          name='model'
          sort={true}
          renderCell={ModelCell}
        />
        <DataGrid.Column<AnswersQuery>
          name='segments'
          sort={true}
          renderCell={SegmentsCell}
        />
      </ResourceListScreen>
    )
  }

  const ModelCell = React.useCallback((query: AnswersQuery) => {
    const {model} = query.question
    return(
      <HBox>
        <ResourceChip
          Model={model.model === 'Script' ? Script : Challenge}
          id={model.id}
        />
      </HBox>
    )
  }, [])

  const SegmentsCell = React.useCallback((query: AnswersQuery) => {
    return (
      <ResourceChipRow
        Model={Group}
        ids={query.segments ?? []}
      />
    )
  }, [])

  return render()

})

export default AnswersQueriesScreen