import React from 'react'
import { QRCodeTrigger } from '~/models'
import { memo } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useFlowPlanner } from '../../FlowPlannerContext'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: QRCodeTrigger
}

const QRCodeTriggerNodeView = memo('QRCodeTriggerNodeView', (props: Props) => {

  const {node} = props
  const {planner} = useFlowPlanner()
  const {t} = useResourceTranslation('flow_planner')

  const viewQRCode = React.useCallback(() => {
    planner.viewNodeDetail(node.uuid)
  }, [node.uuid, planner])

  const extraMenuItems = React.useMemo((): PopupMenuItem[] => [{
    icon:     'qrcode',
    caption:  t('triggers.actions.view_qrcode'),
    keyHint:  'doubleclick',
    onSelect: viewQRCode,
  }], [t, viewQRCode])

  return (
    <TriggerNodeView
      {...props}
      icon='qrcode'
      extraMenuItems={extraMenuItems}
      onDoubleClick={viewQRCode}
    />
  )
})

register('qrcode', QRCodeTriggerNodeView)