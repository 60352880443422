import React from 'react'
import {
  createSortableElement,
  ElementConnect,
  SortableContainerProps,
  SortableItem,
} from 'react-dnd-sortable'
import { ScrollManager } from 'react-scroll-manager'
import { SetOperations } from 'ytil'
import { ScriptMessage } from '~/models'
import { UploadProgress } from '~/stores'
import { memo } from '~/ui/component'
import { Empty, List, ScrollToEdgeButton, VBox } from '~/ui/components'
import { usePrevious, useRefMap } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { closest } from '~/ui/util'
import { useScriptEditing } from './ScriptEditingContext'
import { useScriptEditor } from './ScriptEditorContext'
import ScriptMessageListItem from './ScriptMessageListItem'
import ScriptMessageUploadPlaceholder from './ScriptMessageUploadPlaceholder'
import { NewScriptMessage } from './types'

const SortableScriptMessageItem = createSortableElement(VBox)

export interface Props {
  newMessageUploadProgress?: UploadProgress | null
  requestCancelUpload?:      () => any
}

const ScriptMessageList = memo('ScriptMessageList', (props: Props) => {

  const {
    newMessageUploadProgress = null,
    requestCancelUpload,
  } = props

  const {moveMessage} = useScriptEditor()
  const {messages, editingMessageUUID, newMessage, saveCurrentAndStopEditingMessage, editingList} = useScriptEditing()

  const messageRefs   = useRefMap<string, HTMLDivElement>()
  const scrollManager = React.useMemo(() => new ScrollManager(), [])

  const items = React.useMemo(() => {
    const items: ListItem[] = [...messages]
    if (newMessage != null) {
      items.push(newMessage)
    }
    if (newMessageUploadProgress != null) {
      items.push({uuid: '$upload'})
    }

    return items
  }, [messages, newMessage, newMessageUploadProgress])

  const {t} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex onMouseDown={handleMouseDown} classNames={$.scriptMessageList}>
        <List
          data={items}
          keyExtractor={message => message.uuid}
          renderItem={renderMessage}
          contentPadding={layout.padding.m}
          itemGap={layout.padding.inline.m}
          sortable={sortable}
          renderPlaceholder={renderSortPlaceholder}
          EmptyComponent={renderEmpty}

          scrollable={true}
          scrollManager={scrollManager}
        />
        {renderScrollToEdgeButton('top')}
        {renderScrollToEdgeButton('bottom')}
      </VBox>
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty
        flex
        {...t('empty')}
      />
    )
  }, [t])

  function renderMessage(message: ScriptMessage | NewScriptMessage | UploadMessage, index: number) {
    if (message.uuid === '$upload') {
      return (
        <ScriptMessageUploadPlaceholder
          progress={newMessageUploadProgress ?? UploadProgress.zero()}
          requestCancel={requestCancelUpload}
        />
      )
    }

    const editing = message.uuid === editingMessageUUID
    const scriptMessage = message as ScriptMessage | NewScriptMessage

    return (
      <SortableScriptMessageItem
        enabled={!editingList && editingMessageUUID == null}
        index={index}
        classNames={[$.message, {editing}, scriptMessage.type]}
        itemType='ScriptMessage'
        payload={scriptMessage}
        sourceList={sortableListID}
      >
        <ScriptMessageListItem
          message={scriptMessage}
          ref={messageRefs.for(message.uuid)}
        />
      </SortableScriptMessageItem>
    )
  }

  function renderScrollToEdgeButton(edge: 'top' | 'bottom') {
    if (editingMessageUUID != null) { return null }

    return (
      <ScrollToEdgeButton
        edge={edge}
        scrollManager={scrollManager}
        classNames={[$.scrollToEdgeButton, edge]}
      />
    )
  }

  const renderSortPlaceholder = React.useCallback((connect: ElementConnect, item: SortableItem, isOver: boolean) => {
    return (
      <div
        classNames={[$.sortPlaceholder, {active: isOver}]}
        ref={connect}
      />
    )
  }, [$.sortPlaceholder])

  //------
  // Auto scroll effects

  const prevMessages = usePrevious(items)

  React.useEffect(() => {
    if (prevMessages == null) { return }

    const newMessages = SetOperations.diff(items, prevMessages, (a, b) => a.uuid === b.uuid)
    if (newMessages.length === 0) { return }

    const lastMessage = newMessages[newMessages.length - 1]
    const bubble      = messageRefs.get(lastMessage.uuid)

    bubble?.scrollIntoView()
  }, [messageRefs, items, prevMessages])

  React.useEffect(() => {
    if (editingMessageUUID == null) { return }
    const bubble = messageRefs.get(editingMessageUUID)
    bubble?.scrollIntoView()
  }, [messageRefs, editingMessageUUID])

  //------
  // List editing

  const moveMessageOnDrop = React.useCallback((item: SortableItem<any, ListItem>, index: number) => {
    const message = item.payload
    if (message.uuid === '+new' || message.uuid === '$upload') { return }

    return moveMessage(message.uuid, index)
  }, [moveMessage])

  const sortableListID = React.useMemo(() => Symbol(), [])

  const sortable = React.useMemo(
    (): SortableContainerProps<ListItem> | undefined => ({
      listID:     sortableListID,
      accept:     ['ScriptMessage'],
      onSortDrop: moveMessageOnDrop,
    })
  , [moveMessageOnDrop, sortableListID])

  //------
  // Other callbacks

  /**
   * Collapse the editing message if the user clicks on the list outside of any message.
   */
  const handleMouseDown = React.useCallback((event: React.MouseEvent<any>) => {
    if (!event.currentTarget.contains(event.target)) { return }

    const message = closest(event.target as Element, el => el.classList.contains($.message) && el.classList.contains('editing'))
    if (message != null) { return }

    saveCurrentAndStopEditingMessage()
  }, [$.message, saveCurrentAndStopEditingMessage])

  return render()

})

export default ScriptMessageList

type ListItem = ScriptMessage | NewScriptMessage | UploadMessage

interface UploadMessage {
  uuid: '$upload'
}

const useStyles = createUseStyles({
  scriptMessageList: {
    position: 'relative',
  },

  scrollToEdgeButton: {
    position: 'absolute',
    right:    layout.padding.inline.s + 16,

    '&.top':    {top: layout.padding.inline.s},
    '&.bottom': {bottom: layout.padding.inline.s},
  },

  sortPlaceholder: {
    height: layout.barHeight.s,
  },

  message: {
    maxWidth: 480,
    '&.notice, &.widget, &.editing': {
      alignSelf: 'stretch',
      maxWidth: 640,
    },
  },
})