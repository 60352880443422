import React from 'react'
import { Branding } from '~/models'
import { BrandingGuide } from '~/ui/styling'

interface BrandingContext {
  branding: Branding
  guide:    BrandingGuide
}

const BrandingContext = React.createContext<BrandingContext | null>(null)

export default BrandingContext

export function useBranding() {
  const context = React.useContext(BrandingContext)
  if (context == null) {
    throw new Error("This component needs to be placed inside a BrandingContext.Provider")
  }

  return context
}