import I18next from 'i18next'
import { makeObservable, observable } from 'mobx'
import * as UUID from 'uuid'
import { Module, Ref, TimeOfDay } from '~/models'
import { CalendarPlanner } from '~/stores'
import { SubmitResult } from '~/ui/form'
import ModuleFormModel from '../../modules/ModuleFormModel'

export default class ModuleItemFormModel extends ModuleFormModel {

  constructor(
    protected readonly planner: CalendarPlanner,

    dayUUID: string,
    private readonly itemUUID: string | null,

    module:  Ref<Module> | null,
    time:    TimeOfDay,
  ) {
    super(module)
    this.day    = dayUUID
    this.time   = time

    makeObservable(this)
  }

  public get type() {
    return 'module'
  }

  public get isNew() {
    return this.existing == null
  }

  @observable
  public day: string

  @observable
  public time: TimeOfDay

  //------
  // Submission

  public async submitWithModule(module: Ref<Module>): Promise<SubmitResult | undefined> {
    if (module == null) {
      return {
        status: 'invalid',
        errors: [{
          field:   'module',
          message: I18next.t('validation:required'),
        }],
      }
    }

    return this.planner.addItem(this.day, {
      uuid:   UUID.v4(),
      type:   'module',
      time:   this.time,
      module: module,
    })
  }

}