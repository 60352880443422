import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { Center, Label, Panel, TextBlock } from '~/ui/components'
import { AuthLogo } from '~/ui/layouts/auth'
import { ResourceTypeProvider } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  preamble?:  React.ReactNode
  children?:  React.ReactNode
}

const AuthForm = memo('AuthForm', (props: Props) => {

  const [t] = useTranslation()

  const {
    preamble,
    children,
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='auth'>
        <Panel depth={8} classNames={$.AuthForm} padding={layout.padding.l} gap={layout.padding.m}>
          {renderHeader()}
          {children}
        </Panel>
      </ResourceTypeProvider>
    )
  }

  function renderHeader() {
    return (
      <Center gap={layout.padding.m}>
        <AuthLogo/>
        <Label h1>
          {t('app:title')}
        </Label>
        {renderPreamble()}
      </Center>
    )
  }

  function renderPreamble() {
    if (!isReactText(preamble)) { return preamble }

    return (
      <TextBlock small dim align='center'>
        {preamble}
      </TextBlock>
    )
  }


  return render()

})

export default AuthForm

const useStyles = createUseStyles({
  AuthForm: {
    ...layout.responsiveProp({margin: layout.padding.l}),
    ...layout.breakpoint({minWidth: 360 + 2 * layout.padding.l.desktop})({
      width:  360,
      margin: [0, 'auto'],
    }),
  },
})