import React from 'react'
import { memo } from '~/ui/component'
import { createUseStyles } from '~/ui/styling'
import { useChart } from './ChartContext'

export interface Props {
  axis: 'x' | 'y1' | 'y2'
}

const ChartAxis = memo('ChartAxis', (props: Props) => {

  const {axis} = props
  const {data, chartLayout} = useChart<any>()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <g>
        {renderLine()}
        {renderAxisLabels()}
      </g>
    )
  }

  function renderLine() {
    if (axis === 'x') {
      return (
        <rect
          classNames={$.axisLine}
          x={chartLayout.plotRect.left}
          y={chartLayout.plotRect.top + chartLayout.plotRect.height}
          width={chartLayout.plotRect.width}
          height={0.5}
        />
      )
    } else {
      return (
        <rect
          classNames={$.axisLine}
          x={axis === 'y2' ? chartLayout.plotRect.left + chartLayout.plotRect.width : chartLayout.plotRect.left}
          y={chartLayout.plotRect.top}
          width={0.5}
          height={chartLayout.plotRect.height}
        />
      )
    }
  }

  function renderAxisLabels() {
    if (axis === 'x') {
      return renderPointLabels()
    } else {
      return renderValueLabels()
    }
  }

  function renderPointLabels() {
    return data.map(point => point.axisLabel == null ? null : (
      <g key={point.key} transform={`translate(${chartLayout.xForPointKey(point.key)}, ${chartLayout.plotRect.top + chartLayout.plotRect.height + 4})`} width={0}>
        <text classNames={[$.axisLabel, $.pointLabel]} textAnchor='middle' dy='1.5em'>
          {point.axisLabel}
        </text>
      </g>
    ))
  }

  function renderValueLabels() {
    return null
  }

  return render()

})

export default ChartAxis

const useStyles = createUseStyles(theme => ({
  axisLine: {
    fill: theme.inverse.fg.normal,
  },

  axisLabel: {
    fontSize:   12,
    fontWeight: '700',
    fill:       theme.fg.dim,
  },

  pointLabel: {
  },
}))