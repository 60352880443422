import { makeObservable, observable } from 'mobx'
import { Project, Ref, RegistrationPreset } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class CreateCodesFormModel implements FormModel {

  constructor(
    private readonly originalPreset: RegistrationPreset | null = null,
  ) {
    makeObservable(this)
  }

  public presetKnown: boolean = this.originalPreset != null

  @observable
  public preset: Ref<RegistrationPreset> | null = this.originalPreset?.id ?? null

  @observable
  public count: number = 1

  @observable
  public project: Ref<Project> | null = null

  public async submit(): Promise<SubmitResult | undefined> {
    const [validationResult, preset] = this.validatePreset()
    if (validationResult.status !== 'ok') { return validationResult }

    const document = dataStore.document(RegistrationPreset, preset)
    return await document.callAction('create-codes', {
      data: null,
      meta: {
        count:   this.count,
        project: this.project,
      },
    })
  }

  private validatePreset(): [SubmitResult, Ref<RegistrationPreset>] {
    const {preset} = this

    if (preset == null) {
      return [{
        status: 'invalid',
        errors: [{
          field: 'preset',
          code:  'required',
        }],
      }, '']
    } else {
      return [{status: 'ok'}, preset]
    }
  }

  public reset() {
    this.preset  = this.originalPreset?.id ?? null
    this.count   = 1
    this.project = null
  }

}