import React from 'react'
import { isFunction } from 'lodash'
import { Model } from '~/models'
import { memo } from '~/ui/component'
import { DataGrid } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { childrenNotOfType, childrenOfType } from '~/ui/util'
import CheckboxCell, { checkboxCellWidth } from './CheckboxCell'
import ResourceCollection, {
  ChildrenProps as ResourceCollectionChildrenProps,
  Props as ResourceCollectionProps,
} from './ResourceCollection'

export interface Props<M extends Model> extends Omit<ResourceCollectionProps<M>, 'children'> {
  children?:         React.ReactNode
  headerClassNames?: React.ClassNamesProp
  bodyClassNames?:   React.ClassNamesProp
  rowClassNames?:    (item: M) => React.ClassNamesProp
}

const _ResourceList = <M extends Model>(props: Props<M>) => {

  const {children, headerClassNames, bodyClassNames, rowClassNames, ...rest} = props
  const {endpoint, allowSelect = true, allowRemove = true, bulkActions = []} = props
  const {fieldCaption} = useResourceTranslation(endpoint.Model.resourceType)

  const showCheckboxes  = allowSelect && (allowRemove || bulkActions.length > 0)

  const checkboxEnabled = React.useCallback((model: M) => {
    return isFunction(allowSelect) ? allowSelect(model) : allowSelect
  }, [allowSelect])

  const columns = React.useMemo(() => {
    const columns = childrenOfType(children, DataGrid.Column)
      .map(col => React.cloneElement(col, {
        caption: col.props.caption ?? fieldCaption(col.props.name),
      }))

    if (showCheckboxes) {
      columns.unshift(
        <DataGrid.Column<M>
          name='$checkbox'
          width={checkboxCellWidth}
          padded={false}
          renderCell={model => checkboxEnabled(model) ? <CheckboxCell id={model.id}/> : null}
          renderHeaderCell={() => <CheckboxCell id={null}/>}
        />,
      )
    }

    return columns
  }, [checkboxEnabled, children, fieldCaption, showCheckboxes])

  //------
  // Rendering

  function render() {
    return (
      <ResourceCollection {...rest} renderContent={renderDataGrid}>
        {childrenNotOfType(children, [DataGrid.Column])}
      </ResourceCollection>
    )
  }

  function renderDataGrid(props: ResourceCollectionChildrenProps<M>) {
    return (
      <DataGrid<M>
        data={props.data}
        children={columns}
        keyForItem={keyForItem}
        onItemTap={props.onItemTap}
        itemHref={props.itemHref}
        itemIsSelected={props.itemIsSelected}
        EmptyComponent={props.EmptyComponent}
        sort={props.sort}
        requestSort={props.requestSort}
        headerClassNames={headerClassNames}
        bodyClassNames={bodyClassNames}
        rowClassNames={rowClassNames}
      />
    )
  }

  const keyForItem = React.useCallback(
    (item: M) => item.id,
    [],
  )

  return render()

}

const ResourceList = memo('ResourceList', _ResourceList) as typeof _ResourceList
export default ResourceList