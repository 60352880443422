import React from 'react'
import { useTranslation } from 'react-i18next'
import { Module } from '~/models'
import { DynamicField, DynamicFieldset, DynamicFormSchema } from '~/ui/app/dynamic-form'
import { observer } from '~/ui/component'
import { Center, Panel, Spinner, TextField, VBox } from '~/ui/components'
import { FormDialog, FormField, FormFieldHeader } from '~/ui/form'
import { useModelDocumentData } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../flow/FlowPlannerContext'
import ModuleNodeFormModel from './ModuleNodeFormModel'

export interface Props {
  nodeUUID: string
  moduleID: string

  open:         boolean
  requestClose: () => any
}

const ModuleNodeForm = observer('ModuleNodeForm', (props: Props) => {

  const {nodeUUID, moduleID} = props

  const [module, {fetchStatus}] = useModelDocumentData(Module, moduleID, {
    fetch: 'notfound',
  })

  const {planner} = useFlowPlanner()
  const [t] = useTranslation('flow_planner')

  const formModel = React.useMemo(() => {
    if (planner == null || module == null) { return null }

    const node = planner.plan?.findNode(nodeUUID)
    if (node == null || node.type !== 'module') { return null }

    return new ModuleNodeFormModel(planner, module, node)
  }, [module, nodeUUID, planner])


  const schema = React.useMemo((): DynamicFormSchema => {
    if (module == null || module.parameters == null) { return [] }

    return module.parameters.map((param): DynamicField => {
      const common = {
        name:     param.name,
        caption:  param.name,
        required: param.default != null,
      }

      switch (param.type) {
        case 'string': default:
          return {
            type: 'string',
            minLength: param.minLength ?? undefined,
            maxLength: param.maxLength ?? undefined,
            ...common,
          }
        case 'number':
          return {
            type:    'number',
            min:     param.min ?? undefined,
            max:     param.max ?? undefined,
            integer: param.integer ?? undefined,
            ...common,
          }
        case 'boolean':
          return {
            type: 'boolean',
            ...common,
          }
        case 'script':
          return {
            type:         'resource',
            resourceType: 'scripts',
            multi:        false,
            filters:      param.tag != null ? {tags: param.tag} : undefined,
            ...common,
          }
        case 'challenge':
          return {
            type:         'resource',
            resourceType: 'challenges',
            multi:        false,
            filters:      param.tag != null ? {tags: param.tag} : undefined,
            ...common,
          }
      }
    })
  }, [module])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog
        {...props}
        icon='puzzle'
        title={t('module.form.title')}

        width={640}
        semi={false}
        model={formModel}

        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='$name'>
          {bind => <TextField {...bind}/>}
        </FormField>
        {renderParameters()}
      </VBox>
    )
  }

  function renderParameters() {
    if (fetchStatus === 'done' && schema.length === 0) { return null }

    return (
      <VBox gap={layout.padding.inline.s}>
        <FormFieldHeader caption={t('module.form.parameters')}/>
        <Panel padding={layout.padding.s}>
          {fetchStatus === 'fetching' ? (
            <Center classNames={$.loading} padding={layout.padding.s}>
              <Spinner/>
            </Center>
          ) : (
            <DynamicFieldset schema={schema}/>
          )}
        </Panel>
      </VBox>
    )
  }

  return render()

})

export default ModuleNodeForm

const useStyles = createUseStyles({
  loading: {
    minHeight: 120,
  },
})