import Color from 'color'
import { NotificationResult, SystemNotification } from '~/models'
import { SVGName } from '~/ui/components/SVG'
import { Theme } from '~/ui/styling/Theme'

export interface NotificationSpec {
  icon:     (notification: SystemNotification) => SVGName
  color:    (notification: SystemNotification, theme: Theme) => Color
  actions:  (notification: SystemNotification) => NotificationAction[]

  colorForResult?: (notification: SystemNotification, result: NotificationResult, theme: Theme) => Color | undefined
}

export interface NotificationAction {
  name:      string
  caption?:  string
  href?:     string
  onSelect?: () => any
}

const defaultSpec: NotificationSpec = {
  icon:    () => 'info',
  color:   (_, theme) => theme.semantic.secondary,
  actions: () => [],
}

const registry: Record<string, NotificationSpec> = {}

export function registerNotification(type: string, spec: Partial<NotificationSpec>) {
  registry[type] = {...defaultSpec, ...spec}
}

export function notificationSpec(type: string): NotificationSpec {
  return registry[type] ?? defaultSpec
}