import React from 'react'
import { useSize } from 'react-measure'
import { Media } from '~/models'
import { memo } from '~/ui/component'
import { Grid, VBox } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import PostMediaThumbnail from './PostMediaThumbnail'

export interface Props {
  media: Media[]
  requestRemoveMedia?: (id: string) => any
}

const PostMediaGallery = memo('PostMediaGallery', (props: Props) => {

  const {media, requestRemoveMedia} = props

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [width, setWidth] = React.useState<number>(0)
  const height = width / 16 * 9

  useSize(containerRef, size => { setWidth(size.width) })

  const cellLayout = cellLayouts.get(media.length)!

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Grid
        classNames={$.postMediaGallery}
        ref={containerRef}
        columns={`repeat(2, 1fr)`}
        rows={`repeat(2, 1fr)`}
        style={{height}}
      >
        {media.map(renderMedia)}
      </Grid>
    )
  }

  function renderMedia(media: Media, index: number) {
    const style: React.CSSProperties = {
      gridRow:    `${cellLayout[index][0]} / span ${cellLayout[index][2]}`,
      gridColumn: `${cellLayout[index][1]} / span ${cellLayout[index][3]}`,
    }

    return (
      <VBox key={media.id} style={style}>
        <PostMediaThumbnail
          media={media}
          requestRemove={requestRemoveMedia}
        />
      </VBox>
    )

  }

  return render()

})

export default PostMediaGallery

const cellLayouts = new Map<number, Array<[number, number, number, number]>>([
  [1, [[1, 1, 2, 2]]], // Full
  [2, [[1, 1, 2, 1], [1, 2, 2, 1]]], // Side by side
  [3, [[1, 1, 2, 1], [1, 2, 1, 1], [2, 2, 1, 1]]], // Left long, right on top of each other
  [4, [[1, 1, 1, 1], [1, 2, 1, 1], [2, 1, 1, 1], [2, 2, 1, 1]]], // Two by two
])

const useStyles = createUseStyles({
  postMediaGallery: {

  },
})