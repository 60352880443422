import React from 'react'
import { useTimer } from 'react-timer'
import { Dashboard, Module } from '~/models'
import { dataStore, ModelEndpoint, projectStore } from '~/stores'
import UserCell from '~/ui/app/users/UserCell'
import { memo } from '~/ui/component'
import {
  Center,
  Checkbox,
  ClearButton,
  DataGrid,
  Dimple,
  HBox,
  Label,
  ModalDialog,
  TabPanel,
  VBox,
} from '~/ui/components'
import SVG from '~/ui/components/SVG'
import { Tab } from '~/ui/components/TabPanel'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceList } from '~/ui/resources/collection'
import { ResourceChip } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  open:          boolean
  requestClose?: () => any
}

const DashboardsDialog = memo('DashboardsDialog', (props: Props) => {

  const {open, requestClose} = props

  const {t, actionCaption} = useResourceTranslation('dashboards')

  const project = projectStore.project

  const myDashboards = useModelEndpoint(Dashboard, {
    fetch:   false,
    label:   'mine',
    include: ['module', 'user'],
  })

  const sharedDashboards = useModelEndpoint(Dashboard, {
    fetch:   false,
    label:   'shared',
    include: ['module', 'user'],
  })

  const getEndpoint = React.useCallback((tab: DashboardsTab) => {
    return tab === 'mine' ? myDashboards : sharedDashboards
  }, [myDashboards, sharedDashboards])

  const tabs = React.useMemo((): Tab<DashboardsTab>[] => [{
    name:    'mine',
    caption: t('mine'),
  }, {
    name:    'shared',
    caption: t('shared'),
  }], [t])

  const [currentTab, setCurrentTab] = React.useState<DashboardsTab>('mine')

  const switchTab = React.useCallback((tab: DashboardsTab) => {
    setCurrentTab(tab)
    getEndpoint(tab).fetch()
  }, [getEndpoint])

  const fetch = React.useCallback(() => {
    getEndpoint(currentTab).fetch()
  }, [currentTab, getEndpoint])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        onWillOpen={fetch}
        icon='gauge'
        title={actionCaption('manage')}
        headerRight={renderHeaderRight}
        width={720}
        height={640}
        children={renderBody()}
      />
    )
  }

  function renderHeaderRight(renderCloseButton: () => React.ReactNode) {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderRefreshButton()}
        <Dimple vertical/>
        {renderCloseButton()}
      </HBox>
    )
  }

  function renderRefreshButton() {
    return (
      <ClearButton
        icon='reload'
        onTap={fetch}
        tabIndex={2}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex padding={layout.padding.m}>
        <TabPanel<DashboardsTab>
          flex
          tabs={tabs}
          currentTab={currentTab}
          requestTab={switchTab}
          children={renderTab}
        />
      </VBox>
    )
  }

  function renderTab(tab: DashboardsTab) {
    return renderList(getEndpoint(tab))
  }

  function renderList(endpoint: ModelEndpoint<Dashboard>) {
    return (
      <ResourceList<Dashboard> endpoint={endpoint} allowDetail={false} allowSelect={it => !it.default}>
        <DataGrid.Column<Dashboard>
          name='name'
          sort={true}
          flex={2}
          renderCell={renderNameCell}
        />
        <DataGrid.Column<Dashboard>
          name='user'
          sort={true}
          flex={1}
          renderCell={renderUserCell}
        />
        {endpoint === myDashboards && (
          <DataGrid.Column<Dashboard>
            name='shared'
            sort={true}
            width={120}
            renderCell={renderSharedCell}
          />
        )}
      </ResourceList>
    )
  }

  const renderNameCell = React.useCallback((dashboard: Dashboard) => {
    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='gauge' size={layout.icon.m}/>
        <VBox>
          <Label flex='shrink' h3>
            {dashboard.name}
          </Label>
          {dashboard.module !== project?.id && (
            <ResourceChip Model={Module} id={dashboard.module} small/>
          )}
        </VBox>
      </HBox>
    )
  }, [project?.id])

  const renderUserCell = React.useCallback((dashboard: Dashboard) => {
    if (dashboard.user == null) {
      return <Label dim italic>{t('fields.user.default')}</Label>
    } else {
      return <UserCell id={dashboard.user}/>
    }
  }, [t])

  const renderSharedCell = React.useCallback((dashboard: Dashboard) => {
    return <SharedDashboardCell dashboard={dashboard}/>
  }, [])

  return render()

})

type DashboardsTab = 'mine' | 'shared'

interface SharedDashboardCellProps {
  dashboard: Dashboard
}

const SharedDashboardCell = memo('SharedDashboardCell', (props: SharedDashboardCellProps) => {

  const {dashboard} = props
  const timer       = useTimer()

  const [shared, setShared] = React.useState<boolean>(dashboard.shared)

  const toggle = React.useCallback(async (shared: boolean) => {
    setShared(shared)
    const result = await timer.await(
      dataStore.update(Dashboard, dashboard.id, {shared}),
    )
    if (result?.status !== 'ok') {
      setShared(!shared)
    }
  }, [dashboard.id, timer])

  function render() {
    return (
      <Center>
        <Checkbox
          checked={shared}
          onChange={toggle}
          enabled={!dashboard.default}
        />
      </Center>
    )
  }

  return render()

})

export default DashboardsDialog