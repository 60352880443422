import { action, makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import config from '~/config'
import { Feed, Media, Post } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class PostFormModel implements FormModel {

  constructor(
    private readonly feed: Feed,
    private readonly post: Post | null,
  ) {
    makeObservable(this)
  }

  public reset() {
    this.author = this.post?.author ?? null
    this.body   = this.post?.body ?? null
    this.notify = false
  }

  @observable
  public author = this.post?.author ?? null

  @observable
  public body = this.post?.body ?? null

  @observable
  public notify = false

  //------
  // Media

  @observable
  public media = this.post?.media ?? []

  public get mayAddMedia() {
    return this.media.length < config.news.maxMediaCount
  }

  @action
  public addMedia(media: Media) {
    if (!this.mayAddMedia) { return }
    this.media.push(media.id)
  }

  @action
  public removeMedia(id: string) {
    this.media = this.media.filter(it => it !== id)
  }

  //------
  // Submit

  public async submit(): Promise<SubmitResult | undefined> {
    const data = {
      feed:   this.feed.id,
      author: this.author,
      body:   cleanTextValue(this.body, true),
      media:  this.media,
    }

    if (this.post == null) {
      return dataStore.create(Post, data, {notify: this.notify})
    } else {
      return dataStore.update(Post, this.post.id, data)
    }
  }

}