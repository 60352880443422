import React from 'react'
import { Module } from '~/models'
import { projectStore } from '~/stores'
import { memo } from '~/ui/component'
import { Chip, DataGrid, HBox, Label, SVG } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceListScreen } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import ModuleForm from '../ModuleForm'

const ModulesScreen = memo('ModulesScreen', () => {

  const {t} = useResourceTranslation('modules')
  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen<Module>
        Model={Module}
        CreateFormComponent={ModuleForm}
        defaultSort='name'
        allowSelect={module => !module.main}
        allowRemove={false}
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column
          name='features'
          renderCell={renderFeatures}
          flex={3}
        />
      </ResourceListScreen>
    )
  }

  function renderName(module: Module) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name='puzzle'
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <Label bold>
          {module.name}
        </Label>
        {module.main && (
          <Chip small backgroundColor={colors.semantic.primary}>
            {t('main')}
          </Chip>
        )}
      </HBox>
    )
  }

  function renderFeatures(module: Module) {
    const features = projectStore.moduleFeatures
      .filter(f => module.features[f.name]?.enabled)

    return (
      <HBox gap={layout.padding.inline.s}>
        {features.map(feature => (
          <Chip key={feature.name} small>
            {feature.translate('caption')}
          </Chip>
        ))}
      </HBox>
    )
  }

  return render()

})

export default ModulesScreen