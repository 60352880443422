import i18next from 'i18next'
import { uniq } from 'lodash'
import { TalliedResult } from '~/models'
import { Result, ResultSegment } from '~/stores'

export function pivotData(results: Array<Result & TalliedResult>): [DataPoint[], ResultSegment[]] {
  const data: Record<string, DataPoint> = {}

  const segments = uniq(results.map(result => result.segment))

  for (const result of results) {
    for (const count of result.counts) {
      const answer = count.answer.toString()

      let point = data[answer]
      if (point == null) {
        point = {answer, values: {}}
        for (const segment of segments) {
          point.values[segment.value] = 0
        }
        data[answer] = point
      }

      point.values[result.segment.value] = count.count
    }
  }

  return [Object.values(data), segments]
}

export function segmentCaption(segment: ResultSegment) {
  if (segment.value === 'all') {
    return i18next.t('answers:segments.all')
  } else {
    return segment.caption
  }
}

export interface DataPoint {
  answer: string
  values: {[segment: string]: number}
}