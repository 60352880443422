import React from 'react'
import { DateTime } from 'luxon'
import { Challenge } from '~/models'
import { observer } from '~/ui/component'
import { DateTimeField, TimeIntervalField, VBox } from '~/ui/components'
import { FormField, FormProps, FormSwitch } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog, ResourceFormModel } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  model: Challenge
}

const ChallengeDeadlineForm = observer('ChallengeDeadlineForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props
  const {t, actionCaption} = useResourceTranslation('challenges')

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Challenge}
        model={model}

        icon='clock'
        title={actionCaption('edit_deadline')}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        <FormSwitch>
          <FormSwitch.Option<ResourceFormModel<Challenge>>
            {...t('deadline.none')}
            selected={model => model.getValue('lifetime') == null && model.getValue('deadline') == null}
            initialData={{lifetime: null, deadline: null}}
          />
          <FormSwitch.Option<ResourceFormModel<Challenge>>
            {...t('deadline.deadline')}
            selected={model => model.getValue('lifetime') == null && model.getValue('deadline') != null}
            initialData={{lifetime: null, deadline: DateTime.local()}}
            renderForm={renderDeadlineForm}
          />
          <FormSwitch.Option<ResourceFormModel<Challenge>>
            {...t('deadline.lifetime')}
            selected={model => model.getValue('lifetime') != null && model.getValue('deadline') == null}
            initialData={{lifetime: '1w', deadline: null}}
            renderForm={renderLifetimeForm}
          />
        </FormSwitch>
      </VBox>
    )
  }

  function renderDeadlineForm() {
    return (
      <FormField name='deadline' caption={false} instruction={false}>
        {bind => <DateTimeField {...bind} inputStyle='dark'/>}
      </FormField>
    )
  }

  function renderLifetimeForm() {
    return (
      <FormField name='lifetime' caption={false} instruction={false}>
        {bind => <TimeIntervalField {...bind} inputStyle='dark'/>}
      </FormField>
    )
  }

  return render()

})

export default ChallengeDeadlineForm