import React from 'react'
import { memo, observer } from '~/ui/component'

export function cell<T>(name: string, Component: React.ComponentType<CellProps<T>>): CellComponentType<T> {
  const Cell = memo(name, Component) as unknown as CellComponentType<T>
  Cell.renderer = (item, index) => React.createElement<CellProps<T>>(Component, {item, index})
  Cell.for      = <U>(mapper: (item: U) => T) => cell<U>(name, props => (
    React.createElement(Component, {
      item:  mapper(props.item),
      index: props.index,
    })
  ))

  return Cell
}

export function observerCell<T>(name: string, Component: React.ComponentType<CellProps<T>>): CellComponentType<T> {
  const Cell = observer(name, Component) as CellComponentType<T>
  Cell.renderer = (item, index) => React.createElement<CellProps<T>>(Component, {item, index})
  Cell.for      = <U>(mapper: (item: U) => T) => cell<U>(name, props => (
    React.createElement(Component, {
      item:  mapper(props.item),
      index: props.index,
    })
  ))

  return Cell
}

export interface CellProps<T> {
  item:   T
  index?: number
}

export type CellComponentType<T> = React.ComponentType<CellProps<T>> & {
  renderer: (item: T, index: number) => React.ReactElement<CellProps<T>>
  for:      <U>(mapper: (item: U) => T) => CellComponentType<U>
}