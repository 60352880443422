import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { ExitNode } from '~/models'
import { FlowPlanner } from '~/stores'
import { memo } from '~/ui/component'
import { TextBlock, TextField, VBox } from '~/ui/components'
import { FormDialog, FormField, FormModel, SubmitResult } from '~/ui/form'
import { layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  open:         boolean
  requestClose: () => any
  node:         ExitNode
}

const ExitNodeForm = memo('ExitNodeForm', (props: Props) => {

  const {open, requestClose, node} = props
  const {planner} = useFlowPlanner()

  const formModel = React.useMemo(
    () => planner == null ? null : new ExitNodeFormModel(planner, node),
    [node, planner],
  )

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog open={open} requestClose={requestClose} model={formModel} title={t('nodes.exit.caption')}>
        <VBox gap={layout.padding.m}>
          <TextBlock markup small>
            {t('nodes.exit.detail')}
          </TextBlock>

          {renderFields()}
        </VBox>
      </FormDialog>
    )
  }

  function renderFields() {
    return (
      <FormField name='outlet' required caption={t('nodes.exit.fields.outlet')!}>
        {bind => <TextField {...bind} mono showClearButton='notempty'/>}
      </FormField>
    )
  }

  return render()

})

export default ExitNodeForm

export class ExitNodeFormModel implements FormModel {

  constructor(
    private readonly planner: FlowPlanner,
    private readonly node: ExitNode,
  ) {
    makeObservable(this)
  }

  @observable
  public outlet: string = this.node.outlet ?? ''

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.planner.updateComponents([this.node.uuid], node => ({
      ...node,
      outlet: cleanTextValue(this.outlet),
    }))
  }

}