import React from 'react'
import { useTimer } from 'react-timer'
import { ChallengeTask, LocalizedString } from '~/models'
import { memo } from '~/ui/component'
import {
  ConfirmBox,
  Dimple,
  KebabMenu,
  Label,
  Panel,
  PanelHeader,
  PopupMenuItem,
  VBox,
} from '~/ui/components'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import ChallengeTaskBody from './ChallengeTaskBody'
import ChallengeTaskQuestionSummary from './ChallengeTaskQuestionSummary'
import { useChallengeTasksEditor } from './ChallengeTasksEditorContext'

export interface Props {
  task:          ChallengeTask
  requestEdit:   (task: ChallengeTask) => any
  requestCreate: (atIndex?: number) => any
}

const ChallengeTaskPanel = memo('ChallengeTaskPanel', (props: Props) => {

  const {task} = props
  const {updateTask} = useChallengeTasksEditor()
  const [editingBody, startEditBody, stopEditBody] = useBoolean()

  const timer = useTimer()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel flex classNames={$.challengeTaskPanel} depth={2} header={renderHeader()}>
        {renderBody()}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <ChallengeTaskPanelHeader
        {...props}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex padding={layout.padding.s} gap={layout.padding.s}>
        <VBox flex classNames={$.body}>
          <ChallengeTaskBody
            task={task}
            requestSave={save}
            editing={editingBody}
            requestStartEdit={startEditBody}
            requestStopEdit={stopEditBody}
          />
        </VBox>
        {renderQuestionSummary()}
      </VBox>
    )
  }

  function renderQuestionSummary() {
    return (
      <>
        <Dimple horizontal/>
        <ChallengeTaskQuestionSummary
          task={task}
          saveTask={save}
        />
      </>
    )
  }

  const save = React.useCallback(async (data: AnyObject): Promise<SubmitResult | undefined> => {
    const result = await timer.await(updateTask(task.uuid, data))
    return translateFormErrorPaths(result, path => path.replace(/^tasks\.\d+\./, ''))
  }, [task.uuid, updateTask, timer])

  return render()

})

interface HeaderProps extends Props {
}

const ChallengeTaskPanelHeader = memo('ChallengeTaskPanelHeader', (props: HeaderProps) => {

  const {task, requestEdit, requestCreate} = props
  const {tasks, removeTask, pasteTasks, copyTasks, tasksInClipboard} = useChallengeTasksEditor()
  const {actionCaption, actionConfirm} = useResourceTranslation()

  const taskIndex = React.useMemo(
    () => tasks.findIndex(t => t.uuid === task.uuid), [task.uuid, tasks],
  )

  const {colors} = useStyling()

  const timer = useTimer()

  //------
  // Rendering

  function render() {
    return (
      <PanelHeader
        icon='challenge'
        caption={renderLabels()}
        right={<KebabMenu items={kebabMenuItems}/>}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox gap={-2}>
        <Label h3>
          {LocalizedString.translate(task.title)}
        </Label>
        {task.subtitle != null && (
          <Label small>
            {LocalizedString.translate(task.subtitle)}
          </Label>
        )}
      </VBox>
    )
  }

  const edit = React.useCallback(() => {
    requestEdit(task)
  }, [requestEdit, task])

  const copy = React.useCallback(() => {
    copyTasks([task.uuid])
  }, [copyTasks, task])

  const insertBefore = React.useCallback(() => {
    requestCreate(taskIndex)
  }, [requestCreate, taskIndex])

  const insertAfter = React.useCallback(() => {
    requestCreate(taskIndex + 1)
  }, [requestCreate, taskIndex])

  const pasteBefore = React.useCallback(async () => {
    return await timer.await(pasteTasks(taskIndex))
  }, [pasteTasks, taskIndex, timer])

  const pasteAfter = React.useCallback(async () => {
    return await timer.await(pasteTasks(taskIndex + 1))
  }, [pasteTasks, taskIndex, timer])

  const remove = React.useCallback(async () => {
    const confirmed = await timer.await(ConfirmBox.show({
      ...actionConfirm('remove_task'),
      destructive: true,
    }))
    if (!confirmed) { return }
    return await removeTask(task.uuid)
  }, [actionConfirm, task.uuid, removeTask, timer])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'pencil',
      caption:  actionCaption('edit_task'),
      onSelect: edit,
    })
    items.push({section: '-'})
    items.push({
      icon:     'plus-circle',
      caption:  actionCaption('insert_task_before'),
      onSelect: insertBefore,
    })
    items.push({
      icon:     'plus-circle',
      caption:  actionCaption('insert_task_after'),
      onSelect: insertAfter,
    })
    items.push({section: '-'})
    items.push({
      icon:     'copy',
      caption:  actionCaption('copy_task'),
      onSelect: copy,
    })
    if (tasksInClipboard.length > 0) {
      items.push({
        icon: 'paste',
        caption: actionCaption('paste_tasks_before', {count: tasksInClipboard.length}),
        onSelect: pasteBefore,
      })
      items.push({
        icon: 'paste',
        caption: actionCaption('paste_tasks_after', {count: tasksInClipboard.length}),
        onSelect: pasteAfter,
      })
    }
    items.push({section: '-'})
    items.push({
      icon:     'trash',
      caption:  actionCaption('remove_task'),
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [actionCaption, colors.semantic.negative, edit, remove, copy, insertBefore, insertAfter, pasteBefore, pasteAfter, tasksInClipboard])

  return render()

})

export default ChallengeTaskPanel

export const challengeTaskPanelWidth = 390

const useStyles = createUseStyles({
  challengeTaskPanel: {
    width: challengeTaskPanelWidth,
  },

  body: {
    ...layout.responsive(size => ({
      margin: -layout.padding.s[size],
    })),
  },
})