import I18next from 'i18next'
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { Fetch } from 'mobx-document'
import { cleanTextValue } from 'ytil'
import { FormError, FormModel, SubmitResult } from '~/ui/form'
import {
  GroupInfo,
  ProjectInfo,
  RegistrationData,
  RegistrationTranslationKey,
  RegistrationWidgetModel,
} from './types'

export default class RegistrationFormModel implements FormModel {

  constructor(
    public readonly widgetModel: RegistrationWidgetModel,
  ) {
    makeObservable(this)

    this.disposers = [
      reaction(() => this.availableProjects, this.configureProject.bind(this)),
      reaction(() => this.availableGroups, this.configureGroup.bind(this)),
    ]
  }

  public dispose() {
    this.disposers.forEach(it => it())
  }

  private disposers: IReactionDisposer[] = []

  @observable
  public code: string = ''

  @observable
  public firstName: string = ''

  @observable
  public lastName: string = ''

  @observable
  public email: string = ''

  @observable
  public project: string | null = null

  @observable
  public group: string | null = null

  //------
  // Projects & groups

  @computed
  public get availableProjects(): Fetch<ProjectInfo[]> {
    return this.widgetModel.availableProjects()
  }

  @observable
  public showProjectField: boolean = false

  @computed
  public get availableGroups(): Fetch<GroupInfo[]> {
    if (this.project == null) { return {status: 'done', data: []} }
    return this.widgetModel.availableGroups(this.project)
  }

  @observable
  public showGroupField: boolean = false

  @action
  private configureProject(projects: Fetch<ProjectInfo[]>) {
    if (projects.status !== 'done') { return }

    if (projects.data.some(it => it.id === this.project)) { return }

    this.project          = projects.data.length === 1 ? projects.data[0].id : null
    this.showProjectField = projects.data.length > 0
  }

  @action
  private configureGroup(groups: Fetch<GroupInfo[]>) {
    if (groups.status !== 'done') { return }

    if (groups.data.some(it => it.id === this.group)) { return }

    this.group          = groups.data.length === 1 ? groups.data[0].id : null
    this.showGroupField = this.project != null && groups.data.length > 0
  }

  //------
  // Submission

  public get maySubmit() {
    return true
  }

  public async submit(): Promise<SubmitResult | undefined> {
    if (this.widgetModel.status === 'code') {
      return await this.checkCode()
    } else {
      return await this.register()
    }
  }

  //------
  // Check code

  private async checkCode(): Promise<SubmitResult> {
    const result = this.validateCode()
    if (result.status !== 'ok') { return result }

    const response = await this.widgetModel.preflight(this.code)
    if (response === 'ok') {
      return {status: 'ok'}
    } else {
      return {
        status: 'invalid',
        errors: [{
          field:   'code',
          code:    response,
          message: this.widgetModel.translate(`errors.${response}.detail` as RegistrationTranslationKey),
        }],
      }
    }
  }

  private validateCode(): SubmitResult {
    const errors: FormError[] = []
    if (this.code.length < 4) {
      errors.push({
        field: 'code',
        code: 'string.too_short',
        message: this.widgetModel.translate('errors.invalid-code.detail' as RegistrationTranslationKey),
      })
    }

    if (errors.length === 0) {
      return {status: 'ok'}
    } else {
      return {status: 'invalid', errors}
    }
  }

  //------
  // Register

  private async register() {
    const result = this.validateRegistrationData()
    if (result.status !== 'ok') { return result }

    return await this.widgetModel.register(
      this.registrationData(),
    )
  }

  private validateRegistrationData(): SubmitResult {
    const errors: FormError[] = []

    if (this.firstName.trim().length === 0) {
      errors.push({field: 'firstName', message: I18next.t('validation:required')})
    }
    if (this.email.trim().length === 0) {
      errors.push({field: 'email', message: I18next.t('validation:required')})
    } else if (!/^\w+([.+-]?\w+)*@\w+([.-]?\w+)+$/.test(this.email)) {
      errors.push({field: 'email', message: I18next.t('validation:email')})
    }
    if (this.showProjectField && this.project == null) {
      errors.push({field: 'project', message: I18next.t('validation:required')})
    }
    if (this.showGroupField && this.group == null) {
      errors.push({field: 'group', message: I18next.t('validation:required')})
    }

    if (errors.length === 0) {
      return {status: 'ok'}
    } else {
      return {status: 'invalid', errors}
    }
  }

  private registrationData(): RegistrationData {
    return {
      firstName: this.firstName.trim(),
      lastName:  cleanTextValue(this.lastName, true),
      email:     this.email,
      project:   this.project,
      group:     this.group,
    }
  }

}