import React from 'react'
import { isFunction } from 'lodash'
import { VBox, VBoxProps } from '~/ui/components'
import { isSuccessResult, SubmitResult } from '~/ui/form'
import { assignRef } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { flexStyle } from '../components/layout/styles'
import { focusFirstInvalidField } from './focus'
import { FormContainer, FormContext } from './FormContext'
import { FormContainerHookOptions, useFormContainer } from './hooks'
import { FormModel } from './types'

export interface Props<M extends FormModel> extends FormContainerHookOptions<M> {
  classNames?: React.ClassNamesProp
  children?:   React.ReactNode | ((form: FormContext<M>) => React.ReactNode)

  formRef?:    React.Ref<FormContext<any>>
  elementRef?: React.Ref<HTMLFormElement>

  flex?:     VBoxProps['flex']
  fieldGap?: VBoxProps['gap']
}

const Form = <M extends FormModel>(props: Props<M>) => {

  const {
    children,
    classNames,
    formRef,
    elementRef,
    flex,
    fieldGap = layout.padding.m,
    afterSubmit: props_afterSubmit,
    ...rest
  } = props

  const formElementRef = React.useRef<HTMLFormElement>(null)

  const afterSubmit = React.useCallback((result: SubmitResult, model: M) => {
    if (!isSuccessResult(result) && formElementRef.current != null) {
      focusFirstInvalidField(formElementRef.current)
    }
    props_afterSubmit?.(result, model)
  }, [props_afterSubmit])

  const form = useFormContainer({
    ...rest,
    afterSubmit,
  })

  React.useEffect(() => {
    assignRef(formRef, form)
  }, [formRef, form])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <FormContainer form={form}>
        <form classNames={[$.form, classNames]} style={flexStyle(flex) ?? undefined} onSubmit={form.submit} ref={formElementRef}>
          <VBox flex={flex} gap={fieldGap}>
            {isFunction(children) ? (
              children(form)
            ) : (
              children
            )}
          </VBox>
        </form>
      </FormContainer>
    )
  }

  return render()

}

export default Form

const useStyles = createUseStyles({
  form: {
    ...layout.flex.column,
  },
})