import React from 'react'
import { Post } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Panel, TextBlock, VBox } from '~/ui/components'
import { SelectionCheckbox } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import { findLinks } from '~/ui/util'
import CommentHeader from './CommentHeader'

export interface Props {
  postID?:      string
  comment:      Post
  requestEdit?: (comment: Post) => any
}

const CommentView = memo('CommentView', (props: Props) => {

  const {postID, comment, requestEdit} = props

  const links = React.useMemo(
    () => findLinks(comment.body ?? ''),
    [comment.body],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false} classNames={$.commentView}>
        <HBox gap={layout.padding.xs} align='top'>
          <SelectionCheckbox
            id={comment.id}
            small
          />
          {renderBody()}
        </HBox>
      </Panel>
    )
  }

  function renderBody() {
    return (
      <VBox flex gap={layout.padding.inline.xs}>
        <CommentHeader
          postID={postID}
          comment={comment}
          requestEdit={requestEdit}
        />
        {renderMain()}
      </VBox>

    )
  }

  function renderMain() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <TextBlock markup small links={links} flex>
          {comment.body}
        </TextBlock>
      </HBox>
    )
  }

  return render()

})

export default CommentView

const useStyles = createUseStyles({
  commentView: {
    padding: [layout.padding.inline.m, layout.padding.inline.m],
  },
})