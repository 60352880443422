import React from 'react'
import { Media, SVGImage } from '~/models'
import { LinkFormModel } from '~/ui/app/links'
import { MediaUploaderState } from '~/ui/app/media'
import MediaUploader from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { useForm } from '~/ui/form'

const LinkFormUploadFields = memo('LinkFormUploadFields', () => {

  const {model: formModel} = useForm<LinkFormModel>()

  const handleUploadComplete = React.useCallback((media: Media | SVGImage | null) => {
    if (!(media instanceof Media)) { return }

    formModel.set(media.url, media.name)
  }, [formModel])

  //------
  // Rendering

  function render() {
    return (
      <MediaUploader
        accept='*'
        renderContent={renderUploaderContent}
        onUploadComplete={handleUploadComplete}
        inlineSVG
        flex
      />
    )
  }

  function renderUploaderContent(state: MediaUploaderState) {
    return(
      <>
        {state.renderDropHint()}
        {state.renderUploading()}
      </>
    )
  }

  return render()

})

export default LinkFormUploadFields