import React from 'react'
import { Widget, WidgetAlotment } from '~/models'
import { DataPoint, WidgetState } from '~/stores'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { DonutChart, Legend } from '~/ui/components/datavis'
import { layout, useStyling } from '~/ui/styling'
import { widget } from './registry'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  state:    WidgetState | null
}

const DonutChartWidgetView = memo('DonutChartWidgetView', (props: Props) => {

  const {widget, state} = props

  const data     = state?.data ?? []
  const {colors} = useStyling()

  let palette = state?.config.palette ?? 'default'
  if (palette === 'alpha') {
    palette = {alpha: colors.semantic.neutral}
  }
  if (palette === 'fade') {
    palette = {fade: colors.semantic.neutral}
  }

  //------
  // Rendering

  function render() {
    return (
      <VBox flex gap={layout.padding.inline.l}>
        {renderDonut()}
        {renderLegend()}
      </VBox>
    )
  }

  function renderDonut() {
    return (
      <DonutChart
        data={data}
        valueForPoint={p => p.value}
        palette={palette}
        flex
      />
    )
  }

  function renderLegend() {
    return (
      <Legend
        data={data}
        captionForPoint={captionForPoint}
        valueForPoint={valueForPoint}
        palette={palette}
      />
    )
  }

  const captionForPoint = React.useCallback((point: DataPoint) => {
    return widget.label(point) ?? '??'
  }, [widget])

  const valueForPoint = React.useCallback((point: DataPoint) => {
    return point.value
  }, [])

  return render()

})

widget('donut')(DonutChartWidgetView)
export default DonutChartWidgetView