import React from 'react'
import { SystemNotification } from '~/models'
import { notificationSpec } from '~/notifications'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import NotificationActionButton from '../NotificationActionButton'

export interface Props {
  notification: SystemNotification
}

const NotificationActionsList = memo('NotificationActionsList', (props: Props) => {

  const {notification} = props

  const spec    = notificationSpec(notification?.type ?? '')
  const actions = spec.actions(notification)

  //------
  // Rendering

  function render() {
    if (actions.length === 0) { return null }

    return (
      <VBox gap={layout.padding.inline.m} align='left'>
        {actions.map(action => (
          <NotificationActionButton
            key={action.name}
            action={action}
            type='push'
          />
      ))}
      </VBox>
    )
  }

  return render()

})

export default NotificationActionsList