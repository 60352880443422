import { useHistory, useRouteMatch } from 'react-router-dom'
import { ResourceDetailParams, resourceDetailPath } from '../routes'

export function useResourceDetailLocation<B extends string = string>(): ResourceDetailLocation<B> {
  const history  = useHistory()
  const {params} = useRouteMatch<ResourceDetailParams<B>>()

  const {type, id, body} = params
  const setBody = (body: string) => {
    const path = resourceDetailPath(type, id, body)
    history.replace(path)
  }

  return {body, setBody}
}

export interface ResourceDetailLocation<B extends string> {
  body:    B | undefined
  setBody: (body: B) => void
}