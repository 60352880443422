import React from 'react'
import Color from 'color'
import * as UUID from 'uuid'
import { PlanSegue } from '~/models'
import { memo } from '~/ui/component'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import { Direction } from '../canvas/types'
import {
  calculatePathGravityPoint,
  calculatePointOnPath,
  GravityPointAlgorithm,
  offsetAcross,
  positivePerpendicular,
  segmentMinLength,
  seguePath,
} from './paths'

export interface Props {
  segue:     PlanSegue | null
  from:      SegueArrowEnd
  to:        SegueArrowEnd | Point
  color?:    Color
  thick?:    boolean

  startContent?:  React.ReactNode
  middleContent?: React.ReactNode

  classNames?: React.ClassNamesProp
}

export interface SegueArrowEnd {
  point:     Point
  direction: Direction
}

const SegueArrow = memo('SegueArrow', (props: Props) => {

  const {from, to, color, thick, startContent, middleContent, classNames} = props

  const toEnd = React.useMemo((): SegueArrowEnd => {
    if ('x' in to) {
      const crossOffset = Math.abs(offsetAcross(from.point, to, from.direction))
      const direction   = crossOffset < segmentMinLength ? from.direction : positivePerpendicular(from, to)
      return {point: to, direction}
    } else {
      return to
    }
  }, [from, to])

  const strokeWidth = strokeWidths[thick ? 'thick' : 'normal']
  const arrowSize   = arrowSizes[thick ? 'thick' : 'normal']

  const path = React.useMemo(
    () => seguePath(from, toEnd, arrowSize),
    [arrowSize, from, toEnd],
  )

  const uid = React.useMemo(() => UUID.v4().slice(0, 8), [])


  //------
  // Attributes

  const [startPoint, setStartPoint] = React.useState<Point | null>(null)
  const [middlePoint, setMiddlePoint] = React.useState<Point | null>(null)

  React.useLayoutEffect(() => {
    setStartPoint(calculatePointOnPath(from, toEnd, 0))
    setMiddlePoint(calculatePathGravityPoint(from, toEnd, GravityPointAlgorithm.LONGEST))
  }, [from, toEnd])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={[$.segueArrow, classNames]}>
        {renderSVG()}
        {renderContent($.startContent, startContent, startPoint, from.direction)}
        {renderContent($.middleContent, middleContent, middlePoint)}
      </div>
    )
  }

  function renderSVG() {
    return (
      <svg classNames={$.svg} width={100} height={100} viewBox='0 0 100 100'>
        <defs>
          <marker
            id={`${uid}-arrow`}
            viewBox={`0 0 ${arrowSize} ${arrowSize}`}
            refX={0}
            refY={arrowSize / 2}
            markerUnits='userSpaceOnUse'
            markerWidth={arrowSize}
            markerHeight={arrowSize}
            orient='auto'
          >
            <path
              d={`M 0 0  L ${arrowSize} ${arrowSize / 2}  L 0 ${arrowSize} Z`}
              style={{fill: color?.string()}}
              strokeWidth={0}
              classNames='marker'
            />
          </marker>
        </defs>
        {/* Add an invisible thicker path behind the actual path for hit testing. */}
        <path
          d={path}
          stroke='transparent'
          strokeWidth={32}
          fill='none'
          markerEnd={`url(#${uid}-arrow)`}
          classNames={$.hitTestPath}
        />
        <path
          classNames='line'
          d={path}
          style={{stroke: color?.string()}}
          strokeWidth={strokeWidth}
          fill='none'
          markerEnd={`url(#${uid}-arrow)`}
        />
      </svg>
    )
  }

  function renderContent(classNames: React.ClassNamesProp, content: React.ReactNode, point: Point | null, direction?: Direction) {
    if (content == null || point == null) { return null }

    const vertical = direction === Direction.UP || direction === Direction.DOWN
    const style: React.CSSProperties = {
      left: point.x,
      top:  point.y,
    }

    return (
      <div classNames={[$.content, {vertical}, classNames]} style={style}>
        {content}
      </div>
    )
  }

  return render()

})

export default SegueArrow

export const strokeWidths = {thick: 3, normal: 2}
export const arrowSizes   = {thick: 16, normal: 12}

const useStyles = createUseStyles(theme => ({
  segueArrow: {
    position: 'absolute',
    overflow: 'visible',
    top:      0,
    left:     0,
    width:    100,
    height:   100,

    pointerEvents: 'none',

    '&:hover': {
      filter: `drop-shadow(0 1px 2px ${shadows.shadowColor})`,

      '& .line': {
        stroke: theme.semantic.secondary.darken(0.2),
      },
      '& .marker': {
        fill: theme.semantic.secondary.darken(0.2),
      },
    },
  },

  svg: {
    position:      'absolute',
    overflow:      'visible',
    top:           0,
    left:          0,
    width:         100,
    height:        100,

    '& .line': {
      stroke: theme.semantic.secondary,
    },
    '& .marker': {
      fill: theme.semantic.secondary,
    },
  },

  content: {
    position:   'absolute',
    overflow:   'visible',
    width:      1,
    height:     1,
    marginLeft: -0.5,
    marginTop:  -0.5,

    '&.vertical': {
      transform: 'rotateZ(90deg)',
    },
  },

  startContent: {
    ...layout.row(0),
  },

  middleContent: {
    ...layout.column(0, 'center'),
    justifyContent: 'center',
  },

  hitTestPath: {
    pointerEvents: 'auto',
  },
}))