import React from 'react'
import { Channel, Participant } from '~/models'
import { ParticipantField } from '~/ui/app/fields'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  channel: Channel

  participantID:      string | null
  requestParticipant: (participant: Participant | null) => any
}

const ChannelHeader = memo('ChannelHeader', (props: Props) => {

  const {t} = useResourceTranslation('channels')
  const {channel, participantID, requestParticipant} = props

  const clearParticipant = React.useCallback(() => {
    requestParticipant(null)
  }, [requestParticipant])

  //------
  // Rendering

  function render() {
    return (
      <HBox padding={layout.padding.m} gap={layout.padding.l} align='top' justify='center'>
        <Avatar
          source={channel.image}
          firstName={channel.name}
          size={avatarSize}
        />
        <VBox flex={{basis: 320, shrink: true}} gap={layout.padding.inline.m}>
          <Label h1>
            {channel.name}
          </Label>
          <ParticipantField
            value={participantID}
            onSelect={requestParticipant}
            onClear={clearParticipant}
            placeholder={t('body.view_as')}
            small
          />
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default ChannelHeader

export const avatarSize = {
  width:  72,
  height: 72,
}