import React from 'react'
import I18next from 'i18next'
import { CustomImage, Media, SVGImage } from '~/models'
import { StoreMediaOptions } from '~/stores'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { ClearButton, HBox, TextFieldProps, VBox } from '~/ui/components'
import { FieldChangeCallback, invokeFieldChangeCallback } from '~/ui/form'
import { colors, createUseStyles, layout, presets, ThemeProvider } from '~/ui/styling'

export interface Props {
  value:    CustomImage | null
  onChange: ((value: CustomImage | null) => any) | FieldChangeCallback<CustomImage | null>

  allowClear?:        boolean
  allowedTypes?:      CustomImage['type'][]
  storeMediaOptions?: StoreMediaOptions

  objectFit?:  React.CSSProperties['objectFit']
  size?:       Partial<Size>

  inputStyle?: TextFieldProps['inputStyle']
  inlineSVG?:  boolean
}

const CustomImageField = memo('CustomImageField', (props: Props) => {

  const {
    value,
    onChange,
    allowClear   = true,
    allowedTypes = [],
    storeMediaOptions,
    objectFit    = value?.type === 'svg' ? 'contain' : 'cover',
    inputStyle,
    inlineSVG = true,
    size,
  } = props

  const previewURL =
    value?.type === 'remote' ? value.url :
    value?.type === 'svg' ? `data:image/svg+xml;base64,${value.base64}` :
    null

  const uploadComplete = React.useCallback((image: Media | SVGImage | null) => {
    if (image == null) {
      invokeFieldChangeCallback(onChange, null, false)
    } else if (image instanceof Media) {
      invokeFieldChangeCallback(onChange, {
        type: 'remote',
        url:  image.url,
      }, false)
    } else {
      invokeFieldChangeCallback(onChange, image, false)
    }
  }, [onChange])

  const clear = React.useCallback(() => {
    invokeFieldChangeCallback(onChange, null, false)
  }, [onChange])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox align='stretch' classNames={$.customImageField} style={{...size}}>
        <VBox>
          <MediaUploader
            accept={accept(allowedTypes)}
            storeMediaOptions={storeMediaOptions}
            previewURL={previewURL ?? undefined}
            objectFit={objectFit}
            renderContent={renderContent}
            onUploadComplete={uploadComplete}
            inlineSVG={inlineSVG}
          />
        </VBox>
      </HBox>
    )
  }

  function renderContent(state: MediaUploaderState) {
    return (
      <VBox flex='grow' justify='bottom' classNames={[$.content, inputStyle, {empty: previewURL == null}]}>
        {state.renderPreview()}
        {state.renderDropHint()}
        {state.renderUploading()}
        {renderActions()}
      </VBox>
    )
  }

  function renderActions() {
    if (previewURL == null) { return null }

    return (
      <ThemeProvider dark>
        <HBox justify='center' classNames={$.actions} onClick={event => event.stopPropagation()}>
          {allowClear && renderClearButton()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderClearButton() {
    return (
      <ClearButton
        icon='cross'
        caption={I18next.t('custom_image_field:clear')}
        onTap={clear}
      />
    )
  }

  return render()

})

export const accept = (allowedTypes: CustomImage['type'][]) => {
  if (allowedTypes.length === 0) {
    return ['image/png', 'image/jpeg', 'image/svg+xml']
  } else if (allowedTypes.includes('remote')) {
    return ['image/png', 'image/jpeg']
  } else if (allowedTypes.includes('svg')) {
    return ['image/svg+xml']
  } else {
    return []
  }
}

export const minHeight    = 112
export const defaultWidth = 160

export default CustomImageField

const useStyles = createUseStyles(theme => ({
  customImageField: {
    minHeight,

    '& > *': {
      flex: [1, 1, `${defaultWidth}px`],
    },
  },

  content: {
    ...presets.field(theme),
    borderRadius: layout.radius.m,
    background:   theme.bg.alt,
    overflow:     'hidden',
    padding:      '0 !important',
  },

  actions: {
    position:                'relative',
    background:              colors.shim.medium,
    borderBottomLeftRadius:  layout.radius.m,
    borderBottomRightRadius: layout.radius.m,
  },
}))