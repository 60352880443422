import React from 'react'
import { focusFirst } from 'react-autofocus'
import { useSize } from 'react-measure'
import { rectExtents, svgPath } from 'ytil'
import { observer } from '~/ui/component'
import { HBox, Panel, SwitchField, VBox } from '~/ui/components'
import { FormField, FormFieldProps } from '~/ui/form'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { useFormField } from '~/ui/form/hooks'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { useQualifiedID } from '../datavis/chart/hooks'

export interface Props<T> extends Omit<FormFieldProps<T>, 'children'> {
  switchName: string

  mode?:   'hide' | 'disable'
  layout?: 'stack' | 'box'

  defaultValue?: () => T
  children:      (bind: FormFieldBindProps<T>) => React.ReactNode
}

const _ConditionalField = <T extends any = any>(props: Props<T>) => {

  const {
    switchName,
    mode = 'disable',
    layout: fieldLayout = 'stack',
    defaultValue,
    children,
    ...rest
  } = props

  const [switchValue]   = useFormField<boolean>(switchName)
  const prevSwitchValue = usePrevious(switchValue)
  const showValue       = switchValue || mode !== 'hide'

  const theme  = useTheme()
  const maskID = useQualifiedID('mask')

  const switchFieldRef = React.useRef<HTMLDivElement>(null)
  const valueFieldRef  = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (prevSwitchValue === undefined) { return }
    if (switchValue === prevSwitchValue) { return }

    const valueField = valueFieldRef.current
    if (valueField) {
      focusFirst(valueField, {select: true})
    }
  }, [prevSwitchValue, switchValue])

  //------
  // Rendering

  const $ = useStyles(maskID)

  function render() {
    if (fieldLayout === 'stack') {
      return renderStack()
    } else {
      return renderBox()
    }
  }

  function renderStack() {
    return (
      <VBox classNames={$.ConditionalField} gap={layout.padding.s}>
        <FormField name={switchName} i18nKey={`${props.name}.switch`}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
        {renderValueField()}
      </VBox>
    )
  }

  function renderBox() {
    return (
      <VBox classNames={$.ConditionalField}>
        <Panel depth={1} backgroundColor={theme.bg.alt} padding={layout.padding.s} classNames={[$.box, switchValue ? 'enabled' : 'disabled']}>
          {renderValueField()}
        </Panel>
        {renderBoxSwitchField()}
      </VBox>
    )
  }

  function renderBoxSwitchField() {
    return (
      <VBox classNames={$.boxSwitchField} justify='middle'>
        <HBox classNames={$.boxSwitchFieldContent} ref={switchFieldRef}>
          {renderDimpleCutout()}
          <FormField name={switchName} i18nKey={`${props.name}.switch`} caption={false}>
            {bind => <SwitchField {...bind} small/>}
          </FormField>
        </HBox>
      </VBox>
    )
  }

  function renderValueField() {
    if (!showValue) { return null }

    return (
      <VBox ref={valueFieldRef}>
        <FormField {...rest}>
          {bind => children({
            ...bind,
            value:      switchValue ? bind.value : defaultValue?.(),
            inputStyle: 'dark',
            enabled:    switchValue,
          } as any)}
        </FormField>
      </VBox>
    )
  }

  //------
  // Dimple cutout

  const [cutoutSize, setCutoutSize] = React.useState<Size>({width: 0, height: 0})
  const outerRect = React.useMemo(
    () => ({x: 0, y: 0, width: cutoutSize.width, height: cutoutSize.height}),
    [cutoutSize],
  )

  useSize(switchFieldRef, setCutoutSize)

  const cutoutPath = React.useMemo(() => {
    const roundCorners = [0, 0, layout.radius.xl, layout.radius.xl]
    const cutoutRect   = {x: 1, y: outerRect.height / 2, width: outerRect.width - 2, height: outerRect.height / 2 - 1}
    const shadowRect   = {x: 0, y: outerRect.height / 2, width: outerRect.width, height: outerRect.height / 2}

    const cutout = [
      svgPath(rectExtents(cutoutRect), {roundCorners, close: true}),
    ].join(' ')

    const shadow = [
      svgPath(rectExtents(shadowRect), {close: true}),
      svgPath(rectExtents(cutoutRect), {roundCorners, close: true}),
    ].join(' ')

    const mask = [
      svgPath(rectExtents(cutoutRect), {roundCorners, close: true}),
    ].join(' ')

    return {cutout, shadow, mask}
  }, [outerRect.height, outerRect.width])

  function renderDimpleCutout() {
    const w = outerRect.width
    const h = outerRect.height

    return (
      <>
        <svg classNames={$.cutout} width={w} height={h} viewBox={`0 0 ${w} ${h}`}>
          <defs>
            <mask id={maskID}>
              <path d={cutoutPath.mask} fill="#ffffff" fillRule='evenodd'/>
            </mask>
          </defs>
          <path d={cutoutPath.cutout}/>
        </svg>
        <div classNames={[$.cutoutShadow, switchValue ? 'enabled' : 'disabled']}>
          <svg width={w} height={h} viewBox={`0 0 ${w} ${h}`}>
            <path d={cutoutPath.shadow} fillRule='evenodd'/>
          </svg>
        </div>
      </>
    )
  }

  return render()

}

const ConditionalField = observer('ConditionalField', _ConditionalField) as typeof _ConditionalField
export default ConditionalField

const useStyles = createUseStyles(theme => ({
  ConditionalField: {
    position:   'relative',
    paddingTop: 'calc(0.8em)',
  },

  box: {
    paddingTop: 'calc(0.8em)',

    '&.disabled': {
      opacity: 0.6,
    },
  },

  boxSwitchField: {
    position:   'absolute',
    top:        'calc(0.8em)',
    height:     0,

    ...layout.responsiveProp({
      left: layout.padding.s,
    }),
  },

  boxSwitchFieldContent: {
    '&, & > *': {
      position: 'relative',
    },

    padding:      [layout.padding.inline.xs, layout.padding.inline.m],
    borderRadius: layout.radius.s,
  },

  cutout: {
    ...layout.overlay,
    overflow: 'visible',

    '& path': {
      fill:   theme.bg.semi,
    },
  },

  cutoutShadow: (maskID: string) => ({
    '&, &.svg': {
      ...layout.overlay,
      overflow: 'visible',
    },

    '&.disabled': {
      opacity: 0.6,
    },

    mask: `url(#${maskID})`,

    '& svg': {
      filter: 'drop-shadow(0 0 2px rgba(0,0,0,0.3))',
    },
  }),
}))