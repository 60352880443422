import React from 'react'
import { Group } from '~/models'
import { projectStore } from '~/stores'
import ProjectLabel from '~/ui/app/projects/ProjectLabel'
import { observer } from '~/ui/component'
import { ClearButton, DataGrid, HBox, Label, SVG, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceListScreen, TagsCell, TypeCell } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import GroupForm from '../GroupForm'
import GroupSignupsDialog from '../signup/GroupSignupsDialog'

const GroupsScreen = observer('GroupsScreen', () => {

  const projectID = projectStore.projectID

  const {colors} = useStyling()
  const {actionCaption} = useResourceTranslation('groups')

  const [signupsDialogOpen, openSignupsDialog, closeSignupsDialog] = useBoolean()

  const allowSelect = React.useCallback((group: Group) => (
    group.project === projectID
  ), [projectID])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Group}
        CreateFormComponent={GroupForm}
        defaultSort='name'
        labels={['linked']}
        allowSelect={allowSelect}
        renderActions={renderActions}
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column<Group>
          name='type'
          sort={true}
          renderCell={group => <TypeCell type={group.type}/>}
          flex={2}
        />
        <DataGrid.Column<Group>
          name='tags'
          sort={true}
          flex={2}
          renderCell={group => <TagsCell tags={group.tags}/>}
        />
        <DataGrid.Column
          name='participantCount'
          flex={2}
        />

        <GroupSignupsDialog
          open={signupsDialogOpen}
          requestClose={closeSignupsDialog}
        />
      </ResourceListScreen>
    )
  }

  function renderName(group: Group) {
    return (
      <VBox gap={layout.padding.inline.xs}>
        <HBox gap={layout.padding.s}>
          <SVG
            name='group'
            size={layout.icon.m}
            color={colors.semantic.primary}
          />
          <Label bold>
            {group.name}
          </Label>
        </HBox>
        {group.project !== projectID && (
          <ProjectLabel tiny dim projectID={group.project}/>
        )}
      </VBox>
    )
  }

  function renderActions() {
    return (
      <VBox gap={layout.padding.s} align='left'>
        <ClearButton
          icon='signup'
          caption={actionCaption('manage_signups')}
          onTap={openSignupsDialog}
        />
      </VBox>
    )
  }

  return render()

})

export default GroupsScreen