import { ActionTriggerable, Challenge, Module, PlannerAction, Script, Triggerable } from '~/models'
import { ProxyFormModel, SubmitResult, translateFormErrorPaths } from '~/ui/form'
import ActionTriggerableVariant from './ActionTriggerableVariant'
import ModelTriggerableVariant from './ModelTriggerableVariant'

export default abstract class TriggerableFormModel implements ProxyFormModel<AnyObject> {

  constructor(
    public readonly triggerable: Triggerable | null,
    public readonly type: Triggerable['type'],
    public readonly module: Module,
    public readonly model: Script | Challenge | null = null,
    public readonly action: PlannerAction | null = null,
  ) {
    if (type === 'action') {
      const actionTriggerable = triggerable as ActionTriggerable | null
      this.variant = new ActionTriggerableVariant(
        actionTriggerable,
        action!,
      )
    } else {
      this.variant = new ModelTriggerableVariant(
        type,
        model!,
        module,
      )
    }
  }

  public readonly variant: TriggerableVariant

  public get modelVariant(): ModelTriggerableVariant | null {
    if (this.variant instanceof ModelTriggerableVariant) {
      return this.variant
    } else {
      return null
    }
  }

  public get actionVariant(): ActionTriggerableVariant | null {
    if (this.variant instanceof ActionTriggerableVariant) {
      return this.variant
    } else {
      return null
    }
  }

  public getValue(name: any) {
    return this.variant.getValue(name)
  }

  public assign(data: AnyObject) {
    return this.variant.assign(data)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const resultBefore = await this.variant.beforeSubmit()
    if (resultBefore?.status !== 'ok') { return resultBefore }

    const triggerable = this.variant.buildTriggerable()

    let result = await this.submitWithTriggerable(triggerable)
    result = translateFormErrorPaths(result, path => path.replace(/^nodes\.\d+\.triggerables\.\d+\./, ''))
    return this.variant.translateFormErrorPaths(result)
  }

  protected abstract submitWithTriggerable(triggerable: AnyObject): Promise<SubmitResult | undefined>

}

export interface TriggerableVariant {
  getValue(name: string): any
  assign(data: AnyObject): void

  beforeSubmit():     Promise<SubmitResult | undefined>
  buildTriggerable(): AnyObject
  translateFormErrorPaths(result: SubmitResult | undefined): SubmitResult | undefined
}