import React from 'react'
import Toast from 'react-toast'
import { Participant } from '~/models'
import { authenticationStore, BulkSelector } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ActionButton, ActionStateLabel } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import ResetParticipantForm from '../ResetParticipantForm'

export interface Props {
  participant: Participant
}

const ParticipantActions = observer('ParticipantActions', (props: Props) => {

  const {participant} = props

  const linkedToCurrentUser = authenticationStore.isLinkedToParticipant(participant.id)

  const [resetFormOpen, openResetForm, closeResetForm] = useBoolean()

  const {t} = useResourceTranslation()

  //------
  // Callbacks

  const linkToUser = React.useCallback(async () => {
    const success = await authenticationStore.linkParticipant(participant.id)
    if (success) {
      Toast.show({...t('actions.link.success'), type: 'success'})
    }
  }, [participant.id, t])

  const unlinkFromUser = React.useCallback(async () => {
    const success = await authenticationStore.unlinkParticipant(participant.id)
    if (success) {
      Toast.show({...t('actions.unlink.success'), type: 'success'})
    }
  }, [participant.id, t])

  //------
  // Rendering

  function render() {
    return (
      <VBox align='left' gap={layout.padding.s}>
        {renderResetAction()}
        {renderLinkToMeAction()}
        {renderResetForm()}
      </VBox>
    )
  }

  function renderResetAction() {
    return (
      <ActionButton
        icon='reset'
        name='reset'
        onTap={openResetForm}
      />
    )
  }

  function renderLinkToMeAction() {
    if (!linkedToCurrentUser) {
      return (
        <ActionButton
          icon='user'
          name='link'
          onTap={linkToUser}
        />
      )
    } else {
      return (
        <ActionStateLabel
          icon='user'
          label={t('linked_to_current_user')}
          action={{
            name:  'unlink',
            onTap: unlinkFromUser,
          }}
        />
      )
    }
  }

  function renderResetForm() {
    const selector = BulkSelector.list('participants', [participant.id])

    return (
      <ResetParticipantForm
        open={resetFormOpen}
        requestClose={closeResetForm}
        selector={selector}
      />
    )
  }

  return render()

})

export default ParticipantActions