import React from 'react'
import { ModelOfType } from '~/models'
import { CopyResolution, Linkage } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  Dimple,
  HBox,
  InfoIcon,
  List,
  Panel,
  SelectField,
  Spinner,
  TextBlock,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChip, ResourceField } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import CopyToProjectFormModel from './CopyToProjectFormModel'

export interface Props {}

const PreflightStep = observer('PreflightStep', (props: Props) => {

  const {model: formModel} = useForm<CopyToProjectFormModel>()

  const {
    preflightStatus,
    linkages,
  } = formModel

  const {t} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s} classNames={$.PreflightStep}>
        <TextBlock dim>
          {t('actions.copy_to_project.preflight.preamble')}
        </TextBlock>

        <Panel>
          <List
            data={linkages}
            keyExtractor={keyExtractor}
            renderItem={renderItem}
            EmptyComponent={renderEmpty}
            SeparatorComponent={Dimple.Horizontal}
            scrollable={true}
            flex={false}

            classNames={$.list}
            contentClassNames={$.listContent}
          />
        </Panel>
      </VBox>
    )
  }

  const keyExtractor = React.useCallback(
    (linkage: Linkage<any>) => `${linkage.type}::${linkage.id}`,
    [],
  )

  const renderItem = React.useCallback((linkage: Linkage<any>) => {
    return (
      <PreflightItem linkage={linkage}/>
    )
  }, [])

  const renderEmpty = React.useCallback(() => {
    if (preflightStatus !== 'fetching') { return null }

    return (
      <Center classNames={$.empty} padding={layout.padding.l}>
        <Spinner/>
      </Center>
    )
  }, [$.empty, preflightStatus])

  return render()

})

export default PreflightStep

interface PreflightItemProps {
  linkage: Linkage<any>
}

const PreflightItem = observer('PreflightItem', (props: PreflightItemProps) => {

  const {linkage} = props

  const Model = ModelOfType(linkage.type)
  const id    = linkage.id

  const {model: formModel} = useForm<CopyToProjectFormModel>()
  const destProject = formModel.destProject

  const {t} = useResourceTranslation()
  const {singular} = useResourceTranslation(Model.resourceType)

  const filters = React.useMemo(() => {
    const filters: Record<string, string | null> = {}
    if (destProject != null) {
      filters.project = destProject
    }
    return filters
  }, [destProject])

  const isModule       = linkage.type === 'modules'
  const resolution     = formModel.getResolution(linkage)
  const resolutionType = resolution?.type ?? 'reassign'
  const reassignmentID = resolution?.type === 'reassign' ? resolution.id : null

  const setActionType = React.useCallback((type: CopyResolution['type'] | null) => {
    if (type == null) {
      formModel.setResolution(linkage, null)
    } else if (type === 'copy') {
      formModel.setResolution(linkage, {type: 'copy'})
    } else {
      formModel.setResolution(linkage, {type: 'reassign', id: null})
    }
  }, [formModel, linkage])

  const reassign = React.useCallback((id: string | null) => {
    formModel.setResolution(linkage, {type: 'reassign', id})
  }, [formModel, linkage])

  const resolutionTypeChoices = React.useMemo(() => {
    const choices: Choice<CopyResolution['type']>[] = []

    choices.push({
      value: 'reassign',
      caption: t('actions.copy_to_project.preflight.resolutions.reassign'),
    })

    choices.push({
      value:   'copy',
      caption: t('actions.copy_to_project.preflight.resolutions.copy'),

      enabled:   !isModule,
      accessory: isModule ? <InfoIcon renderTooltip={t('actions.copy_to_project.preflight.module_copy_explanation')}/> : undefined,
    })

    return choices
  }, [isModule, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.PreflightItem} gap={layout.padding.inline.m}>
        <HBox flex={2}>
          <VBox flex='shrink'>
            {renderResourceChip()}
          </VBox>
        </HBox>
        <HBox flex={3} gap={layout.padding.inline.m}>
          <VBox flex={1}>
            {renderActionField()}
          </VBox>
          {resolutionType === 'reassign' && (
            <VBox flex={2}>
              {renderResourceField()}
            </VBox>
          )}
        </HBox>
      </HBox>
    )
  }

  function renderResourceChip() {
    return (
      <ResourceChip
        Model={Model}
        id={id}
        interactive={false}
        modelName
      />
    )
  }

  function renderActionField() {
    return (
      <SelectField
        value={resolutionType}
        choices={resolutionTypeChoices}
        onChange={setActionType}
      />
    )
  }

  function renderResourceField() {
    return (
      <ResourceField
        Model={Model}
        value={reassignmentID}
        placeholder={t('actions.copy_to_project.preflight.placeholder', {singular: singular()})}
        filters={filters}
        onChange={reassign}
        multi={false}
      />
    )
  }

  return render()

})

const useStyles = createUseStyles({
  PreflightStep: {
    maxHeight: 460,
  },

  list: {
    margin: -6,
  },

  listContent: {
    padding: 6,
  },

  PreflightItem: {
    padding: [
      layout.padding.inline.s,
      layout.padding.inline.m,
    ],
  },

  empty: {
  },
})