import React from 'react'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import ModuleForm from '../../modules/ModuleForm'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import CalendarItemCommonFields from './CalendarItemCommonFields'
import ModuleItemFormModel from './ModuleItemFormModel'

export type Props = Omit<FormDialogProps<ModuleItemFormModel>, 'children'>

const ModuleItemForm = memo('ModuleItemForm', (props: Props) => {

  const {planner} = useCalendarPlanner()

  return (
    <ModuleForm<ModuleItemFormModel>
      {...props}
      owningModule={planner.plan?.module}
      commonFields={<CalendarItemCommonFields/>}
    />
  )

})

export default ModuleItemForm