import React from 'react'
import { Metric } from '~/models'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { CreateFormComponentMap, ResourceListScreen, TypeCell } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import MetricForm, { Props as MetricFormProps } from '../MetricForm'

const MetricsScreen = memo('MetricsScreen', () => {

  const {colors} = useStyling()
  const {t} = useResourceTranslation('metrics')

  const createFormMap = React.useMemo((): CreateFormComponentMap => ({
    valued: {
      caption:   t('types.valued.caption'),
      detail:    t('types.valued.detail'),
      Component: (props: Omit<MetricFormProps, 'type'>) => <MetricForm {...props} type='valued'/>,
    },
    duration: {
      caption:   t('types.duration.caption'),
      detail:    t('types.duration.detail'),
      Component: (props: Omit<MetricFormProps, 'type'>) => <MetricForm {...props} type='duration'/>,
    },
    'funnel': {
      caption:   t('types.funnel.caption'),
      detail:    t('types.funnel.detail'),
      Component: (props: Omit<MetricFormProps, 'type'>) => <MetricForm {...props} type='funnel'/>,
    },
  }), [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Metric}
        CreateFormComponent={createFormMap}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={3}
        />
        <DataGrid.Column<Metric>
          name='type'
          sort={true}
          renderCell={it => <TypeCell type={it.type}/>}
          flex={1}
        />
      </ResourceListScreen>
    )
  }

  function renderName(metric: Metric) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name={metric.$icon}
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <Label flex='shrink' bold>
          {metric.name}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default MetricsScreen