import React from 'react'
import { Competition } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { HBox, Label, TextBlock, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  competition: Competition
}

const CompetitionHeader = memo('CompetitionHeader', (props: Props) => {

  const {competition} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.l} align='top'>
        <ImageView
          source={competition.image ?? {icon: 'trophy'}}
          size={logoSize}
          classNames={$.logo}
        />

        <VBox flex='both'>
          <VBox gap={layout.padding.m}>
            <VBox>
              <Label h1>
                {competition.title ?? competition.name}
              </Label>
              {competition.name !== competition.title && (
                <Label dim>
                  {competition.name}
                </Label>
              )}
            </VBox>
            {competition.description != null && (
              <TextBlock dim italic>
                {competition.description}
              </TextBlock>
            )}
          </VBox>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default CompetitionHeader

export const logoSize = {
  width:  80,
  height: 80,
}

const useStyles = createUseStyles({
  logo: {
    borderRadius: layout.radius.m,
  },
})