import React from 'react'
import Color from 'color'
import { memo } from '~/ui/component'
import { ColorSwatch, Label, HBox, SelectField, SelectFieldProps } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { layout } from '~/ui/styling'
import { useBranding } from '../BrandingContext'

export type Props = Omit<SelectFieldProps<string>, 'choices'>

const BrandingColorField = memo('BrandingColorField', (props: Props) => {

  const {branding} = useBranding()

  //------
  // Rendering

  function render() {
    return (
      <SelectField
        {...props}
        choices={choices}
      />
    )
  }

  const renderNamedColor = React.useCallback((name: string, hex: string, list: boolean) => {
    return (
      <HBox padding={list ? layout.padding.inline.m : 0} gap={layout.padding.inline.m}>
        <ColorSwatch
          color={new Color(hex)}
        />
        <Label flex mono>
          {name}
        </Label>
      </HBox>
    )
  }, [])

  const choices = React.useMemo((): Choice<string>[] => {
    return Object.entries(branding?.colors ?? {}).map(([name, hex]) => ({
      value:  name,
      render: list => renderNamedColor(name, hex, list),
    }))
  }, [branding?.colors, renderNamedColor])

  return render()

})

export default BrandingColorField