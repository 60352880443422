import React from 'react'
import { NotificationComponentProps, registerNotification } from 'react-notifications'
import removeMarkdown from 'remove-markdown'
import { SystemNotification } from '~/models'
import { notificationSpec } from '~/notifications'
import { notificationsStore } from '~/stores'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, Panel, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import NotificationActionButton from './NotificationActionButton'

export interface Props {
  notification: SystemNotification
}

const InAppNotification = memo('InAppNotification', (props: Props & NotificationComponentProps) => {

  const {notification, hide} = props

  const theme = useTheme()

  const spec = notificationSpec(notification.type)
  const icon    = spec.icon(notification)
  const color   = spec.color(notification, theme)
  const actions = spec.actions(notification)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel depth={3} onTap={hide}>
        <HBox classNames={$.inAppNotification} gap={layout.padding.inline.l} align='top'>
          {renderIcon()}
          <VBox flex gap={layout.padding.inline.m}>
            {renderLabels()}
            {actions.length > 0 && <Dimple horizontal/>}
            {renderActions()}
          </VBox>
        </HBox>
      </Panel>
    )
  }

  function renderIcon() {
    return (
      <SVG
        name={icon}
        size={layout.icon.m}
        color={color}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox>
        <Label h3>
          {notification.title}
        </Label>
        <Label small markup>
          {notification.message}
        </Label>
      </VBox>
    )
  }

  function renderActions() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {actions.map(action => (
          <NotificationActionButton
            key={action.name}
            action={action}
            type='clear'
          />
        ))}
      </HBox>
    )
  }

  return render()

})

export default InAppNotification

registerNotification(InAppNotification, show => {
  return notificationsStore.addNotificationListener(notification => {
    if (notification.priority !== 'high') { return }

    show(notification.type, {
      notification,
    }, {
      browser: {
        title:     removeMarkdown(notification.title),
        icon:      '/favicon-96x96.png',
        body:      removeMarkdown(notification.message),
        timestamp: notification.createdAt.toMillis(),
      },
    })
  })
})

export const avatarSize = {
  width:  40,
  height: 40,
}

const useStyles = createUseStyles({
  inAppNotification: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
})