import React from 'react'
import * as MIME from 'mime-types'
import { memo } from '~/ui/component'
import { Center, HBox, SVG, Tappable, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { colors, createUseStyles, layout } from '~/ui/styling'

export interface Props {
  items: MediaItem[]
}

export interface MediaItem {
  url:      string
  caption?: string | null
}

export default function MediaGallery(props: Props) {

  const {items} = props

  //------
  // Rendering

  function render() {
    return (
      <HBox flex wrap align='stretch'>
        {items.map(renderMedia)}
      </HBox>
    )
  }

  function renderMedia(item: MediaItem, index: number) {
    return (
      <MediaItemPreview
      key={index}
        item={item}
      />
    )
  }

  return render()

}

interface MediaItemPreviewProps {
  item: MediaItem
}

const MediaItemPreview = memo('MediaItemPreview', (props: MediaItemPreviewProps) => {

  const {item} = props

  const mimeType = React.useMemo(() => {
    let mime = MIME.lookup(item.url)
    if (mime === false) {
      mime = 'image/png'
    }
    return mime
  }, [item.url])

  const [playing, play] = useBoolean()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.mediaItemPreview}>
        {mimeType.startsWith('image/') ? (
          renderImage()
        ) : playing ? (
          renderVideo()
        ) : (
          renderPlayButton()
        )}
      </VBox>
    )
  }

  function renderImage() {
    return (
      <figure classNames={$.image}>
        <img src={item.url} alt={item.caption ?? item.url}/>
      </figure>
    )
  }

  function renderVideo() {
    return (
      <figure classNames={$.video}>
        <video src={item.url} autoPlay controls/>
      </figure>
    )
  }

  function renderPlayButton() {
    return (
      <Tappable flex onTap={play}>
        <Center flex>
          <Center classNames={$.playButton}>
            <SVG
              name='play'
              size={layout.icon.xl}
            />
          </Center>
        </Center>
      </Tappable>
    )

  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  mediaItemPreview: {
    minWidth:  120,
    minHeight: 120,
  },

  image: {
    flex: [1, 0, 0],
    '& > img': {
      display:   'block',
      width:     '100%',
      height:    '100%',
      objectFit: 'cover',
    },
  },

  video: {
    flex: [1, 0, 0],
    '& > video': {
      display:   'block',
      width:     '100%',
      height:    '100%',
      objectFit: 'cover',
    },
  },

  playButton: {
    width:        48,
    height:       48,
    borderRadius: 24,
    background:   theme.bg.active,
    ...colors.overrideForeground(theme.colors.contrast(theme.bg.active)),
  },
}))