import { CalendarItem } from '~/models'
import { panelBorderRadius } from '~/ui/components'
import { layout } from '~/ui/styling'
import { Theme } from '~/ui/styling/Theme'

export const dayWidth = 360

export function dayHeight() {
  return 24 * hourHeight + 2 * padding
}

export function hourTop(hour: number) {
  return hour * hourHeight + padding
}

export function calendarItemBorderRadius(theme: Theme, item: CalendarItem) {
  if (item.type === 'module') {
    return hourHeight / 2
  } else {
    return panelBorderRadius(theme)
  }
}

export const hourHeight = layout.barHeight.m
export const padding    = layout.padding.inline.xl