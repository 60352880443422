import React from 'react'
import { memo } from '~/ui/component'
import { SwitchField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import CommonLinkFields, { Props as CommonLinkFieldProps } from './CommonLinkFields'

export interface Props {
  caption?: CommonLinkFieldProps['caption']
}

const LinkFormURLFields = memo('LinkFormURLFields', (props: Props) => {

  const {
    caption = 'edit',
  } = props

  const {fieldInstruction} = useResourceTranslation('links')

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <CommonLinkFields
          href='edit'
          caption={caption}
        />
        {renderOptions()}
      </VBox>
    )
  }

  function renderOptions() {
    return (
      <VBox gap={layout.padding.s}>
        <FormFieldHeader name='options'/>

        <FormField name='external' caption={false} instruction={false}>
          {bind => <SwitchField {...bind} info={fieldInstruction('external')}/>}
        </FormField>
      </VBox>
    )

  }

  return render()

})

export default LinkFormURLFields