import React from 'react'
import { Widget, WidgetAlotment } from '~/models'
import { WidgetState } from '~/stores'
import { WidgetComponentForType } from './registry'

// @index(WidgetView.tsx$,!^WidgetView.tsx$)
export { default as BarChartWidgetView } from './BarChartWidgetView'
export { default as ChartWidgetView } from './ChartWidgetView'
export { default as DonutChartWidgetView } from './DonutChartWidgetView'
export { default as FunnelWidgetView } from './FunnelWidgetView'
export { default as GaugeWidgetView } from './GaugeWidgetView'
// /index

export default function renderDashboardWidget(widget: Widget, alotment: WidgetAlotment, state: WidgetState | null) {
  const WidgetComponent = WidgetComponentForType(widget.type)
  if (WidgetComponent == null) {
    console.warn(`Unknown widget type: ${widget.type}.`)
    return null
  }

  return (
    <WidgetComponent
      alotment={alotment}
      widget={widget}
      state={state}
    />
  )
}