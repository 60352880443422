import I18next from 'i18next'
import { mapValues } from 'lodash'
import { makeObservable, observable } from 'mobx'
import { ActionTriggerable, PlannerAction } from '~/models'
import { deriveDynamicFormSchema, DynamicFormSchema } from '~/ui/app/dynamic-form'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { TriggerableVariant } from './TriggerableFormModel'

export default class ActionTriggerableVariant implements TriggerableVariant {

  constructor(
    public readonly triggerable: ActionTriggerable | null,
    public readonly action:      PlannerAction,
  ) {
    makeObservable(this)
  }

  @observable
  public params: Record<string, any> = {
    ...this.deriveDefaults(),
    ...this.triggerable?.params ?? {},
  }

  public getValue(name: string) {
    return this.params[name] ?? null
  }

  public assign(data: AnyObject) {
    Object.assign(this.params, data)
  }

  //------
  // Form schema

  public readonly formSchema = this.deriveFormSchema()

  private deriveFormSchema(): DynamicFormSchema {
    const translations =
      this.action.translations[I18next.language]?.params
      ?? this.action.translations.en?.params
      ?? {}

    return deriveDynamicFormSchema(this.action.paramsSchema, translations)
  }

  private deriveDefaults() {
    return mapValues(this.action.paramsSchema, param => param.default)
  }

  //------
  // Submission

  public validate(): SubmitResult {
    if (this.action == null) {
      return {
        status: 'invalid',
        errors: [{
          field:   'action',
          message: I18next.t('validation.required'),
        }],
      }
    }

    return {status: 'ok'}
  }

  public async beforeSubmit(): Promise<SubmitResult | undefined> {
    return this.validate()
  }

  public buildTriggerable(): AnyObject {
    return {
      type:   'action',
      action: this.action.name,
      params: this.params,
    }
  }

  public translateFormErrorPaths(result: SubmitResult | undefined) {
    return translateFormErrorPaths(result, path => path.replace(/^params\./, ''))
  }

}