import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImportSession } from 'sheet-importer'
import { observer } from '~/ui/component'
import { Dimple, Panel, PushButton, SwitchField, TextBlock, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  session:       ImportSession
  requestImport: () => Promise<void>
}

const ImportSummaryPanel = observer('ImportSummaryPanel', (props: Props) => {

  const {session, requestImport} = props
  const [t] = useTranslation('importer')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel>
        {renderInstructions()}
        <Dimple horizontal/>
        {renderOptions()}
        <Dimple horizontal/>
        {renderActions()}
      </Panel>
    )
  }

  function renderInstructions() {
    return (
      <VBox classNames={$.instructions}>
        <TextBlock small dim markup>
          {t('instructions')}
        </TextBlock>
      </VBox>
    )
  }

  function renderOptions() {
    return (
      <VBox classNames={$.options}>
        <SwitchField
          value={session.hasHeaderRow}
          onChange={val => session.setHasHeaderRow(val)}
          label={t('options.has_header_rows.label')}
          small
        />
      </VBox>
    )
  }

  function renderActions() {
    return (
      <VBox classNames={$.actions}>
        <PushButton
          icon='upload'
          caption={t('actions.import')}
          onTap={requestImport}
        />
      </VBox>
    )
  }

  return render()

})

export default ImportSummaryPanel

const useStyles = createUseStyles({
  section: {
    padding: [layout.padding.inline.m, layout.padding.inline.m],
  },

  instructions: {
    extend: 'section',
  },

  options: {
    extend: 'section',
  },

  actions: {
    extend: 'section',
  },
})