import React from 'react'
import { useClipboardQuery } from 'rich-clipboard'
import { CalendarPlanClipboardItem, ClipboardType } from '~/clipboard'
import { CalendarDay } from '~/models'
import { calendarDaySummary } from '~/stores'
import { observer } from '~/ui/component'
import { ConfirmBox, KebabMenu, PanelHeader, PopupMenuItem, VBox } from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, useStyling } from '~/ui/styling'
import ActivateCalendarDayDialog from '../ActivateCalendarDialog'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import CalendarPlanInsightsBadgeRow from './CalendarPlanInsightsBadgeRow'

export interface Props {
  day:           CalendarDay
  requestEdit:   () => any
  requestRemove: () => any
}

const CalendarDayHeader = observer('CalendarDayHeader', (props: Props) => {

  const {day, requestEdit, requestRemove} = props
  const caption = calendarDaySummary(day)

  const {planner}       = useCalendarPlanner()
  const insightsSummary = planner.insights.summaries.find(it => it.day.uuid === day.uuid)

  const {actionCaption, actionConfirm} = useResourceTranslation('calendar_planner')

  const {colors} = useStyling()

  const [clipboardQuery, clipboardItem] = useClipboardQuery<CalendarPlanClipboardItem[]>(ClipboardType.CALENDAR_ITEMS)

  const [activateFormOpen, openActivateForm, closeActivateForm] = useBoolean()

  //------
  // Actions

  const paste = React.useCallback(() => {
    const items = clipboardQuery.getData()
    if (items == null) { return }

    planner.pasteClipboardItems(items, day.uuid)
  }, [clipboardQuery, day.uuid, planner])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove_day'),
      destructive: true,
    })
    if (confirmed) {
      requestRemove()
    }
  }, [actionConfirm, requestRemove])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'pencil',
      caption:  actionCaption('edit_day'),
      onSelect: requestEdit,
    })

    items.push({section: '-'})
    items.push({
      icon:     'flash',
      caption:  actionCaption('activate'),
      onSelect: openActivateForm,
      color:    colors.semantic.positive,
    })

    if (clipboardItem != null) {
      items.push({
        section: '-',
      }, {
        icon:     'paste',
        caption:  actionCaption('paste'),
        keyHint:  'Short+V',
        onSelect: paste,
      }, {
        section: '-',
      })
    }

    items.push({section: '-'})
    items.push({
      icon:     'trash',
      caption:  actionCaption('remove_day'),
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [actionCaption, clipboardItem, colors.semantic.negative, colors.semantic.positive, openActivateForm, paste, remove, requestEdit])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.CalendarDayHeader}>
        <PanelHeader
          icon='calendar'
          caption={caption}
          right={renderKebabMenu()}
          large
        />

        {insightsSummary != null && (
          <VBox align='center' classNames={$.insights}>
            <CalendarPlanInsightsBadgeRow
              summary={insightsSummary}
            />
          </VBox>
        )}

        <ActivateCalendarDayDialog
        open={activateFormOpen}
        requestClose={closeActivateForm}
        dayUUID={day.uuid}
        />
      </VBox>

    )
  }

  function renderKebabMenu() {
    return (
      <KebabMenu
        items={kebabMenuItems}
        button={{padding: 'none'}}
      />
    )
  }

  return render()

})

export default CalendarDayHeader

const useStyles = createUseStyles({
  CalendarDayHeader: {
    position: 'relative',
  },

  insights: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      -badgeSize.normal / 2,
  },
})