import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { memo } from '~/ui/component'
import { ClearButton, ConfirmBox, HBox, Panel } from '~/ui/components'
import { layout } from '~/ui/styling'
import { useChallengeTasksEditor } from './ChallengeTasksEditorContext'

export interface Props {
  selectedUUIDs: string[]
}

const ChallengeTasksBulkEditorActionBar = memo('ChallengeTasksBulkEditorActionBar', (props: Props) => {

  const {selectedUUIDs} = props
  const {removeTasks, copyTasks, pasteTasks, tasksInClipboard} = useChallengeTasksEditor()
  const enabled = selectedUUIDs.length > 0

  const [t] = useTranslation('challenges')

  const timer = useTimer()

  //------
  // Rendering

  function render() {
    return (
      <Panel>
        <HBox flex padding={layout.padding.s} gap={layout.padding.m} justify='space-between'>
          {renderCopyButton()}
          {renderRemoveButton()}
          {tasksInClipboard.length > 0 && renderPasteButton()}
        </HBox>
      </Panel>
    )
  }

  function renderCopyButton() {
    return(
      <ClearButton
        enabled={enabled}
        onTap={copySelectedTasks}
        caption={t('actions.tasks.copy_tasks.caption', {count: selectedUUIDs.length})}
        icon='copy'
      />
    )
  }

   function renderPasteButton() {
    return (
      <ClearButton
        icon='paste'
        caption={t('actions.tasks.paste_tasks.caption', {count: tasksInClipboard.length})}
        onTap={pasteTasksInClipboard}
      />
    )
  }

  function renderRemoveButton() {
    return(
      <ClearButton
        enabled={enabled}
        onTap={removeSelectedTasks}
        icon='trash'
        caption={t('actions.tasks.remove_tasks.caption', {count: selectedUUIDs.length})}
      />
    )
  }

  const copySelectedTasks = React.useCallback(() => {
    copyTasks(selectedUUIDs)

    Toast.show({
      title:  t('actions.tasks.copy_tasks.success.title', {count: selectedUUIDs.length}),
      detail: t('actions.tasks.copy_tasks.success.detail', {count: selectedUUIDs.length}),
      type:   'success',
    })
  }, [copyTasks, selectedUUIDs, t])

  const pasteTasksInClipboard = React.useCallback(async () => {
    if (tasksInClipboard == null) { return }
    return timer.await(pasteTasks())
  }, [pasteTasks, tasksInClipboard, timer])

  const removeSelectedTasks = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('actions.tasks.remove_tasks.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    return await timer.await(removeTasks(selectedUUIDs))
  }, [removeTasks, selectedUUIDs, t, timer])

  return render()

})

export default ChallengeTasksBulkEditorActionBar