import React from 'react'
import { Group, Targeting } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, VBox } from '~/ui/components'
import { FormDialogProps } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'

export interface Props {
  targeting:      Targeting
  FormComponent?: React.ComponentType<Pick<FormDialogProps<any>, 'open' | 'requestClose'>>
}

const TargetingSummary = memo('TargetingSummary', (props: Props) => {

  const {targeting, FormComponent} = props
  const {t}        = useResourceTranslation('targeting')
  const {singular} = useResourceTranslation()

  const [formOpen, openForm, closeForm] = useBoolean()

  //------
  // Rendering

  function render() {
    return (
      <HBox align='top' gap={layout.padding.s}>
        <VBox flex>
          {targeting.groups.length === 0 ? (
            renderEmpty()
          ) : (
            renderTargeting()
          )}
        </VBox>
        {renderEditButton()}
        {renderForm()}
      </HBox>
    )
  }

  function renderEmpty() {
    return (
      <SummaryInfo.Item icon='target' markup>
        <VBox>
          <Label small bold dim>
            {t('empty.title')}
          </Label>
          <Label small dim truncate={false}>
            {t('empty.detail', {type: singular()})}
          </Label>
        </VBox>
      </SummaryInfo.Item>
    )
  }

  function renderTargeting() {
    return (
      <SummaryInfo.Item icon='target' markup>
        <VBox gap={layout.padding.inline.m}>
          <Label small bold dim truncate={false} markup>
            {t(`preamble.${targeting.combinator}`, {type: singular()})}
          </Label>

          <ResourceChipRow
            Model={Group}
            ids={targeting.groups}
            collapseFrom={6}
            wrap
            small
          />
        </VBox>
      </SummaryInfo.Item>
    )
  }

  function renderEditButton() {
    if (FormComponent == null) { return null }

    return (
      <ClearButton
        icon='pencil'
        onTap={openForm}
        padding='both'
      />
    )
  }

  function renderForm() {
    if (FormComponent == null) { return null }

    return (
      <FormComponent
        open={formOpen}
        requestClose={closeForm}
      />
    )
  }

  return render()

})

export default TargetingSummary