import { makeObservable, observable } from 'mobx'
import { BackgroundSpec, Branding } from '~/models'
import {
  angleToGradientDirection,
  GradientDirection,
  gradientDirectionToAngle,
} from '../util/gradients'
import BrandingAssetFormModel from './BrandingAssetFormModel'

export default class BrandingBackgroundFormModel extends BrandingAssetFormModel {

  constructor(branding: Branding, originalName: string | null) {
    super(branding, originalName)

    makeObservable(this)
  }
  public readonly assetType = 'background'

  private readonly spec: BackgroundSpec | null = this.originalName == null ? null : this.branding.backgrounds[this.originalName] ?? null

  @observable
  public type: BackgroundSpec['type'] | null = this.spec?.type ?? 'solid'

  @observable
  public color: string = (this.spec?.type === 'solid' ? this.spec.color : null) ?? 'primary'

  @observable
  public startColor: string = (this.spec?.type === 'gradient' ? this.spec.colors[0] : null) ?? 'primary'

  @observable
  public endColor: string = (this.spec?.type === 'gradient' ? this.spec.colors[1] : null) ?? 'secondary'

  @observable
  public direction: GradientDirection = this.spec?.type === 'gradient' ? angleToGradientDirection(this.spec.angle) : GradientDirection.NESW

  @observable
  public theme: BackgroundSpec['theme'] = this.spec?.theme ?? 'light'

  public reset() {
    super.reset()
    this.type  = this.spec?.type ?? 'solid'
    this.color = (this.spec?.type === 'solid' ? this.spec.color : null) ?? 'primary'
    this.startColor = (this.spec?.type === 'gradient' ? this.spec.colors[0] : null) ?? 'primary'
    this.endColor = (this.spec?.type === 'gradient' ? this.spec.colors[1] : null) ?? 'secondary'
    this.direction = this.spec?.type === 'gradient' ? angleToGradientDirection(this.spec.angle) : GradientDirection.NESW
    this.theme = this.spec?.theme ?? 'light'
  }

  public buildSpec(): BackgroundSpec {
    if (this.type === 'solid') {
      return {
        type:  'solid',
        color: this.color,
        theme: this.theme,
      }
    } else {
      return {
        type:   'gradient',
        colors: [this.startColor, this.endColor],
        angle:  gradientDirectionToAngle(this.direction),
        theme:  this.theme,
      }
    }
  }

}