import React from 'react'
import Color from 'color'
import ChartLayout from './ChartLayout'
import { LayoutRect } from 'react-dnd'
import { SeriesProps } from './series'

interface ChartContext<P> {
  data:              P[]
  series:            React.ReactElement<SeriesProps<P>>[]
  keyForPoint:       (point: P, index: number) => string | number
  axisLabelForPoint: (point: P, index: number) => string | null

  colorForSeries: (name: string) => Color
  chartLayout:    ChartLayout
  defs:           Record<string, string>

  hasPopup:          boolean
  showPopupForPoint: (point: P, element: SVGElement) => void
  hidePopup:         (point?: P) => void
}

const ChartContext = React.createContext<ChartContext<any>>({
  data:              [],
  series:            [],
  keyForPoint:       (_, index) => index,
  axisLabelForPoint: () => null,
  colorForSeries:    () => new Color('black'),
  chartLayout:       new ChartLayout(LayoutRect.zero(), [], 1),
  defs:              {},

  hasPopup:          false,
  showPopupForPoint: () => void 0,
  hidePopup:         () => void 0,
})

export default ChartContext

export function useChart<P>() {
  return React.useContext(ChartContext) as ChartContext<P>
}