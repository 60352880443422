import React from 'react'
import { select } from 'ytil'
import { RegistrationCodeStatus, RegistrationPreset, RegistrationPresetCodeCounts } from '~/models'
import { observer } from '~/ui/component'
import { Chip, HBox, Label, Tooltip, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout, useTheme } from '~/ui/styling'
import { colorForStatus } from '../../codes/list/cells'

export interface Props {
  preset: RegistrationPreset
}

const RegistrationPresetInfo = observer('RegistrationPresetInfo', (props: Props) => {

  const {preset} = props

  const {t} = useResourceTranslation()
  const theme = useTheme()

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo icon='code'>
        <SummaryInfo.Item markup>
          {t('created', {when: preset.createdAt})}
        </SummaryInfo.Item>
        <SummaryInfo.Item
          icon='code'
          important={!preset.requireCode}
          children={preset.requireCode ? renderCodeBody() : t('no_code_required')}
        />
      </SummaryInfo>
    )
  }

  function renderCodeBody() {
    return (
      <HBox wrap gap={layout.padding.inline.s}>
        <Label small dim>
          {t('code_counts.preamble')}
        </Label>
        <Tooltip renderTooltip={renderCountSplit}>
          <HBox wrap gap={layout.padding.inline.s}>
            {renderChip('total', false)}
            <Label small dim>:</Label>
            {renderChip('registered', false)}
            <Label small dim>/</Label>
            {renderChip('other', false)}
          </HBox>
        </Tooltip>
      </HBox>
    )
  }

  function renderCountSplit() {
    return (
      <VBox gap={layout.padding.s}>
        {renderChip('total', true)}
        {renderChip('registered', true)}
        {renderChip('other', true)}
      </VBox>
    )
  }

  function renderChip(which: keyof RegistrationPresetCodeCounts, inTooltip: boolean) {
    const count = preset.codeCounts?.[which] ?? 0
    const color = select(which, {
      registered: colorForStatus(RegistrationCodeStatus.registered, theme),
      other:      colorForStatus(RegistrationCodeStatus.pending, theme),
      default:    undefined,
    })


    return (
      <Chip small={!inTooltip} backgroundColor={color}>
        {inTooltip ? t(`code_counts.${which}`, {count}) : count}
      </Chip>
    )
  }

  return render()

})

export default RegistrationPresetInfo