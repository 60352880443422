import { action, computed, makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { CustomImage, Link, ModelName, reflect, ResourceModels } from '~/models'
import { FormModel, SubmitResult } from '~/ui/form'

export default class LinkFormModel implements FormModel {

  constructor(
    private readonly save: (link: Link | null, caption: string | null, image: CustomImage | null) => Promise<SubmitResult | undefined>,
    private readonly initialLink: Link | null,
    private readonly initialCaption: string | null = null,
  ) {
    makeObservable(this)
  }

  //------
  // Types

  @computed
  public get availableTypes(): LinkType[] {
    return [
      'url',
      'upload',
      ...this.ResourceModelsWithAppLink.map(it => it.name as ModelName),
    ]
  }

  @computed
  private get ResourceModelsWithAppLink() {
    return ResourceModels()
      .filter(Model => reflect(Model, 'appLink', null) != null)
  }

  @computed
  private get initialType(): LinkType {
    // Heuristically, all app links have as a hostname the name of the model, but in lowercase.
    const match = this.initialLink?.href.toLowerCase().match(/^\/\/([^/]+)/)
    if (match != null) {
      const Model = this.ResourceModelsWithAppLink.find(Model => Model.name.toLowerCase() === match[1])
      if (Model != null) { return Model.name as ModelName }
    }

    return 'url'
  }

  @observable
  public type: LinkType = this.initialType

  @action
  public setType(type: LinkType) {
    this.type    = type
    this.caption = ''

    if (type !== 'url') {
      this.href = ''
    }
  }

  //------
  // Link properties

  @observable
  public href: string = this.initialLink?.href ?? ''

  @observable
  public caption: string = this.initialCaption ?? ''

  @observable
  public image: CustomImage | null = null

  @observable
  public external: boolean = this.initialLink?.target === '_blank'

  @observable
  public target: string = this.initialLink?.target ?? ''

  @action
  public set(href: string, caption: string = this.caption) {
    this.href = href
    this.caption = caption
  }

  @action
  public reset() {
    this.type     = this.initialType
    this.href     = this.initialLink?.href ?? ''
    this.caption  = this.initialCaption ?? ''
    this.image    = null
    this.external = this.initialLink?.target === '_blank'
    this.target   = this.initialLink?.target ?? ''
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return this.save(
      this.buildLink(),
      cleanTextValue(this.caption),
      this.image,
    )
  }

  protected buildLink(): Link | null {
    const href = this.href.trim()
    if (href.length === 0) { return null }

    return {
      href:   href,
      target: this.external ? '_blank' : this.target === '_blank' ? '' : this.target,
    }
  }

}

export type LinkType =
  /** Any arbitrary URL */
  | 'url'

  /** Allows something to be uploaded. */
  | 'upload'

  /** Name of a specific model. */
  | ModelName