import { ImportField } from 'sheet-importer'
import { superConstructor } from 'ytil'

export type ImportFieldType<F extends ImportField> = Constructor<F>

export interface ImportFieldFormProps<F extends ImportField> {
  field: F
}

const registry = new WeakMap<ImportFieldType<any>, React.ComponentType<ImportFieldFormProps<any>>>()

export function getImportFieldFormComponent<F extends ImportField>(Field: Function | ImportFieldType<F>): React.ComponentType<ImportFieldFormProps<F>> | null {
  for (
    let current: Function = Field;
    current != null;
    current = superConstructor(current as any)
  ) {
    const Form = registry.get(current as ImportFieldType<F>)
    if (Form != null) { return Form }
  }

  return null
}

export function register<F extends ImportField>(Field: ImportFieldType<F>, target: React.ComponentType<ImportFieldFormProps<F>>) {
  registry.set(Field, target)
}