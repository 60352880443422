import React from 'react'
import { Branding } from '~/models'
import { memo } from '~/ui/component'
import {
  ClearButton,
  ColorSwatch,
  Empty,
  Label,
  List,
  Panel,
  PanelHeader,
  HBox,
  Tappable,
} from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { useBranding } from '../BrandingContext'
import { BrandingAsset, BrandingAssetType } from './BrandingAssetsContainer'
import BrandingBackgroundSwatch from './BrandingBackgroundSwatch'
import BrandingBorderSwatch from './BrandingBorderSwatch'

export interface Props {
  type:          BrandingAssetType
  selectedAsset: BrandingAsset | null

  requestAddAsset:    (type: BrandingAssetType) => any
  requestEditAsset:   (type: BrandingAssetType, name: string) => any
  requestRemoveAsset: (type: BrandingAssetType, name: string) => any
}

const BrandingAssetList = memo('BrandingAssetList', (props: Props) => {

  const {
    type,
    selectedAsset,
    requestEditAsset,
    requestAddAsset,
    requestRemoveAsset,
  } = props

  const {branding} = useBranding()

  const {t} = useResourceTranslation()

  const list =
    type === 'color' ? branding.colors :
    type === 'background' ? branding.backgrounds :
    type === 'border' ? branding.borders : {}

  const addAsset = React.useCallback(() => {
    requestAddAsset(type)
  }, [requestAddAsset, type])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel flex header={renderHeader()}>
        <List<string>
          data={Object.keys(list)}
          keyExtractor={keyExtractor}
          renderItem={renderAsset}
          EmptyComponent={renderEmpty}
          contentClassNames={$.listContent}
          scrollable
        />
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={t(`assets.${type}.caption`)}
        right={
          <ClearButton
            icon='plus'
            caption={t('buttons:add')}
            onTap={addAsset}
            small
          />
        }
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty
        {...t('fields.borders.empty')}
      />
    )
  }, [t])

  const keyExtractor = React.useCallback(
    (name: string) => name,
    [],
  )

  const renderAsset = React.useCallback((name: string) => {
    const selected = selectedAsset?.type === type && selectedAsset.name === name

    return (
      <BrandingAssetListItem
        type={type}
        name={name}
        selected={selected}
        requestEdit={requestEditAsset}
        requestRemove={requestRemoveAsset}
      />
    )
  }, [requestEditAsset, requestRemoveAsset, selectedAsset, type])

  return render()

})

export default BrandingAssetList

interface BrandingAssetListItemProps {
  type:     BrandingAssetType
  name:     string
  selected: boolean

  requestEdit:   (type: BrandingAssetType, name: string) => any
  requestRemove: (type: BrandingAssetType, name: string) => any
}

const BrandingAssetListItem = memo('BrandingAssetListItem', (props: BrandingAssetListItemProps) => {

  const {type, name, selected, requestEdit, requestRemove} = props
  const {branding} = useBranding()

  const edit = React.useCallback(() => {
    requestEdit(type, name)
  }, [name, requestEdit, type])

  const remove = React.useCallback(() => {
    requestRemove(type, name)
  }, [name, requestRemove, type])

  const isWellKnown =
    type === 'color' ? Branding.isWellKnownColor(name) :
    type === 'background' ? Branding.isWellKnownBackground(name) :
    type === 'border' ? Branding.isWellKnownBorder(name) : false

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable classNames={[$.brandingAssetListItem, {selected}]} onTap={edit}>
        <HBox flex='grow' gap={layout.padding.inline.m}>
          {renderSwatch()}
          {renderCaption()}
          {renderRemoveButton()}
        </HBox>
      </Tappable>
    )
  }

  function renderSwatch() {
    if (type === 'background') {
      return <BrandingBackgroundSwatch name={name}/>
    } else if (type === 'border') {
      return <BrandingBorderSwatch name={name}/>
    } else {
      return <ColorSwatch color={branding.colors[name] ?? name} round/>
    }
  }

  function renderCaption() {
    return (
      <HBox flex gap={layout.padding.inline.s}>
        <Label caption bold={isWellKnown} flex='shrink' mono>
          {name}
        </Label>
      </HBox>
    )
  }

  function renderRemoveButton() {
    if (isWellKnown) { return null }

    return (
      <ClearButton
        icon='trash'
        onTap={remove}
        padding='horizontal'
        small
      />
    )
  }

  return render()

})

export const swatchSize = {
  color:      {width: 16, height: 16},
  background: {width: 24, height: 24},
  border:     {width: 24, height: 24},
}

const useStyles = createUseStyles(theme => ({
  listContent: {
    ...layout.responsive(size => ({
      padding: layout.padding.s[size] - layout.padding.inline.s,
    })),
  },

  brandingAssetListItem: {
    height:       layout.barHeight.s,
    padding:      layout.padding.inline.m,
    borderRadius: layout.radius.m,

    '&:hover': {
      background: theme.bg.hover,
    },
    '&:active': {
      background: theme.bg.active,
    },

    '&.selected': {
      ...colors.overrideBackground(theme.semantic.primary),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.primary)),
    },
  },
}))