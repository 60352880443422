import React from 'react'
import { isFunction } from 'lodash'
import { Model } from '~/models'
import { observer } from '~/ui/component'
import { Center, DataGrid, GridList, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { childrenNotOfType, childrenOfType } from '~/ui/util'
import CheckboxCell from './CheckboxCell'
import ResourceCollectionFilters from './ResourceCollectionFilters'
import ResourceCollectionScreen, {
  Props as ResourceCollectionScreenProps,
  ResourceCollectionScreenState,
} from './ResourceCollectionScreen'

export interface Props<M extends Model> extends ResourceCollectionScreenProps<M> {
  allowSelect?: boolean | ((model: M) => boolean)
  renderItem:   (model: M) => React.ReactNode
  columns:      number
}

const _ResourceGridScreen = <M extends Model>(props: Props<M>) => {

  const {
    allowSelect = true,
    children,
    renderItem,
    columns,
    ...rest
  } = props

  const showCheckboxes = allowSelect && ((props.allowRemove ?? true) || (props.bulkActions ?? []).length > 0)
  const checkboxEnabled = React.useCallback((model: M) => {
    return isFunction(allowSelect) ? allowSelect(model) : allowSelect
  }, [allowSelect])

  const filters = childrenOfType(children, ResourceCollectionFilters)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceCollectionScreen {...rest} renderFilters={filters}>
        {state => (
          <>
            {renderGridList(state)}
            {childrenNotOfType(children, [DataGrid.Column, ResourceCollectionFilters])}
          </>
        )}
      </ResourceCollectionScreen>
    )
  }

  function renderGridList(state: ResourceCollectionScreenState<M>) {
    return (
      <GridList<M>
        data={state.data}
        columns={columns}
        renderCell={renderCell}
        EmptyComponent={state.EmptyComponent}
      />
    )
  }

  function renderCell(model: M) {
    return (
      <VBox classNames={$.cell}>
        {renderItem(model)}
        {showCheckboxes && renderCheckbox(model)}
      </VBox>
    )
  }

  function renderCheckbox(model: M) {
    if (!checkboxEnabled(model)) { return null }

    return (
      <Center classNames={$.cellCheckbox} padding={layout.padding.inline.m}>
        <CheckboxCell
          id={model.id}
        />
      </Center>
    )

  }

  return render()

}

const ResourceGridScreen = observer('ResourceGridScreen', _ResourceGridScreen)
Object.assign(ResourceGridScreen, {
  Filters: ResourceCollectionFilters,
})
export default ResourceGridScreen as typeof _ResourceGridScreen & {
  Filters: typeof ResourceCollectionFilters
}

const useStyles = createUseStyles({
  cell: {
    position: 'relative',
  },

  cellCheckbox: {
    position: 'absolute',
    top:      0,
    left:     0,
  },
})