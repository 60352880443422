import { DateTime } from 'luxon'
import { action, makeObservable, observable } from 'mobx'
import { FetchStatus } from 'mobx-document'
import { Metric, MetricDataFilters, MetricDatum } from '~/models'
import { ModelDocument } from '../data'

export default class MetricDataEndpoint {

  constructor(
    public readonly document: ModelDocument<Metric>,
  ) {
    makeObservable(this)
  }

  @observable
  public fetchStatus: FetchStatus = 'idle'

  @observable
  public data: MetricDatum[] = []

  @observable
  public total: number = 0

  private nextOffset: number | null = null

  //------
  // Fetching

  private fetchPromise: Promise<boolean> | null = null

  public async fetch(params: Omit<MetricDataFetchParams, 'offset'> = {}) {
    return await this.fetchImpl({
      ...params,
      offset: 0,
    })
  }

  public async fetchMore(params: Omit<MetricDataFetchParams, 'offset'> = {}) {
    if (this.fetchStatus !== 'done') { return false }

    return await this.fetchImpl({
      ...params,
      offset: this.nextOffset ?? 0,
    })
  }

  @action
  private async fetchImpl(params: MetricDataFetchParams = {}) {
    if (this.fetchPromise != null) {
      return await this.fetchPromise
    }

    this.fetchStatus = 'fetching'

    const {
      filters,
      offset = 0,
      limit,
    } = params

    const promise = this.document.callAction('data', {
      data: null,
      meta: {filters, offset, limit},
    }).then(action(response => {
      this.fetchStatus = 'done'
      this.fetchPromise = null
      if (response.status !== 'ok') { return false }

      const data = response.data.map(transformDatum) as MetricDatum[]
      const meta = response.meta as MetricDataMeta
      this.data       = offset === 0 ? data : [...this.data, ...data]
      this.total      = meta.total
      this.nextOffset = meta.nextOffset

      return true
    }))

    return this.fetchPromise = promise
  }

}

function transformDatum(raw: any) {
  return {
    ...raw,
    timestamp: DateTime.fromMillis(raw.timestamp),
  }
}

export interface MetricDataFetchParams {
  filters?: MetricDataFilters
  offset?:  number
  limit?:   number
}

export interface MetricDataMeta {
  total:      number
  nextOffset: number
}