import { Group } from './Group'
import { Ref } from './types'

export interface Targeting {
  groups:     Ref<Group>[]
  combinator: TargetingCombinator
}

export const Targeting: {empty: () => Targeting} = {
  empty: () => ({groups: [], combinator: 'all'}),
}

export type TargetingCombinator = 'any' | 'all'