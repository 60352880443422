import { isFunction } from 'lodash'
import Logger from 'logger'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { FetchStatus } from 'mobx-document'
import resizeImage, { ResizeSpec } from 'resize-image'
import socket, { SendResponse } from 'socket.io-react'
import { humanFileSize, patternToRegExp } from 'ytil'
import config from '~/config'
import { Media } from '~/models'
import authenticationStore from './auth/authenticationStore'
import { ListPack, resourceToModel } from './data'
import { Pack } from './data/types'
import { MediaUpload, MediaUploadResult } from './media'
import projectStore from './projectStore'
import { register } from './support'

const logger = new Logger('MediaStore')

export class MediaStore {

  constructor() {
    makeObservable(this)
  }

  @observable
  public media: Media[] = []

  @observable
  public fetchStatus: FetchStatus = 'idle'

  @action
  public fetchMedia() {
    this.fetchStatus = 'fetching'

    const promise = socket.send('media:list')
    return promise.then(this.onFetchMediaComplete)
  }

  private onFetchMediaComplete = action((response: SendResponse<ListPack<Media>>) => {
    if (!response.ok) {
      this.fetchStatus = response.error
    } else {
      const media = response.body.data.map(raw => Media.deserialize(resourceToModel(raw)))
      this.media.push(...media)
      this.fetchStatus = 'done'
    }
  })

  @action
  public storeMedia(name: string, blob: Blob, options: StoreMediaOptions = {}): MediaUpload | null {
    const {authToken} = authenticationStore
    const {projectID} = projectStore
    if (authToken == null) { return null }
    if (projectID == null) { return null }

    let mediaUpload!: MediaUpload
    let xhr!:         XMLHttpRequest

    const promise = new Promise<MediaUploadResult>(async resolve => {
      const {
        resize: resizeOpt = defaultResize,
        convert,
        ...rest
      } = options

      const resize    = isFunction(resizeOpt) ? resizeOpt(blob.type) : resizeOpt
      const converted = convert == null ? blob : await this.convertImage(blob, convert)
      const resized   = (!shouldResize(converted.type) || resize === false) ? converted : await resizeImage(converted, resize)

      if (blob.type !== converted.type) {
        logger.info(`Image converted from ${blob.type} to ${converted.type}.`)
      }
      if (converted.size !== resized.size) {
        logger.info(`Image resized from ${humanFileSize(blob.size)} to ${humanFileSize(resized.size)}.`)
      }

      const url = `${config.api.baseURL}/media`

      xhr = new XMLHttpRequest()
      xhr.timeout = 60_000

      xhr.open('POST', url)
      xhr.setRequestHeader('Content-Type', resized.type)
      xhr.setRequestHeader('Authorization', `Bearer ${authToken}`)
      xhr.setRequestHeader('X-ProjectID', projectID)
      xhr.setRequestHeader('X-Form-Data', btoa(JSON.stringify({name, ...rest})))

      xhr.upload.addEventListener('progress', event => {
        mediaUpload.setProgress({
          sent:  event.loaded,
          total: event.total,
        })
      })

      xhr.addEventListener('abort', () => {
        resolve({status: 'canceled'})
      })
      xhr.addEventListener('error', () => {
        resolve({status: 'error'})
      })

      xhr.addEventListener('load', () => {
        if (xhr.status !== 200) {
          const reason = reasonForXHRStatus(xhr.status)
          if (reason == null) {
            return resolve({status: 'error'})
          } else {
            return resolve({
              status: 'invalid',
              reason: reason,
            })
          }
        }

        const pack  = JSON.parse(xhr.responseText) as Pack<Media>
        const media = Media.deserialize(resourceToModel(pack.data!))

        runInAction(() => {
          this.media.push(media)
        })

        resolve({status: 'ok', media})
      })

      xhr.send(resized)
    })

    mediaUpload = new MediaUpload(xhr, promise)
    return mediaUpload
  }

  private async convertImage(blob: Blob, convert: ConvertMedia) {
    const toType = resolveConvert(convert, blob.type)
    if (toType == null) { return blob }

    const url = URL.createObjectURL(blob)

    try {
      return await new Promise<Blob>((resolve, reject) => {
        const canvas  = document.createElement('canvas')
        const context = canvas.getContext("2d")
        if (context == null) {
          reject("unable to obtain 2D context")
          return
        }

        const image = new Image()
        image.onload = async () => {
          canvas.width  = image.width
          canvas.height = image.height
          context.drawImage(image, 0, 0, canvas.width, canvas.height)
          canvas.toBlob(imageOrNull => {
            if (imageOrNull == null) {
              reject("Unable to generate image")
            } else {
              resolve(imageOrNull)
            }
          }, toType)
        }
        image.onerror = error => {
          reject(error)
        }
        image.src = url
      })
    } catch (error: any) {
      throw new Error("Cannot convert image: " + error)
    } finally {
      URL.revokeObjectURL(url)
    }
  }

  public onLogOut() {
    this.media = []
  }


}

function resolveConvert(convert: ConvertMedia, type: string) {
  if (isFunction(convert)) {
    return convert(type)
  } else {
    return Object.entries(convert).find(entry => patternToRegExp(entry[0], {wildcards: true}).test(type))?.[1] ?? null
  }
}

export interface StoreMediaOptions {
  prefix?:  string
  resize?:  ResizeSpec | false
  convert?: ConvertMedia
}

export type ConvertMedia = Record<string, string> | ((from: string) => string | undefined)

function shouldResize(type: string) {
  if (!type.startsWith('image/')) { return false }
  if (type === 'image/svg+xml') { return false }
  return true
}

const defaultResize = (type: string): ResizeSpec | false => {
  if (type === 'image/gif') {
    return false
  } else {
    return {fileSize: 768 * 1024}
  }
}

function reasonForXHRStatus(status: number) {
  switch (status) {
    case 413: return 'too-large'
    default:  return null
  }
}

export default register(new MediaStore())