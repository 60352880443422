import I18next from 'i18next'
import { DateTime } from 'luxon'
import {
  CalendarDay,
  CalendarItem,
  CircuitBreakerNode,
  ExitNode,
  FirstNode,
  isSegue,
  Model,
  ModuleNode,
  PlanComponent,
  PlanComponentWithBounds,
  PlannerAction,
  PlanNode,
  RoutingNode,
  SampleNode,
  SplitterNode,
  Trigger,
  Triggerable,
  TriggerableModelClass,
  TriggerNode,
} from '~/models'
import { SVGName } from '~/ui/components/SVG'
import { Theme } from '~/ui/styling/Theme'
import { CalendarItemType } from './types'

//------
// Icons & colors

export function triggerableIcon(triggerable: Triggerable, model?: Model | null, action?: PlannerAction | null): SVGName {
  if (triggerable.type === 'action') {
    return action?.icon ?? 'star'
  } else if (model != null) {
    return model.$icon
  } else {
    return TriggerableModelClass(triggerable.type)!.$icon
  }
}

export function routingNodeIcon(node: RoutingNode | CircuitBreakerNode | SplitterNode | FirstNode | SampleNode): SVGName {
  switch (node.type) {
    case 'routing':         return 'node'
    case 'circuit-breaker': return 'circuit'
    case 'splitter':        return 'splitter-node'
    case 'first':           return 'finish-flag'
    case 'sample':          return 'sample-node'
  }
}

export function triggerableBandColor(triggerableType: Triggerable['type'], theme: Theme) {
  switch (triggerableType) {
    case 'script':    return theme.colors.named.green
    case 'challenge': return theme.colors.named.orange
    case 'action':    return theme.colors.named.blue
  }
}

//------
// Node editing

export function triggerRequiresParameters(type: Trigger['type']) {
  if (['onboarding', 'registration', 'qrcode', 'manual', 'review:created'].includes(type)) {
    return false
  } else {
    return true
  }
}

export function defaultsForNode(nodeType: PlanNode['type']) {
  switch (nodeType) {
    case 'first': case 'sample':
      return {count: 1}
    default:
      return {}
  }
}

export function isConfigurableNode(node: PlanNode): node is EditablePlanNode {
  if (node.type === 'trigger') { return true }
  if (node.type === 'first' || node.type === 'sample') { return true }
  if (node.type === 'module' || node.type === 'exit') { return true }

  return false
}

export type EditablePlanNode = TriggerNode | ModuleNode | ExitNode | FirstNode | SampleNode

//------
// Misc

export function hasBounds(component: PlanComponent): component is PlanComponentWithBounds {
  return !isSegue(component)
}

//------
// Calendar planner

export function calendarDaySummary(day: CalendarDay) {
  switch (day.selector.type) {
    case 'offset': {
      return I18next.t([
        `flow_planner:day_selector.offset.summary_${day.selector.offset}`,
        'flow_planner:day_selector.offset.summary_other',
      ], day.selector)
    }

    case 'weekday': {
      return I18next.t([
        `flow_planner:day_selector.weekday.summary_${day.selector.week}`,
        'flow_planner:day_selector.weekday.summary_other',
      ], {
        week:    day.selector.week,
        weekday: DateTime.fromObject({weekday: day.selector.weekday}).toFormat('cccc'),
      })
    }
  }
}

export function calendarItemType(item: CalendarItem): CalendarItemType {
  switch (item.type) {
    case 'module':      return item.type
    case 'triggerable': return item.triggerable.type
  }
}