import { merge } from 'lodash'
// @index(\.yml$): import ${variable} from ${relpathwithext}
import answersQueries from './answers-queries.yml'
import brandings from './brandings.yml'
import challengeStates from './challenge-states.yml'
import challenges from './challenges.yml'
import channels from './channels.yml'
import chatBots from './chat-bots.yml'
import clientApps from './client-apps.yml'
import clientTabs from './client-tabs.yml'
import competitions from './competitions.yml'
import customData from './custom-data.yml'
import dashboards from './dashboards.yml'
import extensions from './extensions.yml'
import feeds from './feeds.yml'
import groupSignups from './group-signups.yml'
import groups from './groups.yml'
import insights from './insights.yml'
import locations from './locations.yml'
import media from './media.yml'
import metrics from './metrics.yml'
import modules from './modules.yml'
import organisations from './organisations.yml'
import pages from './pages.yml'
import participants from './participants.yml'
import plans from './plans.yml'
import posts from './posts.yml'
import projects from './projects.yml'
import qAndAs from './q-and-as.yml'
import questions from './questions.yml'
import registration from './registration.yml'
import reviews from './reviews.yml'
import scriptMessages from './script-messages.yml'
import scripts from './scripts.yml'
import users from './users.yml'

// /index

export default merge(
  // @index(\.yml$): ${variable},
  answersQueries,
  brandings,
  challengeStates,
  challenges,
  channels,
  chatBots,
  clientApps,
  clientTabs,
  competitions,
  customData,
  dashboards,
  extensions,
  feeds,
  groupSignups,
  groups,
  insights,
  locations,
  media,
  metrics,
  modules,
  organisations,
  pages,
  participants,
  plans,
  posts,
  projects,
  qAndAs,
  questions,
  registration,
  reviews,
  scriptMessages,
  scripts,
  users,
  // /index
)