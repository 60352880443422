import React from 'react'
import I18next from 'i18next'
import { Channel, ChatBot } from '~/models'
import ChatBotForm from '~/ui/app/chat-bots/ChatBotForm'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, ClearButton, ModalDialog, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { useBoolean, useFormOpen } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { RelatedResourceList, ResourceFieldProps } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import ChatBotField from '../../chat-bots/ChatBotField'
import ChatBotsDialog from '../../chat-bots/ChatBotsDialog'

export interface Props {
  open:         boolean
  requestClose: () => any

  channel: Channel
}

const ChannelBotList = memo('ChannelBotList', (props: Props) => {

  const {open, requestClose, channel} = props
  const {t, fieldPrompt, fieldInstruction} = useResourceTranslation()

  const mayRemoveBot = React.useCallback((bot: ChatBot) => {
    if (channel.type === 'participant') {
      return channel.bots.length > 1
    } else {
      return true
    }
  }, [channel.bots.length, channel.type])

  const [editingBot, setEditingBot] = React.useState<ChatBot | null>(null)
  const [chatBotFormOpen, currentEditingBot, closeChatBotForm] = useFormOpen(editingBot, () => { setEditingBot(null) })
  const [chatBotsDialogOpen, openChatBotsDialog, closeChatBotsDialog] = useBoolean()

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        headerRight='$close'
        width={540}
        icon='robot'
        title={t('bots.title')}
        closeOnClickOutside
        closeOnEscape
      >
        <VBox padding={layout.padding.m} gap={layout.padding.m}>
          <VBox gap={layout.padding.inline.m}>
            <FormFieldHeader
              caption={fieldPrompt('bots')}
              instruction={fieldInstruction('bots')}
            />
            <RelatedResourceList<ChatBot>
              Model={ChatBot}
              parent={channel}
              attribute='bots'
              itemProps={bot => ({image: renderAvatar(bot)})}
              allowRemove={mayRemoveBot}
              onItemTap={setEditingBot}
              renderAddField={renderChatBotField}
            />
          </VBox>

          <Center>
            <ClearButton
              icon='cog'
              caption={I18next.t('chat-bots:manage')}
              onTap={openChatBotsDialog}
            />
          </Center>
        </VBox>

        <ChatBotForm
          open={chatBotFormOpen}
          requestClose={closeChatBotForm}
          chatBot={currentEditingBot}
        />
        <ChatBotsDialog
          open={chatBotsDialogOpen}
          requestClose={closeChatBotsDialog}
        />
      </ModalDialog>
    )
  }

  function renderAvatar(bot: ChatBot) {
    return (
      <Avatar
        source={bot.avatar}
        firstName={bot.displayName}
        size={avatarSize}
      />
    )
  }

  function renderChatBotField(select: (bot: ChatBot) => void, props: Partial<ResourceFieldProps<ChatBot>>) {
    return (
      <ChatBotField
        moduleID={channel.module}
        onSelect={select}
        {...props}
      />
    )
  }

  return render()

})

export default ChannelBotList

export const avatarSize = layout.icon.xl