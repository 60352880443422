import React from 'react'
import {
  PlanTextAnnotation,
  PlanTextAnnotationAlignment,
  PlanTextAnnotationStyle,
  PlanTextAnnotationTextSize,
} from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, Slider, VBox } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  uuids:             string[]
  focusedAnnotation: PlanTextAnnotation
}

const TextAnnotationToolbar = memo('TextAnnotationToolbar', (props: Props) => {

  const {uuids, focusedAnnotation} = props

  const {planner} = useFlowPlanner()

  const textSize = React.useMemo(() => focusedAnnotation.style.textSize ?? PlanTextAnnotationTextSize.NORMAL, [focusedAnnotation.style.textSize])
  const bold     = React.useMemo(() => focusedAnnotation.style.bold ?? false, [focusedAnnotation.style.bold])
  const italic   = React.useMemo(() => focusedAnnotation.style.italic ?? false, [focusedAnnotation.style.italic])
  const align    = React.useMemo(() => focusedAnnotation.style.align ?? 'left', [focusedAnnotation.style.align])

  const setStyle = React.useCallback(async (update: Partial<PlanTextAnnotationStyle>) => {
    await planner.updateComponents<PlanTextAnnotation>(uuids, annotation => ({
      style: {...annotation.style, ...update},
    }))
  }, [planner, uuids])

  const setTextSize = React.useCallback((textSize: PlanTextAnnotationTextSize) => {
    setStyle({textSize})
  }, [setStyle])


  const setBold     = React.useCallback((bold: boolean) => setStyle({bold}), [setStyle])
  const setItalic   = React.useCallback((italic: boolean) => setStyle({italic}), [setStyle])
  const setAlign    = React.useCallback((align: PlanTextAnnotationAlignment) => setStyle({align}), [setStyle])

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <HBox
          classNames={$.textAnnotationToolbar}
          gap={layout.padding.inline.m}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
          onDoubleClick={stopPropagation}
        >
          {renderTextSizeControls()}
          <Label dimmer small>|</Label>
          {renderToggleButtons()}
          <Label dimmer small>|</Label>
          {renderAlignButtons()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderTextSizeControls() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <HBox>
          <ClearButton
            icon='font-smaller'
            onTap={decreaseTextSize}
            small
          />
          <VBox classNames={$.textSizeSlider}>
            <Slider
              value={textSize}
              onChange={setTextSize}
              min={PlanTextAnnotationTextSize.SMALL}
              max={PlanTextAnnotationTextSize.LARGE}
              step={2}
            />
          </VBox>
          <ClearButton
            icon='font-larger'
            onTap={increaseTextSize}
            small
          />
        </HBox>
      </HBox>
    )
  }

  function renderToggleButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='bold'
          onTap={toggleBold}
          color={bold ? colors.semantic.secondary: undefined}
          small
        />
        <ClearButton
          icon='italic'
          onTap={toggleItalic}
          color={italic ? colors.semantic.secondary: undefined}
          small
        />
      </HBox>
    )
  }

  function renderAlignButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='align-left'
          onTap={alignLeft}
          color={align === 'left' ? colors.semantic.secondary: undefined}
          small
        />
        <ClearButton
          icon='align-center'
          onTap={alignCenter}
          color={align === 'center' ? colors.semantic.secondary: undefined}
          small
        />
        <ClearButton
          icon='align-right'
          onTap={alignRight}
          color={align === 'right' ? colors.semantic.secondary: undefined}
          small
        />
      </HBox>
    )
  }

  //-------
  // Callbacks

  const decreaseTextSize = React.useCallback(() => {
    const nextTextSize = Math.max(PlanTextAnnotationTextSize.SMALL, textSize - 2)
    setTextSize(nextTextSize)
  }, [setTextSize, textSize])

  const increaseTextSize = React.useCallback(() => {
    const nextTextSize = Math.min(PlanTextAnnotationTextSize.LARGE, textSize + 2)
    setTextSize(nextTextSize)
  }, [setTextSize, textSize])

  const toggleBold   = React.useCallback(() => setBold(!bold), [bold, setBold])
  const toggleItalic = React.useCallback(() => setItalic(!italic), [italic, setItalic])
  const alignLeft    = React.useCallback(() => setAlign('left'), [setAlign])
  const alignCenter  = React.useCallback(() => setAlign('center'), [setAlign])
  const alignRight   = React.useCallback(() => setAlign('right'), [setAlign])

  const stopPropagation = React.useCallback((event: React.SyntheticEvent) => {
    event.stopPropagation()
  }, [])

  return render()

})

export default TextAnnotationToolbar

export const height = layout.barHeight.xs

const useStyles = createUseStyles({
  textAnnotationToolbar: {
    background:   colors.shim.dark,
    height:       height,
    borderRadius: height / 2,
    padding:      [layout.padding.inline.s, layout.padding.inline.m + layout.padding.inline.m],
  },

  textSizeSlider: {
    width: 64,
  },
})