import React from 'react'
import { ClientTab } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, ListItem, SVG } from '~/ui/components'
import { useLocalizedString } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { TypeCell } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import { useClientTabs } from './ClientTabManagerContext'

export interface Props {
  tab:   ClientTab
  index: number
}

const TabManagerListItem = memo('TabManagerListItem', (props: Props) => {

  const {tab, index} = props
  const {editingItems, removeTab, setEditingItemIndex, openForm, templates} = useClientTabs()

  const platforms = React.useMemo(
    () => templates.find(t => tab.link.href.startsWith(t.href))?.platforms ?? [],
    [templates, tab.link.href],
  )

  const caption = useLocalizedString(tab.caption)

  const {t} = useResourceTranslation('client-tabs')

  const startEditing = React.useCallback(() => {
    setEditingItemIndex(index)
    openForm()
  }, [setEditingItemIndex, openForm, index])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <ListItem
        classNames={[$.item, {editingItems}]}
        spacious
        accessory={renderAccessory()}
        caption={renderCaption()}
      />
    )
  }

  function renderAccessory() {
    return(
      <HBox gap={layout.padding.s}>
        {platforms.length === 1 &&
          <TypeCell
            resource='client-tabs'
            icon={platforms[0] === 'app' ? 'iphone' : 'web'}
            type={platforms[0]} />
        }
        {editingItems ? (
          renderEditAccessory()
        ) : (
          renderEditButton()
        )}
      </HBox>
    )
  }

  function renderCaption() {
    return(
      <HBox gap={layout.padding.s}>
        {renderIcon()}
        <Label children={caption}/>
      </HBox>
    )
  }

  function renderIcon() {
    if (tab.icon.type === 'custom') {
      return (
        <ImageView
          source={tab.icon.value}
          size={iconSize}
          classNames={[$.icon]}
        />
      )
    } else {
      return (
        <SVG
          name={tab.icon.value}
          size={iconSize}
        />
      )
    }
  }

  function renderEditButton() {
    return (
      <ClearButton
        onTap={startEditing}
        icon='pencil'
        aria-label={t('buttons:edit')}
      />
    )
  }

  function renderEditAccessory() {
    return (
      <HBox gap={layout.padding.inline.l}>
        <SVG
          name='sort-handle'
          size={layout.icon.m}
        />
        <ClearButton
          icon='cross-circle'
          aria-label={t('buttons:remove')}
          onTap={() => removeTab(index)}
        />
      </HBox>
    )
  }

  return render()

})

export default TabManagerListItem

export const iconSize = layout.icon.m

const useStyles = createUseStyles({
  icon: {
    '& [fill]': {
      fill: 'currentColor',
    },
  },
  item: {
    height: layout.barHeight.m,
    flex: [1, 0, 'auto'],
    overflow:  'hidden',
    '&.editingItems': {
      cursor: 'grab',
    },
  },
})
