import React from 'react'
import { Post } from '~/models'
import { ParticipantField, RichTextField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { Dimple, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField, SubmitButtonSpec } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import CommentFormModel from './CommentFormModel'

export interface Props extends Omit<FormDialogProps<CommentFormModel>, 'model' | 'children'> {
  feedID:  string
  postID:  string
  comment: Post | null
}

const CommentForm = observer('CommentForm', (props: Props) => {

  const {feedID, postID, comment, ...rest} = props

  const {t} = useResourceTranslation('posts')

  const formModel = React.useMemo(
    () => new CommentFormModel(feedID, postID, comment),
    [comment, feedID, postID],
  )

  const title = React.useMemo(
    () => comment == null ? t('comment_form.title_add') : t('comment_form.title_edit'),
    [comment, t],
  )

  const submitButton = React.useMemo((): SubmitButtonSpec | undefined => {
    if (comment == null) {
      return {
        icon:    'plus',
        caption: t('comment_form.add_comment'),
      }
    }
  }, [comment, t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='posts'>
        <FormDialog
          model={formModel}
          title={title}
          fieldGap={layout.padding.inline.s}
          children={renderFields()}
          submitButton={submitButton}
          resetOnSuccess
          {...rest}
        />
      </ResourceTypeProvider>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='author' required>
          {bind => <ParticipantField {...bind}/>}
        </FormField>
        <Dimple horizontal/>
        <FormField name='body' caption={false}>
          {bind => (
            <RichTextField
              {...bind}
              scope='inline'
              allowedNodes={['em', 'strong']}
              height={160}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default CommentForm