import I18n from 'i18next'
import { some } from 'lodash'
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import {
  Challenge,
  Condition,
  ConditionOperator,
  ConditionSelector,
  feedbackChoiceValue,
  LocalizedString,
  messageSummary,
  operatorRequiresOperand,
  Script,
  Variant,
} from '~/models'
import { dataStore, ModelDocument } from '~/stores'
import { FormError, SubmitResult } from '~/ui/form'
import { ConditionsFormModelContext } from './ConditionsFormModel'

export default class ConditionFormModel {

  constructor(
    public readonly condition: Condition | null,
    private requestSave: (condition: Condition) => SubmitResult | undefined | Promise<SubmitResult | undefined>,
    public readonly context: ConditionsFormModelContext = {},
  ) {
    this.currentStep = condition == null ? 'selector' : 'test'

    makeObservable(this)
    this.disposers.push(
      reaction(() => [this.selectorType, this.script, this.challenge], () => {
        this.fetchSelectorModel()
      }),
      reaction(() => [this.scriptMessageChoices, this.challengeTaskChoices], () => {
        this.selectDefaultQuestion()
      }),
      reaction(() => this.question, () => {
        this.selectDefaultTestVariant()
      }),
      reaction(() => [this.question, this.testVariant], () => {
        this.selectDefaultChoiceOperand()
      }),
    )

    this.fetchSelectorModel()
    this.selectDefaultQuestion()
    this.selectDefaultTestVariant()
    this.selectDefaultChoiceOperand()
  }

  public dispose() {
    this.disposers.forEach(dispose => dispose())
  }

  private disposers: IReactionDisposer[] = []

  @observable
  public currentStep: ConditionFormStep

  @action
  public goToStep(step: ConditionFormStep) {
    this.currentStep = step
  }

  //------
  // Selector

  @observable
  public selectorType: ConditionSelector['type'] = this.condition?.selector?.type ?? 'script-question'

  @observable
  public script: string | null = this.condition?.selector?.type === 'script-question' ? this.condition.selector.model : this.context.recentScriptIDs?.[0] ?? null

  @observable
  public scriptMessageUUID: string | null = this.condition?.selector?.type === 'script-question' ? this.condition.selector.questionUUID : null

  @observable
  public challenge: string | null = this.condition?.selector?.type === 'challenge-task' ? this.condition.selector.model : null

  @observable
  public challengeTaskUUID: string | null = this.condition?.selector?.type === 'challenge-task' ? this.condition.selector.questionUUID : null

  @observable
  public variable: string | null  = this.condition?.selector?.type === 'variable' ? this.condition.selector.variable : null

  @computed
  public get selector(): ConditionSelector {
    switch (this.selectorType) {
      case 'script-question':
        return {
          type:         'script-question',
          model:        this.script as string,
          questionUUID: this.scriptMessageUUID as string,
        }
      case 'challenge-task':
        return {
          type:         this.selectorType,
          model:        this.challenge as string,
          questionUUID: this.challengeTaskUUID as string,
        }
      case 'variable': default:
        return {
          type:      this.selectorType,
          variable:  this.variable as string,
        }
    }
  }

  @computed
  public get isFetchingQuestion() {
    switch (this.selectorType) {
      case 'script-question':
        return this.script != null && this.scriptMessageUUID != null && this.scriptQuestion == null
      case 'challenge-task':
        return this.challenge != null && this.challengeTaskUUID != null && this.challengeTask == null
      default:
        return false
    }
  }

  @computed
  public get question() {
    switch (this.selectorType) {
      case 'script-question': return this.scriptQuestion
      case 'challenge-task':  return this.challengeTask
      default: return null
    }
  }

  @computed
  public get scriptQuestion() {
    if (this.scriptMessageUUID == null) { return null }

    const script  = this.getScript()
    return script?.messages()
      ?.find(msg => msg.uuid === this.scriptMessageUUID)
      ?.feedback ?? null
  }

  @computed
  public get challengeTask() {
    if (this.challengeTaskUUID == null) { return null }

    const challenge = this.getChallenge()
    return challenge?.tasks
      ?.find(task => task.uuid === this.challengeTaskUUID)
      ?.question ?? null
  }

  @action
  private selectDefaultQuestion() {
    if (this.selectorType === 'script-question') {
      const choices = this.scriptMessageChoices
      const found = some(choices, choice => this.scriptMessageUUID === choice.value)
      if (!found) {
        this.scriptMessageUUID = choices[0]?.value ?? null
      }
    }
    if (this.selectorType === 'challenge-task') {
      const choices = this.challengeTaskChoices
      const found = some(choices, choice => this.challengeTaskUUID === choice.value)
      if (!found) {
        this.challengeTaskUUID = choices[0]?.value ?? null
      }
    }

  }

  @computed
  public get scriptMessageChoices() {
    const script = this.getScript()

    return (script?.messages() ?? [])
      .filter(msg => msg.feedback != null)
      .map(msg => ({value: msg.uuid, caption: messageSummary(msg)}))
  }

  @computed
  public get challengeTaskChoices() {
    const challenge = this.getChallenge()

    return (challenge?.tasks ?? [])
      .filter(task => task.question != null)
      .map(task => ({value: task.uuid, caption: LocalizedString.translate(task.title)}))
  }

  private getScript() {
    if (this.script == null) { return null }
    return dataStore.get(Script, this.script)
  }

  private getChallenge() {
    if (this.challenge == null) { return null }

    return dataStore.get(Challenge, this.challenge)
  }

  private fetchSelectorModel() {
    let document: ModelDocument<any> | null = null
    if (this.selectorType === 'script-question' && this.script != null) {
      document = dataStore.document(Script, this.script)
    }
    if (this.selectorType === 'challenge-task' && this.challenge != null) {
      document = dataStore.document(Challenge, this.challenge)
    }
    if (document == null) { return }
    if (document.data?.$hasDetail) { return }

    document.fetch()
  }

  //------
  // Test

  @observable
  public operand: Variant = this.condition?.operand ?? null

  @computed
  public get testVariants(): ConditionTestVariant[] {
    switch (this.question?.type) {
      case 'button':  return ['choice', 'presence', 'advanced']
      case 'choice':  return ['choice', 'presence', 'advanced']
      case 'text':    return ['presence', 'exact-match', 'partial-match', 'advanced']
      case 'numeric': return ['presence', 'exact-match', 'advanced']
      default:        return ['presence', 'advanced']
    }
  }

  @observable
  public testVariant: ConditionTestVariant = this.deriveTestVariant(this.condition?.operator ?? '!empty')

  @observable
  public presence_operator: ConditionOperator = this.derivePresenceOperator(this.condition?.operator ?? '!empty')

  @observable
  public exact_match_operator: ConditionOperator = this.deriveExactMatchOperator(this.condition?.operator ?? 'is')

  @observable
  public partial_match_operator: ConditionOperator = this.derivePartialMatchOperator(this.condition?.operator ?? 'contains')

  @observable
  public advanced_operator: ConditionOperator = this.condition?.operator ?? '=='

  @action
  public setTestVariant(variant: ConditionTestVariant) {
    this.testVariant = variant
  }

  @action
  private selectDefaultTestVariant() {
    this.setTestVariant(this.testVariants[0])
  }

  private selectDefaultChoiceOperand() {
    if (this.question?.type !== 'choice') { return }
    if (this.testVariant !== 'choice') { return }
    if (this.question.choices.length === 0) { return }

    const found = this.question.choices.find(choice => feedbackChoiceValue(choice) === this.operand)
    if (found == null) {
      this.operand = feedbackChoiceValue(this.question.choices[0])
    }
  }

  private resolveOperator(): ConditionOperator {
    switch (this.testVariant) {
      case 'presence':
        return this.presence_operator
      case 'choice':
        return '=='
      case 'exact-match':
        return this.exact_match_operator
      case 'partial-match':
        return this.partial_match_operator
      case 'advanced':
        return this.advanced_operator
    }
  }

  private deriveTestVariant(operator: ConditionOperator) {
    const valid: ConditionTestVariant[] = []
    switch (operator) {
      case 'empty': case '!empty':
        valid.push('presence')
        break
      case '==':
        valid.push('choice')
      // eslint-disable-next-line no-fallthrough
      case '!=': case 'is': case '!is': case 'match': case '!match':
        valid.push('exact-match', 'advanced')
        break
      case 'contains': case '!contains':
        valid.push('partial-match', 'advanced')
        break
      default:
        valid.push('advanced', 'advanced')
        break
    }

    return this.testVariants.find(variant => valid.includes(variant)) ?? 'advanced'
  }

  private derivePresenceOperator(operator: ConditionOperator) {
    if (operator === 'empty') {
      return 'empty'
    } else {
      return '!empty'
    }
  }

  private deriveExactMatchOperator(operator: ConditionOperator) {
    switch (operator) {
      case '!=': case '!is': case '!match': return '!is'
      default: return 'is'
    }
  }

  private derivePartialMatchOperator(operator: ConditionOperator) {
    switch (operator) {
      case '!=': case '!is': case '!match': return '!contains'
      default: return 'contains'
    }
  }

  //------
  // Submission

  public async submit() {
    const result = this.validate()
    if (result.status !== 'ok') { return result }

    const condition = this.buildCondition()
    return await this.requestSave(condition)
  }

  public validate(): SubmitResult {
    const errors: FormError[] = []

    if (this.selector.type === 'script-question' && this.script == null) {
      errors.push({
        field:   'script',
        message: I18n.t('validation:required'),
      })
    }

    if (this.selector.type === 'script-question' && this.scriptMessageUUID == null) {
      errors.push({
        field:   'scriptMessageUUID',
        message: I18n.t('validation:required'),
      })
    }

    if (this.selector.type === 'challenge-task' && this.challenge == null) {
      errors.push({
        field:   'challenge',
        message: I18n.t('validation:required'),
      })
    }

    if (this.selector.type === 'challenge-task' && this.challengeTaskUUID == null) {
      errors.push({
        field:   'challengeTaskUUID',
        message: I18n.t('validation:required'),
      })
    }

    if (this.selector.type === 'variable' && this.variable == null) {
      errors.push({
        field:   'variable',
        message: I18n.t('validation:required'),
      })
    }

    if (errors.length > 0) {
      return {status: 'invalid', errors}
    } else {
      return {status: 'ok'}
    }
  }

  private buildCondition(): Condition {
    const selector = this.selector
    const operator = this.resolveOperator()
    const operand  = operatorRequiresOperand(operator) ? this.operand : null

    return {selector, operator, operand}
  }

}

export type ConditionFormStep = 'selector' | 'test'

export type ConditionTestVariant = 'presence' | 'choice' | 'exact-match' | 'partial-match' | 'advanced'

export type ConditionExactMatchVariant = 'case-insensitive' | 'case-sensitive' | 'regular-expression'