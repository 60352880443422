import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionTriggerable, Challenge, Script, Triggerable, TriggerableModelClass } from '~/models'
import { projectStore, triggerableBandColor, triggerableIcon } from '~/stores'
import ProjectLabel from '~/ui/app/projects/ProjectLabel'
import { observer } from '~/ui/component'
import { HBox, Label, SVG, Tooltip, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useStyling, useTheme } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { useFlowPlanner } from '../flow/FlowPlannerContext'
import TriggerableBar, { Props as TriggerableBarProps } from './TriggerableBar'

export interface Props extends Omit<TriggerableBarProps, 'title' | 'detail' | 'icon'> {
  triggerable: Exclude<Triggerable, ActionTriggerable>
  nodeUUID?:   string
}

const ModelTriggerableBar = observer('ModelTriggerableBar', (props: Props) => {

  const {triggerable, nodeUUID, ...rest} = props
  const {planner} = useFlowPlanner(false)

  const Model         = TriggerableModelClass(triggerable.type)
  const modelID       = triggerable.model
  const isPlaceholder = modelID.startsWith('$')

  const [model, {fetchStatus}] = useModelDocumentData<Script | Challenge>(Model, isPlaceholder ? null : modelID, {
    fetch:  'never',
    detail: false,
  })

  const notFound = !isPlaceholder && model == null
  const empty    = !isPlaceholder && model?.empty

  const [t] = useTranslation('flow_planner')
  const {singular} = useResourceTranslation(Model.resourceType)

  const title = React.useMemo(() => {
    if (isPlaceholder) {
      return <Label mono bold>{modelID}</Label>
    } else {
      return model?.$caption ?? t('triggerables.not_found.title')
    }
  }, [isPlaceholder, model?.$caption, modelID, t])

  const detail = React.useMemo(() => {
    if (isPlaceholder) {
      return t('triggerables.placeholders.detail', {type: singular(), param: modelID})
    } else if (notFound) {
      return t('triggerables.not_found.detail', {type: singular()})
    } else {
      return model?.$details ?? null
    }
  }, [isPlaceholder, model?.$details, modelID, notFound, singular, t])

  const ownProjectID = projectStore.projectID
  const showProject  = ownProjectID != null && model != null && model.project !== ownProjectID

  React.useEffect(() => {
    if (nodeUUID == null) { return }
    planner?.setLoadingComponent(nodeUUID, false)
  }, [fetchStatus, nodeUUID, planner])

  const {colors} = useStyling()
  const theme = useTheme()

  //------
  // Rendering

  function render() {
    return (
      <TriggerableBar
        icon={triggerableIcon(triggerable, model)}
        title={title}
        detail={renderDetail()}
        accessory={renderAccessory()}
        bandColor={triggerableBandColor(triggerable.type, theme)}
        notFound={notFound}
        placeholder={isPlaceholder}
        {...rest}
        requestEdit={notFound ? undefined : rest.requestEdit}
      />
    )
  }

  function renderAccessory() {
    if (empty) {
      return (
        <Tooltip renderTooltip={t('triggerables.empty', {type: singular()})}>
          <SVG
            name='warning-full'
            color={colors.semantic.warning}
            size={layout.icon.xs}
          />
        </Tooltip>
      )
    }
  }

  function renderDetail() {
    if (!showProject && detail == null) { return null }
    if (!showProject) {
      return detail
    } else if (detail == null) {
      return <ProjectLabel projectID={model?.project} tiny dim/>
    } else {
      return (
        <HBox gap={layout.padding.inline.s}>
          <VBox flex='shrink'>
            {isReactText(detail) ? (
              <Label small caption>
                {detail}
              </Label>
            ) : detail}
          </VBox>
          <Label small dim>•</Label>
          <VBox flex='shrink'>
            <ProjectLabel projectID={model?.project} tiny dim/>
          </VBox>
        </HBox>
      )
    }
  }

  return render()

})

export default ModelTriggerableBar