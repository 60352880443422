import React from 'react'
import { NoticeScriptMessage, TextScriptMessage } from '~/models'
import { useScriptEditing } from '~/ui/app/scripts/editor/ScriptEditingContext'
import { memo } from '~/ui/component'
import { ConfirmBox, PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { useScriptEditor } from '../../ScriptEditorContext'
import DetailIndicator from '../DetailIndicator'

export interface Props {
  message: TextScriptMessage | NoticeScriptMessage
}

const LinkIndicator = memo('LinkIndicator', (props: Props) => {

  const {message} = props

  const {actionConfirm} = useResourceTranslation()

  const {colors} = useStyling()

  const {actionCaption}    = useResourceTranslation()
  const {setEditingDetail} = useScriptEditing()
  const {saveMessage}      = useScriptEditor()

  const editLink = React.useCallback(() => {
    setEditingDetail({
      messageUUID: message.uuid,
      type:        'link',
    })
  }, [message.uuid, setEditingDetail])

  const removeLink = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({...actionConfirm('remove_link'), destructive: true})
    if (!confirmed) { return }

    saveMessage(message.uuid, {link: null})
  }, [actionConfirm, message.uuid, saveMessage])

  const items = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    if (message.link == null) {
      items.push({icon: 'plus', caption: actionCaption('add_link'), onSelect: editLink})
    } else {
      items.push({
        icon:     'pencil',
        caption:  actionCaption('edit_link'),
        onSelect: editLink,
      })
      items.push({
        icon:     'cross-circle',
        caption:  actionCaption('remove_link'),
        color:    colors.semantic.negative,
        onSelect: removeLink,
      })
    }

    return items
  }, [actionCaption, colors.semantic.negative, editLink, message.link, removeLink])

  return (
    <DetailIndicator
      icon='link'
      tooltip={message.link?.href ?? null}
      active={message.link != null}
      items={items}
      onDoubleClick={editLink}
    />
  )
})

export default LinkIndicator