import React from 'react'
import { SelectionManager } from 'react-selection-manager'
import { Model } from '~/models'
import { observer } from '~/ui/component'
import { ResourceBar } from '~/ui/resources/components'

export interface Props {
  model:            Model
  selectionManager: SelectionManager<string | null>
}

const CollectionEditorItemBar = observer('CollectionEditorItemBar', (props: Props) => {

  const {model, selectionManager} = props

  const selected = selectionManager.isSelected(model.id)

  React.useEffect(
    () => selectionManager.register(model.id),
    [model.id, selectionManager],
  )

  //------
  // Callbacks

  const select = React.useCallback(() => {
    selectionManager.toggle(model.id)
  }, [model.id, selectionManager])

  //------
  // Rendering

  function render() {
    return (
      <ResourceBar
        model={model}
        onTap={select}
        selected={selected}
      />
    )
  }

  return render()

})

export default CollectionEditorItemBar