import { every, isPlainObject, some } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { BrandingGuide, ComponentBrandingBase } from '~/ui/styling'

export default class ComponentBrandingTree {

  constructor(
    public readonly guide: BrandingGuide,
  ) {
    makeObservable(this)
  }

  @observable
  public root: ComponentBrandingTreeNode = this.deriveTree()

  @computed
  public get allPaths(): string[] {
    return this.deriveAllPaths()
  }

  @computed
  public get treeList(): ComponentBrandingTreeListItem[] {
    return this.deriveTreeList()
  }

  //------
  // Filter

  @observable
  public filterTerms: string[] = []

  @observable
  public hideAppOnly: boolean = false

  @observable
  public hideWebOnly: boolean = false

  @action
  public setFilter(filter: string | null) {
    if (filter == null) {
      this.filterTerms = []
    } else {
      this.filterTerms = filter
        .split(/\s+/)
        .map(it => it.trim())
        .map(it => it.replace(/[^\d\w]/g, ''))
        .filter(Boolean)
    }
  }

  @action
  public toggleApp() {
    this.hideAppOnly = !this.hideAppOnly
    if (this.hideAppOnly && this.hideWebOnly) {
      this.hideAppOnly = false
      this.hideWebOnly = false
    }
  }

  @action
  public toggleWeb() {
    this.hideWebOnly = !this.hideWebOnly
    if (this.hideAppOnly && this.hideWebOnly) {
      this.hideAppOnly = false
      this.hideWebOnly = false
    }
  }

  private componentNameInFilter(name: string) {
    if (this.filterTerms.length === 0) { return true }

    const clean = name.replace(/[^\d\w]/g, '')
    return every(this.filterTerms, term => clean.includes(term))
  }

  private nodeInFilter(node: ComponentBrandingTreeNode): boolean {
    if (node.componentName == null) {
      return some(node.children, it => this.nodeInFilter(it))
    } else {
      if (this.hideWebOnly && !node.app) { return false }
      if (this.hideAppOnly && !node.web) { return false }
      return this.componentNameInFilter(node.componentName)
    }
  }

  //------
  // Expand / collapse tree

  @observable
  private expandedPaths = new Set<string>()

  @action
  public expandPath(path: string) {
    this.expandedPaths.add(path)
  }

  @action
  public collapsePath(path: string) {
    this.expandedPaths.delete(path)
  }

  @action
  public togglePath(path: string) {
    if (this.expandedPaths.has(path)) {
      this.collapsePath(path)
    } else {
      this.expandPath(path)
    }
  }

  public expandAll() {
    for (const path of this.allPaths) {
      this.expandedPaths.add(path)
    }
  }

  public collapseAll() {
    this.expandedPaths.clear()
  }

  //------
  // Derivations

  private deriveTree(): ComponentBrandingTreeNode {
    const nonComponentKeys = [
      /^(app|web)$/,
      /^(colors|backgrounds|borders)$/,
      /\.previewSize$/,
      /\.delegate$/,
      /\.defaults$/,
      /\.variants$/,
    ]

    const isBrandingContainer = (path: string, obj: any) => {
      if (obj == null) { return false }
      if (some(nonComponentKeys, it => it.test(path))) { return false }
      if (isPlainObject(obj)) { return true }
      if (obj instanceof BrandingGuide) { return true }
      if (obj.brandingContainer) { return true }
      return false
    }

    const root: ComponentBrandingTreeNode = {
      key: '',
      componentName: null,
      app: false,
      web: false,
      children: [],
    }

    const insertComponentAt = (path: string, componentName: string, app: boolean, web: boolean) => {
      const parts = path.split('.').filter(Boolean)
      const leaf  = parts.pop()
      if (leaf == null) { return }

      let part = parts.shift()
      let current: ComponentBrandingTreeNode = root
      while (part != null) {
        // eslint-disable-next-line no-loop-func
        let child = current.children.find(it => it.key === part)
        if (child == null) {
          current.children.push(child = {
            key:           part,
            componentName: null,
            app:           false,
            web:           false,
            children:      [],
          })
        }

        part    = parts.shift()
        current = child
      }

      current.children.push({
        key:           leaf,
        componentName: componentName,
        app:           app,
        web:           web,
        children:      [],
      })
    }

    const seen = new Set<any>()
    const visitComponent = (path: string, value: any, app: boolean, web: boolean) => {
      const isContainer   = isBrandingContainer(path, value)
      let componentName = value instanceof ComponentBrandingBase ? path : null
      if (!isContainer && componentName == null) { return null }

      if (componentName != null && app && !web) {
        componentName = `app.${componentName}`
      }
      if (componentName != null && !app && web) {
        componentName = `web.${componentName}`
      }

      if (componentName != null) {
        insertComponentAt(path, componentName, app, web)
      }

      // Prevent loops
      if (!seen.has(value) && isContainer) {
        for (const [childKey, child] of Object.entries(value)) {
          if (/[pP]arent$|[gG]uide$/.test(childKey)) { continue }

          const childPath = path === '' ? childKey : `${path}.${childKey}`
          visitComponent(childPath, child, app, web)
        }
      }
      seen.add(value)
    }

    visitComponent('', this.guide, true, true)
    visitComponent('', this.guide.app, true, false)
    visitComponent('', this.guide.web, false, true)

    // Now go through the tree and sort all children.
    const sortChildren = (node: ComponentBrandingTreeNode) => {
      node.children.sort((a, b) => {
        if (a.componentName == null) { return -1 }
        if (b.componentName == null) { return  1 }
        return a.key.localeCompare(b.key)
      })

      for (const child of node.children) {
        sortChildren(child)
      }
    }

    sortChildren(root)
    return root
  }

  private deriveAllPaths(): string[] {
    const keys: string[] = []

    const visitNode = (node: ComponentBrandingTreeNode) => {
      keys.push(node.key)
      node.children.forEach(visitNode)
    }

    visitNode(this.root)
    return keys
  }

  private deriveTreeList(): ComponentBrandingTreeListItem[] {
    const list: ComponentBrandingTreeListItem[] = []

    const visitNode = (prefix: string, node: ComponentBrandingTreeNode, level: number) => {
      if (!this.nodeInFilter(node)) { return }

      const hasChildren = node.children.length > 0
      const path        = `${prefix}${node.key}`
      const key         = `${path}[${node.app ? 'app' : ''},${node.web ? 'web' : ''}]`
      const expanded    = this.expandedPaths.has(path)

      list.push({
        key:           key,
        path:          path,
        caption:       node.key,
        componentName: node.componentName,
        level:         level,
        app:           node.app,
        web:           node.web,
        status:        !hasChildren ? 'nochildren' : expanded ? 'expanded' : 'collapsed',
      })

      if (level < 0 || expanded || this.filterTerms.length > 0) {
        const childPrefix = prefix === '' ? path : `${prefix}.${path}`
        node.children.forEach(node => visitNode(childPrefix, node, level + 1))
      }
    }

    visitNode('', this.root, -1)

    // Always take out the first (root) item.
    return list.slice(1)
  }


}

export interface ComponentBrandingTreeNode {
  key:           string
  componentName: string | null
  app:           boolean
  web:           boolean
  children:      ComponentBrandingTreeNode[]
}

export interface ComponentBrandingTreeListItem {
  key:           string
  path:          string
  caption:       string
  componentName: string | null
  level:         number
  app:           boolean
  web:           boolean
  status:        'nochildren' | 'expanded' | 'collapsed'
}