import React from 'react'
import { ModelClass, Participant } from '~/models'
import { BulkSelector, dataStore } from '~/stores'
import { memo } from '~/ui/component'
import { CollectionEditor, ModalDialog } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'

export interface Props {
  open:         boolean
  requestClose: () => any

  ParentModel:     ModelClass<any>
  parentID:        string
  filterAttribute: string
}

const ParticipantsForm = memo('ParticipantsForm', (props: Props) => {

  const {
    open,
    requestClose,
    ParentModel,
    parentID,
    filterAttribute,
  } = props

  const availableEndpoint = useModelEndpoint(Participant, {
    fetch:   false,
    filters: {[filterAttribute]: `!${parentID}`},
  })
  const includedEndpoint  = useModelEndpoint(Participant, {
    fetch:   false,
    filters: {[filterAttribute]: parentID},
  })

  const {t} = useResourceTranslation('participants')

  //------
  // Callbacks

  const fetchEndpoints = React.useCallback(() => {
    availableEndpoint.resetAndFetch()
    includedEndpoint.resetAndFetch()
  }, [availableEndpoint, includedEndpoint])

  const add = React.useCallback((selector: BulkSelector) => {
    const document = dataStore.document(ParentModel, parentID)
    return document.callBulkAction('participants:add', selector, {
      timeout: 60_000,
    })
  }, [ParentModel, parentID])

  const remove = React.useCallback((selector: BulkSelector) => {
    const document = dataStore.document(ParentModel, parentID)
    return document.callBulkAction('participants:remove', selector, {
      timeout: 60_000,
    })
  }, [ParentModel, parentID])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        title={t('collection_form.title')}
        onWillOpen={fetchEndpoints}
        headerRight='$close'
        contentPadding={true}
        closeOnClickOutside={true}
        closeOnEscape={true}
        width='max'
        height='max'
      >
        <CollectionEditor<Participant>
          availableEndpoint={availableEndpoint}
          includedEndpoint={includedEndpoint}
          requestAdd={add}
          requestRemove={remove}
          performInitialFetch={false}
        />
      </ModalDialog>
    )
  }

  return render()

})

export default ParticipantsForm