import React from 'react'
import ProjectField from '~/ui/app/projects/ProjectField'
import { observer } from '~/ui/component'
import { Center, Spinner, TextBlock, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import CopyToProjectFormModel from './CopyToProjectFormModel'

export interface Props {}

const DestinationStep = observer('DestinationStep', () => {

  const {model: formModel} = useForm<CopyToProjectFormModel>()

  const {preflightStatus} = formModel

  const {t} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='grow' classNames={$.DestinationStep}>
        {preflightStatus === 'fetching' ? (
          renderPreparing()
        ) : (
          renderContent()
        )}
      </VBox>
    )
  }

  function renderPreparing() {
    return (
      <Center flex='grow'>
        <Spinner/>
      </Center>
    )
  }

  function renderContent() {
    return (
      <VBox flex='grow' gap={layout.padding.s}>
        <TextBlock dim>
          {t('actions.copy_to_project.preamble')}
        </TextBlock>
        {renderProjectField()}
      </VBox>
    )
  }

  function renderProjectField() {
    return (
      <FormField name='destProject' caption={false} placeholder={t('actions.copy_to_project.fields.dest_project.placeholder')}>
        {bind => <ProjectField {...bind}/>}
      </FormField>
    )
  }

  return render()

})

export default DestinationStep

const useStyles = createUseStyles({
  DestinationStep: {
  },
})