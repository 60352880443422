import type { Breadcrumb } from '~/ui/layouts/app'
import I18next from 'i18next'
import { upperFirst } from 'lodash'
import { Model, ModelClass, Module } from '~/models'
import { resourceListPath } from '~/ui/resources/routes'
import { resourceDetailPath } from './routes/paths'

export function resourceDetailBreadcrumbs(modelOrModelClass: Model | ModelClass<Model>, parentModelOrModelClass: Model | ModelClass<Model> | null = null, module: Module | null = null): Breadcrumb[] {
  const Model  = '$ModelClass' in modelOrModelClass ? modelOrModelClass.$ModelClass : modelOrModelClass
  const model  = '$ModelClass' in modelOrModelClass ? modelOrModelClass : null

  const parentBreadcrumbs = parentModelOrModelClass == null ? [] : resourceDetailBreadcrumbs(parentModelOrModelClass)

  const breadcrumbs: Breadcrumb[] = []
  if (module != null) {
    breadcrumbs.push({
      icon:    'puzzle',
      caption: module.$caption,
      href:    resourceDetailPath('modules', module.id),
    })
  }

  breadcrumbs.push(...parentBreadcrumbs)
  if (parentBreadcrumbs.length === 0) {
    breadcrumbs.push(...resourceListBreadcrumbs(Model))
  }

  if (model != null) {
    breadcrumbs.push({
      // If the parent breadcrumb already shows the resource type, we don't have to repeat the icon.
      icon:    parentBreadcrumbs.length === 0 ? undefined : model.$icon,
      caption: model.$caption,
      href:    resourceDetailPath(Model.resourceType, model.id),
    })
  }

  return breadcrumbs
}

export function resourceListBreadcrumbs(Model: ModelClass<any>, parentModelOrModelClass: Model | ModelClass<Model> | null = null, module: Module | null = null): Breadcrumb[] {
  const plural = I18next.t(`${Model.resourceType}:plural`)
  const parentBreadcrumbs = parentModelOrModelClass == null ? [] : resourceDetailBreadcrumbs(parentModelOrModelClass)

  const breadcrumbs: Breadcrumb[] = []

  if (module != null) {
    breadcrumbs.push({
      icon:    'puzzle',
      caption: module.$caption,
      href:    resourceDetailPath('modules', module.id),
    })
  }

  breadcrumbs.push(...parentBreadcrumbs)

  breadcrumbs.push({
    icon:    Model.$icon,
    caption: upperFirst(plural),
    href:    `${resourceListPath(Model.resourceType)}?back=1`,
  })

  return breadcrumbs
}