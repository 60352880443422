import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dashboard } from '~/models'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, HBox, PushButton } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {
  dashboard: Dashboard
  editing:   boolean

  startEditing: () => any
  stopEditing:  () => any
  requestSave:  () => Promise<SubmitResult | undefined>
}

const DashboardActions = observer('DashboardActions', (props: Props) => {

  const {dashboard, editing, startEditing, stopEditing, requestSave} = props
  const me      = authenticationStore.user
  const mayEdit = me != null && (me.isAdmin() || dashboard.user === me.id)

  const [t] = useTranslation('analytics')

  //------
  // Rendering

  function render() {
    if (editing) {
      return (
        <HBox gap={layout.padding.s}>
          <PushButton
            icon='check'
            caption={t('buttons:save')}
            onTap={requestSave}
            small
          />
          <ClearButton
            icon='cross'
            caption={t('buttons:cancel')}
            onTap={stopEditing}
            small
            dim
          />
        </HBox>
      )
    } else if (mayEdit) {
      return (
        <PushButton
          icon='pencil'
          caption={t('actions.edit')}
          onTap={startEditing}
          small
        />
      )
    } else {
      return null
    }
  }

  return render()

})

export default DashboardActions