import Color from 'color'
import { Access, Targeting } from '~/models'
import { serialize } from '../lib/ymodel/src/decorators/serialize'
import { Group } from './Group'
import { Model, resource } from './Model'
import { Ref } from './types'

@resource<Feed>('feeds', {
  icon:      () => 'feed',
  caption:   feed => feed.name,
  appLink:   feed => `//news?feedID=${encodeURIComponent(feed.id)}`,
})
export class Feed extends Model {

  public name!:  string
  public title!:  string

  @serialize(Color)
  public color!: Color

  public postCount!: number

  public targeting!:  Targeting
  public moderation!: FeedModeration
  public mayPost!:    Access
  public mayComment!: Access

  public get isModerated() {
    return this.moderation != null
  }

}

export interface FeedModeration {
  moderatorGroup: Ref<Group>
}