import React from 'react'
import { SampleResult } from '~/models'
import { memo } from '~/ui/component'
import { MediaGallery, VBox } from '~/ui/components'
import { MediaItem } from '~/ui/components/MediaGallery'
import { createUseStyles } from '~/ui/styling'

export interface Props {
  result: SampleResult
}

const GalleryResult = memo('GalleryResult', (props: Props) => {

  const {result} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.galleryResult}>
        <MediaGallery
          items={mediaItems}
        />
      </VBox>
    )
  }

  const mediaItems = React.useMemo(() => {
    const items: MediaItem[] = []
    for (const value of result.values) {
      if (typeof value !== 'string') { continue }
      items.push({url: value})
    }
    return items
  }, [result])

  return render()

})

export default GalleryResult

const useStyles = createUseStyles({
  galleryResult: {
    overflow: 'hidden',
    minHeight: 120,
  },
})
