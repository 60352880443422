import { Media } from '~/models'
import { MediaUploadResult, ProgressListener, UploadProgress } from './types'

export default class MediaUpload {

  constructor(
    private readonly xhr: XMLHttpRequest,
    private readonly promise: Promise<MediaUploadResult>,
  ) {}

  //------
  // Progress handlers

  private readonly progressListeners = new Set<ProgressListener>()

  public addProgressListener(listener: ProgressListener) {
    this.progressListeners.add(listener)
    return () => {
      this.progressListeners.delete(listener)
    }
  }

  public setProgress(progress: UploadProgress) {
    for (const listener of this.progressListeners) {
      listener(progress)
    }
  }

  //------
  // Cancelation

  public cancel() {
    this.xhr.abort()
  }

  //------
  // Promise interface

  public then<TResult1 = Media | null, TResult2 = never>(onfulfilled?: ((value: MediaUploadResult) => TResult1 | PromiseLike<TResult1>) | undefined | null, onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | undefined | null): Promise<TResult1 | TResult2> {
    return this.promise.then(onfulfilled, onrejected)
  }

}