import React from 'react'
import I18n from 'i18next'
import { Script, ScriptTriggerable } from '~/models'
import { useScriptEditing } from '~/ui/app/scripts/editor/ScriptEditingContext'
import ScriptEditor from '~/ui/app/scripts/editor/ScriptEditor'
import { ScriptEditorContainer } from '~/ui/app/scripts/editor/ScriptEditorContext'
import { memo, observer } from '~/ui/component'
import { ClearButton, Dimple, HBox, Label, ModalDialog, Spinner, TextField } from '~/ui/components'
import { InlineFormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { InlineResourceForm } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import DefaultLanguageToggle from '../../fields/DefaultLanguageToggle'
import { useScriptEditor } from '../../scripts/editor/ScriptEditorContext'
import { useCalendarPlanner } from '../calendar/CalendarPlannerContext'
import { useFlowPlanner } from '../flow/FlowPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any

  triggerable:      ScriptTriggerable | null
}

const ScriptTriggerableDialog = memo('ScriptTriggerableDialog', (props: Props) => {

  const {open, requestClose} = props
  const triggerable = open ? props.triggerable : null

  function render() {
    if (triggerable == null) { return null }

    return (
      <ScriptEditorContainer scriptID={triggerable.model}>
        <ScriptTriggerableDialogContent
          open={open}
          requestClose={requestClose}
        />
      </ScriptEditorContainer>
    )
  }

  return render()

})

interface ScriptTriggerableDialogContentProps {
  open:          boolean
  requestClose?: () => any
}


const ScriptTriggerableDialogContent = observer('ScriptTriggerableDialogContent', (props: ScriptTriggerableDialogContentProps) => {

  const {open, requestClose} = props

  const {editor, script} = useScriptEditor()
  const {planner: flowPlanner}     = useFlowPlanner(false)
  const {planner: calendarPlanner} = useCalendarPlanner(false)

  const revalidate = React.useCallback(() => {
    flowPlanner?.service.validate()
    calendarPlanner?.service.validate()
  }, [calendarPlanner?.service, flowPlanner?.service])

  const revalidateAndClose = React.useCallback(async () => {
    revalidate()
    requestClose?.()
  }, [requestClose, revalidate])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={revalidateAndClose}
        children={<ScriptEditor/>}
        icon={script?.$icon}
        title={renderNameEditor()}
        headerRight={renderHeaderRight()}
        width={720}
        height='max'
        showSpinner={editor?.saving}
        semi={false}
        closeOnClickOutside={true}
        // Ensure focus on the new message field, not on the first message (button).
        autoFocus='input[type="text"]'
      />
    )
  }

  function renderNameEditor() {
    if (script == null) {
      return (
        <HBox>
          <Spinner size={12}/>
        </HBox>
      )
    }

    return (
      <InlineResourceForm Model={Script} model={script} autoSubmit>
        <InlineFormField
          name='name'
          caption={false}
          renderView={value => <Label h2>{value}</Label>}
          renderEdit={bind => <TextField {...bind}/>}
        />
      </InlineResourceForm>
    )
  }

  function renderHeaderRight() {
    return (
      <HeaderRight
        {...props}
      />
    )
  }

  return render()

})

const HeaderRight = memo('HeaderRight', (props: ScriptTriggerableDialogContentProps) => {

  const {requestClose} = props

  const {script} = useScriptEditor()
  const {
    editingList,
    startEditingList,
    stopEditingList,
    selectedMessageUUIDs,
    selectAllMessages,
    selectNoMessages,
  } = useScriptEditing()

  const {actionCaption} = useResourceTranslation('script-messages')
  const count = script?.messages?.length ?? 0

  function render() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <DefaultLanguageToggle/>
        {!editingList ? (
          renderEditListButton()
        ) : (
          renderEditingListButtons()
        )}
        <Dimple vertical/>
        {renderCloseButton()}
      </HBox>
    )
  }

  function renderEditListButton() {
    return (
      <ClearButton
        icon='pencil'
        caption={actionCaption('edit_list')}
        onTap={startEditingList}
      />
    )
  }

  function renderEditingListButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          caption={actionCaption('select_all')}
          onTap={selectAllMessages}
          enabled={selectedMessageUUIDs.length < count}
        />
        <ClearButton
          caption={actionCaption('select_none')}
          onTap={selectNoMessages}
          enabled={selectedMessageUUIDs.length > 0}
        />
        <Dimple vertical/>
        <ClearButton
          icon='check'
          caption={I18n.t('buttons:done')}
          onTap={stopEditingList}
        />
      </HBox>
    )
  }

  function renderCloseButton() {
    return (
      <ClearButton
        icon='cross'
        caption={I18n.t('buttons:close')}
        onTap={requestClose}
      />
    )
  }

  return render()

})

export default ScriptTriggerableDialog