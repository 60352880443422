import React from 'react'
import { useTranslation } from 'react-i18next'
import { snakeCase } from 'lodash'
import { observer } from '~/ui/component'
import { FormContext, FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import TriggerableFormModel from './TriggerableFormModel'
import renderTriggerableFields from './fields'

export type Props<M extends TriggerableFormModel> = FormDialogProps<M>

const ModelTriggerableForm = observer('ModelTriggerableForm', <M extends TriggerableFormModel>(props: Props<M>) => {

  const formModel = props.model
  const variant   = formModel.modelVariant

  const renderAsCreate = variant?.creatingNew ?? null

  const TriggerableModel = variant?.Model ?? null

  const createNew = React.useCallback((name: string) => {
    variant?.createNew(name)
  }, [variant])

  const [t]               = useTranslation('flow_planner')
  const {singular}        = useResourceTranslation(variant?.Model.resourceType)
  const title             = t(`create_component.${snakeCase(variant?.triggerableType)}`)
  const createPlaceholder = React.useCallback((name: string | null) => {
    if (name == null) {
      return t('triggerables.create_new', {type: singular()})
    } else {
      return t('triggerables.create_new_named', {type: singular(), name})
    }
  }, [singular, t])

  const formRef = React.useRef<FormContext<any>>(null)

  const initialData = React.useMemo((): any => {
    if (variant?.triggerableType === 'script') {
      return {type: 'linear'}
    } else {
      return {}
    }
  }, [variant?.triggerableType])

  //------
  // Rendering

  function render() {
    if (variant == null) { return null }

    return (
      <FormDialog title={title} initialData={initialData} {...props} formRef={formRef}>
        {props.children}
        {formModel.triggerable == null && renderTriggerableField()}
      </FormDialog>
    )
  }

  function renderTriggerableField() {
    if (variant == null) { return null }
    if (renderAsCreate) {
      return renderTriggerableFields(variant.triggerableType, formModel.module.id)
    } else {
      return renderResourceField()
    }
  }

  function renderResourceField() {
    if (variant == null) { return null }
    if (TriggerableModel == null) { return null }

    return (
      <FormField name='existingModel' caption={t('triggerables.find', {type: singular()})!}>
        {bind => (
          <ResourceField
            {...bind}
            Model={TriggerableModel}
            createPlaceholder={createPlaceholder}
            requestCreate={createNew}
            placeholderNames={variant.triggerableParameterNames}
            placeholdersCaption={t('triggerables.placeholders.caption')}
            placeholderDetail={param => t('triggerables.placeholders.detail', {type: singular(), param})}
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default ModelTriggerableForm