import I18next from 'i18next'
import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Beacon } from '~/models'
import CollectionFieldItemFormModel from '~/ui/components/fields/collection/CollectionFieldItemFormModel'
import { FormError, SubmitResult } from '~/ui/form'

export default class BeaconFormModel extends CollectionFieldItemFormModel<Beacon> {

  constructor(item: Beacon | null, onSubmit: (item: Beacon) => any) {
    super(item, onSubmit)

    makeObservable(this)
  }

  @observable
  private name: string = this.item?.name ?? ''

  @observable
  private proximityUUID: string = this.item?.proximityUUID ?? ''

  @observable
  private major: number | null = this.item?.major ?? null

  @observable
  private minor: number | null = this.item?.minor ?? null

  protected override validate(): SubmitResult {
    const errors: FormError[] = []

    if (!UUID_REGEX.test(this.proximityUUID.trim())) {
      errors.push({
        field:   'proximityUUID',
        message: I18next.t('validation:uuid'),
      })
    }

    return errors.length === 0 ? {status: 'ok'} : {status: 'invalid', errors}
  }

  protected buildItem(): Beacon {
    return {
      name:          cleanTextValue(this.name, true),
      proximityUUID: this.proximityUUID.trim(),
      major:         this.major,
      minor:         this.minor,
    }
  }

}

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i