import React from 'react'
import { useTranslation } from 'react-i18next'
import { cleanTextValue } from 'ytil'
import { LocalizedString } from '~/models'
import { memo } from '~/ui/component'
import {
  CollectionField,
  Dimple,
  HBox,
  ItemFormProps,
  LanguageFlag,
  ModalDialog,
  TextField,
  VBox,
} from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props {
  open: boolean
  requestClose: () => void

  value:    LocalizedString
  setValue: (value: LocalizedString) => any
  languages: Language[]

  multiline?: boolean
  onFocusLanguage?: (language: string) => any
}

export interface Language {
  code: string
  name_en: string
  name_loc: string
}

const LocalizedTextFieldDialog = memo('LocalizedTextFieldDialog', (props: Props) => {

  const {
    open,
    requestClose,
    value,
    setValue,
    multiline = false,
    languages,
    onFocusLanguage,
  } = props

  const [t] = useTranslation('localized_text_field')

  const translations = React.useMemo(
    () => languages.map(language => ({language, text: value[language.code] ?? ''})),
    [languages, value],
  )

  const setTranslations = React.useCallback((translations: LocalizedStringTranslation[]) => {
    setValue(translations.reduce((acc, {language, text}) => {
      const clean = cleanTextValue(text)
      return clean == null ? acc : {...acc, [language.code]: clean}
    }, {}))
  }, [setValue])

  // #region Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        width={640}
        title={t('dialog_title')}
        requestClose={requestClose}
        children={renderDialog()}
        headerRight='$close'
      />
    )
  }

  function renderDialog() {
    const actions = renderActions()

    return (
      <VBox gap={layout.padding.s} padding={layout.padding.m}>
        <VBox>
          <CollectionField
            value={translations}
            onChange={setTranslations}
            renderForm={renderTranslationForm}
            allowAdd={false}
            allowRemove={false}
            sortable={false}
          />
        </VBox>
        {actions && <Dimple horizontal/>}
        {actions}
      </VBox>
    )
  }

  function renderTranslationForm(props: ItemFormProps<LocalizedStringTranslation>) {
    return (
      <LanguageForm
        {...props}
        multiline={multiline}
        onFocus={onFocusLanguage}
      />
    )
  }

  function renderActions() {
    return null // Implemented soon.
  }

  // #endregion

  return render()

})

interface LanguageFormProps extends ItemFormProps<LocalizedStringTranslation> {
  multiline?: boolean
  onFocus?: (language: string) => any
}

const LanguageForm = memo('LanguageForm', (props: LanguageFormProps) => {

  const {
    item: {language},
    multiline,
    onFocus,
  } = props

  const focus = React.useCallback(() => {
    onFocus?.(language.code)
  }, [language, onFocus])

  const inputAttributes = React.useMemo(() => ({
    onFocus: focus,
  }), [focus])

  function render() {
    return (
      <HBox gap={layout.padding.s}>
        <LanguageFlag
          language={props.item.language}
        />
        <VBox flex>
          <TextField
            {...props.bind('text')}
            multiline={multiline}
            inputAttributes={inputAttributes}
          />
        </VBox>
      </HBox>
    )
  }

  return render()

})

interface LocalizedStringTranslation {
  language: Language
  text: string
}

export default LocalizedTextFieldDialog