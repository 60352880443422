import React from 'react'
import { range } from 'lodash'
import { DateTime } from 'luxon'
import { observer } from '~/ui/component'
import { HBox, Label, NumberField, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDataSourceProvider, FormField, FormSwitch, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import DaySelectorFormModel from './DaySelectorFormModel'

export interface Props {
  selectorModelProp: string
}

const DaySelectorFields = observer('DaySelectorFields', (props: Props) => {

  const {selectorModelProp} = props
  const {model} = useForm()
  const {t} = useResourceTranslation('flow_planner')

  const selectorModel = React.useMemo(() => {
    const selectorModel = (model as any)[selectorModelProp]
    if (!(selectorModel instanceof DaySelectorFormModel)) {
      throw new Error(`DaySelectorFields: model.${selectorModelProp} is not a DaySelectorFormModel`)
    }
    return selectorModel
  }, [model, selectorModelProp])

  const weekdayChoices = React.useMemo(
    (): Choice[] => range(1, 8).map(weekday => ({
      value:   weekday,
      caption: DateTime.fromObject({weekday}).toFormat('cccc'),
    })),
    [],
  )

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <FormDataSourceProvider dataSource={selectorModel}>
          <FormSwitch name='type'>
            <FormSwitch.TypeOption
              type='offset'
              {...t('day_selector.offset')}
              renderForm={renderOffsetFields}
            />
            <FormSwitch.TypeOption
              type='weekday'
              {...t('day_selector.weekday')}
              renderForm={renderWeekdayFields}
            />
          </FormSwitch>
        </FormDataSourceProvider>
      </VBox>
    )
  }

  function renderOffsetFields() {
    return (
      <FormField name='offset' caption={false}>
        {bind => (
          <NumberField
            {...bind}
            step={1}
            minimum={0}
            accessoryLeft={renderPrefixAccessory(t('day_selector.offset.accessory'))}
            inputStyle='dark'
          />
        )}
      </FormField>
    )
  }

  function renderWeekdayFields() {
    return (
      <HBox gap={layout.padding.xs} align='top'>
        <VBox flex={2}>
          <FormField name='weekday' caption={false}>
            {bind => <SelectField {...bind} choices={weekdayChoices} inputStyle='dark'/>}
          </FormField>
        </VBox>
        <Label style={{paddingTop: '0.6em'}}>{t('day_selector.weekday.infix')}</Label>
        <VBox flex={1}>
          <FormField name='week' caption={false}>
            {bind => (
              <NumberField
                {...bind}
                step={1}
                minimum={0}
                accessoryLeft={renderPrefixAccessory(t('day_selector.weekday.accessory'))}
                inputStyle='dark'
              />
            )}
          </FormField>
        </VBox>
      </HBox>
    )
  }

  function renderPrefixAccessory(prefix: string) {
    return (
      <Label dimmer>
        {prefix}
      </Label>
    )

  }

  return render()

})

export default DaySelectorFields