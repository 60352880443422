import React from 'react'
import { useTranslation } from 'react-i18next'
import { Feedback } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormSwitch } from '~/ui/form'
import { layout } from '~/ui/styling'
import ConditionFormModel from '../ConditionFormModel'

export interface Props {
  question: Feedback | null
}

const PresenceTextFields = memo('PresenceTextFields', (props: Props) => {

  const [t] = useTranslation('conditions')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormSwitch>
          <FormSwitch.Option
            {...t('tests.presence.present')}
            selected={(model: ConditionFormModel) => model.presence_operator === '!empty'}
            initialData={{presence_operator: '!empty'}}
          />
          <FormSwitch.Option
            {...t('tests.presence.absent')}
            selected={(model: ConditionFormModel) => model.presence_operator === 'empty'}
            initialData={{presence_operator: 'empty'}}
          />
        </FormSwitch>
      </VBox>
    )
  }

  return render()

})

export default PresenceTextFields