import React from 'react'
import Color from 'color'
import { ScriptMessage } from '~/models'
import { observer } from '~/ui/component'
import { Panel, panelBorderRadius, SizeTransitioner, VBox } from '~/ui/components'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { useScriptEditing } from '../ScriptEditingContext'
import { useScriptEditor } from '../ScriptEditorContext'
import { NewScriptMessage } from '../types'

export interface Props {
  message:      ScriptMessage | NewScriptMessage

  renderViewing?: (transitioning: boolean) => React.ReactNode
  renderEditing?: (transitioning: boolean) => React.ReactNode

  requestSave?:     () => any | Promise<any>
  backgroundColor?: Color
}

const ScriptMessageBubble = observer('ScriptMessageBubble', (props: Props) => {

  const {message, requestSave, backgroundColor} = props

  const {editor} = useScriptEditor()
  const readOnly = editor?.readOnly ?? true

  const {editingMessageUUID, setEditingMessageUUID, setOnRequestSaveCurrent, editingList, editingDetail} = useScriptEditing()
  const editing     = message.uuid === editingMessageUUID
  const interactive = !readOnly && !editingList && props.renderEditing != null

  const prevRequestSave = usePrevious(requestSave)
  const prevEditingDetail = usePrevious(editingDetail)

  const wasEditingDetail = prevEditingDetail !== editingDetail

  React.useEffect(() => {
    if (!wasEditingDetail && requestSave === prevRequestSave) { return }

    setOnRequestSaveCurrent?.(requestSave ?? null)
    return () => { setOnRequestSaveCurrent(null) }
  }, [message, prevRequestSave, requestSave, wasEditingDetail, setOnRequestSaveCurrent])

  const [transitioning, startTransition, endTransition] = useBoolean()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!interactive) {
      return (
        <VBox classNames={$.scriptMessageBubble}>
          {renderContent()}
        </VBox>
      )
    } else {
      return (
        <SizeTransitioner
          duration={animation.durations.medium}
          transitionKey={editing}
          classNames={[$.scriptMessageBubble, {editing}]}
          onStartTransition={startTransition}
          onEndTransition={endTransition}
        >
          {renderContent()}
        </SizeTransitioner>
      )
    }
  }

  //------
  // Message bubble

  function renderContent() {
    if (editing) {
      return renderEditing()
    } else {
      return renderViewing()
    }
  }

  function renderViewing() {
    return (
      <Panel
        flex='grow'
        backgroundColor={backgroundColor}
        interactive={interactive}
        onTap={interactive ? startEdit : undefined}
      >
        {renderConditions()}
        {props.renderViewing?.(transitioning)}
      </Panel>
    )
  }

  function renderConditions() {
    return null

    // TODO
    // const {conditions = []} = message

    // return (
    //   <ConditionsHeader
    //     classNames={$.conditions}
    //     conditions={conditions}
    //   />
    // )
  }

  function renderEditing() {
    return (
      <Panel flex='grow'>
        {props.renderEditing?.(transitioning)}
      </Panel>
    )
  }

  //------
  // Editing

  const startEdit = React.useCallback(() => {
    setEditingMessageUUID(message.uuid)
  }, [message.uuid, setEditingMessageUUID])

  return render()

})

export default ScriptMessageBubble

export const minHeight     = 32
export const editingHeight = 176
export const borderRadius  = panelBorderRadius

const useStyles = createUseStyles(theme => {
  const borderRadius = panelBorderRadius(theme)

  return {
    scriptMessageBubble: {
      '&.isEditingList': {
        cursor: ',move',
      },

      '&.viewing': {
        minHeight,
      },
      '&.editing': {
        minHeight: editingHeight,
      },

      '& > *': {
        flex: [1, 0, 'auto'],
      },
    },

    conditions: {
      background:    theme.semantic.secondary,
      borderRadius: [borderRadius, borderRadius,  0, 0],
      padding:      [layout.padding.inline.s, layout.padding.inline.m],
    },

    details: {
      padding: layout.padding.inline.m,
    },

    sortIcon: {
      marginRight: layout.padding.inline.m,
    },
  }
})