import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import { useRouteMatch } from 'react-router-dom'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import clipboard from 'rich-clipboard'
import { useOnDemandService } from 'socket.io-react'
import { ClipboardPlanComponent, ClipboardType } from '~/clipboard'
import {
  getTriggerableID,
  isAnnotation,
  isNode,
  isSegue,
  Module,
  Triggerable,
  TriggerableNode,
  TriggerNode,
  WebhookTrigger,
} from '~/models'
import { FlowPlanner, plannerStore } from '~/stores'
import { createSuperContainer, observer } from '~/ui/component'
import { Center, ErrorDisplay, Spinner } from '~/ui/components'
import { useBoolean, useFormOpen } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import ActiveModuleContext from '../ActiveModuleContext'
import PlannerActions from '../PlannerActions'
import FlowPlannerCanvas from './canvas/FlowPlannerCanvas'
import { FlowPlannerCanvasContextProvider, useCanvas } from './canvas/FlowPlannerCanvasContext'
import { SelectionContextProvider, useSelection } from './canvas/SelectionContext'
import CreateComponent from './create/CreateComponent'
import { FlowPlannerContextProvider, useFlowPlanner } from './FlowPlannerContext'
import FlowPlannerDetailForm from './FlowPlannerDetailForm'
import FlowPlannerInsightsDialog from './FlowPlannerInsightsDialog'
import ActivateNodesDialog from './nodes/ActivateNodesDialog'
import TriggerLinkDialog from './triggers/TriggerLinkDialog'
import WebhookTriggerDialog from './triggers/WebhookTriggerDialog'

import type { Params as PlannerParams } from '../PlannerScreen'
export interface Props {
  moduleIDs:   string[]
  breadcrumbs: Breadcrumb[]
}

const FlowPlannerContainer = observer('FlowPlannerContainer', (props: Props) => {

  const {moduleIDs, breadcrumbs} = props
  const moduleID = moduleIDs[moduleIDs.length - 1]
  const active   = React.useContext(ActiveModuleContext) === moduleID

  const service = React.useMemo(() => {
    if (moduleID == null) { return null }
    return plannerStore.getFlowPlannerService(moduleID)
  }, [moduleID])

  useOnDemandService(service)

  const planner = React.useMemo(
    () => service == null ? null : new FlowPlanner(service),
    [service],
  )

  const plan   = service?.plan ?? null
  const status = service?.status ?? 'idle'

  React.useEffect(() => {
    planner?.setPlan(plan)
  }, [plan, planner])

  const [t] = useTranslation('flow_planner')

  const Container = React.useMemo(() => createSuperContainer<{planner: FlowPlanner}>(
    (props, params) => <FlowPlannerContextProvider planner={params.planner} {...props}/>,
    (props, params) => <FlowPlannerCanvasContextProvider plan={params.planner.plan} {...props}/>,
    props => <SelectionContextProvider {...props}/>,
  ), [])

  function render() {
    if (planner == null) { return null }

    return (
      <Container planner={planner}>
        {active && (
          <AppLayoutConfig
            configKey='planner'
            breadcrumbs={breadcrumbs}
            ActionsComponent={renderActions}
          />
        )}

        {status === 'started' ? (
          <FlowPlannerContent {...props}/>
        ) : (
          renderEmpty()
        )}
      </Container>
    )
  }

  function renderEmpty() {
    if (status === 'started') { return null }
    if (status === 'idle' || status === 'starting') {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }

    console.error(status)
    return (
      <ErrorDisplay
        status={status.status}
        {...t([`errors.${status.status}`, `errors:${status.status}`, 'errors.unknown', 'errors:unknown'])}
      />
    )
  }

  const renderActions = React.useCallback(() => {
    if (planner == null) { return null }
    return (
      <PlannerActions
        planner={planner}
      />
    )
  }, [planner])

  return render()

})

const FlowPlannerContent = observer('FlowPlannerContent', (props: Props) => {

  const {moduleIDs} = props

  const {planner} = useFlowPlanner()
  const moduleID = moduleIDs[moduleIDs.length - 1]
  const [module] = useModelDocumentData(Module, moduleID)

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  function render() {
    return (
      <>
        <FlowPlannerCanvas
          requestCreateComponent={createPopupRecentlyOpen ? null : openCreatePopupAt}
          suspendCreatePoint={createPopupOpen}
        />

        <FlowPlannerDetailForm/>

        {renderCreatePopup()}
        {renderNodeDetailDialog()}
        {renderActivateNodesDialog()}
        {renderInsightsDialog()}
      </>
    )
  }

  //------
  // Keyboard actions

  const {manager} = useSelection()
  const {setMode, toggleInsights} = useCanvas()

  const {params} = useRouteMatch<PlannerParams>()
  const history  = useHistory()

  const goToModule = React.useCallback((moduleID: string) => {
    const prefix = params.modules == null ? '/' : `/${params.modules}/`
    history.push(`/planner${prefix}${moduleID}`)
  }, [history, params.modules])

  const editTriggerable = React.useCallback((node: TriggerableNode, triggerable: Triggerable) => {
    if (triggerable.type === 'challenge') {
      history.push(`/challenges/-/${triggerable.model}`)
    } else {
      planner.editComponent({
        type:        'triggerable',
        nodeUUID:    node.uuid,
        uuid:        getTriggerableID(triggerable),
        triggerable: triggerable,
      })
    }
  }, [history, planner])

  useHotkey('Short+C', React.useCallback(() => {
    if (manager == null || planner == null) { return }

    const components = planner.getClipboardComponents(manager.selectedUUIDs)
    if (components.length === 0) { return }

    clipboard.write([{
      type: ClipboardType.PLANNER_COMPONENTS,
      data: components,
    }])

    Toast.show({
      ...t('actions.copy.success', {count: components.length}),
      type: 'success',
    })
  }, [manager, planner, t]))

  useHotkey('Short+V', React.useCallback(() => {
    if (planner == null) { return }

    const item = clipboard.getData<ClipboardPlanComponent[]>(ClipboardType.PLANNER_COMPONENTS)
    if (item == null) { return }

    planner.pasteComponents(item.data).then(uuids => {
      if (uuids != null) {
        manager?.selectOnly(...uuids)
      }
    })
  }, [manager, planner]))

  useHotkey('Short+D', React.useCallback(() => {
    const uuids = manager?.selectedUUIDs
    if (uuids == null) { return }

    planner.duplicateComponents(uuids).then(newUUIDs => {
      if (newUUIDs == null) { return }
      manager?.selectOnly(...newUUIDs)
    })

  }, [manager, planner]))

  useHotkey('Backspace|Delete', React.useCallback(() => {
    const uuids = manager?.selectedUUIDs
    if (uuids == null) { return }

    planner.removeComponents(uuids)
    planner.closeSegueMenu()
    planner.stopEditingComponent()
  }, [manager?.selectedUUIDs, planner]))

  useHotkey('Enter', React.useCallback(() => {
    const components = manager?.selectedComponents
    if (components == null || components.length !== 1) { return }

    const component = components[0]
    if (isSegue(component)) {
      manager?.deselectSegue()
    } else if (isAnnotation(component)) {
      planner.editComponent({type: 'annotation', uuid: component.uuid, annotation: component})
    } else if (component.type === 'triggerable' && component.triggerables.length === 1) {
      editTriggerable(component, component.triggerables[0])
    } else if (component.type === 'module') {
      goToModule(component.module)
    } else if (isNode(component)) {
      planner.viewNodeDetail(component.uuid)
    }
  }, [editTriggerable, goToModule, manager, planner]))

  useHotkey('I', toggleInsights)

  useHotkey('Escape', React.useCallback(() => {
    manager?.deselectAll()
    setMode('select')
  }, [manager, setMode]))

  //------
  // Node creation

  const [createPopupOpen, openCreatePopup, closeCreatePopup] = useBoolean()
  const [createComponentPoint, setCreateComponentPoint] = React.useState<Point>({x: 0, y: 0})

  const openCreatePopupAt = React.useCallback((point: Point) => {
    setCreateComponentPoint(point)
    openCreatePopup()
  }, [openCreatePopup])

  const [createPopupRecentlyOpen, setCreatePopupRecentlyOpen] = React.useState<boolean>(false)
  const timer = useTimer()

  React.useEffect(() => {
    timer.clearAll()
    if (createPopupOpen) {
      setCreatePopupRecentlyOpen(true)
    } else {
      timer.setTimeout(() => {
        setCreatePopupRecentlyOpen(false)
      }, 200)
    }
  }, [createPopupOpen, timer])

  function renderCreatePopup() {
    if (module == null) { return null }

    return (
      <CreateComponent
        popupOpen={createPopupOpen}
        requestClose={closeCreatePopup}
        operationPoint={createComponentPoint}
        owningModule={module}
      />
    )
  }

  //------
  // Trigger link dialog

  const detailViewNode = planner.detailViewNodeUUID == null ? null : planner.plan?.findNode(planner.detailViewNodeUUID)

  const [triggerLinkDialogOpen, currentTriggerLinkNode, closeTriggerLinkDialog] = useFormOpen(detailViewNode, () => {
    planner.viewNodeDetail(null)
  })

  function renderNodeDetailDialog() {
    if (currentTriggerLinkNode == null || currentTriggerLinkNode.type !== 'trigger') { return null }

    switch (currentTriggerLinkNode.trigger.type) {
      case 'qrcode': case 'manual': {
        return (
          <TriggerLinkDialog
            open={triggerLinkDialogOpen}
            requestClose={closeTriggerLinkDialog}
            node={currentTriggerLinkNode}
          />
        )
      }
      case 'webhook': {
        return (
          <WebhookTriggerDialog
            open={triggerLinkDialogOpen}
            requestClose={closeTriggerLinkDialog}
            node={currentTriggerLinkNode as TriggerNode & {trigger: WebhookTrigger}}
          />
        )
      }
    }
  }

  //------
  // Node activation

  const activationNodeUUIDs = planner.activationNodeUUIDs
  const [activateNodeFormOpen, currentActivationNodeUUIDs, closeActivationForm] = useFormOpen(activationNodeUUIDs, () => {
    planner.setActivationNodeUUIDs([])
  })

  function renderActivateNodesDialog() {
    if (currentActivationNodeUUIDs == null || currentActivationNodeUUIDs.length === 0) { return null }

    return (
      <ActivateNodesDialog
        open={activateNodeFormOpen}
        requestClose={closeActivationForm}
        nodeUUIDs={currentActivationNodeUUIDs}
      />
    )
  }

  //------
  // Who is here

  const focusedInsightsPosition = planner.focusedInsightsPosition
  const [insightsDialogOpen, currentInsightsPosition, closeInsightsDialog] = useFormOpen(focusedInsightsPosition, () => {
    planner.setFocusedInsightsPosition(null)
  })

  function renderInsightsDialog() {
    if (currentInsightsPosition == null) { return null }

    return (
      <FlowPlannerInsightsDialog
        open={insightsDialogOpen}
        requestClose={closeInsightsDialog}
        position={currentInsightsPosition}
      />
    )
  }

  return render()

})

export default FlowPlannerContainer