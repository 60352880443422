import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { AssistantFormDialog } from '~/ui/form'
import ConditionFormModel, { ConditionFormStep } from './ConditionFormModel'
import ConditionSelectorStep from './ConditionSelectorStep'
import ConditionTestStep from './ConditionTestStep'

export interface Props {
  open:          boolean
  requestClose?: () => any
  formModel:     ConditionFormModel
}

const ConditionForm = observer('ConditionForm', (props: Props) => {

  const {open, requestClose, formModel} = props

  const [t] = useTranslation('conditions')

  const title = formModel.condition != null
    ? t('condition_form.title_edit')
    : t('condition_form.title_add')

  const requestStep = React.useCallback((step: ConditionFormStep) => {
    const result = formModel.validate()
    if (result.status === 'ok') {
      formModel.goToStep(step)
    }

    return result
  }, [formModel])

  //------
  // Rendering

  function render() {
    return (
      <AssistantFormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        title={title}
        autoFocus={false}

        steps={['selector', 'test']}
        currentStep={formModel.currentStep}
        requestStep={requestStep}
        captionForStep={step => t(`condition_form.steps.${step}`)}
        nextButtonCaption={step => step === 'test' ? t('buttons:next') : undefined}
        renderStep={renderStep}
      />
    )
  }

  function renderStep(step: ConditionFormStep) {
    switch (step) {
      case 'selector':
        return <ConditionSelectorStep/>
      case 'test':
        return <ConditionTestStep/>
    }
  }
  return render()

})

export default ConditionForm