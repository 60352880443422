import React from 'react'
import { ScriptMessage } from '~/models'
import { NewScriptMessage } from '../types'
import ImageScriptMessageBubble from './ImageScriptMessageBubble'
import NoticeScriptMessageBubble from './NoticeScriptMessageBubble'
import TextScriptMessageBubble from './TextScriptMessageBubble'
import VideoScriptMessageBubble from './VideoScriptMessageBubble'
import WidgetScriptMessageBubble from './WidgetScriptMessageBubble'

export default function renderScriptMessageBubble(message: ScriptMessage | NewScriptMessage) {
  switch (message.type) {
  case 'text':   return <TextScriptMessageBubble message={message}/>
  case 'image':  return <ImageScriptMessageBubble message={message}/>
  case 'video':  return <VideoScriptMessageBubble message={message}/>
  case 'notice': return <NoticeScriptMessageBubble message={message}/>
  case 'widget': return <WidgetScriptMessageBubble message={message}/>
  default:       return null
  }
}