import React from 'react'
import { CircuitBreakerNode, FirstNode, RoutingNode, SampleNode, SplitterNode } from '~/models'
import { routingNodeIcon } from '~/stores'
import { memo } from '~/ui/component'
import { Center, SVG } from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import { useCanvas } from '../canvas/FlowPlannerCanvasContext'
import NodeViewKebabMenu from './NodeViewKebabMenu'

export interface Props {
  node:            RoutingNode | CircuitBreakerNode | SplitterNode | FirstNode | SampleNode
  variant?:        'canvas' | 'list'
  interactive?:    boolean
}

const OtherNodeView = memo('OtherNodeView', (props: Props) => {

  const {
    node,
    variant = 'canvas',
    interactive = true,
  } = props

  const {planner} = useFlowPlanner()
  const {mode}    = useCanvas.unoptim()

  React.useEffect(() => {
    planner.setLoadingComponent(node.uuid, false)
  }, [node.uuid, planner])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const connecting = mode === 'connect'

    return (
      <ThemeProvider contrast='secondary'>
        <Center
          classNames={[$.OtherNodeView, node.type, {connecting, interactive}, variant]}
          flex='grow'
        >
          {renderSummary()}
          {interactive && (
            <Center classNames={$.kebabMenu}>
              <NodeViewKebabMenu node={node}/>
            </Center>
          )}
        </Center>
      </ThemeProvider>
    )
  }

  function renderSummary() {
    if (['routing', 'entry', 'exit'].includes(node.type)) {
      return null
    }

    return (
      <Center flex='grow' classNames={$.summary}>
        <SVG
          name={routingNodeIcon(node)}
          size={variant === 'canvas' ? layout.icon.l : layout.icon.m}
        />
      </Center>
    )
  }

  return render()

})

const listSize = {
  width:  layout.barHeight.s,
  height: layout.barHeight.s,
}

const useStyles = createUseStyles(theme => ({
  OtherNodeView: {
    position:   'relative',

    background:   theme.semantic.secondary,
    borderRadius: 1000,

    '&.connecting': {
      background:   theme.semantic.secondary.alpha(0.8),
    },

    '&.list': {
      alignSelf: 'center',
      ...listSize,
    },

    padding:      [layout.padding.inline.m, layout.padding.inline.m],
    whiteSpace:   'nowrap',
    boxShadow:    shadows.depth(1),
  },

  summary: {
    visibility: 'visible',
    '$OtherNodeView.interactive:hover &': {
      visibility: 'hidden',
    },
  },

  kebabMenu: {
    ...layout.overlay,

    visibility: 'hidden',
    '$OtherNodeView:hover &': {
      visibility: 'visible',
    },
  },
}))

export default OtherNodeView