import React from 'react'
import { BackgroundSpec } from '~/models'
import { observer } from '~/ui/component'
import { HBox, SelectField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import GradientDirectionSelector from '../util/GradientDirectionSelector'
import BrandingBackgroundFormModel from './BrandingBackgroundFormModel'
import BrandingColorField from './BrandingColorField'

export interface Props {
  model: BrandingBackgroundFormModel
}

const BrandingBackgroundFields = observer('BrandingBackgroundFields', (props: Props) => {

  const {model} = props

  const {t} = useResourceTranslation()

  const typeChoices = React.useMemo((): Choice<BackgroundSpec['type']>[] => [
    {value: 'solid', caption: t('backgrounds.solid')},
    {value: 'gradient', caption: t('backgrounds.gradient')},
  ], [t])

  const themeChoices = React.useMemo((): Choice<BackgroundSpec['theme']>[] => [
    {value: 'light', caption: t('themes.light')},
    {value: 'dark', caption: t('themes.dark')},
    {value: 'inherit', caption: t('themes.inherit')},
    {value: 'default', caption: t('themes.default')},
  ], [t])

  //------
  // Rendering

  function render() {
    return (
      <VBox padding={layout.padding.s} gap={layout.padding.s}>
        <FormField name='name' instruction={model.isWellKnown && t('assets.wellknown.name_instruction')}>
          {bind => <TextField {...bind} enabled={!model.isWellKnown}/>}
        </FormField>
        {model.type === 'solid' ? (
          renderSolidFields()
        ) : (
          renderGradientFields()
        )}

        <FormField name='theme'>
          {bind => <SelectField {...bind} choices={themeChoices}/>}
        </FormField>
      </VBox>
    )
  }

  function renderSolidFields() {
    return (
      <HBox gap={layout.padding.s} align='top'>
        <FormField name='type'>
          {bind => <SelectField {...bind} choices={typeChoices}/>}
        </FormField>
        <VBox flex>
          <FormField name='color'>
            {bind => <BrandingColorField {...bind}/>}
          </FormField>
        </VBox>
      </HBox>
    )
  }

  function renderGradientFields() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex gap={layout.padding.s}>
          <FormField name='type'>
            {bind => <SelectField {...bind} choices={typeChoices}/>}
          </FormField>
          <HBox gap={layout.padding.s}>
            <VBox flex>
              <FormField name='startColor'>
                {bind => <BrandingColorField {...bind}/>}
              </FormField>
            </VBox>
            <VBox flex>
              <FormField name='endColor'>
                {bind => <BrandingColorField {...bind}/>}
              </FormField>
            </VBox>
          </HBox>
        </VBox>
        <VBox gap={layout.padding.inline.s}>
          <FormFieldHeader name='gradient'/>
          <GradientDirectionSelector/>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default BrandingBackgroundFields