import React from 'react'
import { Challenge, ChallengeState, ChallengeStateStatus } from '~/models'
import ParticipantCell from '~/ui/app/participants/ParticipantCell'
import { memo } from '~/ui/component'
import { Chip, ClearButton, DataGrid, Dimple, HBox, ModalDialog, VBox } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceList } from '~/ui/resources/collection'
import { layout, useTheme } from '~/ui/styling'
import { Theme } from '~/ui/styling/Theme'

export interface Props {
  open:          boolean
  requestClose?: () => any
  challenge:     Challenge
}

const PublicationsDialog = memo('PublicationsDialog', (props: Props) => {

  const {open, requestClose, challenge} = props

  const {t} = useResourceTranslation('challenge-states')

  const theme = useTheme()

  const endpoint = useModelEndpoint(ChallengeState, {
    include: ['participant', 'challenge'],
    filters: {
      challenge: challenge.id,
    },
  })

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        onWillOpen={fetch}
        title={t('dialog_title')}
        headerRight={renderHeaderRight}
        width={720}
        height={640}
        children={renderContent()}
      />
    )
  }

  function renderHeaderRight(renderCloseButton: () => React.ReactNode) {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderRefreshButton()}
        <Dimple vertical/>
        {renderCloseButton()}
      </HBox>
    )
  }

  function renderRefreshButton() {
    return (
      <ClearButton
        icon='reload'
        onTap={fetch}
        tabIndex={2}
      />
    )
  }

  function renderContent() {
    return (
      <VBox flex padding={layout.padding.m}>
        <ResourceList endpoint={endpoint} allowDetail={false}>
          <DataGrid.Column<ChallengeState>
            name='participant'
            sort={true}
            renderCell={PublicationParticipantCell.renderer}
          />
          <DataGrid.Column<ChallengeState>
            name='status'
            sort={true}
            renderCell={renderStatus}
          />
          <DataGrid.Column<ChallengeState>
            name='publishedAt'
            sort={true}
            renderCell={state => state.publishedAt.toRelative()}
          />
        </ResourceList>
      </VBox>
    )
  }

  const PublicationParticipantCell = React.useMemo(
    () => ParticipantCell.for((state: ChallengeState) => state.participant),
    [],
  )

  function renderStatus(state: ChallengeState) {
    return (
      <HBox>
        <Chip small backgroundColor={colorForStatus(state.status, state.passed, theme)}>
          {t(`status.${state.status}`)}
        </Chip>
      </HBox>
    )
  }

  return render()

})

export default PublicationsDialog

function colorForStatus(status: ChallengeStateStatus, passed: boolean | null, theme: Theme) {
  switch (status) {
    case 'abandoned':
      return theme.fg.dim

    case 'completed':
      return (
        passed === true ? theme.semantic.positive :
        passed === false ? theme.semantic.negative :
        theme.semantic.secondary
      )

    default:
      return theme.semantic.secondary
  }
}