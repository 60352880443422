import React from 'react'
import { Branding } from '~/models'
import { memo } from '~/ui/component'
import { ColorField, HBox, TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import BrandingColorFormModel from './BrandingColorFormModel'

export interface Props {
  model: BrandingColorFormModel
}

const BrandingColorFields = memo('BrandingColorFields', (props: Props) => {

  const {model} = props

  const {t} = useResourceTranslation('brandings')
  const isWellKnown = model.name != null && Branding.isWellKnownColor(model.name)

  //------
  // Rendering

  function render() {
    return (
      <VBox padding={layout.padding.s} gap={layout.padding.s}>
        <FormField name='name' instruction={isWellKnown && t('assets.wellknown.name_instruction')}>
          {bind => <TextField {...bind} mono enabled={!isWellKnown}/>}
        </FormField>
        <HBox gap={layout.padding.s}>
          <VBox flex>
            <FormField name='hex'>
              {bind => <TextField {...bind} mono/>}
            </FormField>
          </VBox>
          <FormField name='color' caption='&nbsp;'>
            {bind => <ColorField {...bind}/>}
          </FormField>
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default BrandingColorFields