import React from 'react'
import { reaction } from 'mobx'
import { LocalizedString } from '~/models'
import { languagesStore } from '~/stores'

export function useDefaultLanguage(dynamic: boolean = true): string {
  const [language, setLanguage] = React.useState<string>(
    languagesStore.defaultLanguage,
  )

  React.useEffect(() => {
    return reaction(
      () => languagesStore.defaultLanguage,
      language => setLanguage(language),
    )
  }, [dynamic])

  return language
}

export function useLocalizedString(value: string | LocalizedString | null | undefined): string {
  const language = useDefaultLanguage(value != null && LocalizedString.isLocalizedString(value))

  if (value == null) { return '' }
  return LocalizedString.translate(value, {language})
}