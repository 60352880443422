import React from 'react'
import { PlanAnnotation } from '~/models'
import { memo } from '~/ui/component'
import TextAnnotationView from '../annotations/TextAnnotationView'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  annotation: PlanAnnotation
}

const AnnotationView = memo('AnnotationView', (props: Props) => {

  const {annotation} = props
  const {planner} = useFlowPlanner()

  React.useEffect(() => {
    planner.setLoadingComponent(annotation.uuid, false)
  }, [annotation.uuid, planner])

  function render() {
    return (
      <TextAnnotationView
        annotation={annotation}
      />
    )
  }

  return render()
})

export default AnnotationView