import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { TextField } from '~/ui/components'
import { FormField } from '~/ui/form'

const CommonTriggerFields = memo('CommonTriggerFields', () => {

  const [t] = useTranslation('flow_planner')

  return (
    <FormField
      name='name'
      caption={t('triggers.fields.name.caption')!}
      instruction={t('triggers.fields.name.instruction')!}
      placeholder={t('triggers.fields.name.placeholder')!}
    >
      {bind => <TextField {...bind} showClearButton='notempty'/>}
    </FormField>
  )

})

export default CommonTriggerFields