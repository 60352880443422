import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '~/models'
import { memo } from '~/ui/component'
import { Panel, TextBlock, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import TagsField from '../../tags/TagsField'

const GroupFeedbackFields = memo('GroupFeedbackFields', () => {

  const [t] = useTranslation('feedback')

  return (
    <VBox gap={layout.padding.s}>
      <Panel semi padding={layout.padding.inline.l}>
        <TextBlock small dim>
          {t('group.instructions')}
        </TextBlock>
      </Panel>

      <FormField name='tag' required>
        {bind => <TagsField {...bind} multi={false} Model={Group}/>}
      </FormField>
    </VBox>
  )
})

export default GroupFeedbackFields