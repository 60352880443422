import { action, computed, makeObservable, observable } from 'mobx'
import * as UUID from 'uuid'
import { Challenge, ModelClass, Module, Script, Triggerable, TriggerableModelClass } from '~/models'
import { dataStore } from '~/stores'
import { SubmitResult } from '~/ui/form'
import { TriggerableVariant } from './TriggerableFormModel'

export default class ModelTriggerableVariant implements TriggerableVariant {

  constructor(
    public readonly triggerableType: Exclude<Triggerable['type'], 'action'>,
    public readonly model:           Script | Challenge | null,
    public readonly module:          Module,
  ) {
    makeObservable(this)
  }

  @computed
  public get Model(): ModelClass<Script | Challenge> {
    return TriggerableModelClass(this.triggerableType)
  }

  //------
  // Existing vs create new

  @observable
  public creatingNew: boolean = false

  private createdModelID: string | null = null

  @observable
  public existingModel: string | null = null

  @observable
  public createNew(name: string) {
    this.creatingNew = true

    this.assign({name})
    if (this.triggerableType === 'script') {
      this.assign({type: 'linear'})
    }
  }

  //------
  // Parameters

  @computed
  public get triggerableParameters() {
    const type = this.Model === Script ? 'script' : 'challenge'
    return (this.module.parameters ?? []).filter(it => it.type === type)
  }

  @computed
  public get triggerableParameterNames() {
    return this.triggerableParameters.map(it => `$${it.name}`)
  }

  //------
  // Data

  @observable
  public data: AnyObject = {...this.model}

  public getValue(name: string) {
    if (this.creatingNew) {
      return this.data[name]
    } else {
      return (this as any)[name]
    }
  }

  @action
  public assign(data: AnyObject) {
    if (this.creatingNew) {
      Object.assign(this.data, data)
    } else {
      Object.assign(this, data)
    }
  }

  //------
  // Submission

  public async beforeSubmit(): Promise<SubmitResult | undefined> {
    if (this.creatingNew) {
      return await this.createModel()
    } else if (this.model == null && this.existingModel == null) {
      return {status: 'invalid', errors: []}
    } else {
      return {status: 'ok'}
    }
  }

  private async createModel(): Promise<SubmitResult | undefined> {
    const Model = TriggerableModelClass(this.triggerableType)
    if (this.model == null) {
      const model = {...this.data}
      if (Model.scopedToModule) {
        Object.assign(model, {module: this.module.id})
      }

      const result = await dataStore.create(Model, model)
      if (result.status === 'ok' && result.data?.id != null) {
        this.createdModelID = result.data.id
      }
      return result
    } else {
      return await dataStore.update(Model, this.model.id, this.data)
    }
  }

  public buildTriggerable(): AnyObject {
    return {
      uuid:  UUID.v4(),
      type:  this.triggerableType,
      model: this.createdModelID ?? this.existingModel,
    }
  }

  public translateFormErrorPaths(result: SubmitResult | undefined) {
    return result
  }

}