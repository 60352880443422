import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { RegistrationCode } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { ResourceDetailScreen } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout } from '~/ui/styling'

export type Props = RouteComponentProps<ResourceDetailParams>

const RegistrationCodeScreen = memo('RegistrationCodeScreen', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<RegistrationCode>
        Model={RegistrationCode}
        id={props.match.params.id}
        renderBody={renderBody}
      />
    )
  }

  function renderBody(group: RegistrationCode) {
    return (
      <VBox flex gap={layout.padding.m}>
      </VBox>
    )
  }


  return render()

})

export default RegistrationCodeScreen