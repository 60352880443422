import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { Model, ModelClass, ModelOfType, Module } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { useModelDocumentData } from '~/ui/hooks/data'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { resourceDetailBreadcrumbs, resourceListBreadcrumbs } from '../breadcrumbs'

import type { Breadcrumb } from '~/ui/layouts/app'
export function useResourceDetailBreadcrumbs(Model: ModelClass<any>, model: Model | null, explicitBreadcrumbs?: Breadcrumb[]) {
  const {params}      = useRouteMatch<ResourceDetailParams>()
  const ParentModel   = params.parentType == null ? null : ModelOfType(params.parentType)
  const [parentModel] = useModelDocumentData(ParentModel, params.parentID ?? null)

  const module = Model.scopedToModule && model?.module != null && model.module !== projectStore.projectID
    ? dataStore.get(Module, model.module)
    : null

  return React.useMemo(() => {
    if (explicitBreadcrumbs != null) {
      return explicitBreadcrumbs
    } else if (model != null) {
      return resourceDetailBreadcrumbs(model, parentModel, module)
    } else {
      return []
    }
  }, [explicitBreadcrumbs, model, module, parentModel])
}

export function useResourceListBreadcrumbs(Model: ModelClass<any>, explicitBreadcrumbs?: Breadcrumb[]) {
  const {params}      = useRouteMatch<ResourceDetailParams>()
  const ParentModel   = params.parentType == null ? null : ModelOfType(params.parentType)
  const [parentModel] = useModelDocumentData(ParentModel, params.parentID ?? null)

  return React.useMemo(() => {
    if (explicitBreadcrumbs != null) {
      return explicitBreadcrumbs
    } else {
      return resourceListBreadcrumbs(Model, parentModel, null)
    }
  }, [Model, explicitBreadcrumbs, parentModel])
}