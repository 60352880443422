import { CustomImage } from './CustomImage'
import { Model, resource } from './Model'

@resource<ChatBot>('chat-bots', {
  icon:    () => 'robot',
  caption: chatBot => chatBot.displayName ?? chatBot.name,
  details: chatBot => chatBot.name !== chatBot.displayName ? chatBot.name : null,
})
export class ChatBot extends Model {

  public name!:        string
  public displayName!: string
  public avatar!:      CustomImage | null

}