import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import CalendarModelTriggerableForm from './CalendarModelTriggerableForm'
import CalendarTriggerableItemFormModel from './CalendarTriggerableItemFormModel'
import ModuleItemForm from './ModuleItemForm'
import ModuleItemFormModel from './ModuleItemFormModel'

export type Props = Omit<FormDialogProps<CalendarItemFormModel>, 'children'>
export type CalendarItemFormModel = CalendarTriggerableItemFormModel | ModuleItemFormModel

const CalendarItemForm = memo('CalendarItemForm', (props: Props) => {

  const [t]   = useTranslation('calendar_planner')
  const title = props.model.isNew ? t(`add_item.${props.model.type}`) : t('edit_item')

  if (props.model instanceof ModuleItemFormModel) {
    return (
      <ModuleItemForm {...props} title={title} model={props.model}/>
    )
  } else if (props.model.type === 'action') {
    return null
    // TODO
    // return (
    //   <ActionTriggerableForm {...props}/>
    // )
  } else {
    return (
      <CalendarModelTriggerableForm {...props} title={title} model={props.model}/>
    )
  }

})

export default CalendarItemForm