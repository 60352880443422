import React from 'react'
import { useTranslation } from 'react-i18next'
import { flatMap } from 'ytil'
import { Project } from '~/models'
import navigation from '~/navigation.yml'
import { authenticationStore } from '~/stores'
import { getNavigationDetailMenuComponent } from '~/ui/app/navigation'
import { memo, observer } from '~/ui/component'
import { Center, HBox, Label, SVG, Tappable, VBox } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { colors, createUseStyles, layout, presets, shadows } from '~/ui/styling'
import ProjectSwitcher from './project-switcher/ProjectSwitcher'
import { Breadcrumb, CustomBreadcrumb, isNavigationBreadcrumb } from './types'

export interface Props {
  breadcrumbs: Breadcrumb[]
}

const BreadcrumbsBar = observer('BreadcrumbsBar', (props: Props) => {

  const {breadcrumbs} = props

  const user      = authenticationStore.user
  const endpoint  = useModelEndpoint(Project, {fetch: false})

  const mayCreate        = user?.isSuper()
  const multipleProjects = endpoint.data.length > 1
  const projectSwitcherInteractive = multipleProjects || mayCreate

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.breadcrumbsBar} align='stretch'>
        <VBox>
          <ProjectSwitcher/>
        </VBox>
        <Separator
          interactiveBefore={projectSwitcherInteractive}
        />
        {renderBreadcrumbs()}
      </HBox>
    )
  }

  function renderBreadcrumbs() {
    return breadcrumbs.map((breadcrumb, index) => (
      <HBox align='stretch' key={index}>
        <BreadcrumbButton
          breadcrumb={breadcrumb}
          interactive={index < breadcrumbs.length - 1}
        />
        {index < breadcrumbs.length - 1 && (
          <Separator/>
        )}
      </HBox>
    ))
  }

  return render()

})

export default BreadcrumbsBar

interface SeparatorProps {
  interactiveBefore?: boolean
}

const Separator = memo('Separator', (props: SeparatorProps) => {

  const {
    interactiveBefore = true,
  } = props

  const $ = useStyles()

  const x1 = 0
  const x2 = arrowSize - 1
  const x3 = 1
  const x4 = arrowSize

  return (
    <HBox classNames={$.separator}>
      <svg viewBox={`0 0 8 ${height}`} width={8} height={height}>
        <path
          d={`M ${x1} 0  L ${x1} ${height / 2 - x2}  L ${x2} ${height / 2}  L ${x1} ${height / 2 + x2}  L ${x1} ${height}`}
          stroke={colors.dimple.dark.string()}
          strokeWidth={1}
          classNames={[$.separatorFill, {interactiveBefore}]}
        />
        {interactiveBefore && (
          <path
            d={`M ${x1} 0  L ${x1} ${height / 2 - x2}  L ${x2} ${height / 2}  L ${x1} ${height / 2 + x2}  L ${x1} ${height}`}
            stroke={colors.dimple.dark.string()}
            strokeWidth={1}
            classNames={$.separatorHover}
          />
        )}
        <path
          d={`M ${x3} 0  L ${x3} ${height / 2 - x4 + 1}  L ${x4} ${height / 2}  L ${x3} ${height / 2 + x4 - 1}  L ${x3} ${height}`}
          stroke={colors.dimple.light.string()}
          strokeWidth={1}
          fill='none'
        />
      </svg>
    </HBox>
  )
})

export interface BreadcrumbButtonProps {
  breadcrumb:  Breadcrumb
  interactive: boolean
}

export const BreadcrumbButton = memo('BreadcrumbButton', (props: BreadcrumbButtonProps) => {

  const [t] = useTranslation('navigation')

  const props_breadcrumb = props.breadcrumb

  const navigationItem = React.useMemo(() => {
    if (!isNavigationBreadcrumb(props_breadcrumb)) { return null }

    const item = allNavigationItems.find(it => 'name' in it && it.name === props_breadcrumb.name)
    if (item == null || !('name' in item)) { return null }

    return item
  }, [props_breadcrumb])

  const breadcrumb: CustomBreadcrumb | null = React.useMemo(() => {
    if (!isNavigationBreadcrumb(props_breadcrumb)) {
      return props_breadcrumb
    } else if (navigationItem != null) {
      return {
        icon:    navigationItem.icon,
        caption: t(`items.${navigationItem.name}.caption`),
        href:    navigationItem.href,
      }
    } else {
      return null
    }
  }, [navigationItem, props_breadcrumb, t])

  const interactive = props.interactive && breadcrumb?.href != null
  const detailItem  = props.breadcrumb.detail == null ? null : allNavigationItems.find(it => 'name' in it && it.name === props_breadcrumb.detail)
  const DetailMenu  = props.breadcrumb.detail == null ? null : getNavigationDetailMenuComponent(props_breadcrumb.detail!)

  const $ = useStyles()

  function render() {
    if (breadcrumb == null) { return null }

    if (interactive && breadcrumb.href != null) {
      return (
        <Tappable classNames={[$.breadcrumbButton, {interactive}]} href={breadcrumb.href}>
          {renderContent()}
        </Tappable>
      )
    } else {
      return renderContent()
    }
  }

  function renderContent() {
    if (breadcrumb == null) { return null }

    return (
      <HBox flex>
        <HBox classNames={$.breadcrumbRow} gap={layout.padding.inline.m}>
          {breadcrumb.icon && <SVG name={breadcrumb.icon} size={layout.icon.m}/>}
          <Label bold={interactive} markup>
            {breadcrumb.caption}
          </Label>
        </HBox>
        {renderDetailMenu()}
      </HBox>
    )
  }

  function renderDetailMenu() {
    if (detailItem == null || DetailMenu == null) { return null }

    return (
      <DetailMenu targetClassNames={$.detailMenu} item={detailItem}>
        {toggle => (
          <Tappable classNames={$.detailMenuButton} flex onTap={toggle}>
            <Center flex>
              <SVG name='chevron-down' size={layout.icon.s}/>
            </Center>
          </Tappable>
        )}
      </DetailMenu>
    )
  }
  return render()

})

const allNavigationItems = flatMap(navigation, group => group.items)

export const height = layout.barHeight.m
export const arrowSize = 8

const useStyles = createUseStyles(theme => ({
  breadcrumbsBar: {
    height: height,
  },

  separator: {
    position:      'relative',
    marginRight:   -arrowSize + 1,
    pointerEvents: 'none',
    zIndex:        layout.z.body + 1,
  },

  separatorFill: {
    '&:not(.interactiveBefore)': {
      fill: theme.bg.semi,
    },

    '&.interactiveBefore': {
      fill: theme.bg.alt,
    },
  },

  separatorHover: {
    fill: 'none',
    pointerEvents: 'auto',
    '$breadcrumbButton:hover + $separator &': {
      fill: theme.bg.hover,
    },
  },

  breadcrumbButton: {
    '&.interactive': {
      background: theme.bg.alt,

      position: 'relative',
      '&:hover': {
        ...presets.overlayAfter({
          background: theme.bg.hover,
        }),
        ...colors.overrideForeground(theme.semantic.primary),
      },
    },
  },

  breadcrumbRow: {
    padding: [layout.padding.inline.s, layout.padding.inline.l],
  },

  detailMenu: {
    alignSelf: 'stretch',
  },

  detailMenuButton: {
    padding:   layout.padding.inline.s,

    '&:hover': {
      background: theme.bg.hover,
    },
    '&:focus-visible': {
      boxShadow: shadows.focus.bold(theme),
    },
  },
}))