import { DraggableItem } from 'react-dnd'
import { Widget, WidgetAlotment } from '~/models'

export enum DraggableItemType {
  WIDGET       = 'app.groundcontrol.analytics.WIDGET',
  LIVE_PREVIEW = 'app.groundcontrol.LIVE_PREVIEW',
}


export interface DraggableWidgetItem extends DraggableItem {
  type:        DraggableItemType.WIDGET
  widget:      Widget
  onDashboard: boolean
  alotment?:   WidgetAlotment
  targetRect?: Rect | null
  fits?:       boolean
}