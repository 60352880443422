import React from 'react'
import Color from 'color'
import { clamp } from 'lodash'
import { sparse } from 'ytil'
import { memo } from '~/ui/component'
import { AutosizeSVG, AutosizeSVGProps, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows, useTheme } from '~/ui/styling'
import { useQualifiedIDFactory } from './chart/hooks'

export interface Props extends Omit<AutosizeSVGProps, 'children'> {
  value: number
  min?:  number
  max:   number

  color?:  Color
  ranges?: GaugeRange[]

  onSize?: (size: Size) => any
}

export interface GaugeRange {
  start: number
  end:   number
  color: Color
}

const Gauge = memo('Gauge', (props: Props) => {

  const theme = useTheme()

  const {
    value,
    min = 0,
    max,
    color = theme.semantic.secondary,
    ranges = [],
    onSize,
    ...rest
  } = props

  const [size, setSizeState] = React.useState<Size | null>(null)

  const setSize = React.useCallback((size: Size) => {
    setSizeState(size)
    onSize?.(size)
  }, [onSize])

  const qualifiedID = useQualifiedIDFactory()
  const gradientID  = qualifiedID('gradient')
  const rangeID     = (start: number, end: number) => qualifiedID(`range-${start}-${end}`)

  const maskData = React.useCallback((start: number, end: number) => {
    if (size == null) { return null }

    const w = size.width
    const h = size.height

    const a1  = Math.PI - clamp((start - min) / (max - min), 0, 1) * Math.PI
    const dx1 = Math.cos(a1) * 3 * h
    const dy1 = -Math.sin(a1) * 3 * h

    const a2  = 0.5 * Math.PI
    const dx2 = Math.cos(a2) * 3 * h
    const dy2 = -Math.sin(a2) * 3 * h

    const a3  = Math.PI - clamp((end - min) / (max - min), 0, 1) * Math.PI
    const dx3 = Math.cos(a3) * 3 * h
    const dy3 = -Math.sin(a3) * 3 * h

    return [
      `M ${w / 2} ${h}`,
      `L ${w / 2 + dx1} ${h + dy1}`,
      `L ${w / 2 + dx2} ${h + dy2}`,
      `L ${w / 2 + dx3} ${h + dy3}`,
      'Z',
    ].join(' ')
  }, [max, min, size])

  const pathData = React.useMemo(() => {
    if (size == null) { return null }

    const w = size.width
    const h = size.height
    const f = 0.45
    const t = Math.max(w / 8, 16)
    const r = layout.radius.s

    const quarterCircleFromTo = (from: [number, number], to: [number, number], flag: boolean) => {
      return sparse([
        `C`,
        flag && `${from[0]} ${from[1] * f + to[1] * (1 - f)}`,
        !flag && `${from[0] * f + to[0] * (1 - f)} ${from[1]}`,
        !flag && `${to[0]} ${to[1] * f + from[1] * (1 - f)}`,
        flag && `${to[0] * f + from[0] * (1 - f)} ${to[1]}`,
        `${to[0]} ${to[1]}`,
      ]).join(' ')
    }

    return [
      `M ${r} ${h}`,
      quarterCircleFromTo([r, h], [0, h - r], false),
      quarterCircleFromTo([0, h - r], [w / 2, 0], true),
      quarterCircleFromTo([w / 2, 0], [w, h - r], false),
      quarterCircleFromTo([w, h - r], [w - r, h], true),
      `L ${w - t + r} ${h}`,
      quarterCircleFromTo([w - t + r, h], [w - t, h - r], false),
      quarterCircleFromTo([w - t, h - r], [w / 2, t], true),
      quarterCircleFromTo([w / 2, t], [t, h - r], false),
      quarterCircleFromTo([t, h - r], [t - r, h], true),
      'Z',
    ].join(' ')
  }, [size])

  const valueIndicatorTransform = React.useMemo(() => {
    if (size == null) { return null }

    const r = size.height + layout.padding.inline.s
    const a = Math.PI - clamp((value - min) / (max - min), 0, 1) * Math.PI
    const x = size.width / 2 + Math.cos(a) * r
    const y = size.height - Math.sin(a) * r

    return [
      `translate(${x}px, ${y}px)`,
      `rotate(${-a}rad)`,
    ].join(' ')
  }, [value, min, max, size])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.gauge} flex={rest.flex}>
        <AutosizeSVG aspectRatio={2} {...rest} onContentSize={setSize}>
          {renderBase()}
          {ranges.map(renderRange)}
          {renderOverlay()}
          {renderValueIndicator()}
        </AutosizeSVG>
      </VBox>
    )
  }

  function renderBase() {
    if (pathData == null) { return null }

    return (
      <path
        d={pathData}
        fill={color.string()}
        classNames={$.base}
      />
    )
  }

  function renderOverlay() {
    if (pathData == null) { return null }

    return (
      <>
        <defs>
          <radialGradient id={gradientID} cx={0.85} cy={0.8} r={1}>
            <stop offset='70%' stopColor='white' stopOpacity={0}/>
            <stop offset='90%' stopColor='white' stopOpacity={0.2}/>
            <stop offset='100%' stopColor='white' stopOpacity={0}/>
          </radialGradient>
        </defs>

        <path
          d={pathData}
          fill={`url(#${gradientID})`}
        />
      </>
    )
  }

  function renderRange(range: GaugeRange) {
    if (pathData == null) { return null }

    const id = rangeID(range.start, range.end)
    return (
      <g key={id}>
        {renderMask(id, maskData(range.start, range.end))}
        <path d={pathData} fill={range.color.string()} mask={`url(#${id})`}/>
      </g>
    )
  }

  function renderMask(id: string, data: string | null) {
    return (
      <mask id={id}>
        {data != null && (
          <path d={data} fill='white'/>
        )}
      </mask>
    )
  }

  function renderValueIndicator() {
    if (valueIndicatorTransform == null) { return null }

    const w = valueIndicatorSize
    const h = valueIndicatorSize * 2 / 3

    return (
      <path
        d={`M 0 0 L ${w} ${-h} L ${w} ${h} Z`}
        fill={theme.fg.normal.string()}
        style={{transform: valueIndicatorTransform}}
      />
    )
  }

  return render()

})

export default Gauge

const valueIndicatorSize = 9
const valueIndicatorDistance = layout.padding.inline.s

const useStyles = createUseStyles({
  gauge: {
    padding:       valueIndicatorDistance + valueIndicatorSize,
    paddingBottom: valueIndicatorSize,
  },

  base: {
    filter: `drop-shadow(1px 1px 2px ${shadows.shadowColor})`,
  },
})