import React from 'react'
import { FeedbackForm, FeedbackFormModel } from '~/ui/app/feedback'
import { memo } from '~/ui/component'
import { NumberField, SwitchField } from '~/ui/components'
import { FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import ScriptMessageFeedbackFormModel from './ScriptMessageFeedbackFormModel'
import ScriptMessageFeedbackPreview from './ScriptMessageFeedbackPreview'

export interface Props extends Omit<FormDialogProps<FeedbackFormModel>, 'model' | 'children'> {
  formModel: ScriptMessageFeedbackFormModel
}

const ScriptMessageFeedbackForm = memo('ScriptMessageFeedbackForm', (props: Props) => {

  const {formModel, ...rest} = props

  //------
  // Rendering

  function render() {
    return (
      <FeedbackForm
        {...rest}
        model={formModel}
        renderExtraSideFields={renderExtraSideFields}
        renderPreview={renderFeedbackPreview}
      />
    )
  }

  const renderExtraSideFields = React.useCallback(() => {
    return (
      <ResourceTypeProvider resourceType='script-messages'>
        <FormField name='skippable' caption={false}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
        <FormField name='expiresAfter'>
          {bind => <NumberField {...bind} minimum={0} step={1}/>}
        </FormField>
      </ResourceTypeProvider>
    )
  }, [])

  const renderFeedbackPreview = React.useCallback(() => {
    return (
      <ScriptMessageFeedbackPreview
        formModel={formModel}
      />
    )
  }, [formModel])

  return render()

})

export default ScriptMessageFeedbackForm