import React from 'react'
import { CalendarDay } from '~/models'
import { observer } from '~/ui/component'
import { FormDialog, FormDialogProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import DaySelectorFields from '../../days/DaySelectorFields'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import CalendarDayFormModel from './CalendarDayFormModel'

export interface Props extends Omit<FormDialogProps<CalendarDayFormModel>, 'model' | 'children'> {
  day: CalendarDay | null
}

const DayForm = observer('DayForm', (props: Props) => {

  const {planner} = useCalendarPlanner()
  const {day, ...rest} = props

  const {t} = useResourceTranslation('calendar_planner')

  const formModel = React.useMemo(
    () => planner == null ? null : new CalendarDayFormModel(planner, day),
    [day, planner],
  )

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog
        model={formModel}
        title={t('add_day.title')}
        children={<DaySelectorFields selectorModelProp='selectorModel'/>}
        {...rest}
      />
    )
  }

  return render()

})

export default DayForm