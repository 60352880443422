import React from 'react'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { Participant, Script } from '~/models'
import { dataStore } from '~/stores'
import { ParticipantField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { Panel, PanelHeader, PushButton, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  script: Script
}

const RunPanel = memo('RunPanel', (props: Props) => {

  const {script} = props

  const {t, actionCaption} = useResourceTranslation()

  const [participantID, setParticipantID] = React.useState<string | null>(null)

  const timer = useTimer()

  const runNow = React.useCallback(async () => {
    if (participantID == null) { return }

    const document    = dataStore.document(Script, script.id)
    const participant = dataStore.get(Participant, participantID)

    const meta: AnyObject = {
      participantID,
    }

    const result = await timer.await(document.callAction('run', {
      data: null,
      meta: meta,
    }))

    if (result.status === 'ok') {
      Toast.show({
        type:  'success',
        title: t('actions.run.success.title'),
        detail: t('actions.run.success.detail', {script, participant}),
      })
    }
  }, [participantID, script, timer, t])

  //------
  // Rendering

  function render() {
    return (
      <Panel header={renderHeader()} padding={layout.padding.inline.l} gap={layout.padding.inline.l}>
        <VBox gap={layout.padding.inline.m}>
          <ParticipantField
            value={participantID}
            onChange={setParticipantID}
          />
        </VBox>
        <PushButton
          caption={actionCaption('run')}
          enabled={participantID != null}
          onTap={runNow}
          small
        />
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        icon='play-circle'
        caption={actionCaption('run')}
        small={true}
      />
    )
  }

  return render()

})

export default RunPanel