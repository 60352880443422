import ImportField from './ImportField'

export default class RegularField extends ImportField {

  public serialize() {
    return {
      ...super.serializeWithType('regular'),
    }
  }

}