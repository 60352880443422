import React from 'react'
import { CalendarPlan } from '~/models'
import { CalendarPlanner } from '~/stores'
import { observer } from '~/ui/component'
import { CalendarMode } from './types'

export interface CalendarPlannerContext {
  planner: CalendarPlanner | null
  plan:    CalendarPlan | null

  mode:    CalendarMode
  setMode: (mode: CalendarMode) => any
}

export const CalendarPlannerContext = React.createContext<CalendarPlannerContext>({
  planner: null,
  plan:    null,

  mode:    'select',
  setMode: () => void 0,
})

export interface CalendarPlannerContextProviderProps {
  planner:   CalendarPlanner
  children?: React.ReactNode
}

export const CalendarPlannerContextProvider = observer('CalendarPlannerContextProvider', (props: CalendarPlannerContextProviderProps) => {
  const {planner, children} = props

  const [mode, setMode] = React.useState<CalendarMode>('select')

  const plan = planner.plan
  const context = React.useMemo(() => ({
    planner: planner,
    plan:    plan,

    mode,
    setMode,
  }), [mode, plan, planner])

  return (
    <CalendarPlannerContext.Provider value={context}>
      {children}
    </CalendarPlannerContext.Provider>
  )
})

export function useCalendarPlanner(require: false): CalendarPlannerContext
export function useCalendarPlanner(require?: true): CalendarPlannerContext & {planner: CalendarPlanner}
export function useCalendarPlanner(require: boolean = true): CalendarPlannerContext & {planner: CalendarPlanner} {
  const context = React.useContext(CalendarPlannerContext)
  if (require && context.planner == null) {
    throw new Error('useCalendarPlanner: context.planner is null')
  }

  return context as CalendarPlannerContext & {planner: CalendarPlanner}
}