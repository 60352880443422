import React from 'react'
import { BrandedComponentSpec, Branding } from '~/models'
import { BrandingGuide } from '~/ui/styling'
import ComponentBrandingFields from './ComponentBrandingFields'
import ComponentBrandingFormModel from './ComponentBrandingFormModel'
import ComponentBrandingPreview from './ComponentBrandingPreview'

export const previews   = new Map<string, ComponentBrandingPreviewComponentType>()
export const fields     = new Map<string, ComponentBrandingFieldsComponentType>()
export const formModels = new Map<string, ComponentBrandingFormModelConstructor>()

export type ComponentBrandingPreviewComponentType = React.ComponentType<ComponentBrandingPreviewProps>
export interface ComponentBrandingPreviewProps {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

export type ComponentBrandingFieldsComponentType = React.ComponentType<{}>
export type ComponentBrandingFormModelConstructor = new(
  guide: BrandingGuide,
  branding: Branding,
  componentName: string,
  initialVariant: string | null
) => ComponentBrandingFormModel<any>

export function registerComponentBrandingPreview(component: string, Preview: ComponentBrandingPreviewComponentType) {
  previews.set(component, Preview)
}

export function registerComponentBrandingFields(component: string, Fields: ComponentBrandingFieldsComponentType) {
  fields.set(component, Fields)
}

export function registerComponentBrandingFormModel(component: string, FormModel: ComponentBrandingFormModelConstructor) {
  formModels.set(component, FormModel)
}

export function renderComponentBrandingPreview(componentName: string, variant: string | null, spec?: BrandedComponentSpec) {
  const Preview = previews.get(componentName) ?? ComponentBrandingPreview
  if (Preview == null) { return null }

  return React.createElement(Preview, {
    componentName,
    variant,
    spec,
  })
}

export function renderComponentBrandingFields(componentName: string) {
  const Fields = fields.get(componentName) ?? ComponentBrandingFields as ComponentBrandingFieldsComponentType
  if (Fields == null) { return null }

  return React.createElement(Fields)
}

export function createComponentBrandingFormModel(componentName: string, guide: BrandingGuide, branding: Branding, initialVariant: string | null = null) {
  const FormModel = formModels.get(componentName) ?? ComponentBrandingFormModel
  return new FormModel(guide, branding, componentName, initialVariant)
}