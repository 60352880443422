import i18next from 'i18next'
import { upperFirst } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { flatMap } from 'ytil'
import config from '~/config'
import { ModelOfType } from '~/models'
import navigationGroups, {
  NavigationFilter,
  NavigationGroup,
  NavigationItem,
  StandardNavigationItem,
} from '~/navigation.yml'
import { resourceListPath } from '~/ui/resources/routes'
import authenticationStore from './auth/authenticationStore'
import projectStore from './projectStore'
import { register } from './support'

export class NavigationStore {

  constructor() {
    makeObservable(this)
  }

  @computed
  public get availableGroups(): NavigationGroup[] {
    const isAvailable = this.isAvailableItem.bind(this)
    return filterNavigationGroups(navigationGroups, isAvailable)
  }

  @computed
  public get availableItems(): NavigationItem[] {
    return flatMap(this.availableGroups, it => it.items)
  }

  //------
  // Visible items

  @observable
  public visibleItems: string[] | null = null

  @action
  public setVisibleItems(names: string[]) {
    this.visibleItems = names
  }

  @action
  public addVisibleItems(names: string[]) {
    if (this.visibleItems == null) { return }
    this.visibleItems.push(...names)
  }

  @action
  public removeVisibleItems(names: string[]) {
    if (this.visibleItems == null) { return }
    this.visibleItems = this.visibleItems.filter(it => names.includes(it))
  }

  @action
  public resetVisibleItems() {
    this.visibleItems = null
  }

  private isVisibleItem(item: NavigationItem) {
    if (this.visibleItems == null) { return true }

    const name = navigationItemName(item)
    return this.visibleItems.includes(name)
  }

  //------
  // Availability checks

  public isAvailableItem(item: NavigationItem | NavigationGroup, group: NavigationGroup) {
    if (!this.isAvailableForCurrentUser(item)) { return false }
    if (group.group !== 'admin' && !this.isAvailableForCurrentProject(item)) { return false }
    if (!this.isAvailableForCurrentEnvironment(item)) { return false }
    if (isNavigationItem(item) && !this.isVisibleItem(item)) { return false }

    return true
  }

  public isAvailableForCurrentUser(item: NavigationFilter) {
    const user = authenticationStore.user
    if (user == null) { return false }

    if (item.role != null && !user.hasRole(item.role)) { return false }
    if (!item.bewizr && user.bewizr) { return false }
    return true
  }

  public isAvailableForCurrentProject(item: NavigationFilter) {
    if (projectStore.projectID == null) { return false }
    if (item.feature == null) { return true }
    return projectStore.isModuleFeatureEnabled(item.feature)
  }

  public isAvailableForCurrentEnvironment(item: NavigationFilter) {
    if (item.env == null) { return true }
    return item.env.includes(config.environment)
  }

  public isActiveItem(item: NavigationItem, pathname: string) {
    const href = navigationItemHref(item)
    if (href == null) { return false }

    return pathname === href || pathname.startsWith(`${href}/`)
  }

}

export function isNavigationGroup(item: NavigationItem | NavigationGroup): item is NavigationGroup {
  return 'group' in item
}

export function isNavigationItem(item: NavigationItem | NavigationGroup): item is NavigationItem {
  return !isNavigationGroup(item)
}

export function isStandardNavigationItem(item: NavigationItem | NavigationGroup): item is StandardNavigationItem {
  return 'name' in item
}

export function navigationItemName(item: NavigationItem) {
  if (isStandardNavigationItem(item)) {
    return item.name
  } else {
    return item.resource
  }
}

export function navigationItemIcon(item: NavigationItem) {
  return isStandardNavigationItem(item) ? item.icon : ModelOfType(item.resource).$icon
}

export function navigationItemHref(item: NavigationItem) {
  return isStandardNavigationItem(item) ? item.href : resourceListPath(item.resource)
}

export function navigationItemOnTap(item: NavigationItem) {
  return isStandardNavigationItem(item) ? item.onTap : undefined
}

export function navigationItemCaption(item: NavigationItem) {
  if ('caption' in item && item.caption != null) {
    return item.caption
  } else if (isStandardNavigationItem(item)) {
    return i18next.t(`navigation:items.${item.name}.caption`)
  } else {
    return upperFirst(i18next.t('plural', {ns: [item.resource, 'resource']}))
  }
}

export function navigationItemDetail(item: NavigationItem) {
  if (isStandardNavigationItem(item)) {
    return i18next.t(`navigation:items.${item.name}.detail`)
  } else {
    return upperFirst(i18next.t('navdetail', {
      ns: [item.resource, 'resource'],
      defaultValue: i18next.t(`navigation:items.resource.detail`, {plural: i18next.t('plural', {ns: item.resource})}),
    }))
  }
}

export function filterNavigationGroups(groups: NavigationGroup[], predicate: (item: NavigationItem | NavigationGroup, group: NavigationGroup) => boolean): NavigationGroup[] {
  const result: NavigationGroup[] = []

  for (const group of groups) {
    if (!predicate(group, group)) { continue }

    const items = group.items.filter(it => predicate(it, group))
    if (items.length > 0) {
      result.push({...group, items})
    }
  }

  return result
}

const navigationStore = register(new NavigationStore())
export default navigationStore
