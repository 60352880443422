import socket from 'socket.io-react'
import MapService from './map/MapService'
import { register } from './support'

export class MapStore {

  public mapService() {
    return new MapService(socket)
  }

}

const mapStore = register(new MapStore())
export default mapStore