import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { useTranslation } from 'react-i18next'
import { Project } from '~/models'
import { authenticationStore, projectStore } from '~/stores'
import CreateProjectForm from '~/ui/app/projects/create/CreateProjectForm'
import ProjectLogo, { defaultLogoSize as logoSize } from '~/ui/app/projects/ProjectLogo'
import { memo, observer } from '~/ui/component'
import {
  flexStyle,
  HBox,
  Label,
  Popup,
  PushButton,
  SegmentedButton,
  SVG,
  Tappable,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { colors, createUseStyles, layout, presets } from '~/ui/styling'
import ProjectList from './ProjectList'

const ProjectSwitcher = observer('ProjectSwitcher', () => {

  const user      = authenticationStore.user
  const project   = projectStore.project

  const endpoint  = useModelEndpoint(Project, {fetch: true, include: ['app']})

  const mayCreate  = user?.isAdmin()
  const oneProject = endpoint.data.length === 1
  const showPopup  = !oneProject || mayCreate

  const [isOpen, open, close] = useBoolean()
  const [category, setCategory] = React.useState<'active' | 'archived'>('active')

  const [createFormOpen, openCreateForm, closeCreateForm] = useBoolean()

  const initialFetch = React.useCallback(() => {
    endpoint.resetAndFetch()
  }, [endpoint])

  const fetchMore = React.useCallback(() => {
    if (!isOpen) { return }
    endpoint.fetchMore()
  }, [endpoint, isOpen])

  React.useEffect(() => {
    if (!isOpen) { return }
    endpoint.setParams({label: category})
  }, [category, endpoint, isOpen])

  const [t] = useTranslation('project_switcher')

  useHotkey('Cmd+Shift+P', open)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (showPopup) {
      return renderPopup()
    } else {
      return renderCurrentProject(false)
    }
  }

  function renderPopup() {
    return (
      <Popup
        open={isOpen}
        onWillOpen={initialFetch}
        requestClose={close}
        renderContent={renderPopupContent}
        children={renderButton()}
        targetStyle={flexStyle('grow')}
        matchTargetSize
        autoFocus='input'
      />
    )
  }

  function renderButton() {
    return (
      <Tappable flex='grow' classNames={$.projectButton} onTap={open}>
        {renderCurrentProject(true)}
        <CreateProjectForm
          open={createFormOpen}
          requestClose={closeCreateForm}
        />
      </Tappable>
    )
  }

  function renderCurrentProject(interactive: boolean) {
    return (
      <VBox flex='grow' justify='middle' classNames={$.currentProject}>
        {project == null ? (
          <EmptyCurrentProject/>
        ) : (
          <CurrentProject project={project} interactive={interactive}/>
        )}
      </VBox>
    )
  }

  function renderPopupContent() {
    return (
      <VBox flex='shrink'>
        {renderHeader()}
        <ProjectList
          endpoint={endpoint}
          onDidSwitchProject={close}
          onEndReached={fetchMore}
        />
        {mayCreate && renderCreateProject()}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <VBox gap={layout.padding.s} classNames={$.header}>
        {renderTitle()}
        {renderCategoryToggle()}
      </VBox>
    )
  }

  function renderTitle() {
    return (
      <Label h2>
        {t('projects')}
      </Label>
    )
  }

  function renderCategoryToggle() {
    return (
      <SegmentedButton
        segments={[
          {value: 'active', caption: t('active')},
          {value: 'archived', caption: t('archived')},
        ]}
        selectedValue={category}
        onChange={setCategory}
        small={true}
      />
    )
  }

  function renderCreateProject() {
    return (
      <VBox classNames={$.createProject}>
        <PushButton
          icon='plus'
          caption={t('create_project.caption')}
          onTap={openCreateForm}
        />
      </VBox>
    )
  }

  return render()

})

export default ProjectSwitcher

export interface CurrentProjectProps {
  project:     Project
  interactive: boolean
}

export const CurrentProject = memo('CurrentProject', (props: CurrentProjectProps) => {

  const {interactive} = props
  const {name, logo, participantCount} = props.project

  const [t] = useTranslation('project_switcher')

  const $ = useStyles()

  return (
    <HBox gap={layout.padding.inline.m} classNames={$.buttonContent}>
      <ProjectLogo logo={logo}/>
      <VBox flex>
        <Label h3>
          {name}
        </Label>
        <Label tiny dim>
          {t('projects:participant_count', {count: participantCount})}
        </Label>
      </VBox>
      {interactive && <SVG name='chevron-down' dim size={layout.icon.s}/>}
    </HBox>
  )
})

export const EmptyCurrentProject = memo('EmptyCurrentProject', () => {

  const [t] = useTranslation('project_switcher')
  const $   = useStyles()

  return (
    <HBox gap={layout.padding.inline.m} classNames={$.buttonContent}>
      <ProjectLogo logo={null}/>
      <VBox flex>
        <Label italic light dim>
          {t('no_project_selected')}
        </Label>
      </VBox>
      <SVG name='chevron-down' dim size={layout.icon.s}/>
    </HBox>
  )
})

const spinnerSize = 12

const useStyles = createUseStyles(theme => ({

  connecting: {
    width:   layout.leftNavWidth,
    padding: [0, layout.padding.inline.l + logoSize.width / 2 - spinnerSize / 2],
  },

  projectButton: {
    background: theme.bg.alt,

    position: 'relative',
    '&:hover': {
      ...presets.overlayAfter({
        background: theme.bg.hover,
      }),
      ...colors.overrideForeground(theme.semantic.primary),
    },
  },

  currentProject: {
    width: layout.leftNavWidth,
  },

  projectImage: {
    borderRadius: layout.radius.s,
  },

  buttonContent: {
    padding: [layout.padding.inline.s, layout.padding.inline.l],
  },

  emptyLogo: {
    ...logoSize,
    background:   theme.bg.alt,
    borderRadius: layout.radius.s,
  },

  header: {
    padding:    [layout.padding.inline.l, layout.padding.inline.l, layout.padding.inline.m],
  },

  createProject: {
    background: theme.bg.semi,
    padding:    layout.padding.inline.l,
  },

}))