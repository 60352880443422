import React from 'react'
import { startCase } from 'lodash'
import { SystemNotification } from '~/models'
import { notificationSpec } from '~/notifications'
import { observer } from '~/ui/component'
import { Center, HBox, VBox } from '~/ui/components'
import { DonutChart, Legend } from '~/ui/components/datavis'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  notification: SystemNotification
}

const NotificationResultsView = observer('NotificationResultsView', (props: Props) => {

  const {notification} = props

  const {t}   = useResourceTranslation('notifications')
  const theme = useTheme()
  const spec  = notificationSpec(notification.type)

  const resultsData = React.useMemo(() => {
    return (notification?.results ?? [])
      .map(result => ({
        label: result.caption ?? t(`results.${result.type}`, {defaultValue: startCase(result.type)}),
        value: result.count,
        color: spec.colorForResult?.(notification, result, theme),
      })).filter(point => point.value > 0)
  }, [notification, spec, t, theme])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex wrap align='stretch' justify='center' gap={layout.padding.l}>
        {renderResultsChart()}
        {renderLegend()}
      </HBox>
    )
  }

  function renderResultsChart() {
    return (
      <Center flex='shrink'>
        {resultsData.length > 0 && (
          <DonutChart
            classNames={$.resultsChart}
            data={resultsData}
            valueForPoint={valueForPoint}
            colorForPoint={colorForPoint}
          />
        )}
      </Center>
    )
  }

  function renderLegend() {
    return (
      <VBox justify='middle' classNames={$.legend}>
        <Legend
          data={resultsData}
          captionForPoint={captionForPoint}
          valueForPoint={valueForPoint}
          colorForPoint={colorForPoint}
        />
      </VBox>
    )
  }

  const captionForPoint = React.useCallback(
    (point: AnyObject) => point.label,
    [],
  )

  const valueForPoint = React.useCallback(
    (point: AnyObject) => point.value,
    [],
  )

  const colorForPoint = React.useCallback(
    (point: AnyObject) => point.color,
    [],
  )

  return render()

})

export default NotificationResultsView

const useStyles = createUseStyles({
  resultsChart: {
    minWidth: 180,
  },

  legend: {
    maxWidth: 180,
  },
})