import React from 'react'
import { OperatorEntry, operatorStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { ClearButton, Dimple, HBox, PanelHeader, Spinner } from '~/ui/components'
import { useTheme } from '~/ui/styling'

export interface Props {
  participantID: string
  entry:         OperatorEntry | null
}

const OperatorPanelHeader = memo('OperatorPanelHeader', (props: Props) => {

  const {participantID, entry} = props

  const close = React.useCallback(() => {
    operatorStore.service?.removeParticipant(participantID)
  }, [participantID])

  const clearAlert = React.useCallback(() => {
    operatorStore.service?.clearAlert(participantID)
  }, [participantID])

  const theme = useTheme()
  const backgroundColor = entry?.lastAlertAt != null
    ? theme.semantic.warning
    : undefined

  //------
  // Rendering


  function render() {
    return (
      <PanelHeader
        icon={renderAvatar()}
        caption={renderCaption()}
        right={renderHeaderRight()}
        backgroundColor={backgroundColor}
        large
      />
    )
  }

  function renderAvatar() {
    if (entry == null) { return null }

    return (
      <Avatar
        source={entry.photoURL ?? null}
        {...entry}
        size={avatarSize}
      />
    )
  }

  function renderCaption() {
    if (entry == null) {
      return <Spinner size={8}/>
    } else {
      return entry.name
    }
  }

  function renderHeaderRight() {
    return (
      <HBox>
        {renderAlertButton()}
        <Dimple vertical/>
        {renderCloseButton()}
      </HBox>
    )
  }

  function renderAlertButton() {
    if (entry?.lastAlertAt == null) { return null }

    return (
      <ClearButton
        icon='bell-strikethrough'
        onTap={clearAlert}
        padding='horizontal'
      />
    )
  }

  function renderCloseButton() {
    return (
      <ClearButton
        icon='cross'
        onTap={close}
        padding='horizontal'
      />
    )
  }

  return render()

})

export const avatarSize = {
  width:  24,
  height: 24,
}

export default OperatorPanelHeader