import React from 'react'
import { Project } from '~/models'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { observer } from '~/ui/component'
import { DateField, HBox, TextField, TimeZoneField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog, ResourceFormDialogProps } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export type Props = Omit<ResourceFormDialogProps<Project>, 'Model' | 'children'>

const ProjectForm = observer('ProjectForm', (props: Props) => {

  const {t, fieldInstruction} = useResourceTranslation('projects')
  const title = props.model == null ? t('create.title') : t('edit.title')

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        {...props}
        Model={Project}
        title={title}
        children={renderFields()}
        semi
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        <HBox gap={layout.padding.m} align='top'>
          <VBox flex gap={layout.padding.s}>
            <FormField name='name' required>
              {bind => <TextField {...bind}/>}
            </FormField>
            <FormField name='code' required instruction={fieldInstruction('code_readonly')!}>
              {bind => <TextField {...bind} readOnly mono/>}
            </FormField>
          </VBox>
          <VBox>
            <FormField name='logo' renderAsLabel={false}>
              {bind => (
                <CustomImageField
                  {...bind}
                  allowedTypes={['remote']}
                  size={{width: 116, height: 116}}
                  objectFit='contain'
                />
              )}
            </FormField>
          </VBox>
        </HBox>
        <VBox gap={layout.padding.s}>
          <FormField name='timeZone' required>
            {bind => <TimeZoneField {...bind}/>}
          </FormField>
          <HBox gap={layout.padding.s} align='top'>
            <VBox flex>
              <FormField name='startDate' required>
                {bind => <DateField {...bind} utc/>}
              </FormField>
            </VBox>
            <VBox flex>
              <FormField name='endDate'>
                {bind => <DateField {...bind} utc/>}
              </FormField>
            </VBox>
          </HBox>
        </VBox>
      </VBox>
    )
  }

  return render()

})

export default ProjectForm