import JSZip from 'jszip'
import * as XLSX from 'xlsx'
import { DataPoint } from '~/stores'

export default class WidgetReport {

  constructor(
    public readonly data: DataPoint[],
  ) {}

  public zip = new JSZip()

  public async generate(): Promise<Blob> {
    const book  = XLSX.utils.book_new()
    const sheet = XLSX.utils.json_to_sheet(this.data)
    XLSX.utils.book_append_sheet(book, sheet)

    const buffer = XLSX.write(book, {type: 'array'})
    return new Blob([buffer])
  }

}