import React from 'react'
import {
  Challenge,
  ChallengeAbandonedTrigger,
  ChallengeCompletedTrigger,
  ChallengePublishedTrigger,
  ChallengeReviewedTrigger,
} from '~/models'
import { observer } from '~/ui/component'
import { Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: ChallengePublishedTrigger | ChallengeAbandonedTrigger | ChallengeCompletedTrigger | ChallengeReviewedTrigger
}

const ChallengeTriggerNodeView = observer('ChallengeTriggerNodeView', (props: Props) => {
  const [challenge, {fetchStatus}] = useModelDocumentData(Challenge, props.trigger.challenge, {
    fetch:  'never',
    detail: false,
  })
  const detail = challenge == null
    ? (fetchStatus === 'fetching' ? <Spinner size={8}/> : null)
    : `[${challenge.name}]`

  return (
    <TriggerNodeView
      {...props}
      detail={detail}
      icon='login'
    />
  )
})

register('challenge:*', ChallengeTriggerNodeView)