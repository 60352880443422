import React from 'react'
import { Group, TargetingCombinator } from '~/models'
import { observer } from '~/ui/component'
import { ClearButton, HBox, Label, Panel, SelectField, TextBlock, VBox } from '~/ui/components'
import { height as clearButtonHeight } from '~/ui/components/ClearButton'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import TargetingFormModel from './TargetingFormModel'

export interface Props extends FormDialogProps<TargetingFormModel> {
  instructions: string
}

const TargetingForm = observer('TargetingForm', (props: Props) => {

  const {t} = useResourceTranslation('targeting')

  const {
    instructions,
    model,
    title = t('form.title'),
    children,
    ...rest
  } = props

  const clearTargeting = React.useCallback(() => {
    model.clear()
  }, [model])

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='targeting'>
        <FormDialog semi={false} model={model} icon='target' title={title} {...rest} autoFocus={false}>
          {renderBody()}
        </FormDialog>
      </ResourceTypeProvider>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>
        <Panel padding={layout.padding.m}>
          <TextBlock small dim>
            {t(instructions)}
          </TextBlock>
        </Panel>

        <VBox gap={layout.padding.xs}>
          {renderPreamble()}
          {renderGroupsField()}
          {renderClearButton()}
        </VBox>

        {children}
      </VBox>
    )
  }

  function renderPreamble() {
    return (
      <HBox gap={layout.padding.inline.s} align='middle'>
        <Label caption dim>
          {t('form.preamble.pre')}
        </Label>
        <FormField name='combinator' caption={false}>
          {bind => <SelectField {...bind} choices={combinatorChoices} small inputStyle='dark'/>}
        </FormField>
        <Label caption dim>
          {t('form.preamble.post')}
        </Label>
      </HBox>
    )
  }

  function renderGroupsField() {
    return (
      <FormField name='groups' caption={false}>
        {bind => <ResourceField {...bind} Model={Group} multi/>}
      </FormField>
    )
  }

  function renderClearButton() {
    if (model.groups.length === 0) {
      return <VBox style={{height: clearButtonHeight.normal}}/>
    }

    return (
      <HBox>
        <ClearButton
          icon='cross'
          caption={t('form.clear')}
          onTap={clearTargeting}
          padding='vertical'
        />
      </HBox>
    )
  }

  const combinatorChoices = React.useMemo((): Choice<TargetingCombinator>[] => [
    {value: 'all', caption: t('combinator.all')},
    {value: 'any', caption: t('combinator.any')},
  ], [t])

  return render()

})

export default TargetingForm