import React from 'react'
import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Competition, formatScore, ScoreFormat } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Dimple,
  HBox,
  Label,
  NumberField,
  Panel,
  SelectField,
  SwitchField,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import {
  FormDialog,
  FormDialogProps,
  FormField,
  FormFieldHeader,
  FormModel,
  SubmitResult,
} from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<FormDialogProps<any>, 'model'> {
  competition: Competition
}

const ScoreFormatForm = observer('ScoreFormatForm', (props: Props) => {

  const {competition, ...rest} = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new ScoreFormatFormModel(competition),
    [competition],
  )

  const availableLocales = React.useMemo(() => {
    const map: Record<string, string> = t('locales:available')
    return Object.entries(map).map(([code, caption]) => ({
      value:   code,
      caption: caption,
    }))
  }, [t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <FormDialog semi={false} model={formModel} {...rest} width={720} title={t('score_format.form_title')}>
        <HBox align='stretch' gap={layout.padding.m}>
          {renderScoreFormatFields()}
          {renderExamples()}
        </HBox>
      </FormDialog>
    )
  }

  function renderScoreFormatFields() {
    return (
      <VBox flex gap={layout.padding.m}>
        <FormField name='locale' caption={t('score_format.locale')}>
          {bind => <SelectField {...bind} choices={availableLocales}/>}
        </FormField>

        <VBox gap={layout.padding.s}>
          <TextBlock small dim markup>
            {t('score_format.template_instructions')}
          </TextBlock>
          <FormField name='templateSingular' caption={t('score_format.template_singular')}>
            {bind => <TextField {...bind} mono showClearButton='notempty'/>}
          </FormField>
          <FormField name='templatePlural' caption={t('score_format.template_plural')}>
            {bind => <TextField {...bind} mono showClearButton='notempty'/>}
          </FormField>
        </VBox>

        <VBox gap={layout.padding.inline.m}>
          <FormFieldHeader
            caption={t('score_format.decimals')}
          />
          <HBox align='top' gap={layout.padding.s}>
            <VBox flex>
              <FormField name='minimumDecimals' caption={false}>
                {bind => <NumberField {...bind} minimum={0} step={1} placeholder={t('score_format.minimum')}/>}
              </FormField>
            </VBox>
            <VBox flex>
              <FormField name='maximumDecimals' caption={false}>
                {bind => <NumberField {...bind} minimum={0} step={1} placeholder={t('score_format.maximum')}/>}
              </FormField>
            </VBox>
          </HBox>
        </VBox>

        <FormField name='useGrouping' caption={t('score_format.other')}>
          {bind => <SwitchField {...bind} label={t('score_format.use_grouping_label')}/>}
        </FormField>
      </VBox>
    )
  }

  function renderExamples() {
    const scoreFormat = formModel.buildScoreFormat()

    return (
      <Panel classNames={$.examples} padding={layout.padding.inline.l} gap={layout.padding.m}>
        <Label h3>
          {t('score_format.examples')}
        </Label>

        <VBox gap={layout.padding.inline.m}>
          <ScoreFormatExample format={scoreFormat} points={1}/>
          <Dimple horizontal/>
          <ScoreFormatExample format={scoreFormat} points={-1}/>
          <Dimple horizontal/>
          <ScoreFormatExample format={scoreFormat} points={2}/>
          <Dimple horizontal/>
          <ScoreFormatExample format={scoreFormat} points={1200}/>
          <Dimple horizontal/>
          <ScoreFormatExample format={scoreFormat} points={123456}/>
          <Dimple horizontal/>
          <ScoreFormatExample format={scoreFormat} points={3.141529}/>
        </VBox>
      </Panel>
    )
  }

  return render()

})

export default ScoreFormatForm

interface ScoreFormatExampleProps {
  points: number
  format: ScoreFormat
}

function ScoreFormatExample(props: ScoreFormatExampleProps) {

  const {points, format} = props

  function render() {
    const formatted = formatScore(points, format)

    return (
      <HBox gap={layout.padding.inline.s}>
        <Label flex={2} mono small dim markup>
          {points}
        </Label>
        <Label flex={3} small>
          {formatted}
        </Label>
      </HBox>
    )
  }

  return render()

}

class ScoreFormatFormModel implements FormModel {

  constructor(
    public readonly competition: Competition,
  ) {
    makeObservable(this)
  }

  @observable
  public locale: string = this.competition.scoreFormat.locale ?? 'en_US'

  @observable
  public templateSingular: string = this.competition.scoreFormat.templateSingular ?? ''

  @observable
  public templatePlural: string = this.competition.scoreFormat.templatePlural ?? ''

  @observable
  public minimumDecimals: number | null = this.competition.scoreFormat.minimumDecimals ?? null

  @observable
  public maximumDecimals: number | null = this.competition.scoreFormat.maximumDecimals ?? null

  @observable
  public useGrouping: boolean = this.competition.scoreFormat.useGrouping ?? true

  public buildScoreFormat(): ScoreFormat {
    return {
      locale:           this.locale,
      templateSingular: cleanTextValue(this.templateSingular) ?? undefined,
      templatePlural:   cleanTextValue(this.templatePlural) ?? undefined,
      minimumDecimals:  this.minimumDecimals ?? undefined,
      maximumDecimals:  this.maximumDecimals ?? undefined,
      useGrouping:      this.useGrouping,
    }
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Competition, this.competition.id, {
      scoreFormat: this.buildScoreFormat(),
    })
  }

}

const useStyles = createUseStyles({
  examples: {
    width: 260,
  },
})