import React from 'react'
import { createUseStyles } from 'react-jss'
import { TalliedResult } from '~/models'
import { Result } from '~/stores'
import { memo } from '~/ui/component'
import { HBox, Scroller, VBox } from '~/ui/components'
import { BarChart, Legend } from '~/ui/components/datavis'
import { layout } from '~/ui/styling'
import { DataPoint, pivotData, segmentCaption } from './util'

export interface Props {
  results: Array<Result & TalliedResult>
}

const BarChartResult = memo('BarChartResult', (props: Props) => {
  const {results} = props
  const [data, segments] = React.useMemo(
    () => pivotData(results),
    [results],
  )

  const captionForPoint = React.useCallback(
    (point: DataPoint) => point.answer,
    [],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Scroller contentClassNames={$.scrollerContent}>
        <HBox wrap flex='both' align='middle' gap={layout.padding.l} classNames={$.barChartResult}>
          <VBox flex='grow'>
            {renderChart()}
          </VBox>
          {renderLegend()}
        </HBox>
      </Scroller>
    )
  }

  function renderChart() {
    return (
      <BarChart<DataPoint>
        data={data}
        axisLabelForPoint={captionForPoint}
      >
        {segments.map(segment => (
          <BarChart.Series<DataPoint>
            key={segment.value}
            name={segment.value}
            caption={segmentCaption(segment)}
            getValue={point => point.values[segment.value]}
          />
        ))}
      </BarChart>
    )
  }

  function renderLegend() {
    return (
      <VBox justify='middle' classNames={$.legend}>
        <Legend
          data={segments}
          captionForPoint={(segment) => segmentCaption(segment)}
        />
      </VBox>
    )
  }

  return render()
})

export default BarChartResult

const useStyles = createUseStyles({
  barChartResult: {
    padding: layout.padding.m,
    marginRight: layout.padding.l,
  },
  scrollerContent: {
    ...layout.responsive(size => ({
      marginRight: layout.padding.l[size],
      marginBottom: layout.padding.l[size],
    })),
  },
  legend: {
    maxWidth: 240,
  },
})