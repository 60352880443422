import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { Challenge, ModelOfType, Script } from '~/models'
import { dataStore, Linkage, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { AutoCompleteField, AutoCompleteFieldProps, ListItem } from '~/ui/components'
import { useModelDocument } from '~/ui/hooks/data'
import { ResourceListItem, ResourceListItemProps } from '~/ui/resources/components'
import ProjectLabel from '../../projects/ProjectLabel'

export interface Props {
  value:    AnswerScopeLinkage | null
  onChange: (value: AnswerScopeLinkage | null) => any

  placeholder?: string

  noResultsPlaceholder?: AutoCompleteFieldProps<string, AnswerScopeModel>['noResultsPlaceholder']
  createPlaceholder?:    AutoCompleteFieldProps<string, AnswerScopeModel>['createPlaceholder']
  requestCreate?:        AutoCompleteFieldProps<string, AnswerScopeModel>['requestCreate']
  onSelect?:             AutoCompleteFieldProps<string, AnswerScopeModel>['onSelect']

  excludeIDs?:          string[]
  renderPropsForModel?: (model: AnswerScopeModel) => Partial<Omit<ResourceListItemProps, 'model'>>

  filters?:     Record<string, string | null>
  inputStyle?:  AutoCompleteFieldProps<any, any>['inputStyle']
}

export type AnswerScopeLinkage = '$all' | Linkage<'challenges' | 'scripts'>
export type AnswerScopeModel   = '$all' | Challenge | Script

const AnswerScopeField = observer('AnswerScopeField', (props: Props) => {

  const {
    value,
    onChange,
    filters,
    excludeIDs = [],
    renderPropsForModel,
    ...rest
  } = props

  const timer = useTimer()
  const [t] = useTranslation('answers')

  const currentProjectID = projectStore.projectID

  //------
  // Fetch

  // Ensure that all models currently in the list are fetched, otherwise the field shows up empty.
  const type = (value !== '$all' ? value?.type : null) ?? null
  const id   = (value !== '$all' ? value?.id : null) ?? null

  const Model = type == null ? null : ModelOfType(type)

  useModelDocument(Model ?? Challenge, id, {detail: false})

  //------
  // Searching

  const [searchResults, setSearchResults] = React.useState<AnswerScopeModel[]>([])

  const results = React.useMemo(() => {
    return searchResults
      .filter(res => res === '$all' || res.id !== id)
      .filter(res => res === '$all' || !excludeIDs.includes(res.id))
  }, [excludeIDs, id, searchResults])

  const search = React.useCallback(async (search: string | null) => {
    const challengesEndpoint = dataStore.detachedEndpoint(Challenge)
    const scriptsEndpoint    = dataStore.detachedEndpoint(Script)

    await timer.await(challengesEndpoint.fetchWithParams({search, filters}))
    await timer.await(scriptsEndpoint.fetchWithParams({search, filters}))

    setSearchResults([
      '$all',
      ...challengesEndpoint.data,
      ...scriptsEndpoint.data,
    ])
  }, [filters, timer])

  const handleChange = React.useCallback((value: string | null) => {
    if (value == null) {
      onChange?.(null)
    } else if (value === '$all') {
      onChange?.('$all')
    } else {
      const [type, id] = value.split(':')
      const linkage = {type, id} as AnswerScopeLinkage
      onChange?.(linkage)
    }
  }, [onChange])

  //------
  // Rendering

  function render() {
    return (
      <AutoCompleteField<string, AnswerScopeModel>
        value={value === '$all' ? '$all' : type == null ? null : `${type}:${id}`}
        onChange={handleChange}
        onSearch={search}
        results={results}
        throttle={500}
        renderChoice={renderChoice}
        captionForChoice={captionForChoice}
        valueForChoice={valueForChoice}
        choiceForValue={choiceForValue}
        {...rest}
      />
    )
  }

  const renderChoice = React.useCallback((model: AnswerScopeModel) => {
    if (model === '$all') {
      return (
        <ListItem
          image='star'
          caption={t('scope.all')}
        />
      )
    } else {
      return (
        <ResourceListItem
          model={model}
          detail={model.project === currentProjectID ? null : <ProjectLabel projectID={model.project} tiny dim/>}
          {...renderPropsForModel?.(model)}
        />
      )
    }
  }, [currentProjectID, renderPropsForModel, t])

  const choiceForValue = React.useCallback((value: string): AnswerScopeModel | null => {
    if (value === '$all') { return '$all' }

    const [type, id] = value.split(':')
    return dataStore.get(ModelOfType(type), id) as AnswerScopeModel | null
  }, [])

  const valueForChoice = React.useCallback((model: AnswerScopeModel): string => {
    if (model === '$all') { return '$all' }

    const type = model.$ModelClass.resourceType
    const id   = model.id
    return `${type}:${id}`
  }, [])

  const captionForChoice = React.useCallback((model: AnswerScopeModel) => {
    if (model === '$all') {
      return t('scope.all')
    } else {
      return model.$caption
    }
  }, [t])

  return render()

})

export default AnswerScopeField