import socket from 'socket.io-react'
import { assignGlobal } from '~/global'

assignGlobal({
  DEV:    process.env.NODE_ENV === 'development',
  CLIENT: typeof window !== 'undefined',
  SERVER: typeof window === 'undefined',
})

if (DEV) {
  assignGlobal({
    socket,
  })
}
