import React from 'react'
import { CalendarPlanDayInsightsSummary } from '~/stores'
import { observer } from '~/ui/component'
import InsightsBadgeRow from '../../insights/InsightsBadgeRow'
import { useCalendarPlanner } from '../CalendarPlannerContext'

export interface Props {
  summary: CalendarPlanDayInsightsSummary
}

const CalendarPlanInsightsBadgeRow = observer('CalendarPlanInsightsBadgeRow', (props: Props) => {

  const {summary} = props

  const {planner} = useCalendarPlanner()
  const insights  = planner.insights

  //------
  // Callbacks

  const focus = React.useCallback(() => {
    planner.setFocusedInsightsDayUUID(summary.day.uuid)
  }, [planner, summary.day.uuid])

  //------
  // Rendering

  function render() {
    if (insights == null) { return null }

    return (
      <InsightsBadgeRow
        summary={summary}
        participants={insights.participants}
        align='center'

        requestFocus={focus}
      />
    )
  }


  return render()

})

export default CalendarPlanInsightsBadgeRow