import React from 'react'
import { Channel, Targeting } from '~/models'
import { dataStore } from '~/stores'
import { TargetingForm, TargetingFormModel, TargetingFormProps } from '~/ui/app/targeting'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

export interface Props extends Omit<TargetingFormProps, 'model'> {
  channel: Channel
}

const ChannelTargetingForm = observer('ChannelTargetingForm', (props: Props) => {

  const {channel, ...rest} = props

  const formModel = React.useMemo(
    () => new ChannelTargetingFormModel(channel),
    [channel],
  )

  //------
  // Rendering

  function render() {
    return (
      <TargetingForm
        model={formModel}
        {...rest}
      />
    )
  }

  return render()

})

export default ChannelTargetingForm

class ChannelTargetingFormModel extends TargetingFormModel {

  constructor(
    public readonly channel: Channel,
  ) {
    super(channel.targeting ?? Targeting.empty())
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Channel, this.channel.id, {
      targeting: this.buildTargeting(),
    })
  }

}