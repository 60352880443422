import React from 'react'
import { useTranslation } from 'react-i18next'
import { AnswersQuery } from '~/models'
import { observer } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { ResourceChip } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import AnswersWidgetForm, { AnswersWidgetFormModel } from './AnswersWidgetForm'
import { register, WidgetProps } from './registry'
import WidgetPanel from './WidgetPanel'

export interface Params {
  query: string
}

export type Props = WidgetProps<Params>

const AnswersWidget = observer('AnswersWidget', (props: Props) => {

  const {params} = props

  const [t] = useTranslation('widgets')

  //------
  // Rendering

  function render() {
    return (
      <WidgetPanel<AnswersWidgetFormModel>
        widget='answers'
        params={params}
        icon='column-chart'
        FormModel={AnswersWidgetFormModel}
        renderForm={renderForm}
      >
        <VBox gap={layout.padding.s}>
          <Label truncate={false} small dim>
            {t('answers.instruction')}
          </Label>
          {renderQuery()}
        </VBox>
      </WidgetPanel>
    )
  }

  function renderQuery() {
    if (params.query == null) { return null }
    return (
      <VBox gap={layout.padding.inline.m} align='left'>
        <FormFieldHeader caption={t('answers.fields.query.caption')}/>
        <ResourceChip
          Model={AnswersQuery}
          id={params.query}
        />
      </VBox>
    )
  }

  function renderForm(formModel: AnswersWidgetFormModel, open: boolean, requestClose: () => any) {
    return (
      <AnswersWidgetForm
        open={open}
        requestClose={requestClose}
        model={formModel}
      />
    )
  }

  return render()

})

register('answers', AnswersWidget)