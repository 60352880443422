import React from 'react'
import { appStore } from '~/stores'
import { memo } from '~/ui/component'
import { Center, Spinner } from '~/ui/components'

export interface Props {
  ready:     boolean
  children?: React.ReactNode
}

const ReadyGuard = memo('ReadyGuard', (props: Props) => {

  const {children} = props

  //------
  // Rendering

  function render() {
    if (!appStore.ready) {
      return renderNotReady()
    } else {
      return <>{children}</>
    }
  }

  function renderNotReady() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  return render()

})

export default ReadyGuard