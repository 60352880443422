import React from 'react'
import { useTranslation } from 'react-i18next'
import { stringContains } from 'ytil'
import { operatorStore, OperatorTrigger } from '~/stores'
import { observer } from '~/ui/component'
import { AutoCompleteField, SingleProps } from '~/ui/components'

export interface Props extends Omit<SingleProps<string, OperatorTrigger>,
  | 'value'
  | 'onChange'
  | 'onSearch'
  | 'choiceForValue'
  | 'valueForChoice'
  | 'captionForChoice'
> {
  value:    OperatorTrigger | null
  onChange: (value: OperatorTrigger | null) => any
}

const OperatorTriggerField = observer('OperatorTriggerField', (props: Props) => {

  const {value, onChange, ...rest} = props

  const service  = operatorStore.service
  const triggers = service?.namedTriggers

  const [filter, setFilter] = React.useState<string | null>(null)

  const filteredTriggers = React.useMemo(() => {
    return (triggers ?? []).filter(
      it => filter == null ? true : stringContains(it.name, filter),
    )
  }, [filter, triggers])

  const choiceForValue = React.useCallback((value: string) => {
    const trigger = triggers?.find(it => it.uuid === value)
    return trigger ?? null
  }, [triggers])

  const valueForChoice = React.useCallback((node: OperatorTrigger) => {
    return node.uuid
  }, [])

  const captionForChoice = React.useCallback((node: OperatorTrigger) => {
    return node.name ?? node.uuid
  }, [])

  const search = React.useCallback((term: string | null) => {
    setFilter(term)
  }, [])

  const [t] = useTranslation('trigger_field')

  //------
  // Rendering

  function render() {
    return (
      <AutoCompleteField<string, OperatorTrigger>
        multi={false}
        value={value?.name ?? null}
        onSelect={onChange}
        results={filteredTriggers}

        {...rest}

        onSearch={search}
        choiceForValue={choiceForValue}
        valueForChoice={valueForChoice}
        captionForChoice={captionForChoice}

        placeholder={t('placeholder')}
        noResultsPlaceholder={t('no_results')}
      />
    )
  }

  return render()

})

export default OperatorTriggerField