import React from 'react'
import { MapService } from '~/stores'
import { memo } from '~/ui/component'

interface MapServiceContext {
  service: MapService | null
}

const MapServiceContext = React.createContext<MapServiceContext>({
  service: null,
})

export interface MapServiceProviderProps {
  service:   MapService | null
  children?: React.ReactNode
}

export const MapServiceProvider = memo('MapServiceProvider', (props: MapServiceProviderProps) => {

  const context = React.useMemo(() => ({
    service: props.service,
  }), [props.service])

  return (
    <MapServiceContext.Provider value={context}>
      {props.children}
    </MapServiceContext.Provider>
  )
})

export function useMapService() {
  return React.useContext(MapServiceContext)
}