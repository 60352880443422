import { SelectionManager } from 'react-selection-manager'
import { BulkSelector } from '~/stores'

export function bulkSelectorForSelection(resourceType: string, manager: SelectionManager<string>, filters: Record<string, any> = {}, label: string | null = null, search: string | null = null) {
  if (manager.allSelected) {
    return BulkSelector.filtered(filters, label, search)
  } else if (manager.selectedKeys.length > 0) {
    return BulkSelector.list(resourceType, manager.selectedKeys)
  } else {
    return null
  }
}
