import React from 'react'
import { Location } from '~/models'
import { GoogleMap, mapIcon, Marker } from '~/ui/app/google-maps'
import { memo } from '~/ui/component'
import { useTheme } from '~/ui/styling'

export interface Props {
  location:     Location
  count:        number
  tint:         'primary' | 'secondary'
  interactive?: boolean
  onTap?:       (location: Location) => any
  markerRef?:   React.Ref<Marker>
}

const LocationMapPin = memo('LocationMapPin', (props: Props) => {

  const {
    location,
    count,
    tint,
    onTap,
    markerRef,
    interactive = onTap != null,
  } = props

  const theme = useTheme()

  const tap = React.useCallback(() => {
    onTap?.(location)
  }, [location, onTap])

  const icon  = mapIcon(`marker.${tint}` as any)
  const color = tint === 'primary' ? theme.semantic.primary : theme.semantic.secondary

  //------
  // Rendering

  function render() {
    return (
      <>
        <GoogleMap.Marker
          coordinate={location.coordinate}
          icon={icon}
          label={count.toString()}
          ref={markerRef}
          onClick={interactive ? tap : undefined}
        />
        <GoogleMap.Circle
          center={location.coordinate}
          radius={location.radius}
          fillColor={color.alpha(0.2)}
          strokeColor={color}
          strokeWeight={2}
        />
      </>
    )
    }

  return render()

})

export default LocationMapPin