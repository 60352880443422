import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { Endpoint } from 'mobx-document'
import { memo } from '~/ui/component'
import { HBox, Panel, SearchField, VBox } from '~/ui/components'
import { Form } from '~/ui/form'
import { useResourceListLocation } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import Pagination, { PaginationMetaShape } from './Pagination'
import ResourceCollectionFiltersFormModel from './ResourceCollectionFiltersFormModel'
import TotalsLabel, { TotalsMetaShape } from './TotalsLabel'

export interface Props<E extends Endpoint<any, any, SummaryMetaShape>> {
  endpoint: E

  search:    string | null | false
  setSearch: (search: string | null) => any

  page:    number
  setPage: (page: number) => any

  showTotals?: boolean

  filters?: React.ReactNode
}

export type SummaryMetaShape = TotalsMetaShape & PaginationMetaShape

const SummaryBar = memo('SummaryBar', (props: Props<any>) => {

  const {
    endpoint,
    search,
    setSearch,
    page,
    setPage,
    showTotals = true,
    filters,
  }  = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.summaryBar}>
        <HBox gap={layout.padding.inline.l}>
          {showTotals && renderTotals()}
          {renderPagination()}
          {renderSearch()}
          {filters && <SummaryBarFilters children={filters}/>}
        </HBox>
      </Panel>
    )
  }

  function renderTotals() {
    return (
      <VBox flex>
        <TotalsLabel
          endpoint={endpoint}
          small
          dim
        />
      </VBox>
    )
  }

  function renderPagination() {
    return (
      <VBox flex>
        <Pagination
          endpoint={endpoint}
          page={page}
          setPage={setPage}
        />
      </VBox>
    )
  }

  function renderSearch() {
    if (search === false) { return null }

    return (
      <VBox flex>
        <SearchField
          ref={searchFieldRef}
          search={search}
          onSearch={setSearch}
          debounce={500}
          inputStyle='light'
        />
      </VBox>
    )
  }

  //------
  // Keyboard

  const searchFieldRef = React.useRef<HTMLInputElement>(null)

  const focusOnSearchField = React.useCallback(() => {
    searchFieldRef.current?.focus()
  }, [])

  useHotkey('/', focusOnSearchField)

  return render()

})

export default SummaryBar

interface SummaryBarFiltersProps {
  children?: React.ReactNode
}

const SummaryBarFilters = memo('SummaryBarFilters', (props: SummaryBarFiltersProps) => {

  const {children} = props

  const {filters, setFilters} = useResourceListLocation()
  const filtersFormModel = React.useMemo(
    () => new ResourceCollectionFiltersFormModel(filters ?? {}, setFilters),
    [filters, setFilters],
  )

  function render() {
    return (
      <Form model={filtersFormModel} autoSubmit>
        <HBox gap={layout.padding.s}>
          {children}
        </HBox>
      </Form>
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  summaryBar: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
}))