import React from 'react'
import { SystemNotification } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import NotificationRelationshipBar from './NotificationRelationshipBar'

export interface Props {
  notification: SystemNotification
}

const NotificationRelationshipsList = memo('NotificationRelationshipsList', (props: Props) => {

  const {notification} = props

  const {fieldCaption} = useResourceTranslation('notifications')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormFieldHeader
          caption={fieldCaption('related')}
        />

        <VBox gap={layout.padding.inline.s}>
          {Object.entries(notification.relationships).map(([name, relationship]) => (
            <NotificationRelationshipBar
              key={name}
              relationship={relationship}
            />
          ))}
        </VBox>
      </VBox>
    )
  }

  return render()

})

export default NotificationRelationshipsList