import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  appStore,
  authenticationStore,
  navigationItemHref,
  navigationStore,
  projectStore,
} from '~/stores'
import { observer } from '~/ui/component'
import { usePrevious } from '~/ui/hooks'
import AuthNavigator from './AuthNavigator'
import RootNavigator from './RootNavigator'

const AppNavigator = observer('AppNavigator', () => {

  const location = useLocation()
  const history  = useHistory()

  const appReady = appStore.ready

  const {isLoggedIn, user, impersonatedUserID} = authenticationStore

  const {projectID} = projectStore
  const navItems    = navigationStore.availableItems

  const isOnAuthPage  = /^\/(login|auth(\/.*))(\/|$)/.test(location.pathname)
  const isOnAuthReset = /^\/auth\/reset/.test(location.pathname)
  const isOnRootPage  = location.pathname == null || location.pathname === '/'

  const prevProjectID          = usePrevious(projectID)
  const prevImpersonatedUserID = usePrevious(impersonatedUserID)
  const prevUser               = usePrevious(user)
  const didSwitchProject       = prevProjectID != null && prevProjectID !== projectID
  const didChangeUser          = prevUser !== undefined && prevUser?.email !== user?.email

  //------
  // Auto-redirect

  const getInitialPath = React.useCallback(() => {
    if (impersonatedUserID == null && prevImpersonatedUserID != null) {
      // Special case: when unimpersonating a user, go back to their profile page.
      return `/users/-/${prevImpersonatedUserID}`
    } else if (navItems.length > 0) {
      return navigationItemHref(navItems[0])
    } else if (user?.isSuper()) {
      return '/organisations'
    } else {
      return '/account'
    }
  }, [impersonatedUserID, navItems, prevImpersonatedUserID, user])

  React.useEffect(() => {
    if (!appReady) { return }

    // If the user visits the auth reset page, log them out.
    if (isOnAuthReset && isLoggedIn) {
      authenticationStore.logOut()
    }

    else if (!isOnAuthPage && !isLoggedIn) {
      history.replace('/login')
    }

    else if (isLoggedIn && (isOnAuthPage || isOnRootPage || didSwitchProject || didChangeUser)) {
      history.replace(getInitialPath())
    }
  }, [appReady, didChangeUser, didSwitchProject, getInitialPath, history, isLoggedIn, isOnAuthPage, isOnAuthReset, isOnRootPage, location.pathname])

  //------
  // Rendering

  function render() {
    if (isLoggedIn) {
      return <RootNavigator/>
    } else {
      return <AuthNavigator/>
    }
  }

  return render()

})
export default AppNavigator