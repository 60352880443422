import React from 'react'
import { useTranslation } from 'react-i18next'
import { Challenge, CustomFilter as CustomFilterModel, ModelOfType, Script } from '~/models'
import { Linkage } from '~/stores'
import { deriveQuestionsFromModel, QuestionDescriptor } from '~/stores/answers'
import { observer } from '~/ui/component'
import {
  Checkbox,
  ClearButton,
  HBox,
  Label,
  ListItem,
  SearchableList,
  Spinner,
  VBox,
} from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import { useWidgetFilterValues } from '../WidgetFilterValuesContext'
import { registerDashboardFilter } from './registry'
import WidgetFilter from './WidgetFilter'

import type { WidgetFilterComponentProps } from './types'
export type Props = WidgetFilterComponentProps<AnswersQuestionFilterModel, string[]>

export interface AnswersQuestionFilterModel extends CustomFilterModel {

}

const AnswersQuestionFilter = observer('AnswersQuestionFilter', (props: Props) => {

  const values = useWidgetFilterValues()

  const {alotment, widget, filter, value} = props

  const [t] = useTranslation('analytics')
  const filterRef = React.useRef<WidgetFilter>(null)

  const questionLinkage      = values.getValue<Linkage<any>[]>('model')?.[0]
  const selectedQuestionUUID = values.getValue<string>('question')

  const QuestionModel = (questionLinkage == null ? null : ModelOfType(questionLinkage.type)) as typeof Script | typeof Challenge | null
  const [questionModel, {fetchStatus}] = useModelDocumentData<Script | Challenge>(QuestionModel ?? Script, questionLinkage?.id ?? null)

  const [search, setSearch] = React.useState<string | null>(null)

  const availableQuestions = React.useMemo((): QuestionDescriptor[] => {
    if (questionModel === null) return []
    return deriveQuestionsFromModel(questionModel)
  }, [questionModel])

  const selectedQuestion = React.useMemo(
    () => availableQuestions.find(it => it.uuid === selectedQuestionUUID),
    [availableQuestions, selectedQuestionUUID],
  )

  const selectQuestion = React.useCallback((question: QuestionDescriptor) => {
    values?.setValue(filter, question.uuid)
    filterRef.current?.close()
  }, [filter, values])

  const clear = React.useCallback(() => {
    values?.setToDefault(filter)
    filterRef.current?.close()
  }, [filter, values])

  const onDidClose = React.useCallback(() => {
    setSearch(null)
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <WidgetFilter
        alotment={alotment}
        widget={widget}
        filter={filter}
        value={value}
        renderValue={renderValue}
        renderPopup={renderPopup}
        onDidClose={onDidClose}
        ref={filterRef}
      />
    )
  }

  const renderValue = React.useCallback(() => {
    if (selectedQuestion != null) {
      return selectedQuestion.caption
    } else if (fetchStatus === 'fetching') {
      return <HBox><Spinner size={8}/></HBox>
    } else {
      return <Label dim italic>{t('filters.resource.none')}</Label>
    }
  }, [fetchStatus, selectedQuestion, t])

  const renderItem = React.useCallback((question: QuestionDescriptor) => {
    return (
      <ListItem
        caption={question.caption}
        onTap={selectQuestion.bind(null, question)}
        accessoryLeft={<Checkbox checked={selectedQuestionUUID === question.uuid}/>}
      />
    )
  }, [selectQuestion, selectedQuestionUUID])

  const renderActions = React.useCallback(() => {
    return (
      <VBox>
        <ClearButton
          caption={t('filters.resource.clear')}
          onTap={clear}
          align='center'
          padding='both'
        />
      </VBox>
    )
  }, [clear, t])

  const renderPopup = React.useCallback(() => {
    return (
      <VBox flex='shrink'>
        <SearchableList<QuestionDescriptor>
          contentClassNames={$.listContent}
          data={availableQuestions}
          search={search}
          onSearch={setSearch}
          fetchStatus={fetchStatus}
          renderItem={renderItem}
          flex={false}
        />
        {renderActions()}
      </VBox>
    )
  }, [$.listContent, availableQuestions, fetchStatus, renderActions, renderItem, search])

  return render()

})

registerDashboardFilter('answers:question', AnswersQuestionFilter)

export default AnswersQuestionFilter

const useStyles = createUseStyles({
  headerLabel: {
    padding: [0, layout.padding.inline.m],
  },

  listContent: {
    paddingBottom: layout.padding.inline.m,
  },
})