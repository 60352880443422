import React from 'react'
import { useTranslation } from 'react-i18next'
import { CustomImage, Link } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, TextField, TextFieldProps } from '~/ui/components'
import {
  FieldChangeCallback,
  invokeFieldChangeCallback,
  SubmitResult,
  useFieldChangeCallback,
} from '~/ui/form'
import { useBoolean, usePrevious } from '~/ui/hooks'
import LinkForm from './form/LinkForm'
import LinkFormModel, { LinkType } from './form/LinkFormModel'

export interface Props extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value:    Link | null
  onChange: FieldChangeCallback<Link | null> | ((value: Link | null) => any)

  excludeTypes?: Array<LinkType>
  includeTypes?: Array<LinkType>

  linkForm?:   boolean | 'open'
  setCaption?: (caption: string) => any
  setImage?:   (image: CustomImage | null) => any
}

const LinkField = memo('LinkField', (props: Props) => {

  const {
    linkForm = true,
    accessoryRight,
    value,
    onChange,
    setCaption,
    setImage,
    excludeTypes,
    includeTypes,
    ...rest
  } = props

  const [linkFormOpen, openLinkForm, closeLinkForm] = useBoolean(linkForm === 'open')

  const prevLinkForm = usePrevious(linkForm)
  React.useEffect(() => {
    if (prevLinkForm !== 'open' && linkForm === 'open' && !linkFormOpen) {
      openLinkForm()
    }
  }, [linkForm, linkFormOpen, openLinkForm, prevLinkForm])

  const saveLink = React.useCallback((link: Link | null, caption: string | null, image: CustomImage | null): Promise<SubmitResult> => {
    onChange?.(link)
    if (caption != null) {
      setCaption?.(caption)
    }
    if (image != null) {
      setImage?.(image)
    }
    return Promise.resolve({status: 'ok'})
  }, [onChange, setCaption, setImage])

  const linkFormModel = React.useMemo(
    () => new LinkFormModel(saveLink, value),
    [saveLink, value],
  )

  const handleHrefChange = useFieldChangeCallback(React.useCallback((href: string, partial?: boolean) => {
    const nextValue = Link.to(href, value ?? {})
    invokeFieldChangeCallback(onChange, nextValue, partial)
  }, [onChange, value]))

  const [t] = useTranslation('links')

  //------
  // Rendering

  function render() {
    return (
      <>
        <TextField
          {...rest}
          value={value?.href ?? ''}
          onChange={handleHrefChange}
          accessoryRight={renderAccessoryRight()}
          showClearButton='always'
          mono
        />
        <LinkForm
          open={linkFormOpen}
          requestClose={closeLinkForm}
          model={linkFormModel}
          includeTypes={includeTypes}
          excludeTypes={excludeTypes}
          caption='hide'
        />
      </>
    )
  }

  function renderAccessoryRight() {
    if (!linkForm && accessoryRight == null) { return null }

    return (
      <HBox>
        {renderLinkButton()}
        {accessoryRight}
      </HBox>
    )
  }

  function renderLinkButton() {
    return (
      <ClearButton
        icon='link'
        aria-label={t('form_button')}
        onTap={openLinkForm}
      />
    )
  }

  return render()

})

export default LinkField