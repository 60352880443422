import I18next from 'i18next'
import { get, isArray } from 'lodash'
import { sparse } from 'ytil'
import { Model, resource } from './Model'

import type { DataPoint } from '~/stores'
@resource<Widget>('widgets', {
  icon:    () => 'hexagons',
  caption: widget => widget.translate('caption', widget.name),
})
export class Widget extends Model {

  public name!:         string
  public type!:         WidgetType
  public preview!:      WidgetPreviewType

  public filters!: {
    dashboard: WidgetFilter[]
    report:    WidgetFilter[]
  }

  //------
  // Translations

  public translations!: Record<string, any>

  public translate(key: string | string[], defaultValue: string): string
  public translate(key: string | string[], defaultValue?: string | null): string | null
  public translate(key: string | string[], defaultValue?: string | null) {
    const language = I18next.language
    const keys     = isArray(key) ? key : [key]

    for (const it of keys) {
      const value = get(this.translations[language] ?? this.translations.en, it)
      if (typeof value === 'string') { return value }
    }

    return defaultValue !== undefined ? defaultValue : keys[0]
  }

  public translateFilter(filter: string, key: string | string[]) {
    const keys = isArray(key) ? key : [key]

    // For filter related values, try the widget, but fall back to application wide translations.
    return this.translate(keys.map(it => `filters.${filter}.${it}`), null) ??
      I18next.t(keys.map(it => `analytics:filters.${filter}.${it}`))
  }

  public filterCaption(filter: string, short: boolean = false) {
    return this.translateFilter(filter, short ? ['caption_short', 'caption'] : 'caption')
  }

  public label(point: DataPoint) {
    return this.translate(sparse([
      point.label && `labels.${point.label}`,
      point.axisLabel && `axis_labels.${point.axisLabel}`,
    ]), point.label ?? point.axisLabel ?? null)
  }

}

export type WidgetType        = 'gauge' | 'donut' | 'bar-chart' | 'chart' | 'funnel'
export type WidgetPreviewType = 'gauge' | 'donut' | 'line-chart' | 'column-chart' | 'funnel'
export type WidgetOutput      = 'dashboard' | 'report'

export type WidgetFilter =
  | EnumFilter
  | ResourceFilter
  | TimeframeFilter
  | CustomFilter

export interface WidgetFilterCommon<T> {
  name:         string
  persistent:   boolean
  defaultValue: T
}

export interface EnumFilter extends WidgetFilterCommon<string> {
  type: 'enum'
  enum: string[]
}

export interface ResourceFilter extends WidgetFilterCommon<string[]> {
  type:     'resource'
  resource: string | string[]
  multi:    boolean
  empty:    'all' | 'none'
}

export interface TimeframeFilter extends WidgetFilterCommon<Timeframe> {
  type:      'timeframe'
  lookbacks: TimeframeLookback[]
}

export interface Timeframe {
  lookback: TimeframeLookback
}

export type TimeframeLookback =
  | '6hr' | '24hr' | '48hr'
  | '1wk' | '2wk'
  | '1mth' | '2mth' | '6mth' | '12mth'


export interface CustomFilter extends WidgetFilterCommon<any> {
  type:       'custom'
  customType: string
}
