import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Channel, Group, supportsModeration } from '~/models'
import { observer } from '~/ui/component'
import { Center, HBox, Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChip } from '~/ui/resources/components'
import { EditFormProps, ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout, useStyling } from '~/ui/styling'
import ChannelForm from '../ChannelForm'
import ChannelAccess from './ChannelAccess'
import ChannelBody from './ChannelBody'
import ChannelBots from './ChannelBots'
import ChannelModeration from './ChannelModeration'
import ChannelTargeting from './ChannelTargeting'

export type Props = RouteComponentProps<ResourceDetailParams>

const ChannelScreen = observer('ChannelScreen', (props: Props) => {

  const {t} = useResourceTranslation('channels')
  const {colors} = useStyling()

  const channelID = props.match.params.id
  const [channel] = useModelDocumentData(Channel, channelID, {fetch: 'never'})

  const TypedChannelForm = React.useMemo((): React.ComponentType<EditFormProps<Channel>> => {
    return props => (
      <ChannelForm
        {...props}
        channel={props.model}
        type={channel?.type ?? 'participant'}
      />
    )
  }, [channel?.type])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={Channel}
        id={channelID}
        include={['bots', 'group']}

        renderType={channel => t(`types.${channel.type}.caption`)}
        renderTags={renderGroup}
        renderInfo={renderInfo}
        renderBody={renderBody}
        renderSummaryFooter={renderSummaryFooter}
        EditFormComponent={TypedChannelForm}
      />
    )
  }

  //------
  // Body

  function renderBody() {
    if (channel == null) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    } else {
      return (
        <ChannelBody
          channel={channel}
        />
      )
    }
  }

  //------
  // Right bar

  function renderGroup(channel: Channel) {
    if (channel.type !== 'group' || channel.group == null) { return null }

    return (
      <HBox wrap gap={layout.padding.inline.s}>
        <ResourceChip
          Model={Group}
          id={channel.group}
          flex='shrink'
          small
        />
      </HBox>
    )
  }

  function renderInfo(channel: Channel) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item
          summary={t('created', {when: channel.createdAt})}
          markup
        />
        {channel.pinIndex != null && (
          <SummaryInfo.Item
            icon='pin'
            summary={t('pin_index', {index: channel.pinIndex})}
            markup
          />
        )}
        {channel.app && (
          <SummaryInfo.Item
            icon='iphone'
            summary={t('app')}
            markup
            color={colors.semantic.secondary}
          />
        )}
        {channel.web && (
          <SummaryInfo.Item
            icon='web'
            summary={t('web')}
            markup
            color={colors.semantic.secondary}
          />
        )}
      </SummaryInfo>
    )
  }

  function renderSummaryFooter(channel: Channel) {
    return [
      channel.type === 'participant' && <ChannelTargeting key='targeting' channel={channel}/>,
      <ChannelAccess key='open' channel={channel}/>,
      <ChannelBots key='bots' channel={channel}/>,
      supportsModeration(channel.type) && <ChannelModeration key='moderation' channel={channel}/>,
    ]
  }

  return render()

})

export default ChannelScreen