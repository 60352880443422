import React from 'react'
import { CalendarDay, CalendarItem } from '~/models'
import { observer } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import CalendarDayContentItem from './CalendarDayContentItem'
import { dayHeight } from './layout'

export interface Props {
  day:       CalendarDay
  transform: string
}

const CalendarDayContentLayer = observer('CalendarDayContentLayer', (props: Props) => {

  const {day, transform} = props

  const {planner} = useCalendarPlanner()
  const items = planner.visibleItemsForDay(day) ?? []

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.calendarDayContentLayer}>
        <div classNames={$.layer} style={{transform}}>
          {items.map(renderItem)}
        </div>
      </div>
    )
  }

  function renderItem(item: CalendarItem) {
    return (
      <CalendarDayContentItem
        key={item.uuid}
        day={day}
        item={item}
      />
    )
  }

  return render()

})

export default CalendarDayContentLayer

const useStyles = createUseStyles({
  calendarDayContentLayer: {
    ...layout.overlay,
    overflow:      'hidden',
    pointerEvents: 'none',
  },

  layer: {
    position: 'absolute',
    left:     0,
    right:    0,
    top:      0,
    height:   dayHeight(),

    willChange:      'transform',
    transformOrigin: [0, 0],
  },
})