import { makeObservable, observable } from 'mobx'
import { RegistrationPreset } from '~/models'
import { ResourceFormModel } from '~/ui/resources/form'

export default class ValidationFormModel extends ResourceFormModel<RegistrationPreset> {

  constructor(Model: typeof RegistrationPreset, model: RegistrationPreset | null) {
    super(Model, model)
    makeObservable(this)
  }

  @observable
  public originValidationEnabled: boolean = (this.model?.allowedOrigins ?? []).length > 0

  @observable
  public allowedOrigins: string[] = this.model?.allowedOrigins ?? []

  @observable
  public emailValidationEnabled: boolean = (this.model?.allowedEmailPatterns ?? []).length > 0

  @observable
  public allowedEmailPatterns: string[] = this.model?.allowedEmailPatterns ?? []

  protected override buildData() {
    return {
      allowedOrigins:       this.originValidationEnabled ? this.allowedOrigins : [],
      allowedEmailPatterns: this.emailValidationEnabled ? this.allowedEmailPatterns : [],
    }
  }

}