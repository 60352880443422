import React from 'react'
import { WidgetScriptMessage } from '~/models'
import renderWidget, { WidgetsContext } from '~/ui/app/widgets'
import { memo } from '~/ui/component'
import { useScriptEditor } from '../ScriptEditorContext'
import { isNewScriptMessage } from '../data'
import { NewScriptMessage } from '../types'
import ScriptMessageBubble from './ScriptMessageBubble'

export interface Props {
  message: WidgetScriptMessage | NewScriptMessage
}

const WidgetScriptMessageBubble = memo('WidgetScriptMessageBubble', (props: Props) => {

  const {message} = props

  const {saveMessage} = useScriptEditor()

  const widget = isNewScriptMessage(message) ? null : message.widget
  const [params, setParams] = React.useState<Record<string, any>>(isNewScriptMessage(message) ? {} : message.params)

  //------
  // Configuration

  const updateParams = React.useCallback((_, params: Record<string, any>) => {
    setParams(params)
  }, [setParams])

  const save = React.useCallback(() => {
    saveMessage?.(message.uuid, {
      type:   'widget',
      widget: widget,
      params: params,
    })
  }, [message.uuid, widget, params, saveMessage])

  const widgetsContext = React.useMemo((): WidgetsContext => ({
    setParams:            updateParams,
    endConfiguringWidget: save,
  }), [updateParams, save])

  //------
  // Rendering

  function render() {
    return(
      <ScriptMessageBubble
        message={message}
        renderEditing={renderWidgetBlock}
        renderViewing={renderWidgetBlock}
        requestSave={save}
      />
    )
  }

  const renderWidgetBlock = React.useCallback(() => {
    if (widget == null ) { return null }
    return (
      <WidgetsContext.Provider value={widgetsContext}>
        {renderWidget(widget, params)}
      </WidgetsContext.Provider>
    )
  }, [widget, widgetsContext, params])

  return render()

})

export default WidgetScriptMessageBubble