import React from 'react'
import { Trigger } from '~/models'
import { Dimple, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
// @index: import ${variable} from ${relpath}
import ChallengeTriggerFields from './ChallengeTriggerFields'
import CommonTriggerFields from './CommonTriggerFields'
import DateTriggerFields from './DateTriggerFields'
import GroupTriggerFields from './GroupTriggerFields'
import LocationTriggerFields from './LocationTriggerFields'
import ScriptTriggerFields from './ScriptTriggerFields'
import WebhookTriggerFields from './WebhookTriggerFields'

// /index

export default function renderTriggerFields(type: Trigger['type'] | 'other') {
  const specificFields = renderTriggerSpecificFields(type)

  if (specificFields == null) {
    return <CommonTriggerFields/>
  } else {
    return (
      <VBox gap={layout.padding.s}>
        {specificFields}
        <Dimple horizontal/>
        <CommonTriggerFields/>
      </VBox>
    )
  }
}

function renderTriggerSpecificFields(type: Trigger['type'] | 'other') {
  switch (type) {
    case 'date':
      return <DateTriggerFields/>

    case 'group:join':
    case 'group:leave':
      return <GroupTriggerFields/>

    case 'script:end':
      return <ScriptTriggerFields/>

    case 'challenge:published':
    case 'challenge:abandoned':
    case 'challenge:completed':
    case 'challenge:reviewed':
      return <ChallengeTriggerFields/>

    case 'location:enter':
    case 'location:exit':
      return <LocationTriggerFields/>

    case 'webhook':
      return <WebhookTriggerFields/>

    default:
      return null
  }
}