import React from 'react'
import { projectStore } from '~/stores'
import { DynamicField, DynamicFieldset, StringField } from '~/ui/app/dynamic-form'
import { LocalizedTextField, RichTextField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { EmailSenderField, HBox, Panel, TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import { PlannerActionFieldsetProps, register } from './registry'

export type Props = PlannerActionFieldsetProps

const EmailFields = observer('EmailFields', (props: Props) => {

  const {schema} = props
  const projectName = projectStore?.project?.name

  const fromField    = schema.find(field => field.name === 'from') as DynamicField
  const subjectField = schema.find(field => field.name === 'subject') as StringField
  const bodyField    = schema.find(field => field.name === 'body') as StringField
  const otherFields  = schema.filter(field => !['subject', 'body', 'from'].includes(field.name))

  const fromSubFieldProps = React.useMemo(() => ({
    name: {
      placeholder: projectName,
    },
    address: {
      placeholder: 'noreply@groundcontrol.app',
    },
  }), [projectName])

  //------
  // Rendering

  function render() {
    return (
      <HBox style={{height: 480}} align='stretch' gap={layout.padding.s}>
        <VBox flex={2} gap={layout.padding.s}>
          {renderOtherFields()}
          {renderFromField()}
        </VBox>
        <VBox flex={3}>
          {renderBodyFields()}
        </VBox>
      </HBox>
    )
  }

  //-------
  // Other


  function renderOtherFields() {
    return (
      <DynamicFieldset schema={otherFields}/>
    )
  }

  //------
  // From


  function renderFromField() {
    return(
      <FormField name='from' caption={fromField.caption}>
        {bind => (
          <Panel padding={layout.padding.xs}>
            <EmailSenderField {...bind} fieldProps={fromSubFieldProps} />
          </Panel>
        )}
      </FormField>
    )
  }

  //------
  // Body


  function renderBodyFields() {
    return (
      <VBox flex gap={layout.padding.s}>
        <FormField name='subject' caption={subjectField.caption!}>
          {bind => subjectField.localized ? (
            <LocalizedTextField {...bind}/>
          ) : (
            <TextField {...bind}/>
          )}
        </FormField>
        <FormField flex name='body' caption={bodyField.caption!} renderAsLabel={false}>
          {bind => (
            <RichTextField
              {...bind}
              value={bind.value ?? ''}
              localized={bodyField.localized}
              allowedNodes={['em', 'strong', 'heading', 'variable', 'link', 'list']}
              flex
            />
          )}
        </FormField>
      </VBox>
    )
  }

  return render()

})

register('external:email', EmailFields)
register('participant:email', EmailFields)

export default EmailFields