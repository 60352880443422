import React from 'react'
import { Challenge } from '~/models'
import { memo } from '~/ui/component'
import { FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import ChallengeFields from './detail/ChallengeFields'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  model?: Challenge | null
}

const ChallengeForm = memo('ChallengeForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props
  const isNew = model == null

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Challenge}
        model={model}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={<ChallengeFields moduleField={isNew}/>}
      />
    )
  }

  return render()

})

export default ChallengeForm