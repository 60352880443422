import React from 'react'
import { useTranslation } from 'react-i18next'
import { ImportColumn, ImportSession } from 'sheet-importer'
import { observer } from '~/ui/component'
import { DataGrid, HBox, Label, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import SVG from '~/ui/components/SVG'
import { layout } from '~/ui/styling'
import ImportFieldSelector from './ImportFieldSelector'

export interface Props {
  session: ImportSession

  fieldCaption: (key: string) => string
  fieldChoice?: (key: string) => Omit<Choice<any>, 'value' | 'children'>
}

const ImportPreview = observer('ImportPreview', (props: Props) => {

  const {session} = props
  const [t] = useTranslation('importer')

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <DataGrid data={session.previewData}>
          {session.columns.map(renderColumn)}
        </DataGrid>
        {renderTotals()}
      </VBox>
    )
  }

  function renderColumn(column: ImportColumn) {
    return (
      <DataGrid.Column<any[]>
        key={column.name}
        name={column.name}
        caption={column.name}
        renderCell={item => renderCell(item, column.index)}
        renderHeaderAccessoryCell={renderFieldSelector.bind(null, column)}
        minWidth={220}
      />
    )
  }

  function renderCell(item: any[], index: number) {
    const value = item[index]

    if (typeof value === 'boolean') {
      return (
        <HBox justify='center'>
          <SVG name={value ? 'check' : 'empty'} size={layout.icon.m}/>
        </HBox>
      )
    } else if (value != null) {
      return value.toString()
    } else {
      return (
        <Label dim>
          {t('preview.empty')}
        </Label>
      )
    }
  }

  function renderFieldSelector(column: ImportColumn) {
    return (
      <ImportFieldSelector
        {...props}
        column={column}
      />
    )
  }

  function renderTotals() {
    return (
      <Label small dim>
        {t('preview.totals', {count: session.previewData.length, total: session.data.length})}
      </Label>
    )
  }

  return render()

})

export default ImportPreview