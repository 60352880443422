import React from 'react'
import Toast from 'react-toast'
import { Group, Participant } from '~/models'
import { authenticationStore, BulkSelector, dataStore } from '~/stores'
import { GroupField } from '~/ui/app/fields'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { ConfirmBox, DataGrid, HBox, Label, SelectField, TimeAgoLabel } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, ResourceFilter, ResourceListScreen } from '~/ui/resources/collection'
import { ResourceChipRow } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import ParticipantImportDialog from '../importer/ParticipantImportDialog'
import ParticipantForm from '../ParticipantForm'
import ResetParticipantForm from '../ResetParticipantForm'

const ParticipantsScreen = memo('ParticipantsScreen', () => {

  const {t} = useResourceTranslation('participants')
  const user = authenticationStore.user

  const loginFilterChoices = React.useMemo((): Choice<boolean | null>[] => [{
    value: null,
    caption: t(['filters.logged_in.unspecified', 'filters._common.unspecified']),
  }, {
    value: true,
    caption: t('filters.logged_in.logged_in'),
  }, {
    value: false,
    caption: t('filters.logged_in.not_logged_in'),
  }], [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Participant}
        include={['groups']}
        CreateFormComponent={ParticipantForm}
        ImportDialog={ParticipantImportDialog}
        bulkActions={bulkActions}
        defaultSort='firstName'
        allowCreate={user?.isEditor()}
        allowImport={user?.isEditor()}
        allowRemove={user?.isEditor()}
      >
        <DataGrid.Column<Participant>
          name='name'
          sort='firstName'
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column
          name='groups'
          renderCell={renderGroups}
          flex={2}
        />
        <DataGrid.Column
          name='lastLogin'
          sort='lastLoginAt'
          renderCell={renderLastLogin}
          flex={1}
        />

        <ResourceListScreen.Filters>
          <ResourceFilter name='groups'>
            {bind => <GroupField {...bind} multi inputStyle='dark' smallChips/>}
          </ResourceFilter>
          <ResourceFilter name='loggedIn'>
            {bind => <SelectField {...bind} inputStyle='dark' choices={loginFilterChoices}/>}
          </ResourceFilter>
        </ResourceListScreen.Filters>

        {renderResetForm()}
      </ResourceListScreen>
    )
  }

  function renderName(participant: Participant) {
    return (
      <HBox gap={layout.padding.inline.l}>
        <Avatar
          source={participant.photoURL}
          {...participant}
          size={avatarSize}
        />
        <Label flex h3>
          {participant.name}
        </Label>
      </HBox>
    )
  }

  function renderGroups(participant: Participant) {
    return (
      <ResourceChipRow
        Model={Group}
        ids={participant.groups}
      />
    )
  }

  function renderLastLogin(participant: Participant) {
    if (participant.lastLoginAt == null) {
      return (
        <Label light dim italic>
          {t('last_login.never')}
        </Label>
      )
    } else {
      return (
        <TimeAgoLabel
          datetime={participant.lastLoginAt}
        />
      )
    }
  }

  //------
  // Reset action

  const [resetFormOpen, openResetForm, closeResetForm] = useBoolean()
  const [bulkSelector, setBulkSelector] = React.useState<BulkSelector | null>(null)

  const reset = React.useCallback((_, selector: BulkSelector) => {
    openResetForm()
    setBulkSelector(selector)
  }, [openResetForm])

  const resetPassword = React.useCallback(async (_, selector: BulkSelector) => {
    const confirmed = await ConfirmBox.show({
      title:   t('actions.reset_pin_bulk.confirm.title'),
      message: t('actions.reset_pin_bulk.confirm.message'),
    })
    if (!confirmed) {return }

    const endpoint = dataStore.endpoint(Participant)
    const result = await endpoint.callBulkAction('auth-reset:request', selector)
    if (result.status !== 'ok') { return }

    const count = result.meta?.count ?? 0
    Toast.show({
      type:   'success',
      title:  t('actions.reset_pin_bulk.success.title'),
      detail: t(`actions.reset_pin_bulk.success.${count > 1 ? 'detail_plural' : 'detail'}`, {count}),
    })
  }, [t])

  function renderResetForm() {
    if (bulkSelector == null) { return null }

    return (
      <ResetParticipantForm
        open={resetFormOpen}
        requestClose={closeResetForm}
        selector={bulkSelector}
      />
    )
  }

  const bulkActions = React.useMemo((): BulkAction[] => [{
    name:    'reset_bulk',
    icon:    'reset',
    execute: reset,
  }, {
    name:    'reset_pin_bulk',
    icon:    'reset',
    execute: resetPassword,
  }], [reset, resetPassword])

  return render()

})

export const avatarSize = {
  width:  40,
  height: 40,
}

export default ParticipantsScreen