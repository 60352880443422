import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectionManager } from 'react-selection-manager'
import { observer } from '~/ui/component'
import { Center, ClearButton, VBox } from '~/ui/components'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  requestAddSelected:    () => any
  requestAddAll:         () => any
  requestRemoveSelected: () => any
  requestRemoveAll:      () => any

  availableSelectionManager: SelectionManager<string>
  includedSelectionManager: SelectionManager<string>

  working: boolean
}

const CollectionEditorActions = observer('CollectionEditorActions', (props: Props) => {

  const {
    requestAddSelected,
    requestAddAll,
    requestRemoveSelected,
    requestRemoveAll,
    availableSelectionManager,
    includedSelectionManager,
    working,
  } = props

  const [t] = useTranslation('collection_editor')

  //------
  // Rendering

  const {colors} = useStyling()

  function render() {
    return (
      <VBox align='center' justify='middle' gap={layout.padding.l}>
        {renderAddActions()}
        {renderRemoveActions()}
      </VBox>
    )
  }

  function renderAddActions() {
    return (
      <Center gap={layout.padding.s}>
        <ClearButton
          icon='chevron-right'
          caption={t('actions.add')}
          onTap={requestAddSelected}
          enabled={!working && availableSelectionManager.hasSelection}
          iconSide='right'
          color={colors.semantic.positive}
        />
        <ClearButton
          icon='double-chevron-right'
          caption={t('actions.add_all')}
          onTap={requestAddAll}
          enabled={!working && availableSelectionManager.available.size > 0}
          iconSide='right'
          color={colors.semantic.positive}
        />
      </Center>
    )
  }

  function renderRemoveActions() {
    return (
      <Center gap={layout.padding.s}>
        <ClearButton
          icon='chevron-left'
          caption={t('actions.remove')}
          onTap={requestRemoveSelected}
          enabled={!working && includedSelectionManager.hasSelection}
          color={colors.semantic.negative}
        />
        <ClearButton
          icon='double-chevron-left'
          caption={t('actions.remove_all')}
          onTap={requestRemoveAll}
          enabled={!working && includedSelectionManager.available.size > 0}
          color={colors.semantic.negative}
        />
      </Center>
    )
  }

  return render()

})

export default CollectionEditorActions