import React from 'react'
import { SelectionManager } from 'react-selection-manager'
import { Model } from '~/models'
import { ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { List, SearchField, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import CollectionEditorItemBar from './CollectionEditorItemBar'

export interface Props<M extends Model> {
  endpoint:         ModelEndpoint<M>
  selectionManager: SelectionManager<string | null>
  onSearchCommit:   () => any
}

const _CollectionEditorList = <M extends Model>(props: Props<M>) => {

  const {
    endpoint,
    selectionManager,
    onSearchCommit,
  } = props

  const search = React.useCallback((search: string | null) => {
    endpoint.setParams({search})
  }, [endpoint])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchMore()
  }, [endpoint])

  const commitSearch = React.useCallback(() => {
    if (endpoint.meta?.count !== 1) { return }
    onSearchCommit()
  }, [endpoint.meta?.count, onSearchCommit])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.collectionEditorList}>
        {renderList()}
        {renderSearch()}
      </VBox>
    )
  }

  function renderList() {
    return (
      <List<M>
        data={endpoint.data}
        renderItem={renderBar}
        keyExtractor={keyExtractor}
        itemGap={layout.padding.inline.s}
        onEndReached={fetchMore}
        contentClassNames={$.listContent}
        scrollable
        flex
      />
    )
  }

  function renderSearch() {
    return (
      <SearchField
        onSearch={search}
        onCommit={commitSearch}
      />
    )
  }

  const keyExtractor = React.useCallback(
    (model: M) => model.id,
    [],
  )

  const renderBar = React.useCallback(
    (model: M) => <CollectionEditorItemBar model={model} selectionManager={selectionManager}/>,
    [selectionManager],
  )

  return render()

}

const CollectionEditorList = observer('CollectionEditorList', _CollectionEditorList) as typeof _CollectionEditorList
export default CollectionEditorList

const useStyles = createUseStyles(theme => ({
  collectionEditorList: {
    background:   theme.bg.subtle,
    borderRadius: layout.radius.l,
  },

  listContent: {
    padding: layout.padding.inline.m,
  },
}))