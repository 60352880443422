import { makeObservable, observable } from 'mobx'
import { GroupSignup } from '~/models'
import { ResourceFormModel } from '~/ui/resources/form'

export default class GroupSignupFormModel extends ResourceFormModel<GroupSignup> {

  constructor(signup: GroupSignup | null) {
    super(GroupSignup, signup)

    makeObservable(this)
  }

  @observable
  public deadlineType: GroupSignupDeadlineType = deriveDeadlineType(this.model)

  protected override buildData() {
    const data = super.buildData()
    if (this.deadlineType !== 'explicit') {
      data.deadline = null
    }
    if (this.deadlineType !== 'variable') {
      data.deadlineVariable = null
    }

    return data
  }

}

export type GroupSignupDeadlineType = 'none' | 'explicit' | 'variable'

function deriveDeadlineType(signup: GroupSignup | null) {
  if (signup == null) { return 'none' }

  if (signup.deadline != null) {
    return 'explicit'
  } else if (signup.deadlineVariable != null) {
    return 'variable'
  } else {
    return 'none'
  }
}