import React from 'react'
import ScoringForm, { Props as ScoringFormProps } from '~/ui/app/competitions/scoring/ScoringForm'
import { memo } from '~/ui/component'
import ChallengeScoringFormModel from './ChallengeScoringFormModel'

export interface Props extends Omit<ScoringFormProps, 'model'> {
  formModel: ChallengeScoringFormModel
}

const ChallengeScoringForm = memo('ChallengeScoringForm', (props: Props) => {

  const {formModel, ...rest} = props

  return (
    <ScoringForm
      {...rest}
      model={formModel}
      competitionField={false}
    />
  )

})

export default ChallengeScoringForm