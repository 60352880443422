import { clamp } from 'lodash'
import { DateTime } from 'luxon'

export class TimeOfDay {

  constructor(
    from: number | TimeOfDay,
  ) {
    if (typeof from === 'number') {
      this.minutes = clamp(from, 0, 24 * 60 - 1)
    } else {
      this.minutes = clamp(from.minutes, 0, 24 * 60 - 1)
    }
  }

  public readonly minutes: number

  public static ZERO = new TimeOfDay(0)
  public static MAX  = new TimeOfDay(24 * 60 - 1)

  public static isTimeOfDay(arg: any): arg is TimeOfDay {
    return arg instanceof TimeOfDay
  }

  public static now() {
    return this.fromDateTime(DateTime.local())
  }

  //------
  // Conversion

  public static fromDateTime(dateTime: DateTime) {
    const midnight = dateTime.startOf('day')
    const minutes  = Math.floor(dateTime.diff(midnight, 'minutes').minutes)
    return new TimeOfDay(minutes)
  }

  public toDateTime() {
    return DateTime.utc().set({
      hour:   Math.floor(this.minutes / 60),
      minute: this.minutes % 60,
    })
  }

  //------
  // Operations

  public add(minutes: number) {
    return new TimeOfDay(this.minutes + minutes)
  }

  public roundTo(unit: number | 'hour' | 'day') {
    const quantity = typeof unit === 'number' ? unit : unit === 'hour' ? 60 : 24 * 60
    const minutes  = Math.round(this.minutes / quantity) * quantity
    return new TimeOfDay(minutes)
  }

  public floorTo(unit: 'hour' | 'day') {
    const quantity = unit === 'hour' ? 60 : 24 * 60
    const minutes = Math.floor(this.minutes / quantity) * quantity
    return new TimeOfDay(minutes)
  }

  //------
  // Compare

  public static min(first: TimeOfDay, ...rest: TimeOfDay[]) {
    let min = first
    for (const time of rest) {
      if (time.minutes < min.minutes) {
        min = time
      }
    }
    return min
  }

  public static max(first: TimeOfDay, ...rest: TimeOfDay[]) {
    let max = first
    for (const time of rest) {
      if (time.minutes > max.minutes) {
        max = time
      }
    }
    return max
  }

  //------
  // Output

  public toString() {
    return this.toFormat('H:mm')
  }

  public toFormat(format: string) {
    const dateTime = DateTime.local().startOf('day').plus({minutes: this.minutes})
    return dateTime.toFormat(format)
  }

  public [Symbol.toPrimitive]() {
    return this.toString()
  }

  public valueOf() {
    return this.toString()
  }

}