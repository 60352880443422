import React from 'react'
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx'
import { CalendarDay, CalendarItem, TimeOfDay } from '~/models'
import { CalendarPlanner } from '~/stores'
import { observer } from '~/ui/component'
import { useCalendarPlanner } from './CalendarPlannerContext'

export interface SelectionContext {
  selectedUUIDs:   string[]
  selectedItems:   CalendarItem[]
  selectionBounds: SelectionBounds | null
  manager:         SelectionManager | null
}

export interface SelectionBounds {
  base:   SelectionExtent
  extent: SelectionExtent
}

export interface SelectionExtent {
  point: Point
  time:  TimeOfDay
}

export class SelectionManager {

  constructor() {
    makeObservable(this)
    this.reactionDisposer = reaction(
      () => this.selectionBounds,
      bounds => {
        if (bounds == null) { return }
        this.selectAllInBounds(bounds)
      },
    )
  }

  private reactionDisposer?: IReactionDisposer

  public dispose() {
    this.reactionDisposer?.()
  }

  @observable
  public planner: CalendarPlanner | null = null

  @action
  public setPlanner(planner: CalendarPlanner | null) {
    this.planner = planner
  }

  private get plan() {
    return this.planner?.plan ?? null
  }

  @observable
  private selectedUUIDSet = new Set<string>()

  @computed
  public get selectedUUIDs() {
    return [...this.selectedUUIDSet]
  }

  @computed
  public get selectedItems() {
    const selectedItems: CalendarItem[] = []
    for (const uuid of this.selectedUUIDs) {
      const item = this.plan?.findItem(uuid)
      if (item != null) {
        selectedItems.push(item)
      }
    }
    return selectedItems
  }

  @observable
  public selectionBounds: SelectionBounds | null = null

  //------
  // Interface

  @action
  public select(...uuids: string[]) {
    for (const uuid of uuids) {
      this.selectedUUIDSet.add(uuid)
    }
  }

  @action
  public deselect(...uuids: string[]) {
    for (const uuid of uuids) {
      this.selectedUUIDSet.delete(uuid)
    }
  }

  @action
  public selectOnly(...uuids: string[]) {
    this.selectedUUIDSet.clear()
    this.select(...uuids)
  }

  @action
  public toggle(uuid: string) {
    if (this.selectedUUIDSet.has(uuid)) {
      this.selectedUUIDSet.delete(uuid)
    } else {
      this.selectedUUIDSet.add(uuid)
    }
  }

  @action
  public selectAll() {
    const allItems = this.planner?.plan?.getAllItems() ?? []
    this.selectedUUIDSet = new Set(allItems.map(it => it.uuid))
  }

  @action
  public deselectAll() {
    this.selectedUUIDSet.clear()
  }

  @action
  public extendSelectionBounds(extent: SelectionExtent) {
    if (this.selectionBounds == null) {
      this.selectionBounds = {
        base:   extent,
        extent: extent,
      }
    } else {
      this.selectionBounds = {
        base:   this.selectionBounds.base,
        extent: extent,
      }
    }
  }

  @action
  public clearSelectionBounds() {
    this.selectionBounds = null
  }

  @action
  private selectAllInBounds(bounds: SelectionBounds) {
    const min = TimeOfDay.min(bounds.base.time, bounds.extent.time).minutes
    const max = TimeOfDay.max(bounds.base.time, bounds.extent.time).minutes

    this.selectedUUIDSet.clear()
    for (const day of this.daysInSelectionBounds(bounds)) {
      for (const item of day.items) {
        if (item.time.minutes > max) { continue }
        if (item.time.minutes + 60 < min) { continue }

        this.selectedUUIDSet.add(item.uuid)
      }
    }
  }

  //------
  // Days

  private dayBodyRects = new Map<string, LayoutRect>()

  public setDayBodyRect(uuid: string, rect: LayoutRect) {
    this.dayBodyRects.set(uuid, rect)
  }

  public deleteDayBodyRect(uuid: string) {
    this.dayBodyRects.delete(uuid)
  }

  public dayAtPoint(point: Point): CalendarDay | null {
    for (const [uuid, rect] of this.dayBodyRects) {
      if (rect.left > point.x || rect.left + rect.width < point.x) { continue }
      return this.plan?.findDay(uuid) ?? null
    }

    return null
  }

  public daysInSelectionBounds(bounds: SelectionBounds) {
    const left  = Math.min(bounds.base.point.x, bounds.extent.point.x)
    const right = Math.max(bounds.base.point.x, bounds.extent.point.x)

    const days: CalendarDay[] = []
    for (const [uuid, rect] of this.dayBodyRects) {
      if (rect.left > right || rect.left + rect.width < left) { continue }

      const day = this.plan?.findDay(uuid)
      if (day == null) { continue }

      days.push(day)
    }
    return days
  }

}

export const defaultContext: SelectionContext = {
  selectedUUIDs:   [],
  selectedItems:   [],
  selectionBounds: null,
  manager:         null,
}

export const SelectionContext = React.createContext<SelectionContext>(defaultContext)

export function useSelection(): SelectionContext {
  return React.useContext(SelectionContext)
}

export interface SelectionContextProviderProps {
  children?: React.ReactNode
}

export const SelectionContextProvider = observer('SelectionContextProvider', (props: SelectionContextProviderProps) => {
  const {children} = props

  const manager = React.useMemo(() => new SelectionManager(), [])

  const {planner} = useCalendarPlanner()
  if (planner != null) {
    manager.setPlanner(planner)
  }

  React.useEffect(() => {
    return () => {
      manager?.dispose()
    }
  }, [manager])

  const selectedUUIDs   = manager.selectedUUIDs
  const selectedItems   = manager.selectedItems
  const selectionBounds = manager.selectionBounds

  const context = React.useMemo((): SelectionContext => ({
    selectedUUIDs,
    selectedItems,
    selectionBounds,
    manager,
  }), [manager, selectedItems, selectedUUIDs, selectionBounds])

  //------
  // Render

  return (
    <SelectionContext.Provider value={context}>
      {children}
    </SelectionContext.Provider>
  )
})