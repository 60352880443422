import { Model, resource } from './Model'
import { Module } from './Module'
import { Ref } from './types'
import { User } from './User'

@resource<Dashboard>('dashboards', {
  icon:      'gauge',
  caption:   dashboard => dashboard.name,
  hasDetail: dashboard => dashboard.widgets != null,
})
export class Dashboard extends Model {

  public module!:  Ref<Module>
  public user!:    Ref<User>
  public name!:    string
  public tags!:    string[]
  public default!: boolean
  public shared!:  boolean
  public widgets?: WidgetAlotment[]

}

export interface WidgetAlotment {
  uuid:    string
  x:       number
  y:       number
  width:   number
  height:  number
  widget:  string
  filters: Record<string, any>
}
