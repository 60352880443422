import React from 'react'
import { omit } from 'lodash'
import clipboard, { useClipboardQuery } from 'rich-clipboard'
import { arrayMove } from 'ytil'
import { ClipboardType } from '~/clipboard'
import { useTimer } from 'react-timer'
import { Challenge, ChallengeTask } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

interface ChallengeTasksEditorContext {
  challenge:        Challenge | null
  tasks:            ChallengeTask[]
  tasksInClipboard: ChallengeTask[]

  updateTasks: (tasks: ChallengeTask[]) => Promise<SubmitResult | undefined>
  removeTasks: (uuids: string[]) => Promise<SubmitResult | undefined>
  copyTasks:   (uuids: string[]) => void
  pasteTasks:  (atIndex?: number) => Promise<SubmitResult | undefined>
  updateTask:  (uuid: string, data: AnyObject) => Promise<SubmitResult | undefined>
  removeTask:  (uuid: string) => Promise<SubmitResult | undefined>
  moveTask:    (uuid: string, index: number) => Promise<SubmitResult | undefined>
}

const ChallengeTasksEditorContext = React.createContext<ChallengeTasksEditorContext>({
  challenge:      null,
  tasks:            [],
  tasksInClipboard: [],

  updateTasks: () => Promise.resolve(void 0),
  removeTasks: () => Promise.resolve(void 0),
  copyTasks:   () => void 0,
  pasteTasks:  () => Promise.resolve(void 0),
  updateTask:  () => Promise.resolve(void 0),
  removeTask:  () => Promise.resolve(void 0),
  moveTask:    () => Promise.resolve(void 0),
})


export interface ChallengeTasksEditorContextProviderProps {
  challenge: Challenge
  children?: React.ReactNode
}

const ChallengeTasksEditorContextProvider = observer('ChallengeTasksEditorContextProvider', (props: ChallengeTasksEditorContextProviderProps) => {
  const {challenge, children} = props

  const originalTasks = React.useMemo(() => challenge.tasks ?? [], [challenge.tasks])
  const [tasks, setTasks] = React.useState<ChallengeTask[]>(originalTasks)

  const [tasksClipboardQuery, tasksPreview] = useClipboardQuery<ChallengeTask[]>(ClipboardType.CHALLENGE_TASKS)
  const tasksInClipboard = React.useMemo(() => tasksPreview?.data ?? [], [tasksPreview?.data])

  const timer = useTimer()

  React.useEffect(() => {
    setTasks(originalTasks)
  }, [originalTasks])

  const updateTasks = React.useCallback(async (newTasks: ChallengeTask[]) => {
    const result = await timer.await(dataStore.update(Challenge, challenge.id, {tasks: newTasks}))
    if (result?.status !== 'ok') {
      setTasks(originalTasks)
    }
    return translateFormErrorPaths(result, path => path.replace(/^tasks\.\d+\./, ''))
  }, [timer, challenge.id, originalTasks])

  const removeTasks = React.useCallback((uuids: string[]) => {
    const cleanedTasks = tasks.filter(task => !uuids.includes(task.uuid))
    return updateTasks(cleanedTasks)
  }, [tasks, updateTasks])

  const updateTask = React.useCallback((uuid: string, data: AnyObject) => {
    const newTasks = tasks.map(task => {
      if (task.uuid === uuid) {
        return {...task, ...data}
      } else {
        return task
      }
    })
    return updateTasks(newTasks)
  }, [tasks, updateTasks])

  const copyTasks = React.useCallback((uuids: string[]) => {
    const copiedTasks = tasks.filter(task => uuids.includes(task.uuid))
    const templates = copiedTasks.map(task => omit(task, 'uuid'))

    clipboard.write([{
      type: ClipboardType.CHALLENGE_TASKS,
      data: templates,
    }])
  }, [tasks])

  const pasteTasks = React.useCallback(async (atIndex?: number) => {
    const tasksToPaste = tasksClipboardQuery.getData()
    if (tasksToPaste == null) { return }

    let newTasks: ChallengeTask[] = []
    if (tasks.length === atIndex || atIndex === undefined) {
      newTasks = [...tasks, ...tasksToPaste]
    } else if (atIndex === 0) {
      newTasks = [...tasksToPaste, ...tasks]
    } else {
      newTasks = [
        ...tasks.slice(0, atIndex),
        ...tasksToPaste,
        ...tasks.slice(atIndex),
      ]
    }

    return updateTasks(newTasks)
  }, [updateTasks, tasks, tasksClipboardQuery])

  const removeTask = React.useCallback((uuid: string) => {
    return removeTasks([uuid])
  }, [removeTasks])

  const moveTask = React.useCallback((uuid: string, index: number) => {
    const originalIndex = tasks.findIndex(t => t.uuid === uuid)
    const sortedTasks = arrayMove(tasks, originalIndex, index)
    // Temporarily set tasks to avoid flash
    setTasks(sortedTasks)
    return updateTasks(sortedTasks)
  }, [updateTasks, tasks])

  const context = {
    challenge,
    tasks,
    tasksInClipboard,
    updateTasks,
    removeTasks,
    copyTasks,
    pasteTasks,
    updateTask,
    removeTask,
    moveTask,
  }

  return(
    <ChallengeTasksEditorContext.Provider value={context}>
      {children}
    </ChallengeTasksEditorContext.Provider>
  )
})

export default ChallengeTasksEditorContextProvider

export function useChallengeTasksEditor() {
  return React.useContext(ChallengeTasksEditorContext)
}