import { every, isArray } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import Semaphore from 'semaphore'
import socket from 'socket.io-react'
import { sparse } from 'ytil'
import config from '~/config'
import {
  AvailableFeature,
  ClientApp,
  ClientTabTemplate,
  Module,
  Project,
  ProjectFeatures,
} from '~/models'
import { Pack, ResourceOf } from './data/types'
import dataStore from './dataStore'
import { register } from './support'

export class ProjectStore {

  constructor() {
    socket.addEventListener('project:update', this.handleProjectUpdate)
    makeObservable(this)
  }

  public readonly projectSemaphore = new Semaphore({
    autoReset: true,
  })

  public onLogOut() {
    this.projectID = null
    this.projectSemaphore.reset()
  }

  //------
  // Project switch / request

  /**
   * The requested project ID. This may be null, in which case the server chooses the first
   * accessible project.
   */
  @observable
  public requestedProjectID: string | null = null

  @action
  public switchProject(projectID: string | null) {
    this.requestedProjectID = projectID
  }

  public async switchToFirstAvailableProject() {
    const endpoint = dataStore.endpoint(Project)
    await endpoint.fetch()

    if (endpoint.data.length > 0) {
      const project = endpoint.data[0]
      this.switchProject(project.id)
      return project
    } else {
      return null
    }
  }

  //------
  // Project

  /**
   * The current project ID.
   */
  @observable
  public projectID: string | null = null

  @computed
  public get project(): Project | null {
    if (this.projectID == null) { return null }
    return dataStore.get(Project, this.projectID)
  }

  @observable
  public moduleIDs: string[] = []

  @computed
  public get modules(): Module[] {
    return dataStore.list(Module, this.moduleIDs)
  }

  @computed
  public get mainModule(): Module | null {
    if (this.projectID == null) { return null }
    return dataStore.get(Module, this.projectID)
  }

  @computed
  public get preferredProjectURLScheme(): string {
    if (this.project == null) {
      return config.urls.defaultAppURLScheme
    } else {
      const app = dataStore.get(ClientApp, this.project.app)
      return app?.urlScheme ?? config.urls.defaultAppURLScheme
    }
  }

  @computed
  public get preferredProjectDomain(): string | null {
    if (this.project == null) {
      return null
    } else {
      const app = dataStore.get(ClientApp, this.project.app)
      return app?.domain ?? null
    }
  }

  //------
  // Features

  @observable
  public appFeatures: AvailableFeature[] = []

  @observable
  public moduleFeatures: AvailableFeature[] = []

  public isModuleFeatureEnabled(feature: string, moduleID?: string) {
    const modules = moduleID != null
      ? this.modules.filter(it => it.id === moduleID)
      : this.modules

    const features = this.getEnabledModuleFeatures(modules)
    return features[feature]?.enabled ?? false
  }

  public getEnabledModuleFeatures(modules: Module[]) {
    const features: ProjectFeatures = {}

    for (const module of modules) {
      for (const [name, feature] of Object.entries(module.features)) {
        if (!feature.enabled) { continue }
        if (name in features) { continue }

        features[name] = feature
      }
    }

    return features
  }

  //------
  // Tabs

  @computed
  public get clientTabs(): ClientTabTemplate[] {
    if (this.project == null) { return [] }

    const document = dataStore.document(Project, this.project.id)
    return document.meta.clientTabs
  }

  @computed
  public get availableClientTabTemplates() {
    return this.clientTabs.filter(tab => (
      every(tab.dependsOn, it => this.isModuleFeatureEnabled(it))
    ))
  }

  public get availableAppFeatures() {
    return this.appFeatures.filter(feature => (
      every(feature.dependsOn, it => this.isModuleFeatureEnabled(it))
    ))
  }

  //------
  // Persistence

  public persistenceKey = 'project'

  public persist() {
    // Note: we persist the *current* project ID, but we rehydrate it to the *requested* project ID.
    // This is because the server may choose a project if not specified. The next time, this project
    // should be requested again.
    return {
      requestedProjectID: this.projectID,
    }
  }

  public rehydrate(state: any) {
    this.requestedProjectID = state.requestedProjectID
  }

  //------
  // Project update

  private handleProjectUpdate = action((pack: Pack<Project>) => {
    this.projectID = dataStore.storePack(pack)?.id ?? null

    if (pack.data != null) {
      this.appFeatures    = sparse(pack.meta.appFeatures.map(AvailableFeature.parse))
      this.moduleFeatures = sparse(pack.meta.moduleFeatures.map(AvailableFeature.parse))

      const modules  = (pack.data as ResourceOf<Project>).relationships.modules
      this.moduleIDs = modules != null && isArray(modules.data)
        ? modules.data.map(linkage => linkage.id)
        : sparse([this.projectID])
    } else {
      this.appFeatures    = []
      this.moduleFeatures = []
      this.moduleIDs      = []
    }

    this.projectSemaphore.signal()
  })

}

const projectStore = register(new ProjectStore())
export default projectStore