import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { useTimer } from 'react-timer'
import { Endpoint } from 'mobx-document'
import { observer } from '~/ui/component'
import {
  ClearButton,
  HBox,
  Label,
  Panel,
  PopupMenu,
  PopupMenuItem,
  PushButton,
  VBox,
} from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useResourceListLocation } from '~/ui/resources/collection'
import { createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'
import { useResourceType } from '../../ResourceTypeContext'
import { SummaryMetaShape } from '../SummaryBar'
import { BulkAction } from '../types'
import { bulkSelectorForSelection } from '../util'
import { ResolvedBulkAction, useBulkActions } from './useBulkActions'
import { WellKnownActionFlags } from './wellknown'

export interface Props<E extends Endpoint<any, any, MetaShape>> extends WellKnownActionFlags {
  endpoint:    E
  actions:     BulkAction[]
  paginated?:  boolean
}

export type MetaShape = SummaryMetaShape

const BulkActionsBar = observer('BulkActionsBar', (props: Props<any>) => {

  const {
    endpoint,
    actions: props_actions,
    paginated = true,
  } = props

  const {t}   = useResourceTranslation()
  const theme = useTheme()
  const timer = useTimer()

  const manager   = useSelectionManager()
  const {filters} = useResourceListLocation()

  const resourceType = useResourceType()
  const allSelected  = manager?.allSelected ?? false
  const selected     = manager?.selectedKeys

  const total     = endpoint.meta?.total ?? 0
  const available = (allSelected || !paginated) ? total : (manager?.available.size ?? 0)
  const count     = manager?.allSelected ? total : (selected?.length ?? 0)

  const [actions, form] = useBulkActions(endpoint, props_actions, props)

  const deriveBulkSelector = React.useCallback(() => {
    if (resourceType == null || manager == null) { return null }
    return bulkSelectorForSelection(resourceType, manager, filters)
  }, [filters, manager, resourceType])

  const executeAction = React.useCallback(async (action: ResolvedBulkAction) => {
    const selector = deriveBulkSelector()
    if (selector == null) { return }

    const success = await timer.await(action.execute(selector))
    if (success) {
      manager?.selectNone()
    }
  }, [deriveBulkSelector, manager, timer])

  const actionItems = React.useMemo(() => {
    const items = actions.map((action): PopupMenuItem => ({
      caption:  action.caption,
      icon:     action.icon,
      color:    action.confirm != null ? theme.semantic.negative : undefined,
      onSelect: () => executeAction(action),
    }))

    if (actions.length > 0 && actions[actions.length - 1].name === 'remove_bulk') {
      // Place a divider before the delete action.
      items.splice(items.length - 1, 0, {section: '-'})
    }

    return items
  }, [actions, executeAction, theme.semantic.negative])

  //------
  // Callbacks

  const selectNone = React.useCallback(() => {
    manager?.selectNone()
  }, [manager])

  const selectAllAvailable = React.useCallback(() => {
    manager?.selectAllAvailable()
  }, [manager])

  const selectAllFiltered = React.useCallback(() => {
    manager?.selectAll()
  }, [manager])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel semi classNames={$.bulkActionsBar}>
        <HBox flex='grow' align='stretch' justify='space-between' gap={layout.padding.m} padding={layout.padding.s}>
          <HBox flex='shrink' gap={layout.padding.s}>
            {renderTotals()}
            {renderSelectionButtons()}
          </HBox>
          {renderActionsMenu()}
        </HBox>
        {form}
      </Panel>
    )
  }

  function renderTotals() {
    return (
      <VBox flex='shrink' classNames={[$.totals, {allSelected}]}>
        <ThemeProvider dark={allSelected}>
          <Label small markup align='center'>
            {t('selection.totals', {
              selected: count,
              count:    available,
            })}
          </Label>
        </ThemeProvider>
      </VBox>
    )
  }

  function renderSelectionButtons() {
    if (manager == null || manager.selectedKeys.length === 0) { return null }

    return (
      <HBox flex='shrink' gap={layout.padding.inline.m}>
        {count < available && (
          <PushButton
            icon='check'
            caption={t('selection.select_all_visible_short', {count: available})}
            onTap={selectAllAvailable}
            flex='shrink'
            small
          />
        )}
        {total > available && !allSelected && (
          <PushButton
            icon='double-check'
            caption={t('selection.select_all_filtered_short', {count: total})}
            onTap={selectAllFiltered}
            flex='shrink'
            small
          />
        )}
        <ClearButton
          icon='cross'
          caption={t('selection.clear')}
          onTap={selectNone}
          padding='vertical'
          dim
          flex='shrink'
          small
        />
      </HBox>
    )
  }

  function renderActionsMenu() {
    return (
      <PopupMenu items={actionItems}>
        {toggle => (
          <PushButton
            caption={t('bulk-actions.menu')}
            icon='chevron-up'
            iconSide='right'
            onTap={toggle}
          />
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default BulkActionsBar

const useStyles = createUseStyles(theme => ({
  bulkActionsBar: {
    minHeight: layout.barHeight.m,
  },

  totals: {
    padding:      layout.padding.inline.m,

    '&.allSelected': {
      background:   theme.semantic.primary,
      borderRadius: layout.radius.s,
    },
  },
}))