import React from 'react'
import { Competition } from '~/models'
import { memo } from '~/ui/component'
import { Chip, ClearButton, HBox, Label, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import CompetitionTeamsForm from './CompetitionTeamsForm'

export interface Props {
  competition: Competition
}

const CompetitionTeamsSummary = memo('CompetitionTeamsSummary', (props: Props) => {

  const {competition} = props
  const {t, fieldCaption}  = useResourceTranslation()

  const [formOpen, openForm, closeForm] = useBoolean()

  //------
  // Rendering

  function render() {
    if (competition.type !== 'team') { return null }
    if (competition.teamTags == null) { return null }

    return (
      <HBox align='top' gap={layout.padding.s}>
        <VBox flex>
          {competition.teamTags.length === 0 ? (
            renderEmpty()
          ) : (
            renderBody()
          )}
        </VBox>
        {renderEditButton()}
        {renderForm()}
      </HBox>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderTeamTags()}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <SummaryInfo.Item icon='group' markup>
        <VBox>
          <Label small bold dim>
            {t('teams.empty.title')}
          </Label>
          <Label small dim truncate={false}>
            {t('teams.empty.detail')}
          </Label>
        </VBox>
      </SummaryInfo.Item>
    )
  }

  function renderTeamTags() {
    return (
      <SummaryInfo.Item icon='tag' markup>
        <VBox gap={layout.padding.inline.s}>
          <Label small bold dim truncate={false} markup>
            {fieldCaption('team_tags')}
          </Label>

          <HBox wrap gap={layout.padding.inline.s}>
            {(competition.teamTags ?? []).map(tag => (
              <Chip
                key={tag}
                icon='tag'
                children={tag}
                flex='shrink'
                small
              />
            ))}
          </HBox>
        </VBox>
      </SummaryInfo.Item>
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        onTap={openForm}
        padding='both'
      />
    )
  }

  function renderForm() {
    return (
      <CompetitionTeamsForm
        open={formOpen}
        requestClose={closeForm}
        competition={competition}
      />
    )
  }

  return render()

})

export default CompetitionTeamsSummary