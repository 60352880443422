import { Script, ScriptMessage } from '~/models'
import { ConditionsFormModel } from '~/ui/app/conditions'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ScriptMessageConditionsFormModel extends ConditionsFormModel {

  constructor(
    private readonly saveMessage: (uuid: string, data: AnyObject) => Promise<SubmitResult | undefined>,
    private readonly message: ScriptMessage,
  ) {
    super(message.conditions)
  }

  public deriveContextFromScript(script: Script) {
    const recentScriptIDs = script.hasQuestion ? [script.id] : []
    this.setContext({recentScriptIDs})
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const {conditions} = this
    const result = await this.saveMessage(this.message.uuid, {conditions})
    return translateFormErrorPaths(result, path => path.replace(/^messages\.\d+\.conditions\./, ''))
  }

}