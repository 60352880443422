import { range } from 'lodash'
import { svgPath } from 'ytil'
import { layout } from '~/ui/styling'
import { Direction } from '../canvas/types'
import { SegueArrowEnd } from './SegueArrow'

export const segmentMinLength = 24

export enum GravityPointAlgorithm {
  CENTER,
  LONGEST,
}

/**
 * This algorithm figures out a natural path between to ends. There are 8 different possibilities given the from-
 * and the to-point, and their directions. For each possibility, points are inserted to form the the most logical
 * segue arrow, using straight non-diagonal lines.
 *
 * @param from The from-end.
 * @param to   The two-end.
 */
export function seguePath(from: SegueArrowEnd, to: SegueArrowEnd, arrowSize: number = 0) {
  const points = seguePathPoints(from, to, arrowSize)
  return svgPath(points, {
    roundCorners: layout.radius.m,
  })
}

export function calculatePathGravityPoint(from: SegueArrowEnd, to: SegueArrowEnd, algorithm: GravityPointAlgorithm) {
  if (algorithm === GravityPointAlgorithm.CENTER) {
    return calculatePointOnPath(from, to, 0.5)
  }

  const [, segments] = buildSeguePath(from, to)
  const segment = findLongestSegment(segments)
  if (segment == null) { return null }

  return {
    x: (segment.start.x + segment.end.x) / 2,
    y: (segment.start.y + segment.end.y) / 2,
  }

}

export function calculatePointOnPath(from: SegueArrowEnd, to: SegueArrowEnd, at: number) {
  const [points, segments] = buildSeguePath(from, to)
  if (at <= 0) { return points[0] }
  if (at >= 1) { return points[points.length - 1] }

  const totalLength = segments.reduce((sum, segment) => sum + segment.length, 0)
  const atLength    = at * totalLength

  let distance = 0
  for (const segment of segments) {
    if ((distance + segment.length) >= atLength) {
      const ratio = (atLength - distance) / segment.length
      return {
        x: segment.start.x * (1 - ratio) + segment.end.x * ratio,
        y: segment.start.y * (1 - ratio) + segment.end.y * ratio,
      }
    }
    distance += segment.length
  }

  return points[points.length - 1]
}

function findLongestSegment(segments: Segment[]) {
  let longest: Segment | null = null
  for (const segment of segments) {
    if (longest == null || segment.length > longest.length) {
      longest = segment
    }
  }
  return longest
}

function buildSeguePath(from: SegueArrowEnd, to: SegueArrowEnd, arrowSize: number = 0): [Point[], Segment[]] {
  const points = seguePathPoints(from, to)
  if (points.length === 0) { return [[], []] }

  const segments = range(0, points.length - 1).map(index => ({
    start:  points[index],
    end:    points[index + 1],
    length: Math.sqrt((points[index].x - points[index + 1].x) ** 2 + (points[index].y - points[index + 1].y) ** 2),
  }))

  return [points, segments]
}

function seguePathPoints(from: SegueArrowEnd, to: SegueArrowEnd, arrowSize: number = 0) {
  const points: Point[] = []

  points.push(from.point)

  const fromPoint = move(from.point, from.direction, segmentMinLength)
  const toPoint   = move(to.point, to.direction, -segmentMinLength)

  // First move a certain fixed amount of pixels out.
  points.push(fromPoint)

  if (from.direction === to.direction) {
    const perpendicular = positivePerpendicular({point: fromPoint, direction: from.direction}, toPoint)
    const crossOffset   = offsetAlong(fromPoint, toPoint, perpendicular)
    if (crossOffset > 0) {
      const offset = offsetAlong(fromPoint, toPoint, from.direction)
      if (offset > 0) {
        points.push(move(fromPoint, from.direction, offset / 2))
        points.push(move(toPoint, to.direction, -offset / 2))
      }
      if (offset < 0) {
        points.push(move(fromPoint, positivePerpendicular(from, toPoint), crossOffset / 2))
        points.push(move(toPoint, positivePerpendicular(from, toPoint), -crossOffset / 2))
      }
    }
  } else if (isPerpendicular(from.direction, to.direction)) {
    const offset      = offsetAlong(fromPoint, toPoint, from.direction)
    const crossOffset = offsetAlong(fromPoint, toPoint, to.direction)
    if (offset > 0 && crossOffset > 0) {
      points.push(move(fromPoint, from.direction, offset))
    } else if (offset > 0) {
      points.push(move(toPoint, from.direction, -offset))
    } else if (crossOffset > 0) {
      points.push(move(fromPoint, to.direction, crossOffset / 2))
      points.push(move(toPoint, to.direction, -crossOffset / 2))
    } else {
      points.push(move(fromPoint, to.direction, crossOffset))
    }
  } else {
    const offset = offsetAlong(fromPoint, toPoint, from.direction)
    if (offset > 0) {
      points.push(move(fromPoint, from.direction, offset))
    } else {
      points.push(move(toPoint, to.direction, offset))
    }
  }

  // End with a straight end again. Leave room for the arrow.
  points.push(toPoint)
  points.push(move(to.point, to.direction, -arrowSize))

  return points
}

export function move(base: Point, direction: Direction, length: number) {
  switch (direction) {
    case Direction.UP:    return {x: base.x, y: base.y - length}
    case Direction.DOWN:  return {x: base.x, y: base.y + length}
    case Direction.LEFT:  return {x: base.x - length, y: base.y}
    case Direction.RIGHT: return {x: base.x + length, y: base.y}
  }
}

export function offsetAlong(from: Point, to: Point, direction: Direction) {
  switch (direction) {
    case Direction.UP:    return from.y - to.y
    case Direction.DOWN:  return to.y - from.y
    case Direction.LEFT:  return from.x - to.x
    case Direction.RIGHT: return to.x - from.x
  }
}

export function offsetAcross(from: Point, to: Point, direction: Direction) {
  switch (direction) {
    case Direction.UP:    return from.x - to.x
    case Direction.DOWN:  return to.x - from.x
    case Direction.LEFT:  return from.y - to.y
    case Direction.RIGHT: return to.y - from.y
  }
}

export function isPerpendicular(direction1: Direction, direction2: Direction) {
  switch (direction1) {
    case Direction.UP:    return direction2 === Direction.LEFT || direction2 === Direction.RIGHT
    case Direction.DOWN:  return direction2 === Direction.LEFT || direction2 === Direction.RIGHT
    case Direction.LEFT:  return direction2 === Direction.UP || direction2 === Direction.DOWN
    case Direction.RIGHT: return direction2 === Direction.UP || direction2 === Direction.DOWN
  }
}

export function positivePerpendicular(from: SegueArrowEnd, to: Point) {
  if (from.direction === Direction.UP || from.direction === Direction.DOWN) {
    return from.point.x < to.x ? Direction.RIGHT : Direction.LEFT
  } else {
    return from.point.y < to.y ? Direction.DOWN : Direction.UP
  }
}

interface Segment {
  start:  Point
  end:    Point
  length: number
}