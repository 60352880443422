import React from 'react'
import { Challenge, ModuleParameter, Script } from '~/models'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  parameter: ModuleParameter
}

const TriggerableParameterFieldset = memo('TriggerableParameterFieldset', (props: Props) => {

  const parameter = props.parameter as ModuleParameter & {type: 'script' | 'challenge'}
  const Model     = parameter.type === 'script' ? Script : Challenge

  const {t}  = useResourceTranslation('modules')
  const i18n = useResourceTranslation(Model.resourceType)
  const singular = i18n.singular()
  const plural = i18n.plural()

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='tag' {...t('parameters.fields.tag', {singular, plural})}>
          {bind => <TagsField Model={Model} {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default TriggerableParameterFieldset