import { patternToRegExp } from 'ytil'
import { Trigger, TriggerNode } from '~/models'
import TriggerNodeView from './TriggerNodeView'

export interface TriggerProps<T extends Trigger> {
  node:    TriggerNode
  trigger: T
}

const registry = new Map<RegExp, React.ComponentType<TriggerProps<any>>>()

export function getNodeViewForTrigger(type: Trigger['type']) {
  for (const [regexp, Component] of registry) {
    if (regexp.test(type)) {
      return Component
    }
  }

  return TriggerNodeView
}

export function register<T extends Trigger>(pattern: string, target: React.ComponentType<TriggerProps<T>>) {
  registry.set(patternToRegExp(pattern, {wildcards: true}), target)
}