import I18next from 'i18next'
import { action, computed, makeObservable, observable } from 'mobx'
import { User } from '~/models'
import { dataStore } from '~/stores'
import { formErrorPath, SubmitResult } from '~/ui/form'

export default class ChangePasswordFormModel {

  constructor(
    public readonly userID: string,
  ) {
    makeObservable(this)
  }

  @observable
  @formErrorPath('currentPassword')
  public currentPassword: string = ''

  @observable
  @formErrorPath('password')
  public newPassword: string = ''

  @observable
  public repeatPassword: string = ''

  @observable
  public showPassword: boolean = false

  @action
  public toggleShowPassword() {
    this.showPassword = !this.showPassword
  }

  @computed
  public get maySubmit() {
    if (this.newPassword === '') { return false }
    if (!this.showPassword && this.newPassword !== this.repeatPassword) { return false }

    return true
  }

  public async submit(): Promise<SubmitResult | undefined> {
    if (!this.showPassword && this.newPassword !== this.repeatPassword) {
      return {
        status: 'invalid',
        errors: [{
          field: 'newPassword',
          message: I18next.t('users:change_password:passwords_dont_match'),
        }],
      }
    }

    const document = dataStore.document(User, this.userID)
    return await document.callAction('change-password', {
      data: {
        type: 'users',
        id:   this.userID,
        attributes: {
          password: this.newPassword,
        },
      },
      meta: {
        currentPassword: this.currentPassword,
      },
    })
  }

}