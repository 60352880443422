import React from 'react'
import config from '~/config'
import { projectStore } from '~/stores'
import { memo } from '~/ui/component'
import { CopyToClipboard, ModalDialog, TabPanel, TextBlock, VBox } from '~/ui/components'
import { Tab } from '~/ui/components/TabPanel'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  open: boolean
  requestClose?: () => any

  token: string
}

const MagicLoginDialog = memo('MagicLoginDialog', (props: Props) => {

  const {
    open,
    requestClose,
    token,
  } = props

  const {t} = useResourceTranslation()

  const tabs = React.useMemo((): Tab<MagicLoginURLType>[] => [
    {name: 'app', caption: t('magic_login.type.app')},
    {name: 'web', caption: t('magic_login.type.web')},
  ], [t])

  const [currentTab, setCurrentTab] = React.useState<MagicLoginURLType>('app')

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        children={renderContent()}
        title={t('magic_login.dialog.title')}
        headerRight='$close'

        width={560}
      />
    )
  }

  function renderContent() {
    return (
      <VBox padding={layout.padding.m}>
        <TabPanel tabs={tabs} currentTab={currentTab} requestTab={setCurrentTab}>
          {type => <MagicLoginDialogTab type={type} token={token} />}
        </TabPanel>
      </VBox>
    )
  }

  return render()

})

export default MagicLoginDialog

interface MagicLoginDialogTabProps {
  type:  MagicLoginURLType
  token: string
}

const MagicLoginDialogTab = memo('MagicLoginDialogTab', (props: MagicLoginDialogTabProps) => {

  const {
    type,
    token,
  } = props

  const {t} = useResourceTranslation()

  const urlScheme = projectStore?.preferredProjectURLScheme
  const domain    = projectStore?.preferredProjectDomain

  const url = type === 'app'
    ? config.urls.appLogin(token, urlScheme)
    : config.urls.webLogin(token, domain)

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim bold>
          {t(`magic_login.dialog.instruction.${type}`)}
        </TextBlock>
        <CopyToClipboard
          value={url}
          height={120}
          small
          multiline
          mono
        />
      </VBox>
    )
  }

  return render()

})

type MagicLoginURLType = 'app' | 'web'