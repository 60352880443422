import React from 'react'
import { Media, User } from '~/models'
import { dataStore } from '~/stores'
import { ProfilePhotoField } from '~/ui/app/fields'
import { HBox, Label, LinkLabel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  user: User
}

const UserProfile = (props: Props) => {

  const {user} = props
  const {organisationName} = user

  const {t} = useResourceTranslation()

  //------
  // Callbacks

  const updateProfilePhoto = React.useCallback((media: Media | null) => {
    const document = dataStore.document(User, user.id)
    return document.update({
      photoURL: media?.url ?? null,
    })
  }, [user.id])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.userProfile} align='top' gap={layout.padding.xl}>
        {renderPhoto()}
        <VBox flex>
          {renderInfoForm()}
        </VBox>
      </HBox>
    )
  }

  function renderPhoto() {
    return (
      <ProfilePhotoField
        model={user}
        size={profilePhotoSize}
        requestUpdate={updateProfilePhoto}
      />
    )
  }

  function renderInfoForm() {
    return (
      <VBox gap={layout.padding.m}>
        <VBox gap={layout.padding.inline.s}>
          <Label h2>
            {user.name}
          </Label>
          {user.organisation == null ? (
            <Label light dim italic>
              {t('profile.no_organisation')}
            </Label>
          ) : (
            <Label light>
              {organisationName}
            </Label>
          )}
        </VBox>
        <HBox>
          <VBox flex='shrink'>
            <LinkLabel href={`mailto:${user.email}`}>
              {user.email}
            </LinkLabel>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  return render()

}

export default UserProfile

export const profilePhotoSize = {
  width:  96,
  height: 96,
}

const useStyles = createUseStyles({
  userProfile: {
    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], 0],
    })),
  },
})