import React from 'react'
import { Beacon, defaultZoom } from '~/models'
import { GoogleMapFields } from '~/ui/app/google-maps'
import { observer } from '~/ui/component'
import { CollectionField, HBox, Label, SVG, TextField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import BeaconForm from './BeaconForm'
import BeaconFormModel from './BeaconFormModel'
import LocationCommonFields from './LocationCommonFields'

const BeaconLocationFields = observer('BeaconLocationFields', () => {

  const {fieldCaption, fieldInstruction} = useResourceTranslation('locations')

  const newItemTemplate = React.useCallback((): Beacon => ({
    name:          '',
    proximityUUID: '',
    major:         null,
    minor:         null,
  }), [])

  const buildBeaconFormModel = React.useCallback((beacon: Beacon, save: (beacon: Beacon) => any) => {
    return new BeaconFormModel(beacon, save)
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <HBox align='top' gap={layout.padding.m}>
        <VBox flex gap={layout.padding.m}>
          <FormField name='name' required>
            {bind => <TextField {...bind}/>}
          </FormField>

          <FormField name='beacons'>
            {bind => (
              <CollectionField<Beacon>
                {...bind}
                ItemFormDialogComponent={BeaconForm}
                itemFormModel={buildBeaconFormModel}
                newItemTemplate={newItemTemplate}
                renderItem={renderBeaconRow}
              />
            )}
          </FormField>

          <LocationCommonFields/>
        </VBox>
        <VBox flex gap={layout.padding.inline.s}>
          <FormFieldHeader
            name='coordinate'
            caption={fieldCaption('beacon_coordinate')}
            instruction={fieldInstruction('beacon_coordinate')}
          />
          <GoogleMapFields
            zoom={defaultZoom}
            hasRadius={false}
          />
        </VBox>
      </HBox>
    )
  }

  function renderBeaconRow(beacon: Beacon) {
    return (
      <HBox gap={layout.padding.inline.m}>
        <SVG name='beacon' size={layout.icon.s} primary/>
        <Label flex mono>
          {beacon.name ?? beacon.proximityUUID}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default BeaconLocationFields