import React from 'react'
import { useHistory } from 'react-router'
import { isEqual } from 'lodash'
import { StringParam, useQueryParam } from 'use-query-params'
import { AnswersService } from '~/stores'
import { observer } from '~/ui/component'
import { AnswerScopeLinkage } from './explore/AnswerScopeField'
import { pathForScope } from './util'

interface AnswersContext {
  service:  AnswersService | null
  scope:    AnswerScopeLinkage | null
  setScope: (scope: AnswerScopeLinkage | null) => void

  focusedQuestions:     Array<string>
  setFocusedQuestions: (questionUUIDs: Array<string>) => void

  participantID:    string | null
  setParticipantID: (id: string | null) => void

  segmentGroups:    string[]
  setSegmentGroups: (groups: string[]) => void
}

const AnswersContext = React.createContext<AnswersContext>({
  service: null,

  scope:    null,
  setScope: () => void 0,

  focusedQuestions:    [],
  setFocusedQuestions: () => void 0,

  participantID:    null,
  setParticipantID: () => void 0,

  segmentGroups:    [],
  setSegmentGroups: () => void 0,
})

export interface AnswersContextProviderProps {
  service:   AnswersService | null
  children?: React.ReactNode
}

export const AnswersContextProvider = observer('AnswersContextProvider', (props: AnswersContextProviderProps) => {

  const history = useHistory()
  const {service} = props

  //------
  // Scope navigation

  const scope = service?.scope ?? null

  const setScope = React.useCallback(scope => {
    const path = pathForScope(scope)
    if (history.location.pathname !== path) {
      history.push(path)
    }
  }, [history])

  //------
  // Filters

  const [focusedQuestionsParam, setFocusedQuestionsParam] = useQueryParam('questionUUIDs', StringParam)
  const [segmentGroupsParam, setSegmentGroupsParam]       = useQueryParam('segmentGroups', StringParam)
  const [participantIDParam,setParticipantIDParam]        = useQueryParam('participantID', StringParam)

  const setFocusedQuestions = React.useCallback((questionUUIds: Array<string>) => {
    setFocusedQuestionsParam(questionUUIds.length > 0 ? questionUUIds.join(',') : null)
  }, [setFocusedQuestionsParam])

  const focusedQuestions = React.useMemo(
    () => focusedQuestionsParam?.split(',') ?? [], [focusedQuestionsParam],
  )

  const setParticipantID = setParticipantIDParam

  React.useEffect(() => {
    if (service == null ||  !service.started) { return }
    const participantID = participantIDParam == null ? undefined : participantIDParam
    if (participantID !== service.filters.participantID)
    service.updateFilters({
      ...service.filters,
      participantID,
    })
  }, [participantIDParam, service])

  const segmentGroups = React.useMemo(
    () => segmentGroupsParam?.split(',') ?? [], [segmentGroupsParam],
  )

  const setSegmentGroups = React.useCallback((groups: string[]) => {
    setSegmentGroupsParam(groups.length === 0 ? null : groups.join(','))
  }, [setSegmentGroupsParam])

  React.useEffect(() => {
    if (service == null || !service.started) { return }
    const segments = ['all', ...segmentGroups ?? []]
    if (isEqual(segments, service.filters.segments)) { return }

    service.updateFilters({
      ...service.filters,
      segments,
    })
  }, [segmentGroups, service])

  //------
  // Rendering

  const context = React.useMemo((): AnswersContext => ({
    scope,
    setScope,
    focusedQuestions,
    setFocusedQuestions,
    participantID: participantIDParam ?? null,
    setParticipantID,
    segmentGroups,
    setSegmentGroups,
    service,
  }), [scope, setScope, focusedQuestions, setFocusedQuestions, participantIDParam, setParticipantID, segmentGroups, setSegmentGroups, service])

  return (
    <AnswersContext.Provider value={context}>
      {props.children}
    </AnswersContext.Provider>
  )
})

export function useAnswers() {
  return React.useContext(AnswersContext)
}