import React from 'react'
import { memo } from '~/ui/component'
import { createUseStyles, animation, layout } from '~/ui/styling'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { Avatar } from '~/ui/app/media'
import { Tappable, Tooltip } from '~/ui/components'

export interface Props<P extends AvatarParticipantInfo> {
  participants: P[]
  onAvatarTap?: (participant: P) => any
  tooltip?:     boolean | ((participant: P) => React.ReactNode)

  gap?:  number
  size?: Size
}

export interface AvatarParticipantInfo {
  id:        string
  firstName: string
  lastName:  string | null
  photoURL:  string | null
}

const _AvatarRow = <P extends AvatarParticipantInfo>(props: Props<P>) => {

  const {
    participants,
    onAvatarTap,
    tooltip = true,
    size    = defaultSize,
    gap     = -size.width / 2,
  } = props

  //------
  // Rendering

  const $ = useStyles(gap)

  function render() {
    return (
      <TransitionGroup classNames={$.avatarRow}>
        {participants.map(renderAvatar)}
      </TransitionGroup>
    )
  }

  function renderAvatar(participant: P) {
    let element = (
      <Avatar
        classNames={$.avatar}
        {...participant}
        source={participant.photoURL}
        size={size}
      />
    )

    if (onAvatarTap != null) {
      element = (
        <Tappable onTap={onAvatarTap.bind(null, participant)}>
          {element}
        </Tappable>
      )
    }

    if (tooltip) {
      const renderTooltip = tooltip === true ? tooltip : tooltip(participant)
      element = (
        <Tooltip renderTooltip={renderTooltip} textAlign='center'>
          {element}
        </Tooltip>
      )
    }

    return (
      <CSSTransition key={participant.id} classNames={$.pop} timeout={popDuration}>
        {element}
      </CSSTransition>
    )
  }

  return render()

}

const AvatarRow = memo('AvatarRow', _AvatarRow) as typeof _AvatarRow
export default AvatarRow

export const defaultSize = {
  width:  32,
  height: 32,
}

const popDuration = animation.durations.short

const useStyles = createUseStyles({
  avatarRow: (gap: number) => ({
    ...layout.row(gap),
  }),

  pop: {
    ...animation.pop(popDuration),
  },

  avatar: {
  },
})