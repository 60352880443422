import React from 'react'
import { SelectionManager, SelectionManagerProvider } from 'react-selection-manager'
import { Model, ModelClass } from '~/models'
import { ModelEndpoint, Sort } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceFilter } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'
import { childrenNotOfType, childrenOfType } from '~/ui/util'
import { resourceDetailPath } from '../routes'
import BulkActionsBar from './bulk-actions/BulkActionsBar'
import SummaryBar from './SummaryBar'
import { BulkAction } from './types'
import { useResourceListLocation } from './useResourceListLocation'

export interface Props<M extends Model> {
  endpoint: ModelEndpoint<any>

  bulkActions?: BulkAction<M>[]
  allowRemove?: boolean
  allowCopy?:   boolean
  allowExport?: boolean

  allowDetail?:    boolean | ((item: M) => boolean)
  allowSelect?:    boolean | ((item: M) => boolean)
  onItemTap?:      (item: M) => any
  itemIsSelected?: (item: M) => boolean

  itemHref?:       (item: M) => string
  appendQuery?:    boolean

  useLocation?: boolean
  showTotals?:  boolean
  allowSearch?: boolean

  i18nResourceType?: string

  parentModel?:   Model
  renderContent?: (props: ChildrenProps<M>) => React.ReactNode
  children?:      React.ReactNode
}

export interface ChildrenProps<M> {
  data:            M[]
  EmptyComponent:  React.ComponentType<{}>
  onItemTap?:      (item: M) => void
  itemHref?:       (item: M) => string | null
  itemIsSelected?: (item: M) => boolean

  sort:        Sort | null
  requestSort: (sort: Sort | null) => any
}

export interface CreateFormProps {
  open:         boolean
  requestClose: () => any
}

const ResourceCollection = observer('ResourceCollection', <M extends Model>(props: Props<M>) => {

  const {
    endpoint,
    parentModel,
    allowDetail = true,
    useLocation = false,
    showTotals = true,
    allowSearch = true,
    bulkActions = [],
    allowRemove = true,
    allowCopy = true,
    allowExport = false,
    itemHref: props_itemHref,
    appendQuery = true,
    onItemTap: props_onItemTap,
    itemIsSelected,
    i18nResourceType = endpoint.Model.resourceType,
    renderContent,
    children,
  } = props

  const {t} = useResourceTranslation(i18nResourceType)

  const [state_search, state_setSearch] = React.useState<string | null>(null)
  const [state_filters]                 = React.useState<Record<string, any> | null>(null)
  const [state_page, state_setPage]     = React.useState<number | null>(null)
  const [state_sort, state_setSort]     = React.useState<Sort | null>(null)

  const {
    search:     location_search,
    setSearch:  location_setSearch,
    filters:    location_filters,
    page:       location_page,
    setPage:    location_setPage,
    sort:       location_sort,
    setSort:    location_setSort,
  } = useResourceListLocation(endpoint.Model.resourceType)

  const search     = useLocation ? location_search : state_search
  const setSearch  = useLocation ? location_setSearch : state_setSearch
  const filters    = useLocation ? location_filters : state_filters
  const page       = useLocation ? location_page : state_page
  const setPage    = useLocation ? location_setPage : state_setPage
  const sort       = useLocation ? location_sort : state_sort
  const setSort    = useLocation ? location_setSort : state_setSort

  const sorts = React.useMemo(() => sort == null ? [] : [sort], [sort])

  const prevSearch = usePrevious(search)
  const prevPage   = usePrevious(page)
  const prevSort   = usePrevious(sort)

  React.useEffect(() => {
    endpoint.setParams({
      search:  search ?? undefined,
      filters: filters ?? undefined,
      page:    page ?? undefined,
      sorts:   sorts ?? undefined,
    })
  }, [endpoint, filters, page, prevPage, prevSearch, prevSort, search, sort, sorts])

  const data        = endpoint.data
  const fetchStatus = endpoint.fetchStatus

  //------
  // Selection

  const selectionManager = React.useMemo(
    () => new SelectionManager(),
    [],
  )

  //------
  // Actions

  const onItemTap = React.useMemo(() => {
    if (props_onItemTap == null) { return undefined }
    return (model: M) => props_onItemTap(model)
  }, [props_onItemTap])

  const itemHrefWithoutQuery = React.useCallback((model: M) => {
    if (props_itemHref != null) {
      return props_itemHref(model)
    } else if (onItemTap == null && allowDetail) {
      const ParentModel = parentModel == null ? null : parentModel.constructor as ModelClass<any>
      const prefix      = parentModel == null ? '' : resourceDetailPath(ParentModel!.resourceType, parentModel.id)

      return prefix + resourceDetailPath(endpoint.Model.resourceType, model.id)
    } else {
      return null
    }
  }, [allowDetail, endpoint.Model.resourceType, onItemTap, parentModel, props_itemHref])

  const itemHref = React.useCallback((model: M) => {
    const withoutQuery = itemHrefWithoutQuery(model)
    if (withoutQuery == null) { return null }
    if (!appendQuery) { return withoutQuery }

    return withoutQuery + document.location.search
  }, [appendQuery, itemHrefWithoutQuery])

  //------
  // Children

  const filtersChildren = React.useMemo(
    () => childrenOfType(children, ResourceFilter),
    [children],
  )

  const restChildren = React.useMemo(
    () => childrenNotOfType(children, [ResourceFilter]),
    [children],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType={endpoint.Model.resourceType} i18nResourceType={i18nResourceType}>
        <SelectionManagerProvider manager={selectionManager}>
          <VBox flex gap={layout.padding.m}>
          {renderContent?.(contentProps)}
          {restChildren}

          {selectionManager.selectedKeys.length > 0 ? (
            renderBulkActionsBar()
          ) : (
            renderSummaryBar()
          )}
        </VBox>
        </SelectionManagerProvider>
      </ResourceTypeProvider>
    )
  }

  function renderSummaryBar() {
    return (
      <SummaryBar
        endpoint={endpoint}
        showTotals={showTotals}
        search={allowSearch ? (search ?? null) : false}
        setSearch={setSearch}
        page={page ?? 1}
        setPage={setPage}
        filters={filtersChildren}
      />
    )
  }

  function renderBulkActionsBar() {
    return (
      <BulkActionsBar
        endpoint={endpoint}
        actions={bulkActions}
        export={allowExport}
        remove={allowRemove}
        copy={allowCopy}
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('empty')}
        status={fetchStatus}
        flex={true}
      />
    )
  }, [fetchStatus, t])

  const contentProps = React.useMemo((): ChildrenProps<M> => ({
    data:            data,
    onItemTap:       onItemTap,
    itemHref:        itemHref,
    itemIsSelected:  itemIsSelected,
    EmptyComponent:  renderEmpty,
    sort:            sort ?? null,
    requestSort:     setSort,
  }), [data, itemHref, itemIsSelected, onItemTap, renderEmpty, setSort, sort])

  return render()

})

export default ResourceCollection