import React from 'react'
import { MediaUploaderState } from '~/ui/app/media'
import MediaUploader from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { ResourceTypeProvider } from '~/ui/resources'
import EditingListActionBar from './EditingListActionBar'
import { useScriptEditing } from './ScriptEditingContext'
import ScriptMediaUploader from './ScriptMediaUploader'
import ScriptMessageInputBar from './ScriptMessageInputBar'
import ScriptMessageList from './ScriptMessageList'
import ScriptEditorDetailForm from './details/ScriptEditorDetailForm'

const ScriptEditor = memo('ScriptEditor', () => {

  const {editingList} = useScriptEditing()

  const [pastingMedia, startPastingMedia, stopPastingMedia] = useBoolean()

  //------
  // Callbacks

  const uploaderRef  = React.useRef<MediaUploader>(null)

  const browseMedia = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  const cancelUpload = React.useCallback(() => {
    uploaderRef.current?.cancel()
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='script-messages'>
        <ScriptMediaUploader uploaderRef={uploaderRef} enabled={!editingList}>
          {renderContent}
        </ScriptMediaUploader>

        <ScriptEditorDetailForm/>
      </ResourceTypeProvider>
    )
  }

  function renderContent(state: MediaUploaderState) {
    const uploadProgress = state.progress ?? (pastingMedia ? {sent: 0, total: 1} : null)

    return (
      <VBox flex>
        <ScriptMessageList
          newMessageUploadProgress={uploadProgress}
          requestCancelUpload={cancelUpload}
        />
        {editingList ? (
          <EditingListActionBar/>
        ) : (
          <ScriptMessageInputBar
            requestAddMedia={browseMedia}
            startPastingMedia={startPastingMedia}
            stopPastingMedia={stopPastingMedia}
          />
        )}
      </VBox>
    )
  }

  return render()

})

export default ScriptEditor