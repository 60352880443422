import React from 'react'
import { BrandedComponentSpec } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent } from '~/ui/components'
import { useBranding } from '../BrandingContext'
import { useComponentBranding } from './hooks'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const ComponentBrandingPreview = memo('ComponentBrandingPreview', (props: Props) => {

  const {componentName, variant = null, spec} = props
  const {guide} = useBranding()

  const [component, flags, size] = useComponentBranding(componentName, variant, spec)

  //------
  // Rendering

  function render() {
    if (component == null) { return null }

    return (
      <BrandedComponent
        branding={component}
        guide={guide}
        variant={flags}
        height={size.height}
        style={{...size}}
      />
    )
  }

  return render()

})

export default ComponentBrandingPreview