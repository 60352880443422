import { cloneDeep, get, set } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { Model, ModelClass } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { ProxyFormModel } from '~/ui/form'

export default class ResourceFormModel<M extends Model> implements ProxyFormModel<M> {

  constructor(
    Model: Constructor<M>,
    model: M | null,
  ) {
    this.Model = Model as ModelClass<M>
    this.model = model
    this.reset()
    makeObservable(this)
  }

  public readonly Model: ModelClass<M>
  public readonly model: M | null

  @computed
  public get defaults(): Partial<M> {
    return cloneDeep(this.model) ?? {}
  }

  @observable.shallow
  protected __data: Record<string, any> = {}

  public getValue(name: keyof M) {
    if (name !== 'name' && name in this) {
      return (this as any)[name]
    } else {
      const value = get(this.__data, name)
      if (value !== undefined) {
        return value
      } else {
        return get(this.defaults, name)
      }
    }
  }

  @action
  public assign(updates: Partial<M>) {
    for (const [name, value] of Object.entries(updates)) {
      if (name !== 'name' && name in this) {
        Object.assign(this, {[name]: value})
      } else {
        const copy = cloneDeep(this.__data)
        set(copy, name, value)
        this.__data = copy
      }
    }
  }

  public async submit() {
    const data = this.serializeData()

    if (this.model == null) {
      return await dataStore.create(this.Model, data)
    } else {
      return await dataStore.update(this.Model, this.model.id, data)
    }
  }

  protected buildData(): Partial<M> {
    const model: Partial<M> = {}
    for (const [path, value] of Object.entries(this.__data)) {
      set(model, path, value)
    }
    return model
  }

  protected serializeData(): AnyObject {
    const data = this.buildData()
    return this.Model.serializePartial(data)
  }

  @action
  public reset() {
    this.__data = {}

    if (this.Model.scopedToModule && this.model?.module == null && projectStore.projectID != null) {
      this.assign({
        module: projectStore.projectID,
      } as Partial<M>)
    }
  }

}