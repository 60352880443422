import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps, useHistory } from 'react-router'
import Toast from 'react-toast'
import { StringParam, useQueryParam } from 'use-query-params'
import { InvalidToken } from '~/stores'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import { useMountTrace } from '~/ui/hooks'
import AuthResetForm from './AuthResetForm'
import AuthResetFormModel from './AuthResetFormModel'

export interface Props extends RouteComponentProps {}

const AuthResetScreen = observer('AuthResetScreen', (props: Props) => {

  const [token] = useQueryParam('token', StringParam)

  useMountTrace('AuthResetScreen')

  const [t] = useTranslation('auth')

  const history = useHistory()
  const formModel = React.useMemo(
    () => new AuthResetFormModel(token ?? null),
    [token],
  )

  const toastInvalidToken = React.useCallback(() => {
    Toast.show({
      type:   'error',
      title:  t('errors.invalid-token.title'),
      detail: t('errors.invalid-token.detail'),
    })
  }, [t])

  const toastSuccess = React.useCallback(() => {
    Toast.show({
      type:   'error',
      title:  t('auth_reset.success.title'),
      detail: t('auth_reset.success.detail'),
    })
  }, [t])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      toastSuccess()
      history.replace('/login')
    }
    if (result.status === 'error' && result.error instanceof InvalidToken) {
      toastInvalidToken()
      history.replace('/login')
    }
  }, [history, toastInvalidToken, toastSuccess])

  //------
  // Rendering

  function render() {
    return (
      <AuthResetForm
        model={formModel}
        afterSubmit={afterSubmit}
        backHref='/login'
      />
    )
  }

  return render()

})

export default AuthResetScreen