import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { upperFirst } from 'lodash'
import { ModelEndpoint } from '~/stores'
import { memo } from '~/ui/component'
import { HBox, Label, Panel, SearchField, SVG, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import Pagination from './Pagination'
import TotalsLabel from './TotalsLabel'

export interface Props {
  endpoint: ModelEndpoint<any>

  searchable?: boolean
  search:      string | null
  setSearch:   (search: string | null) => any

  page:      number
  setPage:   (page: number) => any
}

const ResourceCollectionSummaryPanel = memo('ResourceCollectionSummaryPanel', (props: Props) => {

  const {endpoint, searchable = true, search, setSearch, page, setPage}  = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel>
        {renderHeader()}
        {renderPagination()}
        {renderSearch()}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <ResourceCollectionSummaryPanelHeader
        {...props}
      />
    )
  }

  function renderPagination() {
    return (
      <VBox classNames={$.pagination}>
        <Pagination
          endpoint={endpoint}
          page={page}
          setPage={setPage}
        />
      </VBox>
    )
  }

  function renderSearch() {
    if (!searchable) { return null }

    return (
      <VBox classNames={$.search}>
        <SearchField
          ref={searchFieldRef}
          search={search}
          onSearch={setSearch}
          debounce={500}
          inputStyle='light'
        />
      </VBox>
    )
  }

  //------
  // Keyboard

  const searchFieldRef = React.useRef<HTMLInputElement>(null)

  const focusOnSearchField = React.useCallback(() => {
    searchFieldRef.current?.focus()
  }, [])

  useHotkey('/', focusOnSearchField)

  return render()

})

const ResourceCollectionSummaryPanelHeader = memo('ResourceCollectionSummaryPanelHeader', (props: Props) => {

  const {endpoint} = props

  const {Model} = endpoint
  const {plural} = useResourceTranslation()

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.header} gap={layout.padding.inline.s}>
        <HBox gap={layout.padding.inline.l}>
          {renderIcon()}
          {renderLabels()}
        </HBox>
      </VBox>
    )
  }

  function renderIcon() {
    return (
      <SVG
        name={Model.$icon}
        size={layout.icon.l}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox flex>
        <Label h3>
          {upperFirst(plural())}
        </Label>
        <TotalsLabel
          endpoint={endpoint}
          small
          dim
        />
      </VBox>
    )
  }

  return render()

})

export default ResourceCollectionSummaryPanel

const useStyles = createUseStyles(theme => ({
  header: {
    padding: [layout.padding.inline.l, layout.padding.inline.l],
  },

  pagination: {
    borderTop: [1, 'solid', theme.fg.dimmer],
    padding:   [layout.padding.inline.l, layout.padding.inline.l],
  },

  search: {
    borderTop: [1, 'solid', theme.fg.dimmer],
    padding:   [layout.padding.inline.l, layout.padding.inline.l],
  },
}))