import { ModelClass } from '../Model'
import { ImportMeta } from './types'

const REGISTRY = new WeakMap<ModelClass<any>, ImportMeta>()

export function modelIsImportable(Model: ModelClass<any>) {
  return REGISTRY.has(Model)
}

export function importMetaFor(Model: ModelClass<any>) {
  return REGISTRY.get(Model) ?? null
}

export function ensureImportMeta(Model: ModelClass<any>) {
  let meta = REGISTRY.get(Model)
  if (meta == null) {
    REGISTRY.set(Model, meta = ImportMeta.default())
  }

  return meta
}