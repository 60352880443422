import React from 'react'
import { arrayMove } from 'ytil'
import { ClientTab, ClientTabTemplate, Project } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { useForm } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import ProjectSettingsFormModel from '../ProjectSettingsFormModel'

interface ClientTabsContext {
  project: Project | null

  editingItems:      boolean
  startEditingItems: () => any
  stopEditingItems:  () => any

  editingItemIndex:    number | null
  setEditingItemIndex: (index: number | null) => any

  formOpen:  boolean
  openForm:  () => any
  closeForm: () => any

  addTab:    (tab: ClientTab) => any
  removeTab: (index: number) => any
  moveTab:   (originalIndex: number, newIndex: number) => any

  templates: ClientTabTemplate[]
  tabs:      ClientTab[]
}

interface ClientTabsContextProviderProps {
  children?: React.ReactNode
}

const ClientTabsContext = React.createContext<ClientTabsContext>({
  project: null,

  editingItems:      false,
  startEditingItems: () => void 0,
  stopEditingItems:  () => void 0,

  editingItemIndex:    null,
  setEditingItemIndex: () => void 0,

  formOpen:  false,
  openForm:  () => void 0,
  closeForm: () => void 0,

  addTab:    () => void 0,
  removeTab: () => void 0,
  moveTab:   () => void 0,

  templates: [],
  tabs:      [],
})

const ClientTabsContextProvider = observer('TabManagerContextProvider', (props: ClientTabsContextProviderProps) => {

  const [editingItems, startEditingItems, stopEditingItems] = useBoolean(false)
  const [editingItemIndex, setEditingItemIndex] = React.useState<number | null>(null)
  const [formOpen, openForm, closeForm] = useBoolean(false)

  const {model} = useForm<ProjectSettingsFormModel>()

  const project   = model.project
  const templates = projectStore.availableClientTabTemplates

  const tabs      = React.useMemo(
    () => model.clientTabs ?? [],
    [model.clientTabs],
  )

  const addTab = React.useCallback((tab: ClientTab) => {
    model.clientTabs.push(tab)
    model.submit()
  }, [model])

  const removeTab = React.useCallback((index: number) => {
    model.clientTabs.splice(index, 1)
    model.submit()
  }, [model])

  const moveTab = React.useCallback((originalIndex: number, newIndex: number) => {
    model.clientTabs = arrayMove(model.clientTabs, originalIndex, newIndex)
    model.submit()
  }, [model])

  const context = React.useMemo(() => ({
    project,

    editingItems,
    startEditingItems,
    stopEditingItems,

    formOpen,
    openForm,
    closeForm,

    editingItemIndex,
    setEditingItemIndex,

    addTab,
    removeTab,
    moveTab,

    tabs,
    templates,
  }), [project, addTab, removeTab, moveTab, editingItems, startEditingItems, stopEditingItems, editingItemIndex, setEditingItemIndex, tabs, templates, formOpen, openForm, closeForm])

  return(
    <ClientTabsContext.Provider value={context}>
      {props.children}
    </ClientTabsContext.Provider>
  )
})

export default ClientTabsContextProvider

export function useClientTabs() {
  return React.useContext(ClientTabsContext)
}