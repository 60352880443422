import React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash'
import { sparse } from 'ytil'
import { Model, ModelClass, scopedToProject } from '~/models'
import { observer } from '~/ui/component'
import {
  AutoCompleteField,
  AutoCompleteFieldProps,
  AutoCompleteSection,
  Label,
  ListItem,
} from '~/ui/components'
import { useFiniteModelSetData, useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceListItem, ResourceListItemProps } from '../components'

export type Props<M extends Model> = ValuelessProps<M> | SingleProps<M> | MultiProps<M>

export interface CommonProps<M extends Model> {
  Model:        ModelClass<M>
  placeholder?: string

  noResultsPlaceholder?: AutoCompleteFieldProps<string, M>['noResultsPlaceholder']
  createPlaceholder?:    AutoCompleteFieldProps<string, M>['createPlaceholder']
  requestCreate?:        AutoCompleteFieldProps<string, M>['requestCreate']
  onSelect?:             (model: M) => any
  onSelectParameter?:    (placeholder: string) => any
  onClear?:              () => any

  shortcutsCaption?: string
  shortcutIDs?:      string[]

  placeholdersCaption?: string
  placeholderNames?:    string[]
  placeholderDetail?:   (placeholder: string) => React.ReactNode

  excludeIDs?: string[]
  detached?:   boolean

  renderPropsForModel?: (model: M) => Partial<ModelRenderProps>

  filters?: Record<string, string | null>
  linked?:  boolean

  enabled?:  boolean
  readOnly?: boolean

  small?:       boolean
  smallChips?:  boolean
  inputStyle?:  AutoCompleteFieldProps<any, any>['inputStyle']
}

export type ModelRenderProps = Omit<ResourceListItemProps, 'model'>

export interface ValuelessProps<M extends Model> extends CommonProps<M> {
  value?:    never
  onChange?: never
  multi?:    false
}

export interface SingleProps<M extends Model> extends CommonProps<M> {
  value:    string | null
  onChange: (value: string | null) => any
  multi?:   false
}

export interface MultiProps<M extends Model> extends CommonProps<M> {
  value:    string[]
  onChange: (value: string[]) => any
  multi:    true
}

const _ResourceField = <M extends Model>(props: Props<M>) => {

  const {
    Model,
    multi,
    value,
    onChange,
    filters,
    linked = scopedToProject(Model),
    shortcutsCaption,
    shortcutIDs = [],
    placeholdersCaption,
    placeholderNames = [],
    placeholderDetail,
    renderPropsForModel,
    detached = true,
    excludeIDs = [],
    enabled = true,
    readOnly = false,
    onSelect,
    onSelectParameter,
    ...rest
  } = props

  //------
  // Fetch

  // Ensure that all models currently in the list are fetched, otherwise the field shows up empty.

  const ids      = React.useMemo(() => isArray(value) ? value : value != null ? [value] : [], [value])
  const [models] = useFiniteModelSetData(Model, ids, {
    detail:   false,
    filters:  filters,
    label:    linked ? 'linked' : undefined,
    include:  Model.include,
  })

  //------
  // Searching

  const [shortcuts, shortcutsEndpoint] = useFiniteModelSetData(Model, shortcutIDs, {
    detail:   false,
    label:    linked ? 'linked' : undefined,
    include:  Model.include,
  })

  const searchEndpoint = useModelEndpoint(Model, {
    include:  Model.include,
    filters:  filters,
    label:    linked ? 'linked' : undefined,
    detached: detached,
    fetch:    false,
  })

  const fetchMore = React.useCallback(() => {
    return searchEndpoint.fetchMore()
  }, [searchEndpoint])

  const searchResults = React.useMemo(() => {
    return searchEndpoint.data
      .filter(res => !ids.includes(res.id))
      .filter(res => !excludeIDs.includes(res.id))
  }, [excludeIDs, ids, searchEndpoint.data])

  const placeholdersSection = React.useMemo((): AutoCompleteSection<ResourceFieldChoice<M>> | null => {
    if (placeholderNames.length > 0) {
      return {
        caption: placeholdersCaption,
        choices: placeholderNames,
      }
    } else {
      return null
    }
  }, [placeholderNames, placeholdersCaption])

  const shortcutsSection = React.useMemo((): AutoCompleteSection<ResourceFieldChoice<M>> | null => {
    if (shortcutsEndpoint.fetchStatus === 'fetching') {
      return {
        caption: shortcutsCaption,
        loading: true,
        choices: [],
      }
    } else if (shortcuts.length > 0) {
      return {
        caption: shortcutsCaption,
        choices: shortcuts,
      }
    } else {
      return null
    }
  }, [shortcuts, shortcutsCaption, shortcutsEndpoint.fetchStatus])

  const searchResultsSection = React.useMemo((): AutoCompleteSection<ResourceFieldChoice<M>> => ({
    choices: searchResults,
  }), [searchResults])

  const sections = React.useMemo((): AutoCompleteSection<ResourceFieldChoice<M>>[] => {
    return sparse([placeholdersSection, shortcutsSection, searchResultsSection])
  }, [placeholdersSection, searchResultsSection, shortcutsSection])

  const search = React.useCallback(async (search: string | null) => {
    await searchEndpoint.fetchWithParams({search})
  }, [searchEndpoint])

  const [t]       = useTranslation('resource_field')
  const resourceT = useResourceTranslation(Model.resourceType)
  const singular  = resourceT.singular()
  const plural    = resourceT.plural()

  const defaultNoResultsPlaceholder = t('no_results', {singular, plural})
  const defaultCreatePlaceholder = React.useCallback((name: string | null) => {
    if (name != null) {
      return t('create_named', {singular, plural, name})
    } else {
      return t('create', {singular, plural})
    }
  }, [plural, singular, t])

  //------
  // Handlers

  const handleSelect = React.useMemo(() => {
    if (onSelect == null && onSelectParameter == null) { return }

    return (choice: ResourceFieldChoice<M>) => {
      if (isParameterChoice(choice)) {
        return onSelectParameter?.(choice)
      } else {
        return onSelect?.(choice)
      }
    }
  }, [onSelect, onSelectParameter])

  //------
  // Rendering

  function render() {
    return (
      <AutoCompleteField<string, ResourceFieldChoice<M>>
        multi={multi}
        value={value as any}
        onChange={onChange as any}
        onSearch={search}
        results={sections}
        throttle={500}
        renderChoice={renderChoice}
        iconForChoice={iconForChoice}
        captionForChoice={captionForChoice}
        detailForChoice={detailForChoice}
        valueForChoice={valueForChoice}
        choiceForValue={choiceForValue}
        noResultsPlaceholder={props.noResultsPlaceholder ?? defaultNoResultsPlaceholder}
        createPlaceholder={props.createPlaceholder ?? defaultCreatePlaceholder}
        onEndReached={fetchMore}
        onSelect={handleSelect}
        enabled={enabled}
        readOnly={readOnly}
        {...rest}
      />
    )
  }

  const detailForChoice = React.useCallback((choice: ResourceFieldChoice<M>) => {
    if (isParameterChoice(choice)) { return null }
    return renderPropsForModel?.(choice)?.detail
  }, [renderPropsForModel])

  const renderChoice = React.useCallback((choice: ResourceFieldChoice<M>) => {
    if (isParameterChoice(choice)) {
      return (
        <ListItem
          image='code'
          caption={<Label mono bold>{choice}</Label>}
          detail={placeholderDetail?.(choice)}
        />
      )
    } else {
      return (
        <ResourceListItem
          model={choice}
          {...renderPropsForModel?.(choice)}
        />
      )
    }
  }, [placeholderDetail, renderPropsForModel])

  const choiceForValue = React.useCallback(
    (id: string) => models.find(it => it.id === id) ?? null,
    [models],
  )

  const valueForChoice = React.useCallback(
    (choice: ResourceFieldChoice<M>) => isParameterChoice(choice) ? choice : choice.id,
    [],
  )

  const iconForChoice = React.useCallback((choice: ResourceFieldChoice<M>) => {
    if (isParameterChoice(choice)) { return 'code' }
    return choice.$icon
  }, [])

  const captionForChoice = React.useCallback(
    (choice: ResourceFieldChoice<M>) => isParameterChoice(choice) ? choice : choice.$caption,
    [],
  )

  return render()

}

type ResourceFieldChoice<M extends Model> = M | ParameterChoice
type ParameterChoice = string

function isParameterChoice(item: ResourceFieldChoice<any>): item is ParameterChoice {
  return typeof item === 'string'
}

const ResourceField = observer('ResourceField', _ResourceField) as typeof _ResourceField
export default ResourceField