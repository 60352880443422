import React from 'react'
import { BorderSpec } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, Label } from '~/ui/components'
import { ComponentBranding, layout } from '~/ui/styling'
import { useBranding } from '../BrandingContext'

export interface Props {
  name:  string
  spec?: BorderSpec
}

const BrandingBorderSwatch = memo('BrandingBorderSwatch', (props: Props) => {

  const {name, spec} = props

  const {guide, branding} = useBranding()

  const component = React.useMemo(() => new ComponentBranding(guide, null, {
    background: 'none',
    border:     {...branding.borders[name], ...spec},
    shape:      'rectangle',
    depth:      1,
  }), [branding.borders, guide, name, spec])

  //------
  // Rendering

  function render() {
    return (
      <BrandedComponent
        guide={guide}
        branding={component}
        height={swatchSize.height}
        style={{...swatchSize, borderRadius: layout.radius.s}}
        children={<Center flex><Label>•</Label></Center>}
      />
    )
  }

  return render()

})

export const swatchSize = {
  width:  24,
  height: 24,
}

export default BrandingBorderSwatch