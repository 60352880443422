import React from 'react'
import { useTranslation } from 'react-i18next'
import { Module, Ref } from '~/models'
import ModuleField from '~/ui/app/modules/ModuleField'
import { observer } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField, FormSwitch } from '~/ui/form'
import { layout } from '~/ui/styling'
import ModuleFormModel from './ModuleFormModel'

export interface Props<M extends ModuleFormModel> extends Omit<FormDialogProps<M>, 'children'> {
  owningModule?: Ref<Module>
  commonFields?: React.ReactNode
}

const _ModuleForm = <M extends ModuleFormModel>(props: Props<M>) => {

  const {owningModule, commonFields, model, ...rest} = props

  const [t] = useTranslation('flow_planner')

  const excludeIDs = React.useMemo(() => {
    if (owningModule == null) { return [] }
    return [owningModule]
  }, [owningModule])

  const createNew = React.useCallback((name: string) => {
    model.createNew(name)
  }, [model])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog {...rest} model={model} title={t('module_form.title')}>
        <VBox gap={layout.padding.m}>
          {commonFields}
          {renderFields()}
          {renderCreateFields()}
        </VBox>
      </FormDialog>
    )
  }

  function renderFields() {
    if (model.creatingNew) { return null }

    return (
      <FormField name='existing' required caption={t('module_form.fields.module.prompt')!}>
        {bind => (
          <ModuleField
            {...bind}
            excludeMain
            excludeIDs={excludeIDs}
            requestCreate={createNew}
          />
        )}
      </FormField>
    )
  }

  function renderCreateFields() {
    if (!model.creatingNew) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormSwitch name='plannerType'>
          <FormSwitch.TypeOption
            type='flow'
            title={t('modules:planner_types.flow.caption')}
            detail={t('modules:planner_types.flow.caption')}
          />
          <FormSwitch.TypeOption
            type='calendar'
            title={t('modules:planner_types.calendar.caption')}
            detail={t('modules:planner_types.calendar.caption')}
          />
        </FormSwitch>
      </VBox>
    )
  }

  return render()

}

const ModuleForm = observer('ModuleForm', _ModuleForm) as typeof _ModuleForm
export default ModuleForm