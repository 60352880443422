import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, Label, Panel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props {
  endpoint: ModelEndpoint<any>
}

const SelectionPanel = observer('SelectionPanel', (props: Props) => {

  const {endpoint} = props

  const manager = useSelectionManager()

  const selected    = manager?.selectedKeys.length ?? 0
  const available   = manager?.available.size ?? 0
  const total       = endpoint.meta?.total ?? 0
  const allSelected = manager?.allSelected

  const {t} = useResourceTranslation()

  //------
  // Callbacks

  const selectNone = React.useCallback(() => {
    manager?.selectNone()
  }, [manager])

  const selectAllAvailable = React.useCallback(() => {
    manager?.selectAllAvailable()
  }, [manager])

  const selectAllFiltered = React.useCallback(() => {
    manager?.selectAll()
  }, [manager])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.selectionPanel} padding={layout.padding.m} gap={layout.padding.m}>
        {renderTotals()}
        {renderSelectionButtons()}
      </Panel>
    )
  }

  function renderTotals() {
    return (
      <VBox classNames={[$.totals, {allSelected}]}>
        <ThemeProvider dark={allSelected}>
          <Label small markup align='center'>
            {t('selection.totals', {
              selected: allSelected ? total : selected,
              count:    allSelected ? total : available,
            })}
          </Label>
        </ThemeProvider>
      </VBox>
    )
  }

  function renderSelectionButtons() {
    return (
      <VBox gap={layout.padding.inline.m} align='center'>
        {selected < available && (
          <ClearButton
            icon='check'
            caption={t('selection.select_all_visible', {count: available})}
            onTap={selectAllAvailable}
            small
          />
        )}
        {total > available && !allSelected && (
          <ClearButton
            icon='double-check'
            caption={t('selection.select_all_filtered', {count: total})}
            onTap={selectAllFiltered}
            small
          />
        )}
        <ClearButton
          icon='cross'
          caption={t('selection.clear')}
          onTap={selectNone}
          padding='vertical'
          dim
          small
        />
      </VBox>
    )
  }

  return render()

})

export default SelectionPanel

const useStyles = createUseStyles(theme => ({
  selectionPanel: {
    boxShadow: [0, 0, 4, 0, theme.semantic.primary],
  },

  totals: {
    padding:      layout.padding.inline.m,

    '&.allSelected': {
      background:   theme.semantic.primary,
      borderRadius: layout.radius.s,
    },
  },
}))