export enum GradientDirection {
  WE,
  NWSE,
  NS,
  NESW,
  EW,
  SENW,
  SN,
  SWNE,
}

export function angleToGradientDirection(angle: number): GradientDirection {
  if (angle < 22.5) {
    return GradientDirection.SN
  } else if (angle >= 22.5 && angle < 67.5) {
    return GradientDirection.SWNE
  } else if (angle > 67.5 && angle <= 112.5) {
    return GradientDirection.WE
  } else if (angle > 112.5 && angle <= 157.5) {
    return GradientDirection.NWSE
  } else if (angle > 157.5 && angle <= 202.5) {
    return GradientDirection.NS
  } else if (angle > 202.5 && angle <= 247.5) {
    return GradientDirection.NESW
  } else if (angle > 247.5 && angle < 292.5) {
    return GradientDirection.EW
  } else {
    return GradientDirection.SENW
  }
}

export function gradientDirectionToAngle(direction: GradientDirection): number {
  switch (direction) {
    case GradientDirection.SN:   return 0
    case GradientDirection.SWNE: return 45
    case GradientDirection.WE:   return 90
    case GradientDirection.NWSE: return 135
    case GradientDirection.NS:   return 180
    case GradientDirection.NESW: return 225
    case GradientDirection.EW:   return 270
    case GradientDirection.SENW: return 315
  }
}