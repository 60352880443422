import React from 'react'
import { useBoundingRectangle } from 'react-measure'
import { CalendarDay, Module, TimeOfDay } from '~/models'
import { observer } from '~/ui/component'
import { Panel, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import { useCalendarLayout } from '../CalendarLayoutContext'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import { CalendarItemFormModel } from '../content/CalendarItemForm'
import CalendarTriggerableItemFormModel from '../content/CalendarTriggerableItemFormModel'
import ModuleItemFormModel from '../content/ModuleItemFormModel'
import { useSelection } from '../SelectionContext'
import CalendarDayContentLayer from './CalendarDayContentLayer'
import CalendarDayCreateLayer, { CreateItemType } from './CalendarDayCreateLayer'
import CalendarDayHeader from './CalendarDayHeader'
import CalendarDayTimelineLayer, { labelWidth } from './CalendarDayTimelineLayer'
import { dayWidth } from './layout'

export interface Props {
  day:   CalendarDay
  first: boolean

  requestEdit:       (day: CalendarDay) => any
  requestCreateItem: (model: CalendarItemFormModel) => Promise<void>
}

const CalendarDayPanel = observer('CalendarDayPanel', (props: Props) => {

  const {day, first, requestEdit, requestCreateItem} = props
  const {planner} = useCalendarPlanner()
  const plan      = planner.plan
  const [module]  = useModelDocumentData(Module, plan?.module ?? null)

  const {transform, setDayRect} = useCalendarLayout.unoptim()
  const {manager} = useSelection()

  const bodyRef = React.useRef<HTMLDivElement>(null)

  useBoundingRectangle(bodyRef, React.useCallback(rect => {
    if (first) { setDayRect(rect) }
    manager?.setDayBodyRect(day.uuid, {
      left:   rect.left + labelWidth + layout.padding.inline.m,
      top:    rect.top,
      width:  rect.width - (labelWidth + layout.padding.inline.m),
      height: rect.height,
    })
  }, [day.uuid, first, manager, setDayRect]))

  React.useEffect(() => {
    return () => {
      manager?.deleteDayBodyRect(day.uuid)
    }
  }, [day.uuid, manager])

  //------
  // Callbacks

  const buildCreateFormModel = React.useCallback((type: CreateItemType, time: TimeOfDay) => {
    if (planner == null || module == null) { return null }

    if (type === 'module') {
      return new ModuleItemFormModel(planner, day.uuid, null, null, time)
    } else {
      if (plan?.module == null) { return null }
      return new CalendarTriggerableItemFormModel(planner, day.uuid, null, null, type, time, module)
    }
  }, [day.uuid, module, plan?.module, planner])

  const createItem = React.useCallback(async (type: CreateItemType, time: TimeOfDay) => {
    const model = buildCreateFormModel(type, time)
    if (model == null) { return }

    return requestCreateItem?.(model)
  }, [buildCreateFormModel, requestCreateItem])

  const edit = React.useCallback(() => {
    requestEdit?.(day)
  }, [day, requestEdit])

  const remove = React.useCallback(() => {
    return planner.removeDay(day.uuid)
  }, [day.uuid, planner])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.calendarDayPanelContainer}>
        <Panel flex classNames={$.calendarDayPanel}>
          <VBox classNames={$.header}>
            <CalendarDayHeader
              day={day}
              requestRemove={remove}
              requestEdit={edit}
            />
          </VBox>
          <VBox flex classNames={$.layerContainer} ref={bodyRef}>
            {/* <CalendarDayInteractionLayer
              transform={transform}
            /> */}
            <CalendarDayTimelineLayer
              transform={transform}
            />
            <CalendarDayContentLayer
              day={day}
              transform={transform}
            />
            <CalendarDayCreateLayer
              transform={transform}
              requestCreateItem={createItem}
            />
          </VBox>
        </Panel>
      </VBox>
    )
  }

  return render()

})

export default CalendarDayPanel

export const width = dayWidth

const useStyles = createUseStyles({
  calendarDayPanelContainer: {
    pointerEvents: 'none',
  },

  calendarDayPanel: {
    width: width,
  },

  header: {
    pointerEvents: 'auto',
  },

  layerContainer: {
    position: 'relative',
  },
})