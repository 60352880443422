import React from 'react'
import { Module } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { Chip, ChipProps } from '~/ui/components'
import { ModelDocumentOptions, useModelDocumentData } from '~/ui/hooks/data'
import { colors, createUseStyles, useTheme } from '~/ui/styling'
import ProjectLabel from '../projects/ProjectLabel'

export interface Props extends Omit<ChipProps, 'icon' | 'children'>, ModelDocumentOptions {
  id: string
}

const ModuleChip = observer('ModuleChip', (props: Props) => {

  const {id, fetch = 'never', detail = false, ...rest} = props

  const [model, {fetchStatus}] = useModelDocumentData(Module, id, {fetch, detail})
  const isMainModule = id === projectStore.projectID

  const isOwnproject = model?.project === projectStore.projectID

  //------
  // Rendering

  const $ = useStyles()
  const {colors} = useTheme()

  function render() {
    if (isMainModule) { return null }

    if (model?.project != null && !isOwnproject) {
      return (
        <Chip small backgroundColor={colors.bg.alt}>
          <ProjectLabel projectID={model.project} tiny bold/>
        </Chip>
      )
    }

    return (
      <Chip
        {...rest}
        classNames={$.ModuleChip}
        icon='puzzle'
        loading={fetchStatus === 'fetching'}
        children={model?.$caption}
        bold
      />
    )
  }

  return render()

})

export default ModuleChip

const useStyles = createUseStyles(theme => ({
  ModuleChip: {
    backgroundImage: colors.linearGradient(
      'bottom left',
      theme.semantic.primary,
      theme.semantic.secondary,
    ),
  },
}))