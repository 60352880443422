import { WidgetComponentType } from '../types'

const REGISTRY = new Map<string, WidgetComponentType>()

export function widget(type: string) {
  return (target: WidgetComponentType) => {
    REGISTRY.set(type, target)
  }
}

export function WidgetComponentForType(type: string) {
  return REGISTRY.get(type) ?? null
}