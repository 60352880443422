import React from 'react'
import { useTranslation } from 'react-i18next'
import { runInAction } from 'mobx'
import { SegueDelay } from '~/models'
import { TimeOfDayField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { Center, Empty, SegmentedButton, TextBlock, TimeIntervalField, VBox } from '~/ui/components'
import { ButtonSegment } from '~/ui/components/SegmentedButton'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import DaySelectorFields from '../../days/DaySelectorFields'
import SegueDelayFormModel from './SegueDelayFormModel'

export interface Props {}

const SegueDelayFormTimingStep = observer('SegueDelayFormTimingStep', () => {

  const [t] = useTranslation('flow_planner')
  const {model: formModel} = useForm<SegueDelayFormModel>()

  const typeButtonSegments = React.useMemo((): ButtonSegment<SegueDelay['type']>[] => [{
    value:   'fixed',
    icon:    'stopwatch',
    caption: t('delay_form.fixed.caption'),
  }, {
    value:   'day',
    icon:    'calendar',
    caption: t('delay_form.day.caption'),
  }], [t])

  const setType = React.useCallback((type: SegueDelay['type']) => {
    runInAction(() => {
      formModel.type = type
    })
  }, [formModel])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderTypeSelector()}
        {formModel.type == null ? (
          renderNoDelay()
        ) : formModel.type === 'fixed' ? (
          renderFixedFields()
        ) : (
          renderDayFields()
        )}
      </VBox>
    )
  }

  function renderTypeSelector() {
    return (
      <Center>
        <SegmentedButton
          segments={typeButtonSegments}
          selectedValue={formModel.type}
          onChange={setType}
        />
      </Center>
    )
  }

  function renderNoDelay() {
    return (
      <Empty
        {...t('delays.none')}
        icon='stopwatch'
        dim
      />
    )
  }

  function renderFixedFields() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('delay_form.fixed.instructions')}
        </TextBlock>

        <FormField name='interval' caption={t('segues.fields.delay.interval')!}>
          {bind => <TimeIntervalField {...bind} allowClear={false} autoFocus/>}
        </FormField>
      </VBox>
    )
  }

  function renderDayFields() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('delay_form.day.instructions')}
        </TextBlock>

        <DaySelectorFields
          selectorModelProp='daySelectorModel'
        />
        <FormField name='time' caption={t('delay_form.fields.time.prompt')}>
          {bind => <TimeOfDayField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default SegueDelayFormTimingStep