import React from 'react'
import { ContentState } from 'draft-js'
import { Link } from '~/models'
import { LinkForm, LinkFormModel } from '~/ui/app/links'
import { memo } from '~/ui/component'
import { Popup, Tappable } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, presets } from '~/ui/styling'
import { useRichTextBackend } from '../../RichTextFieldContext'
import DraftJSDecorator from '../DraftJSDecorator'
import LinkDecoratorPopup from './LinkDecoratorPopup'

export interface Props {
  contentState: ContentState
  entityKey:    string
  blockKey:     string
  start:        number
  end:          number

  children?:    React.ReactNode
}

const LinkDecorator = memo('LinkDecorator', (props: Props) => {

  const {
    contentState,
    entityKey,
    blockKey,
    start,
    end,
    children,
  } = props

  const block  = contentState.getBlockForKey(blockKey)
  const text   = block.getText().slice(start, end)
  const entity = contentState.getEntity(entityKey)
  const data   = entity?.getData()

  const link = React.useMemo((): Link => {
    if (data == null) return Link.empty()

    if ('link' in data) return data.link
    if ('url' in data) return Link.to(data.href)
    return Link.empty()
  }, [data])

  const [popupOpen, openPopup, closePopup] = useBoolean()
  const [formOpen, openForm, closeForm] = useBoolean()

  const targetRef = React.useRef<HTMLDivElement>(null)

  //------
  // Callbacks

  const backend = useRichTextBackend()

  const edit = React.useCallback(() => {
    openForm()
  }, [openForm])

  const save = React.useCallback((link: Link | null, caption: string | null): Promise<SubmitResult> => {
    if (link == null) {
      backend.removeLink(blockKey, start, end)
    } else {
      backend.updateLink(blockKey, start, end, link, caption ?? undefined)
    }
    return Promise.resolve({status: 'ok'})
  }, [backend, blockKey, end, start])

  const remove = React.useCallback(() => {
    backend.removeLink(blockKey, start, end)
    closePopup()
  }, [backend, blockKey, closePopup, end, start])

  const linkFormModel = React.useMemo(
    () => new LinkFormModel(save, link, text),
    [link, save, text],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <DraftJSDecorator modals={renderModals()}>
        <Tappable classNames={$.link} onTap={openPopup} ref={targetRef}>
          {children}
        </Tappable>
      </DraftJSDecorator>
    )
  }

  function renderModals() {
    return (
      <>
        <Popup
          open={popupOpen}
          requestClose={closePopup}
          renderContent={renderPopup}
          classNames={$.popup}
          targetRef={targetRef}
        />

        <LinkForm
          open={formOpen}
          requestClose={closeForm}
          model={linkFormModel}
          caption='edit'
        />
      </>
    )
  }

  function renderPopup() {
    return (
      <LinkDecoratorPopup
        link={link}
        requestRemove={remove}
        requestEdit={edit}
      />
    )
  }

  return render()

})

export default LinkDecorator

const useStyles = createUseStyles(theme => ({
  link: {
    display: 'inline-block',
    color:   theme.fg.link,

    position: 'relative',
    '&:hover': {
      ...presets.overlayAfter({
        top:    -2,
        left:   -2,
        right:  -2,
        bottom: -2,

        background:   theme.bg.subtle,
        borderRadius: layout.radius.s,
      }),
    },
  },

  popup: {
    background: theme.bg.alt,
  },
}))