
import React from 'react'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { makeObservable, observable } from 'mobx'
import { AnswersQuery } from '~/models'
import { memo } from '~/ui/component'
import {
  FormDialog,
  FormDialogProps,
  FormError,
  FormField,
  FormModel,
  SubmitResult,
} from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'

export type Props = FormDialogProps<AnswersWidgetFormModel>

const AnswersWidgetForm = memo('AnswersWidgetForm', (props: Props) => {

  const [t] = useTranslation('widgets')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog {...props} icon='column-chart' title={t('answers.title')} semi={false}>
        <FormField name='query' {...t('answers.fields.query')} caption={t('answers.fields.query.prompt')} required>
          {bind => <ResourceField Model={AnswersQuery} {...bind}/>}
        </FormField>
      </FormDialog>
    )
  }

  return render()

})

export default AnswersWidgetForm

export class AnswersWidgetFormModel implements FormModel {

  constructor(
    private readonly params: Record<string, any>,
    private readonly setParams: (params: Record<string, any>) => any,
  ) {
    makeObservable(this)
  }

  @observable
  public query: string | null = this.params.query ?? null

  public async submit(): Promise<SubmitResult | undefined> {
    const result = this.validate()
    if (result.status !== 'ok') { return result }

    this.setParams({
      query: this.query,
    })
    return {status: 'ok'}
  }

  private validate(): SubmitResult {
    const errors: FormError[] = []

    if (this.query == null) {
      errors.push({field: 'query', message: i18next.t('validation:required')})
    }

    return errors.length === 0 ? {status: 'ok'} : {status: 'invalid', errors}
  }

}