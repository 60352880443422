import React from 'react'
import { Model } from '~/models'
import { BulkSelector, ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { AssistantFormDialog, SubmitResult } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction } from '~/ui/resources/collection'
import CopyStep from './CopyStep'
import CopyToProjectFormModel, { CopyToProjectStep } from './CopyToProjectFormModel'
import DestinationStep from './DestinationStep'
import PreflightStep from './PreflightStep'

export interface Props<M extends Model> {
  open:          boolean
  requestClose?: () => any

  action:   BulkAction<M>
  endpoint: ModelEndpoint<M>
  selector: BulkSelector
  count:    number

  afterSubmit?: (result: SubmitResult) => any
}

const CopyToProjectForm = observer('CopyToProjectForm', (props: Props<any>) => {

  const {
    open,
    requestClose,
    endpoint,
    action,
    selector,
    count,
    afterSubmit,
  } = props

  const formModel = React.useMemo(
    () => new CopyToProjectFormModel(endpoint, selector),
    [endpoint, selector],
  )

  const copying = formModel.status === 'copying'

  const {linkages} = formModel

  const preflight = React.useCallback(() => {
    formModel.preflight()
  }, [formModel])

  const {t, actionCaption} = useResourceTranslation()

  const steps = React.useMemo(() => {
    const steps: CopyToProjectStep[] = []

    steps.push(CopyToProjectStep.destination)
    if (linkages.length > 0) {
      steps.push(CopyToProjectStep.preflight)
    }
    steps.push(CopyToProjectStep.copy)

    return steps
  }, [linkages.length])

  const [step, setStep] = React.useState<CopyToProjectStep>(CopyToProjectStep.destination)
  const prevStep = usePrevious(step)

  const captionForStep = React.useCallback((step: CopyToProjectStep) => {
    return t(`actions.copy_to_project.steps.${step}`)
  }, [t])

  React.useEffect(() => {
    if (prevStep === 'preflight' && step === 'copy') {
      formModel.copy()
    }
  }, [formModel, prevStep, step])

  //------
  // Rendering

  function render() {
    return (
      <AssistantFormDialog<CopyToProjectFormModel, CopyToProjectStep>
        open={open}
        requestClose={requestClose}
        model={formModel}
        afterSubmit={afterSubmit}
        icon={action.icon}
        title={actionCaption(action.name, {count})}

        // This form doesn't submit at the end. The copying is done half way.
        submitButton={{caption: t('buttons:done')}}
        working={copying}

        steps={steps}
        currentStep={step}
        requestStep={setStep}
        captionForStep={captionForStep}
        renderStep={renderStep}
        showCancelButtonOnLastStep={false}
        allowSelectStep={copying ? 'none' : 'previous'}

        autoFocus={false}
        closeOnSuccess={false}
        onWillOpen={preflight}

        width={720}
        semi={false}
      />
    )
  }

  const renderStep = React.useCallback((step: CopyToProjectStep) => {
    switch (step) {
      case CopyToProjectStep.destination:
        return <DestinationStep/>
      case CopyToProjectStep.preflight:
        return <PreflightStep/>
      case CopyToProjectStep.copy:
        return <CopyStep count={count}/>
    }
  }, [count])

  return render()

})

export default CopyToProjectForm