import React from 'react'
import { Review } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { TextBlock } from '~/ui/components'
import { isVideoUrl } from '~/ui/util'
import { ReviewQuestionDescriptor } from '../hooks'

export interface Props {
  review:   Review
  question: ReviewQuestionDescriptor
}

const ReviewAnswerView = memo('ReviewAnswerView', (props: Props) => {

  const {review, question} = props

  //------
  // Rendering

  function render() {
    switch (question.feedback.type) {
      case 'media':
        return renderMedia()
      default:
        return renderText()
    }
  }

  function renderMedia() {
    if (isVideoUrl(review.answer as string)) {
      return (
        <video
          controls
          src={review.answer as string}
        />
      )
    }
    return (
      <ImageView
        source={review.answer as string}
      />
    )
  }

  function renderText() {
    return (
      <TextBlock>
        {review.answer}
      </TextBlock>
    )
  }

  return render()

})

export default ReviewAnswerView