import URL from 'url'
import { PlanAnnotation, PlanNode } from '~/models/FlowPlan'

export type DataEnvironment =
  /** Local development. */
  | 'local'
  /** Integration environment. Automatically deployed from the `dev` branch. */
  | 'integration'
  /** Acceptance environment. Automatically deployed from the `release` branch. */
  | 'acceptance'
  /** Live, promoted from acceptance or deployed from hotfix branches. */
  | 'live'

export const APP_NAME        = 'mission'
export const LOCAL_PORT      = 8085
export const LOCAL_WEB_PORT  = 8083
export const LOCAL_SITE_PORT = 8088
export const LOCAL_API_PORT  = 3003
export const APP_URL         = URL.parse(document.location.href)

export function currentEnvironment(): DataEnvironment {
  if (APP_URL.hostname == null) { return 'local' }

  if (/^mission\.acc\./.test(APP_URL.hostname)) {
    return 'acceptance'
  } else if (/^mission\.int\./.test(APP_URL.hostname)) {
    return 'integration'
  } else if (/groundcontrol.app$/.test(APP_URL.hostname)) {
    return 'live'
  } else {
    return 'local'
  }
}

export function currentStack(): string | null {
  if (APP_URL.hostname == null) { return 'local' }

  const match = APP_URL.hostname.match(/^mission(?:\.(?:acc|int))?\.(.+)\.groundcontrol.app$/)
  if (match != null) {
    return ['acc', 'int'].includes(match[1]) ? null : match[1]
  } else {
    return null
  }
}

export function deriveBaseDomain() {
  if (APP_URL.hostname == null) {
    return 'localhost'
  } else {
    return APP_URL.hostname.replace(/^mission\./, '')
  }
}

export function deriveSecurityDomain() {
  if (currentEnvironment() === 'local') {
    return deriveBaseDomain()
  } else {
    return `${APP_NAME}.${deriveBaseDomain()}`
  }
}

export function deriveAPIBase() {
  // Note: don't use a puma-dev domain, as it doesn't work well with websockets. Just
  // use the localhost:<PORT> domain.
  if (currentEnvironment() === 'local') {
    return `http://localhost:${LOCAL_API_PORT}`
  } else {
    return `https://api.${deriveBaseDomain()}`
  }
}

export function deriveMissionBase() {
  const base = deriveBaseDomain()
  if (base === 'localhost') {
    return `http://localhost:${LOCAL_PORT}`
  } else {
    return `https://${APP_NAME}.${base}`
  }
}

export function deriveSiteBase() {
  const base = deriveBaseDomain()
  if (base === 'localhost') {
    return `http://localhost:${LOCAL_SITE_PORT}`
  } else {
    return `https://${base}`
  }
}

export function deriveWebClientBase() {
  const base = deriveBaseDomain()
  if (base === 'localhost') {
    return `http://localhost:${LOCAL_WEB_PORT}`
  } else {
    return `https://web.${base}`
  }
}

const plannerGridSize = 16

const config = {

  version:        process.env.VERSION,
  environment:    currentEnvironment(),
  stack:          currentStack(),
  securityDomain: deriveSecurityDomain(),

  api: {
    baseURL:   `${deriveAPIBase()}/mission`,
    socketURI: `${deriveAPIBase()}/mission`,
  },

  socket: {
    defaultReconnect: 1000,
    sendTimeout:      5000,
  },

  urls: {
    mission:  deriveMissionBase(),
    api:      deriveAPIBase(),
    site:     deriveSiteBase(),

    web: (domain?: string | null) => {
      return domain != null
        ? `https://${domain}`
        : deriveWebClientBase()
    },

    appLogin:    (token: string, urlScheme: string) => `${urlScheme}://login?auth=${token}`,
    webLogin:    (token: string, domain?: string | null) => `${config.urls.web(domain)}/login?auth=${token}`,
    webRegister: (token: string, domain?: string | null) => `${config.urls.web(domain)}/register?token=${token}`,

    relative(path: string) {
      if (path.startsWith('/')) {
        return `${this.mission}${path}`
      } else {
        return `${this.mission}/${path}`
      }
    },

    defaultAppURLScheme: 'groundcontrol',
  },

  dashboard: {
    rows:    16,
    columns: 24,

    minWidgetSize: {
      width:  3,
      height: 2,
    },
  },

  planner: {
    minZoom:  0.5,
    maxZoom:  1.5,
    gridSize: plannerGridSize,

    minimumComponentSize: (type: PlanNode['type'] | PlanAnnotation['type']) => {
      if (isRoutingNodeType(type)) {
        return {
          width:  3 * plannerGridSize,
          height: 3 * plannerGridSize,
        }
      } else if (isModuleBoundaryNodeType(type)) {
        return {
          width:  6 * plannerGridSize,
          height: 3 * plannerGridSize,
        }
      } else if (type === 'text') {
        return {
          width:  2 * plannerGridSize,
          height: 2 * plannerGridSize,
        }
      } else {
        return {
          width:  6 * plannerGridSize,
          height: 3 * plannerGridSize,
        }
      }
    },

    defaultComponentSize: (type: PlanNode['type'] | PlanAnnotation['type']) => {
      if (isRoutingNodeType(type)) {
        return {
          width:  4 * plannerGridSize,
          height: 4 * plannerGridSize,
        }
      } else if (isModuleBoundaryNodeType(type)) {
        return {
          width:  6 * plannerGridSize,
          height: 3 * plannerGridSize,
        }
      } else if (type === 'text') {
        return {
          width:  -1,
          height: -1,
        }
      } else {
        return {
          width: -1,
          height: 3 * plannerGridSize,
        }
      }
    },

    minSizeForThreeConnectors: 6 * 16,

    rules: {
      maxShownResults: 8,
    },
  },

  webPlanner: {
    minZoom: 0.4,
    maxZoom: 5,

    // The pixels-per-hour scale of the planner (at zoom 1).
    pph: 260,

    // The axis tick interval in minutes.
    axisInterval: (zoom: number) => zoom < 3 ? 30 : 10,
  },

  resource: {
    pageSize:      16,
    importTimeout: 120_000, // Extra long timeout for import requests.
  },

  chat: {
    typingTimeout:     10_000,
    timestampInterval: '5m',
    maxReceipts:       20,
  },

  news: {
    maxMediaCount: 4,
  },

  mediaUploader: {
    maxFileSize: 200 * 1024 * 1024, // 100MiB
  },

  googleMaps: {
    apiKey: 'AIzaSyDCbY92uRgcmjhWi9T0X2CEaiO1mB3ByUI',
  },

  changelog: {
    client: '/changelog.json',
    server: `${deriveAPIBase()}/changelog.json`,
  },
}

export function isModuleBoundaryNodeType(type: string): type is PlanNode['type'] {
  return ['entry', 'exit'].includes(type)
}

export function isRoutingNodeType(type: string): type is PlanNode['type'] {
  return ['routing', 'circuit-breaker', 'splitter', 'first', 'sample'].includes(type)
}

export default config
