import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Ref, Review, User } from '~/models'
import { authenticationStore, BulkSelector, dataStore } from '~/stores'
import { memo } from '~/ui/component'
import { FormDialog, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import UserField from '../users/UserField'

export interface Props {
  open:         boolean
  requestClose: () => any

  selector:     BulkSelector
  afterSubmit?: (result: SubmitResult) => any
}

const BulkAssignForm = memo('BulkAssignForm', (props: Props) => {

  const {open, requestClose, selector, afterSubmit} = props
  const {t} = useResourceTranslation()
  const me = authenticationStore.user

  const formModel = React.useMemo(
    () => new BulkAssignFormModel(selector),
    [selector],
  )

  //------
  // Rendering

  function render() {
    if (me?.organisation == null) { return null }

    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        afterSubmit={afterSubmit}
        title={t('actions.bulk_assign.title')}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <FormField name='reviewer' required>
        {bind => <UserField {...bind}/>}
      </FormField>
    )
  }

  return render()

})

export default BulkAssignForm

class BulkAssignFormModel implements FormModel {

  constructor(
    private readonly selector: BulkSelector,
  ) {
    makeObservable(this)
  }

  @observable
  public reviewer: Ref<User> | null = null

  public async submit(): Promise<SubmitResult | undefined> {
    const endpoint = dataStore.endpoint(Review)
    return await endpoint.callBulkAction('assign', {
      ...this.selector,
      meta: {
        ...this.selector.meta,
        reviewerID: this.reviewer,
      },
    })
  }

}