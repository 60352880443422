import React from 'react'
import cn from 'classnames'
import Color from 'color'
import { v4 as uuidV4 } from 'uuid'
import { memo } from '~/ui/component'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import { useChart } from '../ChartContext'
import ValueLabel from '../ValueLabel'

export interface Props<T> {
  name:          string
  caption?:      string
  valueForPoint: (point: T) => number | null | undefined

  color?:           Color
  showValueLabels?: boolean
}

const _ColumnSeries = <T extends {}>(props: Props<T>) => {

  const {name, valueForPoint, color: props_color, showValueLabels} = props
  const {data, series, chartLayout, colorForSeries, keyForPoint} = useChart<T>()

  const columnSeriesCount = series.filter(it => it.type === ColumnSeries).length
  const seriesIndex       = series.findIndex(it => it.props.name === props.name)
  const offsets           = columnOffsets(seriesIndex, columnSeriesCount)

  const color = props_color ?? colorForSeries(name)

  const maskID = React.useMemo(
    () => uuidV4().slice(0, 8) + '-mask',
    [],
  )

  //------
  // Rendering

  function render() {
    return (
      <g style={{mask: `url(#${maskID})`}}>
        {renderMask()}
        {data.map(renderColumn)}
        {showValueLabels && data.map(renderValueLabel)}
      </g>
    )
  }

  function renderMask() {
    return (
      <mask id={maskID}>
        <rect
          x={chartLayout.plotRect.left - 4}
          y={chartLayout.plotRect.top}
          width={chartLayout.plotRect.width + 8}
          height={chartLayout.plotRect.height}
          fill='white'
        />
      </mask>
    )
  }

  function renderColumn(point: T, index: number) {
    const value = valueForPoint(point)
    if (value == null) { return null }

    return (
      <SeriesColumn
        key={keyForPoint(point, index)}
        seriesName={name}
        point={point}
        value={value}
        index={index}
        color={color}
      />
    )
  }

  function renderValueLabel(point: T, index: number) {
    const value = valueForPoint(point)
    if (value == null) { return null }

    return (
      <ValueLabel
        key={keyForPoint(point, index)}
        point={point}
        index={index}
        value={value}
        offset={offsets.center}
      />
    )
  }

  return render()
}

const ColumnSeries = memo('ColumnSeries', _ColumnSeries) as typeof _ColumnSeries
export default ColumnSeries

interface SeriesColumnProps {
  seriesName: string
  point:      any
  value:      number
  index:      number
  color:      Color
}

const SeriesColumn = memo('SeriesColumn', (props: SeriesColumnProps) => {

  const {seriesName, point, value, index, color} = props
  const {series, keyForPoint, chartLayout, defs, showPopupForPoint, hidePopup, hasPopup} = useChart()

  const columnSeriesCount = series.filter(it => it.type === ColumnSeries).length
  const seriesIndex       = series.findIndex(it => it.props.name === seriesName)
  const offsets           = columnOffsets(seriesIndex, columnSeriesCount)
  const interactive       = hasPopup

  const showPopup = React.useCallback((event: React.SyntheticEvent<any>) => {
    showPopupForPoint(point, event.currentTarget)
  }, [point, showPopupForPoint])

  //------
  // Rendering

  const $ = useStyles({color})

  function render() {
    const key    = keyForPoint(point, index)
    const left   = chartLayout.xForPointKey(key, offsets.left)
    const right  = chartLayout.xForPointKey(key, offsets.right)
    const top    = chartLayout.yForValue(value)
    const bottom = chartLayout.yForValue(0)

    const x = left
    const w = right - left
    const h = Math.max(Math.abs(bottom - top), 2)
    const y = bottom - h
    const r = Math.min(h, columnBorderRadius)

    const data = [
      `M ${x} ${y+h}`,
      `L ${x+w} ${y+h}`,
      `L ${x+w} ${y+r}`,
      `A ${r} ${r} 90 0 0 ${x+w-r} ${y}`,
      `L ${x+r} ${y}`,
      `A ${r} ${r} 90 0 0 ${x} ${y+r}`,
      'Z',
    ].join(' ')

    return (
      <g classNames={[$.column, {interactive}]} onMouseEnter={showPopup} onMouseLeave={hidePopup}>
        <rect
          classNames={$.columnHitArea}
          x={x}
          y={chartLayout.plotRect.top}
          width={w}
          height={chartLayout.plotRect.height}
        />
        <path
          classNames={cn($.columnFg, h >= 4 && 'shadow')}
          d={data}
        />
        <path
          d={data}
          fill={`url(#${defs.columnGlare})`}
        />
      </g>
    )

  }

  return render()

})

function columnOffsets(index: number, count: number) {
  const width = 0.8 / count
  const left   = 0.1 + (index + 0) * width
  const center = 0.1 + (index + 0.5) * width
  const right  = 0.1 + (index + 1) * width

  return {left, center, right}
}

export const columnBorderRadius = layout.radius.s

const useStyles = createUseStyles(theme => ({
  column: {},

  columnHitArea: {
    strokeWidth: 8,
    stroke:      'transparent',
    fill:        'transparent',

    '$column.interactive:hover &': {
      fill: theme.bg.hover,
    },
  },

  columnFg: (opts: {color: Color}) => ({
    fill:   opts.color,

    '&.shadow': {
      filter: `drop-shadow(1px 1px 2px ${shadows.shadowColor})`,
    },
  }),
}))