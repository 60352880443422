
import React from 'react'
import { focusFirst } from 'react-autofocus'
import { observer } from '~/ui/component'
import {
  Center,
  Chip,
  Empty,
  HBox,
  Label,
  PanelHeader,
  PushButton,
  Scroller,
  VBox,
} from '~/ui/components'
import { Form, SubmitResult } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import BrandingAssetFormModel from './BrandingAssetFormModel'
import { BrandingAssetType } from './BrandingAssetsContainer'
import BrandingBackgroundFields from './BrandingBackgroundFields'
import BrandingBackgroundFormModel from './BrandingBackgroundFormModel'
import BrandingBackgroundPreview from './BrandingBackgroundPreview'
import BrandingBorderFields from './BrandingBorderFields'
import BrandingBorderFormModel from './BrandingBorderFormModel'
import BrandingBorderPreview from './BrandingBorderPreview'
import BrandingColorFields from './BrandingColorFields'
import BrandingColorFormModel from './BrandingColorFormModel'

export interface Props {
  type:  BrandingAssetType
  model: BrandingAssetFormModel | null

  requestFocus?: (type: BrandingAssetType, name: string) => any
}

const BrandingAssetForm = observer('BrandingAssetForm', (props: Props) => {

  const {type, model, requestFocus} = props
  const {t} = useResourceTranslation('brandings')

  const containerRef = React.useRef<HTMLDivElement>(null)

  const prevModel = usePrevious(model)
  React.useEffect(() => {
    if (containerRef.current == null) { return }
    if (model == null || prevModel === model) { return }
    focusFirst(containerRef.current)
  }, [model, prevModel])

  const focusOnSuccess = React.useCallback((result: SubmitResult) => {
    if (model == null) { return }
    if (result.status !== 'ok') { return }

    requestFocus?.(model.assetType, model.name)
  }, [model, requestFocus])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.brandingAssetForm} ref={containerRef}>
        {renderHeader()}
        {model == null ? (
          renderEmpty()
        ) : (
          renderBody()
        )}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <Empty
        {...t('assets.choose_asset')}
        flex
      />
    )
  }

  function renderHeader() {
    if (model == null) { return null }

    return (
      <PanelHeader
        caption={(
          model.originalName != null ? (
            <Label small bold mono>{model.originalName}</Label>
          ) : (
            <Label small bold>{t('assets.new', {type})}</Label>
          )
        )}
        right={(
          model.isWellKnown && (
            <Chip small>
              {t('assets.wellknown.chip')}
            </Chip>
          )
        )}

        roundTop={false}
      />
    )
  }

  function renderBody() {
    if (model == null) { return null }

    return (
      <Scroller flex>
        <Form flex='grow' model={model} afterSubmit={focusOnSuccess}>
          {renderForm()}
          <Center flex='grow'>
            {renderPreview()}
          </Center>
          {renderButtons()}
        </Form>
      </Scroller>
    )
  }

  function renderForm() {
    if (model instanceof BrandingColorFormModel) {
      return <BrandingColorFields model={model}/>
    } else if (model instanceof BrandingBackgroundFormModel) {
      return <BrandingBackgroundFields model={model}/>
    } else if (model instanceof BrandingBorderFormModel) {
      return <BrandingBorderFields model={model}/>
    }
  }

  function renderPreview() {
    if (model instanceof BrandingBackgroundFormModel) {
      return <BrandingBackgroundPreview spec={model.buildSpec()}/>
    } else if (model instanceof BrandingBorderFormModel) {
      return <BrandingBorderPreview spec={model.buildSpec()}/>
    }
  }

  function renderButtons() {
    return (
      <HBox justify='right' classNames={$.buttons}>
        <PushButton
          submit
          icon='check'
          caption={t('buttons:save')}
        />
      </HBox>
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  brandingAssetForm: {
    background: theme.bg.semi,
  },

  buttons: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.inline.l],
    })),
  },
}))

export default BrandingAssetForm