import React from 'react'
import { getClientPoint, isRightMouse } from 'react-dnd'
import { pick } from 'lodash'
import config from '~/config'
import { WidgetAlotment } from '~/models'
import { memo } from '~/ui/component'
import { useContinuousRef } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'
import { useDashboardContext } from './DashboardContext'
import { useDashboardLayout } from './DashboardLayoutContext'

export interface Props {
  alotment: WidgetAlotment
}

const WidgetResizeHandles = memo('WidgetResizeHandles', (props: Props) => {

  const {alotment} = props
  const currentAlotmentRef = useContinuousRef(alotment)

  const layout = useDashboardLayout()
  const {updateWidget} = useDashboardContext()

  //------
  // Handlers

  const handlePointRef = React.useRef<Point>({x: 0, y: 0})
  const startLayoutRef = React.useRef<(Point & Size)>({x: 0, y: 0, width: 0, height: 0})
  const dragActiveRef = React.useRef<boolean>(false)

  const resizeWidget = React.useCallback((handlePoint: Point, extent: Point) => {
    const left   = Math.max(0, handlePoint.x < 0.5 ? extent.x : startLayoutRef.current.x)
    const top    = Math.max(0, handlePoint.y < 0.5 ? extent.y : startLayoutRef.current.y)
    const right  = Math.min(config.dashboard.columns, handlePoint.x > 0.5 ? extent.x + 1 : startLayoutRef.current.x + startLayoutRef.current.width)
    const bottom = Math.min(config.dashboard.rows, handlePoint.y > 0.5 ? extent.y + 1 : startLayoutRef.current.y + startLayoutRef.current.height)

    const width =  Math.max(right - left, config.dashboard.minWidgetSize.width - left)
    const height = Math.max(bottom - top, config.dashboard.minWidgetSize.height - top)

    const rect = {
      x: right - width,
      y: bottom - height,
      width,
      height,
    }

    if (!layout.widgetIntersects(rect, alotment.uuid)) {
      updateWidget(alotment.uuid, it => ({...it, ...rect}))
    }
  }, [alotment.uuid, layout, updateWidget])

  const handleMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const extent = layout.pointToDashboardCell(clientPoint)
    resizeWidget(handlePointRef.current, extent)

    dragActiveRef.current = true
  }, [layout, resizeWidget])

  const handleEnd = React.useCallback((event: MouseEvent | TouchEvent) => {
    document.removeEventListener('mousemove', handleMove)
    document.removeEventListener('touchmove', handleMove)
    document.removeEventListener('mouseup', handleEnd)
    document.removeEventListener('touchend', handleEnd)

    if (!dragActiveRef.current) { return }
    dragActiveRef.current = false
  }, [handleMove])

  const handleStart = React.useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (isRightMouse(event.nativeEvent)) { return }
    if (closest(event.target, isInteractiveElement) != null) { return }

    event.preventDefault()

    startLayoutRef.current = pick(currentAlotmentRef.current, 'x', 'y', 'width', 'height')
    document.addEventListener('mousemove', handleMove)
    document.addEventListener('touchmove', handleMove)
    document.addEventListener('mouseup', handleEnd)
    document.addEventListener('touchend', handleEnd)
  }, [currentAlotmentRef, handleEnd, handleMove])

  const handleHandlers = React.useCallback((x: number, y: number) => {
    const handler = (event: React.MouseEvent | React.TouchEvent) => {
      handlePointRef.current = {x, y}
      handleStart(event)
    }

    return {
      onMouseDown:   handler,
      onTouchStart:  handler,
    }
  }, [handleStart])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.widgetResizeHandles}>
        <div
          classNames={[$.resizeHandle, 'top', 'left']}
          {...handleHandlers(0, 0)}
        />
        <div
          classNames={[$.resizeHandle, 'top', 'center']}
          {...handleHandlers(0.5, 0)}
        />
        <div
          classNames={[$.resizeHandle, 'top', 'right']}
          {...handleHandlers(1, 0)}
        />
        <div
          classNames={[$.resizeHandle, 'middle', 'left']}
          {...handleHandlers(0, 0.5)}
        />
        <div
          classNames={[$.resizeHandle, 'middle', 'right']}
          {...handleHandlers(1, 0.5)}
        />
        <div
          classNames={[$.resizeHandle, 'bottom', 'left']}
          {...handleHandlers(0, 1)}
        />
        <div
          classNames={[$.resizeHandle, 'bottom', 'center']}
          {...handleHandlers(0.5, 1)}
        />
        <div
          classNames={[$.resizeHandle, 'bottom', 'right']}
          {...handleHandlers(1, 1)}
        />
      </div>
    )
  }

  return render()

})

export const thickness = 8

const useStyles = createUseStyles(theme => ({
  widgetResizeHandles: {
    ...layout.overlay,
    pointerEvents: 'none',
  },

  resizeHandle: {
    position:      'absolute',
    visibility:    'none',
    pointerEvents: 'auto',

    '&.top, &.bottom': {height: thickness},
    '&.left, &.right': {width: thickness},

    '&.top':    {top: 0},
    '&.bottom': {bottom: 0},
    '&.left':   {left: 0},
    '&.right':  {right: 0},

    '&.middle': {
      top:    thickness,
      bottom: thickness,
    },
    '&.center': {
      left: thickness,
      right: thickness,
    },

    '&.top.left, &.bottom.right': {cursor: 'nwse-resize'},
    '&.top.right, &.bottom.left': {cursor: 'nesw-resize'},
    '&.top.center, &.bottom.center': {cursor: 'ns-resize'},
    '&.middle.left, &.middle.right': {cursor: 'ew-resize'},
  },

  uuid: {
    position: 'absolute',
    top:      '100%',
    left:     0,
    right:    0,

    background: theme.semantic.secondary,
    padding:    layout.padding.inline.xs,

    '& > *': {
      fontSize: 8,
    },
    height:       12,
    borderRadius: 6,
  },
}))

export default WidgetResizeHandles