import React from 'react'
import { useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Feed, Post } from '~/models'
import { memo } from '~/ui/component'
import { Dimple, HBox, shrinkFrom, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { ResourceDetailParams, resourceDetailPath } from '~/ui/resources/routes'
import { createUseStyles, layout } from '~/ui/styling'
import CommentForm from '../posts/CommentForm'
import PostDetail from '../posts/PostDetail'
import PostForm from '../posts/PostForm'
import PostList from '../posts/PostList'
import FeedHeader from './FeedHeader'

export interface Props {
  feed: Feed
}

const FeedBody = memo('FeedBody', (props: Props) => {

  const {feed} = props

  const [formOpen, openForm, closeForm] = useBoolean()
  const [editedPost, setEditedPost] = React.useState<Post | null>(null)
  const [editFormType, setEditFormType] = React.useState<'post' | 'comment'>('post')

  const editPost = React.useCallback((post: Post) => {
    setEditedPost(post)
    setEditFormType('post')
    openForm()
  }, [openForm])

  const addNewPost = React.useCallback(() => {
    setEditedPost(null)
    openForm()
  }, [openForm])

  const editComment = React.useCallback((post: Post) => {
    if (post.root == null) { return }

    setEditedPost(post)
    setEditFormType('comment')
    openForm()
  }, [openForm])

  const addComment = React.useCallback(() => {
    setEditedPost(null)
    setEditFormType('comment')
    openForm()
  }, [openForm])

  //------
  // Comments

  const history = useHistory()
  const match   = useRouteMatch<ResourceDetailParams>()

  const selectedPostID    = match.params.body ?? null

  const selectPost = React.useCallback((post: Post) => {
    history.replace(resourceDetailPath('feeds', feed.id, post.id))
  }, [feed.id, history])

  const unselectPost = React.useCallback(() => {
    history.replace(resourceDetailPath('feeds', feed.id))
  }, [feed.id, history])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.feedBody}>
        <FeedHeader
          feed={feed}
          requestNewPost={addNewPost}
        />
        <Dimple horizontal/>
        <HBox flex align='stretch' gap={layout.padding.l}>
          <VBox flex={shrinkFrom(560)}>
            <PostList
              feed={feed}

              selectedPostID={selectedPostID}
              onSelect={selectPost}
              requestEdit={editPost}
            />
          </VBox>
          {selectedPostID != null && (
            <VBox classNames={$.postDetail} flex={shrinkFrom(480)}>
              <PostDetail
                postID={selectedPostID}
                requestAddComment={addComment}
                requestEditComment={editComment}
                requestClose={unselectPost}
              />
            </VBox>
          )}
        </HBox>

        {editFormType === 'post' ? (
          <PostForm
            feed={feed}
            post={editedPost}
            open={formOpen}
            requestClose={closeForm}
          />
        ) : selectedPostID != null ? (
          <CommentForm
            feedID={feed.id}
            postID={selectedPostID}
            comment={editedPost}
            open={formOpen}
            requestClose={closeForm}
          />
        ) : null}
      </VBox>
    )
  }

  return render()

})

export default FeedBody

const useStyles = createUseStyles({
  feedBody: {
    ...layout.responsiveProp({
      paddingLeft: layout.padding.m,
    }),
  },

  postDetail: {
    ...layout.responsiveProp({
      paddingTop: layout.padding.m,
    }),
  },
})