import React from 'react'
import { DateTime } from 'luxon'
import { TimeOfDay } from '~/models'
import { memo } from '~/ui/component'
import { TimeField, TimeFieldProps } from '~/ui/components'

export interface Props extends Omit<TimeFieldProps, 'value' | 'onChange'> {
  value:    TimeOfDay | null
  onChange?: (value: TimeOfDay | null) => any
}

const TimeOfDayField = memo('TimeOfDayField', (props: Props) => {

  const {value, onChange, ...rest} = props

  const dateTime = React.useMemo(() => {
    if (value == null) {
      return null
    } else {
      return value.toDateTime()
    }
  }, [value])

  const onChangeDateTime = React.useCallback((dateTime: DateTime | null) => {
    if (dateTime == null) {
      onChange?.(null)
    } else {
      onChange?.(TimeOfDay.fromDateTime(dateTime))
    }
  }, [onChange])

  //------
  // Rendering

  function render() {
    return (
      <TimeField
        value={dateTime}
        onChange={onChangeDateTime}
        {...rest}
      />
    )
  }

  return render()

})

export default TimeOfDayField