import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionDescriptor } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, Dimple, Panel, PanelHeader, HBox, SearchableList } from '~/ui/components'
import { layout } from '~/ui/styling'
import { useQuestions } from '../hooks'
import { AnswerScopeLinkage } from './AnswerScopeField'
import QuestionsListItem from './QuestionsListItem'

export interface Props {
  scope:         AnswerScopeLinkage
  selectedUUIDs: string[]
  onChange:      (selectedUUIDs: string[]) => any
}

const QuestionsFilter = observer('QuestionsFilter', (props: Props) => {

  const {scope, selectedUUIDs, onChange} = props

  const [t] = useTranslation('answers')

  //------
  // Fetching

  const [questions, fetchStatus] = useQuestions(scope)

  //------
  // Selection management

  const toggleQuestion = React.useCallback((questionUUID: string) => {
    if (selectedUUIDs.includes(questionUUID)) {
      onChange(selectedUUIDs.filter(it => it !== questionUUID))
    } else {
      onChange([...selectedUUIDs, questionUUID])
    }
  }, [onChange, selectedUUIDs])

  const toggleAllQuestions = React.useCallback(() => {
    if (selectedUUIDs.length === questions.length) {
      onChange([])
    } else {
      onChange(questions.map(it => it.uuid))
    }
  }, [selectedUUIDs.length, questions, onChange])

  //------
  // Search

  const [search, setSearch] = useState<string | null>(null)

  const filteredQuestions = React.useMemo(() => {
    if (search == null) { return questions }
    return questions.filter(it => it.caption.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
  }, [search, questions])

  //------
  // Rendering

  function render() {
    return (
      <Panel flex header={renderHeader()}>
        {renderList()}
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        icon='question'
        caption={t('questions.caption')}
        right={renderSelectionManagement()}
      />
    )
  }

  function renderSelectionManagement() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <ClearButton
          small
          caption={t('questions.select_all')}
          onTap={toggleAllQuestions}
          enabled={selectedUUIDs.length !== questions.length}
        />
        <Dimple vertical />
        <ClearButton
          small
          caption={t('questions.select_none')}
          onTap={toggleAllQuestions}
          enabled={selectedUUIDs.length > 0}
        />
      </HBox>
    )
  }

  function renderList() {
    return (
      <SearchableList
        flex
        scrollable
        data={filteredQuestions}
        fetchStatus={fetchStatus}
        onSearch={setSearch}
        renderItem={renderItem}
      />
    )
  }

  const renderItem = React.useCallback((question: QuestionDescriptor) => (
      <QuestionsListItem
        question={question}
        selected={selectedUUIDs.includes(question.uuid)}
        onToggle={toggleQuestion}
      />
  ), [selectedUUIDs, toggleQuestion])

  return render()

})

export default QuestionsFilter