import React from 'react'
import { CollectionFetchOptions, FetchStatus } from 'mobx-document'
import { Model, ModelClass } from '~/models'
import { dataStore, ListParams, ModelEndpoint } from '~/stores'
import { usePrevious } from '~/ui/hooks'
import { useWithCustomDeps } from '../useWithCustomDeps'

export function useModelEndpointData<M extends Model>(Model: Constructor<M>, options: ModelEndpointOptions = {}): UseEndpointDataHook<M> {
  const endpoint    = useModelEndpoint<M>(Model, options)
  const data        = endpoint.data
  const fetchStatus = endpoint.fetchStatus

  const fetch: ModelEndpointFetch = React.useCallback(options => {
    endpoint.fetch(options)
  }, [endpoint])

  return [data, {data, fetch, fetchStatus, endpoint}]
}

// Note: the argument is Constructor<M> and not ModelClass<M> because TypeScript can only infer M if a direct constructor
// argument is used. ModelClass<M> defines additional restrictions, breaking this inference.
export function useModelEndpoint<M extends Model>(Model: Constructor<M>, options: ModelEndpointOptions = {}): ModelEndpoint<M> {
  const {
    fetch: shouldFetch = true,
    fetchOnCreate = true,
    fetchOnRemove = true,
    detached = false,
    ...rest
  } = options

  const params = useWithCustomDeps(
    rest,
    buildParamsDeps,
  )
  const prevParams = usePrevious(params)

  const endpoint = React.useMemo(
    () => detached ? dataStore.detachedEndpoint(Model, params) : dataStore.endpoint(Model, params),
    [Model, detached, params],
  )

  React.useEffect(() => {
    // If the label has changed, it's like we're loading a new page, so first clear the endpoint. All other parameter changes,
    // like search of filters, will just update the current view.
    if (prevParams == null || params.label !== prevParams.label) {
      endpoint.clear()
    }
    endpoint.setParams(params, shouldFetch)
  }, [Model.name, endpoint, params, prevParams, shouldFetch])

  const fetch = React.useCallback(() => {
    if (!shouldFetch) { return }
    endpoint.fetch()
  }, [endpoint, shouldFetch])

  React.useEffect(fetch, [fetch])

  // Reload whenever any page is created or removed.
  React.useEffect(() => {
    if (!fetchOnCreate) { return }
    return dataStore.onAfterCreate(Model as ModelClass<M>, fetch)
  }, [Model, fetch, fetchOnCreate])

  React.useEffect(() => {
    if (!fetchOnRemove) { return }
    return dataStore.onAfterRemove(Model as ModelClass<M>, fetch)
  }, [Model, fetch, fetchOnRemove])

  return endpoint
}

export function buildParamsDeps(params: ListParams) {
  const entries: Array<[string, any]> = []
  for (const [name, value] of Object.entries(params)) {
    if (name === 'filters') {
      for (const [filter, filterValue] of Object.entries(value ?? {})) {
        entries.push([`filters:${filter}`, filterValue])
      }
    } else {
      entries.push([name, value])
    }
  }

  entries.sort((a, b) => a[0].localeCompare(b[0]))
  return [entries.map(([name, value]) => `${name}=${value}`).join('&')]
}

export interface ModelEndpointOptions extends ListParams {
  fetch?:         boolean
  fetchOnCreate?: boolean
  fetchOnRemove?: boolean
  detached?:      boolean
}

export type UseEndpointDataHook<M extends Model> = [M[], UseEndpointDataHookRest<M>]

export interface UseEndpointDataHookRest<M extends Model> {
  data:        M[]
  fetch:       ModelEndpointFetch
  fetchStatus: FetchStatus
  endpoint:    ModelEndpoint<M>
}

export type ModelEndpointFetch = (options?: CollectionFetchOptions) => void