import React from 'react'
import { observer } from '~/ui/component'
import { SelectField } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, useForm } from '~/ui/form'
import RegistrationFormModel from './RegistrationFormModel'
import { useRegistrationWidgetModel } from './RegistrationWidgetContext'
import { RegistrationTranslationKey } from './types'

export interface Props {}

const GroupSelector = observer('GroupSelector', (props: Props) => {

  const model = useRegistrationWidgetModel()
  const {model: formModel} = useForm<RegistrationFormModel>()

  const groups = formModel.availableGroups
  const choices = React.useMemo((): Choice[] => {
    if (groups.status !== 'done') { return [] }

    return groups.data.map(group => ({
      value:   group.id,
      caption: group.name,
    }))
  }, [groups])

  //------
  // Rendering

  function render() {
    if (!formModel.showGroupField) { return null }

    return (
      <FormField
        name='group'
        caption={model.translate(RegistrationTranslationKey.groupCaption)}
        align='center'
        required
      >
        {bind => (
          <SelectField
            {...bind}
            placeholder={model.translate(RegistrationTranslationKey.groupPlaceholder)}
            choices={choices}
            inputStyle='dark'
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default GroupSelector