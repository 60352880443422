import React from 'react'
import { defaultZoom, LatLong } from '~/models'
import { GoogleMapFields, GoogleMapManager } from '~/ui/app/google-maps'
import { observer } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'
import { layout } from '~/ui/styling'
import LocationCommonFields from './LocationCommonFields'

const LatLongLocationFields = observer('LatLongLocationFields', () => {

  const mapManagerRef = React.useRef<GoogleMapManager>(null)

  const [coordinate] = useFormField<LatLong | null>('coordinate')

  const onCommitName = React.useCallback((value: string, event: React.SyntheticEvent) => {
    if (value !== '' && coordinate == null) {
      mapManagerRef.current?.search(value)
      event.preventDefault()
    }
  }, [coordinate])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind} onCommit={onCommitName}/>}
        </FormField>

        <GoogleMapFields
          zoom={defaultZoom}
          mapManagerRef={mapManagerRef}
        />

        <LocationCommonFields/>
      </VBox>
    )
  }

  return render()

})

export default LatLongLocationFields