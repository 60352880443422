import context from './context'

export default class AudioPreloader {

  constructor(
    public readonly path: string,
  ) {}

  private readonly bytes: Uint8Array[] = []

  private async buildAudioBuffer() {
    const totalSize   = this.bytes.reduce((size, it) => size + it.byteLength, 0)
    const arrayBuffer = new ArrayBuffer(totalSize)

    let offset: number = 0
    for (const it of this.bytes) {
      new Uint8Array(arrayBuffer).set(it, offset)
      offset += it.byteLength
    }

    return new Promise<AudioBuffer>((resolve, reject) => {
      context.decodeAudioData(arrayBuffer, resolve, reject)
    })
  }

  public loaded: number = 0
  public total: number | null = null

  public async preload(): Promise<AudioBuffer | null> {
    let done = false
    while (!done) {
      done = await this.loadChunk()
    }
    if (this.bytes.length === 0) {
      return null
    } else {
      return await this.buildAudioBuffer()
    }
  }

  private async loadChunk() {
    const headers: Record<string, string> = {}
    if (this.loaded > 0) {
      headers['Range'] = `bytes=${this.loaded}-`
    }

    const response = await fetch(this.path, {headers})
    if (response.status >= 400) { return true }

    const arrayBuffer = await response.arrayBuffer()
    this.bytes.push(new Uint8Array(arrayBuffer))
    this.loaded += arrayBuffer.byteLength

    if (response.status !== 206) {
      return true
    }

    if (this.total == null) {
      const contentRange = response.headers.get('Content-Range') ?? ''
      if (!/^bytes\s+(\d+)-(\d+)\/(\d+)$/.test(contentRange)) {
        return true
      }

      this.total = parseInt(RegExp.$3, 10)
    }

    return this.loaded >= this.total
  }

}