import React from 'react'
import { LayoutRect } from 'react-dnd'
import { useBoundingRectangle } from 'react-measure'
import { some, uniq } from 'lodash'
import { memo } from '~/ui/component'
import { Popup, VBox } from '~/ui/components'
import { assignRef, useBoolean } from '~/ui/hooks'
import { createUseStyles, layout, PaletteKey, useTheme } from '~/ui/styling'
import { childrenOfType } from '~/ui/util'
import ChartAxis, { Props as ChartAxisProps } from './ChartAxis'
import ChartContext from './ChartContext'
import ChartLayout, { ChartLayoutConfig } from './ChartLayout'
import { useQualifiedIDFactory } from './hooks'
import * as allSeries from './series'
import ColumnSeries from './series/ColumnSeries'
import LineSeries from './series/LineSeries'

import type { SeriesProps } from './series'

export interface Props<T> {
  data:            T[]
  children:        React.ReactNode

  keyForPoint?:      (point: T) => string | number
  axisLabelForPoint: (point: T) => string | null
  palette?:          PaletteKey

  renderPopup?:   (point: T, index: number) => React.ReactNode

  layoutConfig?: ChartLayoutConfig
  classNames?:   React.ClassNamesProp
}

const _Chart = <T extends {}>(props: Props<T>) => {

  const {
    data,
    children,
    keyForPoint,
    palette = 'default',
    layoutConfig: props_layoutConfig,
    renderPopup: props_renderPopup,
    axisLabelForPoint,
    classNames,
  } = props

  const series = React.useMemo(() => {
    return childrenOfType(children, ...Object.values(allSeries)) as React.ReactElement<SeriesProps<T>>[]
  }, [children])

  const axes = React.useMemo(() => {
    return childrenOfType(children, ChartAxis) as React.ReactElement<ChartAxisProps>[]
  }, [children])

  const keys = React.useMemo(
    () => uniq(data.map((it, idx) => keyForPoint?.(it) ?? idx)),
    [data, keyForPoint],
  )

  const maxValue = React.useMemo(() => {
    let maxValue: number = 1
    for (const point of data) {
      for (const serie of series) {
        const value = serie.props.valueForPoint(point)
        if (value == null) { continue }

        if (value > maxValue) { maxValue = value }
      }
    }

    return maxValue
  }, [data, series])

  const theme = useTheme()

  //------
  // Layout

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [boundingRect, setBoundingRect] = React.useState<LayoutRect>(LayoutRect.zero())
  useBoundingRectangle(containerRef, setBoundingRect)

  const layoutConfig = React.useMemo(() => ({
    xAxis:           some(axes, it => it.props.axis === 'x'),
    y1Axis:          some(axes, it => it.props.axis === 'y1'),
    y2Axis:          some(axes, it => it.props.axis === 'y2'),
    hasColumns:      some(series, it => it.type === ColumnSeries),
    hasLines:        some(series, it => it.type === LineSeries),
    showValueLabels: some(series, it => it.props.showValueLabels),
    ...props_layoutConfig,
  }), [axes, props_layoutConfig, series])

  const chartLayout = React.useMemo(() => {
    return new ChartLayout(boundingRect, keys, maxValue, layoutConfig)
  }, [boundingRect, keys, layoutConfig, maxValue])

  //------
  // Popup

  const [popupOpen, openPopup, closePopup] = useBoolean()
  const [hoverPoint, setHoverPoint] = React.useState<T | null>(null)
  const hoverPointRef = React.useRef<SVGElement>(null)

  const showPopupForPoint = React.useCallback((point: T, element: SVGElement) => {
    assignRef(hoverPointRef, element)
    setHoverPoint(point)
    openPopup()
  }, [openPopup])

  //------
  // Context

  const qualifiedID = useQualifiedIDFactory()

  const colorForSeries = React.useCallback((name: string) => {
    const index = series.findIndex(it => it.props.name === name)
    return theme.colors.palette(palette, index, series.length)
  }, [palette, series, theme.colors])

  const defs = React.useMemo(() => ({
    columnGlare: qualifiedID('column-glare'),
  }), [qualifiedID])

  const context = React.useMemo((): ChartContext<T> => ({
    data:              data,
    series:            series,
    keyForPoint:       keyForPoint ?? ((_, index) => index),
    axisLabelForPoint: axisLabelForPoint,

    hasPopup:          props_renderPopup != null,
    showPopupForPoint: showPopupForPoint,
    hidePopup:         closePopup,

    colorForSeries,
    chartLayout,
    defs,
  }), [axisLabelForPoint, chartLayout, closePopup, colorForSeries, data, keyForPoint, props_renderPopup, series, showPopupForPoint, defs])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const size = {
      width:  chartLayout.boundingRect.width,
      height: chartLayout.boundingRect.height,
    }

    return (
      <ChartContext.Provider value={context}>
        <VBox classNames={$.chartContainer} flex ref={containerRef}>
          <svg classNames={[$.chart, classNames]} viewBox={chartLayout.viewBox} style={size}>
            {renderDefs()}
            {children}
          </svg>
        </VBox>

        {props_renderPopup != null && (
          <Popup
            open={popupOpen}
            requestClose={closePopup}
            renderContent={renderPopup}
            targetRef={hoverPointRef ?? undefined}
            layoutKey={hoverPoint}
          />
        )}
      </ChartContext.Provider>
    )
  }

  function renderDefs() {
    return (
      <defs>
        <linearGradient id={defs.columnGlare} x1={0} y1={0} x2={5} y2={60} gradientUnits='userSpaceOnUse'>
          <stop offset={0} stopColor='white' stopOpacity={0.3}/>
          <stop offset={1} stopColor='white' stopOpacity={0}/>
        </linearGradient>
      </defs>
    )
  }

  function renderPopup() {
    if (hoverPoint == null) { return null }
    return props_renderPopup?.(hoverPoint, data.indexOf(hoverPoint)) ?? null
  }

  return render()
}

const Chart = memo('Chart', _Chart) as typeof _Chart
export default Chart

export const columnRadius = layout.radius.s

const useStyles = createUseStyles(theme => ({
  chartContainer: {
    position: 'relative',
  },

  chart: {
    position: 'absolute',
    top:      0,
    left:     0,
  },

  axis: {
    height:          1,
    backgroundColor: theme.fg.dimmer,
    margin:          [1, 0, layout.padding.inline.s],
  },

  point: {
    minWidth: 8,
  },

  column: {
    position: 'relative',
    borderTopLeftRadius:  columnRadius,
    borderTopRightRadius: columnRadius,
  },

  valueLabel: {
    position: 'absolute',
    top:      -layout.padding.inline.s,
    left:     '50%',
    width:    0,
    overflow: 'visible',
  },
}))