import React from 'react'
import { memo } from '~/ui/component'
import { HBox, PushButton } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import CalendarPlannerControls from './CalendarPlannerControls'

export interface Props {
  requestAddDay: () => any
}

const CalendarPlannerHeader = memo('CalendarPlannerHeader', (props: Props) => {

  const {requestAddDay} = props

  const {actionCaption} = useResourceTranslation('calendar_planner')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.calendarPlannerHeader} gap={layout.padding.m} justify='space-between'>
        <div/>
        {renderRight()}
      </HBox>
    )
  }

  function renderRight() {
    return (
      <HBox gap={layout.padding.m}>
        {renderAddDayButton()}
        <CalendarPlannerControls/>
      </HBox>
    )
  }

  function renderAddDayButton() {
    return (
      <PushButton
        icon='plus'
        caption={actionCaption('add_day')}
        onTap={requestAddDay}
      />
    )
  }

  return render()

})

export default CalendarPlannerHeader

const useStyles = createUseStyles({
  calendarPlannerHeader: {
    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], layout.padding.m[size]],
    })),
  },
})