export default class ChartLayout {

  constructor(
    public readonly boundingRect: LayoutRect,
    public readonly keys: Array<number | string>,
    public readonly maxValue: number,
    config: Partial<ChartLayoutConfig> = {},
  ) {
    this.config = {
      ...ChartLayoutConfig.default(),
      ...config,
    }
  }

  public readonly config: ChartLayoutConfig

  public get topMargin() {
    return this.config.showValueLabels ? 24 : 8
  }

  public get xAxisHeight() {
    return this.config.xAxis ? this.config.xAxisHeight : 0
  }

  public get y1AxisWidth() {
    return this.config.y1Axis ? this.config.y1AxisWidth : 0
  }

  public get y2AxisWidth() {
    return this.config.y2Axis ? this.config.y2AxisWidth : 0
  }

  public get viewBox() {
    return [
      0,
      0,
      Math.floor(this.boundingRect.width),
      Math.floor(this.boundingRect.height),
    ].join(' ')
  }

  public get plotRect() {
    return {
      left:   this.y1AxisWidth,
      top:    this.topMargin,
      width:  Math.floor(this.boundingRect.width) - this.y1AxisWidth - this.y2AxisWidth,
      height: Math.floor(this.boundingRect.height) - this.xAxisHeight - this.topMargin,
    }
  }


  public xForPointKey(key: string | number, offset: number = this.config.hasColumns ? 0.5 : 0) {
    const index  = this.keys.indexOf(key)
    const count  = this.config.hasColumns ? this.keys.length : this.keys.length - 1
    const margin = this.config.hasLines ? 6 : 0

    return this.plotRect.left + margin + (index + offset) / count * (this.plotRect.width - 2 * margin)
  }

  public yForValue(value: number) {
    if (this.maxValue === 0) { return this.boundingRect.height }
    return this.plotRect.top + (1 - (value / this.maxValue)) * this.plotRect.height
  }

}

export interface ChartLayoutConfig {
  xAxis:  boolean
  y1Axis: boolean
  y2Axis: boolean

  y1AxisWidth: number
  y2AxisWidth: number
  xAxisHeight: number

  hasColumns:      boolean
  hasLines:        boolean
  showValueLabels: boolean
}

export const ChartLayoutConfig: {
  default: () => ChartLayoutConfig
} = {
  default: () => ({
    xAxis:  true,
    y1Axis: true,
    y2Axis: false,

    y1AxisWidth: 60,
    y2AxisWidth: 60,
    xAxisHeight: 24,

    hasColumns:      false,
    hasLines:        false,
    showValueLabels: false,
  }),
}