import React from 'react'
import { useTranslation } from 'react-i18next'
import { isPlainObject } from 'lodash'
import { LocalizedString } from '~/models'
import { languagesStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  LanguageToggleButton,
  PopupMenuItem,
  SVG,
  TextField,
  TextFieldProps,
  VBox,
} from '~/ui/components'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { createUseStyles, layout, presets } from '~/ui/styling'
import LocalizedTextFieldDialog from './LocalizedTextFieldDialog'

export interface Props extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value:     string | LocalizedString
  onChange?: (value: LocalizedString) => any
}

const LocalizedTextField = observer('LocalizedTextField', (props: Props) => {

  const {
    value,
    onChange: props_onChange,
    multiline,
    ...rest
  } = props

  const [t] = useTranslation('localized_text_field')

  const localizedString = React.useMemo(() => {
    if (isPlainObject(value)) { return value }
    if (typeof value === 'string') { return LocalizedString.from(value) }
    return {}
  }, [value])

  const languages = languagesStore.availableLanguages
  const [language, setLanguage] = React.useState<string>(languagesStore.defaultLanguage)
  const [dialogOpen, openDialog, closeDialog] = useBoolean()

  const currentDefaultLanguage = languagesStore.defaultLanguage
  const prevDefaultLanguage = usePrevious(languagesStore.defaultLanguage)
  React.useEffect(() => {
    if (prevDefaultLanguage === undefined) { return }
    if (currentDefaultLanguage === prevDefaultLanguage) { return }
    setLanguage(currentDefaultLanguage)
  }, [currentDefaultLanguage, prevDefaultLanguage])

  const onChange = React.useCallback((translation: string) => {
    props_onChange?.(LocalizedString.replace(localizedString, language, translation))
  }, [props_onChange, localizedString, language])

  const setLocalizedString = React.useCallback((string: LocalizedString) => {
    props_onChange?.(string)
  }, [props_onChange])

  const openDialogItem = React.useMemo((): PopupMenuItem[] => [{
    caption: t('open_dialog'),
    onSelect: openDialog,
  }], [openDialog, t])


  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <TextField
        classNames={$.LocalizedTextField}
        onChange={onChange}
        value={localizedString[language]}
        multiline={multiline}
        accessoryRight={renderToggle()}
        {...rest}
      />
    )
  }

  function renderToggle() {
    if (languages.length <= 1) { return null }

    return (
      <VBox classNames={$.toggle} flex justify={multiline ? 'top' : 'middle'}>
        <LanguageToggleButton
          value={language}
          languages={languages}
          onChange={setLanguage}
          showLanguage={false}
          accessory={<SVG name='chevron-down' size={layout.icon.xs}/>}
          extraItems={openDialogItem}
          small
        />
        <LocalizedTextFieldDialog
          open={dialogOpen}
          requestClose={closeDialog}
          languages={languages}
          value={localizedString}
          setValue={setLocalizedString}
          multiline={multiline}
          onFocusLanguage={setLanguage}
        />
      </VBox>
    )
  }

  return render()

})

export default LocalizedTextField

const useStyles = createUseStyles({
  LocalizedTextField: {
  },

  toggle: {
    pointerEvents: 'none',
    '& > *': {pointerEvents: 'auto'},

    paddingTop: presets.fieldPadding.top,
  },
})