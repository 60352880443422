import React from 'react'
import { ChoiceFeedback, FeedbackChoice, Group, GroupFeedback, MessageFeedback } from '~/models'
import ChoiceFeedbackBubble from '~/ui/app/chat/feedback/ChoiceFeedbackBubble'
import { observer } from '~/ui/component'
import { useModelEndpointData } from '~/ui/hooks/data'

export interface Props {
  feedback:  MessageFeedback & GroupFeedback
}

const GroupFeedbackBubblePreview = observer('GroupFeedbackBubblePreview', (props: Props) => {

  const {feedback} = props

  const [groups] = useModelEndpointData(Group, {
    fetch: feedback.tag != null,
    filters: {
      tags: feedback.tag,
    },
  })

  const choices: FeedbackChoice[] = React.useMemo(() => {
    if (feedback.tag == null) { return [] }

    return groups.map(group => ({
      value:   group.id,
      caption: group.name,
    }))
  }, [groups, feedback.tag])

  const choiceFeedback = React.useMemo((): MessageFeedback & ChoiceFeedback => ({
    type:         'choice',
    choices:      choices,
    minAnswers:   1,
    maxAnswers:   1,
    splitAnswers: false,
    skippable:    feedback.skippable,
    variable:     feedback.variable,
    expiresAfter: feedback.expiresAfter,
    review:       false,
  }), [choices, feedback.skippable, feedback.variable, feedback.expiresAfter])

  //------
  // Rendering

  function render() {
    return (
      <ChoiceFeedbackBubble
        messageID={null}
        feedback={choiceFeedback}
      />
    )
  }

  return render()

})

export default GroupFeedbackBubblePreview