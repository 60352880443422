import React from 'react'
import { memo, observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  Label,
  List,
  ListProps,
  Panel,
  VBox,
  VBoxProps,
} from '~/ui/components'
import { width as clearButtonWidth } from '~/ui/components/ClearButton'
import { panelBorderRadius } from '~/ui/components/panel'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout } from '~/ui/styling'
import { childrenOfType, isReactText } from '~/ui/util'

export interface Props<T> extends Omit<ListProps<T>, 'renderItem'> {
  children?: React.ReactNode
}

const _PanelList = <T extends {}>(props: Props<T>) => {

  const {children, ...rest} = props

  const scrollerProps = React.useMemo(() => ({
    overflow: 2,
    ...props.scrollerProps,
  }), [props.scrollerProps])

  const columns = React.useMemo((): PanelListColumnProps<T>[] => {
    const columnElements = childrenOfType(children, PanelListColumn)
    return columnElements.map(it => it.props)
  }, [children])

  const actions = React.useMemo((): PanelListActionProps<T>[] => {
    const actionElements = childrenOfType(children, PanelListAction)
    return actionElements.map(it => it.props)
  }, [children])

  //------
  // Rendering

  function render() {
    return (
      <Panel>
        <PanelListHeader
          columns={columns}
          actions={actions}
        />
        <Dimple horizontal/>
        <List
          {...rest}
          renderItem={renderRow}
          SeparatorComponent={Dimple.Horizontal}
          scrollerProps={scrollerProps}
          flex='shrink'
        />
      </Panel>
    )
  }

  const renderRow = React.useCallback((item: T, index: number) => {
    return (
      <PanelListRow
        item={item}
        index={index}
        columns={columns}
        actions={actions}
      />
    )
  }, [actions, columns])

  return render()

}

//------
// Auxiliary components

interface PanelListColumnProps<T> {
  caption?:   React.ReactNode
  renderCell: (item: T, index: number) => React.ReactNode

  flex?:  VBoxProps['flex']
  width?: number
}

const PanelListColumn = <T extends {}>(props: PanelListColumnProps<T>) => null

interface PanelListActionProps<T> {
  icon?:    SVGName
  caption?: string
  onTap?:   (item: T, index: number, event: React.SyntheticEvent<any>) => any
}

const PanelListAction = <T extends {}>(props: PanelListActionProps<T>) => null

//------
// PanelListHeader

interface PanelListHeaderProps {
  columns: PanelListColumnProps<any>[]
  actions: PanelListActionProps<any>[]
}

const PanelListHeader = memo('PanelListHeader', <T extends {}>(props: PanelListHeaderProps) => {

  const {columns, actions} = props

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.panelListHeader} gap={layout.padding.inline.l}>
        {columns.map(renderCell)}
        {renderActionsColumn()}
      </HBox>
    )
  }

  function renderCell(props: PanelListColumnProps<T>, columnIndex: number) {
    return (
      <VBox key={columnIndex} flex={props.flex} width={props.width}>
        {isReactText(props.caption) ? (
          <Label h3>
            {props.caption}
          </Label>
        ) : (
          props.caption
        )}
      </VBox>
    )
  }

  function renderActionsColumn() {
    if (actions.length === 0) { return null }

    return (
      <HBox>
        {actions.map((_, index) => (
          <React.Fragment key={index}>
            {index > 0 && <div style={{width: 2}}/>}
            <div style={{width: clearButtonWidth.normal}}/>
          </React.Fragment>
        ))}
      </HBox>
    )
  }

  return render()

})

//------
// PanelListRow

interface PanelListRowProps {
  item:    any
  index:   number
  columns: PanelListColumnProps<any>[]
  actions: PanelListActionProps<any>[]
}

const PanelListRow = memo('PanelListRow', <T extends {}>(props: PanelListRowProps) => {

  const {item, index: rowIndex, columns, actions} = props

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.panelListRow} gap={layout.padding.inline.l}>
        {columns.map(renderCell)}
        {renderActionsColumn()}
      </HBox>
    )
  }

  function renderCell(props: PanelListColumnProps<T>, columnIndex: number) {
    return (
      <VBox classNames={$.cell} key={columnIndex} flex={props.flex} width={props.width}>
        <HBox gap={layout.padding.inline.m}>
          {props.renderCell(item, rowIndex)}
        </HBox>
      </VBox>
    )
  }

  function renderActionsColumn() {
    if (actions.length === 0) { return null }

    return (
      <HBox>
        {actions.map((action, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Dimple vertical/>}
            <PanelListActionButton
              item={item}
              rowIndex={rowIndex}
              action={action}
            />
          </React.Fragment>
        ))}
      </HBox>
    )
  }

  return render()

})

interface PanelListActionButtonProps {
  item:     any
  rowIndex: number
  action:   PanelListActionProps<any>
}

const PanelListActionButton = memo('PanelListActionButton', (props: PanelListActionButtonProps) => {

  const {item, rowIndex, action} = props

  const tap = React.useCallback((event: React.SyntheticEvent<any>) => {
    action.onTap?.(item, rowIndex, event)
  }, [action, item, rowIndex])

  return (
    <ClearButton
      {...action}
      onTap={action.onTap == null ? undefined : tap}
      padding='both'
    />

  )

})

const PanelList = observer('PanelList', _PanelList) as typeof _PanelList
Object.assign(PanelList, {VBox: PanelListColumn, Action: PanelListAction})

export default PanelList as typeof PanelList & {
  VBox: typeof PanelListColumn
  Action: typeof PanelListAction
}

const useStyles = createUseStyles(theme => ({
  panelListHeader: {
    padding:   layout.padding.inline.m,
  },

  panelListRow: {
    minHeight:  layout.barHeight.l,
    padding:    layout.padding.inline.m,
    background: theme.bg.alt,

    '&:last-child': {
      borderBottomLeftRadius:  panelBorderRadius(theme),
      borderBottomRightRadius: panelBorderRadius(theme),
    },
  },

  cell: {
    overflow: 'hidden',
    padding:  1,
  },
}))