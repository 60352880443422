import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Label,
  PushButton,
  Spinner,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { Form, FormField, FormProps } from '~/ui/form'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import AuthForm from '../AuthForm'
import AuthResetFormModel from './AuthResetFormModel'

export interface Props extends FormProps<AuthResetFormModel> {
  backHref?: string
}

const AuthResetForm = observer('AuthResetForm', (props: Props) => {

  const {model: formModel, backHref} = props

  const [t] = useTranslation('auth')

  const timer = useTimer()
  const hasToken = formModel.token != null
  const [preflightStatus, setPreflightStatus] = React.useState<'preflighting' | 'ok' | 'invalid-token'>('preflighting')

  const theme = useTheme()

  //------
  // Callbacks

  const preflight = React.useCallback(async () => {
    if (formModel.token == null) { return }

    setPreflightStatus('preflighting')

    const promise = authenticationStore.preflightAuthReset(formModel.token)
    const ok      = await timer.await(promise)

    setPreflightStatus(ok ? 'ok' : 'invalid-token')
  }, [formModel.token, timer])

  React.useEffect(() => {
    preflight()
  }, [preflight])

  const toggleShowPassword = React.useCallback(() => {
    formModel.toggleShowPassword()
  }, [formModel])

  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Form {...props}>
        <AuthForm>
          {!hasToken ? (
            renderNoToken()
            ) : preflightStatus === 'preflighting' ? (
            renderPreflighting()
          ) : preflightStatus === 'invalid-token' ? (
            renderInvalidToken()
          ) : (
            renderContent()
          )}
          {renderFooter()}
        </AuthForm>
      </Form>
    )
  }

  function renderNoToken() {
    return (
      <VBox classNames={$.status} justify='middle' gap={layout.padding.inline.s}>
        <Label bold color={theme.colors.fg.dark.error} align='center'>
          {t('errors.no-token.title')}
        </Label>
        <Label small color={theme.colors.fg.dark.error} truncate={false} align='center' dim>
          {t('errors.no-token.detail')}
        </Label>
      </VBox>
    )
  }

  function renderInvalidToken() {
    return (
      <VBox classNames={$.status} justify='middle' gap={layout.padding.inline.s}>
        <Label bold color={theme.colors.fg.dark.error} align='center'>
          {t('errors.invalid-token.title')}
        </Label>
        <Label small color={theme.colors.fg.dark.error} truncate={false} align='center' dim>
          {t('errors.invalid-token.detail')}
        </Label>
      </VBox>
    )
  }

  function renderPreflighting() {
    return (
      <VBox classNames={$.status}>
        <Center>
          <Spinner/>
        </Center>
      </VBox>
    )
  }

  function renderContent() {
    return (
      <>
        <TextBlock small dim align='center'>
          {t('auth_reset.instruction')}
        </TextBlock>

        {renderFields()}
        {renderSubmit()}
      </>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.xs}>
        <FormField name='newPassword' caption={false}>
          {bind => (
            <TextField
              type={formModel.showPassword ? 'text' : 'password'}
              {...bind}
              autoFocus
              placeholder={t('auth_reset.fields.new-password')}
              autoComplete='new-password'
              align='center'
              inputStyle='dark'
              accessoryRight={renderShowPasswordToggle()}
            />
          )}
        </FormField>
        {!formModel.showPassword && (
          <FormField name='repeatPassword' caption={false}>
            {bind => (
              <TextField
                type='password'
                {...bind}
                placeholder={t('auth_reset.fields.repeat-password')}
                autoComplete='new-password'
                align='center'
                inputStyle='dark'
              />
            )}
          </FormField>
        )}
      </VBox>
    )
  }

  function renderSubmit() {
    return (
      <VBox style={{minWidth: 128}}>
        <PushButton
          caption={t('auth_reset.submit')}
          enabled={formModel.maySubmit}
          submit
        />
      </VBox>
    )
  }

  function renderFooter() {
    return (
      <Center gap={layout.padding.m}>
        <ClearButton
          href={backHref}
          icon='arrow-left'
          caption={t('auth_reset.back')}
          padding='both'
          small
          dim
        />
      </Center>
    )
  }

  function renderShowPasswordToggle() {
    return (
      <ClearButton
        icon={formModel.showPassword ? 'eye' : 'eye-off'}
        onTap={toggleShowPassword}
      />
    )
  }

  return render()

})

export default AuthResetForm

const useStyles = createUseStyles({
  status: {
  },
})