import React from 'react'
import { FetchStatus } from 'mobx-document'
import { memo } from '~/ui/component'
import { Center, isListSection, Label, List, SearchField, Spinner, VBox } from '~/ui/components'
import { assignRef, KeyboardNavigationNode, KeyPath, useKeyboardNavigation } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { ListProps } from './list'
import { paddingVertical as listItemPadding } from './ListItem'

export interface Props<T> extends Omit<ListProps<T>, 'EmptyComponent'> {
  search?:         string | null
  onSearch:        (query: string | null) => any
  searchDebounce?: number

  fetchStatus?: FetchStatus
  emptyLabel?:  string

  onKeyboardSelect?: (item: T | null) => any

  searchFieldRef?: React.Ref<HTMLInputElement>
  classNames?:     React.ClassNamesProp
}

const _SearchableList = <T extends {}>(props: Props<T>) => {

  const {
    data,
    search,
    onSearch,
    searchDebounce,
    fetchStatus,
    emptyLabel,
    onKeyboardSelect,
    flex,
    searchFieldRef,
    keyExtractor = defaultKeyExtractor,
    classNames,
    ...rest
  } = props


  //------
  // Keyboard navigation

  const [keyPath, setKeyPath] = React.useState<KeyPath>([])

  const treeNodeForItem = React.useCallback((item: T, index: number): KeyboardNavigationNode<T> => ({
    key:  keyExtractor(item, index),
    item: item,
  }), [keyExtractor])

  const keyboardNavigationData = React.useMemo(() => data.map((item, index): KeyboardNavigationNode<T> => {
    if (isListSection(item)) {
      return {
        key:      item.name,
        children: item.items.map(treeNodeForItem),
      }
    } else {
      return treeNodeForItem(item, index)
    }
  }), [data, treeNodeForItem])

  const [connectKeyboard] = useKeyboardNavigation(keyboardNavigationData, keyPath, setKeyPath, {
    onSelect: onKeyboardSelect,
  })

  React.useEffect(() => {
    let nextKeyPath = keyPath
    if (nextKeyPath.length === 0 && data.length === 1 && !isListSection(data[0])) {
      nextKeyPath = [keyExtractor(data[0], 0)]
    }

    setKeyPath(nextKeyPath)
  }, [data, data.length, keyExtractor, keyPath])

  const connectSearchField = React.useCallback((element: HTMLInputElement) => {
    connectKeyboard(element)
    assignRef(searchFieldRef, element)
  }, [connectKeyboard, searchFieldRef])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex}>
        <VBox classNames={$.header}>
          {renderSearch()}
        </VBox>
        {renderList()}
      </VBox>
    )
  }

  function renderSearch() {
    return (
      <SearchField
        search={search}
        onSearch={onSearch}
        debounce={searchDebounce}
        inputStyle='dark'
        ref={connectSearchField}
        autoFocus
      />
    )
  }

  function renderList() {
    return (
      <List<T>
        {...rest}
        data={data}
        EmptyComponent={renderListEmpty}
        scrollable={true}
        flex={flex}
        selectedKeyPath={keyPath}
        keyExtractor={keyExtractor}
      />
    )
  }

  const renderListEmpty = React.useCallback(() => {
    if (fetchStatus === 'fetching') {
      return <Center><Spinner size={16}/></Center>
    } else {
      return (
        <VBox flex justify='middle' classNames={$.empty}>
          <Label small dim italic align='center' truncate={false}>
            {emptyLabel}
          </Label>
        </VBox>
      )
    }
  }, [$.empty, emptyLabel, fetchStatus])

  return render()

}

const SearchableList = memo('SearchableList', _SearchableList) as typeof _SearchableList
export default SearchableList

const defaultKeyExtractor = (item: any, index: number) => index

export const padding = listItemPadding.normal

const useStyles = createUseStyles({
  header: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  empty: {
    minHeight: layout.barHeight.s,
    padding:   [layout.padding.inline.m, layout.padding.inline.l],
  },
})