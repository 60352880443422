import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import socket from 'socket.io-react'
import { initStores, register, restorePersistedState, setUpPersistence } from './support'

export class AppStore {

  constructor() {
    window.addEventListener('online', action(() => { this.online = true }))
    window.addEventListener('offline', action(() => { this.online = false }))
    makeObservable(this)
  }

  @observable
  public readyState: AppReadyState = 'initializing'

  @observable
  public online: boolean = window.navigator.onLine

  @computed
  public get ready() {
    return this.readyState === 'ready'
  }

  //------
  // Config

  @observable
  public serverVersion: string | null = null

  @observable
  public serverEnvironment: string | null = null

  @observable
  public configDialogOpen: boolean = false

  @action
  public openConfigDialog() {
    this.configDialogOpen = true
  }

  @action
  public closeConfigDialog() {
    this.configDialogOpen = false
  }

  //------
  // Lifecycle

  public async initStores() {
    // First load all persisted state.
    await restorePersistedState()

    // Then initialize all stores.
    const success = await initStores()
    if (success) {
      setUpPersistence()
    }

    runInAction(() => {
      if (success) {
        this.readyState = 'ready'
      } else {
        this.readyState = 'error'
      }
    })
  }

  @action
  public init() {
    socket.addEventListener('config', (config: {environment: string, version: string}) => {
      this.serverEnvironment = config.environment
      this.serverVersion     = config.version
    })
  }

}

export type AppReadyState = 'initializing' | 'ready' | 'error'

const appStore = register(new AppStore())
export default appStore