import { makeObservable, observable } from 'mobx'
import { authenticationStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class LoginFormModel implements FormModel {

  constructor() {
    makeObservable(this)
  }

  @observable
  public email: string = ''

  @observable
  public password: string = ''

  public get maySubmit() {
    if (this.email.trim().length === 0) { return false }
    if (this.password.trim().length === 0) { return false }
    return true
  }

  public async submit(): Promise<SubmitResult> {
    return await authenticationStore.logIn({
      email:    this.email.trim(),
      password: this.password,
    })
  }

}