import { hasBitmask } from 'ytil'
import {
  isModuleBoundaryNode,
  isNode,
  isRoutingNode,
  PlanComponent,
  PlanNode,
  PlanNodeConnector,
  PlanNodeConnectorMask,
  PlanSegueConnection,
} from '~/models'
import { panelBorderRadius } from '~/ui/components'
import { layout } from '~/ui/styling'
import { Theme } from '~/ui/styling/Theme'
import { groupPadding as triggerableNodeGroupPadding } from '../triggerables/TriggerableNodeView'
import { borderRadius as triggerNodeBorderRadius } from '../triggers/node/TriggerNodeView'
import { Direction } from './types'

export function findConnectionPoint(connection: PlanSegueConnection, node: PlanNode, bounds: LayoutRect) {
  const padding = node.type === 'triggerable' && node.triggerables.length > 1
    ? triggerableNodeGroupPadding
    : 0

  const f =
    hasBitmask(connection.connector, PlanNodeConnectorMask.LEADING) ? 1/4 :
    hasBitmask(connection.connector, PlanNodeConnectorMask.TRAILING) ? 3/4 :
    2/4

  if (hasBitmask(connection.connector, PlanNodeConnectorMask.HORIZONTAL)) {
    const x = hasBitmask(connection.connector, PlanNodeConnectorMask.WEST)
      ? bounds.left - padding
      : bounds.left + bounds.width + padding

    const y = bounds.top - padding + (bounds.height + 2 * padding) * f
    return {x, y}
  } else {
    const y = hasBitmask(connection.connector, PlanNodeConnectorMask.NORTH)
      ? bounds.top - padding
      : bounds.top + bounds.height + padding

    const x = bounds.left - padding + (bounds.width + 2 * padding) * f
    return {x, y}
  }
}

export function directionForConnector(connector: PlanNodeConnector, which: 'from' | 'to') {
  if (which === 'from') {
    if (hasBitmask(connector, PlanNodeConnectorMask.NORTH)) { return Direction.UP }
    if (hasBitmask(connector, PlanNodeConnectorMask.EAST)) { return Direction.RIGHT }
    if (hasBitmask(connector, PlanNodeConnectorMask.SOUTH)) { return Direction.DOWN }
    if (hasBitmask(connector, PlanNodeConnectorMask.WEST)) { return Direction.LEFT }
  } else {
    if (hasBitmask(connector, PlanNodeConnectorMask.NORTH)) { return Direction.DOWN }
    if (hasBitmask(connector, PlanNodeConnectorMask.EAST)) { return Direction.LEFT }
    if (hasBitmask(connector, PlanNodeConnectorMask.SOUTH)) { return Direction.UP }
    if (hasBitmask(connector, PlanNodeConnectorMask.WEST)) { return Direction.RIGHT }
  }

  return Direction.UP
}

export function borderRadiusForPlannerComponent(theme: Theme, component: PlanComponent, bounds: LayoutRect): string | number {
  if (isModuleBoundaryNode(component)) {
    const large = Math.min(bounds.width / 2, bounds.height / 2)
    const small = layout.radius.m
    if (component.type === 'entry') {
      return [small, small, large, large].map(r => `${r}px`).join(' ')
    } else {
      return [large, large, small, small].map(r => `${r}px`).join(' ')
    }
  } else if (isRoutingNode(component)) {
    return Math.min(bounds.width, bounds.height) / 2
  } else if (isNode(component) && (component.type === 'trigger' || component.type === 'module')) {
    return triggerNodeBorderRadius
  } else {
    return panelBorderRadius(theme)
  }
}