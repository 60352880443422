import React from 'react'
import Color from 'color'
import { Center, ColorBullet, Tappable } from '~/ui/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'

export interface Props {
  color:     Color
  selected?: boolean
  onTap?:    () => any
}

export default function ColorButton(props: Props) {

  const {color, selected, onTap} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable classNames={[$.colorButton, {selected}]} onTap={onTap}>
        <Center>
          <ColorBullet
            small
            color={color}
          />
        </Center>
      </Tappable>
    )
  }

  return render()

}

export const size = {
  width:  24,
  height: 24,
}

const useStyles = createUseStyles(theme => ({
  colorButton: {
    ...size,

    borderRadius: size.height / 2,
    background:   theme.bg.alt.alpha(0.2),

    boxShadow:    ['inset', ...shadows.depth(1)],

    '&.selected': {
      background:  theme.bg.active,
      borderColor: theme.fg.normal.alpha(0.6),
    },

    ...layout.flex.center,
  },
}))