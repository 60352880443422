import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOnDemandService } from 'socket.io-react'
import { answersStore } from '~/stores'
import { observer } from '~/ui/component'
import { Center, Empty, HBox, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { AnswersContextProvider } from '../AnswersContext'
import { AnswerScopeLinkage } from './AnswerScopeField'
import ExploreAnswersCanvas from './ExploreAnswersCanvas'
import ExploreAnswersSidebar from './ExploreAnswersSidebar'

interface Props {
  scopeID:   string | null
  scopeType: string | null
}

const ExploreAnswersContainer = observer('ExploreAnswersContainer', (props: Props) => {

  const {scopeID, scopeType} = props
  const [t] = useTranslation('answers')

  const service = React.useMemo(() => {
    const type  = scopeType
    const id    = scopeID
    if (type !== 'all' && id == null) { return null }

    const scope = type === 'all' ? '$all' : {type, id} as AnswerScopeLinkage
    return answersStore.getAnswersService(scope)
  }, [scopeID, scopeType])

  useOnDemandService(service)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <AnswersContextProvider service={service}>
        {renderBody()}
      </AnswersContextProvider>
    )
  }

  function renderBody() {
    return (
      <HBox flex align='stretch' classNames={$.container}>
        <VBox flex={3} classNames={$.content}>
          {renderContent()}
        </VBox>
        {renderRight()}
      </HBox>
    )
  }

  function renderContent() {
    if (service == null) {
      return renderEmpty()
    }
    else {
      return (
        <ExploreAnswersCanvas
          loading={service.starting}
        />
      )
    }
  }

  function renderEmpty() {
    return (
      <Center flex gap={layout.padding.l}>
        <Empty {...t('prompt')} />
      </Center>
    )
  }

  function renderRight() {
    return (
      <VBox flex={1} classNames={$.right} gap={layout.padding.m}>
        <ExploreAnswersSidebar />
      </VBox>
    )
  }

  return render()

})

export default ExploreAnswersContainer

const useStyles = createUseStyles({
  container: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },

  content: {
    margin: [-4, 0],
  },

  right: {
    minWidth: 320,
    maxWidth: 320,
  },
})