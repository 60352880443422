import React from 'react'
import { useTranslation } from 'react-i18next'
import { triggerableIcon } from '~/stores'
import { observer } from '~/ui/component'
import { FormDialog, FormDialogProps } from '~/ui/form'
import renderPlannerActionFields from '../actions/index'
import TriggerableFormModel from './TriggerableFormModel'

export type Props = FormDialogProps<TriggerableFormModel>

const ActionTriggerableForm = observer('ActionTriggerableForm', (props: Props) => {

  const formModel = props.model
  const variant   = formModel.actionVariant

  const [t] = useTranslation('flow_planner')

  const icon = variant?.triggerable != null
    ? triggerableIcon(variant.triggerable, null, variant.action)
    : 'star'

  const width = variant?.action.name === 'external:email' || variant?.action.name === 'participant:email'
    ? 820
    : undefined

  //------
  // Rendering

  function render() {
    if (variant == null) { return null }

    return (
      <FormDialog
        icon={icon}
        title={t('planner_actions.title')}
        semi={false}
        width={width}
        children={renderFields()}
        {...props}
      />
    )
  }

  function renderFields() {
    if (variant == null) { return null }

    return renderPlannerActionFields({
      action: variant.action,
      schema: variant.formSchema,
      params: variant.params,
    })
  }

  return render()

})

export default ActionTriggerableForm