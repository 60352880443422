import { mapValues } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { AudioPreloader } from '~/audio'

export class AudioStore<N extends string> {

  constructor(
    private readonly sources: Record<N, [string, boolean]>,
  ) {
    makeObservable(this)
  }

  public readonly preloaders = mapValues(
    this.sources,
    ([path]) => new AudioPreloader(path),
  )

  @observable
  private audioBuffers = mapValues(this.sources,
    ([, loop]): [AudioBuffer | null, boolean] => [null, loop],
  )

  public get(name: N): [AudioBuffer, boolean] | null {
    const [source, loop] = this.audioBuffers[name]
    if (source == null) { return null }

    return [source, loop]
  }

  @observable
  public preloading: boolean = false

  @observable
  public ready: boolean = false

  @action
  public preload() {
    this.preloading = true

    const promises = Object.entries(this.preloaders).map(async ([name, preloader]): Promise<[N, AudioBuffer | null]> => {
      const buffer = await preloader.preload()
      if (buffer == null) {
        console.warn(`Could not load audio at path \`${preloader.path}\``)
      }

      return [name, buffer]
    })

    Promise.all(promises).then(action(entries => {
      for (const [name, buffer] of entries) {
        const existing = this.audioBuffers[name]
        if (!existing) { return }

        this.audioBuffers[name] = [buffer, existing[1]]
      }
      this.preloading   = false
      this.ready        = true
    }))
  }

}

const audioStore = new AudioStore({
  bell: ['/sounds/ding.wav', false],
})

export default audioStore