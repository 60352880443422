import React from 'react'
import { memo } from '~/ui/component'
import {
  CollectionField as CollectionFieldComponent,
  HBox,
  ItemFormProps,
  VBox,
} from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { layout } from '~/ui/styling'
import { CollectionField } from '../types'
import renderDynamicFieldAllowance from './index'

export interface Props {
  field: CollectionField
  bind:  FormFieldBindProps<any[]>
}

const CollectionAllowance = memo('CollectionAllowance', (props: Props) => {

  const {field, bind} = props

  const generateTemplate = React.useCallback(() => {
    if (field.itemSchema.length === 1) { return '' }

    const template: any = {}
    field.itemSchema.forEach(item => {
      template[item.name] = null
    })
    return template
  }, [field])

  //------
  // Rendering

  function render() {
    return (
      <CollectionFieldComponent
        {...bind}
        renderForm={renderForm}
        newItemTemplate={generateTemplate}
      />
    )
  }

  function renderForm(props: ItemFormProps<any>) {
    return (
      <HBox gap={layout.padding.s}>
        {field.itemSchema.map(item => (
          <VBox key={item.name} flex>
            {renderDynamicFieldAllowance(item, props.bind(item.name))}
          </VBox>
        ))}
      </HBox>
    )
  }

  return render()

})

export default CollectionAllowance