import React from 'react'
import { useContinuousRef } from '~/ui/hooks'
import { closest, isInteractiveElement } from '~/ui/util'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import { CalendarMode } from '../types'

export function useModeToggleKeys() {

  const {mode, setMode} = useCalendarPlanner()
  const modeRef         = useContinuousRef(mode)

  const changeModeTo = React.useCallback((mode: CalendarMode) => {
    if (modeRef.current === mode) { return }
    setMode(mode)
  }, [modeRef, setMode])

  const handleKeyDown = React.useCallback((event: KeyboardEvent) => {
    const input = closest(event.target as Element, isInteractiveElement)
    if (input != null) { return }

    if (event.key === ' ') {
      changeModeTo('pan')
      event.preventDefault()
    }
  }, [changeModeTo])

  const handleKeyUp = React.useCallback((event: KeyboardEvent) => {
    const input = closest(event.target as Element, isInteractiveElement)
    if (input != null) { return }

    if (event.key === ' ') {
      event.preventDefault()
      changeModeTo('select')
    }
  }, [changeModeTo])

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [handleKeyDown, handleKeyUp])

}