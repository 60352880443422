import React from 'react'
import { Group } from '~/models'
import { memo } from '~/ui/component'
import { HBox, NumberField, TextField, VBox } from '~/ui/components'
import { FormField, FormProps, FormSwitch } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import { CustomImageField } from '../fields'
import TagsField from '../tags/TagsField'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  model?: Group | null
}

const GroupForm = memo('GroupForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props
  const {t, fieldPrompt} = useResourceTranslation('groups')

  const initialData = React.useMemo(
    () => model == null ? ({type: 'list'}) : ({}),
    [model],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Group}
        model={model}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        initialData={initialData}
        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>

        <HBox align='top' justify='space-between' gap={layout.padding.m}>

          <VBox flex gap={layout.padding.s}>
            <FormField name='name' required>
              {bind => <TextField {...bind} />}
            </FormField>

            <FormField name='tags'>
              {bind => <TagsField Model={Group} {...bind}/>}
            </FormField>
          </VBox>

          <FormField name='image' renderAsLabel={false}>
            {bind => (
              <CustomImageField
                {...bind}
                size={{width: 116, height: 116}}
              />
            )}
          </FormField>

        </HBox>

        <FormField name='capacity'>
          {bind => <NumberField {...bind} minimum={1} step={1}/>}
        </FormField>

        {model == null && (
          <FormSwitch caption={fieldPrompt('type')} required>
            <FormSwitch.TypeOption
              type='list'
              title={t('type.list.caption')}
              detail={t('type.list.detail')}
            />
            <FormSwitch.TypeOption
              type='segment'
              title={t('type.segment.caption')}
              detail={t('type.segment.detail')}
            />
          </FormSwitch>
        )}
      </VBox>
    )
  }

  return render()

})

export default GroupForm