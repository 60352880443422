import { AxiosResponse } from 'axios'
import I18n from 'i18next'
import { isArray } from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import Semaphore from 'semaphore'
import socket from 'socket.io-react'
import axios from '~/axios'
import { Participant, User } from '~/models'
import { Pack } from '../data/types'
import dataStore from '../dataStore'
import projectStore from '../projectStore'
import {
  register,
  SubmitResult,
  submitResultForAxiosResponse,
  submitResultForResponse,
} from '../support'
import { AuthResetRequired, InvalidToken, LoginData, LoginStatus, OAuthTokenSet } from './types'

export class AuthenticationStore {

  constructor() {
    socket.addEventListener('user:update', this.handleUserUpdate)
    makeObservable(this)
  }

  /**
   * The authentication token if logged in.
   */
  @observable
  public authToken: string | null = null

  @observable
  public loginStatus: LoginStatus = 'logged-out'

  /**
   * The current user ID.
   */
  @observable
  private actualUserID: string | null = null

  @computed
  public get userID() {
    return this.impersonatedUserID ?? this.actualUserID
  }

  @computed
  public get actualUser(): User | null {
    if (this.actualUserID == null) { return null }

    return dataStore.get(User, this.actualUserID)
  }

  @computed
  public get user(): User | null {
    const userID = this.impersonatedUserID ?? this.actualUserID
    if (userID == null) { return null }

    if (!this.actualUser?.isAdmin()) { return this.actualUser }
    return dataStore.get(User, userID)
  }

  @computed
  public get linkedParticipantIDs() {
    if (this.actualUserID == null) { return [] }

    const document = dataStore.document(User, this.actualUserID, false)
    if (document == null) { return [] }

    const linkages = document.relationship('linkedParticipants')?.data
    if (!isArray(linkages)) { return [] }

    return linkages.map(linkage => linkage.id)
  }

  @computed
  public get linkedParticipants() {
    return dataStore.list(Participant, this.linkedParticipantIDs)
  }

  @computed
  public get isLoggedIn() {
    return this.loginStatus === 'logged-in'
  }

  //------
  // Log in

  @action
  public async logIn(data: LoginData): Promise<SubmitResult> {
    this.loginStatus = 'logging-in'
    this.authToken = null

    const promise = axios.post('auth', data)
    return await promise.then(res => this.onLogInComplete(res, 'token' in data))
  }

  @action
  private onLogInComplete = (response: AxiosResponse, usedToken: boolean): SubmitResult => {
    this.authToken = null
    this.loginStatus = 'logged-out'

    if (response.status === 200) {
      this.authToken = response.data.authToken
      this.loginStatus = 'logged-in'

      return submitResultForAxiosResponse(response)
    } else if (response.status === 401) {
      if (usedToken) {
        return {
          status: 'error',
          error: new InvalidToken(),
        }
      } else {
        return {
          status: 'invalid',
          errors: [{field: 'password', code: 'invalid', message: I18n.t('auth:login.invalid')}],
        }
      }
    } else if (response.status === 412) {
      return {
        status: 'error',
        error:  new AuthResetRequired(response.data.authResetToken),
      }
    } else {
      return submitResultForAxiosResponse(response)
    }
  }

  @action
  public async authenticateWithToken(token: string) {
    this.logOut()

    this.authToken   = token
    this.loginStatus = 'logged-in'
  }

  @action
  public logOut() {
    this.authToken          = null
    this.actualUserID       = null
    this.impersonatedUserID = null
    this.loginStatus        = 'logged-out'

    this.clearOAuthRefreshTokenTimer()
    this.oAuthTokens.clear()
    this.userSemaphore.reset()
  }

  //------
  // Impersonation

  @observable
  public impersonatedUserID: string | null = null

  @action
  public impersonate(userID: string) {
    this.impersonatedUserID = userID
  }

  @action
  public stopImpersonation() {
    this.impersonatedUserID = null
  }

  //------
  // OAuth

  private readonly oAuthTokens: Map<string, OAuthTokenSet>             = new Map()
  private oAuthRefreshTokenTimer: ReturnType<typeof setTimeout> | null = null

  public storeOAuthTokens(provider: string, tokens: OAuthTokenSet) {
    this.oAuthTokens.set(provider, tokens)
    this.setOAuthRefreshTokenTimer(provider, tokens.expires_at)
  }

  private setOAuthRefreshTokenTimer(provider: string, expiresAt: number) {
    if (expiresAt == null) { return }

    const now       = DateTime.now()
    const refreshIn = expiresAt - now.toSeconds() - 30 // Refresh 30 seconds early, just to be sure

    if (refreshIn <= 0) {
      this.refreshOAuthToken(provider)
    } else {
      this.oAuthRefreshTokenTimer = setTimeout(
        () => this.refreshOAuthToken(provider),
        refreshIn * 1000,
      )
    }
  }

  private clearOAuthRefreshTokenTimer() {
    if (this.oAuthRefreshTokenTimer == null) { return }
    clearTimeout(this.oAuthRefreshTokenTimer)
  }

  public getOAuthTokens(provider: string): OAuthTokenSet | null {
    return this.oAuthTokens.get(provider) ?? null
  }

  public async refreshOAuthToken(provider: string) {
    const tokens = this.getOAuthTokens(provider)
    if (tokens == null) { return }

    const response = await axios.post(`oauth/${provider}/refresh-token`, {
      refreshToken: tokens.refresh_token,
    })

    if (response.status !== 200) { return }

    this.storeOAuthTokens(provider, response.data)
  }

  //------
  // Auth reset

  @action
  public async requestAuthReset(email: string): Promise<SubmitResult> {
    const response = await axios.post('auth/reset/request', {email})

    return runInAction(() => {
      if (response.status === 200) {
        return {
          status: 'ok',
        }
      } else if (response.status === 422 || response.status === 401) {
        return {
          status: 'invalid',
          errors: [],
        }
      } else {
        const error = new Error(`HTTP (${response.status})`)
        Object.assign(error, {status: response.status})
        return {
          status: 'error',
          error:  error,
        }
      }
    })
  }

  @action
  public async preflightAuthReset(token: string) {
    const response = await axios.get('auth/reset/preflight', {
      params: {token},
    })

    return response.status === 200
  }


  public async resetAuth(token: string, password: string): Promise<SubmitResult> {
    const response = await axios.post('auth/reset', {token, password})

    if (response.status === 401) {
      return {
        status: 'error',
        error:  new InvalidToken(),
      }
    } else {
      return submitResultForAxiosResponse(response)
    }
  }

  @action
  public async changePassword(currentPassword: string, newPassword: string) {
    const response = await socket.send('user:reset-auth', currentPassword, newPassword)

    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        if (response.body.meta?.authToken != null) {
          this.authToken = response.body.meta.authToken
          this.loginStatus = 'logged-in'
        }
      })
    }

    return submitResultForResponse(response)
  }

  //------
  // Participant linking

  @action
  public async linkParticipant(participantID: string) {
    const response = await socket.send('user:link-participant', participantID)
    if (!response.ok) { return false }

    runInAction(() => {
      dataStore.storePack(response.body)
    })

    return true
  }

  @action
  public async unlinkParticipant(participantID: string) {
    const response = await socket.send('user:unlink-participant', participantID)
    if (!response.ok) { return false }

    runInAction(() => {
      dataStore.storePack(response.body)
    })
    return true
  }

  public isLinkedToParticipant(participantID: string) {
    return this.linkedParticipantIDs.includes(participantID)
  }

  //------
  // Persistence

  public persistenceKey = 'auth'

  public persist() {
    return {
      authToken:          this.authToken,
      oAuthTokens:        Array.from(this.oAuthTokens.entries()),
      impersonatedUserID: this.impersonatedUserID,
    }
  }

  @action
  public rehydrate(state: any) {
    this.authToken          = state.authToken
    this.impersonatedUserID = state.impersonatedUserID
    this.loginStatus        = this.authToken != null ? 'logging-in' : 'logged-out'

    for (const [provider, tokens] of state.oAuthTokens ?? []) {
      this.storeOAuthTokens(provider, tokens)
    }
  }

  @action
  public async init() {
    if (this.authToken == null) { return }

    this.waitForLoginInfo().then(action(infoSent => {
      if (infoSent) {
        this.loginStatus = 'logged-in'
      } else {
        this.authToken   = null
        this.loginStatus = 'logged-out'
      }
    }))
  }

  private handleUserUpdate = action((pack: Pack<User>) => {
    dataStore.storePack(pack)

    this.actualUserID = pack.meta.userID
    this.userSemaphore.signal()
  })

  public userSemaphore = new Semaphore({
    autoReset: true,
  })

  private async waitForLoginInfo() {
    await Promise.all([
      this.userSemaphore,
      projectStore.projectSemaphore,
    ])

    if (this.impersonatedUserID != null && this.user == null) {
      this.impersonatedUserID = null
    }

    return this.user != null
  }

}

const authenticationStore = register(new AuthenticationStore())
export default authenticationStore