import React from 'react'
import { Condition, PlanSegue, PlanSegueConnection } from '~/models'
import { observer } from '~/ui/component'
import { Center, Label, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { directionForConnector, findConnectionPoint } from '../canvas/layout'
import { useFlowPlanner } from '../FlowPlannerContext'
import ConditionAttribute from './ConditionAttribute'
import DelayAttribute from './DelayAttribute'
import { offsetAcross, positivePerpendicular, segmentMinLength } from './paths'
import SegueArrow from './SegueArrow'
import TargetingAttribute from './TargetingAttribute'

export interface Props {
  segue:    PlanSegue
  selected: boolean
}

const SegueView = observer('SegueView', (props: Props) => {

  const {segue, selected} = props

  const {planner}   = useFlowPlanner()
  const highlighted = planner.isSegueHighlightedForRuleResult(segue.uuid)
  const focused     = planner.focusedSegue?.uuid === segue.uuid
  const level       = planner.highlightedRuleResult?.rule.level

  const findSegueArrowEnd = React.useCallback((connection: PlanSegueConnection | Point, other: PlanSegueConnection, which: 'from' | 'to') => {
    if ('node' in connection) {
      const node = planner.plan?.findNode(connection.node)
      if (node == null) { return }

      const bounds = planner.getComponentBounds(node)
      const point  = findConnectionPoint(connection, node, bounds)

      return {
        point:     point,
        direction: directionForConnector(connection.connector, which),
      }
    } else {
      const otherNode = planner.plan?.findNode(other.node)
      if (otherNode == null) { return null }

      const otherPoint     = findConnectionPoint(other, otherNode, otherNode.bounds)
      const otherDirection = directionForConnector(other.connector, which === 'from' ? 'to' : 'from')
      const otherEnd = {
        point:     otherPoint,
        direction: otherDirection,
      }

      const crossOffset = Math.abs(offsetAcross(otherPoint, connection, otherDirection))
      const direction   = crossOffset < segmentMinLength ? otherDirection : positivePerpendicular(otherEnd, connection)

      return {
        point:     connection,
        direction: direction,
      }
    }
  }, [planner])

  const segueConnections = planner.segueConnections
  const from             = segueConnections?.[segue.uuid]?.from ?? segue.from
  const to               = segueConnections?.[segue.uuid]?.to ?? segue.to
  const fromEnd          = findSegueArrowEnd(from, to as PlanSegueConnection, 'from')
  const toEnd            = findSegueArrowEnd(to, from as PlanSegueConnection, 'to')
  const collective       = planner.segueIsPartOfCollectiveFlow(segue)

  const {colors} = useStyling()

  const color =
    (selected || focused) ? colors.semantic.primary :
    highlighted ? (level === 'error' ? colors.semantic.negative : colors.semantic.warning) :
    undefined

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (fromEnd == null || toEnd == null) { return null }

    return (
      <SegueArrow
        classNames={$.segueView}
        segue={segue}
        from={fromEnd}
        to={toEnd}
        color={color}
        thick={collective || highlighted}
        startContent={renderOutlet()}
        middleContent={renderAttributes()}
      />
    )
  }

  function renderOutlet() {
    if (segue.outlet == null) { return null }

    return (
      <Center classNames={[$.outlet, {selected}]}>
        <ThemeProvider contrast='secondary'>
          <Label mono tiny>
            {segue.outlet == null ? 'null' : segue.outlet}
          </Label>
        </ThemeProvider>
      </Center>
    )
  }

  function renderAttributes() {
    const delay = segue.delay != null && renderDelay()
    const targeting = segue.targeting.groups.length > 0 && renderTargeting()
    const conditions = segue.conditions.map(renderCondition)
    if (!delay && !targeting && conditions.length === 0) { return null }


    return (
      <VBox align='center' classNames={$.attributes}>
        {delay}
        {targeting}
        {conditions}
      </VBox>
    )
  }

  function renderTargeting() {
    return (
      <TargetingAttribute
        segue={segue}
      />
    )
  }

  function renderCondition(condition: Condition, index: number) {
    return (
      <ConditionAttribute
        key={index}
        segue={segue}
        condition={condition}
      />
    )
  }

  function renderDelay() {
    return (
      <DelayAttribute
        segue={segue}
      />
    )
  }

  return render()
})

export default SegueView

export const outletLabelHeight = 16

const useStyles = createUseStyles(theme => ({
  segueView: {
    '&:hover $outlet:not(.selected)': {
      background: theme.semantic.secondary.darken(0.2),
    },
  },

  attributes: {
    pointerEvents: 'auto',
  },

  outlet: {
    background:   theme.semantic.secondary,
    height:       outletLabelHeight,

    marginLeft:  -8,
    padding:      [layout.padding.inline.xs, layout.padding.inline.m],
    paddingLeft: layout.padding.inline.m + 4,

    '&.selected': {
      background: theme.semantic.primary,
    },

    borderRadius:           outletLabelHeight / 2,
    borderTopLeftRadius:    0,
    borderBottomLeftRadius: 0,
  },
}))