import React from 'react'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, shrinkFrom, TextBlock, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import LinkedProjectsList from './LinkedProjectsList'

const LinkedProjectsSettings = observer('LinkedProjectsSettings', () => {

  const {t} = useResourceTranslation('projects')
  const project = projectStore.project

  //------
  // Rendering

  function render() {
    if (project == null) { return null }
    return (
      <HBox flex align='stretch' justify='center' padding={layout.padding.m}>
        <VBox flex={shrinkFrom(480)} gap={layout.padding.m}>
          <TextBlock dim caption>
            {t('linked-projects.preamble')}
          </TextBlock>

          <LinkedProjectsList
            project={project}
          />
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default LinkedProjectsSettings