import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, GroupSignup } from '~/models'
import { observer } from '~/ui/component'
import { Label, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { ResourceChip } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import { register, WidgetProps } from './registry'
import SignupWidgetForm, { SignupWidgetFormModel } from './SignupWidgetForm'
import WidgetPanel from './WidgetPanel'

export interface Params {
  group:  string
  signup: string | null
}

export type Props = WidgetProps<Params>

const SignupWidget = observer('SignupWidget', (props: Props) => {

  const {params} = props

  const [t] = useTranslation('widgets')

  //------
  // Rendering

  function render() {
    return (
      <WidgetPanel<SignupWidgetFormModel>
        widget='signup'
        params={params}
        icon='group'
        FormModel={SignupWidgetFormModel}
        renderForm={renderForm}
      >
        <VBox gap={layout.padding.s}>
          <Label truncate={false} small dim>
            {t('signup.instruction')}
          </Label>
          {renderGroup()}
          {renderSignup()}
        </VBox>
      </WidgetPanel>
    )
  }

  function renderGroup() {
    if (params.group == null) { return null }

    return (
      <VBox gap={layout.padding.inline.m} align='left'>
        <FormFieldHeader caption={t('signup.fields.group.caption')}/>
        <ResourceChip
          Model={Group}
          id={params.group}
        />
      </VBox>
    )
  }

  function renderSignup() {
    if (params.signup == null) { return null }

    return (
      <VBox gap={layout.padding.inline.m} align='left'>
        <FormFieldHeader caption={t('signup.fields.signup.caption')}/>
        <ResourceChip
          Model={GroupSignup}
          id={params.signup}
        />
      </VBox>
    )
  }

  function renderForm(formModel: SignupWidgetFormModel, open: boolean, requestClose: () => any) {
    return (
      <SignupWidgetForm
        open={open}
        requestClose={requestClose}
        model={formModel}
      />
    )
  }

  return render()

})

register('signup', SignupWidget)