import React from 'react'
import { useTranslation } from 'react-i18next'
import { sparse } from 'ytil'
import { projectStore } from '~/stores'
import { memo } from '~/ui/component'
import { ThemeProvider } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import ComponentCatalog, {
  ComponentCatalogItem,
  CreateComponentGroup,
  CreateComponentItem,
} from './ComponentCatalog'
import { CreateComponentType } from './types'

export interface Props {
  requestCreate: (type: CreateComponentType) => any
  active:        boolean
}

const TriggerComponentCatalog = memo('TriggerComponentCatalog', (props: Props) => {

  const {requestCreate, active} = props
  const [t] = useTranslation('flow_planner')

  const {plan} = useFlowPlanner()
  const moduleID = plan?.module

  const hasLocations = moduleID != null
    ? projectStore.isModuleFeatureEnabled('locations', moduleID)
    : false

  //------
  // Rendering

  function render() {
    return(
      <ComponentCatalog
        renderItem={renderTrigger}
        groups={groups}
        requestCreate={requestCreate}
        active={active}
      />
    )
  }

  const renderTrigger = React.useCallback((item: CreateComponentItem, index: number, selected: boolean) => (
    <ThemeProvider dark>
      <ComponentCatalogItem
        item={item}
        requestCreate={requestCreate}
        selected={selected}
      />
    </ThemeProvider>
  ), [requestCreate])

  const generalItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'trigger', name: 'date'},
    icon: 'clock',
    caption: t('triggers.date.caption'),
  }, {
    value: {type: 'trigger', name: 'qrcode'},
    icon: 'qrcode',
    caption: t('triggers.qrcode.caption'),
  }, {
    value:   {type: 'trigger', name: 'onboarding'},
    icon:    'login',
    caption: t('triggers.onboarding.caption'),
  }, {
    value:   {type: 'trigger', name: 'registration'},
    icon:    'create',
    caption: t('triggers.registration.caption'),
  }, {
    value:   {type: 'trigger', name: 'webhook'},
    icon:    'web',
    caption: t('triggers.webhook.caption'),
  }, {
    value: {type: 'trigger', name: 'manual'},
    icon:    'flash',
    caption: t('triggers.manual.caption'),
  }], [t])

  const groupItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'trigger', name: 'group:join'},
    icon: 'group',
    caption: t('triggers.group:join.caption', {nsSeparator: '|'}),
    detail: t('triggers.group:join.detail', {nsSeparator: '|'}),
  }, {
    value: {type: 'trigger', name: 'group:leave'},
    icon: 'group',
    caption: t('triggers.group:leave.caption', {nsSeparator: '|'}),
    detail: t('triggers.group:leave.detail', {nsSeparator: '|'}),
  }], [t])

  const locationItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'trigger', name: 'location:enter'},
    icon: 'location-enter',
    caption: t('triggers.location:enter.caption', {nsSeparator: '|'}),
    detail: t('triggers.location:enter.detail', {nsSeparator: '|'}),
  }, {
    value: {type: 'trigger', name: 'location:exit'},
    icon: 'location-exit',
    caption: t('triggers.location:exit.caption', {nsSeparator: '|'}),
    detail: t('triggers.location:exit.detail', {nsSeparator: '|'}),
  }], [t])

  const scriptItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'trigger', name: 'script:end'},
    icon: 'chat',
    caption: t('triggers.script:end.caption', {nsSeparator: '|'}),
    detail: t('triggers.script:end.detail', {nsSeparator: '|'}),
  }], [t])

  const challengeItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'trigger', name: 'challenge:published'},
    icon: 'challenge',
    caption: t('triggers.challenge:published.caption', {nsSeparator: '|'}),
    detail: t('triggers.challenge:published.detail', {nsSeparator: '|'}),
  }, {
    value: {type: 'trigger', name: 'challenge:abandoned'},
    icon: 'challenge',
    caption: t('triggers.challenge:abandoned.caption', {nsSeparator: '|'}),
    detail: t('triggers.challenge:abandoned.detail', {nsSeparator: '|'}),
  }, {
    value: {type: 'trigger', name: 'challenge:completed'},
    icon: 'challenge',
    caption: t('triggers.challenge:completed.caption', {nsSeparator: '|'}),
    detail: t('triggers.challenge:completed.detail', {nsSeparator: '|'}),
  }, {
    value: {type: 'trigger', name: 'challenge:reviewed'},
    icon: 'challenge',
    caption: t('triggers.challenge:reviewed.caption', {nsSeparator: '|'}),
    detail: t('triggers.challenge:reviewed.detail', {nsSeparator: '|'}),
  }], [t])

  const reviewItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'trigger', name: 'review:created'},
    icon: 'question',
    caption: t('triggers.review:created.caption', {nsSeparator: '|'}),
    detail: t('triggers.review:created.detail', {nsSeparator: '|'}),
  }], [t])

  const groups = React.useMemo((): CreateComponentGroup[] => sparse([
    {
      name: 'general',
      items: generalItems,
    },
    {
      name: 'group',
      icon: 'group',
      caption: t('triggers.group.caption'),
      items: groupItems,
    },
    hasLocations && {
      name: 'location',
      icon: 'map',
      caption: t('triggers.location.caption'),
      items: locationItems,
    },
    {
      name: 'script',
      icon: 'chat',
      caption: t('triggers.script.caption'),
      items: scriptItems,
    },
    {
      name: 'challenge',
      icon: 'challenge',
      caption: t('triggers.challenge.caption'),
      items: challengeItems,
    },
    {
      name: 'review',
      icon: 'question',
      caption: t('triggers.review.caption'),
      items: reviewItems,
    },
  ]), [generalItems, t, groupItems, hasLocations, locationItems, scriptItems, challengeItems, reviewItems])

  return render()

})

export default TriggerComponentCatalog