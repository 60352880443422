import './init'
import React from 'react'
import ReactDOM from 'react-dom'
import { JssProvider } from 'react-jss'
import { Router } from 'react-router'
import App from '~/ui/app/App'
import AppContainer from '~/ui/app/AppContainer'
import jss from '~/ui/styling/jss'
import history from './history'

ReactDOM.render((
  <Router history={history}>
    <JssProvider jss={jss}>
      <AppContainer>
        <App/>
      </AppContainer>
    </JssProvider>
  </Router>
), document.getElementById('root'))