import React from 'react'
import { createSortableElement, SortableItem } from 'react-dnd-sortable'
import { arrayMove } from 'ytil'
import { MenuPageItem, Page } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, Empty, List, ListProps, Panel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import MenuPageListItem from './MenuPageListItem'
import { MenuPageStore, NewItem, RemovedItem } from './MenuPageStore'

export const MENU_PAGE_ITEM = 'app.groundcontrol.sortable.MENU_PAGE_ITEM'

const SortableMenuPageItem = createSortableElement(VBox, {
  axis:     'vertical',
  itemType: MENU_PAGE_ITEM,
})

export interface Props {
  page:         Page
  store:        MenuPageStore
  interactive?: boolean
}

const MenuPageList = observer('MenuPageList', (props: Props) => {

  const {page, store, interactive} = props

  const editing      = store.editingItems != null
  const editingItems = store.editingItems
  const newItem      = store.newItem

  const {t} = useResourceTranslation('pages')

  const items = React.useMemo(() => {
    const items: Array<MenuPageItem | RemovedItem | NewItem> = editingItems ?? [...page.items]
    if (editingItems == null && newItem) {
      items.push(NewItem)
    }
    return items
  }, [editingItems, newItem, page.items])

  //------
  // Sorting

  const sortEnabled    = editing && store.editingItemIndex == null
  const sortableListID = React.useMemo(() => Symbol(), [])

  const handleSortDrop = React.useCallback(async (item: SortableItem, dropIndex: number) => {
    if (store.editingItems == null) { return }

    const items = arrayMove(store.editingItems, item.index, dropIndex)
    store.setEditingItems(items)
  }, [store])

  const sortable = React.useMemo((): ListProps<any>['sortable'] | undefined => {
    if (!sortEnabled) { return undefined }

    return {
      listID:     sortableListID,
      accept:     [MENU_PAGE_ITEM],
      onSortDrop: handleSortDrop,
    }
  }, [handleSortDrop, sortEnabled, sortableListID])

  function render() {
    if (items.length === 0) {
      return renderEmpty()
    }

    return (
      <Panel semi={false}>
        <List
          data={items}
          renderItem={sortEnabled ? renderSortableItem : renderItem}
          sortable={sortable}
          renderPlaceholder={renderSortPlaceholder}
          flex={false}
        />
      </Panel>
    )
  }

  function renderEmpty() {
    return (
      <Empty
        {...t('menu.no_items')}
        flex
      />
    )
  }

  function renderSortableItem(item: MenuPageItem | RemovedItem | NewItem, index: number) {
    return (
      <SortableMenuPageItem index={index} sourceList={sortableListID} payload={item}>
        {renderItem(item, index)}
      </SortableMenuPageItem>
    )
  }

  function renderItem(item: MenuPageItem | RemovedItem | NewItem, index: number) {
    if (item instanceof RemovedItem) { return null }

    return (
      <VBox>
        {index > 0 && <Dimple horizontal/>}
        <MenuPageListItem
          store={store}
          pageID={page.id}
          pageSlug={page.slug}
          item={item}
          index={index}
          isFirst={index === 0}
          isLast={index === items.length - 1}
          interactive={interactive}
        />
      </VBox>
    )
  }

  const renderSortPlaceholder = React.useCallback(
    () => <VBox style={{height: sortPlaceholderHeight}}/>,
    [],
  )

  return render()

})

const sortPlaceholderHeight = layout.barHeight.l + 2 // 2px for the dimple

export default MenuPageList