import React from 'react'
import { useTranslation } from 'react-i18next'
import { Module } from '~/models'
import { memo } from '~/ui/component'
import { colors, createUseStyles, ThemeProvider } from '~/ui/styling'
import ComponentCatalog, {
  ComponentCatalogItem,
  CreateComponentGroup,
  CreateComponentItem,
} from './ComponentCatalog'
import { CreateComponentType } from './types'

export interface Props {
  owningModule:  Module
  requestCreate: (type: CreateComponentType) => any
  active:        boolean
}

const OtherComponentCatalog = memo('OtherComponentCatalog', (props: Props) => {

  const {requestCreate, owningModule, active} = props
  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <ComponentCatalog
        groups={groups}
        renderItem={renderItem}
        requestCreate={requestCreate}
        active={active}
      />
    )
  }

  const renderItem = React.useCallback((item: CreateComponentItem, index: number, selected: boolean) => {
    const isAnnotation = item.value.type === 'annotation'
    const isModuleLifecycleItem = 'name' in item.value && (item.value.name === 'entry' || item.value.name === 'exit')
    const classNames = [
      $.item,
      isAnnotation && $.annotation,
      isModuleLifecycleItem && $.moduleLifecycleItem,
    ]
    return (
      <ThemeProvider dark={!isAnnotation}>
        <ComponentCatalogItem
          item={item}
          requestCreate={requestCreate}
          selected={selected}
          iconClassNames={classNames}
        />
      </ThemeProvider>
    )
  }, [requestCreate, $])

  const moduleLifecycleItems = React.useMemo((): CreateComponentItem[] => owningModule.main ? [] : [{
    value: {type: 'node', name: 'entry'},
    icon:    'login',
    caption: t('nodes.entry.caption'),
  }, {
    value: {type: 'node', name: 'exit'},
    icon:    'logout',
    caption: t('nodes.exit.caption'),
  }], [owningModule.main, t])

  const annotationItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'annotation', name: 'text'},
    icon: 'text',
    caption:  t('nodes.annotation.text.caption'),
  }], [t])

  const routingItems = React.useMemo((): CreateComponentItem[] => [{
    value: {type: 'node', name: 'routing'},
    icon: 'node',
    caption: t('nodes.routing.caption'),
  }, {
    value: {type: 'node', name: 'circuit-breaker'},
    icon: 'circuit',
    caption: t('nodes.circuit-breaker.caption'),
  }, {
    value: {type: 'node', name: 'splitter'},
    icon: 'splitter-node',
    caption: t('nodes.splitter.caption'),
    detail: t('nodes.splitter.detail'),
  // TODO: Not implemented on server.
  // }, {
  //   value: {type: 'node', name: 'first'},
  //   icon: 'finish-flag',
  //   caption: t('nodes.first.caption'),
  //   detail: t('nodes.first.detail'),
  }, {
    value: {type: 'node', name: 'sample'},
    icon: 'sample-node',
    caption: t('nodes.random.caption'),
    detail: t('nodes.random.detail'),
  }], [t])

  const groups = React.useMemo((): CreateComponentGroup[] => [{
    name: 'modules',
    caption: t('create_component.module_lifecycle'),
    items: moduleLifecycleItems,
  }, {
    name: 'annotation',
    caption: t('create_component.annotation'),
    items: annotationItems,
  }, {
    name: 'routing',
    icon: 'node',
    caption: t('create_component.routing'),
    items: routingItems,
  }], [t, annotationItems, routingItems, moduleLifecycleItems])

  return render()
})

export default OtherComponentCatalog

const useStyles = createUseStyles(theme => ({
  item: {
    background: theme.semantic.secondary,
  },

  annotation: {
    background: theme.bg.normal,
  },

  moduleLifecycleItem: {
    backgroundImage: colors.linearGradient(
      'bottom left',
      theme.semantic.primary,
      theme.semantic.secondary,
    ),
  },
}))