import { DateTime } from 'luxon'

export function date(format: string = 'DDD') {
  return (value: any) => {
    const dateTime = toDateTime(value)
    return dateTime.toFormat(format)
  }
}

export function time(format: string = 'HH:mm') {
  return (value: any) => {
    const dateTime = toDateTime(value)
    return dateTime.toFormat(format)
  }
}

export function datetime(format: string = 'DDD, HH:mm') {
  return (value: any) => {
    const dateTime = toDateTime(value)
    return dateTime.toFormat(format)
  }
}

function toDateTime(arg: any): DateTime {
  if (arg instanceof DateTime) {
    return arg
  } else if (arg instanceof Date) {
    return DateTime.fromJSDate(arg)
  } else if (typeof arg === 'number') {
    return DateTime.fromMillis(arg)
  } else if (typeof arg === 'string') {
    return DateTime.fromISO(arg)
  } else {
    return DateTime.invalid('invalid-type', "Could not convert to date")
  }
}