import { DraftDecorator } from 'draft-js'
import LinkDecorator from './LinkDecorator'

const linkDecorator: DraftDecorator = {
	component: LinkDecorator,
	strategy:  (contentBlock, callback, contentState) => {
		contentBlock.findEntityRanges(character => {
			const entityKey = character.getEntity()
			const entity    = entityKey == null ? null : contentState.getEntity(entityKey)
			return entity?.getType() === 'LINK'
		}, callback)
	},
}

export default linkDecorator