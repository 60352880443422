import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlanSegue } from '~/models'
import { memo } from '~/ui/component'
import { SVGName } from '~/ui/components/SVG'
import { AssistantFormDialog } from '~/ui/form'
import { useFlowPlanner } from '../FlowPlannerContext'
import SegueDelayFormModel from './SegueDelayFormModel'
import SegueDelayFormReminderStep from './SegueDelayFormReminderStep'
import SegueDelayFormTimingStep from './SegueDelayFormTimingStep'

export interface Props {
  open:          boolean
  requestClose?: () => any

  segue: PlanSegue
}

type SegueDelayFormStep = 'reminder' | 'timing'
const SegueDelayFormStep: {
  all: SegueDelayFormStep[]
} = {
  all: ['reminder', 'timing'],
}

const SegueDelayForm = memo('SegueDelayForm', (props: Props) => {

  const {open, requestClose, segue} = props

  const [t] = useTranslation('flow_planner')

  const initialStep     = segue.delay == null ? 'reminder' : 'timing'
  const [step, setStep] = React.useState<SegueDelayFormStep>(initialStep)

  const resetToInitialStep = React.useCallback(() => {
    setStep(initialStep)
  }, [initialStep])

  const {planner} = useFlowPlanner()
  const formModel = React.useMemo(
    () => new SegueDelayFormModel(planner, segue),
    [planner, segue],
  )

  //------
  // Rendering

  function render() {
    return (
      <AssistantFormDialog
        open={open}
        requestClose={requestClose}

        model={formModel}
        steps={SegueDelayFormStep.all}
        currentStep={step}
        requestStep={setStep}
        onWillOpen={resetToInitialStep}

        icon='clock'
        title={t('delay_form.title')}
        iconForStep={iconForStep}
        captionForStep={captionForStep}
        renderStep={renderStep}
      />
    )
  }

  const iconForStep = React.useCallback(
    (step: SegueDelayFormStep): SVGName => step === 'reminder' ? 'bell' : 'stopwatch',
    [],
  )

  const captionForStep = React.useCallback(
    (step: SegueDelayFormStep) => t(`delay_form.steps.${step}`),
    [t],
  )

  const renderStep = React.useCallback((step: SegueDelayFormStep) => {
    if (step === 'reminder') {
      return <SegueDelayFormReminderStep/>
    } else {
      return <SegueDelayFormTimingStep/>
    }
  }, [])

  return render()

})

export default SegueDelayForm