import React from 'react'
import { useTranslation } from 'react-i18next'
import { Feedback } from '~/models'
import { memo } from '~/ui/component'
import { ConfirmBox, PushButton, VBox } from '~/ui/components'
import { useForm } from '~/ui/form'
import FeedbackFormModel from '../FeedbackFormModel'

interface Props {
  availableTypes?: Array<Feedback['type']>
}

const ButtonFeedbackActions = memo('ButtonFeedbackActions', (props: Props) => {

  const {availableTypes} = props

  const [t] = useTranslation('feedback')
  const {model} = useForm<FeedbackFormModel>()

  const convertToChoice = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('button.actions.convert_to_choice.confirm'),
    })
    if (!confirmed) { return }

    model.convertFromButtonToChoice()
  }, [model, t])

  //------
  // Rendering

  function render() {
    if (!availableTypes?.includes('choice')) { return null }
    return (
      <VBox align='left'>
        <PushButton
          caption={t('button.actions.convert_to_choice.caption')}
          onTap={convertToChoice}
          small
        />

      </VBox>
    )
  }

  return render()

})

export default ButtonFeedbackActions