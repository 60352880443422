import React from 'react'
import { CalendarDay, CalendarItem, Group, TimeOfDay } from '~/models'
import { ConditionSummaryLabel } from '~/ui/app/conditions'
import { observer } from '~/ui/component'
import { HBox, Label, panelBorderRadius, VBox } from '~/ui/components'
import { useSimpleDrag } from '~/ui/hooks'
import { ResourceChipRow } from '~/ui/resources/components'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import CalendarItemBar from '../content/CalendarItemBar'
import { useSelection } from '../SelectionContext'
import { labelWidth } from './CalendarDayTimelineLayer'
import { dayWidth, hourHeight, hourTop } from './layout'

export interface Props {
  day:  CalendarDay
  item: CalendarItem
}

const CalendarDayContentItem = observer('CalendarDayContentItem', (props: Props) => {

  const {day, item} = props

  const {planner} = useCalendarPlanner()

  const {manager, selectedItems, selectedUUIDs} = useSelection()
  const selected = selectedUUIDs.includes(item.uuid)

  const moving = planner.isMoving(item.uuid)

  //------
  // Overlapping items

  let overlappingItems = planner.itemsBetweenTimes(day.uuid, item.time, item.time.add(60))
  if (overlappingItems == null || overlappingItems.length === 0) {
    overlappingItems = [item]
  }

  const overlapCount = moving ? 1 : overlappingItems.length
  const overlapIndex = moving ? 0 : Math.max(0, overlappingItems.indexOf(item))
  const gap          = layout.padding.inline.xs

  const top   = hourTop(item.time.minutes / 60)
  const left  = availableLeft + (overlapIndex / overlapCount) * (availableWidth + gap) + (overlapIndex - 1) * gap
  const width = (availableWidth + gap) / overlapCount - gap

  const style = {top, left, width}

  //------
  // Drag & drop

  const bodyRef = React.useRef<HTMLDivElement>(null)
  const referenceTimeRef = React.useRef<TimeOfDay | null>(null)
  const activeRef = React.useRef<boolean>(false)

  const [connectDrag] = useSimpleDrag({
    onStart: (_point, event) => {
      referenceTimeRef.current = item.time

      if (!selected) {
        if (event.shiftKey) {
          manager?.select(item.uuid)
        } else {
          manager?.selectOnly(item.uuid)
        }
      }

      planner.setMoveMode(event.altKey ? 'copy' : 'move')
    },

    onMove: (point, delta, event) => {
      if (Math.abs(delta.y) > 0) {
        activeRef.current = true
      }
      const roundTo = event.shiftKey ? 1 : 15

      const deltaMinutes = delta.y / hourHeight * 60
      const nextDay      = manager?.dayAtPoint(point) ?? day
      const dayIndex     = planner.plan?.days.indexOf(day) ?? 0
      const nextDayIndex = planner.plan?.days.indexOf(nextDay) ?? 0
      const deltaDays    = nextDayIndex - dayIndex

      planner.setMoveMode(event.altKey ? 'copy' : 'move')
      planner.moveItemsBy(selectedItems, deltaMinutes, deltaDays, {roundTo})
    },

    onEnd: (_point, _delta, event) => {
      if (activeRef.current) {
        planner.commitMoveOrCopy().then(uuids => {
          if (uuids != null) {
            manager?.selectOnly(...uuids)
          }
        })
      } else if (event.shiftKey) {
        manager?.select(item.uuid)
      } else {
        manager?.selectOnly(item.uuid)
      }
      referenceTimeRef.current = null
      activeRef.current = false
    },
  })

  const connect = connectDrag(bodyRef)

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.calendarDayContentItem, {moving}]} style={style} ref={connect}>
        <VBox classNames={$.header}>
          <HBox gap={layout.padding.inline.m} justify='space-between'>
            {renderTimeLabel()}
            {renderTargeting()}
          </HBox>
          {renderConditions()}
        </VBox>
        <VBox classNames={$.itemBody}>
          <CalendarItemBar
            item={item}
            selected={selected}
          />
        </VBox>
      </VBox>
    )
  }

  function renderConditions() {
    if (item.conditions.length === 0) { return null }

    return (
      <VBox classNames={$.conditions}>
        <ThemeProvider contrast='secondary'>
          {item.conditions.map((condition, index) => (
            <ConditionSummaryLabel
              key={index}
              condition={condition}
              small
            />
          ))}
        </ThemeProvider>
      </VBox>
    )
  }

  function renderTimeLabel() {
    return (
      <Label classNames={$.timeLabel} caption small color={colors.semantic.secondary}>
        {item.time.toFormat('H:mm')}
      </Label>
    )
  }

  function renderTargeting() {
    if (item.targeting.groups.length === 0) { return null }

    return (
      <VBox classNames={$.targeting}>
        <ResourceChipRow
          Model={Group}
          ids={item.targeting.groups}
          collapseFrom={6}
          wrap
          small
        />
      </VBox>
    )
  }

  return render()

})

export default CalendarDayContentItem

const availableLeft  = labelWidth + layout.padding.inline.m
const availableWidth = dayWidth - availableLeft - layout.padding.inline.m

const useStyles = createUseStyles(theme => ({
  calendarDayContentItem: {
    position: 'absolute',

    '&.hidden': {
      visibility: 'hidden',
    },
    '&.moving': {
      zIndex: layout.z.bodyTop,
    },
  },

  itemBody: {
    pointerEvents: 'auto',
    height:        hourHeight,
    position:      'relative',
  },

  header: {
    position: 'absolute',
    left:     0,
    right:    0,
    bottom:   '100%',
  },

  conditions: {
    background:   theme.semantic.secondary,
    borderRadius: [panelBorderRadius(theme), panelBorderRadius(theme), 0, 0],
    padding:      [layout.padding.inline.xs, layout.padding.inline.s],

    paddingBottom: layout.padding.inline.xs + panelBorderRadius(theme),
    marginBottom:  -panelBorderRadius(theme),
  },

  targeting: {
    marginBottom: layout.padding.inline.xs,
  },

  timeLabel: {
    textShadow: `1px 1px 2px ${shadows.shadowColor.alpha(0.2)}`,
  },

}))