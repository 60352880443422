import React from 'react'
import { Project } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Dimple, HBox, Label, SVG, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  project: Project
}

const ProjectHeader = memo('ProjectHeader', (props: Props) => {

  const {project} = props
  const {t} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.l} align='top'>
        <ImageView
          source={project.logo ?? {icon: 'logo'}}
          size={logoSize}
          classNames={$.logo}
          objectFit='contain'
        />

        <VBox flex='both'>
          <VBox style={{maxWidth: 480}} gap={layout.padding.m}>
            <VBox>
              <Label h1>
                {project.name}
              </Label>
              <Label mono dim>
                {project.code}
              </Label>
            </VBox>

            <Dimple horizontal/>

            <VBox gap={layout.padding.s}>
              <HBox align='baseline' gap={layout.padding.inline.m}>
                <SVG name='clock' size={layout.icon.s} dim/>
                <Label flex>
                  {project.timeZone}
                </Label>
              </HBox>
              <HBox align='baseline' gap={layout.padding.inline.m}>
                <SVG name='calendar' size={layout.icon.s} dim/>
                <Label flex>
                  {[
                    project.startDate.toFormat('d MMM yyyy'),
                    project.endDate?.toFormat('d MMM yyyy'),
                  ].filter(Boolean).join('  ∼  ')}
                </Label>
              </HBox>
              <HBox align='baseline' gap={layout.padding.inline.m}>
                <SVG name='participant' size={layout.icon.s} dim/>
                <Label flex>
                  {t('participant_count', {count: project.participantCount})}
                </Label>
              </HBox>
            </VBox>
          </VBox>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default ProjectHeader

export const logoSize = {
  width:  160,
  height: 160,
}

const useStyles = createUseStyles({
  logo: {
    borderRadius: layout.radius.m,
  },
})