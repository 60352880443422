import socket from 'socket.io-react'
import { register } from './support'

export class RuntimeStore {

  public async runScript(scriptID: string, participantID: string | null, options: RunScriptOptions = {}) {
    const response = await socket.send('runtime:run-script', scriptID, participantID, options)
    return response.ok
  }

  public async activateNode(planID: string, nodeUUID: string, participantID: string | null) {
    const response = await socket.send('runtime:activate-node', planID, nodeUUID, participantID)
    return response.ok
  }

}

export interface RunScriptOptions {
  revision?:  number
  chapter?:   string
  testRun?:   boolean
}

const runtimeStore = register(new RuntimeStore())
export default runtimeStore