import React from 'react'
import { useTranslation } from 'react-i18next'
import { TalliedResult } from '~/models'
import { Result } from '~/stores'
import { memo } from '~/ui/component'
import { ColorBullet, DataTable, HBox, Label, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { pivotData, segmentCaption } from './util'

export interface Props {
  results: Array<Result & TalliedResult>
}

const DataTableResult = memo('DataTableResult', (props: Props) => {

  const {results} = props
  const [data, segments] = React.useMemo(
    () => pivotData(results),
    [results],
  )

  const [t] = useTranslation('answers')
  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='grow' classNames={$.dataTableResult}>
        <DataTable flex data={data} bodyClassNames={$.body} headerClassNames={$.header} scrollable>
          <DataTable.Column flex name='answer' header={t('results.columns.answer')} classNames={$.answerCell}>
            {(point, index) => (
              <HBox flex gap={layout.padding.inline.m}>
                <ColorBullet
                  color={colors.palette('default', index, data.length)}
                />
                <VBox flex>
                  <Label truncate={false}>
                    {point.answer}
                  </Label>
                </VBox>
              </HBox>
            )}
          </DataTable.Column>
          {segments.map(segment => (
            <DataTable.Column
              key={segment.value}
              name={segment.value}
              header={segmentCaption(segment)}
              align='right'
              children={point => <Label mono>{point.values[segment.value]}</Label>}
            />
          ))}
        </DataTable>

      </VBox>
    )
  }

  return render()

})

export default DataTableResult

const useStyles = createUseStyles((theme) => ({
  dataTableResult: {
    margin: [0, -layout.padding.inline.l],
  },

  header: {
    padding: [0, layout.padding.inline.l],
  },

  body: {
    padding: [0, layout.padding.inline.l],
  },

  answerCell: {
    padding: [layout.padding.inline.m, 0],
  },
}))