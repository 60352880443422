import React from 'react'
import { useTranslation } from 'react-i18next'
import { sprintf } from 'sprintf-js'
import { Widget, WidgetAlotment } from '~/models'
import { DataPoint, WidgetState } from '~/stores'
import { memo } from '~/ui/component'
import { Gauge, HBox, Label, SVG, Tooltip, VBox } from '~/ui/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { widget } from './registry'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  state:    WidgetState | null
}

const GaugeWidgetView = memo('GaugeWidgetView', (props: Props) => {

  const {state} = props
  const [t] = useTranslation('analytics')

  const {colors} = useStyling()

  const [gaugeSize, setGaugeSize] = React.useState<Size | null>(null)
  const fontSize = gaugeSize == null ? 0 : gaugeSize.width / 6

  const data  = state?.data ?? []
  const color = colors.resolve(state?.config?.color ?? 'neutral')
  const min   = state?.config?.min ?? 0
  const max   = state?.config?.max ?? 100

  const ranges = React.useMemo(() => {
    return (state?.config?.ranges ?? []).map((range: any) => ({
      start: range.start,
      end:   range.end,
      color: colors.resolve(range.color),
    }))
  }, [state?.config?.ranges, colors])

  const actualPoint: DataPoint | null   = data?.find(it => it.key === 'actual') ?? null
  const previousPoint: DataPoint | null = data?.find(it => it.key === 'previous') ?? null

  const trend = previousPoint == null || actualPoint == null ? null : actualPoint.value - previousPoint.value

  const formatValue = React.useCallback((value: number) => {
    if (state?.config?.format != null) {
      return sprintf(state.config.format, value)
    } else {
      return value.toString()
    }
  }, [state?.config?.format])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.gaugeWidgetView}>
        {renderGauge()}
        {renderActual()}
      </VBox>
    )
  }

  function renderGauge() {
    if (actualPoint == null) { return null }

    return (
      <Gauge
        value={actualPoint.value}
        min={min}
        max={max}

        color={color}
        ranges={ranges}

        flex
        align='center'
        justify='bottom'

        onSize={setGaugeSize}
      />
    )
  }

  function renderActual() {
    if (actualPoint == null) { return null }

    return (
      <HBox classNames={$.actual} flex='grow' justify='center' align='middle' gap={layout.padding.inline.m}>
        <Label classNames={$.actualLabel} align='center' style={{fontSize}}>
          {formatValue(actualPoint.value)}
        </Label>
        {renderTrend()}
      </HBox>
    )
  }

  function renderTrend() {
    if (trend == null) { return null }

    return (
      <Tooltip renderTooltip={renderTrendTooltip}>
        <VBox align='center' gap={layout.padding.inline.xs}>
          {trend < 0 && <SVG name='triangle-down' color={colors.semantic.negative} size={layout.icon.s}/>}
          {trend > 0 && <SVG name='triangle-up' color={colors.semantic.positive} size={layout.icon.s}/>}
          {trend === 0 && <SVG name='equals' color={colors.semantic.neutral} size={layout.icon.s}/>}
          {trend !== 0 && (
            <Label caption tiny dim>
              {`${trend >= 0 ? '+' : ''}${formatValue(trend)}`}
            </Label>
          )}
        </VBox>
      </Tooltip>
    )
  }

  const renderTrendTooltip = React.useCallback(() => {
    if (trend == null) { return null }

    const key = trend > 0 ? 'up' : trend < 0 ? 'down' : 'equal'
    return t(`gauge.trend.${key}`, {trend: formatValue(trend)})
  }, [formatValue, t, trend])

  return render()

})

widget('gauge')(GaugeWidgetView)
export default GaugeWidgetView

const useStyles = createUseStyles({
  gaugeWidgetView: {
    position: 'relative',
  },

  actual: {
    position: 'absolute',
    left:     0,
    right:    0,
    bottom:   0,

    fontWeight:   'bold',
    marginBottom: 4,
  },

  actualLabel: {
    fontSize: 'inherit',
  },
})