import React from 'react'
import { action, makeObservable, observable } from 'mobx'
import { ClientApp, Language } from '~/models'
import { dataStore, SubmitResult } from '~/stores'
import { memo } from '~/ui/component'
import { Label, SwitchField, VBox } from '~/ui/components'
import { FormDialog, FormField, FormModel } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import { LocalizedTextField } from '../fields'

export interface Props {
  open:         boolean
  name:         string
  title:        string
  clientApp:    ClientApp
  requestClose: () => any
}

const OAuthProviderConfigForm = memo('OAuthProviderConfigForm', (props: Props) => {

  const {open, requestClose, name, clientApp, title} = props

  const {t} = useResourceTranslation('client-apps')

  const model = React.useMemo(
    () => new OAuthProviderConfigFormModel(name, clientApp),
    [name, clientApp],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDialog open={open} model={model} requestClose={requestClose} icon='login' title={t('fields.auth.oauth.configure.title', {title})}>
        {renderDisplayLogo()}
        {renderCaption()}
      </FormDialog>
    )
  }

  function renderDisplayLogo() {
    return (
      <FormField name='displayLogo' caption={false}>
        {bind => (
          <SwitchField
            {...bind}
            label={enabled => (
              <VBox gap={layout.padding.inline.s}>
                <Label caption dim={!enabled}>
                  {t('fields.auth.oauth.configure.displayLogo.caption')}
                </Label>
                <Label small dim>
                  {t('fields.auth.oauth.configure.displayLogo.detail')}
                </Label>
              </VBox>
            )}
          />
        )}
        </FormField>
    )
  }

  function renderCaption() {
    return (
      <FormField name='caption' i18nKey='auth.oauth.configure.caption' placeholder={t('fields.auth.oauth.caption', {title})}>
        {bind => <LocalizedTextField {...bind}/>}
      </FormField>
    )
  }

  return render()

})

export default OAuthProviderConfigForm

class OAuthProviderConfigFormModel implements FormModel {

  constructor(
    public readonly providerName: string,
    public readonly clientApp:    ClientApp,
  ) {
    makeObservable(this)

    const provider = clientApp.auth.oAuthProviders.find(it => it.name === providerName)
    if (provider != null) {
      this.displayLogo = provider?.config.displayLogo
      this.caption = provider?.config.caption
    }
  }

  @observable
  public displayLogo: boolean = true

  @observable
  public caption: Record<Language, string> | null = null

  @action
  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(ClientApp, this.clientApp.id, {
      auth: {
        ...this.clientApp.auth,
        oAuthProviders: [
          ...this.clientApp.auth.oAuthProviders.filter(it => it.name !== this.providerName),
          {
            name:    this.providerName,
            enabled: true,
            config: {
              displayLogo: this.displayLogo,
              caption:     this.caption,
            },
          },
        ],
      },
    })
  }
}