import Toast from 'react-toast'
import Axios, { AxiosInstance, AxiosResponse } from 'axios'
import I18n from 'i18next'
import config from '~/config'
import { authenticationStore } from '~/stores'

let axios!: AxiosInstance
initAxios(null)

export function initAxios(authToken: string | null) {
  axios = createAxios(authToken)
}

function createAxios(authToken: string | null) {
  const axios = Axios.create({
    // Configure base URL from config.
    baseURL: config.api.baseURL,

    // Never throw errors.
    validateStatus: null,
  })

  if (authToken != null) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  }

  axios.interceptors.response.use(response => {
    const {status} = response
    if (status < 400) { return response }

    if (status >= 500) {
      Toast.show({
        type:   'error',
        ...I18n.t<any>(['errors:500', 'errors:unknown']),
      })
    } else if (status >= 401) {
      authenticationStore.logOut()
    } else if (status !== 404 && status !== 410 && status !== 422) {
      Toast.show({
        type:   'error',
        ...I18n.t<any>([`errors:${status}`, 'errors:unknown']),
      })
    }

    return response
  }, error => {
    // As we're validating any response status, the only reason an error will be thrown will be a
    // connection issue.
    Toast.show({
      type:   'error',
      ...I18n.t<any>('errors:no_connection'),
    })

    // I don't like exceptions on runtime stuff. Rather, I'd like to return a response with a 0 status and
    // `null` data.
    const response: AxiosResponse = error.response ?? {
      data:       null,
      status:     0,
      statusText: "No Connection",
      headers:    {},
      config:     error.request?.config ?? {},
      request:    error.request,
    }

    return response
  })

  return axios
}

export default axios