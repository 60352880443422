import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import md5 from 'md5'
import { Media, Model, SVGImage } from '~/models'
import { mediaStore, StoreMediaOptions } from '~/stores'
import { Avatar } from '~/ui/app/media'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { Center, ClearButton, ConfirmBox, Label, PopupMenu, Spinner, VBox } from '~/ui/components'
import { PopupMenuItem } from '~/ui/components/popup-menu'
import { colors, createUseStyles, layout, shadows, useStyling } from '~/ui/styling'
import processMediaUpload from '../media/processMediaUpload'

export interface Props<M extends ProfilePhotoModel> {
  model: M
  size:  Size

  requestUpdate: (media: Media | null) => Promise<any>
}

export interface ProfilePhotoModel extends Model {
  name:       string
  photoURL:   string | null

  firstName?: string
  lastName?:  string
  email?:     string
}

const _ProfilePhotoField = <M extends ProfilePhotoModel>(props: Props<M>) => {

  const {model, size, requestUpdate} = props

  const [t] = useTranslation('profile_photo_field')

  const uploaderRef = React.useRef<MediaUploader>(null)

  const {colors} = useStyling()

  const options = React.useMemo((): StoreMediaOptions => ({
    convert: {'image/*': 'image/jpeg'},
  }), [])

  //------
  // Rendering

  const $ = useStyles(size)

  function render() {
    return (
      <VBox align='center' gap={layout.padding.inline.s}>
        <MediaUploader
          ref={uploaderRef}
          accept='image/*'
          storeMediaOptions={options}
          onUploadComplete={handleUploadComplete}
          renderContent={renderContent}
          onDropRejected={handleDropRejected}
          noClick
        />
        <PopupMenu items={actionMenuItems} crossAlign='center'>
          {toggle => (
            <ClearButton
              icon='pencil'
              caption={t('edit_photo')}
              onTap={toggle}
            />
          )}
        </PopupMenu>
      </VBox>
    )
  }

  function renderContent(state: MediaUploaderState) {
    const avatarData: any = 'firstName' in model
      ? {firstName: model.firstName, lastName: model.lastName, source: model.photoURL}
      : {firstName: model.name, lastName: null, source: model.photoURL}

    return (
      <VBox classNames={$.dropzoneContent}>
        <Avatar
          {...avatarData}
          size={size}
        />
        {state.isDragAccept ? (
          renderHint('accept')
        ) : state.isDragReject ? (
          renderHint('reject')
        ) : state.isDragActive ? (
          renderHint('active')
        ) : null}
        {(state.uploading || updating) && (
          <Center classNames={$.working}>
            <Spinner/>
          </Center>
        )}
      </VBox>
    )
  }

  function renderHint(type: string) {
    return (
      <VBox justify='middle' classNames={[$.dropHint, type]} flex gap={layout.padding.inline.m}>
        <Label h2 align='center'>
          {t(`drop_hint.${type}.title`)}
        </Label>
        <Label dim small align='center' truncate={false}>
          {t(`drop_hint.${type}.detail`)}
        </Label>
      </VBox>
    )
  }

  //------
  // Drop

  const handleDropRejected = React.useCallback(() => {
      Toast.show({
      ...t('drop_hint.reject'),
      type: 'error',
    })
  }, [t])

  //------
  // Popup menu

  const timer = useTimer()
  const [updating, setUpdating] = React.useState<boolean>(false)

  const updatePhoto = React.useCallback(async (media: Media | null) => {
    try {
      setUpdating(true)
      await timer.await(requestUpdate(media))
    } finally {
      setUpdating(false)
    }
  }, [requestUpdate, timer])

  const handleUploadComplete = React.useCallback((media: Media | SVGImage | null) => {
    if (!(media instanceof Media)) { return }
    return updatePhoto(media)
  }, [updatePhoto])

  const upload = React.useCallback(() => {
    uploaderRef.current?.browse()
  }, [])

  const findOnGratavar = React.useCallback(async () => {
    if (model.email == null) { return }

    setUpdating(true)

    try {
      const blob = await timer.await(fetchFromGravatar(model.email))
      if (blob == null) {
        Toast.show({
          ...t('gravatar.not_found'),
          type: 'info',
        })
      } else {
        const result = await timer.await(mediaStore.storeMedia(model.name, blob))
        const media  = processMediaUpload(result)
        if (media == null) { return null }

        return updatePhoto(media)
      }
    } finally {
      setUpdating(false)
    }
  }, [model.email, model.name, t, timer, updatePhoto])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('remove_photo.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    return updatePhoto(null)
  }, [t, updatePhoto])

  const actionMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'camera',
      caption:  t('upload_photo'),
      onSelect: upload,
    })

    if (model.email != null) {
      items.push({
        icon:     'gravatar',
        caption:  t('gravatar.caption'),
        onSelect: findOnGratavar,
      })
    }
    items.push({
      icon:     'trash',
      caption:  t('remove_photo.caption'),
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [colors.semantic.negative, findOnGratavar, model.email, remove, t, upload])

  return render()

}

const ProfilePhotoField = memo('ProfilePhotoField', _ProfilePhotoField) as typeof _ProfilePhotoField
export default ProfilePhotoField

async function fetchFromGravatar(email: string): Promise<Blob | null> {
  const hash = md5(email.toLocaleLowerCase())
  const url  = `https://www.gravatar.com/avatar/${hash}?s=512&d=404`

  try {
    const response = await fetch(url)
    if (response.status === 404) { return null }

    return await response.blob()
  } catch {
    return null
  }
}

const useStyles = createUseStyles(theme => ({
  dropzoneContent: (size: Size) => ({
    ...size,
    borderRadius: size.height / 2,

    boxShadow: shadows.depth(1),
  }),

  dropHint: (size: Size) => ({
    ...layout.overlay,
    borderRadius: size.height / 2,

    background: colors.white.alpha(0.2),
    padding:    layout.padding.inline.l,

    '&.active': {
      background: theme.semantic.focus.alpha(0.6),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.focus)),
    },

    '&.accept': {
      background: theme.semantic.positive.alpha(0.6),
      ...colors.overrideForeground(theme.colors.fg.light.normal),
    },
    '&.reject': {
      background: theme.semantic.negative.alpha(0.6),
      ...colors.overrideForeground(theme.colors.fg.light.normal),
    },
  }),

  working: (size: Size) => ({
    ...layout.overlay,
    borderRadius: size.height / 2,
    background:   colors.shim.white,
  }),
}))