import * as UUID from 'uuid'
import config from '~/config'
import { Challenge, Module, PlannerAction, Script, Triggerable } from '~/models'
import { FlowPlanner } from '~/stores'
import { SubmitResult } from '~/ui/form'
import TriggerableFormModel from '../../triggerables/TriggerableFormModel'

export default class FlowTriggerableFormModel extends TriggerableFormModel {

  constructor(
    protected readonly planner: FlowPlanner,
    private readonly operation: FlowTriggerableFormOperation,

    triggerable: Triggerable | null,
    type:        Triggerable['type'],
    module:      Module,
    model:       Script | Challenge | null = null,
    action:      PlannerAction | null = null,
  ) {
    super(triggerable, type, module, model, action)
  }

  //------
  // Submission

  protected async submitWithTriggerable(triggerable: AnyObject): Promise<SubmitResult | undefined> {
    if ('create' in this.operation) {
      const size = config.planner.defaultComponentSize('triggerable')
      const bounds = {
        left:   this.operation.create.x,
        top:    this.operation.create.y,
        width:  -1,
        height: size.height,
      }

      return this.planner.createNode({
        type:         'triggerable',
        uuid:         UUID.v4(),
        name:         null,
        triggerables: [{uuid: UUID.v4(), ...triggerable} as Triggerable],
        bounds:       bounds,
      })
    } else {
      return this.planner.updateComponents<Triggerable>(
        [this.operation.update],
        t => ({...t, ...triggerable}),
      )
    }
  }

}

export type FlowTriggerableFormOperation = {
  create: Point
} | {
  update: string
}