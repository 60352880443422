import React from 'react'
import { range } from 'lodash'
import { memo } from '~/ui/component'
import { HBox } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'

export interface Props {
  reaction: string
  count:    number
}

const ReactionCountRow = memo('ReactionCountRow', (props: Props) => {

  const {reaction, count} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.reactionCountRow}>
        {range(0, Math.min(count, 4)).map(index => (
          <span key={index}>
            {reaction}
          </span>
        ))}
      </HBox>
    )
  }

  return render()

})

export default ReactionCountRow

export const reactionSize = 16
export const gap = 4

const useStyles = createUseStyles({
  reactionCountRow: {
    '& span': {
      display:  'block',
      fontSize: reactionSize,
      '&:not(:first-child)': {
        marginLeft: gap - reactionSize,
      },
    },
  },
})