/* eslint-disable react-hooks/rules-of-hooks */

import React from 'react'
import { OptimizedContext, OptimizedContextProvider, OptimizedContextUseHook } from './types'

export function createOptimizedContext<Ctx, OCtx>(defaultValue: Ctx & OCtx): OptimizedContext<Ctx, OCtx> {
  const Context          = React.createContext<Ctx>(defaultValue)
  const OptimizedContext = React.createContext<OCtx>(defaultValue)

  const Provider: OptimizedContextProvider<Ctx, OCtx> = props => (
    <Context.Provider value={props.value}>
      <OptimizedContext.Provider value={props.optimizedValue}>
        {props.children}
      </OptimizedContext.Provider>
    </Context.Provider>
  )

  const useHook   = (() => React.useContext(OptimizedContext)) as OptimizedContextUseHook<Ctx, OCtx>
  useHook.unoptim = () => ({ ...React.useContext(OptimizedContext), ...React.useContext(Context)})

  return {Provider, useHook}
}