import React from 'react'
import { ClientApp } from '~/models'
import { authenticationStore, projectStore } from '~/stores'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import OrganisationField from '~/ui/app/organisations/OrganisationField'
import { observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  LanguageField,
  TextField,
  TimeZoneField,
  Tooltip,
  VBox,
} from '~/ui/components'
import { FormDialog, FormField, SubmitResult } from '~/ui/form'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout, useStyling } from '~/ui/styling'
import CreateProjectFormModel from './CreateProjectFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any
}

const CreateProjectForm = observer('CreateProjectForm', (props: Props) => {

  const {
    open,
    requestClose,
  } = props

  const user = authenticationStore.user
  const isSuper = user != null && user.isSuper()

  const formModel = React.useMemo(
    () => new CreateProjectFormModel(isSuper ? undefined : user?.organisation),
    [isSuper, user],
  )

  const {t} = useResourceTranslation('projects')

  const {colors} = useStyling()

  //------
  // Callbacks

  const createOrganisation = React.useCallback((name: string) => {
    formModel.createNewOrganisation(name)
  }, [formModel])

  const switchToCreatedProject = React.useCallback((result: SubmitResult) => {
    if (result.status !== 'ok' || result.data?.id == null) { return }
    projectStore.switchProject(result.data.id)
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='projects'>
        <FormDialog
          open={open}
          requestClose={requestClose}
          afterSubmit={switchToCreatedProject}
          model={formModel}
          title={t('create.title')}
          children={renderFields()}
          semi
        />
      </ResourceTypeProvider>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        {isSuper && renderOrganisationField()}
        {formModel.hasOrganisation && (
          <>
            {isSuper && <Dimple horizontal/>}
            {renderProjectFields()}
          </>
        )}
      </VBox>
    )
  }

  function renderOrganisationField() {
    if (formModel.creatingNewOrganisation) {
      return (
        <>
          <FormField name='newOrganisationName' required>
            {bind => <TextField {...bind}/>}
          </FormField>
          <FormField name='defaultApp' required>
            {bind => <ResourceField {...bind} Model={ClientApp}/>}
          </FormField>
        </>
      )
    } else {
      return (
        <FormField name='organisation' required>
          {bind => (
            <OrganisationField
              requestCreate={createOrganisation}
              {...bind}
            />
          )}
        </FormField>
      )
    }
  }

  function renderProjectFields() {
    return (
      <VBox gap={layout.padding.m}>
        <HBox gap={layout.padding.m} align='top'>
          <VBox flex gap={layout.padding.s}>
            <FormField name='name' required>
              {bind => <TextField {...bind}/>}
            </FormField>
            <FormField name='code' required>
              {bind => renderCodeField(bind)}
            </FormField>
          </VBox>
          <VBox>
            <FormField name='logo' renderAsLabel={false}>
              {bind => (
                <CustomImageField
                  {...bind}
                  allowedTypes={['remote']}
                  size={{width: 116, height: 116}}
                  objectFit='contain'
                />
              )}
            </FormField>
          </VBox>
        </HBox>
        <VBox gap={layout.padding.s}>
          <FormField name='timeZone' required>
            {bind => <TimeZoneField {...bind}/>}
          </FormField>
          <FormField name='languages'>
            {bind => <LanguageField {...bind} multi/>}
          </FormField>
        </VBox>
      </VBox>
    )
  }

  function renderCodeField(bind: FormFieldBindProps<string>) {
    return (
      <TextField
        {...bind}
        mono
        readOnly={formModel.autoGenerateCode}
        accessoryRight={renderAutoGenerateCodeToggle()}
      />
    )
  }

  function renderAutoGenerateCodeToggle() {
    const tooltip = formModel.autoGenerateCode
      ? t('fields.code.autogenerated.on')
      : t('fields.code.autogenerated.off')

    return (
      <Tooltip renderTooltip={tooltip}>
        <ClearButton
          icon='flash'
          color={formModel.autoGenerateCode ? colors.semantic.primary : colors.fg.dark.dim}
          onTap={() => formModel.toggleAutoGenerateCode()}
        />
      </Tooltip>
    )
  }

  return render()

})

export default CreateProjectForm