import { ImportFieldGroup, RelatedField, RelatedFieldOptions } from 'sheet-importer'

export default class GroupsField extends RelatedField {

  constructor(
    name: string,
    tag: string | null,
    options: RelatedFieldOptions = {},
  ) {
    super(name, 'groups', options)
    this.tag = tag
  }

  public readonly tag: string | null

  public static fieldGroup(name: string, tags: string[], options: Omit<RelatedFieldOptions, 'multi'> = {}) {
    const group: ImportFieldGroup = {
      key: name,
      fields: [],
    }

    group.fields.push({
      key:    name,
      create: () => new GroupsField(name, null, {multi: true, ...options}),
    })
    for (const tag of tags) {
      group.fields.push({
        key:    `${name}:${tag}`,
        create: () => new GroupsField(name, tag, options),
      })
    }

    return group
  }

  public serialize() {
    return {
      ...super.serialize(),
      type: 'groups',
      tag:  this.tag,
    }
  }

  public save() {
    return super.save()
  }

  public load(raw: any) {
    super.load(raw)
  }

}