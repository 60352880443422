import React from 'react'
import { Participant, Review } from '~/models'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, Label, Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { layout } from '~/ui/styling'

export interface Props {
  review: Review
}

const ReviewParticipant = memo('ReviewParticipant', (props: Props) => {

  const {review} = props
  const [participant, {fetchStatus}] = useModelDocumentData(Participant, review?.participant ?? null)

  //------
  // Rendering

  function render() {
    if (participant != null) {
      return renderParticipant()
    } else if (fetchStatus === 'fetching') {
      return renderFetching()
    } else {
      return null
    }
  }

  function renderFetching() {
    return (
      <Center flex>
        <Spinner size={16}/>
      </Center>
    )
  }

  function renderParticipant() {
    if (participant == null) { return null }

    return (
      <Center gap={layout.padding.s}>
        <Avatar
          {...participant}
          source={participant.photoURL}
          size={avatarSize}
        />
        <Label h3>
          {participant.name}
        </Label>
      </Center>
    )
  }

  return render()

})

export default ReviewParticipant

export const avatarSize = {
  width:  96,
  height: 96,
}