import React from 'react'
import { snakeCase } from 'lodash'
import { ModelClass } from '~/models'
import { memo } from '~/ui/component'
import { Chip, HBox, Label, Tooltip, VBox } from '~/ui/components'
import { ModelDocumentOptions } from '~/ui/hooks/data'
import { colors, createUseStyles, layout, useTheme } from '~/ui/styling'
import { useResourceTranslation } from '../ResourceTranslation'
import ResourceChip from './ResourceChip'

export interface Props {
  Model:         ModelClass<any>
  ids:           string[]
  fetch?:        ModelDocumentOptions['fetch']
  relationship?: 'other' | 'has' | 'belongs-to' | 'included-in' | 'used-by'

  interactive?:  boolean
  wrap?:         boolean
  collapseFrom?: number
  tooltip?:      string
  small?:        boolean
}

const ResourceChipRow = memo('ResourceChipRow', (props: Props) => {

  const {
    Model,
    ids,
    fetch,
    collapseFrom = 3,
    wrap         = false,
    relationship = 'has',
    interactive  = true,
    small        = false,
  } = props

  const {t}      = useResourceTranslation()
  const {plural} = useResourceTranslation(Model.resourceType)
  const tooltip  = props.tooltip ?? t(`resource_cell.tooltip.${snakeCase(relationship)}`, {objectPlural: plural()})


  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox wrap={wrap} gap={layout.padding.inline.s}>
        {ids.slice(0, collapseFrom - 1).map(id => (
          <ResourceChip
            key={id}
            Model={Model}
            id={id}
            fetch={fetch}
            small={small}
            interactive={interactive}
          />
        ))}
        {ids.length > collapseFrom - 1 && (
          <Tooltip renderTooltip={renderMoreTooltip()}>
            <Chip small backgroundColor={theme.fg.dimmer} color={theme.fg.dim} classNames={$.moreChip}>
              {t('resource_cell.more', {count: ids.length - collapseFrom + 1})}
            </Chip>
          </Tooltip>
        )}
      </HBox>
    )
  }

  function renderMoreTooltip() {
    return (
      <VBox gap={layout.padding.s}>
        {relationship !== 'other' && (
          <Label small bold align='center'>
            {tooltip}
          </Label>
        )}
        <HBox wrap gap={layout.padding.inline.s} justify='center'>
          {ids.map(id => (
            <ResourceChip
              key={id}
              Model={Model}
              id={id}
              fetch={fetch}
            />
          ))}
        </HBox>
      </VBox>
    )
  }

  return render()

})

const useStyles = createUseStyles(theme => ({
  moreChip: {
    '&:hover': {
      ...colors.overrideBackground(theme.semantic.secondary),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.secondary)),
    },
  },

}))

export default ResourceChipRow