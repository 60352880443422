import React from 'react'
import { Condition } from '~/models'
import { ConditionSummaryLabel } from '~/ui/app/conditions'
import { memo } from '~/ui/component'
import { Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { borderRadius as scriptMessageBubbleBorderRadius } from './messages/ScriptMessageBubble'

export interface Props {
  conditions:  Condition[]
  requestEdit: () => any
}

const ScriptMessageConditionsHeader = memo('ScriptMessageConditionsHeader', (props: Props) => {

  const {conditions, requestEdit} = props

  //------
  // Rendering

  const {colors} = useStyling()
  const $        = useStyles()

  function render() {
    if (conditions.length === 0) { return null }

    return (
      <ThemeProvider contrast={colors.semantic.secondary}>
        <VBox classNames={$.container}>
          <VBox classNames={$.conditionsHeader} gap={layout.padding.inline.s}>
            {conditions.map((condition, index) => (
              <Tappable key={index} onDoubleClick={requestEdit}>
                <ConditionSummaryLabel
                  condition={condition}
                  small
                  bold
                />
              </Tappable>
            ))}
          </VBox>
        </VBox>
      </ThemeProvider>
    )
  }

  return render()

})

export default ScriptMessageConditionsHeader

const useStyles = createUseStyles(theme => {
  const borderRadius = scriptMessageBubbleBorderRadius(theme)

  return {
    container: {
      marginBottom:  -borderRadius,
    },

    conditionsHeader: {
      borderTopLeftRadius:  borderRadius,
      borderTopRightRadius: borderRadius,
      background:           theme.semantic.secondary,
      padding:              [layout.padding.inline.s, layout.padding.inline.m],
      paddingBottom:        layout.padding.inline.s + borderRadius,
    },
  }
})