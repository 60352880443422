import React from 'react'
import { Widget } from '~/models'
import { memo } from '~/ui/component'
import { panelBorderRadius, VBox } from '~/ui/components'
import { createUseStyles, presets } from '~/ui/styling'
import WidgetTile from './WidgetTile'

export interface Props {
  widget:      Widget
  onDashboard: boolean
  fits?:       boolean
}

const WidgetGhost = memo('WidgetGhost', (props: Props) => {

  const {widget, onDashboard, fits = true} = props

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={[$.widgetGhost, {fits}]}>
        <WidgetTile
          widget={widget}
          truncate={onDashboard}
          flex
        />
      </VBox>
    )
  }

  return render()

})

export default WidgetGhost

const useStyles = createUseStyles(theme => ({
  widgetGhost: {
    position: 'relative',
    ...presets.overlayAfter({
      borderRadius: panelBorderRadius(theme),
    }),

    '&:not(.fits)': {
      opacity: 0.8,

      '&::after': {
        background: theme.semantic.negative.alpha(0.1),
      },
    },
  },
}))