import React from 'react'
import { TimeOfDay } from '~/models'
import { observer } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import { useCalendarLayout } from './CalendarLayoutContext'
import { useSelection } from './SelectionContext'

const SelectionLayer = observer('SelectionLayer', () => {

  const {timeOfDayToScreenY} = useCalendarLayout()

  //------
  // Layout

  const layerRef = React.useRef<HTMLDivElement>(null)

  const {selectionBounds} = useSelection()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.selectionLayer} ref={layerRef}>
        <div classNames={$.layer}>
          {renderSelectionRectangle()}
        </div>
      </div>
    )
  }

  function renderSelectionRectangle() {
    if (selectionBounds == null) { return null }

    return (
      <div
        classNames={$.selectionRectangle}
        style={selectionRectangleStyle}
      />
    )
  }

  const selectionRectangleStyle = React.useMemo(() => {
    if (selectionBounds == null) { return {} }

    const layerRect = layerRef.current?.getBoundingClientRect()

    const left      = Math.min(selectionBounds.base.point.x, selectionBounds.extent.point.x)
    const right     = Math.max(selectionBounds.base.point.x, selectionBounds.extent.point.x)
    const min       = TimeOfDay.min(selectionBounds.base.time, selectionBounds.extent.time)
    const max       = TimeOfDay.max(selectionBounds.base.time, selectionBounds.extent.time)
    return {
      left:    left - (layerRect?.left ?? 0),
      width:   right - left,
      top:     timeOfDayToScreenY(min) - (layerRect?.top ?? 0),
      height:  timeOfDayToScreenY(max) - timeOfDayToScreenY(min),
    }
  }, [selectionBounds, timeOfDayToScreenY])

  return render()

})

const useStyles = createUseStyles(theme => ({
  selectionLayer: {
    ...layout.overlay,
    pointerEvents: 'none',
  },

  layer: {
    position: 'absolute',
    top:      0,
    bottom:   0,
  },

  selectionRectangle: {
    position:  'absolute',
    border:    [1, 'solid', theme.colors.fg.dark.dim],
  },
}))

export default SelectionLayer