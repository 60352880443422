import React from 'react'
import { Condition } from '~/models'
import { memo } from '~/ui/component'
import { List, ListProps } from '~/ui/components'
import { layout } from '~/ui/styling'
import ConditionBar from './ConditionBar'

export interface Props extends Omit<ListProps<Condition>, 'data' | 'keyExtractor' | 'renderItem'> {
  conditions:     Condition[]
  requestEdit?:   (condition: Condition, index: number) => any
  requestRemove?: (condition: Condition, index: number) => any
}

const ConditionsList = memo('ConditionsList', (props: Props) => {

  const {
    conditions,
    requestEdit,
    requestRemove,
    ...rest
  } = props

  //------
  // Rendering

  function render() {
    return (
      <List
        data={conditions}
        renderItem={renderConditionBar}
        itemGap={layout.padding.inline.s}
        {...rest}
      />
    )
  }

  function renderConditionBar(condition: Condition, index: number) {
    return (
      <ConditionBar
        condition={condition}
        index={index}
        requestEdit={requestEdit}
        requestRemove={requestRemove}
      />
    )
  }

  return render()

})

export default ConditionsList