import React from 'react'
import { AppLayoutConfig } from './types'

export interface AppLayoutContext {
  configure:           (key: string, config: AppLayoutConfig) => void
  removeConfiguration: (key: string) => void
}

export const AppLayoutContext = React.createContext<AppLayoutContext>({
  configure: () => void 0,
  removeConfiguration: () => void 0,
})