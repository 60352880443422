import React from 'react'
import { Challenge, Module, ModuleParameter, ModuleParameterType, Script } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Empty,
  HBox,
  Label,
  ModalDialog,
  PanelList,
  PopupMenu,
  PopupMenuItem,
  PopupProps,
  PushButton,
  VBox,
} from '~/ui/components'
import { translateFormErrorPaths } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ResourceChip from '../../../resources/components/ResourceChip'
import ParameterForm from './ParameterForm'
import ParameterFormModel from './ParameterFormModel'

export interface Props {
  moduleID: string

  open:         boolean
  requestClose: () => any
}

const ParametersDialog = observer('ParametersDialog', (props: Props) => {

  const {moduleID, open, requestClose} = props
  const [module]   = useModelDocumentData(Module, moduleID, {fetch: 'notfound'})

  const {t} = useResourceTranslation('modules')

  const [editingParameterIndex, setEditingParameterIndex] = React.useState<number>(-1)
  const [addingParameterType, setAddingParameterType] = React.useState<ModuleParameterType>('string')
  const [parameterFormOpen, openParameterForm, closeParameterForm] = useBoolean()

  const saveParameter = React.useCallback(async (data: AnyObject) => {
    if (module?.parameters == null) { return }

    const parameters = [...module.parameters]
    const parameter  = data as ModuleParameter

    if (editingParameterIndex === -1) {
      parameters.push(parameter)
    } else {
      parameters[editingParameterIndex] = parameter
    }

    const result = await dataStore.update(Module, module.id, {parameters})
    return translateFormErrorPaths(result, path => path.replace(/^parameters\.\d+\./, ''))
  }, [editingParameterIndex, module])

  const editingParameterFormModel = React.useMemo(() => {
    if (module?.parameters == null) { return null }

    if (editingParameterIndex === -1) {
      return ParameterFormModel.addParameter(addingParameterType, saveParameter)
    } else {
      const parameter = module.parameters[editingParameterIndex]
      if (parameter != null) {
        return ParameterFormModel.editParameter(parameter, saveParameter)
      }
    }

    return null
  }, [addingParameterType, editingParameterIndex, module, saveParameter])

  const addParameter = React.useCallback((type: ModuleParameterType) => {
    setEditingParameterIndex(-1)
    setAddingParameterType(type)
    openParameterForm()
  }, [openParameterForm])

  const editParameter = React.useCallback((_, index: number) => {
    setEditingParameterIndex(index)
    openParameterForm()
  }, [openParameterForm])

  const removeParameter = React.useCallback((_, index: number) => {
    if (module?.parameters == null) { return }

    const parameters = [...module.parameters]
    parameters.splice(index, 1)
    return dataStore.update(Module, module.id, {parameters})
  }, [module])

  //------
  // Choices

  const addParameterItems = React.useMemo((): PopupMenuItem[] => ModuleParameterType.all().map(type => ({
    value:   type,
    caption: t(`parameters.types.${type}.caption`),
    detail:  t(`parameters.types.${type}.detail`),
  })), [t])

  //------
  // Rendering

  function render() {
    if (module?.parameters == null) { return null }

    return (
      <ModalDialog
        icon='code'
        title={t('parameters.title')}
        headerRight='$close'
        width={720}
        height={560}
        semi={false}

        open={open}
        requestClose={requestClose}
      >
        {module.parameters.length === 0 ? (
          <Empty flex padding={layout.padding.m} {...t('parameters.empty')}>
            {renderAddParameter('center')}
          </Empty>
        ) : (
          <VBox flex padding={layout.padding.m} gap={layout.padding.s}>
            {renderParameterList()}
            {renderListFooter()}
          </VBox>
        )}

        {editingParameterFormModel != null && (
          <ParameterForm
            open={parameterFormOpen}
            requestClose={closeParameterForm}
            model={editingParameterFormModel}
          />
        )}
      </ModalDialog>
    )
  }

  function renderParameterList() {
    return (
      <PanelList<ModuleParameter> data={module?.parameters ?? []}>
        <PanelList.VBox<ModuleParameter>
          caption={t('parameters.fields.name.list_caption')}
          renderCell={parameter => <Label mono bold>{parameter.name}</Label>}
          flex={2}
        />
        <PanelList.VBox<ModuleParameter>
          caption={t('parameters.fields.type.list_caption')}
          renderCell={parameter => <Label box>{t(`parameters.types.${parameter.type}.caption`)}</Label>}
          flex={1}
        />
        <PanelList.VBox<ModuleParameter>
          caption={t('parameters.fields.default.list_caption')}
          renderCell={renderDefault}
          flex={3}
        />

        <PanelList.Action<ModuleParameter>
          icon='pencil'
          onTap={editParameter}
        />
        <PanelList.Action<ModuleParameter>
          icon='cross-circle'
          onTap={removeParameter}
        />
      </PanelList>
    )
  }

  const renderDefault = React.useCallback((parameter: ModuleParameter) => {
    if (parameter.default == null) {
      return (
        <Label dim italic>
          {t('parameters.no_default')}
        </Label>
      )
    }

    if (ModuleParameter.isTriggerableParameter(parameter)) {
      return (
        <ResourceChip
          Model={parameter.type === 'script' ? Script : Challenge}
          id={parameter.default}
        />
      )
    } else if (parameter.type === 'boolean') {
      return (
        <Label>
          {t(`parameters.boolean.default.${parameter.default}`)}
        </Label>
      )
    } else {
      return (
        <Label>
          {parameter.default.toString()}
        </Label>
      )
    }
  }, [t])

  function renderListFooter() {
    return (
      <HBox>
        {renderAddParameter('near')}
      </HBox>
    )
  }

  function renderAddParameter(crossAlign: PopupProps['crossAlign']) {
    return (
      <PopupMenu items={addParameterItems} onValueSelect={addParameter} crossAlign={crossAlign}>
        {toggle => (
          <PushButton
            icon='plus'
            caption={t('parameters.add_parameter')}
            onTap={toggle}
          />
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default ParametersDialog