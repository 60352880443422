import React from 'react'
import { memo } from '~/ui/component'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

const ReviewTriggerNodeView = memo('ReviewTriggerNodeView', (props: CommonTriggerNodeViewProps) => {
  return (
    <TriggerNodeView
      {...props}
      detail={false}
      icon='flash'
    />
  )
})

register('review:created', ReviewTriggerNodeView)