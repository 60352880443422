import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Post } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class PostFormModel implements FormModel {

  constructor(
    private readonly feedID: string,
    private readonly postID: string,
    private readonly comment: Post | null,
  ) {
    makeObservable(this)
  }

  @observable
  public readonly author = this.comment?.author ?? null

  @observable
  public readonly body  = this.comment?.body ?? null

  public async submit(): Promise<SubmitResult | undefined> {
    const data = {
      feed:   this.feedID,
      root:   this.postID,
      author: this.author,
      body:   cleanTextValue(this.body, true),
    }

    if (this.comment == null) {
      return dataStore.create(Post, data)
    } else {
      return dataStore.update(Post, this.comment.id, data)
    }
  }

}