import { makeObservable, observable } from 'mobx'
import { Branding, CustomImage, Language } from '~/models'
import { BrandingGuide } from '~/ui/styling'
import ComponentBrandingFormModel from '../ComponentBrandingFormModel'
import { registerComponentBrandingFormModel } from '../registry'

export default class WebHeaderBrandingFormModel extends ComponentBrandingFormModel {

  constructor(guide: BrandingGuide, branding: Branding, componentName: string, initialVariant: string | null) {
    super(guide, branding, componentName, initialVariant)

    makeObservable(this)
  }

  @observable
  public logo: CustomImage | null = this.getImage('logo', 'web')

  @observable
  public title: Record<Language, string> = this.getTextTranslations('title')

  public buildData() {
    return {
      ...super.buildData(),
      images: this.mergeImage('logo', 'web', this.logo),
      texts: this.mergeTexts({
        title: this.title,
      }),
    }
  }

}

registerComponentBrandingFormModel('web.header', WebHeaderBrandingFormModel)