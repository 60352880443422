import React from 'react'
import { memo } from '~/ui/component'
import DateTimeComponentsField, { Props as DateTimeComponentsFieldProps, DateTimeComponentsFieldTemplate } from './DateTimeComponentsField'

export interface Props extends Omit<DateTimeComponentsFieldProps, 'template' | 'picker'> {
  separator?: string
}

const TimeField = memo('TimeField', (props: Props) => {

  const {separator = ':', ...rest} = props

  const template = React.useMemo((): DateTimeComponentsFieldTemplate => [
    {component: 'hour'},
    {separator},
    {component: 'minute'},
  ], [separator])

  return (
    <DateTimeComponentsField
      {...rest}
      template={template}
      picker={false}
    />
  )

})

export default TimeField