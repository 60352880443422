import React from 'react'
import { every } from 'lodash'
import { memo } from '~/ui/component'
import {
  Center,
  Dimple,
  HBox,
  Label,
  ListSection,
  SearchableGridList,
  SVG,
  Tappable,
  Tooltip,
  VBox,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useFocusOnActivate } from '~/ui/hooks'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { CreateComponentType } from './types'

export interface Props {
  groups:        CreateComponentGroup[]
  renderItem:    (item: CreateComponentItem, index: number, selected: boolean) => React.ReactNode
  requestCreate: (type: CreateComponentType) => any
  columns?:      number
  active:        boolean
}

const ComponentCatalog = memo('ComponentCatalog', (props: Props) => {

  const {groups, renderItem, requestCreate, columns = 4, active} = props
  const [search, setSearch] = React.useState<string | null>(null)

  const searchFieldRef = useFocusOnActivate(active, {timeout: 0})

  const filtered = React.useMemo((): CreateComponentGroup[] => {
    if (search == null) {
      return groups.filter(g => g.items.length > 0)
    }

    const terms = search.toLowerCase().split(/\s+/).filter(Boolean)
    const matches = (group: CreateComponentGroup, item: CreateComponentItem) => {
      return every(terms, term => {
        if (group.caption?.toLowerCase().includes(term)) { return true }
        if (item.caption.toLowerCase().includes(term)) { return true }
        return false
      })
    }

    return groups
      .map(group => ({
        ...group,
        items: group.items.filter(item => matches(group, item)),
      }))
      .filter(g => g.items.length > 0)
  }, [search, groups])

  const handleKeyboardSelect = React.useCallback((item: CreateComponentItem) => {
    requestCreate(item.value)
  }, [requestCreate])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <SearchableGridList
        data={filtered}
        searchFieldRef={searchFieldRef}
        onSearch={setSearch}
        renderCell={renderItem}
        renderSectionHeader={renderGroupHeader}
        keyExtractor={keyExtractor}
        itemGap={layout.padding.xs}
        columns={columns}
        classNames={$.componentCatalog}
        contentClassNames={$.content}
        onKeyboardSelect={handleKeyboardSelect}
        sectioned={false}
        flex
      />
    )
  }

  const renderGroupHeader = React.useCallback((section: ListSection<CreateComponentItem>) => {
    const index = filtered.findIndex(g => g.name === section.name)
    const group = filtered[index]
    return (
      <VBox gap={layout.padding.m} classNames={index > 0 && $.groupHeader}>
        {index !== 0 && <Dimple horizontal />}
        {group.caption != null && (
          <HBox classNames={$.groupName}>
            <Label caption small>
              {group.caption}
            </Label>
          </HBox>
        )}
      </VBox>
    )
  }, [filtered, $.groupHeader, $.groupName])

  const keyExtractor = React.useCallback((item: CreateComponentItem) => (
    `${item.value.type}${'name' in item.value ? item.value.name : null}`
  ), [])

  return render()
})

export default ComponentCatalog

export interface ComponentCatalogItemProps {
  item:          CreateComponentItem
  requestCreate: (type: CreateComponentType) => any
  selected:      boolean

  classNames?:     React.ClassNamesProp
  iconClassNames?: React.ClassNamesProp
}

export const ComponentCatalogItem = memo('ComponentCatalogItem', (props: ComponentCatalogItemProps) => {

  const {item, requestCreate, selected, classNames, iconClassNames} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return item.detail ? (
      <Tooltip renderTooltip={item.detail} delay={1000}>
        {renderButton()}
      </Tooltip>
    ) : renderButton()
  }

  function renderButton() {
    return (
      <Tappable classNames={[$.componentButton, {selected}, classNames]} onTap={tap}>
        <Center>
          <VBox classNames={[$.componentButtonIcon, iconClassNames]} align='center' justify='middle'>
            <SVG
              name={item.icon}
              size={layout.icon.m}
            />
          </VBox>
          <VBox paddingVertical={layout.padding.xs}>
            <ThemeProvider light>
              <Label bold tiny truncate>{item.caption}</Label>
            </ThemeProvider>
          </VBox>
        </Center>
      </Tappable>
    )
  }

  const tap = React.useCallback(() => {
    requestCreate(item.value)
  }, [requestCreate, item.value])

  return render()
})

export const size = layout.barHeight.l

const useStyles = createUseStyles(theme => ({
  componentCatalog: {
    background: theme.bg.semi,
  },

  content: {
    ...layout.responsive(size => ({
      paddingTop: layout.padding.xs[size],
      paddingBottom: layout.padding.s[size],
    })),
  },

  groupHeader: {
    ...layout.responsive(size => ({
      paddingTop: layout.padding.s[size],
    })),
  },

  groupName: {
    ...layout.responsive(size => ({
      padding: [layout.padding.xs[size], layout.padding.s[size]],
      paddingTop: 0,
    })),
  },

  componentButton: {

  },

  componentButtonIcon: {
    height:          size,
    width:           size,
    borderRadius:    size,
    backgroundColor: theme.colors.bg.dark.normal,
    boxShadow:       shadows.depth(1),

    '$componentButton.selected &': {
      boxShadow: [
        shadows.depth(1),
        shadows.selected(theme),
      ],
    },
  },
}))

export interface CreateComponentGroup {
  name:     string
  items:    CreateComponentItem[]
  icon?:    SVGName
  caption?: string
}

export interface CreateComponentItem {
  value:   CreateComponentType
  icon:    SVGName
  caption: string
  detail?: string
}
