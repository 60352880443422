import React from 'react'
import { Feed } from '~/models'
import { memo } from '~/ui/component'
import { ColorSwatch, HBox, Label, PushButton } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  feed: Feed
  requestNewPost: () => any
}

const FeedHeader = memo('FeedHeader', (props: Props) => {

  const {feed, requestNewPost} = props
  const {actionCaption} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.postHeader} gap={layout.padding.inline.m}>
        {renderLeft()}
        {renderRight()}
      </HBox>
    )
  }

  function renderLeft() {
    return (
      <HBox flex gap={layout.padding.inline.m}>
        <ColorSwatch
          color={feed.color}
          round
        />
        <Label h1 flex>
          {feed.name}
        </Label>
      </HBox>
    )
  }

  function renderRight() {
    return (
      <PushButton
        icon='plus'
        caption={actionCaption('new_post')}
        onTap={requestNewPost}
      />
    )
  }

  return render()

})

export default FeedHeader

export const avatarSize = {
  width:  72,
  height: 72,
}

const useStyles = createUseStyles({
  postHeader: {
    ...layout.responsiveProp({
      paddingBottom: layout.padding.m,
    }),
  },
})