import React from 'react'
import { LocalizedString } from '~/models'
import { LocalizedTextField, RichTextField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { TextField } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { StringField } from '../types'

export interface Props {
  field: StringField
  bind:  FormFieldBindProps<string | LocalizedString>
}

const StringAllowance = memo('StringAllowance', (props: Props) => {

  const {field, bind} = props

  //------
  // Rendering

  function render() {
    if (field.richText) {
      return (
        <RichTextField
          {...bind}
          value={bind.value ?? ''}
          localized={field.localized}
        />
      )
    } else if (field.localized) {
      return (
        <LocalizedTextField
          {...bind}
          maxLength={field.maxLength}
          multiline={field.multiline}
          mono={field.mono}
        />
      )
    } else {
      return (
        <TextField
          {...bind as FormFieldBindProps<string>}
          maxLength={field.maxLength}
          multiline={field.multiline}
          mono={field.mono}
        />
      )
    }
  }

  return render()

})

export default StringAllowance