import React from 'react'
import { reaction } from 'mobx'
import { observer } from '~/ui/component'
import InsightsDialog from '../insights/InsightsDialog'
import { useCalendarPlanner } from './CalendarPlannerContext'

export interface Props {
  open:         boolean
  requestClose: () => any

  dayUUID: string
}

const CalendarPlannerInsightsDialog = observer('CalendarPlannerInsightsDialog', (props: Props) => {

  const {open, requestClose, dayUUID} = props

  const {planner} = useCalendarPlanner()

  const endpoint = React.useMemo(
    () => planner.service.dayPositionsEndpoint(dayUUID),
    [dayUUID, planner.service],
  )

  const fetch = React.useCallback(() => {
    endpoint?.fetch()
  }, [endpoint])

  React.useEffect(() => {
    if (!open) { return }
    return reaction(() => planner.service.insights, fetch)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch, open])

  const abandon = React.useCallback(async (uuids: string[]) => {
    await planner.service.abandonStates(uuids)
  }, [planner.service])

  if (endpoint == null) { return null }

  return (
    <InsightsDialog
      open={open}
      requestClose={requestClose}
      endpoint={endpoint}
      requestAbandon={abandon}
    />
  )

})

export default CalendarPlannerInsightsDialog