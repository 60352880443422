import React from 'react'
import { Route, RouteComponentProps } from 'react-router-dom'
import { ResourceDetailParams, ResourceListParams } from '~/ui/resources/routes'

export interface Components {
  ListScreen?:   React.ComponentType<RouteComponentProps<ResourceListParams>>
  DetailScreen?: React.ComponentType<RouteComponentProps<ResourceDetailParams<any>>>
}

export function renderResourceRoutes(resourceType: string, components: Components) {

  const {ListScreen, DetailScreen} = components

  const routes = []

  if (ListScreen != null) {
    routes.push(
      <Route
        key={`resource:${resourceType}:list`}
        path={`/:type(${resourceType})/:label?`}
        exact={true}
        component={ListScreen}
      />,
    )
  }

  if (DetailScreen != null) {
    routes.push(
      <Route
        key={`resource:${resourceType}:detail`}
        path={`/:type(${resourceType})/-/:id/:body?`}
        exact={true}
        component={DetailScreen}
      />,
    )
  }

  return routes
}

export function renderNestedResourceRoutes(parentType: string, resourceType: string, components: Components) {

  const {ListScreen, DetailScreen} = components

  const routes = []

  if (ListScreen != null) {
    routes.push(
      <Route
        key={`resource:${parentType}:${resourceType}:list`}
        path={`/:parentType(${parentType})/:parentID/:type(${resourceType})/:label?`}
        exact={true}
        component={ListScreen}
      />,
    )
  }

  if (DetailScreen != null) {
    routes.push(
      <Route
        key={`resource:${parentType}:${resourceType}:detail`}
        path={`/:parentType(${parentType})/-/:parentID/:type(${resourceType})/-/:id/:body?`}
        exact={true}
        component={DetailScreen}
      />,
    )
    }

  return routes
}