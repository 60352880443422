import React from 'react'
import { Metric, MetricDatum } from '~/models'
import { dataStore, MetricDataEndpoint } from '~/stores'
import ParticipantCell from '~/ui/app/participants/ParticipantCell'
import { observer } from '~/ui/component'
import { Chip, DataGrid, Empty, HBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'

export interface Props {
  metric: Metric
}

const MetricDataGrid = observer('MetricDataGrid', (props: Props) => {

  const {metric} = props
  const document = dataStore.document(Metric, metric.id)
  const endpoint = React.useMemo(
    () => new MetricDataEndpoint(document),
    [document],
  )

  const {t} = useResourceTranslation('metrics')

  React.useEffect(() => {
    endpoint.fetch()
  }, [endpoint])

  //------
  // Rendering

  function render() {
    return (
      <DataGrid<MetricDatum>
        data={endpoint.data}
        EmptyComponent={renderEmpty}
        keyForItem={keyForItem}
      >
        <DataGrid.Column<MetricDatum>
          flex={2}
          name='participant'
          caption={t('data.columns.participant.caption')}
          renderCell={MetricParticipantCell.renderer}
        />
        <DataGrid.Column<MetricDatum>
          flex={2}
          name='timestamp'
          caption={t('data.columns.timestamp.caption')}
          renderCell={datum => datum.timestamp.toRelative()}
        />
        <DataGrid.Column<MetricDatum>
          flex={1}
          name='value'
          caption={t('data.columns.value.caption')}
          renderCell={datum => <HBox><Chip>{datum.value}</Chip></HBox>}
        />
      </DataGrid>
    )
  }

  const MetricParticipantCell = React.useMemo(
    () => ParticipantCell.for((datum: MetricDatum) => datum.participant),
    [],
  )

  const keyForItem = React.useCallback(
    (datum: MetricDatum) => `${datum.participant}:${datum.timestamp}`,
    [],
  )

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty
        {...t('data.empty')}
        flex
      />
    )
  }, [t])

  return render()

})

export default MetricDataGrid