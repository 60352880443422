import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import config from '~/config'
import { Document, DocumentFetchResponse } from 'mobx-document'
import { observer } from '~/ui/component'
import {
  EmptyOrFetching,
  HBox,
  Label,
  List,
  ListItem,
  ModalDialog,
  Panel,
  SelectField,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { usePrevious } from '~/ui/hooks'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  which:    'client' | 'server'
  version?: string
}

const ChangelogDialog = observer('ChangelogDialog', (props: Props) => {

  const {open, requestClose, which, version: initialVersion} = props

  const [t] = useTranslation('config')

  const [version, setVersion] = React.useState<string | null>(initialVersion ?? null)

  const document = React.useMemo(() => {
    return new ChangelogDocument(config.changelog[which])
  }, [which])

  React.useEffect(() => {
    document.fetch()
  }, [document])

  const fetchStatus    = document.fetchStatus
  const changelog      = document.data
  const currentVersion = changelog?.versions.find(it => it.version === version)

  const versions = React.useMemo(() => {
    const versions = changelog?.versions.map(it => it.version) ?? []
    versions.sort((a, b) => {
      const [aMajor, aMinor] = a.split('.')
      const [bMajor, bMinor] = a.split('.')

      if (aMajor !== bMajor) { return parseInt(aMajor, 10) - parseInt(bMajor, 10) }
      if (aMinor !== bMinor) { return parseInt(aMinor, 10) - parseInt(bMinor, 10) }
      return 0
    })

    return versions
  }, [changelog?.versions])

  const prevInitialVersion = usePrevious(initialVersion)

  React.useEffect(() => {
    if (prevInitialVersion !== undefined && initialVersion !== prevInitialVersion && initialVersion !== version && initialVersion != null) {
      setVersion(initialVersion)
    } else if (fetchStatus === 'done' && version == null && versions.length > 0) {
      setVersion(versions[versions.length - 1])
    }
  }, [fetchStatus, initialVersion, prevInitialVersion, version, versions])

  const versionChoices = React.useMemo(
    () => versions.map((version): Choice => ({value: version, caption: version})),
    [versions],
  )

  const handleVersionChange = React.useCallback((version: string | null) => {
    setVersion(version)
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        icon='logo'
        title={t(`changelog.title.${which}`)}
        headerRight='$close'
        width={720}
        height={560}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox flex gap={layout.padding.m} padding={layout.padding.m}>
        {renderVersionField()}
        {renderChangeList()}
      </VBox>
    )
  }

  function renderVersionField() {
    return (
      <VBox gap={layout.padding.s} align='left'>
        <HBox gap={layout.padding.inline.m}>
          <Label caption dim>
            {t('changelog.version')}
          </Label>
          <VBox width={160}>
            <SelectField<string | null>
              value={version}
              onChange={handleVersionChange}
              choices={versionChoices}
            />
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderChangeList() {
    return (
      <Panel flex>
        <List
          data={currentVersion?.changes ?? []}
          contentClassNames={$.changeListContent}
          EmptyComponent={renderEmpty}
          renderItem={renderChangeRow}
          scrollable
        />
      </Panel>
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('changelog.empty')}
        status={fetchStatus}
        flex
      />
    )
  }, [fetchStatus, t])

  const renderChangeRow = React.useCallback((change: ChangelogChange) => {
    return (
      <ListItem
        caption={change.title}
        detail={<Label small dim truncate={false}>{change.description}</Label>}
      />
    )
  }, [])

  return render()

})

interface Changelog {
  versions: ChangelogVersion[]
}

interface ChangelogVersion {
  version: string
  changes: ChangelogChange[]
}

interface ChangelogChange {
  title:       string
  description: string
}

class ChangelogDocument extends Document<Changelog> {

  constructor(
    public readonly url: string,
  ) {
    super(url)
  }

  protected async performFetch(): Promise<DocumentFetchResponse<Changelog | null> | null | undefined> {
    const response = await fetch(this.url)
    if (!response.ok) {
      return {error: new Error(`Could not fetch changelog (HTTP ${response.status})`)}
    }

    const changelog = await response.json()
    return {
      data: changelog,
      meta: {},
    }
  }


}

export default ChangelogDialog

const useStyles = createUseStyles({
  changeListContent: {
    padding: [layout.padding.inline.m / 2, 0],
  },
})