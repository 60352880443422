import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { BeaconLocation, LatLongLocation, Location, Participant } from '~/models'
import { GoogleMapManager } from '~/ui/app/google-maps'
import ParticipantCollectionForm from '~/ui/app/participants/ParticipantCollectionForm'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useModelDocument, useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ActionButton, ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import LocationForm from '../form/LocationForm'
import BeaconLocationBody from './BeaconLocationBody'
import LatLongLocationBody from './LatLongLocationBody'

export type Props = RouteComponentProps<ResourceDetailParams>

const LocationScreen = observer('LocationScreen', (props: Props) => {

  const locationID = props.match.params.id

  const {t} = useResourceTranslation('locations')

  const document = useModelDocument(Location, locationID)

  const mapManagerRef = React.useRef<GoogleMapManager>(null)
  const fitMapOnSave = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      mapManagerRef.current?.fitFeatures()
    }
  }, [])

  //------
  // Participants

  const participantsEndpoint = useModelEndpoint(Participant, {
    filters: {
      currentLocation: locationID,
    },
  })

  const [participantsFormOpen, openParticipantsForm, closeParticipantsForm] = useBoolean()

  const closeFormsAndReload = React.useCallback(() => {
    closeParticipantsForm()
    participantsEndpoint.fetch()
    document.fetch()
  }, [closeParticipantsForm, document, participantsEndpoint])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<Location>
        Model={Location}
        id={props.match.params.id}

        EditFormComponent={LocationForm}
        afterSubmitEdit={fitMapOnSave}
        renderInfo={renderInfo}
        renderBody={renderBody}
        renderActions={renderActions}
      />
    )
  }

  function renderInfo(location: Location) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: location.createdAt})}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  function renderBody(location: Location) {
    if (location.type === 'latlong') {
      return (
        <LatLongLocationBody
          location={location as LatLongLocation}
          participantsEndpoint={participantsEndpoint}
          mapManagerRef={mapManagerRef}
        />
      )
    } else {
      return (
        <BeaconLocationBody
          location={location as BeaconLocation}
          participantsEndpoint={participantsEndpoint}
        />
      )
    }
  }

  function renderActions() {
    return (
      <VBox align='left'>
        <ActionButton
          icon='participant'
          name='edit-participants'
          onTap={openParticipantsForm}
        />

        <ParticipantCollectionForm
          open={participantsFormOpen}
          requestClose={closeFormsAndReload}

          ParentModel={Location}
          parentID={locationID}
          filterAttribute='currentLocation'
        />
      </VBox>
    )
  }

  return render()

})

export default LocationScreen