import React from 'react'
import { SystemNotification } from '~/models'
import { observer } from '~/ui/component'
import { EmptyOrFetching, VBox } from '~/ui/components'
import { useModelDocument } from '~/ui/hooks/data'
import { layout } from '~/ui/styling'
import NotificationDetailHeader from './NotificationDetailHeader'
import NotificationInfoList from './NotificationInfoList'
import NotificationRelationshipsList from './NotificationRelationshipsList'
import NotificationResultsView from './NotificationResultsView'

export interface Props {
  id: string
}

const NotificationDetail = observer('NotificationDetail', (props: Props) => {

  const {id} = props
  const document = useModelDocument(SystemNotification, id)

  const fetchStatus   = document.fetchStatus
  const relationships = document.relationships

  const notification = document.data != null
    ? {...document.data, relationships} as SystemNotification
    : null

  //------
  // Rendering

  function render() {
    if (notification == null) {
      return renderEmpty()
    } else {
      return (
        <VBox flex gap={layout.padding.m}>
          <NotificationDetailHeader notification={notification}/>
          <NotificationInfoList notification={notification}/>
          <NotificationResultsView notification={notification}/>
          <NotificationRelationshipsList notification={notification}/>
        </VBox>
      )
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        status={fetchStatus}
        flex
      />
    )
  }

  return render()

})

export default NotificationDetail