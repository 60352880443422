import Color from 'color'
import { objectEquals } from 'ytil'
import { Theme } from '~/ui/styling/Theme'
import createFeatureComponent from './createFeatureComponent'
import { LatLong } from './types'
import { convertToLatLng } from './util'

export interface Props {
  coordinate: LatLong

  icon?: google.maps.Icon

  label?:      string
  labelStyle?: LabelStyle
  options?:    google.maps.MarkerOptions

  onClick?: () => any
}

export interface LabelStyle {
  color?:      Color
  fontFamily?: string
  fontSize?:   string | number
  fontWeight?: string | number
}

interface Marker {
  getMarker(): google.maps.Marker | null
  center():    void
  bounce():    void
}

const Marker = createFeatureComponent<google.maps.Marker, Props, Marker>('GoogleMap.Marker', {

  create: (props, manager, theme) => {
    const marker = new google.maps.Marker({
      position:  convertToLatLng(props.coordinate),
      label:     createLabel(props, theme) ?? undefined,
      icon:      props.icon,
      clickable: props.onClick != null,
      ...props.options,
    })

    return marker
  },

  update: (prevProps, nextProps, marker, manager, theme) => {
    let modified = false

    if (!objectEquals(prevProps.coordinate, nextProps.coordinate)) {
      marker.setPosition(convertToLatLng(nextProps.coordinate))
      modified = true
    }
    if (prevProps.label !== nextProps.label) {
      marker.setLabel(createLabel(nextProps, theme))
      modified = true
    }

    const iconChanged =
      nextProps.icon == null ? prevProps.icon != null :
      prevProps.icon != null ? !objectEquals(prevProps.icon, nextProps.icon) :
      false
    if (iconChanged) {
      marker.setIcon(nextProps.icon ?? null)
      modified = true
    }

    return modified
  },

  listeners: props => ({
    click: props.onClick,
  }),

  handle: (props, marker, manager) => ({
    getMarker() {
      return marker
    },

    center() {
      const position = marker?.getPosition()
      if (position == null) { return }

      manager?.googleMap.panTo(position)
    },

    bounce() {
      marker?.setAnimation(google.maps.Animation.BOUNCE)
      window.setTimeout(() => {
        marker?.setAnimation(null)
      }, 500)
    },
  }),

})

export default Marker

function createLabel(props: Props, theme: Theme): google.maps.MarkerLabel | null {
  const {label, labelStyle = {}} = props
  if (label == null) { return null }

  const defaultFGColor = theme.colors.contrast(theme.semantic.primary)

  return {
    color:      `${labelStyle.color?.alpha(1) ?? defaultFGColor}`,
    fontFamily: labelStyle.fontFamily ?? theme.fonts.captionLarge.family,
    fontSize:   `${labelStyle.fontSize ?? theme.fonts.captionLarge.size}px`,
    fontWeight: `${labelStyle.fontWeight ?? theme.fonts.captionLarge.weight}`,
    text:       label,
  }
}