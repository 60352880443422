import React from 'react'
import { useTranslation } from 'react-i18next'
import { LocalizedString, Model, ModelClass, reflect } from '~/models'
import { memo, observer } from '~/ui/component'
import { EmptyOrFetching, List, SearchField, VBox } from '~/ui/components'
import { useForm } from '~/ui/form'
import { useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceBar } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import LinkFormModel from './LinkFormModel'

export interface Props<M extends Model> {
  Model:    ModelClass<M>
  filters?: Record<string, any>
}

const _LinkFormResourceFields = <M extends Model>(props: Props<M>) => {

  const {Model, filters} = props
  const [models, {fetchStatus, endpoint}] = useModelEndpointData(Model, {filters})
  const {model: formModel} = useForm<LinkFormModel>()

  const [t] = useTranslation('links')
  const {plural} = useResourceTranslation(Model.resourceType)

  const [search, setSearch] = React.useState<string | null>(null)

  React.useEffect(() => {
    const searchParam = endpoint.param('search') ?? null
    if (search !== searchParam) {
      endpoint.setParams({search})
    }
  }, [endpoint, search])

  const href = formModel.href

  const isSelected = React.useCallback((model: M) => {
    return reflect(model, 'appLink') === href
  }, [href])

  const select = React.useCallback((model: M) => {
    formModel.set(
      reflect(model, 'appLink'),
      LocalizedString.translate(model.$caption),
    )
  }, [formModel])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.modelLinkTab}>
        <SearchField
          onSearch={setSearch}
        />
        <List
          classNames={$.list}
          data={models}
          renderItem={renderListItem}
          itemGap={layout.padding.inline.s}
          contentPadding={layout.padding.s}
          EmptyComponent={renderEmpty}
          scrollable
        />
      </VBox>
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('resource.empty', {plural: plural()})}
        status={fetchStatus}
      />
    )
  }, [fetchStatus, plural, t])

  const renderListItem = React.useCallback((model: M) => {
    return (
      <LinkFormResourceListItem<M>
        model={model}
        selected={isSelected(model)}
        onSelect={select}
      />
    )
  }, [isSelected, select])

  return render()

}

const LinkFormResourceFields = observer('LinkFormResourceFields', _LinkFormResourceFields) as typeof _LinkFormResourceFields
export default LinkFormResourceFields

interface LinkFormResourceListItemProps<M extends Model> {
  model:    M
  selected: boolean
  onSelect: (model: M) => any
}

const _LinkFormResourceListItem = <M extends Model>(props: LinkFormResourceListItemProps<M>) => {

  const {model, selected, onSelect} = props

  const select = React.useCallback(() => {
    onSelect(model)
  }, [onSelect, model])

  return (
    <ResourceBar
      model={model}
      selected={selected}
      onTap={select}
    />
  )

}

const LinkFormResourceListItem = memo('LinkFormResourceListItem', _LinkFormResourceListItem) as typeof _LinkFormResourceListItem

const useStyles = createUseStyles({
  list: {
    ...layout.responsive(size => ({
      margin: [0, -layout.padding.s[size]],
    })),
  },

  modelLinkTab: {
    paddingTop: 2,
    paddingBottom: 2,
  },
})