import React from 'react'
import { ModelOfType } from '~/models'
import { CopyResult } from '~/stores'
import { observer } from '~/ui/component'
import { Center, Chip, Dimple, HBox, Label, List, Panel, TextBlock, VBox } from '~/ui/components'
import SVG from '~/ui/components/SVG'
import { FormError, FormErrors, useForm } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import CopyToProjectFormModel from './CopyToProjectFormModel'

export interface Props {}

const CopyToProjectResultList = observer('CopyToProjectResultList', (props: Props) => {

  const {model: formModel} = useForm<CopyToProjectFormModel>()
  const results = formModel.results ?? []

  const {t} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.CopyToProjectResultList} gap={layout.padding.m}>
        <TextBlock dim>
          {t('actions.copy_to_project.results.preamble')}
        </TextBlock>

        <List
          classNames={$.resultList}
          contentPadding={6}
          data={results}
          keyExtractor={keyForItem}
          renderItem={renderItem}
          itemGap={layout.padding.inline.s}
          scrollable={true}
          flex={false}
          pageSize={20}
        />
      </VBox>
    )
  }

  const keyForItem = React.useCallback(
    (result: CopyResult) => result.meta.originalID,
    [],
  )

  const renderItem = React.useCallback((result: CopyResult) => {
    return (
      <CopyToProjectResultItem
        result={result}
      />
    )
  }, [])

  return render()

})

export default CopyToProjectResultList

interface CopyToProjectResultItemProps {
  result: CopyResult
}

const CopyToProjectResultItem = observer('CopyToProjectResultItem', (props: CopyToProjectResultItemProps) => {

  const {result} = props

  const {
    data: linkage,
    meta: {caption, issues},
  } = result

  const theme = useTheme()
  const {t}   = useResourceTranslation()

  const Model      = ModelOfType(linkage.type)

  const valid = issues.length === 0
  const errors = React.useMemo(() => {
    return issues.map((issue): FormError => ({
      field:   issue.path,
      code:    issue.code,
      message: issue.message,
    }))

  }, [issues])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.CopyToProjectResultItem}>
        {renderMain()}
        {!valid && <Dimple horizontal/>}
        {!valid && renderIssueList()}
      </Panel>
    )
  }

  function renderMain() {
    return (
      <HBox classNames={$.resultItemMain}>
        {renderNameCell()}
        {renderResult()}
      </HBox>
    )
  }

  function renderNameCell() {
    return (
      <HBox flex gap={layout.padding.inline.m}>
        <SVG
          name={Model.$icon}
          size={layout.icon.m}
          color={theme.semantic.primary}
        />
        <Label flex>
          {caption}
        </Label>
      </HBox>
    )
  }

  function renderResult() {
    return (
      <Center>
        <Chip
          icon={valid ? 'check' : 'cross'}
          backgroundColor={valid ? theme.semantic.positive : theme.semantic.negative}
          children={t(`actions.copy_to_project.results.${valid ? 'ok' : 'invalid'}`)}
          small
        />
      </Center>
    )
  }

  function renderIssueList() {
    return (
      <VBox classNames={$.resultItemIssueList}>
        <FormErrors
          errors={errors}
          showFieldCaptions
        />
      </VBox>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  CopyToProjectResultList: {
  },

  resultList: {
    maxHeight: 360,
    margin:    -6,
  },

  CopyToProjectResultItem: {

  },

  resultItemMain: {
    minHeight: layout.barHeight.m,
    padding: [
      layout.padding.inline.m,
      layout.padding.inline.m,
    ],
  },

  resultItemIssueList: {
    padding: [
      layout.padding.inline.m,
      layout.padding.inline.m,
    ],
  },
})