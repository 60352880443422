import React from 'react'
import { useTimer } from 'react-timer'
import { OnDemandServiceStatus } from 'socket.io-react'
import * as UUID from 'uuid'
import { WidgetAlotment } from '~/models'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import { useContinuousRef } from '~/ui/hooks'
import { useAnalyticsService } from './AnalyticsServiceContext'

interface DashboardContext {
  status:         OnDemandServiceStatus
  editing:        boolean
  currentWidgets: WidgetAlotment[]

  startEditing:  () => any
  stopEditing:   () => any
  saveDashboard: () => Promise<SubmitResult | undefined>

  addWidget:    (name: string, rect: Rect) => WidgetAlotment | null
  updateWidget: (uuid: string, update: (alotment: WidgetAlotment) => WidgetAlotment) => void
  removeWidget: (uuid: string, immediately?: boolean) => void

  requestReport:      (uuid: string) => any
  requestCloseReport: () => any
  reportAlotmentUUID: string | null
}

const DashboardContext = React.createContext<DashboardContext>({
  status:         'idle',
  editing:        false,
  currentWidgets: [],

  startEditing:  () => void 0,
  stopEditing:   () => void 0,
  saveDashboard: () => Promise.resolve(void 0),

  addWidget:     () => null,
  updateWidget:  () => void 0,
  removeWidget:  () => void 0,

  requestReport:      () => void 0,
  requestCloseReport: () => void 0,
  reportAlotmentUUID: null,
})

export interface DashboardContextContainerProps {
  children?:  React.ReactNode
}

export const DashboardContextContainer = observer('DashboardContextContainer', (props: DashboardContextContainerProps) => {

  const {children} = props

  const {service} = useAnalyticsService()
  const dashboard = service?.dashboard

  const me       = authenticationStore.user
  const mayEdit  = me != null && (me.isAdmin() || dashboard?.user === me.id)
  const status   = service?.status ?? 'idle'

  const dashboardRef = useContinuousRef(dashboard)

  const timer = useTimer()

  //------
  // Editing & save

  const [editingWidgets, setEditingWidgets] = React.useState<WidgetAlotment[] | null>(null)
  const editingWidgetsRef = useContinuousRef(editingWidgets)
  const editing = mayEdit && editingWidgets != null

  const startEditing = React.useCallback(() => {
    const dashboard = dashboardRef.current
    if (dashboard == null || !mayEdit) { return }

    setEditingWidgets(dashboard.widgets ?? [])
  }, [dashboardRef, mayEdit])

  const stopEditing = React.useCallback(() => {
    setEditingWidgets(null)
  }, [])

  React.useEffect(() => {
    if (dashboard?.widgets?.length === 0) {
      startEditing()
    }
  }, [dashboard, startEditing])

  const saveDashboard = React.useCallback(async (): Promise<SubmitResult | undefined> => {
    const dashboard = dashboardRef.current
    if (dashboard == null || !mayEdit) { return }

    const editingWidgets = editingWidgetsRef.current
    if (editingWidgets == null) { return }

    const retval = await timer.await(service?.updateDashboard(() => ({
      widgets: editingWidgets,
    })))
    if (retval?.status === 'ok') {
      stopEditing()
    }
  }, [dashboardRef, editingWidgetsRef, mayEdit, service, stopEditing, timer])

  //------
  // Widget operations

  const addWidget = React.useCallback((widget: string, rect: Rect) => {
    const editingWidgets = editingWidgetsRef.current
    if (editingWidgets == null) { return null }

    const uuid = UUID.v4()
    const alotment: WidgetAlotment = {uuid, widget, ...rect, filters: {}}

    setEditingWidgets([...editingWidgets, alotment])
    return alotment
  }, [editingWidgetsRef])

  const removeWidget = React.useCallback((uuid: string, immediately: boolean = false) => {
    if (immediately) {
      service?.updateDashboard(dashboard => ({
        widgets: dashboard.serialized.widgets.filter((it: any) => it.uuid !== uuid),
      }))
    } else {
      const editingWidgets = editingWidgetsRef.current
      if (editingWidgets == null) { return }
      setEditingWidgets(editingWidgets.filter(it => it.uuid !== uuid))
    }
  }, [editingWidgetsRef, service])

  const updateWidget = React.useCallback((uuid: string, update: (alotment: WidgetAlotment) => WidgetAlotment) => {
    const editingWidgets = editingWidgetsRef.current
    if (editingWidgets == null) { return }

    setEditingWidgets(editingWidgets.map(alotment => {
      if (alotment.uuid === uuid) {
        return update(alotment)
      } else {
        return alotment
      }
    }))
  }, [editingWidgetsRef])

  //------
  // Report

  const [reportAlotmentUUID, setReportAlotmentUUID] = React.useState<string | null>(null)

  const context = React.useMemo((): DashboardContext => ({
    editing,
    status,
    currentWidgets: editingWidgets ?? dashboard?.widgets ?? [],

    startEditing,
    stopEditing,
    saveDashboard,

    addWidget,
    updateWidget,
    removeWidget,

    requestReport: setReportAlotmentUUID,
    requestCloseReport: () => { setReportAlotmentUUID(null) },
    reportAlotmentUUID,
  }), [addWidget, dashboard?.widgets, editing, editingWidgets, removeWidget, reportAlotmentUUID, saveDashboard, startEditing, status, stopEditing, updateWidget])

  return (
    <DashboardContext.Provider value={context}>
      {children}
    </DashboardContext.Provider>
  )
})

export function useDashboardContext() {
  return React.useContext(DashboardContext)
}