import React from 'react'
import { useTranslation } from 'react-i18next'
import { ConditionOperator, Feedback } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, SelectField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {
  question: Feedback
  partial:  boolean
}

const TextTestFields = memo('TextTestFields', (props: Props) => {

  const {partial} = props

  const [t] = useTranslation('conditions')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderPreamble()}
        {renderOperandField()}
      </VBox>
    )
  }

  function renderPreamble() {
    return (
      <HBox gap={layout.padding.inline.s} align='baseline'>
        <Label small dim>
          {t('tests.match.preamble.pre')}
        </Label>
        <FormField name={partial ? 'partial_match_operator' : 'exact_match_operator'} caption={false}>
          {bind => <SelectField {...bind} choices={operatorChoices} small inputStyle='dark'/>}
        </FormField>
        <Label small dim>
          {t('tests.match.preamble.post')}
        </Label>
      </HBox>
    )
  }

  function renderOperandField() {
    return (
      <FormField name='operand' caption={false}>
        {bind => <TextField {...bind}/>}
      </FormField>
    )
  }

  const operatorChoices = React.useMemo((): Choice<ConditionOperator>[] => {
    if (partial) {
      return [
        {value: 'contains', caption: t('tests.match.preamble.operators.contains')},
        {value: '!contains', caption: t('tests.match.preamble.operators.!contains')},
      ]
    } else {
      return [
        {value: 'is', caption: t('tests.match.preamble.operators.is')},
        {value: '!is', caption: t('tests.match.preamble.operators.!is')},
      ]
    }
  }, [partial, t])

  return render()

})

export default TextTestFields