import React from 'react'
import { Chip, HBox, Label, Tooltip, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { colors, createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  tags:          string[]
  collapseFrom?: number
}

const TagsCell = (props: Props) => {

  const {tags, collapseFrom = 3} = props

  const {t} = useResourceTranslation()

  const theme = useTheme()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.inline.s}>
        {tags.slice(0, collapseFrom - 1).map(tag => (
          <Chip
            key={tag}
            icon='tag'
            children={tag}
            dim
            small
          />
        ))}
        {tags.length > collapseFrom - 1 && (
          <Tooltip renderTooltip={renderMoreTooltip()}>
            <Chip small backgroundColor={theme.fg.dimmer} color={theme.fg.dim} classNames={$.moreChip}>
              {t('resource_cell.more', {count: tags.length - collapseFrom + 1})}
            </Chip>
          </Tooltip>
        )}
      </HBox>
    )
  }

  function renderMoreTooltip() {
    return (
      <VBox gap={layout.padding.s}>
        <Label small bold align='center'>
          {t('tags_cell.tooltip')}
        </Label>
        <HBox wrap gap={layout.padding.inline.s} justify='center'>
          {tags.map(tag => (
            <Chip
              key={tag}
              icon='tag'
              children={tag}
              dim
              small
            />
          ))}
        </HBox>
      </VBox>
    )
  }

  return render()

}

const useStyles = createUseStyles(theme => ({
  moreChip: {
    '&:hover': {
      ...colors.overrideBackground(theme.semantic.secondary),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.secondary)),
    },
  },

}))

export default React.memo(TagsCell) as typeof TagsCell