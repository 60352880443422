import React from 'react'
import { useTranslation } from 'react-i18next'
import { Module, TriggerableModelClass } from '~/models'
import { ConditionsForm } from '~/ui/app/conditions'
import { TargetingForm } from '~/ui/app/targeting'
import { observer } from '~/ui/component'
import { useFormOpen } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import CalendarItemConditionsFormModel from './CalendarItemConditionsFormModel'
import CalendarItemTargetingFormModel from './CalendarItemTargetingFormModel'
import { useCalendarPlanner } from './CalendarPlannerContext'
import CalendarItemForm from './content/CalendarItemForm'
import CalendarTriggerableItemFormModel from './content/CalendarTriggerableItemFormModel'
import ModuleItemFormModel from './content/ModuleItemFormModel'

const CalendarItemDetailForm = observer('CalendarItemDetailForm', () => {

  const {planner, plan} = useCalendarPlanner()

  const [t] = useTranslation('calendar_planner')

  //------
  // Form models

  const detail   = planner.itemDetail
  const itemUUID = planner.editingItemUUID

  const item      = itemUUID == null ? null : plan?.findItem(itemUUID)
  const modelName = item?.type === 'triggerable' && item.triggerable.type !== 'action' ? item.triggerable.type : null
  const Model     = modelName == null ? null : TriggerableModelClass(modelName)
  const modelID   = item?.type === 'triggerable' && item.triggerable.type !== 'action' ? item.triggerable.model : null

  const [model]   = useModelDocumentData(Model, modelID)
  const [module]  = useModelDocumentData(Module, plan?.module ?? null)

  const timeFormModel = React.useMemo(() => {
    if (detail !== 'time') { return null }
    if (planner == null) { return null }
    if (plan == null || item == null || module == null) { return null }

    const day = plan.days.find(it => it.items.includes(item))
    if (day == null) { return null }

    const {uuid, time} = item
    if (item.type === 'module') {
      return module == null ? null : new ModuleItemFormModel(planner, day.uuid, uuid, item.module, time)
    } else {
      return model == null ? null : new CalendarTriggerableItemFormModel(planner, day.uuid, uuid, item.triggerable, item.triggerable.type, time, module, model)
    }
  }, [detail, item, model, module, plan, planner])

  const targetingFormModel = React.useMemo(() => {
    if (detail !== 'targeting') { return null }

    const item = itemUUID == null ? null : plan?.findItem(itemUUID)
    if (planner == null || plan == null || item == null) { return null }

    return new CalendarItemTargetingFormModel(planner, plan, item)
  }, [detail, itemUUID, plan, planner])

  const conditionsFormModel = React.useMemo(() => {
    if (detail !== 'conditions') { return null }

    const item = itemUUID == null ? null : plan?.findItem(itemUUID)
    if (planner == null || plan == null || item == null) { return null }

    return new CalendarItemConditionsFormModel(planner, item)
  }, [detail, itemUUID, plan, planner])

  React.useEffect(() => {
    if (plan == null) { return }
    conditionsFormModel?.deriveContextFromCalendarPlan(plan)
  }, [conditionsFormModel, plan])

  const formModel = timeFormModel ?? targetingFormModel ?? conditionsFormModel

  const [open, currentFormModel, close] = useFormOpen(formModel, () => {
    planner.stopEditingItem()
  })

  //------
  // Rendering

  function render() {
    if (currentFormModel instanceof ModuleItemFormModel || currentFormModel instanceof CalendarTriggerableItemFormModel) {
      return (
        <CalendarItemForm
          open={open}
          requestClose={close}
          model={currentFormModel}
        />
      )
    } else if (currentFormModel instanceof CalendarItemTargetingFormModel) {
      return (
        <TargetingForm
          open={open}
          requestClose={close}
          model={currentFormModel}
          instructions={t('targeting.instructions')}
        />
      )
    } else if (currentFormModel instanceof CalendarItemConditionsFormModel) {
      return (
        <ConditionsForm
          open={open}
          requestClose={close}
          model={currentFormModel}
          instructions={t('conditions.instructions')}
        />
      )
    } else {
      return null
    }
  }

  return render()

})

export default CalendarItemDetailForm