import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { memo } from '~/ui/component'
import { Center, ClearButton, ErrorDisplay, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props {
  detail?: string
}

const PageNotFoundScreen = memo('PageNotFoundScreen', (props: Props) => {

  const history = useHistory()
  const [t] = useTranslation('errors')

  const goBack = React.useCallback(() => {
    history.go(-1)
  }, [history])

  function render() {
    return (
      <Center flex>
        <VBox gap={layout.padding.m} align='left'>
          <ErrorDisplay
            status={404}
            title={t('page_not_found.title')}
            detail={props.detail ?? t('page_not_found.detail')}
          >
            <ClearButton
              icon='arrow-left'
              caption={t('page_not_found.go_back')}
              onTap={goBack}
            />
          </ErrorDisplay>
        </VBox>
      </Center>
    )
  }

  return render()

})

export default PageNotFoundScreen