import React from 'react'
import { FeedbackForm, FeedbackFormModel } from '~/ui/app/feedback'
import { memo } from '~/ui/component'
import { SwitchField } from '~/ui/components'
import { FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import ChallengeQuestionFormModel from './ChallengeQuestionFormModel'

export interface Props extends Omit<FormDialogProps<FeedbackFormModel>, 'model' | 'children'> {
  formModel: ChallengeQuestionFormModel
}

const ChallengeQuestionForm = memo('ChallengeQuestionForm', (props: Props) => {

  const {formModel, ...rest} = props

  //------
  // Rendering

  function render() {
    return (
      <FeedbackForm
        {...rest}
        model={formModel}
        renderExtraSideFields={renderExtraSideFields}
        excludeTypes={['group', 'button', 'rating']}
      />
    )
  }

  const renderExtraSideFields = React.useCallback(() => {
    return (
      <ResourceTypeProvider resourceType='script-messages'>
        <FormField name='skippable' caption={false}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </ResourceTypeProvider>
    )
  }, [])

  return render()

})

export default ChallengeQuestionForm