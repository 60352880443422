import React from 'react'
import Timer from 'react-timer'
import { merge, omit, pick } from 'lodash'
import Semaphore from 'semaphore'
import { layout } from '~/ui/styling'
import { obtain, recycle } from './pool'
import { LatLong, MapFeature, Viewport } from './types'
import {
  applyLatLongBoundsPadding,
  centerCoordinate,
  convertFromLatLng,
  convertFromLatLngBounds,
  convertPixelsToMeters,
  convertToLatLng,
  roundCoordinate,
  viewportEquals,
  zoomForBounds,
} from './util'

export default class GoogleMapManager {

  constructor(
    private readonly elementRef: React.RefObject<HTMLElement>,
    private readonly onStateUpdate: (state: GoogleMapState) => any,
    options: GoogleMapManagerOptions,
    private readonly callbacks: GoogleMapManagerCallbacks = {},
  ) {
    this.options         = merge({}, DEFAULT_OPTIONS, options)
    this.initialViewport = this.calculateInitialViewport()
    this.viewport        = this.initialViewport ?? {}
  }

  private readonly options: GoogleMapManagerOptions
  public readonly ready = new Semaphore()

  private readonly state: GoogleMapState = GoogleMapState.default()

  private initialBoundsChanged = false

  public getElement() {
    return this.elementRef.current
  }

  private setState(state: Partial<GoogleMapState>) {
    Object.assign(this.state, state)
    this.onStateUpdate(this.state)
  }

  //------
  // Map lifecycle

  private _googleMap?: google.maps.Map
  public get googleMap(): google.maps.Map {
    if (this._googleMap == null) {
      throw new Error("Google Map not initialized yet")
    }

    return this._googleMap
  }

  public createMap() {
    const element = this.elementRef.current
    if (element == null) {
      throw new Error("Google Map not connected to a element")
    }

    const {transparent}  = this.options
    const {center, zoom} = this.centerAndZoomForViewport(this.initialViewport ?? {})

    const options: google.maps.MapOptions = {
      center:  center == null ? undefined : convertToLatLng(center),
      zoom:    zoom ?? DEFAULT_ZOOM,

      clickableIcons:    false,
      mapTypeControl:    false,
      streetViewControl: false,

      ...omit(this.options, 'center', 'zoom'),
    }

    if (transparent) {
      options.backgroundColor = 'rgba(0, 0, 0, 0)'
    }

    const map = obtain(element, options)
    if (map == null) { return }

    this._googleMap = map

    // Add an empty overlay view for coordinate conversion.
    this.overlay = new google.maps.OverlayView()
    this.overlay.draw = function () {}
    this.overlay.setMap(map)

    this.addListener('click', this.onClick)
    this.addListener('dblclick', this.onDoubleClick)
    this.addListener('projection_changed', this.checkReady.bind(this))
    this.addListener('bounds_changed', this.checkReady.bind(this))
    this.addListener('bounds_changed', this.onBoundsChange)
    this.addListener('zoom_changed', this.onZoomChange)

    this.calculateZoom()
    this.checkReady()
    this.fitFeatures()

    return this.destroyMap.bind(this)
  }

  private destroyMap() {
    if (this._googleMap == null) { return }
    recycle(this.googleMap)
  }

  public checkReady() {
    if (this.state.ready) { return }

    if (this.isReady()) {
      this.setState({ready: true})
      this.ready.signal()
      this.callbacks.onReady?.()

      if (this.options.initialSearch != null) {
        this.search(this.options.initialSearch)
      }
    }
  }

  private isReady() {
    if (this.googleMap.getProjection() == null) { return false }
    if (this.initialViewport != null) {
      if (this.googleMap.getBounds() == null) { return false }
      if (!this.initialBoundsChanged) { return false }
    }

    return true
  }

  //------
  // Viewport

  private readonly initialViewport: Viewport | null
  private viewport: Viewport
  private overlay: google.maps.OverlayView | null = null

  private calculateInitialViewport(): Viewport | null {
    const {
      initialBounds,
      initialCenter,
      initialZoom,
      bounds,
      center,
      zoom,
    } = this.options

    if (initialBounds != null) {
      return {bounds: initialBounds}
    } else if (initialCenter != null) {
      return {center: initialCenter, zoom: initialZoom}
    }

    if (bounds) { return {bounds} }
    if (center) { return {center, zoom} }

    return null
  }

  public resetToInitialViewport(options: SetViewportOptions = {}) {
    this.setViewport(this.initialViewport ?? {}, options)
  }

  public setViewport(viewport: Viewport, options: SetViewportOptions = {}) {
    viewport = pick(viewport, 'bounds', 'center', 'zoom')

    // If a zoom is not explicitly specified, re-use the current zoom.
    if (viewport.center != null && viewport.zoom == null) {
      viewport.zoom = this.googleMap.getZoom()
    }
    if (viewport.center != null) {
      viewport.center = roundCoordinate(viewport.center)
    }

    if (viewportEquals(this.viewport, viewport)) { return }
    this.viewport = viewport

    const {center, zoom} = this.centerAndZoomForViewport(viewport)
    if (center == null || zoom == null) { return }

    this.setCenterAndZoom(center, zoom, options)
  }

  private updateViewport(viewport: Viewport) {
    if (this.viewport.bounds != null) {
      this.viewport = {bounds: viewport.bounds}
    } else {
      this.viewport = pick(viewport, 'center', 'zoom')
    }
  }

  public fitFeatures(options: SetViewportOptions = {}) {
    const {
      spanFeatures = true,
      padding      = 0,
      maximumInitialZoom,
    } = this.options

    if (!spanFeatures || this.features.size === 0) { return }

    let latLngBounds = new google.maps.LatLngBounds()
    for (const feature of this.features) {
      const featureBounds = this.getFeatureBounds(feature)
      if (featureBounds != null) {
        latLngBounds = latLngBounds.union(featureBounds)
      }
    }

    let bounds = convertFromLatLngBounds(latLngBounds)

    const element = this.elementRef.current
    if (padding != null && element != null) {
      const center = convertFromLatLng(latLngBounds.getCenter())
      const {width, height} = element.getBoundingClientRect()
      const zoom            = zoomForBounds(bounds, {width, height})
      const paddingInMeters = convertPixelsToMeters(padding, center, zoom)

      bounds = applyLatLongBoundsPadding(bounds, paddingInMeters)
    }

    this.fitBounds(bounds, options)

    // Use a maximum zoom level.
    const zoom = this.googleMap.getZoom()
    if (maximumInitialZoom != null && zoom > maximumInitialZoom) {
      this.googleMap.setZoom(maximumInitialZoom)
    }
  }

  public getFeatureBounds(feature: MapFeature) {
    if (feature instanceof google.maps.Marker) {
      return new google.maps.LatLngBounds(feature.getPosition() ?? undefined, feature.getPosition() ?? undefined)
    }
    if (feature instanceof google.maps.Circle) {
      return feature.getBounds()
    }
    return null
  }

  public fitBounds(bounds: [LatLong, LatLong], options: SetViewportOptions = {}) {
    const {center, zoom} = this.centerAndZoomForViewport({bounds})
    if (center == null || zoom == null) { return }

    this.setCenterAndZoom(center, zoom, options)
  }

  private centerAndZoomForViewport(viewport: Viewport) {
    const {bounds, center, zoom} = viewport
    if (bounds == null && (center == null || zoom == null)) { return {} }

    const element = this.elementRef.current
    if (element == null) { return {} }

    if (bounds != null) {
      const {width, height} = element.getBoundingClientRect()
      const center = centerCoordinate(bounds)
      const zoom   = zoomForBounds(bounds, {width, height})
      return {center, zoom}
    } else {
      return {center, zoom}
    }
  }

  public setCenterAndZoom(center: LatLong, zoom: number, options: SetViewportOptions = {}) {
    center = roundCoordinate(center)

    this.callbacks.onCenterChange?.(center)

    if (options.animated) {
      this.googleMap.panTo(convertToLatLng(center))
    } else {
      this.googleMap.setCenter(convertToLatLng(center))
    }
    if (zoom !== this.googleMap.getZoom()) {
      this.googleMap.setZoom(zoom)
      this.calculateZoom()
    }
  }

  public calculateZoom() {
    this.callbacks.onCalculateZoom?.(this.googleMap.getZoom())
  }

  public roundCoordinate(coordinate: LatLong) {
    const {precision = DEFAULT_PRECISION} = this.options
    return roundCoordinate(coordinate, precision)
  }

  //------
  // Layout

  public coordinateToClientPoint(coordinate: LatLong) {
    const projection = this.overlay?.getProjection()
    if (projection == null) { return {x: 0, y: 0} }

    const latLng = new google.maps.LatLng(coordinate.latitude, coordinate.longitude)
    const point  = projection.fromLatLngToContainerPixel(latLng)
    return {x: point.x, y: point.y}
  }

  //------
  // Search / geocode

  private lastSearch: string | null = null

  public search(search: string | null) {
    this.callbacks.onSearch?.(search)
    if (search == null) {
      this.setState({searchState: 'idle'})
      return
    }

    this.setState({searchState: 'searching'})
    this.lastSearch = search

    const geocoder = new google.maps.Geocoder()
    geocoder.geocode({address: search}, (results, status) => {
      if (this.lastSearch !== search) { return }

      if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
        this.setState({searchState: 'notfound'})
      }

      if (status === google.maps.GeocoderStatus.OK) {
        const {location} = results[0].geometry
        const coordinate = convertFromLatLng(location)

        this.setViewport({center: coordinate, zoom: 15}, {animated: true})
      }

      this.setState({searchState: 'idle'})
    })
  }

  //------
  // Feature lifecycles

  private features: Set<MapFeature> = new Set()
  private initialFeatureQueueCount: number = 0
  private initialFeaturesAdded: boolean = false

  public queueFeature() {
    if (this.initialFeaturesAdded) { return }
    this.initialFeatureQueueCount += 1
  }

  public addFeature(feature: MapFeature) {
    feature.setMap(this.googleMap)
    this.features.add(feature)

    if (!this.initialFeaturesAdded) {
      this.initialFeatureQueueCount -= 1
      if (this.initialFeatureQueueCount === 0) {
        this.initialFeaturesAdded = true
        this.fitFeatures()
      }
    }

    return this.removeFeature.bind(this, feature)
  }

  private removeFeature(feature: MapFeature) {
    feature.setMap(null)
    this.features.delete(feature)
  }

  //------
  // Event listeners

  private centerOnClickTimer = new Timer()

  private onZoomChange = () => {
    this.calculateZoom()
  }

  private onBoundsChange = () => {
    const bounds = this.googleMap.getBounds()
    if (bounds == null) { return }

    const center = this.googleMap.getCenter()
    const zoom   = this.googleMap.getZoom()

    const convertedCenter = roundCoordinate(convertFromLatLng(center))
    const convertedBounds: [LatLong, LatLong] = [
      convertFromLatLng(bounds.getSouthWest()),
      convertFromLatLng(bounds.getNorthEast()),
    ]

    this.updateViewport({
      bounds: convertedBounds,
      center: convertedCenter,
      zoom:   zoom,
    })

    if (this.initialBoundsChanged) {
      if (this.callbacks.onCenterChange) {
        this.callbacks.onCenterChange(convertedCenter)
      }
      if (this.callbacks.onBoundsChange) {
        this.callbacks.onBoundsChange(convertedBounds)
      }
    }

    this.initialBoundsChanged = true
    this.checkReady()
  }

  private onClick = (event: google.maps.MapMouseEvent) => {
    const coordinate = convertFromLatLng(event.latLng)
    this.callbacks.onClick?.(coordinate, event)

    if (this.options.centerOnClick) {
      this.centerOnClickTimer.throttle(() => {
        this.setViewport({center: coordinate}, {animated: true})
      }, 200)
    }

    event.domEvent.stopImmediatePropagation()
    event.domEvent.preventDefault()
  }

  private onDoubleClick = () => {
    this.centerOnClickTimer.clearAll()
  }

  //------
  // Listeners

  public addListener<N extends keyof google.maps.MapHandlerMap<google.maps.Map>>(
    eventName: N,
    listener:  google.maps.MapHandlerMap<google.maps.Map>[N],
  ) {
    return this.googleMap.addListener(eventName as any, listener as any)
  }

}

export const DEFAULT_PRECISION = 5
export const DEFAULT_ZOOM = 3
export const DEFAULT_OPTIONS: GoogleMapManagerOptions = {
  options: {
    fullscreenControl: false,
  },
  padding: layout.padding.inline.l,
}

export interface GoogleMapManagerOptions {
  options?: google.maps.MapOptions

  initialBounds?: [LatLong, LatLong]
  initialCenter?: LatLong
  initialZoom?:   number

  maximumInitialZoom?: number
  spanFeatures?:       boolean

  bounds?:       [LatLong, LatLong]
  center?:       LatLong
  zoom?:         number
  precision?:    number
  padding?:      number

  initialSearch?: string
  transparent?:   boolean
  centerOnClick?: boolean
}

export interface GoogleMapManagerCallbacks {
  onReady?:          () => any
  onClick?:          (coordinate: LatLong, event: google.maps.MapMouseEvent) => any
  onCenterChange?:   (coordinate: LatLong) => any
  onReverseGeocode?: (result: google.maps.GeocoderResult | null) => any
  onBoundsChange?:   (bounds: [LatLong, LatLong]) => any
  onCalculateZoom?:  (zoom: number) => any
  onSearch?:         (search: string | null) => any
}

export interface GoogleMapState {
  ready:       boolean
  searchState: GoogleMapSearchState
}

export const GoogleMapState: {
  default: () => GoogleMapState
} = {
  default: () => ({
    ready:       false,
    searchState: 'idle',
  }),
}

export type GoogleMapSearchState = 'idle' | 'searching' | 'notfound'

export interface SetViewportOptions {
  animated?: boolean
}