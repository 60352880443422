import React from 'react'
import { LocalizedString, Page, PageType } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  ConfirmBox,
  Dimple,
  EmptyOrFetching,
  HBox,
  KebabMenu,
  Label,
  Panel,
  PanelHeader,
  PopupMenu,
  PopupMenuItem,
  PushButton,
  Spinner,
  SVG,
  VBox,
} from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useBoolean, useFormOpen } from '~/ui/hooks'
import { useModelDocumentData, useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceAppLink } from '~/ui/resources/components'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import renderPageBody from './bodies'
import PageForm from './create/PageForm'
import { usePagesContext } from './PagesContext'
import PageTargetingForm, { PageTargetingFormModel } from './PageTargetingForm'

export interface Props {
  id?:      string | null
  slug?:    string
  filters?: Record<string, any>
  root:     boolean
}

const PagePanel = observer('PagePanel', (props: Props) => {

  const {id, slug, filters = {}, root} = props

  const context = usePagesContext()
  const {popSlug, removePage} = context

  const projectID = projectStore.projectID

  const [pages, endpoint] = useModelEndpointData(Page, {
    filters: {slug, ...filters},
    include: ['children'],
    label:   'linked',
    fetch:   slug != null,

    fetchOnCreate: false,
    fetchOnRemove: false,
  })

  const [pageFromID, document] = useModelDocumentData(Page, id ?? null, {
    label: 'linked',
  })

  const page = pageFromID ?? pages.find(it => it.project === projectID) ?? pages[0]
  const fetchStatus = slug != null ? endpoint.fetchStatus : document.fetchStatus

  const interactive = page?.project === projectID

  const {t, actionCaption, actionConfirm} = useResourceTranslation('pages')
  const [formOpen, openForm, closeForm] = useBoolean()
  const [createType, setCreateType] = React.useState<PageType>(page?.type ?? 'content')

  const [targetingFormModel, setTargetingFormModel] = React.useState<PageTargetingFormModel | null>(null)
  const [targetingFormOpen, currentTargetingFormModel, closeTargetingForm] = useFormOpen(targetingFormModel, () => {
    setTargetingFormModel(null)
  })

  const {colors} = useStyling()

  //------
  // Actions

  const close = React.useCallback(() => {
    popSlug(page?.slug)
  }, [page, popSlug])

  const edit = React.useCallback(() => {
    if (page == null) { return }

    setCreateType(page.type)
    openForm()
  }, [openForm, page])

  const editTargeting = React.useCallback(() => {
    if (page == null) { return }
    setTargetingFormModel(new PageTargetingFormModel(page, context))
  }, [context, page])

  const create = React.useCallback((type: PageType) => {
    setCreateType(type)
    openForm()
  }, [openForm])

  const remove = React.useCallback(async () => {
    if (page == null) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove'),
      destructive: true,
    })
    if (!confirmed) { return }

    await removePage(page.id)
    popSlug(page.slug)
  }, [actionConfirm, page, popSlug, removePage])

  const fetchOnCreate = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      endpoint.fetch()
    }
  }, [endpoint])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []
    if (!interactive) { return items }

    items.push({
      icon:     'pencil',
      caption:  actionCaption('edit'),
      onSelect: edit,
    })

    if (page != null && !root) {
      items.push({
        icon:     'target',
        caption:  actionCaption('edit_targeting'),
        onSelect: editTargeting,
      })
      items.push({section: '-'})
      items.push({
        icon:     'trash',
        caption:  actionCaption('remove'),
        color:    colors.semantic.negative,
        onSelect: remove,
      })
    }

    return items
  }, [interactive, actionCaption, colors.semantic.negative, edit, editTargeting, page, root, remove])

  const createMenuItems = React.useMemo((): PopupMenuItem[] => [
    {value: 'content', icon: 'content-page', ...t('types.content')},
    {section: '-'},
    {value: 'menu', icon: 'menu-page', ...t('types.menu')},
    {value: 'index', icon: 'index-page', ...t('types.index')},
  ], [t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel flex semi classNames={$.pagePanel} header={renderHeader()} depth={2}>
        {page == null ? (
          renderEmpty()
        ) : (
          renderPageBody(page, interactive)
        )}
        <PageForm
          open={formOpen}
          requestClose={closeForm}
          pageID={page?.id}
          root={root}
          type={createType}
          defaults={page ?? {slug}}
          afterSubmit={fetchOnCreate}
        />
        {currentTargetingFormModel != null && (
          <PageTargetingForm
            open={targetingFormOpen}
            requestClose={closeTargetingForm}
            formModel={currentTargetingFormModel}
          />
        )}
      </Panel>
    )
  }

  function renderHeader() {
    if (page == null) { return null }
    return (
      <PanelHeader
        icon={page?.$icon}
        caption={renderCaption()}
        right={renderActions()}
      />
    )
  }

  function renderCaption() {
    if (page == null) {
      return fetchStatus === 'fetching' ? <Spinner size={12}/> : null
    }

    return (
      <VBox>
        <HBox gap={layout.padding.inline.m}>
          <Label h2 flex='shrink'>
            {LocalizedString.translate(page.title)}
          </Label>
          {page.locked && <SVG name='lock' size={layout.icon.xs}/>}
        </HBox>
        <ResourceAppLink model={page}/>
      </VBox>
    )
  }

  function renderActions() {
    return (
      <HBox>
        {kebabMenuItems.length > 0 && (
          <KebabMenu
            items={kebabMenuItems}
          />
        )}
        {!root && <Dimple vertical/>}
        {!root && (
          <ClearButton
            icon='cross'
            onTap={close}
            padding='both'
          />
        )}
      </HBox>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        title={t(`${root ? 'no-root' : 'not_found'}.title`, {slug})}
        detail={t(`${root ? 'no-root' : 'not_found'}.detail`, {slug})}
        status={fetchStatus}
        children={renderCreateButton()}
        flex
        markup
      />
    )
  }

  function renderCreateButton() {
    return (
      <PopupMenu items={createMenuItems} onValueSelect={create} crossAlign='center'>
        {toggle => (
          <PushButton
            icon='plus'
            caption={t('not-found.create')}
            onTap={toggle}
          />
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default PagePanel

export const pagePanelWidth = 390

const useStyles = createUseStyles({
  pagePanel: {
    width: pagePanelWidth,
  },
})