import React from 'react'
import { isFunction } from 'lodash'
import { observer } from '~/ui/component'
import { flexStyle, SVG, VBox, VBoxProps } from '~/ui/components'
import { textAlignForAlign } from '~/ui/components/label'
import { FieldTransform, invokeFieldChangeCallback, useFieldChangeCallback } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { useAssistantFormDialog } from './assistant-form'
import { INVALID_FIELD_MARKER_CLASS } from './focus'
import FormErrors from './FormErrors'
import FormFieldHeader from './FormFieldHeader'
import { useFormField } from './hooks/useFormField'
import { FieldChangeCallback, FormError } from './types'

export interface Props<T> {
  name:         string
  caption?:     string | null | false
  instruction?: string | null | false
  placeholder?: string | null | false
  label?:       string | {on: string, off: string} | null | false

  defaultValue?: T | (() => T)
  required?:     boolean

  i18nKey?:     string

  showErrors?: boolean
  transform?:  FieldTransform<T, any>

  renderAsLabel?: boolean
  headerRight?:   React.ReactNode

  align?:   VBoxProps['align']
  flex?:    VBoxProps['flex']
  children: (bind: BindProps<T>) => React.ReactNode
}

export interface BindProps<T> {
  value:        T
  onChange:     FieldChangeCallback<T>
  placeholder?: string
  label?:       string | {on: string, off: string}
  invalid:      boolean
  enabled?:     boolean
  errors:       FormError[]
  childErrors?: FormError[]
}

const _FormField = <T extends any = any>(props: Props<T>) => {

  const {
    name,
    i18nKey = name,
    defaultValue,
    required,
    align,
    flex,
    showErrors = true,
    transform,
    renderAsLabel = true,
    children,
  } = props

  const [fieldValue, onChange, errors, form] = useFormField<T>(name)
  const value       = fieldValue ?? (isFunction(defaultValue) ? defaultValue() : defaultValue)!
  const childErrors = form.errorsFor(name as never, true)

  const {fieldPrompt, fieldPlaceholder, fieldInstruction, fieldLabel} = useResourceTranslation()

  const resolve = <T extends any>(value: T | false, defaultValue: () => T): T | null => {
    if (value === false) { return null }
    if (value === null) { return null }
    return value ?? defaultValue()
  }

  const caption     = resolve(props.caption, () => fieldPrompt(i18nKey))
  const instruction = resolve(props.instruction, () => fieldInstruction(i18nKey))
  const placeholder = resolve(props.placeholder, () => fieldPlaceholder(i18nKey)) ?? undefined
  const label       = resolve(props.label, () => fieldLabel(i18nKey)) ?? undefined
  const invalid     = errors.length > 0
  const enabled     = form.submitting ? false : undefined

  const hasCaption     = caption != null
  const hasInstruction = instruction != null

  const transformedValue = React.useMemo(() => {
    return transform?.toValue(value) ?? value
  }, [transform, value])

  const handleChangeWithTransform = useFieldChangeCallback(React.useCallback((value: T, partial?: boolean) => {
    if (transform == null) {
      return invokeFieldChangeCallback(onChange, value, partial)
    } else {
      return invokeFieldChangeCallback(onChange, transform.fromValue(value), partial)
    }
  }, [onChange, transform]))

  const bind = React.useMemo((): BindProps<T> => ({
    value:    transformedValue,
    onChange: handleChangeWithTransform,

    placeholder,
    label,
    invalid,
    enabled,
    errors,
    childErrors,
  }), [childErrors, enabled, errors, handleChangeWithTransform, invalid, label, placeholder, transformedValue])

  //------
  // Connection with AssistantFormDialog

  const fieldRef = React.useRef<HTMLDivElement>(null)
  const theme    = useTheme()

  const {
    getStepContaining,
    requestStep,
    setStepAccessory,
  } = useAssistantFormDialog()

  const prevInvalid = usePrevious(invalid)

  React.useEffect(() => {
    const field = fieldRef.current
    if (field == null) { return }
    if (prevInvalid || !invalid) { return }

    const step = getStepContaining(field)
    if (step == null) { return }

    setStepAccessory(step, (
      <SVG
        name='warning-full'
        size={layout.icon.xs}
        color={theme.semantic.negative}
      />
    ))
    requestStep(step)
  }, [getStepContaining, invalid, prevInvalid, requestStep, setStepAccessory, theme.semantic.negative, theme.semantic.warning])

  //------
  // Rendering

  const $ = useStyles()

  const classNames = [
    $.FormField,
    {invalid},
    invalid && INVALID_FIELD_MARKER_CLASS,
  ]

  function render() {
    const el = (
      <VBox flex={flex} gap={layout.padding.inline.s} ref={fieldRef}>
        {renderHeader()}
        <VBox flex={flex} gap={layout.padding.inline.s}>
          {children(bind)}
          <VBox align={align}>
            {renderErrors()}
          </VBox>
        </VBox>
      </VBox>
    )

    if (renderAsLabel) {
      return (
        <label classNames={[classNames, $.label]} style={flexStyle(flex)}>
          {el}
        </label>
      )
    } else {
      return el
    }
  }

  function renderHeader() {
    if (!hasCaption && !hasInstruction) { return null }

    return (
      <FormFieldHeader
        name={name}
        i18nKey={i18nKey}
        caption={props.caption}
        instruction={props.instruction}
        right={props.headerRight}
        required={required}
        textAlign={textAlignForAlign(align)}
      />
    )
  }

  function renderErrors() {
    if (!showErrors) { return null }
    if (errors.length === 0) { return null }

    return (
      <FormErrors errors={errors}/>
    )
  }

  return render()

}

const FormField = observer('FormField', _FormField) as typeof _FormField
export default FormField

const useStyles = createUseStyles({
  FormField: {},

  label: {
    ...layout.flex.column,
  },
})