import I18next from 'i18next'
import { Model, ModelClass, resource } from './Model'

@resource<NotFound>('', {
  icon:    () => 'error-full',
  caption: () => I18next.t('not_found:caption'),
  details: nf => [I18next.t('not_found:detail', {type: I18next.t(`${nf.ModelClass.resourceType}:singular`)})],
})
export class NotFound extends Model {

  constructor(
    public readonly ModelClass: ModelClass<any>,
    id: string,
  ) {
    super({id})
  }

}