import React from 'react'
import { useTimer } from 'react-timer'
import { upperFirst } from 'lodash'
import { Extension, Project, ProjectExtension } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import {
  ClearButton,
  ConfirmBox,
  Dimple,
  Empty,
  GridList,
  HBox,
  Label,
  PushButton,
  Switch,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import AddExtensionForm from './AddExtensionForm'
import ExtensionConfigurationForm from './ExtensionConfigurationForm'
import ExtensionTile from './ExtensionTile'

const ExtensionScreen = observer('ExtensionScreen', () => {

  const {t, plural, actionConfirm} = useResourceTranslation('extensions')

  const {project} = projectStore
  const endpoint = useModelEndpoint(Extension)

  const breadcrumbs = React.useMemo((): Breadcrumb[] => [{
    icon:    'hexagons',
    caption: upperFirst(plural()),
    href:    '/extensions',
  }], [plural])

  const [addFormOpen, openAddForm, closeAddForm] = useBoolean()
  const [configureFormOpen, openConfigureForm, closeConfigureForm] = useBoolean()
  const [configuringExtension, setConfiguringExtension] = React.useState<Extension | null>(null)

  const configForExtension = React.useMemo(() => {
    if (configuringExtension == null) { return null }

    const projectExtension = project?.extensions.find(it => it.name === configuringExtension.name)
    return projectExtension?.config ?? {}
  }, [configuringExtension, project?.extensions])

  const timer = useTimer()

  const addExtension = React.useCallback(async (extension: Extension) => {
    if (project == null) { return }

    const existing = project.extensions.find(it => it.name === extension.name)
    if (existing != null) { return }

    const result = await timer.await(dataStore.update(Project, project.id, {
      extensions: [
        ...project.extensions,
        {
          name:    extension.name,
          enabled: false,
          config:  {},
        },
      ],
    }))

    if (result?.status === 'ok') {
      closeAddForm()
    }
  }, [closeAddForm, project, timer])

  const configureExtension = React.useCallback((extension: Extension) => {
    setConfiguringExtension(extension)
    openConfigureForm()
  }, [openConfigureForm])

  const toggleEnabled = React.useCallback(async (extension: Extension, enabled: boolean) => {
    if (project == null) { return }

    const extensions = project.extensions.map(current => {
      if (current.name === extension.name) {
        return {...current, enabled}
      } else {
        return current
      }
    })

    await timer.await(dataStore.update(Project, project.id, {extensions}))
  }, [project, timer])

  const removeExtension = React.useCallback(async (extension: Extension) => {
    if (project == null) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove'),
      destructive: true,
    })
    if (!confirmed) { return }

    const extensions = project.extensions.filter(it => it.name !== extension.name)
    await timer.await(dataStore.update(Project, project.id, {extensions}))
  }, [actionConfirm, project, timer])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.container}>
        <AppLayoutConfig
          configKey='extensions'
          breadcrumbs={breadcrumbs}
          ActionsComponent={renderActions}
        />

        <GridList
          columns={4}
          data={project?.extensions ?? []}
          keyExtractor={keyExtractor}
          renderCell={renderExtension}
          EmptyComponent={renderEmpty}
        />

        <AddExtensionForm
          open={addFormOpen}
          requestClose={closeAddForm}
          requestAdd={addExtension}
        />

        {configuringExtension != null && configForExtension != null && (
          <ExtensionConfigurationForm
            open={configureFormOpen}
            requestClose={closeConfigureForm}
            extension={configuringExtension}
            config={configForExtension}
          />
        )}
      </VBox>
    )
  }

  const renderActions = React.useCallback(() => {
    return (
      <PushButton
        icon='plus'
        caption={t('add')}
        onTap={openAddForm}
        small
      />
    )
  }, [openAddForm, t])

  const keyExtractor = React.useCallback(
    (extension: ProjectExtension) => extension.name,
    [],
  )

  const renderExtension = React.useCallback((projectExtension: ProjectExtension) => {
    const extension = endpoint.data.find(it => it.name === projectExtension.name)
    if (extension == null) {
      return null
    }

    return (
      <ProjectExtensionTile
        extension={extension}
        valid={projectExtension.valid}
        enabled={projectExtension.enabled}
        onToggleEnabled={toggleEnabled}
        requestConfigure={configureExtension}
        requestRemove={removeExtension}
      />
    )
  }, [configureExtension, endpoint.data, removeExtension, toggleEnabled])

  const renderEmpty = React.useCallback(() => {
    return (
      <Empty {...t('empty')} icon='hexagons' flex>
        <PushButton
          icon='plus'
          caption={t('add')}
          onTap={openAddForm}
        />
      </Empty>
    )
  }, [openAddForm, t])

  return render()

})

export default ExtensionScreen

interface ProjectExtensionTileProps {
  extension:      Extension
  valid:            boolean
  enabled:          boolean
  onToggleEnabled:  (extension: Extension, enabled: boolean) => any
  requestConfigure: (extension: Extension) => any
  requestRemove:    (extension: Extension) => any
}

const ProjectExtensionTile = memo('ProjectExtensionTile', (props: ProjectExtensionTileProps) => {

  const {
    extension,
    valid,
    enabled,
    onToggleEnabled,
    requestConfigure,
    requestRemove,
  } = props

  const {t} = useResourceTranslation('extensions')

  const configure = React.useCallback(() => {
    requestConfigure(extension)
  }, [extension, requestConfigure])

  const remove = React.useCallback(() => {
    requestRemove(extension)
  }, [extension, requestRemove])

  const theme = useTheme()
  const enabledColor = enabled
    ? theme.semantic.positive
    : theme.fg.dimmer

  function render() {
    return (
      <ExtensionTile
        extension={extension}
        enabled={enabled}
        footer={renderFooter()}
      />
    )
  }

  function renderFooter() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderEnabled()}
        <Dimple vertical/>
        <HBox gap={layout.padding.inline.m}>
          {renderConfigureButton()}
          {renderRemoveButton()}
        </HBox>
      </HBox>
    )
  }

  function renderEnabled() {
    if (!valid) {
      return (
        <Label flex caption small align='center' color={theme.fg.error}>
          {t('invalid')}
        </Label>
      )
    } else {
      return (
        <HBox flex gap={layout.padding.inline.m} tag='label'>
          <Switch
            isOn={enabled ?? false}
            onChange={enabled => onToggleEnabled?.(extension, !!enabled)}
          />
          <Label flex caption small color={enabledColor}>
            {t(`enabled.${enabled ? 'on' : 'off'}`)}
          </Label>
        </HBox>
      )
    }
  }

  function renderConfigureButton() {
    return (
      <ClearButton
        icon='cog'
        onTap={configure}
      />
    )
  }

  function renderRemoveButton() {
    if (enabled) { return null }

    return (
      <ClearButton
        icon='trash'
        onTap={remove}
      />
    )
  }

  return render()

})

const useStyles = createUseStyles({
  container: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },
})