import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import {
  acceptedFeedbackMediaMimeTypes,
  FeedbackMediaType,
  Media,
  MessageMedia,
  SVGImage,
} from '~/models'
import MediaUploader, { MediaUploaderState } from '~/ui/app/media/MediaUploader'
import { memo } from '~/ui/component'
import { Center, Label, VBox } from '~/ui/components'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { useScriptEditor } from './ScriptEditorContext'

export interface Props {
  enabled?:     boolean
  mediaTypes?:  FeedbackMediaType[]
  uploaderRef?: React.Ref<MediaUploader>
  children?:    ((state: MediaUploaderState) => React.ReactNode)
}

const ScriptMediaUploader = memo('ScriptMediaUploader', (props: Props) => {

  const {
    enabled = true,
    mediaTypes = FeedbackMediaType.all,
    uploaderRef,
    children,
  } = props
  const {addMessages} = useScriptEditor()

  const [t] = useTranslation('script-messages')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <MediaUploader
        ref={uploaderRef}
        accept={acceptedFeedbackMediaMimeTypes(mediaTypes)}
        enabled={enabled}
        onUploadComplete={addMessage}
        renderContent={renderContent}
        onDropRejected={handleDropRejected}
        reportProgress
        noClick
        noKeyboard
      />
    )
  }

  function renderContent(state: MediaUploaderState) {
    if (children == null) { return null }

    return (
      <VBox flex>
        {children?.(state)}
        {state.isDragAccept ? (
          renderHint('accept')
        ) : state.isDragReject ? (
          renderHint('reject')
        ) : state.isDragActive ? (
          renderHint('active')
        ) : null}
      </VBox>
    )
  }

  function renderHint(type: string) {
    return (
      <Center classNames={[$.dropHint, type]} flex gap={layout.padding.inline.m}>
        <Label h2>
          {t(`media_dropzone.drop_hint.${type}.title`)}
        </Label>
        <Label dim>
          {t(`media_dropzone.drop_hint.${type}.detail`)}
        </Label>
      </Center>
    )
  }

  //------
  // Drop

  const handleDropRejected = React.useCallback(() => {
      Toast.show({
      ...t('media_dropzone.drop_hint.reject'),
      type: 'error',
    })
  }, [t])

  //------
  // Add

  const addMessage = React.useCallback((media: Media | SVGImage | null) => {
    if (!(media instanceof Media)) { return }

    const isVideo = /^video\/*/.test(media.contentType)
    const isImage = /^image\/*/.test(media.contentType)

    const messageMedia: MessageMedia = {
      media:       media.id,
      name:        media.name,
      contentType: media.contentType,
      url:         media.url,
    }

    if (isVideo) {
      addMessages([{
        type:  'video',
        video: messageMedia,
      }])
    } else if (isImage) {
      addMessages([{
        type:  'image',
        image: messageMedia,
      }])
    }
  }, [addMessages])

  return render()

})

export default ScriptMediaUploader

const useStyles = createUseStyles(theme => ({
  dropHint: {
    ...layout.overlay,
    zIndex:     1,
    background: colors.white.alpha(0.2),

    '&.active': {
      background: theme.semantic.focus.alpha(0.6),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.focus)),
    },

    '&.accept': {
      background: theme.semantic.positive.alpha(0.6),
      ...colors.overrideForeground(theme.colors.fg.light.normal),
    },
    '&.reject': {
      background: theme.semantic.negative.alpha(0.6),
      ...colors.overrideForeground(theme.colors.fg.light.normal),
    },
  },
}))