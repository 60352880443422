import React from 'react'
import { Beacon } from '~/models'
import { memo } from '~/ui/component'
import { HBox, NumberField, TextBlock, TextField } from '~/ui/components'
import { CollectionFieldItemFormDialogProps } from '~/ui/components/fields/collection/CollectionField'
import { VBox } from '~/ui/components/layout'
import { FormDialog, FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export type Props = CollectionFieldItemFormDialogProps<Beacon>

const BeaconForm = memo('BeaconForm', (props: Props) => {

  const {t, fieldCaption} = useResourceTranslation('locations')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        {...props}
        icon='beacon'
        title={fieldCaption('beacons')}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <TextBlock small>
          {t('beacon.instructions')}
        </TextBlock>

        <FormField name='name' i18nKey='beacon_name'>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='proximityUUID' required>
          {bind => <TextField {...bind} mono/>}
        </FormField>
        <HBox gap={layout.padding.s}>
          <VBox flex>
            <FormField name='major'>
              {bind => <NumberField {...bind} mono/>}
            </FormField>
          </VBox>
          <VBox flex>
            <FormField name='minor'>
              {bind => <NumberField {...bind} mono/>}
            </FormField>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default BeaconForm