import React from 'react'
import { observer } from '~/ui/component'
import { SelectField } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, useForm } from '~/ui/form'
import RegistrationFormModel from './RegistrationFormModel'
import { useRegistrationWidgetModel } from './RegistrationWidgetContext'
import { RegistrationTranslationKey } from './types'

export interface Props {}

const ProjectSelector = observer('ProjectSelector', (props: Props) => {

  const model = useRegistrationWidgetModel()
  const {model: formModel} = useForm<RegistrationFormModel>()

  const projects = formModel.availableProjects
  const choices = React.useMemo((): Choice[] => {
    if (projects.status !== 'done') { return [] }
    return projects.data.map(project => ({
      value:   project.id,
      caption: project.name,
    }))
  }, [projects])

  //------
  // Rendering

  function render() {
    if (!formModel.showProjectField) { return null }

    return (
      <FormField
        name='project'
        caption={model.translate(RegistrationTranslationKey.projectCaption)}
        align='center'
        required
      >
        {bind => (
          <SelectField
            {...bind}
            placeholder={model.translate(RegistrationTranslationKey.projectPlaceholder)}
            choices={choices}
            inputStyle='dark'
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default ProjectSelector