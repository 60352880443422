import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import {
  Calendar,
  CalendarAnnotation,
  CalendarShortcut,
  ClearButton,
  HBox,
  ModalDialog,
  PushButton,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  initialDate?: DateTime | null
  utc?:         boolean

  annotations?:   CalendarAnnotation[]
  dateShortcuts?: CalendarShortcut[]

  onSelect:    (date: DateTime | null) => any
  autoCommit?: boolean
}

export default function DatePickerDialog(props: Props) {

  const {
    open,
    requestClose,
    utc         = false,
    initialDate = null,
    dateShortcuts,
    annotations,
    onSelect,
  } = props

  const [selectedDate, setSelectedDate] = React.useState<DateTime | null>(initialDate)

  const [t] = useTranslation('date_picker')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        onWillOpen={selectInitialDate}

        title={t('caption')}
        footer={renderButtons()}
        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox classNames={$.body}>
        <Calendar
          selectedDate={selectedDate}
          onSelect={selectDate}
          utc={utc}
          annotations={annotations}
          dateShortcuts={dateShortcuts}
        />
      </VBox>
    )
  }

  function renderButtons() {
    return (
      <HBox justify='right' gap={layout.padding.s}>
        <PushButton
          caption={t('buttons:select')}
          onTap={commit}
        />
        <ClearButton
          caption={t('buttons:cancel')}
          onTap={requestClose}
          dim
        />
      </HBox>
    )
  }

  const selectInitialDate = React.useCallback(() => {
    setSelectedDate(initialDate)
  }, [initialDate])

  const commit = React.useCallback(() => {
    const date = selectedDate
    return onSelect?.(date)
  }, [onSelect, selectedDate])

  const selectDate = React.useCallback((dateTime: DateTime | null) => {
    setSelectedDate(dateTime)
    onSelect?.(dateTime)
  }, [onSelect])

  return render()

}

const useStyles = createUseStyles(theme => ({
  body: {
    ...layout.responsive(size => ({
      padding: layout.padding.m[size],
    })),
  },
}))