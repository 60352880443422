import React from 'react'
import Color from 'color'
import { BrandedComponentSpec, ClientTab } from '~/models'
import { projectStore } from '~/stores'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, HBox, SVG, Tappable, VBox } from '~/ui/components'
import { AppTabBarBranding, createUseStyles } from '~/ui/styling'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const AppTabBarBrandingPreview = memo('AppTabBarBrandingPreview', (props: Props) => {

  const {componentName, variant, spec} = props
  const {guide} = useBranding()

  const [component, flags, size] = useComponentBranding<AppTabBarBranding>(componentName, variant, spec)

  const [activeIconIndex, setActiveIconIndex] = React.useState<number>(0)
  const project = projectStore.project
  const tabs    = project?.clientTabs ?? []

  const barHeight = component.resolveBarHeight(0, flags)
  const padding   = component.resolve('padding', flags)
  const margin    = component.resolve('margin', flags)
  const detached  = margin > 0
  const scale     = Math.min(1, size.height / barHeight)

  const iconSizeNum     = component.resolve('iconSize', flags)
  const iconColor       = guide.colors.resolve(component.resolve('iconColor', flags))
  const activeIconColor = guide.colors.resolve(component.resolve('activeIconColor', flags))

  const iconSize = React.useMemo(
    () => ({width: iconSizeNum, height: iconSizeNum}),
    [iconSizeNum],
  )

  //------
  // Rendering

  const $ = useStyles({iconColor, activeIconColor})

  function render() {
    if (component == null) { return null }

    return (
      <BrandedComponent
        classNames={[$.clientTabBarBrandingPreview, {detached}]}
        branding={component}
        guide={guide ?? undefined}
        variant={flags}
        style={{...size, height: barHeight, transform: `scale(${scale})`}}
        height={barHeight}
        children={renderContent()}
        flipShadow
      />
    )
  }

  function renderContent() {
    return (
      <HBox flex align='stretch' style={{padding}}>
        {tabs.map(renderTab)}
      </HBox>
    )
  }

  function renderTab(tab: ClientTab, index: number) {
    return (
      <VBox key={index} flex>
        <Tappable flex onTap={() => setActiveIconIndex(index)}>
          <Center flex>
            {renderIcon(tab, index)}
          </Center>
        </Tappable>
      </VBox>
    )
  }

  function renderIcon(tab: ClientTab, index: number) {
    const active = index === activeIconIndex
    if (tab.icon.type === 'custom') {
      return (
        <ImageView
          source={tab.icon.value}
          size={iconSize}
          classNames={[$.icon, {active, custom: true}]}
        />
      )
    } else {
      return (
        <SVG
          name={tab.icon.value}
          size={iconSize}
          classNames={[$.icon, {active}]}
          color={active ? activeIconColor : iconColor}
        />
      )
    }
  }

  return render()

})

registerComponentBrandingPreview('app.tabBar', AppTabBarBrandingPreview)
export default AppTabBarBrandingPreview

const useStyles = createUseStyles({
  clientTabBarBrandingPreview: {
    '&:not(.detached)': {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      borderBottomWidth: 0,
      borderLeftWidth:   0,
      borderRightWidth:  0,
    },
  },

  icon: ({iconColor, activeIconColor}: {iconColor: Color, activeIconColor: Color}) => ({
    '&.custom [fill]': {
      fill: iconColor,
    },
    '&.active': {
      '&.custom [fill]': {
        fill: activeIconColor,
      },
      filter: `drop-shadow(0 0 2px ${activeIconColor.alpha(0.4)})`,
    },
  }),
})