import React from 'react'
import { memo } from '~/ui/component'
import { Chip, HBox, Label, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { MapBuilder } from '../../../lib/ytil/src/MapBuilder'

export interface Props {
  conflicts: Reference[]
}

export interface Reference {
  path:     string
  referee:  {model: string, id: string}
  referent: {model: string, id: string}
  strategy: 'disallow' | 'delete' | 'cascade' | 'other'
}


const ReferentialIntegrityNotice = memo('ReferentialIntegrityNotice', (props: Props) => {

  const {
    conflicts,
  } = props

  const conflictsByModel = React.useMemo(() => (
    MapBuilder.groupBy(conflicts, conflict => conflict.referent.model)
  ), [conflicts])

  const {t} = useResourceTranslation()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.ReferentialIntegrityNotice} gap={layout.padding.s}>
        <Label caption small dim truncate={false} align='center'>
          {t('referential-integriy.notice')}
        </Label>
        <HBox justify='center' wrap gap={layout.padding.s}>
          {Array.from(conflictsByModel.entries()).map(renderConflictModel)}
        </HBox>
      </VBox>
    )
  }

  function renderConflictModel([model, references]: [string, Reference[]]) {
    return (
      <HBox key={model} gap={layout.padding.inline.m}>
        <Label caption small>
          {`${model}:`}
        </Label>
        <Chip>
          {references.length}
        </Chip>
      </HBox>
    )
  }

  return render()

})

export default ReferentialIntegrityNotice

const useStyles = createUseStyles({
  ReferentialIntegrityNotice: {},
})