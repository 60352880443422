import { initReactI18next } from 'react-i18next'
import I18next from 'i18next'
import languageDetector from 'i18next-browser-languagedetector'
import config from '~/config'
import { assignGlobal } from '~/global'
import * as locales from '~/locales'
import parseFormatSpec, { FormatFunction } from './i18n-formats'

I18next
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    whitelist:     Object.keys(locales),
    fallbackLng:   'en',
    resources:     locales,
    returnObjects: true,
    interpolation: {
      // Not necessary as React already escapes.
      escapeValue:     false,
      formatSeparator: '|',

      format: (value, format, lng) => {
        const formatSpecs = format?.split('|').map(it => it.trim()).filter(Boolean) ?? []
        const formats     = formatSpecs.map(parseFormatSpec).filter(Boolean) as FormatFunction[]

        for (const format of formats) {
          value = format(value, lng)
        }
        return value
      },
    },

    detection: {
      order:  ['querystring', 'cookie', 'navigator'],

      lookupQuerystring: 'lng',
      lookupCookie:      'i18next',
      lookupHeader:      'accept-language',

      caches:         ['cookie'],
      cookieDomain:   config.securityDomain,
      cookieSecure:   config.environment !== 'local',
      cookieSameSite: 'strict',
    },
  })

if (DEV) {
  assignGlobal({I18next})
}