import React from 'react'
import { Triggerable } from '~/models'
import ChallengeFields from '~/ui/app/challenges/detail/ChallengeFields'
import ScriptFields from '~/ui/app/scripts/detail/ScriptFields'

export default function renderTriggerableFields(type: Triggerable['type'], moduleID: string) {
  switch (type) {
  case 'script':
    return <ScriptFields moduleID={moduleID} moduleField={false}/>
  case 'challenge':
    return <ChallengeFields moduleField={false}/>
  default:
    return null
  }
}