import React from 'react'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { ClearButton, HBox, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'

interface Props {
  currentMonth: DateTime
  goToMonth:    (dateTime: DateTime) => any
  onCaptionTap: () => any
  enabled:      boolean
}

const MonthSelector = memo('MonthSelector', (props: Props) => {

  const {currentMonth, goToMonth, onCaptionTap, enabled} = props
  const caption = currentMonth.toFormat('LLLL, y')

  const goToPreviousMonth = React.useCallback(() => {
    goToMonth(currentMonth.plus({months: -1}))
  }, [currentMonth, goToMonth])

  const goToNextMonth = React.useCallback(() => {
    goToMonth(currentMonth.plus({months: 1}))
  }, [currentMonth, goToMonth])

  return (
    <HBox gap={layout.padding.inline.m}>
      <ClearButton
        icon='double-chevron-left'
        onTap={goToPreviousMonth}
        enabled={enabled}
        padding='both'
      />
      <VBox flex>
        <ClearButton
          caption={caption}
          onTap={onCaptionTap}
          enabled={enabled}
          padding='both'
          align='center'
        />
      </VBox>
      <ClearButton
        icon='double-chevron-right'
        onTap={goToNextMonth}
        enabled={enabled}
        padding='both'
      />
    </HBox>
  )

})

export default MonthSelector