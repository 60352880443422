import React from 'react'
import { ClientApp } from '~/models'
import { memo } from '~/ui/component'
import { Dimple, Label, TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import ClientAppFormModel from './ClientAppFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any
}

const NewClientAppForm = memo('NewClientAppForm', (props: Props) => {

  const {t} = useResourceTranslation('client-apps')

  const formModel = React.useMemo(
    () => new ClientAppFormModel(null),
    [],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<ClientApp>
        {...props}
        icon='iphone'
        title={t('new')}
        Model={ClientApp}
        formModel={formModel}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='id' required>
          {bind => <TextField mono {...bind} accessoryLeft={renderIDAccessory()}/>}
        </FormField>
        <Dimple horizontal/>
        <FormField name='bundleID' required>
          {bind => <TextField mono {...bind}/>}
        </FormField>
        <FormField name='applicationID' required>
          {bind => <TextField mono {...bind}/>}
        </FormField>
        <FormField name='urlScheme' required>
          {bind => <TextField mono {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderIDAccessory() {
    return (
      <Label small dim mono>
        app.groundcontrol.whitelabel.
      </Label>
    )
  }

  return render()

})

export default NewClientAppForm