import React from 'react'
import { Model } from '~/models'
import { ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching, List } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceBar } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  endpoint: ModelEndpoint<any>
}

const IndexPageItemList = observer('IndexPageItemList', (props: Props) => {

  const {endpoint} = props
  const {t} = useResourceTranslation('pages')
  const {singular, plural} = useResourceTranslation(endpoint.Model.resourceType)

  //------
  // Rendering

  function render() {
    return (
      <List<Model>
        data={endpoint.data}
        EmptyComponent={renderEmpty}
        renderItem={renderItem}
        itemGap={layout.padding.inline.s}
        flex={false}
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('index.empty', {singular: singular(), plural: plural()})}
        status={endpoint.fetchStatus}
        flex
      />
    )
  }, [endpoint.fetchStatus, plural, singular, t])

  const renderItem = React.useCallback((model: Model) => {
    return (
      <ResourceBar
        model={model}
      />
    )
  }, [])

  return render()

})

export default IndexPageItemList