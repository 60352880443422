import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { observer } from '~/ui/component'
import ActivateDialog, { ActivateOptions } from '../activate/ActivateDialog'
import { useCalendarPlanner } from './CalendarPlannerContext'

export interface Props {
  open:          boolean
  requestClose?: () => any

  dayUUID?: string
}

const ActivateCalendarDialog = observer('ActivateCalendarDialog', (props: Props) => {

  const {dayUUID, open, requestClose} = props

  const {planner} = useCalendarPlanner()
  const [t]       = useTranslation('calendar_planner')

  //------
  // Callbacks

  const activateCalendar = React.useCallback(async (participantIDs: Array<string | null>, options: ActivateOptions) => {
    return await planner.service.activateCalendar(participantIDs, {
      dayUUID,
      ...options,
    })
  }, [dayUUID, planner])

  const showNotice = React.useCallback((who: string) => {
    Toast.show({
      type: 'success',
      ...t('activate.success', {who}),
    })
  }, [t])


  //------
  // Rendering

  function render() {
    return (
      <ActivateDialog
        open={open}
        requestClose={requestClose}

        title={t('activate.title')}
        instruction={t('activate.instruction')}

        requestActivate={activateCalendar}
        onDidActivate={showNotice}
      />
    )
  }

  return render()

})

export default ActivateCalendarDialog