import React from 'react'
import { AnswersQuery } from '~/models'
import { GroupField, ParticipantField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField, SubmitResult } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: (result: SubmitResult) => any

  model: AnswersQuery
}

const AnswersQueryForm = memo('AnswersQueryForm', (props: Props) => {

  const {open, model, requestClose, afterSubmit} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={AnswersQuery}
        model={model}
        initialData={{}}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return(
      <VBox gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind} />}
        </FormField>
        <FormField name='segments'>
          {bind => <GroupField {...bind} multi />}
        </FormField>
        <FormField name='participant'>
          {bind => <ParticipantField {...bind} />}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default AnswersQueryForm