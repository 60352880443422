import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { isFunction } from 'lodash'
import { Model } from '~/models'
import { observer } from '~/ui/component'
import {
  Checkbox,
  HBox,
  Label,
  Panel,
  panelBorderRadius,
  Scroller,
  Tappable,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'
import ResourceCollectionScreen, {
  Props as ResourceCollectionScreenProps,
  ResourceCollectionScreenState,
} from './ResourceCollectionScreen'

export interface Props<M extends Model> extends ResourceCollectionScreenProps<M> {
  allowSelect?: boolean | ((model: M) => boolean)
  renderItem:   (model: M) => React.ReactNode
}

const _ResourceCarouselScreen = <M extends Model>(props: Props<M>) => {

  const {
    allowSelect = true,
    renderItem,
    children,
    ...rest
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceCollectionScreen {...rest}>
        {state => (
          <>
            {renderCarousel(state)}
          </>
        )}
      </ResourceCollectionScreen>
    )
  }

  function renderCarousel(state: ResourceCollectionScreenState<M>) {
    return (
      <Scroller horizontal contentClassNames={$.scrollerContent}>
        <HBox flex align='stretch' gap={layout.padding.m}>
          {state.data.map(item => (
            <CarouselItem
              key={item.id}
              item={item}
              allowSelect={isFunction(allowSelect) ? allowSelect(item) : allowSelect}
              state={state}
              renderContent={renderItem}
            />
          ))}
        </HBox>
      </Scroller>
    )
  }

  return render()

}

export interface CarouselItemProps<M extends Model> {
  item:          M
  state:         ResourceCollectionScreenState<M>
  allowSelect:   boolean
  renderContent: (model: M) => React.ReactNode
}

export const CarouselItem = <M extends Model>(props: CarouselItemProps<M>) => {

  const {item, state, allowSelect, renderContent} = props

  const selectionManager = useSelectionManager()
  const selected         = selectionManager?.isSelected(item.id) ?? false

  const toggle = React.useCallback(() => {
    selectionManager?.toggle(item.id)
  }, [item.id, selectionManager])

  const $ = useStyles()

  function render() {
    return (
      <Panel href={state.itemHref(item) ?? undefined} classNames={$.carouselItem}>
        {renderHeader()}
        <VBox flex>
          {renderContent(item)}
        </VBox>
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <ThemeProvider contrast='secondary'>
        <HBox classNames={$.carouselItemHeader} align='stretch' gap={layout.padding.inline.l}>
          {allowSelect && (
            <Tappable onTap={toggle} classNames={$.checkboxContainer}>
              <Checkbox
                checked={selected}
                onChange={toggle}
              />
            </Tappable>
          )}
          <HBox flex>
            <Label h3 flex>
              {item.$caption}
            </Label>
          </HBox>
        </HBox>
      </ThemeProvider>
    )
  }

  return render()

}

const ResourceCarouselScreen = observer('ResourceCarouselScreen', _ResourceCarouselScreen) as typeof _ResourceCarouselScreen
export default ResourceCarouselScreen

export const carouselItemMinWidth = 320

const useStyles = createUseStyles(theme => ({
  scrollerContent: {
    padding: 6,
  },

  carouselItem: {
    minWidth: carouselItemMinWidth,
  },

  carouselItemHeader: {
    height:  layout.barHeight.m,
    padding: [layout.padding.inline.s, layout.padding.inline.l],

    borderTopLeftRadius:  panelBorderRadius(theme),
    borderTopRightRadius: panelBorderRadius(theme),

    background: theme.semantic.secondary,
  },

  checkboxContainer: {
    margin:         [0, -layout.padding.inline.s],
    padding:        layout.padding.inline.s,
    justifyContent: 'center',
  },
}))