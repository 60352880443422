import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { notificationsStore, operatorStore } from '~/stores'
import { observer } from '~/ui/component'
import { Center, EmptyOrFetching, Spinner, VBox } from '~/ui/components'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import OperatorActions from './OperatorActions'
import OperatorCarousel from './OperatorCarousel'

const OperatorScreen = observer('OperatorScreen', () => {

  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({
      name: 'operator',
    })

    return breadcrumbs
  }, [])

  const {starting, startError} = operatorStore

  React.useEffect(
    () => operatorStore.startService(),
    [],
  )

  // Don't show any operator notifications while viewing this screen.
  React.useEffect(
    () => notificationsStore.suspend(['operator:alert']),
    [],
  )

  //------
  // Launch arguments

  const service = operatorStore.service

  const [participant, setParticipant] = useQueryParam('participant', StringParam)
  const [channel, setChannel]         = useQueryParam('channel', StringParam)

  React.useEffect(() => {
    if (service == null || participant == null) { return }
    if (service.participants.has(participant)) { return }

    service.addParticipant(participant)
    if (channel != null) {
      service.switchParticipantToChannel(participant, channel)
    }

    setParticipant(null)
    setChannel(null)
  }, [channel, participant, service, setChannel, setParticipant])

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <AppLayoutConfig
          configKey='operator'
          breadcrumbs={breadcrumbs}
          ActionsComponent={OperatorActions}
        />

        {starting ? (
          renderStarting()
        ) : startError != null ? (
          renderStartError()
        ) : (
          <OperatorCarousel/>
        )}
      </VBox>
    )
  }

  function renderStarting() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  function renderStartError() {
    if (startError == null) { return null }


    return (
      <EmptyOrFetching
        flex
        status={startError}
      />
    )
  }

  return render()

})

export default OperatorScreen