import React from 'react'
import { TimeIntervalUnit } from '~/models'
import { memo } from '~/ui/component'
import { ConditionalField, DateTimeField, TimeIntervalField, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import TimingFormModel from './TimingFormModel'

export interface Props extends Omit<FormDialogProps<TimingFormModel>, 'children'> {}

const TimingForm = memo('TimingForm', (props: Props) => {

  const {t} = useResourceTranslation('registration-presets')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        icon='clock'
        title={t('timing.form_title')}

        {...props}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <ResourceTypeProvider resourceType='registration-presets'>
        <VBox gap={layout.padding.m}>
          <ConditionalField switchName='validUntilEnabled' name='validUntil' layout='box'>
            {bind => <DateTimeField {...bind}/>}
          </ConditionalField>
          <ConditionalField switchName='durationEnabled' name='duration' layout='box'>
            {bind => <TimeIntervalField {...bind} defaultUnit={TimeIntervalUnit.WEEKS}/>}
          </ConditionalField>
        </VBox>
      </ResourceTypeProvider>
    )
  }

  return render()

})

export default TimingForm