import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '~/models'
import { memo } from '~/ui/component'
import { Panel, SwitchField, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import { ResourceFormModel } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  user: User
}

const UserNotificationsSettingsForm = memo('UserNotificationsSettingsForm', (props: Props) => {

  const {open, requestClose, user} = props

  const [t] = useTranslation('notifications')

  const formModel = React.useMemo(
    () => new ResourceFormModel(User, user),
    [user],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='users'>
        <FormDialog
          open={open}
          requestClose={requestClose}

          icon='satellite'
          title={t('settings.title')}
          children={renderForm()}
          headerRight='$close'
          closeOnClickOutside={true}

          model={formModel}

          width={640}
          semi={false}
        />
      </ResourceTypeProvider>
    )
  }

  function renderForm() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('settings.preamble')}
        </TextBlock>

        <Panel padding={layout.padding.s}>
          <FormField name='notificationEmails' caption={false}>
            {bind => <SwitchField {...bind}/>}
          </FormField>
        </Panel>
      </VBox>
    )
  }

  return render()

})

export default UserNotificationsSettingsForm