import React from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { some } from 'lodash'
import { ImportSession } from 'sheet-importer'
import { observer } from '~/ui/component'
import {
  ClearButton,
  ConfirmBox,
  HBox,
  InputBox,
  PushButton,
  SelectField,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormFieldHeader } from '~/ui/form'
import { useViewState } from '~/ui/hooks'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  session: ImportSession
}

const ImportProfileSelector = observer('ImportProfileSelector', (props: Props) => {

  const {session} = props
  const [t] = useTranslation('importer')

  const [profiles, setProfiles] = useViewState<ImportProfile[]>(`import.profiles.${session.model.type}`, [])
  const [currentProfileName, setCurrentProfileName] = useViewState<string | null>(`import.profile.${session.model.type}`, null)

  //------
  // Callbacks

  const createProfile = React.useCallback(async () => {
    const name = await InputBox.show({
      ...t('profiles.create.prompt'),
      validate: name => {
        const existing = profiles.find(p => p.name === name)
        if (existing != null) {
          return t('profiles.create.exists')
        } else {
          return true
        }
      },
    })
    if (name == null) { return }

    const profile = session.saveProfile()
    const nextProfiles = [...profiles, {name, profile}]
    nextProfiles.sort((a, b) => a.name.localeCompare(b.name))
    unstable_batchedUpdates(() => {
      setProfiles(nextProfiles)
      setCurrentProfileName(name)
    })
  }, [t, session, profiles, setProfiles, setCurrentProfileName])

  const loadProfile = React.useCallback((name: string) => {
    if (name === '$current') {
      return
    }

    if (name === '$create') {
      createProfile()
      return
    }

    const storedProfile = profiles.find(profile => profile.name === name)
    if (storedProfile == null) { return }

    session.loadProfile(storedProfile.profile)
    setCurrentProfileName(name)
  }, [createProfile, profiles, session, setCurrentProfileName])

  const loadCurrentProfile = React.useCallback(() => {
    if (currentProfileName == null) { return }
    loadProfile(currentProfileName)
  }, [currentProfileName, loadProfile])

  const saveCurrentProfile = React.useCallback(() => {
    if (currentProfileName == null) { return }

    const profile = session.saveProfile()
    const nextProfiles = profiles.map(p => p.name === currentProfileName ? {...p, profile} : p)
    setProfiles(nextProfiles)
  }, [currentProfileName, profiles, session, setProfiles])

  const removeCurrentProfile = React.useCallback(async () => {
    if (currentProfileName == null) { return }

    const confirmed = await ConfirmBox.show({
      ...t('profiles.remove.confirm', {name: currentProfileName}),
      destructive: true,
    })
    if (!confirmed) { return }

    const index = profiles.findIndex(p => p.name === currentProfileName)
    if (index < 0) { return }

    const nextProfiles = [...profiles]
    nextProfiles.splice(index, 1)
    const nextProfileName = profiles[Math.min(index, profiles.length - 1)].name

    unstable_batchedUpdates(() => {
      setProfiles(nextProfiles)
      setCurrentProfileName(nextProfileName)
    })
  }, [currentProfileName, profiles, setCurrentProfileName, setProfiles, t])

  //------
  // Effects

  // If the currently selected profile does not exist for some reason, select the first available one.
  React.useEffect(() => {
    if (!some(profiles, p => p.name === currentProfileName)) {
      setCurrentProfileName(profiles.length === 0 ? null : profiles[0].name)
    }
  }, [currentProfileName, profiles, setCurrentProfileName])

  // Load the current profile whenever necessary.
  React.useEffect(() => {
    loadCurrentProfile()
  }, [loadCurrentProfile])

  //------
  // Drop down choices

  const choices = React.useMemo(() => {
    const choices: Choice[] = []

    if (currentProfileName == null) {
      choices.push({
        value:   '$current',
        caption: t('profiles.$current'),
      })
    }

    for (const profile of profiles) {
      choices.push({
        value:   profile.name,
        caption: profile.name,
      })
    }

    choices.push({
      value:   '$create',
      caption: t('profiles.create.caption'),
    })

    return choices
  }, [currentProfileName, profiles, t])

  //------
  // Rendering

  const {colors} = useStyling()

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <VBox gap={layout.padding.inline.s}>
          <FormFieldHeader
            caption={t('profiles.caption')}
            instruction={t('profiles.instruction')}
          />

          <SelectField
            value={currentProfileName ?? '$current'}
            onChange={loadProfile}
            choices={choices}
          />
        </VBox>

        {currentProfileName != null && (
          <HBox>
            <VBox flex='shrink'>
              <PushButton
                caption={t('profiles.save.caption')}
                onTap={saveCurrentProfile}
                enabled={session.modified}
                small
              />
            </VBox>
            {session.modified && (
              <ClearButton
                icon='reload'
                onTap={loadCurrentProfile}
                padding='both'
                small
              />
            )}
            <ClearButton
              icon='trash'
              onTap={removeCurrentProfile}
              color={colors.semantic.negative}
              padding='both'
              small
            />
          </HBox>
        )}
      </VBox>
    )
  }

  return render()

})

export default ImportProfileSelector

interface ImportProfile {
  name:    string
  profile: AnyObject
}