import React from 'react'
import { Module } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'
import { ModelRenderProps } from '~/ui/resources/components/ResourceField'

export interface Props extends Omit<ResourceFieldProps<Module>, 'Model' | 'shortcutsCaption' | 'shortcutIDs'> {
  excludeMain?: boolean
}

const ModuleField = observer('ModuleField', (props: Props) => {

  const {excludeIDs, excludeMain, ...rest} = props

  const project = projectStore.project
  const excludeAllIDs = React.useMemo(() => {
    const ids: string[] = [...excludeIDs ?? []]
    if (project != null && excludeMain) {
      ids.push(project.id)
    }
    return ids
  }, [excludeIDs, excludeMain, project])

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...rest as any}
        Model={Module}
        renderPropsForModel={renderPropsForModel}
        excludeIDs={excludeAllIDs}
      />
    )
  }

  const renderPropsForModel = React.useCallback((module: Module): ModelRenderProps => {
    return {
      image: module.image ?? module.$icon,
    }
  }, [])

  return render()

})

export default ModuleField