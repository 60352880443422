import { FlowPlan, PlanSegue, ScriptTriggerable } from '~/models'
import { FlowPlanner } from '~/stores'
import { ConditionsFormModel, ConditionsFormModelContext } from '~/ui/app/conditions'
import { SubmitResult } from '~/ui/form'

export default class SegueConditionsFormModel extends ConditionsFormModel {

  constructor(
    private readonly planner: FlowPlanner,
    private readonly segue: PlanSegue,
  ) {
    super(segue.conditions)
  }

  public deriveContextFromFlowPlan(plan: FlowPlan) {
    this.setContext(deriveContext(plan, this.segue))
  }

  public async submit(): Promise<SubmitResult | undefined> {
    await this.planner.updateSegue(this.segue.uuid, segue => ({
      ...segue,
      conditions: this.conditions,
    }))

    return {status: 'ok'}
  }

}

function deriveContext(plan: FlowPlan, segue: PlanSegue): ConditionsFormModelContext {
  const context: ConditionsFormModelContext = {}

  const fromNode = plan.nodes.find(node => node.uuid === segue.from.node)
  if (fromNode?.type === 'triggerable') {
    context.recentScriptIDs = fromNode.triggerables
      .filter(triggerable => triggerable.type === 'script')
      .map(triggerable => (triggerable as ScriptTriggerable).model)
  }

  return context
}