import React from 'react'
import { BorderSpec } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, Label } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { ComponentBranding, createUseStyles, layout } from '~/ui/styling'
import { useBranding } from '../BrandingContext'

export interface Props {
  spec: BorderSpec
}

const BrandingBorderPreview = memo('BrandingBorderPreview', (props: Props) => {

  const {spec} = props
  const {guide} = useBranding()

  const {t} = useResourceTranslation()

  const componentBranding = React.useMemo(() => guide == null ? null : new ComponentBranding(guide, null, {
    background: 'none',
    border:     spec,
    shape:      {rounded: layout.radius.m},
    depth:      1,
  }), [spec, guide])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (componentBranding == null) { return null }

    return (
      <BrandedComponent
        classNames={$.brandedBorderPreview}
        guide={guide ?? undefined}
        branding={componentBranding}
        height={size.height}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <Center flex>
        <Label caption>
          {t('preview.placeholder')}
        </Label>
      </Center>
    )
  }

  return render()

})

export default BrandingBorderPreview

export const size = {
  width:  160,
  height: 120,
}

const useStyles = createUseStyles({
  brandedBorderPreview: {
    ...size,
  },
})