import React from 'react'
import { Location, Participant } from '~/models'
import { dataStore, ModelEndpoint } from '~/stores'
import ParticipantBar from '~/ui/app/participants/ParticipantBar'
import { memo, observer } from '~/ui/component'
import { ClearButton, List } from '~/ui/components'
import { isSuccessResult } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  location: Location
  endpoint: ModelEndpoint<Participant>
}

const LocationParticipantList = observer('LocationParticipantList', (props: Props) => {

  const {location, endpoint} = props

  const document = dataStore.document(Location, location.id)

  const removeParticipant = React.useCallback(async (participant: Participant) => {
    const result = await document.callBulkAction('participants:remove', {
      data: [{type: 'participants', id: participant.id}],
      meta: {},
    })
    if (isSuccessResult(result)) {
      endpoint.remove([participant.id])
    }
  }, [document, endpoint])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <List
        data={endpoint.data}
        keyExtractor={keyForParticipant}
        renderItem={renderParticipant}
        itemGap={layout.padding.inline.s}
        classNames={$.LocationParticipantList}
        contentClassNames={$.listContent}
        flex='both'
        scrollable
      />
    )
  }

  const keyForParticipant = React.useCallback(
    (participant: Participant) => participant.id,
    [],
  )

  const renderParticipant = React.useCallback((participant: Participant) => {
    return (
      <LocationParticipantBar
        participant={participant}
        requestRemove={removeParticipant}
      />
    )
  }, [removeParticipant])

  return render()

})

export default LocationParticipantList

interface LocationParticipantBarProps {
  participant:   Participant
  requestRemove: (participant: Participant) => any
}

const LocationParticipantBar = memo('LocationParticipantBar', (props: LocationParticipantBarProps) => {

  const {
    participant,
    requestRemove,
  } = props

  const remove = React.useCallback(() => {
    requestRemove(participant)
  }, [participant, requestRemove])

  function render() {
    return (
      <ParticipantBar
        participant={participant}
        accessory={renderRemoveButton()}
      />
    )
  }

  function renderRemoveButton() {
    return (
      <ClearButton
        icon='location-exit'
        onTap={remove}
      />
    )
  }

  return render()

})

const useStyles = createUseStyles({
  LocationParticipantList: {
    margin: -4,
  },

  listContent: {
    ...layout.responsive(size => ({
      padding: [4, layout.padding.s[size] + 4],
    })),
  },
})