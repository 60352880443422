import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  HBox,
  Label,
  PushButton,
  Shaker,
  TextField,
  VBox,
} from '~/ui/components'
import { Form, FormField, FormProps, useAutoFilledDetection } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'
import AuthForm from '../AuthForm'
import LoginFormModel from './LoginFormModel'

export interface Props extends FormProps<LoginFormModel> {
  otherLoginOptions?: React.ReactNode
  requestForgotPassword?:  (email: string) => any
}

const LoginForm = observer('LoginForm', (props: Props) => {

  const {
    model: formModel,
    otherLoginOptions,
    requestForgotPassword,
  } = props

  const emailFieldRef    = React.useRef<HTMLInputElement>(null)
  const passwordFieldRef = React.useRef<HTMLInputElement>(null)
  const autoFilled       = useAutoFilledDetection([emailFieldRef, passwordFieldRef])

  const maySubmit = autoFilled || formModel.maySubmit

  const [t] = useTranslation('auth')

  const onForgotPasswordTap = React.useCallback(() => {
    requestForgotPassword?.(formModel.email)
  }, [formModel.email, requestForgotPassword])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Form {...props}>
        {form => (
          <Shaker shake={form.invalid}>
            <AuthForm preamble={t('login.instruction')}>
              {renderFields()}
              {renderButtons(form.submitting)}
              {renderOtherLoginOptions()}
            </AuthForm>
          </Shaker>
        )}
      </Form>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.xs}>
        {renderEmailField()}
        {renderPasswordField()}
      </VBox>
    )
  }

  function renderEmailField() {
    return (
      <FormField align='center' name='email' caption={false}>
        {bind => (
          <TextField
            {...bind}
            ref={emailFieldRef}
            autoFocus
            placeholder={t('login.fields.email')}
            inputAttributes={{
              autoCapitalize: 'off',
              autoCorrect:    'off',
              autoComplete:   'username',
            }}
            align='center'
            inputStyle='dark'
          />
        )}
      </FormField>
    )
  }

  function renderPasswordField() {
    return (
      <FormField align='center' name='password' caption={false}>
        {bind => (
          <TextField
            type='password'
            {...bind}
            placeholder={t('login.fields.password')}
            inputAttributes={{
              autoCapitalize: 'off',
              autoCorrect:    'off',
              autoComplete:   'password',
            }}
            align='center'
            inputStyle='dark'
            ref={passwordFieldRef}
          />
        )}
      </FormField>
    )
  }

  function renderButtons(submitting: boolean) {
    return (
      <VBox gap={layout.padding.s}>
        <PushButton
          icon='login'
          caption={t('login.submit')}
          enabled={maySubmit}
          working={submitting}
          submit
        />
        {requestForgotPassword != null && (
          <Center>
            <ClearButton
              caption={t('login.forgot-password')}
              onTap={onForgotPasswordTap}
              align='center'
              padding='horizontal'
              dim
            />
          </Center>
        )}
      </VBox>
    )
  }

  function renderOtherLoginOptions() {
    if (otherLoginOptions == null) { return null }

    return (
      <>
        <HBox classNames={$.otherLoginOptionsHeader} justify='center'>
          <Label small bold dim>
            {t('login.other')}
          </Label>
        </HBox>
        <VBox>
          {otherLoginOptions}
        </VBox>
      </>
    )
  }

  return render()

})

export default LoginForm

const useStyles = createUseStyles(theme => ({
  otherLoginOptionsHeader: {
    '&::before, &::after': {
      content:    '""',
      width:      80,
      height:     1,
      background: theme.colors.fg.light.dimmer,
    },

    '&::before': {marginRight: layout.padding.inline.l},
    '&::after':  {marginLeft: layout.padding.inline.l},
  },
}))