// This class is here only to provide compatibility with the web repo.
// Needs to be sorted out at some point.

export class PreviewStore {

  public static branding = false

  public selectForBranding(path: string, variant: string | null) {
    // Noop
  }

}

const previewStore = new PreviewStore()
export default previewStore