import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { Model } from '~/models'
import { BulkSelector, ModelEndpoint } from '~/stores'
import { memo, observer } from '~/ui/component'
import { ClearButton, PushButton, VBox } from '~/ui/components'
import { useResourceListLocation } from '~/ui/resources/collection'
import { layout, useTheme } from '~/ui/styling'
import { useResourceType } from '../../ResourceTypeContext'
import { BulkAction } from '../types'
import { bulkSelectorForSelection } from '../util'
import { ResolvedBulkAction, useBulkActions } from './useBulkActions'
import { WellKnownActionFlags } from './wellknown'

export interface Props<M extends Model> extends WellKnownActionFlags {
  endpoint: ModelEndpoint<any>
  actions:  BulkAction<M>[]

  onActionComplete?: (action: BulkAction<M>, selector: BulkSelector) => any
}

const BulkActionsPanel = observer('BulkActionsPanel', (props: Props<any>) => {

  const {
    endpoint,
    actions: props_actions,
    onActionComplete,
  } = props

  const resourceType = useResourceType()

  const [actions, form] = useBulkActions(endpoint, props_actions, props, onActionComplete)

  const manager      = useSelectionManager()
  const {filters}    = useResourceListLocation()

  const deriveBulkSelector = React.useCallback(() => {
    if (resourceType == null || manager == null) { return null }
    return bulkSelectorForSelection(resourceType, manager, filters)
  }, [filters, manager, resourceType])

  const executeAction = React.useCallback((action: ResolvedBulkAction) => {
    const selector = deriveBulkSelector()
    if (selector == null) { return }

    return action.execute(selector)
  }, [deriveBulkSelector])

  //------
  // Rendering

  function render() {
    return (
      <VBox align='left' gap={layout.padding.s}>
        {actions.map(renderBulkActionButton)}
        {form}
      </VBox>
    )
  }

  //------
  // Remove action

  function renderBulkActionButton(action: ResolvedBulkAction) {
    return (
      <BulkActionButton
        key={action.name}
        action={action}
        requestExecute={executeAction}
      />
    )
  }

  return render()

})

export default BulkActionsPanel

interface BulkActionButtonProps {
  action:         ResolvedBulkAction
  requestExecute: (action: ResolvedBulkAction) => any
}

export const BulkActionButton = memo('BulkActionButton', (props: BulkActionButtonProps) => {

  const {action, requestExecute} = props
  const style = action.style ?? 'push'

  const execute = React.useCallback(() => {
    requestExecute(action)
  }, [action, requestExecute])

  const theme = useTheme()

  function render() {
    if (style === 'push') {
      return (
        <PushButton
          icon={action.icon}
          caption={action.caption}
          onTap={execute.bind(null, action)}
        />
      )
    } else {
      return (
        <ClearButton
          icon={action.icon}
          caption={action.caption}
          color={style === 'destructive' ? theme.semantic.negative : undefined}
          onTap={execute.bind(null, action)}
        />
      )
    }
  }

  return render()

})