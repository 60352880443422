import React from 'react'
import { RegistrationPreset } from '~/models'
import { memo } from '~/ui/component'
import { Center, HBox, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import TimingForm from '../forms/TimingForm'
import TimingFormModel from '../forms/TimingFormModel'

export interface Props {
  preset: RegistrationPreset
}

const RegistrationPresetTiming = memo('RegistrationPresetTiming', (props: Props) => {

  const {t} = useResourceTranslation()

  const {preset} = props

  const [formOpen, openForm, closeForm] = useBoolean()

  const formModel = React.useMemo(
    () => new TimingFormModel(RegistrationPreset, preset),
    [preset],
  )

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon='clock'
        summary={t(`timing.caption`)}
        body={renderBody()}
        requestEdit={openForm}
        bold
      >
        {renderForm()}
      </SummaryInfo.Item>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderRow('calendar', 'valid_until', preset.validUntil)}
        {renderRow('stopwatch', 'duration', preset.duration)}
      </VBox>
    )
  }

  function renderRow(icon: SVGName, key: string, value: any | null) {
    return (
      <HBox flex='shrink' gap={layout.padding.inline.m} align='top'>
        <Center style={{paddingTop: ' 0.2em'}}>
          <SVG
            name={icon}
            size={layout.icon.xs}
            dim={value == null}
          />
        </Center>
        <Label small dim={value == null} flex='shrink' truncate={false} markup>
          {t(`timing.${key}.${value == null ? 'unspecified' : 'specified'}`, {value})}
        </Label>
      </HBox>
    )
  }

  function renderForm() {
    return (
      <TimingForm
        open={formOpen}
        requestClose={closeForm}
        model={formModel}
      />
    )
  }

  return render()

})

export default RegistrationPresetTiming