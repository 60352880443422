import React from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { upperFirst } from 'lodash'
import { ClientApp, Organisation, Project, Ref } from '~/models'
import { authenticationStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, Label, Spinner, SVG, TabPanel, VBox } from '~/ui/components'
import { Tab } from '~/ui/components/TabPanel'
import { InlineForm, InlineFormField } from '~/ui/form'
import { useModelDocumentData } from '~/ui/hooks/data'
import { Breadcrumb } from '~/ui/layouts/app/types'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { InlineResourceForm } from '~/ui/resources/form'
import { ResourceDetailParams, resourceDetailPath, resourceListPath } from '~/ui/resources/routes'
import { layout } from '~/ui/styling'
import ProjectForm from '../ProjectForm'
import FeaturesSettings from '../settings/FeaturesSettings'
import LinkedProjectsSettings from '../settings/LinkedProjectsSettings'
import MiscellaneousSettings from '../settings/MiscellaneousSettings'
import ProjectSettingsFormModel from '../settings/ProjectSettingsFormModel'
import WebSettings from '../settings/WebSettings'
import ProjectActions from './ProjectActions'
import ProjectHeader from './ProjectHeader'

export type Props = RouteComponentProps<ResourceDetailParams>

const ProjectScreen = observer('ProjectScreen', (props: Props) => {

  const {t} = useResourceTranslation('projects')

  const user      = authenticationStore.user
  const projectID = props.match.params.id ?? projectStore.projectID

  const organisationI18n = useResourceTranslation('organisations')
  const [project]        = useModelDocumentData(Project, projectID, {fetch: 'never'})

  const organisationID   = user?.isSuper() ? project?.organisation : null
  const [organisation]   = useModelDocumentData(Organisation, organisationID ?? null)
  const [app, {fetchStatus: appFetchStatus}] = useModelDocumentData(ClientApp, project?.app ?? null)

  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    if (user?.isSuper() && organisation != null) {
      breadcrumbs.push({
        icon:    Organisation.$icon,
        caption: upperFirst(organisationI18n.plural()),
        href:    resourceListPath(Organisation.resourceType),
      })

      breadcrumbs.push({
        caption: organisation.name,
        href:    resourceDetailPath(Organisation.resourceType, organisation.id),
      })
    }

    if (project != null) {
      breadcrumbs.push({
        icon:    Project.$icon,
        caption: project.name,
        href:    resourceDetailPath(Project.resourceType, project.id),
      })
    }

    return breadcrumbs
  }, [organisation, organisationI18n, project, user])

  //------
  // Tabs

  const history = useHistory()

  const {params} = props.match

  const switchTab = React.useCallback((tab: string) => {
    const path = params.id != null
      ? resourceDetailPath('projects', params.id, tab)
      : `/project/${tab}`

    history.replace(path)
  }, [history, params.id])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={Project}
        id={projectID}
        include={['organisation', 'app']}
        breadcrumbs={breadcrumbs}

        EditFormComponent={ProjectForm}
        renderInfo={renderInfo}
        renderBody={renderBody}
        renderSummaryFooter={renderAppField}
        renderActions={project => <ProjectActions project={project}/>}
        removeConfirmText={project => project.name}
      />
    )
  }

  //------
  // Body

  const settingsFormModel = React.useMemo(
    () => project == null ? null : new ProjectSettingsFormModel(project),
    [project],
  )

  const tabs = React.useMemo((): Tab<any>[] => [{
    name:    'linked-projects',
    caption: t('tabs.linked-projects'),
    render:  LinkedProjectsSettings,
  }, {
    name:    'features',
    caption: t('tabs.features'),
    render:  FeaturesSettings,
  }, {
    name:    'web',
    caption: t('tabs.web'),
    render:  WebSettings,
  }, {
    name:    'miscellaneous',
    caption: t('tabs.miscellaneous'),
    render:  MiscellaneousSettings,
  }], [t])

  function renderBody(project: Project) {
    if (settingsFormModel == null) { return null }

    return (
      <InlineForm flex model={settingsFormModel} autoSubmit>
        <VBox flex gap={layout.padding.m}>
          <ProjectHeader
            project={project}
          />
          <TabPanel
            tabs={tabs}
            currentTab={params.body ?? 'features'}
            requestTab={switchTab}
            flex={true}
          />
        </VBox>
      </InlineForm>
    )
  }

  //------
  // Right bar

  function renderInfo(project: Project) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: project.createdAt})}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  function renderAppField() {
    if (!user?.isSuper()) { return null }

    return (
      <InlineResourceForm Model={Project} model={project} autoSubmit>
        <InlineFormField<Ref<ClientApp> | null>
          name='app'
          renderView={renderApp}
          renderEdit={bind => <ResourceField Model={ClientApp} {...bind}/>}
          clearOnEdit
        />
      </InlineResourceForm>
    )
  }

  function renderApp() {
    if (appFetchStatus === 'fetching') {
      return <Spinner size={8}/>
    } else if (app == null) {
      return null
    }

    return (
      <HBox flex gap={layout.padding.inline.s}>
        <SVG name='iphone' size={layout.icon.s}/>
        <Label flex mono box>
          {app.$caption}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default ProjectScreen