import React from 'react'
import { Label, LabelProps, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'

export interface Props extends LabelProps {
}

const IDLabel = (props: Props) => {

  const {
    mono = true,
    classNames,
    ...rest
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.idLabel, classNames]}>
        <Label
          {...rest}
          mono={mono}
        />
      </VBox>
    )
  }

  return render()

}

export default IDLabel

const useStyles = createUseStyles(theme => ({
  idLabel: {
    borderRadius: layout.radius.xs,
    boxShadow:    shadows.depth(0.5),
    lineHeight:   1,

    padding:     [layout.padding.inline.xs, layout.padding.inline.s],
    background:  theme.bg.alt,
    alignSelf:   'flex-start',
  },
}))