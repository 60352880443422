import { registerNotification } from './registry'

registerNotification('script:started', {
  color: (_, theme) => theme.semantic.positive,
  icon:  notification => notification.relationships.participant?.data != null
    ? 'play-circle'
    : 'play-circle-full',

  colorForResult: (notifications, result, theme) => {
    if (result.type === 'pending') {
      return theme.colors.palette({fade: theme.colors.semantic.neutral}, 0, 1)
    } else if (result.type === 'ok') {
      return theme.colors.palette({fade: theme.colors.semantic.positive}, 0, 1)
    } else {
      const index = notifications.results.indexOf(result)
      return theme.colors.palette({fade: theme.colors.semantic.negative}, index, 2)
    }
  }
  ,
})