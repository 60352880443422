import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import I18next from 'i18next'
import config from '~/config'
import { FlowPlanRuleResult } from '~/stores'
import { memo, observer } from '~/ui/component'
import {
  ClearButton,
  HBox,
  Label,
  Panel,
  panelBorderRadius,
  SVG,
  TextBlock,
  Tooltip,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout, presets, useStyling } from '~/ui/styling'
import { useFlowPlanner } from './FlowPlannerContext'

const PlanRuleResultList = observer('PlanRuleResultList', () => {

  const {planner} = useFlowPlanner()
  const results = planner.ruleResults

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (results == null || results.length === 0) { return null }

    return (
      <VBox gap={layout.padding.m} classNames={$.rules}>
        {renderHeader()}
        {renderResults()}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <Label small caption dim truncate={false} align='center'>
        {t('rules.header')}
      </Label>
    )
  }

  function renderResults() {
    if (results == null) { return null }

    return (
      <VBox gap={layout.padding.inline.s}>
        <VBox gap={layout.padding.inline.xs}>
          {results.slice(0, config.planner.rules.maxShownResults).map(renderResult)}
        </VBox>
        {results.length > config.planner.rules.maxShownResults && (
          <Label small caption dim>
            {t('rules.more', {total: results.length, more: results.length - config.planner.rules.maxShownResults})}
          </Label>
        )}
      </VBox>
    )
  }

  function renderResult(result: FlowPlanRuleResult) {
    return (
      <PlanRuleResultBar
        key={result.hash}
        result={result}
      />
    )
  }

  return render()

})

export default PlanRuleResultList

interface PlanRuleResultBarProps {
  result: FlowPlanRuleResult
}

const PlanRuleResultBar = memo('PlanRuleResultBar', (props: PlanRuleResultBarProps) => {

  const {result} = props
  const {rule} = result
  const translations = rule.translations[I18next.language] ?? rule.translations.en

  const [t] = useTranslation('flow_planner')

  const {planner} = useFlowPlanner()

  const focus = React.useCallback(() => {
    planner.highlightRuleResult(result)
  }, [planner, result])

  const blur = React.useCallback(() => {
    planner.unhighlightRuleResult(result)
  }, [planner, result])

  const silence = React.useCallback(() => {
    planner.silenceRule(result.hash)
    Toast.show({
      ...t('rules.silence.success', {...translations}),
      type: 'success',
    })
  }, [planner, result.hash, t, translations])

  //------
  // Rendering

  const $ = useStyles()
  const {colors} = useStyling()

  function render() {
    return (
      <Tooltip renderTooltip={renderTooltip} onWillOpen={focus} onWillClose={blur}>
        <Panel semi={false}>
          {renderRow()}
        </Panel>
      </Tooltip>
    )
  }

  function renderRow() {
    return (
      <HBox flex='grow' classNames={$.resultRow} gap={layout.padding.inline.s}>
        {rule.level === 'error' ? (
          <SVG name='error-full' color={colors.semantic.negative} size={layout.icon.s}/>
        ) : (
          <SVG name='warning-full' color={colors.semantic.warning} size={layout.icon.s}/>
        )}

        <VBox flex>
          <Label caption truncate={false} small>
            {translations?.caption ?? rule.name}
          </Label>
        </VBox>

        {rule.level === 'warning' && (
          <ClearButton
            icon='cross'
            small
            onTap={silence}
          />
        )}
      </HBox>
    )
  }

  function renderTooltip() {
    if (translations?.details == null) { return null }

    return (
      <VBox gap={layout.padding.s}>
        <VBox gap={layout.padding.inline.s}>
          <Label caption>{t('rules.details')}</Label>
          <TextBlock small markup convertNewlines>
            {translations.details}
          </TextBlock>
        </VBox>

        {translations.how_to_fix != null && (
          <VBox gap={layout.padding.inline.s}>
            <Label caption>{t('rules.how_to_fix')}</Label>
            <TextBlock small markup convertNewlines>
              {translations.how_to_fix}
            </TextBlock>
          </VBox>
        )}
      </VBox>
    )
  }

  return render()

})

export const width = 260
export const resultBarHeight = layout.barHeight.xs

const useStyles = createUseStyles(theme => ({
  rules: {
    width,

    borderRadius: layout.radius.m,
    background:   theme.colors.bg.light.alt.alpha(0.6),
    padding:      layout.padding.inline.m,
  },

  resultRow: {
    position: 'relative',
    cursor:   'help',
    padding:  [layout.padding.inline.m, layout.padding.inline.m],

    '&:hover': {
      ...presets.overlayAfter({
        background:   theme.bg.hover,
        borderRadius: panelBorderRadius(theme),
      }),
    },
  },
}))