import React from 'react'
import { Feedback } from '~/models'
import { ConditionTestVariant } from '../ConditionFormModel'
import AdvancedTestFields from './AdvancedTestFields'
import ChoiceTestFields from './ChoiceTestFields'
import NumericTestFields from './NumericTestFields'
import PresenceTestFields from './PresenceTestFields'
import TextTestFields from './TextTestFields'

export default function renderTestFields(variant: ConditionTestVariant, question: Feedback | null) {
  switch (variant) {
    case 'presence':
      return <PresenceTestFields question={question}/>
    case 'choice':
      if (question?.type === 'choice' || question?.type === 'button') {
        return <ChoiceTestFields question={question}/>
      } else {
        return null
      }
    case 'exact-match':
      if (question?.type === 'numeric') {
        return <NumericTestFields question={question}/>
      } else if (question?.type === 'text' || question?.type === 'choice') {
        return <TextTestFields question={question} partial={false}/>
      } else {
        return null
      }
    case 'partial-match':
      if (question?.type === 'text') {
        return <TextTestFields question={question} partial={true}/>
      } else {
        return null
      }
    case 'advanced':
      return <AdvancedTestFields question={question}/>
  }

}