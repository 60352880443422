import { Database } from 'mobx-document'
import { Model, ModelClass } from '~/models'
import ModelDocument from './ModelDocument'

export default class ModelDatabase<M extends Model> extends Database<ModelDocument<M>> {

  constructor(Model: ModelClass<M>) {
    super({
      getID:         model => model.id,
      getDocument:   model => new ModelDocument<M>(Model, model.id, {initialData: model}),
      emptyDocument: id    => new ModelDocument<M>(Model, id),
    })
  }

}