import React from 'react'
import { QAndA, Question } from '~/models'
import ParticipantCell from '~/ui/app/participants/ParticipantCell'
import { memo } from '~/ui/component'
import { Chip, DataGrid, HBox, Label, SVG } from '~/ui/components'
import { ResourceList, ResourceListProps } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'

export interface Props extends Omit<ResourceListProps<Question>, 'parentModel'> {
  qAndA: QAndA
}

const QuestionList = memo('QuestionList', (props: Props) => {

  const {qAndA, ...rest} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceList
        {...rest}
        parentModel={qAndA}
      >
        <DataGrid.Column
          name='title'
          renderCell={renderTitle}
          flex={2}
        />
        <DataGrid.Column
          name='participant'
          renderCell={renderParticipant}
          flex={2}
        />
        <DataGrid.Column
          name='voteCount'
          renderCell={renderVoteCount}
          flex={2}
        />
      </ResourceList>
    )
  }

  function renderTitle(question: Question) {
    return (
      <HBox gap={layout.padding.inline.l}>
        <SVG
          name='question'
          size={layout.icon.m}
        />
        <Label flex h3>
          {question.title}
        </Label>
      </HBox>
    )
  }

  function renderParticipant(question: Question) {
    return (
      <HBox>
        <ParticipantCell
          item={question.participant}
        />
      </HBox>
    )
  }

  function renderVoteCount(question: Question) {
    return (
      <HBox gap={layout.padding.inline.l}>
        <Chip>{`★ ${question.voteCount}`}</Chip>
      </HBox>
    )
  }

  return render()

})

export const avatarSize = {
  width:  40,
  height: 40,
}

export default QuestionList