import React from 'react'
import { Feed, Post } from '~/models'
import { observer } from '~/ui/component'
import { HBox, PushButton, SwitchField, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import PostFormBody from './PostFormBody'
import PostFormModel from './PostFormModel'

export interface Props extends Omit<FormDialogProps<PostFormModel>, 'model' | 'children'> {
  feed: Feed
  post: Post | null
}

const PostForm = observer('PostForm', (props: Props) => {

  const {feed, post, ...rest} = props
  const {t} = useResourceTranslation('posts')

  const model = React.useMemo(
    () => new PostFormModel(feed, post),
    [feed, post],
  )

  const title = React.useMemo(
    () => post == null ? t('post_form.title_add') : t('post_form.title_edit'),
    [post, t],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='posts'>
        <FormDialog
          model={model}
          title={title}
          footer={renderFooter()}
          headerRight='$close'
          autoFocus={false}
          children={<PostFormBody feedField={false}/>}
          {...rest}
        />
      </ResourceTypeProvider>
    )
  }

  function renderFooter() {
    if (post != null) { return null }

    return (
      <VBox flex>
        <HBox justify='space-between'>
          <FormField name='notify' caption={false}>
            {bind => (
              <SwitchField
                {...bind}
                label={{...t('post_form.notify')}}
              />
            )}
          </FormField>
          <PushButton
            caption={t('post_form.add_post')}
            submit
          />
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default PostForm