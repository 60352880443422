import React from 'react'
import { Competition, formatScore, ScoreFormat } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, NumberField, SVG, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import ScoreFormatForm from './ScoreFormatForm'

export interface Props {
  competition: Competition
}

const ScoreFormatSummary = memo('ScoreFormatSummary', (props: Props) => {

  const {competition} = props
  const {t} = useResourceTranslation('competitions')

  const [formOpen, openForm, closeForm] = useBoolean()

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item icon='participant'>
        <HBox align='top'>
          {renderScoreFormat()}
          {renderEditButton()}
        </HBox>

        <ScoreFormatForm
          open={formOpen}
          requestClose={closeForm}
          competition={competition}
        />
      </SummaryInfo.Item>
    )
  }

  function renderScoreFormat() {
    return (
      <VBox flex gap={layout.padding.inline.m}>
        <Label small dim truncate={false}>
          {t('score_format.preamble')}
        </Label>
        <ScoreFormatTester
          format={competition.scoreFormat}
        />
      </VBox>
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        padding='horizontal'
        onTap={openForm}
      />
    )
  }

  return render()

})

export default ScoreFormatSummary

interface ScoreFormatTesterProps {
  format: ScoreFormat
}

const ScoreFormatTester = memo('ProfileItemPreview', (props: ScoreFormatTesterProps) => {

  const {format} = props

  const [points, setPoints] = React.useState<number | null>(100)

  function render() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <NumberField
          value={points}
          onChange={setPoints}
          showClearButton='never'
          minimum={null}
          step={10}
          small
        />
        <HBox gap={layout.padding.inline.s}>
          <SVG name='arrow-hook' size={layout.icon.xs} dim/>
          <Label mono small variables>
            {`[${formatScore(points ?? 0, format)}]`}
          </Label>
        </HBox>
      </VBox>
    )
  }

  return render()

})