import React from 'react'
import { useDrag } from 'react-dnd'
import { DraggableItemType, DraggableWidgetItem } from '~/dnd'
import { Widget } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import WidgetTile from './WidgetTile'

export interface Props {
  widget: Widget
}

const WidgetCatalogTile = memo('WidgetCatalogTile', (props: Props) => {

  const {widget} = props

  const [connectDraggable, connectDragHandle] = useDrag<DraggableWidgetItem>({
    item: React.useMemo((): DraggableWidgetItem => ({
      type:        DraggableItemType.WIDGET,
      widget:      widget,
      onDashboard: false,
    }), [widget]),
  })

  const connect = React.useCallback((element: HTMLDivElement) => {
    connectDraggable(element)
    connectDragHandle(element)
  }, [connectDragHandle, connectDraggable])

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='grow' classNames={$.widgetCatalogTile} ref={connect}>
        <WidgetTile
          widget={widget}
          flex='grow'
        />
      </VBox>
    )
  }

  return render()

})

export default WidgetCatalogTile

const useStyles = createUseStyles({
  widgetCatalogTile: {
    cursor:    'grab',
  },
})