import React from 'react'
import { Feed, Post } from '~/models'
import { observer } from '~/ui/component'
import { EmptyOrFetching, List } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceCollection, ResourceCollectionChildrenProps } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import PostView from './PostView'

export interface Props {
  feed:     Feed

  selectedPostID: string | null
  onSelect?:      (post: Post) => any

  requestEdit?: (post: Post) => any
}

const PostList = observer('PostList', (props: Props) => {

  const {feed, selectedPostID, onSelect, requestEdit} = props
  const {t} = useResourceTranslation('feeds')

  const endpoint = useModelEndpoint(Post, {
    filters: {
      feed:        feed.id,
      root:        null,
      publishedAt: {$ne: null},
    },
    sorts: [
      {field: 'timestamp', direction: -1},
    ],
  })

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceCollection<Post>
        endpoint={endpoint}
        renderContent={renderList}
      />
    )
  }

  function renderList(props: ResourceCollectionChildrenProps<Post>) {
    return (
      <List<Post>
        data={props.data}
        renderItem={renderPost}
        classNames={$.listView}
        contentClassNames={$.listViewContent}
        EmptyComponent={renderEmpty}
        itemGap={layout.padding.s}
        scrollable
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...t('empty')}
        status={endpoint.fetchStatus}
        flex
      />
    )
  }, [endpoint.fetchStatus, t])

  const renderPost = React.useCallback((post: Post, index: number) => {
    return (
      <PostView
        key={post.id}
        post={post}
        selected={post.id === selectedPostID}
        onSelect={onSelect}
        requestEdit={requestEdit}
      />
    )
  }, [onSelect, requestEdit, selectedPostID])

  return render()

})

export default PostList

const shadowPadding = 10

const useStyles = createUseStyles({
  listView: {
    flex: 1,
    margin: [0, -shadowPadding],
  },

  listViewContent: {
    ...layout.responsive(size => ({
      padding: [layout.padding.m[size], shadowPadding],
    })),
  },
})