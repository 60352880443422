import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { HBox, HrefField, InfoIcon, Label, Switch, TextField, VBox } from '~/ui/components'
import { PROTOCOL_REGEXP } from '~/ui/components/fields/HrefField'
import { FormField } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'
import { layout } from '~/ui/styling'

export interface Props {
  href?:    'hide' | 'view' | 'edit'
  caption?: 'hide' | 'view' | 'edit'
}

const CommonLinkFields = memo('CommonLinkFields', (props: Props) => {

  const {
    href    = 'view',
    caption = 'view',
  } = props

  const [hrefValue] = useFormField<string>('href')

  const hasVariables = /\{\{[\d\w_:]+\}\}/.test(hrefValue) && !PROTOCOL_REGEXP.test(hrefValue)
  const [skipValidation, setSkipValidation] = React.useState<boolean>(hasVariables)

  const [t] = useTranslation('links')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.xs}>
        {href !== 'hide' && (
          <FormField name='href' headerRight={renderSkipValidationToggle()} renderAsLabel={false}>
            {bind => (
              <HrefField
                {...bind}
                readOnly={href !== 'edit'}
                small={href !== 'edit'}
                skipValidation={href !== 'edit' || skipValidation}
              />
            )}
          </FormField>
        )}
        {caption !== 'hide' && (
          <FormField name='caption'>
            {bind => (
              <TextField
                {...bind}
                readOnly={caption !== 'edit'}
                small={caption !== 'edit'}
              />
            )}
          </FormField>
        )}
      </VBox>
    )
  }

  function renderSkipValidationToggle() {
    if (href !== 'edit') { return null }

    return (
      <label>
        <HBox gap={layout.padding.inline.m}>
          <HBox gap={layout.padding.inline.s}>
            <Switch
              isOn={skipValidation}
              onChange={allow => setSkipValidation(!!allow)}
              small
            />
            <Label caption dim={!skipValidation} small>
              {t('skip_validation.caption')}
            </Label>
          </HBox>
          <InfoIcon
            renderTooltip={t('skip_validation.instruction')}
            small
          />
        </HBox>
      </label>
    )
  }

  return render()

})

export default CommonLinkFields