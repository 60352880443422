import { DateTime } from 'luxon'
import { serialize } from 'ymodel'
import { Branding } from '~/models'
import { Model, resource } from './Model'
import { Organisation } from './Organisation'
import { Project } from './Project'
import { TimeInterval } from './TimeInterval'
import { Ref } from './types'

@resource<RegistrationPreset>('registration-presets', {
  icon:           'asterisk',
  caption:        preset => preset.name,
  scopedToModule: false,
  hasDetail:      preset => preset.codeCounts !== undefined,
})
export class RegistrationPreset extends Model {

  public organisation!: Ref<Organisation>

  public name!:     string
  public token!:    string
  public branding!: Ref<Branding> | null

  public requireCode!: boolean

  public allowedOrigins!:       string[]
  public allowedEmailPatterns!: string[]

  @serialize(DateTime)
  public validUntil!: DateTime | null

  @serialize(TimeInterval)
  public duration!:   TimeInterval | null

  public allowedProjects!: Ref<Project>[]
  public groupTag!:        string | null

  public codeCounts?: RegistrationPresetCodeCounts

}

export interface RegistrationPresetCodeCounts {
  total:      number
  registered: number
  other:      number
}