import React from 'react'
import { DataField } from 'sheet-importer'
import { observer } from '~/ui/component'
import { TextField } from '~/ui/components'
import { register } from './registry'

export interface Props {
  field: DataField
}

const DataFieldForm = observer('DataFieldForm', (props: Props) => {

  const {field} = props

  return (
    <TextField
      {...field.bind('variable')}
      mono
      small
    />
  )

})

export default DataFieldForm
register(DataField, DataFieldForm)