import '~/notifications'
import '~/ui/app/notifications/InAppNotification'
import React from 'react'
import { useHotkey } from 'react-hotkeys'
import Toast from 'react-toast'
import I18next from 'i18next'
import { observer } from '~/ui/component'
import { layout } from '~/ui/styling'
import appStore from '../../stores/appStore'
import AppDragLayer from './AppDragLayer'
import ConfigDialog from './config/ConfigDialog'
import useGlobalStyles from './globalStyles'
import AppNavigator from './navigator/AppNavigator'

const App = observer('App', () => {

  const appError   = appStore.readyState === 'error'

  React.useEffect(() => {
    if (appError) {
      Toast.show({
        type: 'error',
        ...I18next.t('errors:unknown'),
      })
    }
  }, [appError])

  useGlobalStyles()

  //------
  // Hotkeys

  const closeConfigDialog = React.useCallback(() => {
    appStore.closeConfigDialog()
  }, [])

  useHotkey('Cmd+Alt+Shift+C', React.useCallback(() => {
    appStore.openConfigDialog()
  }, []), {
    inputs: 'handle',
  })

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppNavigator/>
        <AppDragLayer zIndex={layout.z.dragDrop}/>

        <ConfigDialog
          open={appStore.configDialogOpen}
          requestClose={closeConfigDialog}
        />
      </>
    )
  }

  return render()

})

export default App