import { Group } from './Group'
import { Model, resource } from './Model'
import { Participant } from './Participant'
import { QuestionRef } from './Review'
import { Ref } from './types'

@resource<AnswersQuery>('answers-queries', {
  icon:    () => 'column-chart',
  caption: query => query.name,
  scopedToModule: false,
})

export class AnswersQuery extends Model {
  public name!:        string
  public question!:    QuestionRef
  public segments?:    Ref<Group>[]
  public participant?: Ref<Participant>
}