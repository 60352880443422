import Toast from 'react-toast'
import I18n from 'i18next'
import { isPlainObject } from 'lodash'
import socket, { configure, SocketError } from 'socket.io-react'
import { assignGlobal } from '~/global'
import { appStore, authenticationStore, projectStore } from '~/stores'

assignGlobal({socket})

configure(config =>  {
  const isUserError = (error: SocketError) => {
    return [304, 422, 404, 409].includes(error.status)
  }

  // As we use JSON API, we need to interpret a JSON object with an 'error' property as an error.
  config.extractError = json => {
    if (isPlainObject(json) && 'error' in json) {
      const {error: {status, message, ...extra}, meta} = json
      const certainStatus  = typeof status === 'number' ? status : 500
      const certainMessage = typeof message === 'string' ? message : "Unknown error"
      return new SocketError(certainStatus, certainMessage, {...extra, ...meta})
    } else {
      return SocketError.deserialize(json)
    }
  }

  config.errorHandler = (error, options) => {
    if (options.silent || isUserError(error)) { return }

    if (error.status === 401) {
      Toast.show({
        ...I18n.t('errors:401'),
        type: 'error',
      })
      authenticationStore.logOut()
    } else if (error.status === 410) {
      projectStore.switchProject(null)
    } else if (error.status === 0 && !appStore.online) {
      Toast.show({
        ...I18n.t('errors:no_connection'),
        type: 'error',
      })
    } else if (error.status >= 500) {
      Toast.show({
        ...I18n.t(['errors:status', 'errors:unknown']),
        type: 'error',
      })
    } else {
      Toast.show({
        ...I18n.t([`errors:${error.status}`, 'errors:unknown']),
        type: 'error',
      })
    }
  }
})