import Color from 'color'
import { objectEquals } from 'ytil'
import createFeatureComponent from './createFeatureComponent'
import { LatLong } from './types'
import { convertToLatLng } from './util'

export interface Props {
  center: LatLong
  radius: number

  fillColor?:   Color | null
  strokeColor?: Color | null
  strokeWeight?: number

  options?: google.maps.CircleOptions

  onClick?: () => any
}

const Circle = createFeatureComponent<google.maps.Circle, Props>('GoogleMap.Circle', {

  create: props => {
    const circle = new google.maps.Circle({
      center: convertToLatLng(props.center),
      radius: props.radius,
      ...fillOptions(props),
      ...strokeOptions(props),
      clickable: props.onClick != null,
      ...props.options,
    })

    if (props.onClick != null) {
      circle.addListener('click', props.onClick)
    }

    return circle
  },

  update: (prevProps, nextProps, circle) => {
    let modified = false

    if (!objectEquals(prevProps.center, nextProps.center)) {
      circle.setCenter(convertToLatLng(nextProps.center))
      modified = true
    }
    if (prevProps.radius !== nextProps.radius) {
      circle.setRadius(nextProps.radius)
      modified = true
    }

    const prevFillColor = prevProps.fillColor?.string()
    const nextFillColor = nextProps.fillColor?.string()
    const prevStrokeColor = prevProps.strokeColor?.string()
    const nextStrokeColor = nextProps.strokeColor?.string()

    if (prevFillColor !== nextFillColor || prevStrokeColor !== nextStrokeColor) {
      circle.setOptions({
        ...fillOptions(nextProps),
        ...strokeOptions(nextProps),
      })
      modified = true
    }

    return modified
  },

})

export default Circle

function fillOptions(props: Props): Partial<google.maps.CircleOptions> {
  if (props.fillColor == null) {
    return {fillOpacity: 0}
  }

  return {
    fillColor:   props.fillColor.alpha(1).hex(),
    fillOpacity: props.fillColor.alpha(),
  }
}

function strokeOptions(props: Props): Partial<google.maps.CircleOptions> {
  if (props.strokeColor == null) {
    return {strokeWeight: 0, strokeOpacity: 0}
  }

  return {
    strokeWeight:  props.strokeWeight ?? 1,
    strokeColor:   props.strokeColor.alpha(1).hex(),
    strokeOpacity: props.strokeColor.alpha(),
  }
}