import React from 'react'
import { memo } from '~/ui/component'
import { Center, Dimple, HBox, Panel, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { FormModel, useForm } from '~/ui/form'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { GradientDirection } from './gradients'

const GradientDirectionSelector = memo('GradientDirectionSelector', () => {

  const {model: formModel} = useForm<GradientFormModel>()

  //------
  // Callbacks

  const setDirection = React.useCallback((value: GradientDirection) => {
    if (formModel == null) { return }
    formModel.direction = value
  }, [formModel])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.directionToggle}>
        <HBox align='stretch'>
          {renderDirectionButton(GradientDirection.NWSE)}
          <Dimple vertical/>
          {renderDirectionButton(GradientDirection.NS)}
          <Dimple vertical/>
          {renderDirectionButton(GradientDirection.NESW)}
        </HBox>
        <Dimple horizontal/>
        <HBox align='stretch'>
          {renderDirectionButton(GradientDirection.WE)}
          <Dimple vertical/>
          <VBox classNames={$.directionButton}/>
          <Dimple vertical/>
          {renderDirectionButton(GradientDirection.EW)}
        </HBox>
        <Dimple horizontal/>
        <HBox align='stretch'>
          {renderDirectionButton(GradientDirection.SWNE)}
          <Dimple vertical/>
          {renderDirectionButton(GradientDirection.SN)}
          <Dimple vertical/>
          {renderDirectionButton(GradientDirection.SENW)}
        </HBox>
      </Panel>
    )
  }

  function renderDirectionButton(direction: GradientDirection) {
    const selected = direction === formModel?.direction

    return (
      <Tappable classNames={[$.directionButton, {selected}]} onTap={setDirection.bind(null, direction)}>
        <Center flex>
          <SVG
            name={`gradient-${GradientDirection[direction].toLowerCase()}` as SVGName}
            size={layout.icon.l}
          />
        </Center>
      </Tappable>
    )
  }

  return render()

})

interface GradientFormModel extends FormModel {
  direction: GradientDirection
}

export default GradientDirectionSelector

const useStyles = createUseStyles(theme => ({
  directionToggle: {
    overflow: 'hidden',
  },

  directionButton: {
    width:  32,
    height: 32,

    '&.selected': {
      ...colors.overrideBackground(theme.semantic.primary),
      ...colors.overrideForeground(theme.colors.contrast(theme.semantic.primary)),
    },
  },
}))