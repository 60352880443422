import React from 'react'
import I18next from 'i18next'
import { isArray } from 'lodash'
import { sparse } from 'ytil'
import { ChoiceField, DynamicField, DynamicTypeTranslations } from '~/ui/app/dynamic-form'
import { memo } from '~/ui/component'
import {
  Center,
  CollectionField,
  HBox,
  ItemFormProps,
  SegmentedButton,
  SelectField,
  TextField,
  VBox,
} from '~/ui/components'
import { FieldChangeCallback, FormField, FormFieldHeader } from '~/ui/form'
import { BindProps } from '~/ui/form/FormField'
import { makeFieldChangeCallback } from '~/ui/form/hooks'
import { layout } from '~/ui/styling'
import { PlannerActionFieldsetProps, register } from './registry'

export type Props = PlannerActionFieldsetProps

const HTTPRequestFields = memo('HTTPRequestFields', (props: Props) => {

  const {schema, action} = props

  const pasteTransform = React.useCallback((line: string) => {
    return sparse(line.split('\n').map(line => {
      const match = line.match(/^(.+?)[:=](.*)$/)
      if (match == null || match[0].trim() === '') { return null }

      return {
        name:  match[0].trim(),
        value: match[1].trim(),
      }
    }))

  }, [])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderEndpointFields()}

        {renderHeadersField()}
        {renderBodyField()}
      </VBox>
    )
  }

  //------
  // Endpoint

  const methodField = schema.find(field => field.name === 'method') as ChoiceField<string>
  const urlField    = schema.find(field => field.name === 'url') as DynamicField

  function renderEndpointFields() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <FormFieldHeader caption={urlField.caption}/>
        <HBox align='top' gap={layout.padding.s}>
          <VBox flex={1}>
            {renderMethodField()}
          </VBox>
          <VBox flex={3}>
            {renderURLField()}
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderMethodField() {
    return (
      <FormField name='method' caption={false}>
        {bind => <SelectField {...bind} choices={methodField.choices}/>}
      </FormField>
    )
  }

  function renderURLField() {
    return (
      <FormField name='url' caption={false}>
        {bind => <TextField {...bind} mono/>}
      </FormField>
    )
  }

  //-------
  // Headers

  const headersField = schema.find(field => field.name === 'headers') as DynamicField

  function renderHeadersField() {
    return (
      <FormField name='headers' caption={headersField.caption!}>
        {bind => (
          <CollectionField
            {...bind}
            renderForm={renderHeaderForm}
            newItemTemplate={() => ({name: '', value: ''})}
            pasteTransform={pasteTransform}
          />
        )}
      </FormField>
    )
  }

  function renderHeaderForm(props: ItemFormProps<any>) {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex={2}>
          <TextField {...props.bind('name')}/>
        </VBox>
        <VBox flex={3}>
          <TextField {...props.bind('value')}/>
        </VBox>
      </HBox>
    )
  }

  //------
  // Body

  const bodyField        = schema.find(field => field.name === 'body') as DynamicField
  const bodyTranslations = (action.translations[I18next.language] ?? action.translations.en)?.params?.body as DynamicTypeTranslations

  function renderBodyField() {
    return (
      <FormField name='body' caption={bodyField.caption!}>
        {renderBodyFields}
      </FormField>
    )
  }

  function renderBodyFields(bind: BindProps<any>) {
    const format       = bind.value?.format ?? 'json'
    const changeFormat = (format: string) => bind.onChange({...bind.value, format})

    const content       = bind.value?.content
    const changeContent = makeFieldChangeCallback((content: any) => bind.onChange({...bind.value, content}))

    return (
      <VBox gap={layout.padding.s}>
        <Center>
          <SegmentedButton
            segments={[
              {value: 'json', caption: bodyTranslations?.enum?.json ?? 'json'},
              {value: 'form', caption: bodyTranslations?.enum?.form ?? 'form'},
              {value: 'raw', caption: bodyTranslations?.enum?.raw ?? 'raw'},
            ]}
            selectedValue={format}
            onChange={changeFormat}
            small
          />
        </Center>
        {format === 'json' && renderRawBodyField(content, changeContent)}
        {format === 'form' && renderFormBodyField(content, changeContent)}
        {format === 'raw' && renderRawBodyField(content, changeContent)}
      </VBox>
    )
  }

  function renderFormBodyField(content: any[], onChange: FieldChangeCallback<any[]>) {
    return (
      <CollectionField
        value={isArray(content) ? content : []}
        onChange={onChange}
        newItemTemplate={() => ({name: '', value: ''})}
        renderForm={renderFormItemForm}
        pasteTransform={pasteTransform}
      />
    )
  }

  function renderFormItemForm(props: ItemFormProps<any>) {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex={2}>
          <TextField {...props.bind('name')}/>
        </VBox>
        <VBox flex={3}>
          <TextField {...props.bind('value')}/>
        </VBox>
      </HBox>
    )
  }

  function renderRawBodyField(content: string, onChange: FieldChangeCallback<string>) {
    return (
      <TextField
        value={typeof content === 'string' ? content : ''}
        onChange={onChange}
        height={160}
        mono
        multiline
      />
    )
  }

  return render()

})

register('external:http', HTTPRequestFields)
export default HTTPRequestFields