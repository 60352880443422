import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, PopupMenu, PopupMenuItem, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, presets } from '~/ui/styling'
import LanguageFlag from './LanguageFlag'

export interface Props {
  languages: Language[]

  value:    string
  onChange: (value: string) => any

  small?:           boolean
  showFlag?:        boolean
  showLanguage?:    boolean
  accessory?:       React.ReactNode

  extraItems?:      PopupMenuItem[]
}

export interface Language {
  code: string
  name_en: string
  name_loc: string
}

const LanguageToggleButton = memo('LanguageToggleButton', (props: Props) => {

  const {
    languages,
    value,
    onChange,
    small = false,
    showFlag = true,
    showLanguage = true,
    accessory,
    extraItems,
  } = props

  const $ = useStyles()

  const items = React.useMemo((): PopupMenuItem[] =>{
    const items: PopupMenuItem[] = languages.map(language => ({
      value: language.code,
      render: () => (
        <HBox gap={layout.padding.inline.m} classNames={$.languageItem}>
          <LanguageFlag
            small
            language={language}
          />
          <VBox flex>
            <Label truncate small bold>
              {language.name_loc}
            </Label>
          </VBox>
        </HBox>
      ),
    }))

    if (extraItems != null && extraItems.length > 0) {
      items.push({section: '-'})
      items.push(...extraItems)
    }

    return items
  }, [$.languageItem, extraItems, languages])

  const selectedLanguage = React.useMemo(() => {
    if (value == null) { return null }
    return languages.find(it => it.code === value) ?? null
  }, [languages, value])

  const valueSelect = React.useCallback((value: string | undefined) => {
    if (value == null) { return }
    onChange(value)
  }, [onChange])

  return (
    <PopupMenu items={items} onValueSelect={valueSelect}>
      {toggle => (
        <Tappable classNames={$.languageToggleButton} onTap={toggle} showFocus noFeedback>
          <HBox gap={layout.padding.inline.s}>
            {showFlag && selectedLanguage != null ? (
              <LanguageFlag
                language={selectedLanguage}
                small={small}
              />
            ) : (
              <Label small={small} box mono>
                {selectedLanguage?.code}
              </Label>
            )}
            {showLanguage && (
              <Label small={small}>
                {selectedLanguage?.name_loc}
              </Label>
            )}
            {accessory}
          </HBox>
        </Tappable>
      )}
    </PopupMenu>
  )

})

export default LanguageToggleButton

const useStyles = createUseStyles(theme => ({
  languageToggleButton: {
    position:     'relative',
    padding:      [layout.padding.inline.s, layout.padding.inline.s],
    borderRadius: layout.radius.s,

    '&:hover': {
      ...presets.overlayAfter({
        borderRadius: layout.radius.s,
        background:   theme.bg.hover,
      }),
    },
  },

  languageItem: {
    minHeight: 40,
    padding:   [layout.padding.inline.s, layout.padding.inline.m],
  },
}))