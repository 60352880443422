import React from 'react'
import { AutofocusProvider } from 'react-autofocus'
import { ModelWithName } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, Transition, VBox } from '~/ui/components'
import { FormDialog } from '~/ui/form'
import { useRefMap } from '~/ui/hooks'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import CommonLinkFields, { Props as CommonLinkFieldsProps } from './CommonLinkFields'
import LinkFormDrawer from './LinkFormDrawer'
import LinkFormModel, { LinkType } from './LinkFormModel'
import LinkFormResourceFields from './LinkFormResourceFields'
import LinkFormUploadFields from './LinkFormUploadFields'
import LinkFormURLFields from './LinkFormURLFields'

export interface Props {
  open:          boolean
  requestClose?: () => any

  model: LinkFormModel

  excludeTypes?: Array<LinkType>
  includeTypes?: Array<LinkType>
  caption?:      CommonLinkFieldsProps['caption']
}

const LinkForm = observer('LinkForm', (props: Props) => {

  const {
    open,
    requestClose,
    model,
    excludeTypes = [],
    includeTypes = [],
    caption,
  } = props

  const {t} = useResourceTranslation('links')

  const availableTypes = React.useMemo(() => {
    return model.availableTypes
      .filter(type => includeTypes.length > 0 ? includeTypes.includes(type) : true)
      .filter(type => !excludeTypes.includes(type))
  }, [excludeTypes, includeTypes, model.availableTypes])

  const reset = React.useCallback(() => {
    model.reset()
  }, [model])

  const switchToType = React.useCallback((type: LinkType) => {
    model.setType(type)
  }, [model])

  const projectID = projectStore.projectID

  //------
  // Focus

  const formRefs   = useRefMap<string, HTMLDivElement>()

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        icon='link'
        title={t('form_title')}
        model={model}
        children={renderContent()}
        onDidClose={reset}

        drawerExpanded={true}
        drawerSide='left'
        drawerWidth={260}
        renderDrawer={renderDrawer}

        // We provide our own focus traps.
        trapFocus={false}

        width={640}
        height={560}
      />
    )
  }

  function renderDrawer() {
    return (
      <LinkFormDrawer
        availableTypes={availableTypes}
        currentType={model.type}
        selectType={switchToType}
      />
    )
  }

  function renderContent() {
    return (
      <ResourceTypeProvider resourceType='links'>
        <Transition flex currentScene={model.type}>
          {availableTypes.map(type => (
            <Transition.Scene key={type} name={type}>
              {renderForm(type)}
            </Transition.Scene>
          ))}
        </Transition>
      </ResourceTypeProvider>
    )
  }

  function renderForm(type: LinkType) {
    return (
      <AutofocusProvider
        enabled={type === model.type}
        containerRef={formRefs.getter(type)}
        defaultFocus={{buttons: false}}
        trap='exclude'
      >
        <VBox flex='grow' ref={formRefs.for(type)} gap={layout.padding.m}>
          <VBox flex='grow'>
            {type === 'upload' ? (
              <LinkFormUploadFields/>
            ) : type === 'url' ? (
              <LinkFormURLFields caption={caption}/>
            ) : (
              <LinkFormResourceFields
                Model={ModelWithName(type)}
                filters={{project: projectID}}
              />
            )}
          </VBox>
          <Dimple horizontal/>
          {type !== 'url' && (
            <CommonLinkFields
              caption={caption}
            />
          )}
        </VBox>
      </AutofocusProvider>
    )
  }

  return render()

})

export default LinkForm