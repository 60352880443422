import React from 'react'
import Color from 'color'
import { Feed } from '~/models'
import { memo } from '~/ui/component'
import { ColorField, TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  feed?: Feed | null
}

const FeedForm = memo('FeedForm', (props: Props) => {

  const {open, requestClose, afterSubmit, feed = null} = props
  const {colors} = useStyling()

  const [initialColor, setInitialColor] = React.useState<Color>(
    colors.paletteRandom('default'),
  )

  const resetInitialColor = React.useCallback(() => {
    setInitialColor(colors.paletteRandom('default'))
  }, [colors])

  const initialData = React.useMemo(() => {
    if (feed != null) { return }
    return {color: initialColor}
  }, [feed, initialColor])

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<Feed>
        Model={Feed}
        model={feed}
        initialData={initialData}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}
        onWillOpen={resetInitialColor}

        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='color'>
          {bind => <ColorField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default FeedForm