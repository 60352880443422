import React from 'react'
import { Group, GroupSignup } from '~/models'
import { useClock } from '~/socket/useClock'
import { dataStore } from '~/stores'
import { TranslationsField } from '~/ui/app/fields'
import { TagsField } from '~/ui/app/tags'
import { observer } from '~/ui/component'
import {
  ClearButton,
  ConfirmBox,
  DateTimeField,
  HBox,
  Label,
  Notice,
  NumberField,
  SelectField,
  SwitchField,
  TextField,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormField } from '~/ui/form'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import GroupSignupFormModel from './GroupSignupFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any

  signupID:  string | null
}

const GroupSignupForm = observer('GroupSignupForm', (props: Props) => {

  const {open, requestClose, signupID} = props
  const {t, fieldCaption, actionCaption, actionConfirm} = useResourceTranslation('group-signups')

  const {colors} = useStyling()

  const [signup] = useModelDocumentData(GroupSignup, signupID)

  const {currentTime} = useClock()
  const pastDeadline  = currentTime == null ? false : signup?.isPastDeadline(currentTime)

  const formModel = React.useMemo(
    () => new GroupSignupFormModel(signup),
    [signup],
  )

  const initialData = React.useMemo(
    () => signup == null ? {checkCapacity: true} : {},
    [signup],
  )

  const removeSignup = React.useCallback(async () => {
    if (signup == null) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove'),
      destructive: true,
    })
    if (!confirmed) { return }

    const result = await dataStore.delete(GroupSignup, signup.id)
    if (result?.status === 'ok') {
      requestClose()
    }
  }, [actionConfirm, signup, requestClose])

  const closeSignup = React.useCallback(async () => {
    if (signup == null) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('close'),
      destructive: true,
    })
    if (!confirmed) { return }

    await dataStore.update(GroupSignup, signup.id, {closed: true})
  }, [actionConfirm, signup])

  const reopenSignup = React.useCallback(async () => {
    if (signup == null) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('reopen'),
      destructive: true,
    })
    if (!confirmed) { return }

    await dataStore.update(GroupSignup, signup.id, {closed: false})
  }, [actionConfirm, signup])

  //------
  // Deadline

  const deadlineTypeChoices = React.useMemo((): Choice[] => [
    {value: 'none', caption: t('fields.deadline_type.choices.none')},
    {value: 'explicit', caption: t('fields.deadline_type.choices.explicit')},
    {value: 'variable', caption: t('fields.deadline_type.choices.variable')},
  ], [t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        initialData={initialData}
        icon='signup'
        title={t(signup == null ? 'new' : 'edit', {...signup})}
        headerRight={renderActions()}
        children={renderContent()}
        semi={false}
        contentPadding={0}
        width={860}
      />
    )
  }

  function renderActions() {
    if (signup == null) { return null }

    return (
      <HBox gap={layout.padding.s}>
        <ClearButton
          icon='trash'
          caption={actionCaption('remove')}
          onTap={removeSignup}
        />
      </HBox>
    )
  }

  function renderContent() {
    return (
      <VBox padding={layout.padding.m} gap={layout.padding.m}>
        {renderClosedNotice()}
        <HBox align='stretch' gap={layout.padding.m}>
          {renderCommonFields()}
          {renderTranslationField()}
        </HBox>
      </VBox>
    )
  }

  function renderCommonFields() {
    return (
      <VBox flex={2} gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='tag' required>
          {bind => <TagsField Model={Group} {...bind} multi={false}/>}
        </FormField>
        {renderDeadlineFields()}
        <FormField name='maxSignups'>
          {bind => <NumberField {...bind} minimum={0} step={1}/>}
        </FormField>
        <FormField name='checkCapacity'>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderDeadlineFields() {
    return (
      <VBox gap={layout.padding.inline.s}>
        <FormField name='deadlineType'>
          {bind => <SelectField {...bind} choices={deadlineTypeChoices}/>}
        </FormField>

        {formModel.deadlineType === 'explicit' && (
          <FormField name='deadline'>
            {bind => <DateTimeField {...bind} utc/>}
          </FormField>
        )}
        {formModel.deadlineType === 'variable' && (
          <FormField name='deadlineVariable'>
            {bind => <TextField {...bind} mono/>}
          </FormField>
      )}
      </VBox>
    )
  }

  function renderTranslationField() {
    return (
      <VBox flex={3}>
        <FormField name='translations'>
          {bind => (
            <TranslationsField
              caption={fieldCaption('translations')}
              keys={Object.keys(t('translations.en'))}
              defaults={t('translations')}
              {...bind}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  function renderClosedNotice() {
    if (signup == null) { return null }

    const backgroundColor = signup.closed || pastDeadline
      ? colors.semantic.negative
      : colors.semantic.positive

    return (
      <Notice classNames={$.closedNotice} backgroundColor={backgroundColor}>
        <HBox flex='grow'>
          <Label flex caption truncate={false}>
            {t(`${signup.closed ? 'closed' : pastDeadline ? 'closed_deadline' : 'open'}.summary`)}
          </Label>
          {signup.closed ? (
            <ClearButton
              icon='check-circle'
              caption={actionCaption('reopen')}
              onTap={reopenSignup}
              padding='none'
              small
            />
          ) : (
            <ClearButton
              icon='cross-circle'
              caption={actionCaption('close')}
              onTap={closeSignup}
              padding='none'
              small
            />
          )}
        </HBox>
      </Notice>
    )
  }

  return render()

})

export default GroupSignupForm

const useStyles = createUseStyles({
  closedNotice: {
    padding:   layout.padding.inline.m,
    minHeight: layout.barHeight.m,
  },
})