import React from 'react'
import { memo } from '~/ui/component'
import { HBox, InfoIcon, PushButton, PushButtonProps } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props extends Omit<PushButtonProps, 'icon' | 'caption'> {
  name:         string
  caption?:     string
  icon?:        SVGName | null
  instruction?: string | false
}

const ActionButton = memo('ActionButton', (props: Props) => {

  const {name, caption, instruction, ...rest} = props

  const {t, actionCaption} = useResourceTranslation()

  const resolvedCaption     = caption ?? actionCaption(name)
  const resolvedInstruction = instruction === false ? null : instruction ?? t(`actions.${name}.instruction`, {defaultValue: null})

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.s}>
        <PushButton
          caption={resolvedCaption}
          {...rest}
        />
        {resolvedInstruction != null && (
          <InfoIcon
            renderTooltip={resolvedInstruction}
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default ActionButton