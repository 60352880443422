import React from 'react'
import { memo } from '~/ui/component'
import { Panel, panelBorderRadius, VBox } from '~/ui/components'
import { resourceDetailPath } from '~/ui/resources/routes'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import ResourceListItem, { Props as ResourceListItemProps } from './ResourceListItem'

export interface Props extends ResourceListItemProps {
  link?: boolean
  onTap?: () => any
}

const ResourceBar = memo('ResourceBar', (props: Props) => {

  const {
    onTap,
    selected,
    classNames,
    link = onTap == null,
    compact,
    spacious,
    ...rest
  } = props

  const $ = useStyles()

  const href = link ? resourceDetailPath(props.model.$ModelClass.resourceType, props.model.id) : undefined

  return (
    <VBox classNames={[$.resourceBar, {selected, compact, spacious}, classNames]}>
      <Panel contentClassNames={$.content} href={href} onTap={onTap} justify='middle'>
        <ResourceListItem compact={compact} spacious={spacious} {...rest}/>
      </Panel>
    </VBox>
  )

})

export default ResourceBar

export const height = {
  compact:  layout.barHeight.s,
  normal:   layout.barHeight.m,
  spacious: layout.barHeight.m,
}

const useStyles = createUseStyles(theme => ({
  resourceBar: {
    borderRadius: panelBorderRadius(theme),

    '&.selected': {
      boxShadow: shadows.selected(theme),
    },
  },

  content: {
    minHeight:    height.normal,
    '$resourceBar.compact &':  {minHeight: height.compact},
    '$resourceBar.spacious &': {minHeight: height.spacious},
  },
}))