import { useTimer } from 'react-timer'
import { useLocalStore } from 'mobx-react'
import { MenuPageItem, Page } from '~/models'
import { SubmitResult } from '~/ui/form'
import { useContinuousRef } from '~/ui/hooks'
import { usePagesContext } from '../PagesContext'

export interface MenuPageStore {
  editingItems:     Array<MenuPageItem | RemovedItem> | null
  startEditingList: () => void
  setEditingItems:  (items: Array<MenuPageItem | RemovedItem>) => void
  commitList:       () => Promise<SubmitResult | undefined>

  newItem:    boolean
  setNewItem: (newItem: boolean) => void

  editingItemIndex:    number | null
  setEditingItemIndex: (index: number | null) => void

  removeItemAt:     (index: number) => void
  undoRemoveItemAt: (index: number) => void
}

export function useMenuPageStore(page: Page) {
  const {updatePage} = usePagesContext()
  const pageRef = useContinuousRef(page)

  const timer = useTimer()
  const store = useLocalStore((): MenuPageStore => ({
    editingItems: null,

    startEditingList: () => {
      store.editingItems = pageRef.current.items
    },
    setEditingItems: items => {
      store.editingItems = items
    },
    commitList: async () => {
      if (store.editingItems == null) { return }

      const items = store.editingItems.filter(it => !(it instanceof RemovedItem))
      const result = await timer.await(updatePage(pageRef.current.id, {items}))
      if (result?.status === 'ok') {
        store.editingItems = null
      }
      return result
    },

    newItem: false,
    setNewItem: newItem => {
      store.newItem = newItem
    },

    editingItemIndex: null,
    setEditingItemIndex: index => {
      store.editingItemIndex = index
    },

    removeItemAt: index => {
      if (store.editingItems == null) { return }

      store.editingItems = store.editingItems.map((item, idx) => {
        if (idx === index && !(item instanceof RemovedItem)) {
          return new RemovedItem(item)
        } else {
          return item
        }
      })
    },

    undoRemoveItemAt: index => {
      if (store.editingItems == null) { return }

      store.editingItems = store.editingItems.map((item, idx) => {
        if (idx === index && item instanceof RemovedItem) {
          return item.item
        } else {
          return item
        }
      })

    },
  }))

  return store
}

export class RemovedItem {
  constructor(
    public readonly item: MenuPageItem,
  ) {}
}

export const NewItem = Symbol()
export type NewItem = typeof NewItem