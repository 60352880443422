import I18next from 'i18next'
import { action, computed, makeObservable, observable } from 'mobx'
import { authenticationStore } from '~/stores'
import { formErrorPath, SubmitResult } from '~/ui/form'

export default class AuthResetFormModel {

  constructor(
    public readonly token: string | null,
  ) {
    makeObservable(this)
  }

  @observable
  @formErrorPath('password')
  public newPassword: string = ''

  @observable
  public repeatPassword: string = ''

  @observable
  public showPassword: boolean = false

  @action
  public toggleShowPassword() {
    this.showPassword = !this.showPassword
  }

  @computed
  public get maySubmit() {
    if (this.newPassword === '') { return false }
    if (!this.showPassword && this.newPassword !== this.repeatPassword) { return false }

    return true
  }

  public async submit(): Promise<SubmitResult | undefined> {
    if (this.token == null) { return }

    if (!this.showPassword && this.newPassword !== this.repeatPassword) {
      return {
        status: 'invalid',
        errors: [{
          field: 'newPassword',
          message: I18next.t('users:change_password:passwords_dont_match'),
        }],
      }
    }

    // Log in with the current password.
    return await authenticationStore.resetAuth(this.token, this.newPassword)
  }

}