import { CustomImage } from './CustomImage'
import { Model, resource } from './Model'
import { ProfileItemVisibility } from './Profile'
import { Targeting } from './Targeting'

@resource<Competition>('competitions', {
  icon:    () => 'trophy',
  caption: competition => competition.name,
  appLink: competition => `//rankings/${competition.id}`,
})
export class Competition extends Model {

  public type!:  'head-to-head' | 'team'

  public name!:        string
  public title!:       string
  public image!:       CustomImage | null
  public description!: string | null

  public scoreFormat!:       ScoreFormat
  public profileVisibility!: ProfileItemVisibility | null
  public discloseRankings!:  boolean

  // For `head-to-head`

  public targeting?: Targeting

  // For `team`

  public teamTags?:  string[]
  public normalize?: {variable: string | null} | null

  public formatScore(points: number) {
    return formatScore(points, this.scoreFormat)
  }

}

@resource<Ranking>('rankings', {
  icon:    () => 'trophy',
  caption: ranking => ranking.name,
})
export class Ranking extends Model {

  public rank!: number | null

  public name!:      string
  public firstName!: string
  public lastName!:  string | null
  public image!:     CustomImage | null

  public score!:      Score
  public normalized!: Score | null
  public count!:      number

}

export interface Score {
  points:    number
  formatted: string
}

export interface CompetitionProfileItem {
  visibility:   ProfileItemVisibility
  showRankings: boolean
  scoreFormat:  ScoreFormat
}

export interface ScoreFormat {
  locale?:           string
  templateSingular?: string
  templatePlural?:   string
  minimumDecimals?:  number | null
  maximumDecimals?:  number | null
  useGrouping?:      boolean
}

export const ScoreFormat: {
  default: () => ScoreFormat
} = {
  default: () => ({}),
}

export function formatScore(points: number, format: ScoreFormat) {
  const {templateSingular, templatePlural, locale, minimumDecimals, maximumDecimals, useGrouping = true} = format
  const options = {
    minimumFractionDigits: minimumDecimals ?? 0,
    maximumFractionDigits: maximumDecimals == null ? undefined : Math.max(minimumDecimals ?? 0, maximumDecimals),
  }

  const template = Math.abs(points) === 1 ? templateSingular : templatePlural

  let formatted: string
  try {
    const numberFormat = new Intl.NumberFormat(locale, {...options, useGrouping})
    formatted = numberFormat.format(points)
  } catch {
    const numberFormat = new Intl.NumberFormat(undefined, {...options, useGrouping})
    formatted = numberFormat.format(points)
  }

  if (template == null) { return formatted }
  return template.replace(/\{\}/g, formatted)
}

export interface Scoring {
  pattern: string
  score:   number
}