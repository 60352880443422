import React from 'react'
import { Competition, Targeting } from '~/models'
import { dataStore } from '~/stores'
import { TargetingForm, TargetingFormModel, TargetingFormProps } from '~/ui/app/targeting'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

export interface Props extends Omit<TargetingFormProps, 'model'> {
  competition: Competition
}

const CompetitionTargetingForm = observer('CompetitionTargetingForm', (props: Props) => {

  const {competition, ...rest} = props

  const formModel = React.useMemo(
    () => new CompetitionTargetingFormModel(competition),
    [competition],
  )

  //------
  // Rendering

  function render() {
    return (
      <TargetingForm
        model={formModel}
        {...rest}
      />
    )
  }

  return render()

})

export default CompetitionTargetingForm

class CompetitionTargetingFormModel extends TargetingFormModel {

  constructor(
    public readonly competition: Competition,
  ) {
    super(competition.targeting ?? Targeting.empty())
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Competition, this.competition.id, {
      targeting: this.buildTargeting(),
    })
  }

}