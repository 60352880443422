import React from 'react'
import { Project } from '~/models'
import { observer } from '~/ui/component'
import { HBox, Label, LabelProps, Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { layout, ThemeProvider } from '~/ui/styling'
import ProjectLogo from './ProjectLogo'

export interface Props extends Omit<LabelProps, 'children'> {
  projectID: string
}

const ProjectLabel = observer('ProjectLabel', (props: Props) => {

  const {t} = useResourceTranslation('projects')

  const {projectID, ...rest} = props

  const [project, {fetchStatus}] = useModelDocumentData(
    Project,
    projectID,
    {fetch: 'notfound'},
  )

  //------
  // Rendering

  function render() {
    if (fetchStatus === 'fetching') {
      return <Spinner size={8}/>
    }
    if (project == null) {
      return (
        <Label {...rest}>
          {t('label.not_found')}
        </Label>
      )
    } else {
      return (
        <HBox gap={layout.padding.inline.s}>
          {project.logo != null && (
            <ThemeProvider light>
              <ProjectLogo logo={project.logo} size={layout.icon.s}/>
            </ThemeProvider>
          )}
          <Label {...rest}>
            {project.$caption}
          </Label>
        </HBox>
      )
    }
  }

  return render()

})

export default ProjectLabel