import React from 'react'
import Color from 'color'
import { AutosizeSVG, AutosizeSVGProps, VBox } from '~/ui/components'
import { createUseStyles, Palette, shadows, useStyling } from '~/ui/styling'
import { useQualifiedID } from './chart/hooks'

export interface Props<T> extends AutosizeSVGProps {
  data: T[]

  keyForPoint?:   (point: T) => string | number
  valueForPoint:  (point: T) => number
  colorForPoint?: (point: T) => Color
  palette?:       Palette
}

const DonutChart = <T extends {}>(props: Props<T>) => {

  const {
    data,
    keyForPoint,
    valueForPoint,
    colorForPoint,
    palette = 'default',
    align   = 'center',
    justify = 'middle',
    ...rest
  } = props

  const [size, setSize] = React.useState<Size | null>(null)

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.donutChart, rest.classNames]} flex={rest.flex}>
        <AutosizeSVG {...rest} align={align} justify={justify} aspectRatio={1} onContentSize={setSize}>
          {renderBase()}
          {segments.map(renderSegment)}
          {renderOverlay()}
        </AutosizeSVG>
      </VBox>
    )
  }

  const gradientID = useQualifiedID('gradient')

  //------
  // Layout

  const height        = (size?.height ?? 0)
  const radius        = height / 2
  const segmentThickness  = Math.max((size?.width ?? 0) / 8, 16)
  const midlineRadius = radius - segmentThickness / 2

  const segments = React.useMemo((): Segment[] => {
    const totalValue = data.reduce((total, point) => total + valueForPoint(point), 0)
    if (totalValue === 0) {
      return [{
        key:        0,
        startAngle: 0,
        endAngle:   Math.PI * 2 - 0.001,
        color:      colors.fg.dark.dim,
      }]
    }

    let current = 0
    return data.map((point, index) => {
      const key        = keyForPoint?.(point) ?? index
      const value      = valueForPoint(point)
      const radians    = value / totalValue * 2 * Math.PI
      const startAngle = current
      const endAngle   = current + Math.min(radians, Math.PI * 2 - 0.0001)
      const color      = colorForPoint?.(point) ?? colors.palette(palette, index, data.length)

      current = endAngle
      return {key, startAngle, endAngle, color}
    })
  }, [data, valueForPoint, colors, keyForPoint, colorForPoint, palette])

  function renderSegment(segment: Segment, index: number) {
    const color = segment.color ?? colors.palette(palette, index, data.length)
    const path  = segmentPath(segment)
    if (path == null) { return null }

    return (
      <path
        key={segment.key}
        d={path}
        fill={color.string()}
      />
    )
  }

  function segmentPath(segment: Segment) {
    const {startAngle, endAngle} = segment
    if (size == null || midlineRadius == null) { return null }

    const start = Math.PI / 2 - startAngle
    const end   = Math.PI / 2 - endAngle

    const ro = radius
    const ri = radius - segmentThickness

    const x1 = height / 2 + Math.cos(start) * ro
    const y1 = height / 2 - Math.sin(start) * ro
    const x2 = height / 2 + Math.cos(end) * ro
    const y2 = height / 2 - Math.sin(end) * ro
    const x3 = height / 2 + Math.cos(end) * ri
    const y3 = height / 2 - Math.sin(end) * ri
    const x4 = height / 2 + Math.cos(start) * ri
    const y4 = height / 2 - Math.sin(start) * ri

    const l = (endAngle - startAngle) > Math.PI ? '1' : '0'

    return [
      `M ${x1} ${y1}`,
      `A ${ro} ${ro} 0 ${l} 1 ${x2} ${y2}`,
      `L ${x3} ${y3}`,
      `A ${ri} ${ri} 0 ${l} 0 ${x4} ${y4}`,
    ].join(' ')
  }

  const basePathData = React.useMemo(() => {
    if (size == null) { return null }

    const w = size.width
    const h = size.height
    const t = segmentThickness

    return [
      `M ${w / 2} 0`,
      `A ${w / 2} ${h / 2} 0 1 0 ${w / 2} ${h}`,
      `A ${w / 2} ${h / 2} 0 1 0 ${w / 2} 0`,
      'Z',
      `M ${w / 2} ${t}`,
      `A ${w / 2 - t} ${h / 2 - t} 0 0 1 ${w / 2} ${h - t}`,
      `A ${w / 2 - t} ${h / 2 - t} 0 0 1 ${w / 2} ${t}`,
      'Z',
    ].join(' ')
  }, [segmentThickness, size])

  const basePathColor = colors.palette(palette, 0)

  function renderBase() {
    if (basePathData == null) { return null }

    return (
      <path
        d={basePathData}
        classNames={$.base}
        fill={basePathColor.string()}
      />
    )
  }

  function renderOverlay() {
    if (basePathData == null) { return null }

    return (
      <>
        <defs>
          <radialGradient id={gradientID} cx={0.75} cy={0.75} r={1}>
            <stop offset='70%' stopColor='white' stopOpacity={0}/>
            <stop offset='85%' stopColor='white' stopOpacity={0.3}/>
            <stop offset='100%' stopColor='white' stopOpacity={0}/>
          </radialGradient>
        </defs>

        <path
          d={basePathData}
          fill={`url(#${gradientID})`}
        />
      </>
    )
  }

  return render()

}

export default DonutChart

interface Segment {
  key:        string | number
  startAngle: number
  endAngle:   number
  color?:     Color
}

const useStyles = createUseStyles({
  donutChart: {
    padding: 4, // For the shadow
  },

  base: {
    filter: `drop-shadow(1px 1px 2px ${shadows.shadowColor})`,
  },
})