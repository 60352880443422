import React from 'react'
import { projectStore } from '~/stores'
import { FeatureListFieldset } from '~/ui/app/fields'
import { observer } from '~/ui/component'

const ModuleFeatureList = observer('ModuleFeatureList', () => {

  return (
    <FeatureListFieldset
      prefix='features'
      available={projectStore.moduleFeatures}
    />
  )

})

export default ModuleFeatureList