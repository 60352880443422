import { isPlainObject, omit } from 'lodash'
import { Model, ModelClass } from '~/models'
import { ResourceOf } from './types'

export function resourceToModel(resource: ResourceOf<any>): AnyObject {
  return {
    id: resource.id,
    ...resource.attributes,
  }
}

export function modelToResource<M extends Model>(
  type:   ModelClass<M>['resourceType'],
  id:     string | null,
  data:   AnyObject,
  extra?: Partial<ResourceOf<M>>,
): ResourceOf<M> {
  const resource = {
    type:          type,
    attributes:    omit(data, 'id'),
    relationships: {},
    meta:          {},
  } as ResourceOf<M>

  if (id != null) {
    resource.id = id
  }

  Object.assign(resource, extra ?? {})

  return resource
}

export function isResource(arg: any): arg is ResourceOf<any> {
  if (!isPlainObject(arg)) { return false }
  if (typeof arg.type !== 'string') { return false }
  if (!isPlainObject(arg.attributes)) { return false }

  return true
}