import React from 'react'
import { memo } from '~/ui/component'
import { createUseStyles, layout, useTheme, ThemeProvider } from '~/ui/styling'
import { SVGName, svgExists } from '~/ui/components/SVG'
import Color from 'color'
import { Center, SVG } from '~/ui/components'
import { clamp } from 'lodash'

export interface Props {
  image:     SVGName | React.ReactNode
  progress?: number

  backgroundColor?: Color
  foregroundColor?: Color
  progressColor?:   Color

  small?: boolean
}

const ProgressBadge = memo('ProgressBadge', (props: Props) => {

  const {
    image,
    progress,
    progressColor,
    small,
  } = props

  const hasProgress = progress != null

  const theme = useTheme()

  const {
    backgroundColor = theme.inverse.bg.alt,
    foregroundColor,
  } = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Center classNames={[$.progressBadge, {hasProgress, small}]}>
        {renderBadge()}
        {renderProgress()}
      </Center>
    )
  }

  function renderBadge() {
    return (
      <Center classNames={$.badge} style={{backgroundColor: backgroundColor.string()}}>
        <ThemeProvider contrast={backgroundColor} overrides={{fg: {normal: foregroundColor}}}>
          {renderImage()}
        </ThemeProvider>
      </Center>
    )
  }

  function renderImage() {
    if (typeof image === 'string' && svgExists(image)) {
      return (
        <SVG
          name={image}
          size={small ? imageSize.small : imageSize.normal}
        />
      )
    } else {
      return image
    }
  }

  //------
  // Progress

  const size = small ? progressBadgeSize.small.withProgress : progressBadgeSize.normal.withProgress
  const thickness = small ? progressThickness.small : progressThickness.normal

  const progressToPoint = React.useCallback((progress: number, radius: number) => {
    const {width, height} = size
    const a  = -progress * 2 * Math.PI + 0.5 * Math.PI
    const cx = width / 2
    const cy = height / 2

    return {
      x: cx + Math.cos(a) * radius,
      y: cy - Math.sin(a) * radius,
    }
  }, [size])

  const pathData = React.useCallback((progress: number) => {
    let close = false
    progress = clamp(progress, 0, 1)
    if (progress === 0) { return null }
    if (progress === 1) { close = true; progress = 0.999 }

    const r = size.height / 2 - thickness / 2
    const {x: sx, y: sy} = progressToPoint(0, r)
    const {x: ex, y: ey} = progressToPoint(progress, r)
    const large          = progress > 0.5 ? '1' : '0'

    return [
      `M ${sx} ${sy}`,
      `A ${r} ${r} 0 ${large} 1 ${ex} ${ey}`,
      close ? 'Z' : '',
    ].join(' ')
  }, [progressToPoint, size.height, thickness])

  const progressPathBackgroundData = React.useMemo(
    () => pathData(1)!,
    [pathData],
  )

  const progressPathData = React.useMemo(
    () => pathData(progress ?? 0),
    [pathData, progress],
  )

  function renderProgress() {
    if (progress == null) { return null }

    const {width: w, height: h} = size
    return (
      <svg classNames={$.progress} viewBox={`0 0 ${w} ${h}`}>
        <path
          classNames={$.progressPathBackground}
          d={progressPathBackgroundData}
          strokeWidth={thickness}
        />
        {progressPathData && (
          <path
            classNames={$.progressPath}
            d={progressPathData}
            stroke={progressColor?.string()}
            strokeWidth={thickness}
          />
        )}
      </svg>
    )
  }

  return render()

})

export default ProgressBadge

export const progressBadgeSize = {
  small: {
    withProgress:    {width: 56, height: 56},
    withoutProgress: {width: 40, height: 40},
  },
  normal: {
    withProgress:    {width: 116, height: 116},
    withoutProgress: {width: 80, height: 80},
  },
}

export const progressThickness = {
  small:  4,
  normal: 10,
}

export const imageSize = {
  small: {
    width: progressBadgeSize.small.withoutProgress.width * 0.701,
    height: progressBadgeSize.small.withoutProgress.height * 0.701,
  },
  normal: {
    width: progressBadgeSize.normal.withoutProgress.width * 0.701,
    height: progressBadgeSize.normal.withoutProgress.height * 0.701,
  },
}

const useStyles = createUseStyles(theme => ({
  progressBadge: {
    position: 'relative',

    '&.small': {
      '&:not(.hasProgress)': {
        ...progressBadgeSize.small.withoutProgress,
      },
      '&.hasProgress': {
        ...progressBadgeSize.small.withProgress,
      },
    },
    '&:not(.small)': {
      '&:not(.hasProgress)': {
        ...progressBadgeSize.normal.withoutProgress,
      },
      '&.hasProgress': {
        ...progressBadgeSize.normal.withProgress,
      },
    },
  },

  badge: {
    position: 'absolute',
    top:      '50%',
    left:     '50%',
    overflow: 'hidden',

    '$progressBadge.small &': {
      marginTop:    -progressBadgeSize.small.withoutProgress.width / 2,
      marginLeft:   -progressBadgeSize.small.withoutProgress.height / 2,
      borderRadius: progressBadgeSize.small.withoutProgress.height / 2,
      ...progressBadgeSize.small.withoutProgress,
    },
    '$progressBadge:not(.small) &': {
      marginTop:    -progressBadgeSize.normal.withoutProgress.width / 2,
      marginLeft:   -progressBadgeSize.normal.withoutProgress.height / 2,
      borderRadius: progressBadgeSize.normal.withoutProgress.height / 2,
      ...progressBadgeSize.normal.withoutProgress,
    },
  },

  progress: {
    ...layout.overlay,
  },

  progressPathBackground: {
    fill:          'none',
    stroke:        theme.fg.normal.alpha(0.1),
  },

  progressPath: {
    fill:          'none',
    stroke:        theme.semantic.secondary,
    strokeLinecap: 'round',
  },
}))