import React from 'react'
import { component } from '~/ui/component'
import { Center, ClearButton, HBox, Label, LabelProps, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  icon?:      SVGName
  children?:  React.ReactNode
}

const SummaryInfo = component('SummaryInfo', (props: Props) => {

  const {children} = props

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {children}
      </VBox>
    )
  }

  return render()

})

export interface ItemProps extends Omit<LabelProps, 'children'> {
  icon?:        SVGName | null
  summary?:     React.ReactNode
  body?:        React.ReactNode
  children?:    React.ReactNode
  important?:   boolean
  requestEdit?: () => any
}

const Item = component('SummaryInfoItem', (props: ItemProps) => {
  const {
    icon = 'info',
    color: props_color,
    important = false,
    dim       = !important && props_color == null,
    dimmer,
    summary: props_summary,
    body,
    children: props_children,
    requestEdit,
    ...rest
  } = props

  const $ = useStyles()
  const {colors} = useStyling()

  const backgroundColor = important ? (props_color ?? colors.semantic.warning) : undefined
  const color = important ? undefined : props_color

  // Legacy: if only children are specified and not a summary, interpret the children as a summary.
  const summary  = isReactText(props_summary) ? props_summary : props_children
  const children = isReactText(props_summary) ? props_children : undefined

  //-------
  // Rendering

  function render() {
    return (
      <ThemeProvider contrast={backgroundColor}>
        <VBox classNames={[$.summaryInfoItem, {important}]} style={{backgroundColor: backgroundColor?.string()}} gap={layout.padding.inline.m}>
          {(icon != null || summary != null || requestEdit != null) && (
            <HBox gap={layout.padding.inline.m} align='top'>
              {renderIcon()}
              <VBox flex>
                {renderSummary()}
              </VBox>
              {requestEdit != null && renderEditButton()}
            </HBox>
          )}
          {body != null && !isReactText(body) && (
            <VBox classNames={[$.iconAlign, {icon: !!icon}]}>
              {body}
            </VBox>
          )}
        </VBox>
        {children}
      </ThemeProvider>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }

    return (
      <SVG
        name={icon}
        size={layout.icon.s}
        classNames={$.summaryAlign}
        {...{dim, dimmer, color}}
      />
    )
  }

  function renderSummary() {
    if (isReactText(summary)) {
      return (
        <Label
          small
          truncate={false}
          bold={important}
          {...{dim, dimmer, color}}
          children={summary}
          {...rest}
        />
      )
    } else {
      return summary
    }
  }

  function renderEditButton() {
    return (
      <Center classNames={$.summaryAlign}>
        <ClearButton
          icon='pencil'
          onTap={requestEdit}
          padding='horizontal'
          small
        />
      </Center>
    )
  }

  return render()
})

Object.assign(SummaryInfo, {Item})
export default SummaryInfo as typeof SummaryInfo & {Item: typeof Item}

const useStyles = createUseStyles(theme => ({
  summaryInfoItem: {
    '&.important': {
      background:   theme.semantic.warning,
      borderRadius: layout.radius.m,
      padding:      layout.padding.inline.s,
      marginLeft:   -layout.padding.inline.s,
    },
  },

  summaryAlign: {
    marginTop: 3,
  },

  iconAlign: {
    '&.icon': {
      marginLeft: layout.icon.s.width + layout.padding.inline.m,
    },
  },
}))