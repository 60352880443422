import React from 'react'
import { Competition } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, Panel, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { createUseStyles, layout } from '~/ui/styling'
import CompetitionProfileItemForm from './CompetitionProfileItemForm'

export interface Props {
  competition: Competition
}

const CompetitionProfileItemSummary = memo('CompetitionProfileItemSummary', (props: Props) => {

  const {competition} = props
  const {t, fieldCaption} = useResourceTranslation('competitions')

  const [formOpen, openForm, closeForm] = useBoolean()

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderProfileItemInfoItem()}
        {renderVisibilityInfoItem()}
        {renderShowRankingsInfoItem()}
      </VBox>
    )
  }

  function renderProfileItemInfoItem() {
    return (
      <SummaryInfo.Item icon='participant'>
        <HBox align='top'>
          <VBox flex>
            {competition.profileVisibility == null ? (
              renderEmpty()
            ) : (
              renderProfileItem()
            )}
          </VBox>
          {renderEditButton()}
        </HBox>

        <CompetitionProfileItemForm
          open={formOpen}
          requestClose={closeForm}
          competition={competition}
        />
      </SummaryInfo.Item>
    )
  }

  function renderEmpty() {
    return (
      <Label small dim truncate={false}>
        {t('profile_item.empty')}
      </Label>
    )
  }

  function renderProfileItem() {
    if (competition.profileVisibility == null) { return null }

    return (
      <VBox gap={layout.padding.inline.m}>
        <Label caption dim>
          {fieldCaption('profile_item')}
        </Label>
        <Label small dim truncate={false}>
          {t('profile_item.preamble')}
        </Label>
        <ProfileItemPreview
          competition={competition}
        />
      </VBox>
    )
  }

  function renderVisibilityInfoItem() {
    if (competition.profileVisibility == null) { return null }

    const icon: SVGName =
      competition.profileVisibility === 'private' ? 'eye-off' :
      competition.profileVisibility === 'connections' ? 'handshake' :
      'eye'

    return (
      <SummaryInfo.Item small dim truncate={false} icon={icon}>
        {t(`profile:visibility.${competition.profileVisibility}.summary`)}
      </SummaryInfo.Item>
    )
  }

  function renderShowRankingsInfoItem() {
    if (competition.profileVisibility == null) { return null }

    return (
      <SummaryInfo.Item small dim truncate={false}>
        {t(`profile_item.disclose_rankings_summary`)}
      </SummaryInfo.Item>
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        padding='horizontal'
        onTap={openForm}
      />
    )
  }

  return render()

})

export default CompetitionProfileItemSummary

interface ProfileItemPreviewProps {
  competition: Competition
}

const ProfileItemPreview = memo('ProfileItemPreview', (props: ProfileItemPreviewProps) => {

  const {competition} = props
  const points = 10

  const $ = useStyles()

  function render() {
    return (
      <Panel semi={false} contentClassNames={$.profileItemPreview}>
        <HBox flex gap={layout.padding.inline.m}>
          {renderImage()}
          {renderCaption()}
          {renderScore()}
        </HBox>
      </Panel>
    )
  }

  function renderImage() {
    if (competition.image == null) { return null }

    return (
      <ImageView
        classNames={$.profileItemImage}
        source={competition.image}
        size={profileItemImageSize}
        blocks
      />
    )
  }

  function renderCaption() {
    return (
      <Label flex caption>
        {competition.title}
      </Label>
    )
  }

  function renderScore() {
    return (
      <Label caption markup>
        {`***${competition.formatScore(points)}***`}
      </Label>
    )
  }

  return render()

})

const profileItemImageSize = {
  width:  24,
  height: 24,
}

const useStyles = createUseStyles({
  profileItemPreview: {
    height:  layout.barHeight.s,
    padding: [layout.padding.inline.s, layout.padding.inline.l],
  },

  profileItemImage: {
    borderRadius: layout.radius.s,
  },
})