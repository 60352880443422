import React from 'react'

export interface AssistantFormContext<S extends string> {
  showStepList:    boolean
  setShowStepList: (value: boolean) => any

  allowSelectStep:    'all' | 'previous' | 'none'
  setAllowSelectStep: (value: 'all' | 'previous' | 'none') => any

  getStepContaining: (element: Element) => S | null
  requestStep:       (step: S) => any
  setStepAccessory:  (step: S, accessory: React.ReactNode) => any
}

export const AssistantFormContext = React.createContext<AssistantFormContext<any>>({
  showStepList: true,
  setShowStepList: () => undefined,

  allowSelectStep:    'all',
  setAllowSelectStep: () => undefined,

  getStepContaining: () => null,
  requestStep:       () => undefined,
  setStepAccessory:  () => undefined,
})

export function useAssistantFormDialog<S extends string>(): AssistantFormContext<S> {
  return React.useContext(AssistantFormContext)
}

