import {
  Challenge,
  ChallengeTask,
  ConditionSelector,
  messageSummary,
  ModelClass,
  Script,
  ScriptMessage,
} from '~/models'
import { SVGName } from '~/ui/components/SVG'

export function iconForConditionSelector(selector: ConditionSelector): SVGName {
  switch (selector.type) {
    case 'script-question': return 'chat'
    case 'challenge-task':  return 'question'
    case 'variable':        return 'code'
  }
}

export function modelForConditionSelector(selector: ConditionSelector): ModelClass<any> | null {
  switch (selector.type) {
    case 'script-question': return Script
    case 'challenge-task':  return Challenge
    case 'variable':        return null
  }
}

export function questionForConditionSelector(selector: ConditionSelector, model: Script | Challenge) {
  if (selector.type === 'variable') { return null }

  if (model instanceof Script) {
    return model.messages()?.find(msg => msg.uuid === selector.questionUUID) ?? null
  } else {
    return model.tasks?.find(msg => msg.uuid === selector.questionUUID) ?? null
  }
}

export function captionForConditionSelector(selector: ConditionSelector, model: Script | Challenge | null) {
  const question = model == null ? null : questionForConditionSelector(selector, model)
  if (selector.type !== 'variable' && question == null) { return null }

  switch (selector.type) {
    case 'script-question': return messageSummary(question as ScriptMessage)
    case 'challenge-task':  return (question as ChallengeTask).title
    case 'variable':        return selector.variable
  }
}