import React from 'react'
import { DateTime } from 'luxon'
import { useClock } from '~/socket/useClock'
import { notificationsStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, HBox, panelBorderRadius, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { animation, createUseStyles, layout, presets, shadows } from '~/ui/styling'
import NotificationsSettingsDialog from '../settings/NotificationsSettingsDialog'
import { height as notificationPopupItemHeight } from './NotificationPopupItem'
import NotificationsCycler from './NotificationsCycler'
import NotificationsPopup from './NotificationsPopup'

const NotificationsViewer = observer('NotificationsViewer', () => {

  const notifications          = notificationsStore.viewerNotifications
  const mostRecentNotification = notifications.length === 0 ? null : notifications[0]

  const [currentStartTime, setCurrentStartTime] = React.useState<DateTime | null>(null)
  const [visible, setVisible] = React.useState<boolean>(false)
  const [popupOpen, openPopup, closePopup] = useBoolean()
  const [settingsOpen, openSettings, closeSettings] = useBoolean()

  const {getCurrentTime, currentTime} = useClock({
    active:   visible,
    interval: 'second',
  })

  const deriveCurrentNotification = React.useCallback((date: DateTime) => {
    if (mostRecentNotification == null) { return null }

    const age = mostRecentNotification.createdAt.diff(date, 'seconds')
    if (age.seconds > -lifetime) {
      return mostRecentNotification
    } else {
      return null
    }
  }, [mostRecentNotification])

  React.useEffect(() => {
    const currentTime = getCurrentTime()
    if (currentTime == null) { return }

    const nextItem = deriveCurrentNotification(currentTime)
    if (nextItem != null && !visible) {
      setCurrentStartTime(nextItem.createdAt)
      setVisible(true)
    }
  }, [deriveCurrentNotification, getCurrentTime, visible])

  React.useEffect(() => {
    if (currentStartTime == null) { return }
    if (currentTime == null) { return }

    const age = currentStartTime.diff(currentTime, 'seconds')
    if (age.seconds < -lifetime) { setVisible(false) }
  }, [currentStartTime, currentTime])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <NotificationsPopup open={popupOpen} requestClose={closePopup} requestOpenSettings={openSettings}>
          <VBox classNames={[$.mostRecentNotification, {visible: visible}]}>
            {mostRecentNotification != null && <NotificationsCycler notification={mostRecentNotification}/>}
          </VBox>
        </NotificationsPopup>

        <ClearButton
          icon='satellite'
          enabled={mostRecentNotification != null}
          onTap={openPopup}
          padding='both'
          large
        />

        <NotificationsSettingsDialog
          open={settingsOpen}
          requestClose={closeSettings}
        />
      </HBox>
    )
  }

  return render()

})

export const width    = 320
export const lifetime = 10

export default NotificationsViewer

const useStyles = createUseStyles(theme => ({
  mostRecentNotification: {
    width:    width,
    height:   notificationPopupItemHeight,

    willChange:      ['opacity', 'transform'],
    transition:      animation.transitions.short(['opacity', 'transform'], animation.timings.popIn),
    transformOrigin: ['100%', '50%'],

    '&:not(.visible)': {
      opacity:   0,
      transform: 'scale(0.8)',
      pointerEvents: 'none',
    },

    borderRadius: panelBorderRadius(theme),
    background:   theme.colors.bg.dark.alt.alpha(0.2),

    ...presets.overlayAfter({
      borderRadius: panelBorderRadius(theme),
      boxShadow:    ['inset', 1, 0, 2, 0, shadows.shadowColor],
    }),
  },
}))