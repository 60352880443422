import React from 'react'
import { snakeCase } from 'lodash'
import { memo } from '~/ui/component'
import { PushButton, TextBlock, TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import GroupSelector from './GroupSelector'
import ProjectSelector from './ProjectSelector'
import RegistrationFormModel from './RegistrationFormModel'
import { useRegistrationWidgetModel } from './RegistrationWidgetContext'
import { RegistrationTranslationKey } from './types'

export interface Props {}

const RegistrationFields = memo('RegistrationFields', (props: Props) => {

  const model = useRegistrationWidgetModel()
  const {model: formModel} = useForm<RegistrationFormModel>()

  //------
  // Rendering

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        <VBox flex gap={layout.padding.s} justify='middle'>
          <TextBlock caption dim align='center'>
            {model.translate(RegistrationTranslationKey.fieldsPrompt)}
          </TextBlock>

          <VBox gap={layout.padding.inline.m}>
            {renderField('firstName', {
              autoCorrect:    'off',
              autoComplete:   'name',
            })}
            {renderField('lastName', {
              autoCorrect:    'off',
              autoComplete:   'name',
            })}
            {renderField('email', {
              autoCapitalize: 'off',
              autoCorrect:    'off',
              autoComplete:   'email',
            })}

            <ProjectSelector/>
            <GroupSelector/>
          </VBox>
        </VBox>
        {renderSubmit()}
      </VBox>
    )
  }

  function renderField(name: string, inputAttributes: React.InputHTMLAttributes<HTMLInputElement> = {}) {
    const placeholder = model.translate(`fields.${snakeCase(name)}.placeholder` as RegistrationTranslationKey) ?? undefined

    return (
      <FormField name={name} caption={null} align='center'>
        {bind => (
          <TextField
            {...bind}
            placeholder={placeholder}
            inputAttributes={inputAttributes}
            align='center'
            inputStyle='dark'
          />
        )}
      </FormField>

    )
  }

  function renderSubmit() {
    const caption = model.translate(RegistrationTranslationKey.fieldsButton) ?? undefined

    return (
      <PushButton
        caption={caption}
        enabled={formModel.maySubmit}
        submit
      />
    )
  }

  return render()

})

export default RegistrationFields