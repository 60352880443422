import React from 'react'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { useClipboardQuery } from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { Condition, MessageFeedback, ScriptMessage } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, ConfirmBox, HBox, Label, Panel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useScriptEditing } from './ScriptEditingContext'
import { useScriptEditor } from './ScriptEditorContext'

const EditingListActionBar = memo('EditingListActionBar', () => {

  const {selectedMessageUUIDs, stopEditingList} = useScriptEditing()
  const {removeMessages, copyMessages, updateMessages, addMessages} = useScriptEditor()

  const {colors} = useStyling()

  const {t} = useResourceTranslation()

  const [messagesClipboardQuery, messagesPreview] = useClipboardQuery<ScriptMessage[]>(ClipboardType.SCRIPT_MESSAGES)
  const messagesInClipboard = React.useMemo(() => messagesPreview?.data ?? [], [messagesPreview?.data])

  const [feedbackClipboardQuery, feedbackPreview] = useClipboardQuery<MessageFeedback>(ClipboardType.MESSAGE_FEEDBACK)
  const feedbackInClipboard = React.useMemo(() => feedbackPreview?.data ?? null, [feedbackPreview?.data])

  const [conditionsClipboardQuery, conditionsPreview] = useClipboardQuery<Condition[]>(ClipboardType.CONDITIONS)
  const conditionsInClipboard = React.useMemo(() => conditionsPreview?.data ?? [], [conditionsPreview?.data])

  const hasClipboardData = React.useMemo(
    () => messagesInClipboard.length > 0 || feedbackInClipboard != null || conditionsInClipboard.length > 0,
    [conditionsInClipboard.length, feedbackInClipboard, messagesInClipboard.length],
  )

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox padding={layout.padding.m}>
        <Panel semi contentClassNames={$.editingListActionBar}>
          <HBox flex gap={layout.padding.inline.m} justify='space-between'>
            <HBox gap={layout.padding.inline.m}>
              {renderCopyButton()}
              {hasClipboardData && renderPasteButton()}
            </HBox>
            {renderRemoveButton()}
          </HBox>
        </Panel>
      </VBox>
    )
  }

  function renderCopyButton() {
    return (
      <ClearButton
        icon='copy'
        caption={t('actions.copy_messages.caption', {count: selectedMessageUUIDs.length})}
        onTap={copySelectedMessages}
        enabled={selectedMessageUUIDs.length > 0}
      />
    )
  }

  function renderPasteButton() {
    return (
      <>
        <Label small dimmer>|</Label>
        {messagesInClipboard.length > 0 && renderPasteMessagesButton()}
        {feedbackInClipboard != null && renderPasteFeedbackButton()}
        {conditionsInClipboard.length > 0 && renderPasteConditionsButton()}
      </>
    )
  }

  function renderPasteMessagesButton() {
    return (
      <ClearButton
        icon='paste'
        caption={t('actions.paste_messages.caption', {count: messagesInClipboard.length})}
        onTap={pasteMessagesInClipboard}
      />
    )
  }

  function renderPasteFeedbackButton() {
    return (
      <ClearButton
        icon='paste'
        caption={t('actions.paste_feedback.caption')}
        onTap={pasteFeedbackInClipboard}
        enabled={selectedMessageUUIDs.length > 0}
      />
    )
  }

  function renderPasteConditionsButton() {
    return (
      <ClearButton
        icon='paste'
        caption={t('actions.paste_conditions.caption')}
        onTap={pasteConditionsInClipboard}
        enabled={selectedMessageUUIDs.length > 0}
      />
    )
  }

  function renderRemoveButton() {
    return (
      <ClearButton
        icon='trash'
        caption={t('actions.remove_messages.caption', {count: selectedMessageUUIDs.length})}
        onTap={removeSelectedMessages}
        color={colors.semantic.negative}
        enabled={selectedMessageUUIDs.length > 0}
      />
    )
  }

  const timer = useTimer()

  const copySelectedMessages = React.useCallback(() => {
    copyMessages(selectedMessageUUIDs)
    stopEditingList()

    Toast.show({
      title:  t('actions.copy_messages.success.title', {count: selectedMessageUUIDs.length}),
      detail: t('actions.copy_messages.success.detail', {count: selectedMessageUUIDs.length}),
      type:   'success',
    })
  }, [copyMessages, selectedMessageUUIDs, stopEditingList, t])

  const pasteMessagesInClipboard = React.useCallback(async () => {
    const messages = messagesClipboardQuery.getData()
    if (messages == null) { return }

    await addMessages(messages)
    stopEditingList()
  }, [addMessages, messagesClipboardQuery, stopEditingList])

  const pasteFeedbackInClipboard = React.useCallback(async () => {
    const feedback = feedbackClipboardQuery.getData()
    if (feedback == null) { return }

    await updateMessages(selectedMessageUUIDs, () => ({feedback}))
    stopEditingList()
  }, [feedbackClipboardQuery, selectedMessageUUIDs, stopEditingList, updateMessages])

  const pasteConditionsInClipboard = React.useCallback(async () => {
    const conditions = conditionsClipboardQuery.getData()
    if (conditions == null) { return }

    await updateMessages(selectedMessageUUIDs, () => ({conditions}))
    stopEditingList()
  }, [conditionsClipboardQuery, selectedMessageUUIDs, stopEditingList, updateMessages])

  const removeSelectedMessages = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('actions.remove_messages.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    const result = await timer.await(removeMessages(selectedMessageUUIDs))
    if (result?.status === 'ok') {
      stopEditingList()
    }
  }, [removeMessages, selectedMessageUUIDs, stopEditingList, t, timer])

  return render()

})

export default EditingListActionBar

const useStyles = createUseStyles({
  editingListActionBar: {
    height:  layout.barHeight.m,
    padding: [layout.padding.inline.s, layout.padding.inline.l],
  },
})