import socket, { SendResponse } from 'socket.io-react'
import { Ranking } from '~/models'
import { BulkSelector, submitResultForResponse } from '~/stores'
import { ListPack } from './data/types'
import { register } from './support'

export class CompetitionStore {

  public setScores(competitionID: string, selector: BulkSelector, value: number) {
    const promise = socket.send('competition:scores:set', competitionID, selector, value)
    return promise.then(response => this.onScoreUpdateComplete(response))
  }

  public increaseScores(competitionID: string, selector: BulkSelector, value: number) {
    const promise = socket.send('competition:scores:increase', competitionID, selector, value)
    return promise.then(response => this.onScoreUpdateComplete(response))
  }

  public multiplyScores(competitionID: string, selector: BulkSelector, value: number) {
    const promise = socket.send('competition:scores:multiply', competitionID, selector, value)
    return promise.then(response => this.onScoreUpdateComplete(response))
  }

  private onScoreUpdateComplete = (response: SendResponse<ListPack<Ranking>>) => {
    return submitResultForResponse(response)
  }

  public async downloadRankings(competitionID: string) {
    const response = await socket.send('competition:download-rankings', competitionID)
    if (response.ok) {
      return new Blob([response.body])
    } else {
      return null
    }
  }

}

export default register(new CompetitionStore())