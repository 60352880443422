import { isArray } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { Document, DocumentOptions } from 'mobx-document'
import socket, { SocketOperation } from 'socket.io-react'
import { Model, ModelClass } from '~/models'
import { SubmitResult } from '~/ui/form'
import dataStore from '../dataStore'
import { submitResultForResponse } from '../support'
import {
  ActionOptions,
  AnyPack,
  BulkSelector,
  CopyResult,
  DuplicateOptions,
  Linkage,
  LongRunningActionOptions,
  Pack,
  Relationship,
  ShowParams,
} from './types'
import { modelToResource } from './util'

export default class ModelDocument<M extends Model> extends Document<M> {

  constructor(
    Model:    ModelClass<M>,
    id:       string,
    options:  DocumentOptions<M> = {},
  ) {
    super(id, options)
    this.Model = Model

    makeObservable(this)
  }

  public readonly Model: ModelClass<M>

  protected async performFetch(params: ShowParams) {
    const response = await socket.fetch('data:show', this.Model.resourceType, this.id, params)
    if (!response.ok) { return response }

    const pack = response.body as Pack<M>

    // Delegate storing to the database - this will store the returned data for this document, as well as all included data.
    // There is no need to return the data to the base Document class, as the dataStore will already hydrate this document.
    dataStore.storePack(pack)
  }

  protected onDidChange() {
    super.onDidChange()
    if (this.data == null) { return }

    // Some models store their relationships inside the model itself. Like Notification.
    if ('relationships' in this.data) {
      Object.assign(this.data, {
        relationships: this.relationships,
      })
    }
  }

  public async update(data: AnyObject, options: ActionOptions = {}) {
    const resource   = modelToResource(this.Model.resourceType, this.id, data)
    const response   = await socket.send('data:update', {data: resource}, options)
    if (response.ok) {
      const pack = response.body as Pack<M>
      dataStore.storePack(pack)
    }

    return submitResultForResponse(response)
  }

  //------
  // Actions

  public async callAction(name: string, pack: AnyPack = {data: null}, options: LongRunningActionOptions = {}) {
    const {timeout} = options

    const response = await socket.sendWithOptions({timeout}, 'data:document-action', this.Model.resourceType, this.id, name, pack, options)
    if (response.ok) {
      const pack = response.body as Pack<M>
      dataStore.storePack(pack)
    }

    return submitResultForResponse(response)
  }

  public async callBulkAction(name: string, selector: BulkSelector, options: LongRunningActionOptions = {}) {
    return this.callAction(name, selector, options)
  }

  public async duplicate(options: DuplicateOptions = {}): Promise<SubmitResult<CopyResult>> {
    const {
      timeout = false,
      onStart,
      onEnd,
      ...rest
    } = options

    const operation = new SocketOperation(socket, 'data:duplicate')
    onStart?.(operation)

    const response = await socket.sendWithOptions(
      {timeout, operation},
      'data:duplicate',
      this.Model.resourceType,
      {data: [{type: this.Model.resourceType, id: this.id}]},
      rest,
    )
    onEnd?.(operation)

    return submitResultForResponse(response)
  }

  public async delete(options: ActionOptions = {}) {
    const selector: BulkSelector = {
      data: [{
        type: this.Model.resourceType,
        id:   this.id,
      }],
      meta: {},
    }

    const response = await socket.send('data:delete', this.Model.resourceType, selector, options)
    if (response.ok) {
      const pack = response.body as Pack<M>
      dataStore.storePack(pack)
      dataStore.db(this.Model).delete(this.id)
      dataStore.emitAfterRemove(this.Model, selector)
    }

    return submitResultForResponse(response)
  }

  //------
  // Relationships

  @observable
  public relationships: Record<string, Relationship> = {}

  @action
  public storeRelationships(relationships: Record<string, Relationship>) {
    this.relationships = {
      ...this.relationships,
      ...relationships,
    }
  }

  public relationship(name: string): Relationship | null {
    return this.relationships[name] ?? null
  }

  public relationshipData<T extends string, ID = string>(name: string, expectPlural: false): Linkage<T, ID> | null
  public relationshipData<T extends string, ID = string>(name: string, expectPlural: true): Linkage<T, ID>[]
  public relationshipData<T extends string, ID = string>(name: string, expectPlural: boolean): Linkage<T, ID>[] | Linkage<T, ID> | null
  public relationshipData(name: string, expectPlural: boolean) {
    const data = this.relationship(name)?.data
    if (data == null) {
      return expectPlural ? [] : null
    } else if (expectPlural && !isArray(data)) {
      console.warn(`Relationship \`${name}\`: expected array`)
      return []
    } else {
      return data
    }
  }

}
