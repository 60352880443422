import React from 'react'
import { range } from 'lodash'
import { BrandedComponentSpec } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, Label, VBox } from '~/ui/components'
import {
  colors,
  createUseStyles,
  layout,
  shadows,
  ThemeProvider,
  ThemeProviderProps,
  TimelineBranding,
} from '~/ui/styling'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const TimelineBrandingPreview = memo('TimelineBrandingPreview', (props: Props) => {

  const {componentName, variant, spec} = props
  const {guide} = useBranding()

  const [component, flags, size] = useComponentBranding<TimelineBranding>(componentName, variant, spec)

  const tickColor       = guide.colors.resolve(component.resolve('tickColor', flags))
  const activeTickColor = guide.colors.resolve(component.resolve('activeTickColor', flags))
  const nowPlayingColor = guide.colors.resolve(component.resolve('nowPlayingColor', flags))

  //------
  // Rendering

  const $ = useStyles(component)

  function render() {
    return (
      <VBox style={{...size}}>
        <HBox flex='grow' classNames={$.container} align='stretch'>
          <VBox flex>
            {renderItemBar(true)}
          </VBox>
          <VBox flex>
            {renderItemBar(false)}
          </VBox>
        </HBox>
        {renderAxis()}
      </VBox>
    )
  }

  function renderItemBar(nowPlaying: boolean) {
    const themeOverrides: ThemeProviderProps['overrides'] = {
      fg: {
        normal: nowPlaying ? nowPlayingColor : undefined,
      },
    }

    return (
      <BrandedComponent
        guide={guide ?? undefined}
        branding={component.itemBar}
        height={component.itemBar.previewSize.height}
        variant={{nowplaying: nowPlaying}}
        flex
      >
        <ThemeProvider overrides={themeOverrides}>
          <VBox flex classNames={$.content} gap={layout.padding.inline.xs}>
            <Label light tiny caption dim>
              {nowPlaying ? "NOW PLAYING" : '16:30'}
            </Label>
            <Label h3>
              Title
            </Label>
            <Label small dim>
              Detail
            </Label>
          </VBox>
        </ThemeProvider>
      </BrandedComponent>
    )
  }

  function renderAxis() {
    return (
      <HBox>
        {range(11, 14).map(hour => (
          renderAxisLabel(hour.toString(), hour === 11)
        ))}
      </HBox>
    )
  }

  function renderAxisLabel(label: string, now: boolean) {
    const color = guide.colors.resolve(now ? activeTickColor : tickColor)

    return (
      <VBox key={label} classNames={[$.axisLabel, {now}]} flex>
        <Label
          caption
          tiny
          bold={now}
          color={color}
          children={label}
        />
      </VBox>
    )
  }
  return render()

})

registerComponentBrandingPreview('web.timeline', TimelineBrandingPreview)
export default TimelineBrandingPreview

const useStyles = createUseStyles(theme => ({
  container: (branding: StyleProps) => {
    const depth   = branding.resolve('depth')

    return {
      margin:     [0, -layout.padding.inline.l - 2],
      padding:    [depth > 0 ? layout.padding.inline.m : 0, layout.padding.inline.l + 2],
      background: depth > 0 ? (theme.isDark ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)') : undefined,
      boxShadow:  shadows.depth(-depth / 2),
    }
  },

  content: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  axisLabel: (branding: StyleProps) => ({
    borderLeft:  [1, 'solid', theme.colors.resolve(branding.resolve('tickColor'))],
    boxShadow:   ['inset', 2, 1, 0, -1, colors.white],
    paddingTop:  layout.padding.inline.s,
    paddingLeft: layout.padding.inline.s,

    '&.now': {
      borderColor: theme.colors.resolve(branding.resolve('activeTickColor')),
    },
  }),
}))

type StyleProps = TimelineBranding