import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { useBoundingRectangle } from 'react-measure'
import config from '~/config'
import { isNode } from '~/models'
import { observer } from '~/ui/component'
import { Center, Spinner, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import PlanRuleResultList from '../PlanRuleResultList'
import SelectionSummary from '../SelectionSummary'
import CanvasControls from './CanvasControls'
import ConnectLayer from './ConnectLayer'
import ContentLayer from './ContentLayer'
import { ResetCanvasIf, useCanvas } from './FlowPlannerCanvasContext'
import { useCanvasPan, useCanvasZoom, useModeToggleKeys } from './hooks'
import InsightsLayer from './InsightsLayer'
import InteractionLayer from './InteractionLayer'
import { useSelection } from './SelectionContext'
import SelectionLayer from './SelectionLayer'

export interface Props {
  requestCreateComponent:  ((point: Point) => any) | null
  suspendCreatePoint: boolean
}

const FlowPlannerCanvas = observer('FlowPlannerCanvas', (props: Props) => {

  const {requestCreateComponent, suspendCreatePoint} = props

  const {
    mode,
    setMode,
    viewport,
    setCanvasRect,
    setViewport,
    fitCanvas,
    zoomIn,
    zoomOut,
  } = useCanvas.unoptim()

  const {manager: selectionManager} = useSelection()
  const {planner} = useFlowPlanner()

  const starting = planner.service.starting
  const modified = planner.service.modified

  //------
  // Pan & zoom

  const [origin, connectPan, connectLayer] = useCanvasPan({
    enabled: mode === 'pan',

    origin: viewport.origin,
    zoom:   viewport.zoom,

    onPan:  point => {
      setViewport({...viewport, origin: point})
    },
  })

  const [connectZoom] = useCanvasZoom({
    zoom:   viewport.zoom,
    onZoom: zoom => {
      setViewport({...viewport, zoom})
    },
  })

  //------
  // Keyboard

  // Mode toggles use a keydown/keyup handler as opposed to a hotkey.
  useModeToggleKeys()

  useHotkey('Short+Alt+0', React.useCallback(() => { fitCanvas() }, [fitCanvas]))
  useHotkey('Short+Alt+=', React.useCallback(() => { zoomIn() }, [zoomIn]))
  useHotkey('Short+Alt+-', React.useCallback(() => { zoomOut() }, [zoomOut]))

  useHotkey('Short+A',       React.useCallback(() => { selectionManager?.selectAll() }, [selectionManager]))
  useHotkey('Short+Shift+A', React.useCallback(() => { selectionManager?.deselectAll() }, [selectionManager]))

  useHotkey('C', React.useCallback(() => {
    if (mode === 'connect') {
      setMode('select')
    } else {
      setMode('connect')
    }
  }, [mode, setMode]))

  useHotkey('A', React.useCallback(() => {
    if (modified) { return }

    const components = selectionManager?.selectedComponents ?? []
    const nodes      = components.filter(isNode)
    if (nodes.length === 0) { return }

    planner.setActivationNodeUUIDs(nodes.map(it => it.uuid))
  }, [modified, planner, selectionManager?.selectedComponents]))

  //------
  // Layout

  const ref               = React.useRef<HTMLDivElement>(null)
  const connectPanAndZoom = connectZoom(connectPan(ref))
  const panLayerRef       = React.useRef<HTMLDivElement>(null)
  const connectPanLayer   = connectLayer(panLayerRef)
  const [canvasRect, setCanvasRectState] = React.useState<LayoutRect>({left: 0, top: 0, width: 0, height: 0})

  useBoundingRectangle(ref, {throttle: 500}, rect => {
    setCanvasRectState(rect)
    setCanvasRect(rect)
  })

  React.useEffect(() => {
    if (planner.plan == null) { return }
    fitCanvas({
      if: ResetCanvasIf.DEFAULT | ResetCanvasIf.OUT_OF_BOUNDS,
    })
  }, [fitCanvas, planner.plan])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const transform   = canvasTransform()
    const bgTransform = backgroundTransform()

    return (
      <div
        ref={connectPanAndZoom}
        classNames={$.canvas}
      >
        <div classNames={$.background}>
          <div style={{transform: bgTransform}}/>
        </div>

        <InteractionLayer
          transform={transform}
          requestCreateComponent={requestCreateComponent}
          suspendCreatePoint={suspendCreatePoint}
        />
        <ContentLayer
          transform={transform}
        />
        <ConnectLayer
          enabled={mode === 'connect'}
          transform={transform}
          zoom={viewport.zoom}
        />
        <SelectionLayer
          transform={transform}
          zoom={viewport.zoom}
        />
        <InsightsLayer
          transform={transform}
        />
        <div
          ref={connectPanLayer}
          classNames={[$.panLayer, {active: mode === 'pan'}]}
        />
        {renderControls()}
        {renderSelectionSummary()}
        {starting && renderLoadingLayer()}
      </div>
    )
  }

  function renderControls() {
    return (
      <VBox gap={layout.padding.s} classNames={$.controls} align='center'>
        <CanvasControls/>
        <PlanRuleResultList/>
      </VBox>
    )
  }

  function renderSelectionSummary() {
    return (
      <VBox classNames={$.selectionSummary}>
        <SelectionSummary/>
      </VBox>
    )
  }

  function renderLoadingLayer() {
    return (
      <Center classNames={$.loading}>
        <Spinner/>
      </Center>
    )
  }

  function canvasTransform() {
    // 1. First, translate the origin of the viewport to the center of the screen.
    // 2. Then, apply zoom.
    // 3. Then, translate the origin of the viewport back to the top left corner.
    // 4. Finally, apply viewport translate.

    const width  = canvasRect?.width ?? 0
    const height = canvasRect?.height ?? 0
    return [
      `translate(${width / 2}px, ${height / 2}px)`,
      `scale(${viewport.zoom})`,
      `translate(${-width / 2}px, ${-height / 2}px)`,
      `translate(${origin.x}px, ${origin.y}px)`,
    ].join(' ')
  }

  function backgroundTransform() {
    return [
      `scale(${viewport.zoom})`,
      `translate(${origin.x % gridSize}px, ${origin.y % gridSize}px)`,
    ].join(' ')
  }

  return render()

})

export default FlowPlannerCanvas

const gridSize = config.planner.gridSize
const backgroundImage = 'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACPTkDJAAAARUlEQVRYCe3QMQoAIAgAQOlL/f9t1dDqqDicIIKC4kVE7J+v9MfqP5lcPD+ScVl7jkDZixYTIECAAAECBAgQIECAwHSBC9HKCGNcqYgkAAAAAElFTkSuQmCC)'

const useStyles = createUseStyles({
  canvas: {
    flex: [1, 0, 0],

    position: 'relative',
    overflow: 'hidden',

    willChange: ['background-position'],

    '&:focus': {
      outline: 'none',
    },
  },

  background: {
    ...layout.overlay,

    '& > *': {
      position: 'absolute',
      top:      -100 * gridSize,
      left:     -100 * gridSize,
      right:    -100 * gridSize,
      bottom:   -100 * gridSize,

      backgroundImage:  backgroundImage,
      backgroundSize:   [gridSize, gridSize],
      backgroundRepeat: 'repeat',
    },
  },

  panLayer: {
    ...layout.overlay,
    cursor: 'grab',

    '&:not(.active)': {
      pointerEvents: 'none',
    },
  },

  controls: {
    position: 'absolute',
    top:      0,
    right:    0,

    ...layout.responsiveProp({
      padding: layout.padding.m,
    }),
  },

  selectionSummary: {
    position: 'absolute',
    bottom:   0,
    right:    0,

    ...layout.responsiveProp({
      margin: layout.padding.m,
    }),
  },

  loading: {
    ...layout.overlay,
    pointerEvents: 'none',
  },
})