import React from 'react'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import FeedbackButtonsField from './FeedbackButtonsField'

const ButtonFeedbackFields = memo('ButtonFeedbackFields', () => {
  return (
    <VBox gap={layout.padding.s}>
      <FormField name='buttons' required>
        {bind => <FeedbackButtonsField {...bind}/>}
      </FormField>
    </VBox>
  )
})

export default ButtonFeedbackFields