import React from 'react'
import { useTranslation } from 'react-i18next'
import { Script } from '~/models'
import { memo } from '~/ui/component'
import { FormField } from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'

const ScriptTriggerFields = memo('ScriptTriggerFields', () => {

  const [t] = useTranslation('flow_planner')

  return (
    <FormField
      name='script'
      caption={t('triggers.script.fields.script.caption')!}
      instruction={t('triggers.script.fields.script.instruction')!}
      placeholder={t('triggers.script.fields.script.placeholder')!}
    >
      {bind => (
        <ResourceField
          {...bind}
          Model={Script}
        />
      )}
    </FormField>
  )

})

export default ScriptTriggerFields