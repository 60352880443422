export function resourceListPath(resourceType: string, label?: string) {
  if (label == null) {
    return `/${resourceType}`
  } else {
    return `/${resourceType}/${label}`
  }
}

export function resourceDetailPath(resourceType: string, id: string, body?: string) {
  return `/${resourceType}/-/${id}${body == null ? '' : `/${body}`}`
}