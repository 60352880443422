import { PlannerAction } from '~/models'
import { DynamicFormSchema } from '~/ui/app/dynamic-form'

export interface PlannerActionFieldsetProps {
  action: PlannerAction
  schema: DynamicFormSchema
  params: Record<string, any>
}

const registry = new Map<string, React.ComponentType<PlannerActionFieldsetProps>>()

export function getPlannerActionFieldsComponent(action: string) {
  for (const [name, Component] of registry) {
    if (name === action) {
      return Component
    }
  }

  return null
}

export function register(action: string, target: React.ComponentType<PlannerActionFieldsetProps>, FormModel?: PlannerAction) {
  registry.set(action, target)
}