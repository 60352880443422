import { uniq } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { Group, Participant } from '~/models'
import { dataStore } from '~/stores'
import { SubmitResult } from '~/ui/form'

export default class GroupsFormModel {

  constructor(
    private readonly participant: Participant,
    private readonly tags: string[],
  ) {
    makeObservable(this)
  }

  @computed
  public get listGroups() {
    const groups: Group[] = []
    for (const id of this.participant.groups) {
      const group = dataStore.get(Group, id)
      if (group != null) {
        groups.push(group)
      }
    }

    return groups
  }

  @observable
  public byTag: Record<string, string[]> = this.tags.reduce((byTag, tag) => {
    return {
      ...byTag,
      [tag]: this.listGroups.filter(group => group.tags.includes(tag)).map(group => group.id),
    }
  }, {})

  @observable
  public otherGroups: string[] = this.listGroups
    .filter(group => group.tags.length === 0)
    .map(group => group.id)

  public getValue(field: string) {
    if (/^tag:(.*)$/.test(field)) {
      return this.byTag[RegExp.$1] ?? []
    } else {
      return (this as any)[field]
    }
  }

  @action
  public assign(data: AnyObject) {
    for (const [field, value] of Object.entries(data)) {
      if (/^tag:(.*)$/.test(field)) {
        this.byTag[RegExp.$1] = value
      } else {
        (this as any)[field] = value
      }
    }
  }

  public async submit(): Promise<SubmitResult> {
    const allGroupIDs: string[] = []
    for (const groups of Object.values(this.byTag)) {
      const newGroups = uniq(groups.filter(g => !allGroupIDs.includes(g)))
      allGroupIDs.push(...newGroups)
    }
    allGroupIDs.push(...this.otherGroups)

    const document = dataStore.document(Participant, this.participant.id)
    return document.update({groups: allGroupIDs})
  }

}