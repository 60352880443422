import React from 'react'
import { RouteComponentProps } from 'react-router'
import { upperFirst } from 'lodash'
import { Branding } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { useModelEndpointData } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { BrandingGuide, useStyling } from '~/ui/styling'
import BrandingContainer from './BrandingContainer'
import BrandingContext from './BrandingContext'

export interface BrandingParams {
  view: View
}

export type View = 'components' | 'assets'

const BrandingScreen = observer('BrandingScreen', (props: RouteComponentProps<BrandingParams>) => {

  const {singular} = useResourceTranslation('brandings')
  const breadcrumbs = React.useMemo((): Breadcrumb[] => [{
    icon:    'palette',
    caption: upperFirst(singular()),
    href:    '/branding',
  }], [singular])

  const project = projectStore.project
  const [brandings, {fetchStatus}] = useModelEndpointData(Branding, {
    filters: {id: project?.id ?? '----'},
  })

  const [fetching, setFetching] = React.useState<boolean>(true)

  React.useEffect(() => {
    if (fetchStatus === 'done') {
      setFetching(false)
    }
  }, [fetchStatus])

  const {guide} = useStyling()
  const branding = React.useMemo(() => {
    if (brandings.length > 0) {
      return brandings[0]
     } else if (project != null) {
       return Branding.default(project, guide)
     }
  }, [brandings, guide, project])

  const context = React.useMemo(() => branding == null ? null : ({
    branding: branding,
    guide:    BrandingGuide.deserialize(branding),
  }), [branding])

  //------
  // Rendering

  function render() {
    return (
      <>
        <AppLayoutConfig
          configKey='branding'
          breadcrumbs={breadcrumbs}
        />

        <ResourceTypeProvider resourceType='brandings'>
          <BrandingContext.Provider value={context}>
            <BrandingContainer
              fetching={fetching}
              working={fetchStatus === 'fetching'}
            />
          </BrandingContext.Provider>
        </ResourceTypeProvider>
      </>
    )
  }

  return render()

})

export default BrandingScreen