import React from 'react'
import { memo } from '~/ui/component'
import { ClearButton, HBox, SVG, VBox } from '~/ui/components'
import { FormError } from '~/ui/form'
import { makeFieldChangeCallback } from '~/ui/form/hooks'
import { layout, useStyling } from '~/ui/styling'
import { CollectionRowFieldBindProps, ItemFormProps } from './types'

export interface Props<T> {
  item:  T
  index: number

  getValue?:  (item: T, key: string) => any
  setValue?:  (item: T, key: string, value: any) => T
  getErrors?: (index: number, key: string) => FormError[]

  renderItem?: (item: T) => React.ReactNode
  renderForm?: (props: ItemFormProps<T>) => React.ReactNode

  requestAdd?:     (item: T) => any
  requestEdit?:    (index: number, item: T) => any
  requestReplace?: (index: number, item: T) => any
  requestRemove?:  (index: number) => any

  requestFocus?:   (delta: number) => boolean
  onCommit?:       (index: number) => boolean

  onPasteLines?:   (index: number, lines: string[]) => any

  enabled?:  boolean
  readOnly?: boolean
  sortHandleClassName?: string
}

const _CollectionFieldRow = <T extends {}>(props: Props<T>) => {

  const {
    item,
    index,
    getValue  = (item, key)        => key === '$' ? item : (item as any)[key],
    setValue  = (item, key, value) => key === '$' ? value : ({...item, [key]: value}),
    getErrors = (item, key)        => [],
    renderItem,
    renderForm,
    requestAdd,
    requestEdit,
    requestReplace,
    requestRemove,
    requestFocus,
    onCommit,
    onPasteLines,
    enabled = true,
    readOnly = false,
    sortHandleClassName,
  } = props

  const makeFormChangeHandler = React.useCallback(((key: string) => {
    return (value: any) => {
      const nextValue = setValue(item, key, value)
      requestReplace?.(index, nextValue)
    }
  }), [index, item, requestReplace, setValue])

  const add = React.useCallback(() => {
    requestAdd?.(item)
  }, [item, requestAdd])

  const edit = React.useCallback(() => {
    requestEdit?.(index, item)
  }, [index, item, requestEdit])

  const remove = React.useCallback(() => {
    requestRemove?.(index)
  }, [index, requestRemove])

  const commit = React.useCallback((_, event: React.SyntheticEvent) => {
    if (onCommit?.(index)) {
      event.preventDefault()
    }
  }, [index, onCommit])

  const formProps = React.useMemo((): ItemFormProps<T> => ({
    item,
    index,
    bind: (key: string): CollectionRowFieldBindProps<any> => ({
      value:    getValue(item, key),
      onChange: makeFieldChangeCallback(makeFormChangeHandler(key)),
      onCommit: commit,
      invalid:  getErrors(index, key).length > 0,
      errors:   getErrors(index, key),
      enabled,
      readOnly,
    }),
  }), [commit, enabled, getErrors, getValue, index, item, makeFormChangeHandler, readOnly])

  //------
  // Keyboard navigation

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    const focus = (index: number) => {
      if (requestFocus?.(index)) {
        event.preventDefault()
      }
    }

    switch (event.key) {
      case 'ArrowUp':   return focus(index - 1)
      case 'ArrowDown': return focus(index + 1)
    }
  }, [index, requestFocus])

  //------
  // Clipboard

  const handlePaste = React.useCallback((event: React.ClipboardEvent) => {
    // Only run this if there is a line break. Otherwise, let the fields handle the paste.
    const text = event.clipboardData.getData('text/plain')
    if (!text.includes('\n')) { return }

    const lines = text.split('\n').map(line => line.trim()).filter(Boolean)
    onPasteLines?.(index, lines)
    event.preventDefault()
  }, [index, onPasteLines])

  //------
  // Rendering

  const {colors} = useStyling()

  function render() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex onKeyDown={handleKeyDown} onPasteCapture={handlePaste}>
          {renderItem?.(item)}
          {renderForm?.(formProps)}
        </VBox>
        {renderRowActions()}
      </HBox>
    )
  }

  function renderRowActions() {
    return (
      <HBox gap={layout.padding.inline.s}>
        {requestAdd == null && sortHandleClassName != null && (
          <SVG
            name='sort-handle'
            classNames={sortHandleClassName}
            size={layout.icon.m}
            dim
          />
        )}
        {requestAdd != null && sortHandleClassName != null && (
          <SVG
            name='empty'
            size={layout.icon.m}
            dim
          />
        )}
        {requestAdd != null && (
          <ClearButton
            icon='plus-circle'
            onTap={add}
            color={colors.semantic.positive}
            padding='both'
            enabled={enabled && !readOnly}
          />
        )}
        {requestAdd == null && requestEdit != null && (
          <ClearButton
            icon='pencil'
            onTap={edit}
            padding='both'
            enabled={enabled && !readOnly}
          />
        )}
        {requestRemove != null && (
          <ClearButton
            icon='minus-circle'
            onTap={remove}
            color={colors.semantic.negative}
            padding='both'
            enabled={enabled && !readOnly}
          />
        )}
      </HBox>
    )
  }

  return render()

}

const CollectionFieldRow = memo('CollectionFieldRow', _CollectionFieldRow) as typeof _CollectionFieldRow
export default CollectionFieldRow