import React from 'react'
import { useLocation } from 'react-router-dom'
import { Dashboard } from '~/models'
import { NavigationItem } from '~/navigation.yml'
import { authenticationStore } from '~/stores'
import CreateDashboardForm from '~/ui/app/dashboard/CreateDashboardForm'
import DashboardsDialog from '~/ui/app/dashboard/DashboardsDialog'
import useDashboards from '~/ui/app/dashboard/useDashboards'
import { observer } from '~/ui/component'
import { PopupMenu, PopupMenuItem, PopupMenuProps } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { registerNavigationDetailMenu } from './registry'

export interface Props extends Omit<PopupMenuProps<any>, 'items'> {
  item: NavigationItem
}

const DashboardNavigationDetailMenu = observer('DashboardNavigationDetailMenu', (props: Props) => {

  const {item, ...rest} = props

  const {t, actionCaption} = useResourceTranslation('dashboards')
  const {colors}           = useStyling()

  const location = useLocation()

  const [dashboardsDialogOpen, openDashboardsDialog, closeDashboardsDialog] = useBoolean()
  const [createDashboardFormOpen, openCreateDashboardForm, closeCreateDashboardForm] = useBoolean()

  const [dashboards, endpoint] = useDashboards()

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])


  const myID = authenticationStore.userID

  const menuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    let currentSection = ''
    let nextSection = ''

    const sorted = [...dashboards].sort((a, b) => {
      if (a.default !== b.default) {
        return a.default ? -1 : 1
      } else if (a.shared !== b.shared) {
        return a.shared ? 1 : -1
      } else {
        return a.name.localeCompare(b.name)
      }
    })

    const addItemForDashboard = (dashboard: Dashboard) => {
      if (nextSection !== currentSection) {
        items.push({section: t(nextSection)})
        currentSection = nextSection
      }
      items.push({
        icon:    'gauge',
        caption: dashboard.name,
        href:    `/dashboard/-/${dashboard.id}`,
        checked: location.pathname === `/dashboard/-/${dashboard.id}`,
      })
    }

    for (const dashboard of sorted) {
      if (dashboard.default) {
        nextSection = 'default'
      } else if (dashboard.user === myID) {
        nextSection = 'mine'
      } else {
        nextSection = 'shared'
      }

      addItemForDashboard(dashboard)
    }

    items.push({section: '-'})

    items.push({
      icon:     'plus',
      caption:  t('new'),
      color:    colors.semantic.positive,
      onSelect: openCreateDashboardForm,
    })

    items.push({
      icon:     'unordered-list',
      caption:  actionCaption('manage'),
      color:    colors.semantic.primary,
      onSelect: openDashboardsDialog,
    })

    return items
  }, [actionCaption, colors.semantic.positive, colors.semantic.primary, dashboards, location.pathname, myID, openCreateDashboardForm, openDashboardsDialog, t])

  return (
    <>
      <PopupMenu
        items={menuItems}
        onWillOpen={fetch}
        {...rest}
      />
      <CreateDashboardForm
        open={createDashboardFormOpen}
        requestClose={closeCreateDashboardForm}
      />
      <DashboardsDialog
        open={dashboardsDialogOpen}
        requestClose={closeDashboardsDialog}
      />
    </>
  )
})

registerNavigationDetailMenu('dashboard', DashboardNavigationDetailMenu)