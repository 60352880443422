import React from 'react'
import { useTranslation } from 'react-i18next'
import { Variant } from '~/models'
import { HBox, Label, LabelProps, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<LabelProps, 'value' | 'onChange' | 'children'> {
  value:  Variant | null
}

const VariantField = (props: Props) => {

  const {value, classNames, ...rest} = props

  const text = React.useMemo(
    () => Variant.stringify(value),
    [value],
  )
  const type = React.useMemo(
    () => Variant.type(value),
    [value],
  )

  const {t} = useTranslation('variant')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.s} classNames={classNames}>
        <VBox flex='both'>
          <Label
            children={text}
            {...rest}
          />
        </VBox>
        {renderTypeAccessory()}
      </HBox>
    )
  }

  function renderTypeAccessory() {
    return (
      <VBox classNames={$.typeAccessory}>
        <Label small dim>
          {t(`type.${type}`)}
        </Label>
      </VBox>
    )
  }

  return render()

}

export default VariantField

const useStyles = createUseStyles(theme => ({
  typeAccessory: {
    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    borderRadius: layout.radius.m,
    background:   theme.bg.subtle,
  },
}))