import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useOnDemandService } from 'socket.io-react'
import { Dashboard } from '~/models'
import { analyticsStore, dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { AnalyticsServiceProvider } from './AnalyticsServiceContext'
import DashboardActions from './DashboardActions'
import DashboardContainer from './DashboardContainer'
import { DashboardContextContainer, useDashboardContext } from './DashboardContext'
import { DashboardLayoutContainer } from './DashboardLayoutContext'
import useDashboards from './useDashboards'

export interface DashboardParams {
  dashboardID: string | undefined
}

export type Props = RouteComponentProps<DashboardParams>

const DashboardScreen = observer('DashboardScreen', (props: Props) => {

  const {dashboardID}    = props.match.params

  const [dashboards] = useDashboards(true)

  const dashboard         = dashboardID == null ? null : dashboards.find(it => it.id === dashboardID)
  const actualDashboardID = dashboard?.id ?? null
  const defaultDashboard  = dashboards.find(it => it.default)

  const history = useHistory()

  React.useEffect(() => {
    if (dashboard != null) { return }

    if (defaultDashboard != null) {
      history.replace(`/dashboard/-/${defaultDashboard.id}`)
    }
  }, [dashboard, dashboardID, defaultDashboard, history])

  //------
  // Service

  const service = React.useMemo(
    () => actualDashboardID == null ? null : analyticsStore.analyticsService(actualDashboardID),
    [actualDashboardID],
  )

  useOnDemandService(service)

  //------
  // Rendering

  function render() {
    return (
      <AnalyticsServiceProvider service={service}>
        <DashboardContextContainer>
          <DashboardLayoutContainer>
            <DashboardAppLayoutConfig/>
            <DashboardContainer/>
          </DashboardLayoutContainer>
        </DashboardContextContainer>
      </AnalyticsServiceProvider>
    )
  }

  return render()

})

const DashboardAppLayoutConfig = observer('DashboardAppLayoutConfig', () => {

  const {editing, startEditing, stopEditing, saveDashboard} = useDashboardContext()

  const {params}   = useRouteMatch<DashboardParams>()
  const dashboard  = params.dashboardID == null ? null : dataStore.get(Dashboard, params.dashboardID)

  //------
  // Breadcrumbs & actions

  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({
      name: 'dashboard',
    })

    if (dashboard != null) {
      breadcrumbs.push({
        caption: dashboard.name,
        href:    `/dashboard/-/${dashboard.id}`,
        detail:  'dashboard',
      })
    }

    return breadcrumbs
  }, [dashboard])

  //------
  // Rendering

  function render() {
    return (
      <AppLayoutConfig
        configKey='dashboard'
        breadcrumbs={breadcrumbs}
        ActionsComponent={renderActions}
      />
    )
  }

  const renderActions = React.useCallback(() => {
    if (dashboard == null) { return null }

    return (
      <DashboardActions
        dashboard={dashboard}
        editing={editing}
        startEditing={startEditing}
        stopEditing={stopEditing}
        requestSave={saveDashboard}
      />
    )

  }, [dashboard, editing, saveDashboard, startEditing, stopEditing])

  return render()

})

export default DashboardScreen