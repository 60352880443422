import { FetchStatus } from 'mobx-document'
import {
  Challenge,
  ChallengeTask,
  Feedback,
  messageSummary,
  Review,
  Script,
  ScriptMessage,
} from '~/models'
import { ModelDocumentOptions, useModelDocumentData } from '~/ui/hooks/data'

export function useReviewQuestion(review: Review | null, options: ModelDocumentOptions = {}): [ReviewQuestionDescriptor | null, FetchStatus] {
  const questionRef   = review?.question
  const questionModel = questionRef?.model
  const challengeID   = questionModel?.model === 'Challenge' ? questionModel.id : null
  const scriptID      = questionModel?.model === 'Script' ? questionModel.id : null

  const [challenge, rest_challenge] = useModelDocumentData(Challenge, challengeID, options)
  const [script, rest_script]       = useModelDocumentData(Script, scriptID, options)

  const challengeTask = challenge?.tasks?.find(task => task.uuid === questionRef?.uuid)
  const scriptMessage = script?.messages()?.find(message => message.uuid === questionRef?.uuid)

  if (challengeTask != null && challengeTask.question != null) {
    return [
      {
        model:    challenge!,
        question: challengeTask,
        text:     [challengeTask.title, challengeTask.subtitle].filter(Boolean).join(' - '),
        feedback: challengeTask.question,
      },
      rest_challenge.fetchStatus,
    ]
  } else if (scriptMessage != null && scriptMessage.feedback != null) {
    return [
      {
        model:    script!,
        question: scriptMessage,
        feedback: scriptMessage.feedback,
        text:     messageSummary(scriptMessage),
      },
      rest_script.fetchStatus,
    ]
  } else {
    return [null, 'done']
  }
}

export interface ReviewQuestionDescriptor {
  model:    Challenge | Script
  question: ChallengeTask | ScriptMessage
  feedback: Feedback
  text:     string
}