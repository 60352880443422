import { makeObservable, observable } from 'mobx'
import { ProxyFormModel, SubmitResult } from '~/ui/form'

export default class ResourceCollectionFiltersFormModel implements ProxyFormModel<Record<string, any>> {

  constructor(
    filters: Record<string, any>,
    private readonly update: (filters: Record<string, any>) => any,
  ) {
    this.filters = new Map(Object.entries(filters))
    makeObservable(this)
  }

  @observable
  public filters: Map<string, any>

  public getValue(name: string) {
    return this.filters.get(name)
  }

  public assign(data: AnyObject) {
    for (const [name, value] of Object.entries(data)) {
      this.filters.set(name, value)
    }
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const filters = Object.fromEntries(this.filters.entries())
    return await this.update(filters)
  }

}