import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '~/models'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormField, FormSwitch } from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

const GroupTriggerFields = memo('GroupTriggerFields', () => {

  const [t] = useTranslation('flow_planner')

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <FormSwitch name='groupOption' autoFocus={false}>
          <FormSwitch.TypeOption
            type='any'
            {...t('triggers.group.switch.any')}
          />
          <FormSwitch.TypeOption
            type='group'
            {...t('triggers.group.switch.group')}
            renderForm={renderGroupField}
          />
          <FormSwitch.TypeOption
            type='tags'
            {...t('triggers.group.switch.tags')}
            renderForm={renderTagsField}
          />
        </FormSwitch>
      </VBox>
    )
  }

  function renderGroupField() {
    return (
      <FormField
        name='group'
        caption={t('triggers.group.fields.group.caption')!}
        placeholder={t('triggers.group.fields.group.placeholder')!}
      >
        {bind => (
          <ResourceField
            {...bind}
            Model={Group}
          />
        )}
      </FormField>
    )
  }

  function renderTagsField() {
    return (
      <FormField
        name='tags'
        caption={t('triggers.group.fields.tags.caption')!}
        instruction={t('triggers.group.fields.tags.instruction')!}
        placeholder={t('triggers.group.fields.tags.placeholder')!}
      >
        {bind => <TagsField Model={Group} {...bind}/>}
      </FormField>
    )
  }

  return render()

})

export default GroupTriggerFields