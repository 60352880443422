import React from 'react'
import { LatLongLocation, Participant } from '~/models'
import { ModelEndpoint } from '~/stores'
import { boundsWithCenterAndRadius, GoogleMap, GoogleMapManager } from '~/ui/app/google-maps'
import { observer } from '~/ui/component'
import { Center, SegmentedButton, VBox } from '~/ui/components'
import { ButtonSegment } from '~/ui/components/SegmentedButton'
import { assignRef } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import LocationMapPin from '../LocationMapPin'
import LocationParticipantList from './LocationParticipantList'

export interface Props {
  location:             LatLongLocation
  participantsEndpoint: ModelEndpoint<Participant>
  mapManagerRef?:       React.Ref<GoogleMapManager>
}

const LatLongLocationBody = observer('LatLongLocationBody', (props: Props) => {

  const {location, participantsEndpoint, mapManagerRef: props_mapManagerRef} = props
  const {t} = useResourceTranslation()

  const mapBounds = React.useMemo(() => {
    const {coordinate, radius} = location
    return boundsWithCenterAndRadius(coordinate, radius)
  }, [location])

  const buttonSegments = React.useMemo((): ButtonSegment<LatLongLocationBodyTab>[] => [{
    value:   'map',
    icon:    'map',
    caption: t('tabs.map'),
  }, {
    value:   'participants',
    icon:    'participant',
    caption: t('tabs.participants'),
  }], [t])

  const [currentTab, setCurrentTab] = React.useState<LatLongLocationBodyTab>('map')

  const mapManagerRef = React.useRef<GoogleMapManager>(null)

  const connectMapManager = React.useCallback((manager: GoogleMapManager) => {
    assignRef(mapManagerRef, manager)
    if (props_mapManagerRef != null) {
      assignRef(props_mapManagerRef, manager)
    }
  }, [props_mapManagerRef])

  //------
  // Map actions

  React.useEffect(() => {
    mapManagerRef.current?.fitBounds(mapBounds, {
      animated: true,
    })

  }, [mapBounds])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        {renderBodyToggleButton()}
        {renderBodyContent()}
      </VBox>
    )
  }

  function renderBodyToggleButton() {
    return (
      <Center>
        <SegmentedButton
          segments={buttonSegments}
          selectedValue={currentTab}
          onChange={setCurrentTab}
        />
      </Center>
    )
  }

  function renderBodyContent() {
    return (
      <VBox flex classNames={$.bodyContent}>
        {renderMap()}
        {renderParticipantsList()}
      </VBox>
    )
  }

  function renderMap() {
    return (
      <VBox classNames={[$.bodyTab, {current: currentTab === 'map'}]}>
        <GoogleMap
          initialBounds={mapBounds}
          classNames={$.map}
          children={renderMapPin()}
          managerRef={connectMapManager}
          centerOnClick={false}
          options={{
            draggable:   false,
            zoomControl: false,
          }}
          flex
        />
      </VBox>
    )
  }

  function renderMapPin() {
    return (
      <LocationMapPin
        location={location}
        count={participantsEndpoint.count ?? location.participantCount}
        tint='primary'
      />
    )
  }

  function renderParticipantsList() {
    return (
      <VBox classNames={[$.bodyTab, {current: currentTab === 'participants'}]}>
        <LocationParticipantList
          location={location}
          endpoint={participantsEndpoint}
        />
      </VBox>
    )
  }

  return render()

})

export type LatLongLocationBodyTab = 'map' | 'participants'

export default LatLongLocationBody

const useStyles = createUseStyles({
  bodyContent: {
    position: 'relative',
  },

  bodyTab: {
    ...layout.overlay,

    '&:not(.current)': {
      visibility: 'hidden',
    },
  },

  map: {
    borderRadius: layout.radius.s,
    boxShadow:    shadows.depth(1),
    overflow:     'hidden',
  },
})