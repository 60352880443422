import React from 'react'
import { getClientPoint, isRightMouse } from 'react-dnd'
import { useHotkey } from 'react-hotkeys'
import { useTimer } from 'react-timer'
import { pick, some } from 'lodash'
import { objectEquals } from 'ytil'
import {
  isAnnotation,
  isNode,
  isSegue,
  nodeHasTriggerable,
  PlanComponent,
  TriggerableNode,
} from '~/models'
import { memo } from '~/ui/component'
import { useBoolean, useContinuousRef } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { closest, isInteractiveElement } from '~/ui/util'
import AnnotationView from '../annotations/AnnotationView'
import { useFlowPlanner } from '../FlowPlannerContext'
import NodeView from '../nodes/NodeView'
import SegueView from '../segues/SegueView'
import { useCanvas } from './FlowPlannerCanvasContext'
import { useSelection } from './SelectionContext'

export interface Props {
  component: PlanComponent
  bounds:    LayoutRect
  selected:  boolean
}

const PlannerComponent = memo('PlannerComponent', (props: Props) => {

  const {bounds, component, selected} = props
  const {uuid} = component

  const {manager, getSelectedUUIDs} = useSelection()

  const {planner, plan} = useFlowPlanner()
  const {getViewport}   = useCanvas()

  const timer = useTimer()

  //------
  // Auto-sizing

  const $ = useStyles()

  const containerRef = React.useRef<HTMLDivElement>(null)

  const autoSize = React.useCallback((handlePoint: Point) => {
    const container = containerRef.current
    if (container == null || planner == null) { return }

    const reset = pick(container.style, 'width', 'height')
    if (handlePoint.x >= 0) { container.style.width  = '' }
    if (handlePoint.y >= 0) { container.style.height = '' }

    container.classList.add($.measuring, '__measuring')

    const size = {
      width:  handlePoint.x < 0 ? bounds.width : planner.roundToGrid(container.offsetWidth, true),
      height: handlePoint.y < 0 ? bounds.height : planner.roundToGrid(container.offsetHeight, true),
    }

    planner.resizeComponentTo(uuid, handlePoint, size)
    planner.commitComponentBounds()

    Object.assign(container.style, reset)
    container.classList.remove($.measuring, '__measuring')
  }, [$.measuring, bounds.height, bounds.width, planner, uuid])

  React.useEffect(() => {
    return planner.addAutoSizeHandler(uuid, autoSize)
  }, [autoSize, planner, uuid])

  //------
  // Rendering

  const hasSize = isSegue(component) || (bounds.width >= 0 && bounds.height >= 0)

  function render() {
    if (plan == null) { return null }

    const style: React.CSSProperties = hasSize ? {
      ...bounds,
    } : {
      left:       bounds.left,
      top:        bounds.top,
      visibility: 'hidden',
    }

    return (
      <div
        ref={containerRef}
        classNames={$.plannerComponent}
        style={style}

        onMouseDown={handleStart}
        onTouchStart={handleStart}
      >
        {isNode(component) ? (
          <NodeView
            plan={plan}
            node={component}
          />
        ) : isSegue(component) ? (
          <SegueView
            segue={component}
            selected={selected}
          />
        ) : isAnnotation(component) ? (
          <AnnotationView
            annotation={component}
          />
        ) : null}
      </div>
    )
  }

  //------
  // Drag & drop

  const startPointRef    = React.useRef<Point | null>(null)
  const groupNodeRef     = React.useRef<TriggerableNode | null>(null)

  const [dragActive, startDrag, endDrag] = useBoolean()
  const dragActiveRef = useContinuousRef(dragActive)

  const startCopy = React.useCallback(() => {
    planner.setMoveMode('copy')
  }, [planner])

  const endCopy = React.useCallback(() => {
    planner.setMoveMode('move')
  }, [planner])

  useHotkey(dragActive ? 'Alt' : null, {
    down: startCopy,
    up:   endCopy,
  })

  const calculateMouseDelta = React.useCallback((point: Point) => {
    if (startPointRef.current == null) { return null }
    return Math.max(
      Math.abs(point.x - startPointRef.current.x),
      Math.abs(point.y - startPointRef.current.y),
    )
  }, [])

  const handleMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (planner == null) { return }

    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const delta = calculateMouseDelta(clientPoint)
    if (startPointRef.current == null || delta == null || delta < 4) { return }

    const {zoom} = getViewport()

    let uuids = getSelectedUUIDs()
    if (!uuids.includes(uuid)) {
      manager?.selectOnly(uuid)
      uuids = [uuid]
    }

    const moveBy = {
      x: (clientPoint.x - startPointRef.current.x) / zoom,
      y: (clientPoint.y - startPointRef.current.y) / zoom,
    }
    const nextBounds = {
      left:   planner.roundToGrid(bounds.left + moveBy.x),
      top:    planner.roundToGrid(bounds.top + moveBy.y),
      width:  planner.roundToGrid(bounds.width),
      height: planner.roundToGrid(bounds.height),
    }

    if (!isSegue(component) && component.type === 'triggerable') {
      const touchingComponent = planner.findTouchingComponent(nextBounds, {types: ['triggerable'], exclude: uuids})
      if ((touchingComponent?.type === 'triggerable') &&
          !some(component.triggerables, it=> nodeHasTriggerable(touchingComponent, it))) {
        planner.setGroupTriggerablePreview(touchingComponent?.uuid ?? null)
        groupNodeRef.current = touchingComponent as TriggerableNode
      } else {
        planner.setGroupTriggerablePreview(null)
        groupNodeRef.current = null
      }
    }

    planner.moveComponents(uuids, moveBy)

    startDrag()
  }, [bounds.height, bounds.left, bounds.top, bounds.width, calculateMouseDelta, component, getSelectedUUIDs, getViewport, manager, planner, startDrag, uuid])

  const handleEnd = React.useCallback(async (event: MouseEvent | TouchEvent) => {
    startPointRef.current = null
    document.removeEventListener('mousemove', handleMove)
    document.removeEventListener('touchmove', handleMove)
    document.removeEventListener('mouseup', handleEnd)
    document.removeEventListener('touchend', handleEnd)

    if (dragActiveRef.current && groupNodeRef.current != null) {
      await timer.await(planner.addTriggerablesFromNodesToNode(getSelectedUUIDs(), groupNodeRef.current.uuid))
    } else if (dragActiveRef.current) {
      const uuids = await timer.await(planner.commitComponentBounds())
      if (uuids != null) {
        manager?.selectOnly(...uuids)
      }
    } else if (isSegue(component)) {
      const clientPoint = getClientPoint(event)
      if (clientPoint == null) { return }

      planner.openSegueMenu(component, clientPoint)
    } else if (event instanceof MouseEvent && event.shiftKey) {
      manager?.toggle(uuid)
    } else {
      manager?.selectOnly(uuid)
    }

    endDrag()
    groupNodeRef.current  = null
  }, [component, dragActiveRef, endDrag, getSelectedUUIDs, handleMove, manager, planner, timer, uuid])

  const handleStart = React.useCallback((event: React.MouseEvent | React.TouchEvent) => {
    if (isRightMouse(event.nativeEvent)) { return }
    if (closest(event.target, isInteractiveElement) != null) { return }

    event.preventDefault()

    planner.setMoveMode(event.altKey ? 'copy' : 'move')

    startPointRef.current = getClientPoint(event.nativeEvent)
    document.addEventListener('mousemove', handleMove)
    document.addEventListener('touchmove', handleMove)
    document.addEventListener('mouseup', handleEnd)
    document.addEventListener('touchend', handleEnd)
  }, [handleEnd, handleMove, planner])

  return render()

}, (prevProps, nextProps) => {
  const {bounds: prevBounds, ...prevRest} = prevProps
  const {bounds: nextBounds, ...nextRest} = nextProps

  if (!objectEquals(prevBounds, nextBounds)) { return false }
  if (!objectEquals(prevRest, nextRest)) { return false }
  return true
})

export default PlannerComponent

const useStyles = createUseStyles({
  plannerComponent: {
    position: 'absolute',
    ...layout.flex.column,

    pointerEvents: 'auto',
    cursor:        'pointer',
  },

  measuring: {
    '&, & *': {
      whiteSpace: 'nowrap !important',
    },
  },
})