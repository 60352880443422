import React from 'react'
import { TalliedCount, TalliedResult } from '~/models'
import { Result } from '~/stores'
import { memo } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { DonutChart } from '~/ui/components/datavis'
import { createUseStyles, layout } from '~/ui/styling'
import DataTableResult from './DataTableResult'
import { segmentCaption } from './util'

export interface Props {
  results: Array<Result & TalliedResult>
}

const DonutChartResult = memo('DonutChartResult', (props: Props) => {

  const {results} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='both' gap={layout.padding.m} classNames={$.donutChartResult}>
        {renderCharts()}
        {renderDataTable()}
      </VBox>
    )
  }

  function renderCharts() {
    return (
      <HBox gap={layout.padding.m}>
        {results.map(renderChart)}
      </HBox>
    )
  }

  function renderChart(result: Result & TalliedResult) {
    return (
      <HBox key={result.segment.value} flex='both' justify='center' align='stretch'>
        <VBox classNames={$.chart} gap={layout.padding.s}>
          <DonutChart
            flex
            data={result.counts}
            valueForPoint={valueForPoint}
          />
          {results.length > 1 && (
            <Label caption>
            {segmentCaption(result.segment)}
            </Label>
          )}
        </VBox>
      </HBox>
    )
  }

  const valueForPoint = React.useCallback(
    (count: TalliedCount) => count.count, [],
  )

  function renderDataTable() {
    return (
      <DataTableResult
        results={results}
      />
    )
  }

  return render()

})

export default DonutChartResult

const useStyles = createUseStyles((theme) => ({
  donutChartResult: {
    padding: layout.padding.m,
  },
  chart: {
    minWidth: 160,
    minHeight: 160,
    flex: [0, 1, '480px'],
  },
}))