export interface Link {
  /** The URL the link points to. */
  href: string

  /**
   * The target is used like <a href>'s target attribute, and behaves slightly differently between our
   * app and our web client. It's only applied to external links - links to internal app components are
   * always opened in the app / web client's appropriate screen.
   *
   * This property defaults to `''` (the empty string), which means the link is opened in the 'top-most'
   * tab in our web client, or simply in the in-app browser in the app.
   *
   * Web client:
   * - The value `'_blank'` means it's opened in a new tab.
   * - Any other value means the link is opened in the in-app iframe, and signifies a 'tab'. If this tab
   *   does not yet exist, a new iframe tab is opened and the link is loaded in it. If the tab does exist,
   *   it is activated and the link is loaded in it.
   *
   * > Note: In our web client, if the layout does not include the iframe viewer, *all* links are opened
   * > in a new tab.
   *
   * App:
   * - The value `'_blank'` means an external browser is opened.
   * - Any other value will mean it's opened in the in-app browser. Contrary to the web client, there is
   *   no concept of different tabs in the in-app browser, so this value is ignored if it's not
   *   `'_blank'`.
   */
  target?: LinkTarget
}

export type LinkTarget = string | '_blank'

export const Link: {
  to:    (href: string, options?: Partial<Omit<Link, 'href'>>) => Link
  empty: () => Link
} = {
  to: (href, options = {}) => ({
    href:   href,
    target: options.target,
  }),

  empty: () => ({
    href:     '',
    external: false,
  }),
}