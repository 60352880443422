import React from 'react'
import { memo } from '~/ui/component'
import { CollectionField, ConditionalField, TextField, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps } from '~/ui/form'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ValidationFormModel from './ValidationFormModel'

export interface Props extends Omit<FormDialogProps<ValidationFormModel>, 'children'> {}

const ValidationForm = memo('ValidationForm', (props: Props) => {

  const {t} = useResourceTranslation('registration-presets')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        icon='clock'
        title={t('validation.form_title')}

        {...props}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <ResourceTypeProvider resourceType='registration-presets'>
        <VBox gap={layout.padding.m}>
          <ConditionalField<string[]> switchName='originValidationEnabled' name='allowedOrigins' defaultValue={() => []} layout='box'>
            {renderCollectionField}
          </ConditionalField>
          <ConditionalField<string[]> switchName='emailValidationEnabled' name='allowedEmailPatterns' defaultValue={() => []} layout='box'>
            {renderCollectionField}
          </ConditionalField>
        </VBox>
      </ResourceTypeProvider>
    )
  }

  function renderCollectionField(bind: FormFieldBindProps<string[]>) {
    return (
      <CollectionField
        {...bind}
        newItemTemplate={() => ''}
        renderForm={props => <TextField {...props.bind('$')} mono inputStyle='dark'/>}
      />
    )
  }

  return render()

})

export default ValidationForm