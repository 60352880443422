import React from 'react'
import { BorderSpec } from '~/models'
import { observer } from '~/ui/component'
import { HBox, NumberField, SelectField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField, FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import GradientDirectionSelector from '../util/GradientDirectionSelector'
import BrandingBorderFormModel from './BrandingBorderFormModel'
import BrandingColorField from './BrandingColorField'

export interface Props {
  model: BrandingBorderFormModel
}

const BrandingBorderFields = observer('BrandingBorderFields', (props: Props) => {

  const {model} = props

  const {t} = useResourceTranslation()

  const typeChoices = React.useMemo((): Choice<BorderSpec['type']>[] => [
    {value: 'solid', caption: t('backgrounds.solid')},
    {value: 'gradient', caption: t('backgrounds.gradient')},
  ], [t])

  const positionChoices = React.useMemo((): Choice<BorderSpec['position']>[] => [
    {value: 'inside', caption: t('border.position.inside')},
    {value: 'outside', caption: t('border.position.outside')},
  ], [t])

  //------
  // Rendering

  function render() {
    return (
      <VBox padding={layout.padding.s} gap={layout.padding.s}>
        <FormField name='name' instruction={model.isWellKnown && t('assets.wellknown.name_instruction')}>
          {bind => <TextField {...bind} enabled={!model.isWellKnown}/>}
        </FormField>
        <FormField name='width'>
          {bind => <NumberField {...bind} minimum={0} maximum={8} step={1}/>}
        </FormField>
        <HBox gap={layout.padding.s} align='top'>
          <VBox flex={1}>
            <FormField name='position'>
              {bind => <SelectField {...bind} choices={positionChoices}/>}
            </FormField>
          </VBox>
          <VBox flex={3}>
            <FormField name='offset'>
              {bind => <NumberField {...bind} minimum={0} maximum={12} step={1}/>}
            </FormField>
          </VBox>
        </HBox>

        {model.type === 'solid' ? (
          renderSolidFields()
        ) : (
          renderGradientFields()
        )}
      </VBox>
    )
  }

  function renderSolidFields() {
    return (
      <HBox gap={layout.padding.s} align='top'>
        <VBox flex={1}>
          <FormField name='type'>
            {bind => <SelectField {...bind} choices={typeChoices}/>}
          </FormField>
        </VBox>
        <VBox flex={3}>
          <FormField name='color'>
            {bind => <BrandingColorField {...bind}/>}
          </FormField>
        </VBox>
      </HBox>
    )
  }

  function renderGradientFields() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex gap={layout.padding.s}>
          <FormField name='type'>
            {bind => <SelectField {...bind} choices={typeChoices}/>}
          </FormField>
          <HBox gap={layout.padding.s}>
            <VBox flex>
              <FormField name='startColor'>
                {bind => <BrandingColorField {...bind}/>}
              </FormField>
            </VBox>
            <VBox flex>
              <FormField name='endColor'>
                {bind => <BrandingColorField {...bind}/>}
              </FormField>
            </VBox>
          </HBox>
        </VBox>
        <VBox gap={layout.padding.inline.s}>
          <FormFieldHeader name='gradient'/>
          <GradientDirectionSelector/>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default BrandingBorderFields