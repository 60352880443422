import React from 'react'
import { upperFirst } from 'lodash'
import { Model, ModelClass } from '~/models'
import { ImportDialog, ImportDialogProps } from '~/ui/app/import'
import { memo } from '~/ui/component'
import { useResourceTranslation } from '~/ui/resources'
import ResourceImportModel from './ResourceImportModel'

export interface Props<M extends Model> extends Omit<ImportDialogProps, 'model' | 'fieldCaption'> {
  Model:         ModelClass<any>
  moduleID:      string
  importModel?:  ResourceImportModel<M>
  fieldCaption?: ImportDialogProps['fieldCaption']
}

const ResourceImportDialog = memo('ResourceImportDialog', (props: Props<any>) => {

  const {
    Model,
    moduleID,
    importModel: props_importModel,
    ...rest
  } = props

  const i18n = useResourceTranslation()

  const importModel = React.useMemo(() => {
    if (props_importModel != null) {
      return props_importModel
    }

    const defaults: Record<string, any> = {}
    if (Model.scopedToModule) {
      defaults.module = moduleID
    }

    return new ResourceImportModel(Model, defaults, i18n)
  }, [Model, i18n, moduleID, props_importModel])

  const importFieldCaption = React.useCallback((key: string) => {
    return i18n.fieldCaption(key, {defaultValue: upperFirst(key)})
  }, [i18n])

  //------
  // Rendering

  function render() {
    return (
      <ImportDialog
        title={i18n.actionCaption('import')}
        model={importModel}
        fieldCaption={importFieldCaption}
        {...rest}
      />
    )
  }

  return render()

})

export default ResourceImportDialog