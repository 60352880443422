import { isArray, isPlainObject } from 'lodash'
import { TextFieldProps } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'

// V: Value type (primitive value)
// T: Choice type (associated larger value)

export type AutoCompleteFieldProps<V extends Primitive, C> = SingleProps<V, C> | MultiProps<V, C>

export interface SingleProps<V, C> extends CommonProps<V, C> {
  value:     V | null
  onChange?: (value: V | null) => any
  multi?:    false
}

export interface MultiProps<V, C> extends CommonProps<V, C> {
  value:      V[]
  onChange?:  (value: V[]) => any
  multi?:     true
  minValues?: number
  maxValues?: number

  /**
   * Renders a selected choice chip.
   */
  renderChoiceChip?: (choice: C) => React.ReactNode
}

export interface CommonProps<V, C> {

  /**
   * Maps a value to a choice item. May be omitted for a select-only usage.
   */
  choiceForValue?: (value: V) => C | null

  /**
   * Extracts the value from a choice item.
   */
  valueForChoice?: (choice: C) => V

  /**
   * Retrieves an icon for a choice item.
   */
  iconForChoice?: (choice: C) => SVGName | React.ReactNode

  /**
   * Retrieves the label for a choice item.
   */
  captionForChoice?: (choice: C) => string

  /**
   * Retrieves an extra detail label for a choice item.
   */
  detailForChoice?: (choice: C) => React.ReactNode | null

  /**
   * Renders a choice in the drop down. By default, it's the label.
   */
  renderChoice?:  (choice: C) => React.ReactNode

  /**
   * Renders an accessory for a value item.
   */
  renderValueAccessory?: (value: C) => React.ReactNode

  // Callbacks
  onSearch:       (query: string | null) => void | C[] | AutoCompleteSection<C>[] | Promise<void | C[] | AutoCompleteSection<C>[]>
  onSelect?:      (choice: C) => any
  onClear?:       () => any
  requestCreate?: (name: string) => C | null | void | Promise<C | null | void>

  // Explicit setting of results.
  results?: C[] | AutoCompleteSection<C>[]

  // Behavior
  readOnly?: boolean
  enabled?: boolean
  preventDuplicates?: boolean
  minimumSearchQueryLength?: number
  throttle?: number | null

  onEndReached?: () => any

  // Placeholders
  placeholder?:          string | null
  emptyPlaceholder?:     string | null
  noResultsPlaceholder?: string | {title: string, detail: string} | null
  createPlaceholder?:    ((name: string | null) => string) | string | null

  // Styling
  small?:                 boolean
  smallChips?:            boolean
  invalid?:               boolean
  inputStyle?:            TextFieldProps['inputStyle']
  classNames?:            React.ClassNamesProp
  searchInputClassNames?: React.ClassNamesProp

}

export interface AutoCompleteSection<C> {
  caption?: string | null
  loading?: boolean
  choices:  C[]
}

export function isAutoCompleteSection<C>(arg: C | AutoCompleteSection<C>): arg is AutoCompleteSection<C> {
  if (!isPlainObject(arg)) { return false }
  return 'choices' in arg && isArray(arg.choices)
}