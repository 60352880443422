import React from 'react'
import { Model } from '~/models'
import { HBox, Label, SVG } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { cell } from './cell'

export interface Props {
  item: Model
}

const NameCell = cell('NameCell', (props: Props) => {

  const {item} = props
  const theme = useTheme()

  return (
    <HBox gap={layout.padding.s}>
      <SVG
        name={item.$icon}
        size={layout.icon.m}
        color={theme.semantic.primary}
      />
      <Label bold>
        {item.$caption}
      </Label>
    </HBox>
  )

})

export default NameCell