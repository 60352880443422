import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { getTriggerableID, Triggerable, TriggerableNode } from '~/models'
import { observer } from '~/ui/component'
import { HBox, Label, Panel, SVG, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import FlowPlannerTriggerableBar from './FlowPlannerTriggerableBar'

export interface Props {
  node:         TriggerableNode
  variant?:     'canvas' | 'list'
  interactive?: boolean
}

const TriggerableNodeView = observer('TriggerableNodeView', (props: Props) => {

  const {node, variant = 'canvas', interactive = true} = props
  const {triggerables} = node

  const {planner}    = useFlowPlanner()
  const groupPreview = planner.groupTriggerablePreviewUUID === node.uuid

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (!groupPreview && triggerables.length === 1) {
      return renderTriggerableBar(triggerables[0], false)
    } else {
      return renderGroup()
    }
  }

  function renderGroup() {
    return (
      <VBox flex='grow' classNames={[$.group, {groupPreview}]}>
        <Panel semi classNames={$.groupBorder}/>
        {triggerables.map(triggerable => (
          renderTriggerableBar(triggerable, true)
        ))}
        {groupPreview && renderDropHint()}
      </VBox>
    )
  }

  function renderTriggerableBar(triggerable: Triggerable, partOfGroup: boolean) {
    return (
      <FlowPlannerTriggerableBar
        key={getTriggerableID(triggerable)}
        triggerable={triggerable}
        node={node}
        partOfGroup={partOfGroup}
        small={variant === 'list'}
        interactive={interactive}
      />
    )
  }

  function renderDropHint() {
    return (
      <HBox justify='center' classNames={$.dropHint} gap={layout.padding.inline.s}>
        <SVG
          name='planner-group'
          size={layout.icon.xs}
          dimmer
        />
        <Label small dimmer>
          {t('triggerables.group_drop_hint')}
        </Label>
      </HBox>
    )
  }

  return render()

})

export const groupPadding = layout.padding.inline.s

const useStyles = createUseStyles({
  group: {
    position: 'relative',
  },

  groupBorder: {
    position: 'absolute',
    top:      -groupPadding,
    right:    -groupPadding,
    bottom:   -groupPadding,
    left:     -groupPadding,
  },

  dropHint: {
    height: config.planner.minimumComponentSize('triggerable').height,
  },
})

export default TriggerableNodeView