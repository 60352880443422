import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import clipboard, { useClipboardQuery } from 'rich-clipboard'
import { ClipboardType } from '~/clipboard'
import { Condition, PlanSegue } from '~/models'
import { ConditionSummaryLabel } from '~/ui/app/conditions'
import { memo } from '~/ui/component'
import { ConfirmBox, HBox, PopupMenu, PopupMenuItem, SVG, Tappable } from '~/ui/components'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  segue:     PlanSegue
  condition: Condition
}

const ConditionAttribute = memo('ConditionAttribute', (props: Props) => {

  const {condition, segue} = props

  const {planner}   = useFlowPlanner()

  const [clipboardQuery, clipboardItem] = useClipboardQuery<Condition[]>(ClipboardType.CONDITIONS)

  const [t]      = useTranslation('flow_planner')
  const {colors} = useStyling()

  //------
  // Actions

  const edit = React.useCallback(() => {
    planner.editSegueDetail(segue, 'conditions')
  }, [planner, segue])

  const copy = React.useCallback(() => {
    clipboard.write([{
      type: ClipboardType.CONDITIONS,
      data: segue.conditions,
    }])

    Toast.show({
      ...t('segues.actions.copy_conditions.success'),
      type: 'success',
    })
  }, [segue, t])

  const paste = React.useCallback(() => {
    const conditions = clipboardQuery.getData()
    if (conditions == null) { return null }

    return planner.updateSegue(segue.uuid, segue => ({...segue, conditions}))
  }, [clipboardQuery, planner, segue.uuid])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('segues.actions.remove_conditions.confirm'),
      destructive: true,
    })
    if (!confirmed) { return }

    return planner.updateSegue(segue.uuid, segue => ({...segue, conditions: []}))
  }, [planner, segue.uuid, t])

  const menuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'pencil',
      caption:  t('segues.actions.edit_conditions'),
      onSelect: edit,
    }, {
      icon:     'copy',
      caption:  t('segues.actions.copy_conditions.caption'),
      onSelect: copy,
    })

    if (clipboardItem != null) {
      items.push({
        icon:     'paste',
        caption:  t('segues.actions.paste_conditions'),
        onSelect: paste,
      })
    }

    items.push({
      section: '-',
    }, {
      icon:     'trash',
      caption:  t('segues.actions.remove_conditions.caption'),
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [clipboardItem, colors.semantic.negative, copy, edit, paste, remove, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <PopupMenu items={menuItems}>
        {renderContent}
      </PopupMenu>
    )
  }

  function renderContent(toggle: () => any) {
    return (
      <Tappable onTap={toggle} onDoubleClick={edit}>
        <ThemeProvider contrast='secondary'>
          <HBox classNames={$.condition} gap={layout.padding.inline.m}>
            <SVG name='if' size={layout.icon.s}/>
            <ConditionSummaryLabel
              condition={condition}
              small
              caption
            />
          </HBox>
        </ThemeProvider>
      </Tappable>
    )
  }

  return render()

})

export default ConditionAttribute

export const height   = 32
export const minWidth = 120

const useStyles = createUseStyles(theme => ({
  condition: {
    height:       height,
    borderRadius: height / 2,
    minWidth:     minWidth,
    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    background:   theme.semantic.secondary,

    position: 'relative',
    '&:hover::after': {
      ...layout.overlay,
      content:       '""',
      pointerEvents: 'none',
      borderRadius:   height / 2,
      background:     theme.bg.hover,
      boxShadow:      shadows.depth(1),
    },
  },
}))