import React from 'react'
import { BackgroundSpec } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, Label } from '~/ui/components'
import { ComponentBranding, layout } from '~/ui/styling'
import { useBranding } from '../BrandingContext'

export interface Props {
  name: string
  spec?: BackgroundSpec
}

const BrandingBackgroundSwatch = memo('BrandingBackgroundSwatch', (props: Props) => {

  const {name, spec} = props

  const {guide, branding} = useBranding()

  const component = React.useMemo(() => new ComponentBranding(guide, null, {
    background: {...branding.backgrounds[name], ...spec},
    border:     'none',
    shape:      'rectangle',
    depth:      1,
  }), [branding.backgrounds, guide, name, spec])

  //------
  // Rendering

  function render() {
    return (
      <BrandedComponent
        guide={guide}
        branding={component}
        height={swatchSize.height}
        style={{...swatchSize, borderRadius: layout.radius.s}}
        children={<Center flex><Label>•</Label></Center>}
      />
    )
  }

  return render()

})

export const swatchSize = {
  width:  24,
  height: 24,
}

export default BrandingBackgroundSwatch