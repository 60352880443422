import React from 'react'
import { Page } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, HBox, PopupMenu, PopupMenuItem, Toolbar, Tooltip } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { MenuPageStore } from './MenuPageStore'

export interface Props {
  store: MenuPageStore

  layout:        Page['layout']
  requestLayout: (layout: Page['layout']) => any

  requestAddItem: (type: NewMenuPageItemType) => any
}

export type NewMenuPageItemType = Page['type'] | 'link'

const MenuPageToolbar = observer('MenuPageToolbar', (props: Props) => {

  const {
    store,
    layout,
    requestLayout,
    requestAddItem,
  } = props

  const editing = store.editingItems != null

  const {t, actionCaption} = useResourceTranslation('pages')

  //------
  // Add item

  const addMenuPageItemMenuItems = React.useMemo((): PopupMenuItem<NewMenuPageItemType>[] => [{
    icon:    'page',
    caption: t('actions.menu.add_item.page'),
    children: [
      {value: 'content', icon: 'content-page', ...t('types.content')},
      {section: '-'},
      {value: 'menu', icon: 'menu-page', ...t('types.menu')},
      {value: 'index', icon: 'index-page', ...t('types.index')},
    ],
  }, {
    icon:    'link',
    value:   'link',
    caption: t('actions.menu.add_item.link'),
  }], [t])

  const layoutItems = React.useMemo((): PopupMenuItem<Page['layout']>[] => [{
    icon:    'list',
    value:   'list',
    checked: layout === 'list',
    caption: t('menu.layout.list'),
  }, {
    icon:    'grid',
    value:   'grid',
    checked: layout === 'grid',
    caption: t('menu.layout.grid'),
  }], [layout, t])

  //------
  // Rendering

  function render() {
    return (
      <Toolbar style='panel'>
        <HBox>
          {renderAddItemButton()}
        </HBox>
        <HBox>
          {renderLayoutButton()}
          {!editing && <Dimple vertical/>}
          {renderEditButton()}
        </HBox>
      </Toolbar>
    )
  }

  function renderLayoutButton() {
    if (editing) { return null }

    const button = (
      <PopupMenu items={layoutItems} onValueSelect={requestLayout}>
        {toggle => (
          <Toolbar.Button
            icon={layout}
            caption={t(`menu.layout.${layout}`)}
            onTap={toggle}
          />
        )}
      </PopupMenu>
    )

    if (layout === 'grid') {
      return (
        <Tooltip renderTooltip={t('menu.grid_tooltip')} textAlign='center'>
          {button}
        </Tooltip>
      )
    } else {
      return button
    }
  }

  function renderAddItemButton() {
    if (!editing) {
      return (
        <PopupMenu items={addMenuPageItemMenuItems} onValueSelect={requestAddItem}>
          {toggle => (
            <Toolbar.Button
              icon='plus'
              caption={actionCaption('menu.add-item')}
              onTap={toggle}
            />
          )}
        </PopupMenu>
      )
    }
  }

  function renderEditButton() {
    if (!editing) {
      return (
        <Toolbar.Button
          icon='unordered-list'
          caption={actionCaption('menu.edit')}
          onTap={store.startEditingList}
        />
      )
    } else {
      return (
        <Toolbar.Button
          icon='check'
          caption={t('buttons:done')}
          onTap={store.commitList}
        />
      )
    }
  }

  return render()

})

export default MenuPageToolbar