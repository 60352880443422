import React from 'react'
import { Channel } from '~/models'
import { TargetingSummary } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import ChannelTargetingForm from './ChannelTargetingForm'

export interface Props {
  channel: Channel
}

const ChannelTargeting = memo('ChannelTargeting', (props: Props) => {

  const {channel} = props
  const {t} = useResourceTranslation('channels')

  const BoundTargetingForm = React.useCallback(
    (props: Pick<FormDialogProps<any>, 'open' | 'requestClose'>) => (
      <ChannelTargetingForm
        {...props}
        channel={channel}
        instructions={t('targeting_form.instructions')}
      />
    ),
    [channel, t],
  )

  if (channel.targeting == null) {
    return null
  } else {
    return (
      <TargetingSummary
        targeting={channel.targeting}
        FormComponent={BoundTargetingForm}
      />
    )
  }

})

export default ChannelTargeting