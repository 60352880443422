import React from 'react'
import { Media, Participant } from '~/models'
import { dataStore } from '~/stores'
import { ProfilePhotoField } from '~/ui/app/fields'
import { Center, Label, TextBlock, TextField, VBox } from '~/ui/components'
import { InlineFormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { InlineResourceForm } from '~/ui/resources/form'
import { createUseStyles, layout } from '~/ui/styling'
import ProfileTable from './ProfileTable'

export interface Props {
  participant: Participant
}

const ParticipantProfile = (props: Props) => {

  const {participant} = props
  const {t} = useResourceTranslation()

  //------
  // Callbacks

  const updateProfilePhoto = React.useCallback((media: Media | null) => {
    const document = dataStore.document(Participant, participant.id)
    return document.update({
      photoURL: media?.url ?? null,
    })
  }, [participant.id])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.participantProfile} gap={layout.padding.l}>
        <Center gap={layout.padding.s}>
          {renderPhoto()}
          {renderInfoForm()}
        </Center>
        <ProfileTable
          participant={participant}
        />
      </VBox>
    )
  }

  function renderPhoto() {
    return (
      <ProfilePhotoField
        model={participant}
        size={profilePhotoSize}
        requestUpdate={updateProfilePhoto}
      />
    )
  }

  function renderInfoForm() {
    return (
      <InlineResourceForm fieldGap={layout.padding.s} Model={Participant} model={participant}>
        <VBox gap={layout.padding.inline.xs}>
          <Label h2 align='center'>
            {participant.name}
          </Label>
          <InlineFormField
            name='headline'
            caption={false}
            align='center'
            renderView={headline => (
              <Label light dim italic={headline == null} align='center'>
                {headline ?? t('profile.headline_empty')}
              </Label>
            )}
            renderEdit={bind => <TextField {...bind}/>}
          />
        </VBox>
        <VBox>
          <InlineFormField
            name='bio'
            caption={false}
            align='center'
            renderView={bio => (
              <TextBlock small dim={bio == null} italic={bio == null} align='center'>
                {bio ?? t('profile.bio_empty')}
              </TextBlock>
            )}
            renderEdit={bind => <TextField multiline height={120} {...bind}/>}
          />
        </VBox>
      </InlineResourceForm>
    )
  }

  return render()

}

export default ParticipantProfile

export const profilePhotoSize = {
  width:  240,
  height: 240,
}

const useStyles = createUseStyles({
  participantProfile: {
    width: 420,

    ...layout.responsive(size => ({
      minWidth: profilePhotoSize.width + 2 * layout.padding.l[size],
    })),
  },
})