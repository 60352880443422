import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Toast from 'react-toast'
import { Competition, Ranking } from '~/models'
import { competitionStore, dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { Center, ConfirmBox, Dimple, PushButton, Spinner, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { useModelDocumentData, useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { EditFormProps, ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout, useStyling } from '~/ui/styling'
import { saveAs } from '~/ui/util'
import CompetitionForm from '../CompetitionForm'
import CompetitionHeader from './CompetitionHeader'
import CompetitionProfileItemSummary from './CompetitionProfileItemSummary'
import CompetitionTargeting from './CompetitionTargeting'
import CompetitionTeamsSummary from './CompetitionTeamsSummary'
import RankingList from './RankingList'
import ScoreFormatSummary from './ScoreFormatSummary'
import TeamScoringSummary from './TeamScoringSummary'

export type Props = RouteComponentProps<ResourceDetailParams>

const CompetitionScreen = observer('CompetitionScreen', (props: Props) => {

  const {t, actionCaption, actionConfirm} = useResourceTranslation('competitions')

  const competitionID = props.match.params.id
  const [competition] = useModelDocumentData(Competition, competitionID, {fetch: 'never'})

  const rankingsEndpoint = useModelEndpoint(Ranking, {
    filters: {competition: competitionID},
  })

  const TypedCompetitionForm = React.useMemo((): React.ComponentType<EditFormProps<Competition>> => {
    return props => (
      <CompetitionForm
        {...props}
        model={props.model}
        type={competition?.type ?? 'head-to-head'}
      />
    )
  }, [competition?.type])

  // Reload rankings upon any update on the competition object.
  const prevLastUpdatedAt = usePrevious(competition?.updatedAt.valueOf())
  React.useEffect(() => {
    if (competition == null) { return }

    if (competition.updatedAt.valueOf() !== prevLastUpdatedAt) {
      rankingsEndpoint.fetch()
    }
  }, [competition, prevLastUpdatedAt, rankingsEndpoint])

  //------
  // Download

  const downloadRankings = React.useCallback(async () => {
    const blob = await competitionStore.downloadRankings(competitionID)
    if (blob == null) { return }
    saveAs(blob, 'Rankings.xlsx')
  }, [competitionID])

  const recount = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('recount'),
    })
    if (!confirmed) { return }

    const document = dataStore.document(Competition, competitionID)
    const result = await document.callAction('recount')
    if (result.status === 'ok') {
      rankingsEndpoint.fetch()
      Toast.show({
        type: 'success',
        ...t('actions.recount.success'),
      })
    }
  }, [actionConfirm, competitionID, rankingsEndpoint, t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={Competition}
        id={competitionID}
        include={['targeting.groups', 'teams']}

        EditFormComponent={TypedCompetitionForm}

        renderType={competition => t(`types.${competition.type}.caption`)}
        renderInfo={renderInfo}
        renderBody={renderBody}
        renderSummaryFooter={renderSummaryFooter}
        renderActions={renderActions}
      />
    )
  }

  //------
  // Body

  function renderBody() {
    if (competition == null) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }

    return (
      <VBox flex gap={layout.padding.l}>
        <CompetitionHeader competition={competition}/>
        <Dimple horizontal/>
        <VBox flex>
          <RankingList
            competition={competition}
            endpoint={rankingsEndpoint}
          />
        </VBox>
      </VBox>
    )
  }

  //------
  // Right bar

  const {colors} = useStyling()

  function renderInfo(competition: Competition) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: competition.createdAt})}
        </SummaryInfo.Item>
        {rankingsEndpoint.fetchStatus !== 'fetching' && rankingsEndpoint.data.length === 0 && (
          <SummaryInfo.Item markup icon='cross' important={true} color={colors.semantic.negative}>
            {t(competition.type === 'head-to-head' ? 'no_participants' : 'no_teams')}
          </SummaryInfo.Item>
        )}
      </SummaryInfo>
    )
  }

  function renderSummaryFooter(competition: Competition) {
    return [
      competition.type === 'head-to-head' ? (
        <CompetitionTargeting competition={competition}/>
      ) : (
        <CompetitionTeamsSummary competition={competition}/>
      ),
      <CompetitionProfileItemSummary competition={competition}/>,
      competition.type === 'team' && (
        <TeamScoringSummary competition={competition}/>
      ),
      <ScoreFormatSummary competition={competition}/>,
    ]
  }

  //------
  // Actions

  function renderActions(competition: Competition) {
    return (
      <VBox align='left' gap={layout.padding.s}>
        <PushButton
          icon='reset'
          caption={actionCaption('recount')}
          onTap={recount}
        />
        <PushButton
          icon='download'
          caption={t('rankings.download.button')}
          onTap={downloadRankings}
        />
      </VBox>
    )
  }

  return render()

})

export default React.memo(CompetitionScreen)