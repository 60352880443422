import React from 'react'
import { useTranslation } from 'react-i18next'
import { Location, Participant } from '~/models'
import { observer } from '~/ui/component'
import { ClearButton, Empty, Label, PanelHeader, VBox } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import LocationActions from './LocationActions'
import LocationParticipantList from './LocationParticipantList'

export interface Props {
  focusedLocation: Location | null
}

const MapSidebar = observer('MapSidebar', (props: Props) => {

  const {focusedLocation} = props
  const [t] = useTranslation('map')

  const participantsEndpoint = useModelEndpoint(Participant, {
    fetch: focusedLocation != null,
    filters: {
      currentLocation: focusedLocation?.id,
    },
  })

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.MapSidebar}>
        {focusedLocation == null ? (
          <Empty flex dim icon='map' {...t('sidebar.empty')}/>
        ) : (
          <>
            {renderHeader(focusedLocation)}
            {renderBody(focusedLocation)}
            {renderActions(focusedLocation)}
          </>
        )}
      </VBox>
    )
  }

  function renderHeader(location: Location) {
    return (
      <PanelHeader
        icon='map-pin'
        caption={location.name}
        right={renderGoToButton(location)}
      />
    )
  }

  function renderGoToButton(location: Location) {
    return (
      <ClearButton
        icon='external'
        caption={t('sidebar.go_to_location')}
        href={`/locations/-/${location.id}`}
      />
    )
  }

  function renderBody(location: Location) {
    return (
      <VBox flex classNames={$.body} gap={layout.padding.inline.m}>
        <VBox classNames={$.listHeader}>
          <Label caption dim>
            {t('sidebar.participants.caption')}
          </Label>
        </VBox>
        <LocationParticipantList
          location={location}
          endpoint={participantsEndpoint}
        />
      </VBox>
    )
  }

  function renderActions(location: Location) {
    return (
      <LocationActions
        location={location}
        participantsEndpoint={participantsEndpoint}
      />
    )
  }

  return render()

})

export default MapSidebar

const useStyles = createUseStyles({
  MapSidebar: {
  },

  body: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], 0],
    })),
  },

  listHeader: {
    ...layout.responsive(size => ({
      padding: [0, layout.padding.s[size]],
    })),
  },
})