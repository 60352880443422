import { some } from 'lodash'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { FetchStatus } from 'mobx-document'
import { SocketOperation } from 'socket.io-react'
import { CopyAction, ModelOfType, Project, Ref, reflect } from '~/models'
import { BulkSelector, CopyResolution, CopyResult, Linkage, ModelEndpoint } from '~/stores'
import { AssistantFormModel, FormError, FormModel, SubmitResult } from '~/ui/form'

export default class CopyToProjectFormModel implements FormModel, AssistantFormModel<CopyToProjectStep> {

  constructor(
    public readonly endpoint: ModelEndpoint<any>,
    public readonly selector: BulkSelector,
  ) {
    makeObservable(this)

    reaction(() => this.destProject, action(() => {
      this.primeResolutions()
    }))
  }

  @observable
  public destProject: Ref<Project> | null = null

  @observable
  public linkages: Linkage<any>[] = []

  @observable
  public resolutions = new Map<string, PartialCopyResolution | null>()

  @computed
  public get nonEmptyResolutions(): Array<[Linkage<any>, CopyResolution]> {
    const resolutions: Array<[Linkage<any>, CopyResolution]> = []
    for (const [id, resolution] of this.resolutions) {
      if (resolution == null) { continue }
      if (resolution.type === 'reassign' && resolution.id == null) { continue }

      const linkage = this.linkages.find(it => it.id === id)
      if (linkage == null) { continue }

      resolutions.push([linkage, resolution as CopyResolution])
    }
    return resolutions
  }

  public getResolution(linkage: Linkage<any>) {
    return this.resolutions.get(linkage.id) ?? null
  }

  @action
  public setResolution(linkage: Linkage<any>, resolution: PartialCopyResolution | null) {
    this.resolutions.set(linkage.id, resolution)
  }

  @action
  public primeResolutions() {
    this.resolutions.clear()
    for (const linkage of this.linkages) {
      this.resolutions.set(linkage.id, this.defaultResolution(linkage))
    }
  }

  private defaultResolution(linkage: Linkage<any>): PartialCopyResolution | null {
    const Model = ModelOfType(linkage.type)
    if (Model == null) { return null }

    const copyAction = reflect<CopyAction>(Model, 'copyAction')
    if (copyAction === 'copy') {
      return {type: 'copy'}
    } else {
      return {type: 'reassign', id: null}
    }
  }

  //------
  // Preflight

  @observable
  public preflightStatus: FetchStatus = 'idle'

  private lastPreflightPromise: Promise<any> | null = null
  private preflightReactionDisposer: IReactionDisposer | null = null

  @action
  public async preflight() {
    this.preflightStatus = 'fetching'

    const promise = this.endpoint.copyToProjectPreflight(this.selector, this.nonEmptyResolutions)
    this.lastPreflightPromise = promise

    const linkages = await promise
    if (promise !== this.lastPreflightPromise) { return }

    runInAction(() => {
      this.mergePreflightResult(linkages)
      this.preflightStatus = 'done'

      this.preflightReactionDisposer?.()
      this.preflightReactionDisposer = reaction(() => this.nonEmptyResolutions, action(resolutions => {
        if (some(resolutions, it => it[1].type === 'copy')) {
          this.preflight()
        }
      }))
    })
  }

  @action
  private mergePreflightResult(linkages: Linkage<any>[]) {
    this.linkages = linkages
    this.linkages.sort((a, b) => {
      // Always list modules first.
      if (a.type === 'modules' && b.type !== 'modules') {
        return -1
      } else if (a.type !== 'modules' && b.type === 'modules') {
        return 1
      } else {
        return a.type.localeCompare(b.type)
      }
    })

    // Remove any resolutions that we're not gonna use anymore.
    for (const id of this.resolutions.keys()) {
      const linkage = linkages.find(it => it.id === id)
      if (linkage == null) {
        this.resolutions.delete(id)
      }
    }
  }

  //------
  // Validation

  public mayContinueFromStep(step: CopyToProjectStep): boolean {
    if (this.preflightStatus !== 'done') { return false }

    if (step === CopyToProjectStep.destination) {
      if (this.destProject == null) { return false }
    }
    if (step === CopyToProjectStep.preflight) {
      if (some(this.linkages, it => !this.hasResolution(it))) { return false }
    }
    if (step === CopyToProjectStep.copy) {
      return this.results != null
    }

    return true
  }

  private hasResolution(linkage: Linkage<any>) {
    const resolution = this.resolutions.get(linkage.id)
    if (resolution == null) { return false }
    if (resolution.type === 'reassign' && resolution.id == null) { return false }
    return true
  }

  @computed
  public get maySubmit() {
    return this.mayContinueFromStep(CopyToProjectStep.copy)
  }

  @observable
  public operation: SocketOperation | null = null

  @observable
  public results: CopyResult[] | null = null

  private validate(): [string, FormError[]] {
    const errors: FormError[] = []
    if (this.destProject == null) {
      errors.push({field: 'destProject', code: 'required'})
    }

    for (const linkage of this.linkages) {
      if (!this.hasResolution(linkage)) {
        errors.push({field: `resolutions.${linkage.id}`, code: 'required'})
      }
    }

    return [
      this.destProject!,
      errors,
    ]
  }

  //------
  // Copy

  @observable
  public status: CopyStatus = 'idle'

  public async copy(): Promise<SubmitResult | undefined> {
    if (this.operation != null) { return }
    if (this.status !== 'idle') { return }

    this.status = 'copying'
    this.results = null

    const [project, errors] = this.validate()
    if (errors.length > 0) {
      return {
        status: 'invalid',
        errors: errors,
      }
    }

    const result = await this.endpoint.copyToProject(this.selector, project, this.nonEmptyResolutions, {
      onStart: action(operation => {
        this.operation = operation
      }),
      onEnd: action(() => {
        this.operation = null
      }),
    })

    runInAction(() => {
      if (result.status === 'ok') {
        this.results = result.data ?? []
      } else {
        this.results = null
      }
      this.status = 'idle'
    })

    return result
  }

  public submit(): SubmitResult {
    return {status: 'ok'}
  }

  //------
  // Reset

  @action
  public reset() {
    this.linkages = []
    this.resolutions = new Map()
    this.destProject = null

    this.preflightStatus = 'idle'
    this.lastPreflightPromise = null
    this.status = 'idle'
  }

}

export type PartialCopyResolution =
  | {type: 'copy'}
  | {type: 'reassign', id: string | null}

export enum CopyToProjectStep {
  destination = 'destination',
  preflight   = 'preflight',
  copy        = 'copy',
}

export type CopyStatus = 'idle' | 'copying'