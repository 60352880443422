import React from 'react'
import { Link } from '~/models'
import { ConditionsForm } from '~/ui/app/conditions'
import ReviewForm from '~/ui/app/feedback/ReviewForm'
import { LinkForm, LinkFormModel } from '~/ui/app/links'
import { memo } from '~/ui/component'
import { translateFormErrorPaths } from '~/ui/form'
import { useFormOpen } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { useScriptEditing } from '../ScriptEditingContext'
import { useScriptEditor } from '../ScriptEditorContext'
import ScriptMessageConditionsFormModel from './conditions/ScriptMessageConditionsFormModel'
import ScriptMessageFeedbackForm from './feedback/ScriptMessageFeedbackForm'
import ScriptMessageFeedbackFormModel from './feedback/ScriptMessageFeedbackFormModel'
import ScriptMessageScoringForm from './scoring/ScriptMessageScoringForm'
import ScriptMessageScoringFormModel from './scoring/ScriptMessageScoringFormModel'

const ScriptEditorDetailForm = memo('ScriptEditorDetailForm', () => {

  const {script, saveMessage} = useScriptEditor()
  const {editingDetail, setEditingDetail} = useScriptEditing()

  const message = React.useMemo(() => {
    if (editingDetail == null) { return null }

    return script?.findMessage(msg => msg.uuid === editingDetail.messageUUID)
  }, [editingDetail, script])

  const saveFeedback = React.useCallback(async (data: AnyObject) => {
    if (message == null) { return }

    const result = await saveMessage(message.uuid, {feedback: data})
    return translateFormErrorPaths(result, path => path.replace(/^feedback\./, ''))
  }, [message, saveMessage])

  const closeForm = React.useCallback(() => {
    setEditingDetail(null)
  }, [setEditingDetail])

  const {t} = useResourceTranslation('script-messages')

  //------
  // Rendering

  function render() {
    if (editingDetail == null) { return null }

    switch (editingDetail.type) {
      case 'feedback':   return renderFeedbackForm()
      case 'scoring':    return renderScoringForm()
      case 'review':     return renderReviewForm()
      case 'conditions': return renderConditionsForm()
      case 'link':       return renderLinkForm()
    }
  }

  //------
  // Feedback

  const feedbackFormModel = React.useMemo(() => {
    if (message == null) { return null }

    const type = message.feedback?.type ?? 'button'
    return new ScriptMessageFeedbackFormModel(saveMessage, message, type)
  }, [message, saveMessage])

  const [feedbackFormOpen, currentFeedbackFormModel, closeFeedbackForm] = useFormOpen(feedbackFormModel, closeForm)

  function renderFeedbackForm() {
    if (currentFeedbackFormModel == null) { return null }

    return (
      <ScriptMessageFeedbackForm
        open={feedbackFormOpen}
        requestClose={closeFeedbackForm}
        formModel={currentFeedbackFormModel}
      />
    )
  }

  //------
  // Scoring & review

  const scoringFormModel = React.useMemo(() => {
    if (message == null) { return null }
    if (message.feedback == null) { return null }

    const saveThisMessage = saveMessage.bind(null, message.uuid)
    return new ScriptMessageScoringFormModel(saveThisMessage, message.feedback)
  }, [message, saveMessage])

  const [scoringFormOpen, currentScoringFormModel, closeScoringForm] = useFormOpen(scoringFormModel, closeForm)

  function renderScoringForm() {
    if (currentScoringFormModel == null) { return null }

    return (
      <ScriptMessageScoringForm
        open={scoringFormOpen}
        requestClose={closeScoringForm}
        formModel={currentScoringFormModel}
      />
    )
  }

  function renderReviewForm() {
    if (message?.feedback == null) { return null }

    return (
      <ReviewForm
        open={true}
        requestClose={closeForm}

        feedback={message.feedback}
        requestSave={saveFeedback}
      />
    )
  }

  //------
  // Conditions

  const conditionsFormModel = React.useMemo(
    () => message == null ? null : new ScriptMessageConditionsFormModel(saveMessage, message),
    [message, saveMessage],
  )

  React.useEffect(() => {
    if (script == null) { return }
    conditionsFormModel?.deriveContextFromScript(script)
  }, [conditionsFormModel, script])

  const [conditionsFormOpen, currentConditionsFormModel, closeConditionsForm] = useFormOpen(conditionsFormModel, closeForm)

  function renderConditionsForm() {
    if (currentConditionsFormModel == null) { return null }

    return (
      <ConditionsForm
        open={conditionsFormOpen}
        requestClose={closeConditionsForm}
        model={currentConditionsFormModel}
        instructions={t('conditions.instructions')}
      />
    )
  }

  //------
  // Link

  const saveLink = React.useCallback(async (link: Link | null) => {
    if (message == null) { return }
    return await saveMessage(message?.uuid, {link})
  }, [message, saveMessage])

  const link = React.useMemo(() => {
    if (message?.type === 'text') {
      return message.link
    } else {
      return null
    }
  }, [message])

  const linkFormModel = React.useMemo(
    () => message == null ? null : new LinkFormModel(saveLink, link),
    [link, message, saveLink],
  )

  const [linkFormOpen, currentLinkFormModel, closeLinkForm] = useFormOpen(linkFormModel, closeForm)

  function renderLinkForm() {
    if (currentLinkFormModel == null) { return null }

    return (
      <LinkForm
        open={linkFormOpen}
        requestClose={closeLinkForm}
        model={currentLinkFormModel}
        caption='hide'
      />
    )
  }

  return render()

})

export default ScriptEditorDetailForm