import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import { AnswersQuery, QuestionRef } from '~/models'
import { dataStore, Result } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  HBox,
  KebabMenu,
  Label,
  Panel,
  PanelHeader,
  PopupMenu,
  PopupMenuItem,
  PushButton,
  VBox,
} from '~/ui/components'
import { useViewState } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { useAnswers } from '../AnswersContext'
import { useQuestion } from '../hooks'
import renderResults from '../results'
import { ResultStyle } from '../types'
import { translationKeyForResultStyleType } from '../util'

export interface Props {
  question:  QuestionRef
  data:      Result[]
  focused?:  boolean
}

const ExploreAnswersResultsPanel = observer('ExploreAnswersResultsPanel', (props: Props) => {

  const {data, focused = false} = props
  const {service, setFocusedQuestions}  = useAnswers()
  const questionRef = props.question
  const [question] = useQuestion(questionRef)

  const [t] = useTranslation('answers')

  const [resultStyle, setResultStyle] = useViewState<ResultStyle>(
    `answers.questions.${question?.uuid}`,
    question != null ? ResultStyle.defaultForFeedback(question.feedback) : ResultStyle.default('data-table'),
  )

  const resultStyleTypeItems = React.useMemo((): PopupMenuItem[] => {
    if (question == null) {return []}

    const types = ResultStyle.availableForFeedback(question.feedback)
    return types.map(type => ({
      value: type,
      caption: t(`results.types.${translationKeyForResultStyleType(type)}`),
    }))
  }, [question, t])

  const setResultStyleType = React.useCallback((type: ResultStyle['type'] | null) => {
    if (type == null) { return }
    setResultStyle(ResultStyle.default(type))
  }, [setResultStyle])

  //------
  // Actions

  const save = React.useCallback(async () => {
    if (question == null || service == null) { return }
    const result = await dataStore.create(AnswersQuery, {
      name:        question.caption,
      question:    questionRef,
      segments:    service.filters.segments?.filter(it => it !== 'all'),
      participant: service.filters.participantID,
    })

    if (result.status === 'ok') {
      Toast.show({
        type: 'succes',
        ...t('results.actions.save.success', {question}),
      })
    }
  }, [t, service, question, questionRef])

  const kebabMenuItems = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []
    items.push({
      icon: 'pin',
      onSelect: save,
      ...t('results.actions.save'),
    })
    return items
  }, [t, save])

  //------
  // Rendering

  const $ = useStyles()

  const focus = React.useCallback(() => {
    if (question == null) { return }
    setFocusedQuestions([question.uuid])
  }, [question, setFocusedQuestions])

  const refresh = React.useCallback(() => {
    if (question == null) { return }
    service?.refreshQuestion(question.uuid)
  }, [question, service])

  function render() {
    return (
      <Panel
        onTap={focused ? undefined : focus}
        flex='grow'
        header={renderHeader()}
        children={renderBody()}
        classNames={[$.panel, {focused}]}
      />
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        icon='question'
        caption={question?.caption}
        right={renderActions()}
      />
    )
  }

  function renderActions() {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderViewSwitcher()}
        {renderRefreshButton()}
        {renderKebabMenu()}
      </HBox>
    )
  }

  function renderViewSwitcher() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Label caption dim>
          {t('results.type_label')}
        </Label>
        <PopupMenu items={resultStyleTypeItems} onValueSelect={setResultStyleType}>
          {toggle => (
            <PushButton
              icon='chevron-down'
              iconSide='right'
              caption={t(`results.types.${translationKeyForResultStyleType(resultStyle.type)}`)}
              onTap={toggle}
              small
            />
          )}
        </PopupMenu>
      </HBox>
    )
  }

  function renderRefreshButton() {
    return(
      <ClearButton
        icon='reload'
        onTap={refresh}
      />
    )
  }

  function renderKebabMenu() {
    if (kebabMenuItems.length === 0) { return null }
    return (
      <KebabMenu
        items={kebabMenuItems}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex='grow' justify='middle' gap={layout.padding.inline.m} classNames={$.body}>
        {renderResults(data, resultStyle)}
      </VBox>
    )
  }

  return render()

})

export default ExploreAnswersResultsPanel

export const resultPanelMaxHeight = 380

const useStyles = createUseStyles({
  panel: {
    height: resultPanelMaxHeight,
    '&:not(.focused)': {
      maxHeight: resultPanelMaxHeight,
    },
  },

  body: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },
})