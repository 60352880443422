import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import Semaphore from 'semaphore'
import { CalendarDay, Module } from '~/models'
import { CalendarPlanner, plannerStore } from '~/stores'
import { createSuperContainer, observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useBoolean, useFormOpen } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import ActiveModuleContext from '../ActiveModuleContext'
import PlannerActions from '../PlannerActions'
import ActionTriggerableForm from '../triggerables/ActionTriggerableForm'
import { renderModelTriggerableDialog } from '../triggerables/index'
import CalendarCanvas from './CalendarCanvas'
import CalendarItemDetailForm from './CalendarItemDetailForm'
import { CalendarLayoutContextProvider } from './CalendarLayoutContext'
import { CalendarPlannerContextProvider, useCalendarPlanner } from './CalendarPlannerContext'
import CalendarPlannerHeader from './CalendarPlannerHeader'
import CalendarPlannerInsightsDialog from './CalendarPlannerInsightsDialog'
import CalendarItemForm, { CalendarItemFormModel } from './content/CalendarItemForm'
import CalendarTriggerableItemFormModel from './content/CalendarTriggerableItemFormModel'
import CalendarDayForm from './days/CalendarDayForm'
import { SelectionContextProvider } from './SelectionContext'

export interface Props {
  moduleIDs:   string[]
  breadcrumbs: Breadcrumb[]
}

const CalendarPlannerContainer = observer('CalendarPlannerContainer', (props: Props) => {

  const {moduleIDs, breadcrumbs} = props

  const moduleID = moduleIDs[moduleIDs.length - 1]
  const active   = React.useContext(ActiveModuleContext) === moduleID

  const service = React.useMemo(() => {
    if (moduleID == null) { return null }
    return plannerStore.getCalendarPlannerService(moduleID)
  }, [moduleID])

  useOnDemandService(service)

  const planner = React.useMemo(
    () => service == null ? null : new CalendarPlanner(service),
    [service],
  )
  if (service != null) {
    planner?.setPlan(service.plan)
  }

  const Container = React.useMemo(() => createSuperContainer<{planner: CalendarPlanner}>(
    (props, params) => <CalendarPlannerContextProvider planner={params.planner} {...props}/>,
    props => <CalendarLayoutContextProvider {...props}/>,
    props => <SelectionContextProvider {...props}/>,
  ), [])

  //------
  // Rendering

  function render() {
    if (planner == null) { return null }

    return (
      <Container planner={planner}>
        {active && (
          <AppLayoutConfig
            configKey='planner'
            breadcrumbs={breadcrumbs}
            ActionsComponent={renderActions}
          />
        )}

        <CalendarPlannerContent/>
      </Container>
    )
  }

  const renderActions = React.useCallback(() => {
    if (planner == null) { return null }
    return (
      <PlannerActions
        planner={planner}
      />
    )
  }, [planner])

  return render()

})

export default CalendarPlannerContainer

const CalendarPlannerContent = observer('CalendarPlannerContent', () => {

  const {planner} = useCalendarPlanner()

  const [dayFormOpen, openDayForm, closeDayForm] = useBoolean()
  const [editedDay, setEditedDay] = React.useState<CalendarDay | null>(null)

  const [createItemFormModel, setCreateItemFormModel] = React.useState<CalendarItemFormModel | null>(null)
  const [createItemFormOpen, currentCreateItemFormModel, closeItemForm] = useFormOpen(createItemFormModel, () => setCreateItemFormModel(null))

  //------
  // Callbacks

  const addDay = React.useCallback(() => {
    setEditedDay(null)
    openDayForm()
  }, [openDayForm])

  const editDay = React.useCallback((day: CalendarDay) => {
    setEditedDay(day)
    openDayForm()
  }, [openDayForm])

  const createItemSemaphore = React.useMemo(() => new Semaphore({autoReset: true}), [])

  const createItem = React.useCallback(async (model: CalendarItemFormModel) => {
    setCreateItemFormModel(model)
    await createItemSemaphore
  }, [createItemSemaphore])

  const endCreateItem = React.useCallback(() => {
    closeItemForm()
    createItemSemaphore.signal()
  }, [closeItemForm, createItemSemaphore])

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        <CalendarPlannerHeader
          requestAddDay={openDayForm}
        />
        <CalendarCanvas
          loading={planner.service.starting ?? false}
          requestAddDay={addDay}
          requestEditDay={editDay}
          requestCreateItem={createItem}
        />

        {renderDayForm()}
        {renderCreateItemForm()}
        {renderInsightsDialog()}

        <CalendarItemDetailForm/>
        {renderTriggerableForm()}
      </VBox>
    )
  }

  function renderDayForm() {
    return (
      <CalendarDayForm
        open={dayFormOpen}
        requestClose={closeDayForm}
        day={editedDay}
      />
    )
  }

  function renderCreateItemForm() {
    if (currentCreateItemFormModel == null) { return }

    return (
      <CalendarItemForm
        open={createItemFormOpen}
        requestClose={endCreateItem}
        model={currentCreateItemFormModel}
      />
    )
  }

  //------
  // Triggerable

  const detail      = planner.itemDetail
  const itemUUID    = planner.editingItemUUID
  const item        = itemUUID == null ? null : planner.plan?.findItem(itemUUID)
  const editingItem = item?.type === 'triggerable' && detail === 'content' ? item : null
  const [module]    = useModelDocumentData(Module, planner.plan?.module ?? null)

  const [editFormOpen, currentEditingItem, closeEditForm] = useFormOpen(editingItem, () => {
    planner.stopEditingItem()
  })

  const actionTriggerableFormModel = React.useMemo(() => {
    if (planner.plan == null || module == null || item == null) { return null }
    if (item.type !== 'triggerable') { return null }
    if (item.triggerable.type !== 'action') { return null }

    const action = plannerStore.getAction(item.triggerable.action)
    if (action == null) { return null }

    const day = planner.plan?.days.find(it => it.items.includes(item))
    if (day == null) { return null }

    return new CalendarTriggerableItemFormModel(
      planner,
      day.uuid,
      item.uuid,
      item.triggerable,
      'action',
      item.time,
      module,
      null,
      action,
    )
  }, [item, module, planner])

  function renderTriggerableForm() {
    if (currentEditingItem == null) { return null }

    if (actionTriggerableFormModel != null) {
      return (
        <ActionTriggerableForm
          open={editFormOpen}
          requestClose={closeEditForm}
          model={actionTriggerableFormModel}
        />
      )
    } else {
      return renderModelTriggerableDialog(currentEditingItem.triggerable, {
        open:         editFormOpen,
        requestClose: closeEditForm,
      })
    }
  }

  //------
  // Who is here

  const focusedInsightsDayUUID = planner.focusedInsightsDayUUID
  const [insightsDialogOpen, currentInsightsDayUUID, closeInsightsDialog] = useFormOpen(focusedInsightsDayUUID, () => {
    planner.setFocusedInsightsDayUUID(null)
  })

  function renderInsightsDialog() {
    if (currentInsightsDayUUID == null) { return null }

    return (
      <CalendarPlannerInsightsDialog
        open={insightsDialogOpen}
        requestClose={closeInsightsDialog}
        dayUUID={currentInsightsDayUUID}
      />
    )
  }


  return render()

})