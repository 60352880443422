import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import Toast from 'react-toast'
import { observer } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import ForgotPasswordForm from './ForgotPasswordForm'
import ForgotPasswordFormModel from './ForgotPasswordFormModel'

export interface Props extends RouteComponentProps {}

const ForgotPasswordScreen = observer('ForgotPasswordScreen', (props: Props) => {

  const {history} = props

  const [t] = useTranslation('auth')

  const formModel = React.useMemo(() => {
    const search = new URLSearchParams(document.location.search)
    return new ForgotPasswordFormModel(search.get('email'))
  }, [])

  const goBack = React.useCallback(() => {
    history.replace('/login')
  }, [history])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'ok') {
      Toast.show({
        type: 'success',
        ...t('forgot_password.success'),
      })
      goBack()
    }
  }, [goBack, t])

  //------
  // Rendering

  function render() {
    return (
      <ForgotPasswordForm
        model={formModel}
        afterSubmit={afterSubmit}
        onBackTap={goBack}
      />
    )
  }

  return render()

})

export default ForgotPasswordScreen