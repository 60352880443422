import React from 'react'
import { AutofocusProvider } from 'react-autofocus'
import { clamp } from 'lodash'
import { memo } from '~/ui/component'
import { HBox, PushButton } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { SubmitResult } from '~/ui/form'
import { assignRef, useContinuousRef, useRefMap } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import { FormContext } from '../FormContext'
import FormDialog, { Props as FormDialogProps } from '../FormDialog'
import { FormModel } from '../types'
import AssistantFormBody, { isAssistantFormModel } from './AssistantFormBody'
import { AssistantFormContext } from './AssistantFormContext'
import AssistantFormStepsPanel from './AssistantFormStepsPanel'

export interface Props<M extends FormModel, S extends string> extends Omit<FormDialogProps<M>, 'children'> {
  steps:          S[]

  currentStep:    S
  requestStep: (step: S) => SubmitResult | undefined | void

  iconForStep?:           (step: S) => SVGName
  captionForStep:         (step: S) => string
  nextButtonCaption?:     (step: S) => string | null | undefined
  previousButtonCaption?: (step: S) => string | null | undefined
  renderStep:             (step: S) => React.ReactNode

  showStepList?:            boolean
  allowSelectStep?: 'all' | 'previous' | 'none'

  showCancelButtonOnLastStep?: boolean
}

const _AssistantFormDialog = <M extends FormModel, S extends string>(props: Props<M, S>) => {

  const {
    steps,
    currentStep,
    requestStep,
    captionForStep,
    nextButtonCaption,
    previousButtonCaption,
    renderStep,
    showStepList: props_showStepList,
    allowSelectStep: props_allowSelectStep,
    semi,
    formRef: props_formRef,
    closeOnSuccess = true,
    showCancelButtonOnLastStep = true,
    ...rest
  } = props

  const {model: formModel} = props

  const ownFormRef = React.useRef<FormContext<M>>(null)

  const formRef = React.useCallback((form: FormContext<M> | null) => {
    assignRef(ownFormRef, form)
    assignRef(props_formRef, form)
  }, [props_formRef])

  const stepRefs   = useRefMap<S, HTMLDivElement>([steps.join(',')])

  const [stepAccessories, setStepAccessories] = React.useState<Partial<Record<S, React.ReactNode>>>({})
  const stepAccessoriesRef = useContinuousRef(stepAccessories)

  //-------
  // Navigation

  const currentStepIndex = steps.indexOf(currentStep)
  const isFirstStep      = currentStepIndex === 0
  const isLastStep       = currentStepIndex === steps.length - 1

  const goToPreviousStep = React.useCallback(() => {
    const index = clamp(currentStepIndex - 1, 0, steps.length - 1)
    requestStep(steps[index])
  }, [currentStepIndex, requestStep, steps])

  const goToNextStep = React.useCallback(() => {
    const index = clamp(currentStepIndex + 1, 0, steps.length - 1)
    const result = requestStep(steps[index])
    if (result == null) { return }

    if (result.status === 'invalid') {
      ownFormRef.current?.clearErrors()
      for (const error of result.errors) {
        ownFormRef.current?.addError(error)
      }
    }
  }, [currentStepIndex, requestStep, steps])

  const beforeSubmit = React.useCallback(() => {
    setStepAccessories({})

    if (currentStepIndex < steps.length - 1) {
      goToNextStep()
      return false
    } else {
      return true
    }
  }, [currentStepIndex, goToNextStep, steps.length])

  //------
  // Context

  const [state_showStepList, setShowStepList] = React.useState<boolean>(props_showStepList ?? true)
  const showStepList = props_showStepList ?? state_showStepList

  const [state_allowSelectStep, setAllowSelectStep] = React.useState<'all' | 'previous' | 'none'>(props_allowSelectStep ?? 'previous')
  const allowSelectStep = props_allowSelectStep ?? state_allowSelectStep

  const getStepContaining = React.useCallback((element: Element) => {
    for (const [step, container] of stepRefs.entries()) {
      if (container.contains(element)) {
        return step
      }
    }

    return null
  }, [stepRefs])

  const setStepAccessory = React.useCallback((step: S, accesory: React.ReactNode) => {
    setStepAccessories(stepAccessoriesRef.current = {
      ...stepAccessoriesRef.current,
      [step]: accesory,
    })
  }, [stepAccessoriesRef])


  const context = React.useMemo((): AssistantFormContext<S> => ({
    showStepList,
    setShowStepList,

    allowSelectStep,
    setAllowSelectStep,

    getStepContaining,
    requestStep,
    setStepAccessory,
  }), [allowSelectStep, getStepContaining, requestStep, setAllowSelectStep, setStepAccessory, showStepList])

  const onDidClose = React.useCallback(() => {
    requestStep(steps[0])
    setShowStepList(true)
    setAllowSelectStep('previous')
    props.onDidClose?.()
  }, [requestStep, steps, setAllowSelectStep, props])

  //------
  // Rendering

  function render() {
    return (
      <AssistantFormContext.Provider value={context}>
        <FormDialog
          semi={semi}
          renderButtons={renderButtons}
          closeOnSuccess={closeOnSuccess}
          {...rest}
          onDidClose={onDidClose}
          beforeSubmit={beforeSubmit}
          formRef={formRef}
          renderDrawer={renderStepsPanel}
          drawerWidth={stepsPanelWidth}
          drawerSide='left'
          drawerExpanded={showStepList}
          children={renderContent()}
          trapFocus={false}
        />
      </AssistantFormContext.Provider>
    )
  }

  function renderStepsPanel() {
    return (
      <AutofocusProvider enabled={false}>
        <AssistantFormStepsPanel
          {...props}
          accessories={stepAccessories}
        />
      </AutofocusProvider>
    )
  }

  function renderContent() {
    return (
      <AssistantFormBody
        {...props}
        stepRefs={stepRefs}
      />
    )
  }

  //------
  // Form buttons

  function renderButtons(saveButton: React.ReactNode, cancelButton: React.ReactNode) {
    return (
      <HBox justify='right' gap={layout.padding.s}>
        {!isFirstStep && renderPreviousButton()}
        {isLastStep ? saveButton : renderNextButton()}
        {(!isLastStep || showCancelButtonOnLastStep) && cancelButton}
      </HBox>
    )
  }

  function renderPreviousButton() {
    if (allowSelectStep === 'none') { return null }

    const step    = steps[currentStepIndex - 1]
    const caption = previousButtonCaption?.(step) ?? captionForStep(step)

    return (
      <PushButton
        icon='arrow-left'
        iconSide='left'
        caption={caption}
        onTap={goToPreviousStep}
      />
    )
  }

  function renderNextButton() {
    const step        = steps[currentStepIndex + 1]
    const caption     = nextButtonCaption?.(step) ?? captionForStep(step)
    const mayContinue = isAssistantFormModel(formModel)
      ? formModel.mayContinueFromStep?.(steps[currentStepIndex]) ?? true
      : true

    return (
      <PushButton
        icon='arrow-right'
        iconSide='right'
        caption={caption}
        enabled={mayContinue}
        submit
      />
    )
  }

  return render()

}

const AssistantFormDialog = memo('AssistantFormDialog', _AssistantFormDialog) as typeof _AssistantFormDialog
export default AssistantFormDialog

export const stepsPanelWidth = 260