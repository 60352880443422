import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { TriggerNode } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, PopupMenuItem, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, shadows, ThemeProvider } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { useFlowPlanner } from '../../FlowPlannerContext'
import NodeViewKebabMenu from '../../nodes/NodeViewKebabMenu'

export interface Props {
  node:       TriggerNode

  icon?:      SVGName
  caption?:   React.ReactNode
  detail?:    React.ReactNode
  accessory?: React.ReactNode

  extraMenuItems?: PopupMenuItem[]
  onDoubleClick?:  () => any

  variant?:     'canvas' | 'list'
  interactive?: boolean
}

const TriggerNodeView = memo('TriggerNodeView', (props: Props) => {

  const {planner} = useFlowPlanner()
  const {trigger} = props.node

  const [t] = useTranslation('flow_planner')

  const {
    node,
    variant        = 'canvas',
    icon           = 'flash',
    caption        = node.name ?? t([`triggers.${trigger.type}.summary`, `triggers.${trigger.type}.caption`], {nsSeparator: '|'}),
    detail         = t(`triggers.${trigger.type}.detail`, {nsSeparator: '|', defaultValue: null}),
    accessory      = null,
    extraMenuItems = [],
    onDoubleClick,
    interactive = true,
  } = props

  React.useEffect(() => {
    planner.setLoadingComponent(node.uuid, false)
  }, [node.uuid, planner])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <HBox
          flex='grow'
          justify='center'
          classNames={$.triggerNodeView}
          gap={layout.padding.inline.l}
          onDoubleClick={onDoubleClick}
        >
          <HBox flex gap={layout.padding.inline.m}>
            {renderIcon()}
            {renderLabels()}
            {accessory}
          </HBox>
          {interactive && (
            <NodeViewKebabMenu
              node={node}
              extraItems={extraMenuItems}
            />
          )}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderIcon() {
    return (
      <SVG name={icon} size={layout.icon.m}/>
    )
  }

  function renderLabels() {
    return (
      <VBox classNames={$.labels} flex gap={-2}>
        {isReactText(caption) ? (
          <Label>
            {caption}
          </Label>
        ) : caption}
        {variant === 'canvas' && (isReactText(detail) ? (
          <Label tiny dim markup>
            {detail}
          </Label>
        ) : detail)}
      </VBox>
    )
  }

  return render()

})

export const borderRadius    = config.planner.minimumComponentSize('trigger').height / 2
export const accessoryHeight = 16

const useStyles = createUseStyles(theme => ({
  triggerNodeView: {
    borderRadius,

    background: theme.colors.bg.dark.alt,
    padding:    [layout.padding.inline.s, layout.padding.inline.xl],
    position:   'relative',
    whiteSpace: 'nowrap',
    boxShadow:  shadows.depth(1),
  },

  labels: {
    overflow:   'hidden',
  },

  accessory: {
    position:     'absolute',
    top:          -accessoryHeight / 2,
    right:        24,
    height:       accessoryHeight,
    borderRadius: accessoryHeight / 2,
  },
}))

export default TriggerNodeView