import React from 'react'
import { memo } from '~/ui/component'
import { CalendarShortcut } from './types'
import { PushButton } from '~/ui/components'
import { DateTime } from 'luxon'

interface Props {
  shortcut: CalendarShortcut
  onTap:    (date: DateTime | null) => any
  enabled:  boolean
}

const ShortcutButton = memo('ShortcutButton', (props: Props) => {

  const {shortcut, onTap, enabled} = props

  const tap = React.useCallback(() => {
    onTap(shortcut.date())
  }, [onTap, shortcut])

  return (
    <PushButton
      caption={shortcut.caption}
      onTap={tap}
      enabled={enabled}
    />
  )
})


export default ShortcutButton