import React from 'react'
import { isFunction, some } from 'lodash'
import { memo } from '~/ui/component'
import { Dimple, HBox, InfoIcon, Label, SVG, Tappable, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, presets, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { cellPadding } from './DataGridBar'
import { Props as DataGridColumnProps } from './DataGridColumn'
import { Sort, SortDirection } from './types'

export interface Props<T> {
  sort:        Sort | null | undefined
  requestSort: ((sort: Sort | null) => any) | undefined
  defaultSortDirection?: SortDirection | ((field: string) => SortDirection)

  columns: React.ReactElement<DataGridColumnProps<T>>[]
}

const DataGridHeader = memo('DataGridHeader', <T extends {}>(props: Props<T>) => {

  const {
    columns,
    sort,
    requestSort,
    defaultSortDirection,
  } = props

  const hasAccessoryCells = some(columns, col => col.props.renderHeaderAccessoryCell != null)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.dataGridHeader} gap={layout.padding.inline.s}>
        <HBox classNames={$.mainRow} align='stretch'>
          {columns.map(renderMainCell)}
        </HBox>
        {hasAccessoryCells && (
          <HBox classNames={$.accessoryRow} align='stretch'>
            {columns.map(renderAccessoryCell)}
          </HBox>
        )}
      </VBox>
    )
  }

  function renderMainCell(column: React.ReactElement<DataGridColumnProps<T>>, index: number) {
    const {name, padded = true, renderHeaderCell} = column.props

    return (
      <HBox key={name} classNames='DataGrid--cell' align='stretch'>
        {index > 0 && <Dimple vertical classNames={$.separator}/>}

        <VBox flex='auto' classNames={[$.headerCell, {padded}]}>
          {renderHeaderCell != null ? (
            renderHeaderCell()
          ) : (
            <StandardHeaderCell
              column={column}
              sort={sort}
              requestSort={requestSort}
              defaultSortDirection={defaultSortDirection}
            />
          )}
        </VBox>
      </HBox>
    )
  }

  function renderAccessoryCell(column: React.ReactElement<DataGridColumnProps<T>>, index: number) {
    const {name, renderHeaderAccessoryCell} = column.props

    return (
      <VBox key={name} classNames='DataGrid--cell' align='stretch'>
        {renderHeaderAccessoryCell?.()}
      </VBox>
    )
  }

  return render()

})

interface StandardHeaderCellProps<T> {
  column:      React.ReactElement<DataGridColumnProps<T>>
  sort:        Sort | null | undefined
  requestSort: ((sort: Sort | null) => any) | undefined
  defaultSortDirection?: SortDirection | ((field: string) => SortDirection)
}

const StandardHeaderCell = <T extends {}>(props: StandardHeaderCellProps<T>) => {

  const {column, sort, requestSort, defaultSortDirection: props_defaultSortDirection} = props
  const {name, caption, instruction, align = 'stretch'} = column.props
  const textAlign = align === 'stretch' ? 'left' : align

  const sortField =
    (column.props.sort == null || column.props.sort === false) ? null :
    column.props.sort === true ? name :
    column.props.sort

  const sortable  = sortField != null
  const sorted    = sortField != null && sort?.field === sortField
  const direction = sort?.direction === -1 ? 'descending' : 'ascending'

  const defaultSortDirection = React.useMemo(() => {
    if (sortField == null) { return 1 }

    if (isFunction(props_defaultSortDirection)) {
      return props_defaultSortDirection(sortField)
    } else if (props_defaultSortDirection != null) {
      return props_defaultSortDirection
    } else {
      return 1
    }
  }, [props_defaultSortDirection, sortField])

  const {colors} = useStyling()

  const $ = useStyles()

  function render() {
    if (caption == null) { return null }

    const classNames = [
      $.standardHeaderCell,
      {sortable, sorted},
      direction,
    ]

    if (sortable) {
      return (
        <Tappable flex classNames={classNames} onTap={sortOnColumn}>
          {renderContent()}
        </Tappable>
      )
    } else {
      return (
        <VBox flex classNames={classNames} align={align}>
          {renderContent()}
        </VBox>
      )
    }
  }

  function renderContent() {
    return (
      <HBox flex gap={layout.padding.inline.s}>
        <HBox flex gap={layout.padding.inline.s}>
          <VBox flex='shrink' align={align}>
            {isReactText(caption) ? (
              <Label bold align={textAlign}>
                {caption}
              </Label>
            ) : (
              caption
            )}
          </VBox>
          {sorted && (
            <SVG
              name={`sort-${direction}` as SVGName}
              size={layout.icon.xs}
              color={colors.semantic.primary}
            />
          )}
        </HBox>
        {instruction != null && (
          <InfoIcon
            renderTooltip={instruction}
          />
        )}
      </HBox>
    )
  }

  const sortOnColumn = React.useCallback(() => {
    if (sortField == null) { return }

    if (sortField === sort?.field) {
      requestSort?.({field: sortField, direction: -sort.direction as SortDirection})
    } else {
      requestSort?.({field: sortField, direction: defaultSortDirection})
    }
  }, [defaultSortDirection, requestSort, sort, sortField])

  return render()

}

export default DataGridHeader

export const mainRowHeight = layout.barHeight.xs

const useStyles = createUseStyles(theme => ({
  dataGridHeader: {
    padding: [0, 2],
  },

  mainRow: {
    height:       mainRowHeight,
    background:   theme.inverse.bg.alt.alpha(0.05),
    borderRadius: [layout.radius.m, layout.radius.m, 0, 0],

    overflow: 'hidden',
  },

  accessoryRow: {

  },

  headerCell: {
    overflow: 'hidden',

    '&.padded': {
      ...cellPadding,
      paddingTop:    0,
      paddingBottom: 0,
    },
  },

  separator: {
    padding: '0 !important',
  },

  standardHeaderCell: {
    padding: [layout.padding.inline.s, 0],

    '&.sortable': {
      position: 'relative',
      '&:hover': {
        ...presets.overlayAfter({
          background: theme.colors.bg.light.active,
        }),
      },
    },
  },
}))