import React from 'react'
import { QAndA } from '~/models'
import { memo } from '~/ui/component'
import { Chip, DataGrid, HBox, Label, SVG } from '~/ui/components'
import { ResourceListScreen } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import QAndAForm from '../QAndAForm'

const QAndAsScreen = memo('QAndAsScreen', () => {

  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={QAndA}
        CreateFormComponent={QAndAForm}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column<QAndA>
          name='questionCount'
          flex={2}
          renderCell={qAndA => <HBox><Chip>{qAndA.questionCount}</Chip></HBox>}
        />
      </ResourceListScreen>
    )
  }

  function renderName(qAndA: QAndA) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name='question'
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <Label bold>
          {qAndA.name}
        </Label>
      </HBox>
    )
  }

  return render()

})

export default QAndAsScreen