import { makeObservable, observable } from 'mobx'
import { BorderSpec, Branding } from '~/models'
import {
  angleToGradientDirection,
  GradientDirection,
  gradientDirectionToAngle,
} from '../util/gradients'
import BrandingAssetFormModel from './BrandingAssetFormModel'

export default class BrandingBorderFormModel extends BrandingAssetFormModel {

  constructor(branding: Branding, originalName: string | null) {
    super(branding, originalName)

    makeObservable(this)
  }

  public readonly assetType = 'border'

  private readonly spec: BorderSpec | null = this.originalName == null ? null : this.branding.borders[this.originalName] ?? null

  @observable
  public type: BorderSpec['type'] = this.spec?.type ?? 'solid'

  @observable
  public width: number = this.spec?.width ?? 1

  @observable
  public position: BorderSpec['position'] = this.spec?.position ?? 'inside'

  @observable
  public offset: BorderSpec['offset'] = this.spec?.offset ?? 0

  @observable
  public color: string = (this.spec?.type === 'solid' ? this.spec.color : null) ?? 'primary'

  @observable
  public startColor: string = (this.spec?.type === 'gradient' ? this.spec.colors[0] : null) ?? 'primary'

  @observable
  public endColor: string = (this.spec?.type === 'gradient' ? this.spec.colors[1] : null) ?? 'secondary'

  @observable
  public direction: GradientDirection = this.spec?.type === 'gradient' ? angleToGradientDirection(this.spec.angle) : GradientDirection.NESW

  public reset() {
    super.reset()
    this.type  = this.spec?.type ?? 'solid'
    this.width  = this.spec?.width ?? 1
    this.position  = this.spec?.position ?? 'inside'
    this.offset  = this.spec?.offset ?? 0
    this.color = (this.spec?.type === 'solid' ? this.spec.color : null) ?? 'primary'
    this.startColor = (this.spec?.type === 'gradient' ? this.spec.colors[0] : null) ?? 'primary'
    this.endColor = (this.spec?.type === 'gradient' ? this.spec.colors[1] : null) ?? 'secondary'
    this.direction = this.spec?.type === 'gradient' ? angleToGradientDirection(this.spec.angle) : GradientDirection.NESW
  }

  public buildSpec(): BorderSpec {
    if (this.type === 'solid') {
      return {
        type:     'solid',
        color:    this.color,
        width:    this.width,
        position: this.position,
        offset:   this.offset,
      }
    } else {
      return {
        type:     'gradient',
        colors:   [this.startColor, this.endColor],
        angle:    gradientDirectionToAngle(this.direction),
        width:    this.width,
        position: this.position,
        offset:   this.offset,
      }
    }
  }

}