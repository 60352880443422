import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Page } from '~/models'
import { TargetingForm, TargetingFormModel } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { SwitchField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import PagesContext from './PagesContext'

export interface Props {
  open:          boolean
  requestClose?: () => any
  formModel:     PageTargetingFormModel
}

const PageTargetingForm = memo('PageTargetingForm', (props: Props) => {

  const {open, requestClose, formModel} = props

  const {t, fieldLabel, fieldCaption} = useResourceTranslation('pages')

  //------
  // Rendering

  function render() {
    return (
      <TargetingForm
        open={open}
        requestClose={requestClose}
        model={formModel}
        instructions={t('targeting.instructions')}
        children={renderLockedField()}
      />
    )
  }

  function renderLockedField() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <FormFieldHeader
          icon='lock'
          caption={fieldCaption('locking')}
        />
        <FormField name='locked' caption={false} label={fieldLabel('locked')}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default PageTargetingForm

export class PageTargetingFormModel extends TargetingFormModel {

  constructor(
    private readonly page: Page,
    private readonly context: PagesContext,
  ) {
    super(page.targeting)
    this.locked = page.locked

    makeObservable(this)
  }

  @observable
  public locked: boolean

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.context.updatePage(this.page.id, {
      targeting: this.buildTargeting(),
      locked:    this.locked,
    })
  }

}