import React from 'react'
import { useTranslation } from 'react-i18next'
import { User } from '~/models'
import { authenticationStore, projectStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'

export interface Props extends Omit<ResourceFieldProps<User>, 'Model' | 'shortcutsCaption' | 'shortcutIDs'> {
  sameOrganisation?: boolean
}

const UserField = observer('UserField', (props: Props) => {

  const [t] = useTranslation('user_field')

  const {sameOrganisation = true, ...rest} = props

  const ownID          = authenticationStore.userID
  const organisationID = projectStore.project?.organisation

  //------
  // Rendering

  function render() {
    return (
      <ResourceField
        {...rest as any}
        Model={User}
        renderPropsForModel={renderPropsForModel}
        placeholder={t('placeholder')}
        filters={sameOrganisation ? {organisation: organisationID} : {}}
        shortcutsCaption={ownID == null ? undefined : t('me')}
        shortcutIDs={ownID == null ? undefined : [ownID]}
      />
    )
  }

  const renderAvatar = React.useCallback((user: User) => {
    return (
      <Avatar
        source={user.photoURL}
        firstName={user.name}
        size={avatarSize}
      />
    )
  }, [])

  const renderPropsForModel = React.useCallback((user: User) => {
    return {
      image: renderAvatar(user),
    }
  }, [renderAvatar])

  return render()

})

export default UserField

export const avatarSize = {
  width:  32,
  height: 32,
}