import React from 'react'
import { FileRejection } from 'react-dropzone'
import { useTimer } from 'react-timer'
import config from '~/config'
import { Media, SVGImage } from '~/models'
import { mediaStore, MediaUpload, StoreMediaOptions, UploadProgress } from '~/stores'
import { forwardRef } from '~/ui/component'
import Uploader, { Props as UploaderProps, UploaderState } from '~/ui/components/Uploader'
import { createUseStyles, layout } from '~/ui/styling'
import processMediaUpload from './processMediaUpload'

export interface Props extends Omit<UploaderProps<Media | SVGImage>, 'accept' | 'upload'> {
  accept?:   string | string[]
  filename?: string

  storeMediaOptions?: StoreMediaOptions
  onUploadComplete?:  (media: Media | SVGImage) => any
  onUploadError?:     (error: Error) => any

  renderContent:   (state: MediaUploaderState) => React.ReactNode
  reportProgress?: boolean

  objectFit?:  React.CSSProperties['objectFit']
  previewURL?: string | null
  previewAlt?: string | null
  inlineSVG?:  boolean
}

export interface UploadData {
  contentType: string
  base64:      string
  prefix?:     string
  filename?:   string
}

export interface MediaUploaderState extends UploaderState {
  progress?: UploadProgress | null
}

export type RejectReason = 'invalid-type' | 'too-large'

interface MediaUploader extends Uploader {
  cancel(): void
}

const MediaUploader = forwardRef('MediaUploader', (props: Props, ref: React.Ref<MediaUploader>) => {

  const {
    accept,
    maxSize = config.mediaUploader.maxFileSize,
    filename,
    storeMediaOptions,
    objectFit = 'contain',
    previewURL: props_previewURL,
    renderPreview: props_renderPreview,
    previewAlt = null,
    renderContent,
    reportProgress = false,
    onDropRejected,
    inlineSVG,
    ...rest
  } = props

  //------
  // Uploading

  const timer = useTimer()
  const [progress, setProgress] = React.useState<UploadProgress | null>(null)
  const [mediaUpload, setMediaUpload] = React.useState<MediaUpload | null>(null)

  const uploadFile = React.useCallback(async (file: File): Promise<Media | null> => {
    try {
      const mediaUpload = mediaStore.storeMedia(filename ?? file.name, file, storeMediaOptions)
      if (reportProgress) {
        mediaUpload?.addProgressListener(setProgress)
      }

      setMediaUpload(mediaUpload)
      const result = await timer.await(mediaUpload)
      return processMediaUpload(result, maxSize)
    } finally {
      setMediaUpload(null)
      setProgress(null)
    }
  }, [filename, maxSize, reportProgress, storeMediaOptions, timer])

  const processInlineSVG = React.useCallback(async (file: File): Promise<SVGImage | null> => {
    return {
      type:   'svg',
      base64: await readFileAsBase64(file),
    }
  }, [])

  const upload = React.useCallback(async (file: File) => {
    if (inlineSVG && file.type === 'image/svg+xml') {
      return processInlineSVG(file)
    } else {
      return uploadFile(file)
    }
  }, [inlineSVG, processInlineSVG, uploadFile])

  const handleDropRejected = React.useCallback((rejections: FileRejection[], event) => {
    const error = rejections[0]?.errors?.[0]
    if (error.code === 'file-too-large') {
      processMediaUpload({status: 'invalid', reason: 'too-large'})
    }
    onDropRejected?.(rejections, event)
  }, [onDropRejected])

  const cancel = React.useCallback(() => {
    mediaUpload?.cancel()
  }, [mediaUpload])

  const uploaderRef = React.useRef<Uploader>(null)

  React.useImperativeHandle(ref, () => ({
    cancel,
    browse: () => { uploaderRef.current?.browse() },
  }), [cancel])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Uploader
        {...rest}
        maxSize={maxSize}
        renderContent={renderContentWithProgress}
        accept={accept}
        ref={uploaderRef}
        upload={upload}
        renderPreview={renderPreview}
        showIdleHint={props.showIdleHint ?? (props_previewURL == null)}
        onDropRejected={handleDropRejected}
      />
    )
  }

  const renderContentWithProgress = React.useCallback((state: UploaderState) => {
    if (reportProgress) {
      return renderContent({...state, progress})
    } else {
      return renderContent(state)
    }
  }, [progress, renderContent, reportProgress])

  function renderPreview(uploadPreviewURLs: string[] | null) {
    if (props_renderPreview != null) {
      return props_renderPreview(uploadPreviewURLs)
    }

    const previewURL = uploadPreviewURLs?.[0] ?? props_previewURL
    if (previewURL == null) { return null }

    return (
      <img
        classNames={[$.preview, objectFit]}
        src={previewURL}
        alt={previewAlt ?? undefined}
      />
    )
  }

  return render()

})

function readFileAsBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = () => reject(reader.error)
    reader.onload  = () => {
      const dataURI = reader.result as string
      resolve(dataURI.replace(/^data:.*;base64,/, ''))
    }
    reader.readAsDataURL(file)
  })
}

export default MediaUploader

const useStyles = createUseStyles(theme => ({
  preview: {
    ...layout.overlay,
    width:  '100%',
    height: '100%',

    borderRadius: layout.radius.s,

    '&.contain': {objectFit: 'contain'},
    '&.cover':   {objectFit: 'cover'},
  },
}))