import React from 'react'
import Color from 'color'
import { memo } from '~/ui/component'
import {
  Center,
  HBox,
  KebabMenu,
  Label,
  Panel,
  panelBorderRadius,
  PopupMenuItem,
  Spinner,
  SVG,
  VBox,
} from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, shadows, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props {
  icon:       SVGName
  title:      React.ReactNode
  detail?:    React.ReactNode
  accessory?: React.ReactNode
  bandColor?: Color

  fetching?:    boolean
  notFound?:    boolean
  placeholder?: boolean

  focused?:    boolean
  focusLevel?: FocusLevel
  selected?:   boolean

  kebabMenuItems?: PopupMenuItem[]
  requestEdit?:   () => any

  small?:       boolean
  onTap?:       () => any
  interactive?: boolean
}

export type FocusLevel = 'error' | 'warning'

const TriggerableBar = memo('TriggerableBar', (props: Props) => {

  const {
    icon,
    title,
    detail,
    accessory,
    bandColor,
    fetching,
    notFound,
    placeholder,
    focused,
    focusLevel,
    selected,
    kebabMenuItems,
    requestEdit,
    small = false,
    onTap,
    interactive = true,
  } = props

  const {colors}   = useStyling()

  const backgroundColor =
    notFound ? colors.semantic.negative :
    placeholder ? colors.named.yellow :
    undefined

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel
        flex='grow'
        semi={false}
        bandColor={bandColor}
        backgroundColor={backgroundColor}
        classNames={[$.triggerableBar, {notFound, focused, selected, small, interactive}, focused ? focusLevel : null]}
        contentClassNames={$.barContent}
        onTap={onTap}
        onDoubleClick={requestEdit}
      >
        {renderContent()}
      </Panel>
    )
  }

  function renderContent() {
    return (
      <HBox flex='grow' gap={layout.padding.s}>
        <SVG
          name={icon}
          size={layout.icon.m}
        />
        {fetching ? (
          <VBox flex>
            <Spinner size={12}/>
          </VBox>
        ) : (
          <VBox flex='both'>
            {isReactText(title) ? (
              <Label h3={!small} caption={small}>
                {title}
              </Label>
            ) : (
              title
            )}
            {!small && (
              isReactText(detail) ? (
                <Label tiny dim markup>
                  {detail}
                </Label>
              ) : detail
            )}
          </VBox>
        )}
        <HBox gap={layout.padding.inline.s}>
          {accessory && (
            <Center>
              {accessory}
            </Center>
          )}
          {renderKebabMenu()}
        </HBox>
      </HBox>
    )
  }

  function renderKebabMenu() {
    if (!interactive) { return null }
    if (kebabMenuItems == null || kebabMenuItems.length === 0) { return null }

    return (
      <KebabMenu
        items={kebabMenuItems}
        small
      />
    )
  }

  return render()

})

export default TriggerableBar

export const minHeight = {
  small:  layout.barHeight.s,
  normal: layout.barHeight.m,
}

const useStyles = createUseStyles(theme => ({
  triggerableBar: {
    '&.notFound': {
      background: theme.semantic.negative,
    },

    '&.focused.error': {
      background: theme.semantic.negative,
    },
    '&.focused.warning': {
      background: theme.semantic.warning,
    },
    '&.selected': {
      boxShadow: shadows.selected(theme),
    },

    '&:not(.notFound):hover::after': {
      content: '""',
      ...layout.overlay,
      pointerEvents: 'none',
      borderRadius:  panelBorderRadius(theme),
      background:    theme.bg.hover,
    },

    '&.interactive': {
      cursor: 'pointer',
    },
  },

  barContent: {
    padding:      [layout.padding.inline.s, layout.padding.inline.l],
    paddingRight: layout.padding.inline.m,
    minHeight:    minHeight.normal,

    '$triggerableBar.small &': {
      padding:   [layout.padding.inline.s, layout.padding.inline.l],
      minHeight: minHeight.small,
    },
  },
}))