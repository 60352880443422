import React from 'react'
import { useTranslation } from 'react-i18next'
import Toast from 'react-toast'
import QRCode from 'qrcode.react'
import clipboard from 'rich-clipboard'
import { ClientApp, TriggerNode } from '~/models'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Dimple,
  HBox,
  Label,
  ModalDialog,
  NumberField,
  Panel,
  Spinner,
  SwitchField,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { createUseStyles, layout } from '~/ui/styling'
import { saveAs } from '~/ui/util'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  open:         boolean
  requestClose: () => any

  node: TriggerNode
}

const TriggerLinkDialog = observer('TriggerLinkDialog', (props: Props) => {

  const {open, requestClose, node} = props
  const {plan} = useFlowPlanner()

  const isQRCode = node.trigger.type === 'qrcode'
  const project  = projectStore.project
  const moduleID = plan?.module

  const [app, {fetchStatus}] = useModelDocumentData(ClientApp, project?.app ?? null)

  const [absoluteURL, setAbsoluteURL] = React.useState<boolean>(true)
  const [pngSize, setPngSize]         = React.useState<number>(512)

  const triggerURL = absoluteURL && app != null
    ? `${app.urlScheme}://trigger/${moduleID}/${node.uuid}`
    : `//trigger/${moduleID}/${node.uuid}`

  const [t] = useTranslation('flow_planner')

  //------
  // Actions

  const $ = useStyles()

  const containerRef = React.useRef<HTMLDivElement>(null)

  const downloadAsPNG = React.useCallback(() => {
    const canvas = containerRef.current?.querySelector(`.${$.qrCanvas}`)
    if (!(canvas instanceof HTMLCanvasElement)) { return }

    const data = canvas.toDataURL('image/png')
    saveAs(data, 'qrcode.png')
  }, [$.qrCanvas])

  const downloadAsSVG = React.useCallback(() => {
    const svg = containerRef.current?.querySelector(`.${$.qrSVG}`)
    if (svg == null) { return }

    const svgData = svg.outerHTML
    const blob    = new Blob([svgData])
    saveAs(blob, 'qrcode.svg')
  }, [$.qrSVG])

  const copyToClipboard = React.useCallback(() => {
    clipboard.write([{type: 'text/plain', data: triggerURL}])
    Toast.show({
      ...t('trigger_link_dialog.copy_to_clipboard.success'),
      type: 'success',
    })
  }, [t, triggerURL])

  //------
  // Rendering

  function render() {
    if (moduleID == null) { return null }

    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        icon={isQRCode ? 'qrcode' : 'flash'}
        title={t('trigger_link_dialog.title')}
        width={640}
        headerRight='$close'
        closeOnClickOutside={true}
        autoFocus={`.${$.link} input`}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox padding={layout.padding.m} gap={layout.padding.m}>
        {renderQRCode()}
        {isQRCode && <Dimple horizontal/>}
        {renderLink()}
      </VBox>
    )
  }

  function renderQRCode() {
    if (!isQRCode) { return null }

    return (
      <VBox gap={layout.padding.s}>
        {renderInstructions('qrcode')}
        <HBox gap={layout.padding.s} align='stretch'>
          <Panel semi={false} padding={layout.padding.m}>
            {renderQRCodes()}
          </Panel>
          <VBox flex gap={layout.padding.s}>
            <HBox gap={layout.padding.inline.s}>
              <ClearButton
                icon='image'
                caption={t('trigger_link_dialog.download_as_png')}
                padding='both'
                onTap={downloadAsPNG}
              />
              <VBox width={120}>
                <NumberField
                  value={pngSize}
                  onChange={value => setPngSize(value ?? 512)}
                  showClearButton='never'
                  accessoryRight={<Label small dim>{`x${pngSize}px`}</Label>}
                  step={16}
                  small
                />
              </VBox>
            </HBox>
            <HBox>
              <ClearButton
                icon='download'
                caption={t('trigger_link_dialog.download_as_svg')}
                padding='both'
                onTap={downloadAsSVG}
              />
            </HBox>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  function renderQRCodes() {
    if (absoluteURL && fetchStatus === 'fetching') {
      return (
        <Center flex classNames={$.qrCodeContainer}>
          <Spinner size={12}/>
        </Center>
      )
    } else {
      return (
        <VBox classNames={$.qrCodeContainer} ref={containerRef}>
          <QRCode
            classNames={$.qrSVG}
            value={triggerURL}
            size={128}
            renderAs='svg'
          />
          <QRCode
            classNames={$.qrCanvas}
            value={triggerURL}
            size={pngSize}
            renderAs='canvas'
          />
        </VBox>
      )
    }
  }

  function renderLink() {
    return (
      <VBox gap={layout.padding.s}>
        {renderInstructions('link')}
        <HBox gap={layout.padding.s}>
          <VBox flex>
            <TextField
              value={triggerURL}
              classNames={$.link}
              selectOnFocus
              readOnly
              mono
            />
          </VBox>
          <ClearButton
            icon='copy'
            caption={t('trigger_link_dialog.copy_to_clipboard.caption')}
            onTap={copyToClipboard}
          />
        </HBox>
        <SwitchField
          value={absoluteURL}
          onChange={setAbsoluteURL}
          label={t('trigger_link_dialog.absolute')}
        />
      </VBox>
    )
  }

  function renderInstructions(which: string) {
    return (
      <TextBlock small dim>
        {t(`trigger_link_dialog.instructions.${which}`)}
      </TextBlock>
    )
  }

  return render()

})

export default TriggerLinkDialog

const useStyles = createUseStyles({
  link: {},

  qrCodeContainer: {
    width:  128,
    height: 128,
  },

  qrSVG: {},

  qrCanvas: {
    display: 'none',
  },
})