import React from 'react'
import { AnswersState } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, EmptyOrFetching, Panel, PanelHeader, VBox } from '~/ui/components'
import { useQuestion } from '../../hooks'
import renderResults from '../../results'
import { ResultStyle } from '../../types'

export interface Props {
  state:     AnswersState
  onRefresh: (questionUUID: string) => any
}

const AnswersQueryResults = observer('AnswersQueryResults', (props: Props) => {

  const {state, onRefresh} = props
  const [question, fetchStatus] = useQuestion(state.question)

  const refresh = React.useCallback(() => {
    onRefresh(state.question.uuid)
  }, [state.question.uuid, onRefresh])

  //------
  // Rendering

  function render() {
    return fetchStatus === 'done' ? renderPanel() : renderEmpty()
  }

  function renderPanel() {
    return(
      <Panel
        header={renderHeader()}
        flex='grow'
        children={renderContent()}
      />
    )
  }

  function renderHeader() {
    return(
      <PanelHeader
        icon='question'
        caption={question?.caption}
        right={renderRefreshButton()}
      />
    )
  }

  function renderRefreshButton() {
    return (
      <ClearButton
        icon='reload'
        onTap={refresh}
      />
    )
  }

  function renderContent() {
    if (question == null) { return null }
    return(
      <VBox flex>
        {renderResults(state.results, ResultStyle.defaultForFeedback(question.feedback))}
      </VBox>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching status={fetchStatus} />
    )
  }

  return render()

})

export default AnswersQueryResults