import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { upperFirst } from 'lodash'
import { ClientApp } from '~/models'
import { observer } from '~/ui/component'
import { Empty, HBox, panelBorderRadius, PushButton, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelDocumentData, useModelEndpoint } from '~/ui/hooks/data'
import { AppLayoutConfig, Breadcrumb } from '~/ui/layouts/app'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import ClientAppDetail from './ClientAppDetail'
import ClientAppsList from './ClientAppsList'
import NewClientAppForm from './NewClientAppForm'

export interface Params {
  id?: string
}

export type Props = RouteComponentProps<Params>

const ClientAppsScreen = observer('ClientAppsScreen', (props: Props) => {

  const {id} = props.match.params
  const {t, plural} = useResourceTranslation('client-apps')

  const [newAppFormOpen, openNewAppForm, closeNewAppForm] = useBoolean()

  const endpoint = useModelEndpoint(ClientApp)

  const [clientApp] = useModelDocumentData(ClientApp, id ?? null, {fetch: 'never'})

  const breadcrumbs = React.useMemo(() => {
    const breadcrumbs: Breadcrumb[] = []

    breadcrumbs.push({
      icon:    'iphone',
      caption: upperFirst(plural()),
      href:   '/client-apps',
    })

    if (clientApp != null) {
      breadcrumbs.push({
        caption: clientApp.id,
      })
    }

    return breadcrumbs
  }, [clientApp, plural])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox flex align='stretch' gap={layout.padding.l} classNames={$.container}>
        <AppLayoutConfig
          configKey='client-apps'
          breadcrumbs={breadcrumbs}
          ActionsComponent={renderActions}
        />

        <VBox flex={2}>
          <ClientAppsList endpoint={endpoint}/>
        </VBox>
        <VBox flex={1}>
          {id == null ? (
            <Empty
              {...t('select_app')}
              icon='iphone'
              classNames={$.empty}
              flex
            />
          ) : (
            <ClientAppDetail
              oAuthProviders={endpoint.meta?.oAuthProviders}
              key={id}
              id={id}
            />
          )}
        </VBox>

        <NewClientAppForm
          open={newAppFormOpen}
          requestClose={closeNewAppForm}
        />
      </HBox>
    )
  }

  const renderActions = React.useCallback(() => {
    return (
      <PushButton
        icon='plus'
        caption={t('new')}
        onTap={openNewAppForm}
        small
      />
    )
  }, [openNewAppForm, t])

  return render()

})

export default ClientAppsScreen

const useStyles = createUseStyles(theme => ({
  container: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },
  empty: {
    borderRadius: panelBorderRadius(theme),
    border:       [1, 'solid', theme.fg.dimmer],
  },
}))