import { DateTime } from 'luxon'
import { serialize } from 'ymodel'
import { Model, resource } from './Model'

@resource<Post>('posts', {
  icon:    () => 'feed',
  caption: post => post.body ?? "(Media)",
  appLink: post => `//post/${post.id}`,
})
export class Post extends Model {

  public feed!:   string
  public root!:   string
  public parent!: string
  public author!: string

  public body!:  string | null
  public media!: string[]

  public reactions!:    PostReaction[]
  public commentCount!: number

  public moderatedBy!: string

  @serialize(DateTime, {path: 'editedAt'})
  public edits!:       PostEdit[]

  @serialize(DateTime)
  public publishedAt!: DateTime | null

  @serialize(DateTime)
  public approvedAt!:  DateTime | null

  @serialize(DateTime)
  public redactedAt!:  DateTime | null

  public countReactions(): Record<string, number> {
    const counts = {} as Record<string, number>
    for (const reaction of this.reactions) {
      counts[reaction.reaction] ??= 0
      counts[reaction.reaction] += 1
    }
    return counts
  }

}

export interface PostReaction {
  participant: string
  reaction:    string
}

export interface PostEdit {
  editedAt: DateTime
}