import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Challenge, ModelClass, ModuleParameter, ModuleParameterType, Script } from '~/models'
import { FormModel, SubmitResult } from '~/ui/form'

export default class ParameterFormModel implements FormModel {

  private constructor(
    public readonly parameter: ModuleParameter,
    public readonly isNew: boolean,
    private readonly saveParameter: (data: AnyObject) => Promise<SubmitResult | undefined>,
  ) {
    makeObservable(this)
  }

  public static editParameter(parameter: ModuleParameter, saveParameter: (data: AnyObject) => Promise<SubmitResult | undefined>) {
    return new ParameterFormModel(parameter, false, saveParameter)
  }

  public static addParameter(type: ModuleParameterType, saveParameter: (data: AnyObject) => Promise<SubmitResult | undefined>) {
    const parameter = ModuleParameter.default(type)
    return new ParameterFormModel(parameter, true, saveParameter)
  }

  //------
  // Common

  @observable
  public name: string = this.parameter.name

  @observable
  public type: ModuleParameterType = this.parameter.type

  @observable
  public default: any = this.parameter.default

  //------
  // String

  @observable
  public minLength: number | null = this.parameter.type === 'string' ? this.parameter.minLength : null

  @observable
  public maxLength: number | null = this.parameter.type === 'string' ? this.parameter.maxLength : null

  @observable
  public pattern: string | null = this.parameter.type === 'string' ? this.parameter.pattern : null

  //------
  // Number

  @observable
  public min: number | null = this.parameter.type === 'number' ? this.parameter.min : null

  @observable
  public max: number | null = this.parameter.type === 'number' ? this.parameter.max : null

  @observable
  public integer: boolean | null = this.parameter.type === 'number' ? this.parameter.integer : null

  //------
  // Triggerable

  public get TriggerableModel(): ModelClass<any> {
    if (this.type === 'challenge') {
      return Challenge
    } else {
      return Script
    }
  }

  @observable
  public tag: string | null = ModuleParameter.isTriggerableParameter(this.parameter) ? this.parameter.tag : null

  //------
  // Submit

  public async submit(): Promise<SubmitResult | undefined> {
    return this.saveParameter(this.buildParameter())
  }

  private buildParameter() {
    return {
      name:    this.name,
      type:    this.type,
      default: typeof this.default === 'string' ? cleanTextValue(this.default, true) : this.default,

      minLength: this.type === 'string' ? this.minLength : undefined,
      maxLength: this.type === 'string' ? this.maxLength : undefined,
      pattern:   this.type === 'string' ? this.pattern : undefined,

      min:     this.type === 'number' ? this.min : undefined,
      max:     this.type === 'number' ? this.max : undefined,
      integer: this.type === 'number' ? this.integer : undefined,

      tag: ModuleParameter.isTriggerableParameter(this.parameter) ? this.tag : undefined,
    }
  }

}