import socket from 'socket.io-react'
import { Dashboard } from '~/models'
import { dataStore, ModelEndpoint } from '~/stores'
import AnalyticsService from './analytics/AnalyticsService'
import { register } from './support'

export class AnalyticsStore {

  // Expose a singleton endpoint for the user's dashboards.
  public _dashboards: ModelEndpoint<Dashboard> | null = null

  public get dashboards() {
    if (this._dashboards == null) {
      this._dashboards = new ModelEndpoint<Dashboard>(dataStore.db(Dashboard), Dashboard)
    }
    return this._dashboards
  }

  public onLogOut() {
    this._dashboards = null
  }

  public analyticsService(dashboardID: string) {
    return new AnalyticsService(socket, dashboardID)
  }

}

const analyticsStore = register(new AnalyticsStore())
export default analyticsStore