import React from 'react'
import { Module } from '~/models'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { memo } from '~/ui/component'
import { Chip, HBox, Label, TextBlock, TextField, VBox } from '~/ui/components'
import { FormField, InlineFormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { InlineResourceForm } from '~/ui/resources/form'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  module: Module
}

const ModuleHeader = memo('ModuleHeader', (props: Props) => {

  const {module} = props

  const {t} = useResourceTranslation()
  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <InlineResourceForm Model={Module} model={module} autoSubmit>
        <HBox gap={layout.padding.l} align='top'>
          <VBox>
            <FormField name='image' caption={false} renderAsLabel={false}>
              {bind => (
                <CustomImageField
                  {...bind}
                  size={imageSize}
                />
              )}
            </FormField>
          </VBox>
          <VBox flex='both'>
            <VBox style={{maxWidth: 480}} gap={layout.padding.m}>
              <VBox gap={layout.padding.inline.s}>
                <Label h1>
                  {module.name}
                </Label>
                {module.main && (
                  <HBox>
                    <Chip backgroundColor={colors.semantic.primary}>
                      {t('main')}
                    </Chip>
                  </HBox>
                )}
              </VBox>

              <InlineFormField
                name='description'
                caption={false}
                renderView={description => <TextBlock small dim={description == null} italic={description == null}>{description ?? t('description_empty')}</TextBlock>}
                renderEdit={bind => <TextField multiline height={120} {...bind}/>}
              />
            </VBox>
          </VBox>
        </HBox>
      </InlineResourceForm>
    )
  }

  return render()

})

export default ModuleHeader

export const imageSize = {
  width:  160,
  height: 160,
}