import { pick } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Module, ModuleNode } from '~/models'
import { FlowPlanner } from '~/stores'
import { ProxyFormModel, SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ModuleNodeFormModel implements ProxyFormModel<Record<string, any>> {

  constructor(
    public readonly planner: FlowPlanner,
    public readonly module: Module,
    public readonly node: ModuleNode,
  ) {
    makeObservable(this)
  }

  @observable
  public name: string = this.node.name ?? this.module.name

  @observable
  public params: Record<string, any> = pick(this.node.params, ...this.module.parameters?.map(it => it.name) ?? [])

  public getValue(name: string) {
    if (name === '$name') {
      return this.name
    } else {
      return this.params[name]
    }
  }

  @action
  public assign(values: Record<string, any>) {
    const {$name: name, ...rest} = values

    if (name !== undefined) {
      this.name = name
    }

    if (Object.keys(rest).length > 0) {
      this.params = {
        ...this.params,
        ...values,
      }
    }
  }

  //------
  // Submission

  public async submit(): Promise<SubmitResult | undefined> {
    const result = await this.planner.configureModuleNode(this.node.uuid, {
      name:   this.name === this.module.name ? null : cleanTextValue(this.name, true),
      params: this.params,
    })

    return translateFormErrorPaths(result, path => path.replace(/^params\./, ''))
  }

}