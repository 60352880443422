import React from 'react'
import {
  createSortableContainer,
  ElementConnect,
  SortableContainerChildProps,
  SortableContainerProps,
  SortableItem,
} from 'react-dnd-sortable'
import { createUseStyles } from 'react-jss'
import { ScrollManager } from 'react-scroll-manager'
import { isFunction, range } from 'lodash'
import { arrayEquals } from 'ytil'
import { memo } from '~/ui/component'
import { HBox, isListSection, ListSection, VBox, VBoxProps } from '~/ui/components'
import Scroller, { Props as ScrollerProps } from '~/ui/components/scroller/Scroller'
import { useScrollIntoView } from '~/ui/hooks'
import { animation, layout } from '~/ui/styling'
import { isReactComponent } from '~/ui/util'

const SortableContainer = createSortableContainer<VBoxProps, any>(VBox)

export interface Props<T> {

  //-------
  // Basics

  /**
   * The items in the grid list.
   */
  data: T[] | ListSection<T>[]

  /**
   * The list will figure out if it's receiving list sections or a flat list of items. You can
   * use this property to specify this.
   */
   sectioned?: boolean

  /**
   * The number of columns in the grid.
   */
  columns: number

  /**
   * A function to retrieve the key for each item. If unspecified, the index is used.
   */
  keyExtractor?: (item: T, index: number) => Key


  /**
   * A function to render each item at the given index.
   */
  renderCell: (item: T, index: number, selected: boolean) => React.ReactNode

  /**
   * Optionally the key path for a selected item.
   */
  selectedKeyPath?: KeyPath

   //------
  // Header / footer

  /**
   * A header component. Scrolls with the list if the list is scrollable.
   */
  HeaderComponent?:    React.ComponentType<{}> | React.ReactNode
  FooterComponent?:    React.ComponentType<{}> | React.ReactNode
  EmptyComponent?:     React.ComponentType<{}> | React.ReactNode

  //------
  // Sections

  renderSectionHeader?: (section: ListSection<T>, index: number) => React.ReactNode
  renderSectionFooter?: (section: ListSection<T>, index: number) => React.ReactNode

  //------
  // Scrollable

  /**
   * Whether the list is scrollable. If so, it will also get a `flex: 1` styling, meaning it will adjust to its parent rather
   * than to its children.
   */
  scrollable?: boolean

  /**
   * Callback to invoke when the end of the list has been reached. Use for fetching more items.
   */
  onEndReached?: ScrollerProps['onEndReached']

  /**
   * Additional props for the scroller component.
   */
  scrollerProps?: Omit<ScrollerProps, 'onEndReached'>

  /**
   * An optional scroll manager to use.
   */
  scrollManager?: ScrollManager

  //------
  // Sortable

  /**
   * If the items in the list are sortable, pass in sortable props.
   */
  sortable?: Omit<SortableContainerProps<T>, 'children'>

  /**
   * Renders a placeholder for the given sortable item.
   */
  renderPlaceholder?: (connect: ElementConnect, item: SortableItem<any, T>, isOver: boolean) => React.ReactNode

  /**
   * Whether the placeholder should be shown while an acceptable item is being
   * dragged, or only if it's hovered over the list. If set to `'always'`, the
   * placeholder is shown at the end of the list if the item is not over the list.
   */
  showPlaceholder?: ShowPlaceholderOption | ((item: SortableItem<any, T>) => ShowPlaceholderOption)

  //------
  // Layout & styling

  contentPadding?: VBoxProps['padding']

  /**
   * The gap between items.
   */
  itemGap?: VBoxProps['gap']

  /**
   * The alignment of the items.
   */
  itemAlign?: VBoxProps['align']

  /**
   * Whether to flex.
   */
  flex?: VBoxProps['flex']

  /**
   * Class names.
   */
  classNames?: React.ClassNamesProp

  /**
   * Class names for the content container.
   */
  contentClassNames?: React.ClassNamesProp

}

export type Key = string | number
export type KeyPath = Key[]

const _GridList = <T extends any>(props: Props<T>) => {

  const {
    data,
    columns,
    renderCell,
    sectioned,

    HeaderComponent,
    FooterComponent,
    EmptyComponent,

    renderSectionHeader,
    renderSectionFooter,

    scrollable,
    onEndReached,
    scrollerProps,
    scrollManager,

    sortable,
    renderPlaceholder,
    showPlaceholder = 'always',

    contentPadding,
    itemGap,
    itemAlign,

    selectedKeyPath,

    flex = true,
  } = props

  const isSectioned = sectioned || (data.length > 0 && isListSection(data[0]))
  const flatData    = isSectioned ? [] : (data as T[])
  const sections    = isSectioned ? (data as ListSection<T>[]) : []
  const isEmpty     = data.length === 0

  const scrollIntoView = useScrollIntoView({
    time: animation.durations.short,
    align: {
      top:       0.3,
      topOffset: layout.padding.inline.m,
    },
  })

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (scrollable) {
      return renderScroller()
    } else {
      return renderNonScrollable()
    }
  }

  function renderNonScrollable() {
    return (
      <VBox flex={flex} classNames={[props.classNames, props.contentClassNames]} padding={contentPadding}>
        {renderComponentOrElement(HeaderComponent)}
        {renderBody()}
        {renderComponentOrElement(FooterComponent)}
      </VBox>
    )
  }

  function renderScroller() {
    const classNames = [props.classNames, props.scrollerProps?.classNames]
    return (
      <Scroller
        {...scrollerProps}
        onEndReached={onEndReached}
        scrollManager={scrollManager}
        classNames={classNames}
        contentClassNames={props.contentClassNames}
        contentPadding={contentPadding}
        flex={flex}
      >
        {renderComponentOrElement(HeaderComponent)}
        {renderBody()}
        {renderComponentOrElement(FooterComponent)}
      </Scroller>
    )
  }

  function renderBody() {
    if (props.sortable) {
      return renderSortableGrid()
    } else {
      return renderGrid()
    }
  }

  //------
  // List

  function renderGrid() {
    return (
      <VBox classNames={$.gridList} align={isEmpty ? 'stretch' : itemAlign}>
        {isEmpty ? (
          renderComponentOrElement(EmptyComponent)
        ) : (
          renderSectionOrRows()
        )}
      </VBox>
    )
  }

  function renderSectionOrRows() {
    if (isEmpty || !isListSection(data[0])) {
      return (
        <VBox gap={itemGap}>
          {renderRows(data as T[], [])}
        </VBox>
      )
    } else {
      return sections.map((section, index) => (
        <VBox key={section.name}>
          {renderSectionHeader?.(section, index)}
          <VBox gap={itemGap}>
            {renderRows(section.items, [section.name])}
          </VBox>
          {renderSectionFooter?.(section, index)}
        </VBox>
      ))
    }
  }

  //------
  // Sortable list

  const [sortPlaceholderShown, setSortPlaceholderShown] = React.useState<boolean>()

  const onSortEnter = React.useCallback((item: SortableItem<any, T>) => {
    setSortPlaceholderShown(true)
    props.sortable?.onSortEnter?.(item)
  }, [props.sortable])

  const onSortLeave = React.useCallback((item: SortableItem<any, T>) => {
    setSortPlaceholderShown(false)
    props.sortable?.onSortLeave?.(item)
  }, [props.sortable])

  function renderSortableGrid() {
    if (sortable == null) { return null }

    const rows  = renderRows(flatData, [])
    const empty = rows.length === 0 && !sortPlaceholderShown

    return (
      <SortableContainer
        classNames={$.gridList}
        gap={itemGap}
        align={empty ? 'stretch' : itemAlign}
        onSortEnter={onSortEnter}
        onSortLeave={onSortLeave}
        {...sortable}
      >
        {props => renderSortableContent(rows, props)}
      </SortableContainer>
    )
  }

  function renderSortableContent(rows: React.ReactChild[], sortableProps: SortableContainerChildProps<T>) {
    const {hoverIndex, isOver, item, connectPlaceholder} = sortableProps
    const placeholderIndex = calculatePlaceholderIndex(item, hoverIndex)

    const copy = [...rows]

    if (item != null && placeholderIndex != null) {
      copy.splice(placeholderIndex, 0, (
        <React.Fragment key={Constants.placeholderKey}>
          {renderPlaceholder?.(connectPlaceholder, item, isOver)}
        </React.Fragment>
      ))
    }

    if (copy.length === 0) {
      return renderComponentOrElement(EmptyComponent)
    }

    return copy
  }

  const showPlaceholderForItem = React.useCallback((item: SortableItem<any, T>): ShowPlaceholderOption => {
    if (isFunction(showPlaceholder)) {
      return showPlaceholder(item)
    } else {
      return showPlaceholder
    }
  }, [showPlaceholder])

  const calculatePlaceholderIndex = React.useCallback((item: SortableItem<any, T> | null, dropIndex: number | null) => {
    if (item == null) { return null }

    // If the item comes from this list, and it's the only one, always show the placeholder, even if it's set to 'hover'.
    const isSameList = item.sourceList === sortable?.listID
    if (isSameList && data.length === 1) { return 0 }

    if (dropIndex == null) {
      return showPlaceholderForItem(item) === 'always'
        ? data.length
        : null
    }

    const isBefore = isSameList && item.index <= dropIndex
    return isBefore ? dropIndex + 1 : dropIndex
  }, [data.length, showPlaceholderForItem, sortable?.listID])

  //------
  // Data

  function renderRows(data: T[], sectionKey: Key[]) {
    const rows = Math.ceil(data.length / columns)

    return range(0, rows).map((row: number) => {
      const start = row * columns
      const end = (row + 1) * columns

      const items  = range(start, end).map(idx => data[idx]).filter(Boolean)
      const keys   = items.map((it, idx) => props.keyExtractor?.(it, idx) ?? idx)
      const rowKey = props.keyExtractor == null ? row : keys.join('--')

      return (
        <HBox align='stretch' gap={itemGap} key={rowKey}>
          {range(0, columns).map(idx => renderRowCell(idx, keys, items, sectionKey))}
        </HBox>
      )
    })
  }

  function renderRowCell(index: number, keys: Key[], items: T[], sectionKey: KeyPath) {
    const key     = keys[index] ?? `empty-${index}`
    const item    = items[index]
    const keyPath = [...sectionKey, key]
    const selected = selectedKeyPath == null ? false : arrayEquals(selectedKeyPath, keyPath)

    return (
      <VBox flex key={key} ref={selected ? scrollIntoView : undefined}>
        {index < items.length && (
          renderCell(item, index, selected)
        )}
      </VBox>
    )
  }

  function renderComponentOrElement(Component: React.ComponentType<{}> | React.ReactNode) {
    if (isReactComponent(Component)) {
      return <Component/>
    } else {
      return Component
    }
  }

  return render()

}

const GridList = memo('GridList', _GridList) as typeof _GridList
export default GridList

export type ShowPlaceholderOption = 'always' | 'hover'

const useStyles = createUseStyles({
  gridList: {
    flex: [1, 1, 'auto'],
  },
})

const Constants = {
  placeholderKey: '$$gridlist.placeholder',
}