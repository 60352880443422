import React from 'react'
import { createUseStyles } from 'react-jss'
import Color from 'color'
import { memo } from '../component'
import { shadows } from '~/ui/styling'

export interface Props {
  color:       Color | string
  round?:      boolean
  classNames?: React.ClassNamesProp
}

const ColorSwatch = memo('ColorSwatch', (props: Props) => {

  const {color, round} = props

  const $ = useStyles()

  const classNames: React.ClassNamesProp = [
    $.colorBullet,
    {round},
    props.classNames,
  ]
  const style: React.CSSProperties = {
    backgroundColor: color.toString(),
  }

  return (
    <div
      classNames={classNames}
      style={style}
    />
  )

})

export default ColorSwatch

export const size = {
  rectangle: {
    width:  24,
    height: 16,
  },
  round: {
    width:  16,
    height: 16,
  },
}

const useStyles = createUseStyles({
  colorBullet: {
    ...size.rectangle,
    boxShadow: shadows.depth(1),

    '&.round': {
      ...size.round,
      borderRadius: size.round.height / 2,
    },
  },
})