import React from 'react'
import { LocalizedString, Page } from '~/models'
import { RichTextBodyEditor } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { PushButton, VBox } from '~/ui/components'
import { Markdown } from '~/ui/components/markdown'
import { SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFromLinkedProjectNotice } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import { usePagesContext } from '../PagesContext'

export interface Props {
  page:         Page
  interactive?: boolean
  afterUpdate?: (result: SubmitResult) => any
}

const ContentPageBody = memo('ContentPageBody', (props: Props) => {

  const {t}    = useResourceTranslation('challenges')
  const {page, interactive} = props

  const {updatePage} = usePagesContext()

  const [editing, setEditing] = React.useState<boolean>(false)

  const startEdit = React.useCallback(() => {
    setEditing(true)
  }, [])

  const stopEdit = React.useCallback(() => {
    setEditing(false)
  }, [])

  const commit = React.useCallback((body: string | LocalizedString | null) => {
    updatePage(page.id, {
      body: body == null ? null : LocalizedString.from(body),
    })
  }, [page.id, updatePage])

  //------
  // Rendering

  function render() {
    if (interactive) {
      return (
        <RichTextBodyEditor
          value={page.body}
          localized
          onCommit={commit}
          editing={editing}
          requestStartEdit={startEdit}
          requestStopEdit={stopEdit}
          scrollable
          widgets
          empty={{
            ...t('page_body_empty'),
            children: renderButton(),
          }}
        />
      )
    }

    return (
      <VBox padding={layout.padding.s} gap={layout.padding.s}>
        <ResourceFromLinkedProjectNotice Model={Page}/>
        <Markdown>
          {LocalizedString.translate(page.body)}
        </Markdown>
      </VBox>
    )
  }

  function renderButton() {
    return (
      <PushButton
        caption={t('page_body_empty.button')}
        onTap={startEdit}
      />
    )
  }

  return render()

})

export default ContentPageBody