import React from 'react'
import { useTranslation } from 'react-i18next'
import { range } from 'lodash'
import { observer } from '~/ui/component'
import { Center, EmptyOrFetching, HBox, Scroller, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { useAnswers } from '../AnswersContext'
import ExploreAnswersResultsPanel from './ExploreAnswersResultsPanel'

interface Props {
  loading:  boolean
}

const ExploreAnswersCanvas = observer('ExploreAnswersCanvas', (props: Props) => {

  const {loading} = props
  const {focusedQuestions, service} = useAnswers()

  const states = React.useMemo(() => (
    service?.states == null ? [] : focusedQuestions.length > 0
      ? service.states.filter(it => focusedQuestions.includes(it.question.uuid))
      : service.states
  ), [service?.states, focusedQuestions])

  const empty = states.length === 0 || loading

  const [t] = useTranslation('answers')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (empty) {
      return renderEmpty()
    }
    return (
      <Scroller
        contentClassNames={$.scrollerContent}
        children={renderContent()}
      />
    )
  }

  function renderEmpty() {
    return (
      <Center flex>
        <EmptyOrFetching
          {...t('empty')}
          status={loading ? 'fetching' : 'done'}
        />
      </Center>
    )
  }

  function renderContent() {
    if (states.length === 1) {
      return(
        <VBox flex align='stretch'>
          <ExploreAnswersResultsPanel
            question={states[0].question}
            data={states[0].results}
            focused
          />
        </VBox>
      )
    }
    return (
      <VBox gap={layout.padding.m} align='stretch'>
        {renderRows()}
      </VBox>
    )
  }

  function renderRows() {
    const rowCount = Math.ceil(states.length / 2)
    return range(0, rowCount).map((row, index) => (
      <HBox key={index} flex='both' gap={layout.padding.m}>
        {states.slice(2 * row, 2 * (row + 1)).map((state) => (
          <VBox key={state.question.uuid} flex>
            <ExploreAnswersResultsPanel
              data={state.results}
              question={state.question}
            />
          </VBox>
        ))}
      </HBox>
    ))
  }

  return render()

})

export default ExploreAnswersCanvas

const useStyles = createUseStyles({
  scrollerContent: {
    padding: 4,
    ...layout.responsive(size => ({
      marginRight: layout.padding.m[size],
    })),
  },
})