import React, { useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useHistory } from 'react-router-dom'
import { takeWhile } from 'lodash'
import * as Path from 'path'
import { Page } from '~/models'
import { dataStore } from '~/stores'
import { memo } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

interface PagesContext {
  carousel: boolean

  path:    string[]
  pushSlug: (path: string, parentPath: string | null) => void
  popSlug:  (from?: string) => void

  createPage: (data: AnyObject) => Promise<SubmitResult | undefined>
  updatePage: (id: string, update: AnyObject) => Promise<SubmitResult | undefined>
  removePage: (id: string) => Promise<SubmitResult | undefined>
}

const PagesContext = React.createContext<PagesContext>({
  carousel: false,

  path:     [],
  pushSlug: () => void 0,
  popSlug:  () => void 0,

  createPage: () => Promise.resolve(undefined),
  updatePage: () => Promise.resolve(undefined),
  removePage: () => Promise.resolve(undefined),
})

export function usePagesContext() {
  return React.useContext(PagesContext)
}

export interface PagesContextContainerProps {
  initialPath: string[]
  carousel:    boolean
  children?:   React.ReactNode
}

export const PagesContextContainer = memo('PagesContextContainer', (props: PagesContextContainerProps) => {

  const {initialPath, carousel, children} = props

  const rootSlug = initialPath[0]

  //------
  // Navigation

  const history = useHistory()
  const [path, setPath] = useState(initialPath)

  const pushSlug = React.useCallback((slug: string, parentPath: string | null) => {
    const head = parentPath == null ? [rootSlug] : [...takeWhile(path, it => it !== parentPath), parentPath]
    unstable_batchedUpdates(() => {
      history.push(Path.join('/pages', [...head, slug].join('/')))
      setPath([...head, slug])
    })
  }, [history, path, setPath, rootSlug])

  const popSlug= React.useCallback((from?: string) => {
    const length    = Math.max(0, from == null ? path.length - 1 : path.indexOf(from))
    const nextPath = length === 0 ? [rootSlug] : path.slice(0, length)

    const slug = nextPath[nextPath.length - 1]
    unstable_batchedUpdates(() => {
      setPath(nextPath)
      history.replace(Path.join('/pages', slug))
    })
  }, [history, path, setPath, rootSlug])

  const createPage = React.useCallback(async (data: AnyObject) => {
    return await dataStore.create(Page, data)
  }, [])

  const replaceSlug = React.useCallback((prevSlug: string, nextSlug: string) => {
    const nextPath = path.map(slug => slug === prevSlug ? nextSlug : slug)

    setPath(nextPath)
    if (carousel && path.includes(prevSlug)) {
      history.replace(Path.join('/pages/', nextPath.join('/')))
    }
  }, [carousel, history, path, setPath])

  const updatePage = React.useCallback(async (id: string, data: AnyObject) => {
    const prevSlug = dataStore.get(Page, id)?.slug ?? null
    const nextSlug = data.slug ?? null

    const result = await dataStore.update(Page, id, data, {include: ['modified']})

    if (result?.status === 'ok' && result.data?.id != null) {
      if (prevSlug != null && nextSlug != null && prevSlug !== nextSlug) {
        replaceSlug(prevSlug, nextSlug)
      }
    }

    return result
  }, [replaceSlug])

  const removePage = React.useCallback(async (id: string) => {
    const document = dataStore.document(Page, id, false)
    return await document?.delete({include: ['modified']})
  }, [])

  const context = React.useMemo((): PagesContext => ({
    carousel,
    path:     carousel ? path : [path[path.length - 1]],
    pushSlug: carousel ? pushSlug : () => void 0,
    popSlug:  carousel ? popSlug : () => void 0,

    createPage,
    updatePage,
    removePage,
  }), [carousel, path, pushSlug, popSlug, createPage, updatePage, removePage])

  return (
    <PagesContext.Provider value={context}>
      {children}
    </PagesContext.Provider>
  )

})

export default PagesContext