import React from 'react'
import { Widget, WidgetAlotment } from '~/models'
import { DataPoint, DataSeries, WidgetState } from '~/stores'
import { memo } from '~/ui/component'
import { HBox, VBox } from '~/ui/components'
import { BarChart, Legend } from '~/ui/components/datavis'
import { createUseStyles, layout } from '~/ui/styling'
import { widget } from './registry'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  state:    WidgetState | null
}

const BarChartWidgetView = memo('BarChartWidgetView', (props: Props) => {

  const {widget, state} = props

  const data           = React.useMemo(() => state?.data ?? [], [state?.data])
  const series         = React.useMemo(() => state?.series ?? [], [state?.series])
  const seriesInLegend = React.useMemo(() => series.filter(it => it.showInLegend !== false), [series])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.barChartWidgetView} flex gap={layout.padding.inline.l}>
        {renderBarChart()}
        {renderLegend()}
      </VBox>
    )
  }

  function renderBarChart() {
    return (
      <VBox flex>
        <BarChart<DataPoint>
          data={data}
          axisLabelForPoint={axisLabelForPoint}
          // renderPopup={renderPopup}
        >
          {series.map(series => (
            <BarChart.Series<DataPoint>
              key={series.name}
              name={series.name}
              caption={series.caption ?? widget.translate(`series.${series.name}`)}
              getValue={point => point[series.valueKey] ?? 0}
            />
          ))}
        </BarChart>
      </VBox>
    )
  }

  function renderLegend() {
    if (seriesInLegend.length === 0) { return null }

    return (
      <HBox justify='center'>
        <Legend
          data={seriesInLegend}
          captionForPoint={captionForSeries}
          horizontal
        />
      </HBox>
    )
  }

  const captionForSeries = React.useCallback((series: DataSeries) => {
    return series.caption ?? widget.translate(`series.${series.name}`, series.name)
  }, [widget])

  const axisLabelForPoint = React.useCallback((point: DataPoint) => {
    return point.label ?? widget.translate(`labels.${point.key}`, '')
  }, [widget])

  // const renderPopup = React.useCallback((point: DataPoint) => {
  //   const items = series.map(it => [
  //     captionForSeries(it),
  //     point[it.valueKey],
  //   ])

  //   return (
  //     <VBox gap={layout.padding.inline.s} padding={layout.padding.inline.m}>
  //       {point.label != null && (
  //         <Label caption>
  //           {point.label}
  //         </Label>
  //       )}
  //       <Dimple horizontal/>
  //       <Legend
  //         data={items}
  //         captionForPoint={it => it[0]}
  //         valueForPoint={it => it[1]}
  //         dimLabels={false}
  //       />
  //     </VBox>
  //   )
  // }, [captionForSeries, series])

  return render()

})

widget('bar-chart')(BarChartWidgetView)
export default BarChartWidgetView

const useStyles = createUseStyles({
  barChartWidgetView: {
    paddingBottom: 4, // For the legend shadows
  },
})