import React from 'react'
import Toast from 'react-toast'
import Color from 'color'
import { Role, User } from '~/models'
import { authenticationStore, BulkSelector, dataStore } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Chip, ConfirmBox, DataGrid, HBox, Label, TimeAgoLabel } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, CreateFormComponentMap, ResourceListScreen } from '~/ui/resources/collection'
import { layout, useTheme } from '~/ui/styling'
import { Theme } from '~/ui/styling/Theme'
import NewUserForm from '../NewUserForm'

const UsersScreen = observer('UsersScreen', () => {

  const {t} = useResourceTranslation('users')

  const me = authenticationStore.user
  const createFormComponentChoices = React.useMemo(() => {
    if (me == null) { return {} }

    const choices: CreateFormComponentMap = {}
    for (const role of Role.all(me)) {
      choices[role] = {
        caption:   t(`roles.${role}.title`),
        detail:    t(`roles.${role}.detail`),
        Component: props => <NewUserForm {...props} role={role}/>,
      }
    }

    return choices
  }, [me, t])

  //------
  // Rendering

  const theme = useTheme()

  function render() {
    return (
      <ResourceListScreen
        Model={User}
        include={['groups']}
        CreateFormComponent={createFormComponentChoices}
        defaultSort='name'
        bulkActions={bulkActions}
        allowSelect={user => user.id !== me?.id}
      >
        <DataGrid.Column<User>
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column
          name='email'
          sort={true}
          flex={2}
        />
        <DataGrid.Column
          name='role'
          sort={true}
          flex={1}
          renderCell={renderRole}
        />
        <DataGrid.Column
          name='lastLogin'
          sort='lastLoginAt'
          renderCell={renderLastLogin}
          flex={2}
        />
      </ResourceListScreen>
    )
  }

  function renderName(user: User) {
    return (
      <HBox gap={layout.padding.inline.l}>
        <Avatar
          source={user.photoURL}
          firstName={user.name}
          size={avatarSize}
        />
        <Label flex h3>
          {user.name}
        </Label>
      </HBox>
    )
  }

  function renderRole(user: User) {
    return (
      <HBox>
        <Chip
          backgroundColor={colorForRole(user.role, theme)}
          children={user.role}
          mono
          small
        />
      </HBox>
    )
  }

  function renderLastLogin(user: User) {
    if (user.lastLoginAt == null) {
      return (
        <Label light dim italic>
          {t('last_login.never')}
        </Label>
      )
    } else {
      return (
        <TimeAgoLabel
          datetime={user.lastLoginAt}
        />
      )
    }
  }

  const resetPassword = React.useCallback(async (_, selector: BulkSelector) => {
    const confirmed = await ConfirmBox.show({
      title:   t('actions.reset_password_bulk.confirm.title'),
      message: t('actions.reset_password_bulk.confirm.message'),
    })
    if (!confirmed) {return }

    const endpoint = dataStore.endpoint(User)
    const result = await endpoint.callBulkAction('auth-reset:request', selector)
    if (result.status !== 'ok') { return }

    const count = result.meta?.count ?? 0
    Toast.show({
      type:   'success',
      title:  t('actions.reset_password_bulk.success.title'),
      detail: t(`actions.reset_password_bulk.success.${count > 1 ? 'detail_plural' : 'detail'}`, {count}),
    })
  }, [t])

  const bulkActions = React.useMemo((): BulkAction[] => [{
    name:    'reset_password_bulk',
    icon:    'reset',
    execute: resetPassword,
  }], [resetPassword])

  return render()

})

export function colorForRole(role: Role, theme: Theme): Color {
  switch (role) {
    case 'super':      return theme.colors.named.red
    case 'admin':      return theme.colors.named.orange
    case 'controller': return theme.colors.named.purple
    case 'editor':     return theme.colors.named.blue
    case 'analyst':    return theme.colors.named.lime
    case 'host':       return theme.colors.named.green
  }
}

export const avatarSize = {
  width:  40,
  height: 40,
}

export default UsersScreen