import React from 'react'
import { RegistrationPreset } from '~/models'
import { observer } from '~/ui/component'
import { NumberField, VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import CreateCodesFormModel from './CreateCodesFormModel'

export interface Props extends Omit<FormDialogProps<CreateCodesFormModel>, 'children'> {}

const CreateCodesForm = observer('CreateCodesForm', (props: Props) => {

  const {model: formModel} = props
  const {t} = useResourceTranslation('registration-codes')

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='registration-codes'>
        <FormDialog
          {...props}

          icon='code'
          title={t('create_form.title')}

          children={renderContent()}
          semi={false}
        />
      </ResourceTypeProvider>
    )
  }

  function renderContent() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='preset'>
          {bind => (
            <ResourceField
              Model={RegistrationPreset}
              readOnly={formModel.presetKnown}
              {...bind}
            />
          )}
        </FormField>

        <FormField name='count' required>
          {bind => <NumberField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default CreateCodesForm