import React from 'react'
import { Widget, WidgetAlotment, WidgetFilter as WidgetFilterModel } from '~/models'
import { forwardRef } from '~/ui/component'
import {
  Center,
  ClearButton,
  Dimple,
  HBox,
  Label,
  Panel,
  Popup,
  PopupMenu,
  PopupMenuItem,
  SVG,
  VBox,
} from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, fonts, layout, ThemeProvider } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import { useWidgetFilterValues } from '../WidgetFilterValuesContext'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  filter:   WidgetFilterModel
  value:    any

  renderValue:  (value: any) => React.ReactNode
  renderPopup?: () => React.ReactNode
  menuItems?:   PopupMenuItem[]

  onDidClose?: () => any
}

interface WidgetFilter {
  close(): void
}

const WidgetFilter = forwardRef('WidgetFilter', (props: Props, ref: React.Ref<WidgetFilter>) => {

  const {
    widget,
    filter,
    value,
    renderValue,
    renderPopup,
    menuItems,
    onDidClose,
  } = props

  const {persistent} = filter

  const values = useWidgetFilterValues()

  const setValue = React.useCallback((value: any) => {
    values?.setValue(filter, value)
  }, [filter, values])

  const remove = React.useCallback(() => {
    values?.remove(filter)
  }, [filter, values])

  const [popupOpen, openPopup, closePopup] = useBoolean()

  React.useImperativeHandle(ref, () => ({
    close: closePopup,
  }))

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.widgetFilter}>
        {renderInPopup()}
      </VBox>
    )
  }

  function renderInPopup() {
    if (renderPopup != null) {
      return (
        <Popup
          open={popupOpen}
          requestClose={closePopup}
          renderContent={renderPopup}
          children={renderButton()}
          crossAlign='near'
          autoFocus={true}
          onDidClose={onDidClose}
        />
      )
    } else if (menuItems != null && menuItems.length > 0) {
      return (
        <PopupMenu
          open={popupOpen}
          requestClose={closePopup}
          items={menuItems}
          onValueSelect={setValue}
          children={renderButton()}
          onDidClose={onDidClose}
        />
      )
    } else {
      return renderButton()
    }
  }

  function renderButton() {
    return (
      <Panel onTap={openPopup}>
        <HBox flex align='stretch' gap={layout.padding.inline.s}>
          {renderCaption()}
          <Dimple vertical/>
          {renderActualValue()}
          {!persistent && (
            <HBox align='stretch'>
              <Dimple vertical/>
              {renderRemoveButton()}
            </HBox>
          )}
        </HBox>
      </Panel>
    )
  }

  function renderCaption() {
    return (
      <ThemeProvider primary>
        <Label classNames={$.caption} small caption>
          {widget.filterCaption(filter.name, true)}
        </Label>
      </ThemeProvider>
    )
  }

  function renderActualValue() {
    const valueContent = renderValue(value)

    return (
      <HBox classNames={$.value} flex align='stretch' gap={layout.padding.inline.m}>
        <VBox flex justify='middle'>
          {isReactText(valueContent) ? (
            <Label classNames={$.valueLabel} noFont>
              {valueContent}
            </Label>
          ) : valueContent}
        </VBox>
        <Center>
          <SVG name='chevron-down' dim size={layout.icon.s}/>
        </Center>
      </HBox>
    )
  }

  function renderRemoveButton() {
    return (
      <ClearButton
        classNames={$.removeButton}
        icon='cross'
        padding='both'
        onTap={remove}
      />
    )
  }

  return render()

})

export default WidgetFilter

export const widgetFilterMinWidth = 240
export const widgetFilterHeight = layout.barHeight.xs

const useStyles = createUseStyles(theme => ({
  widgetFilter: {
    height:   widgetFilterHeight,
    flex:     [0, 1, `auto`],
    minWidth: widgetFilterMinWidth,
  },

  caption: {
    padding:   [0, layout.padding.inline.m],
    alignSelf: 'center',
  },

  value: {
    padding:   [0, layout.padding.inline.m],
  },

  valueLabel: {
    font: fonts.responsiveFontStyle(theme.fonts.input),
  },

  removeButton: {
    alignSelf: 'center',
  },
}))