import React from 'react'
import { RegistrationPreset } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { ActionButton } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import CreateCodesForm from '../../codes/CreateCodesForm'
import CreateCodesFormModel from '../../codes/CreateCodesFormModel'

export interface Props {
  preset: RegistrationPreset
  afterCreateCodesSubmit?: (result: SubmitResult) => void
}

const RegistrationPresetActions = memo('RegistrationPresetActions', (props: Props) => {

  const {preset, afterCreateCodesSubmit} = props
  const [codesFormOpen, openCodesForm, closeCodesForm] = useBoolean()

  const createCodesFormModel = React.useMemo(
    () => new CreateCodesFormModel(preset),
    [preset],
  )

  //------
  // Rendering

  function render() {
    if (!preset.requireCode) { return null }

    return (
      <VBox align='left' gap={layout.padding.m}>
        <ActionButton
          name='create-codes'
          icon='code'
          onTap={openCodesForm}
        />

        <CreateCodesForm
          open={codesFormOpen}
          requestClose={closeCodesForm}
          model={createCodesFormModel}
          afterSubmit={afterCreateCodesSubmit}
        />
      </VBox>
    )
  }

  return render()

})

export default RegistrationPresetActions