import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Feedback } from '~/models'
import { memo } from '~/ui/component'
import { SwitchField, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  feedback:    Feedback & {review: boolean}
  requestSave: (data: AnyObject) => Promise<SubmitResult | undefined>
}

const ReviewForm = memo('ReviewForm', (props: Props) => {

  const {feedback, requestSave, ...rest} = props

  const {t} = useResourceTranslation('feedback')
  const title = t('review.title')

  const formModel = React.useMemo(
    () => new ReviewFormModel(feedback, requestSave),
    [requestSave, feedback],
  )

  //------
  // Rendering

  function render() {
    return (
      <FormDialog semi={false} {...rest} model={formModel} title={title}>
        <VBox gap={layout.padding.m}>
          <TextBlock small dim>
            {t('review.instructions')}
          </TextBlock>
        </VBox>

        <FormField name='review' caption={false} label={t('review.label')}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </FormDialog>
    )
  }

  return render()

})

export default ReviewForm

export class ReviewFormModel implements FormModel {

  constructor(
    public readonly feedback: Feedback & {review: boolean},
    private readonly requestSave: (data: AnyObject) => Promise<SubmitResult | undefined>,
  ) {
    makeObservable(this)
  }

  @observable
  public review: boolean = this.feedback.review ?? false

  public async submit(): Promise<SubmitResult | undefined> {
    return await this.requestSave({
      ...this.feedback,
      review: this.review,
    })
  }

}