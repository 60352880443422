import { makeObservable, observable } from 'mobx'
import ImportField, { ImportFieldOptions } from './ImportField'

export default class RelatedToggleField extends ImportField {

  constructor(
    name: string,
    resourceType: string,
    options: RelatedToggleFieldOptions = {},
  ) {
    super(name, options)

    this.resourceType = resourceType
    this.multi        = options.multi ?? false
    this.defaults     = options.defaults ?? {}

    makeObservable(this)
  }

  public readonly resourceType: string
  public readonly multi: boolean

  @observable
  public relatedID: string | null = null

  @observable
  public defaults: AnyObject

  public serialize() {
    return {
      ...super.serializeWithType('related_toggle'),
      resourceType: this.resourceType,
      relatedID:    this.relatedID,
      multi:        this.multi,
      defaults:     this.defaults,
    }
  }

  public save() {
    return {
      ...super.save(),
      relatedID: this.relatedID,
    }
  }

  public load(raw: any) {
    if (raw.relatedID != null) {
      this.relatedID = raw.relatedID
    }
    super.load(raw)
  }

}

export interface RelatedToggleFieldOptions extends ImportFieldOptions {
  multi?:     boolean
  defaults?:  AnyObject
}