import React from 'react'
import { Group, GroupJoinTrigger } from '~/models'
import { observer } from '~/ui/component'
import { Chip, HBox, Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { layout } from '~/ui/styling'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: GroupJoinTrigger
}

const GroupTriggerNodeView = observer('GroupTriggerNodeView', (props: Props) => {
  const [group, {fetchStatus}] = useModelDocumentData(Group, props.trigger.group, {
    detail: false,
  })

  const groupDetail = group == null
    ? (fetchStatus === 'fetching' ? <Spinner size={8}/> : null)
    : `[${group.name}]`

  const tagsDetail = (
    <HBox gap={layout.padding.inline.xs} padding={2}>
      {props.trigger.tags.map(tag => <Chip icon='tag' children={tag} small/>)}
    </HBox>
  )

  return (
    <TriggerNodeView
      {...props}
      detail={groupDetail ?? tagsDetail}
      icon='group'
    />
  )
})

register('group:*', GroupTriggerNodeView)