import { makeObservable, observable } from 'mobx'
import ImportField, { ImportFieldOptions } from './ImportField'

export default class RelatedField extends ImportField {

  constructor(
    name: string,
    resourceType: string,
    options: RelatedFieldOptions = {},
  ) {
    super(name, options)

    this.resourceType = resourceType
    this.multi        = options.multi ?? false
    this.create       = options.create ?? false
    this.defaults     = options.defaults ?? {}

    makeObservable(this)
  }

  public readonly resourceType: string
  public readonly multi: boolean

  @observable
  public create: boolean

  @observable
  public operation: RelatedFieldOperation = 'replace'

  @observable
  public defaults: AnyObject

  public serialize() {
    return {
      ...super.serializeWithType('related'),
      resourceType:   this.resourceType,
      multi:          this.multi,
      operation:      this.operation,
      create:         this.create,
      defaults:       this.defaults,
    }
  }

  public save() {
    return {
      ...super.save(),
      create:    this.create,
      operation: this.operation,
    }
  }

  public load(raw: any) {
    if (raw.create != null) {
      this.create = raw.create
    }
    super.load(raw)
  }

}

export interface RelatedFieldOptions extends ImportFieldOptions {
  multi?:     boolean
  create?:    boolean
  operation?: RelatedFieldOperation
  defaults?:  AnyObject
}

export type RelatedFieldOperation = 'replace' | 'add' | 'remove'
export const RelatedFieldOperation: {
  all: RelatedFieldOperation[]
} = {
  all: ['replace', 'add', 'remove'],
}