import React from 'react'
import { Competition } from '~/models'
import ModuleChip from '~/ui/app/modules/ModuleChip'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SelectField, SVG, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { useResourceTranslation } from '~/ui/resources'
import {
  CreateFormComponentMap,
  ResourceFilter,
  ResourceListScreen,
  TypeCell,
} from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import CompetitionForm, { Props as CompetitionFormProps } from '../CompetitionForm'

const CompetitionsScreen = memo('CompetitionsScreen', () => {

  const {colors} = useStyling()
  const {t} = useResourceTranslation('competitions')

  const createFormMap = React.useMemo((): CreateFormComponentMap => ({
    'head-to-head': {
      caption:   t('types.head-to-head.caption'),
      detail:    t('types.head-to-head.detail_create'),
      Component: (props: Omit<CompetitionFormProps, 'type'>) => <CompetitionForm {...props} type='head-to-head'/>,
    },
    team: {
      caption:   t('types.team.caption'),
      detail:    t('types.team.detail_create'),
      Component: (props: Omit<CompetitionFormProps, 'type'>) => <CompetitionForm {...props} type='team'/>,
    },
  }), [t])

  const typeChoices = React.useMemo((): Choice[] => [{
    value:   null,
    caption: t('misc:show_all'),
    dim:     true,
  }, {
    value: 'head-to-head',
    caption: t('types.head-to-head.caption'),
  }, {
    value: 'team',
    caption: t('types.team.caption'),
  }], [t])

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Competition}
        CreateFormComponent={createFormMap}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={3}
        />
        <DataGrid.Column<Competition>
          name='type'
          sort={true}
          flex={2}
          renderCell={competition => <TypeCell type={competition.type}/>}
        />

        <ResourceListScreen.Filters>
          <ResourceFilter name='type'>
            {bind => <SelectField {...bind} choices={typeChoices}/>}
          </ResourceFilter>
        </ResourceListScreen.Filters>
      </ResourceListScreen>
    )
  }

  function renderName(competition: Competition) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name='trophy'
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <VBox gap={layout.padding.inline.xs}>
          <Label bold>
            {competition.name}
          </Label>
          <HBox>
            <ModuleChip
              id={competition.module}
              small
            />
          </HBox>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default CompetitionsScreen