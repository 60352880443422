import React from 'react'
import { RouteComponentProps } from 'react-router'
import { AnswersQuery } from '~/models'
import { memo } from '~/ui/component'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import AnswersQueryForm from '../AnswersQueryForm'
import AnswersQueryContainer from './AnswersQueryContainer'

export type Props = RouteComponentProps<ResourceDetailParams>

const AnswersQueryScreen = memo('AnswersQueryScreen', (props: Props) => {

  const {actionCaption} = useResourceTranslation('answers-queries')

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<AnswersQuery>
        Model={AnswersQuery}
        id={props.match.params.id}
        renderBody={renderBody}
        EditFormComponent={[{
          name:    'edit',
          icon:    'pencil',
          caption: actionCaption('edit'),
          Form:    AnswersQueryForm,
        }]}
      />
    )
  }

  function renderBody(query: AnswersQuery) {
    return(
      <AnswersQueryContainer
        query={query}
      />
    )
  }

  return render()

})

export default AnswersQueryScreen