import React from 'react'
import { useTranslation } from 'react-i18next'
import { Participant } from '~/models'
import { operatorStore } from '~/stores'
import { ParticipantField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { HBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import OperatorAlertQueue from './OperatorAlertQueue'

export interface Props {}

const OperatorActions = observer('OperatorActions', () => {

  const [t] = useTranslation('operator')

  const started = operatorStore.started

  const addParticipant = React.useCallback((participant: Participant) => {
    operatorStore.service?.addParticipant(participant.id)
  }, [])

  //------
  // Rendering

  function render() {
    if (!started) { return null }

    return (
      <HBox gap={layout.padding.s}>
        <OperatorAlertQueue/>
        <ParticipantField
          placeholder={t('add_participant')}
          onSelect={addParticipant}
        />
      </HBox>
    )
  }

  return render()

})

export default OperatorActions