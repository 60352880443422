import React from 'react'
import Color from 'color'
import { ColorBullet, Dimple, HBox, Label, VBox, VBoxProps } from '~/ui/components'
import { DataTable } from '~/ui/components/datavis'
import { createUseStyles, layout, PaletteKey, useStyling } from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props<T> {
  data:            T[]
  keyForPoint?:    (point: T) => string | number
  captionForPoint: (point: T) => string | null
  valueForPoint?:  (point: T) => string | number

  horizontal?:     boolean
  flex?:           VBoxProps['flex']

  colorForPoint?:  (point: T) => Color
  palette?:        PaletteKey
  dimLabels?:      boolean
}

const Legend = <T extends {}>(props: Props<T>) => {

  const {
    data,
    keyForPoint,
    captionForPoint,
    colorForPoint,
    valueForPoint,
    palette = 'default',
    horizontal = false,
    flex,
    dimLabels = true,
  } = props

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (horizontal) {
      return renderHorizontal()
    } else {
      return (
        <DataTable classNames={$.Legend} flex={flex} data={data} keyForPoint={keyForPoint}>
          <DataTable.Column<T> flex>
            {renderCaptionCell}
          </DataTable.Column>
          {valueForPoint != null && (
            <DataTable.Column<T> align='right'>
              {renderValueCell}
            </DataTable.Column>
          )}
        </DataTable>
      )
    }
  }

  function renderHorizontal() {
    return (
      <HBox flex={flex} gap={layout.padding.inline.m}>
        {data.map((point, index) => (
          <React.Fragment key={keyForPoint?.(point) ?? index}>
            {index > 0 && <Dimple vertical/>}
            <HBox flex='shrink' gap={layout.padding.inline.m}>
              {renderCaptionCell(point, index)}
              {renderValueCell(point)}
            </HBox>
          </React.Fragment>
        ))}
      </HBox>
    )
  }

  const renderCaptionCell = React.useCallback((point: T, index: number) => {
    const caption = captionForPoint(point)
    const color = colorForPoint?.(point) ?? colors.palette(palette, index, data.length)

    return (
      <HBox flex gap={layout.padding.inline.m} classNames={$.captionCell}>
        <ColorBullet
          color={color}
        />
        <VBox flex='shrink'>
          <Label small dim={dimLabels} caption>
            {caption}
          </Label>
        </VBox>
      </HBox>
    )
  }, [captionForPoint, colorForPoint, colors, palette, data.length, $.captionCell, dimLabels])

  const renderValueCell = React.useCallback((point: T) => {
    const value = valueForPoint?.(point)

    if (isReactText(value)) {
      return (
        <Label caption align='right'>
          {value}
        </Label>
      )
    } else {
      return value
    }
  }, [valueForPoint])

  return render()

}

export default Legend

const useStyles = createUseStyles({
  Legend: {},

  captionCell: {
  },
})