import React from 'react'
import { Participant } from '~/models'
import { memo } from '~/ui/component'
import { HBox, SwitchField, TextField, VBox } from '~/ui/components'
import { FormField, SubmitResult } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: (result: SubmitResult) => any

  model?: Participant | null
}

const ParticipantForm = memo('ParticipantForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props

  const initialData = React.useMemo(
    () => model == null ? {listed: true} : {},
    [model],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Participant}
        model={model}
        initialData={initialData}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>
        <HBox gap={layout.padding.s}>
          <VBox flex={2}>
            <FormField name='firstName' required>
              {bind => <TextField {...bind}/>}
            </FormField>
          </VBox>
          <VBox flex={3}>
            <FormField name='lastName'>
              {bind => <TextField {...bind}/>}
            </FormField>
          </VBox>
        </HBox>

        <FormField name='email' required>
          {bind => <TextField {...bind} autoComplete='email'/>}
        </FormField>
        <FormField name='listed' required>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default ParticipantForm