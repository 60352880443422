import { makeObservable, observable } from 'mobx'
import { Challenge, ChallengeTask, LocalizedString } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class ChallengeTaskFormModel implements FormModel {

  constructor(
    public readonly challenge: Challenge,
    public readonly task:      ChallengeTask | null,
    public readonly atIndex:   number | null,
  ) {
    makeObservable(this)
  }

  @observable
  public title: LocalizedString = this.task?.title ?? {}

  @observable
  public subtitle: LocalizedString = this.task?.subtitle ?? {}

  public async submit(): Promise<SubmitResult | undefined> {
    const tasks = this.buildTasks()
    if (tasks == null) { return }

    return await dataStore.update(Challenge, this.challenge.id, {tasks})
  }

  private buildTasks() {
    if (this.challenge.tasks == null) { return null }

    if (this.task != null) {
      return this.challenge.tasks.map(task => {
        if (task.uuid === this.task?.uuid) {
          return this.buildTask()
        } else {
          return task
        }
      })
    } else if (this.atIndex === null || this.atIndex === this.challenge.tasks.length) {
      return [
        ...this.challenge.tasks,
        this.buildTask(),
      ]
    } else {
      const tasks: any[] = []
      this.challenge.tasks.forEach((task, index) => {
        if (index === this.atIndex) {
          tasks.push(this.buildTask())
        }
        tasks.push(task)
      })
      return tasks
    }
  }

  private buildTask() {
    return {
      ...this.task,
      title:    this.title,
      subtitle: this.subtitle,
    }
  }

}