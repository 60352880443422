import React from 'react'
import { DragDropContainer } from 'react-dnd'
import Toast from 'react-toast'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5'
import { ClockContainer } from '~/socket/useClock'
import { audioStore, authenticationStore } from '~/stores'
import { createSuperContainer, observer } from '~/ui/component'
import { MessageBoxHost, ToastItem } from '~/ui/components'
import {
  animation,
  applyBewizrBranding,
  BrandingGuide,
  createUseStyles,
  layout,
} from '~/ui/styling'
import appStore from '../../stores/appStore'
import LightboxHost from '../components/lightbox/LightboxHost'
import { createStylingContext, StylingContext } from '../styling/StylingContext'
import { ThemeProvider } from '../styling/ThemeContext'
import ReadyGuard from './ReadyGuard'
import AppNavigationContainer from './navigation/AppNavigationContainer'

export interface Props {
  children?: React.ReactNode
}

const AppContainer = observer('AppContainer', (props: Props) => {

  const {children} = props

  const appReady     = appStore.readyState === 'ready'
  const authToken    = authenticationStore.authToken
  const isLoggedIn   = authenticationStore.isLoggedIn
  const isBewizrUser = authenticationStore.actualUser?.bewizr ?? false

  React.useEffect(() => {
    appStore.initStores()
  }, [])

  const showReadyGard = React.useMemo(() => {
    if (!appReady) { return true }
    if (authToken != null && !isLoggedIn) { return true }

    return false
  }, [appReady, authToken, isLoggedIn])

  React.useEffect(() => {
    audioStore.preload()
  }, [])

  //------
  // Branding

  const stylingContext = React.useMemo(
    () => createStylingContext(isBewizrUser ? new BrandingGuide(applyBewizrBranding) : new BrandingGuide()),
    [isBewizrUser],
  )

  //------
  // Rendering

  const $ = useStyles()

  const Container = React.useMemo(() => createSuperContainer<{stylingContext: StylingContext}>(
    props => <ClockContainer {...props}/>,
    props => <DragDropContainer {...props}/>,
    props => <LightboxHost {...props}/>,
    props => <AppNavigationContainer {...props}/>,
    props => <QueryParamProvider adapter={ReactRouter5Adapter} children={props.children!}/>,
    (props, params) => <StylingContext.Provider value={params.stylingContext} {...props}/>,
    props => <ThemeProvider {...props}/>,
  ), [])

  function render() {
    return (
      <>
        <Container stylingContext={stylingContext}>
          <ReadyGuard ready={showReadyGard}>
            {children}
          </ReadyGuard>
        </Container>

        <Toast
          className={$.toast}
          ItemComponent={ToastItem}
          duration={animation.durations.medium}
        />

        <MessageBoxHost/>
      </>
    )
  }

  return render()

})

export default AppContainer

const useStyles = createUseStyles({
  toast: {
    ...layout.overlay,
    position:      'fixed',
    zIndex:        layout.z.toast,
    pointerEvents: 'none',
  },
})