import React from 'react'
import { difference } from 'lodash'
import { CollectionFetchOptions, FetchStatus } from 'mobx-document'
import { Model, ModelClass } from '~/models'
import { dataStore, ModelEndpoint, Sort } from '~/stores'
import { useWithCustomDeps } from '~/ui/hooks'
import { buildParamsDeps } from './endpoints'

export function useFiniteModelSetData<M extends Model>(Model: Constructor<M>, ids: string[], options: FiniteModelSetOptions = {}): UseFiniteModelSetDataHook<M> {
  const endpoint    = useFiniteModelSetEndpoint<M>(Model, ids, options)
  const data        = endpoint.data
  const fetchStatus = endpoint.fetchStatus
  const empty       = ids.length

  const fetch: FiniteModelSetFetch = React.useCallback((options) => {
    if (empty) { return }
    endpoint.fetch(options)
  }, [empty, endpoint])

  return [data, {data, fetch, fetchStatus, endpoint}]
}

export function useFiniteModelSetEndpoint<M extends Model>(Model: Constructor<M>, ids: string[], options?: FiniteModelSetOptions): ModelEndpoint<M>
export function useFiniteModelSetEndpoint<M extends Model>(Model: ModelClass<M>, ids: string[], options?: FiniteModelSetOptions): ModelEndpoint<M>
export function useFiniteModelSetEndpoint<M extends Model>(Model: Constructor<M>, ids: string[], options: FiniteModelSetOptions = {}): ModelEndpoint<M> {
  const {
    fetch: shouldFetch = true,
    detached = false,
    detail = true,
    label,
    ...rest
  } = options

  const params = useWithCustomDeps(
    rest,
    buildParamsDeps,
  )
  const hookIDs = useWithCustomDeps(
    ids,
    ids => [[...ids].sort().join(',')],
  )

  const endpoint: ModelEndpoint<M> = React.useMemo(
    () => dataStore[detached ? 'detachedEndpoint' : 'endpoint'](Model, params, {
      initialIDs: hookIDs,
    }),
    [detached, Model, params, hookIDs],
  )

  // Determine if we need to fetch any more data.
  const existing = React.useMemo(() => {
    const models = endpoint.database.list(hookIDs)
    if (detail) {
      return models.filter(model => model.$hasDetail)
    } else {
      return models
    }
  }, [detail, endpoint.database, hookIDs])

  const existingIDs = existing.map(model => model.id)
  const needFetch   = difference(ids, existingIDs).length > 0

  React.useEffect(() => {
    if (!shouldFetch || !needFetch) {
      endpoint.markFetched()
    }
    if (endpoint.fetchStatus !== 'idle') { return }

    endpoint.fetchWithParams({
      ...params,
      filters: {
        ...params.filters,
        id: hookIDs,
      },
      label,
    })
  }, [Model, endpoint, hookIDs, label, needFetch, params, shouldFetch])

  return endpoint
}

export interface FiniteModelSetOptions  {
  fetch?:    boolean
  filters?:  Record<string, any>
  label?:    string
  sorts?:    Sort[]
  include?:  string[]
  detail?:   boolean
  detached?: boolean
}

export type UseFiniteModelSetDataHook<M extends Model> = [M[], UseFiniteModelSetDataHookRest<M>]

export interface UseFiniteModelSetDataHookRest<M extends Model> {
  data:        M[]
  fetch:       FiniteModelSetFetch
  fetchStatus: FetchStatus
  endpoint:    ModelEndpoint<M>
}

export type FiniteModelSetFetch = (options?: CollectionFetchOptions) => void