import React from 'react'
import { upperFirst } from 'lodash'
import { ModelClass } from '~/models'
import { observer } from '~/ui/component'
import { Chip, ChipProps } from '~/ui/components'
import { ModelDocumentOptions, useModelDocumentData, useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { resourceDetailPath } from '../routes/paths'

export interface Props extends ChipProps, ModelDocumentOptions {
  Model:        ModelClass<any>

  id?:          string

  filters?:     Record<string, any>
  interactive?: boolean
  modelName?:   boolean
}

const ResourceChip = observer('ResourceChip', (props: Props) => {

  const {
    Model,
    id,
    filters,
    fetch = 'notfound',
    detail = false,
    interactive = true,
    modelName = false,
    ...rest
  } = props

  const [singleModel, {fetchStatus: singleFetchStatus}] = useModelDocumentData(Model, id ?? null, {fetch, detail})
  const [multiModels, {fetchStatus: multiFetchStatus}] = useModelEndpointData(Model, {fetch: filters != null, filters, detail})

  const model       = id ? singleModel : multiModels[0]
  const fetchStatus = id ? singleFetchStatus : multiFetchStatus
  const href        = interactive && model != null ? resourceDetailPath(Model.resourceType, model.id) : undefined

  const {singular} = useResourceTranslation(Model.resourceType)

  //------
  // Rendering

  function render() {
    return (
      <Chip
        {...rest}
        icon={model?.$icon}
        loading={fetchStatus === 'fetching'}
        children={renderLabel()}
        href={href}
      />
    )
  }

  function renderLabel() {
    if (!modelName) {
      return model?.$caption
    } else {
      return (
        `${upperFirst(singular())}: ${model?.$caption}`
      )
    }
  }

  return render()

})

export default ResourceChip