import I18next from 'i18next'
import { isPlainObject, some } from 'lodash'
import { CustomImage } from '~/models'
import { BrandingGuide, ComponentBrandingBase } from '~/ui/styling'
import { Model, resource } from './Model'
import { Project } from './Project'
import { Translations } from './Translations'

@resource<Branding>('brandings', {
  icon:    'palette',
  caption: branding => branding.name,
  scopedToModule: false,
})
export class Branding extends Model {

  public name!:        string
  public images!:      Record<string, CustomImage>
  public texts!:       Translations
  public colors!:      Record<string, string>
  public backgrounds!: Record<string, BackgroundSpec>
  public borders!:     Record<string, BorderSpec>
  public components!:  Record<string, BrandedComponentSpec>

  public default: boolean = false

  public static default(project: Project, guide: BrandingGuide): Branding {
    const branding = this.deserialize({
      id:      project.id,
      name:    project.name,
      images: {
        logo: project.logo ?? undefined,
      },

      texts:  {
        en: {
          title:    project.name,
          subtitle: "Powered by GroundControl",
        },
      },

      colors:      wellKnownColors(guide),
      backgrounds: wellKnownBackgrounds(),
      borders:     wellKnownBorders(),

      components: {},
    })

    branding.default = true
    return branding
  }

  //------
  // Components

  public image(key: string, variant: string | null = null) {
    if (variant != null) {
      return this.images[`${key}:${variant}`] ?? this.images[key] ?? null
    } else {
      return this.images[key] ?? null
    }
  }

  public text(key: string, language: string = I18next.language) {
    return this.texts[language]?.[key] ?? this.texts.en?.[key] ?? null
  }

  public backgroundInUse(name: string) {
    return some(Object.values(this.components), it => it.background === name)
  }

  public borderInUse(name: string) {
    return some(Object.values(this.components), it => it.border === name)
  }

  public colorInUse(name: string) {
    if (some(this.backgrounds, backgroundUsesColor(name))) { return true }
    if (some(this.borders, borderUsesColor(name))) { return true }
    return false
  }

  //------
  // Well known assets

  private static _wellKnown?: {
    colors:      Record<string, string>
    backgrounds: Record<string, BackgroundSpec>
    borders:     Record<string, BorderSpec>
  }

  private static get wellKnown() {
    if (this._wellKnown == null) {
      const guide = new BrandingGuide()
      this._wellKnown = {
        colors:      wellKnownColors(guide),
        backgrounds: wellKnownBackgrounds(),
        borders:     wellKnownBorders(),
      }
    }

    return this._wellKnown
  }

  public static isWellKnownColor(name: string) {
    return name in this.wellKnown.colors
  }

  public static isWellKnownBackground(name: string) {
    return name in this.wellKnown.backgrounds
  }

  public static isWellKnownBorder(name: string) {
    return name in this.wellKnown.borders
  }

  public getNamedColor(name: string): string | null {
    return this.colors[name] ?? null
  }

  public findColorNameByHex(hex: string): string | null {
    for (const [name, color] of Object.entries(this.colors)) {
      if (color === hex) {
        return name
      }
    }

    return null
  }

}

export interface BrandedComponentSpec {
  background: BackgroundSpec | string
  border:     BorderSpec | string
  shape:      ComponentShape
  depth:      number
}

export const BrandedComponentSpec: {
  default:       () => BrandedComponentSpec
  serializeFrom: (branding: ComponentBrandingBase<any>, flags: Record<string, boolean>) => BrandedComponentSpec
} = {
  default: () => ({
    background: 'primary',
    border:     'none',
    shape:      'rectangle',
    depth:      1,
  }),

  serializeFrom: (branding, flags) => {
    const background = 'background' in branding.defaults ? branding.resolve('background', flags) : undefined
    const border     = 'border' in branding.defaults ? branding.resolve('border', flags) : undefined
    const shape      = 'shape' in branding.defaults ? branding.resolve('shape', flags) : undefined
    const depth      = 'depth' in branding.defaults ? branding.resolve('depth', flags) : undefined
    const otherKeys  = Object.keys(branding.defaults).filter(key => !['background', 'border', 'shape', 'depth'].includes(key))

    return {
      background: typeof background === 'string' ? background : BackgroundSpec.default(),
      border:     typeof border === 'string' ? border : BorderSpec.none(),
      shape:      shape ?? 'rectangle',
      depth:      depth ?? 0,
      ...otherKeys.reduce((acc, key) => ({...acc, [key]: branding.resolve(key, flags)}), {}),
    }
  },
}

export type ComponentShape =
  | 'rectangle'
  | 'oval'
  | {rounded: number}

export const ComponentShape: {
  type:   (shape: ComponentShape) => ComponentShapeType
  radius: (shape: ComponentShape) => number
} = {
  type:   shape => isPlainObject(shape) ? 'rounded' : (shape as ComponentShapeType),
  radius: shape => isPlainObject(shape) ? (shape as {rounded: number}).rounded : 0,
}

export type ComponentShapeType = 'rectangle' | 'oval' | 'rounded'

export type BackgroundSpec = SolidColorBackgroundSpec | GradientBackgroundSpec

export const BackgroundSpec: {
  default:   () => BackgroundSpec
  solid:     (color: string, theme: 'light' | 'dark') => BackgroundSpec
  gradient:  (color1: string, color2: string, angle: number, theme: 'light' | 'dark') => BackgroundSpec
} = {
  default: () => ({
    type:  'solid',
    color: '#ffffff',
    theme: 'dark',
  }),
  solid: (color, theme) => ({
    type:  'solid',
    color: color,
    theme: theme,
  }),
  gradient: (color1, color2, angle, theme) => ({
    type:   'gradient',
    colors: [color1, color2],
    angle:  angle,
    theme:  theme,
  }),
}

export interface BackgroundSpecCommon {
  theme: 'dark' | 'light' | 'inherit' | 'default'
}

export interface SolidColorBackgroundSpec extends BackgroundSpecCommon {
  type:  'solid'
  color: string
}

export interface GradientBackgroundSpec extends BackgroundSpecCommon {
  type:   'gradient'
  colors: string[]
  angle:  number
}

export type BorderSpec = SolidBorderSpec | GradientBorderSpec

export interface BorderSpecCommon {
  width:    number
  offset:   number
  position: 'inside' | 'outside'
}

export interface SolidBorderSpec extends BorderSpecCommon {
  type:  'solid'
  color: string
}

export interface GradientBorderSpec extends BorderSpecCommon {
  type:   'gradient'
  colors: string[]
  angle:  number
}
export const BorderSpec: {
  none:  () => BorderSpec
  solid: (color: string, width?: number, offset?: number) => BorderSpec
} = {
  none: () => ({
    type:     'solid',
    color:    '#000000',
    width:    0,
    position: 'inside',
    offset:   0,
  }),

  solid: (color, width = 1, offset = 0) => ({
    type:     'solid',
    color:    color,
    width:    width,
    position: 'inside',
    offset:   offset,
  }),
}

function wellKnownColors(guide: BrandingGuide) {
  return {
    primary:     guide.colors.semantic.primary.hex(),
    secondary:   guide.colors.semantic.secondary.hex(),

    'bg-light-normal': guide.colors.bg.light.normal.hex(),
    'bg-light-semi':   guide.colors.bg.light.semi.hex(),
    'bg-light-alt':    guide.colors.bg.light.alt.hex(),

    'bg-dark-normal': guide.colors.bg.dark.normal.hex(),
    'bg-dark-semi':   guide.colors.bg.dark.semi.hex(),
    'bg-dark-alt':    guide.colors.bg.dark.alt.hex(),

    'fg-dark-normal':    guide.colors.fg.dark.normal.hex(),
    'fg-dark-dim':       guide.colors.fg.dark.dim.hex(),
    'fg-dark-highlight': guide.colors.fg.dark.highlight.hex(),
    'fg-dark-error':     guide.colors.fg.dark.error.hex(),
    'fg-dark-link':      guide.colors.fg.dark.link.hex(),

    'fg-light-normal':    guide.colors.fg.light.normal.hex(),
    'fg-light-dim':       guide.colors.fg.light.dim.hex(),
    'fg-light-highlight': guide.colors.fg.light.highlight.hex(),
    'fg-light-error':     guide.colors.fg.light.error.hex(),
    'fg-light-link':      guide.colors.fg.light.link.hex(),
  }
}

function wellKnownBackgrounds() {
  return {
    body:             BackgroundSpec.solid('bg-light-normal', 'light'),
    primary:          BackgroundSpec.gradient('primary', 'secondary', 45, 'dark'),
    secondary:        BackgroundSpec.gradient('secondary', 'primary', 45, 'dark'),
    solid:            BackgroundSpec.solid('primary', 'dark'),
    launch:           BackgroundSpec.gradient('primary', 'secondary', 45, 'dark'),
    normal:           BackgroundSpec.solid('bg-light-normal', 'light'),
    alt:              BackgroundSpec.solid('bg-light-alt', 'light'),
    semi:             BackgroundSpec.solid('bg-light-semi', 'light'),
    'in-app-browser': BackgroundSpec.solid('primary', 'dark'),
  }
}

function wellKnownBorders() {
  return {
    none:          BorderSpec.none(),
    'light-thin':  BorderSpec.solid('fg-light-normal', 1),
    'light-thick': BorderSpec.solid('fg-light-normal', 2),
    'dark-thin':   BorderSpec.solid('fg-dark-normal', 1),
    'dark-thick':  BorderSpec.solid('fg-dark-normal', 2),
  }
}

function backgroundUsesColor(color: string) {
  return (background: BackgroundSpec) => {
    if (background.type === 'solid') {
      return background.color === color
    } else if (background.type === 'gradient') {
      return some(background.colors, it => it === color)
    } else {
      return false
    }
  }
}

function borderUsesColor(color: string) {
  return (border: BorderSpec) => {
    if (border.type === 'solid') {
      return border.color === color
    } else if (border.type === 'gradient') {
      return some(border.colors, it => it === color)
    } else {
      return false
    }
  }
}