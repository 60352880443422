import React from 'react'
import { Post } from '~/models'
import { observer } from '~/ui/component'
import { BrandedComponent, HBox, Markdown, Tappable, VBox } from '~/ui/components'
import SelectionCheckbox from '~/ui/resources/collection/SelectionCheckbox'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import PostFooter from './PostFooter'
import PostHeader from './PostHeader'
import PostMedia from './PostMedia'

export interface Props {
  post: Post

  selected?:    boolean
  onSelect?:    (post: Post) => any

  requestEdit?: (post: Post) => any
}

const PostView = observer('PostView', (props: Props) => {

  const {
    post,
    selected,
    requestEdit,
    onSelect,
  } = props
  const {guide} = useStyling()

  const select = React.useCallback(() => {
    onSelect?.(post)
  }, [onSelect, post])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <BrandedComponent classNames={[$.postView, {selected}]} branding={guide.feed.post} height={minPostHeight}>
        <Tappable onTap={select}>
          <HBox gap={layout.padding.s} align='top'>
            <SelectionCheckbox id={post.id}/>
            <VBox flex gap={layout.padding.s}>
              <PostHeader
                post={post}
                requestEdit={requestEdit}
              />
              {renderBody()}
              <PostMedia mediaIDs={post.media}/>
              <PostFooter post={post}/>
            </VBox>
          </HBox>
        </Tappable>
      </BrandedComponent>
    )
  }

  function renderBody() {
    if (post.body == null) { return null }

    return (
      <Markdown>
        {post.body}
      </Markdown>
    )
  }

  return render()

})

export default PostView

export const avatarSize = {
  width:  24,
  height: 24,
}

export const minPostHeight = 72

const useStyles = createUseStyles(theme => ({
  postView: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],

    '&.selected': {
      outline: [2, 'solid', theme.semantic.primary],
    },
  },
}))