import { ImportFieldCreator } from 'sheet-importer'

export interface ImportMeta {
  fields: Record<string, ImportFieldMeta | false>
}

export const ImportMeta: {
  default: () => ImportMeta
} = {
  default: () => ({
    fields: {
      id: {},
    },
  }),
}

export interface ImportFieldMeta {
  field?: ImportFieldCreator
  type?:  BooleanConstructor | string
}