import React from 'react'
import { useTimer } from 'react-timer'
import { PlanAnnotation } from '~/models'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import { useTextAnnotationStyle } from './styles'

export interface Props {
  annotation: PlanAnnotation
}

const TextAnnotationEditor = memo('TextAnnotationEditor', (props: Props) => {

  const {annotation} = props

  const {planner} = useFlowPlanner()

  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  const [text, setText] = React.useState<string>(annotation.text)

  const prevAnnotation = usePrevious(annotation)
  React.useEffect(() => {
    if (prevAnnotation !== annotation) {
      setText(annotation.text)
    }
  }, [annotation, prevAnnotation])

  React.useEffect(() => {
    textAreaRef.current?.select()
  }, [])

  const textAreaStyle = useTextAnnotationStyle(annotation)

  const [hasFocus, setHasFocus] = React.useState<boolean>(false)
  const [saving, setSaving] = React.useState<boolean>(false)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.textAnnotationEditor}>
        {renderTextArea()}
      </VBox>
    )
  }

  function renderTextArea() {
    return (
      <textarea
        classNames={[$.textArea, {focus: hasFocus, saving}]}
        disabled={saving}
        style={textAreaStyle}
        ref={textAreaRef}

        value={text}
        onChange={event => setText(event.currentTarget.value)}
        onKeyDown={handleKeyDown}

        autoFocus={true}
        onFocus={focus}
        onBlur={commit}
      />
    )
  }

  const focus = React.useCallback(() => {
    setHasFocus(true)
  }, [])

  const timer = useTimer()

  const commit = React.useCallback(async () => {
    setSaving(true)
    const promise = planner.updateComponents([annotation.uuid], () => ({text}))
    await timer.await(promise)

    setHasFocus(false)
    setSaving(false)
    planner.stopEditingComponent()
  }, [annotation.uuid, planner, text, timer])

  const handleKeyDown = React.useCallback((event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && event.metaKey) {
      commit()
      event.preventDefault()
      return
    }

    if (event.key === 'Escape') {
      planner.stopEditingComponent()
      event.preventDefault()
      return
    }
  }, [commit, planner])

  return render()

})

export default TextAnnotationEditor

const useStyles = createUseStyles(theme => ({
  textAnnotationEditor: {
    minHeight: 32,
  },

  textArea: {
    flex:       [1, 0, 0],
    overflow:   'hidden',
    lineHeight: 1.2,

    border:       'none',
    background:   theme.colors.bg.light.alt,
    borderRadius: layout.radius.s,

    '&.saving': {
      background:   theme.colors.bg.light.semi,
    },

    '&:focus, &.focus': {
      outline:    'none',
      boxShadow:  [0, 0, 0, 2, theme.semantic.primary],
    },

    padding:  layout.padding.inline.s,
    resize:   'none',

    ...fonts.responsiveFontStyle(theme.fonts.hand),
  },
}))