import React from 'react'
import { RegistrationPreset } from '~/models'
import { memo } from '~/ui/component'
import { Center, Chip, HBox, Label, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import ValidationForm from '../forms/ValidationForm'
import ValidationFormModel from '../forms/ValidationFormModel'

export interface Props {
  preset: RegistrationPreset
}

const RegistrationPresetValidation = memo('RegistrationPresetValidation', (props: Props) => {

  const {t} = useResourceTranslation()

  const {preset} = props

  const [formOpen, openForm, closeForm] = useBoolean()

  const formModel = React.useMemo(
    () => new ValidationFormModel(RegistrationPreset, preset),
    [preset],
  )

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon='flash'
        summary={t(`validation.caption`)}
        requestEdit={openForm}
        body={renderBody()}
        bold
      >
        {renderForm()}
      </SummaryInfo.Item>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderRow('web', 'allowed_origins', preset.allowedOrigins)}
        {renderRow('email', 'allowed_email_patterns', preset.allowedEmailPatterns)}
      </VBox>
    )
  }

  function renderRow(icon: SVGName, key: string, value: string[]) {
    const specified = value.length > 0

    return (
      <HBox flex='shrink' gap={layout.padding.inline.m} align='top'>
        <Center style={{paddingTop: ' 0.2em'}}>
          <SVG
            name={icon}
            size={layout.icon.xs}
            dim={!specified}
          />
        </Center>
        <VBox flex gap={layout.padding.inline.s}>
          <Label small dim={!specified} truncate={false} markup>
            {t(`validation.${key}.${specified ? 'specified' : 'unspecified'}`, {value})}
          </Label>
          {specified && (
            <VBox gap={layout.padding.inline.xs} align='left'>
              {value.map(it => <Chip key={it} small children={it} mono/>)}
            </VBox>
          )}
        </VBox>
      </HBox>
    )
  }

  function renderForm() {
    return (
      <ValidationForm
        open={formOpen}
        requestClose={closeForm}
        model={formModel}
      />
    )
  }

  return render()

})

export default RegistrationPresetValidation