import React from 'react'
import { Project, User } from '~/models'
import ProjectField from '~/ui/app/projects/ProjectField'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { RelatedResourceList, ResourceFieldProps } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  user:     User
  readOnly: boolean
}

const UserProjectsList = memo('UserProjectsList', (props: Props) => {

  const {user, readOnly} = props
  const {t, fieldPrompt, fieldInstruction} = useResourceTranslation()

  const filters = React.useMemo(
    () => ({organisation: user.organisation}),
    [user.organisation],
  )

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <FormFieldHeader
          caption={fieldPrompt('projects')}
          instruction={fieldInstruction('projects')}
        />
        <RelatedResourceList<Project>
          Model={Project}
          parent={user}
          attribute='projects'
          filters={filters}
          renderAddField={renderProjectField}
          emptyPlaceholder={t('fields.projects.empty')}
          allowAdd={!readOnly}
          allowDetail={!readOnly}
          allowRemove={!readOnly}
        />
      </VBox>
    )
  }

  function renderProjectField(select: (project: Project) => void, props: Partial<ResourceFieldProps<Project>>) {
    return (
      <ProjectField
        onSelect={select}
        {...props}
      />
    )
  }

  return render()

})

export default UserProjectsList

export const avatarSize = layout.icon.xl