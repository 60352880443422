import React from 'react'
import Color from 'color'
import { range } from 'lodash'
import { memo } from '~/ui/component'
import { createUseStyles, shadows } from '~/ui/styling'
import { useChart } from '../ChartContext'
import ValueLabel from '../ValueLabel'

export interface Props<T> {
  name:          string
  caption?:      string
  valueForPoint: (point: T) => number | null | undefined

  color?:           Color
  showValueLabels?: boolean
}

const _LineSeries = <T extends {}>(props: Props<T>) => {

  const {name, valueForPoint, color: props_color, showValueLabels} = props
  const {data, keyForPoint, colorForSeries, chartLayout, hasPopup, showPopupForPoint, hidePopup} = useChart<T>()

  const color = props_color ?? colorForSeries(name)

  //------
  // Rendering

  const $ = useStyles({color})

  function render() {
    return (
      <g>
        {renderLine()}
        {data.map(renderPoint)}
        {showValueLabels && data.map(renderValueLabel)}
      </g>
    )
  }

  function renderPoint(point: T, index: number) {
    const value = valueForPoint(point)
    if (value == null) { return null }

    return (
      <LineSeriesPoint<T>
        key={keyForPoint(point, index)}
        point={point}
        value={value}
        index={index}
        color={color}
        onEnter={hasPopup ? showPopupForPoint : undefined}
        onLeave={hasPopup ? hidePopup : undefined}
      />
    )
  }

  function renderLine() {
    const path = range(0, data.length).map(index => {
      const cmd   = index === 0 ? 'M' : 'L'
      const point = data[index]
      const key   = keyForPoint(point, index - 1)
      const value = valueForPoint(point) ?? 0
      const x     = chartLayout.xForPointKey(key)
      const y     = chartLayout.yForValue(value)

      return `${cmd} ${x} ${y}`
    }).join(' ')

    return (
      <path
        classNames={$.line}
        d={path}
      />
    )

  }

  function renderValueLabel(point: T, index: number) {
    const value = valueForPoint(point)
    if (value == null) { return null }

    return (
      <ValueLabel
        key={keyForPoint(point, index)}
        point={point}
        index={index}
        value={value}
      />
    )
  }

  return render()
}

const LineSeries = memo('LineSeries', _LineSeries) as typeof _LineSeries
export default LineSeries

interface LineSeriesPointProps<T> {
  point: T
  value: number
  index: number
  color: Color

  onEnter?: (point: T, element: SVGElement) => any
  onLeave?: (point: T, element: SVGElement) => any
}

const _LineSeriesPoint = <T extends {}>(props: LineSeriesPointProps<T>) => {

  const {point, value, index, color, onEnter, onLeave} = props
  const {keyForPoint, chartLayout} = useChart()

  const interactive   = onEnter != null || onLeave != null
  const popupHandlers = React.useMemo<React.SVGAttributes<SVGElement>>(() => {
    if (onEnter == null && onLeave == null) { return {} }

    return {
      onMouseEnter: e => onEnter?.(point, e.currentTarget),
      onMouseLeave: e => onLeave?.(point, e.currentTarget),
    }
  }, [onEnter, onLeave, point])

  const $ = useStyles({color})

  function render() {
    const key = keyForPoint(point, index)
    const x   = chartLayout.xForPointKey(key)
    const y   = chartLayout.yForValue(value)

    return (
      <g classNames={[$.point, {interactive}]} {...popupHandlers}>
        <rect
          classNames={$.pointHitArea}
          x={chartLayout.xForPointKey(key, -0.5)}
          y={chartLayout.plotRect.top}
          width={chartLayout.xForPointKey(key, 0.5) - chartLayout.xForPointKey(key, -0.5)}
          height={chartLayout.plotRect.height}
        />
        <rect
          classNames={$.pointHitAreaFg}
          x={chartLayout.xForPointKey(key, -0.2)}
          y={chartLayout.plotRect.top}
          width={chartLayout.xForPointKey(key, 0.2) - chartLayout.xForPointKey(key, -0.2)}
          height={chartLayout.plotRect.height}
        />
        <g transform={`translate(${x},${y})`}>
          <ellipse
            classNames={$.pointDisc}
            rx={4}
            ry={4}
          />
        </g>
      </g>
    )
  }


  return render()

}

const LineSeriesPoint = memo('LineSeries.LineSeriesPoint', _LineSeriesPoint) as typeof _LineSeriesPoint

const useStyles = createUseStyles(theme => ({
  line: (opts: {color: Color}) => ({
    strokeWidth: 2,
    stroke:      opts.color,
    fill:        'none',
  }),

  point: {},

  pointDisc: (opts: {color: Color}) => ({
    fill: opts.color,

    '$point.interactive:hover &': {
      transform: 'scale(1.5)',
    },
    filter: `drop-shadow(1px 1px 2px ${shadows.shadowColor})`,
  }),

  pointHitArea: {
    fill: 'transparent',
  },

  pointHitAreaFg: {
    fill: 'transparent',

    '$point:hover &': {
      fill: theme.bg.hover,
    },
  },
}))