import React from 'react'
import { observer } from '~/ui/component'
import { Center, Flipper, Label, Spinner, TextBlock, VBox } from '~/ui/components'
import { Form } from '~/ui/form'
import { layout } from '~/ui/styling'
import CodeFields from './CodeFields'
import RegistrationFields from './RegistrationFields'
import RegistrationFormModel from './RegistrationFormModel'
import RegistrationPanel from './RegistrationPanel'
import { RegistrationWidgetContainer } from './RegistrationWidgetContext'
import {
  RegistrationTranslationKey,
  RegistrationWidgetModel,
  RegistrationWidgetModelStatus,
} from './types'

export interface Props {
  model: RegistrationWidgetModel
  step?: RegistrationStep

  renderDefaultLogo:       (size: Size) => React.ReactNode
  renderLanguageSwitcher?: () => React.ReactNode
}

const RegistrationWidget = observer('RegistrationWidget', (props: Props) => {

  const {
    model,
    step: props_step,
    renderDefaultLogo,
    renderLanguageSwitcher,
  } = props

  const status = model.status
  const stepForStatus = React.useCallback((status: RegistrationWidgetModelStatus) => {
    if (status === 'code') {
      return RegistrationStep.enterCode
    } else if (status === 'register') {
      return RegistrationStep.register
    } else {
      return null
    }
  }, [])

  const [step, setStep] = React.useState(props_step ?? stepForStatus(status))

  React.useEffect(() => {
    if (props_step != null) { return }

    const nextStep = stepForStatus(status)
    if (nextStep == null) { return }
    if (nextStep === step) { return }

    setStep(nextStep)
  }, [props_step, status, step, stepForStatus])

  const formModel = React.useMemo(
    () => new RegistrationFormModel(model),
    [model],
  )

  //------
  // Rendering

  function render() {
    return (
      <RegistrationWidgetContainer model={model}>
        <Form model={formModel}>
          {step == null ? (
            renderPreflight()
          ) : (
            <Flipper flipped={step === RegistrationStep.register}>
              {renderPanel()}
            </Flipper>
          )}
        </Form>
      </RegistrationWidgetContainer>
    )
  }

  function renderPreflight() {
    return (
      <Center flex>
        <Spinner/>
      </Center>
    )
  }

  function renderPanel() {
    return (
      <RegistrationPanel
        key={step}
        renderDefaultLogo={renderDefaultLogo}
        renderLanguageSwitcher={renderLanguageSwitcher}
        renderFields={renderFields}
        renderConfirmation={renderConfirmation}
      />
    )
  }

  function renderFields() {
    if (step === RegistrationStep.enterCode) {
      return <CodeFields/>
    } else {
      return <RegistrationFields/>
    }
  }

  function renderConfirmation() {
    return (
      <VBox flex gap={layout.padding.s} justify='middle'>
        <Label h3 align='center'>
          {model.translate(RegistrationTranslationKey.confirmationTitle)}
        </Label>
        <TextBlock caption markup align='center'>
          {model.translate(RegistrationTranslationKey.confirmationBody)}
        </TextBlock>
      </VBox>
    )
  }

  return render()

})

export default RegistrationWidget

export enum RegistrationStep {
  enterCode,
  register,
}