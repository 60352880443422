import React from 'react'
import { makeObservable, observable } from 'mobx'
import { Competition, ProfileItemVisibility } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { Panel, SelectField, SwitchField, TextBlock, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormDialogProps, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props extends Omit<FormDialogProps<any>, 'model'> {
  competition: Competition
}

const CompetitionProfileItemForm = observer('CompetitionProfileItemForm', (props: Props) => {

  const {competition, ...rest} = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new CompetitionProfileItemFormModel(competition),
    [competition],
  )

  const visibilityChoices = React.useMemo((): Choice[] => [{
    value:   'private',
    caption: t('profile:visibility.private.summary'),
  }, {
    value:   'connections',
    caption: t('profile:visibility.connections.summary'),
  }, {
    value:   'public',
    caption: t('profile:visibility.public.summary'),
  }], [t])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog semi={false} model={formModel} {...rest} title={t('profile_item.form.title')}>
        <VBox gap={layout.padding.m}>
          <TextBlock small dim>
            {t('profile_item.form.instructions')}
          </TextBlock>
          {renderProfileItemToggle()}
          {/* <Dimple horizontal/> */}
          <Panel padding={layout.padding.m}>
            {renderFields()}
          </Panel>
        </VBox>
      </FormDialog>
    )
  }

  function renderProfileItemToggle() {
    return (
      <FormField name='showInProfile' caption={false}>
        {bind => <SwitchField {...bind}/>}
      </FormField>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='profileVisibility'>
          {bind => <SelectField {...bind} choices={visibilityChoices} enabled={formModel.showInProfile}/>}
        </FormField>
        <FormField name='discloseRankings'>
          {bind => <SwitchField {...bind} enabled={formModel.showInProfile}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default CompetitionProfileItemForm

class CompetitionProfileItemFormModel implements FormModel {

  constructor(
    public readonly competition: Competition,
  ) {
    this.showInProfile = this.competition.profileVisibility != null
    this.profileVisibility = this.competition.profileVisibility ?? 'private'
    this.discloseRankings = this.competition.discloseRankings ?? true
    makeObservable(this)
  }

  @observable
  public showInProfile: boolean

  @observable
  public profileVisibility: ProfileItemVisibility

  @observable
  public discloseRankings: boolean

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Competition, this.competition.id, {
      profileVisibility: this.showInProfile ? this.profileVisibility : null,
      discloseRankings:  this.discloseRankings,
    })
  }

}