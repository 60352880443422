import I18next from 'i18next'
import { DateTime } from 'luxon'
import { ModelSerialized, serialize } from 'ymodel'
import { Model, resource } from './Model'

@resource<Insight>('insights', {
  icon:    () => 'eye',
  caption: () => I18next.t('insights:singular'),
})
export class Insight extends Model {

  protected static override beforeDeserialize(serialized: ModelSerialized) {
    // Insights are not actually models - they have an `uuid` property instead of an `id` property.
    if ('uuid' in serialized) {
      serialized.id = serialized.uuid
      delete serialized.uuid
    }

    delete serialized.position
    return serialized
  }

  public participant!: ParticipantDescriptor | null

  @serialize(DateTime)
  public dateIn!: DateTime

  @serialize(DateTime)
  public dateOut!: DateTime | null


}

export interface ParticipantDescriptor {
  id:        string
  firstName: string
  lastName:  string | null
  photoURL:  string | null
}