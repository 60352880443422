import React from 'react'
import { observer } from '~/ui/component'
import { Dimple, Label, NumberField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import BrandingColorField from '../../assets/BrandingColorField'
import ComponentBrandingFields from '../ComponentBrandingFields'
import { registerComponentBrandingFields } from '../registry'

export interface Props {}

const TimelineBrandingFields = observer('TimelineBrandingFields', (props: Props) => {

  const {fieldCaption} = useResourceTranslation()

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        {renderScheduleFields()}
        <Dimple horizontal/>
        {renderItemBarFields()}
      </VBox>
    )
  }

  function renderScheduleFields() {
    return (
      <VBox gap={layout.padding.m}>
        <Label h3>
          {fieldCaption('schedule')}
        </Label>

        <FormField name='depth'>
          {bind => <NumberField {...bind} minimum={0} maximum={5} step={1}/>}
        </FormField>

        <VBox gap={layout.padding.s}>
          <FormField name='tickColor'>
            {bind => <BrandingColorField {...bind}/>}
          </FormField>
          <FormField name='activeTickColor'>
            {bind => <BrandingColorField {...bind}/>}
          </FormField>
          <FormField name='nowPlayingColor'>
            {bind => <BrandingColorField {...bind}/>}
          </FormField>
        </VBox>
      </VBox>
    )
  }

  function renderItemBarFields() {
    return (
      <VBox gap={layout.padding.m}>
        <Label h3>
          {fieldCaption('itemBar')}
        </Label>
        <ComponentBrandingFields prefix='itemBar.'/>
      </VBox>
    )
  }

  return render()

})

registerComponentBrandingFields('web.timeline', TimelineBrandingFields)
export default TimelineBrandingFields