import React from 'react'
import I18next from 'i18next'
import { makeObservable, observable } from 'mobx'
import { Organisation, Role, User } from '~/models'
import { authenticationStore, dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, SwitchField, TextBlock, TextField, VBox } from '~/ui/components'
import { FormField, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { ResourceFormDialog, ResourceFormModel } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: (result: SubmitResult) => any

  role: Role
}

const NewUserForm = observer('NewUserForm', (props: Props) => {

  const {open, requestClose, afterSubmit, role} = props

  const user = authenticationStore.user

  const formModel = React.useMemo(
    () => new NewUserFormModel(role),
    [role],
  )

  const toggleShowPassword = React.useCallback(() => {
    formModel.toggleShowPassword()
  }, [formModel])

  const {t} = useResourceTranslation()

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<User>
        Model={User}
        formModel={formModel}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='email' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        {user?.hasRole('super') && role !== 'super' &&
          <FormField name='organisation' required>
            {bind => <ResourceField {...bind} Model={Organisation} />}
          </FormField>
        }
        {renderPasswordFields()}
      </VBox>
    )
  }

  function renderPasswordFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='assignPassword' caption={false}>
          {bind => <SwitchField {...bind}/>}
        </FormField>
        {formModel.assignPassword ? (
          <VBox gap={layout.padding.s}>
            <FormField name='sendEmail' caption={false}>
              {bind => <SwitchField {...bind}/>}
            </FormField>
            <TextBlock small dim>
              {formModel.sendEmail
                ? t('assign_password.explanation.with_email')
                : t('assign_password.explanation.without_email')
              }
            </TextBlock>
          </VBox>
        ) : (
          <VBox gap={layout.padding.s}>
            <FormField name='newPassword' required>
              {bind => (
                <TextField
                  {...bind}
                  type={formModel.showPassword ? 'text' : 'password'}
                  autoComplete='new-password'
                  accessoryRight={renderShowPasswordToggle()}
                />
              )}
            </FormField>

            <FormField name='repeatPassword' required>
              {bind => (
                <TextField
                  {...bind}
                  type={formModel.showPassword ? 'text' : 'password'}
                  autoComplete='new-password'
                  enabled={!formModel.showPassword}
                />
              )}
            </FormField>
          </VBox>
        )}
      </VBox>
    )
  }

  function renderShowPasswordToggle() {
    return (
      <ClearButton
        icon={formModel.showPassword ? 'eye' : 'eye-off'}
        onTap={toggleShowPassword}
      />
    )
  }

  return render()

})

export default NewUserForm

class NewUserFormModel extends ResourceFormModel<User> {

  constructor(public readonly role: Role) {
    super(User, null)

    makeObservable(this)
  }

  @observable
  public assignPassword: boolean = true

  @observable
  public sendEmail: boolean = true

  @observable
  public newPassword: string = ''

  @observable
  public repeatPassword: string = ''

  @observable
  public showPassword: boolean = false

  @observable
  public toggleShowPassword() {
    this.showPassword = !this.showPassword
  }

  public override async submit(): Promise<SubmitResult | undefined> {
    if (this.newPassword != null && this.newPassword !== this.repeatPassword) {
      return {
        status: 'invalid',
        errors: [{
          field:   'repeatPassword',
          message: I18next.t('validation:passwords_dont_match'),
        }],
      }
    }

    return await dataStore.create(this.Model, this.serializeData(), {
      assignPassword: this.assignPassword,
      sendEmail:      this.sendEmail,
    })
  }

  protected override buildData() {
    return {
      ...super.buildData(),
      role:     this.role,
      password: this.newPassword,
    }
  }

}