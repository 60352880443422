import I18n from 'i18next'
import { isArray } from 'lodash'
import { formatList } from '~/formatting'

export function list(tail: 'and' | 'or' = 'and') {
  return (value: any) => {
    if (!isArray(value)) {
      value = [value]
    }

    return formatList(value, {
      tailSeparator: I18n.t(`list:${tail}`),
    })
  }
}