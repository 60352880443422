import { get, omit } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { BrandedComponentSpec, Branding, ComponentShape, CustomImage, Language } from '~/models'
import { dataStore } from '~/stores'
import { ProxyFormModel, SubmitResult } from '~/ui/form'
import { BrandingGuide, ComponentBrandingBase } from '~/ui/styling'

export default class ComponentBrandingFormModel<S extends {} = BrandedComponentSpec> implements ProxyFormModel<S> {

  constructor(
    public readonly guide: BrandingGuide,
    public readonly branding: Branding,
    public readonly componentName: string,
    private readonly initialVariant: string | null,
  ) {
    this.currentVariant = this.resolveVariant(initialVariant)
    makeObservable(this)
  }

  private readonly component = this.deriveComponent(this.guide, this.componentName)

  //------
  // Component spec derivation

  protected readonly componentSpecs = this.deriveComponentSpecs()

  private deriveComponent(guide: BrandingGuide, name: string) {
    const component = get(guide, name)
    if (!(component instanceof ComponentBrandingBase)) {
      throw new Error("Component branding component must be a subclass of ComponentBrandingBase")
    }
    return component
  }

  private deriveComponentSpecs() {
    const specs = new Map<string | null, S>()
    for (const variant of [null, ...this.component.variantNames]) {
      const key   = variant == null ? this.componentName : `${this.componentName}:${variant}`
      const flags = variant == null ? {} : {[variant]: true}
      const spec  = this.branding.components[key] as any as S

      if (spec != null) {
        specs.set(variant, spec)
      } else {
        specs.set(variant, {
          ...this.defaultComponentSpec(),
          ...BrandedComponentSpec.serializeFrom(this.component, flags),
        })
      }
    }

    return specs
  }

  protected defaultComponentSpec(): S {
    return this.component.defaults
  }

  //------
  // Variants

  @observable
  private currentVariant: string | null

  @action
  public switchVariant(variant: string | null) {
    this.currentVariant = this.resolveVariant(variant)
    return this.currentVariant
  }

  @computed
  public get currentSpec() {
    return this.componentSpecs.get(this.currentVariant) ?? this.defaultComponentSpec()
  }

  private resolveVariant(variant: string | null) {
    if (variant == null) { return variant }
    if (this.component.variantNames.includes(variant)) {
      return variant
    } else {
      return null
    }
  }

  //------
  // Images & texts

  protected getImage(name: string, variant: string | null = null) {
    if (variant != null) {
      return this.branding.images[`${name}:${variant}`] ?? this.branding.images[name] ?? null
    } else {
      return this.branding.images[name] ?? null
    }
  }

  protected mergeImage(name: string, variant: string | null, image: CustomImage | null) {
    const keys = variant == null
      ? [name]
      : [`${name}:${variant}`, name]

    let images = {...this.branding.images}
    for (const key of keys) {
      if (image == null) {
        images = omit(images, key)
      } else {
        images = {...images, [key]: image}
      }
    }

    return images
  }

  protected getTextTranslations(key: string) {
    const result: Record<Language, string> = {}
    for (const [language, translations] of Object.entries(this.branding.texts)) {
      const translation = translations[key]
      if (translation != null) {
        result[language] = translation
      }
    }

    return result
  }

  protected mergeTexts(texts: Record<string, Record<Language, string>>) {
    let result = this.branding.texts
    for (const [key, translations] of Object.entries(texts)) {
      for (const [language, translation] of Object.entries(translations)) {
        result ={
          ...result,
          [language]: {
            ...result[language],
            [key]: translation,
          },
        }
      }
    }

    return result
  }

  //------
  // Properties

  @observable
  private overrides = new Map<string | null, AnyObject>()

  public getValue(name: string) {
    if (name in this) {
      return (this as any)[name]
    }

    const overrides = this.overrides.get(this.currentVariant) ?? {}
    if (name in overrides) {
      return overrides[name]
    } else {
      return this.convertFromSpec(name, this.currentSpec)
    }
  }

  @action
  public assign(data: AnyObject) {
    const rest: AnyObject = {}
    for (const [key, value] of Object.entries(data)) {
      if (key in this) {
        Object.assign(this, {[key]: value})
      } else {
        Object.assign(rest, {[key]: value})
      }
    }

    if (Object.keys(rest).length > 0) {
      this.overrides.set(this.currentVariant, {
        ...this.overrides.get(this.currentVariant),
        ...rest,
      })
    }
  }

  //------
  // Shape

  protected convertFromSpec(name: string, spec: S) {
    if (name === 'shapeType') {
      return ComponentShape.type((spec as any).shape)
    } else if (name === 'shapeRadius') {
      return ComponentShape.radius((spec as any).shape)
    } else {
      return (spec as any)[name]
    }
  }

  protected setSpecProp(spec: any, name: string, overrides: AnyObject) {
    if (name === 'shape' && !(name in overrides)) {
      const shapeType   = overrides.shapeType ?? ComponentShape.type((spec as any).shape)
      const shapeRadius = overrides.shapeRadius ?? ComponentShape.radius((spec as any).shape)
      spec.shape = shapeType === 'rounded' ? {rounded: shapeRadius} : shapeType
    } else {
      spec[name] = overrides[name] ?? this.convertFromSpec(name, spec)
    }
  }

  //------
  // Submit

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.updateOrCreate(
      Branding,
      this.branding.id,
      this.buildData(),
      this.branding.serialized,
    )
  }

  protected buildData(): AnyObject {
    const components: Record<string, any> = {
      ...this.branding.components,
    }

    for (const variant of this.overrides.keys()) {
      const key = variant == null ? this.componentName : `${this.componentName}:${variant}`
      components[key] = this.buildSpec(variant)
    }

    return {components}
  }

  public reset() {
    this.overrides = new Map()
    this.currentVariant = this.initialVariant
  }

  public buildSpec(variant: string | null = this.currentVariant) {
    const base      = this.componentSpecs.get(variant) ?? this.defaultComponentSpec()
    const overrides = this.overrides.get(variant) ?? {}

    const spec: Record<string, any> = {...base}
    for (const key of Object.keys(spec)) {
      this.setSpecProp(spec, key, overrides)
    }

    return spec as any as S
  }

}