import React from 'react'
import { OnboardingTrigger } from '~/models'
import { memo } from '~/ui/component'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: OnboardingTrigger
}

const OnboardingTriggerNodeView = memo('OnboardingTriggerNodeView', (props: Props) => {
  return (
    <TriggerNodeView
      {...props}
      icon='login'
    />
  )
})

register('onboarding', OnboardingTriggerNodeView)