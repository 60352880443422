import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Feed } from '~/models'
import { observer } from '~/ui/component'
import { Center, Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { EditFormProps, ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import FeedForm from '../FeedForm'
import FeedAccess from './FeedAccess'
import FeedBody from './FeedBody'
import FeedModeration from './FeedModeration'
import FeedTargeting from './FeedTargeting'

export type Props = RouteComponentProps<ResourceDetailParams>

const FeedScreen = observer('FeedScreen', (props: Props) => {

  const {t} = useResourceTranslation('feeds')

  const feedID = props.match.params.id
  const [feed] = useModelDocumentData(Feed, feedID, {fetch: 'never'})

  const TypedFeedForm = React.useMemo((): React.ComponentType<EditFormProps<Feed>> => {
    return props => (
      <FeedForm
        {...props}
        feed={props.model}
      />
    )
  }, [])

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen
        Model={Feed}
        id={feedID}
        include={['bots', 'group']}

        renderInfo={renderInfo}
        renderBody={renderBody}
        renderSummaryFooter={renderSummaryFooter}
        EditFormComponent={TypedFeedForm}
      />
    )
  }

  //------
  // Body

  function renderBody() {
    if (feed == null) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    } else {
      return (
        <FeedBody
          feed={feed}
        />
      )
    }
  }

  //------
  // Right bar

  function renderInfo(feed: Feed) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item
          summary={t('created', {when: feed.createdAt})}
          markup
        />
      </SummaryInfo>
    )
  }

  function renderSummaryFooter(feed: Feed) {
    return [
      <FeedTargeting key='targeting' feed={feed}/>,
      <FeedAccess key='posts-open' feed={feed}/>,
      <FeedAccess key='comments-open' feed={feed} attribute='mayComment'/>,
      <FeedModeration key='moderation' feed={feed}/>,
    ]
  }
  return render()

})

export default FeedScreen