import socket from 'socket.io-react'
import { Database } from 'mobx-document'
import { Participant, Sender, User } from '~/models'
import { ChatService, ChatServiceOptions, SenderDocument } from './chat'
import { register } from './support'

export class ChatStore {

  public chatService(participantID: string, senderModel: User | Participant, options: ChatServiceOptions = {}) {
    const sender = this.buildSender(senderModel)
    return new ChatService(socket, participantID, sender, options)
  }

  //------
  // Senders

  public readonly senders = new Database<SenderDocument>({
    getID:         (sender) => sender.id,
    getDocument:   sender => new SenderDocument(sender.id, {initialData: sender}),
    emptyDocument: id => new SenderDocument(id),
  })

  private buildSender(sender: User | Participant): Sender {
    if (sender instanceof User) {
      return {
        type:      'user',
        id:        sender.id,
        firstName: sender.name,
        lastName:  null,
        photoURL:  sender.photoURL,
        headline:  null,
      }
    } else {
      return {
        type:      'participant',
        id:        sender.id,
        firstName: sender.firstName,
        lastName:  sender.lastName,
        photoURL:  sender.photoURL,
        headline:  sender.headline,
      }
    }
  }

}

export type Viewpoint = 'participant' | 'operator'

const chatStore = register(new ChatStore())
export default chatStore