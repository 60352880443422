import React from 'react'
import { DateTime } from 'luxon'
import { memo } from '~/ui/component'
import { Calendar, DateTimeComponentsField, DateTimeField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

const DateTriggerFields = memo('DateTriggerFields', () => {

  const dateTimeFieldRef    = React.useRef<DateTimeComponentsField>(null)
  const onCalendarChangeRef = React.useRef<(value: DateTime | null) => any>()
  const selectDateRef       = React.useRef<(value: DateTime | null) => any>()

  const selectDate = React.useCallback((upstream: (value: DateTime | null) => any) => {
    if (onCalendarChangeRef.current !== upstream || selectDateRef.current == null) {
      selectDateRef.current = (value: DateTime | null) => {
        upstream(value)
        dateTimeFieldRef.current?.focus('hour')
      }
    }

    onCalendarChangeRef.current = upstream
    return selectDateRef.current
  }, [])

  return (
    <VBox gap={layout.padding.m}>
      <FormField name='date' caption={false}>
        {bind => (
          <Calendar
            selectedDate={bind.value}
            onSelect={selectDate(bind.onChange)}
            utc
          />
        )}
      </FormField>
      <FormField name='date' caption={false}>
        {bind => <DateTimeField ref={dateTimeFieldRef} {...bind} utc picker={false}/>}
      </FormField>
    </VBox>
  )

})

export default DateTriggerFields