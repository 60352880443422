import React from 'react'
import { isSegue, PlanComponent } from '~/models'
import { observer } from '~/ui/component'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import PlannerComponent from './PlannerComponent'
import { useSelection } from './SelectionContext'

export interface Props {
  transform: string
}

const ContentLayer = observer('ContentLayer', (props: Props) => {

  const {transform} = props

  const {planner}       = useFlowPlanner()
  const {selectedUUIDs} = useSelection.unoptim()
  const components      = planner.zOrderedComponents ?? []
  const boundsOverrides = planner.componentBoundsOverrides

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={$.contentLayer}>
        <div classNames={$.container} style={{transform}}>
          {components.map(renderComponent)}
        </div>
      </div>
    )
  }

  function renderComponent(component: PlanComponent) {
    const boundsOverride = boundsOverrides?.get(component.uuid)
    const selected       = selectedUUIDs.includes(component.uuid)
    return (
      <ContentLayerItem
        key={component.uuid}
        component={component}
        boundsOverride={boundsOverride}
        selected={selected}
      />
    )
  }

  return render()

})

export default ContentLayer


export interface ContentLayerItemProps {
  component:      PlanComponent
  boundsOverride: LayoutRect | undefined
  selected:       boolean
}

const ContentLayerItem = observer('ContentLayerItem', (props: ContentLayerItemProps) => {

  const {component, boundsOverride, selected} = props

  const {planner} = useFlowPlanner()
  const loading   = planner.isLoadingComponent(component.uuid) ?? false

  const isCopying    = planner.moveMode === 'copy' && boundsOverride != null
  const actualBounds = isSegue(component) ? emptyLayoutRect : component.bounds
  const bounds       = isSegue(component) ? emptyLayoutRect : (boundsOverride ?? component.bounds)

  //------
  // Request auto size

  const hasSize = (bounds.width >= 0 && bounds.height >= 0)

  React.useEffect(() => {
    if (hasSize || isSegue(component)) { return }
    if (loading) { return }

    planner.requestAutoSize(component.uuid, {
      x: bounds.width === -1 ? 1 : -1,
      y: bounds.height === -1 ? 1 : -1,
    })
  }, [bounds, component, hasSize, loading, planner])

  if (isCopying) {
    return (
      <>
        <PlannerComponent
          component={component}
          bounds={actualBounds}
          selected={selected}
        />
        <PlannerComponent
          component={component}
          bounds={bounds}
          selected={selected}
        />
      </>
    )
  } else {
    return (
      <PlannerComponent
        component={component}
        bounds={bounds}
        selected={selected}
      />
    )
  }

})

const emptyLayoutRect: LayoutRect = {top: 0, left: 0, width: 0, height: 0}

export const createIconSize = layout.icon.m

const useStyles = createUseStyles({
  contentLayer: {
    ...layout.overlay,
    transformStyle: 'preserve-3d',
    pointerEvents:  'none',
  },

  container: {
    position: 'absolute',
    top:      0,
    left:     0,
    width:    0,
    height:   0,

    willChange: ['transform'],
    'html.canvas-anim &': {
      transition: animation.transitions.medium('transform'),
    },
  },
})