import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { DaySelector, PlanSegue } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, PopupMenu, PopupMenuItem, Tappable } from '~/ui/components'
import SVG from '~/ui/components/SVG'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  segue: PlanSegue
}

const DelayAttribute = memo('DelayAttribute', (props: Props) => {

  const {segue}   = props
  const delay     = segue.delay

  const {planner} = useFlowPlanner()
  const [t]       = useTranslation('flow_planner')
  const {colors}  = useStyling()

  //------
  // Actions

  const edit = React.useCallback(() => {
    planner.editSegueDetail(segue, 'delay')
  }, [planner, segue])

  const remove = React.useCallback(() => {
    return planner.updateSegue(segue.uuid, segue => ({...segue, delay: null}))
  }, [planner, segue.uuid])

  const menuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:    'pencil',
      caption: t(`segues.actions.${delay?.reminder ? 'edit_reminder' : 'edit_delay'}`),
      onSelect: edit,
    })
    items.push({
      section: '-',
    }, {
      icon:     'trash',
      caption:  t(`segues.actions.${delay?.reminder ? 'remove_reminder' : 'remove_delay'}`),
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [colors.semantic.negative, delay, edit, remove, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (delay == null) { return null }

    return (
      <PopupMenu items={menuItems}>
        {renderContent}
      </PopupMenu>
    )
  }

  function renderContent(toggle: () => any) {
    return (
      <Tappable onTap={toggle} onDoubleClick={edit}>
        <ThemeProvider secondary>
          <HBox classNames={$.delay} gap={layout.padding.inline.s}>
            <SVG name={delay?.reminder ? 'bell' : 'stopwatch'} size={layout.icon.m}/>
            {renderLabel()}
          </HBox>
        </ThemeProvider>
      </Tappable>
    )
  }

  function renderLabel() {
    if (delay == null) { return null }

    const i18nPrefix = delay.reminder ? 'segues.delay.reminder' : 'segues.delay.delay'

    switch (delay.type) {
      case 'fixed': return (
        <Label color={colors.semantic.secondary} align='center'>
          {t(`${i18nPrefix}.summary.fixed`, delay)}
        </Label>
      )

      case 'day': return (
        <Label color={colors.semantic.secondary} align='center'>
          {t(`${i18nPrefix}.summary.day`, {
            day:  formatDaySelector(delay.day),
            time: delay.time.toString(),
          })}
        </Label>
      )
    }
  }

  function formatDaySelector(day: DaySelector) {
    if (day.type === 'offset') {
      return t([`day_selector.offset.summary_${day.offset}`, 'day_selector.offset.summary_other'], day).toLocaleLowerCase()
    } else {
      return t([`day_selector.weekday.summary_${day.week}`, 'day_selector.weekday.summary_other'], {
        week:    day.week,
        weekday: DateTime.fromObject({weekday: day.weekday}).toFormat('EEEE'),
      })
    }
  }

  return render()

})

export default DelayAttribute

export const height = 32

const useStyles = createUseStyles(theme => ({
  delay: {
    minHeight:    height,
    borderRadius: 1000,
    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    background:   theme.colors.bg.light.alt,

    position: 'relative',
    '&:hover::after': {
      ...layout.overlay,
      content:       '""',
      pointerEvents: 'none',
      borderRadius:  height / 2,
      background:    theme.bg.hover,
      boxShadow:     shadows.depth(1),
    },
  },
}))