import React from 'react'
import { PlanNode } from '~/models'
import { memo } from '~/ui/component'
import TriggerForm from '../triggers/TriggerForm'
import ExitNodeForm from './ExitNodeForm'
import FirstNodeForm from './FirstNodeForm'
import SampleNodeForm from './SampleNodeForm'

export interface Props {
  open:         boolean
  requestClose: () => any
  node:         PlanNode
}

const NodeForm = memo('NodeForm', (props: Props) => {

  const {node, ...rest} = props

  switch (node.type) {
    case 'trigger':
      return (
        <TriggerForm
          trigger={node.trigger}
          triggerType={node.trigger.type}
          node={node}
          operation={{update: node.uuid}}
          {...rest}
        />
      )
    case 'sample':
      return (
        <SampleNodeForm
          node={node}
          {...rest}
        />
      )
    case 'first':
      return (
        <FirstNodeForm
          node={node}
          {...rest}
        />
      )
    case 'exit':
      return (
        <ExitNodeForm
          node={node}
          {...rest}
        />
      )
    default:
      return null
  }

})

export default NodeForm