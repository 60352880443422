import React from 'react'
import { Project } from '~/models'
import ProjectField from '~/ui/app/projects/ProjectField'
import { memo } from '~/ui/component'
import { useResourceTranslation } from '~/ui/resources'
import { RelatedResourceList, ResourceFieldProps } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  project:  Project
}

const LinkedProjectsList = memo('LinkedProjectsList', (props: Props) => {

  const {project} = props
  const {t} = useResourceTranslation('projects')

  const filters = React.useMemo(() => ({
    organisation: project.organisation,
    id: {$ne: project.id},
  }), [project.id, project.organisation])

  //------
  // Rendering

  function render() {
    return (
      <RelatedResourceList<Project>
        Model={Project}
        parent={project}
        attribute='linkedProjects'
        filters={filters}
        renderAddField={renderProjectField}
        addPrompt={t('linked-projects.link-project')}
        linked={false}
        allowDetail={false}
      />
    )
  }

  function renderProjectField(select: (project: Project) => void, props: Partial<ResourceFieldProps<Project>>) {
    return (
      <ProjectField
        onSelect={select}
        {...props}
      />
    )
  }

  return render()

})

export default LinkedProjectsList

export const avatarSize = layout.icon.xl