import React from 'react'
import { useTranslation } from 'react-i18next'
import config from '~/config'
import { memo } from '~/ui/component'
import { ClearButton, HBox, KeyHint, Label, Slider, Tooltip, VBox } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import { useCanvas } from './FlowPlannerCanvasContext'

const CanvasControls = memo('CanvasControls', () => {

  const {
    mode,
    viewport,
    insightsShown,
    toggleInsights,
    setMode,
    setViewport,
    fitCanvas,
    resetCanvas,
    zoomIn,
    zoomOut,
  } = useCanvas.unoptim()

  const setZoom = React.useCallback((zoom: number) => {
    setViewport({zoom})
  }, [setViewport])

  const [t] = useTranslation('flow_planner')

  const {colors} = useStyling()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider dark>
        <HBox
          classNames={$.canvasControls}
          gap={layout.padding.inline.m}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
        >
          {renderZoomControls()}
          <Label dimmer small>|</Label>
          {renderButtons()}
          <Label dimmer small>|</Label>
          {renderInsightsButton()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderZoomControls() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <HBox>
          <ClearButton
            icon='zoom-out'
            onTap={zoomOut}
            small
          />
          <VBox classNames={$.zoomSlider}>
            <Slider
              value={viewport.zoom}
              onChange={setZoom}
              min={config.planner.minZoom}
              max={config.planner.maxZoom}
              logarithmic
            />
          </VBox>
          <ClearButton
            icon='zoom-in'
            onTap={zoomIn}
            small
          />
        </HBox>
        <ClearButton
          icon='zoom-reset'
          onTap={reset}
        />
      </HBox>
    )
  }

  function renderButtons() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <ClearButton
          icon='pointer'
          onTap={setSelectMode}
          color={mode === 'select' ? colors.semantic.secondary : undefined}
        />
        <ClearButton
          icon='move'
          onTap={setPanMode}
          color={mode === 'pan' ? colors.semantic.secondary : undefined}
        />
        <ClearButton
          icon='connect'
          onTap={setConnectMode}
          color={mode === 'connect' ? colors.semantic.secondary : undefined}
        />
      </HBox>
    )
  }

  function renderInsightsButton() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <Tooltip renderTooltip={renderInsightsTooltip}>
          <ClearButton
            icon='eye'
            onTap={toggleInsights}
            color={insightsShown ? colors.semantic.secondary : undefined}
          />
        </Tooltip>
      </HBox>
    )
  }

  function renderInsightsTooltip() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <Label>
          {t('insights.tooltip')}
        </Label>
        <KeyHint keyHint='I'/>
      </HBox>
    )
  }

  const reset = React.useCallback((event: React.SyntheticEvent<any>) => {
    if ((event.nativeEvent as any).altKey) {
      resetCanvas()
    } else {
      fitCanvas()
    }
  }, [fitCanvas, resetCanvas])

  const setSelectMode = React.useCallback(() => {
    setMode('select')
  }, [setMode])

  const setPanMode = React.useCallback(() => {
    setMode('pan')
  }, [setMode])

  const setConnectMode = React.useCallback(() => {
    setMode('connect')
  }, [setMode])

  const stopPropagation = React.useCallback((event: React.SyntheticEvent) => {
    event.nativeEvent.stopPropagation()
  }, [])

  return render()

})

export default CanvasControls

export const height = layout.barHeight.s

const useStyles = createUseStyles({
  canvasControls: {
    background:   colors.shim.light,
    height:       height,
    borderRadius: height / 2,
    padding:      [layout.padding.inline.s, layout.padding.inline.m + layout.padding.inline.m],
  },

  zoomSlider: {
    width: 120,
  },
})