import React from 'react'
import { Page } from '~/models'
import ContentPageBody from './content/ContentPageBody'
import IndexPageBody from './index/IndexPageBody'
import MenuPageBody from './menu/MenuPageBody'

export default function renderPageBody(page: Page, interactive: boolean) {
  switch (page.type) {
    case 'menu':
      return <MenuPageBody page={page} interactive={interactive}/>
    case 'content':
      return <ContentPageBody page={page} interactive={interactive}/>
    case 'index':
      return <IndexPageBody page={page} interactive={interactive}/>
    default:
      return null
  }
}