import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import { Model, ModelClass } from '~/models'
import { observer } from '~/ui/component'
import { ClearButton, EmptyOrFetching, Popup, Scroller, SearchField, VBox } from '~/ui/components'
import { useModelEndpointData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { ResourceListItem, ResourceListItemProps } from '../components'

export interface Props<M extends Model> {
  open:         boolean
  requestClose: () => any

  Model:     ModelClass<M>
  value:     string | null
  onChange?: (value: string | null) => any
  onCommit?: (value: string | null) => any

  filters?:          Record<string, string | null>

  renderPropsForModel?: (model: M) => Partial<Omit<ResourceListItemProps, 'model'>>

  header?:   React.ReactNode
  children?: React.ReactNode
}

const _ResourcePopup = <M extends Model>(props: Props<M>) => {

  const {
    open,
    requestClose,
    Model,
    value,
    onChange,
    onCommit,
    filters,
    renderPropsForModel,
    header,
    ...rest
  } = props

  const timer = useTimer()

  //------
  // Searching

  const [results, {endpoint, fetchStatus}] = useModelEndpointData(Model)

  const search = React.useCallback(async (search: string | null) => {
    await timer.await(endpoint.fetchWithParams({search, filters}))
  }, [endpoint, filters, timer])

  const [t]       = useTranslation('resource_popup')
  const resourceT = useResourceTranslation(Model.resourceType)
  const singular  = resourceT.singular()
  const plural    = resourceT.plural()

  const select = React.useCallback((id: string) => {
    onChange?.(id)
    onCommit?.(id)
    requestClose?.()
  }, [onChange, onCommit, requestClose])

  const clear = React.useCallback(() => {
    onChange?.(null)
    onCommit?.(null)
    requestClose?.()
  }, [onChange, onCommit, requestClose])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Popup
        open={open}
        requestClose={requestClose}
        renderContent={renderContent}
        classNames={$.resourcePopup}
        autoFocus={true}
        {...rest}
      />
    )
  }

  function renderContent() {
    return (
      <VBox flex='both'>
        {header}
        {renderHeader()}
        {renderList()}
        {renderFooter()}
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <VBox classNames={$.header}>
        <SearchField
          onSearch={search}
          inputStyle='dark'
        />
      </VBox>
    )
  }

  function renderFooter() {
    return (
      <VBox align='center' classNames={$.footer}>
        <ClearButton
          icon='cross-circle'
          caption={t('clear')}
          onTap={clear}
          padding='horizontal'
        />
      </VBox>
    )
  }

  function renderList() {
    return (
      <Scroller contentClassNames={$.content} flex='both'>
        {results.length === 0 ? (
          renderEmpty()
        ) : (
          renderResults()
        )}
      </Scroller>
    )
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        {...t('no_results', {singular, plural})}
        status={fetchStatus}
        flex='grow'
      />
    )
  }

  function renderResults() {
    return (
      <VBox flex='both'>
        {results.map(renderChoice)}
      </VBox>
    )
  }

  const renderChoice = React.useCallback((model: M) => {
    return (
      <ResourceListItem
        key={model.id}
        model={model}
        onTap={select.bind(null, model.id)}
        {...renderPropsForModel?.(model)}
      />
    )
  }, [renderPropsForModel, select])

  return render()

}

const ResourcePopup = observer('ResourcePopup', _ResourcePopup) as typeof _ResourcePopup
export default ResourcePopup

export const width     = 320
export const minHeight = 360

const useStyles = createUseStyles(theme => ({
  resourcePopup: {
    width:     width,
    minHeight: minHeight,
  },

  header: {
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
    })),
  },

  content: {
    ...layout.responsive(size => ({
      padding: [0, layout.padding.s[size]],
    })),
  },

  footer: {
    background: theme.bg.normal,
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
    })),
  },
}))