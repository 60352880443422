import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, PopupMenu, PopupMenuItem, Tappable } from '~/ui/components'
import { colors, createUseStyles, layout } from '~/ui/styling'
import TextField, { Props as TextFieldProps } from './TextField'

export interface Props extends TextFieldProps {
  skipValidation?: boolean
}

const HrefField = memo('HrefField', (props: Props) => {

  const {
    accessoryLeft,
    skipValidation = false,
    ...rest
  } = props

  const match     = (props.value ?? '').match(PROTOCOL_REGEXP)
  const href      = !skipValidation ? (props.value ?? '').replace(PROTOCOL_REGEXP, '') : props.value
  const scheme    = !skipValidation ? match?.[1] ?? (href.startsWith('//') ? '' : 'https') : ''
  const isHTTPish = ['http', 'https'].includes(scheme)

  const schemeItems = React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = [
      {value: 'http', caption: 'http://'},
      {value: 'https', caption: 'https://'},
      {value: 'mailto', caption: 'mailto:'},
    ]
    if (scheme !== '' && !items.some(it => (it as any).value === scheme)) {
      items.push({value: scheme, caption: `${scheme}:`})
    }

    return items
  }, [scheme])

  const setScheme = React.useCallback((scheme: string) => {
    const clean = href.replace(PROTOCOL_REGEXP, '')
    const prefix = isHTTPish ? `${scheme}://` : `${scheme}:`
    props.onChange?.(prefix + clean)
  }, [href, isHTTPish, props])

  const transformHref = React.useCallback((href: string) => {
    // If the href starts with //, it's an app link, leave it as is.
    if (/^\/\//.test(href)) { return href }

    // If the href has a scheme already, leave it as is.
    if (PROTOCOL_REGEXP.test(href)) { return href }

    const schemeToUse = scheme ?? 'https'
    if (schemeToUse === '') { return href }

    // Otherwise, prepend a default 'https://'.
    return isHTTPish ? `${schemeToUse}://${href}` : `${schemeToUse}:${href}`
  }, [isHTTPish, scheme])

  const handleChange = React.useCallback((href: string) => {
    const newHref = transformHref(href)
    props.onChange?.(newHref)
  }, [props, transformHref])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <TextField
        accessoryLeft={renderAccessoryLeft()}
        mono
        {...rest}
        value={href}
        onChange={handleChange}
      />
    )
  }

  function renderAccessoryLeft() {
    const schemeSelector = renderSchemeSelector()
    if (accessoryLeft != null && schemeSelector != null) {
      return (
        <HBox>
          {accessoryLeft}
          {schemeSelector}
        </HBox>
      )
    } else {
      return accessoryLeft ?? schemeSelector
    }
  }

  function renderSchemeSelector() {
    if (scheme === '') { return null }
    return (
      <PopupMenu items={schemeItems} value={scheme} onValueSelect={setScheme}>
        {toggle => (
          <Tappable classNames={$.schemeSelector} onTap={toggle} showFocus>
            <Label small dim mono>
              {isHTTPish ? `${scheme}://` : `${scheme}:`}
            </Label>
          </Tappable>
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default HrefField

export const PROTOCOL_REGEXP = /^([\d\w+.-]+):(?:\/\/)?/

const useStyles = createUseStyles(theme => ({
  schemeSelector: {
    position: 'relative',

    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    borderRadius: layout.radius.m,
    background:   theme.bg.subtle,

    '&::after': {
      ...layout.overlay,
      content:      '""',
      borderRadius: layout.radius.m,
    },

    '&:hover::after': {
      ...colors.overrideBackground(theme.bg.hover),
    },
    '&:active::after': {
      ...colors.overrideBackground(theme.bg.active),
    },
  },
}))