import { DateTime } from 'luxon'
import { SVGName } from '~/ui/components/SVG'
import { importable } from './importing'
import { Model, resource } from './Model'
import { Module } from './Module'
import { Ref } from './types'

@resource<Metric>('metrics', {
  icon:    metric => metric == null ? 'metric' : `${metric.type}-metric` as SVGName,
  caption: metric => metric.name,
})
export class Metric extends Model {

  public module!: Ref<Module>

  @importable()
  public name!: string

  @importable()
  public type!: MetricType

  @importable()
  public repeatBehavior!: RepeatBehavior

  @importable()
  public steps!: string[]

}

export type MetricType = 'valued' | 'duration' | 'funnel'
export const MetricType: {
  all: () => MetricType[]
} = {
  all: () => ['valued', 'duration', 'funnel'],
}

export type RepeatBehavior = 'overwrite' | 'accumulate'
export const RepeatBehavior: {
  all: () => RepeatBehavior[]
} = {
  all: () => ['overwrite', 'accumulate'],
}


export interface MetricDatum {
  project:     string
  participant: string
  groups:      string[]
  timestamp:   DateTime
  value:       number
}

export interface MetricDataFilters {
  participant?: string
  groups?:      string[]
  before?:      Date
  after?:       Date
}