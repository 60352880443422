import React from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { observer } from '~/ui/component'
import { Center, Empty, Spinner, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { animation, createUseStyles, layout } from '~/ui/styling'
import ChannelList from './ChannelList'
import ChannelView from './ChannelView'
import { useChat } from './ChatContext'
import ChatHeader from './ChatHeader'
import ChannelInfo from './info/ChannelInfo'

export interface Props {
  header?:      boolean
  subheader?:   React.ReactNode
  transparent?: boolean
  forceOpen?:   boolean
  previewing?:  boolean

  noChannel?:    '$list' | {title: string, detail: string}
  emptyChannel?: {title: string, detail: string}
}

const ChatView = observer('ChatView', (props: Props) => {

  const {
    header = true,
    subheader,
    forceOpen,
    previewing = false,
    noChannel = '$list',
    emptyChannel,
  } = props

  const {chat, channel, showChannelInfo} = useChat()
  const disabled = chat?.channels.length === 0

  const channelInfoWasVisible = usePrevious(showChannelInfo)
  const channelViewTransitionClassNames = channelInfoWasVisible || showChannelInfo ? 'transition-reversed' : 'transition'

  const childFactory = React.useCallback((child: React.ReactElement) => {
    return React.cloneElement(child, {
      classNames: channelViewTransitionClassNames,
    })
  }, [channelViewTransitionClassNames])

  const [t] = useTranslation('chat')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={[$.chat, {disabled}]}>
        {header && <ChatHeader/>}
        {subheader}

        {chat?.starting ? (
          renderLoading()
        ) : (
          renderBody()
        )}
      </VBox>
    )
  }

  function renderLoading() {
    return (
      <Center flex>
        <Spinner dim/>
      </Center>
    )
  }

  function renderBody() {
    if (disabled) {
      return (
        <Empty
          flex
          dim
          {...t('disabled')}
        />
      )
    }

    return (
      <TransitionGroup classNames={$.body} childFactory={childFactory}>
        {channel == null && renderListOrEmpty()}
        {channel != null && renderCurrentChannel()}
      </TransitionGroup>
    )
  }

  function renderListOrEmpty() {
    if (noChannel === '$list') {
      return (
        <CSSTransition key='$channel-list' timeout={animation.durations.medium} classNames='transition'>
          <VBox flex classNames={$.channelList}>
            <ChannelList />
          </VBox>
        </CSSTransition>
      )
    } else {
      return (
        <CSSTransition key='$empty' timeout={animation.durations.medium} classNames='transition'>
          <Empty classNames={$.empty} dim {...noChannel}/>
        </CSSTransition>
      )
    }
  }

  function renderCurrentChannel() {
    if (channel == null) { return null }

    if (showChannelInfo) {
      return (
        <CSSTransition key='$channel-info' timeout={animation.durations.medium} classNames='transition'>
          <VBox flex classNames={$.channelInfo}>
            <ChannelInfo />
          </VBox>
        </CSSTransition>
      )
    } else {
      return (
        <CSSTransition key='$channel' timeout={animation.durations.medium} classNames={channelViewTransitionClassNames}>
          <VBox flex classNames={$.channelView}>
            <ChannelView
              key={channel.channel.id}
              controller={channel}
              previewing={previewing}
              forceOpen={forceOpen}
              empty={emptyChannel}
            />
          </VBox>
        </CSSTransition>
      )
    }
  }

  return render()

})

export default ChatView

const useStyles = createUseStyles({
  chat: {
    '&.disabled': {
      opacity: 0.6,
    },
  },

  body: {
    flex:     [1, 0, 0],
    position: 'relative',
    overflow: 'hidden',
  },

  channelInfo: {
    ...layout.overlay,
    '&.transition': {
      ...animation.slideFromRight(animation.durations.medium),
    },
    '&.transition-reversed': {
      ...animation.slideFromRight(animation.durations.medium),
    },
  },
  channelView: {
    ...layout.overlay,
    '&.transition': {
      ...animation.slideFromRight(animation.durations.medium),
    },
    '&.transition-reversed': {
      ...animation.slideFromLeft(animation.durations.medium),
    },
  },
  channelList: {
    ...layout.overlay,
    '&.transition': {
      ...animation.slideFromLeft(animation.durations.medium),
    },
  },
  empty: {
    ...layout.overlay,
  },
})