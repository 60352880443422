import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Group, Participant, Variable } from '~/models'
import { BulkSelector, dataStore, projectStore } from '~/stores'
import { CustomDataList } from '~/ui/app/custom-data'
import ParticipantList from '~/ui/app/participants/list/ParticipantList'
import ParticipantCollectionForm from '~/ui/app/participants/ParticipantCollectionForm'
import { observer } from '~/ui/component'
import { Center, ConfirmBox, Label, ProgressBar, SegmentedButton, VBox } from '~/ui/components'
import { translateFormErrorPaths } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction } from '~/ui/resources/collection'
import {
  ActionButton,
  ResourceDetailScreen,
  SummaryInfo,
  useResourceDetailLocation,
} from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout, useStyling } from '~/ui/styling'
import GroupForm from '../GroupForm'
import GroupConditionsForm from './GroupConditionsForm'

export type Props = RouteComponentProps<ResourceDetailParams>

const GroupScreen = observer('GroupScreen', (props: Props) => {

  const {t, actionCaption, actionConfirm} = useResourceTranslation('groups')
  const {body = 'participants', setBody} = useResourceDetailLocation()

  const groupID   = props.match.params.id
  const projectID = projectStore.projectID

  const group        = dataStore.get(Group, groupID)
  const isOwnProject = group?.project === projectID

  const participantsEndpoint = useModelEndpoint(Participant, {
    filters: {groups: groupID, project: projectID},
    include: ['groups'],
  })

  const [participantsFormOpen, openParticipantsForm, closeParticipantsForm] = useBoolean()
  const [conditionsFormOpen, openConditionsForm, closeConditionsForm] = useBoolean()

  const closeFormsAndReload = React.useCallback(() => {
    closeParticipantsForm()
    closeConditionsForm()
    participantsEndpoint.fetch()
  }, [closeConditionsForm, closeParticipantsForm, participantsEndpoint])

  const saveData = React.useCallback(async (data: Variable[]) => {
    const document = dataStore.document(Group, groupID)
    const result   = await document.update({data})
    return translateFormErrorPaths(result, path => path.replace(/^data./, ''))
  }, [groupID])

  const removeParticipantsFromGroup = React.useCallback(async (_, selector: BulkSelector) => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('participants.remove-from-group'),
    })
    if (!confirmed) { return }

    const document = dataStore.document(Group, groupID)
    const result = await document.callBulkAction('participants:remove', selector)
    if (result.status === 'ok') {
      participantsEndpoint.fetch()
    }
  }, [actionConfirm, groupID, participantsEndpoint])

  const participantBulkActions = React.useMemo((): BulkAction[] => [{
    name:    'remove-from-group',
    icon:    'cross-circle',
    caption: count => actionCaption('participants.remove_from_group', {count}),
    execute: removeParticipantsFromGroup,
  }], [actionCaption, removeParticipantsFromGroup])

  //------
  // Rendering

  const {colors} = useStyling()

  function render() {
    return (
      <ResourceDetailScreen<Group>
        Model={Group}
        id={props.match.params.id}

        renderType={group => t(`type.${group.type}.caption`)}
        renderInfo={renderInfo}

        EditFormComponent={isOwnProject ? GroupForm : undefined}
        renderBody={renderBody}
        renderActions={renderActions}

        allowRemove={isOwnProject}
      />
    )
  }

  function renderInfo(group: Group) {
    return (
      <SummaryInfo>
        {isOwnProject && (
          <SummaryInfo.Item markup>
            {t('created', {when: group.createdAt})}
          </SummaryInfo.Item>
        )}
        {group.capacity != null && (
          <SummaryInfo.Item markup>
            {t('capacity', {count: group.capacity})}
          </SummaryInfo.Item>
        )}
        {group.capacity == null ? (
          <SummaryInfo.Item markup>
            {t('participant_count', {count: group.participantCount})}
          </SummaryInfo.Item>
        ) : (
          <SummaryInfo.Item markup>
            <VBox gap={layout.padding.xs}>
              <Label small dim markup>
                {t('participant_count_with_capacity', {count: group.participantCount, capacity: group.capacity})}
              </Label>
              <ProgressBar
                progress={group.participantCount / group.capacity}
                barColor={group.participantCount > group.capacity ? colors.semantic.negative : undefined}
              />
            </VBox>
          </SummaryInfo.Item>
        )}
      </SummaryInfo>
    )
  }

  function renderBody(group: Group) {
    return (
      <VBox flex gap={layout.padding.m}>
        {isOwnProject && renderBodyToggleButton()}
        {body === 'participants' && renderParticipantList(group)}
        {body === 'data' && renderData(group)}

        {group.type === 'list' && (
          <ParticipantCollectionForm
            open={participantsFormOpen}
            requestClose={closeFormsAndReload}

            ParentModel={Group}
            parentID={props.match.params.id}
            filterAttribute='groups'
          />
        )}
        {group.type === 'segment' && (
          <GroupConditionsForm
            open={conditionsFormOpen}
            requestClose={closeFormsAndReload}
            group={group as Group & {type: 'segment'}}
          />
        )}
      </VBox>
    )
  }

  function renderBodyToggleButton() {
    return (
      <Center>
        <SegmentedButton
          segments={[{
            value:   'participants',
            caption: t('participants.caption'),
          }, {
            value:   'data',
            caption: t('data.caption'),
          }]}
          selectedValue={body}
          onChange={setBody}
        />
      </Center>
    )
  }

  function renderParticipantList(group: Group) {
    return (
      <ParticipantList
        endpoint={participantsEndpoint}
        useLocation={true}
        parentModel={group}
        bulkActions={participantBulkActions}
      />
    )
  }

  function renderData(group: Group) {
    if (group.data == null) { return null }

    return (
      <CustomDataList
        value={group.data}
        requestSave={saveData}
        scrollable
        flex
      />
    )
  }


  function renderActions(group: Group) {
    return (
      <VBox align='left' gap={layout.padding.s}>
        {group.type === 'list' && renderEditParticipantsAction()}
        {group.type === 'segment' && renderEditConditionsAction()}
      </VBox>
    )
  }

  function renderEditParticipantsAction() {
    return (
      <ActionButton
        icon='participant'
        name='edit-participants'
        onTap={openParticipantsForm}
      />
    )
  }

  function renderEditConditionsAction() {
    return (
      <ActionButton
        icon='code'
        name='edit-conditions'
        onTap={openConditionsForm}
      />
    )
  }

  return render()

})

export default GroupScreen