import React from 'react'
import { Location } from '~/models'
import { GoogleMap, GoogleMapManager } from '~/ui/app/google-maps'
import { observer } from '~/ui/component'
import { HBox, Label, SelectField, Toolbar, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { useModelEndpoint, useTags } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField, ResourceFieldProps } from '~/ui/resources/components'
import { createUseStyles, layout, shadows } from '~/ui/styling'
import LocationMapPin from './LocationMapPin'

export interface Props extends Omit<ResourceFieldProps<Location>, 'Model'> {
  type?:    Location['type']
  showMap?: boolean
}

const LocationField = observer('LocationField', (props: Props) => {

  const {
    type,
    filters: props_filters,
    showMap,
    ...rest
  } = props

  const {t} = useResourceTranslation('locations')

  const [tag, setTag] = React.useState<string | null>(null)

  const filters = React.useMemo(() => {
    const filters: Record<string, string | null> = {}
    if (type != null) {
      filters.type = type
    }
    if (tag != null) {
      filters.tags = tag
    }
    if (props_filters != null) {
      Object.assign(filters, props_filters)
    }
    return filters
  }, [props_filters, tag, type])

  const endpoint  = useModelEndpoint(Location, {filters})
  const locations = endpoint.data ?? []

  const {value, onChange} = props
  const selectedLocationID = value as string | null

  const mapManagerRef = React.useRef<GoogleMapManager>(null)

  const fit = React.useCallback(() => {
    mapManagerRef.current?.fitFeatures({
      animated: true,
    })
  }, [])

  const select = React.useCallback((location: Location) => {
    // TODO: Multi mode with toggles.
    onChange?.(location.id as any)
    mapManagerRef.current?.setViewport({
      center: location.coordinate,
    }, {
      animated: true,
    })
  }, [onChange])

  //------
  // Tags

  const tags = useTags(Location)

  const tagChoices = React.useMemo((): Choice[] => [
    {value: null, caption: t('tags.show_all')},
    ...(tags ?? []).map(it => ({value: it, caption: it})),
  ], [t, tags])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {showMap && renderMap()}
        {renderField()}
      </VBox>
    )
  }

  function renderMap() {
    return (
      <VBox classNames={$.mapContainer}>
        {renderToolbar()}
        <GoogleMap classNames={$.map} managerRef={mapManagerRef}>
          {locations.map(loc => (
            <LocationMapPin
              key={loc.id}
              location={loc}
              count={loc.participantCount}
              tint={loc.id === selectedLocationID ? 'primary' : 'secondary'}
              onTap={select}
            />
          ))}
        </GoogleMap>
      </VBox>
    )
  }

  function renderToolbar() {
    return (
      <Toolbar>
        <Toolbar.Button
          icon='zoom-fit'
          caption={t('map.fit')}
          onTap={fit}
        />
        <Toolbar.Spacer/>
        {(tags ?? []).length > 0 && (
          <HBox gap={layout.padding.s}>
            <Label caption small>
              {t('tags.choose')}
            </Label>
            <SelectField
              value={tag}
              onChange={setTag}
              choices={tagChoices}
              tiny
            />
          </HBox>
        )}
      </Toolbar>
    )
  }

  function renderField() {
    return (
      <ResourceField
        Model={Location}
        filters={filters}
        {...rest as any}
      />
    )
  }

  return render()

})

export default LocationField

const useStyles = createUseStyles({
  mapContainer: {
    borderRadius: layout.radius.s,
    overflow:     'hidden',
    boxShadow:    shadows.depth(1),
  },

  map: {
    height: 260,
  },
})