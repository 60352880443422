import React from 'react'
import { observer } from '~/ui/component'
import { HBox, NumberField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import BrandingColorField from '../../assets/BrandingColorField'
import ComponentBrandingFields from '../ComponentBrandingFields'
import { registerComponentBrandingFields } from '../registry'

export interface Props {}

const AppTabBarBrandingFields = observer('AppTabBarBrandingFields', (props: Props) => {

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.l}>
        <ComponentBrandingFields/>

        <FormField name='margin'>
          {bind => <NumberField {...bind} minimum={0} maximum={64} step={1}/>}
        </FormField>

        <FormField name='padding'>
          {bind => <NumberField {...bind} minimum={0} maximum={64} step={1}/>}
        </FormField>

        <FormField name='iconSize'>
          {bind => <NumberField {...bind} minimum={12} maximum={48} step={4}/>}
        </FormField>

        <HBox gap={layout.padding.s} align='top'>
          <VBox flex>
            <FormField name='iconColor'>
              {bind => <BrandingColorField {...bind}/>}
            </FormField>
          </VBox>
          <VBox flex>
            <FormField name='activeIconColor'>
              {bind => <BrandingColorField {...bind}/>}
            </FormField>
          </VBox>
        </HBox>
      </VBox>
    )
  }

  return render()

})

registerComponentBrandingFields('app.tabBar', AppTabBarBrandingFields)
export default AppTabBarBrandingFields