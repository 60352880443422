import React from 'react'
import { WidgetFilterValueSet } from '~/stores'
import { memo } from '~/ui/component'

type WidgetFilterValuesContext = WidgetFilterValueSet

const WidgetFilterValuesContext = React.createContext<WidgetFilterValuesContext>(
  new WidgetFilterValueSet('', {}, () => null),
)

export interface WidgetFilterValuesProviderProps {
  values:    WidgetFilterValueSet
  children?: React.ReactNode
}

export const WidgetFilterValuesProvider = memo('WidgetFilterValuesProvider', (props: WidgetFilterValuesProviderProps) => {
  return (
    <WidgetFilterValuesContext.Provider value={props.values}>
      {props.children}
    </WidgetFilterValuesContext.Provider>
  )
})

export function useWidgetFilterValues() {
  return React.useContext(WidgetFilterValuesContext)
}