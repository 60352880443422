import React from 'react'
import { AccessType, Group } from '~/models'
import { observer } from '~/ui/component'
import { Label, Panel, SwitchField, TextBlock, VBox } from '~/ui/components'
import SelectField, { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import AccessFormModel from './AccessFormModel'

export interface Props extends FormDialogProps<AccessFormModel> {
  action:        string
  instructions?: string
}

const AccessForm = observer('AccessForm', (props: Props) => {

  const {singular} = useResourceTranslation()
  const {t} = useResourceTranslation('access')

  const {
    open,
    model,
    title = t('form.title'),
    icon = 'unlock',
    action,
    instructions,
    ...rest
  } = props

  const typeChoices = React.useMemo((): Choice<AccessType>[] => [{
      value:   'global',
      caption: t('type.global'),
    }, {
      value:  'limited',
      caption: t('type.limited'),
    },
  ], [t])

  const type = singular()

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='access'>
        <FormDialog semi={false} model={model} open={open} title={title} icon={icon} {...rest}>
          {renderInstructions()}
          {renderType()}
          {model.type === 'global' ? renderGlobal() : renderLimited()}
        </FormDialog>
      </ResourceTypeProvider>
    )
  }

  function renderInstructions() {
    if (instructions == null) { return null }

    return (
      <Panel padding={layout.padding.m}>
        <TextBlock small dim>
          {t(instructions)}
        </TextBlock>
      </Panel>
    )
  }

  function renderType() {
    return (
      <FormField name='type'>
        {bind => <SelectField choices={typeChoices} {...bind}/>}
      </FormField>
    )
  }

  function renderGlobal() {
    if (model.type !== 'global') { return null }

    return(
      <FormField name='value'>
        {bind => (
          <SwitchField
            {...bind}
            label={{
              on:  t('summary.may', {action, type}),
              off: t('summary.may_not', {action, type}),
            }}
          />
        )}
      </FormField>
    )
  }

  function renderLimited() {
    return (
      <VBox gap={layout.padding.inline.m}>
        <FormField name='group' required>
          {bind => <ResourceField {...bind} Model={Group}/>}
        </FormField>
        <Label dim small>{t('summary.limited', {action, type})}</Label>
      </VBox>
    )
  }

  return render()

})

export default AccessForm