import { Model, resource } from './Model'

import type { Relationship } from '~/stores'

@resource<SystemNotification>('system-notifications', {
  icon:    () => 'flash',
  caption: log => log.type,
})
export class SystemNotification extends Model {

  public type!:          string
  public priority!:      NotificationPriority
  public title!:         string
  public message!:       string
  public info!:          NotificationInfo
  public results!:       NotificationResult[]

  public relationships: Record<string, Relationship> = {}

}

export type NotificationPriority      = 'normal' | 'high'
export type NotificationInfo          = Record<string, any>

export interface NotificationResult {
  type:    string
  caption: string
  count:   number
}