import React from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserNotificationManager } from 'react-notifications'
import Toast from 'react-toast'
import { memo } from '~/ui/component'
import {
  HBox,
  InfoIcon,
  ModalDialog,
  Panel,
  PanelHeader,
  SwitchField,
  TextBlock,
  VBox,
} from '~/ui/components'
import { useViewState } from '~/ui/hooks'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
}

const NotificationsSettingsDialog = memo('NotificationsSettingsDialog', (props: Props) => {

  const {open, requestClose} = props

  const [t] = useTranslation('notifications')

  //------
  // Browser notifications

  const [browserNotifications, setBrowserNotifications] = useViewState('notifications.browser_notifications', false)

  const toggleBrowserNotifications = React.useCallback((enable: boolean) => {
    if (!enable) {
      setBrowserNotifications(false)
    } else {
      setBrowserNotifications(true)
      BrowserNotificationManager.ensurePermission().then(permission => {
        if (permission !== 'granted') {
          setBrowserNotifications(false)

          Toast.show({
            type:   'error',
            title:  t('settings.browser_notifications.permission_denied.title'),
            detail: t('settings.browser_notifications.permission_denied.detail'),
          })
        }
      })
    }
  }, [setBrowserNotifications, t])

  React.useEffect(() => {
    if (!browserNotifications) { return }

    if (BrowserNotificationManager.permission === 'denied') {
      setBrowserNotifications(false)
    }
  }, [browserNotifications, setBrowserNotifications])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}

        icon='satellite'
        title={t('settings.title')}
        children={renderForm()}
        headerRight='$close'
        closeOnClickOutside={true}

        width={640}
        semi={false}
      />
    )
  }

  function renderForm() {
    return (
      <VBox padding={layout.padding.m} gap={layout.padding.m}>
        <TextBlock small dim>
          {t('settings.preamble')}
        </TextBlock>

        {renderBrowserNotificationsSettings()}
      </VBox>
    )
  }

  function renderBrowserNotificationsSettings() {
    return (
      <Panel padding={layout.padding.s} header={renderBrowserNotificationsHeader()}>
        <HBox gap={layout.padding.s}>
          <SwitchField
            value={browserNotifications}
            onChange={toggleBrowserNotifications}
            label={t('settings.browser_notifications.label')}
          />
        </HBox>
      </Panel>
    )
  }

  function renderBrowserNotificationsHeader() {
    return (
      <PanelHeader
        caption={t('settings.browser_notifications.caption')}
        right={<InfoIcon renderTooltip={t('settings.browser_notifications.instruction')}/>}
      />
    )
  }

  return render()

})

export default NotificationsSettingsDialog