import { makeObservable, observable } from 'mobx'
import { ClientTab, ClientTabIconType, CustomImage, Language, Link, Project } from '~/models'
import { dataStore } from '~/stores'
import { SVGName } from '~/ui/components/SVG'
import { FormModel, SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ClientTabFormModel implements FormModel<ClientTab> {

  constructor(
    private project: Project | null,
    private index:   number | '$new',
    private tab:     ClientTab | null,
  ) {
    this.caption  = this.tab?.caption
    this.iconType = this.tab?.icon.type ?? 'named'
    this.icon     = this.tab?.icon.value ?? 'link'
    this.link     = this.tab?.link ?? Link.empty()

    makeObservable(this)
  }

  @observable
  public caption?: Record<Language, string>

  @observable
  public iconType: ClientTabIconType

  @observable
  public icon: SVGName | CustomImage | undefined

  @observable
  public link: Link

  public async submit(): Promise<SubmitResult | undefined> {
    const clientTabs = this.deriveItems()
    const result = this.project == null
      ? await dataStore.create(Project, {clientTabs})
      : await dataStore.update(Project, this.project.id, {clientTabs})

    return translateFormErrorPaths(result, path => path.replace(/^items\.\d+\./, ''))
  }

  private deriveItems() {
    if (this.index === '$new') {
      return [
        ...this.project?.clientTabs ?? [],
        this.buildItem(),
      ]
    } else {
      return (this.project?.clientTabs ?? []).map((tab, index) => {
        if (index === this.index) {
          return {...tab, ...this.buildItem()}
        } else {
          return tab
        }
      })
    }
  }

  private buildItem() {
    return {
      caption: this.caption,
      icon: {
        type:  this.iconType,
        value: this.icon,
      },
      link: this.link,
    }
  }
}
