import React from 'react'
import { Challenge, Competition } from '~/models'
import { memo } from '~/ui/component'
import { Chip, HBox, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import ChallengeScoringForm from './ChallengeScoringForm'

export interface Props {
  challenge: Challenge
}

const ChallengeScoring = memo('ChallengeScoring', (props: Props) => {

  const {challenge} = props
  const {t, fieldCaption} = useResourceTranslation('challenges')

  const [formOpen, openForm, closeForm] = useBoolean()

  //------
  // Rendering

  function render() {
    if (challenge.competitions == null) { return null }

    return (
      <SummaryInfo.Item
        icon='trophy'
        summary={renderSummary()}
        body={renderBody()}
        bold={challenge.competitions.length > 0}
        requestEdit={openForm}
      >
        {renderForm()}
      </SummaryInfo.Item>
    )
  }

  function renderSummary() {
    if (challenge.competitions == null) { return null }

    if (challenge.competitions.length === 0) {
      return t('fields.competitions.empty')
    } else {
      return t('fields.competitions.notempty')
    }
  }

  function renderBody() {
    if (challenge.competitions == null) { return null }
    if (challenge.competitions.length === 0) { return null }

    return (
      <VBox align='left' gap={layout.padding.inline.m}>
        <ResourceChipRow
          Model={Competition}
          ids={challenge.competitions}
          wrap
        />
        {renderPassingScore()}
      </VBox>
    )
  }

  function renderPassingScore() {
    if (challenge.passingScore == null) { return null }

    return (
      <VBox gap={layout.padding.inline.s}>
        <FormFieldHeader
          caption={fieldCaption('passingScore')}
        />
        <HBox>
          <Chip flex='shrink'>
            {`${challenge.passingScore} / ${challenge.maximumScore}`}
          </Chip>
        </HBox>
      </VBox>
    )
  }

  function renderForm() {
    return (
      <ChallengeScoringForm
        open={formOpen}
        requestClose={closeForm}
        challenge={challenge}
      />
    )
  }

  return render()

})

export default ChallengeScoring