import { fonts } from '~/ui/styling'
import * as layout from './layout'
import * as shadows from './shadows'
import { Theme } from './Theme'
import { jssPadding } from './util'

export const clearInput = (theme: Theme) => ({
  display:  'block',
  width:    '100%',
  border:   'none',
  font:     'inherit',

  background: 'none',
  '-webkit-appearance': 'unset',

  '&:focus': {
    outline: 'none',
  },

  color: 'inherit',
  '&::placeholder': {
    color: theme.fg.placeholder,
  },
})

export const fieldPadding          = jssPadding(layout.padding.inline.xs, layout.padding.inline.m)
export const fieldPaddingMultiline = jssPadding(layout.padding.inline.m, layout.padding.inline.m)
export const fieldBorderRadius  = layout.radius.m
export const fieldHeight = {
  normal: 40,
  small:  32,
  tiny:   24,
}

export const fieldFocus = (theme: Theme) => ({
  outline:     'none',
  borderColor: 'transparent',

  boxShadow: shadows.focus.bold(theme),
})

export const readOnlyFocus = (theme: Theme) => ({
  outline:     'none',
  borderColor: 'transparent',
  boxShadow:   shadows.focus.readonly(theme),
})

export const field = (theme: Theme) => ({
  position: 'relative',

  ...fonts.responsiveFontStyle(theme.guide.fonts.input),

  minHeight: fieldHeight.normal,
  '&.small': {
    minHeight: fieldHeight.small,
  },

  padding: fieldPadding,

  '&.light, &.dark': {
    color: theme.fg.normal,
  },

  '&:not(.light):not(.dark)': {
    background: theme.colors.bg.light.alt,
    color:      theme.colors.fg.dark.normal,
    '&::placeholder, & ::placeholder': {
      color: theme.colors.fg.dark.placeholder,
    },
    '&.invalid': {
      background: theme.colors.fg.light.error.mix(theme.colors.bg.light.alt, 0.9),
    },
    '&::after': {
      ...layout.overlay,
      borderRadius:  fieldBorderRadius,
      content:       '""',
      pointerEvents: 'none',
    },
    '&:not(.readOnly)::after': {
      boxShadow: ['inset', 1, 1, 2, shadows.shadowColor.alpha(0.2)],
    },
    '&.readOnly::after': {
      ...layout.overlay,
      boxShadow: ['inset', 0, 0, 0, 1, theme.colors.fg.dark.placeholder],
    },
  },

  '&.disabled': {
    opacity:   0.6,
  },

  '&, & input': {
    borderRadius: fieldBorderRadius,
  },
  '&:-webkit-autofill, & input:-webkit-autofill': {
    borderRadius: fieldBorderRadius,
  },

  ...theme.isDark ? {
    '&.light': {
      border:     'none',
      background: theme.colors.bg.light.alt.alpha(0.1),
    },

    '&.dark': {
      border:     'none',
      background: theme.colors.bg.dark.alt.alpha(0.2),
    },
  } : {
    '&.light': {
      border:     'none',
      background: theme.colors.bg.light.alt.alpha(0.8),
    },

    '&.dark': {
      border:     'none',
      background: theme.colors.bg.dark.alt.alpha(0.05),
    },
  },

  '&:not(.disabled):focus, &:not(.disabled):focus-within': {
    ...fieldFocus(theme),
  },
  '&.readOnly:focus, &.readOnly:focus-within, &.disabled:focus, &.disabled:focus-within': {
    ...readOnlyFocus(theme),
  },
})

export const invalidField = (theme: Theme) => ({
  outline:     'none',
  borderColor: 'transparent',
  boxShadow:   [0, 0, 0, 2, theme.semantic.negative],
})

export const unstyledField = (theme: Theme) => ({
  '&:not(.light):not(.dark)': {
    background: 'transparent',
    '&::after': {
      borderRadius:  0,
    },
    '&:not(.readOnly)::after': {
      boxShadow: 'none',
    },
    '&.readOnly::after': {
      boxShadow: 'none',
    },
  },

  '&, & input': {
    borderRadius: 0,
  },
  '&:-webkit-autofill, & input:-webkit-autofill': {
    borderRadius: 0,
  },
})

export const link = (theme: Theme) => ({
  color:          theme.semantic.primary,
  textDecoration: 'underline',
})

export const overlayBefore = (styles: AnyObject = {}) => ({
  '&::before': {
    content: '""',
    ...layout.overlay,
    pointerEvents: 'none',
    ...styles,
  },
})

export const overlayAfter = (styles: AnyObject = {}) => ({
  '&::after': {
    content: '""',
    ...layout.overlay,
    pointerEvents: 'none',
    ...styles,
  },
})