import { uniq } from 'lodash'
import { ImportFieldGroup } from 'sheet-importer'
import { flatMap } from 'ytil'
import { Group, ImportFieldMeta, Participant } from '~/models'
import { ResourceTranslation } from '~/ui/resources'
import { ResourceImportModel } from '~/ui/resources/import'
import GroupsField from './GroupsField'

export default class ParticipantImportModel extends ResourceImportModel<Participant> {

  constructor(
    private readonly groupModuleID: string,
    private readonly groups: Group[],
    i18n: ResourceTranslation,
  ) {
    super(Participant, {}, i18n)
  }

  protected fieldGroupFor(name: string, meta: ImportFieldMeta): ImportFieldGroup {
    if (name === 'groups') {
      const tags = uniq(flatMap(this.groups, it => it.tags))
      return GroupsField.fieldGroup('groups', tags, {
        defaults: {
          type:   'list',
          module: this.groupModuleID,
        },
      })
    } else {
      return super.fieldGroupFor(name, meta)
    }
  }

}