import React from 'react'
import { useTranslation } from 'react-i18next'
import I18next from 'i18next'
import { makeObservable, observable } from 'mobx'
import { GroupSignup } from '~/models'
import { GroupField } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import {
  FormDialog,
  FormDialogProps,
  FormError,
  FormField,
  FormModel,
  SubmitResult,
} from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'

export type Props = FormDialogProps<SignupWidgetFormModel>

const SignupWidgetForm = memo('SignupWidgetForm', (props: Props) => {

  const [t] = useTranslation('widgets')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog {...props} icon='group' title={t('signup.title')} semi={false}>
        <FormField name='group' {...t('signup.fields.group')} caption={t('signup.fields.group.prompt')} required>
          {bind => <GroupField {...bind}/>}
        </FormField>
        <FormField name='signup' {...t('signup.fields.signup')} caption={t('signup.fields.signup.prompt')} required>
          {bind => <ResourceField Model={GroupSignup} {...bind}/>}
        </FormField>
      </FormDialog>
    )
  }

  return render()

})

export default SignupWidgetForm

export class SignupWidgetFormModel implements FormModel {

  constructor(
    private readonly params: Record<string, any>,
    private readonly setParams: (params: Record<string, any>) => any,
  ) {
    makeObservable(this)
  }

  @observable
  public group: string | null = this.params.group ?? null

  @observable
  public signup: string | null = this.params.signup ?? null

  public async submit(): Promise<SubmitResult | undefined> {
    const result = this.validate()
    if (result.status !== 'ok') { return result }

    this.setParams({
      group:  this.group,
      signup: this.signup,
    })
    return {status: 'ok'}
  }

  private validate(): SubmitResult {
    const errors: FormError[] = []

    if (this.group == null) {
      errors.push({field: 'group', message: I18next.t('validation:required')})
    }
    if (this.signup == null) {
      errors.push({field: 'signup', message: I18next.t('validation:required')})
    }

    return errors.length === 0 ? {status: 'ok'} : {status: 'invalid', errors}
  }

}