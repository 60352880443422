import { EndpointOptions } from 'mobx-document'
import { SocketOperation } from 'socket.io-react'
import { Model, ModelClass } from '~/models'
import ModelDocument from './ModelDocument'

export type ModelEndpointOptions<M extends Model> = EndpointOptions<ModelDocument<M>, ListMeta> & FetchOptions & {
  initialIDs?: string[]
}

export interface FetchOptions {
  detail?:  boolean
  include?: string[]
}

export interface ActionOptions {
  timeout?: number | false
  detail?:  boolean
  include?: string[]
  params?:  Record<string, any>
}

export interface DuplicateOptions extends LongRunningActionOptions {
  overrides?: Record<string, any>
}

export interface LongRunningActionOptions extends ActionOptions {
  onStart?: (operation: SocketOperation) => any
  onEnd?:   (operation: SocketOperation) => any
}

export interface ImportOptions {
  timeout?: number | false
  onStart?: (operation: SocketOperation) => any
  onEnd?:   (operation: SocketOperation) => any
}


export interface ShowParams extends FetchOptions {
}

export interface ListParams extends FetchOptions {
  page?:       number
  label?:      string
  filters?:    Record<string, any>
  sorts?:      Sort[]
  search?:     string | null
  pagination?: PaginationParams
}

export interface PaginationParams {
  limit?: number
}
export interface ListMeta {
  count:       number
  isFirst:     boolean
  isLast:      boolean
  nextOffset:  number | null
  offset:      number
  total:       number | null
  searchTotal: number

  [key: string]: any
}

export interface Sort {
  field:     string
  direction: -1 | 1
}

export type AnyPack = CustomListPack<any> | CustomPack<any> | ListPack<any> | Pack<any>

export interface CustomListPack<T, M = any> {
  data:      T[] | null
  included?: ResourceOf<Model>[]
  meta?:     M
}

export interface CustomPack<T, M = any> {
  data:      T | null
  included?: ResourceOf<Model>[]
  meta?:     M
}

export interface ListPack<M extends Model, Meta extends ListMeta = ListMeta> {
  data:      ResourceOf<M>[]
  included?: ResourceOf<Model>[]
  meta?:     Meta
}

export interface Pack<M extends Model, Meta extends {} = any> {
  data:      ResourceOf<M> | null
  included?: ResourceOf<Model>[]
  meta?:     Meta
}

export interface UpdatePack<T> {
  data:  DeepPartial<T> | null
  meta?: any
}

export interface ResourceOf<M extends Model> {
  type:          ModelClass<M>['resourceType']
  id:            M['id']
  attributes:    Omit<M, 'id'>
  relationships: Record<string, Relationship>
  meta:          Record<string, any>
}

export interface Relationship {
  data: Linkage<any> | Linkage<any>[]
}

export interface Linkage<T extends string, ID = string> {
  type: T
  id:   ID
}

export interface BulkSelector {
  data: Linkage<any>[] | null
  meta: {
    filters?: AnyObject
    label?:   string | null
    search?:  string | null
    [key: string]: any
  }
}

export const BulkSelector: {
  all:      () => BulkSelector
  list:     (type: string, ids: string[]) => BulkSelector
  filtered: (filters?: AnyObject, label?: string | null, search?: string | null) => BulkSelector
} = {
  all:      ()                       => ({data: null, meta: {}}),
  list:     (type, ids)              => ({data: ids.map(id => ({type, id})), meta: {}}),
  filtered: (filters, label, search) => ({data: null, meta: {filters, label, search}}),
}

export type CopyResolution =
  | {type: 'copy'}
  | {type: 'reassign', id: string}

export interface CopyResult {
  data: Linkage<any>
  meta: {
    originalID: string
    caption:    string
    issues:     CopyIssue[]
  }
}

export interface CopyIssue {
  path:     string | null
  code?:    string
  message?: string
}