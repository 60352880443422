import React from 'react'
import { useLayout } from 'react-measure'
import i18n from 'i18next'
import { isFunction, some } from 'lodash'
import { HBox, Scroller, VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props<T> {
  data:        T[]
  scrollable?: boolean
  children?:   React.ReactNode

  keyForPoint?: (point: T) => string | number

  flex?:             VBoxProps['flex']
  columnGap?:        VBoxProps['gap']

  classNames?:       React.ClassNamesProp
  headerClassNames?: React.ClassNamesProp
  bodyClassNames?:   React.ClassNamesProp
}

export interface ColumnProps<T> extends Omit<VBoxProps, 'children'> {
  name?:       string
  header?:     React.ReactNode | null
  children?:   React.ReactNode | ((item: T, index: number) => React.ReactNode)

  nowrap?: boolean

  headerClassNames?: React.ClassNamesProp
  classNames?:       React.ClassNamesProp
}

const DataTable = <T extends {}>(props: Props<T>) => {

  const {
    data,
    scrollable,
    classNames,
    keyForPoint,
    headerClassNames,
    bodyClassNames,
    flex,
    columnGap = layout.padding.inline.m,
  } = props

  const containerRef = React.useRef<HTMLDivElement>(null)

  const columns = React.useMemo((): Array<ColumnProps<T>> => {
    return React.Children
      .toArray(props.children)
      .filter(isColumnElement)
      .map(child => child.props)
  }, [props.children])

  const hasHeaders = some(columns, col => col.header != null)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} classNames={classNames} ref={containerRef} gap={layout.padding.inline.m}>
        {renderHeader()}
        {renderBody()}
      </VBox>
    )
  }

  function renderHeader() {
    if (!hasHeaders) { return null }

    return (
      <HBox align='stretch' classNames={$.header} gap={columnGap}>
        {columns.map(renderHeaderCell)}
      </HBox>
    )
  }

  function renderHeaderCell(column: ColumnProps<T>, index: number) {
    const header = column.header
      ? column.header
      : column.name
      ? i18n.t(`fields.${column.name}`)
      : null

    const classNames = [
      headerClassNames,
      cellClassNames(column, true),
    ]

    return (
      <HBox key={index} classNames={classNames} flex={column.flex}>
        {header}
      </HBox>
    )
  }

  function renderBody() {
    const body = (
      <VBox
        gap={layout.padding.inline.s}
        children={data.map((item, index) => renderRow(item, index))}
        classNames={bodyClassNames}
      />
    )

    if (scrollable) {
      return (
        <Scroller flex={flex}>
          {body}
        </Scroller>
      )
    } else {
      return body
    }

  }

  function renderRow(item: T, index: number) {
    const key = keyForPoint?.(item) ?? index
    return (
      <HBox key={key} gap={columnGap}>
        {columns.map((column, columnIndex) => renderBodyCell(item, column, index, columnIndex))}
      </HBox>
    )
  }

  function renderBodyCell(item: T, column: ColumnProps<T>, rowIndex: number, columnIndex: number) {
    return (
      <HBox
        key={columnIndex}
        classNames={cellClassNames(column, false)}
        flex={column.flex}
        children={renderBodyCellContent(item, column, rowIndex)}
      />
    )
  }

  function renderBodyCellContent(item: T, column: ColumnProps<T>, index: number) {
    if (column.children == null && column.name != null) {
      return (item as any)[column.name]
    } else if (isFunction(column.children)) {
      return column.children(item, index)
    } else {
      return column.children
    }
  }

  const cellClassNames = React.useCallback((column: ColumnProps<T>, header: boolean) => {
    return [
      $.cell,
      column.align,
      {nowrap: column.nowrap},
      header ? column.headerClassNames : column.classNames,
    ]
  }, [$.cell])

  //------
  // VBox alignment

  const alignColumn = React.useCallback((index: number) => {
    const container = containerRef.current
    if (container == null) { return }

    const selector = `.${$.cell}:nth-child(${index+1})`
    const cells = Array.from(container.querySelectorAll(selector)) as HTMLElement[]

    for (const cell of cells) {
      cell.style.width = ''
    }
    const maxWidth = Math.max(...cells.map(cell => cell.offsetWidth))
    for (const cell of cells) {
      cell.style.width = `${Math.ceil(maxWidth + 1)}px`
    }
  }, [$.cell])

  const alignColumns = React.useCallback(() => {
    // For all columns that don't flex, make sure all their take the size of the largest cell.
    for (const [i, column] of columns.entries()) {
      if (column.flex != null) { continue }
      alignColumn(i)
    }
  }, [alignColumn, columns])

  useLayout(containerRef, alignColumns)

  return render()

}

function Column<T extends {} = any>(props: ColumnProps<T>) {
  return null
}

Object.assign(DataTable, {Column})
export default DataTable as typeof DataTable & {Column: typeof Column}

function isColumnElement<T>(element: React.ReactNode): element is React.ReactElement<ColumnProps<T>> {
  if (!React.isValidElement(element)) { return false }
  return (element as React.ReactElement<any>).type === Column
}

const useStyles = createUseStyles(theme => ({
  header: {
    color: theme.fg.dim,
    borderBottom: [1, theme.fg.dimmer, 'solid'],
    paddingBottom: layout.padding.inline.s,
  },

  // Must exist for column alignment to work.
  cell: {
    '&.left': {
      justifyContent: 'flex-start',
      textAlign: 'left',
    },

    '&.center': {
      justifyContent: 'center',
      textAlign: 'center',
    },

    '&.right': {
      justifyContent: 'flex-end',
      textAlign: 'right',
    },
  },

}))