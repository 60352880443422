import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import {
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  useQueryParams,
  withDefault,
} from 'use-query-params'
import { Sort } from '~/stores'
import { useViewState } from '~/ui/hooks'
import { ResourceListParams, resourceListPath } from '~/ui/resources/routes'
import { useResourceType } from '../ResourceTypeContext'
import { parseFilter, stringifyFilter } from './filters'

export function useResourceListLocation(type?: string, sync: boolean = false): ResourceListLocation {
  const contextType  = useResourceType()
  const resourceType = type ?? contextType

  const [, setQueryParams] = useQueryParams()

  const [searchParam, setSearchParam] = useQueryParam('search', StringParam)
  const [pageParam, setPageParam]     = useQueryParam('page', NumberParam)
  const [sortParam, setSortParam]     = useQueryParam('sort', StringParam)
  const [filterParam, setFilterParam] = useQueryParam('filter', StringParam)

  const [searchViewState, setSearchViewState] = useViewState<string | null>(`resource.${resourceType}.search`, null)
  const [pageViewState, setPageViewState]     = useViewState<number | null>(`resource.${resourceType}.page`, null)
  const [sortViewState, setSortViewState]     = useViewState<string | null>(`resource.${resourceType}.sort`, null)
  const [filterViewState, setFilterViewState] = useViewState<string | null>(`resource.${resourceType}.filter`, null)

  const [back] = useQueryParam('back', withDefault(BooleanParam, false))

  const syncToViewState = React.useCallback(() => {
    setSearchViewState(searchParam ?? null)
    setPageViewState(pageParam ?? 1)
    setSortViewState(sortParam ?? null)
    setFilterViewState(filterParam ?? null)
  }, [filterParam, pageParam, searchParam, setFilterViewState, setPageViewState, setSearchViewState, setSortViewState, sortParam])

  const syncFromViewState = React.useCallback(() => {
    setQueryParams({
      search: searchViewState,
      page:   pageViewState,
      sort:   sortViewState,
      filter: filterViewState,
      back:   null,
    })
  }, [filterViewState, pageViewState, searchViewState, setQueryParams, sortViewState])

  React.useEffect(() => {
    if (sync && back) {
      syncFromViewState()
    }
  }, [back, sync, syncFromViewState])

  React.useEffect(() => {
    if (sync) {
      syncToViewState()
    }
  }, [sync, syncToViewState])



  const match   = useRouteMatch<ResourceListParams>()
  const history = useHistory()

  const label = match.params.label
  const setLabel = React.useCallback((label: string | null) => {
    history.push(resourceListPath(match.params.type, label ?? undefined))
  }, [history, match.params.type])

  const search = React.useMemo(() => {
    return searchParam
  }, [searchParam])

  const setSearch = React.useCallback((search: string | null) => {
    setSearchParam(search)
    setSearchViewState(search)
  }, [setSearchParam, setSearchViewState])

  const page = React.useMemo(() => {
    return pageParam
  }, [pageParam])

  const setPage = React.useCallback((page: number) => {
    setPageParam(page)
    setPageViewState(page)
  }, [setPageParam, setPageViewState])

  const filters = React.useMemo(() => {
    const filters: Record<string, any> = {}
    for (const pair of (filterParam ?? '').split(';')) {
      const [key, value] = pair.split('=')
      if (key === '') { continue }

      if (value == null) {
        filters[key] = true
      } else {
        filters[key] = parseFilter(value)
      }
    }
    return filters
  }, [filterParam])

  const setFilters = React.useCallback((update: Record<string, any>) => {
    const nextFilters = {...filters, ...update}
    const pairs: string[] = []
    for (const [name, value] of Object.entries(nextFilters)) {
      if (value == null) { continue }

      const stringified = stringifyFilter(value)
      if (stringified == null) { continue }

      if (value === true) {
        pairs.push(name)
      } else {
        pairs.push(`${name}=${stringified}`)
      }
    }
    if (pairs.length === 0) {
      setFilterParam(null)
      setFilterViewState(null)
    } else {
      setFilterParam(pairs.join(';'))
      setFilterViewState(pairs.join(';'))
    }
    setPage(1)
  }, [filters, setFilterParam, setFilterViewState, setPage])

  const sort = React.useMemo((): Sort | undefined => {
    if (sortParam == null) { return undefined }

    if (sortParam.startsWith('-')) {
      return {field: sortParam.slice(1), direction: -1}
    } else {
      return {field: sortParam, direction: 1}
    }
  }, [sortParam])

  const setSort = React.useCallback((sort: Sort | null) => {
    if (sort == null) {
      setSortParam(null)
      setSortViewState(null)
    } else if (sort.direction === 1) {
      setSortParam(sort.field)
      setSortViewState(sort.field)
    } else {
      setSortParam(`-${sort.field}`)
      setSortViewState(`-${sort.field}`)
    }
  }, [setSortParam, setSortViewState])

  return {
    syncing: sync && back,
    label,
    setLabel,
    search: search ?? undefined,
    setSearch,
    filters,
    setFilters,
    page: page ?? undefined,
    setPage,
    sort,
    setSort,
  }
}

export interface ResourceListLocation {
  syncing: boolean

  label:    string | undefined
  setLabel: (label: string | null) => void

  search:    string | undefined
  setSearch: (search: string | null) => void

  filters:    Record<string, any> | undefined
  setFilters: (filters: Record<string, any>) => void

  page:    number | undefined
  setPage: (page: number) => void

  sort:    Sort | undefined
  setSort: (sort: Sort | null) => void
}