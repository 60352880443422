import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useTheme } from '~/ui/styling'
import { FormError } from './types'

export interface Props {
  errors:             FormError[]
  showFieldCaptions?: boolean
}

const FormErrors = memo('FormErrors', (props: Props) => {

  const theme = useTheme()

  const {errors = [], showFieldCaptions = false} = props
  const {fieldCaption, fieldError} = useResourceTranslation()

  const messageForError = React.useCallback((error: FormError) => {
    if (error.code == null) {
      return error.message
    } else {
      return fieldError(error.field, error.code, {
        defaultValue: error.message,
      })
    }
  }, [fieldError])

  return (
    <VBox>
      {errors.map((error, index) => (
        <HBox key={index} gap={layout.padding.inline.s} align='baseline'>
          <SVG name='cross' size={layout.icon.xs} color={theme.semantic.negative}/>
          {showFieldCaptions && error.field != null && (
            <Label box small color={theme.semantic.negative}>
              {fieldCaption(error.field)}
            </Label>
          )}
          <Label flex='shrink' small color={theme.semantic.negative} truncate={false}>
            {messageForError(error)}
          </Label>
        </HBox>
      ))}
    </VBox>
  )
})

export default FormErrors