import { action, makeObservable, observable, reaction, runInAction } from 'mobx'
import { SocketOperation } from 'socket.io-react'
import { slugify } from 'ytil'
import { Organisation, Project, Ref } from '~/models'
import { CopyResult, dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class DuplicateProjectFormModel implements FormModel {

  constructor(
    public readonly project: Project,
  ) {
    makeObservable(this)

    reaction(() => [this.name, this.autoGenerateCode], action(() => {
      if (!this.autoGenerateCode) { return }
      this.code = slugify(this.name)
    }))
  }

  @observable
  public name: string = `${this.project.name} - Copy`

  @observable
  public code: string = ''

  @observable
  public autoGenerateCode: boolean = true

  @action
  public toggleAutoGenerateCode() {
    this.autoGenerateCode = !this.autoGenerateCode
  }

  @observable
  public organisation: Ref<Organisation> = this.project.organisation

  @action
  private updateCode(newName: string) {
    if (this.code !== slugify(this.name)) { return }
    this.code = slugify(newName)
  }

  //------
  // Copy

  @observable
  public operation: SocketOperation | null = null

  @observable
  public results: CopyResult[] | null = null

  @observable
  public status: DuplicateStatus = 'idle'

  @action
  public async duplicate(): Promise<SubmitResult | undefined> {
    if (this.operation != null) { return }
    if (this.status !== 'idle') { return }

    this.status = 'duplicating'
    this.results = null

    const endpoint = dataStore.endpoint(Project)
    const result = await endpoint.duplicate({
      data: [{type: 'projects', id: this.project.id}],
      meta: {
        overrides: {
          name:         this.name,
          code:         this.code,
          organisation: this.organisation,
        },
      },
    }, {
      onStart: action(operation => {
        this.operation = operation
      }),
      onEnd: action(() => {
        this.operation = null
      }),
    })

    runInAction(() => {
      if (result.status === 'ok') {
        this.results = result.data ?? []
      } else {
        this.results = null
      }
      this.status = 'idle'
    })

    return result
  }

  //------
  // Submit (noop) & reset

  public async submit(): Promise<SubmitResult | undefined> {
    return {status: 'ok'}
  }

  @action
  public reset() {
    this.name = `${this.project.name} - Copy`
    this.code = slugify(this.name)
    this.autoGenerateCode = true
    this.organisation = this.project.organisation
    this.status = 'idle'
    this.results = null
  }

}

export type DuplicateStatus = 'idle' | 'duplicating'