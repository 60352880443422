import React from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import config from '~/config'
import { ModuleNode } from '~/models'
import { observer } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import ModuleBar from '../../modules/ModuleBar'
import { Params as PlannerParams } from '../../PlannerScreen'
import { useFlowPlanner } from '../FlowPlannerContext'
import { useNodeViewKebabMenuItems } from './NodeViewKebabMenu'

export interface Props {
  node:         ModuleNode
  variant?:     'canvas' | 'list'
  interactive?: boolean
}

const ModuleNodeView = observer('ModuleNodeView', (props: Props) => {

  const {node, variant = 'canvas', interactive = true} = props

  const {planner} = useFlowPlanner()
  const {t} = useResourceTranslation('flow_planner')

  React.useEffect(() => {
    planner.setLoadingComponent(node.uuid, false)
  }, [node.uuid, planner])

  //------
  // Actions

  const history = useHistory()
  const {params} = useRouteMatch<PlannerParams>()

  const goToModule = React.useCallback(() => {
    const prefix = params.modules == null ? '/' : `/${params.modules}/`
    history.push(`/planner${prefix}${node.module}`)
  }, [history, node.module, params.modules])

  const extraMenuItems = React.useMemo((): PopupMenuItem[] => [{
    icon:     'puzzle',
    caption:  t('module.actions.go_to'),
    keyHint:  'doubleclick',
    onSelect: goToModule,
  }], [goToModule, t])

  const menuItems = useNodeViewKebabMenuItems(node, extraMenuItems)

  //------
  // Rendering

  function render() {
    return (
      <ModuleBar
        moduleID={node.module}
        caption={node.name ?? undefined}
        kebabMenuItems={menuItems}
        interactive={interactive}
        small={variant === 'list'}
      />
    )
  }

  return render()

})

export default ModuleNodeView

export const borderRadius = config.planner.minimumComponentSize('module').height / 2