import React from 'react'
import { Module } from '~/models'
import { CalendarPlanner, FlowPlanner, projectStore } from '~/stores'
import ParametersDialog from '~/ui/app/modules/parameters/ParametersDialog'
import { observer } from '~/ui/component'
import {
  ClearButton,
  ConfirmBox,
  Dimple,
  HBox,
  Label,
  PushButton,
  TimeAgoLabel,
} from '~/ui/components'
import SVG from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { useModelDocument } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useStyling } from '~/ui/styling'

export interface Props {
  planner: FlowPlanner | CalendarPlanner
}

const PlannerActions = observer('PlannerActions', (props: Props) => {

  const {planner} = props

  const plan    = planner.plan
  const service = planner.service

  const modified      = service?.modified
  const mayPublish    = service?.mayPublish ?? false
  const lastPublished = service?.lastPublished
  const isSubModule   = plan != null && plan.module !== projectStore?.projectID

  const {t, actionCaption, actionConfirm} = useResourceTranslation('flow_planner')

  const {colors} = useStyling()

  // Make sure to fetch the detailed module.
  useModelDocument(Module, plan?.module ?? null)

  //------
  // Rendering

  function render() {
    return (
      <HBox flex='shrink' gap={layout.padding.s}>
        {isSubModule && renderParameterActions()}
        {isSubModule && modified && <Dimple vertical/>}
        {!modified && lastPublished != null && (
          <TimeAgoLabel
            template={t('last_published', {interpolation: {skipOnVariables: true}})}
            datetime={lastPublished}
            dim
            italic
            small
          />
        )}
        {modified && (
          <>
            {renderModified()}
            {renderPublishActions()}
          </>
        )}
      </HBox>
    )
  }

  function renderModified() {
    return (
      <HBox flex='shrink' gap={layout.padding.inline.m}>
        <SVG name='warning-full' color={colors.semantic.warning} size={layout.icon.s}/>
        <Label flex='shrink' caption small>
          {t('unsaved_changes')}
        </Label>
      </HBox>
    )
  }

  function renderPublishActions() {
    return (
      <>
        <PushButton
          icon='check'
          caption={actionCaption('publish')}
          onTap={publishNow}
          enabled={mayPublish}
          small
        />
        <ClearButton
          icon='cross'
          caption={actionCaption('rollback')}
          onTap={rollback}
          small
          dim
        />
      </>
    )
  }

  const publishNow = React.useCallback(() => {
    return service?.publishChanges()
  }, [service])

  const rollback = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({...actionConfirm('rollback'), destructive: true})
    if (!confirmed) { return }

    return service?.rollbackChanges()
  }, [actionConfirm, service])

  //------
  // Module parameters

  const [parametersFormOpen, openParametersDialog, closeParametersDialog] = useBoolean()

  function renderParameterActions() {
    return (
      <>
        <ClearButton
          icon='code'
          caption={actionCaption('parameters')}
          onTap={openParametersDialog}
        />

        {planner.plan != null && (
          <ParametersDialog
            open={parametersFormOpen}
            requestClose={closeParametersDialog}
            moduleID={planner.plan.module}
          />
        )}
      </>
    )
  }

  return render()

})

export default PlannerActions