import React from 'react'
import { Channel } from '~/models'
import { dataStore, SubmitResult } from '~/stores'
import { AccessFormModel, AccessSummary } from '~/ui/app/access'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'

export interface Props {
  channel: Channel
}

const ChannelAccess = memo('ChannelAccess', (props: Props) => {

  const {channel} = props

  const {t} = useResourceTranslation()

  const formModel = React.useMemo(
    () => new ChannelAccessFormModel(channel),
    [channel],
  )

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {renderWarning()}
        {renderSummary()}
      </VBox>
    )
  }

  function renderWarning() {
    if (channel.type !== 'participant' || (channel.maySend.type === 'global' && channel.maySend.value === false)) { return null }

    return (
      <SummaryInfo.Item
        icon='warning-full'
        summary={t('open')}
        important
        markup
      />
    )
  }

  function renderSummary() {
    return (
      <AccessSummary
        access={channel.maySend}
        title={t('access.title')}
        icon='chat'
        instructions={t(`access.form.instructions.${channel.type}`)}
        action={t('access.action')}
        formModel={formModel}
      />
    )
  }

  return render()

})

export default ChannelAccess

class ChannelAccessFormModel extends AccessFormModel {

  constructor(
    public readonly channel: Channel,
  ) {
    super(channel.maySend)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Channel, this.channel.id, {
      maySend: this.buildAccess(),
    })
  }

}