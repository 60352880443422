import { CalendarItem, CalendarPlan } from '~/models'
import { CalendarPlanner } from '~/stores'
import { TargetingFormModel } from '~/ui/app/targeting'
import { SubmitResult } from '~/ui/form'

export default class CalendarItemTargetingFormModel extends TargetingFormModel {

  constructor(
    private readonly planner: CalendarPlanner,
    private readonly plan: CalendarPlan,
    private readonly item: CalendarItem,
  ) {
    super(item.targeting)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    await this.planner.updateItem(this.item.uuid, item => ({
      ...item,
      targeting: this.buildTargeting(),
    }))

    return {status: 'ok'}
  }

}