import React from 'react'
import { ChallengeQuestion, ChallengeTask, isScoreableFeedback } from '~/models'
import { memo } from '~/ui/component'
import { ConfirmBox, DetailIndicator, HBox, PopupMenuItem, PushButton, VBox } from '~/ui/components'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { useBoolean, useFormOpen } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useStyling } from '~/ui/styling'
import ReviewForm from '../../feedback/ReviewForm'
import ChallengeQuestionForm from '../questions/ChallengeQuestionForm'
import ChallengeQuestionFormModel from '../questions/ChallengeQuestionFormModel'
import ChallengeScoringForm from '../questions/ChallengeScoringForm'
import ChallengeScoringFormModel from '../questions/ChallengeScoringFormModel'

export interface Props {
  task:     ChallengeTask
  saveTask: (data: AnyObject) => Promise<SubmitResult | undefined>
}

const ChallengeTaskQuestionSummary = memo('ChallengeTaskQuestionSummary', (props: Props) => {

  const {task, saveTask} = props
  const {t, actionCaption, actionConfirm} = useResourceTranslation()
  const {colors} = useStyling()

  const question = task.question
  const scorings = task.question?.scorings ?? []

  //------
  // Question form

  const [questionFormModel, setQuestionFormModel] = React.useState<ChallengeQuestionFormModel | null>(null)
  const [questionFormOpen, currentQuestionFormModel, closeQuestionForm] = useFormOpen(
    questionFormModel,
    () => { setQuestionFormModel(null) },
  )

  const openQuestionForm = React.useCallback(() => {
    const formModel = new ChallengeQuestionFormModel(saveTask, task)
    setQuestionFormModel(formModel)
  }, [saveTask, task])

  //------
  // Scoring form

  const [scoringFormModel, setScoringFormModel] = React.useState<ChallengeScoringFormModel | null>(null)
  const [scoringFormOpen, currentScoringFormModel, closeScoringForm] = useFormOpen(
    scoringFormModel,
    () => { setScoringFormModel(null) },
  )

  const openScoringForm = React.useCallback(() => {
    if (task.question == null) { return }

    const formModel = new ChallengeScoringFormModel(saveTask, task as ChallengeTask & {question: ChallengeQuestion})
    setScoringFormModel(formModel)
  }, [saveTask, task])

  //------
  // Review form

  const [reviewFormOpen, openReviewForm, closeReviewForm] = useBoolean()

  const saveReviewData = React.useCallback(async (data: AnyObject) => {
    const result = await saveTask({question: data})
    return translateFormErrorPaths(result, path => path.replace(/^question\./, ''))
  }, [saveTask])

  //------
  // Actions

  const removeQuestion = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('tasks.remove_question'),
      destructive: true,
    })
    if (!confirmed) { return }

    return await saveTask({question: null})
  }, [actionConfirm, saveTask])

  const removeScoring = React.useCallback(async () => {
    if (question == null) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('tasks.remove_scoring'),
      destructive: true,
    })
    if (!confirmed) { return }

    return await saveTask({
      question: {
        ...question,
        scorings: [],
      },
    })
  }, [actionConfirm, question, saveTask])

  const questionMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'pencil',
      caption:  actionCaption('tasks.edit_question'),
      onSelect: openQuestionForm,
    })

    items.push({section: '-'})
    items.push({
      icon:     'trash',
      caption:  actionCaption('tasks.remove_question'),
      color:    colors.semantic.negative,
      onSelect: removeQuestion,
    })

    return items
  }, [actionCaption, colors.semantic.negative, openQuestionForm, removeQuestion])

  const scoringMenuItems = React.useMemo(() => {
    if (question == null) { return [] }

    const automaticScoringSupported = isScoreableFeedback(question)

    const items: PopupMenuItem[] = []

    if (automaticScoringSupported) {
      if (scorings.length === 0) {
        items.push({
          icon:     'plus',
          caption:  actionCaption('tasks.add_scoring'),
          onSelect: openScoringForm,
        })
      } else {
        items.push({
          icon:     'pencil',
          caption:  actionCaption('tasks.edit_scoring'),
          onSelect: openScoringForm,
        })
        items.push({section: '-'})
        items.push({
          icon:     'trash',
          caption:  actionCaption('tasks.remove_scoring'),
          color:    colors.semantic.negative,
          onSelect: removeScoring,
        })
      }
    }

    items.push({
      section: '-',
    }, {
      caption:  t('feedback:review.menu_item'),
      checked:  question.review,
      onSelect: openReviewForm,
    })

    return items
  }, [actionCaption, colors.semantic.negative, openReviewForm, openScoringForm, question, removeScoring, scorings.length, t])

  //------
  // Rendering

  function render() {
    return (
      <VBox>
        {task.question == null ? (
          renderAddButton()
        ) : (
          renderQuestionSummary()
        )}

        {currentQuestionFormModel != null && (
          <ChallengeQuestionForm
            open={questionFormOpen}
            requestClose={closeQuestionForm}
            formModel={currentQuestionFormModel}
          />
        )}
        {currentScoringFormModel != null && (
          <ChallengeScoringForm
            open={scoringFormOpen}
            requestClose={closeScoringForm}
            formModel={currentScoringFormModel}
          />
        )}

        {task.question != null && (
          <ReviewForm
            open={reviewFormOpen}
            requestClose={closeReviewForm}
            feedback={task.question}
            requestSave={saveReviewData}
          />
        )}
      </VBox>
    )
  }

  function renderAddButton() {
    return (
      <PushButton
        icon='question'
        caption={actionCaption('tasks.add_question')}
        onTap={openQuestionForm}
      />
    )
  }

  function renderQuestionSummary() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex>
          {renderQuestionIndicator()}
        </VBox>
        {renderScoringIndicator()}
      </HBox>
    )
  }

  function renderQuestionIndicator() {
    if (question == null) { return null }

    return (
      <DetailIndicator
        icon='question'
        caption={t(`questions.${question.type}`)}
        filled={true}
        menuItems={questionMenuItems}
        onDoubleClick={openQuestionForm}
      />
    )
  }

  function renderScoringIndicator() {
    if (scoringMenuItems.length === 0) { return null }

    return (
      <DetailIndicator
        icon='trophy'
        filled={scorings.length > 0 || question?.review}
        menuItems={scoringMenuItems}
        onDoubleClick={openScoringForm}
      />
    )
  }

  return render()

})

export default ChallengeTaskQuestionSummary