import React from 'react'
import { useTimer } from 'react-timer'
import { SystemNotification } from '~/models'
import { memo } from '~/ui/component'
import { panelBorderRadius, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { animation, createUseStyles } from '~/ui/styling'
import NotificationPopupItem, { height as notificationPopupItemHeight } from './NotificationPopupItem'

export interface Props {
  notification: SystemNotification
  active?:     boolean
}

const NotificationCycler = memo('NotificationCycler', (props: Props) => {

  const {notification, active = true} = props

  const timer      = useTimer()
  const currentLog = usePrevious(notification)

  const [previousLog, setPreviousLog] = React.useState<SystemNotification | null>(null)
  const [phase, setPhase] = React.useState<string>('idle')

  React.useEffect(() => {
    if (!active) { return }
    if (currentLog == null || notification.id === currentLog.id) { return }
    setPreviousLog(currentLog)
  }, [active, currentLog, notification])

  React.useEffect(() => {
    timer.clearAll()
    if (previousLog == null) { return }

    setPhase('prepare')
    timer.setTimeout(() => {
      setPhase('commit')
    }, 16)
    timer.setTimeout(() => {
      setPhase('idle')
      setPreviousLog(null)
    }, 16 + animation.durations.medium)
  }, [previousLog, timer])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.notificationsCycler}>
        <VBox classNames={[$.content, phase]}>
          {previousLog && <NotificationPopupItem notification={previousLog}/>}
          <NotificationPopupItem notification={notification}/>
        </VBox>
      </VBox>
    )
  }

  return render()

})

export default NotificationCycler

const useStyles = createUseStyles(theme => ({
  notificationsCycler: {
    position:     'relative',
    height:       notificationPopupItemHeight,
    overflow:     'hidden',

    borderRadius: panelBorderRadius(theme),
  },

  content: {
    willChange: 'transform',

    '&.prepare': {
      transform:  'translateY(0)',
    },
    '&.commit': {
      transform:  `translateY(${-notificationPopupItemHeight}px)`,
      transition: animation.transitions.medium('transform'),
    },
  },
}))