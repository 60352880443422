import React from 'react'
import { memo } from '~/ui/component'
import { Chip, HBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useTheme } from '~/ui/styling'

export interface Props {
  type?:     string
  icon?:     SVGName
  resource?: string
}

const TypeCell = memo('TypeCell', (props: Props) => {

  const {type, icon, resource} = props

  const {t} = useResourceTranslation(resource)
  const theme = useTheme()

  const caption = t([
    `types.${type}.caption`,
    `types.${type}`,
  ], type)

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.s}>
        <Chip
          icon={icon}
          children={caption}
          backgroundColor={theme.bg.normal}
          small
        />
      </HBox>
    )
  }

  return render()

})

export default TypeCell