import React from 'react'
import { useTranslation } from 'react-i18next'
import { useTimer } from 'react-timer'
import Toast from 'react-toast'
import { ImportModel, ImportResult, ImportSession } from 'sheet-importer'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  Dimple,
  Dropzone,
  Empty,
  HBox,
  Label,
  ModalDialog,
  ModalDialogProps,
  ProgressBar,
  Scroller,
  Spinner,
  SVG,
  VBox,
} from '~/ui/components'
import { DropzoneState } from '~/ui/components/Dropzone'
import { Choice } from '~/ui/components/fields/SelectField'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout, ThemeProvider, useStyling } from '~/ui/styling'
import ImportIssueList from './ImportIssueList'
import ImportPreview from './ImportPreview'
import ImportProfileSelector from './ImportProfileSelector'
import ImportResultSummaryPanel from './ImportResultSummaryPanel'
import ImportSummaryPanel from './ImportSummaryPanel'

export interface Props extends Omit<ModalDialogProps, 'children'> {
  open:          boolean
  requestClose?: () => any

  model:             ImportModel

  fieldCaption:     (key: string) => string
  fieldChoice?:     (key: string) => Omit<Choice<any>, 'value' | 'children'>
  renderFieldForm?: (field: string) => React.ReactNode

  startWithFile?:    File | null
  onImportComplete?: (result: ImportResult) => any
}

const ImportDialog = observer('ImportDialog', (props: Props) => {

  const [t] = useTranslation('importer')
  const {
    model,
    fieldCaption,
    fieldChoice,
    onImportComplete,
    title = t('title'),
    headerRight,
    startWithFile,
    renderFieldForm,
    ...rest
  } = props

  const [session, setSession] = React.useState<ImportSession | null>(null)
  const loading   = session?.loading ?? false
  const importing = session?.importing ?? false
  const progress  = session?.importProgress ?? null

  const result        = session?.lastRun?.result
  const hasResult     = result != null
  const issues        = result?.status === 'completed' ? result.issues : []
  const nonInfoIssues = issues.filter(issue => issue.level !== 'info')

  const error     = session?.error ?? null
  const prevError = usePrevious(error)

  React.useEffect(() => {
    if (error == null) { return }
    if (error === prevError) { return }

    const status = (error as any)?.status ?? 500

    Toast.show({
      ...t([`errors:${status}`, 'errors:unknown']),
      type: 'error',
    })
  }, [error, prevError, t])

  //------
  // Drop file

  const timer = useTimer()

  const handleDropzoneDrop = React.useCallback(async (files: File[]) => {
    if (files.length === 0) { return }

    const session = await timer.await(ImportSession.start(model, files[0]))
    setSession(session)
  }, [model, timer])

  const start = React.useCallback(async () => {
    if (startWithFile != null) {
      const session = await timer.await(ImportSession.start(model, startWithFile))
      setSession(session)
    } else {
      setSession(null)
    }
  }, [model, startWithFile, timer])

  const onWillOpen = React.useCallback(() => {
    start()
    props.onWillOpen?.()
  }, [props, start])

  React.useEffect(() => {
    if (!props.open) { return }
    if (session == null) { return }
    if (session.lastRun != null) { return }
    if (session.model === model) { return }

    start()
  }, [model, props.open, session, start])

  //------
  // Importing

  const importNow = React.useCallback(async () => {
    const result = await session?.import()
    if (result != null) {
      onImportComplete?.(result)
    }
  }, [onImportComplete, session])

  //------
  // Rendering

  const $ = useStyles()
  const {colors} = useStyling()

  function render() {
    return (
      <ModalDialog icon='upload' title={title} width='max' height='max' headerRight={renderCloseButton()} {...rest} onWillOpen={onWillOpen}>
        {loading && renderLoading()}
        {importing && renderImporting()}

        {!loading && !importing && (
          <Dropzone
            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // Jesus
            onDrop={handleDropzoneDrop}
            renderContent={renderContent}
            showIdleHint={false}
            activeHint={t('drop_hint.active')}
            acceptHint={t('drop_hint.active')}
            noClick={session != null}
            flex
          />
        )}
      </ModalDialog>
    )
  }

  function renderCloseButton() {
    return (
      <HBox gap={layout.padding.s}>
        {headerRight}
        <ClearButton
          icon='cross'
          caption={t('buttons:close')}
          onTap={props.requestClose}
        />
      </HBox>
    )
  }

  function renderContent(state: DropzoneState) {
    return (
      <VBox flex>
        {renderRestart()}
        <HBox flex align='stretch'>
          {renderBody()}
          {renderSidebar()}
        </HBox>
        {state.renderDropHint()}
      </VBox>
    )
  }

  function renderBody() {
    return (
      <VBox flex>
        {session == null ? (
          renderEmpty()
        ) : hasResult ? (
          renderResult()
        ) : (
          renderPreview()
        )}
      </VBox>
    )
  }

  function renderResult() {
    if (session == null) { return null }

    if (issues.length > 0) {
      return (
        <Scroller flex>
          <Dimple horizontal/>
          <VBox classNames={$.result} gap={layout.padding.inline.s}>
            <ImportIssueList
              session={session}
            />
          </VBox>
        </Scroller>
      )
    } else {
      return (
        <Center flex gap={layout.padding.m}>
          <SVG
            name='check-circle'
            size={layout.icon.xxl}
            color={colors.semantic.positive}
          />
          <Label h2 dim>
            {t('result.no_issues')}
          </Label>
        </Center>
  )
    }
  }

  function renderPreview() {
    if (session == null) { return null }

    return (
      <Scroller flex horizontal classNames={$.previewScroller}>
        <VBox flex classNames={$.preview}>
          <ImportPreview
            session={session}
            fieldCaption={fieldCaption}
            fieldChoice={fieldChoice}
          />
        </VBox>
      </Scroller>
    )
  }

  function renderSidebar() {
    if (session == null) { return null }

    return (
      <VBox classNames={$.sidebar} gap={layout.padding.m}>
        {hasResult ? (
          <ImportResultSummaryPanel
            session={session}
          />
        ) : (
          <ImportSummaryPanel
            session={session}
            requestImport={importNow}
          />
        )}
        <ImportProfileSelector
          session={session}
        />
      </VBox>
    )
  }

  function renderRestart() {
    if (result == null) { return null }
    if (nonInfoIssues.length === 0) { return null }

    return (
      <ThemeProvider contrast='primary'>
        <HBox classNames={$.restart} gap={layout.padding.inline.l}>
          <SVG name='upload' size={layout.icon.l}/>
          <VBox flex>
            <Label>
              {t('restart.title')}
            </Label>
            <Label small dim truncate={false}>
              {t('restart.detail')}
            </Label>
          </VBox>
        </HBox>
      </ThemeProvider>
    )
  }

  function renderEmpty() {
    return (
      <ThemeProvider primary>
        <Empty
          flex
          dim
          icon='upload'
          title={t('empty.title')}
          detail={t('empty.detail')}
        />
      </ThemeProvider>
    )
  }

  function renderLoading() {
    return (
      <Center flex padding={layout.padding.m}>
        <Spinner/>
      </Center>
    )
  }

  function renderImporting() {
    if (progress == null) { return renderLoading() }

    return (
      <VBox flex justify='middle' gap={layout.padding.s} padding={layout.padding.m}>
        <ProgressBar
          progress={progress.progress}
        />
        <Label align='center' small dim>
          {t(`progress.${progress.label}`, progress)}
        </Label>
      </VBox>
    )
  }

  return render()

})

export default ImportDialog

const useStyles = createUseStyles(theme => ({
  bodySelector: {
    ...layout.responsive(size => ({
      padding:       layout.padding.m[size],
      paddingBottom: 0,
    })),
  },

  previewScroller: {
    ...layout.responsive(size => ({
      marginRight:  layout.padding.m[size],
    })),
  },

  preview: {
    ...layout.responsive(size => ({
      padding:      layout.padding.m[size],
      paddingRight: 0,
    })),
  },

  restart: {
    background: theme.semantic.primary,
    padding:    [layout.padding.inline.m, layout.padding.inline.xl],
  },

  result: {
    ...layout.responsive(size => ({
      padding: layout.padding.m[size],
    })),
  },

  sidebar: {
    width: 288,
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },
}))