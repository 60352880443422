import React from 'react'
import { ScriptMessage } from '~/models'
import { memo } from '~/ui/component'
import { NewScriptMessage, ScriptMessageDetail } from './types'

interface ScriptEditingContext {
  messages: ScriptMessage[]

  editingMessageUUID:    string | null
  setEditingMessageUUID: (uuid: string) => void

  editingDetail:    ScriptMessageDetail | null
  setEditingDetail: (detail: ScriptMessageDetail | null) => void

  newMessage:            NewScriptMessage | null
  setNewMessage:         (message: NewScriptMessage) => void
  stopEditingMessage:    () => void

  setOnRequestSaveCurrent:          (handler: (() => any | Promise<any>) | null) => void
  saveCurrentAndStopEditingMessage: () => Promise<void>

  editingList:          boolean
  startEditingList:     () => void
  stopEditingList:      () => void

  toggleSelectMessage:  (uuid: string) => any
  selectedMessageUUIDs: string[]
  selectAllMessages:    () => any
  selectNoMessages:    () => any
}

const ScriptEditingContext = React.createContext<ScriptEditingContext>({
  messages: [],

  editingMessageUUID:    null,
  setEditingMessageUUID: () => void 0,

  editingDetail:         null,
  setEditingDetail:      () => void 0,

  newMessage:            null,
  setNewMessage:         () => void 0,
  stopEditingMessage:    () => void 0,

  setOnRequestSaveCurrent:          () => void 0,
  saveCurrentAndStopEditingMessage: () => Promise.resolve(void 0),

  editingList:          false,
  startEditingList:     () => void 0,
  stopEditingList:      () => void 0,

  toggleSelectMessage:  () => void 0,

  selectedMessageUUIDs: [],
  selectAllMessages:    () => void 0,
  selectNoMessages:     () => void 0,
})

export default ScriptEditingContext

export interface ScriptEditingProviderProps {
  messages:  ScriptMessage[]
  children?: React.ReactNode
}

export const ScriptEditingProvider = memo('ScriptEditingProvider', (props: ScriptEditingProviderProps) => {

  const {messages, children} = props

  //------
  // Editing message

  const [editingMessageUUID, setEditingMessageUUID] = React.useState<string | null>(null)
  const [editingDetail, setEditingDetail] = React.useState<ScriptMessageDetail | null>(null)

  const [newMessage, setNewMessage] = React.useState<NewScriptMessage | null>(null)

  const saveCurrentRef = React.useRef<(() => any | Promise<any>) | null>(null)

  const [selectedMessageUUIDs, setSelectedMessageUUIDs] = React.useState<string[] | null>(null)

  const editingList      = React.useMemo(() => selectedMessageUUIDs != null, [selectedMessageUUIDs])
  const startEditingList = React.useCallback(() => {
    setSelectedMessageUUIDs([])
    setEditingMessageUUID(null)
    setNewMessage(null)
  }, [])
  const stopEditingList  = React.useCallback(() => setSelectedMessageUUIDs(null), [])

  const toggleSelectMessage = React.useCallback((uuid: string) => {
    if (selectedMessageUUIDs == null) { return }

    const nextSelectedMessageUUIDs = selectedMessageUUIDs.filter(u => u !== uuid)
    if (!selectedMessageUUIDs.includes(uuid)) {
      nextSelectedMessageUUIDs.push(uuid)
    }
    setSelectedMessageUUIDs(nextSelectedMessageUUIDs)
  }, [selectedMessageUUIDs])

  const selectAllMessages = React.useCallback(() => {
    setSelectedMessageUUIDs(messages.map(it => it.uuid))
  }, [messages])

  const selectNoMessages = React.useCallback(() => {
    setSelectedMessageUUIDs([])
  }, [])

  //------
  // List editing

  const context = React.useMemo((): ScriptEditingContext => ({
    messages,

    editingMessageUUID,
    setEditingMessageUUID,

    editingDetail,
    setEditingDetail,

    newMessage,
    setNewMessage,

    stopEditingMessage: () => {
      setEditingMessageUUID(null)
      setNewMessage(null)
    },

    setOnRequestSaveCurrent: handler => {
      saveCurrentRef.current = handler
    },
    saveCurrentAndStopEditingMessage: async () => {
      await saveCurrentRef.current?.()

      setEditingMessageUUID(null)
      setNewMessage(null)
    },

    editingList,
    startEditingList,
    stopEditingList,

    selectedMessageUUIDs: selectedMessageUUIDs ?? [],
    toggleSelectMessage,
    selectAllMessages,
    selectNoMessages,
  }), [messages, editingMessageUUID, editingDetail, newMessage, editingList, startEditingList, stopEditingList, selectedMessageUUIDs, toggleSelectMessage, selectAllMessages, selectNoMessages])

  return (
    <ScriptEditingContext.Provider value={context}>
      {children}
    </ScriptEditingContext.Provider>
  )

})

export function useScriptEditing(): ScriptEditingContext {
  return React.useContext(ScriptEditingContext)
}