import React from 'react'
import { Competition } from '~/models'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import ModuleField from '~/ui/app/modules/ModuleField'
import { memo } from '~/ui/component'
import { HBox, TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']
  type:         Competition['type']
  model?:       Competition | null
}

const CompetitionForm = memo('CompetitionForm', (props: Props) => {

  const {open, requestClose, afterSubmit, type, model = null} = props
  const isNew = model == null

  const initialData = React.useMemo(
    () => isNew ? ({type}) : {},
    [isNew, type],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        Model={Competition}
        model={model}
        initialData={initialData}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        {isNew && (
          <FormField name='module'>
            {bind => <ModuleField {...bind}/>}
          </FormField>
        )}

        <HBox align='top' gap={layout.padding.s}>
          {renderBasicFields()}
          {renderImageField()}
        </HBox>

        <FormField name='description'>
          {bind => <TextField {...bind} multiline/>}
        </FormField>
      </VBox>
    )
  }

  function renderBasicFields() {
    return (
      <VBox flex gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='title'>
          {bind => <TextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderImageField() {
    return (
      <FormField name='image' renderAsLabel={false}>
        {bind => (
          <CustomImageField
            {...bind}
            size={{width: 116, height: 116}}
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default CompetitionForm