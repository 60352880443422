import React from 'react'
import { forwardRef } from '~/ui/component'
import { context } from '~/audio'
import { isArray } from 'lodash'

export interface Props {
  source: AudioSource
}

export type AudioSource =
  | AudioBuffer
  | [AudioBuffer, boolean]

interface Audio {
  play(): void
  stop(): void
}

const Audio = forwardRef('Audio', (props: Props, ref: React.Ref<Audio>) => {

  const {source} = props

  const sourceBuffer = isArray(source) ? source[0] : source
  const loop         = isArray(source) ? source[1] : false

  //------
  // Pipeline / cross fade

  const [pipeline, setPipeline] = React.useState<AudioPipeline | null>(null)

  const playingRef = React.useRef<boolean>(false)

  const createSource = React.useCallback(() => {
    if (context == null) { return null }

    const source = context.createBufferSource()
    source.buffer = sourceBuffer
    source.loop = loop
    return source
  }, [loop, sourceBuffer])

  const createPipeline = React.useCallback((source: AudioBufferSourceNode): AudioPipeline | null => {
    if (context == null) { return null }

    return {
      source: source,
      gain:   context.createGain(),
    }
  }, [])

  const stop = React.useCallback(() => {
    if (pipeline == null) { return }
    if (!playingRef.current) { return }

    pipeline.source.stop()
    pipeline.gain.disconnect()
    pipeline.source.disconnect()
    playingRef.current = false

    setPipeline(null)
  }, [pipeline])

  const play = React.useCallback(() => {
    stop()

    const source = createSource()
    if (source == null) { return }

    const pipeline = createPipeline(source)
    if (pipeline == null) { return }

    pipeline.source.connect(pipeline.gain)
    pipeline.gain.connect(context.destination)
    pipeline.source.start()
    playingRef.current = true

    setPipeline(pipeline)
  }, [createPipeline, createSource, stop])

  React.useImperativeHandle(ref, () => ({play, stop}))
  React.useEffect(() => stop, [stop])

  return null

})

interface AudioPipeline {
  source: AudioBufferSourceNode
  gain:   GainNode
}

export default Audio