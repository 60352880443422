import React from 'react'
import { useTranslation } from 'react-i18next'
import { sprintf } from 'sprintf-js'
import { Widget, WidgetAlotment } from '~/models'
import { DataPoint, DataSeries, WidgetState } from '~/stores'
import { observer } from '~/ui/component'
import {
  ClearButton,
  DataGrid,
  EmptyOrFetching,
  HBox,
  Panel,
  PanelHeader,
  PushButton,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { saveAs } from '~/ui/util'
import { useAnalyticsService } from './AnalyticsServiceContext'
import WidgetFiltersBar from './WidgetFiltersBar'
import { WidgetFilterValuesProvider } from './WidgetFilterValuesContext'
import WidgetReport from './WidgetReport'
import renderDashboardWidget from './widgets/index'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  state:    WidgetState

  requestClose?: () => any
}

const WidgetReportDialog = observer('WidgetReportDialog', (props: Props) => {

  const {alotment, widget, state, requestClose} = props
  const {service} = useAnalyticsService()

  const initialStateRef = React.useRef(state)

  const endpoint = React.useMemo(
    () => service?.widgetDataEndpoint(initialStateRef.current),
    [service],
  )

  const data        = endpoint?.state.data
  const series      = endpoint?.state.series
  const fetchStatus = endpoint?.fetchStatus ?? 'idle'

  const [t] = useTranslation('analytics')

  const widgetRef = React.useRef<HTMLDivElement>(null)

  const fetch = React.useCallback(() => {
    endpoint?.fetch()
  }, [endpoint])

  const download = React.useCallback(() => {
    const svg = widgetRef.current?.querySelector('svg')
    if (svg == null) { return }

    const report = new WidgetReport(data ?? [])
    report.generate().then(buffer => {
      saveAs(buffer, `${widget.$caption}.xlsx`)
    })
  }, [data, widget.$caption])

  const columnSeries = React.useMemo((): DataSeries[] => {
    if (series == null) { return [] }
    if (series.length > 0) { return series }

    return [{
      name:     'value',
      type:     'line',
      valueKey: 'value',
    }]
  }, [series])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (endpoint == null) { return null }

    return (
      <WidgetFilterValuesProvider values={endpoint.filterValues}>
        <Panel flex classNames={$.WidgetReportDialog}>
          <PanelHeader
            icon='page'
            caption={t('report.title', props)}
            right={renderHeaderRight()}
          />
          {renderBody()}
        </Panel>
      </WidgetFilterValuesProvider>
    )
  }

  function renderHeaderRight() {
    return (
      <ClearButton
        icon='cross'
        caption={t('buttons:close')}
        onTap={requestClose}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex padding={layout.padding.m} gap={layout.padding.m}>
        {renderPreamble()}
        {renderWidget()}
        {renderData()}
      </VBox>
    )
  }

  function renderPreamble() {
    return (
      <HBox gap={layout.padding.s} align='top'>
        <VBox flex>
          <WidgetFiltersBar
            {...props}
            output='report'
          />
        </VBox>
        {renderGenerateButton()}
      </HBox>
    )
  }

  function renderWidget() {
    if (data == null || data.length === 0) { return null }
    if (endpoint == null) { return null }

    return (
      <VBox flex={1} ref={widgetRef}>
        {renderDashboardWidget(widget, alotment, endpoint.state)}
      </VBox>
    )
  }

  function renderData() {
    if (data == null || data.length === 0) {
      return renderEmpty()
    }

    return (
      <DataGrid flex={2} data={data}>
        <DataGrid.Column<DataPoint>
          flex
          name='label'
          caption={widget.translate('label', t('report.label'))}
          renderCell={renderLabel}
        />
        {columnSeries.map(series => (
          <DataGrid.Column<DataPoint>
            flex
            key={series.name}
            name={series.name}
            caption={series.caption ?? widget.translate(`series.${series.name}`)}
            renderCell={renderValue.bind(null, series)}
          />
        ))}
      </DataGrid>
    )
  }

  function renderLabel(point: DataPoint) {
    if (point.label != null) {
      return widget.translate(`labels.${point.label}`, point.label)
    } else {
      return point.axisLabel ?? null
    }
  }

  function renderValue(series: DataSeries, point: DataPoint) {
    const value = point[series.valueKey] ?? 0
    if (state?.config.format != null) {
      return sprintf(state.config.format, value)
    } else {
      return value.toString()
    }
  }

  function renderEmpty() {
    return (
      <EmptyOrFetching
        icon='database'
        {...t(`report.${fetchStatus === 'idle' ? 'idle' : 'empty'}`)}
        status={fetchStatus}
        dim={fetchStatus === 'done'}
        flex
      />
    )
  }

  function renderGenerateButton() {
    return (
      <HBox gap={layout.padding.inline.m}>
        <PushButton
          icon='flash'
          caption={t('report.generate')}
          onTap={fetch}
          small
        />
        <PushButton
          icon='download'
          caption={t('report.download')}
          onTap={download}
          small
        />
      </HBox>
    )
  }

  return render()

})

export default WidgetReportDialog

const useStyles = createUseStyles(theme => ({
  WidgetReportDialog: {
  },
}))