import React from 'react'
import { useTranslation } from 'react-i18next'
import { Project } from '~/models'
import { ModelEndpoint, projectStore } from '~/stores'
import ProjectLogo from '~/ui/app/projects/ProjectLogo'
import { memo, observer } from '~/ui/component'
import { ListItem, SearchableList } from '~/ui/components'

export interface Props {
  endpoint:            ModelEndpoint<Project>
  onEndReached?:       () => any
  onDidSwitchProject?: (project: Project) => any
}

const ProjectList = observer('ProjectList', (props: Props) => {

  const {endpoint, onEndReached, onDidSwitchProject} = props

  const projects    = endpoint.data
  const fetchStatus = endpoint.fetchStatus

  const search = React.useCallback((search: string | null) => {
    endpoint.setParams({search})
  }, [endpoint])

  const [t] = useTranslation('project_switcher')

  const switchProject = React.useCallback((project: Project | null) => {
    if (project == null) { return }

    projectStore.switchProject(project.id)
    onDidSwitchProject?.(project)
  }, [onDidSwitchProject])

  //------
  // Rendering

  function render() {
    return (
      <SearchableList
        onSearch={search}

        data={projects}
        renderItem={renderProjectItem}

        flex='shrink'
        scrollable={true}
        onEndReached={onEndReached}

        fetchStatus={fetchStatus}
        emptyLabel={t('empty')}

        onKeyboardSelect={switchProject}
      />
    )
  }

  const renderProjectItem = React.useCallback((project: Project, _, selected: boolean) => (
    <ProjectItem
      project={project}
      switchProject={switchProject}
      selected={selected}
    />
  ), [switchProject])

  return render()

})

export default ProjectList

export interface ProjectItemProps {
  project:       Project
  switchProject: (project: Project) => any
  selected:      boolean
}

export const ProjectItem = memo('ProjectItem', (props: ProjectItemProps) => {

  const {project, switchProject, selected} = props
  const {name, logo, participantCount} = project

  const [t] = useTranslation('projects')

  function render() {
    return (
      <ListItem
        image={<ProjectLogo logo={logo}/>}
        caption={name}
        detail={t('projects:participant_count', {count: participantCount})}
        onTap={tap}
        selected={selected}
        spacious
      />
    )
  }

  const tap = React.useCallback(() => {
    switchProject(project)
  }, [project, switchProject])

  return render()

})