import React from 'react'
import { memo } from '~/ui/component'
import { Dimple, NumberField, SelectField, TextField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import renderParameterFieldset from './fieldsets/index'
import ParameterFormModel from './ParameterFormModel'

export type Props = FormDialogProps<ParameterFormModel>

const ParameterForm = memo('ParameterForm', (props: Props) => {

  const {t} = useResourceTranslation('modules')

  const {model: formModel} = props
  const title = t(`parameters.parameter_form.title.${formModel.isNew ? 'add' : 'edit'}`)

  const booleanDefaultChoices = React.useMemo((): Choice<boolean | null>[] => [
    {value: null, caption: t('parameters.boolean.default.null')},
    {value: false, caption: t('parameters.boolean.default.false')},
    {value: true, caption: t('parameters.boolean.default.true')},
  ], [t])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        icon='code'
        title={title}
        width={560}
        semi={false}
        {...props}
      >
        {renderPreamble()}
        {renderValidationOptions()}
      </FormDialog>
    )
  }

  function renderPreamble() {
    return (
      <VBox gap={layout.padding.m}>
        <FormField name='name' {...t('parameters.fields.name')} required>
          {bind => <TextField {...bind} mono/>}
        </FormField>
        <FormField name='default' {...t('parameters.fields.default')} required>
          {bind => formModel.type === 'string' ? (
            <TextField {...bind} mono showClearButton='notempty'/>
          ) : formModel.type === 'number' ? (
            <NumberField {...bind}/>
          ) : formModel.type === 'boolean' ? (
            <SelectField {...bind} choices={booleanDefaultChoices}/>
          ) : (
            <ResourceField {...bind} Model={formModel.TriggerableModel} filters={{tag: formModel.tag}}/>
          )}
        </FormField>
      </VBox>
    )
  }

  function renderValidationOptions() {
    const fieldset = renderParameterFieldset(formModel.parameter)
    if (fieldset == null) { return null }

    return (
      <VBox gap={layout.padding.m}>
        <Dimple horizontal/>
        {fieldset}
      </VBox>
    )
  }

  return render()

})

export default ParameterForm