import React from 'react'
import { IndexSort, ModelWithName, Page } from '~/models'
import { memo } from '~/ui/component'
import { DetailIndicator, PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { usePagesContext } from '../PagesContext'

export interface Props {
  page:  Page
  sorts: IndexSort[]
}

const SortIndicator = memo('SortIndicator', (props: Props) => {

  const {page, sorts} = props
  const {updatePage} = usePagesContext()

  const {t, fieldCaption} = useResourceTranslation('pages')

  const Model        = ModelWithName(page.itemType)
  const resourceI18n = useResourceTranslation(Model.resourceType)

  const setSort = React.useCallback((sort: string) => {
    updatePage(page.id, {sort: sort != null && sort === page.sort ? null : sort})
  }, [page.id, page.sort, updatePage])

  const menuItems = React.useMemo(
    () => sorts.map((sort): PopupMenuItem => ({
      caption: resourceI18n.fieldCaption(sort.name),
      checked: page.sort === sort.name || page.sort === `-${sort.name}`,
      children: [{
        value:   sort.name,
        checked: page.sort === sort.name,
        caption: t('index.sort_ascending'),
      }, {
        value:   `-${sort.name}`,
        checked: page.sort === `-${sort.name}`,
        caption: t('index.sort_descending'),
      }],
    }))
  , [page.sort, resourceI18n, sorts, t])

  const sortField     = page.sort?.startsWith('-') ? page.sort.slice(1) : page.sort
  const sortDirection = page.sort?.startsWith('-') ? -1 : 1

  const icon    = sortDirection === -1 ? 'sort-descending' : 'sort-ascending'
  const caption = sortField != null ? resourceI18n.fieldCaption(sortField) : fieldCaption('sort')

  //------
  // Rendering

  function render() {
    return (
      <DetailIndicator
        icon={icon}
        caption={caption}
        filled={page.sort != null}
        menuItems={menuItems}
        menuHeader={t('index.sort_by')}
        onValueSelect={setSort}
      />
    )
  }

  return render()

})

export default SortIndicator