import { DateTime } from 'luxon'
import {
  ImageMessageTemplate,
  Message,
  NoticeMessageTemplate,
  TextMessageTemplate,
  VideoMessageTemplate,
} from '~/models'

export type PendingMessage = PendingMessageTemplate & PendingMessageCommon

export interface PendingMessageCommon {
  id:      string
  replyTo: string | null
  channel: string
  status:  'pending' | 'error' | 'timeout'
  sentAt:  DateTime

  [key: string]: any
}

export type PendingMessageTemplate =
  | PendingSkipMessageTemplate
  | PendingTextMessageTemplate
  | PendingNoticeMessageTemplate
  | PendingImageMessageTemplate
  | PendingVideoMessageTemplate

export interface PendingSkipMessageTemplate extends PendingMessageCommon {
  type: 'skip'
}

export type PendingTextMessageTemplate   = TextMessageTemplate
export type PendingNoticeMessageTemplate = NoticeMessageTemplate
export type PendingImageMessageTemplate  = Omit<ImageMessageTemplate, 'image'> & {image: PendingMessageResource}
export type PendingVideoMessageTemplate  = Omit<VideoMessageTemplate, 'video'> & {video: PendingMessageResource}

export interface PendingMessageResource {
  filename: string
  mimeType: string
  binary:   Blob
}

export function isPendingMessage(message: Message | PendingMessage): message is PendingMessage {
  return !message.hasOwnProperty('timestamp')
}