import React from 'react'
import { useHotkey } from 'react-hotkeys'
import { PlanNode } from '~/models'
import { isConfigurableNode } from '~/stores'
import { memo } from '~/ui/component'
import { InfoIcon, KebabMenu, PopupMenuItem } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'
import { useCanvas } from '../canvas/FlowPlannerCanvasContext'
import { useFlowPlanner } from '../FlowPlannerContext'

export interface Props {
  node:        PlanNode
  extraItems?: PopupMenuItem[]
}

const NodeViewKebabMenu = memo('NodeViewKebabMenu', (props: Props) => {

  const {node, extraItems = []} = props

  return (
    <KebabMenu
      items={useNodeViewKebabMenuItems(node, extraItems)}
      small
    />
  )

})

export function useNodeViewKebabMenuItems(node: PlanNode, extraItems: PopupMenuItem[], handlerOverrides: NodeViewKebabMenuHandlerOverrides = {}) {

  const {planner}    = useFlowPlanner()
  const {setMode}    = useCanvas()
  const planModified = !!planner.service.modified

  const {colors} = useStyling()
  const {t, actionCaption} = useResourceTranslation('flow_planner')

  const [devItemsShown, showDevActions, hideDevActions] = useBoolean()
  useHotkey('Alt', {
    down: showDevActions,
    up:   hideDevActions,
  })

  //------
  // Actions

  const configure = React.useCallback(() => {
    if (!isConfigurableNode(node)) { return }

    planner.editComponent({
      type: 'node',
      uuid: node.uuid,
      node: node,
    })
  }, [node, planner])

  const setConnectMode = React.useCallback(() => {
    setMode('connect')
  }, [setMode])

  const duplicateNode = React.useCallback(() => {
    planner.duplicateComponents([node.uuid])
  }, [node, planner])

  const removeNode = React.useCallback(() => {
    planner.removeComponents([node.uuid])
  }, [node, planner])

  const activateNode = React.useCallback(() => {
    planner.setActivationNodeUUIDs([node.uuid])
  }, [node.uuid, planner])

  const renderActivateUnavailableIcon = React.useCallback(() => {
    return (
      <InfoIcon
        renderTooltip={t('nodes.activate_not_available')}
      />
    )
  }, [t])

  //------
  // Dev actions

  const showInsights = React.useCallback(() => {
    planner.setFocusedInsightsPosition({
      planID: '',
      node:   node.uuid,
      segue:  null,
    })
  }, [node.uuid, planner])

  //------
  // Items

  return React.useMemo((): PopupMenuItem[] => {
    const items: PopupMenuItem[] = []

    if (extraItems.length > 0) {
      items.push(...extraItems)
    }

    if (isConfigurableNode(node)) {
      items.push({
        icon:     'cog',
        caption:  t(`triggers.actions.configure_${node.type}`),
        onSelect: configure,
      })
    }

    items.push({section: '-'})

    items.push({
      icon:      'flash',
      caption:   t('nodes.actions.activate'),
      enabled:   !planModified,
      color:     colors.semantic.warning,
      keyHint:   !planModified ? 'A' : undefined,
      accessory: planModified ? renderActivateUnavailableIcon() : undefined,
      onSelect:  activateNode,
    })
    items.push({
      icon:     'connect',
      caption:  actionCaption('connect'),
      keyHint:  'C',
      onSelect: setConnectMode,
    })

    if (devItemsShown) {
      items.push({section: '-'})
      items.push({
        icon:    'eye',
        caption:  t('nodes.actions.show_insights'),
        onSelect: showInsights,
      })
    }

    items.push({section: '-'})

    items.push({
      icon:     'copy',
      caption:  t('nodes.actions.duplicate'),
      keyHint:  'Short+D',
      onSelect: duplicateNode,
    })

    items.push({
      icon:     'trash',
      caption:  t('nodes.actions.remove'),
      color:    colors.semantic.negative,
      keyHint:  'delete',
      onSelect: handlerOverrides.remove ?? removeNode,
    })

    return items
  }, [actionCaption, activateNode, colors.semantic.negative, colors.semantic.warning, configure, devItemsShown, duplicateNode, extraItems, handlerOverrides.remove, node, planModified, removeNode, renderActivateUnavailableIcon, setConnectMode, showInsights, t])

}

export interface NodeViewKebabMenuHandlerOverrides {
  remove?: () => any
}

export default NodeViewKebabMenu