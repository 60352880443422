import React from 'react'
import { useTranslation } from 'react-i18next'
import { PlanSegue } from '~/models'
import { FlowPlanner } from '~/stores'
import { TargetingForm, TargetingFormModel } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { SubmitResult } from '~/ui/form'

export interface Props {
  open:          boolean
  requestClose?: () => any
  formModel:     SegueTargetingFormModel
}

const SegueTargetingForm = memo('SegueTargetingForm', (props: Props) => {

  const {open, requestClose, formModel} = props

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  function render() {
    return (
      <TargetingForm
        open={open}
        requestClose={requestClose}
        model={formModel}
        instructions={t('segues.targeting.instructions')}
      />
    )
  }

  return render()

})

export default SegueTargetingForm

export class SegueTargetingFormModel extends TargetingFormModel {

  constructor(
    private readonly planner: FlowPlanner,
    private readonly segue: PlanSegue,
  ) {
    super(segue.targeting)
  }

  public async submit(): Promise<SubmitResult | undefined> {
    await this.planner.updateSegue(this.segue.uuid, segue => ({
      ...segue,
      targeting: this.buildTargeting(),
    }))
    return {status: 'ok'}
  }

}