import { pick, some, uniq } from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable } from 'mobx'
import * as UUID from 'uuid'
import { offsetBounds, rectsIntersect, resizeBoundsBy, resizeBoundsTo } from 'ytil'
import { ClipboardPlanComponent } from '~/clipboard'
import config from '~/config'
import {
  EntryNode,
  ExitNode,
  FlowPlan,
  getTriggerableID,
  isAnnotation,
  isCollectiveTrigger,
  isCompleteableTriggerable,
  isNode,
  isSegue,
  Module,
  ModuleNode,
  PlanAnnotation,
  PlanComponent,
  PlanComponentWithBounds,
  PlanNode,
  PlanNodeConnector,
  PlanSegue,
  PlanSegueConnection,
  PlanSegueDelay,
  SegueDelay,
  Targeting,
  Triggerable,
  TriggerableNode,
} from '~/models'
import { dataStore, FlowPlannerService } from '~/stores'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'
import {
  ComponentBounds,
  ContentTypeOf,
  EditableComponent,
  FlowPlanRuleResult,
  PositionLocator,
  SegueDetail,
} from '../types'
import { ActivateNodeOptions } from './FlowPlannerService'
import ZStack from './ZStack'

export default class FlowPlanner {

  constructor(
    public readonly service: FlowPlannerService,
  ) {
    makeObservable(this)
  }

  // Assigned by FlowPlannerContext.
  @observable.ref
  public plan: FlowPlan | null = null

  @action
  public setPlan(plan: FlowPlan | null) {
    this.plan = plan
  }

  //------
  // Components

  @computed
  public get zStack() {
    return new ZStack(this.plan?.zStack ?? [])
  }

  @computed
  public get zOrderedComponents() {
    if (this.plan == null) { return [] }

    const annotations = this.plan.annotations ?? []
    const nodes       = this.plan.nodes ?? []

    const segues     = this.zStack.sortByZOrder(this.plan.segues ?? [])
    const components = this.zStack.sortByZOrder([...nodes, ...annotations])
    return [...segues, ...components]
  }

  @observable
  private loadingComponents = new Set<string>()

  public isLoadingComponent(uuid: string) {
    return this.loadingComponents.has(uuid)
  }

  @action
  public setLoadingComponent(uuid: string, loading: boolean) {
    if (loading) {
      this.loadingComponents.add(uuid)
    } else {
      this.loadingComponents.delete(uuid)
    }
  }

  //------
  // Component editing

  @observable
  public editingComponent: EditableComponent | null = null

  @action
  public editComponent(component: EditableComponent) {
    if (this.plan == null) { return }
    this.editingComponent = component
  }

  @action
  public stopEditingComponent() {
    if (this.plan == null) { return }
    this.editingComponent = null
  }

  //------
  // Trigger details

  @observable
  public detailViewNodeUUID: string | null = null

  @action
  public viewNodeDetail(nodeUUID: string | null) {
    this.detailViewNodeUUID = nodeUUID
  }

  //------
  // Segue menu & editing

  @observable
  public focusedSegue: PlanSegue | null = null

  @observable
  public segueMenuPoint: Point | null = null

  @observable
  public segueOutletMenu: boolean = false

  @observable
  public segueDetail: SegueDetail = 'targeting'

  @action
  public openSegueMenu(segue: PlanSegue, point: Point, outlet: boolean = false) {
    this.focusedSegue    = segue
    this.segueMenuPoint  = point
    this.segueOutletMenu = outlet
    this.zStack.makeTopMost([segue.uuid])
  }

  @action
  public closeSegueMenu() {
    this.focusedSegue = null
    this.segueMenuPoint = null
    this.segueOutletMenu = false
  }

  @action
  public editSegueDetail(segue: PlanSegue, detail: SegueDetail) {
    if (this.plan == null) { return }
    this.segueDetail = detail
    this.editingComponent = {type: 'segue', uuid: segue.uuid, segue}
  }

  //------
  // Node creation / update

  public createNode(node: PlanNode) {
    if (this.plan == null) { return }

    this.setLoadingComponent(node.uuid, true)

    return this.service.update({
      nodes: [
        ...this.plan?.nodes ?? [],
        node,
      ],
      zStack: [
        ...this.zStack.uuids,
        node.uuid,
      ],
    })
  }

  public createAnnotation(annotation: PlanAnnotation) {
    if (this.plan == null) { return }

    this.setLoadingComponent(annotation.uuid, true)

    return this.service.update({
      annotations: [
        ...this.plan.annotations,
        annotation,
      ],
    })
  }

  public updateComponents<C extends ContentTypeOf<EditableComponent>>(uuids: string[], update: (content: C) => Partial<C>) {
    if (this.plan == null) { return }
    const updateAnnotation  = (a: PlanAnnotation) => uuids.includes(a.uuid) ? {...a, ...update(a as any)} : a
    const updateTriggerable = (t: Triggerable)    => uuids.includes(getTriggerableID(t)) ? {...t, ...update(t as any)} : t
    const updateNode        = (node: PlanNode)    => uuids.includes(node.uuid) ? {...node, ...update(node as any)} : node

    const data: Partial<FlowPlan> = {
      nodes: this.plan.nodes.map(node => {
        if (node.type === 'triggerable') {
          return {...node, triggerables: node.triggerables.map(updateTriggerable)}
        } else {
          return updateNode(node)
        }
      }),
      annotations: this.plan.annotations.map(updateAnnotation),
    }

    return this.service.update(data)
  }

  public getClipboardComponents(uuids: string[]): ClipboardPlanComponent[] {
    const components: ClipboardPlanComponent[] = []
    uuids.forEach(uuid => {
      const node = this.plan?.findNode(uuid)
      if (node != null) {
        components.push(node)
        const segues = this.plan
          ?.findSeguesFrom(uuid)
          .filter(segue => uuids.includes(segue.to.node))
        if (segues != null) {
          components.push(...segues.map(segue => ({
            ...segue,
            delay: SegueDelay.serialize(segue.delay),
          })))
        }
      }
      const annotation = this.plan?.findAnnotation(uuid)
      if (annotation != null) {
        components.push(annotation)
      }
    })
    return components
  }

  public async pasteComponents(components: ClipboardPlanComponent[]) {
    if (this.plan == null) { return }

    const nodes:       PlanNode[] = []
    const annotations: PlanAnnotation[] = []
    const segues:      PlanSegue[] = []
    const zStack:      string[] = []

    const uuids: Map<string, string> = new Map(
      components.map(c => [c.uuid, UUID.v4()]),
    )

    for (const [originalUUID, newUUID] of uuids) {
      const component = components.find(c => c.uuid === originalUUID)
      if (component == null) { continue }

      if ('to' in component && 'from' in component) {
        const fromNode = uuids.get(component.from.node)
        const toNode   = uuids.get(component.to.node)
        if (fromNode != null && toNode != null) {
          const delay = component.delay != null
            ? SegueDelay.deserialize(component.delay) as PlanSegueDelay
            : null

          segues.push({
            ...component,
            uuid:  newUUID,
            delay: delay,
            from:  {...component.from, node: fromNode},
            to:    {...component.to, node: toNode},
          })
        }
      } else if (isNode(component)) {
        if (component.type === 'triggerable') {
          nodes.push({
            ...component,
            uuid: newUUID,
            triggerables: component.triggerables.map(it => it.type === 'action' ? {...it, uuid: UUID.v4()} : {...it}),
          })
        } else if (component.type === 'trigger' && component.trigger.type === 'date') {
          nodes.push({
            ...component,
            trigger: {
              ...component.trigger,
              date: DateTime.fromISO(component.trigger.date as any),
            },
            uuid: newUUID,
          })
        } else {
          nodes.push({...component, uuid: newUUID})
        }
      } else if (isAnnotation(component)) {
        annotations.push({...component, uuid: newUUID})
      }
      zStack.push(newUUID)
    }

    const update: Partial<FlowPlan> = {
      nodes:       [...this.plan.nodes, ...nodes],
      annotations: [...this.plan.annotations, ...annotations],
      segues:      [...this.plan.segues, ...segues],
      zStack:      [...this.plan.zStack, ...zStack],
    }

    const result = await this.service.update(update)
    return result.status === 'ok' ? [...nodes.map(n => n.uuid), ...annotations.map(a => a.uuid)] : []
  }

  public async duplicateComponents(uuids: string[]) {
    if (this.plan == null) { return }

    const newBounds = new Map()
    for (const uuid of uuids) {
      const component = this.plan.findNode(uuid) ?? this.plan.findAnnotation(uuid)
      if (component == null) { continue }

      newBounds.set(uuid, {
        top:    component.bounds.top + 4 * config.planner.gridSize,
        left:   component.bounds.left + 2 * config.planner.gridSize,
        width:  component.bounds.width,
        height: component.bounds.height,
      })
    }

    const [data, newUUIDS] = this.copyComponentsImpl(newBounds)
    if (data === false) { return [] }

    const result = await this.service.update(data)
    return result.status === 'ok' ? newUUIDS : []
  }

  public async removeComponents(uuids: string[]) {
    if (this.plan == null) { return }

    const {nodes, segues, annotations} = this.removeComponentsImpl(uuids)

    this.zStack.remove(uuids)
    this.zStack.removeUnused(this.componentBounds.map(bounds => bounds.component.uuid))
    return this.service.update({nodes, segues, annotations, zStack: this.zStack.uuids})
  }

  private removeComponentsImpl(uuids: string[]) {
    if (this.plan == null) {
      return {nodes: [], segues: [], annotations: []}
    }

    const nodes: PlanNode[] = []
    const removedNodeUUIDs: string[] = []

    for (const node of this.plan!.nodes) {
      if (uuids.includes(node.uuid)) {
        removedNodeUUIDs.push(node.uuid)
      } else {
        nodes.push(node)
      }
    }

    const segues = this.plan.segues.filter(segue => {
      if (uuids.includes(segue.uuid)) { return false }
      if (removedNodeUUIDs.includes(segue.from.node)) { return false }
      if (removedNodeUUIDs.includes(segue.to.node)) { return false }

      return true
    })

    const annotations = this.plan.annotations
      .filter(annotation => !uuids.includes(annotation.uuid))

    return {nodes, segues, annotations}
  }

  public async removeTriggerable(nodeUUID: string, uuid: string) {
    if (this.plan == null) { return }

    const node = this.plan.findNode(nodeUUID)
    if (node == null || node.type !== 'triggerable') { return }

    const hasTriggerable = some(node.triggerables, t => getTriggerableID(t) === uuid)
    if (!hasTriggerable) { return }

    if (node.triggerables.length === 1) {
      // Remove the entire node.
      return this.removeComponents([nodeUUID])
    } else {
      // Remove the triggerable from the node.
      const data: Partial<FlowPlan> = {
        nodes: this.plan.nodes.map(node => {
          if (node.uuid !== nodeUUID) { return node }

          return {
            ...node,
            triggerables: (node as TriggerableNode).triggerables.filter(t => getTriggerableID(t) !== uuid),
          }
        }),
      }

      return this.service.update(data)
    }
  }

  public async configureModuleNode(uuid: string, update: Pick<ModuleNode, 'name' | 'params'>) {
    return await this.service.configureModuleNode(uuid, update)
  }

  //------
  // Segues

  public async createSegue(segue: PlanSegue) {
    if (this.plan == null) { return }
    if (segue.from.node === segue.to.node) { return }

    await this.service.update({
      segues: [
        ...this.plan.segues,
        segue,
      ],
    })

    return segue
  }

  public async updateSegue(uuid: string, update: (segue: PlanSegue) => PlanSegue) {
    if (this.plan == null) { return }
    const segue = this.plan.findSegue(uuid)
    if (segue == null) { return }

    const result = await this.service.update({
      segues: this.plan.segues.map(seg => {
        if (seg.uuid === uuid) {
          return update(seg)
        } else {
          return seg
        }
      }),
    })

    return translateFormErrorPaths(result, it => it.replace(/^segues\.\d+\./, ''))
  }

  @observable
  public segueConnectionsOverrides: SegueOverride[] = []

  @action
  public redirectSegues(overrides: SegueOverride[]) {
    if (this.plan == null) { return }
    this.segueConnectionsOverrides = overrides
  }

  @action
  public cancelRedirectSegues() {
    if (this.plan == null) { return }
    this.segueConnectionsOverrides = []
  }

  @action
  public async commitRedirectSegues() {
    if (this.plan == null) { return }
    let updated: boolean = false

    const nextSegues = this.plan.segues.map(segue => {
      const override = this.segueConnectionsOverrides.find(override => override.uuid === segue.uuid)
      if (override == null) { return segue }
      if (override.from != null && 'x' in override.from) { return segue }
      if (override.to != null && 'x' in override.to) { return segue }

      const nextSegue = {
        ...segue,
        from: override.from ?? segue.from,
        to:   override.to ?? segue.to,
      }

      if (nextSegue.from.node === nextSegue.to.node) {
        return segue
      } else {
        updated = true
        return nextSegue
      }
    })

    try {
      if (updated) {
        return await this.service.update({segues: nextSegues})
      }
    } finally {
      this.segueConnectionsOverrides = []
    }
  }

  @computed
  public get segueConnections() {
    const connections: Record<string, {from: PlanSegueConnection | Point, to: PlanSegueConnection | Point}> = {}
    for (const segue of this.plan?.segues ?? []) {
      connections[segue.uuid] = {
        ...pick(segue, 'from', 'to'),
        ...this.segueConnectionsOverrides.find(override => override.uuid === segue.uuid),
      }
    }
    return connections
  }

  public segueIsPartOfCollectiveFlow(segue: PlanSegue): boolean {
    const node = this.plan?.findNode(segue.from.node)
    if (node == null) { return false }

    return this.nodeIsPartOfCollectiveFlow(node)
  }

  public nodeIsPartOfCollectiveFlow(node: PlanNode): boolean {
    if (this.plan == null) { return false }

    if (node.type === 'trigger') {
      // Trigger node -> check trigger type.
      return isCollectiveTrigger(node.trigger)
    } else if (node.type === 'entry') {
      // Entry node -> check whether this flow plan is marked for collective activation (quite rare).
      return this.plan?.collective ?? false
    } else if (node.type === 'triggerable' && some(node.triggerables, isCompleteableTriggerable)) {
      // Triggerable node with completeable triggerables, the flow will be individual from here on out.
      return false
    }

    // If we get here, the node itself does not provide enough information. We need to follow all segues. If *any* of them
    // have a collective flow, the node as a whole is part of a collective flow.
    const segues = this.plan.findSeguesTo(node.uuid)
    return some(segues, it => this.segueIsPartOfCollectiveFlow(it))
  }

  //------
  // Exits

  public outletsForNode(node: PlanNode): Array<string | null> {
    if (this.plan == null) { return [] }
    switch (node.type) {
      case 'triggerable':
        return ['completed', 'abandoned', null]
      case 'first':
        return ['selected', 'rest', null]
      case 'sample':
        return ['selected', 'rest', null]
      case 'module':
        return this.outletsForModule(node.module)
      default:
        return [null]
    }
  }

  private outletsForModule(moduleID: string): Array<string | null> {
    const document = dataStore.document(Module, moduleID)
    return document.meta?.results ?? []
  }

  public needsResult(node: PlanNode) {
    switch (node.type) {
      case 'first': case 'sample':
        return true
      case 'module':
        return some(this.outletsForModule(node.module), result => result != null)
      default:
        return false
    }
  }

  //------
  // Insights

  @computed
  public get insights() {
    return this.service.insights ?? {
      summaries:    [],
      participants: [],
    }
  }

  @observable
  public activationNodeUUIDs: string[] = []

  @action
  public setActivationNodeUUIDs(uuids: string[]) {
    if (this.plan == null) { return }
    this.activationNodeUUIDs = uuids
  }

  public activateNodes(uuids: string[], participantIDs: Array<string | null>, options: ActivateNodeOptions = {}) {
    if (this.plan == null) { return }
    return this.service.activateNodes(uuids, participantIDs, options)
  }

  @observable
  public focusedInsightsPosition: PositionLocator | null = null

  @action
  public setFocusedInsightsPosition(node: PositionLocator | null) {
    this.focusedInsightsPosition = node
  }

  //------
  // Module extraction

  public canExtractModule(components: PlanComponent[]) {
    if (this.plan == null) { return false }

    // A module can only be extracted if there is at most one node receiving segues from outside the selection,
    // and at most one node having segues going out.
    const uuids    = components.filter(isNode).map(it => it.uuid)
    const incoming = this.plan.segues.filter(it => !uuids.includes(it.from.node) && uuids.includes(it.to.node))
    const outgoing = this.plan.segues.filter(it => uuids.includes(it.from.node) && !uuids.includes(it.to.node))

    const incomingNodes = uniq(incoming.map(it => it.to.node))
    const outgoingNodes = uniq(outgoing.map(it => it.to.node))
    return incomingNodes.length <= 1 && outgoingNodes.length <= 1
  }

  public async extractModule(name: string, components: PlanComponent[]): Promise<SubmitResult | undefined> {
    if (!this.canExtractModule(components)) { return }

    let result: SubmitResult

    // First create the module.
    result = await dataStore.create(Module, {name, plannerType: 'flow'})
    if (result.status !== 'ok') { return result }
    if (result.data?.id == null) { return }

    const data = this.extractModuleComponents(components, result.data.id)
    if (data === false) { return }

    // Then create the new plan. Find all segues.
    result = await dataStore.create(FlowPlan, {
      module: result.data.id,
      ...FlowPlan.serializePartial(data.module),
    })
    if (result.status !== 'ok') { return result }

    // Finally, update the current flow plan by replacing the components with a module node.
    return await this.service.update(data.remaining)
  }

  private extractModuleComponents(components: PlanComponent[], moduleID: string) {
    if (this.plan == null) { return false }

    const moduleNodes       = components.filter(isNode)
    const moduleAnnotations = components.filter(isAnnotation)

    const uuids        = moduleNodes.map(it => it.uuid)
    const incoming     = this.plan.segues.filter(it => !uuids.includes(it.from.node) && uuids.includes(it.to.node))
    const outgoing     = this.plan.segues.filter(it => uuids.includes(it.from.node) && !uuids.includes(it.to.node))
    const moduleSegues = this.plan.segues.filter(it => uuids.includes(it.from.node) && uuids.includes(it.to.node))

    const remainingNodes       = this.plan.nodes.filter(it => !moduleNodes.includes(it))
    const remainingSegues      = this.plan.segues.filter(it => !moduleSegues.includes(it) && !incoming.includes(it) && !outgoing.includes(it))
    const remainingAnnotations = this.plan.annotations.filter(it => !moduleAnnotations.includes(it))

    // Find the 'entry' and 'exit' nodes of the module.
    const entry = moduleNodes.find(node => some(incoming, it => it.to.node === node.uuid)) ?? null
    const exit  = moduleNodes.find(node => some(outgoing, it => it.from.node === node.uuid)) ?? null

    // Add a node to the module to the remaining nodes.
    const top  = Math.min(...[...moduleNodes, ...moduleAnnotations].map(it => it.bounds.top))
    const left = Math.min(...[...moduleNodes, ...moduleAnnotations].map(it => it.bounds.left))

    const size = config.planner.defaultComponentSize('module')
    const moduleNode: ModuleNode = {
      type:   'module',
      uuid:   UUID.v4(),
      module: moduleID,
      name:   null,
      params: {},
      bounds: entry?.bounds ?? {top, left, ...size},
    }
    remainingNodes.push(moduleNode)

    // If found, create an actual entry node in the module and reroute segues to it

    if (entry != null) {
      const size = config.planner.defaultComponentSize('entry')

      const entryNodeBounds = {
        top:  entry.bounds.top - size.height - 2 * config.planner.gridSize,
        left: entry.bounds.left + entry.bounds.width / 2 - size.width / 2,
        ...size,
      }

      const entryNode: EntryNode = {
        uuid:   UUID.v4(),
        type:   'entry',
        name:   null,
        bounds: entryNodeBounds,
      }

      moduleNodes.unshift(entryNode)

      for (const segue of incoming) {
        remainingSegues.push({
          ...segue,
          to: {...segue.to, node: moduleNode.uuid},
        })

        moduleSegues.push({
          uuid:       UUID.v4(),
          from:       {node: entryNode.uuid, connector: PlanNodeConnector.S},
          to:         {node: segue.to.node, connector: PlanNodeConnector.N},
          outlet:     null,
          targeting:  Targeting.empty(),
          conditions: [],
          delay:      null,
        })
      }
    }

    if (exit != null) {
      const moduleBounds = {
        left:   Math.min(...moduleNodes.map(it => it.bounds.left)),
        right:  Math.max(...moduleNodes.map(it => it.bounds.left + it.bounds.width)),
        bottom: Math.max(...moduleNodes.map(it => it.bounds.top + it.bounds.height)),
      }

      const size = config.planner.defaultComponentSize('exit')
      const exitNodeBounds = {
        top:  moduleBounds.bottom + 2 * config.planner.gridSize,
        left: Math.round((moduleBounds.left + (moduleBounds.right - moduleBounds.left) / 2 - size.width / 2) / config.planner.gridSize) * config.planner.gridSize,
        ...size,
      }

      const exitNode: ExitNode = {
        uuid:   UUID.v4(),
        type:   'exit',
        name:   null,
        outlet: null,
        bounds: exitNodeBounds,
      }

      moduleNodes.unshift(exitNode)

      for (const segue of outgoing) {
        remainingSegues.push({
          ...segue,
          from: {...segue.from, node: moduleNode.uuid},
        })

        moduleSegues.push({
          uuid:       UUID.v4(),
          from:       {node: segue.from.node, connector: PlanNodeConnector.S},
          to:         {node: exitNode.uuid, connector: PlanNodeConnector.N},
          outlet:     null,
          targeting:  Targeting.empty(),
          conditions: [],
          delay:      null,
        })
      }
    }

    return {
      module: {
        nodes:       moduleNodes,
        segues:      moduleSegues,
        annotations: moduleAnnotations,
      },
      remaining: {
        nodes:       remainingNodes,
        segues:      remainingSegues,
        annotations: remainingAnnotations,
      },
    }
  }

  //------
  // Component bounds

  @computed
  public get componentBounds(): ComponentBounds[] {
    if (this.plan == null) { return [] }

    return [...this.plan.nodes, ...this.plan.annotations].map(component => ({
      component: component,
      bounds:    this.getComponentBounds(component),
    }))
  }

  public getComponentBounds(component: PlanComponentWithBounds) {
    if (this.moveMode === 'copy') {
      return component.bounds
    } else {
      return this.componentBoundsOverrides.get(component.uuid) ?? component.bounds
    }
  }

  @computed
  public get boundingRectangle() {
    let left: number | null   = null
    let top: number | null    = null
    let right: number | null  = null
    let bottom: number | null = null

    for (const {bounds} of this.componentBounds) {
      if (left == null || bounds.left < left) {
        left = bounds.left
      }
      if (top == null || bounds.top < top) {
        top = bounds.top
      }
      if (right == null || (bounds.left + bounds.width) > right) {
        right = bounds.left + bounds.width
      }
      if (bottom == null || (bounds.top + bounds.height) > bottom) {
        bottom = bounds.top + bounds.height
      }
    }

    return {
      left:   left!,
      top:    top!,
      width:  right! - left!,
      height: bottom! - top!,
    }
  }

  //------
  // Moving & grouping

  @observable
  public componentBoundsOverrides = new Map<string, LayoutRect>()

  @observable
  public groupTriggerablePreviewUUID: string | null = null

  @observable
  public moveMode: 'copy' | 'move' = 'move'

  @action
  public setMoveMode(moveMode: 'copy' | 'move') {
    this.moveMode = moveMode
  }

  @action
  public moveComponents(uuids: string[], delta: Point) {
    if (this.plan == null) { return }

    for (const uuid of uuids) {
      const node = this.plan.findComponent(uuid)
      if (node == null || isSegue(node)) { continue }

      const override  = offsetBounds(node.bounds, delta)
      override.left   = this.roundToGrid(override.left)
      override.top    = this.roundToGrid(override.top)
      override.width  = this.roundToGrid(override.width)
      override.height = this.roundToGrid(override.height)

      this.componentBoundsOverrides.set(uuid, override)
    }

    this.zStack.makeTopMost(uuids)
  }

  @action
  public async addTriggerablesFromNodesToNode(sourceNodeUUIDs: string[], targetNodeUUID: string) {
    if (this.plan == null) { return }
    // Remove all selected triggerables first, as we're moving.
    const {nodes, segues} = this.removeComponentsImpl(sourceNodeUUIDs)

    // Find the target node.
    const targetNode = nodes.find(node => node.uuid === targetNodeUUID)
    if (targetNode == null || targetNode.type !== 'triggerable') { return }

    // Add the triggerables to the target node.
    for (const uuid of sourceNodeUUIDs) {
      const node = this.plan.nodes.find(node => node.uuid === uuid)
      if (node == null || node.type !== 'triggerable') { continue }

      targetNode.triggerables.push(...node.triggerables)
    }

    // Update the size.
    const size = config.planner.defaultComponentSize('triggerable')
    targetNode.bounds.height = targetNode.triggerables.length * size.height

    const result = await this.service.update({nodes, segues})

    // Roll back any temporary component bounds.
    this.rollbackComponentBounds()
    return result
  }

  @action
  public async detachTriggerableFromGroup(nodeUUID: string, uuid: string) {
    if (this.plan == null) { return }

    const triggerable = this.plan.findTriggerable(uuid)
    const node        = this.plan.findNode(nodeUUID)
    if (triggerable == null || node == null) { return null }

    const size   = config.planner.defaultComponentSize('triggerable')
    const height = size.height

    const nodes = this.plan.nodes.map(node => {
      if (node.type !== 'triggerable' || node.uuid !== nodeUUID) { return node }

      const triggerables = node.triggerables.filter(t => getTriggerableID(t) !== uuid)
      const height       = triggerables.length * size.height
      const bounds       = {...node.bounds, height}

      return {...node, triggerables, bounds}
    })

    const bounds = {
      left:   node.bounds.left,
      top:    node.bounds.top + node.bounds.height + config.planner.gridSize - height,
      width:  node.bounds.width,
      height: height,
    }

    const newNode: TriggerableNode = {
      type:         'triggerable',
      uuid:         UUID.v4(),
      name:         null,
      triggerables: [triggerable],
      bounds:       bounds,
    }

    return this.service.update({
      nodes: [...nodes, newNode],
    })
  }

  @action
  public setGroupTriggerablePreview(uuid: string | null) {
    if (this.plan == null) { return }
    this.groupTriggerablePreviewUUID = uuid
  }

  public findTouchingComponent(bounds: LayoutRect, options: FindTouchingNodeOptions = {}) {
    if (this.plan == null) { return }
    const expanded = {
      left:   bounds.left - 1,
      top:    bounds.top - 1,
      width:  bounds.width + 2,
      height: bounds.height + 2,
    }

    for (const other of this.componentBounds) {
      if (options.exclude != null && options.exclude.includes(other.component.uuid)) { continue }
      if (options.types != null && !options.types.includes(other.component.type)) { continue }

      if (rectsIntersect(expanded, other.bounds)) {
        return other.component
      }
    }

    return null
  }

  //-------
  // Bounds

  @action
  public resizeComponentBy(uuid: string, handlePoint: Point, delta: Point) {
    if (this.plan == null) { return }
    const component = this.plan.findComponent(uuid)
    if (component == null || isSegue(component)) { return }

    const minSize = config.planner.minimumComponentSize(component.type)
    const override = resizeBoundsBy(component.bounds, handlePoint, delta, {
      roundTo:       config.planner.gridSize,
      minimumWidth:  minSize.width,
      minimumHeight: minSize.height,
    })

    this.componentBoundsOverrides.set(uuid, override)
  }

  @action
  public resizeComponentTo(uuid: string, handlePoint: Point, size: Size) {
    if (this.plan == null) { return }
    const component = this.plan.findComponent(uuid)
    if (component == null || isSegue(component)) { return }

    const minSize  = config.planner.minimumComponentSize(component.type)
    const override = resizeBoundsTo(component.bounds, handlePoint, size, {
      roundTo:       config.planner.gridSize,
      minimumWidth:  minSize.width,
      minimumHeight: minSize.height,
    })

    this.componentBoundsOverrides.set(uuid, override)
  }

  @action
  public rollbackComponentBounds() {
    this.setGroupTriggerablePreview(null)
    this.componentBoundsOverrides.clear()
    this.moveMode = 'move'
  }

  @action
  public async commitComponentBounds() {
    const [data, uuids] = this.moveMode === 'move'
      ? this.moveComponentsImpl()
      : this.copyComponentsImpl()
    if (data === false) { return }

    const result = await this.service.update(data)
    this.rollbackComponentBounds()

    return result.status === 'ok' ? uuids : []
  }

  private moveComponentsImpl(): [Partial<FlowPlan> | false, string[]] {
    if (this.plan == null) { return [false, []] }

    let updated = false

    let segues = [...this.plan.segues]

    const maybeMoveSegueToCenterConnector = (segueUUID: string, bounds: LayoutRect, which: 'from' | 'to') => {
      segues = segues.map(segue => {
        if (segue.uuid !== segueUUID) { return segue }

        const tooNarrow = bounds.width < config.planner.minSizeForThreeConnectors
        const tooShort  = bounds.height < config.planner.minSizeForThreeConnectors
        if (!tooNarrow && !tooShort) { return segue }

        let connector = segue[which].connector
        if (tooNarrow && [PlanNodeConnector.NNW, PlanNodeConnector.NNE].includes(connector)) {
          connector = PlanNodeConnector.N
        }
        if (tooNarrow && [PlanNodeConnector.SSW, PlanNodeConnector.SSE].includes(connector)) {
          connector = PlanNodeConnector.S
        }
        if (tooShort && [PlanNodeConnector.ENE, PlanNodeConnector.ESE].includes(connector)) {
          connector = PlanNodeConnector.E
        }
        if (tooShort && [PlanNodeConnector.WNW, PlanNodeConnector.WSW].includes(connector)) {
          connector = PlanNodeConnector.W
        }

        return {...segue, [which]: {...segue[which], connector}}
      })
    }

    const mapComponent = <C extends Exclude<PlanComponent, PlanSegue>>(component: C): C => {
      const override = this.componentBoundsOverrides.get(component.uuid)
      if (override == null) { return component }

      updated = true

      for (const segue of this.plan?.findSeguesFrom(component.uuid) ?? []) {
        maybeMoveSegueToCenterConnector(segue.uuid, override, 'from')
      }
      for (const segue of this.plan?.findSeguesTo(component.uuid) ?? []) {
        maybeMoveSegueToCenterConnector(segue.uuid, override, 'to')
      }

      return {...component, bounds: override}
    }

    const data = {
      nodes:       this.plan.nodes.map(mapComponent),
      segues:      segues,
      annotations: this.plan.annotations.map(mapComponent),
      zStack:      this.zStack.uuids,
    }

    if (updated) {
      return [data, Array.from(this.componentBoundsOverrides.keys())]
    } else {
      return [false, []]
    }
  }

  private copyComponentsImpl(newBounds: Map<string, LayoutRect> = this.componentBoundsOverrides): [Partial<FlowPlan> | false, string[]] {
    if (this.plan == null) { return [false, []] }
    if (newBounds.size === 0) { return [false, []] }

    const nodes:       PlanNode[] = []
    const annotations: PlanAnnotation[] = []
    const segues:      PlanSegue[] = []
    const zStack:      string[] = []

    const uuids: Map<string, string> = new Map()

    for (const [uuid, bounds] of newBounds) {
      const node = this.plan.findNode(uuid)
      if (node != null) {
        const newUUID = UUID.v4()

        if (node.type === 'triggerable') {
          nodes.push({
            ...node,
            triggerables: node.triggerables.map(it => it.type === 'action' ? {...it, uuid: UUID.v4()} : {...it}),
            uuid: newUUID,
            bounds,
          })

        } else {
          nodes.push({...node, uuid: newUUID, bounds})
        }
        uuids.set(uuid, newUUID)
        zStack.push(newUUID)
      }

      const annotation = this.plan.findAnnotation(uuid)
      if (annotation != null) {
        const newUUID = UUID.v4()
        annotations.push({...annotation, uuid: newUUID, bounds})
        zStack.push(newUUID)
      }
    }

    for (const [originalUUID, copyUUID] of uuids) {
      this.plan.findSeguesFrom(originalUUID).forEach(segue => {
        const fromNode = copyUUID
        const toNode   = uuids.get(segue.to.node)

        if (toNode != null) {
          const newUUID = UUID.v4()
          segues.push({
            ...segue,
            uuid: newUUID,
            from: {...segue.from, node: fromNode},
            to: {...segue.to, node: toNode},
          })
          zStack.push(newUUID)
        }
      })
    }

    return [
      {
        nodes:       [...this.plan.nodes, ...nodes],
        annotations: [...this.plan.annotations, ...annotations],
        segues:      [...this.plan.segues, ...segues],
        zStack:      [...this.plan.zStack, ...zStack],
      },
      [...nodes.map(node => node.uuid), ...annotations.map(annotation => annotation.uuid)],
    ]
  }

  private autoSizeHandlers = new Map<string, (handlePoint: Point) => any>()

  @action
  public requestAutoSize(uuid: string, handlePoint: Point) {
    if (this.plan == null) { return }
    this.autoSizeHandlers.get(uuid)?.(handlePoint)
  }

  public addAutoSizeHandler(uuid: string, handler: (handlePoint: Point) => any) {
    if (this.plan == null) { return }
    this.autoSizeHandlers.set(uuid, handler)
    return () => {
      this.autoSizeHandlers.delete(uuid)
    }
  }

  public roundToGrid(val: number, ceil: boolean = false) {
    if (ceil) {
      return Math.ceil(val / config.planner.gridSize) * config.planner.gridSize
    } else {
      return Math.round(val / config.planner.gridSize) * config.planner.gridSize
    }
  }

  //------
  // Rules

  @observable
  public highlightedRuleResult: FlowPlanRuleResult | null = null

  @computed
  public get silencedRules() {
    return this.service.silencedRules
  }

  @computed
  public get ruleResults() {
    return this.service.rules.filter(result => !this.silencedRules.includes(result.hash))
  }

  @action
  public silenceRule(hash: string) {
    this.service.silenceRule(hash)
  }

  @action
  public highlightRuleResult(result: FlowPlanRuleResult) {
    this.highlightedRuleResult = result
  }

  @action
  public unhighlightRuleResult(result: FlowPlanRuleResult) {
    if (this.highlightedRuleResult !== result) { return }
    this.highlightedRuleResult = null
  }

  public isNodeHighlightedForRuleResult(uuid: string) {
    if (this.highlightedRuleResult == null) { return false }
    return this.highlightedRuleResult.nodes.includes(uuid)
  }

  public isTriggerableHighlightedForRuleResult(uuid: string) {
    if (this.highlightedRuleResult == null) { return false }
    return this.highlightedRuleResult.triggerables.includes(uuid)
  }

  public isSegueHighlightedForRuleResult(uuid: string) {
    if (this.highlightedRuleResult == null) { return false }
    return this.highlightedRuleResult.segues.includes(uuid)
  }

}

export interface SegueOverride {
  uuid:  string
  from?: PlanSegueConnection | Point
  to?:   PlanSegueConnection | Point
}

export interface FindTouchingNodeOptions {
  exclude?: string[]
  types?:   Array<PlanComponentWithBounds['type']>
}