import React from 'react'
import { useTimer } from 'react-timer'
import { ModelClass } from '~/models'
import { dataStore } from '~/stores'

export function useTags(Model: ModelClass<any>) {

  const [tags, setTags] = React.useState<string[] | null>(null)

  const timer = useTimer()

  React.useEffect(() => {
    dataStore.getTags(Model)
      .then(tags => {
        if (!timer.isDisposed) {
          setTags(tags)
        }
      })
  }, [Model, timer.isDisposed])

  return tags

}