import React from 'react'
import { iconForMedia, Media } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, Panel, SVG } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  media: Media
  requestRemove?: (id: string) => any
}

const PostAttachmentButton = memo('PostAttachmentButton', (props: Props) => {

  const {media, requestRemove} = props

  const {t} = useResourceTranslation('posts')

  const remove = React.useCallback(() => {
    requestRemove?.(media.id)
  }, [media, requestRemove])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel classNames={$.postAttachmentButton} href={media.url}>
        <HBox flex gap={layout.padding.inline.s}>
          {renderIcon()}
          {renderName()}
          {renderRemoveButton()}
        </HBox>
      </Panel>
    )
  }

  function renderIcon() {
    return (
      <SVG
        name={iconForMedia(media)}
        size={iconSize}
        primary
      />
    )
  }

  function renderName() {
    return (
      <Label flex caption>
        {media.name}
      </Label>
    )
  }

  function renderRemoveButton() {
    if (requestRemove == null) { return null }

    return (
      <ClearButton
        icon='cross'
        caption={t('post_form.remove_media')}
        onTap={remove}
        padding='horizontal'
        small
      />
    )
  }

  return render()

})

export default PostAttachmentButton

export const iconSize = layout.icon.m

const useStyles = createUseStyles({
  postAttachmentButton: {
    padding: [
      layout.padding.inline.s,
      layout.padding.inline.m,
    ],
  },
})