import { get, snakeCase } from 'lodash'
import {
  DynamicField,
  DynamicType,
  DynamicTypeTranslationMap,
  DynamicTypeTranslations,
} from './types'

export function deriveDynamicFormSchema(params: Record<string, DynamicType>, translations: DynamicTypeTranslationMap = {}, prefix?: string) {
  return Object.entries(params).map(([name, param]): DynamicField => {
    const prefixedName = (prefix ?? '') + name
    return deriveDynamicField(prefixedName, param, translations)
  })
}

function deriveDynamicField(name: string, param: AnyObject, translations: DynamicTypeTranslationMap): DynamicField {
  const i18nKey = name.split('.').map(it => snakeCase(it)).join('.')

  const translationResult = get(translations, i18nKey)
  const paramTranslations = typeof translationResult === 'string'
    ? {caption: translationResult}
    : translationResult ?? {}

  const caption = getParamTranslation(paramTranslations, 'caption')
  return {
    ...mapParamToField(name, param, paramTranslations, translations),
    name:        name,
    required:    param.required && param.default == null,
    caption:     caption === false ? false : caption ?? name,
    label:       getParamTranslation(paramTranslations, 'label') ?? param.name,
    instruction: getParamTranslation(paramTranslations, 'instruction'),
  } as DynamicField
}

function mapParamToField(name: string, param: AnyObject, paramTranslations: DynamicTypeTranslations | DynamicTypeTranslationMap, translations: DynamicTypeTranslationMap | null): Partial<DynamicField> {
  if (param.type === 'string' && param.enum != null) {
    return {
      type:    'choice',
      choices: param.enum.map((value: string) => ({
        value:   value,
        caption: getParamTranslation(paramTranslations, 'choices')?.[value] ?? value,
      })),
    }
  } else if (param.type === 'ref') {
    return {
      type:         'resource',
      resourceType: param.resourceType,
      multi:        false,
    }
  } else if (param.type === 'array' && param.itemType.type === 'ref') {
    return {
      type:         'resource',
      resourceType: param.resourceType,
      multi:        true,
    }
  } else if (param.type === 'string' && /^tag:(.*)$/.test(param.hint)) {
    return {
      type:         'tag',
      resourceType: RegExp.$1,
      multi:        false,
    }
  } else if (param.type === 'array' &&  param.itemType.type === 'string' && /^tag:(.*)$/.test(param.itemType.type)) {
    return {
      type:         'tag',
      resourceType: RegExp.$1,
      multi:        true,
    }
  } else if (param.type === 'array' && param.itemType.type === 'object') {
    return {
      type:       'collection',
      itemSchema: deriveDynamicFormSchema(param.itemType.schema, translations as DynamicTypeTranslationMap),
    }
  } else if (param.type === 'array') {
    return {
      type:       'collection',
      itemSchema: [deriveDynamicField('$', param.itemType, translations as DynamicTypeTranslationMap)],
    }
  } else if (param.type === 'object') {
    return {
      type:   'object',
      schema: deriveDynamicFormSchema(param.schema, translations as DynamicTypeTranslationMap, `${name}.`),
    }
  } else if (param.type === 'any') {
    return {
      type: 'variant',
    }
  } else {
    return param
  }
}

function getParamTranslation(translations: DynamicTypeTranslations, key: keyof DynamicTypeTranslations) {
  if (`$${key}` in translations) {
    return translations[`$${key}`]
  } else {
    return translations[key]
  }
}