import React from 'react'
import { useTranslation } from 'react-i18next'
import { Challenge } from '~/models'
import { memo } from '~/ui/component'
import { FormField } from '~/ui/form'
import { ResourceField } from '~/ui/resources/components'

const ChallengeTriggerFields = memo('ChallengeTriggerFields', () => {

  const [t] = useTranslation('flow_planner')

  return (
    <FormField
      name='challenge'
      caption={t('triggers.challenge.fields.challenge.caption')!}
      instruction={t('triggers.challenge.fields.challenge.instruction')!}
      placeholder={t('triggers.challenge.fields.challenge.placeholder')!}
    >
      {bind => (
        <ResourceField
          {...bind}
          Model={Challenge}
        />
      )}
    </FormField>
  )

})

export default ChallengeTriggerFields