import React from 'react'
import { observer } from '~/ui/component'
import { PushButton, TextBlock, TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { createUseStyles, fonts, layout } from '~/ui/styling'
import RegistrationFormModel from './RegistrationFormModel'
import { useRegistrationWidgetModel } from './RegistrationWidgetContext'
import { RegistrationTranslationKey } from './types'

export interface Props {}

const CodeFields = observer('CodeFields', (props: Props) => {

  const model = useRegistrationWidgetModel()
  const {model: formModel} = useForm<RegistrationFormModel>()

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        <VBox flex gap={layout.padding.s} justify='middle'>
          <TextBlock caption dim align='center'>
            {model.translate(RegistrationTranslationKey.codePrompt)}
          </TextBlock>
          {renderCodeField()}
        </VBox>
        {renderSubmit()}
      </VBox>
    )
  }

  function renderCodeField() {
    const placeholder = model.translate(RegistrationTranslationKey.codePlaceholder) ?? undefined

    return (
      <FormField
        name='code'
        caption={null}
        align='center'
      >
        {bind => (
          <TextField
            {...bind}
            classNames={$.codeInput}
            placeholder={placeholder}
            transform='uppercase'
            align='center'
            inputStyle='dark'
            autoFocus
          />
        )}
      </FormField>

    )
  }


  function renderSubmit() {
    const caption = model.translate(RegistrationTranslationKey.codeButton) ?? undefined

    return (
      <PushButton
        caption={caption}
        enabled={formModel.maySubmit}
        submit
      />
    )
  }

  return render()

})

export default CodeFields

const useStyles = createUseStyles(theme => ({
  codeInput: {
    ...fonts.responsiveFontStyle(theme.guide.fonts.mono),
    fontSize: 24,
    padding:  [layout.padding.inline.m, layout.padding.inline.l],
  },
}))