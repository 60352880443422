import React from 'react'
import { useTranslation } from 'react-i18next'
import { FeedbackButton } from '~/models'
import { memo } from '~/ui/component'
import {
  CollectionField,
  CollectionFieldProps,
  HBox,
  ItemFormProps,
  TextField,
  VBox,
} from '~/ui/components'
import { layout } from '~/ui/styling'

export type Props = Omit<CollectionFieldProps<FeedbackButton>, 'renderForm' | 'newItemTemplate'>

const FeedbackButtonsField = memo('FeedbackButtonsField', (props: Props) => {

  const [t] = useTranslation('feedback')

  //------
  // Rendering

  function render() {
    return (
      <CollectionField<FeedbackButton>
        {...props}
        renderForm={renderButtonForm}
        newItemTemplate={(): FeedbackButton => ({value: null, caption: ''})}
        pasteTransform={line => [{value: null, caption: line}]}
      />
    )
  }

  const renderButtonForm = React.useCallback((props: ItemFormProps<FeedbackButton>) => {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex={3}>
          <TextField
            {...props.bind('caption')}
            placeholder={t('fields.buttons.caption_placeholder')}
            inputStyle='light'
          />
        </VBox>
        <VBox flex={2}>
          <TextField
            {...props.bind('value')}
            placeholder={t('fields.buttons.value_placeholder')}
            inputStyle='light'
            mono
          />
        </VBox>
      </HBox>
    )
  }, [t])

  return render()

})

export default FeedbackButtonsField