import React from 'react'
import { useTranslation } from 'react-i18next'
import { languagesStore } from '~/stores'
import { observer } from '~/ui/component'
import { LanguageToggleButton, Tooltip } from '~/ui/components'

export interface Props {

}

const DefaultLanguageToggle = observer('DefaultLanguageToggle', (props: Props) => {

  const [t] = useTranslation('default_language_toggle')

  const setDefaultLanguage = React.useCallback((language: string) => {
    languagesStore.setDefaultLanguage(language)
  }, [])

  // #region Rendering

  function render() {
    return (
      <Tooltip renderTooltip={t('instruction')} delay={800}>
        <LanguageToggleButton
          languages={languagesStore.availableLanguages}
          value={languagesStore.defaultLanguage}
          onChange={setDefaultLanguage}
          showLanguage={true}
          small
        />
      </Tooltip>
    )
  }

  // #endregion

  return render()

})

export default DefaultLanguageToggle