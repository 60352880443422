import React from 'react'
import { useSize } from 'react-measure'
import Color from 'color'
import URL from 'url'
import config from '~/config'
import { memo } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'
import { childrenOfType } from '~/ui/util'
import { LatLong } from './types'
import { centerCoordinate, convertMetersToPixels, formatLatLong, zoomForBounds } from './util'

export interface Props {
  bounds?: [LatLong, LatLong]
  center?: LatLong
  zoom?:   number

  boundsPadding?: number
  aspectRatio?:   number

  // Alt-text for the image.
  alt: string

  children?:   React.ReactNode
  classNames?: React.ClassNamesProp
}

export interface MarkerProps {
  coordinate: LatLong
  color?:     string
  size?:      'tiny' | 'mid' | 'small'
}

export interface CircleProps {
  radius: number
  color:  Color
}

const StaticMap = memo('StaticMap', (props: Props) => {

  const {
    bounds,
    boundsPadding,
    center,
    zoom,
    aspectRatio,
    alt,
    classNames,
  } = props

  const elementRef = React.useRef<HTMLDivElement>(null)
  const [size, setSize] = React.useState<Size>({width: 0, height: 0})

  //------
  // Layout

  useSize(elementRef, setSize)

  const actualZoom = React.useMemo(() => {
    if (bounds != null) {
      const paddedSize = {...size} as Size
      if (boundsPadding != null) {
        paddedSize.width  -= 2 * boundsPadding * paddedSize.width
        paddedSize.height -= 2 * boundsPadding * paddedSize.height
      }

      return size ? zoomForBounds(bounds, paddedSize) : 12
    } else if (zoom != null) {
      return zoom
    } else {
      throw new Error("Prop `zoom` is required if no bounds are given")
    }
  }, [bounds, boundsPadding, size, zoom])

  const actualCenter = React.useMemo((): LatLong => {
    if (bounds != null) {
      return centerCoordinate(bounds)
    } else if (center != null) {
      return center
    } else {
      throw new Error("Prop `center` is required if no bounds are given")
    }
  }, [bounds, center])

  const getCircleLayout = React.useCallback((props: CircleProps): React.CSSProperties | null => {
    const {radius} = props

    const radiusInPoints = convertMetersToPixels(radius, actualCenter, actualZoom)
    const size = radiusInPoints * 2 + 2 * circleBorderWidth

    return {
      width:        size,
      height:       size,
      marginLeft:   -size / 2,
      marginTop:    -size / 2,
      borderRadius: size / 2,
    }
  }, [actualCenter, actualZoom])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const markers = childrenOfType<MarkerProps>(props.children, Marker)
    const url     = size && buildStaticMapURL(size, actualCenter, actualZoom, markers.map(it => it.props))

    const style: React.CSSProperties = {}
    if (aspectRatio && size.width) {
      style.height = size.width / aspectRatio
    }

    return (
      <figure classNames={[$.staticMap, classNames]} style={style} ref={elementRef}>
        {url && <img src={url} alt={alt}/>}
        {url && renderCircles()}
      </figure>
    )
  }

  function renderCircles() {
    const circles     = childrenOfType<CircleProps>(props.children, Circle)
    const circleProps = circles.map(it => it.props)

    return circleProps.map((props, index) => {
      const layout = getCircleLayout(props)
      return (
        <div
          key={index}
          classNames={$.circle}
          style={{
            ...layout,
            backgroundColor: props.color.alpha(0.2).string(),
            borderColor:     props.color.string(),
          }}
        />
      )
    })
  }

  return render()

})

Object.assign(StaticMap, {
  Marker: (props: MarkerProps) => null,
  Circle: (props: CircleProps) => null,
})

export default StaticMap as typeof StaticMap & {
  Marker: React.ComponentType<MarkerProps>
  Circle: React.ComponentType<CircleProps>
}

export function Marker(props: MarkerProps) { return null }
export function Circle(props: CircleProps) { return null }

const circleBorderWidth = 1

const useStyles = createUseStyles({
  staticMap: {
    position: 'relative',

    '& img': {
      ...layout.overlay,
      objectFit: 'cover',
      width:     '100%',
      height:    '100%',
    },
  },

  circle: {
    position: 'absolute',
    top:      '50%',
    left:     '50%',
    border:   [circleBorderWidth, 'solid'],
  },
})

function buildStaticMapURL(size: Size, center: LatLong, zoom: number, markers: MarkerProps[]) {
  const url = URL.parse('https://maps.googleapis.com/maps/api/staticmap', true)

  url.query['key'] = config.googleMaps.apiKey

  // Set size
  url.query['size'] = `${Math.ceil(size.width * 2)}x${Math.ceil(size.height * 2)}`

  // Set bounds.
  url.query['center'] = formatLatLong(center)
  url.query['zoom'] = `${zoom}`

  const markerParts = []
  for (const marker of markers) {
    if (marker.size != null) {
      markerParts.push(`size:${marker.size}`)
    }
    if (marker.color != null) {
      markerParts.push(`color:${marker.color}`)
    }
    markerParts.push(formatLatLong(marker.coordinate))
    url.query['markers'] = markerParts.join('|')
  }

  return URL.format(url)
}