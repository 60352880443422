import {
  BooleanField,
  ImportFieldGroup,
  ImportIssue,
  ImportModel,
  ImportOptions,
  ImportResult,
  ImportSession,
  RegularField,
} from 'sheet-importer'
import { ImportFieldMeta, importMetaFor, Model, ModelClass } from '~/models'
import { dataStore } from '~/stores'
import { ResourceTranslation } from '../ResourceTranslation'

export default class ResourceImportModel<M extends Model> extends ImportModel {

  constructor(
    Model: ModelClass<M>,
    public readonly defaults: Record<string, any>,
    protected readonly i18n: ResourceTranslation,
  ) {
    super()
    this.Model = Model
    this.type  = this.Model.resourceType
  }

  public type: string

  protected readonly Model: ModelClass<M>

  //------
  // Fields

  private importFieldMetaFor(field: string) {
    const importMeta = importMetaFor(this.Model)
    const meta       = importMeta?.fields[field]
    if (meta === false) { return null }

    return meta ?? {}
  }

  protected buildFields() {
    const importMeta = importMetaFor(this.Model)
    if (importMeta == null) { return [] }

    const fields: ImportFieldGroup[] = []
    for (const [field, meta] of Object.entries(importMeta.fields)) {
      if (meta === false) { continue }
      fields.push(this.fieldGroupFor(field, meta))
    }
    return fields
  }

  protected fieldGroupFor(name: string, meta: ImportFieldMeta): ImportFieldGroup {
    if (meta.field != null) {
      return {
        key: name,
        fields: [{
          key:    name,
          create: meta.field,
        }],
      }
    } else if (meta.type === Boolean) {
      return {
        key: name,
        fields: [{
          key:    name,
          create: () => new BooleanField(name),
        }],
      }
    } else {
      return {
        key: name,
        fields: [{
          key:    name,
          create: () => new RegularField(name, {
            type: meta.type as string | undefined,
          }),
        }],
      }
    }
  }

  //------
  // Importing

  public async import(session: ImportSession, options: ImportOptions = {}): Promise<ImportResult | undefined> {
    const {
      onProgress,
    } = options

    const issues = this.validate(session)
    if (issues.length > 0) {
      return {
        status:    'completed',
        issues:    issues,
        nImported: 0,
        nCreated:  0,
        nUpdated:  0,
      }
    }

    let removeProgressListener: (() => any) | null = null

    return await dataStore.import(this.Model, session, {
      onStart: operation => {
        if (onProgress == null) { return }
        removeProgressListener = operation.addProgressListener((progress, label, detail) => {
          onProgress({progress, label, detail})
        })
      },
      onEnd: () => {
        removeProgressListener?.()
      },
    })
  }

  protected validate(session: ImportSession): ImportIssue[] {
    const issues: ImportIssue[] = []
    return issues
  }


}