import { LocalizedString } from '~/models'
import { SVGName } from '~/ui/components/SVG'

export interface AppLayoutConfig {
  breadcrumbs:       Breadcrumb[]
  ActionsComponent?: React.ComponentType<{}>
}

export type Breadcrumb = NavigationBreadcrumb | CustomBreadcrumb

export interface NavigationBreadcrumb {
  name:    string
  detail?: string
}

export interface CustomBreadcrumb {
  icon?:   SVGName
  caption: string | LocalizedString
  href?:   string | null
  detail?: string
}

export function isNavigationBreadcrumb(breadcrumb: Breadcrumb): breadcrumb is NavigationBreadcrumb {
  return 'name' in breadcrumb
}

export function isCustomBreadcrumb(breadcrumb: Breadcrumb): breadcrumb is CustomBreadcrumb {
  return 'caption' in breadcrumb
}