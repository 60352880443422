import React from 'react'
import { useTranslation } from 'react-i18next'
import { Challenge, Condition, Script } from '~/models'
import { observer } from '~/ui/component'
import { ClearButton, Dimple, HBox, Label, Panel, SVG, Tooltip, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import {
  captionForConditionSelector,
  iconForConditionSelector,
  modelForConditionSelector,
} from './data'

export interface Props {
  condition:      Condition
  index:          number
  requestEdit?:   (condition: Condition, index: number) => any
  requestRemove?: (condition: Condition, index: number) => any
}

const ConditionBar = observer('ConditionBar', (props: Props) => {

  const {
    condition,
    index,
    requestEdit,
    requestRemove,
  } = props

  const icon  = iconForConditionSelector(condition.selector)
  const Model = modelForConditionSelector(condition.selector)

  const modelID = condition.selector.type !== 'variable' && Model != null ? condition.selector.model : null
  const [model] = useModelDocumentData<Script | Challenge>(Model, modelID, {
    fetch: 'notfound',
  })

  const caption    = captionForConditionSelector(condition.selector, model)
  const isVariable = condition.selector.type === 'variable'

  const [t] = useTranslation('conditions')

  const {colors} = useStyling()

  //------
  // Callbacks

  const edit = React.useCallback(() => {
    requestEdit?.(condition, index)
  }, [condition, index, requestEdit])

  const remove = React.useCallback(() => {
    requestRemove?.(condition, index)
  }, [condition, index, requestRemove])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel>
        <HBox align='stretch'>
          {renderSelectorCell()}
          <Dimple vertical/>
          {renderTestCell()}
          {(requestEdit || requestRemove) && (
            <>
              <Dimple vertical/>
              {renderActionsCell()}
            </>
          )}
        </HBox>
      </Panel>
    )
  }

  function renderSelectorCell() {
    return (
      <HBox flex classNames={$.conditionCell} gap={layout.padding.inline.m}>
        <SVG
          name={icon}
          size={layout.icon.l}
          color={colors.semantic.primary}
        />

        <VBox flex>
          <HBox gap={layout.padding.inline.s}>
            {caption == null && (
              <SVG
                name='warning-full'
                size={layout.icon.xs}
                color={colors.semantic.warning}
              />
            )}
            <VBox flex>
              <Tooltip renderTooltip={caption} enabled={caption != null}>
                <Label bold dim={caption == null} mono={isVariable} markup={!isVariable} variables={!isVariable}>
                  {caption ?? t('selector.not_found')}
                </Label>
              </Tooltip>
            </VBox>
          </HBox>
          <Label small dim>
            {t(`selector.types.${condition.selector.type}.caption`)}
          </Label>
        </VBox>
      </HBox>
    )
  }

  function renderTestCell() {
    return (
      <HBox classNames={$.conditionCell}>
        <Label small markup={true}>
          {t(`test_summaries.${condition.operator}`, condition)}
        </Label>
      </HBox>
    )
  }

  function renderActionsCell() {
    return (
      <HBox gap={layout.padding.inline.m} classNames={$.conditionCell}>
        {requestEdit != null && (
          <ClearButton
            icon='pencil'
            onTap={edit}
            padding='both'
          />
        )}
        {requestRemove != null && (
          <ClearButton
            icon='trash'
            onTap={remove}
            padding='both'
          />
        )}
      </HBox>
    )
  }

  return render()

})

export default ConditionBar

const useStyles = createUseStyles({
  conditionCell: {
    ...layout.responsiveProp({
      padding: layout.padding.m,
    }),
  },
})