import React from 'react'
import { useLayout } from 'react-measure'
import { ScrollState } from 'react-scroll-manager'
import { memo } from '~/ui/component'
import { Scroller, VBox, VBoxProps } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import { childrenNotOfType, childrenOfType } from '~/ui/util'
import DataGridBar from './DataGridBar'
import DataGridColumn, { Props as DataGridColumnProps } from './DataGridColumn'
import DataGridHeader from './DataGridHeader'
import { Sort, SortDirection } from './types'

export interface Props<T> {
  data:        T[]
  keyForItem?: (item: T, index: number) => string | number

  itemHref?:       (item: T) => string | null
  onItemTap?:      (item: T) => any
  itemIsSelected?: (item: T) => boolean

  EmptyComponent?: React.ComponentType<{}>

  sort?:        Sort | null
  requestSort?: (sort: Sort | null) => any
  defaultSortDirection?: SortDirection | ((field: string) => SortDirection)

  onEndReached?: () => any

  flex?:     VBoxProps['flex']
  children?: React.ReactNode

  classNames?:       React.ClassNamesProp
  headerClassNames?: React.ClassNamesProp
  bodyClassNames?:   React.ClassNamesProp
  rowClassNames?:    (item: T) => React.ClassNamesProp
}

const _DataGrid = <T extends {}>(props: Props<T>) => {

  const {
    data,
    keyForItem,
    itemHref,
    onItemTap,
    itemIsSelected,
    EmptyComponent,
    sort,
    requestSort,
    defaultSortDirection,
    onEndReached,
    flex = true,
    children,
    classNames,
    headerClassNames,
    bodyClassNames,
    rowClassNames,
  } = props

  const listEmpty = data.length === 0

  const columns = childrenOfType(children, DataGridColumn)
  const rest    = childrenNotOfType(children, [DataGridColumn])
  if (rest.length > 0) {
    throw new Error("DataGrid: may only contain children of type <DataGridColumn/>")
  }

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [scrollState, setScrollState] = React.useState<ScrollState>(ScrollState.default)
  const scrollbarWidth = scrollState.scrollbarWidth ?? 0

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.DataGrid, classNames]} flex={flex} gap={layout.padding.s} ref={containerRef}>
        {renderHeader()}
        {renderScroller()}
      </VBox>
    )
  }

  function renderHeader() {
    const paddingRight = scrollbarWidth > 0
      ? scrollbarWidth + layout.padding.inline.m
      : 0

    return (
      <VBox style={{paddingRight}} classNames={headerClassNames}>
        <DataGridHeader
          columns={listEmpty ? [] : columns}
          sort={sort}
          requestSort={requestSort}
          defaultSortDirection={defaultSortDirection}
        />
      </VBox>
    )
  }

  function renderScroller() {
    const scrollbar = scrollState.scrollbarWidth != null
    return (
      <Scroller
        contentClassNames={[$.scrollerContent, {scrollbar}, bodyClassNames]}
        onScrollStateChange={setScrollState}
        scrollStateKey={data.length}
        scrollThrottle={200}
        onEndReached={onEndReached}
        children={renderBody()}
        flex={flex}
      />
    )
  }

  function renderBody() {
    return (
      <VBox flex={flex} gap={layout.padding.xs}>
        {listEmpty && EmptyComponent ? (
          <EmptyComponent/>
        ) : (
          data.map(renderBar)
        )}
      </VBox>
    )
  }

  function renderBar(item: T, index: number) {
    const key = (keyForItem ?? defaultKeyForItem)?.(item, index)

    return (
      <DataGridBar<T>
        key={key}
        item={item}
        index={index}
        columns={columns}
        onItemTap={onItemTap}
        href={itemHref?.(item) ?? undefined}
        selected={itemIsSelected?.(item) ?? false}
        classNames={rowClassNames?.(item)}
      />
    )
  }

  const defaultKeyForItem = React.useCallback((item: T, index: number) => {
    if ('id' in item) {
      return (item as any).id
    } else {
      return index
    }
  }, [])

  //------
  // Layout

  const fitCells = React.useCallback((cells: HTMLElement[]) => {
    for (const cell of cells) {
      cell.style.width = ''
    }

    const maxWidth = Math.max(...cells.map(cell => cell.offsetWidth))

    for (const cell of cells) {
      cell.style.width = `${maxWidth}px`
      cell.style.flex  = '0 0 auto'
    }
  }, [])

  const alignColumn = React.useCallback((container: HTMLElement, column: DataGridColumnProps<any>, index: number) => {
    const selector = `.DataGrid--cell:nth-child(${index+1})`
    const cells    = Array.from(container.querySelectorAll(selector)) as HTMLElement[]

    if (column.minWidth != null) {
      for (const cell of cells) {
        cell.style.minWidth = `${column.minWidth}px`
      }
    }

    if (column.width != null) {
      for (const cell of cells) {
        cell.style.width = `${column.width}px`
      }
    } else if (column.flex) {
      for (const cell of cells) {
        cell.style.flex = `${column.flex === true ? 1 : column.flex} 0 0`
      }
    } else {
      return fitCells(cells)
    }
  }, [fitCells])

  const alignColumns = React.useCallback((container: HTMLElement) => {
    for (const [index, column] of columns.entries()) {
      alignColumn(container, column.props, index)
    }
  }, [alignColumn, columns])

  useLayout(containerRef, {debounce: 50}, alignColumns)

  return render()

}

const useStyles = createUseStyles({
  DataGrid: {

  },

  scrollerContent: {
    flexGrow: 1,
    padding:  3,

    '&.scrollbar': {
      paddingRight: layout.padding.inline.m,
    },
  },
})

const DataGrid = memo('Export', _DataGrid) as any as typeof _DataGrid & {Column: typeof DataGridColumn}
Object.assign(DataGrid, {Column: DataGridColumn})
export default DataGrid