import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { observer } from '~/ui/component'
import { Checkbox, CheckboxProps, Tappable } from '~/ui/components'

export interface Props extends Omit<CheckboxProps, 'checked' | 'onChange'> {
  id: string | null
}

const SelectionCheckbox = observer('SelectionCheckbox', (props: Props) => {

  const {id, enabled = true, ...rest} = props
  const manager = useSelectionManager()

  React.useEffect(() => {
    if (manager == null || id == null) { return }
    if (!enabled) { return }

    manager.register(id)
    return () => manager.unregister(id)
  }, [enabled, manager, id])

  const selected = id == null
    ? manager?.allAvailableSelected ?? false
    : manager?.isSelected(id) ?? false

  const toggle = React.useCallback(() => {
    if (id == null) {
      if (manager?.allAvailableSelected) {
        manager?.selectNone()
      } else {
        manager?.selectAllAvailable()
      }
    } else {
      manager?.toggle(id)
    }
  }, [manager, id])

  //------
  // Rendering

  function render() {
    return (
      <Tappable enabled={enabled} onTap={toggle}>
        <Checkbox
          checked={selected}
          onChange={toggle}
          enabled={enabled}
          {...rest}
        />
      </Tappable>
    )
  }

  return render()

})

export default SelectionCheckbox