import React from 'react'
import Toast from 'react-toast'
import clipboard, { ClipboardItem } from 'rich-clipboard'
import { CalendarPlanClipboardItem, ClipboardType } from '~/clipboard'
import { CalendarItem } from '~/models'
import { calendarItemType } from '~/stores'
import { memo } from '~/ui/component'
import { ConfirmBox, PopupMenuItem, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, useStyling, useTheme } from '~/ui/styling'
import { useCalendarPlanner } from '../CalendarPlannerContext'
import { calendarItemBorderRadius } from '../days/layout'
import { useSelection } from '../SelectionContext'
import CalendarPlannerModuleBar from './CalendarPlannerModuleBar'
import CalendarPlannerTriggerableBar from './CalendarPlannerTriggerableBar'

export interface Props {
  item:      CalendarItem
  selected?: boolean
}

const CalendarItemBar = memo('CalendarItemBar', (props: Props) => {

  const {item, selected = false} = props
  const uuid   = item.uuid

  const {planner} = useCalendarPlanner()
  const {manager} = useSelection()

  const {t, actionCaption, actionConfirm} = useResourceTranslation('calendar_planner')
  const type = t(`item_type.${calendarItemType(item)}`)

  const theme    = useTheme()
  const {colors} = useStyling()

  const borderRadius = calendarItemBorderRadius(theme, item)

  //------
  // Actions

  const copy = React.useCallback(() => {
    const items = planner.getClipboardItems([uuid]) ?? []
    if (items.length === 0) { return }

    const clipboardItem: ClipboardItem<CalendarPlanClipboardItem[]> = {
      type: ClipboardType.CALENDAR_ITEMS,
      data: items,
    }
    clipboard.write([clipboardItem])

    Toast.show({
      ...t('actions.copy.success', {count: items.length}),
      type: 'success',
    })
  }, [planner, t, uuid])

  const duplicate = React.useCallback(() => {
    const items = planner.getClipboardItems([uuid]) ?? []
    if (items.length === 0) { return }

    planner.pasteClipboardItems(items).then(uuids => {
      if (uuids != null) {
        manager?.selectOnly(...uuids)
      }
    })
  }, [manager, planner, uuid])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...actionConfirm('remove_item', {type}),
      destructive: true,
    })
    if (confirmed) {
      planner.removeItems([uuid])
    }
  }, [actionConfirm, planner, type, uuid])

  const editTime = React.useCallback(() => {
    planner.editItem(item.uuid, 'time')
  }, [item.uuid, planner])

  const editConditions = React.useCallback(() => {
    planner.editItem(item.uuid, 'conditions')
  }, [item.uuid, planner])

  const editTargeting = React.useCallback(() => {
    planner.editItem(item.uuid, 'targeting')
  }, [item.uuid, planner])

  const commonMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:     'clock',
      caption:  actionCaption('edit_time'),
      onSelect: editTime,
    })

    items.push({
      icon:     'target',
      caption:  actionCaption('edit_targeting'),
      onSelect: editTargeting,
    })

    items.push({
      icon:     'code',
      caption:  actionCaption('edit_conditions'),
      onSelect: editConditions,
    })

    items.push({section: '-'})

    items.push({
      icon:     'copy',
      caption:  actionCaption('duplicate'),
      keyHint:  'Short+D',
      onSelect: duplicate,
    })

    items.push({
      icon:     'copy',
      caption:  actionCaption('copy'),
      keyHint:  'Short+C',
      onSelect: copy,
    })

    items.push({
      icon:     'trash',
      caption:  actionCaption('remove_item', {type}),
      keyHint:  'Delete',
      color:    colors.semantic.negative,
      onSelect: remove,
    })

    return items
  }, [actionCaption, colors.semantic.negative, copy, duplicate, editConditions, editTargeting, editTime, remove, type])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={[$.calendarItemBar, {selected}]} style={{borderRadius}}>
        {renderBar()}
      </VBox>
    )
  }

  function renderBar() {
    if (item.type === 'triggerable') {
      return (
        <CalendarPlannerTriggerableBar
          item={item}
          triggerable={item.triggerable}
          commonMenuItems={commonMenuItems}
        />
      )
    } else if (item.type === 'module') {
      return (
        <CalendarPlannerModuleBar
          item={item}
          moduleID={item.module}
          commonMenuItems={commonMenuItems}
        />
      )
    } else {
      return null
    }
  }

  return render()

})

export default CalendarItemBar

const useStyles = createUseStyles(theme => ({
  calendarItemBar: {
    '&.selected': {
      boxShadow: [0, 0, 0, 2, theme.semantic.primary],
    },
  },
}))