import React from 'react'
import { authenticationStore } from '~/stores'
import OrganisationField from '~/ui/app/organisations/OrganisationField'
import { observer } from '~/ui/component'
import { ClearButton, TextField, Tooltip, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import DuplicateProjectFormModel from './DuplicateProjectFormModel'

export interface Props {}

const PreflightStep = observer('PreflightStep', (props: Props) => {

  const me = authenticationStore.user

  const {model: formModel} = useForm<DuplicateProjectFormModel>()
  const {t, fieldInstruction} = useResourceTranslation()

  const {colors} = useStyling()


  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s} classNames={$.PreflightStep}>
        {renderFields()}
      </VBox>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='name'>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='code' required instruction={fieldInstruction('code_readonly')!}>
          {renderCodeField}
        </FormField>
        {me?.isSuper() && (
          <FormField name='organisation'>
            {bind => <OrganisationField {...bind}/>}
          </FormField>
        )}
      </VBox>
    )
  }

  function renderCodeField(bind: FormFieldBindProps<string>) {
    return (
      <TextField
        {...bind}
        mono
        readOnly={formModel.autoGenerateCode}
        accessoryRight={renderAutoGenerateCodeToggle()}
      />
    )
  }

  function renderAutoGenerateCodeToggle() {
    const tooltip = formModel.autoGenerateCode
      ? t('fields.code.autogenerated.on')
      : t('fields.code.autogenerated.off')

    return (
      <Tooltip renderTooltip={tooltip}>
        <ClearButton
          icon='flash'
          color={formModel.autoGenerateCode ? colors.semantic.primary : colors.fg.dark.dim}
          onTap={() => formModel.toggleAutoGenerateCode()}
        />
      </Tooltip>
    )
  }

  return render()

})

export default PreflightStep

const useStyles = createUseStyles({
  PreflightStep: {
    maxHeight: 460,
  },

  list: {
    margin: -6,
  },

  listContent: {
    padding: 6,
  },
})