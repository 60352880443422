import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import I18next from 'i18next'
import { CalendarItem, Triggerable, TriggerableModelClass } from '~/models'
import { plannerStore } from '~/stores'
import { observer } from '~/ui/component'
import { InfoIcon, PopupMenuItem } from '~/ui/components'
import { resourceDetailPath } from '~/ui/resources/routes'
import ActionTriggerableBar from '../../triggerables/ActionTriggerableBar'
import ModelTriggerableBar from '../../triggerables/ModelTriggerableBar'
import { useCalendarPlanner } from '../CalendarPlannerContext'

export interface Props {
  item:            CalendarItem
  triggerable:     Triggerable
  commonMenuItems: PopupMenuItem[]
}

const CalendarPlannerTriggerableBar = observer('CalendarPlannerTriggerableBar', (props: Props) => {

  const {item, triggerable, commonMenuItems = []} = props

  const {planner} = useCalendarPlanner()

  const [t]       = useTranslation('calendar_planner')
  const singular  = triggerable.type === 'action'
    ? t('planner_action')
    : I18next.t(TriggerableModelClass(triggerable.type).resourceType + ':singular')

  //------
  // Actions

  const action = triggerable.type === 'action'
    ? plannerStore.getAction(triggerable.action)
    : null

  const allowEdit = React.useMemo(() => {
    if (action != null) {
      if (Object.keys(action!.paramsSchema).length === 0) { return }
    } else {
      return true
    }
  }, [action])

  const history = useHistory()
  const editContent = React.useCallback(() => {
    if (triggerable.type === 'challenge') {
      history.push(resourceDetailPath('challenges', triggerable.model))
    } else {
      planner.editItem(item.uuid, 'content')
    }
  }, [history, item.uuid, planner, triggerable])

  const togglePublishAgain = React.useCallback(() => {
    if (planner == null) { return }
    if (triggerable.type !== 'challenge') { return }

    return planner.updateItem(item.uuid, item => {
      if (item.type !== 'triggerable') { return item }
      if (item.triggerable.type !== 'challenge') { return item }

      return {
        ...item,
        triggerable: {
          ...item.triggerable,
          publishAgain: !item.triggerable.publishAgain,
        },
      }
    })
  }, [item.uuid, planner, triggerable.type])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    if (allowEdit) {
      items.push({
        icon:     'pencil',
        caption:  t('flow_planner:triggerables.actions.edit', {type: singular}),
        keyHint:  'doubleclick',
        onSelect: editContent,
      })
    }

    if (triggerable.type === 'challenge') {
      items.push({
        caption:   t('flow_planner:triggerables.challenge.fields.publish_again.caption'),
        accessory: <InfoIcon renderTooltip={t('flow_planner:triggerables.challenge.fields.publish_again.instruction')}/>,
        checked:   triggerable.publishAgain,
        onSelect:  togglePublishAgain,
      })
    }

    items.push({section: '-'})
    items.push(...commonMenuItems)

    return items
  }, [allowEdit, commonMenuItems, editContent, singular, t, togglePublishAgain, triggerable])

  //------
  // Rendering

  function render() {
    if (triggerable.type === 'action') {
      return (
        <ActionTriggerableBar
          triggerable={triggerable}
          kebabMenuItems={kebabMenuItems}
          requestEdit={editContent}
        />
      )
    } else {
      return (
        <ModelTriggerableBar
          triggerable={triggerable}
          kebabMenuItems={kebabMenuItems}
          requestEdit={editContent}
        />
      )
    }
  }

  return render()

})

export default CalendarPlannerTriggerableBar