import React from 'react'
import { useTimer } from 'react-timer'
import { DateTime } from 'luxon'
import { SubmitResult } from '~/ui/form'

export function useAutoSave(save: SaveFn, interval: number) {

  const [lastSaved, setLastSaved] = React.useState<DateTime | null>(null)
  const activeRef = React.useRef<boolean>(false)

  const timer = useTimer()

  const saveNow = React.useCallback(async () => {
    const result = await timer.await(save())
    if (result?.status === 'ok') {
      setLastSaved(DateTime.local())
    }

    if (activeRef.current) {
      timer.debounce(saveNow, interval)
    }

    return result
  }, [interval, save, timer])

  const resume = React.useCallback((options: ResumeOptions = {}) => {
    if (activeRef.current) { return }

    activeRef.current = true
    if (options.saveNow !== false) {
      saveNow()
    } else {
      timer.debounce(saveNow, interval)
    }
  }, [interval, saveNow, timer])

  const suspend = React.useCallback(() => {
    if (!activeRef.current) { return }
    timer.clearAll()
    activeRef.current = false
  }, [timer])

  const suspendTimer = useTimer()
  const suspendFor = React.useCallback((timeout: number) => {
    suspend()
    suspendTimer.debounce(resume, timeout)
  }, [resume, suspend, suspendTimer])

  React.useEffect(() => {
    resume({saveNow: false})
  }, [resume])

  return {
    saveNow,
    lastSaved,
    suspend,
    resume,
    suspendFor,
  }

}

export interface AutoSaveHook {
  saveNow:   SaveFn
  lastSaved: DateTime | null

  suspend: () => void
  resume:  (options: ResumeOptions) => void

  suspendFor: (timeout: number) => void
}

export interface ResumeOptions {
  saveNow?: boolean
}

export type SaveFn = () => Promise<SubmitResult | undefined>