import React from 'react'
import { AnalyticsService } from '~/stores'
import { memo } from '~/ui/component'

interface AnalyticsServiceContext {
  service: AnalyticsService | null
}

const AnalyticsServiceContext = React.createContext<AnalyticsServiceContext>({
  service: null,
})

export interface AnalyticsServiceProviderProps {
  service:   AnalyticsService | null
  children?: React.ReactNode
}

export const AnalyticsServiceProvider = memo('AnalyticsServiceProvider', (props: AnalyticsServiceProviderProps) => {

  const context = React.useMemo(() => ({
    service: props.service,
  }), [props.service])

  return (
    <AnalyticsServiceContext.Provider value={context}>
      {props.children}
    </AnalyticsServiceContext.Provider>
  )
})

export function useAnalyticsService() {
  return React.useContext(AnalyticsServiceContext)
}