import I18n from 'i18next'
import { ChatBot } from './ChatBot'
import { Condition } from './Condition'
import {
  ImageMessageTemplate,
  MessageTemplate,
  NoticeMessageTemplate,
  TextMessageTemplate,
  VideoMessageTemplate,
  WidgetMessageTemplate,
} from './MessageTemplate'
import { Model, resource } from './Model'
import { Module } from './Module'
import { Project } from './Project'
import { Targeting } from './Targeting'
import { Language, TranslateOptions } from './Translations'
import { Ref } from './types'

@resource<Script>('scripts', {
  icon:      () => 'linear-script',
  caption:   script => script.name,
})
export class Script extends Model {

  public project!: Ref<Project>

  public module!:  Ref<Module>
  public name!:    string

  public tags!:     string[]

  public bot!:      Ref<ChatBot>
  public category!: string

  public targeting!: Targeting

  public activeVersions!:  Record<string, ScriptVersion>
  public activeRevisions!: Record<string, number>

  public messagesByLanguage?: Record<Language, ScriptMessage[]>

  public messages(options: TranslateOptions = {}) {
    const {messagesByLanguage = {}} = this
    const languages = new Set<Language>()
    if (options.language != null) {
      languages.add(options.language)
    }
    if (options.fallback !== false) {
      if (typeof I18n.options.fallbackLng === 'string') {
        languages.add(I18n.options.fallbackLng)
      }
      languages.add(Object.keys(messagesByLanguage)[0])
    }

    for (const language of languages) {
      if (messagesByLanguage[language] != null) {
        return messagesByLanguage[language]
      }
    }

    return []
  }

  public findMessage(predicate: (message: ScriptMessage) => boolean, language?: string) {
    const messages = language != null ? this.messages({language}) : Object.values(this.messagesByLanguage).flatMap(it => it)
    return messages.find(predicate)
  }

  public empty!:        boolean
  public hasQuestion!:  boolean

}

export interface RevisionInfo {
  revision:    number
  createdAt:   Date
  updatedAt:   Date
}

export interface ScriptVersion extends RevisionInfo {
  messages: ScriptMessage[]
}

export type ScriptMessage        = ScriptMessageCommon & MessageTemplate
export type TextScriptMessage    = ScriptMessageCommon & TextMessageTemplate
export type NoticeScriptMessage  = ScriptMessageCommon & NoticeMessageTemplate
export type ImageScriptMessage   = ScriptMessageCommon & ImageMessageTemplate
export type VideoScriptMessage   = ScriptMessageCommon & VideoMessageTemplate
export type WidgetScriptMessage  = ScriptMessageCommon & WidgetMessageTemplate

export interface ScriptMessageCommon {
  uuid:       string
  conditions: Condition[]
}

export interface ScriptCompileError {
  type:    string
  message: string
  range:   NodeRange | null
  details: Record<string, any>
}

export interface NodeRange {
  start: Position
  end:   Position
}

export interface Position {
  line: number
  col:  number
}

//------
// Utility

export function emptyScriptMessage(type: ScriptMessage['type'], project: Project | null): Partial<ScriptMessage> {
  const common = {feedback: null} as Partial<ScriptMessageCommon>

  switch (type) {
    case 'text':
      return {
        type: 'text',
        text: '',
        link: null,
        ...common,
      }

    case 'notice':
      return {
        type: 'notice',
        text: '',
        ...common,
      }

    default:
      return {
        type,
        ...common,
      }
  }
}

export function scriptMessageIsEmpty(message: ScriptMessage) {
  switch (message.type) {
  case 'text':  return message.text == null
  case 'image': return message.image == null
  case 'video': return message.video == null
  }
}