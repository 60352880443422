import I18next from 'i18next'
import { get } from 'lodash'
import { Model, resource } from './Model'

@resource<Extension>('extensions', {
  icon:    () => 'hexagons',
  caption: it => it.name,
})
export class Extension extends Model {

  public name!:         string
  public configSchema!: Record<string, any>
  public translations!: Record<string, any>
  public logo!:         ExtensionLogo

  public translate(key: string, language: string = I18next.language) {
    return get(this.translations[language] ?? this.translations.en, key)
  }

}

export type ExtensionLogo =
  | {type: 'svg', data: {attributes: Record<string, any>, content: string}}
  | {type: 'png', base64: string}