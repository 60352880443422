import I18next from 'i18next'
import { Access, Targeting } from '~/models'
import { ChatBot } from './ChatBot'
import { CustomImage } from './CustomImage'
import { Group } from './Group'
import { Model, resource } from './Model'
import { Ref } from './types'

@resource<Channel>('channels', {
  icon:    () => 'channel',
  caption: channel => channel.name,
  details: channel => [I18next.t(`channels:types.${channel.type}.caption`)],
  appLink: channel => `//chat/${channel.id}`,
})
export class Channel extends Model {

  public type!: ChannelType

  public name!:  string
  public image!: CustomImage | null

  public app!: boolean
  public web!: boolean

  public pinIndex!: number | null
  public pinning!:  number | string

  public bots!:  Ref<ChatBot>[]

  public maySend!: Access


  // type: participant

  public targeting?: Targeting

  // type: group

  public group?: Ref<Group> | null

  public moderation!: ChannelModeration

  public get isOpen() {
    return this.maySend.type === 'global' ? this.maySend.value : true
  }

  public get isModerated() {
    return false
  }

  public get isModerator() {
    return false
  }

}

export type ChannelType = 'participant' | 'group'
export const ChannelType: {
  all: ChannelType[]
} = {
  all: ['participant', 'group'],
}

export interface ChannelModeration {
  moderatorGroup: Ref<Group>
}

export function supportsModeration(type: ChannelType) {
  return type !== 'participant'
}