import React from 'react'
import { ModuleParameter } from '~/models'
// @index: import ${variable} from ${relpath}
import NumberParameterFieldset from './NumberParameterFieldset'
import StringParameterFieldset from './StringParameterFieldset'
import TriggerableParameterFieldset from './TriggerableParameterFieldset'

// /index

export default function renderParameterFieldset(parameter: ModuleParameter) {
  switch (parameter.type) {
    case 'string':
      return <StringParameterFieldset parameter={parameter}/>
    case 'number':
      return <NumberParameterFieldset parameter={parameter}/>
    case 'script': case 'challenge':
      return <TriggerableParameterFieldset parameter={parameter}/>
    default:
      return null
  }
}