import { makeObservable, observable } from 'mobx'
import { Module, Ref } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default abstract class ModuleFormModel implements FormModel {

  constructor(module: Ref<Module> | null) {
    this.existing = module
    makeObservable(this)
  }

  @observable
  public existing: Ref<Module> | null

  @observable
  public creatingNew: boolean = false

  @observable
  public name: string = ''

  @observable
  public plannerType: Module['plannerType'] = 'flow'

  @observable
  public createNew(name: string) {
    this.creatingNew = true
    this.name = name
  }

  public async submit(): Promise<SubmitResult | undefined> {
    let moduleID: Ref<Module> | null

    if (this.creatingNew) {
      const result = await this.createModule()
      if (result.status !== 'ok') { return result }

      moduleID = result.data?.id ?? null
    } else {
      moduleID = this.existing
    }
    if (moduleID == null) { return }

    return await this.submitWithModule(moduleID)
  }

  public abstract submitWithModule(module: Ref<Module>): Promise<SubmitResult | undefined>

  private async createModule(): Promise<SubmitResult> {
    return dataStore.create(Module, {
      name:        this.name,
      plannerType: this.plannerType,
    })
  }

}
