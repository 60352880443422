import React from 'react'
import { Dashboard } from '~/models'
import { analyticsStore, ModelEndpoint, projectStore } from '~/stores'

export default function useDashboards(fetch: boolean = false): [Dashboard[], ModelEndpoint<Dashboard>] {
  const endpoint = analyticsStore.dashboards
  const project  = projectStore.project

  React.useEffect(() => {
    if (project == null) { return }
    if (fetch) {
      endpoint.fetchIfNeeded()
    }
  }, [endpoint, fetch, project])

  return [endpoint.data, endpoint]
}