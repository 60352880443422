import React from 'react'
import { Challenge } from '~/models'
import { TargetingSummary } from '~/ui/app/targeting'
import { memo } from '~/ui/component'
import { FormDialogProps } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import ChallengeTargetingForm from './ChallengeTargetingForm'

export interface Props {
  challenge: Challenge
}

const ChallengeTargeting = memo('ChallengeTargeting', (props: Props) => {

  const {challenge} = props
  const {t} = useResourceTranslation('challenges')

  const [,] = useBoolean()

  const BoundTargetingForm = React.useCallback(
    (props: Pick<FormDialogProps<any>, 'open' | 'requestClose'>) => (
      <ChallengeTargetingForm
        {...props}
        challenge={challenge}
        instructions={t('targeting_form.instructions')}
      />
    ),
    [challenge, t],
  )

  if (challenge.targeting == null) {
    return null
  } else {
    return (
      <TargetingSummary
        targeting={challenge.targeting}
        FormComponent={BoundTargetingForm}
      />
    )
  }

})

export default ChallengeTargeting