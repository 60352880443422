import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import * as Path from 'path'
import { Page } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { HBox, SegmentedButton, VBox } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import PagesListContainer from './list/PagesListContainer'
import PagesCarousel from './PagesCarousel'
import { PagesContextContainer } from './PagesContext'

export interface Params {
  path?: string
  id?:   string
}

export type Props = RouteComponentProps<Params>

const PagesScreen = observer('PagesScreen', (props: Props) => {

  const [rootPage] = useModelDocumentData(Page, projectStore.project?.rootPage ?? null, {
    label: 'linked',
  })

  const id   = props.match.params.id ?? null
  const path = React.useMemo(() => {
    if (props.match.params.path == null) {
      return [rootPage?.slug ?? '']
    }
    return [
      rootPage?.slug ?? '',
      ...props.match.params.path.split('/'),
    ]
  }, [rootPage, props.match.params.path])

  const {t} = useResourceTranslation('pages')

  //------
  // Navigation

  const history  = useHistory()
  const bodyType = 'id' in props.match.params ? 'list' : 'carousel'

  const switchToCarousel = React.useCallback(() => {
    if (bodyType === 'carousel') { return }

    const page = id == null ? null : dataStore.get(Page, id)
    history.replace(Path.join('/pages/', page?.slug ?? ''))
  }, [bodyType, history, id])

  const findPageBySlug = React.useCallback((slug: string) => {
    const pages = dataStore.db(Page).all().filter(it => it.slug === slug)
    return pages.find(it => it.project === projectStore.projectID) ?? pages[0]
  }, [])

  const switchToList = React.useCallback(() => {
    if (bodyType === 'list') { return }

    const currentSlug = path[path.length - 1]
    const page = findPageBySlug(currentSlug)

    // Pages from other projects are not listed
    history.replace(`/pages/-/${page != null && page.project === projectStore?.projectID ? page.id : ''}`)
  }, [bodyType, history, path, findPageBySlug])

  const handleBodyTypeChange = React.useCallback((type: 'carousel' | 'list') => {
    if (type === 'carousel') {
      switchToCarousel()
    } else {
      switchToList()
    }
  }, [switchToCarousel, switchToList])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType='pages'>
        {renderContent()}
      </ResourceTypeProvider>
    )
  }

  function renderContent() {
    return (
      <PagesContextContainer initialPath={path} carousel={bodyType === 'carousel'}>
        <VBox flex gap={layout.padding.s} classNames={$.container}>
          {renderBodyToggle()}
          {bodyType === 'carousel' ? (
            <PagesCarousel/>
          ) : (
            <PagesListContainer id={id}/>
          )}
        </VBox>
      </PagesContextContainer>
    )
  }

  function renderBodyToggle() {
    return (
      <HBox>
        <SegmentedButton
          segments={[{
            value: 'carousel',
            caption: t('body.carousel'),
          }, {
            value: 'list',
            caption: t('body.list'),
          }]}

          selectedValue={bodyType}
          onChange={handleBodyTypeChange}
        />
      </HBox>
    )
  }

  return render()

})

export default PagesScreen

const useStyles = createUseStyles({
  container: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },
})