import React from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { Variant, VariantType } from '~/models'
import { forwardRef } from '~/ui/component'
import {
  DateTimeComponentsField,
  DateTimeField,
  Label,
  PopupMenu,
  Tappable,
  TextField,
  TextFieldProps,
} from '~/ui/components'
import { FieldChangeCallback, invokeFieldChangeCallback, useFieldChangeCallback } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { colors, createUseStyles, layout } from '~/ui/styling'

export interface Props extends Omit<TextFieldProps, 'value' | 'onChange'> {
  value:    Variant | null
  onChange?: FieldChangeCallback<Variant> | ((value: Variant) => any)
}

const VariantField = forwardRef('VariantField', (props: Props, ref: React.Ref<HTMLInputElement>) => {

  const {value, onChange, ...rest} = props

  const {t} = useTranslation('variant')

  const [text, setText] = React.useState<string>(Variant.stringify(value))
  const [fixedType, setFixedType] = React.useState<VariantType | null>(
    Variant.isDate(value) ? 'date' : null,
  )

  const textChangedRef = React.useRef<boolean>(false)

  const dateTimeFieldRef = React.useRef<DateTimeComponentsField>(null)

  const type = React.useMemo(
    () => fixedType ?? Variant.type(value),
    [fixedType, value],
  )

  const prevValue = usePrevious(value)
  React.useEffect(() => {
    const textChanged = textChangedRef.current
    textChangedRef.current = false

    if (textChanged) { return }
    if (prevValue === value) { return }

    const nextText = Variant.stringify(value)
    if (nextText === text) { return }

    setText(nextText)
  }, [prevValue, text, value])

  //------
  // Callbacks

  const handleChange = React.useCallback((nextText: string) => {
    if (nextText === text) { return }

    setText(nextText)

    if (fixedType != null && !Variant.canBeExpressedAs(nextText, fixedType)) {
      setFixedType(null)
    }

    // Prevent updating the value while typing.
    textChangedRef.current = true

    // When parsing, there is no safe way to differentiate between text that includes commas and a comma seperated list.
    // Below, we make sure the value remains a list if it was explicitly set as such
    const value = Variant.parse(nextText, {type: fixedType ?? undefined})

    if (type === 'list' && Variant.type(value) === 'text') {
      const valueAsList = Variant.parse(nextText, {type: 'list'})
      onChange?.(valueAsList)
    } else {
      onChange?.(value)
    }
  }, [text, fixedType, type, onChange])

  const dateTime = React.useMemo(() => {
    const value = Variant.parse(text)
    if (!Variant.isDate(value)) { return null }

    return Variant.toDateTime(value)
  }, [text])

  const setDateTime = useFieldChangeCallback(React.useCallback((dateTime: DateTime | null, partial?: boolean) => {
    const value: Variant = dateTime == null ? null : Variant.fromDateTime(dateTime)
    invokeFieldChangeCallback(onChange, value, partial)
  }, [onChange]))

  const typeMenuItems = React.useMemo(
    () => VariantType.all.map(type => ({value: type, caption: t(`type.${type}`)})),
    [t],
  )

  const fixType = React.useCallback((fixedType: VariantType) => {
    const converted = Variant.parse(text, {type: fixedType})

    // See comment about lists above
    if (fixedType !== 'list' && Variant.type(converted) !== type) {
      setText(Variant.stringify(converted))
    }
    setFixedType(fixedType)
    onChange?.(converted)

    if (Variant.isDate(converted)) {
      setDateTime(Variant.toDateTime(converted))
    }

  }, [onChange, setDateTime, text, type])

  const prevFixedType = usePrevious(fixedType)
  React.useEffect(() => {
    if (prevFixedType !== undefined && prevFixedType !== 'date' && fixedType === 'date') {
      dateTimeFieldRef.current?.openPicker()
    }
  }, [fixedType, prevFixedType, type])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        {fixedType === 'date' ? (
          <DateTimeField
            value={dateTime}
            onChange={setDateTime}
            ref={dateTimeFieldRef}
            showClearButton='never'
            accessoryRight={renderTypeAccessory()}
            inputRef={ref}
            utc
          />
        ) : (
          <TextField
            value={text}
            onChange={handleChange}
            accessoryRight={renderTypeAccessory()}
            ref={ref}
            {...rest}
          />
        )}
      </>
    )
  }

  function renderTypeAccessory() {
    return (
      <PopupMenu items={typeMenuItems} value={type} onValueSelect={fixType}>
        {toggle => (
          <Tappable classNames={$.typeAccessory} onTap={toggle} showFocus>
            <Label small dim>
              {t(`type.${type}`)}
            </Label>
          </Tappable>
        )}
      </PopupMenu>
    )
  }

  return render()

})

export default VariantField

const useStyles = createUseStyles(theme => ({
  typeAccessory: {
    position: 'relative',

    padding:      [layout.padding.inline.s, layout.padding.inline.m],
    borderRadius: layout.radius.m,
    background:   theme.bg.subtle,

    '&::after': {
      ...layout.overlay,
      content:      '""',
      borderRadius: layout.radius.m,
    },

    '&:hover::after': {
      ...colors.overrideBackground(theme.bg.hover),
    },
    '&:active::after': {
      ...colors.overrideBackground(theme.bg.active),
    },
  },
}))