import React from 'react'
import { useTranslation } from 'react-i18next'
import { isPlainObject, omit } from 'lodash'
import { sparse } from 'ytil'
import { TranslationMap } from '~/models'
import { languagesStore, projectStore } from '~/stores'
import { memo, observer } from '~/ui/component'
import {
  Dimple,
  HBox,
  Label,
  LanguageToggleButton,
  Panel,
  PanelHeader,
  TextField,
  VBox,
} from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  value:    TranslationMap<Record<string, string>>
  onChange: (value: TranslationMap<Record<string, string>>) => any

  defaults?: TranslationMap<Record<string, string>>

  caption?:         string
  keys:             string[]
}

const TranslationsField = observer('TranslationsField', (props: Props) => {

  const {caption, keys, value, defaults, onChange} = props
  const [t] = useTranslation('translations_field')

  const project = projectStore.project
  const translations = React.useMemo(
    () => isPlainObject(value) ? value : {},
    [value],
  )

  const status = languagesStore.status

  const languages = React.useMemo(() => {
    if (status !== 'done') { return [] }

    const codes = new Set(project?.languages ?? [])
    for (const key of Object.keys(translations)) {
      codes.add(key)
    }

    return sparse(Array.from(codes).map(it => languagesStore.getLanguage(it)))
  }, [project?.languages, status, translations])


  //------
  // Language

  const [language, setLanguage] = React.useState<string>(languages[0]?.code)
  React.useEffect(() => {
    if (language != null) { return }
    if (languages.length === 0) { return }
    setLanguage(languages[0].code)
  }, [language, languages])

  //------
  // Actions

  const setTranslation = React.useCallback((key: string, translation: string) => {
    onChange({
      ...translations,
      [language]: (
        translation.trim() !== '' ? (
          {...translations[language], [key]: translation}
        ) : (
          omit(translations[language] ?? {}, key)
        )
      ),
    })
  }, [language, onChange, translations])

  //------
  // Rendering

  function render() {
    return (
      <Panel header={renderHeader()}>
        {keys.map(renderRow)}
      </Panel>
    )
  }

  function renderRow(key: string, index: number) {
    const translation = translations?.[language]?.[key] ?? ''

    return (
      <React.Fragment key={key}>
        {index > 0 && <Dimple horizontal/>}
        <TranslationsFieldRow
          translationKey={key}
          translation={translation}
          placeholder={defaults?.[language]?.[key] ?? ''}
          onChange={setTranslation}
        />
      </React.Fragment>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={caption ?? t('caption')}
        right={renderLanguageToggle()}
        small
      />
    )
  }

  function renderLanguageToggle() {
    return (
      <LanguageToggleButton
        value={language}
        onChange={setLanguage}
        languages={languages}
        small
      />
    )
  }

  return render()

})

export default TranslationsField

interface TranslationsFieldRowProps {
  translationKey: string
  translation:    string
  placeholder:    string
  onChange:       (key: string, translation: string) => any
}

const TranslationsFieldRow = memo('TranslationsFieldRow', (props: TranslationsFieldRowProps) => {

  const {translationKey, translation, placeholder, onChange} = props

  const change = React.useCallback((value: string) => {
    onChange(translationKey, value)
  }, [onChange, translationKey])

  const revertToDefault = React.useCallback(() => {
    onChange(translationKey, '')
  }, [onChange, translationKey])

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.translationsFieldRow} gap={layout.padding.inline.m}>
        <Label caption bold mono flex={1}>
          {translationKey}
        </Label>
        <VBox flex={2}>
          <TextField
            value={translation}
            placeholder={placeholder}
            onChange={change}
            inputStyle='light'
            showClearButton='notempty'
            onClear={revertToDefault}
          />
        </VBox>
      </HBox>
    )
  }

  return render()

})

const useStyles = createUseStyles({
  translationsFieldRow: {
    padding: [layout.padding.inline.xs, layout.padding.inline.m],
  },
})