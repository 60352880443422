import React from 'react'
import socket from 'socket.io-react'
import { useClock } from '~/socket/useClock'
import { projectStore } from '~/stores'
import { observer } from '~/ui/component'
import { Label, HBox, Spinner, Tappable } from '~/ui/components'
import { useViewState } from '~/ui/hooks'

export interface Props {
  showSpinnerWhenConnecting?: boolean
}

const Clock = observer('Clock', (props: Props) => {

  const {showSpinnerWhenConnecting = false} = props

  const [showSeconds, setShowSeconds] = useViewState('clock.seconds', false)

  const toggleSeconds = React.useCallback(() => {
    setShowSeconds(!showSeconds)
  }, [setShowSeconds, showSeconds])


  const {currentTime} = useClock({
    interval: showSeconds ? 'second' : 'minute',
  })
  const socketStatus = socket.status
  const ready        = socketStatus === 'ready'

  const project   = projectStore.project
  const localTime = project == null ? currentTime : currentTime?.setZone(project.timeZone)

  const width = showSeconds ? '4em' : '3em'

  //------
  // Rendering

  function render() {
    if (ready) {
      return renderClock()
    } else if (showSpinnerWhenConnecting) {
      return <Spinner/>
    } else {
      return null
    }
  }

  function renderClock() {
    if (localTime == null) { return null }

    return (
      <Tappable onTap={toggleSeconds} style={{width}}>
        <HBox align='baseline'>
          <Label h2>
            {localTime.toFormat('HH:mm')}
          </Label>
          {showSeconds && (
            <Label small dim>
              {localTime.toFormat(':ss')}
            </Label>
          )}
        </HBox>
      </Tappable>
    )
  }

  return render()

})

export default Clock