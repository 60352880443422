import React from 'react'
import { useTimer } from 'react-timer'
import { filter, some } from 'lodash'
import { forwardRef, memo } from '~/ui/component'
import { assignRef, useContinuousRef, usePrevious } from '~/ui/hooks'
import { useTheme } from '~/ui/styling'
import { Theme } from '~/ui/styling/Theme'
import GoogleMapContext from './GoogleMapContext'
import GoogleMapManager from './GoogleMapManager'
import { MapFeature } from './types'

function createFeatureComponent<T extends MapFeature, P, H = never>(displayName: string, config: MapFeatureComponentConfig<T, P, H> & {handle: MapFeatureComponentConfig<T, P, H>['handle']}): React.ForwardRefExoticComponent<React.PropsWithoutRef<P> & React.RefAttributes<H>>
function createFeatureComponent<T extends MapFeature, P, H = never>(displayName: string, config: MapFeatureComponentConfig<T, P, H>): React.FunctionComponent<P>
function createFeatureComponent<T extends MapFeature, P, H = never>(displayName: string, config: MapFeatureComponentConfig<T, P, H>) {

  const Component = (props: P, ref: H | null) => {

    const manager         = React.useContext(GoogleMapContext)
    const featureRef      = React.useRef<T>(null)
    const initialPropsRef = useContinuousRef(props)
    const prevProps       = usePrevious(props)
    const initializedRef  = React.useRef<boolean>(false)

    const timer = useTimer()

    const theme    = useTheme()
    const themeRef = useContinuousRef(theme)

    const listeners = React.useMemo<Record<string, [() => any, google.maps.MapsEventListener]>>(
      () => ({}),
      [],
    )

    const updateListeners = React.useCallback((props: P) => {
      const feature = featureRef.current
      if (manager == null || feature == null) { return }

      const nextListeners = config.listeners?.(props, feature, manager) ?? {}

      const toAdd    = filter(Object.entries(nextListeners), listener => !some(listeners, it => it[0] === listener[1]))
      const toRemove = filter(listeners, listener => !some(nextListeners, it => it === listener[0]))

      for (const [, subscription] of toRemove) {
        subscription.remove()
      }

      for (const [event, listener] of toAdd) {
        if (listener == null) { continue }
        listeners[event] = [
          listener,
          feature.addListener(event, listener),
        ]
      }


    }, [listeners, manager])

    React.useEffect(() => {
      if (manager == null) { return }
      if (initializedRef.current) { return }

      manager.queueFeature()

      const initialProps = initialPropsRef.current
      const theme        = themeRef.current

      const feature = config.create(initialProps, manager, theme)
      assignRef(featureRef, feature)

      let removeFeature: (() => any) | undefined

      timer.await(manager.ready).then(() => {
        removeFeature = manager.addFeature(feature)
        updateListeners(initialProps)
        initializedRef.current = true
      })

      return () => {
        removeFeature?.()
        config.destroy?.()
      }
    }, [initialPropsRef, manager, themeRef, timer, updateListeners])

    React.useEffect(() => {
      if (manager == null) { return }
      if (prevProps === undefined) { return }
      if (!initializedRef.current) { return }

      const feature = featureRef.current
      if (feature == null) { return }

      config.update?.(prevProps, props, feature, manager, theme)
      updateListeners(props)
    }, [manager, prevProps, props, theme, updateListeners])

    if (config.handle != null) {
      // This conditional hook is ok as the condition (config.handle != null) is based on something outside
      // of this component. It will never change during the lifetime of some element of it.
      // eslint-disable-next-line react-hooks/rules-of-hooks
      React.useImperativeHandle(ref as React.Ref<H>, (): H => {
        const feature = featureRef.current
        return config.handle!(props, feature, manager)
      }, [manager, props])
    }

    // These are virtual components.
    return null

  }

  if (config.handle != null) {
    return forwardRef(displayName, Component as any)
  } else {
    return memo(displayName, Component)
  }
}

export interface MapFeatureComponentConfig<T extends MapFeature, P, H> {
  create:     (props: P, manager: GoogleMapManager, theme: Theme) => T
  update?:    (prevProps: P, nextProps: P, feature: T, manager: GoogleMapManager, theme: Theme) => boolean
  destroy?:   () => any
  listeners?: (props: P, feature: T, manager: GoogleMapManager) => Record<string, AnyFunction | undefined>
  handle?:    (props: P, feature: T | null, manager: GoogleMapManager | null) => H
}

export default createFeatureComponent