import React from 'react'
import { AvailableFeature } from '~/models'
import { memo, observer } from '~/ui/component'
import {
  ClearButton,
  Dimple,
  HBox,
  InfoIcon,
  Label,
  Panel,
  SwitchField,
  VBox,
} from '~/ui/components'
import { FormField } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'
import { useBoolean } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import FeatureOptionsForm from './FeatureOptionsForm'

export interface Props {
  prefix:    FeaturePrefix
  available: AvailableFeature[]
}

const FeatureListFieldset = memo('FeatureListFieldset', (props: Props) => {

  const {prefix, available} = props

  const [optionsFormOpen, openOptionsForm, closeOptionsForm] = useBoolean()
  const [configuringFeature, setConfiguringFeature] = React.useState<AvailableFeature | null>(null)

  const configureFeature = React.useCallback((feature: AvailableFeature) => {
    setConfiguringFeature(feature)
    openOptionsForm()
  }, [openOptionsForm])


  //------
  // Rendering

  function render() {
    return (
      <Panel semi={false}>
        {available.map((feature, index) => (
          <React.Fragment key={feature.name}>
            {index > 0 && <Dimple horizontal/>}
            <FeatureToggle
              prefix={prefix}
              feature={feature}
              requestConfigure={configureFeature}
            />
          </React.Fragment>
        ))}

        {configuringFeature != null && (
          <FeatureOptionsForm
            open={optionsFormOpen}
            requestClose={closeOptionsForm}

            prefix={prefix}
            feature={configuringFeature}
          />
        )}
      </Panel>
    )
  }

  return render()

})

export default FeatureListFieldset

interface FeatureToggleProps {
  prefix:  string
  feature: AvailableFeature

  requestConfigure?: (feature: AvailableFeature) => any
}

const FeatureToggle = observer('FeatureToggle', (props: FeatureToggleProps) => {

  const {prefix, feature, requestConfigure} = props

  const enabledFieldName = `${prefix}.${feature.name}.enabled`
  const [enabled] = useFormField<boolean>(enabledFieldName)

  const configure = React.useCallback(() => {
    requestConfigure?.(feature)
  }, [feature, requestConfigure])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.featureToggle}>
        <VBox flex>
          {renderField()}
        </VBox>
        <HBox gap={layout.padding.inline.s}>
          {renderConfigureButton()}
          {renderInstruction()}
        </HBox>
      </HBox>
    )
  }

  function renderField() {
    return (
      <FormField name={enabledFieldName} caption={false}>
        {bind => (
          <SwitchField
            {...bind}
            label={renderLabel()}
          />
        )}
      </FormField>
    )
  }

  function renderLabel() {
    return (
      <VBox gap={layout.padding.inline.xs}>
        <Label caption>
          {feature.translate('caption')}
        </Label>
        <Label small dim>
          {feature.translate('detail')}
        </Label>
      </VBox>
    )
  }

  function renderConfigureButton() {
    if (!enabled) { return null }
    if (requestConfigure == null) { return null }
    if (Object.keys(feature.optionsSchema).length === 0) { return null }

    return (
      <ClearButton
        icon='cog'
        onTap={configure}
        padding='both'
      />
    )
  }

  function renderInstruction() {
    const instruction = feature.translate('instruction')
    if (instruction == null) { return null }

    return (
      <InfoIcon renderTooltip={instruction}/>
    )
  }

  return render()

})

export type FeaturePrefix = 'features' | 'appFeatures'

const useStyles = createUseStyles(theme => ({
  featureToggle: {
    padding: [layout.padding.inline.s, layout.padding.inline.m],
    '&:hover': {
      background: theme.bg.hover,
    },
  },
}))
