import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonFeedback, ChoiceFeedback } from '~/models'
import { memo } from '~/ui/component'
import { SelectField, TextBlock, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

export interface Props {
  question: ChoiceFeedback | ButtonFeedback
}

const ChoiceTestFields = memo('ChoiceTestFields', (props: Props) => {

  const {question} = props
  const choices = question.type === 'button' ? question.buttons : question.choices

  const [t] = useTranslation('conditions')

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('tests.choice.preamble')}
        </TextBlock>
        {renderOperandField()}
      </VBox>
    )
  }

  function renderOperandField() {
    return (
      <FormField name='operand' caption={false}>
        {bind => <SelectField {...bind} choices={operandChoices}/>}
      </FormField>
    )
  }

  const operandChoices = React.useMemo(() => choices.map((choice): Choice<string> => ({
    value:   (choice.value ?? choice.caption).toString(),
    caption: choice.caption,
  })), [choices])

  return render()

})

export default ChoiceTestFields