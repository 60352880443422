import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { Center, ClearButton, PushButton, TextField, VBox } from '~/ui/components'
import { Form, FormField, FormProps, useAutoFilledDetection } from '~/ui/form'
import { useMountTrace } from '~/ui/hooks'
import { layout } from '~/ui/styling'
import AuthForm from '../AuthForm'
import ForgotPasswordFormModel from './ForgotPasswordFormModel'

export interface Props extends FormProps<ForgotPasswordFormModel> {
  onBackTap?: () => any
}

const ForgotPasswordForm = observer('ForgotPasswordForm', (props: Props) => {

  const {model: formModel, onBackTap} = props

  const emailFieldRef = React.useRef<HTMLInputElement>(null)
  const autoFilled    = useAutoFilledDetection([emailFieldRef])
  const maySubmit     = autoFilled || formModel.maySubmit

  const [t] = useTranslation('auth')

  useMountTrace('ForgotPasswordForm')

  //------
  // Rendering

  function render() {
    return (
      <Form {...props}>
        {form => (
          <AuthForm preamble={t('forgot_password.instruction')}>
            {renderFields()}
            {renderButtons(form.submitting)}
          </AuthForm>
        )}
      </Form>
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.s}>
        {renderEmailField()}
      </VBox>
    )
  }

  function renderEmailField() {
    return (
      <FormField align='center' name='email' caption={false}>
        {bind => (
          <TextField
            {...bind}
            ref={emailFieldRef}
            autoFocus
            placeholder={t('forgot_password.fields.email')}
            inputAttributes={{
              autoCapitalize: 'off',
              autoCorrect:    'off',
              autoComplete:   'username',
            }}
            align='center'
            inputStyle='dark'
          />
        )}
      </FormField>
    )
  }

  function renderButtons(submitting: boolean) {
    return (
      <VBox gap={layout.padding.s}>
        <PushButton
          icon='reset'
          caption={t('forgot_password.submit')}
          enabled={maySubmit}
          working={submitting}
          submit
        />
        {onBackTap != null && (
          <Center>
            <ClearButton
              icon='arrow-left'
              caption={t('forgot_password.back')}
              onTap={onBackTap}
              align='center'
              padding='horizontal'
              dim
            />
          </Center>
        )}
      </VBox>
    )
  }

  return render()

})

export const avatarSize = {
  width:  48,
  height: 48,
}

export default ForgotPasswordForm