import React from 'react'
import { Competition } from '~/models'
import { memo } from '~/ui/component'
import { ClearButton, HBox, Label, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import TeamScoringForm from './TeamScoringForm'

export interface Props {
  competition: Competition
}

const TeamScoringSummary = memo('TeamScoringSummary', (props: Props) => {

  const {competition} = props
  const [formOpen, openForm, closeForm] = useBoolean()

  const {t} = useResourceTranslation('competitions')

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item icon='trophy'>
        <HBox align='top'>
          {renderSummary()}
          {renderEditButton()}
        </HBox>

        <TeamScoringForm
          open={formOpen}
          requestClose={closeForm}
          competition={competition}
        />
      </SummaryInfo.Item>
    )
  }

  function renderSummary() {
    const translationKey     = `fields.normalize.summary.${competition.normalize != null ? 'on' : 'off'}`
    const options: AnyObject = {...competition.normalize}

    return (
      <VBox flex>
        <Label small dim>
          {t(translationKey, options)}
        </Label>
      </VBox>
    )
  }

  function renderEditButton() {
    return (
      <ClearButton
        icon='pencil'
        onTap={openForm}
        padding='both'
      />
    )
  }

  return render()

})

export default TeamScoringSummary