import I18n from 'i18next'
import { Scoring } from './Competition'
import { Feedback } from './Feedback'
import { Link } from './Link'
import { Media } from './Media'
import { Ref } from './types'

export type MessageTemplate =
  | TextMessageTemplate
  | ImageMessageTemplate
  | VideoMessageTemplate
  | WidgetMessageTemplate
  | NoticeMessageTemplate
  | RedactMessageTemplate

export type TextMessage   = TextMessageTemplate & MessageCommon
export type ImageMessage  = ImageMessageTemplate & MessageCommon
export type VideoMessage  = VideoMessageTemplate & MessageCommon
export type NoticeMessage = NoticeMessageTemplate & MessageCommon
export type RedactMessage = RedactMessageTemplate & MessageCommon
export type WidgetMessage = WidgetMessageTemplate & MessageCommon

export interface MessageTemplateCommon {
  feedback: MessageFeedback | null
}

export interface MessageCommon {
  read:   boolean | null
  sentAt: Date | null
}

export interface TextMessageTemplate extends MessageTemplateCommon {
  type: 'text'
  text: string
  link: Link | null
}

export interface ImageMessageTemplate extends MessageTemplateCommon {
  type:     'image'
  image:    MessageMedia
  caption:  string
}

export interface VideoMessageTemplate extends MessageTemplateCommon {
  type:     'video'
  video:    MessageMedia
  caption:  string
}

export interface NoticeMessageTemplate extends MessageTemplateCommon {
  type:   'notice'
  text:   string
  sticky: boolean
  link:   Link | null
}

export interface RedactMessageTemplate extends MessageTemplateCommon {
  type: 'redact'
}

export interface WidgetMessageTemplate extends MessageTemplateCommon {
  type:  'widget'
  widget: string
  params: Record<string, any>
}

export interface MessageMedia {
  media?:      Ref<Media> // Older messages will not have a Media object attached to it.
  name:        string
  contentType: string
  url:         string
}

export type MessageFeedback = MessageFeedbackCommon & Feedback

interface MessageFeedbackCommon {
  skippable:    boolean
  skipText?:    string
  variable:     string | null
  expiresAfter: number | null
  scorings?:    Scoring[]
  review:       boolean
}

export function messageSummary(message: MessageTemplate) {
  if (message.type === 'text' || message.type === 'notice') {
    return message.text
  } else {
    return I18n.t(`messages:summary.${message.type}`, {message})
  }
}