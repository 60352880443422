import React from 'react'
import DraftJSBackend from './backend/DraftJSBackend'
import MarkdownBackend from './backend/MarkdownBackend'

export interface RichTextFieldContext {
  backend:   MarkdownBackend | DraftJSBackend | null
  setModals: (handle: any, modals: React.ReactNode) => () => any
}

export const RichTextFieldContext = React.createContext<RichTextFieldContext>({
  backend:   null,
  setModals: () => () => undefined,
})

export function useRichTextContext() {
  return React.useContext(RichTextFieldContext)
}

export function useRichTextBackend() {
  const context = useRichTextContext()
  if (context.backend == null) {
    throw new Error("useRichTextBackend() may only be called inside of a RichTextContext")
  }

  return context.backend
}