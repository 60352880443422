import { DateTime } from 'luxon'
import { makeObservable, observable } from 'mobx'
import * as UUID from 'uuid'
import { cleanTextValue } from 'ytil'
import {
  Challenge,
  ChallengePublishedTrigger,
  DateTrigger,
  Group,
  GroupJoinTrigger,
  Location,
  LocationEnterTrigger,
  Ref,
  Script,
  ScriptEndTrigger,
  Trigger,
  TriggerNode,
  WebhookTrigger,
} from '~/models'
import { FlowPlanner } from '~/stores'
import { SubmitResult } from '~/ui/form'

export default class TriggerFormModel {

  constructor(
    private planner:   FlowPlanner,
    private trigger:   Partial<Trigger>,
    private node:      TriggerNode | null,
    private operation: TriggerFormOperation,
  ) {
    makeObservable(this)
  }

  @observable
  public name: string = this.node?.name ?? ''

  // Type: `date`

  @observable
  public date: DateTime = (this.trigger as DateTrigger).date

  // Type: `group:*`

  @observable
  public groupOption: 'any' | 'group' | 'tags' =
    (this.trigger as GroupJoinTrigger).group != null ? 'group' :
    (this.trigger as GroupJoinTrigger).tags?.length ? 'tags' :
    'any'

  @observable
  public group: Ref<Group> | null = (this.trigger as GroupJoinTrigger).group

  @observable
  public tags: string[] = (this.trigger as GroupJoinTrigger).tags

  // Type: `location:*`

  @observable
  public location: Ref<Location> | null = (this.trigger as LocationEnterTrigger).location

  // Type: `script:*`

  @observable
  public script: Ref<Script> | null = (this.trigger as ScriptEndTrigger).script

  // Type: `challenge:*`

  @observable
  public challenge: Ref<Challenge> | null = (this.trigger as ChallengePublishedTrigger).challenge

  // Type: 'webhook'

  @observable
  public collective: boolean = (this.trigger as WebhookTrigger).collective ?? false

  //------
  // Submission

  public async submit(): Promise<SubmitResult | undefined> {
    if ('create' in this.operation) {
      const bounds = {
        left:   this.operation.create.x,
        top:    this.operation.create.y,
        width:  -1,
        height: -1,
      }

      return this.planner.createNode({
        uuid:    UUID.v4(),
        type:    'trigger',
        name:    null,
        trigger: this.buildTrigger() as Trigger,
        bounds:  bounds,
      })
    } else {
      return this.planner.updateComponents([this.operation.update], node => ({
        ...node,
        ...this.buildUpdate(),
      }))
    }
  }

  private buildUpdate(): AnyObject {
    return {
      name:    cleanTextValue(this.name, true),
      trigger: this.buildTrigger(),
    }
  }

  private buildTrigger(): AnyObject {
    const common = {
      type: this.trigger.type,
    }

    switch (this.trigger.type) {
    case 'date':
      return {
        ...common,
        date: this.date,
      }
    case 'group:join':
    case 'group:leave':
      return {
        ...common,
        group: this.groupOption === 'group' ? this.group : null,
        tags:  this.groupOption === 'tags' ? this.tags : [],
      }
    case 'location:enter':
    case 'location:exit':
      return {
        ...common,
        location: this.location,
      }
    case 'script:end':
      return {
        ...common,
        script: this.script,
      }
    case 'challenge:published':
    case 'challenge:abandoned':
    case 'challenge:completed':
    case 'challenge:reviewed':
      return {
        ...common,
        challenge: this.challenge,
      }
    case 'webhook':
      return {
        ...common,
        collective: this.collective,
      }
    default:
      return common
    }
  }

}

export type TriggerFormOperation = {
  create: Point
} | {
  update: string
}