import { Variant } from './custom-data'

export interface Condition {
  selector: ConditionSelector
  operator: ConditionOperator
  operand:  Variant
}

export interface QuestionSelector {
  type:         'script-question' | 'challenge-task'
  model:        string
  questionUUID: string
}

export interface QuestionDescriptor extends QuestionSelector {
  text: string
}

export type ConditionSelector = QuestionSelector | VariableSelector

export interface VariableSelector {
  type:     'variable'
  variable: string
}

export type ConditionOperator =
  '==' | '!=' |
  '>' | '>=' | '<' | '<=' |
  'empty' | '!empty' |
  'is' | '!is' |
  'contains' | '!contains' |
  'match' | '!match'

export const ConditionOperator: {all: ConditionOperator[]} = {
  all: [
    '==', '!=',
    '>', '>=', '<', '<=',
    'empty', '!empty',
    'is', '!is',
    'contains', '!contains',
    'match', '!match',
  ],
}

export function operatorRequiresOperand(operator: ConditionOperator) {
  return operator !== 'empty' && operator !== '!empty'
}

export function operatorRequiresTextOperand(operator: ConditionOperator) {
  if (operator === 'is' || operator === '!is') { return true }
  if (operator === 'match' || operator === '!match') { return true }
  if (operator === 'contains' || operator === '!contains') { return true }

  return false
}