import React from 'react'
import { RegistrationPreset } from '~/models'
import { authenticationStore } from '~/stores'
import OrganisationField from '~/ui/app/organisations/OrganisationField'
import { observer } from '~/ui/component'
import { SwitchField, TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  model?:       RegistrationPreset | null
  afterSubmit?: FormProps<any>['afterSubmit']
}

const RegistrationPresetForm = observer('RegistrationPresetForm', (props: Props) => {

  const {
    open, requestClose,
    model,
    afterSubmit,
  } = props

  const user    = authenticationStore.user
  const isSuper = user?.isSuper()

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog
        open={open}
        requestClose={requestClose}

        Model={RegistrationPreset}
        model={model}

        afterSubmit={afterSubmit}

        children={renderContent()}
        semi={false}
      />
    )
  }

  function renderContent() {
    return (
      <VBox gap={layout.padding.s}>
        {isSuper && (
          <FormField name='organisation' required>
            {bind => <OrganisationField {...bind}/>}
          </FormField>
        )}

        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='requireCode' required>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default RegistrationPresetForm