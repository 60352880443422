import React from 'react'
import { LocalizedString, Page, PageType } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import { Dimple, Scroller, VBox } from '~/ui/components'
import { Markdown } from '~/ui/components/markdown'
import { SubmitResult } from '~/ui/form'
import { useBoolean } from '~/ui/hooks'
import { ResourceFromLinkedProjectNotice } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import PageCaptionEditor from '../PageCaptionEditor'
import { usePagesContext } from '../PagesContext'
import PageForm from '../create/PageForm'
import MenuPageList from './MenuPageList'
import { useMenuPageStore } from './MenuPageStore'
import MenuPageToolbar, { NewMenuPageItemType } from './MenuPageToolbar'

export interface Props {
  page:         Page
  interactive?: boolean
}

const MenuPageBody = observer('MenuPageBody', (props: Props) => {

  const {page, interactive = true} = props
  const {pushSlug, updatePage} = usePagesContext()

  const [editingCaption, startEditCaption, stopEditCaption] = useBoolean()
  const [createFormOpen, openCreateForm, closeCreateForm] = useBoolean()
  const [createType, setCreateType] = React.useState<PageType>('content')

  const store = useMenuPageStore(page)

  //------
  // Actions

  const changeLayout = React.useCallback((layout: Page['layout']) => {
    updatePage(page.id, {layout})
  }, [page.id, updatePage])

  const addItem = React.useCallback((type: NewMenuPageItemType) => {
    if (type === 'link') {
      store.setNewItem(true)
    } else {
      setCreateType(type)
      openCreateForm()
    }
  }, [openCreateForm, store])

  const goToCreatedPage = React.useCallback((result: SubmitResult) => {
    if (result.status !== 'ok' || result.data?.id == null) { return }

    const createdPage = dataStore.get(Page, result.data.id)
    if (createdPage != null) {
      pushSlug(createdPage.slug, page.slug)
    }
  }, [page.slug, pushSlug])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='grow'>
        {!interactive && (
          <ResourceFromLinkedProjectNotice
            Model={Page}
            className={$.linkedProjectNotice}
          />
        )}

        {editingCaption && renderCaptionEditor()}

        <Scroller>
          <VBox flex='grow'>
            {!editingCaption && renderCaption()}
            <VBox flex='grow' classNames={$.body}>
              {renderBody()}
            </VBox>
          </VBox>
        </Scroller>
        {interactive && !editingCaption && (
          <VBox gap={layout.padding.s} classNames={$.toolbar}>
            <Dimple horizontal/>
            {renderToolbar()}
          </VBox>
        )}
        {renderCreatePageForm()}
      </VBox>
    )
  }

  function renderToolbar() {
    return (
      <MenuPageToolbar
        store={store}

        layout={page.layout}
        requestLayout={changeLayout}

        requestAddItem={addItem}
      />
    )
  }

  function renderCaption() {
    if (interactive) { return renderCaptionEditor() }
    return (
      <Markdown classNames={$.caption}>
        {LocalizedString.translate(page.caption)}
      </Markdown>
    )
  }

  function renderCaptionEditor() {
    return (
      <PageCaptionEditor
        page={page}
        editing={editingCaption}
        requestStartEdit={startEditCaption}
        requestStopEdit={stopEditCaption}
      />
    )
  }

  function renderBody() {
    return (
      <MenuPageList
        page={page}
        store={store}
        interactive={interactive}
      />
    )
  }

  function renderCreatePageForm() {
    return (
      <PageForm
        open={createFormOpen}
        requestClose={closeCreateForm}
        type={createType}
        parentMenuPageID={page.id}
        afterSubmit={goToCreatedPage}
      />
    )
  }

  return render()

})

export default MenuPageBody

const useStyles = createUseStyles({
  linkedProjectNotice: {
    ...layout.responsive(size => ({
      margin: layout.padding.s[size],
    })),
  },

  caption: {
    ...layout.responsive(size => ({
      padding: layout.padding.s[size],
    })),
  },

  body: {
    ...layout.responsive(size => ({
      padding:    layout.padding.s[size],
      paddingTop: 0,
    })),
  },

  toolbar: {
    ...layout.responsive(size => ({
      padding:    layout.padding.s[size],
      paddingTop: 0,
    })),
  },
})