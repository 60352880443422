import React from 'react'
import { WebLayout } from '~/models'
import { memo } from '~/ui/component'
import { Center, Scroller, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'

const WebSettings = memo('WebSettings', () => {

  const {t} = useResourceTranslation()
  const layoutChoices = React.useMemo((): Choice<WebLayout>[] => [{
    value: 'live',
    caption: t('fields.web_layout.choices.live'),
  }, {
    value: 'chat',
    caption: t('fields.web_layout.choices.chat'),
  }, {
    value: 'full',
    caption: t('fields.web_layout.choices.full'),
  }], [t])

  return (
    <Scroller>
      <Center>
        <VBox width={360}>
          <FormField name='web.layout' i18nKey='web_layout'>
            {bind => <SelectField {...bind} choices={layoutChoices}/>}
          </FormField>
        </VBox>
      </Center>
    </Scroller>
  )

})

export default WebSettings