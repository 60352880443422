import React from 'react'
import { BrandedComponentSpec } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { BrandedComponent, HBox, SVG, VBox } from '~/ui/components'
import { AppHeaderBranding, colors, createUseStyles, layout } from '~/ui/styling'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const AppHeaderBrandingPreview = memo('AppHeaderBrandingPreview', (props: Props) => {

  const {componentName, variant, spec} = props
  const {guide} = useBranding()

  const [component, flags, size] = useComponentBranding<AppHeaderBranding>(componentName, variant, spec)
  const height = variant === 'ios' ? appHeaderDefaultHeight.ios : appHeaderDefaultHeight.android

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (component == null) { return null }

    return (
      <BrandedComponent
        guide={guide ?? undefined}
        branding={component}
        variant={flags}
        height={height}
        style={{width: size.width, height}}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox>
        {renderAppStatusBar()}
        {renderBody()}
      </VBox>
    )
  }

  function renderAppStatusBar() {
    if (variant !== 'ios') { return null }

    return (
      <HBox classNames={$.appStatusBar} justify='space-between'>
        <SVG
          name='ios-status-bar-left'
          size={{width: 74, height: 20}}
        />
        <SVG
          name='ios-status-bar-center'
          size={{width: 74, height: 20}}
        />
        <SVG
          name='ios-status-bar-right'
          size={{width: 74, height: 20}}
        />
      </HBox>
    )
  }

  function renderBody() {
    return (
      <VBox classNames={$.body} align='left'>
        <ImageView
          source={guide.image('logo', 'app') ?? {icon: 'logo'}}
          size={variant === 'ios' ? logoSize.ios : logoSize.android}
          objectFit='contain'
        />
      </VBox>
    )
  }

  return render()

})

registerComponentBrandingPreview('app.header', AppHeaderBrandingPreview)
export default AppHeaderBrandingPreview

export const appHeaderDefaultHeight = {
  ios:     64 + 20,
  android: 64,
}

export const logoSize = {
  ios: {
    width:  appHeaderDefaultHeight.ios - 20 - layout.padding.inline.xs * 2,
    height: appHeaderDefaultHeight.ios - 20 - layout.padding.inline.xs * 2,
  },
  android: {
    width:  appHeaderDefaultHeight.android - layout.padding.inline.xs * 2,
    height: appHeaderDefaultHeight.android - layout.padding.inline.xs * 2,
  },
}

const useStyles = createUseStyles({
  appHeaderBrandingPreview: {
    borderTopWidth:   0,
    borderLeftWidth:  0,
    borderRightWidth: 0,
  },

  body: {
    padding: [layout.padding.inline.xs, layout.padding.inline.s],
  },

  appStatusBar: {
    height:        20,
    padding:       [layout.padding.inline.xs, layout.padding.inline.s],
    background:    colors.shim.light,

    '& span': {
      font: {
        size:   10,
        weight: 'bold',
        family: 'sans-serif',
      },
    },
  },
})