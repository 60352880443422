import React from 'react'
import { projectStore } from '~/stores'
import { FeatureListFieldset } from '~/ui/app/fields'
import { observer } from '~/ui/component'

const AppFeatureList = observer('AppFeatureList', () => {

  return (
    <FeatureListFieldset
      prefix='appFeatures'
      available={projectStore.availableAppFeatures}
    />
  )

})

export default AppFeatureList