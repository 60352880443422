import React from 'react'
import { authenticationStore } from '~/stores'
import { observer } from '~/ui/component'
import { ClearButton, TextField } from '~/ui/components'
import { FormDialog, FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import ChangePasswordFormModel from './ChangePasswordFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  userID: string
}

const ChangePasswordForm = observer('ChangePasswordForm', (props: Props) => {

  const {userID, ...rest} = props

  const me   = authenticationStore.user
  const isMe = userID === me?.id

  const formModel = React.useMemo(() => {
    return new ChangePasswordFormModel(userID)
  }, [userID])

  const {actionCaption} = useResourceTranslation('users')

  //------
  // Callbacks

  const toggleShowPassword = React.useCallback(() => {
    formModel?.toggleShowPassword()
  }, [formModel])

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog
        model={formModel}
        {...rest}

        semi={false}
        title={actionCaption('change_password')}
        submitButton={{
          caption: actionCaption('change_password'),
        }}
      >
        {isMe && (
          <FormField name='currentPassword' required>
            {bind => (
              <TextField
                {...bind}
                type='password'
                autoComplete='current-password'
              />
            )}
          </FormField>
        )}

        <FormField name='newPassword' required>
          {bind => (
            <TextField
              {...bind}
              type={formModel.showPassword ? 'text' : 'password'}
              autoComplete='new-password'
              accessoryRight={renderShowPasswordToggle()}
            />
          )}
        </FormField>

        <FormField name='repeatPassword' required>
          {bind => (
            <TextField
              {...bind}
              type={formModel.showPassword ? 'text' : 'password'}
              autoComplete='new-password'
              enabled={!formModel.showPassword}
            />
          )}
        </FormField>
      </FormDialog>
    )
  }

  function renderShowPasswordToggle() {
    return (
      <ClearButton
        icon={formModel?.showPassword ? 'eye' : 'eye-off'}
        onTap={toggleShowPassword}
      />
    )
  }

  return render()

})

export default ChangePasswordForm