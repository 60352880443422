import React from 'react'
import { useSize } from 'react-measure'
import { memo } from '~/ui/component'
import { Center, VBox, VBoxProps } from '~/ui/components'
import { layout } from '~/ui/styling'
import ComponentBrandingTile, {
  padding as componentTilePadding,
  Props as ComponentBrandingTileProps,
} from './ComponentBrandingTile'
import { useComponentBranding } from './hooks'

export interface Props extends ComponentBrandingTileProps {
  flex?: VBoxProps['flex']
}

const ComponentBrandingAutoSizedTile = memo('ComponentBrandingAutoSizedTile', (props: Props) => {

  const {flex, ...rest} = props

  const [, , componentSize]= useComponentBranding(props.componentName, props.variant, props.spec)

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [containerSize, setContainerSize] = React.useState<Size>({width: 0, height: 0})
  useSize(containerRef, setContainerSize)

  const width = React.useMemo(
    () => Math.min(containerSize.width - padding, componentSize.width + 2 * componentTilePadding),
    [componentSize.width, containerSize.width],
  )

  //------
  // Rendering

  function render() {
    return (
      <Center flex={flex} ref={containerRef}>
        <VBox style={{minWidth: width}}>
          <ComponentBrandingTile
            {...rest}
          />
        </VBox>
      </Center>
    )
  }

  return render()

})

export const padding = layout.padding.inline.l

export default ComponentBrandingAutoSizedTile