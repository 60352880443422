import Toast from 'react-toast'
import { isArray, some } from 'lodash'
import { DateTime } from 'luxon'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { FlowPlan, Insight, ModuleNode, PlannerActionCategoryDescriptor } from '~/models'
import { Pack } from '../../data'
import dataStore from '../../dataStore'
import { submitResultForResponse } from '../../support'
import {
  FlowPlanInsights,
  FlowPlannerServiceMeta,
  FlowPlanRuleResult,
  PlanPositionInsightsSummary,
  PositionLocator,
} from '../types'
import FlowPlanPositionInsightsEndpoint from './FlowPlanPositionInsightsEndpoint'

export default class FlowPlannerService extends OnDemandService {

  constructor(
    socket: Socket,
    private readonly moduleID: string,
    private readonly setActions: (categories: PlannerActionCategoryDescriptor[]) => any,
  ) {
    super(socket)
    makeObservable(this)
  }

  @observable
  public planID: string | null = null

  @computed
  public get plan() {
    if (this.planID == null) { return null }
    return dataStore.get<FlowPlan>(FlowPlan, this.planID)
  }

  @action
  public async start() {
    await super.startWithEvent('flow-planner:start', {
      moduleID: this.moduleID,
    })
  }

  protected override onStarted = action((result: StartSuccess<Pack<FlowPlan>>) => {
    this.socket.prefix = `flow-planner:${this.uid}:`

    const pack     = result.data
    const document = dataStore.storePack(pack)

    this.planID = document?.id ?? null
    this.storeMeta(pack.meta)

    this.socket.on('insights', this.onInsightsUpdate)

    // Now rehydrate.
    this.rehydrate()
  })

  public override stop() {
    this.persistDisposer?.()
    super.stop()
  }

  public override restart() {
    this.persistDisposer?.()
    this.planID = null
    return super.restart()
  }

  //------
  // Interface

  @observable
  public publishing: boolean = false

  @observable
  public lastPublished: DateTime | null = null

  @computed
  public get mayPublish() {
    return !some(this.rules, result => result.rule.level === 'error')
  }

  @computed
  public get modified() {
    return this.plan?.modified ?? false
  }

  @action
  public async validate() {
    const response = await this.socket.send('validate', {
      data: {
        type: 'flow-plans',
        id:   this.planID,
      },
    })

    if (response.ok) {
      dataStore.storePack(response.body)
      this.storeMeta(response.body.meta)
    }

    return submitResultForResponse(response)
  }

  @action
  public async update(update: Partial<FlowPlan>) {
    const response = await this.socket.send('save-draft', {
      data: {
        type:       'flow-plans',
        id:         this.planID,
        attributes: FlowPlan.serializePartial(update),
      },
    })

    if (response.ok) {
      dataStore.storePack(response.body)
      this.storeMeta(response.body.meta)
    } else if (DEV && response.error.status === 422) {
      Toast.show({
        type: 'error',
        title: "Validation error",
        detail: "Check console",
      })
    }

    return submitResultForResponse(response)
  }

  @action
  public async publishChanges() {
    if (this.publishing) { return }
    this.publishing = true

    const response = await this.socket.send('publish')
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)

        this.publishing    = false
        this.lastPublished = DateTime.local()
      })
    }

    return submitResultForResponse(response)
  }

  public async configureModuleNode(uuid: string, update: Pick<ModuleNode, 'name' | 'params'>) {
    const response = await this.socket.send('module:configure', uuid, update)
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)
      })
    }

    return submitResultForResponse(response)
  }

  @action
  public async rollbackChanges() {
    const response = await this.socket.send('rollback')
    if (response.ok) {
      runInAction(() => {
        dataStore.storePack(response.body)
        this.storeMeta(response.body.meta)
      })
    }

    return submitResultForResponse(response)
  }

  //------
  // Metadata

  @action
  private storeMeta(meta: FlowPlannerServiceMeta) {
    if (meta.insights != null) {
      this.insights = meta.insights
    }
    if (meta.actions != null) {
      this.setActions(meta.actions)
    }
    if (meta.rules != null) {
      this.rules = meta.rules
    }

    // Any silenced rules that don't occur anymore need to be removed, as they need to re-appear when they occur again.
    this.silencedRules = this.silencedRules.filter(hash => some(this.rules, result => hash === result.hash))
  }

  //------
  // FlowPlanner runtime

  @observable
  public insights: FlowPlanInsights | null = null

  private onInsightsUpdate = action((insights: FlowPlanInsights) => {
    this.insights = insights
  })

  public positionsInsightsEndpoint(node: PositionLocator) {
    return new FlowPlanPositionInsightsEndpoint(this.socket, node, dataStore.db(Insight))
  }

  @action
  public async activateNodes(uuids: string[], participantIDs: Array<string | null>, options: ActivateNodeOptions = {}) {
    return this.socket.send('runtime:activate_nodes', uuids, participantIDs, options)
      .then(response => submitResultForResponse(response))
  }

  @action
  public async abandonPositions(query: PositionQuery) {
    const response = await this.socket.send('runtime:abandon_positions', query)
    const result   = submitResultForResponse(response)
    if (result.status === 'ok') {
      this.removeFromInsightsSummaries(query)
    }

    return result
  }

  @action
  public removeFromInsightsSummaries(query: PositionQuery) {
    if (this.insights == null) { return null }

    const removeFromInsightsSummary = (summary: PlanPositionInsightsSummary) => {
      const nodeUUIDs      = query.node === undefined ? undefined : isArray(query.node) ? query.node : [query.node]
      const segueUUIDs     = query.segue === undefined ? [null] : isArray(query.segue) ? query.segue : [query.segue]
      const presenceUUIDs  = query.uuid === undefined ? undefined : isArray(query.uuid) ? query.uuid : [query.uuid]
      const participantIDs = query.participant === undefined ? undefined : isArray(query.participant) ? query.participant : [query.participant]

      if (nodeUUIDs != null && !nodeUUIDs.includes(summary.position.node)) { return summary }
      if (!segueUUIDs.includes(summary.position.segue)) { return summary }

      summary.presences = summary.presences.filter(presence => {
        if (presenceUUIDs == null && participantIDs == null) { return false }
        if (presenceUUIDs?.includes(presence.id)) { return false }
        if (participantIDs?.includes(presence.participantID)) { return false }
        return true
      })

      return summary
    }

    this.insights = {
      ...this.insights,
      summaries: this.insights.summaries.map(removeFromInsightsSummary),
    }
  }

  @action
  public async obtainWebhookToken(nodeUUID: string): Promise<string | null> {
    const response = await this.socket.send('webhook:token', nodeUUID)
    return response.ok ? response.body : null
  }

  //------
  // Warnings

  @observable
  public rules: FlowPlanRuleResult[] = []

  @observable
  public silencedRules: string[] = []

  @observable
  public silenceRule(hash: string) {
    this.silencedRules.push(hash)
  }

  //------
  // Persistence

  private persistDisposer?: IReactionDisposer

  public onRequestRehydrate?: (planID: string) => any
  public onRequestPersist?:   (planID: string, insights: any) => void

  @action
  private rehydrate() {
    if (this.planID == null) { return }

    const insights = this.onRequestRehydrate?.(this.planID)
    if (insights?.silencedRules != null) {
      this.silencedRules = insights.silencedRules
    }

    this.persistDisposer = reaction(() => this.persistedState, insights => {
      if (this.planID == null) { return }
      this.onRequestPersist?.(this.planID, insights)
    })
  }

  @computed
  private get persistedState() {
    return {
      silencedRules: [...this.silencedRules],
    }
  }

}

export interface ActivateNodeOptions {
  params?: AnyObject
}

export interface PositionQuery {
  uuid?:        string | string[]
  participant?: string | null | Array<string | null>
  node?:        string | string[]
  segue?:       string | null
}