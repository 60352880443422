import React from 'react'
import { getClientPoint } from 'react-dnd'
import { useTimer } from 'react-timer'
import * as UUID from 'uuid'
import { objectEquals } from 'ytil'
import {
  isNode,
  isSegue,
  PlanNodeConnector,
  PlanNodeConnectorMask,
  PlanSegue,
  PlanSegueConnection,
  Targeting,
} from '~/models'
import { observer } from '~/ui/component'
import { isSuccessResult } from '~/ui/form'
import { useContinuousRef } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { useFlowPlanner } from '../FlowPlannerContext'
import SegueArrow, { SegueArrowEnd } from '../segues/SegueArrow'
import ConnectHandles from './ConnectHandles'
import { useCanvas } from './FlowPlannerCanvasContext'
import { directionForConnector, findConnectionPoint } from './layout'
import { useSelection } from './SelectionContext'

export interface Props {
  enabled:   boolean
  transform: string
  zoom:      number
}

const ConnectLayer = observer('ConnectLayer', (props: Props) => {

  const {enabled, transform, zoom} = props

  const {setMode}       = useCanvas()
  const {planner, plan} = useFlowPlanner()

  const timer = useTimer()

  const {pointToCanvas, pointToScreen} = useCanvas()

  const layerRef = React.useRef<HTMLDivElement>(null)

  const {selectedComponents, manager} = useSelection.unoptim()
  const nodeBounds = planner.componentBounds.filter(bounds => isNode(bounds.component))

  // If enabled (after pressing C), show all nodes with all connectors, for the purpose of creating a new segue.
  // If not, but segues are selected, show all connectors for those segues, for the purpose of redirecting those segues.

  const [redirectActive, setRedirectActive] = React.useState<boolean>(false)
  const [fromConnection, setFromConnection] = React.useState<PlanSegueConnection | null>(null)

  const selectedSegues = selectedComponents.filter(comp => isSegue(comp)) as PlanSegue[]
  const shownConnectors: Record<string, PlanNodeConnector | PlanNodeConnectorMask> = React.useMemo(() => {
    const connectors: Record<string, PlanNodeConnector | PlanNodeConnectorMask> = {}
    if (enabled || redirectActive) {
      for (const {component} of nodeBounds ?? []) {
        if (fromConnection != null && component.type === 'trigger') {
          // It should not be allowed to connect TO a trigger node.
          connectors[component.uuid] = PlanNodeConnectorMask.NONE
        } else if (component.type === 'entry') {
          // Only use the south connectors.
          connectors[component.uuid] = PlanNodeConnectorMask.SOUTH
        } else if (component.type === 'exit') {
          // Only use the north connectors.
          connectors[component.uuid] = PlanNodeConnectorMask.NORTH
        } else {
          connectors[component.uuid] = PlanNodeConnectorMask.ALL
        }
      }
    } else if (selectedSegues.length > 0) {
      for (const segue of selectedSegues) {
        connectors[segue.from.node] = segue.from.connector
        connectors[segue.to.node]   = segue.to.connector
      }
    }

    // If we're connecting or redirecting, disallow connecting a node with itself.
    if (fromConnection != null && fromConnection.node in connectors) {
      connectors[fromConnection.node] = PlanNodeConnectorMask.NONE
    }

    return connectors
  }, [nodeBounds, enabled, fromConnection, redirectActive, selectedSegues])

  // The entire layer becomes invisible if there's no connector to show.
  const visible = Object.keys(shownConnectors).length > 0

  const showSelected = !(enabled || redirectActive) && selectedSegues.length > 0

  //------
  // Handlers

  const connectActiveRef  = React.useRef<boolean>(false)

  const previewingRef     = React.useRef<boolean>(false)
  const fromRef           = React.useRef<PlanSegueConnection | null>(null)
  const fromConnectionRef = useContinuousRef(fromConnection)

  const [from, setFrom] = React.useState<SegueArrowEnd | null>(null)
  const [to, setTo]     = React.useState<SegueArrowEnd | Point | null>(null)

  const redirectActiveRef = React.useRef<boolean>(false)
  const redirectSeguesRef = React.useRef<Record<string, 'from' | 'to'>>({})

  const handleDocumentMoveRef = React.useRef<AnyFunction | null>(null)
  const handleDocumentEndRef  = React.useRef<AnyFunction | null>(null)

  const cancelConnectOrRedirect = React.useCallback(() => {
    document.removeEventListener('mousemove', handleDocumentMoveRef.current!)
    document.removeEventListener('touchmove', handleDocumentMoveRef.current!)
    document.removeEventListener('mouseup', handleDocumentEndRef.current!)
    document.removeEventListener('touchend', handleDocumentEndRef.current!)

    connectActiveRef.current  = false
    setRedirectActive(redirectActiveRef.current = false)
    redirectSeguesRef.current = {}
    fromRef.current = null
    setFromConnection(null)
    previewingRef.current = false
  }, [])

  // If the plan is changed, cancel any ongoing operation.
  React.useEffect(() => {
    setFrom(null)
    setTo(null)
    cancelConnectOrRedirect()
  }, [cancelConnectOrRedirect, plan])

  const findCurrentConnectionPoint = React.useCallback((connection: PlanSegueConnection) => {
    const node = planner.plan?.findNode(connection.node)
    if (node == null) { return null }

    return findConnectionPoint(connection, node, node.bounds)
  }, [planner.plan])

  const handleDocumentMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (previewingRef.current) { return }

    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const canvasPoint = pointToCanvas(clientPoint, false)

    if (connectActiveRef.current) {
      setTo(canvasPoint)
    }
    if (redirectActiveRef.current) {
      const overrides = Object.entries(redirectSeguesRef.current).map(([uuid, which]) => ({
        uuid,
        from: which === 'from' ? canvasPoint : undefined,
        to:   which === 'to' ? canvasPoint : undefined,
      }))
      planner.redirectSegues(overrides)
    }

    event.preventDefault()
  }, [planner, pointToCanvas])

  const end = React.useCallback((event: MouseEvent | TouchEvent) => {
    if (connectActiveRef.current || redirectActiveRef.current) {
      event.preventDefault()
    }

    if (redirectActiveRef.current && !previewingRef.current) {
      planner.cancelRedirectSegues()
    }

    cancelConnectOrRedirect()
  }, [cancelConnectOrRedirect, planner])

  const handlePreview = React.useCallback(async (to: PlanSegueConnection | null, event: MouseEvent | TouchEvent) => {
    // If there is no from-connection yet, we don't handle the preview.
    if (fromConnection == null) { return }

    const toPoint = to == null ? null : findCurrentConnectionPoint(to)
    previewingRef.current = to != null

    // Only allow setting the preview if connecting to a different node.
    const setPreview = to != null && to.node !== fromConnectionRef.current?.node

    if (connectActiveRef.current) {
      if (setPreview) {
        setTo({
          point:     toPoint!,
          direction: directionForConnector(to!.connector, 'to'),
        })
      } else {
        const clientPoint = getClientPoint(event)
        if (clientPoint == null) { return }

        setTo(pointToCanvas(clientPoint, false))
      }
    }
    if (redirectActiveRef.current) {
      const clientPoint = getClientPoint(event)
      if (clientPoint == null) { return }

      const override  = (setPreview ? to : null) ?? pointToCanvas(clientPoint, false)
      const overrides = Object.entries(redirectSeguesRef.current).map(([uuid, which]) => ({
        uuid,
        from: which === 'from' ? override : undefined,
        to:   which === 'to' ? override : undefined,
      }))
      planner.redirectSegues(overrides)
    }


  }, [findCurrentConnectionPoint, fromConnection, fromConnectionRef, planner, pointToCanvas])

  const handleEnd = React.useCallback(async (to: PlanSegueConnection, event: MouseEvent | TouchEvent) => {
    const from = fromRef.current
    if (from == null) { return }

    // Prevent the end() method from being called on document mouse up, as we want to wait for the operation
    // to be saved to the server.
    event.stopImmediatePropagation()

    if (connectActiveRef.current) {
      const segue = await timer.await(planner.createSegue({
        uuid:       UUID.v4(),
        from:       from,
        to:         to,
        outlet:     null,
        targeting:  Targeting.empty(),
        conditions: [],
        delay:      null,
      }))

      const fromNode = plan?.findNode(from.node)
      if (fromConnection == null || fromNode == null) { return }

      if (!redirectActiveRef.current && segue != null && planner.needsResult(fromNode)) {
        const fromPoint = findConnectionPoint(fromConnection, fromNode, fromNode.bounds)

        const point = fromPoint == null ? getClientPoint(event) : pointToScreen(fromPoint)
        if (point == null) { return }

        planner.openSegueMenu(segue, point, true)
      }
    }

    if (redirectActiveRef.current) {
      const result = await timer.await(planner.commitRedirectSegues())
      if (result != null && isSuccessResult(result)) {
        manager?.deselectSegue()
      }
    }
    setMode('select')

    end(event)
  }, [end, fromConnection, manager, plan, planner, pointToScreen, setMode, timer])

  const handleDocumentEnd = React.useCallback((event: MouseEvent | TouchEvent) => {
    setFrom(null)
    setTo(null)
    end(event)
  }, [end])

  const startConnect = React.useCallback((connection: PlanSegueConnection) => {
    const endPoint = findCurrentConnectionPoint(connection)
    if (endPoint == null) { return }

    setFrom({
      point:     endPoint,
      direction: directionForConnector(connection.connector, 'from'),
    })
    connectActiveRef.current = true
  }, [findCurrentConnectionPoint])

  const startRedirect = React.useCallback((connection: PlanSegueConnection) => {
    redirectSeguesRef.current = {}

    for (const segue of selectedSegues) {
      if (objectEquals(segue.from, connection)) {
        redirectSeguesRef.current[segue.uuid] = 'from'
        setFromConnection(segue.to)
      }
      if (objectEquals(segue.to, connection)) {
        redirectSeguesRef.current[segue.uuid] = 'to'
        setFromConnection(segue.from)
      }
    }

    setRedirectActive(redirectActiveRef.current = true)
  }, [selectedSegues])

  const handleStart = React.useCallback((connection: PlanSegueConnection) => {
    if (!enabled && selectedSegues.length > 0) {
      startRedirect(connection)
    } else {
      setFromConnection(connection)
      startConnect(connection)
    }

    fromRef.current = connection

    handleDocumentMoveRef.current = handleDocumentMove
    handleDocumentEndRef.current  = handleDocumentEnd

    document.addEventListener('mousemove', handleDocumentMoveRef.current!)
    document.addEventListener('touchmove', handleDocumentMoveRef.current!)
    document.addEventListener('mouseup', handleDocumentEndRef.current!)
    document.addEventListener('touchend', handleDocumentEndRef.current!)
  }, [enabled, handleDocumentEnd, handleDocumentMove, selectedSegues.length, startConnect, startRedirect])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <div classNames={[$.connectLayer, {visible}]} ref={layerRef}>
        <div classNames={$.layer} style={{transform}}>
          {Object.entries(shownConnectors).map(([uuid, connectors]) => (
            renderConnectHandles(uuid, connectors)),
          )}
          {renderConnectArrow()}
        </div>
      </div>
    )
  }

  function renderConnectHandles(uuid: string, connectors: PlanNodeConnector | PlanNodeConnectorMask) {
    const bounds = nodeBounds?.find(bounds => bounds.component.uuid === uuid)
    if (bounds == null) { return null }
    if (connectors === PlanNodeConnectorMask.NONE) { return null }

    return (
      <ConnectHandles
        key={uuid}
        component={bounds.component}
        bounds={bounds.bounds}
        connectors={connectors}
        showSelected={showSelected}
        zoom={zoom}
        onStart={handleStart}
        onPreview={handlePreview}
        onEnd={handleEnd}
      />
    )
  }

  function renderConnectArrow() {
    if (from == null || to == null) { return null }

    return (
      <SegueArrow
        segue={null}
        from={from}
        to={to}
      />
    )
  }

  return render()

})

const useStyles = createUseStyles({
  connectLayer: {
    ...layout.overlay,

    '&:not(.visible)': {
      visibility: 'hidden',
    },
  },

  layer: {
    position: 'absolute',
  },
})

export default ConnectLayer