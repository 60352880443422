import React from 'react'
import { FetchStatus } from 'mobx-document'
import {
  EmptyOrFetching,
  GridList,
  GridListProps,
  isListSection,
  SearchField,
  VBox,
} from '~/ui/components'
import { assignRef, KeyboardNavigationNode, KeyPath, useKeyboardNavigation } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import { paddingVertical as listItemPadding } from './ListItem'

export interface Props<T> extends GridListProps<T> {
  search?:  string | null
  onSearch: (query: string | null) => any

  fetchStatus?: FetchStatus
  empty?: {
    title:  string
    detail: string
  }

  onKeyboardSelect?: (item: T) => any
  searchFieldRef?:   React.Ref<HTMLInputElement>

  classNames?: React.ClassNamesProp
}

export default function SearchableGridList<T>(props: Props<T>) {

  const {
    data,
    search,
    onSearch,
    fetchStatus,
    empty,
    onKeyboardSelect,
    searchFieldRef,
    flex,
    classNames,
    columns,
    keyExtractor = defaultKeyExtractor,
    ...rest
  } = props

  const [keyPath, setKeyPath] = React.useState<KeyPath>([])

  const treeNodeForItem = React.useCallback((item: T, index: number): KeyboardNavigationNode<T> => ({
    key:  keyExtractor(item, index),
    item: item,
  }), [keyExtractor])

  const keyboardNavigationData = React.useMemo(() => data.map((item, index): KeyboardNavigationNode<T> => {
    if (isListSection(item)) {
      return {
        key:      item.name,
        children: item.items.map(treeNodeForItem),
      }
    } else {
      return treeNodeForItem(item, index)
    }
  }), [data, treeNodeForItem])

  const [connectKeyboard] = useKeyboardNavigation(keyboardNavigationData, keyPath, setKeyPath, {
    mode:     'grid',
    columns:  columns,
    onSelect: onKeyboardSelect,
  })

  const connectSearchField = React.useCallback((element: HTMLInputElement) => {
    connectKeyboard(element)
    assignRef(searchFieldRef, element)
  }, [connectKeyboard, searchFieldRef])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex={flex} classNames={classNames}>
        <VBox classNames={$.header}>
          {renderSearch()}
        </VBox>
        {renderList()}
      </VBox>
    )
  }

  function renderSearch() {
    return (
      <SearchField
        search={search}
        onSearch={onSearch}
        ref={connectSearchField}
        inputStyle='dark'
        autoFocus
      />
    )
  }

  function renderList() {
    return (
      <GridList
        {...rest}
        data={data}
        columns={columns}
        EmptyComponent={empty == null ? undefined : renderListEmpty}
        scrollable={true}
        flex={flex}
        selectedKeyPath={keyPath}
        keyExtractor={keyExtractor}
      />
    )
  }

  const renderListEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        {...empty}
        classNames={$.empty}
        status={fetchStatus ?? 'done'}
      />
    )
  }, [$.empty, empty, fetchStatus])

  return render()

}

const defaultKeyExtractor = (item: any, index: number) => index

export const padding = listItemPadding.normal

const useStyles = createUseStyles({
  header: {
    padding: [layout.padding.inline.m, layout.padding.inline.l],
  },

  empty: {
    minHeight: 80,
  },
})