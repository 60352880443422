import React from 'react'
import { omit, upperFirst } from 'lodash'
import { IndexFilter, ModelOfType, Page } from '~/models'
import { observer } from '~/ui/component'
import { DetailIndicator, PanelHeader, Spinner } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import ResourcePopup from '../../../resources/components/ResourcePopup'
import { usePagesContext } from '../PagesContext'

export interface Props {
  page:   Page
  filter: IndexFilter
}

const FilterIndicator = observer('FilterIndicator', (props: Props) => {

  const {page, filter} = props
  const {updatePage}   = usePagesContext()

  const value = React.useMemo((): string | null => {
    const value = page.filter[filter.name]
    if (typeof value === 'string') {
      return value
    } else {
      return null
    }
  }, [filter.name, page.filter])

  const commit = React.useCallback((id: string | null) => {
    updatePage(page.id, {
      filter: id == null ? omit(page.filter, filter.name) : {
        ...page.filter,
        [filter.name]: id,
      },
    })
  }, [filter.name, page.filter, page.id, updatePage])

  const [popupOpen, openPopup, closePopup] = useBoolean()

  const Model   = ModelOfType(filter.resourceType)
  const [model, {fetchStatus}] = useModelDocumentData(Model, value)
  const caption = fetchStatus === 'fetching'
    ? <Spinner size={12}/>
    : model?.$caption ?? upperFirst(filter.name)

  const {t} = useResourceTranslation('pages')
  const {singular} = useResourceTranslation(Model.resourceType)

  //------
  // Rendering

  function render() {
    return (
      <ResourcePopup
        open={popupOpen}
        requestClose={closePopup}
        Model={Model}
        value={value}
        onCommit={commit}
        header={renderHeader()}
        children={renderIndicator()}
      />
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={t('index.filter.header', {singular: singular()})}
      />
    )
  }

  function renderIndicator() {
    return (
      <DetailIndicator
        icon={Model.$icon}
        caption={caption}
        filled={value != null}
        onTap={openPopup}
      />
    )
  }

  return render()

})

export default FilterIndicator