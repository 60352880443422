import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Participant, Variable } from '~/models'
import { authenticationStore, dataStore } from '~/stores'
import { CustomDataList } from '~/ui/app/custom-data'
import { observer } from '~/ui/component'
import { Center, Scroller, SegmentedButton, VBox } from '~/ui/components'
import { translateFormErrorPaths } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen, useResourceDetailLocation } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { createUseStyles, layout } from '~/ui/styling'
import ParticipantForm from '../ParticipantForm'
import ParticipantProfile from '../profile/ParticipantProfile'
import GroupsForm from './GroupsForm'
import ParticipantActions from './ParticipantActions'
import ParticipantAuthForm from './ParticipantAuthForm'
import ParticipantInfo from './ParticipantInfo'
import ParticipantQRCode from './ParticipantQRCode'

export type Props = RouteComponentProps<ResourceDetailParams>

const ParticipantScreen = observer('ParticipantScreen', (props: Props) => {

  const {t, actionCaption} = useResourceTranslation('participants')

  const user = authenticationStore.user

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceDetailScreen
        Model={Participant}
        id={props.match.params.id}
        include={['groups']}

        renderInfo={participant => (
          <ParticipantInfo participant={participant}/>
        )}
        renderSummaryFooter={participant => [
          <ParticipantAuthForm participant={participant}/>,
          <ParticipantQRCode participant={participant}/>,
        ]}
        renderActions={participant => (
          <ParticipantActions participant={participant}/>
        )}
        allowRemove={user?.isEditor()}

        EditFormComponent={[{
          name:    'edit',
          icon:    'pencil',
          caption: actionCaption('edit'),
          Form:    ParticipantForm,
        }, {
          name:    'groups',
          icon:    'group',
          caption: actionCaption('edit_groups'),
          Form:    GroupsForm,
        }]}

        renderBody={renderBody}
      />
    )
  }

  //------
  // Body

  const {body = 'profile', setBody} = useResourceDetailLocation()

  function renderBody(participant: Participant) {
    return (
      <VBox flex gap={layout.padding.m}>
        {renderBodyToggleButton()}
        {body === 'profile' && renderProfile(participant)}
        {body === 'data' && renderData(participant)}
      </VBox>
    )
  }

  function renderBodyToggleButton() {
    return (
      <Center>
        <SegmentedButton
          segments={[{
            value:   'profile',
            caption: t('profile.caption'),
          }, {
            value:   'data',
            caption: t('data.caption'),
          }]}
          selectedValue={body}
          onChange={setBody}
        />
      </Center>
    )
  }

  function renderProfile(participant: Participant) {
    return (
      <Scroller flex contentClassNames={$.scrollerContent}>
        <VBox flex align='center'>
          <ParticipantProfile
            participant={participant}
          />
        </VBox>
      </Scroller>
    )
  }

  function renderData(participant: Participant) {
    if (participant.data == null) { return null }

    return (
      <CustomDataList
        value={participant.data}
        requestSave={saveData}
        scrollable
        flex
      />
    )
  }

  const saveData = React.useCallback(async (data: Variable[]) => {
    const document = dataStore.document(Participant, props.match.params.id)
    const result   = await document.update({data})
    return translateFormErrorPaths(result, path => path.replace(/^data./, ''))
  }, [props.match.params.id])

  return render()

})

export default ParticipantScreen

export const qrCodeSize = 80

const useStyles = createUseStyles(theme => ({
  scrollerContent: {
    padding: 2,
  },
}))