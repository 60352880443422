import { ClientApp, Organisation, Project, User } from './'
import { ModelClass } from './Model'

export function scopedToProject(Model: ModelClass<any>) {
  if (Model === Project) { return false }
  if (Model === User) { return false }
  if (Model === Organisation) { return false }
  if (Model === ClientApp) { return false }
  return true
}

// @index(!serialization): export * from ${relpath}
export * from './Access'
export * from './AnswersQuery'
export * from './Branding'
export * from './CalendarPlan'
export * from './Challenge'
export * from './ChallengeState'
export * from './Channel'
export * from './ChatBot'
export * from './ClientApp'
export * from './Competition'
export * from './Condition'
export * from './CustomImage'
export * from './Dashboard'
export * from './Extension'
export * from './Feature'
export * from './Feed'
export * from './Feedback'
export * from './FlowPlan'
export * from './Group'
export * from './GroupSignup'
export * from './Insight'
export * from './Link'
export * from './Location'
export * from './Media'
export * from './Message'
export * from './MessageList'
export * from './MessageTemplate'
export * from './Metric'
export * from './Model'
export * from './Module'
export * from './NotFound'
export * from './Organisation'
export * from './Page'
export * from './Participant'
export * from './PendingMessage'
export * from './PlannerAction'
export * from './Post'
export * from './Profile'
export * from './Project'
export * from './QAndA'
export * from './Question'
export * from './RegistrationCode'
export * from './RegistrationPreset'
export * from './Review'
export * from './Script'
export * from './Sender'
export * from './SystemNotification'
export * from './Targeting'
export * from './TimeInterval'
export * from './TimeOfDay'
export * from './Translations'
export * from './User'
export * from './Widget'
export * from './custom-data'
export * from './importing'
export * from './serializers'
export * from './types'
// /index