import { isArray, range } from 'lodash'
import {
  action,
  computed,
  IReactionDisposer,
  makeObservable,
  observable,
  reaction,
  runInAction,
} from 'mobx'
import * as XLSX from 'xlsx'
import { sparse } from 'ytil'
import { ImportField } from './fields'
import ImportModel from './ImportModel'
import {
  ImportColumn,
  ImportData,
  ImportFieldDescriptor,
  ImportMeta,
  ImportPack,
  ImportProgress,
  ImportResult,
  ImportRun,
} from './types'

export default class ImportSession {

  private constructor(
    public readonly model: ImportModel,
  ) {
    makeObservable(this)
  }

  @observable
  public loading: boolean = false

  @observable
  public error: Error | null = null

  @observable
  public workbook: XLSX.WorkBook | null = null

  @computed
  public get worksheet(): XLSX.WorkSheet | null {
    return Object.values(this.workbook?.Sheets ?? {})[0] ?? null
  }

  //------
  // Session start

  public static async start(profile: ImportModel, binary: Blob) {
    const session = new ImportSession(profile)
    await session.load(binary)
    return session
  }

  public async load(binary: Blob) {
    this.loading = true

    try {
      const bytes = await readArrayBuffer(binary)
      runInAction(() => {
        this.workbook = XLSX.read(bytes, {type: 'array'})
        this.assignSuggestedFields()
      })
    } catch (error: any) {
      runInAction(() => {
        console.error(error)
        this.error = error
      })
    } finally {
      runInAction(() => {
        this.loading = false
        this.modified = false
      })
    }
  }

  private assignSuggestedFields() {
    for (const column of this.columns) {
      const field = this.model.fieldSuggestion(column.name)
      if (field != null) {
        this.mapColumn(column, field)
      }
    }
  }

  //------
  // Previewing

  @observable
  public previewRows: number = 5

  @computed
  public get headers(): Array<[number, string]> {
    if (this.worksheet == null) { return [] }
    if (this.worksheet['!ref'] == null) { return [] }

    const sheetRange = XLSX.utils.decode_range(this.worksheet['!ref'])
    if (this.hasHeaderRow) {
      const data = XLSX.utils.sheet_to_json(this.worksheet, {
        blankrows: false,
        header:    1,
        range:     {s: {r: 0, c: sheetRange.s.c}, e: {r: 0, c: sheetRange.e.c}},
      }) as any[][]

      return sparse(
        data[0].map((hdr, index) => hdr == null ? null : [index, `${hdr}`]),
      )
    } else {
      return sparse(
        range(sheetRange.s.c, sheetRange.e.c + 1)
        .map(index => [index, XLSX.utils.encode_col(index)]),
      )
    }
  }

  @computed
  public get data(): any[] {
    if (this.worksheet == null) { return [] }
    if (this.worksheet['!ref'] == null) { return [] }

    const range = XLSX.utils.decode_range(this.worksheet['!ref'])
    if (this.hasHeaderRow) {
      range.s.r += 1
    }

    return XLSX.utils.sheet_to_json(this.worksheet, {
      blankrows: false,
      header:    1,
      range:     range,
    })
  }

  @computed
  public get previewData(): any[] {
    return this.data.slice(0, this.previewRows)
  }

  @computed
  public get columns(): ImportColumn[] {
    if (this.data == null) { return [] }
    if (this.data.length === 0) { return [] }

    return this.headers.map(([index, name]) => ({
      index,
      name,
    }))
  }

  //------
  // Import profile

  @observable
  public hasHeaderRow: boolean = true

  @observable
  public readonly mappings = new Map<number, [ImportFieldDescriptor, ImportField]>()

  @action
  public setHasHeaderRow(hasHeaderRow: boolean) {
    this.hasHeaderRow = hasHeaderRow
  }

  @action
  public mapColumn(column: ImportColumn, descriptor: ImportFieldDescriptor | null) {
    if (descriptor == null) {
      this.mappings.delete(column.index)
      return
    }

    const field = descriptor.create(column)
    if (field.exclusive) {
      for (const [col, [desc]] of this.mappings.entries()) {
        if (desc.key === descriptor.key) {
          this.mappings.delete(col)
        }
      }
    }

    this.mappings.set(column.index, [descriptor, field])
  }

  public fieldDescriptorForColumn(column: ImportColumn): ImportFieldDescriptor | null {
    return this.mappings.get(column.index)?.[0] ?? null
  }

  public fieldForColumn(column: ImportColumn): ImportField | null {
    return this.mappings.get(column.index)?.[1] ?? null
  }

  @computed
  public get importPack(): ImportPack {
    return {
      data: this.importData,
      meta: this.importMeta,
    }
  }

  @computed
  public get importData(): ImportData {
    return this.data.map(row => {
      return Array.from(this.mappings.keys())
        .map(column => row[column])
    })
  }

  @computed
  public get importMeta(): ImportMeta {
    return {
      startRow: this.hasHeaderRow ? 2 : 1,
      defaults: this.model.defaults,
      fields:   Array.from(this.mappings).map(([column, [descriptor, field]], index) => ({
        index:  index,
        column: column,
        key:    descriptor.key,
        field:  field.serialize(),
      })),
    }
  }

  //------
  // Profile

  @observable
  public modified: boolean = false

  @action
  public saveProfile() {
    this.modified = false
    return this.serializeProfile()
  }

  private serializeProfile() {
    const mappings: any[] = []
    for (const [columnIndex, [descriptor, field]] of this.mappings) {
      const column = this.columns.find(it => it.index === columnIndex)
      if (column == null) { continue }

      mappings.push([column.name, descriptor.key, field.save()])
    }

    return {
      hasHeaderRow: this.hasHeaderRow,
      mappings:     mappings,
    }
  }

  @action
  public loadProfile(raw: AnyObject) {
    this.mappings.clear()
    if (raw.hasHeaderRow != null) {
      this.hasHeaderRow = raw.hasHeaderRow
    }

    if (isArray(raw.mappings)) {
      for (const [columnName, key, fieldRaw] of raw.mappings) {
        const column = this.columns.find(it => it.name === columnName)
        if (column == null) { continue }

        const descriptor = this.model.fields.find(it => it.key === key)
        if (descriptor == null) { continue }

        const field = descriptor.create(column)
        field.load(fieldRaw)

        this.mappings.set(column.index, [descriptor, field])
      }
    }

    this.modified = false
    this.setupModifiedReaction()
  }

  private modifiedReactionDisposer: IReactionDisposer | null = null

  private setupModifiedReaction() {
    this.modifiedReactionDisposer?.()

    this.modifiedReactionDisposer = reaction(() => this.serializeProfile(), action(() => {
      this.modified = true
    }))
  }

  //------
  // Import

  @observable
  private importPromise: Promise<ImportResult | undefined> | null = null

  @computed
  public get importing() {
    return this.importPromise != null
  }

  @observable
  public importProgress: ImportProgress | null = null

  @observable
  public lastRun: ImportRun | null = null

  @action
  public import(): Promise<ImportResult | undefined> {
    return this.importPromise ??= (
      this.importImpl().finally(action(() => {
        this.importPromise = null
      }))
    )
  }

  private async importImpl(): Promise<ImportResult | undefined> {
    const result = await this.model.import(this, {
      onProgress: action(progress => {
        this.importProgress = progress
      }),
    })
    if (result == null) { return }

    this.processResult(result)
    return result
  }

  @action
  private processResult(result: ImportResult) {
    if (result.status === 'error') {
      this.error = result.error
    } else {
      this.lastRun = {
        result: result,
      }
    }

    return result
  }

}

function readArrayBuffer(blob: Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      resolve(reader.result as ArrayBuffer)
    }

    reader.readAsArrayBuffer(blob)
  })
}