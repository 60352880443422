/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Branding, Ref } from '~/models'
import { observer } from '~/ui/component'
import { HBox, SelectField, TextBlock, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import Scroller from '~/ui/components/scroller/Scroller'
import { Form, FormField } from '~/ui/form'
import { useModelEndpointData } from '~/ui/hooks/data'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import RegistrationWidget, { RegistrationStep } from '../../widget/RegistrationWidget'
import { PreviewWidgetModel } from './PreviewWidgetModel'

export interface Props {
  model: PreviewWidgetModel
  renderDefaultLogo: (size: Size) => React.ReactNode
}

const RegistrationWidgetBranding = observer('RegistrationWidgetBranding', (props: Props) => {

  const {model, renderDefaultLogo} = props
  const {t} = useResourceTranslation('registration-presets')

  //------
  // Branding

  const [brandings, {fetchStatus}] = useModelEndpointData(Branding, {
    fetch: true,
    filters: {
      organisation: model.preset.organisation,
    },
  })

  const brandingChoices = React.useMemo(() => {
    const choices: Choice<Ref<Branding> | null>[] = []
    choices.push({
      value:   null,
      caption: t('preview.branding.default'),
    })

    choices.push(...brandings.map(branding => ({
      value:   branding.id,
      caption: branding.$caption,
    })))

    return choices
  }, [brandings, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex classNames={$.RegistrationWidgetBranding} gap={layout.padding.m}>
        {renderWidgets()}
        {renderForm()}
      </VBox>
    )
  }

  function renderWidgets() {
    return (
      <Scroller flex={false} horizontal overflow={8} contentJustify='center'>
        <HBox gap={layout.padding.m} justify='center'>
          <VBox>
            <RegistrationWidget
              model={model}
              step={RegistrationStep.enterCode}
              renderDefaultLogo={renderDefaultLogo}
            />
          </VBox>
          <VBox>
            <RegistrationWidget
              model={model}
              step={RegistrationStep.register}
              renderDefaultLogo={renderDefaultLogo}
            />
          </VBox>
        </HBox>
      </Scroller>
    )
  }

  const theme = useTheme()

  function renderForm() {
    return (
      <ResourceTypeProvider resourceType='registration-presets' i18nPrefix='preview'>
        <Form model={model} fieldGap={layout.padding.inline.m} autoSubmit>
          <VBox align='center'>
            <VBox classNames={$.brandingField}>
              <FormField name='branding' caption={t('preview.branding.prompt')} align='center'>
                {bind => (
                  <SelectField
                    {...bind}
                    choices={brandingChoices}
                  />
                )}
              </FormField>
            </VBox>
            <TextBlock small caption={model.branding != null} color={model.branding != null ? theme.semantic.primary : undefined} dim={model.branding == null} italic={model.branding == null} align='center'>
              {t(`preview.branding.instruction.${model.branding == null ? 'unavailable' : 'available'}`)}
            </TextBlock>
          </VBox>
        </Form>
      </ResourceTypeProvider>
    )
  }

  return render()

})

export default RegistrationWidgetBranding

const useStyles = createUseStyles({
  RegistrationWidgetBranding: {
    ...layout.responsiveProp({
      paddingLeft: layout.padding.m,
    }),
  },

  brandingField: {
    width: 320,
  },
})