import { FetchStatus } from 'mobx-document'
import { Challenge, QuestionDescriptor, QuestionSelector, Script } from '~/models'
import { deriveQuestionsFromModel } from '~/stores'
import { useModelDocumentData } from '~/ui/hooks/data'

export function useConditionQuestion(selector: QuestionSelector | null): [QuestionDescriptor | null, FetchStatus] {
  const [text, fetchStatus] = useConditionQuestionText(selector)
  if (selector == null || text == null) { return [null, 'idle'] }

  return [{...selector, text}, fetchStatus]
}

export function useConditionQuestionText(selector: QuestionSelector | null): [string | null, FetchStatus] {
  const [script, scriptEndpoint] = useModelDocumentData(Script, selector?.type === 'script-question' ? selector.model : null, {
    detail: true,
  })
  const [challenge, challengeEndpoint] = useModelDocumentData(Challenge, selector?.type === 'challenge-task' ? selector.model : null, {
    detail: true,
  })

  const model = selector?.type === 'script-question' ? script : challenge
  if (selector == null || model == null) { return [null, 'idle'] }

  const question    = deriveQuestionsFromModel(model).find(it => it.uuid === selector.questionUUID)
  const fetchStatus = selector.type === 'script-question' ? scriptEndpoint.fetchStatus : challengeEndpoint.fetchStatus

  return [question?.caption ?? null, fetchStatus]
}