import { action, computed, makeObservable, observable, reaction } from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { sparse } from 'ytil'
import {
  OperatorAlert,
  OperatorAlertListener,
  OperatorEntry,
  OperatorPack,
  OperatorTrigger,
} from './types'

export default class OperatorService extends OnDemandService {

  constructor(
    socket: Socket,
    participants: Array<[string, string]>,
  ) {
    super(socket)
    this.participants = new Map(participants)

    makeObservable(this)
    this.setUpAlertReaction()
  }

  private setUpAlertReaction() {
    let prevLastAlertAt = this.lastAlertAt
    reaction(() => this.lastAlertAt, nextLastAlertAt => {
      if (nextLastAlertAt > prevLastAlertAt) {
        this.alertListeners.forEach(it => it())
      }

      prevLastAlertAt = nextLastAlertAt
    })

  }

  @observable
  public participants: Map<string /* participantID */, string | null /* channelID */>

  @observable
  public alerts = new Map<string, OperatorAlert>()

  @observable
  public entries = new Map<string, OperatorEntry>()

  @observable
  public namedTriggers: OperatorTrigger[] = []

  @computed
  public get lastAlertAt(): number {
    const lastAlertAts = sparse(Array.from(this.alerts.values()).map(
      it => it.lastAlertAt == null ? null : new Date(it.lastAlertAt),
    ))
    if (lastAlertAts.length === 0) { return 0 }

    return Math.max(...lastAlertAts.map(it => it.getTime()))
  }

  public async start() {
    await super.startWithEvent('operator:start', {
      participantIDs: Array.from(this.participants.keys()),
    })
  }

  protected onStarted = (response: StartSuccess<OperatorPack>) => {
    this.socket.prefix = `operator:${this.uid}:`
    this.update(response.data)

    this.socket.addEventListener('update', (update: OperatorPack) => {
      this.update(update)
    })
    this.socket.addEventListener('alert:update', (alert: OperatorAlert) => {
      this.updateAlert(alert)
    })
    this.socket.addEventListener('entry:update', (entry: OperatorEntry) => {
      this.updateEntry(entry)
    })
    this.socket.addEventListener('participant:removed', action((participantID: string) => {
      this.participants.delete(participantID)
      this.removeEntry(participantID)
    }))
  }

  public onStop() {
    this.alerts.clear()
    this.entries.clear()
  }

  //------
  // Updates

  @action
  public update(update: OperatorPack) {
    for (const entry of update.data) {
      this.entries.set(entry.participantID, entry)
      this.alerts.delete(entry.participantID)
    }
    for (const alert of update.meta.alerts) {
      this.alerts.set(alert.participantID, alert)
    }
    this.namedTriggers = update.meta.triggers
  }

  public updateAlert(alert: OperatorAlert) {
    this.alerts.set(alert.participantID, alert)
  }

  public updateEntry(entry: OperatorEntry) {
    this.entries.set(entry.participantID, entry)
  }

  public removeEntry(participantID: string) {
    const entry = this.entries.get(participantID)
    if (entry == null) { return }

    this.entries.delete(participantID)
    if (entry.lastAlertAt != null) {
      this.alerts.set(participantID, entry)
    }
  }

  public getEntry(participantID: string) {
    return this.entries.get(participantID) ?? null
  }
  //------
  // Participants

  @action
  public addParticipant(participantID: string) {
    this.participants.set(participantID, null)
    this.socket.emit('participants:add', [participantID])
  }

  @action
  public removeParticipant(participantID: string) {
    this.participants.delete(participantID)
    this.removeEntry(participantID)
    this.socket.emit('participants:remove', [participantID])
  }

  @action
  public switchParticipantToChannel(participantID: string, channelID: string | null) {
    this.participants.set(participantID, channelID)
  }

  public getParticipantChannel(participantID: string) {
    return this.participants.get(participantID) ?? null
  }

  //------
  // Alerts

  public async clearAlert(participantID: string) {
    const undo = this.clearLocalAlert(participantID)

    const response = await this.socket.send('alert:clear', participantID)
    if (response.ok) {
      return true
    } else {
      undo?.()
      return false
    }
  }

  private clearLocalAlert(participantID: string) {
    const set   = this.alerts.has(participantID) ? this.alerts : this.entries
    const entry = set.get(participantID)
    if (entry == null) { return }

    set.set(participantID, ({
      ...entry,
      alertType:    null,
      firstAlertAt: null,
      lastAlertAt:  null,
    }))

    return () => {
      set.set(participantID, entry)
    }
  }

  private alertListeners = new Set<OperatorAlertListener>()

  public addAlertListener(listener: OperatorAlertListener) {
    this.alertListeners.add(listener)
    return () => {
      this.alertListeners.delete(listener)
    }
  }

}