import React from 'react'
import { useTranslation } from 'react-i18next'
import { TimeInterval, TimeIntervalUnit } from '~/models'
import { memo } from '~/ui/component'
import {
  HBox,
  NumberField,
  NumberFieldProps,
  SelectField,
  SelectFieldProps,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import {
  ChangeCallback,
  FieldChangeCallback,
  invokeFieldChangeCallback,
  isFieldChangeCallback,
  useFieldChangeCallback,
} from '~/ui/form'
import { layout } from '~/ui/styling'
import { InputElement } from './TextField'

export interface Props extends Omit<NumberFieldProps & SelectFieldProps<any>, 'value' | 'onChange' | 'choices' | 'showClearButton'> {
  value:    TimeInterval | null
  onChange: FieldChangeCallback<TimeInterval | null> | ChangeCallback<TimeInterval | null>

  defaultUnit?: TimeIntervalUnit
  allowClear?:  boolean
}

const TimeIntervalField = memo('TimeIntervalField', (props: Props) => {

  const {
    value,
    onChange,
    inputAttributes: {
      onKeyDown: props_onKeyDown,
      ...inputAttributes
    } = {},
    allowClear = true,
    defaultUnit =  TimeIntervalUnit.MINUTES,
    ...rest
  } = props

  const [unit, setUnit] = React.useState<TimeIntervalUnit>(value?.unit ?? defaultUnit)

  const changeQuantity = useFieldChangeCallback(React.useCallback((quantity: number | null, partial?: boolean) => {
    if (onChange == null) { return }
    if (!allowClear && quantity == null) {
      invokeFieldChangeCallback(onChange, value, partial)
    } else {
      const nextValue = quantity == null ? null : new TimeInterval(quantity, unit)
      invokeFieldChangeCallback(onChange, nextValue, partial)
    }
  }, [onChange, allowClear, value, unit]))

  const changeUnit = useFieldChangeCallback(React.useCallback((unit: TimeIntervalUnit, partial?: boolean) => {
    setUnit(unit)
    if (onChange == null) { return null }
    if (!allowClear && value == null) { return }

    const nextValue = value == null ? null : new TimeInterval(value.quantity, unit)
    if (partial && isFieldChangeCallback(onChange)) {
      onChange.partial(nextValue)
    } else {
      onChange(nextValue)
    }
  }, [onChange, allowClear, value]))

  //------
  // Keyboard shortcut

  const onKeyDown = React.useCallback((event: React.KeyboardEvent<InputElement>) => {
    const maybeUnit = event.key.toLowerCase()
    if (TimeInterval.isValidUnit(maybeUnit)) {
      invokeFieldChangeCallback(changeUnit, maybeUnit, true)
      event.preventDefault()
    }

    props_onKeyDown?.(event)
  }, [changeUnit, props_onKeyDown])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex>
          <NumberField
            value={value?.quantity ?? null}
            onChange={changeQuantity}
            step={1}
            inputAttributes={{...inputAttributes, onKeyDown}}
            {...rest}
            showClearButton={allowClear ? 'always' : 'never'}
          />
        </VBox>
        <VBox style={{width: 120}}>
          <SelectField
            value={unit}
            onChange={changeUnit}
            choices={unitChoices}
            {...rest}
          />
        </VBox>
      </HBox>
    )
  }

  const [t] = useTranslation('time_interval')

  const unitChoices = React.useMemo((): Choice<TimeIntervalUnit>[] => [
    {value: TimeIntervalUnit.SECONDS, caption: t(`units.${TimeIntervalUnit.SECONDS}`, {count: 10})},
    {value: TimeIntervalUnit.MINUTES, caption: t(`units.${TimeIntervalUnit.MINUTES}`, {count: 10})},
    {value: TimeIntervalUnit.HOURS,   caption: t(`units.${TimeIntervalUnit.HOURS}`, {count: 10})},
    {value: TimeIntervalUnit.DAYS,    caption: t(`units.${TimeIntervalUnit.DAYS}`, {count: 10})},
    {value: TimeIntervalUnit.WEEKS,   caption: t(`units.${TimeIntervalUnit.WEEKS}`, {count: 10})},
  ], [t])

  return render()

})

export default TimeIntervalField