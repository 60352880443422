import React from 'react'
import { memo } from '~/ui/component'

interface ResourceTypeContext {
  resourceType:     string | null
  i18nResourceType: string | null
  i18nPrefix:       string | null
}

const ResourceTypeContext = React.createContext<ResourceTypeContext>({
  resourceType:     null,
  i18nResourceType: null,
  i18nPrefix:       null,
})

export default ResourceTypeContext

export interface ResourceTypeProviderProps {
  resourceType:      string
  i18nResourceType?: string
  i18nPrefix?:       string
  children?:         React.ReactNode
}

export const ResourceTypeProvider = memo('ResourceTypeProvider', (props: ResourceTypeProviderProps) => {

  const {
    resourceType,
    i18nResourceType = resourceType,
    i18nPrefix = null,
    children,
  } = props

  const context = React.useMemo((): ResourceTypeContext => ({
    resourceType,
    i18nResourceType,
    i18nPrefix,
  }), [i18nPrefix, i18nResourceType, resourceType])

  function render() {
    return (
      <ResourceTypeContext.Provider value={context}>
        {children}
      </ResourceTypeContext.Provider>
    )
  }

  return render()

})

export function useResourceType() {
  const context = React.useContext(ResourceTypeContext)
  return context.resourceType
}

export function useResourceTypeI18n() {
  const context = React.useContext(ResourceTypeContext)
  return {
    type:   context.i18nResourceType,
    prefix: context.i18nPrefix,
  }
}
