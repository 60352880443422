import { ImportField, ImportFieldOptions } from 'sheet-importer'

export default class CredentialField extends ImportField {

  constructor(
    name: string,
    public readonly credentialType: Credential['type'],
    options: ImportFieldOptions = {},
  ) {
    super(name, options)
  }

  public serialize() {
    return {
      ...super.serializeWithType('credential'),
      credentialType: this.credentialType,
    }
  }

}