import { action, computed, makeObservable, observable } from 'mobx'
import socket from 'socket.io-react'
import { SystemNotification } from '~/models'
import { ListMeta, ListPack } from './data/types'
import dataStore from './dataStore'
import { register } from './support'

export class NotificationsStore {

  constructor() {
    makeObservable(this)
  }

  @observable
  public initializing: boolean = true

  @observable
  private viewerNotificationIDs: string[] = []

  @computed
  public get viewerNotifications(): SystemNotification[] {
    return dataStore.list(SystemNotification, this.viewerNotificationIDs)
  }

  @observable
  public badges = new Map<string, BadgeType & {value: BadgeValue}>()

  //------
  // Lifecycle

  public async init() {
    socket.addEventListener('system-notifications:initial', this.handleInitialNotifications)
    socket.addEventListener('system-notifications:update', this.handleNotificationsUpdate)
  }

  //------
  // Notifications update

  private handleInitialNotifications = action((pack: NotificationsPack) => {
    const documents = dataStore.storePack(pack).slice(-viewerPageSize)
    this.viewerNotificationIDs = documents.map(it => it.id)
    this.updateBadges(pack.meta?.badges ?? {})
    this.initializing  = false
  })

  private handleNotificationsUpdate = action((pack: NotificationsPack) => {
    const existing = dataStore.db(SystemNotification).allDocuments.map(it => it.id)
    const ids      = dataStore.storePack(pack).map(it => it.id)
    const newIDs   = ids.filter(it => !existing.includes(it))

    this.viewerNotificationIDs = [
      ...newIDs,
      ...this.viewerNotificationIDs,
    ].slice(0, viewerPageSize)

    for (const id of newIDs) {
      const document = dataStore.document(SystemNotification, id)
      if (document.data == null) { continue }

      this.notify(document.data)
    }

    this.updateBadges(pack.meta?.badges ?? {})
  })

  @action
  private updateBadges(badges: BadgeValues) {
    for (const [type, value] of Object.entries(badges)) {
      if (value.value == null || value.value === 0) {
        this.badges.delete(type)
      } else {
        this.badges.set(type, value)
      }
    }
  }

  //------
  // Notifier

  private notificationListeners = new Set<NotificationListener>()

  private suspendedNotificationTypes = new Set<string[]>()

  private notify(notification: SystemNotification) {
    if (!document.hidden && this.isSuspended(notification.type)) { return }

    for (const listener of this.notificationListeners) {
      listener(notification)
    }
  }

  public addNotificationListener(listener: NotificationListener) {
    this.notificationListeners.add(listener)
    return () => {
      this.notificationListeners.delete(listener)
    }
  }

  private isSuspended(type: string) {
    for (const types of this.suspendedNotificationTypes) {
      if (types.includes(type)) { return true }
    }

    return false
  }

  public suspend(types: string[]) {
    this.suspendedNotificationTypes.add(types)
    return () => {
      this.suspendedNotificationTypes.delete(types)
    }
  }

}

const viewerPageSize = 20

export type NotificationListener = (notification: SystemNotification) => any

export type NotificationsPack = ListPack<SystemNotification, NotificationMeta>

export interface NotificationMeta extends ListMeta {
  badges?:       BadgeValues
  translations?: any
}

export interface BadgeType {
  name:   string
  icon?:  string
  color?: string
}

export type BadgeValues = Record<string, BadgeType & {value: BadgeValue}>
export type BadgeValue  = number | string | boolean

const notificationsStore = register(new NotificationsStore())
export default notificationsStore