import React from 'react'
import { useTranslation } from 'react-i18next/'
import { memo } from '~/ui/component'
import { ClearButton, Panel, PanelHeader, PanelHeaderProps, PanelProps } from '~/ui/components'
import { FormModel } from '~/ui/form'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import WidgetsContext from './WidgetsContext'

export interface Props<FM extends FormModel> extends PanelProps, PanelHeaderProps {
  widget: string
  params: Record<string, any>

  FormModel:  WidgetFormModelConstructor<FM>
  renderForm: (formModel: FM, open: boolean, requestClose: () => any) => React.ReactNode
}

const _WidgetPanel = <FM extends FormModel>(props: Props<FM>) => {

  const {widget, params, icon, caption, FormModel, renderForm, ...rest} = props

  const [t] = useTranslation('widgets')

  const [formOpen, openForm, closeForm] = useBoolean()
  const [formModel, setFormModel] = React.useState<FM | null>(null)

  const context = React.useContext(WidgetsContext)
  const {startConfiguringWidget, endConfiguringWidget} = context

  const formWasOpen = usePrevious(formOpen)

  React.useEffect(() => {
    if (formOpen && !formWasOpen) {
      startConfiguringWidget?.()
    } else if (!formOpen && formWasOpen) {
      endConfiguringWidget?.()
    }
  }, [endConfiguringWidget, formOpen, formWasOpen, startConfiguringWidget])

  const setParams = React.useCallback((params: Record<string, any>) => {
    return context.setParams(widget, params)
  }, [context, widget])

  const configure = React.useCallback(() => {
    setFormModel(new FormModel(params, setParams))
    openForm()
  }, [FormModel, openForm, params, setParams])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <>
        <Panel
          flex='grow'
          classNames={$.widgetPanel}
          depth={0}
          semi={false}
          gap={layout.padding.inline.m}
          padding={layout.padding.inline.m}
          header={renderHeader()}
          {...rest}
        />
        {formModel != null && renderForm(formModel, formOpen, closeForm)}
      </>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        icon={icon}
        caption={t(`${widget}.title`)}
        right={(
          <ClearButton
            icon='cog'
            onTap={configure}
            padding='both'
            small
          />
        )}
        small
      />
    )
  }

  return render()

}

const WidgetPanel = memo('WidgetPanel', _WidgetPanel) as typeof _WidgetPanel
export default WidgetPanel

export type WidgetFormModelConstructor<FM extends FormModel> = new (
  params: Record<string, any>,
  setParams: (params: Record<string, any>) => void
) => FM

const useStyles = createUseStyles(theme => ({
  widgetPanel: {
    border: [1, 'solid', theme.fg.dimmer],
  },
}))