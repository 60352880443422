import React from 'react'
import { Widget, WidgetAlotment, WidgetFilter, WidgetOutput } from '~/models'
import { observer } from '~/ui/component'
import { Dimple, HBox, PopupMenu, PopupMenuItem, PushButton } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import renderWidgetFilter from './filters'
import { useWidgetFilterValues } from './WidgetFilterValuesContext'

export interface Props {
  widget:   Widget
  output:   WidgetOutput
  alotment: WidgetAlotment
}

const WidgetFiltersBar = observer('WidgetFiltersBar', (props: Props) => {

  const {widget, output, alotment} = props

  const values      = useWidgetFilterValues()
  const filterNames = values?.names

  const persistentFilters    = React.useMemo(() => widget.filters[output].filter(it => it.persistent), [output, widget.filters])
  const nonPersistentFilters = React.useMemo(() => widget.filters[output].filter(it => !it.persistent), [output, widget.filters])

  const addedNonPersistentFilters = React.useMemo(
    () => nonPersistentFilters.filter(it => (filterNames ?? []).includes(it.name)),
    [filterNames, nonPersistentFilters],
  )
  const availableNonPersistentFilters = React.useMemo(
    () => nonPersistentFilters.filter(it => !(filterNames ?? []).includes(it.name)),
    [filterNames, nonPersistentFilters],
  )

  const nonPersistentFiltersMenuItems = React.useMemo(() => {
    return availableNonPersistentFilters.map((filter): PopupMenuItem => ({
      value:   filter,
      caption: widget.filterCaption(filter.name),
    }))
  }, [availableNonPersistentFilters, widget])

  const addFilter = React.useCallback((filter: WidgetFilter) => {
    values?.setToDefault(filter)
  }, [values])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (values == null) { return null }

    return (
      <HBox wrap gap={layout.padding.inline.m}>
        {renderFilters(persistentFilters)}
        {addedNonPersistentFilters.length > 0 && <Dimple vertical/>}
        {renderFilters(addedNonPersistentFilters)}
        {availableNonPersistentFilters.length > 0 && <Dimple vertical/>}
        {availableNonPersistentFilters.length > 0 && renderAddFilterMenu()}
      </HBox>
    )
  }

  function renderFilters(filters: WidgetFilter[]) {
    return filters.map((filter, index) => (
      <React.Fragment key={filter.name}>
        {renderFilter(filter)}
      </React.Fragment>
    ))
  }

  function renderAddFilterMenu() {
    return (
      <PopupMenu items={nonPersistentFiltersMenuItems} onValueSelect={addFilter}>
        {toggle => (
          <PushButton
            classNames={$.nonPersistentFilterMenuButton}
            icon='plus'
            onTap={toggle}
            small
          />
      )}
      </PopupMenu>
    )
  }

  function renderFilter(filter: WidgetFilter) {
    const value = values?.getValue(filter.name)
    return renderWidgetFilter(alotment, widget, filter, value)
  }

  return render()

})

export default WidgetFiltersBar

const useStyles = createUseStyles({
  nonPersistentFilterMenuButton: {
    '&, &.small > ::after': {
      borderTopLeftRadius:    layout.radius.s,
      borderBottomLeftRadius: layout.radius.s,
    },
  },
})