import React from 'react'
import { Feed } from '~/models'
import { memo } from '~/ui/component'
import { Chip, ColorSwatch, DataGrid, HBox, Label } from '~/ui/components'
import { ResourceListScreen } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'
import FeedForm from '../FeedForm'

const FeedsScreen = memo('FeedsScreen', () => {

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Feed}
        include={['bots', 'group']}
        CreateFormComponent={FeedForm}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={2}
        />
        <DataGrid.Column
          name='postCount'
          renderCell={renderPostCount}
          flex={1}
        />
      </ResourceListScreen>
    )
  }

  function renderName(feed: Feed) {
    return (
      <HBox gap={layout.padding.s}>
        <ColorSwatch color={feed.color} round/>
        <Label flex='shrink' bold>
          {feed.name}
        </Label>
      </HBox>
    )
  }

  function renderPostCount(feed: Feed) {
    return (
      <HBox>
        <Chip>
          {feed.postCount}
        </Chip>
      </HBox>
    )
  }

  return render()

})

export const avatarSize = {
  width:  32,
  height: 32,
}

export default FeedsScreen