import { makeObservable, observable } from 'mobx'
import socket from 'socket.io-react'
import { PlannerActionCategoryDescriptor } from '~/models'
import { CalendarPlannerService, FlowPlannerService, ScheduleService } from './planner'
import { register } from './support'

export class PlannerStore {

  constructor() {
    makeObservable(this)
  }

  //------
  // Planner service

  public getFlowPlannerService(moduleID: string) {
    const service = new FlowPlannerService(socket, moduleID, categories => {
      this.availableActionCategories = categories
    })

    service.onRequestRehydrate = planID => {
      return this.getPersistedState(`planner:${planID}`)
    }
    service.onRequestPersist = (planID, state) => {
      return this.setPersistedState(`planner:${planID}`, state)
    }

    return service
  }

  //------
  // Calendar planner service

  public getCalendarPlannerService(moduleID: string) {
    const service = new CalendarPlannerService(socket, moduleID)

    service.onRequestRehydrate = planID => {
      return this.getPersistedState(`calendar:${planID}`)
    }
    service.onRequestPersist = (planID, state) => {
      return this.setPersistedState(`calendar:${planID}`, state)
    }

    return service
  }

  //------
  // Scheduler service

  public createScheduleService(participantID: string | null) {
    return new ScheduleService(socket, participantID)
  }

  //------
  // Planner actions

  @observable
  public availableActionCategories: PlannerActionCategoryDescriptor[] = []

  public getAction(name: string) {
    for (const {actions} of this.availableActionCategories) {
      for (const action of actions) {
        if (action.name === name) {
          return action
        }
      }
    }

    return null
  }

  //------
  // Persistence

  private getPersistedState(planID: string) {
    const json = localStorage.getItem(`${PERSISTENCE_NS}:${planID}`)
    if (json == null) { return null }

    return JSON.parse(json)
  }

  private setPersistedState(planID: string, state: any) {
    localStorage.setItem(`${PERSISTENCE_NS}:${planID}`, JSON.stringify(state))
  }

}

const PERSISTENCE_NS = 'groundcontrol.planner'

export default register(new PlannerStore())