import Color from 'color'
import { makeObservable, observable, reaction } from 'mobx'
import { Branding } from '~/models'
import BrandingAssetFormModel from './BrandingAssetFormModel'

export default class BrandingColorFormModel extends BrandingAssetFormModel {

  constructor(branding: Branding, name: string | null) {
    super(branding, name)

    this.hex   = (name == null ? null : branding.colors[name]) ?? '#ffffff'
    this.color = new Color(this.hex)

    makeObservable(this)
    reaction(() => this.hex, hex => {
      if (/^#?[\da-f]{6}$/i.test(hex)) {
        this.color = new Color(hex)
      }
    })
    reaction(() => this.color, color => {
      if (color.hex() !== this.hex) {
        this.hex = color.hex()
      }
    })
  }

  public readonly assetType = 'color'

  @observable
  public color: Color

  @observable
  public hex: string

  public reset() {
    const name = this.originalName
    this.name  = name ?? ''
    this.hex   = (name == null ? null : this.branding.colors[name]) ?? '#ffffff'
    this.color = new Color(this.hex)
  }

  public buildSpec() {
    return this.color.hex()
  }

}