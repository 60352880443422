import React from 'react'
import { focusFirst, useAutofocus } from 'react-autofocus'
import { isFunction } from 'lodash'
import { observer } from '~/ui/component'
import { HBox, Label, Panel, SVG, VBox } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import { FormFieldHeader } from '~/ui/form'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout, ThemeProvider, useStyling, useTheme } from '~/ui/styling'
import { useForm } from './FormContext'
import FormErrors from './FormErrors'
import { useFormField } from './hooks/useFormField'
import { FormData, FormModel } from './types'

export interface Props {
  name?:        string
  caption?:     string | null
  instruction?: string | null
  required?:    boolean
  autoFocus?:   boolean
  enabled?:     boolean
  children?:    React.ReactNode
}

const FormSwitch = (props: Props) => {

  const {name, caption, instruction, autoFocus = true, enabled = true, required, children} = props
  const {errors} = useFormField(name ?? null)
  const context  = React.useMemo((): FormSwitchContext => ({
    name,
    autoFocus,
    enabled,
  }), [autoFocus, enabled, name])

  //------
  // Rendering

  function render() {
    return (
      <FormSwitchContext.Provider value={context}>
        <VBox gap={layout.padding.inline.s}>
          <FormFieldHeader
            caption={caption}
            required={required}
            instruction={instruction}
          />
          <VBox gap={layout.padding.inline.s}>
            {children}
          </VBox>
          {renderErrors()}
        </VBox>
      </FormSwitchContext.Provider>
    )
  }

  function renderErrors() {
    if (errors.length === 0) { return null }

    return (
      <FormErrors errors={errors}/>
    )
  }

  return render()

}

export interface OptionProps<M extends FormModel> {
  icon?:       SVGName
  title:       string
  detail:      string
  renderForm?: () => React.ReactNode

  selected:    boolean | ((model: M) => boolean)
  onTap?:      () => void
  initialData: FormData<M> | ((model: M) => FormData<M>)

  enabled?:    boolean
  autoFocus?:  boolean
}

const _Option = <M extends FormModel>(props: OptionProps<M>) => {

  const {model, setData, submitting}  = useForm<M>()
  const {autoFocus: defaultAutoFocus, enabled: defaultEnabled} = React.useContext(FormSwitchContext)

  const {title, detail, renderForm, onTap, autoFocus = defaultAutoFocus, enabled = defaultEnabled} = props
  const {icon = 'check'} = props

  const selected    = isFunction(props.selected) ? props.selected(model) : props.selected
  const initialData = isFunction(props.initialData) ? props.initialData(model) : props.initialData

  const {colors} = useStyling()
  const theme    = useTheme()

  const tintColor = selected ? colors.semantic.primary : theme.fg.dimmer

  const tap = React.useCallback(() => {
    setData(initialData)
    onTap?.()
  }, [initialData, onTap, setData])

  //------
  // Auto-focus

  const formRef = React.useRef<HTMLDivElement>(null)

  const prevSelected = usePrevious(selected)
  const performAutoFocus = React.useCallback(() => {
    if (!prevSelected && selected && autoFocus && formRef.current != null) {
      focusFirst(formRef.current)
    }
  }, [autoFocus, prevSelected, selected])

  useAutofocus(performAutoFocus)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Panel
        onTap={enabled && !selected ? tap : undefined}
        semi={false}
        bandColor={tintColor}
        classNames={[$.Option, {enabled: enabled && !submitting}]}
        contentClassNames={$.optionContent}
        gap={layout.padding.m}
      >
        {renderMain()}
        {selected && renderOptionForm()}
      </Panel>
    )
  }

  function renderMain() {
    return (
      <ThemeProvider primary={selected}>
        <HBox gap={layout.padding.m}>
          {renderIcon()}
          <VBox flex gap={layout.padding.inline.s}>
            <Label h3>{title}</Label>
            <Label small dim truncate={false} markup>{detail}</Label>
          </VBox>
        </HBox>
      </ThemeProvider>
    )
  }

  function renderIcon() {
    return (
      <SVG
        name={selected ? icon : 'empty'}
        color={tintColor}
        size={layout.icon.l}
      />
    )
  }

  function renderOptionForm() {
    if (renderForm == null) { return null }

    return (
      <VBox classNames={$.optionForm} ref={formRef}>
        {renderForm?.()}
      </VBox>
    )
  }

  return render()

}

export interface TypeOptionProps<M extends FormModel = any> extends Omit<OptionProps<M>, 'selected' | 'initialData' | 'renderForm'> {
  type:         string
  initialData?: OptionProps<M>['initialData']
  renderForm?:  (type: string) => React.ReactNode
}

const _TypeOption = <M extends FormModel>(props: TypeOptionProps<M>) => {
  const {name = 'type'} = React.useContext(FormSwitchContext)

  const {type, initialData, renderForm: props_renderForm, ...rest} = props
  const [value] = useFormField<string>(name)

  const renderForm = React.useMemo(() => {
    if (props_renderForm == null) { return undefined }
    return () => props_renderForm(type)
  }, [props_renderForm, type])

  return (
    <Option
      {...rest}
      selected={value === type}
      initialData={{[name]: type, ...initialData}}
      renderForm={renderForm}
    />
  )
}

interface FormSwitchContext {
  name:      string | undefined
  enabled:   boolean
  autoFocus: boolean
}

const FormSwitchContext = React.createContext<FormSwitchContext>({
  name:      undefined,
  enabled:   true,
  autoFocus: false,
})

const Option     = observer('FormSwitch.Option', _Option) as typeof _Option
const TypeOption = observer('FormSwitch.TypeOption', _TypeOption) as typeof _TypeOption

Object.assign(FormSwitch, {Option, TypeOption})
export default FormSwitch as typeof FormSwitch & {
  Option:     typeof Option
  TypeOption: typeof TypeOption
}

const useStyles = createUseStyles(theme => ({
  Option: {
    '&:not(.enabled)': {
      opacity:       0.6,
      pointerEvents: 'none',
      boxShadow:     [0, 0, 0, 1, theme.fg.dimmer],
    },
  },

  optionContent: {
    ...layout.responsiveProp({
      padding: layout.padding.m,
    }),
  },

  optionForm: {
    ...layout.responsive(size => ({
      marginLeft: layout.icon.l.width + layout.padding.m[size],
    })),
  },
}))