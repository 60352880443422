import I18next from 'i18next'
import { action, makeObservable, observable } from 'mobx'
import { Fetch } from 'mobx-document'
import { Branding, Group, Project, Ref, RegistrationPreset } from '~/models'
import { dataStore, ModelEndpoint } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

import type {
  GroupInfo,
  PreflightResponse,
  ProjectInfo,
  RegistrationData,
  RegistrationTranslationKey,
  RegistrationWidgetModel,
  RegistrationWidgetModelStatus,
  PreflightErrorReason,
} from '../../widget/types'

export class PreviewWidgetModel implements RegistrationWidgetModel, FormModel {

  constructor(
    public readonly preset: RegistrationPreset,
  ) {
    makeObservable(this)
  }

  @observable
  public branding: Ref<Branding> | null = this.preset.branding ?? null

  //------
  // Available projects / groups

  private projectEndpoint: ModelEndpoint<Project> = new ModelEndpoint<Project>(dataStore.db(Project), Project, {
    filters: {
      id:           {$in: this.preset.allowedProjects},
      organisation: this.preset.organisation,
    },
  })
  private groupEndpoint:   ModelEndpoint<Group> = new ModelEndpoint<Group>(dataStore.db(Group), Group)

  public availableProjects(): Fetch<ProjectInfo[]> {
    if (this.preset.allowedProjects.length === 0) {
      return {status: 'done', data: []}
    }

    this.projectEndpoint.fetchIfNeeded()
    return Fetch.map(this.projectEndpoint.asFetch, data => data.map(project => ({
      id:   project.id,
      name: project.name,
    })))
  }

  public availableGroups(projectID: string): Fetch<GroupInfo[]> {
    const {groupTag} = this.preset
    if (groupTag == null) { return {status: 'done', data: []} }

    const projectFilter = this.groupEndpoint.param('filters')?.project
    if (projectFilter !== projectID) {
      this.groupEndpoint.fetchWithParams({
        filters: {
          project: projectID,
          tags:    groupTag,
        },
      })
    }

    return Fetch.map(this.groupEndpoint.asFetch, data => data.map(group => ({
      id:   group.id,
      name: group.name,
      projectID,
    })))
  }

  //------
  // RegistrationWidgetModel

  @observable
  public status: RegistrationWidgetModelStatus = 'register'

  @observable
  public errorReason: PreflightErrorReason = 'other'

  @action
  public setStatus(status: RegistrationWidgetModelStatus) {
    this.status = status
  }

  public async preflight(): Promise<PreflightResponse | undefined> {
    return 'ok'
  }

  public async checkCode(code: string): Promise<PreflightResponse | undefined> {
    return 'ok'
  }

  public async register(data: RegistrationData): Promise<SubmitResult | undefined> {
    return
  }

  //------
  // Image & translation

  public image(name: string, variant: string | null = null) {
    const branding = this.branding == null ? null : dataStore.get(Branding, this.branding)
    if (branding == null) { return null }

    return branding.image(name, variant)
  }

  public translate(key: RegistrationTranslationKey, language: string = I18next.language) {
    const branding = this.branding == null ? null : dataStore.get(Branding, this.branding)
    if (branding != null) {
      const text = branding.text(`registration.${key}`, language)
      if (text != null) { return text }
    }

    return I18next.t(`registration-preview:${key}`, {lng: language, defaultValue: null})
  }

  //------
  // FormModel

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(RegistrationPreset, this.preset.id, {
      branding: this.branding,
    })
  }

}