import React from 'react'
import { ImageView } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { Center } from '~/ui/components'
import { createUseStyles } from '~/ui/styling'
import { useRegistrationWidgetModel } from './RegistrationWidgetContext'

export interface Props {
  renderDefaultLogo: (size: Size) => React.ReactNode
}

const RegistrationWidgetLogo = observer('RegistrationWidgetLogo', (props: Props) => {

  const {renderDefaultLogo} = props
  const model = useRegistrationWidgetModel()
  const image = model.image('logo', 'registration')

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Center classNames={$.RegistrationWidgetLogo}>
        {renderContent()}
      </Center>

    )
  }

  function renderContent() {
    return (
      <Center classNames={$.wrapper}>
        {image != null ? (
          <ImageView
            source={image}
            size={registrationLogoSize}
            objectFit='contain'
          />
        ) : (
          renderDefaultLogo(registrationLogoSize)
        )}
      </Center>
    )
  }

  return render()

})

export const registrationLogoSize = {
  width:  128,
  height: 128,
}

export default RegistrationWidgetLogo

const useStyles = createUseStyles({
  RegistrationWidgetLogo: {
    height: registrationLogoSize.height,
  },

  wrapper: {
    ...registrationLogoSize,
  },
})
