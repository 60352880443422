import { makeObservable, observable } from 'mobx'
import { RegistrationPreset } from '~/models'
import { ResourceFormModel } from '~/ui/resources/form'

export default class TimingFormModel extends ResourceFormModel<RegistrationPreset> {

  constructor(Model: typeof RegistrationPreset, model: RegistrationPreset | null) {
    super(Model, model)
    makeObservable(this)
  }

  @observable
  public validUntilEnabled: boolean = this.model?.validUntil != null

  @observable
  public durationEnabled: boolean = this.model?.duration != null

  protected override buildData() {
    return {
      validUntil: this.validUntilEnabled ? this.getValue('validUntil') : null,
      duration:   this.durationEnabled ? this.getValue('duration') : null,
    }
  }

}