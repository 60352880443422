import React from 'react'
import { Challenge } from '~/models'
import ModuleChip from '~/ui/app/modules/ModuleChip'
import { memo } from '~/ui/component'
import { DataGrid, HBox, Label, SVG, VBox } from '~/ui/components'
import { ResourceListScreen, TagsCell } from '~/ui/resources/collection'
import { layout, useStyling } from '~/ui/styling'
import ChallengeForm from '../ChallengeForm'

const ChallengesScreen = memo('ChallengesScreen', () => {

  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <ResourceListScreen
        Model={Challenge}
        CreateFormComponent={ChallengeForm}
        defaultSort='name'
      >
        <DataGrid.Column
          name='name'
          sort={true}
          renderCell={renderName}
          flex={3}
        />
        <DataGrid.Column<Challenge>
          name='tags'
          sort={true}
          flex={2}
          renderCell={challenge => <TagsCell tags={challenge.tags}/>}
        />
        <DataGrid.Column<Challenge>
          name='taskCount'
          flex={1}
          renderCell={challenge => challenge.taskCount}
        />
      </ResourceListScreen>
    )
  }

  function renderName(challenge: Challenge) {
    return (
      <HBox gap={layout.padding.s}>
        <SVG
          name='challenge'
          size={layout.icon.m}
          color={colors.semantic.primary}
        />
        <VBox gap={layout.padding.inline.xs}>
          <Label bold>
            {challenge.name}
          </Label>
          <HBox>
            <ModuleChip
              id={challenge.module}
              small
            />
          </HBox>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default ChallengesScreen