import React from 'react'
import { Media } from '~/models'
import { observer } from '~/ui/component'
import { useFiniteModelSetData } from '~/ui/hooks/data'
import PostAttachmentList from './PostAttachmentList'
import PostMediaGallery from './PostMediaGallery'

export interface Props {
  mediaIDs: string[]
  requestRemoveMedia?: (id: string) => any

  showAs?: PostMediaDisplay
}

export type PostMediaDisplay = 'mixed' | 'gallery' | 'list'

const PostMedia = observer('PostMedia', (props: Props) => {

  const {mediaIDs, requestRemoveMedia, showAs = 'mixed'} = props

  const [media] = useFiniteModelSetData(Media, mediaIDs)

  const galleryMedia = React.useMemo(
    () => media.filter(it => /^(image|video)\//.test(it.contentType)),
    [media],
  )

  const attachmentMedia = React.useMemo(
    () => media.filter(it => !/^(image|video)\//.test(it.contentType)),
    [media],
  )

  //------
  // Rendering

  function render() {
    if (media.length === 0) { return null }

    return (
      <>
        {showAs !== 'list' && renderDisplay('gallery')}
        {showAs !== 'gallery' && renderDisplay('list')}
      </>
    )
  }

  function renderDisplay(display: Exclude<PostMediaDisplay, 'mixed'>) {
    const mediaToShow = showAs === 'mixed'
      ? display === 'gallery' ? galleryMedia : attachmentMedia
      : media
    if (mediaToShow.length === 0) { return null }

    if (display === 'gallery') {
      return (
        <PostMediaGallery
          media={mediaToShow}
          requestRemoveMedia={requestRemoveMedia}
        />
      )
    } else {
      return (
        <PostAttachmentList
          media={mediaToShow}
          requestRemoveMedia={requestRemoveMedia}
        />
      )
    }
  }

  return render()

})

export default PostMedia