import { Linkage } from '~/stores'
import { registerNotification } from './registry'

registerNotification('operator:alert', {
  color: (_, theme) => theme.semantic.warning,
  icon:  () => 'headset',

  actions: notification => {
    const participantID = (notification.relationships.participant?.data as Linkage<any>)?.id
    const channelID     = (notification.relationships.channel?.data as Linkage<any>)?.id
    if (participantID == null || channelID == null) { return [] }

    return [{
      name: 'launch-operator',
      href: `/operator?participant=${participantID}&channel=${channelID}`,
    }]
  },
})