import React from 'react'
import { ChatBot } from '~/models'
import CustomImageField from '~/ui/app/fields/CustomImageField'
import { memo } from '~/ui/component'
import { HBox, TextField, VBox } from '~/ui/components'
import { FormField, FormProps } from '~/ui/form'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  chatBot?: ChatBot | null
}

const ChatBotForm = memo('ChatBotForm', (props: Props) => {

  const {open, requestClose, afterSubmit, chatBot = null} = props

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<ChatBot>
        Model={ChatBot}
        model={chatBot}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>
        <HBox align='top' gap={layout.padding.s}>
          {renderBasicFields()}
          {renderImageField()}
        </HBox>
      </VBox>
    )
  }

  function renderBasicFields() {
    return (
      <VBox flex gap={layout.padding.s}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>
        <FormField name='displayName' required>
          {bind => <TextField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderImageField() {
    return (
      <VBox>
        <FormField name='avatar' renderAsLabel={false}>
          {bind => (
            <CustomImageField
              {...bind}
              allowedTypes={['remote']}
              size={{width: 116, height: 116}}
            />
          )}
        </FormField>
      </VBox>
    )
  }

  return render()

})

export default ChatBotForm