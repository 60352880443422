import React from 'react'
import { Participant, Post } from '~/models'
import { observer } from '~/ui/component'
import { HBox, KebabMenu, Label, LinkLabel, PopupMenuItem, Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useTheme } from '~/ui/styling'

export interface Props {
  postID?:      string
  comment:      Post
  requestEdit?: (comment: Post) => any
}

const CommentHeader = observer('CommentHeader', (props: Props) => {

  const {comment, requestEdit} = props
  const theme = useTheme()

  const {actionCaption} = useResourceTranslation('posts')

  const [author] = useModelDocumentData(Participant, comment.author, {
    fetch: 'notfound',
  })

  const edit = React.useCallback(() => {
    requestEdit?.(comment)
  }, [comment, requestEdit])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    items.push({
      icon:    'pencil',
      caption:  actionCaption('edit_comment'),
      onSelect: edit,
    })

    return items
  }, [actionCaption, edit])

  //------
  // Rendering

  function render() {
    return (
      <HBox gap={layout.padding.inline.s} justify='space-between'>
        {author == null ? (
          <Spinner size={8}/>
        ) : (
          <LinkLabel
            bold
            small
            href={`/participants/-/${comment.author}`}
            color={theme.fg.normal}
            children={author.name}
            flex='shrink'
          />
        )}
        <HBox gap={layout.padding.inline.s}>
          {renderDate()}
          {kebabMenuItems.length > 0 && (
            <KebabMenu
              items={kebabMenuItems}
              button={{small: true, padding: 'horizontal'}}
            />
          )}
        </HBox>
      </HBox>
    )
  }

  function renderDate() {
    if (comment.publishedAt == null) { return null }

    return (
      <Label tiny dim>
        {comment.publishedAt?.toFormat('MMM d, HH:mm')}
      </Label>
    )
  }


  return render()

})

export default CommentHeader