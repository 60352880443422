import React from 'react'
import { useTranslation } from 'react-i18next'
import { isFunction } from 'lodash'
import { Model, ModelClass } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  ClearButton,
  ConfirmBox,
  Dimple,
  Label,
  List,
  Spinner,
  VBox,
} from '~/ui/components'
import { useFiniteModelSetData } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import ResourceBar, { Props as ResourceBarProps } from '../components/ResourceBar'
import ResourceField, { Props as ResourceFieldProps } from './ResourceField'

export interface Props<M extends Model> {
  Model:     ModelClass<M>
  parent:    Model
  attribute: string

  allowAdd?:      boolean
  allowDetail?:   boolean
  allowRemove?:   boolean | ((model: M) => boolean)
  removeConfirm?: {title: string, message: string} | false

  filters?: Record<string, any>
  linked?:  boolean

  renderItem?:  (model: M) => React.ReactNode
  itemProps?:   (model: M) => Partial<Omit<ResourceBarProps, 'model' | 'onTap'>>
  onItemTap?:   (model: M) => any

  emptyPlaceholder?: string
  addPrompt?:        React.ReactNode

  renderAddField?: (select: (model: M) => void, props: Partial<ResourceFieldProps<M>>) => React.ReactNode
}

const RelatedResourceList = observer('RelatedResourceList', <M extends Model>(props: Props<M>) => {

  const {
    Model,
    parent,
    attribute,
    allowAdd = true,
    allowDetail = true,
    allowRemove = true,
    filters,
    linked,
    emptyPlaceholder,
    removeConfirm,
    renderItem,
    itemProps,
    onItemTap,
    renderAddField,
    addPrompt,
  } = props

  const [t] = useTranslation('related_resource_list')
  const {singular, plural} = useResourceTranslation(Model.resourceType)
  const {singular: parentSingular} = useResourceTranslation(parent.$ModelClass.resourceType)

  //------
  // Data

  const ids = parent[attribute as keyof typeof parent] as string[]
  const [models, {fetchStatus}] = useFiniteModelSetData(Model, ids ?? [], {detail: false})

  //------
  // Callbacks

  const addItem = React.useCallback((item: M) => {
    return dataStore.update(parent.$ModelClass, parent.id, {
      [attribute]: [...ids, item.id],
    })
  }, [attribute, ids, parent.$ModelClass, parent.id])

  const removeItem = React.useCallback(async (id: string) => {
    const confirmed = removeConfirm === false ? true : await ConfirmBox.show({
      ...removeConfirm ?? {
        title:   t('remove_confirm.title', {type: singular(), parent: parentSingular()}),
        message: t('remove_confirm.message', {type: singular(), parent: parentSingular()}),
      },
      destructive: true,
    })
    if (!confirmed) { return }

    const newIDs = ids.filter(it => it !== id)
    await dataStore.update(parent.$ModelClass, parent.id, {
      [attribute]: newIDs,
    })
  }, [attribute, ids, parent.$ModelClass, parent.id, parentSingular, removeConfirm, singular, t])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {renderList()}
        {allowAdd && renderAdd()}
      </VBox>
    )
  }

  function renderList() {
    return (
      <List
        data={models}
        renderItem={renderListItem}
        EmptyComponent={renderEmpty}
        itemGap={layout.padding.inline.s}
        flex={false}
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    if (fetchStatus === 'fetching') {
      return (
        <Center classNames={$.fetching}>
          <Spinner size={16}/>
        </Center>
      )
    } else {
      return (
        <VBox justify='middle' classNames={$.empty}>
          <Label dimmer align='center'>
            {emptyPlaceholder ?? t('empty', {plural: plural()})}
          </Label>
        </VBox>
      )
    }
  }, [$.empty, $.fetching, emptyPlaceholder, fetchStatus, plural, t])

  function renderListItem(item: M) {
    if (renderItem != null) {
      return renderItem(item)
    }

    return (
      <ResourceBar
        model={item}
        accessory={renderRemoveButton(item)}
        onTap={onItemTap?.bind(null, item)}
        link={allowDetail && onItemTap == null}
        {...itemProps?.(item)}
      />
    )
  }

  function renderRemoveButton(item: M) {
    const mayRemove = isFunction(allowRemove) ? allowRemove(item) : allowRemove
    if (!mayRemove) { return null }

    return (
      <ClearButton
        icon='minus-circle'
        onTap={() => removeItem(item.id)}
      />
    )
  }

  function renderAdd() {
    const prompt = addPrompt != null && !isReactText(addPrompt)
      ? addPrompt
      : (
        <Label dim caption small>
          {addPrompt ?? t('add_prompt', {type: singular()})}
        </Label>
      )

    return (
      <>
        <Dimple horizontal/>
        <VBox gap={layout.padding.inline.m}>
          {prompt}
          {renderAddField ? (
            renderAddField(addItem, {
              excludeIDs: ids,
              filters:    filters,
              linked:     linked,
            })
          ) : (
            <ResourceField
              Model={Model}
              onSelect={addItem}
              excludeIDs={ids}
              filters={filters}
              linked={linked}
            />
          )}
        </VBox>
      </>
    )
  }

  return render()

})

export default RelatedResourceList

const useStyles = createUseStyles(theme => ({
  fetching: {
    height: layout.barHeight.m,
  },

  empty: {
    height:       layout.barHeight.m,
    background:   theme.bg.alt,
    borderRadius: layout.radius.m,
    padding:      [layout.padding.inline.m, layout.padding.inline.l],
  },
}))