import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { SwitchField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'

const WebhookTriggerFields = memo('WebhookTriggerFields', () => {

  const [t] = useTranslation('flow_planner')

  return (
    <VBox gap={layout.padding.s}>
      <FormField
        name='collective'
        caption={t('triggers.webhook.fields.collective.caption')!}
        label={t('triggers.webhook.fields.collective.label')!}
        instruction={t('triggers.webhook.fields.collective.instruction')!}
        required
      >
        {bind => <SwitchField {...bind}/>}
      </FormField>
    </VBox>
  )

})

export default WebhookTriggerFields