import React from 'react'
import { cloneDeep, get, mapValues, set } from 'lodash'
import { action, makeObservable, observable } from 'mobx'
import { AvailableFeature, Project } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { deriveDynamicFormSchema, DynamicFieldset } from '~/ui/app/dynamic-form'
import { memo } from '~/ui/component'
import { FormDialog, ProxyFormModel, SubmitResult, translateFormErrorPaths } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'

import type { FeaturePrefix } from './FeatureListFieldset'
export interface Props {
  open:         boolean
  requestClose: () => any

  prefix:  FeaturePrefix
  feature: AvailableFeature
}

const FeatureOptionsForm = memo('FeatureOptionsForm', (props: Props) => {

  const {
    open,
    requestClose,
    feature,
    prefix,
  } = props

  const [options] = useFormField<AnyObject>(`${prefix}.${feature.name}.options`)

  const formModel = React.useMemo(
    () => new FeatureOptionsFormModel(prefix, feature, options),
    [prefix, feature, options],
  )

  const optionsFormSchema = React.useMemo(() => {
    return deriveDynamicFormSchema(feature.optionsSchema, feature.translate('options') ?? {})
  }, [feature])

  const caption = feature.translate('caption')

  //------
  // Rendering

  function render() {
    return (
      <FormDialog open={open} requestClose={requestClose} model={formModel} semi={false} icon='hexagons' title={caption}>
        <DynamicFieldset schema={optionsFormSchema}/>
      </FormDialog>
    )
  }

  return render()

})

export default FeatureOptionsForm

class FeatureOptionsFormModel implements ProxyFormModel<AnyObject> {

  constructor(
    public readonly prefix:  FeaturePrefix,
    public readonly feature: AvailableFeature,
    options: AnyObject,
  ) {
    this.options = options

    makeObservable(this)
  }

  @observable
  private options: AnyObject

  public getValue(path: string) {
    return get(this.options, path)
  }

  @action
  public assign(data: AnyObject) {
    const options = cloneDeep(this.options)
    for (const [path, value] of Object.entries(data)) {
      set(options, path, value)
    }
    this.options = options
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const project = projectStore.project
    if (project == null) { return }

    const features = mapValues(project[this.prefix], (feature, name) => {
      if (name === this.feature.name) {
        return {...feature, options: this.options}
      } else {
        return feature
      }
    })

    const result = await dataStore.update(Project, project.id, {[this.prefix]: features})
    return translateFormErrorPaths(result, path => path.replace(/^features\.[^.]+\./, ''))
  }

}