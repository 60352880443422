import React from 'react'
import { Participant } from '~/models'
import { Avatar } from '~/ui/app/media'
import { HBox, Label, SVG } from '~/ui/components'
import { RelatedCell } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'

const ParticipantCell = RelatedCell.of(Participant, {
  image:   renderAvatar,
  caption: renderCaption,
})

export default ParticipantCell

function renderAvatar(participant: Participant) {
  return (
    <Avatar
      {...participant}
      source={participant.photoURL}
      size={avatarSize}
    />
  )
}

function renderCaption(participant: Participant) {
  return (
    <HBox flex='shrink' gap={layout.padding.inline.m}>
      <Label flex='shrink' bold small>
        {participant.name}
      </Label>
      {!participant.listed && (
        <SVG name='eye-off' dim size={layout.icon.xs}/>
      )}
    </HBox>
  )
}

export const avatarSize = {
  width:  32,
  height: 32,
}