import React from 'react'
import { useHistory } from 'react-router-dom'
import { SelectionManager, SelectionManagerProvider } from 'react-selection-manager'
import { useTimer } from 'react-timer'
import { isFunction, some } from 'lodash'
import { FetchStatus } from 'mobx-document'
import { ImportResult } from 'sheet-importer'
import { Model, ModelClass, modelIsImportable } from '~/models'
import { BulkSelector, projectStore, Sort } from '~/stores'
import { observer } from '~/ui/component'
import {
  Center,
  Dropzone,
  EmptyOrFetching,
  HBox,
  KebabMenu,
  Label,
  PopupMenu,
  PopupMenuHeader,
  PopupMenuItem,
  PushButton,
  SegmentedButton,
  Spinner,
  VBox,
} from '~/ui/components'
import { DropzoneState } from '~/ui/components/Dropzone'
import { SubmitResult } from '~/ui/form'
import { useBoolean, usePrevious } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { AppLayoutConfig } from '~/ui/layouts/app'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { colors, createUseStyles, layout } from '~/ui/styling'
import { isReactComponent, saveAs } from '~/ui/util'
import { useResourceListBreadcrumbs } from '../hooks'
import ResourceImportDialog, { Props as ResourceImportDialogProps } from '../import/ResourceImportDialog'
import { resourceDetailPath } from '../routes'
import ResourceCollectionSummaryPanel from './ResourceCollectionSummaryPanel'
import SelectionPanel from './SelectionPanel'
import BulkActionsPanel from './bulk-actions/BulkActionsPanel'
import {
  BulkAction,
  CreateFormComponentMap,
  CreateFormComponentProp,
  CreateFormProps,
} from './types'
import { useResourceListLocation } from './useResourceListLocation'
import { bulkSelectorForSelection } from './util'

export interface Props<M extends Model> {
  Model:    ModelClass<M>
  include?: string[]

  allowCreate?: boolean
  allowDetail?: boolean
  allowImport?: boolean
  allowExport?: boolean

  ImportDialog?: React.ComponentType<ResourceImportDialogProps<M>>

  bulkActions?: BulkAction<M>[]
  allowRemove?: boolean
  allowCopy?:   boolean

  labels?:      Array<ResourceLabel | string>
  defaultSort?: Sort | string
  searchable?:  boolean

  CreateFormComponent?: CreateFormComponentProp
  afterCreate?:         (result: SubmitResult) => any

  children?:      React.ReactNode | ((state: ResourceCollectionScreenState<M>) => React.ReactNode)
  renderFilters?: React.ReactNode | (() => React.ReactNode)
  renderActions?: React.ReactNode | (() => React.ReactNode)
}

export interface ResourceLabel {
  label:   string
  caption: string
}

export interface ResourceCollectionScreenState<T> {
  data:        T[]
  fetchStatus: FetchStatus

  itemHref: (item: T) => string | null

  sort:    Sort | null
  setSort: (sort: Sort | null) => any

  EmptyComponent: React.ComponentType<{}>

  selectionManager: SelectionManager<string>
}

const _ResourceCollectionScreen = <M extends Model>(props: Props<M>) => {

  const {
    Model,
    include,
    allowCreate = true,
    allowRemove = true,
    allowDetail = true,
    searchable  = true,
    allowCopy = true,
    allowImport = modelIsImportable(Model),
    ImportDialog,
    allowExport = true,
    bulkActions = [],
    CreateFormComponent,
    afterCreate,
    children,
    renderFilters,
    renderActions,
  } = props

  const defaultSort: Sort | null =
    props.defaultSort == null ? null :
    typeof props.defaultSort === 'string' ? {field: props.defaultSort, direction: 1} :
    props.defaultSort

  const {t, actionCaption, plural} = useResourceTranslation(Model.resourceType)

  const labels = React.useMemo(() => (props.labels ?? []).map((label): ResourceLabel => {
    if (typeof label === 'string') {
      return {label, caption: t(`labels.${label}`)}
    } else {
      return label
    }
  }), [props.labels, t])

  const {
    syncing,
    label, setLabel,
    search, setSearch,
    filters,
    page, setPage,
    sort, setSort,
  } = useResourceListLocation(Model.resourceType, true)

  const history = useHistory()

  const resolvedSort = sort ?? defaultSort
  const sorts = React.useMemo(() => resolvedSort == null ? [] : [resolvedSort], [resolvedSort])

  const endpoint = useModelEndpoint(Model, {
    include,
    label: label ?? undefined,
    fetch: !syncing && (label != null || labels.length === 0),
    search: searchable ? search : undefined,
    filters,
    page,
    sorts,
  })

  const prevPage = usePrevious(page)
  const prevSort = usePrevious(sort)

  const shouldFetch = !syncing && (prevPage === undefined || prevSort === undefined || page !== prevPage || sort !== prevSort)

  React.useEffect(() => {
    if (shouldFetch) {
      endpoint.setParams({
        filters,
        page,
        sorts,
      })
    }
  }, [Model.name, endpoint, label, page, prevPage, shouldFetch, sort, sorts, filters])

  const canCreate = allowCreate && CreateFormComponent != null
  const breadcrumbs = useResourceListBreadcrumbs(Model)

  //------
  // Labels

  const labelButtons = React.useMemo(() => labels.length === 1 ? [] : labels.map(label => ({
    value:   label.label,
    caption: label.caption,
  })), [labels])

  React.useEffect(() => {
    if (labels.length === 0) { return }
    if (label == null || !some(labels, it => it.label === label)) {
      setLabel(labels[0].label)
    }
  }, [label, labels, setLabel])

  //------
  // Create option

  const [createFormOpen, openCreateForm, closeCreateFormState] = useBoolean()
  const [createValue, setCreateValue] = React.useState<string | null>(null)

  const closeCreateForm = React.useCallback(() => {
    const params = new URLSearchParams(document.location.search)
    if (params.has('new')) {
      history.replace('?')
    }
    closeCreateFormState()
  }, [closeCreateFormState, history])

  React.useEffect(() => {
    const params = new URLSearchParams(document.location.search)
    if (params.has('new')) {
      openCreateForm()
    }
  }, [openCreateForm])

  const createMenuItems = React.useMemo(() => {
    if (CreateFormComponent == null) { return [] }
    if (isReactComponent(CreateFormComponent)) { return [] }

    const map = CreateFormComponent as CreateFormComponentMap
    return Object.entries(map).map(([value, info]): PopupMenuItem => ({
      value:   value,
      caption: info.caption,
      detail:  info.detail,
      enabled: info.available ?? true,
    }))
  }, [CreateFormComponent])

  const openCreateFormFor = React.useCallback((value: string) => {
    setCreateValue(value)
    openCreateForm()
  }, [openCreateForm])

  const redirectAfterCreate = React.useCallback((result: SubmitResult) => {
    if (result.status !== 'ok') { return }
    if (result.data?.id == null) { return }

    history.push(resourceDetailPath(Model.resourceType, result.data.id))
  }, [Model.resourceType, history])

  //------
  // Selection

  const selectionManager = React.useMemo(
    () => new SelectionManager<string>(),
    [],
  )

  const {hasSelection} = selectionManager

  const handleActionComplete = React.useCallback((action: BulkAction) => {
    selectionManager.selectNone()
    if (action.refetch) {
      endpoint.fetch()
    }
  }, [endpoint, selectionManager])

  //------
  // Export

  const [exporting, setExporting] = React.useState<boolean>(false)
  const exportTimer = useTimer()

  const exportData = React.useCallback(async () => {
    setExporting(true)

    const selector = bulkSelectorForSelection(Model.resourceType, selectionManager, filters, label)
    const blob     = await endpoint.exportData(selector ?? BulkSelector.all())
    if (blob == null) { return }

    saveAs(blob, `${plural()}.xlsx`)
    if (!exportTimer.isDisposed) {
      setExporting(false)
    }
  }, [Model.resourceType, selectionManager, filters, label, endpoint, plural, exportTimer.isDisposed])

  //------
  // Import

  const [importDialogOpen, openImportDialog, closeImportDialog] = useBoolean()
  const [importFile, setImportFile] = React.useState<File | null>(null)

  const startImport = React.useCallback(() => {
    setImportFile(null)
    openImportDialog()
  }, [openImportDialog])

  const kebabMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []

    if (allowImport) {
      items.push({
        icon:     'upload',
        caption:  actionCaption('import'),
        onSelect: startImport,
      })
    }

    if (allowExport) {
      items.push({
        icon:     'download',
        caption:  actionCaption('export-all'),
        onSelect: exportData,
      })
    }

    return items
  }, [actionCaption, allowExport, allowImport, exportData, startImport])

  const handleDropzoneDrop = React.useCallback((files: File[]) => {
    if (files.length === 0) { return }
    setImportFile(files[0])
    openImportDialog()
  }, [openImportDialog])

  const fetchAfterImport = React.useCallback((result: ImportResult) => {
    if (result.status !== 'completed') { return }

    if (result.nImported > 0) {
      endpoint.fetch()
    }
  }, [endpoint])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType={Model.resourceType}>
        <SelectionManagerProvider manager={selectionManager}>
          <AppLayoutConfig
            configKey={Model.resourceType}
            breadcrumbs={breadcrumbs}
            ActionsComponent={AppHeaderActions}
          />

          {renderBody()}
          {renderCreateForm()}
          {renderImportDialog()}
          {exporting && renderExportOverlay()}
        </SelectionManagerProvider>
      </ResourceTypeProvider>
    )
  }

  function renderImportDialog() {
    if (!allowImport) { return null }

    const mainModuleID = projectStore.mainModule?.id
    if (mainModuleID == null) { return null }

    const Dialog = ImportDialog ?? ResourceImportDialog
    return (
      <Dialog
        open={importDialogOpen}
        requestClose={closeImportDialog}
        Model={Model}
        moduleID={mainModuleID}
        startWithFile={importFile}
        onImportComplete={fetchAfterImport}
      />
    )
  }

  function renderExportOverlay() {
    return (
      <Center classNames={$.exporting} gap={layout.padding.s}>
        <Spinner/>
        <Label caption dim>
          {t('exporting')}
        </Label>
      </Center>
    )
  }

  //------
  // Actions

  const renderCreateButton = React.useCallback((onTap: () => any) => {
    return (
      <PushButton
        icon='plus'
        caption={t('new')}
        onTap={onTap}
        small
      />
    )
  }, [t])

  const renderCreateMenuHeader = React.useCallback(() => {
    return (
      <PopupMenuHeader
        icon='plus'
        caption={t('create_header')}
      />
    )
  }, [t])

  const renderCreateButtonOrMenu = React.useCallback(() => {
    if (!canCreate) { return null }
    if (CreateFormComponent == null) { return null }

    if (!isReactComponent(CreateFormComponent)) {
      return (
        <PopupMenu
          header={renderCreateMenuHeader()}
          items={createMenuItems}
          onValueSelect={openCreateFormFor}
          children={renderCreateButton}
          crossAlign='center'
        />
      )
    } else {
      return renderCreateButton(openCreateForm)
    }
  }, [CreateFormComponent, canCreate, createMenuItems, openCreateForm, openCreateFormFor, renderCreateButton, renderCreateMenuHeader])

  const AppHeaderActions = React.useCallback(() => {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderCreateButtonOrMenu()}
        <KebabMenu
          items={kebabMenuItems}
        />
      </HBox>
    )
  }, [kebabMenuItems, renderCreateButtonOrMenu])

  const EmptyComponent = React.useCallback(() => (
    <EmptyOrFetching
      title={t('empty.title')}
      detail={t(canCreate ? 'empty.detail_create' : 'empty.detail')}
      status={endpoint.fetchStatus}
      flex={true}
      children={renderCreateButtonOrMenu()}
    />
  ), [canCreate, endpoint.fetchStatus, renderCreateButtonOrMenu, t])

  //------
  // Body

  function renderBody() {
    const body = (
      <HBox flex classNames={$.body} align='stretch' gap={layout.padding.m}>
        <VBox flex={3} gap={layout.padding.s}>
          {renderLabelSelector()}
          <VBox flex>
            {renderContent()}
          </VBox>
        </VBox>
        {renderRight()}
      </HBox>
    )

    if (allowImport) {
      return wrapInDropzone(body)
    } else {
      return body
    }
  }

  function wrapInDropzone(content: React.ReactNode) {
    return (
      <Dropzone
        accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' // Jesus
        onDrop={handleDropzoneDrop}
        renderContent={renderDropzoneContent.bind(null, content)}
        showIdleHint={false}
        acceptHint={t('import.drop_hint')}
        noClick
        flex
      />
    )
  }

  function renderDropzoneContent(content: React.ReactNode, state: DropzoneState) {
    return (
      <VBox flex>
        <VBox flex>
          {content}
        </VBox>
        {state.renderDropHint()}
      </VBox>
    )
  }

  function renderLabelSelector() {
    if (labelButtons.length === 0) { return null }

    return (
      <Center>
        <SegmentedButton
          segments={labelButtons}
          selectedValue={label ?? null}
          onChange={setLabel}
        />
      </Center>
    )
  }

  function renderContent() {
    if (isFunction(children)) {
      return children(state)
    } else {
      return children
    }
  }

  function renderRight() {
    return (
      <VBox flex={1} classNames={$.right} gap={layout.padding.m}>
        <ResourceCollectionSummaryPanel
          endpoint={endpoint}
          searchable={searchable}
          search={search ?? null}
          setSearch={setSearch}
          page={page ?? 1}
          setPage={setPage}
        />
        {hasSelection && (
          <SelectionPanel
            endpoint={endpoint}
          />
        )}
        {hasSelection ? (
          <BulkActionsPanel
            endpoint={endpoint}
            actions={bulkActions}
            export={allowExport}
            remove={allowRemove}
            copy={allowCopy}
            onActionComplete={handleActionComplete}
          />
        ) : isFunction(renderFilters) ? (
          renderFilters()
        ) : (
          renderFilters
        )}
        {isFunction(renderActions) ? (
          renderActions()
        ) : (
          renderActions
        )}
      </VBox>
    )
  }

  //------
  // Create form

  function renderCreateForm() {
    if (!allowCreate) { return null }
    if (CreateFormComponent == null) { return null }

    let Component: React.ComponentType<CreateFormProps>
    if (isReactComponent(CreateFormComponent)) {
      Component = CreateFormComponent as React.ComponentType<CreateFormProps>
    } else if (createValue != null && createValue in CreateFormComponent) {
      Component = (CreateFormComponent as any)[createValue].Component
    } else {
      return null
    }

    return (
      <Component
        open={createFormOpen}
        requestClose={closeCreateForm}
        afterSubmit={afterCreate ?? redirectAfterCreate}
      />
    )
  }

  //------
  // State

  const itemHref = React.useCallback((model: Model) => {
    if (!allowDetail) { return null }
    return resourceDetailPath(Model.resourceType, model.id)
  }, [Model.resourceType, allowDetail])

  const state = React.useMemo((): ResourceCollectionScreenState<M> => ({
    data:             endpoint.data,
    fetchStatus:      endpoint.fetchStatus,
    itemHref:         itemHref,
    sort:             resolvedSort,
    setSort:          setSort,
    selectionManager: selectionManager,
    EmptyComponent:   EmptyComponent,
  }), [EmptyComponent, endpoint.data, endpoint.fetchStatus, itemHref, resolvedSort, selectionManager, setSort])

  return render()

}

const ResourceCollectionScreen = observer('ResourceCollectionScreen', _ResourceCollectionScreen) as typeof _ResourceCollectionScreen
export default ResourceCollectionScreen

export const rightMinWidth = 320
export const rightMaxWidth = 320

const useStyles = createUseStyles({
  body: {
    ...layout.responsive(size => ({
      padding:     layout.padding.m[size],
      paddingLeft: 0,
    })),
  },

  right: {
    minWidth: rightMinWidth,
    maxWidth: rightMaxWidth,
  },

  exporting: {
    ...layout.overlay,
    background: colors.shim.white,
  },
})