import React from 'react'
import { assignRef, useContinuousRef } from '~/ui/hooks'

export function useCanvasZoom(spec: FlowPlannerCanvasZoomSpec): [FlowPlannerCanvasZoomConnect] {
  const elementRef = React.useRef<HTMLElement | null>()

  // Use refs internally for the handlers.
  const onZoom  = useContinuousRef(spec.onZoom)
  const zoomRef = useContinuousRef(spec.zoom)

  //------
  // Mouse wheel

  const onWheel = React.useCallback((event: WheelEvent) => {
    if (!event.metaKey) { return }

    event.preventDefault()

    const newZoom = zoomRef.current * Math.pow(1.1, event.deltaY / 50)
    onZoom.current?.(newZoom)
  }, [onZoom, zoomRef])

  //------
  // Connect

  const connect = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (element: HTMLElement | null) => {
      assignRef(ref, element)

      if (element === elementRef.current) { return }

      if (elementRef.current) {
        elementRef.current.removeEventListener('wheel', onWheel)
      }
      if (element)     {
        element.addEventListener('wheel', onWheel)
      }

      elementRef.current = element
    }
  }, [onWheel])

  return [connect]
}

export interface FlowPlannerCanvasZoomSpec {
  zoom:   number
  onZoom: (zoom: number) => any
}

export type FlowPlannerCanvasZoomConnect = (ref: React.Ref<HTMLElement>) => (element: HTMLElement | null) => void