import React from 'react'
import { BeaconLocation, Participant } from '~/models'
import { ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import LocationParticipantList from './LocationParticipantList'

export interface Props {
  location:             BeaconLocation
  participantsEndpoint: ModelEndpoint<Participant>
}

const BeaconLocationBody = observer('BeaconLocationBody', (props: Props) => {

  const {location, participantsEndpoint} = props

  //------
  // Rendering

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        <LocationParticipantList
          location={location}
          endpoint={participantsEndpoint}
        />
      </VBox>
    )
  }

  return render()

})

export default BeaconLocationBody