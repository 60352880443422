import I18next from 'i18next'
import { Project, Ref } from '~/models'
import { CustomImage } from './CustomImage'
import { Model, resource } from './Model'
import { ProjectFeatures } from './Project'

@resource<Module>('modules', {
  icon:      module => module?.plannerType === 'calendar' ? 'calendar' : 'puzzle',
  caption:   module => module.name,
  details:   module => module.main ? I18next.t('modules:main') : null,
  hasDetail: module => module.parameters != null,
  scopedToModule: false,
})
export class Module extends Model {

  public project!: Ref<Project>

  public main!:        boolean
  public name!:        string
  public description!: string
  public image!:       CustomImage
  public tags!:        string[]

  public plannerType!: PlannerType
  public features!:    ProjectFeatures

  public parameterCount!: number
  public parameters?:     ModuleParameter[]

}

export type PlannerType = 'flow' | 'calendar'
export const PlannerType: {
  all: PlannerType[]
} = {
  all: ['flow', 'calendar'],
}

export type ModuleParameter =
  | StringModuleParameter
  | NumberModuleParameter
  | BooleanModuleParameter
  | TriggerableModuleParameter

export interface ModuleParameterCommon {
  name: string
  type: ModuleParameterType
}

export interface StringModuleParameter extends ModuleParameterCommon {
  type:    'string'
  default: string | null

  minLength: number | null
  maxLength: number | null
  pattern:   string | null
}

export interface NumberModuleParameter extends ModuleParameterCommon {
  type:    'number'
  default: number | null

  min:     number | null
  max:     number | null
  integer: boolean | null
}

export interface BooleanModuleParameter extends ModuleParameterCommon {
  type:    'boolean'
  default: boolean | null
}

export interface TriggerableModuleParameter extends ModuleParameterCommon {
  type:    'script' | 'challenge'
  tag:     string | null
  default: string | null
}

export const ModuleParameter: {
  default:                (type: ModuleParameterType, extra?: Partial<ModuleParameter>) => ModuleParameter
  isTriggerableParameter: (parameter: ModuleParameter) => parameter is TriggerableModuleParameter
} = {
  default: (type, extra = {}): ModuleParameter => {
    const defaults = {name: '', default: null}
    switch (type) {
      case 'string':
        return {
          type: 'string',
          ...defaults,
          minLength: null,
          maxLength: null,
          pattern: null,
          ...extra,
        } as StringModuleParameter
      case 'number':
        return {
          type: 'number',
          ...defaults,
          min: null, max: null, integer: false,
          ...extra,
        } as NumberModuleParameter
      case 'boolean':
        return {
          type: 'boolean',
          ...defaults,
          ...extra,
        } as BooleanModuleParameter
      case 'script':
        return {
          type: 'script',
          ...defaults,
          tag: null,
          ...extra,
        } as TriggerableModuleParameter
      case 'challenge':
        return {
          type: 'challenge',
          ...defaults,
          tag: null,
          ...extra,
        } as TriggerableModuleParameter
    }
  },

  isTriggerableParameter: (parameter): parameter is TriggerableModuleParameter => {
    return parameter.type === 'script' || parameter.type === 'challenge'
  },
}

export type ModuleParameterType = 'string' | 'number' | 'boolean' | 'script' | 'challenge'
export const ModuleParameterType: {
  all: () => ModuleParameterType[]
} = {
  all: () => ['string', 'number', 'boolean', 'script',  'challenge'],
}

