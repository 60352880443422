import React from 'react'
import { observer } from '~/ui/component'
import { NumberField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import ComponentBrandingFields from '../ComponentBrandingFields'
import { registerComponentBrandingFields } from '../registry'

const OAuthButtonFields = observer('OAuthButtonFields', () => {

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.l}>
        <ComponentBrandingFields/>
        <FormField name='height'>
          {bind => <NumberField {...bind} minimum={40} maximum={60} step={1}/>}
        </FormField>
      </VBox>
    )
  }

  return render()

})

registerComponentBrandingFields('web.login.oAuthButton', OAuthButtonFields)
registerComponentBrandingFields('app.login.oAuthButton', OAuthButtonFields)
export default OAuthButtonFields