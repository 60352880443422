import { CalendarItem, CalendarPlan } from '~/models'
import { CalendarPlanner } from '~/stores'
import { ConditionsFormModel, ConditionsFormModelContext } from '~/ui/app/conditions'
import { SubmitResult } from '~/ui/form'

export default class CalendarItemConditionsFormModel extends ConditionsFormModel {

  constructor(
    private readonly planner: CalendarPlanner,
    private readonly item: CalendarItem,
  ) {
    super(item.conditions)
  }

  public deriveContextFromCalendarPlan(plan: CalendarPlan) {
    this.setContext(deriveContextFromCalendarPlan(plan, this.item))
  }

  public async submit(): Promise<SubmitResult | undefined> {
    await this.planner.updateItem(this.item.uuid, item => ({
      ...item,
      conditions: this.conditions,
    }))

    return {status: 'ok'}
  }

}

function deriveContextFromCalendarPlan(plan: CalendarPlan, item: CalendarItem): ConditionsFormModelContext {
  const context: ConditionsFormModelContext = {}

  const day = plan.days.find(it => it.items.includes(item))
  if (day == null) { return context }

  const itemIndex   = day.items.indexOf(item)
  const itemsBefore = day.items.slice(0, itemIndex)

  context.recentScriptIDs = []
  for (const item of itemsBefore) {
    if (item.type !== 'triggerable' || item.triggerable.type !== 'script') { continue }
    context.recentScriptIDs.push(item.triggerable.model)
  }

  return context
}