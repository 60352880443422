import I18next from 'i18next'
import { CustomImage } from '~/models'
import { Model, resource } from './Model'
import { Module } from './Module'
import { Ref } from './types'

@resource<QAndA>('q-and-as', {
  icon:    () => 'question',
  caption: qAndA => qAndA.name,
  details: qAndA => I18next.t('q-and-as:question_count', {count: qAndA.questionCount}),
})
export class QAndA extends Model {

  public module!:        Ref<Module>
  public name!:          string
  public image!:         CustomImage | null
  public questionCount!: number

}