import React from 'react'
import { SystemNotification } from '~/models'
import { notificationSpec } from '~/notifications'
import { memo } from '~/ui/component'
import { Center, HBox, Label, SVG, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, presets, useTheme } from '~/ui/styling'

export interface Props {
  notification: SystemNotification
  onTap?:       () => any
}

const NotificationPopupItem = memo('NotificationPopupItem', (props: Props) => {

  const {notification, onTap} = props
  const theme = useTheme()

  const spec  = notificationSpec(notification.type)
  const icon  = spec.icon(notification)
  const color = theme.isDark ? theme.fg.normal : spec.color(notification, theme)
  const href  = `/notifications/-/${notification.id}`

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Tappable href={href} onTap={onTap}>
        <HBox classNames={$.notificationListItem} gap={layout.padding.inline.m} align='stretch'>
          <Center>
            <SVG name={icon} size={layout.icon.m} color={color}/>
          </Center>
          <VBox flex justify='middle'>
            <HBox gap={layout.padding.inline.s}>
              <Label flex='shrink' small bold markup>
                {notification.title}
              </Label>
              {notification.priority === 'high' && (
                <SVG
                  name='exclamation'
                  color={theme.semantic.negative}
                  size={layout.icon.xxs}
                />
              )}
            </HBox>
            <Label tiny dim markup>
              {notification.message}
            </Label>
          </VBox>
          <VBox>
            <Label tiny bold style={{paddingTop: '0.2em'}}>
              {notification.createdAt.toFormat('H:mm')}
            </Label>
          </VBox>
        </HBox>
      </Tappable>
    )
  }

  return render()

})

export default NotificationPopupItem

export const height = layout.barHeight.s

const useStyles = createUseStyles(theme => ({
  notificationListItem: {
    height,
    padding: [layout.padding.inline.xs, layout.padding.inline.m],

    position: 'relative',
    '&:hover': {
      ...presets.overlayAfter({
        background: theme.bg.hover,
      }),
    },
  },
}))