import React from 'react'
import { Group } from '~/models'
import { dataStore } from '~/stores'
import { ConditionsForm, ConditionsFormModel } from '~/ui/app/conditions'
import { memo } from '~/ui/component'
import { SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'

export interface Props {
  open:         boolean
  requestClose: () => any
  group:        Group & {type: 'segment'}
}

const GroupConditionsForm = memo('GroupConditionsForm', (props: Props) => {

  const {open, requestClose, group} = props

  const formModel = React.useMemo(
    () => new GroupConditionsFormModel(group),
    [group],
  )

  const {t} = useResourceTranslation('groups')

  //------
  // Rendering

  function render() {
    return (
      <ConditionsForm
        open={open}
        requestClose={requestClose}
        model={formModel}
        instructions={t('conditions.instructions')}
      />
    )
  }

  return render()

})

export default GroupConditionsForm

class GroupConditionsFormModel extends ConditionsFormModel {

  constructor(
    private readonly group: Group & {type: 'segment'},
  ) {
    super(group.conditions ?? [])
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Group, this.group.id, {
      conditions: this.conditions,
    })
  }

}