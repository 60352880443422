import React from 'react'
import { BrandedComponentSpec } from '~/models'
import { observer } from '~/ui/component'
import { BrandedComponent } from '~/ui/components'
import OAuthButtonBranding from '~/ui/styling/branding/web/OAuthButtonBranding'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const OAuthButtonPreview = observer('OAuthButtonPreview', (props: Props) => {

  const {componentName, variant, spec} = props
  const {guide} = useBranding()

  const [component, flags, size] = useComponentBranding<OAuthButtonBranding>(componentName, variant, spec)

  const height = component.resolve('height')

  //------
  // Rendering

  function render() {
    if (component == null) { return null }

    return (
      <BrandedComponent
        branding={component}
        variant={flags}
        height={height}
        style={{width: size.width, height}}
        guide={guide ?? undefined}
      />
    )
  }

  return render()

})

registerComponentBrandingPreview('web.login.oAuthButton', OAuthButtonPreview)
registerComponentBrandingPreview('app.login.oAuthButton', OAuthButtonPreview)

export default OAuthButtonPreview