import React from 'react'
import { Script, ScriptEndTrigger } from '~/models'
import { observer } from '~/ui/component'
import { Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: ScriptEndTrigger
}

const ScriptTriggerNodeView = observer('ScriptTriggerNodeView', (props: Props) => {
  const [script, {fetchStatus}] = useModelDocumentData(Script, props.trigger.script, {
    detail: false,
  })

  const detail = script == null
    ? (fetchStatus === 'fetching' ? <Spinner size={8}/> : null)
    : `[${script.name}]`

  return (
    <TriggerNodeView
      {...props}
      detail={detail}
      icon='login'
    />
  )
})

register('script:*', ScriptTriggerNodeView)