import React from 'react'
import { memo } from '~/ui/component'
import { HBox, Label, PopupMenu, PopupMenuItem, SVG, Tappable } from '~/ui/components'
import { SVGName } from '~/ui/components/SVG'
import {
  animation,
  createUseStyles,
  layout,
  presets,
  shadows,
  ThemeProvider,
  ThemeProviderProps,
} from '~/ui/styling'
import { isReactText } from '~/ui/util'

export interface Props<T = any> {
  icon?:          SVGName | null
  caption?:       React.ReactNode
  filled?:        boolean

  menuItems?:     PopupMenuItem<T>[]
  menuHeader?:    React.ReactNode
  onValueSelect?: (value: T) => any

  onTap?:         () => any
  onDoubleClick?: (event: React.MouseEvent) => any
}

const DetailIndicator = memo('DetailIndicator', (props: Props) => {

  const {
    icon,
    caption,
    filled    = false,

    menuItems = [],
    menuHeader,
    onValueSelect,

    onTap,
    onDoubleClick,
  } = props

  const hasCaption = caption != null
  const themeProps: ThemeProviderProps = filled ? {
    contrast: 'primary',
  } : {
    primary: true,
  }

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (menuItems.length > 0) {
      return renderAsMenu()
    } else if (onTap != null || onDoubleClick != null) {
      return renderAsButton()
    } else {
      return renderIndicator(false)
    }
  }

  function renderAsMenu() {
    return (
      <PopupMenu items={menuItems} onValueSelect={onValueSelect} header={menuHeader} crossAlign='center'>
        {toggle => (
          <Tappable onTap={toggle} onDoubleClick={onDoubleClick}>
            {renderIndicator(true)}
          </Tappable>
        )}
      </PopupMenu>
    )
  }


  function renderAsButton() {
    return (
      <Tappable onTap={onTap} onDoubleClick={onDoubleClick}>
        {renderIndicator(true)}
      </Tappable>
    )
  }

  function renderIndicator(interactive: boolean) {
    return (
      <ThemeProvider {...themeProps}>
        <HBox classNames={[$.detailIndicator, {interactive, filled, hasCaption}]} gap={layout.padding.inline.m}>
          {icon != null && (
            <SVG
              name={icon}
              size={layout.icon.m}
            />
          )}
          {isReactText(caption) ? (
            <Label flex caption>
              {caption}
            </Label>
          ) : (
            caption
          )}
          {interactive && hasCaption && (
            <SVG name='chevron-down' size={layout.icon.s}/>
          )}
        </HBox>
      </ThemeProvider>
    )
  }

  return render()

})

export default DetailIndicator

const useStyles = createUseStyles(theme => ({
  detailIndicator: {
    height:       layout.barHeight.s,
    borderRadius: layout.barHeight.s / 2,

    '&:not(.filled)': {
      background: theme.bg.alt,
    },
    '&.filled': {
      background: theme.semantic.primary,
    },

    '&:not(.hasCaption)': {
      padding: layout.padding.inline.s,
      width:   layout.barHeight.s,
      justifyContent: 'center',
    },
    '&.hasCaption': {
      padding: [layout.padding.inline.s, layout.padding.inline.l],
    },

    willChange: 'box-shadow',
    transition: animation.transitions.short('box-shadow'),

    '&.interactive:hover': {
      boxShadow: shadows.depth(1),
    },
    '&.interactive:active': {
      ...presets.overlayAfter({
        background: theme.colors.bg.dark.active,
      }),
    },
    '&:not(.interactive)': {
      cursor: 'default',
    },
  },
}))