import React from 'react'
import { getClientPoint } from 'react-dnd'
import { assignRef, useContinuousRef } from '~/ui/hooks'

export function useCalendarPan(spec: CalendarPanSpec): [CalendarPanConnect, CalendarPanConnect] {
  const containerRef = React.useRef<HTMLElement | null>()
  const panLayerRef  = React.useRef<HTMLElement | null>()

  const {origin, enabled} = spec

  // Use refs internally for the handlers.
  const originRef = useContinuousRef(origin)

  // Use a ref to keep track of onPan - it's only used after dragging.
  const onPan = React.useRef(spec.onPan)
  onPan.current = spec.onPan

  //------
  // Dragging

  const startMouseYRef = React.useRef<number>(0)
  const startOffsetRef = React.useRef<number>(0)

  const onMouseMove = React.useCallback((event: MouseEvent | TouchEvent) => {
    const clientPoint = getClientPoint(event)
    if (clientPoint == null) { return }

    const delta = clientPoint.y - startMouseYRef.current
    const current = startOffsetRef.current + delta
    onPan.current?.(current)
  }, [startOffsetRef])

  const onMouseUp = React.useCallback(() => {
    document.removeEventListener('mousemove', onMouseMove)
    document.removeEventListener('touchmove', onMouseMove)
    document.removeEventListener('mouseup', onMouseUp)
    document.removeEventListener('touchend', onMouseUp)
  }, [onMouseMove])

  const onMouseDown = React.useCallback((event: MouseEvent) => {
    event.preventDefault()

    startMouseYRef.current = event.clientY
    startOffsetRef.current = originRef.current

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('touchmove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)
    document.addEventListener('touchend', onMouseUp)
  }, [onMouseMove, onMouseUp, originRef])

  React.useEffect(() => {
    if (enabled) {
      panLayerRef.current?.addEventListener('mousedown', onMouseDown, {capture: true})
    } else {
      panLayerRef.current?.removeEventListener('mousedown', onMouseDown, {capture: true})
    }
  }, [enabled, onMouseDown])

  //------
  // Mouse wheel

  const onWheel = React.useCallback((event: WheelEvent) => {
    if (event.metaKey) { return }
    if (Math.abs(event.deltaY) < 2) { return }

    onPan.current?.(originRef.current - event.deltaY / 2)
    event.preventDefault()
  }, [originRef])

  //------
  // Connect

  const connectContainer = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (container: HTMLElement | null) => {
      assignRef(ref, container)
      if (container === containerRef.current) { return }

      if (containerRef.current) {
        containerRef.current.removeEventListener('wheel', onWheel)
      }
      if (container) {
        container.addEventListener('wheel', onWheel)
      }

      containerRef.current = container
    }
  }, [onWheel])

  const connectPanLayer = React.useCallback((ref: React.Ref<HTMLElement>) => {
    return (panLayer: HTMLElement | null) => {
      assignRef(ref, panLayer)
      if (panLayer === panLayerRef.current) { return }

      panLayerRef.current = panLayer
    }
  }, [])

  return [connectContainer, connectPanLayer]
}

export interface CalendarPanSpec {
  enabled: boolean
  origin:  number
  onPan:   (origin: number) => any
}

export type CalendarPanConnect = (ref: React.Ref<HTMLElement>) => (element: HTMLElement | null) => void