import React from 'react'
import { Group, Participant } from '~/models'
import { memo } from '~/ui/component'
import { ModalDialog, Panel, Spinner, TextBlock, VBox } from '~/ui/components'
import { FormDialog, FormField } from '~/ui/form'
import { useTags } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import GroupsFormModel from './GroupsFormModel'

export interface Props {
  open:          boolean
  requestClose?: () => any

  model: Participant
}

const GroupsForm = memo('GroupsForm', (props: Props) => {

  const {open, requestClose, model} = props

  const tags = useTags(Group)

  const {t, fieldPlaceholder} = useResourceTranslation()

  const formModel = React.useMemo(
    () => tags == null ? null : new GroupsFormModel(model, tags),
    [model, tags],
  )

  //------
  // Rendering

  function render() {
    if (formModel == null) {
      return (
        <ModalDialog open={open} requestClose={requestClose} title={t('groups_form.title')}>
          <Spinner/>
        </ModalDialog>
      )
    } else {
      return (
        <FormDialog
          open={open}
          requestClose={requestClose}
          model={formModel}
          icon='group'
          title={t('groups_form.title')}
          children={renderFields()}
          autoFocus={false}
          semi={false}
        />
      )
    }
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        <TextBlock small dim>
          {t('groups_form.instructions')}
        </TextBlock>

        <FormField name='otherGroups'>
          {bind => <ResourceField Model={Group} multi filters={{tag: null}} {...bind}/>}
        </FormField>

        {tags != null && tags.length > 0 && (
          <Panel semi padding={layout.padding.m} gap={layout.padding.s}>
            {tags?.map(renderTagGroupField)}
          </Panel>
        )}
      </VBox>
    )
  }

  function renderTagGroupField(tag: string) {
    const groups = formModel?.byTag[tag]
    if (groups == null) { return null }

    return (
      <FormField key={tag} name={`tag:${tag}`} caption={tag}>
        {bind => (
          <ResourceField
            Model={Group}
            multi
            filters={{tag}}
            {...bind}
            placeholder={fieldPlaceholder('tagGroups')!}
          />
        )}
      </FormField>
    )
  }

  return render()

})

export default GroupsForm