import { humanFileSize } from 'ytil'
import { SVGName } from '~/ui/components/SVG'
import { Model, resource } from './Model'

@resource<Media>('media', {
  icon:    media => media == null ? 'image' : iconForMedia(media),
  caption: media => media.name,
  details: media => [media.contentType, humanFileSize(media.fileSize)],

  copyAction: 'copy',
})
export class Media extends Model {

  public name!:        string
  public url!:         string
  public contentType!: string
  public fileSize!:    number

}

export function iconForMedia(media: Media): SVGName {
  if (/^image\//.test(media.contentType)) {
    return 'image'
  } else if (/^video\//.test(media.contentType)) {
    return 'video'
  } else {
    return 'page'
  }
}