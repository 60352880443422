import React from 'react'
import { useOnDemandService } from 'socket.io-react'
import { AnswersQuery } from '~/models'
import { answersStore } from '~/stores'
import { observer } from '~/ui/component'
import { EmptyOrFetching } from '~/ui/components'
import { AnswerScopeLinkage } from '../../explore/AnswerScopeField'
import AnswersQueryResults from './AnswersQueryResults'

export interface Props {
  query: AnswersQuery
}

const AnswersQueryContainer = observer('AnswersQueryContainer', (props: Props) => {

  const {question, segments, participant} = props.query

  const service = React.useMemo(() => {
    const scope = {
      type: question.model.model === 'Script' ? 'scripts' : 'challenges',
      id:   question.model.id,
    } as AnswerScopeLinkage

    return answersStore.getAnswersService(scope, {
      participantID: participant ?? undefined,
      segments:      ['all', ...segments ?? []],
      questionUUIDs: [question.uuid],
    })
  }, [question, participant, segments])

  useOnDemandService(service)

  const state = service.started ? service.states[0] ?? null : null

  //------
  // Rendering

  function render() {
    return state == null ? renderEmpty() : renderBody()
  }

  function renderBody() {
    if (state == null) { return null }
    return (
      <AnswersQueryResults
        state={state}
        onRefresh={service.refreshQuestion}
      />
    )
  }

  function renderEmpty() {
    return(
      <EmptyOrFetching
        status={service.started ? 'done' : 'fetching'}
      />
    )
  }

  return render()

})

export default AnswersQueryContainer