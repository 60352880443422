import { isArray } from 'lodash'

export function wrap(start: string, end: string) {
  const wrap = (value: any) => `${start}${value}${end}`

  return (value: any) => {
    if (isArray(value)) {
      return value.map(wrap)
    } else {
      return wrap(value)
    }
  }
}