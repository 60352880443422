import React from 'react'
import { memo } from '~/ui/component'
import { Center, ClearButton, PopupMenu, PopupMenuProps, SVG, Tooltip } from '~/ui/components'
import { height as clearButtonHeight } from '~/ui/components/ClearButton'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, useStyling, useTheme } from '~/ui/styling'

export interface DetailIndicatorProps<T = any> extends PopupMenuProps<T> {
  icon:           SVGName
  interactive?:   boolean
  active?:        boolean
  tooltip?:       string | null
  onDoubleClick?: () => any
}

const DetailIndicator = memo('DetailIndicator', (props: DetailIndicatorProps) => {
  const {icon, interactive = true, active = false, tooltip, onDoubleClick, ...rest} = props

  const theme    = useTheme()
  const {colors} = useStyling()

  const backgroundColor = active ? theme.semantic.primary : theme.bg.alt
  const color           = colors.contrast(backgroundColor)

  const $ = useStyles()

  if (!active && !interactive) { return null }

  if (!interactive) {
    return (
      <Tooltip renderTooltip={tooltip}>
        <Center classNames={$.detailIndicator} style={{backgroundColor: backgroundColor.string()}}>
          <SVG
            name={icon}
            color={color}
            size={layout.icon.xs}
          />
        </Center>
      </Tooltip>
    )
  } else {
    return (
      <PopupMenu {...rest}>
        {toggle => (
          <ClearButton
            icon={icon}
            onTap={toggle}
            backgroundColor={backgroundColor}
            onDoubleClick={onDoubleClick}
            padding='both'
            round
            small
          />
        )}
      </PopupMenu>
    )
  }

})

export const indicatorSize = 24
export const indicatorButtonSize = clearButtonHeight.small

const useStyles = createUseStyles({
  detailIndicator: {
    width:        indicatorSize,
    height:       indicatorSize,
    borderRadius: indicatorSize / 2,
  },
})

export default DetailIndicator