import React from 'react'
import { CustomImage } from '~/models'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, SVG } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'

export interface Props {
  logo:  CustomImage | null
  size?: Size
}

const ProjectLogo = memo('ProjectLogo', (props: Props) => {
  const {
    logo,
    size = defaultLogoSize,
  } = props

  const $     = useStyles()
  const theme = useTheme()

  if (logo == null) {
    return (
      <Center classNames={[$.ProjectLogo, 'empty']} style={{...size}}>
        <SVG
          name='logo'
          color={theme.semantic.primary.alpha(0.6)}
          size={{width: size.width * 0.8, height: size.height * 0.8}}
        />
      </Center>
    )
  } else {
    return (
      <ImageView
        source={logo}
        size={size}
        classNames={$.ProjectLogo}
        objectFit='contain'
      />
    )
  }
})

export default ProjectLogo

export const defaultLogoSize = {
  width:  32,
  height: 32,
}

const useStyles = createUseStyles(theme => ({
  ProjectLogo: {
    borderRadius: layout.radius.s,
    background:   theme.bg.alt,

    '&.empty': {
      borderRadius: layout.radius.s,
    },
  },
}))