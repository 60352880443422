import { isFunction } from 'lodash'
import { IReactionDisposer, reaction } from 'mobx'
import { register } from './support'

const storage: Storage | null = 'localStorage' in global ? (global as any).localStorage : null

export class ViewStateStore {

  public get<T>(key: string): T | undefined
  public get<T>(key: string, initialValue: T): T
  public get<T>(key: string, initialValue?: T): T | undefined {
    const serialized = storage?.getItem(key)
    if (serialized == null) { return initialValue }

    try {
      return JSON.parse(serialized) as T
    } catch {
      return initialValue
    }
  }

  public set<T>(key: string, value: T) {
    storage?.setItem(key, JSON.stringify(value))
  }

  public bind<T>(key: string, set: (value: T) => any, get: () => T): IReactionDisposer
  public bind(key: string, obj: any, propertyKey: string): IReactionDisposer
  public bind<T>(key: string, arg1: any, arg2: any) {
    let setFn: (value: T) => any
    let getFn: () => T

    if (isFunction(arg1) && isFunction(arg2)) {
      setFn = arg1
      getFn = arg2
    } else {
      setFn = value => arg1[arg2] = value
      getFn = () => arg1[arg2]
    }

    const value = this.get<T>(key)
    if (value !== undefined) {
      setFn(value)
    }

    return reaction(getFn, value => this.set(key, value))
  }

}

export default register(new ViewStateStore())