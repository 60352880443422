import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { observer } from '~/ui/component'
import { Center, Checkbox, Tappable } from '~/ui/components'
import { size as checkboxSize } from '~/ui/components/Checkbox'
import { layout } from '~/ui/styling'

export interface Props {
  id:       string | null
  enabled?: boolean
}

const CheckboxCell = observer('CheckboxCell', (props: Props) => {

  const {id, enabled = true} = props
  const manager = useSelectionManager()

  React.useEffect(() => {
    if (manager == null || id == null) { return }
    if (!enabled) { return }

    return manager.register(id)
  }, [enabled, manager, id])

  const selected = id == null
    ? manager?.allAvailableSelected ?? false
    : manager?.isSelected(id) ?? false

  const toggle = React.useCallback(() => {
    if (id == null) {
      if (manager?.allAvailableSelected) {
        manager?.selectNone()
      } else {
        manager?.selectAllAvailable()
      }
    } else {
      manager?.toggle(id)
    }
  }, [manager, id])

  //------
  // Rendering

  function render() {
    return (
      <Tappable flex enabled={enabled} onTap={toggle} noFeedback>
        <Center flex padding={padding}>
          <Checkbox
            checked={selected}
            onChange={toggle}
            enabled={enabled}
          />
        </Center>
      </Tappable>
    )
  }

  return render()

})

export default CheckboxCell

export const padding           = layout.padding.inline.l
export const checkboxCellWidth = checkboxSize.normal.width + 2 * padding