import React from 'react'
import { useTranslation } from 'react-i18next'
import { useOnDemandService } from 'socket.io-react'
import { Channel } from '~/models'
import { authenticationStore, chatStore, operatorStore } from '~/stores'
import Chat from '~/ui/app/chat/Chat'
import { observer } from '~/ui/component'
import { Empty, VBox } from '~/ui/components'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  participantID: string
}

const OperatorChat = observer('OperatorChat', (props: Props) => {

  const {participantID} = props

  const [t] = useTranslation('operator')

  //------
  // Services

  const operator  = operatorStore.service
  const user      = authenticationStore.user
  const channelID = operator?.getParticipantChannel(participantID) ?? null

  const switchChannel = React.useCallback((channelID: string | null) => {
    operator?.switchParticipantToChannel(participantID, channelID)
  }, [operator, participantID])

  const chat = React.useMemo(() => {
    if (user == null) { return null }
    return chatStore.chatService(participantID, user, {
      initialChannelID: channelID,
    })
  }, [channelID, participantID, user])

  useOnDemandService(chat)

  //------
  // Rendering

  function render() {
    return (
      <VBox flex>
        {renderPreamble()}
        {renderChat()}
      </VBox>
    )
  }

  function renderPreamble() {
    return (
      <VBox padding={layout.padding.s}>
        <ResourceField
          Model={Channel}
          value={channelID}
          onChange={switchChannel}
          placeholder={t('channel_placeholder')}
        />
      </VBox>
    )
  }

  function renderChat() {
    if (chat == null) { return null }

    if (channelID == null) {
      return (
        <Empty
          flex
          icon='headset'
          {...t('choose_channel')}
        />
      )
    } else {
      return (
        <Chat
          controller={chat}
          header={false}
          forceOpen={true}
        />
      )
    }
  }

  return render()

})

export default OperatorChat