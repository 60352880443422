import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import Toast from 'react-toast'
import { observer } from 'mobx-react'
import { StringParam, useQueryParam } from 'use-query-params'
import { appStore, authenticationStore, AuthResetRequired, InvalidToken } from '~/stores'
import { Center, Spinner } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import BeWizrLoginButton from './BeWizrLoginButton'
import LoginForm from './LoginForm'
import LoginFormModel from './LoginFormModel'

const LoginScreen = observer(() => {

  const history = useHistory()
  const [t]     = useTranslation('auth')

  const appReady      = appStore.ready
  const {loginStatus} = authenticationStore

  const forgotPin = React.useCallback((email: string) => {
    history.push(`/auth/forgot-password?email=${encodeURIComponent(email)}`)
  }, [history])

  //------
  // Form

  const formModel = React.useMemo(
    () => new LoginFormModel(),
    [],
  )

  const toastInvalidToken = React.useCallback(() => {
    Toast.show({
      type:   'error',
      title:  t('errors.invalid-token.title'),
      detail: t('errors.invalid-token.detail'),
    })
  }, [t])

  const redirectToAuthReset = React.useCallback((token: string) => {
    const params = new URLSearchParams({token})
    history.replace(`/auth/reset?${params}`)
  }, [history])

  const afterSubmit = React.useCallback((result: SubmitResult) => {
    if (result.status === 'error' && result.error instanceof InvalidToken) {
      toastInvalidToken()
    }
    if (result.status === 'error' && result.error instanceof AuthResetRequired) {
      redirectToAuthReset(result.error.authResetToken)
    }
  }, [redirectToAuthReset, toastInvalidToken])

  //------
  // Magic login

  const [authToken_query, setAuthToken_query] = useQueryParam('auth', StringParam)

  React.useEffect(() => {
    if (!appReady) { return }
    if (loginStatus === 'logging-in') { return }

    if (authToken_query != null) {
      authenticationStore.logIn({token: authToken_query}).then(result => {
        setAuthToken_query(null)
        afterSubmit(result)
      })
    }
  }, [afterSubmit, appReady, authToken_query, history, loginStatus, setAuthToken_query, toastInvalidToken])

  //------
  // Rendering

  function render() {
    if (authToken_query != null) {
      return (
        <Center flex>
          <Spinner/>
        </Center>
      )
    }

    return (
      <LoginForm
        model={formModel}
        afterSubmit={afterSubmit}
        requestForgotPassword={forgotPin}
        otherLoginOptions={renderBeWizrLogin()}
      />
    )
  }

  function renderBeWizrLogin() {
    return (
      <BeWizrLoginButton/>
    )
  }

  return render()

})

export default LoginScreen