import I18next from 'i18next'

export enum TimeIntervalUnit {
  WEEKS        = 'w',
  DAYS         = 'd',
  HOURS        = 'h',
  MINUTES      = 'm',
  SECONDS      = 's',
  MILLISECONDS = 'ms',
}

export class TimeInterval {

  constructor(
    public readonly quantity: number,
    public readonly unit: TimeIntervalUnit,
  ) {}

  public static parse(text: string) {
    const interval = this.tryParse(text)
    if (interval == null) {
      throw new InvalidTimeInterval(`"${text}" is not a valid time interval`)
    }
    return interval
  }

  public static tryParse(text: string) {
    const match = text.trim().match(/^(\d+)(ms|s|m|h|d|w)$/)
    if (match == null) { return null }

    const quantity = parseInt(match[1], 10)
    const unit     = match[2] as TimeIntervalUnit
    if (unit == null) { return null }

    return new TimeInterval(quantity, unit)
  }

  public static zero = new TimeInterval(0, TimeIntervalUnit.MILLISECONDS)

  public static isTimeInterval(arg: any): arg is TimeInterval {
    return arg instanceof TimeInterval
  }

  //------
  // Properties

  public get multiplier() {
    switch (this.unit) {
      default:
      case TimeIntervalUnit.MILLISECONDS: return 1
      case TimeIntervalUnit.SECONDS:      return 1000
      case TimeIntervalUnit.MINUTES:      return 1000 * 60
      case TimeIntervalUnit.HOURS:        return 1000 * 60 * 60
      case TimeIntervalUnit.DAYS:         return 1000 * 60 * 60 * 24
      case TimeIntervalUnit.WEEKS:        return 1000 * 60 * 60 * 24 * 7
    }
  }

  public get isZero() {
    return this.quantity === 0
  }

  //------
  // Util

  public static isValidUnit(unit: string): unit is TimeIntervalUnit {
    return Array.from(Object.values(TimeIntervalUnit)).includes(unit as any)
  }

  //------
  // Output

  public toString() {
    return `${this.quantity}${this.unit}`
  }

  public toLongString() {
    return `${this.quantity} ${I18next.t(`time_interval:units.${this.unit}`, {count: this.quantity})}`
  }

  public [Symbol.toPrimitive]() {
    return this.toString()
  }

  public valueOf() {
    return this.toString()
  }

}

export class InvalidTimeInterval extends Error {}