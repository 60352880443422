import React from 'react'
import I18next from 'i18next'
import { Project, RegistrationCode, RegistrationCodeStatus, RegistrationPreset } from '~/models'
import { Chip, CopyToClipboard, HBox, Label, SVG, Tooltip, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { cell, RelatedCell } from '~/ui/resources/collection'
import { layout, useTheme } from '~/ui/styling'
import { Theme } from '~/ui/styling/Theme'
import RegistrationCodeStatusSummary from '../detail/RegistrationCodeStatusSummary'

export const CodeCell = cell('CodeCell', (props: {item: RegistrationCode}) => {

  const {item} = props
  const theme = useTheme()

  return (
    <HBox gap={layout.padding.s}>
      <SVG
        name={item.$icon}
        size={layout.icon.m}
        color={theme.semantic.primary}
      />
      <Label flex='shrink' bold mono box>
        {item.code}
      </Label>
      <CopyToClipboard
        value={item.code}
        showValue={false}
        button={{small: true, padding: 'vertical'}}
      />
    </HBox>
  )

})

export const StatusCell = cell('StatusCell', (props: {item: RegistrationCode}) => {

  const {item} = props
  const {t} = useResourceTranslation('registration-codes')
  const theme = useTheme()

  function render() {
    if (item.status === RegistrationCodeStatus.registered) {
      return renderTooltip()
    }
    return renderStatus()
  }

  function renderTooltip() {
    return (
      <Tooltip
        renderTooltip={renderSummary()}
        children={renderStatus()}
      />
    )
  }

  function renderStatus() {
    return(
      <Chip flex='shrink' backgroundColor={colorForStatus(item.status, theme)}>
        {t(`status.${item.status}`)}
      </Chip>
    )
  }

  function renderSummary() {
    return (
      <VBox width={180} align='center' justify='middle'>
        <RegistrationCodeStatusSummary item={item}/>
      </VBox>
    )
  }

  return render()

})

export const PresetCell = RelatedCell
  .of(RegistrationPreset)
  .for((code: RegistrationCode) => code.preset)

export const ProjectCell = RelatedCell
  .of(Project, {renderEmpty: () => I18next.t('registration-codes:fields.project.none')})
  .for((code: RegistrationCode) => code.project)

export function colorForStatus(status: RegistrationCodeStatus, theme: Theme) {
  switch (status) {
    case RegistrationCodeStatus.pending:
      return theme.fg.dimmer
    case RegistrationCodeStatus.registered:
      return theme.semantic.positive
    case RegistrationCodeStatus.revoked:
      return theme.semantic.negative
    case RegistrationCodeStatus.expired:
      return theme.semantic.negative
    }
}