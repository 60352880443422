import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable } from 'mobx'
import { OnDemandService, Socket, StartSuccess } from 'socket.io-react'
import { Dashboard } from '~/models'
import { CustomListPack, Pack } from '../data/types'
import dataStore from '../dataStore'
import { AnalyticsMeta, WidgetState } from './types'
import WidgetDataEndpoint from './WidgetDataEndpoint'
import WidgetFilterValueSet from './WidgetFilterValueSet'

export default class AnalyticsService extends OnDemandService {

  constructor(
    socket: Socket,
    public readonly dashboardID: string,
  ) {
    super(socket)
    makeObservable(this)
  }

  @computed
  public get dashboard() {
    if (this.dashboardID == null) { return null }
    return dataStore.get(Dashboard, this.dashboardID)
  }

  @observable
  public states: WidgetState[] = []

  @observable
  public meta: AnalyticsMeta | null = null

  public async start() {
    await super.startWithEvent('analytics:start', {
      dashboardID: this.dashboardID,
    })
  }

  public async resetDashboard() {
    if (this.dashboardID == null) { return }
    return await dataStore.update(Dashboard, this.dashboardID, {
      widgets: [],
    })
  }

  protected onStarted = (response: StartSuccess<CustomListPack<WidgetState>>) => {
    dataStore.storePack(response.data)

    this.states = deserializeData(response.data.data ?? [])
    this.meta   = response.data.meta ?? {}

    this.deriveFilterValues()

    this.socket.prefix = `analytics:${this.uid}:`
    this.socket.addEventListener('update', this.handleUpdate)
    this.socket.addEventListener('dashboard', this.handleDashboardUpdate)
  }

  public onStop() {
    this.states = []
    this.meta   = null
  }

  public async updateDashboard(updater: (dashboard: Dashboard) => AnyObject) {
    if (this.dashboard == null) { return }

    const data = updater(this.dashboard)
    return await dataStore.update(Dashboard, this.dashboard.id, data)
  }

  private handleUpdate = action((update: CustomListPack<WidgetState>) => {
    const updates = deserializeData(update.data ?? [])

    const nextStates = [...this.states]
    for (const update of updates) {
      const index = nextStates.findIndex(it => it.alotmentUUID === update.alotmentUUID)
      if (index >= 0) {
        nextStates.splice(index, 1, update)
      } else {
        nextStates.push(update)
      }
    }

    this.states = nextStates
    this.meta   = update.meta ?? {}

    this.deriveFilterValues()
  })

  private handleDashboardUpdate = (pack: Pack<Dashboard>) => {
    dataStore.storePack(pack)
  }

  public async refreshWidget(alotmentUUID: string): Promise<boolean> {
    const response = await this.socket.send('widget:refresh', alotmentUUID)
    return response.ok ? response.body : false
  }

  public widgetDataEndpoint(initialState: WidgetState) {
    return new WidgetDataEndpoint(this, initialState)
  }

  public async fetchWidgetData(alotmentUUID: string, filters: Record<string, any> = {}) {
    return await this.socket.send('widget:data', alotmentUUID, filters)
  }

  //------
  // Filter values

  @observable
  private filterValues = new Map<string, WidgetFilterValueSet>()

  public getFilterValues(alotmentUUID: string) {
    return this.filterValues.get(alotmentUUID) ?? null
  }

  @action
  private deriveFilterValues() {
    for (const state of this.states) {
      const valueSet = new WidgetFilterValueSet(
        state.alotmentUUID,
        state.filters,
      )

      valueSet.sendSetValue = this.sendSetFilterValue
      valueSet.sendRemove   = this.sendRemoveFilter

      this.filterValues.set(state.alotmentUUID, valueSet)
    }
  }

  private sendSetFilterValue = (alotmentUUID: string, name: string, value: any) => {
    return this.socket
      .send('set-filter', alotmentUUID, name, value)
      .then(response => response.ok)
  }

  private sendRemoveFilter = (alotmentUUID: string, name: string) => {
    return this.socket
      .send('remove-filter', alotmentUUID, name)
      .then(response => response.ok)
  }

}

function deserializeData(data: any[]): WidgetState[] {
  return data.map(it => ({
    ...it,
    lastUpdate: DateTime.fromISO(it.lastUpdate),
  }))
}