import { pick } from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { slugify } from 'ytil'
import { ClientApp, CustomImage, Language, Organisation, Project, Ref } from '~/models'
import { dataStore } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'

export default class CreateProjectFormModel implements FormModel {

  constructor(organisation: string | null = null) {
    this.organisation = organisation

    makeObservable(this)
    reaction(() => this.organisation, action(() => {
      if (this.logo != null) { return }

      const organisation = this.organisation == null ? null : dataStore.get(Organisation, this.organisation)
      this.logo = organisation?.logo ?? null
    }))
    reaction(() => [this.name, this.autoGenerateCode], action(() => {
      if (!this.autoGenerateCode) { return }
      this.code = slugify(this.name)
    }))
  }

  @observable
  public organisation: Ref<Organisation> | null

  @observable
  public newOrganisationName: string = ''

  @observable
  public defaultApp: Ref<ClientApp> | null = 'app.groundcontrol.phone'

  @observable
  public creatingNewOrganisation: boolean = false

  @computed
  public get hasOrganisation() {
    return this.organisation != null || this.creatingNewOrganisation
  }

  @action
  public createNewOrganisation(name: string) {
    this.creatingNewOrganisation = true
    this.newOrganisationName = name
  }

  @observable
  public name: string = ''

  @observable
  public code: string = ''

  @observable
  public autoGenerateCode: boolean = true

  @action
  public toggleAutoGenerateCode() {
    this.autoGenerateCode = !this.autoGenerateCode
  }

  @observable
  public logo: CustomImage | null = null

  @observable
  public timeZone: string = DateTime.local().zoneName

  @observable
  public startDate: DateTime | null = null

  @observable
  public languages: Language[] = ['nl']

  public async submit(): Promise<SubmitResult | undefined> {
    if (this.creatingNewOrganisation) {
      const result = await this.createOrganisation()
      if (result.status !== 'ok') { return result}
    }

    const result = await dataStore.create(Project, this.buildProjectData())
    if (result.status !== 'ok') { return result }

    return result
  }

  @action
  private async createOrganisation() {
    const result = await dataStore.create(Organisation, {
      name:       this.newOrganisationName,
      defaultApp: this.defaultApp,
    })
    if (result.status !== 'ok') { return result }

    runInAction(() => {
      const organisation = result.data?.id == null ? null : dataStore.get(Organisation, result.data.id)
      if (organisation != null) {
        this.organisation = organisation.id
      }
    })

    return result
  }

  private buildProjectData(): AnyObject {
    this.startDate = DateTime.local().setZone(this.timeZone)
    return pick(this, 'organisation', 'name', 'code', 'logo', 'timeZone', 'startDate', 'languages')
  }

  @action
  public reset() {
    this.organisation = null
    this.name = ''
    this.code = ''
    this.autoGenerateCode = true
    this.logo = null
    this.timeZone = DateTime.local().zoneName
    this.startDate = null
  }

}