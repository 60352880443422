import { Feedback } from '~/models'

type ResultStyle = DataTableResultStyle | BarChartResultStyle | DonutChartResultStyle | GalleryResultStyle

interface ResultStyleStatic {
  allTypes: Array<ResultStyle['type']>
  availableForFeedback: (feedback: Feedback) => Array<ResultStyle['type']>

  default: (type: ResultStyle['type']) => ResultStyle
  defaultForFeedback: (feedback: Feedback) => ResultStyle
}

const ResultStyle: ResultStyleStatic = {
  allTypes: ['data-table', 'bar-chart', 'donut-chart', 'gallery'],

  availableForFeedback: feedback => {
    if (feedback.type === 'media') {
      return ['gallery']
    } else if (feedback.type === 'text') {
      return ['data-table']
    } else {
      return ['data-table', 'bar-chart', 'donut-chart']
    }
  },

  default: type => ({type}),

  defaultForFeedback: feedback => {
    switch (feedback.type) {
      case 'text': return {type: 'data-table'}
      case 'media': return {type: 'gallery'}
      default: return {type: 'bar-chart'}
    }
  },
}

export { ResultStyle}

export interface DataTableResultStyle {
  type: 'data-table'
}

export interface BarChartResultStyle {
  type: 'bar-chart'
}

export interface DonutChartResultStyle {
  type: 'donut-chart'
}

export interface GalleryResultStyle {
  type: 'gallery'
}