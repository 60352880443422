import React from 'react'
import { memo } from '~/ui/component'
import { useDashboardContext } from './DashboardContext'
import DashboardLayout from './DashboardLayout'

const DashboardLayoutContext = React.createContext<DashboardLayout>(
  new DashboardLayout(),
)

export interface DashboardLayoutContainerProps {
  children?: React.ReactNode
}

export const DashboardLayoutContainer = memo('DashboardLayoutContainer', (props: DashboardLayoutContainerProps) => {

  const {currentWidgets} = useDashboardContext()
  const layout = React.useMemo(
    () => new DashboardLayout(),
    [],
  )

  React.useEffect(() => {
    layout.widgets = currentWidgets
  }, [layout, currentWidgets])

  return (
    <DashboardLayoutContext.Provider value={layout}>
      {props.children}
    </DashboardLayoutContext.Provider>
  )
})

export function useDashboardLayout() {
  return React.useContext(DashboardLayoutContext)
}