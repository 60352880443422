import React from 'react'
import { useTranslation } from 'react-i18next'
import { upperFirst } from 'lodash'
import { Challenge, Model, Module, Script, Triggerable } from '~/models'
import { projectStore, triggerableBandColor } from '~/stores'
import ProjectLabel from '~/ui/app/projects/ProjectLabel'
import { memo, observer } from '~/ui/component'
import {
  HBox,
  Label,
  ListSection,
  PushButton,
  SearchableList,
  Tappable,
  VBox,
} from '~/ui/components'
import { useFocusOnActivate } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { TriggerableBar } from '../../triggerables'
import { CreateTriggerableComponentType } from './types'

export interface Props {
  triggerableType: TriggerableType
  owningModule:    Module
  requestCreate:   (type: CreateTriggerableComponentType) => any
  active:          boolean
}

const TriggerableComponentCatalog = observer('TriggerableComponentCatalog', (props: Props) => {

  const {triggerableType, owningModule, active, requestCreate} = props

  const Model    = ModelForTriggerableType(triggerableType)
  const endpoint = useModelEndpoint<Script | Challenge>(Model, {fetch: false, label: 'linked'})

  const searchFieldRef = useFocusOnActivate(active, {timeout: 0})

  const [t] = useTranslation('flow_planner')
  const resourceI18n = useResourceTranslation(Model.resourceType)

  //------
  // Fetching

  React.useEffect(() => {
    if (active) {
      endpoint.fetchIfNeeded()
    }
  }, [active, endpoint])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchMore()
  }, [endpoint])

  //------
  // List data

  const buildParameterComponentType = React.useCallback((parameter: string): CreateTriggerableComponentType => {
    return {
      type:      'triggerable',
      name:      triggerableType,
      parameter: parameter,
    }

  }, [triggerableType])

  const buildCreateNewType = React.useCallback((nameSuggestion: string | null): CreateTriggerableComponentType => {
    return {
      type: 'triggerable',
      name: triggerableType,
      data: {name: nameSuggestion},
    }
  }, [triggerableType])

  const buildOtherComponentType = React.useCallback((model: Script | Challenge): CreateTriggerableComponentType => {
    return {
      type:  'triggerable',
      name:  triggerableType,
      model: model,
    }
  }, [triggerableType])

  const listSections = React.useMemo(() => {
    const sections: ListSection<CreateTriggerableComponentType>[] = []

    const placeholderNames = (owningModule.parameters ?? [])
      .filter(it => it.type === triggerableType)
      .map(it => it.name)

      if (placeholderNames.length > 0) {
      sections.push({
        name: 'placeholders',
        items: placeholderNames.map(buildParameterComponentType),
      })
    }

    sections.push({
      name: 'other',
      items: endpoint.data.map(buildOtherComponentType),
    })

    return sections
  }, [buildOtherComponentType, buildParameterComponentType, endpoint.data, owningModule.parameters, triggerableType])

  //------
  // Callbacks

  const search = React.useCallback((search: string | null) => {
    endpoint.setParams({search})
  }, [endpoint])

  const select = React.useCallback((item: CreateTriggerableComponentType | null) => {
    if (item == null) { return }
    requestCreate(item)
  }, [requestCreate])

  const create = React.useCallback(() => {
    requestCreate(buildCreateNewType(endpoint.param('search') ?? null))
  }, [buildCreateNewType, endpoint, requestCreate])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ResourceTypeProvider resourceType={Model.resourceType}>
        <VBox flex classNames={$.triggerableComponentDialog}>
          <SearchableList<CreateTriggerableComponentType>
            data={listSections}
            fetchStatus={endpoint.fetchStatus}
            onSearch={search}
            searchDebounce={300}
            onKeyboardSelect={select}
            searchFieldRef={searchFieldRef}
            onEndReached={fetchMore}
            renderItem={renderItem}
            renderSectionHeader={renderSectionHeader}
            contentClassNames={$.content}
            itemGap={layout.padding.xs}
            flex
          />
          <CreateButton
            nameSuggestion={endpoint.param('search') ?? null}
            onCreate={create}
          />
        </VBox>
      </ResourceTypeProvider>
    )
  }

  const renderSectionHeader = React.useCallback((section: ListSection<any>, index: number) => {
    if (listSections.length < 2) { return null }

    return (
      <VBox classNames={[$.sectionHeader, {first: index === 0}]}>
        <Label tiny dimmer bold>
          {section.name === 'placeholders' ? (
            t('create_component.placeholders')
          ) : (
            upperFirst(resourceI18n.plural())
          )}
        </Label>
      </VBox>
    )
  }, [$.sectionHeader, listSections.length, resourceI18n, t])

  const renderItem = React.useCallback((item: CreateTriggerableComponentType, index: number, selected: boolean) => {
    return (
      <TriggerableCatalogItem
        item={item}
        onSelect={select}
        selected={selected}
      />
    )
  }, [select])

  return render()
})

export default TriggerableComponentCatalog

export interface TriggerableComponentCatalogItem {
  item:     CreateTriggerableComponentType
  onSelect: (item: CreateTriggerableComponentType) => any
  selected: boolean
}

const TriggerableCatalogItem = memo('TriggerableCatalogItem', (props: TriggerableComponentCatalogItem) => {

  const {item, onSelect, selected} = props
  const Model = ModelForTriggerableType(item.name)

  const tap = React.useCallback(() => {
    onSelect(item)
  }, [item, onSelect])

  const ownProjectID = projectStore.projectID
  const otherProject = React.useMemo(() => {
    if (item.model == null) { return false }
    if (!('project' in item.model)) { return false }
    return item.model.project !== ownProjectID
  }, [item.model, ownProjectID])

  const theme = useTheme()

  function render() {
    return (
      <VBox>
        <Tappable onTap={tap}>
          {item.model != null ? (
            renderTriggerable(item.model)
          ) : item.parameter != null ? (
            renderPlaceholder(item.parameter)
          ) : null}
        </Tappable>
      </VBox>
    )
  }

  function renderTriggerable(triggerable: Model) {
    return (
      <TriggerableBar
        title={triggerable.$caption}
        icon={triggerable.$icon}
        detail={otherProject && item.model != null ? (
          <ProjectLabel projectID={item.model.project} tiny dim/>
        ) : (
          null
        )}
        bandColor={triggerableBandColor(item.name, theme)}
        selected={selected}
      />
    )
  }

  function renderPlaceholder(parameter: string) {
    return (
      <TriggerableBar
        title={`$${parameter}`}
        icon={Model.$icon}
        bandColor={triggerableBandColor(item.name, theme)}
        selected={selected}
        placeholder
      />
    )
  }

  return render()
})

export interface CreateTriggerableButtonProps {
  nameSuggestion?: string | null
  onCreate:        () => any
}

export const CreateButton = memo('CreateButton', (props: CreateTriggerableButtonProps) => {

  const {nameSuggestion, onCreate} = props
  const {t} = useResourceTranslation()

  const caption = nameSuggestion != null
    ? t('new_named', {name: nameSuggestion})
    : t('new')

  const $ = useStyles()

  function render() {
    return (
      <HBox justify='center' classNames={$.buttonContainer} padding={layout.padding.s}>
        <PushButton
          icon='plus'
          onTap={onCreate}
          caption={caption}
        />
      </HBox>
    )
  }

  return render()
})

export type TriggerableType = Exclude<Triggerable['type'], 'action'>

function ModelForTriggerableType(type: TriggerableType): typeof Script | typeof Challenge {
  if (type === 'script') {
    return Script
  } else {
    return Challenge
  }
}

const useStyles = createUseStyles(theme => ({
  triggerableComponentDialog: {
    background: theme.bg.semi,
  },

  content: {
    ...layout.responsive(size => ({
      padding:       layout.padding.s[size],
      paddingTop:    layout.padding.xs[size],
      paddingBottom: layout.padding.s[size],
    })),
  },

  sectionHeader: {
    '&:not(.first)': {
      ...layout.responsiveProp({
        paddingTop: layout.padding.s,
      }),
    },
    paddingBottom: layout.padding.inline.xs,
  },

  buttonContainer: {
    background: theme.bg.semi,
  },
}))