/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Branding } from '~/models'
import { observer } from '~/ui/component'
import { Center, HBox, Label, SegmentedButton, VBox } from '~/ui/components'
import { ButtonSegment } from '~/ui/components/SegmentedButton'
import { FormFieldHeader } from '~/ui/form'
import { useModelDocumentData } from '~/ui/hooks/data'
import { AuthLogo } from '~/ui/layouts/auth'
import { useResourceTranslation } from '~/ui/resources'
import { BrandingGuide, createUseStyles, layout } from '~/ui/styling'
import { createStylingContext, StylingContext } from '~/ui/styling/StylingContext'
import RegistrationWidget from '../../widget/RegistrationWidget'
import { PreviewWidgetModel } from './PreviewWidgetModel'
import RegistrationWidgetBranding from './RegistrationWidgetBranding'

export interface Props {
  model: PreviewWidgetModel
}

const RegistrationPresetPreview = observer('RegistrationPresetPreview', (props: Props) => {

  const {model} = props
  const {t}     = useResourceTranslation('registration-presets')

  //------
  // Side

  const [view, setView] = React.useState<PreviewView>('register')

  const viewButtonSegments = React.useMemo((): ButtonSegment<PreviewView>[] => [
    {value: 'enter-code', icon: 'code', caption: t('preview.view.enter-code')},
    {value: 'register', icon: 'asterisk', caption: t('preview.view.register')},
    {value: 'branding', icon: 'palette', caption: t('preview.view.branding')},
  ], [t])

  React.useEffect(() => {
    model.setStatus(view === 'enter-code' ? 'code' : 'register')
  }, [model, view])

  //------
  // branding

  const [selectedBranding] = useModelDocumentData(Branding, model.branding, {fetch: 'notfound'})

  const brandingGuide = React.useMemo(() => {
    if (selectedBranding == null) { return null }
    return BrandingGuide.deserialize(selectedBranding)
  }, [selectedBranding])

  const stylingContext = React.useMemo(() => {
    if (brandingGuide == null) { return null }
    return createStylingContext(brandingGuide)
  }, [brandingGuide])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.RegistrationPresetPreview} flex gap={layout.padding.m}>
        {renderViewToggle()}
        {stylingContext != null ? (
          <StylingContext.Provider value={stylingContext}>
            {renderContent()}
          </StylingContext.Provider>
        ) : (
          renderContent()
        )}
      </VBox>
    )
  }

  function renderViewToggle() {
    return (
      <Center>
        <SegmentedButton
          segments={viewButtonSegments}
          selectedValue={view}
          onChange={setView}
          small
        />
      </Center>
    )
  }

  function renderContent() {
    switch (view) {
      case 'enter-code': case 'register':
        return (
          <VBox align='center'>
            <RegistrationWidget
              model={model}
              renderDefaultLogo={renderDefaultLogo}
            />
          </VBox>
        )
      case 'branding':
        return (
          <RegistrationWidgetBranding
            model={model}
            renderDefaultLogo={renderDefaultLogo}
          />
        )
    }
  }

  function renderDefaultLogo(size: Size) {
    return (
      <AuthLogo size={size}/>
    )
  }

  return render()

})

export type PreviewView = 'enter-code' | 'register' | 'branding'

export default RegistrationPresetPreview

const useStyles = createUseStyles({
  RegistrationPresetPreview: {
    ...layout.responsiveProp({
      paddingLeft: layout.padding.m,
    }),
  },
})