import React from 'react'
import * as UUID from 'uuid'
import config from '~/config'
import {
  Module,
  PlanAnnotation,
  PlanNode,
  PlanTextAnnotationStyle,
  Trigger,
  Triggerable,
} from '~/models'
import { defaultsForNode, plannerStore, triggerRequiresParameters } from '~/stores'
import { observer } from '~/ui/component'
import { useBoolean } from '~/ui/hooks'
import { useFlowPlanner } from '../FlowPlannerContext'
import { useCanvas } from '../canvas/FlowPlannerCanvasContext'
import CreateComponentForm from './CreateComponentForm'
import CreateComponentPopup from './CreateComponentPopup'
import { CreateComponentType } from './types'

export interface Props {
  popupOpen:      boolean
  requestClose:   () => any
  owningModule:   Module
  operationPoint: Point
}

const CreateComponent = observer('CreateComponent', (props: Props) => {

  const {popupOpen, requestClose, owningModule, operationPoint} = props
  const {pointToScreen} = useCanvas()
  const {planner} = useFlowPlanner()

  const getPopupTargetRect = React.useCallback(() => {
    const point = pointToScreen(operationPoint)
    return {
      left:   point.x,
      top:    point.y,
      width:  1,
      height: 1,
    }
  }, [operationPoint, pointToScreen])

  const [createFormOpen, openCreateForm, closeCreateForm] = useBoolean()
  const [createComponentType, setCreateComponentType] = React.useState<CreateComponentType | null>(null)

  const createComponent = React.useCallback((type: CreateComponentType) => {
    setCreateComponentType(type)
    openCreateForm()
  }, [openCreateForm])

  //------
  // Rendering

  function render() {
    return(
      <>
        {renderForm()}
        {renderPopup()}
      </>
    )
  }

  function renderForm() {
    if (createComponentType == null) { return null }
    return(
      <CreateComponentForm
        open={createFormOpen}
        requestClose={closeCreateForm}
        owningModule={owningModule}
        component={createComponentType}
        operationPoint={operationPoint}
      />
    )
  }

  function renderPopup() {
    return(
      <CreateComponentPopup
        open={popupOpen}
        requestClose={requestClose}
        requestCreate={requestCreateComponent}
        getTargetRect={getPopupTargetRect}
        owningModule={owningModule}
      />
    )
  }

  const createTriggerNow = React.useCallback((name: Trigger['type']) => {
    const size = config.planner.defaultComponentSize('trigger')

    planner.createNode({
      uuid:    UUID.v4(),
      type:    'trigger',
      name:    null,
      trigger: {type: name} as Trigger,
      bounds:  {left: operationPoint.x, top: operationPoint.y, ...size},
    })
  }, [planner, operationPoint])

  const createNodeNow = React.useCallback((name: PlanNode['type']) => {
    const defaultSize = config.planner.defaultComponentSize(name)

    planner.createNode({
      uuid:   UUID.v4(),
      type:   name,
      bounds: {left: operationPoint.x, top: operationPoint.y, ...defaultSize},
      ...defaultsForNode(name),
    } as PlanNode)
  }, [planner, operationPoint])

  const createAnnotationNow = React.useCallback((name: PlanAnnotation['type']) => {
    planner.createAnnotation({
      uuid:    UUID.v4(),
      type:    name,
      text:    'Annotation',
      style:   PlanTextAnnotationStyle.empty(),
      bounds:  {left: operationPoint.x, top: operationPoint.y, width: -1, height: -1},
    })
  }, [planner, operationPoint])

  const createAction = React.useCallback((name: string) => {
    const action = plannerStore.getAction(name)
    if (action == null) { return }
    if (Object.keys(action.paramsSchema).length === 0) {
      planner.createNode({
        uuid:         UUID.v4(),
        type:         'triggerable',
        triggerables: [{type: 'action', action: name, params: {}}],
        bounds:       {left: operationPoint.x, top: operationPoint.y, width: -1, height: -1},
      } as PlanNode)
    } else {
      createComponent({type: 'action', name: name})
    }
  }, [planner, operationPoint.x, operationPoint.y, createComponent])

  const createTriggerableNow = React.useCallback((type: Triggerable['type'], modelID: string) => {
    const bounds = {
      left:   operationPoint.x,
      top:    operationPoint.y,
      width:  -1,
      height: config.planner.defaultComponentSize('triggerable').height,
    }
    planner.createNode({
      uuid:         UUID.v4(),
      type:         'triggerable',
      name:         null,
      triggerables: [{uuid: UUID.v4(), type, model: modelID} as Triggerable],
      bounds:       bounds,
    })
  }, [planner, operationPoint])

  const createModuleNow = React.useCallback((model: Module) => {
    const size = config.planner.defaultComponentSize('module')
    planner.createNode({
      uuid: UUID.v4(),
      type: 'module',
      module: model.id,
      name: null,
      params: {},
      bounds: {left: operationPoint.x, top: operationPoint.y, ...size},
    })
  }, [planner, operationPoint])

  const requestCreateComponent = React.useCallback((value: CreateComponentType) => {
    if (value.type === 'trigger' && !triggerRequiresParameters(value.name)) {
      createTriggerNow(value.name)
    } else if (value.type === 'node') {
      createNodeNow(value.name)
    } else if (value.type === 'annotation') {
      createAnnotationNow(value.name)
    } else if (value.type === 'action') {
      createAction(value.name)
    } else if (value.type === 'triggerable' && value.model != null) {
      createTriggerableNow(value.name, value.model.id)
    } else if (value.type === 'triggerable' && value.parameter != null) {
      createTriggerableNow(value.name, `$${value.parameter}`)
    } else if (value.type === 'module' && value.model != null) {
      createModuleNow(value.model)
    } else {
      createComponent(value)
    }
    requestClose()
  }, [requestClose, createTriggerNow, createNodeNow, createAnnotationNow, createAction, createTriggerableNow, createModuleNow, createComponent])

  return render()
})

export default CreateComponent