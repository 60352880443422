export type LoginData =
  | {email: string, password: string}
  | {token: string}

export class AuthResetRequired extends Error {
  constructor(
    public readonly authResetToken: string,
  ) {
    super("Auth reset required")
  }
}

export class InvalidToken extends Error {
  constructor() {
    super("Invalid token")
  }
}

export type LoginStatus =
  | 'logged-out'
  | 'logging-in'
  | 'logged-in'

export interface OAuthTokenSet {
  access_token:  string
  refresh_token: string
  expires_at:    number
}