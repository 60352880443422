import React from 'react'
import { useTranslation } from 'react-i18next'
import { Location, Participant } from '~/models'
import { dataStore, ModelEndpoint } from '~/stores'
import { ParticipantField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { HBox, Label, SVG, VBox } from '~/ui/components'
import { isSuccessResult } from '~/ui/form'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  location:             Location
  participantsEndpoint: ModelEndpoint<Participant>
}

const LocationActions = observer('LocationActions', (props: Props) => {

  const {location, participantsEndpoint} = props
  const participantIDs = participantsEndpoint.ids

  const [t] = useTranslation('map')

  const document = dataStore.document(Location, location.id)

  const addParticipant = React.useCallback(async (participant: Participant) => {
    const result = await document.callBulkAction('participants:add', {
      data: [{type: 'participants', id: participant.id}],
      meta: {},
    })
    if (isSuccessResult(result)) {
      participantsEndpoint.add(participant)
    }
  }, [document, participantsEndpoint])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.LocationActions} gap={layout.padding.inline.m}>
        <HBox gap={layout.padding.inline.s}>
          <SVG name='location-enter' size={layout.icon.xs} dim/>
          <Label flex caption dim>
            {t('sidebar.actions.add_participant')}
          </Label>
        </HBox>
        <ParticipantField
          excludeIDs={participantIDs}
          onSelect={addParticipant}
        />
      </VBox>
    )
  }

  return render()

})

export default LocationActions

const useStyles = createUseStyles({
  LocationActions: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.s[size]],
    })),
  },
})