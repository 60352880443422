import React from 'react'
import { DateTrigger } from '~/models'
import { memo } from '~/ui/component'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: DateTrigger
}

const DateTriggerNodeView = memo('DateTriggerNodeView', (props: Props) => {
  // Convert the date to UTC, as planned dates are stored in UTC by default.
  // E.g. a stored date of 15:00 UTC means 15:00 in the project's local timezone, allowing for plans to be re-used across projects
  // in different timezones.
  const date    = props.trigger.date
  const utcDate = date.toUTC()

  return (
    <TriggerNodeView
      {...props}
      icon='clock'
      caption={utcDate.toFormat('MMM d, HH:mm')}
    />
  )
})

register('date', DateTriggerNodeView)