import React from 'react'
import { ContentState } from 'draft-js'
import { memo } from '~/ui/component'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  contentState: ContentState
  entityKey:    string
  blockKey:     string
  start:        number
  end:          number

  children?:    React.ReactNode
}

const Link = memo('Link', (props: Props) => {

  const {children} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <span classNames={$.variable}>
        {children}
      </span>
    )
  }

  return render()

})

export default Link

const useStyles = createUseStyles(theme => ({
  variable: {
    display:      'inline-block',
    background:   theme.bg.subtle,
    boxShadow:    ['inset', 0, 0, 0, 1, theme.fg.dimmer],
    borderRadius: layout.radius.s,
    padding:      layout.padding.inline.xs,
    fontFamily:   theme.fonts.mono.family,
    fontSize:     '0.9em',
  },
}))