import React from 'react'
import { memo } from '~/ui/component'
import { FlipNavigator } from '~/ui/components'
import { AuthLayout } from '~/ui/layouts/auth'
import ForgotPasswordScreen from '../auth/forgot-password/ForgotPasswordScreen'
import LoginScreen from '../auth/login/LoginScreen'
import AuthResetScreen from '../auth/reset/AuthResetScreen'

export interface Props {

}

const AuthNavigator = memo('AuthNavigator', (props: Props) => {

  return (
    <AuthLayout>
      <FlipNavigator>
        <FlipNavigator.Route
          side='front'
          component={LoginScreen}
          path='/login'
          exact
        />
        <FlipNavigator.Route
          side='back'
          component={AuthResetScreen}
          path='/auth/reset'
          exact
        />
        <FlipNavigator.Route
          side='back'
          component={ForgotPasswordScreen}
          path='/auth/forgot-password'
          exact
        />
      </FlipNavigator>
    </AuthLayout>
  )

})

export default AuthNavigator