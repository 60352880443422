import React from 'react'
import { Module } from '~/models'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField, FormFieldHeader, FormProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceFormDialog } from '~/ui/resources/form'
import { layout } from '~/ui/styling'
import ModuleFeatureList from './detail/ModuleFeatureList'
import ModuleFormModel from './ModuleFormModel'

export interface Props {
  open:         boolean
  requestClose: () => any
  afterSubmit?: FormProps<any>['afterSubmit']

  model?: Module | null
}

const ModuleForm = memo('ModuleForm', (props: Props) => {

  const {open, requestClose, afterSubmit, model = null} = props

  const {field} = useResourceTranslation('modules')

  const formModel = React.useMemo(
    () => new ModuleFormModel(model),
    [model],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceFormDialog<Module>
        Model={Module}
        formModel={formModel}

        open={open}
        requestClose={requestClose}
        afterSubmit={afterSubmit}

        children={renderBody()}
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>
        <FormField name='name' required>
          {bind => <TextField {...bind}/>}
        </FormField>

        {module == null && (
          <VBox gap={layout.padding.inline.s}>
            <FormFieldHeader {...field('features')}/>
            <ModuleFeatureList/>
          </VBox>
        )}
      </VBox>
    )
  }

  return render()

})

export default ModuleForm