import React from 'react'
import { memo } from '~/ui/component'
import { PopupMenu, PopupMenuItem, Toolbar, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useClientTabs } from './ClientTabManagerContext'
import { translateClientTabCaption } from './util'

const TabManagerToolbar = memo('TabManagerToolbar', () => {

  const {addTab, tabs, templates, editingItems, startEditingItems, stopEditingItems, openForm, setEditingItemIndex} = useClientTabs()

  const {t} = useResourceTranslation('client-tabs')

  const openAddForm = React.useCallback(() => {
    setEditingItemIndex(null)
    openForm()
  }, [openForm, setEditingItemIndex])

  const popupMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = templates
      .filter(template => !tabs.find(tab => tab.link.href === template.href))
      .map(template => ({
        caption: translateClientTabCaption(template.caption),
        icon:    template.icon,
        onSelect: () => addTab({
          link:    {href: template.href},
          icon:    {type: 'named', value: template.icon},
          caption: template.caption,
        }),
    }))

    items.push({section: '-'})

    items.push({
      caption:  t('custom'),
      icon:     'plus',
      onSelect: openAddForm,
    })

    return items
  }, [t, templates, tabs, addTab, openAddForm])

  //------
  // Rendering

  function render() {
    return(
      <Toolbar style='panel'>
        <VBox>
          {!editingItems && renderAddButton()}
        </VBox>
        <VBox>
          {editingItems ? renderDoneButton() : renderEditButton()}
        </VBox>
      </Toolbar>
    )
  }

  function renderAddButton() {
    return(
      <PopupMenu items={popupMenuItems}>
        {toggle => (
          <Toolbar.Button
            icon='plus'
            caption={t('add')}
            onTap={toggle}
          />
        )}
      </PopupMenu>
    )
  }

  function renderEditButton() {
    return(
      <Toolbar.Button
        icon='pencil'
        caption={t('buttons:edit')}
        onTap={startEditingItems}
          />
    )
  }

  function renderDoneButton() {
    return(
      <Toolbar.Button
        icon='check'
        caption={t('buttons:done')}
        onTap={stopEditingItems}
      />
    )
  }

  return render()

})

export default TabManagerToolbar