import React from 'react'
import Toast from 'react-toast'
import Color from 'color'
import { isArray, upperFirst } from 'lodash'
import { Model, ModelClass } from '~/models'
import { projectStore } from '~/stores'
import ModuleChip from '~/ui/app/modules/ModuleChip'
import { component, observer } from '~/ui/component'
import {
  Chip,
  Dimple,
  HBox,
  IDLabel,
  KebabMenu,
  Label,
  Panel,
  PopupMenuItem,
  SVG,
  Tappable,
  VBox,
} from '~/ui/components'
import { panelBorderRadius } from '~/ui/components/panel'
import { SVGName } from '~/ui/components/SVG'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceAppLink } from '~/ui/resources/components'
import { createUseStyles, layout } from '~/ui/styling'
import { isReactText } from '~/ui/util'
import SummaryPanelSection from './SummaryPanelSection'

export interface Props {
  Model: ModelClass<any>
  model: Model

  icon?:           SVGName
  caption?:        React.ReactNode
  type?:           React.ReactNode
  tags?:           React.ReactNode
  info?:           React.ReactNode
  footer?:         React.ReactNode
  kebabMenuItems?: PopupMenuItem[]
}

export interface EditAction {
  name: string
  icon?: SVGName
  caption: string
  color?: Color
}

const ResourceDetailSummaryPanel = component('ResourceDetailSummaryPanel', (props: Props) => {

  const {model, info, footer} = props

  //------
  // Rendering

  function render() {
    const info   = renderInfo()
    const footer = renderFooter()

    return (
      <Panel>
        {renderHeader()}
        {info && <Dimple horizontal/>}
        {info}
        {footer}
      </Panel>
    )
  }

  function renderHeader() {
    if (model == null) { return null }

    return (
      <ResourceDetailSummaryPanelHeader
        {...props}
        model={model}
      />
    )
  }

  function renderInfo() {
    if (info == null) { return null }

    return (
      <SummaryPanelSection>
        {info}
      </SummaryPanelSection>
    )
  }

  function renderFooter() {
    if (footer == null) { return null }

    return React.Children.map(footer, item => item == null ? null : (
      <>
        <Dimple horizontal/>
        <SummaryPanelSection>
          {item}
        </SummaryPanelSection>
      </>
    ))
  }

  return render()

})

const ResourceDetailSummaryPanelHeader = observer('ResourceDetailSummaryPanelHeader', (props: Props) => {

  const {projectID} = projectStore
  const {model, icon, caption, type, tags, kebabMenuItems = []} = props

  const {t, singular} = useResourceTranslation()

  const [idShown, setIdShown] = React.useState<boolean>(false)

  const toggleID = React.useCallback(() => {
    if (!idShown) {
      setIdShown(true)
    } else {
      navigator.clipboard.writeText(model.id)
      setIdShown(false)
      Toast.show({
        ...t('id.copied_to_clipboard'),
        type: 'success',
      })
    }
  }, [idShown, model.id, t])

  const isOwnproject = 'project' in model && model.project === projectID
  const moduleID     = ('module' in model && (model as any).module != null) ? (model as any).module : null
  const isMainModule = moduleID === projectStore.projectID

  const getTags = React.useCallback(() => {
    const tags: string[] = []
    if ('tags' in model && isArray((model as any).tags)) {
      tags.push(...(model as any).tags)
    }

    return tags
  }, [model])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <SummaryPanelSection classNames={$.header} gap={layout.padding.inline.m}>
        <HBox gap={layout.padding.inline.l} align='top'>
          <HBox flex gap={layout.padding.inline.l} align='top'>
            {renderIcon()}
            {renderLabels()}
          </HBox>
          {renderKebabMenu()}
        </HBox>
        {renderTags()}
      </SummaryPanelSection>
    )
  }

  function renderIcon() {
    return (
      <SVG
        name={icon ?? model.$icon}
        size={layout.icon.l}
      />
    )
  }

  function renderLabels() {
    return (
      <VBox flex gap={layout.padding.inline.xs}>
        {caption != null && !isReactText(caption) ? (
          caption
        ) : (
          <Label h3>
            {caption ?? model.$caption}
          </Label>
        )}
        {renderID()}
        <ResourceAppLink model={model}/>
      </VBox>
    )
  }

  function renderID() {
    return (
      <HBox>
        <Tappable classNames={$.id} flex='shrink' onTap={toggleID} showFocus noFeedback>
          {idShown ? (
            <IDLabel tiny dim>
              {model.id}
            </IDLabel>
          ) : type == null || isReactText(type) ? (
            <Label small dim>
              {type ?? upperFirst(singular())}
            </Label>
          ) : (
            type
          )}
        </Tappable>
      </HBox>
    )
  }

  function renderTags() {
    const content = tags ?? renderTagsDefault()
    if (content == null) { return null }

    return (
      <VBox classNames={$.tags}>
        {content}
      </VBox>
    )
  }

  function renderTagsDefault() {
    const tags = getTags()
    if (isOwnproject && (moduleID == null || isMainModule) && tags.length === 0) { return null }

    return (
      <HBox wrap gap={layout.padding.inline.s}>
        {moduleID != null && (
          <ModuleChip
            id={moduleID}
            flex='shrink'
            fetch='notfound'
            small
          />
        )}
        {tags.map(tag => (
          <Chip
            key={tag}
            icon='tag'
            children={tag}
            flex='shrink'
            small
            dim
          />
        ))}
      </HBox>
    )
  }

  function renderKebabMenu() {
    if (kebabMenuItems.length === 0) { return null }
    else {
      return (
        <KebabMenu
          items={kebabMenuItems}
        />
      )
    }

  }

  return render()

})

export default ResourceDetailSummaryPanel

const useStyles = createUseStyles(theme => ({
  header: {
    borderRadius:            panelBorderRadius(theme),
    borderBottomLeftRadius:  0,
    borderBottomRightRadius: 0,

    background: theme.semantic.secondary.mix(theme.bg.alt, 0.95),
  },

  id: {
    borderRadius: layout.radius.s,
  },

  tags: {
    paddingLeft: layout.icon.l.width + layout.padding.inline.l,
  },
}))