import React from 'react'
import Color from 'color'
import { HBox, Label, Panel, panelBorderRadius, VBox } from '~/ui/components'
import { createUseStyles, layout, shadows, useStyling } from '~/ui/styling'
import { Props as DataGridColumnProps } from './DataGridColumn'

export interface Props<T> {
  item:    T
  index:   number
  columns: React.ReactElement<DataGridColumnProps<T>>[]

  onItemTap?:  (item: T) => any
  href?:       string
  selected?:   boolean
  classNames?: React.ClassNamesProp
}

const DataGridBar = <T extends {}>(props: Props<T>) => {

  const {item, index, columns, onItemTap, href, selected, classNames} = props

  const handleTap = React.useMemo(() => {
    if (onItemTap == null) { return undefined }
    return () => { onItemTap?.(item) }
  }, [item, onItemTap])

  //------
  // Rendering

  const $ = useStyles()
  const {colors} = useStyling()

  function render() {
    return (
      <VBox classNames={[$.dataGridBar, {selected}, classNames]}>
        <Panel
          semi={false}
          onTap={handleTap}
          href={href}
          style={{overflow: 'hidden'}}
          bandColor={selected ? colors.semantic.primary : transparent}
        >
          <HBox align='stretch'>
            {columns.map(renderCell)}
          </HBox>
        </Panel>
      </VBox>
    )
  }

  function renderCell(column: {props: DataGridColumnProps<T>}) {
    const {name, renderCell, align = 'stretch', padded = true} = column.props
    const textAlign = align === 'stretch' ? 'left' : align

    return (
      <VBox key={name} classNames={[$.cell, {padded}, 'DataGrid--cell']}>
        <VBox flex='grow' align={align} justify='middle'>
          {renderCell != null ? (
            renderCell(item, index)
          ) : (
            <Label align={textAlign}>
              {(item as any)[name]}
            </Label>
          )}
        </VBox>
      </VBox>
    )
  }

  return render()

}

export default React.memo(DataGridBar) as typeof DataGridBar

export const cellPadding = {
  '& > *': {
    padding: [layout.padding.inline.s, layout.padding.inline.m],
  },
  '&:first-child > *': {
    paddingLeft: layout.padding.inline.l,
  },
  '&:last-child > *': {
    paddingRight: layout.padding.inline.l,
  },
}

const transparent = new Color('transparent')

const useStyles = createUseStyles(theme => ({
  dataGridBar:{
    borderRadius: panelBorderRadius(theme),
    '&.selected': {
      boxShadow: shadows.selected(theme),
    },
  },

  cell: {
    overflow:  'hidden',
    minHeight: layout.barHeight.m,

    '&.padded': {
      minHeight: layout.barHeight.l,
      ...cellPadding,
    },
  },
}))