import React from 'react'
import { memo, observer } from '~/ui/component'
import { HBox, Label, Panel, Switch, VBox } from '~/ui/components'
import { FormField, FormFieldHeader } from '~/ui/form'
import { useFormField } from '~/ui/form/hooks'
import { layout } from '~/ui/styling'
import renderDynamicFieldAllowance from './allowances/index'
import { DynamicField, DynamicFormSchema } from './types'

export interface Props {
  schema: DynamicFormSchema
}

const DynamicFieldset = memo('DynamicFieldset', (props: Props) => {

  const {schema} = props

  const renderAsLabel = (field: DynamicField) => {
    if (field.type !== 'string') { return true }
    return !field.richText
  }

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        {schema.map(renderField)}
      </VBox>
    )
  }

  function renderField(field: DynamicField) {
    if (field.type === 'object') {
      return (
        <NestedFieldset
          key={field.name}
          name={field.name}
          caption={field.caption}
          label={field.label}
          required={field.required}
          schema={field.schema}
        />
      )
    } else {
      return (
        <FormField
          key={field.name}
          name={field.name}
          caption={field.caption ?? false}
          label={field.label ?? false}
          required={field.required}
          instruction={field.instruction}
          renderAsLabel={renderAsLabel(field)}
          children={bind => renderDynamicFieldAllowance(field, bind)}
        />
      )
    }
  }

  return render()

})

interface NestedFieldsetProps extends Props {
  name:      string
  caption?:  string | null
  label?:    string | {on: string, off: string} | null
  required?: boolean
}

const NestedFieldset = observer('NestedFieldset', (props: NestedFieldsetProps) => {

  const {name, caption, required, ...rest} = props

  const [value, setValue] = useFormField<object | null>(name)
  const prevValueRef = React.useRef<object | null>(value)

  const enabled    = required || value != null
  const setEnabled = React.useCallback((enabled: boolean | null) => {
    if (enabled) {
      setValue(prevValueRef.current ?? {})
    } else {
      prevValueRef.current = value
      setValue(null)
    }
  }, [setValue, value])

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {caption != null && (
          <FormFieldHeader
            caption={renderCaption()}
          />
        )}
        {enabled && (
          <Panel semi padding={layout.padding.s}>
            <DynamicFieldset {...rest}/>
          </Panel>
        )}
      </VBox>
    )
  }

  function renderCaption() {
    if (required) {
      return caption
    } else {
      return (
        <HBox gap={layout.padding.s}>
          <Switch
            isOn={enabled}
            onChange={setEnabled}
          />
          <Label bold={enabled} dim={!enabled} flex='shrink'>
            {caption}
          </Label>
        </HBox>
      )
    }
  }

  return render()

})

export default DynamicFieldset