import { action, computed, makeObservable, observable } from 'mobx'
import socket from 'socket.io-react'
import { OperatorService } from './operator'
import { register } from './support'

export class OperatorStore {

  constructor() {
    makeObservable(this)
  }

  @observable
  public service: OperatorService | null = null

  @computed
  public get starting() {
    return this.service?.starting ?? true
  }

  @computed
  public get started() {
    return this.service?.started ?? false
  }

  @computed
  public get startError() {
    return this.service?.startError ?? null
  }

  private persistedParticipants: Array<[string, string]> = []

  @action
  public startService() {
    const service = new OperatorService(socket, this.persistedParticipants)
    service.start().then(action(() => {
      this.service = service
    }))

    return () => {
      service.stop()

      if (service === this.service) {
        this.service = null
      }
    }
  }

  //------
  // Persistence

  public persistenceKey = 'operator'

  public persist() {
    const participants = this.service != null
      ? Array.from(this.service.participants)
      : this.persistedParticipants

    return {
      participants: participants ?? [],
    }
  }

  public rehydrate(raw: AnyObject) {
    if (raw.participants != null) {
      this.persistedParticipants = raw.participants
    }
  }

}

const operatorStore = register(new OperatorStore())
export default operatorStore