import React from 'react'
import { memo } from '~/ui/component'
import { HBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import BrandingAssetList from './BrandingAssetList'

export interface Props {
  focusedAsset:       BrandingAsset | null
  requestAddAsset:    (type: BrandingAssetType) => any
  requestEditAsset:   (type: BrandingAssetType, name: string) => any
  requestRemoveAsset: (type: BrandingAssetType, name: string) => any
}

export interface BrandingAsset {
  type: BrandingAssetType
  name: string
}

export type BrandingAssetType =
  | 'color'
  | 'background'
  | 'border'

const BrandingAssetsContainer = memo('BrandingAssetsContainer', (props: Props) => {

  const {
    focusedAsset,
    requestAddAsset,
    requestEditAsset,
    requestRemoveAsset,
  } = props

  //------
  // Rendering

  function render() {
    return (
      <HBox flex align='stretch' gap={layout.padding.m}>
        <BrandingAssetList
          type='color'
          selectedAsset={focusedAsset}
          requestAddAsset={requestAddAsset}
          requestEditAsset={requestEditAsset}
          requestRemoveAsset={requestRemoveAsset}
        />
        <BrandingAssetList
          type='background'
          selectedAsset={focusedAsset}
          requestAddAsset={requestAddAsset}
          requestEditAsset={requestEditAsset}
          requestRemoveAsset={requestRemoveAsset}
        />
        <BrandingAssetList
          type='border'
          selectedAsset={focusedAsset}
          requestAddAsset={requestAddAsset}
          requestEditAsset={requestEditAsset}
          requestRemoveAsset={requestRemoveAsset}
        />
      </HBox>
    )
  }

  return render()

})

export default BrandingAssetsContainer