import React from 'react'
import Color from 'color'
import { BrandedComponentSpec, ClientTab } from '~/models'
import { projectStore } from '~/stores'
import { ImageView } from '~/ui/app/media'
import { translateClientTabCaption } from '~/ui/app/projects/settings/client-tabs/util'
import { observer } from '~/ui/component'
import { BrandedComponent, Center, HBox, Label, SVG, Tappable, VBox } from '~/ui/components'
import { createUseStyles, layout, WebTabBarBranding } from '~/ui/styling'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const WebTabBarBrandingPreview = observer('WebTabBarBrandingPreview', (props: Props) => {

  const {componentName, spec, variant} = props
  const {guide} = useBranding()

  const project = projectStore.project
  const tabs    = project?.clientTabs ?? []

  const [component, flags, size] = useComponentBranding<WebTabBarBranding>(componentName, variant, spec)

  const [activeIconIndex, setActiveIconIndex] = React.useState<number>(0)

  const iconColor       = guide.colors.resolve(component.resolve('iconColor', flags))
  const activeIconColor = guide.colors.resolve(component.resolve('activeIconColor', flags))

  //------
  // Rendering

  const $ = useStyles({iconColor, activeIconColor})

  function render() {
    return (
      <BrandedComponent
        classNames={$.webTabBarBrandingPreview}
        guide={guide ?? undefined}
        branding={component}
        variant={flags}
        height={size.height}
        style={{...size}}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <HBox flex align='stretch'>
        {tabs.map(renderTab)}
      </HBox>
    )
  }

  const colorForTab = React.useCallback((tab: ClientTab, index: number) => {
    if (index === activeIconIndex) {
      return activeIconColor
    } else {
      return iconColor
    }
  }, [activeIconColor, activeIconIndex, iconColor])

  function renderTab(tab: ClientTab, index: number) {
    return (
      <VBox key={index} flex>
        <Tappable flex onTap={() => setActiveIconIndex(index)}>
          <VBox flex justify='middle' gap={layout.padding.inline.s}>
            <Center>
              {renderIcon(tab, index)}
            </Center>
            <Label small caption align='center' color={colorForTab(tab, index) ?? undefined}>
              {translateClientTabCaption(tab.caption)}
            </Label>
          </VBox>
        </Tappable>
      </VBox>
    )
  }

  function renderIcon(tab: ClientTab, index: number) {
    const active = index === activeIconIndex
    if (tab.icon.type === 'custom') {
      return (
        <ImageView
          source={tab.icon.value}
          size={iconSize}
          classNames={[$.icon, {active, custom: true}]}
        />
      )
    } else {
      return (
        <SVG
          name={tab.icon.value}
          size={iconSize}
          classNames={[$.icon, {active}]}
          color={colorForTab(tab, index)}
        />
      )
    }
  }

  return render()

})

registerComponentBrandingPreview('web.tabBar', WebTabBarBrandingPreview)
export default WebTabBarBrandingPreview

export const iconSize = {width: 24, height: 24}

const useStyles = createUseStyles({
  webTabBarBrandingPreview: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,

    borderBottomWidth: 0,
    borderLeftWidth:   0,
    borderRightWidth:  0,
  },

  icon: ({iconColor, activeIconColor}: {iconColor: Color, activeIconColor: Color}) => ({
    '&.custom [fill]': {
      fill: iconColor,
    },
    '&.active': {
      '&.custom [fill]': {
        fill: activeIconColor,
      },
    },
  }),
})