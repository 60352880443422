import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModelClass } from '~/models'
import { memo } from '~/ui/component'
import { Label, Notice, NoticeProps, TextBlock, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { layout, ThemeProvider, useStyling } from '~/ui/styling'

export interface Props extends Omit<NoticeProps, 'backgroundColor' | 'padding'> {
  Model: ModelClass<any>
}

const ResourceFromLinkedProjectNotice = memo('ResourceFromLinkedProjectNotice', (props: Props) => {

  const {Model, ...rest} = props

  const [t] = useTranslation('resource')
  const {singular} = useResourceTranslation(Model.resourceType)

  //------
  // Rendering

  const {colors} = useStyling()

  function render() {
    return (
      <ThemeProvider dark>
        <Notice backgroundColor={colors.semantic.dark.warning} padding={layout.padding.inline.m} {...rest}>
          <VBox align='center' gap={layout.padding.inline.xs}>
            <Label bold small>{t('linked-project.notice.title', {resource: singular()})}</Label>
            <TextBlock small dim align='center'>
              {t('linked-project.notice.detail')}
            </TextBlock>
          </VBox>
        </Notice>
      </ThemeProvider>
    )
  }

  return render()

})

export default ResourceFromLinkedProjectNotice