import React from 'react'
import { useTranslation } from 'react-i18next'
import { RelatedField, RelatedFieldOperation } from 'sheet-importer'
import { observer } from '~/ui/component'
import { CheckboxField, SelectField, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import { register } from './registry'

export interface Props {
  field: RelatedField
}

const RelatedFieldForm = observer('RelatedFieldForm', (props: Props) => {

  const {field} = props

  const [t] = useTranslation('importer')

  const operationChoices = React.useMemo(() => {
    return RelatedFieldOperation.all.map(operation => ({
      value:   operation,
      caption: t(`preview.related.operations.${operation}`),
    }))
  }, [t])

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.inline.m}>
        {field.multi && (
          <SelectField<RelatedFieldOperation>
            {...field.bind('operation')}
            choices={operationChoices}
            small={true}
          />
        )}
        <CheckboxField
          {...field.bind('create')}
          label={t('preview.related.create_if_not_found')}
          smallLabel={true}
        />
      </VBox>
    )
}

  return render()

})

export default RelatedFieldForm
register(RelatedField, RelatedFieldForm)