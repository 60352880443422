import React from 'react'
import { Widget, WidgetAlotment } from '~/models'
import { DataPoint, WidgetState } from '~/stores'
import { memo } from '~/ui/component'
import { HBox, Label, VBox } from '~/ui/components'
import { Funnel } from '~/ui/components/datavis'
import { createUseStyles, layout } from '~/ui/styling'
import { widget } from './registry'

export interface Props {
  alotment: WidgetAlotment
  widget:   Widget
  state:    WidgetState | null
}

const FunnelWidgetView = memo('FunnelWidgetView', (props: Props) => {

  const {widget, state} = props

  const data    = state?.data ?? []
  const palette = state?.config.palette ?? 'default'

  const valueForPoint = React.useCallback((point: DataPoint) => {
    return point.value
  }, [])

  const [segmentHeight, setSegmentHeight] = React.useState<number>(0)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox classNames={$.funnelWidgetView} flex align='stretch' gap={layout.padding.inline.l}>
        <VBox flex>
          {renderFunnel()}
        </VBox>
        {renderLegend()}
      </HBox>
    )
  }

  function renderFunnel() {
    return (
      <Funnel
        flex
        data={data}
        palette={palette}
        valueForPoint={valueForPoint}
        onSegmentHeight={setSegmentHeight}
        legendLines='right'
      />
    )
  }

  function renderLegend() {
    return (
      <VBox classNames={$.legend}>
        {data.map(renderLegendRow)}
      </VBox>
    )
  }

  function renderLegendRow(point: DataPoint, index: number) {
    const top   = segmentHeight * index
    const label = widget.label(point)

    return (
      <HBox key={label ?? index} classNames={$.legendRow} style={{top}} gap={layout.padding.inline.m}>
        <Label small dim bold>
          {label}
        </Label>
        <Label small bold>
          {valueForPoint(point)}
        </Label>
      </HBox>
    )
  }

  return render()

})

widget('funnel')(FunnelWidgetView)
export default FunnelWidgetView

const useStyles = createUseStyles({
  funnelWidgetView: {
    // Leave some room as the top legend item will extend above the top line.
    padding: [layout.padding.inline.l, 0],
  },

  legend: {
    position: 'relative',
  },

  legendRow: {
    position: 'absolute',
    left:     0,
    right:    0,
    height:   0,
  },
})