import React from 'react'
import I18next from 'i18next'
import { isArray, isPlainObject, snakeCase } from 'lodash'
import { useResourceTypeI18n } from './ResourceTypeContext'

import type { TFunction, TFunctionKeys } from 'i18next'

export function useResourceTranslation(type?: string, options: UseResourceTranslationOptions = {}): ResourceTranslation {
  const i18n         = useResourceTypeI18n()
  const resourceType = type ?? i18n.type
  const prefix       = options.prefix ?? i18n.prefix

  const prefixKey = React.useCallback((key: TFunctionKeys) => {
    if (prefix != null) {
      return `${prefix}.${key}`
    } else {
      return key
    }
  }, [prefix])

  const ns = React.useMemo((): string[] => {
    if (resourceType == null) {
      return ['resource']
    } else {
      return [resourceType, 'resource']
    }
  }, [resourceType])

  const singular = React.useCallback(() => {
    return I18next.t('singular', {ns})
  }, [ns])

  const plural = React.useCallback(() => {
    return I18next.t('plural', {ns})
  }, [ns])

  const subtitle = React.useCallback(() => {
    return I18next.t('subtitle', {ns})
  }, [ns])

  const t: TFunction = React.useCallback((key: TFunctionKeys | TFunctionKeys[], ...args: any[]) => {
    const options = isPlainObject(args[args.length - 1]) ? args.pop()! : {}

    const keys     = isArray(key) ? key : [key]
    const prefixed = keys.map(prefixKey)


    return I18next.t(prefixed, ...args, {
      singular: singular(),
      plural:   plural(),
      ns:       ns,
      ...options,
    })
  }, [ns, plural, prefixKey, singular])

  const field = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `fields.${key}`,
      `resource:fields.${key}`,
    ], options)
  }, [t])

  const fieldCaption = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `fields.${key}.caption`,
      `resource:fields.${key}.caption`,
      `fields.${key}`,
      `resource:fields.${key}`,
    ], options)
  }, [t])

  const fieldPrompt = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `fields.${key}.prompt`,
      `resource:fields.${key}.prompt`,
      `fields.${key}.caption`,
      `resource:fields.${key}.caption`,
      `fields.${key}`,
      `resource:fields.${key}`,
    ], options)
  }, [t])

  const fieldLabel = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `fields.${key}.label`,
      `resource:fields.${key}.label`,
    ], options)
  }, [t])

  const fieldPlaceholder = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `fields.${key}.placeholder`,
      `resource:fields.${key}.placeholder`,
    ], {defaultValue: null, ...options})
  }, [t])

  const fieldInstruction = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `fields.${key}.instruction`,
      `resource:fields.${key}.instruction`,
    ], {defaultValue: null, ...options})
  }, [t])

  const fieldError = React.useCallback((name: string | null, code: string, options: AnyObject = {}) => {
    const keys: string[] = []

    if (name != null) {
      const key = escapeKey(name)
      keys.push(
        `fields.${key}.errors.${code}`,
        `resource:fields.${key}.errors.${code}`,
      )
    }

    return t([
      ...keys,
      `fields._common.errors.${code}`,
      `resource:fields._common.errors.${code}`,
    ], {defaultValue: null, ...options})
  }, [t])

  const filterCaption = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `filters.${key}.caption`,
      `resource:filters.${key}.caption`,
      `filters.${key}`,
      `resource:filters.${key}`,
      `fields.${key}.caption`,
      `resource:fields.${key}.caption`,
      `fields.${key}`,
      `resource:fields.${key}`,
      `filters.${key}`,
    ], options)
  }, [t])

  const filterInstruction = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `filters.${key}.instruction`,
      `resource:filters.${key}.instruction`,
      `fields.${key}.instruction`,
      `resource:fields.${key}.instruction`,
      `filters.${key}.instruction`,
    ], {defaultValue: null, ...options})
  }, [t])

  const filterPlaceholder = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `filters.${key}.placeholder`,
      `resource:filters.${key}.placeholder`,
      `fields.${key}.placeholder`,
      `resource:fields.${key}.placeholder`,
      `filters.${key}.placeholder`,
    ], {defaultValue: null, ...options})
  }, [t])

  const filterLabel = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `filters.${key}.label`,
      `resource:filters.${key}.label`,
      `fields.${key}.label`,
      `resource:fields.${key}.label`,
      `filters.${key}.label`,
    ], options)
  }, [t])

  const actionCaption = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    return t([
      `actions.${key}.caption`,
      `actions.${key}`,
      `resource:actions.${key}.caption`,
      `resource:actions.${key}`,
    ], options)
  }, [t])

  const actionConfirm = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    const title = t([
      `actions.${key}.confirm.title`,
      `resource:actions.${key}.confirm.title`,
    ], options)
    const message = t([
      `actions.${key}.confirm.message`,
      `resource:actions.${key}.confirm.message`,
    ], options)
    const yesCaption = t([
      `actions.${key}.confirm.yes_caption`,
      `resource:actions.${key}.confirm.yes_caption`,
    ], options)
    const noCaption = t([
      `actions.${key}.confirm.no_caption`,
      `resource:actions.${key}.confirm.no_caption`,
    ], options)

    return {title, message, yesCaption, noCaption}
  }, [t])

  const actionSuccess = React.useCallback((name: string, options: AnyObject = {}) => {
    const key = escapeKey(name)
    const title = t([
      `actions.${key}.success.title`,
      `resource:actions.${key}.success.title`,
    ], options)
    const detail = t([
      `actions.${key}.success.detail`,
      `resource:actions.${key}.success.detail`,
    ], options)

    return {title, detail}
  }, [t])

  return React.useMemo(() => ({
    t,
    singular,
    plural,
    subtitle,
    field,
    fieldCaption,
    fieldPrompt,
    fieldInstruction,
    fieldPlaceholder,
    fieldLabel,
    fieldError,
    filterCaption,
    filterInstruction,
    filterPlaceholder,
    filterLabel,
    actionCaption,
    actionConfirm,
    actionSuccess,
  }), [t, singular, plural, subtitle, field, fieldCaption, fieldPrompt, fieldInstruction, fieldPlaceholder, fieldLabel, fieldError, filterCaption, filterInstruction, filterPlaceholder, filterLabel, actionCaption, actionConfirm, actionSuccess])
}

function escapeKey(key: string) {
  return key.split('.').map(snakeCase).join('.')
}

export interface UseResourceTranslationOptions {
  prefix?: string
}

export interface ResourceTranslation {
  t: TFunction

  singular: () => string
  plural:   () => string

  subtitle: () => string

  field:            (name: string, options?: AnyObject) => any
  fieldCaption:     (name: string, options?: AnyObject) => string
  fieldPrompt:      (name: string, options?: AnyObject) => string
  fieldLabel:       (name: string, options?: AnyObject) => string | null
  fieldPlaceholder: (name: string, options?: AnyObject) => string | null
  fieldInstruction: (name: string, options?: AnyObject) => string | null
  fieldError:       (name: string | null, code: string, options?: AnyObject) => string | null

  filterCaption:     (name: string, options?: AnyObject) => string
  filterLabel:       (name: string, options?: AnyObject) => string | null
  filterPlaceholder: (name: string, options?: AnyObject) => string | null
  filterInstruction: (name: string, options?: AnyObject) => string | null

  actionCaption:    (name: string, options?: AnyObject) => string
  actionConfirm:    (name: string, options?: AnyObject) => {title: string, message: string, yesCaption: string, noCaption: string}
  actionSuccess:    (name: string, options?: AnyObject) => {title: string, detail: string}
}