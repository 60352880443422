import React from 'react'
import { Participant } from '~/models'
import { observer } from '~/ui/component'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'

export interface Props {
  participant: Participant
}

const ParticipantInfo = observer('ParticipantInfo', (props: Props) => {

  const {participant} = props

  const {t} = useResourceTranslation()

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: participant.createdAt})}
        </SummaryInfo.Item>
        {!participant.listed && (
          <SummaryInfo.Item markup icon='eye-off' important>
            {t('unlisted')}
          </SummaryInfo.Item>
        )}
        {participant.lastResetAt != null && (
          <SummaryInfo.Item markup>
            {t('last_reset', {when: participant.lastResetAt.toFormat('DDD, HH:mm')})}
          </SummaryInfo.Item>
        )}
        <SummaryInfo.Item markup dimmer={participant.lastLoginAt == null}>
          {participant.lastLoginAt != null ? (
            t('last_login.info', {when: participant.lastLoginAt.toFormat('DDD, HH:mm')})
          ) : (
            t('last_login.never')
          )}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  return render()

})

export default ParticipantInfo