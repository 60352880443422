import React from 'react'
import { EnumUtil } from 'ytil'
import { RegistrationCode, RegistrationCodeStatus, RegistrationPreset } from '~/models'
import { ModelEndpoint } from '~/stores'
import { observer } from '~/ui/component'
import { DataGrid, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, ResourceFilter, ResourceList } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import { CodeCell, ProjectCell, StatusCell } from '../../codes/list/cells'

export interface Props {
  preset:   RegistrationPreset
  endpoint: ModelEndpoint<RegistrationCode>
}

const RegistrationPresetCodesList = observer('RegistrationPresetCodesList', (props: Props) => {

  const {preset, endpoint} = props
  const {t} = useResourceTranslation('registration-codes')

  const statusFilterChoices = React.useMemo((): Choice<RegistrationCodeStatus | null>[] => [
    {value: null, caption: t(['filters.status.unspecified', 'filters._common.unspecified'])},
    ...EnumUtil.values(RegistrationCodeStatus).map(status => ({
      value:   status,
      caption: t(`status.${status}`),
    }))], [t])

  const bulkActions = React.useMemo((): BulkAction[] => {
    return [{
      name: 'revoke',
      icon: 'eye-off',
    }, {
      name: 'unrevoke',
      icon: 'eye',
    }]
  }, [])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.RegistrationPresetCodesList} flex>
        <ResourceList
          endpoint={endpoint}
          parentModel={preset}
          bulkActions={bulkActions}
          allowCopy={false}
          allowExport={true}
          allowDetail={false}
          useLocation
        >
          <DataGrid.Column<RegistrationCode>
            name='code'
            renderCell={CodeCell.renderer}
            flex={2}
          />
          <DataGrid.Column
            name='project'
            renderCell={ProjectCell.renderer}
            flex={1}
          />
          <DataGrid.Column<RegistrationCode>
            name='status'
            renderCell={StatusCell.renderer}
            width={120}
          />

          <ResourceFilter<RegistrationCodeStatus | null> name='status' caption={false} defaultValue={null}>
            {bind => (
              <SelectField
                {...bind}
                inputStyle='dark'
                choices={statusFilterChoices}
              />
            )}
          </ResourceFilter>
        </ResourceList>
      </VBox>
    )
  }

  return render()

})

export default RegistrationPresetCodesList

const useStyles = createUseStyles({
  RegistrationPresetCodesList: {
    ...layout.responsiveProp({
      paddingLeft: layout.padding.m,
    }),
  },
})