import URL from 'url'
import { WebhookTrigger } from '~/models'

export default function webhookTriggerExamples(trigger: WebhookTrigger, url: string) {
  const examples = {} as Record<WebhookTriggerExampleType, string>
  for (const type of WebhookTriggerExampleType.all) {
    examples[type] = webhookTriggerExample(type, trigger, url)
  }

  return examples
}

export function webhookTriggerExample(type: WebhookTriggerExampleType, trigger: WebhookTrigger, url: string) {
  switch (type) {
    case 'raw': return webhookTriggerRawExample(trigger, url)
    case 'http': return webhookTriggerHTTPExample(trigger, url)
    case 'curl': return webhookTriggerCurlExample(trigger, url)
  }

}

export function webhookTriggerRawExample(trigger: WebhookTrigger, url: string) {
  return webhookTriggerJSONBody(trigger)
}

export function webhookTriggerHTTPExample(trigger: WebhookTrigger, urlString: string) {
  const url  = URL.parse(urlString)
  const body = webhookTriggerJSONBody(trigger)

  return [
    `GET ${url.path} HTTP/1.1`,
    `Host: ${url.host}`,
    'Connection: close',
    'Content-Type: application/json; charset=utf-8',
    `Content-Length: ${body.length}`,
    '',
    body,
  ].join('\n')
}

export function webhookTriggerCurlExample(trigger: WebhookTrigger, url: string) {
  const body = webhookTriggerJSONBody(trigger)

  return [
    `curl -X 'POST' ${JSON.stringify(url)} \\`,
    `     -H 'Content-Type: application/json; charset=utf-8' \\`,
    `     -d $'${body}'`,
  ].join('\n')

}

export function webhookTriggerJSONBody(trigger: WebhookTrigger) {
  const body: AnyObject = {}

  if (!trigger.collective) {
    body.participant = "PARTICIPANT_ID_OR_REF"
  }
  body.params = {}

  return JSON.stringify(body, null, 2)
}

export type WebhookTriggerExampleType =
  | 'raw'
  | 'http'
  | 'curl'

export const WebhookTriggerExampleType: {
  all: WebhookTriggerExampleType[]
} = {
  all: ['raw', 'http', 'curl'],
}