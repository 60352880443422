import { Fetch } from 'mobx-document'
import { CustomImage } from '~/models'
import { SubmitResult } from '~/ui/form'

export interface RegistrationWidgetModel {
  status: RegistrationWidgetModelStatus
  errorReason: PreflightErrorReason

  availableProjects(): Fetch<ProjectInfo[]>
  availableGroups(projectID: string): Fetch<GroupInfo[]>

  preflight(code?: string | null): Promise<PreflightResponse | undefined>
  register(data: RegistrationData): Promise<SubmitResult | undefined>

  image(name: string, variant?: string | null): CustomImage | null
  translate(key: RegistrationTranslationKey, language?: string): string | null
}

export type PreflightResponse = 'ok' | PreflightErrorReason

export type RegistrationWidgetModelStatus =
  | 'preflight'
  | 'code'
  | 'register'
  | 'registered'
  | 'error'

export type PreflightErrorReason =
  | 'invalid-code'
  | 'invalid-origin'
  | 'project-gone'
  | 'preset-gone'
  | 'registration-closed'
  | 'other'

export enum RegistrationTranslationKey {
  codePrompt      = 'code.prompt',
  codePlaceholder = 'code.placeholder',
  codeButton      = 'code.button',

  fieldsPrompt       = 'fields.prompt',
  projectCaption     = 'fields.project.caption',
  projectPlaceholder = 'fields.project.placeholder',
  groupCaption       = 'fields.group.caption',
  groupPlaceholder   = 'fields.group.placeholder',
  fieldsButton       = 'fields.button',

  confirmationTitle = 'confirmation.title',
  confirmationBody  = 'confirmation.body',

  errorInvalidCode        = 'errors.invalid-code',
  errorInvalidOrigin      = 'errors.invalid-origin',
  errorRegistrationClosed = 'errors.registration-closed',
  errorOther              = 'errors.other',
}

export interface ProjectInfo {
  id:   string
  name: string
}

export interface GroupInfo {
  id:        string
  projectID: string
  name:      string
}

export interface RegistrationData {
  firstName: string
  lastName:  string | null
  email:     string
  project:   string | null
  group:     string | null
}