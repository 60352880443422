import { action, computed, makeObservable, observable } from 'mobx'
import { FetchStatus } from 'mobx-document'
import { AnalyticsService, WidgetState } from '~/stores'
import WidgetFilterValueSet from './WidgetFilterValueSet'

export default class WidgetDataEndpoint {

  constructor(
    public readonly service: AnalyticsService,
    public readonly baseState: WidgetState,
  ) {
    makeObservable(this)
  }

  @observable
  private overrides: Partial<WidgetState> = {data: []}

  @observable
  public fetchStatus: FetchStatus = 'idle'

  @computed
  public get state(): WidgetState {
    return {
      ...this.baseState,
      ...this.overrides,
    }
  }

  //------
  // Filters

  public readonly filterValues = new WidgetFilterValueSet(
    this.baseState.alotmentUUID,
    this.baseState.filters,
    this.invalidate.bind(this),
  )

  @action
  private invalidate() {
    this.overrides = {data: []}
    this.fetchStatus = 'idle'
  }

  //------
  // Fetching

  private fetchPromise: Promise<boolean> | null = null

  @action
  public async fetch() {
    if (this.fetchPromise != null) {
      return await this.fetchPromise
    }

    this.fetchStatus = 'fetching'

    const promise = this.service.fetchWidgetData(this.state.alotmentUUID, this.filterValues.values)
      .then(action(response => {
        this.fetchStatus = 'done'
        this.fetchPromise = null
        if (!response.ok) { return false }

        this.overrides = {
          data: response.body.data,
          ...response.body.meta,
        }
        return true
      }))

    return this.fetchPromise = promise
  }

}