import React from 'react'
import { Endpoint } from 'mobx-document'
import { observer } from '~/ui/component'
import { HBox, Label, LabelProps, Spinner } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useStyling } from '~/ui/styling'

export interface Props<E extends Endpoint<any, any, TotalsMetaShape>> extends Omit<LabelProps, 'children'> {
  endpoint: E
}

export interface TotalsMetaShape {
  offset: number
  count:  number | null
  total:  number
}

const TotalsLabel = observer('TotalsLabel', (props: Props<any>) => {

  const {endpoint, ...rest} = props

  const fetchStatus = endpoint.fetchStatus

  const offset = endpoint.meta?.offset
  const count  = endpoint.meta?.count ?? 0
  const total  = endpoint.meta?.total

  const {t} = useResourceTranslation()

  const {fonts} = useStyling()
  const height = fonts.small.size * fonts.small.lineHeight

  //------
  // Rendering

  function render() {
    if (offset == null || fetchStatus === 'fetching') {
      return (
        <HBox style={{height}}>
          <Spinner size={8}/>
        </HBox>
      )
    }

    const start = offset + 1
    const end   = start + count - 1

    return (
      <Label markup small {...rest} style={{height}}>
        {total == null ? (
          t('pagination_without_total', {
            start: Math.max(0, start),
            end:   Math.max(0, end),
          })
        ) : (
          t('pagination', {
            start: Math.min(Math.max(0, start), total),
            end:   Math.min(Math.max(0, end), total),
            count: total,
          })
        )}
      </Label>
      )
  }

  return render()

})

export default TotalsLabel