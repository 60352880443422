import React from 'react'
import { useLocation } from 'react-router-dom'
import { NavigationItem } from '~/navigation.yml'
import {
  isStandardNavigationItem,
  navigationItemCaption,
  navigationItemDetail,
  navigationItemHref,
  navigationItemIcon,
  navigationItemOnTap,
  navigationStore,
  notificationsStore,
} from '~/stores'
import { memo, observer } from '~/ui/component'
import { Badge, Center, HBox, Label, SVG, Tappable, VBox } from '~/ui/components'
import { badgeSize } from '~/ui/components/Badge'
import { SVGName } from '~/ui/components/SVG'
import { createUseStyles, layout, shadows, ThemeProvider, useStyling } from '~/ui/styling'
import { getNavigationDetailMenuComponent } from './detail'

export interface Props {
  item: NavigationItem
}

const NavigationButton = memo('NavigationButton', (props: Props) => {

  const {item} = props

  const location = useLocation()
  const icon     = navigationItemIcon(item)
  const href     = navigationItemHref(item)
  const onTap    = navigationItemOnTap(item)
  const caption  = navigationItemCaption(item)
  const detail   = navigationItemDetail(item)

  const active     = navigationStore.isActiveItem(item, location.pathname)
  const DetailMenu = isStandardNavigationItem(item) ? getNavigationDetailMenuComponent(item.name) : null

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ThemeProvider primary={active}>
        <HBox align='stretch' classNames={[$.navigationButtonContainer, {active}]}>
          {renderButton()}
          {renderDetailMenu()}
        </HBox>
      </ThemeProvider>
    )
  }

  function renderButton() {
    return (
      <VBox flex>
        <Tappable classNames={$.navigationButton} href={href} onTap={onTap} noFeedback>
          <HBox flex gap={layout.padding.m}>
            {renderIcon()}
            {renderMain()}
            <NavigationItemBadges {...props}/>
          </HBox>
        </Tappable>
      </VBox>
    )
  }

  function renderIcon() {
    if (icon == null) { return null }

    return (
      <SVG
        name={icon}
        size={layout.icon.m}
      />
    )
  }

  function renderMain() {
    return (
      <VBox gap={layout.padding.inline.xs}>
        <Label flex='shrink' small bold>
          {caption}
        </Label>
        {detail != null && (
          <Label flex='grow' dim tiny>
            {detail}
          </Label>
        )}
      </VBox>
    )
  }

  function renderDetailMenu() {
    if (DetailMenu == null) { return }

    return (
      <DetailMenu targetClassNames={$.detailMenu} item={item}>
        {toggle => (
          <Tappable classNames={$.detailMenuButton} flex onTap={toggle} noFeedback>
            <Center flex>
              <SVG name='chevron-right' size={layout.icon.s}/>
            </Center>
          </Tappable>
        )}
      </DetailMenu>
    )
  }

  return render()

})

const NavigationItemBadges = observer('NavigationButtonBadges', (props: Props) => {

  const badgeTypes = props.item.badges ?? []

  const {colors} = useStyling()

  const $ = useStyles()

  function render() {
    if (badgeTypes.length === 0) { return null }

    return (
      <HBox classNames={$.badges}>
        {badgeTypes.map(renderBadge)}
      </HBox>
    )
  }

  function renderBadge(type: string) {
    const value = notificationsStore.badges.get(type)
    if (value == null) { return }

    return (
      <Badge
        key={type}
        icon={value.icon as SVGName}
        color={value.color == null ? undefined : colors.resolve(value.color)}
        value={value.value}
        hideOnEmpty
      />
    )
  }

  return render()

})

export default NavigationButton

const useStyles = createUseStyles(theme => ({
  navigationButtonContainer: {
    borderRadius: layout.radius.m,
    '&.active': {
      background: theme.bg.active,
    },
    '&:hover': {
      background: theme.bg.hover,
    },
  },

  navigationButton: {
    position: 'relative',
    height:   layout.barHeight.m,
    padding:  [layout.padding.inline.xs, layout.padding.inline.m],

    borderRadius: layout.radius.m,
    '&.detailMenu': {
      borderTopRightRadius:    0,
      borderBottomRightRadius: 0,
    },

    '&:focus-visible': {
      boxShadow: shadows.focus.bold(theme),
    },
  },

  badges: {
    position: 'absolute',
    top:      -badgeSize.small.height * 1/3,
    right:    -badgeSize.small.height * 1/3,
  },

  detailMenu: {
    alignSelf: 'stretch',
  },

  detailMenuButton: {
    padding:   layout.padding.inline.s,

    borderRadius: [0, layout.radius.m, layout.radius.m, 0],
    '&:hover': {
      background: theme.bg.hover,
    },
    '&:focus-visible': {
      boxShadow: shadows.focus.bold(theme),
    },
  },
}))