import Logger from 'logger'

const logger = new Logger('GoogleMap')

const available: google.maps.Map[] = []
const inUse:     Set<google.maps.Map> = new Set()

export function obtain(container: HTMLElement, options: google.maps.MapOptions): google.maps.Map | null {
  if (google == null) { return null }

  if (available.length === 0) {
    return create(container, options)
  } else {
    return reuse(available.pop()!, container, options)
  }
}

export function recycle(map: google.maps.Map) {
  if (google == null) { return }

  inUse.delete(map)
  if (map.getDiv() == null) {
    logger.info('Disposed map', {inUse: inUse.size})
  } else {
    google.maps.event.clearInstanceListeners(map.getDiv())
    available.push(map)
    logger.info('Recycled map', {inUse: inUse.size})
  }

  if (inUse.size === 0) {
    cleanUp()
  }
}

export function create(container: HTMLElement, options: google.maps.MapOptions) {
  if (google == null) { return null }

  const element = document.createElement('div')
  Object.assign(element.style, {
    position: 'absolute',
    top:      0,
    right:    0,
    bottom:   0,
    left:     0,
  })
  container.appendChild(element)

  const map = new google.maps.Map(container, options)
  inUse.add(map)

  logger.info('Created map', {inUse: inUse.size})
  return map
}

export function reuse(map: google.maps.Map, container: HTMLElement, options: google.maps.MapOptions) {
  map.setOptions(options)

  container.appendChild(map.getDiv())
  google.maps.event.trigger(map, 'resize')

  inUse.add(map)
  logger.info('Reused map', {inUse: inUse.size})
  return map
}

function cleanUp() {
  if (google == null) { return }
  logger.info("Cleaning up")

  google.maps.event.clearInstanceListeners(window)
  google.maps.event.clearInstanceListeners(document)
}