import React from 'react'
import { BrandedComponentSpec } from '~/models'
import { memo } from '~/ui/component'
import { BrandedComponent, Center, SVG } from '~/ui/components'
import { layout, useTheme } from '~/ui/styling'
import { useBranding } from '../../BrandingContext'
import { useComponentBranding } from '../hooks'
import { registerComponentBrandingPreview } from '../registry'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const CheckBoxBrandingPreview = memo('CheckBoxBrandingPreview', (props: Props) => {

  const {componentName, variant, spec} = props
  const {guide} = useBranding()

  const [component, flags, size] = useComponentBranding(componentName, variant, spec)

  const theme = useTheme()
  const color = !theme.isDark ? theme.semantic.primary : theme.fg.normal

  function render() {
    return (
      <BrandedComponent
        branding={component}
        guide={guide ?? undefined}
        variant={flags}
        style={{...size}}
        height={size.height}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return(
      <Center flex>
        <SVG name='check' size={layout.icon.s} color={color}/>
      </Center>
    )
  }

  return render()

})

registerComponentBrandingPreview('checkBox', CheckBoxBrandingPreview)
registerComponentBrandingPreview('radioButton', CheckBoxBrandingPreview)
export default CheckBoxBrandingPreview