import React from 'react'
import { startCase } from 'lodash'
import { ClientTab, ClientTabIconType, Project } from '~/models'
import { dataStore } from '~/stores'
import { CustomImageField, LocalizedTextField } from '~/ui/app/fields'
import { LinkField } from '~/ui/app/links'
import { observer } from '~/ui/component'
import { HBox, SelectField, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { SVGName } from '~/ui/components/SVG'
import { FormDialog, FormField } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import ClientTabFormModel from './ClientTabFormModel'

export interface Props {
  requestClose: () => any
  projectID: string | null
  index:     number | null
  tab:       ClientTab | null
  open:      boolean
}

const ClientTabForm = observer('TabForm', (props: Props) => {

  const {projectID, index, tab, open, requestClose} = props
  const project = projectID == null ? null : dataStore.get(Project, projectID)

  const {t, fieldCaption, fieldInstruction} = useResourceTranslation('client-tabs')

  const formModel = React.useMemo(
    () => project == null ? null : new ClientTabFormModel(project, index !== null ? index : '$new', tab),
    [index, tab, project],
  )

  const iconChoices = React.useMemo((): Choice[] => (availableIcons.map((icon) => ({
    value:   icon,
    icon:    icon,
    caption: captionForIcon(icon),
  }))), [])

  const iconTypeChoices = React.useMemo(
    (): Choice[] => (ClientTabIconType.all.map(type => ({
      value: type,
      caption: t(`fields.icon_type.choices.${type}`),
    }))
  ), [t])

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }
    return (
      <FormDialog
        requestClose={requestClose}
        title={index !== null ? t('edit') : t('new')}
        open={open}
        model={formModel}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    if (formModel == null) { return null }
    return (
      <VBox gap={layout.padding.s}>
        <FormField name='caption' caption={fieldCaption('caption')} required>
          {bind => <LocalizedTextField {...bind} />}
        </FormField>
        {renderIconField()}
        <FormField name='link' caption={fieldCaption('link')} required>
          {bind => <LinkField {...bind}/>}
        </FormField>
      </VBox>
    )
  }

  function renderIconField() {
    return (
      <HBox gap={layout.padding.s} align='top'>
        <VBox flex>
          <FormField name='iconType' caption={fieldCaption('icon_type')} instruction={fieldInstruction('icon_type')} required>
            {bind => <SelectField {...bind} choices={iconTypeChoices} />}
          </FormField>
        </VBox>
        {formModel?.iconType === 'custom' ? renderImageField() : renderIconDropdown()}
      </HBox>
    )
  }

  function renderImageField() {
    return (
      <FormField name='icon' caption={fieldCaption('image')} required renderAsLabel={false}>
        {bind => (
          <CustomImageField
            {...bind}
            allowedTypes={['svg']}
            size={{width: 116, height:116}}
            objectFit='contain'
          />
        )}
      </FormField>
    )
  }

  function renderIconDropdown() {
    return (
      <FormField name='icon' caption={fieldCaption('icon')} required>
        {bind => <SelectField {...bind} choices={iconChoices} />}
      </FormField>
    )
  }

  return render()

})

export default ClientTabForm

function captionForIcon(name: SVGName): string {
  switch (name) {
    case 'error-full':
      return 'Error'
    case 'plus-circle':
      return 'Plus'
    case 'qrcode':
      return 'QR Code'
    case 'warning-full':
      return 'Warning'
    default:
      return startCase(name.replace('-', ' '))
  }
}

export const availableIcons: SVGName[] = [
  'asterisk',
  'beacon',
  'bell',
  'blank-page',
  'building',
  'calendar',
  'camera',
  'challenge',
  'chat',
  'check',
  'clock',
  'cog',
  'database',
  'deactivate',
  'debug',
  'disk',
  'download',
  'error-full',
  'eye',
  'eye-off',
  'finish-flag',
  'flash',
  'grid',
  'group',
  'handshake',
  'headset',
  'home',
  'image',
  'index-page',
  'iphone',
  'info',
  'link',
  'lock',
  'list',
  'map-pin',
  'map',
  'menu-page',
  'page',
  'palette',
  'paperclip',
  'participant',
  'pencil',
  'pin',
  'planner',
  'plus-circle',
  'puzzle',
  'qrcode',
  'question',
  'robot',
  'satellite',
  'search',
  'star',
  'stopwatch',
  'tag',
  'trash',
  'trophy',
  'unlock',
  'upload',
  'video',
  'warning-full',
  'web',
  'webcast',
]