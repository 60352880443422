import React from 'react'
import { ScrollManager } from 'react-scroll-manager'
import { ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import { Center, HBox, Scroller, SVG, VBox } from '~/ui/components'
import { usePrevious } from '~/ui/hooks'
import { createUseStyles, layout } from '~/ui/styling'
import ChallengeTaskPanel from '../tasks/ChallengeTaskPanel'
import { useChallengeTasksEditor } from '../tasks/ChallengeTasksEditorContext'
import ChallengeIntroductionPanel from './ChallengeIntroductionPanel'

export interface Props {
  renderEmpty:       () => React.ReactNode
  requestCreateTask: (atIndex?: number) => any
  requestEditTask:   (task: ChallengeTask) => any
}

const ChallengeTaskCarousel = memo('ChallengeTaskCarousel', (props: Props) => {

  const {renderEmpty, requestEditTask, requestCreateTask} = props
  const {challenge, tasks} = useChallengeTasksEditor()
  const empty = tasks.length === 0

  const scrollManager = React.useMemo(() => new ScrollManager(), [])
  const previousTasks = usePrevious(tasks)

  React.useEffect(() => {
    if (previousTasks !== undefined && previousTasks.length > 0 && tasks.length > previousTasks.length && tasks[tasks.length - 1].uuid !== previousTasks[previousTasks.length -1].uuid) {
      scrollManager.scrollTo({
        left: scrollManager.maxScrollPosition.left,
        behavior: 'smooth',
      })
    }
  }, [scrollManager, tasks, previousTasks])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <Scroller horizontal contentClassNames={$.challengeTaskCarousel} scrollManager={scrollManager}>
        <HBox gap={layout.padding.s} align='stretch'>
          <VBox>
            {renderIntroductionPanel()}
          </VBox>
          <Center>
            <SVG
              name='chevron-right'
              size={layout.icon.xxxl}
              dimmer
            />
          </Center>
          {empty ? (
            <VBox width={480}>
              {renderEmpty()}
            </VBox>
          ) : (
            tasks.map(renderTaskPanel)
          )}
        </HBox>
      </Scroller>

    )
  }

  function renderIntroductionPanel() {
    if (challenge == null) { return null }
    return (
      <ChallengeIntroductionPanel
        challenge={challenge}
      />
    )
  }

  function renderTaskPanel(task: ChallengeTask) {
    return (
      <VBox key={task.uuid}>
        <ChallengeTaskPanel
          task={task}
          requestEdit={requestEditTask}
          requestCreate={requestCreateTask}
        />
      </VBox>
    )
  }

  return render()

})

export default ChallengeTaskCarousel

const useStyles = createUseStyles({
  challengeTaskCarousel: {
    padding: 4,
  },
})
