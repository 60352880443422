import React from 'react'
import Toast from 'react-toast'
import clipboard from 'rich-clipboard'
import config from '~/config'
import { RegistrationPreset } from '~/models'
import { projectStore } from '~/stores'
import { memo } from '~/ui/component'
import { ClearButton, Dimple, HBox, LinkLabel, VBox } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { SummaryInfo } from '~/ui/resources/detail'
import { layout } from '~/ui/styling'
import { registrationWidgetSize } from '../../widget/RegistrationPanel'

export interface Props {
  preset: RegistrationPreset
}

const RegistrationPresetLink = memo('RegistrationPresetLink', (props: Props) => {

  const {t} = useResourceTranslation()

  const {preset} = props

  const link = config.urls.webRegister(preset.token, projectStore.preferredProjectDomain)

  //------
  // Copying

  const generateEmbedCode = React.useCallback(() => {
    const iframe = document.createElement('iframe')
    iframe.setAttribute('frameborder', '0')
    iframe.setAttribute('scrolling', 'no')
    iframe.setAttribute('src', link)
    iframe.setAttribute('width', `${registrationWidgetSize.width}`)
    iframe.setAttribute('height', `${registrationWidgetSize.height}`)
    iframe.setAttribute('style', `border: none; overflow: hidden; width: ${registrationWidgetSize.width}px; height: ${registrationWidgetSize.height}px;`)

    return iframe.outerHTML
  }, [link])

  const copyLink = React.useCallback(() => {
    clipboard.write([{
      type: 'text/uri-list',
      data: link,
    }, {
      type: 'text/plain',
      data: link,
    }])
    Toast.show({
      type: 'success',
      title: t('link.copy_link.success.title'),
      detail: t('link.copy_link.success.detail'),
    })
  }, [link, t])

  const copyEmbed = React.useCallback(() => {
    const embedCode = generateEmbedCode()

    clipboard.write([{
      type: 'text/html',
      data: embedCode,
    }, {
      type: 'text/plain',
      data: embedCode,
    }])
    Toast.show({
      type: 'success',
      title: t('link.copy_embed.success.title'),
      detail: t('link.copy_embed.success.detail'),
    })
  }, [generateEmbedCode, t])

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon='link'
        summary={t('link.caption')}
        body={renderBody()}
        bold
      />
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.s}>
        <LinkLabel small href={link}>
          {link}
        </LinkLabel>
        {renderCopyButtons()}
      </VBox>
    )
  }

  function renderCopyButtons() {
    return (
      <HBox gap={layout.padding.s}>
        <ClearButton
          caption={t('link.copy_link.caption')}
          onTap={copyLink}
          small
        />
        <Dimple vertical/>
        <ClearButton
          caption={t('link.copy_embed.caption')}
          onTap={copyEmbed}
          small
        />
      </HBox>
    )
  }

  return render()

})

export default RegistrationPresetLink