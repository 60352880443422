import { DateTime } from 'luxon'

export interface AnalyticsResponse {
  data: WidgetState[]
  meta: AnalyticsMeta
}

export interface AnalyticsMeta {
}

//------
// Widget state

export interface WidgetState {
  alotmentUUID: string
  widget:       string
  filters:      Record<string, any>
  series:       DataSeries[]
  config:       WidgetConfig
  data:         DataPoint[]
  error:        boolean
  lastUpdate:   DateTime
}

export type WidgetType = 'gauge' | 'chart'

export interface WidgetConfig {
  showValueLabels?: boolean

  xAxis?:  boolean
  y1Axis?: boolean
  y2Axis?: boolean

  // Different widget types may have additional configuration.
  [key: string]: any
}

export interface DataSeries {
  name:     string
  type:     SeriesType
  valueKey: string
  caption?: string

  axis?:            'y1' | 'y2'
  showValueLabels?: boolean
  showInLegend?:    boolean
}

export type SeriesType = 'column' | 'line'

export interface DataPoint {
  key:        string | number
  label?:     string
  axisLabel?: string | null

  [key: string]: any
}

export const DataPoint: {
  wellKnownKeys: string[]
  valueKeys:     (point: DataPoint) => string[]
} = {
  wellKnownKeys: ['key', 'label', 'axisLabel'],
  valueKeys:     point => Object.keys(point).filter(it => !DataPoint.wellKnownKeys.includes(it)),
}