import React from 'react'
import { Extension } from '~/models'
import { observer } from '~/ui/component'
import { EmptyOrFetching, GridList, HBox, SearchField, TextBlock, VBox } from '~/ui/components'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { useResourceListLocation } from '~/ui/resources/collection'
import { createUseStyles, layout } from '~/ui/styling'
import ExtensionTile from './ExtensionTile'

export interface Props {
  selectedExtension: Extension | null
  onSelect:          (extension: Extension | null) => any
}

const ExtensionCatalog = observer('ExtensionCatalog', (props: Props) => {

  const {selectedExtension, onSelect} = props

  const {t} = useResourceTranslation('extensions')

  const {search, setSearch} = useResourceListLocation('extensions')
  const endpoint  = useModelEndpoint(Extension, {search})

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex gap={layout.padding.m}>
        {renderHeader()}
        <GridList
          contentPadding={layout.padding.m}
          data={endpoint.data}
          columns={3}
          itemGap={layout.padding.s}
          renderCell={renderExtensionTile}
          EmptyComponent={renderEmpty}
          scrollable
        />
      </VBox>
    )
  }

  function renderHeader() {
    return (
      <HBox classNames={$.preamble} gap={layout.padding.m}>
        <TextBlock flex small dim>
          {t('catalog.preamble')}
        </TextBlock>
        <SearchField
          onSearch={setSearch}
          classNames={$.search}
        />
      </HBox>
    )
  }

  function renderExtensionTile(extension: Extension) {
    return (
      <ExtensionTile
        extension={extension}
        selected={extension.name === selectedExtension?.name}
        onTap={onSelect.bind(null, extension)}
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        status={endpoint.fetchStatus}
        {...t('catalog.empty')}
        flex
      />
    )
  }, [endpoint.fetchStatus, t])

  return render()

})

export default ExtensionCatalog

const useStyles = createUseStyles({
  preamble: {
    ...layout.responsive(size => ({
      padding:       layout.padding.m[size],
      paddingBottom: 0,
    })),
  },

  search: {
    width: 240,
  },
})