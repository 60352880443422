import React from 'react'
import { BrandingGuide, ColorGuide } from './branding'
import FontGuide from './branding/FontGuide'

export interface StylingContext {
  guide:  BrandingGuide
  colors: ColorGuide
  fonts:  FontGuide
}

export function createStylingContext(guide: BrandingGuide): StylingContext {
  return {
    guide:  guide,
    colors: guide.colors,
    fonts:  guide.fonts,
  }
}

const defaultContext = createStylingContext(new BrandingGuide())
export const StylingContext = React.createContext<StylingContext>(defaultContext)

export function useStyling() {
  return React.useContext(StylingContext)
}