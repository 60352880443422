import React from 'react'
import ModalPortal from 'react-modal-portal'
import { memo } from '~/ui/component'
import { ModalDialogProps, VBox, VBoxProps } from '~/ui/components'
import { modalDialogFlex } from '~/ui/components/ModalDialog'
import { animation, createUseStyles, layout } from '~/ui/styling'
import { cloneElement } from '~/ui/util'

export interface Props extends VBoxProps {
  flipped:        boolean
  requestUnflip?: () => any

  renderFlipSide?: () => React.ReactNode

  width?:  ModalDialogProps['width']
  height?: ModalDialogProps['height']

  classNames?:         React.ClassNamesProp
  flipBackClassNames?: React.ClassNamesProp

  children?:   React.ReactNode
}

interface FlipLayout {
  elements: {
    container: HTMLElement
    front:     HTMLElement
    back:      HTMLElement
  }

  sourceRect: LayoutRect
  destSize:   Size
}

const FlipModal = memo('FlipModal', (props: Props) => {

  const {
    renderFlipSide,
    flipped,
    requestUnflip,
    width,
    height,
    children,
    flipBackClassNames,
    ...rest
  } = props

  const elementRef       = React.useRef<HTMLDivElement>(null)
  const flipContainerRef = React.useRef<HTMLDivElement>(null)
  const cloneRef         = React.useRef<HTMLElement | null>(null)

  const $ = useStyles()

  const flex = modalDialogFlex(height)

  //------
  // Flipping

  const flipLayoutRef = React.useRef<FlipLayout | null>(null)

  const measureFlipFront = React.useCallback((front: HTMLElement) => {
    // Make the element assume its own size by setting right and bottom to auto temporarily.
    front.style.right = 'auto'
    front.style.bottom = 'auto'

    const {width, height} = front.getBoundingClientRect()

    // Reset the element.
    front.style.right = ''
    front.style.bottom = ''

    return {width, height}

  }, [])

  const calculateFlipLayout = React.useCallback((): FlipLayout | null => {
    if (flipLayoutRef.current != null) {
      return flipLayoutRef.current
    }

    const element   = elementRef.current
    const container = flipContainerRef.current
    if (element == null || container == null) { return null }

    const front = container.querySelector(`.${$.flipFront}`) as HTMLElement
    const back  = container.querySelector(`.${$.flipBack}`) as HTMLElement

    const sourceRect = element.getBoundingClientRect()
    const destSize   = measureFlipFront(front)

    const flipLayout: FlipLayout = {
      elements: {
        container,
        front,
        back,
      },

      sourceRect: sourceRect,
      destSize:   destSize,
    }

    flipLayoutRef.current = flipLayout
    return flipLayout
  }, [$.flipBack, $.flipFront, measureFlipFront])

  const applyFlipStyles = React.useCallback((forwards: boolean) => {
    const flipLayout = calculateFlipLayout()
    if (flipLayout == null) { return }

    const {container, front, back} = flipLayout.elements
    const {sourceRect, destSize} = flipLayout

    const scaleX = destSize.width / sourceRect.width
    const scaleY = destSize.height / sourceRect.height

    // Fix the widths of the front and back panel.
    Object.assign(container.style, {
      position: 'absolute',
    })
    Object.assign(front.style, {
      position:   'absolute',
      top:        '50%',
      left:       '50%',
      width:      `${destSize.width}px`,
      height:     `${destSize.height}px`,
      marginLeft: `${-destSize.width / 2}px`,
      marginTop:  `${-destSize.height / 2}px`,
    })
    Object.assign(back.style, {
      position:   'absolute',
      top:        '50%',
      left:       '50%',
      width:      `${sourceRect.width}px`,
      height:     `${sourceRect.height}px`,
      marginLeft: `${-sourceRect.width / 2}px`,
      marginTop:  `${-sourceRect.height / 2}px`,
    })

    if (forwards) {
      const portalRect = container.parentElement!.getBoundingClientRect()
      const centerX    = portalRect.left + portalRect.width / 2
      const centerY    = portalRect.top + portalRect.height / 2

      Object.assign(container.style, {
        left:   `${centerX - destSize.width / 2}px`,
        top:    `${centerY - destSize.height / 2}px`,
        width:  `${destSize.width}px`,
        height: `${destSize.height}px`,
      })

      Object.assign(front.style, {
        transform: 'rotateY(0) scale(1)',
      })

      Object.assign(back.style, {
        transform: `rotateY(-180deg) scaleX(${scaleX}) scaleY(${scaleY})`,
      })
    } else {
      Object.assign(container.style, {
        left:   `${sourceRect.left}px`,
        top:    `${sourceRect.top}px`,
        width:  `${sourceRect.width}px`,
        height: `${sourceRect.height}px`,
      })

      Object.assign(front.style, {
        transform: `rotateY(180deg) scaleX(${1/scaleX}) scaleY(${1/scaleY})`,
      })

      Object.assign(back.style, {
        transform: 'rotateY(0) scale(1)',
      })
    }
  }, [calculateFlipLayout])

  const cleanupFlipStyles = React.useCallback(() => {
    const flipLayout = calculateFlipLayout()
    if (flipLayout == null) { return }

    const {container, front} = flipLayout.elements

    Object.assign(container.style, {
      position: '',
      left:     '',
      top:      '',
      width:    '',
      height:   '',
    })

    Object.assign(front.style, {
      position:   '',
      top:        '',
      left:       '',
      width:      '',
      height:     '',
      marginTop:  '',
      marginLeft: '',
      transform:  '',
    })
  }, [calculateFlipLayout])

  const createClone = React.useCallback(() => {
    const back  = flipContainerRef.current?.querySelector(`.${$.flipBack}`) as HTMLElement
    if (!(back instanceof HTMLElement)) { return }

    const element = elementRef.current?.childNodes[0]
    if (!(element instanceof HTMLElement)) { return }

    const clone = cloneElement(element)

    cloneRef.current = clone
    back.appendChild(clone)
  }, [$.flipBack])

  const destroyClone = React.useCallback(() => {
    cloneRef.current?.remove()
    cloneRef.current = null
  }, [])

  const setCloneVisible = React.useCallback((visible: boolean) => {
    const clone = cloneRef.current
    if (clone == null) { return }

    clone.style.visibility = visible ? '' : 'hidden'
  }, [])

  const setContentVisible = React.useCallback((visible: boolean) => {
    const element = elementRef.current
    if (element == null) { return }

    element.style.visibility = visible ? '' : 'hidden'
  }, [])

  const prepareTransition = React.useCallback((enter: boolean) => {
    const element = elementRef.current
    if (element == null) { return }

    if (enter) {
      createClone()
      setContentVisible(false)
    } else {
      setCloneVisible(true)
    }
    applyFlipStyles(!enter)
  }, [applyFlipStyles, createClone, setCloneVisible, setContentVisible])

  const commitTransition = React.useCallback((enter: boolean) => {
    applyFlipStyles(enter)
  }, [applyFlipStyles])

  const cleanUpTransition = React.useCallback((enter: boolean) => {
    if (enter) {
      cleanupFlipStyles()
      setCloneVisible(false)
    } else {
      destroyClone()
      setContentVisible(true)
    }
    flipLayoutRef.current = null
  }, [cleanupFlipStyles, destroyClone, setCloneVisible, setContentVisible])

  //------
  // Rendering

  function render() {
    return (
      <>
        <VBox {...rest} ref={elementRef}>
          {React.Children.only(children)}
        </VBox>
        {renderFlipPortal()}
      </>
    )
  }

  function renderFlipPortal() {
    return (
      <ModalPortal
        classNames={$.flipPortal}
        open={flipped}
        requestClose={requestUnflip}
        transitionName={$.flipPortalAnim}
        transitionDuration={flipDuration}
        onPrepareTransition={prepareTransition}
        onCommitTransition={commitTransition}
        onCleanUpTransition={cleanUpTransition}
        children={renderFlipSideModal()}
      />
    )
  }

  function renderFlipSideModal() {
    return (
      <VBox classNames={[$.flipContainer, {center: width !== 'max'}]} ref={flipContainerRef}>
        <VBox classNames={$.flipFront} flex={flex}>
          {renderFlipSide?.()}
        </VBox>
        <div classNames={[$.flipBack, flipBackClassNames]}/>
      </VBox>
    )
  }

  return render()

})

export default FlipModal

export const flipDuration = animation.durations.long

const useStyles = createUseStyles({
  flipPortal: {
    ...layout.flex.center,
    zIndex: layout.z.modal,
  },

  flipContainer: {
    ...layout.overlay,
    ...layout.flex.column,

    ...layout.responsiveProp({
      padding: layout.padding.xl,
    }),

    '&.center': {
      alignItems: 'center',
    },

    perspective:    2000,
    transformStyle: 'preserve-3d',
  },

  flipPortalAnim: {
    '&-open-active': {
      transition: animation.transition(['left', 'top', 'width', 'height'], flipDuration),
    },
    '&-open-active > div': {
      transition: animation.transition(['transform'], flipDuration),
    },
    '&-close-active': {
      transition: animation.transition(['left', 'top', 'width', 'height'], flipDuration),
    },
    '&-close-active > div': {
      transition: animation.transition(['transform'], flipDuration),
    },
  },

  flipBack: {
    ...layout.flex.column,
    backfaceVisibility: 'hidden',
    pointerEvents:      'none',

    // Bug in Chrome: a text area will show its backface always.
    '& textarea': { visibility: 'hidden' },
  },

  flipFront: {
    ...layout.flex.column,
    backfaceVisibility: 'hidden',
  },

})