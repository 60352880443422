import React from 'react'
import { safeParseInt } from 'ytil'
import { Competition, Scoring } from '~/models'
import { memo } from '~/ui/component'
import {
  CheckboxField,
  CollectionField,
  HBox,
  ItemFormProps,
  NumberField,
  SelectField,
  TextBlock,
  TextField,
  VBox,
} from '~/ui/components'
import { FormDialog, FormDialogProps, FormField, FormFieldHeader } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'
import ScoringFormModel from './ScoringFormModel'

export interface Props extends Omit<FormDialogProps<ScoringFormModel>, 'children'> {
  competitionField?: boolean
}

const ScoringForm = memo('ScoringForm', (props: Props) => {

  const {t} = useResourceTranslation('scoring')

  const {
    model,
    competitionField = true,
    title = t('form.title'),
    ...rest
  } = props

  const newItemTemplate = React.useCallback(() => ({
    pattern:     '',
    competition: null,
    score:       null,
  }) as any, [])

  const answerChoices = React.useMemo(() => [
    {value: null, caption: ''},
    ...model.answerChoices,
  ], [model.answerChoices])

  //------
  // Rendering

  function render() {
    return (
      <FormDialog
        model={model}
        children={renderFields()}
        icon='trophy'
        title={title}
        width={720}
        height={560}
        semi={false}
        {...rest}
      />
    )
  }

  function renderFields() {
    return (
      <ResourceTypeProvider resourceType='scoring'>
        <VBox gap={layout.padding.m}>
          <TextBlock small dim>
            {t('form.instructions')}
          </TextBlock>
          <FormField name='scorings' caption={false}>
            {bind => (
              <CollectionField
                {...bind}
                renderHeader={renderHeader}
                renderForm={renderScoringForm}
                newItemTemplate={newItemTemplate}
              />
            )}
          </FormField>
        </VBox>
      </ResourceTypeProvider>
    )
  }

  function renderHeader() {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex={4}>
          {model.feedback.type === 'text' ? (
            <FormFieldHeader name='pattern'/>
          ) : (
            <FormFieldHeader name='answer'/>
          )}
        </VBox>

        {competitionField && (
          <VBox flex={3}>
            <FormFieldHeader name='competition'/>
          </VBox>
        )}
        <VBox flex={2}>
          <FormFieldHeader name='score'/>
        </VBox>
        <VBox width={60}>
          <FormFieldHeader name='correct'/>
        </VBox>
      </HBox>
    )
  }

  function renderScoringForm(props: ItemFormProps<Scoring>) {
    return (
      <HBox gap={layout.padding.s}>
        <VBox flex={4}>
          {model.feedback.type === 'text' ? (
            <TextField {...props.bind('pattern')} mono/>
          ) : model.feedback.type === 'numeric' ? (
            <NumberField {...props.bind('pattern')} value={safeParseInt(props.bind('pattern').value)} step={1} minimum={model.feedback.min} maximum={model.feedback.max}/>
          ) : model.feedback.type === 'button' || model.feedback.type === 'choice' ? (
            <SelectField {...props.bind('pattern')} choices={answerChoices}/>
          ) : (
            null
          )}
        </VBox>

        {competitionField && (
          <VBox flex={3}>
            <ResourceField Model={Competition} {...props.bind('competition')}/>
          </VBox>
        )}
        <VBox flex={2}>
          <NumberField {...props.bind('score')} minimum={null} step={10}/>
        </VBox>
        <VBox width={60}>
          <CheckboxField {...props.bind('correct')}/>
        </VBox>
      </HBox>
    )
  }

  return render()

})

export default ScoringForm