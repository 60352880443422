import React from 'react'
import { ChallengeTask, LocalizedString } from '~/models'
import { RichTextBodyEditor } from '~/ui/app/fields'
import { memo } from '~/ui/component'
import { PushButton } from '~/ui/components'
import { SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'

export interface Props {
  task:        ChallengeTask
  editing:     boolean

  requestStartEdit: () => any
  requestStopEdit:  () => any

  requestSave: (data: AnyObject) => Promise<SubmitResult | undefined>
}

const ChallengeTaskBody = memo('ChallengeTaskBody', (props: Props) => {

  const {t} = useResourceTranslation('challenges')
  const {task, requestSave, editing, requestStartEdit, requestStopEdit} = props

  const commit = React.useCallback((body: string | LocalizedString | null) => {
    requestSave({

      body: LocalizedString.from(body),
    })
  }, [requestSave])

  return (
    <RichTextBodyEditor
      value={task.body}
      localized
      onCommit={commit}
      editing={editing}
      requestStartEdit={requestStartEdit}
      requestStopEdit={requestStopEdit}
      scrollable
      empty={{
        ...t('task_body_empty'),
        children: renderButton(),
      }}
    />
  )

  function renderButton() {
    return (
      <PushButton
        caption={t('task_body_empty.button')}
        onTap={requestStartEdit}
      />
    )
  }

})

export default ChallengeTaskBody