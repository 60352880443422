import React from 'react'
import { Variant } from '~/models'
import { VariantField as VariantFieldComponent } from '~/ui/app/custom-data'
import { memo } from '~/ui/component'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { VariantField } from '../types'

export interface Props {
  field: VariantField
  bind:  FormFieldBindProps<Variant>
}

const VariantAllowance = memo('VariantAllowance', (props: Props) => {

  const {bind} = props

  //------
  // Rendering

  function render() {
    return (
      <VariantFieldComponent
        {...bind}
      />
    )
  }

  return render()

})

export default VariantAllowance