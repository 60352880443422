import React from 'react'
import { SelectionManager, SelectionManagerProvider } from 'react-selection-manager'
import { Competition, Ranking, Score } from '~/models'
import { ModelEndpoint, Sort } from '~/stores'
import { observer } from '~/ui/component'
import { DataGrid, EmptyOrFetching, HBox, Label, VBox } from '~/ui/components'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { BulkActionsBar } from '~/ui/resources/collection'
import CheckboxCell, { checkboxCellWidth } from '~/ui/resources/collection/CheckboxCell'
import { layout } from '~/ui/styling'
import ChipCell from '../../../resources/collection/cells/ChipCell'
import RankingBulkActions from './RankingBulkActions'
import RankingNameCell from './RankingNameCell'

export interface Props {
  competition: Competition
  endpoint:    ModelEndpoint<Ranking>
}

const RankingList = observer('RankingList', (props: Props) => {

  const {competition, endpoint} = props
  const {t, fieldCaption} = useResourceTranslation('rankings')

  const rankings    = endpoint.data
  const fetchStatus = endpoint.fetchStatus

  const [sort, setSort] = React.useState<Sort | null>({
    field:     competition.normalize != null ? 'normalized' : 'score',
    direction: -1,
  })

  const reload = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  const fetchMore = React.useCallback(() => {
    endpoint.fetchMore()
  }, [endpoint])

  React.useEffect(() => {
    endpoint.setParams({
      sorts: sort == null ? [] : [sort],
    })
  }, [endpoint, sort])

  const defaultSortDirection = React.useCallback((field: string) => {
    if (field === 'score' || field === 'normalized') {
      return -1
    } else {
      return 1
    }
  }, [])

  const selectionManager = React.useMemo(
    () => new SelectionManager(),
    [],
  )

  //------
  // Rendering

  function render() {
    return (
      <ResourceTypeProvider resourceType='rankings'>
        <SelectionManagerProvider manager={selectionManager}>
          <HBox align='stretch' flex gap={layout.padding.m}>
            <VBox flex={2} gap={layout.padding.inline.m}>
              {renderList()}
              {renderSelectionBar()}
            </VBox>
            {selectionManager.selectedKeys.length > 0 && (
              <VBox flex={1}>
                <RankingBulkActions
                  competition={competition}
                  afterSubmit={reload}
                />
              </VBox>
            )}
          </HBox>
        </SelectionManagerProvider>
      </ResourceTypeProvider>
    )
  }

  function renderList() {
    return (
      <DataGrid
        data={rankings}
        keyForItem={ranking => ranking.id}
        EmptyComponent={renderEmpty}
        onEndReached={fetchMore}
        onItemTap={ranking => selectionManager.toggle(ranking.id)}
        sort={sort}
        requestSort={setSort}
        defaultSortDirection={defaultSortDirection}
      >
        <DataGrid.Column<Ranking>
          name='$checkbox'
          width={checkboxCellWidth}
          padded={false}
          renderCell={model => <CheckboxCell id={model.id}/>}
          renderHeaderCell={() => <CheckboxCell id={null}/>}
        />
        <DataGrid.Column<Ranking>
          name='name'
          sort={true}
          caption={fieldCaption('name')}
          renderCell={ranking => <RankingNameCell ranking={ranking}/>}
          flex
        />
        <DataGrid.Column<Ranking>
          name='score'
          sort={true}
          caption={fieldCaption('score')}
          renderCell={ranking => renderScoreLabel(ranking.score)}
          width={120}
        />
        {competition.normalize != null && (
          <DataGrid.Column<Ranking>
            name='normalized'
            sort={true}
            caption={fieldCaption('normalized')}
            renderCell={ranking => ranking.normalized == null ? null : renderScoreLabel(ranking.normalized)}
            width={120}
          />
        )}
        {competition.normalize != null && (
          <DataGrid.Column<Ranking>
            name='count'
            sort={true}
            caption={fieldCaption('count')}
            renderCell={ranking => <ChipCell>{ranking.count}</ChipCell>}
            width={120}
          />
        )}
      </DataGrid>
    )
  }

  function renderScoreLabel(score: Score) {
    return (
      <Label markup>
        {`***${score.formatted}***`}
      </Label>
    )
  }

  function renderSelectionBar() {
    return (
      <BulkActionsBar
        endpoint={endpoint}
        actions={[]}
        paginated={false}
      />
    )
  }

  const renderEmpty = React.useCallback(() => {
    return (
      <EmptyOrFetching
        flex='grow'
        status={fetchStatus}
        {...t('rankings.empty')}
      />
    )
  }, [fetchStatus, t])

  return render()

})

export default RankingList