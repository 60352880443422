import React from 'react'
import { useTranslation } from 'react-i18next'
import { computed, makeObservable, observable } from 'mobx'
import { PlanComponent } from '~/models'
import { FlowPlanner } from '~/stores'
import { memo } from '~/ui/component'
import { TextField } from '~/ui/components'
import { FormDialog, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useFlowPlanner } from './FlowPlannerContext'

export interface Props {
  open:         boolean
  requestClose: () => any

  components: PlanComponent[]
}

const ExtractModuleForm = memo('ExtractModuleForm', (props: Props) => {

  const {open, requestClose, components} = props

  const {planner} = useFlowPlanner()
  const formModel = React.useMemo(
    () => planner == null ? null : new ExtractModuleFormModel(planner, components),
    [components, planner],
  )

  const [t] = useTranslation('flow_planner')

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog
        open={open}
        requestClose={requestClose}
        model={formModel}
        icon='puzzle'
        title={t('extract_module.caption')}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <FormField name='name'>
        {bind => <TextField {...bind}/>}
      </FormField>
    )
  }

  return render()

})

export default ExtractModuleForm

class ExtractModuleFormModel implements FormModel {

  constructor(
    private readonly planner:    FlowPlanner,
    private readonly components: PlanComponent[],
  ) {
    makeObservable(this)
  }

  @observable
  public name: string = ''

  @computed
  public get maySubmit() {
    return this.name.trim().length > 0
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return this.planner.extractModule(this.name, this.components)
  }

}