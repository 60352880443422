import React from 'react'
import { FeedbackFormModel } from '~/ui/app/feedback'
import { observer } from '~/ui/component'
import { HBox, NumberField, SwitchField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import FeedbackChoicesField from './FeedbackChoicesField'

const ChoiceFeedbackFields = observer('ChoiceFeedbackFields', () => {

  const {model} = useForm<FeedbackFormModel>()

  return (
    <VBox gap={layout.padding.s}>
      <FormField name='choices' required>
        {bind => <FeedbackChoicesField {...bind}/>}
      </FormField>

      {model.multiSelectAllowed && (
        <FormField name='multiSelect'>
          {bind => <SwitchField {...bind}/>}
        </FormField>
      )}

      {model.multiSelect && (
        <VBox gap={layout.padding.s}>
          <HBox gap={layout.padding.s}>
            <VBox flex>
              <FormField name='minAnswers' required>
                {bind => <NumberField {...bind} minimum={0} maximum={model.maxAnswers} step={1}/>}
              </FormField>
            </VBox>
            <VBox flex>
              <FormField name='maxAnswers' required>
                {bind => <NumberField {...bind} minimum={model.minAnswers} step={1}/>}
              </FormField>
            </VBox>
          </HBox>
          <FormField name='splitAnswers' required>
            {bind => <SwitchField {...bind}/>}
          </FormField>
        </VBox>
      )}
    </VBox>
  )
})

export default ChoiceFeedbackFields