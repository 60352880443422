import React from 'react'
import { ManualTrigger } from '~/models'
import { memo } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { useFlowPlanner } from '../../FlowPlannerContext'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: ManualTrigger
}

const ManualTriggerNodeView = memo('ManualTriggerNodeView', (props: Props) => {

  const {node} = props
  const {planner} = useFlowPlanner()
  const {t} = useResourceTranslation('flow_planner')

  const viewLink = React.useCallback(() => {
    planner.viewNodeDetail(node.uuid)
  }, [node.uuid, planner])

  const extraMenuItems = React.useMemo((): PopupMenuItem[] => [{
    icon:     'link',
    caption:  t('triggers.actions.view_link'),
    keyHint:  'doubleclick',
    onSelect: viewLink,
  }], [t, viewLink])

  return (
    <TriggerNodeView
      {...props}
      icon='flash'
      extraMenuItems={extraMenuItems}
      onDoubleClick={viewLink}
    />
  )
})

register('manual', ManualTriggerNodeView)