import React from 'react'
import { useTranslation } from 'react-i18next'
import LocationField from '~/ui/app/locations/LocationField'
import { memo } from '~/ui/component'
import { FormField } from '~/ui/form'

const LocationTriggerFields = memo('LocationTriggerFields', () => {

  const [t] = useTranslation('flow_planner')

  return (
    <FormField
      name='location'
      caption={t('triggers.location.fields.location.caption')!}
      renderAsLabel={false}
      required
    >
      {bind => (
        <LocationField
          {...bind}
          showMap
        />
      )}
    </FormField>
  )

})

export default LocationTriggerFields