import { CalendarDay } from '~/models'
import { CalendarPlanner } from '~/stores'
import { FormModel, SubmitResult } from '~/ui/form'
import DaySelectorFormModel from '../../days/DaySelectorFormModel'

export default class CalendarDayFormModel implements FormModel {

  constructor(
    private readonly planner: CalendarPlanner,
    private readonly day: CalendarDay | null,
  ) {}

  public selectorModel = new DaySelectorFormModel(this.day?.selector ?? {type: 'offset', offset: 0})

  public async submit(): Promise<SubmitResult | undefined> {
    const selector = this.selectorModel.buildSelector()
    if (this.day == null) {
      return await this.planner.addDay(selector)
    } else {
      return await this.planner.updateDay(this.day.uuid, day => ({...day, selector}))
    }
  }

}