import React from 'react'
import { User } from '~/models'
import { Avatar } from '~/ui/app/media'
import { observer } from '~/ui/component'
import { useModelDocumentData } from '~/ui/hooks/data'
import { ResourceBar } from '~/ui/resources/components'

export interface Props {
  id: string
}

const UserCell = observer('UserCell', (props: Props) => {

  const [user] = useModelDocumentData(User, props.id, {
    detail: false,
  })

  //------
  // Rendering

  function render() {
    if (user == null) { return null }

    return (
      <ResourceBar
        model={user}
        image={renderAvatar(user)}
        compact
      />
    )
  }

  function renderAvatar(user: User) {
    return (
      <Avatar
        source={user.photoURL}
        firstName={user.name}
        size={avatarSize}
      />
    )
  }

  return render()

})

export default UserCell

export const avatarSize = {
  width:  32,
  height: 32,
}