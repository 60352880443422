import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { memo } from '~/ui/component'
import { HBox, SegmentedButton, VBox } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'
import ExploreAnswersScreen from './explore/ExploreAnswersScreen'
import AnswersQueriesScreen from './queries/list/AnswersQueriesScreen'

export interface Params {
  scopeID?:   string
  scopeType?: string
}

export type Props = RouteComponentProps<Params>

export type View = 'explore' | 'queries'

const AnswersScreen = memo('AnswersScreen', (props: Props) => {

  const [t] = useTranslation('answers')
  const history = useHistory()

  //------
  // Navigation

  const currentView = props.location.pathname.startsWith('/answers-queries') ? 'queries' : 'explore'

  const handleViewChange = React.useCallback((view: View) => {
    if (currentView === view) { return }
    if (view === 'explore') {
      history.push('/answers')
    } else {
      history.push('/answers-queries')
    }
  }, [currentView, history])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return(
      <VBox flex gap={layout.padding.s} classNames={$.container}>
        {renderViewToggle()}
        {currentView === 'explore' ? (
          <ExploreAnswersScreen />
        ) : (
          <AnswersQueriesScreen />
        )}
      </VBox>
    )
  }

  function renderViewToggle() {
    return(
      <HBox>
        <SegmentedButton
          segments={[{
            value:   'explore',
            icon:    'search',
            caption: t('views.explore'),
          }, {
            value:   'queries',
            icon:    'pin',
            caption: t('views.queries'),
          }]}
          selectedValue={currentView}
          onChange={handleViewChange}
        />
      </HBox>
    )
  }

  return render()

})

export default AnswersScreen

const useStyles = createUseStyles({
  container: {
    ...layout.responsive(size => ({
      paddingTop: layout.padding.m[size],
    })),
  },
})