const formatFunctions: Record<string, FormatFunctionFactory> = {
  // @index: ...require(${relpath}),
  ...require('./amount'),
  ...require('./casing'),
  ...require('./date'),
  ...require('./interval'),
  ...require('./list'),
  ...require('./numbers'),
  ...require('./wrapping'),
  // /index
}

export type FormatFunctionFactory = (...options: any[]) => FormatFunction
export type FormatFunction = (value: any, lng: string | undefined) => string

export default function parseFormatSpec(spec: string) {
  const match = spec.match(/^(\w+?)\s*\((.*)\)$/)
  if (match != null) {
    const name = match[1]
    const args = match[2].split(',').map(arg => {
      try {
        return JSON.parse(arg.trim())
      } catch {
        return arg.trim()
      }
    })

    return formatFunctions[name]?.(...args)
  } else {
    return formatFunctions[spec]?.()
  }
}