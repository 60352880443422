import React from 'react'
import { Group, GroupSignup } from '~/models'
import { formatWidgetParams } from '~/ui/app/widgets'
import { memo } from '~/ui/component'
import { CopyToClipboard, ModalDialog, TextBlock, VBox } from '~/ui/components'
import { FormFieldHeader } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceField } from '~/ui/resources/components'
import { layout } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  signup: GroupSignup
}

const GroupSignupWidgetDialog = memo('GroupSignupWidgetDialog', (props: Props) => {

  const {open, requestClose, signup} = props

  const {t} = useResourceTranslation('group-signups')

  const [groupID, setGroupID] = React.useState<string | null>(null)

  const widget = React.useMemo(() => {
    const params = formatWidgetParams({
      group:  groupID,
      signup: signup.id,
    }, {
      multiline: true,
    })

    return `$[signup](\n${params}\n)`
  }, [groupID, signup.id])

  const groupFieldFilters = React.useMemo((): Record<string, string | null> => {
    if (signup.tag == null) {
      return {}
    } else {
      return {tags: signup.tag}
    }
  }, [signup.tag])

  //------
  // Rendering

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}

        width={560}
        headerRight='$close'

        title={t('widget.title')}
        children={renderContent()}
      />
    )
  }

  function renderContent() {
    return (
      <VBox gap={layout.padding.m} padding={layout.padding.m}>
        <TextBlock small dim>
          {t('widget.preamble')}
        </TextBlock>

        <VBox gap={layout.padding.inline.m}>
          <FormFieldHeader caption={t('widget.group')}/>
          <ResourceField
            Model={Group}
            value={groupID}
            filters={groupFieldFilters}
            onChange={setGroupID}
          />
        </VBox>

        <CopyToClipboard
          value={widget}
          multiline
          height={160}
          mono
        />
      </VBox>
    )
  }

  return render()

})

export default GroupSignupWidgetDialog