import React from 'react'
import { ImageView } from '~/ui/app/media'
import { memo } from '~/ui/component'

export interface Props {
  size?: Size
}

const AuthLogo = memo('AuthLogo', (props: Props) => {

  const {
    size = authLogoSize,
  } = props

  function render() {
    return (
      <ImageView
        source='/images/authlogo.png'
        densities={[1, 2, 3]}
        size={size}
      />
    )
  }

  return render()

})

export default AuthLogo

export const authLogoSize = {
  width:  128,
  height: 128,
}