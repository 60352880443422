import React from 'react'
import { FeedbackFormModel } from '~/ui/app/feedback'
import { observer } from '~/ui/component'
import { HBox, NumberField, TextField, VBox } from '~/ui/components'
import { FormField, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import NumericFeedbackCaptionsField from './NumericFeedbackCaptionsField'

const NumericFeedbackFields = observer('NumericFeedbackFields', () => {

  const {model} = useForm<FeedbackFormModel>()

  return (
    <VBox gap={layout.padding.s}>
      <HBox gap={layout.padding.s}>
        <VBox flex>
          <FormField name='min' required>
            {bind => (
              <NumberField
                {...bind}
                minimum={null}
                maximum={model.max ?? undefined}
                step={model.step ?? undefined}
              />
            )}
          </FormField>
        </VBox>
        <VBox flex>
          <FormField name='max' required>
            {bind => (
              <NumberField
                {...bind}
                minimum={model.min ?? undefined}
                step={model.step ?? undefined}
              />
            )}
          </FormField>
        </VBox>
      </HBox>
      <FormField name='step'>
        {bind => <NumberField {...bind}/>}
      </FormField>
      <FormField name='emoji'>
        {bind => <TextField {...bind} maxLength={1}/>}
      </FormField>
      {model.step != null && (
        <FormField name='captions'>
          {bind => (
            <NumericFeedbackCaptionsField
              {...bind}
              min={model.min}
              max={model.max}
              step={model.step}
            />
          )}
        </FormField>
      )}
    </VBox>
  )
})

export default NumericFeedbackFields