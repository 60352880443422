import React from 'react'
import { DragLayer, DragLayerProps } from 'react-dnd'
import { DraggableItemType, DraggableWidgetItem } from '~/dnd'
import { memo } from '~/ui/component'
import WidgetGhost from './dashboard/WidgetGhost'

export type Props = Omit<DragLayerProps<DraggableWidgetItem>, 'renderGhost'>

const AppDragLayer = memo('AppDragLayer', (props: Props) => {

  function render() {
    return (
      <DragLayer
        renderGhost={renderGhost}
        {...props}
      />
    )
  }

  function renderGhost(item: DraggableWidgetItem) {
    if (item.type === DraggableItemType.WIDGET) {
      return renderWidget(item)
    } else {
      return null
    }
  }

  function renderWidget(item: DraggableWidgetItem) {
    return (
      <WidgetGhost
        widget={item.widget}
        fits={item.fits}
        onDashboard={item.onDashboard}
        {...props}
      />
    )
  }



  return render()

})

export default AppDragLayer