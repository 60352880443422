import React from 'react'
import { IndexItemType, Page } from '~/models'
import { dataStore, projectStore } from '~/stores'
import { LocalizedTextField } from '~/ui/app/fields'
import ModuleField from '~/ui/app/modules/ModuleField'
import { observer } from '~/ui/component'
import { ClearButton, SelectField, TextField, Tooltip, VBox } from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { useResourceTranslation } from '~/ui/resources'
import { layout, useStyling } from '~/ui/styling'
import { usePagesContext } from '../PagesContext'
import PageFormModel from './PageFormModel'

export interface Props extends Omit<FormDialogProps<PageFormModel>, 'children' | 'model'> {
  type:              Page['type']
  pageID?:           string | null
  defaults?:         Partial<Page>
  parentMenuPageID?: string
  root?:             boolean
}

const PageForm = observer('PageForm', (props: Props) => {

  const {type = 'content', pageID = null, defaults, parentMenuPageID, root = false, ...rest} = props

  const context = usePagesContext()
  const mainModuleID = projectStore.mainModule?.id ?? null

  const {t} = useResourceTranslation('pages')
  const {colors} = useStyling()

  const parentMenuPage = parentMenuPageID == null ? null : dataStore.get(Page, parentMenuPageID)

  const formModel = React.useMemo(() => {
    if (mainModuleID == null) { return null }

    return new PageFormModel(
      pageID,
      type,
      context,
      {...defaults, module: mainModuleID},
      parentMenuPage,
      root,
    )
  }, [context, defaults, mainModuleID, pageID, parentMenuPage, root, type])

  const indexItemTypeChoices = React.useMemo(
    () => IndexItemType.all.map((type): Choice<IndexItemType> => ({
      value:   type,
      caption: t(`fields.item_type.choices.${type.toLowerCase()}`),
    }),
  ), [t])

  //------
  // Rendering

  function render() {
    if (formModel == null) { return null }

    return (
      <FormDialog
        {...rest}
        model={formModel}
        icon='page'
        title={t('create.title')}
        submitButton={{icon: pageID ? 'check' : 'plus', caption: t(`buttons:${pageID ? 'save' : 'create'}`)}}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        <FormField name='module' required>
          {bind => <ModuleField {...bind}/>}
        </FormField>
        <FormField name='title' required>
          {bind => <LocalizedTextField {...bind}/>}
        </FormField>
        {!root && (
          <FormField name='slug' required>
            {bind => renderSlugField(bind)}
          </FormField>
        )}
        {type === 'index' && renderIndexFields()}
      </VBox>
    )
  }

  function renderSlugField(bind: FormFieldBindProps<string>) {
    if (formModel == null) { return null }

    return (
      <TextField
        {...bind}
        mono
        readOnly={formModel.autoGenerateSlug}
        accessoryRight={renderAutoGenerateSlugToggle()}
      />
    )
  }

  function renderAutoGenerateSlugToggle() {
    if (formModel == null) { return null }

    const tooltip = formModel.autoGenerateSlug
      ? t('fields.slug.autogenerated.on')
      : t('fields.slug.autogenerated.off')

    return (
      <Tooltip renderTooltip={tooltip}>
        <ClearButton
          icon='flash'
          color={formModel.autoGenerateSlug ? colors.semantic.primary : colors.fg.dark.dim}
          onTap={() => formModel.toggleAutoGenerateSlug()}
        />
      </Tooltip>
    )
  }

  function renderIndexFields() {
    return (
      <FormField name='itemType'>
        {bind => <SelectField {...bind} choices={indexItemTypeChoices}/>}
      </FormField>
    )
  }

  return render()

})

export default PageForm