import { AlertType, OperatorButton } from '~/models'

export type OperatorAlertListener = () => any

export interface OperatorPack {
  data: OperatorEntry[]
  meta: OperatorMeta
}

export interface OperatorMeta {
  alerts:   OperatorAlert[]
  triggers: OperatorTrigger[]
}

export interface OperatorAlert {
  // Participant info
  participantID: string
  name:          string
  firstName:     string
  lastName:      string | null
  photoURL:      string | null

  // Alerts
  alertType:    AlertType | null
  firstAlertAt: Date | null
  lastAlertAt:  Date | null
}

export interface OperatorTrigger {
  planID: string
  uuid:   string
  name:   string
}

export interface OperatorEntry extends OperatorAlert {

  // Chat
  responderSuspended: boolean
  buttons:            OperatorButton[]

  // Related info
  activeScripts:   ActiveScript[]

  // Location
  currentLocationID:   string | null
  currentLocationName: string | null

  // Times
  lastResetAt: Date | null
  updatedAt:   Date
  createdAt:   Date
}

export const OperatorEntry: {
  isOperatorAlert: (entry: OperatorAlert | OperatorEntry) => entry is OperatorAlert
  isOperatorEntry: (entry: OperatorAlert | OperatorEntry) => entry is OperatorEntry
} = {
  isOperatorAlert: (entry): entry is OperatorAlert => {
    return !OperatorEntry.isOperatorEntry(entry)
  },
  isOperatorEntry: (entry): entry is OperatorEntry => {
    return 'activeScripts' in entry
  },
}

interface ActiveScript {
  id:        string
  name:      string
  startedAt: Date
  error:     string | null
}