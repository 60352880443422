import React from 'react'
import { memo } from '~/ui/component'
import { Panel, VBox } from '~/ui/components'
import { layout } from '~/ui/styling'
import ClientTabForm from './ClientTabForm'
import { useClientTabs } from './ClientTabManagerContext'
import ClientTabsList from './ClientTabsList'
import ClientTabsToolbar from './ClientTabsToolbar'

const ClientTabsBody = memo('ClientTabManagerBody', () => {

  const {formOpen, closeForm, project, editingItemIndex, tabs} = useClientTabs()

  //------
  // Rendering

  function render() {
    return (
      <VBox flex='both' gap={layout.padding.s}>
        <Panel flex='both' semi={false}>
          <ClientTabsList />
        </Panel>
        <ClientTabsToolbar />
        <ClientTabForm
          open={formOpen}
          requestClose={closeForm}
          index={editingItemIndex}
          projectID={project?.id ?? null}
          tab={editingItemIndex !== null ? tabs[editingItemIndex] : null}
        />
      </VBox>
    )
  }

  return render()

})

export default ClientTabsBody