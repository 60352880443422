import React from 'react'
import { Access, Group } from '~/models'
import { memo } from '~/ui/component'
import { SVGName } from '~/ui/components/SVG'
import { useBoolean } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceChipRow } from '~/ui/resources/components'
import { SummaryInfo } from '~/ui/resources/detail'
import AccessForm from './AccessForm'
import AccessFormModel from './AccessFormModel'

export interface Props {
  access:       Access
  title?:       string
  instructions: string
  action:       string
  icon?:        SVGName
  formModel:    AccessFormModel
}

const AccessSummary = memo('AccessSummary', (props: Props) => {

  const {
    access,
    title,
    instructions,
    action,
    icon = 'unlock',
    formModel,
  } = props

  const {singular} = useResourceTranslation()
  const {t} = useResourceTranslation('access')

  const [formOpen, openForm, closeForm] = useBoolean()

  const type = singular()
  const summary = t(
    `summary.${access.type === 'limited'
      ? 'limited'
      : access.value === true ? 'may' : 'may_not'
    }`, {action, type},
  )

  //------
  // Rendering

  function render() {
    return (
      <SummaryInfo.Item
        icon={icon}
        requestEdit={openForm}
        summary={summary}
        body={renderBody()}
      >
        {renderForm()}
      </SummaryInfo.Item>
    )
  }

  function renderBody() {
    if (access.type !== 'limited') { return null }

    return (
      <ResourceChipRow
        Model={Group}
        ids={[access.group]}
        small
      />
    )
  }

  function renderForm() {
    return (
      <AccessForm
        open={formOpen}
        requestClose={closeForm}
        model={formModel}
        icon={icon}
        title={title}
        action={action}
        instructions={instructions}
      />
    )
  }

  return render()

})

export default AccessSummary