import React from 'react'
import { Ranking } from '~/models'
import { Avatar } from '~/ui/app/media'
import { memo } from '~/ui/component'
import { Center, HBox, Label } from '~/ui/components'
import { colors, createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props {
  ranking: Ranking
}

const RankingNameCell = memo('RankingNameCell', (props: Props) => {

  const {ranking} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <HBox gap={layout.padding.inline.l}>
        {renderAvatar()}
        {renderName()}

      </HBox>
    )
  }

  function renderAvatar() {
    return (
      <Center classNames={$.rankingAvatar}>
        <Avatar
          source={ranking.image}
          firstName={ranking.firstName}
          lastName={ranking.lastName}
          size={avatarSize}
        />
        {renderRank()}
      </Center>
    )
  }

  function renderRank() {
    return (
      <ThemeProvider dark>
        <Center classNames={$.rank}>
          <Label h3>
            {ranking.rank}
          </Label>
        </Center>
      </ThemeProvider>
    )
  }

  function renderName() {
    return (
      <Label flex h3>
        {ranking.name}
      </Label>
    )
  }

  return render()

})

export default RankingNameCell

export const avatarSize = layout.icon.xxl

const useStyles = createUseStyles({
  rankingAvatar: {
    position: 'relative',
  },

  rank: {
    ...layout.overlay,
    background:   colors.shim.medium,
    borderRadius: avatarSize.height / 2,
  },
})