import {
  DraftBlockType,
  DraftInlineStyle,
  DraftInlineStyleType,
  RawDraftInlineStyleRange,
} from 'draft-js'
import { OrderedSet } from 'immutable'
import { every, range } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { StringStream } from 'unicode'
import { Link, Media } from '~/models'
import { formatWidgetParams } from '~/ui/app/widgets'
import { SubmitResult } from '~/ui/form'
import { RichTextScope } from '../types'

export default class MarkdownBackend {

  constructor(
    value: string,
    public readonly textAreaRef: React.RefObject<HTMLTextAreaElement>,
    public readonly scope: RichTextScope,
    private readonly onChange: (value: string, commit: boolean) => any,
    private readonly commit: (value: string) => any,
  ) {
    this.value = value
    makeObservable(this)
  }

  @observable
  public value: string

  @computed
  public get lines() {
    return this.value.split('\n')
  }

  @computed
  public get lineRanges() {
    const ranges: Array<{start: number, end: number}> = []

    let offset: number = 0
    for (const line of this.lines) {
      ranges.push({start: offset, end: offset + line.length})
      offset += line.length + 1
    }

    return ranges
  }

  @computed
  public get inlineStyleRanges() {
    return findInlineStyleRanges(this.value)
  }

  @computed
  private get currentInlineStyleRanges() {
    return this.inlineStyleRanges.filter(range => {
      if (this.selection.start < range.offset) { return false }
      if (this.selection.end >= range.offset + range.length) { return false }
      return true
    })
  }

  @computed
  public get currentInlineStyle(): DraftInlineStyle {
    const ranges = this.currentInlineStyleRanges
    return OrderedSet.of(...ranges.map(range => range.style))
  }

  @computed
  public get currentBlockType(): DraftBlockType | null {
    const lines = this.selectedLines

    if (lines.length === 1 && /^(#+)/.test(lines[0])) {
      return RegExp.$1.length === 1 ? 'header-one' : 'header-two'
    } else if (every(lines, it => /^\s*[-*]\s/.test(it))) {
      return 'unordered-list-item'
    } else if (every(lines, it => /^\s*\d+\W\s/.test(it))) {
      return 'ordered-list-item'
    } else {
      return 'paragraph'
    }
  }

  //------
  // Changes

  private nextSelection?: {start: number, end: number}

  @action
  public updateFromValue(value: string) {
    if (value !== this.value) {
      this.value = value
    }

    if (this.nextSelection != null) {
      this.textAreaRef.current?.setSelectionRange(this.nextSelection.start, this.nextSelection.end)
      delete this.nextSelection
    }
  }

  @action
  public handleChange(value: string) {
    if (value === this.value) { return }
    this.value = value
    this.onChange(value, false)
  }

  @action
  public handleSelectionChange() {
    this.selection = this.getCurrentSelection()
  }

  //------
  // Editing

  public toggleBlockType(type: DraftBlockType) {
    const {start, end} = this.selectedLineRange

    const removeBlockStyle = (line: string) => line.replace(/^(#+|\s*[-*]|\s*\d+\W)\s*/, '')

    switch (type) {
      case 'paragraph':
        this.replaceLines(start, end, removeBlockStyle)
        break

      case 'header-one':
        this.replaceLines(start, end, line => removeBlockStyle(line).replace(/^/, '# '))
        break

      case 'header-two':
        this.replaceLines(start, end, line => removeBlockStyle(line).replace(/^/, '## '))
        break

      case 'unordered-list-item': {
        this.replaceLines(start, end, line => {
          if (/^\s*[-*]\s/.test(line)) {
            return removeBlockStyle(line)
          } else {
            return removeBlockStyle(line).replace(/^/, '- ')
          }
        })
        break
      }

      case 'ordered-list-item': {
        this.replaceLines(start, end, (line, index) => {
          if (/^\s*\d+\W\s/.test(line)) {
            return removeBlockStyle(line)
          } else {
            return removeBlockStyle(line).replace(/^/, `${index + 1}. `)
          }
        })
        break
      }
    }
  }

  public toggleInlineStyle(style: DraftInlineStyleType) {
    const ranges = this.currentInlineStyleRanges.filter(range => range.style === style)
    const range  = ranges.length === 0 ? null : ranges.reduce((result, range) => range.length < result.length ? range : result, ranges[0])
    const marker = INLINE_STYLES[style] ?? ''

    if (range == null) {
      const {start, end} = this.selection
      this.replace(start, end, text => `${marker}${text}${marker}`)
    } else {
      this.replace(range.offset, range.offset + range.length, text => text.slice(marker.length, -marker.length))
    }
  }

  public updateLink() {
    // Noop for Markdown - this is invoked from a DraftJS link block.
  }

  public removeLink() {
    // Noop for Markdown - this is invoked from a DraftJS link block.
  }

  @action
  public insert(text: string) {
    const {start, end} = this.selection
    this.replace(start, end, () => text)
  }

  @action
  public replace(start: number, end: number, replace: (text: string) => string) {
    const pre  = this.value.slice(0, start)
    const post = this.value.slice(end)
    const replaced = replace(this.value.slice(start, end))

    if (this.selection.end > this.selection.start) {
      // There was a selection with a length -> select the entire replaced region.
      this.nextSelection = {start: pre.length, end: pre.length + replaced.length}
    } else {
      // There was no a selection with a length -> place the cursor at the end.
      this.nextSelection = {start: pre.length + replaced.length, end: pre.length + replaced.length}
    }
    this.handleChange(pre + replaced + post)
  }

  @action
  public replaceLines(startLine: number, endLine: number, replace: (line: string, index: number) => string) {
    for (const [index, {start, end}] of this.lineRanges.slice(startLine, endLine).entries()) {
      this.replace(start, end, text => replace(text, index))
    }
  }

  //------
  // Selection

  @observable
  private selection: {start: number, end: number} = {start: 0, end: 0}

  private getCurrentSelection() {
    if (this.textAreaRef.current == null) {
      return {start: 0, end: 0}
    } else {
      const start = this.textAreaRef.current?.selectionStart
      const end   = this.textAreaRef.current?.selectionEnd
      return {start, end}
    }
  }

  private get selectedLineRange() {
    const {start, end} = this.selection

    let startLine: number | undefined
    let endLine: number | undefined

    let offset = 0
    for (const [index, line] of this.lines.entries()) {
      if (startLine == null && offset > start) {
        startLine = index - 1
      }
      if (endLine == null && offset > end) {
        endLine = index
        break
      }
      offset += line.length + 1
    }

    return {
      start: startLine ?? this.lines.length - 1,
      end:   endLine ?? this.lines.length,
    }
  }

  private get selectedLines() {
    const {start, end} = this.selectedLineRange
    return range(start, end).map(it => this.lines[it])
  }

  public selectAll() {
    this.textAreaRef.current?.focus()
    this.textAreaRef.current?.select()
    this.selection = this.getCurrentSelection()
  }

  //------
  // Media & links

  public insertMedia(media: Media) {
    this.insert(`![${media.name}](${media.url})`)
  }

  public insertLink(link: Link, caption: string | null): Promise<SubmitResult | undefined> {
    this.insert(`[${caption ?? ''}](${link.href})`)
    return Promise.resolve({status: 'ok'})
  }

  //------
  // Widgets

  public insertWidget(widget: string, params: Record<string, any> = {}) {
    const paramsString = formatWidgetParams(params, {
      multiline: true,
      indent:     2,
    })
    if (paramsString.length === 0) {
      this.insert(`$[${widget}]()`)
    } else {
      this.insert(`$[${widget}](\n${paramsString}\n)`)
    }
  }

}

function findInlineStyleRanges(markdown: string): RawDraftInlineStyleRange[] {
  const inlineStyleRanges: RawDraftInlineStyleRange[] = []
  const markers = Object.values(INLINE_STYLES) as string[]

  const parse = (text: string, startOffset: number) => {
    const stream = new StringStream(text, {
      escapeChar: '\\',
    })

    const startFound = stream.eatUntil(markers)

    // If no marker was found, stop.
    if (!startFound) { return }

    // Find the right style entry.
    const entry  = Object.entries(INLINE_STYLES).find(([, marker]) => stream.match(marker!))!
    const style  = entry?.[0]!
    const marker = entry?.[1]!

    // Find the start of the style entitiy within the parsed string.
    const start = startOffset + stream.pos

    // Mark the start, and skip the marker.
    stream.advance(marker.length)
    stream.markStart()

    // Try to find the end marker - if not found, append the rest of the string.
    const endFound = stream.eatUntil(marker)
    if (!endFound) { return }

    // Parse the content of this marker.
    parse(stream.current(true), stream.start)

    // Eat the end marker.
    stream.advance(marker.length)

    // Append the style range now.
    inlineStyleRanges.push({
      style:  style,
      offset: start,
      length: stream.pos - start + startOffset,
    })

    // Continue.
    stream.markStart()
    stream.eatUntilEos()

    const remainder = stream.current(true)
    if (remainder.length > 0) {
      parse(remainder, stream.start)
    }
  }

  parse(markdown, 0)

  return inlineStyleRanges
}

export const INLINE_STYLES: Partial<Record<DraftInlineStyleType, string>> = {
  BOLD:   '**',
  ITALIC: '_',
  CODE:   '`',
}
