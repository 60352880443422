import React from 'react'
import { isArray, some } from 'lodash'
import { DateTime } from 'luxon'
import { Insight, ParticipantDescriptor } from '~/models'
import { BulkSelector, ModelEndpoint } from '~/stores'
import { Avatar } from '~/ui/app/media'
import { memo, observer } from '~/ui/component'
import {
  Badge,
  Chip,
  ClearButton,
  ConfirmBox,
  DataGrid,
  Dimple,
  HBox,
  Label,
  ModalDialog,
  VBox,
} from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { BulkAction, ResourceList } from '~/ui/resources/collection'
import { createUseStyles, layout, useStyling } from '~/ui/styling'

export interface Props {
  open:         boolean
  requestClose: () => any

  endpoint:        ModelEndpoint<Insight>
  requestAbandon?: (uuids: string[]) => any
}

const InsightsDialog = observer('InsightsDialog', (props: Props) => {

  const {open, requestClose, endpoint, requestAbandon} = props

  const insights      = endpoint.data ?? []
  const hasCollective = some(insights, pos => pos.participant == null)

  const {t, fieldCaption, actionCaption, actionConfirm} = useResourceTranslation('insights')

  //------
  // Callbacks

  const fetch = React.useCallback(() => {
    endpoint.fetch()
  }, [endpoint])

  const abandon = React.useCallback(async (_, selector: BulkSelector) => {
    const uuids = isArray(selector.data) ? selector.data.map(it => it.id) : []
    if (uuids.length === 0) { return }

    const confirmed = await ConfirmBox.show({
      ...actionConfirm('abandon'),
    })
    if (!confirmed) { return false }

    await requestAbandon?.(uuids)
    return true
  }, [actionConfirm, requestAbandon])

  const bulkActions = React.useMemo((): BulkAction[] => [{
    name:    'abandon',
    caption: actionCaption('abandon'),
    icon:    'cross-circle',
    style:   'destructive',
    execute: abandon,
  }], [abandon, actionCaption])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ModalDialog
        open={open}
        requestClose={requestClose}
        onWillOpen={fetch}
        width={720}
        height={560}
        contentPadding={true}
        closeOnEscape={true}
        title={t('title')}
        headerRight={renderHeaderRight}
        children={renderBody()}
      />
    )
  }

  function renderHeaderRight(renderCloseButton: () => React.ReactNode) {
    return (
      <HBox gap={layout.padding.inline.m}>
        {renderRefreshButton()}
        <Dimple vertical/>
        {renderCloseButton()}
      </HBox>
    )
  }

  function renderRefreshButton() {
    return (
      <ClearButton
        icon='reload'
        onTap={fetch}
        tabIndex={2}
      />
    )
  }

  function renderBody() {
    return (
      <ResourceList endpoint={endpoint} bulkActions={bulkActions} allowRemove={false} rowClassNames={rowClassNames}>
        <DataGrid.Column
          name='participant'
          sort='name'
          caption={fieldCaption('participant')}
          instruction={hasCollective ? t('insights.columns.participant.instruction') : null}
          renderCell={renderParticipantCell}
        />
        <DataGrid.Column
          name='dateIn'
          sort='date'
          caption={fieldCaption('date_in')}
          renderCell={renderDateInCell}
        />
        <DataGrid.Column
          name='dateOut'
          caption={fieldCaption('date_out')}
          renderCell={renderDateOutCell}
        />
      </ResourceList>
    )
  }

  const rowClassNames = React.useCallback((insight: Insight): React.ClassNamesProp => {
    return [$.row, {active: insight.dateOut == null}]
  }, [$.row])

  const renderParticipantCell = React.useCallback(
    (insight: Insight) => <ParticipantCell participant={insight.participant}/>,
    [],
  )

  const renderDateInCell = React.useCallback(
    (insight: Insight) => <DateCell date={insight.dateIn}/>,
    [],
  )

  const renderDateOutCell = React.useCallback(
    (insight: Insight) => hasDateOut(insight) ? <DateCell date={insight.dateOut}/> : null,
    [],
  )

  return render()

})

export default InsightsDialog

const ParticipantCell = memo('ParticipantCell', (props: {participant: ParticipantDescriptor | null}) => {

  const {participant} = props
  const {t} = useResourceTranslation('insights')

  if (participant == null) {
    return (
      <HBox gap={layout.padding.inline.m}>
        <Badge
          icon='asterisk'
          size={avatarSize}
          depth={0}
        />
        <Label bold italic flex>
          {t('collective')}
        </Label>
      </HBox>
    )
  } else {
    const name = [participant.firstName, participant.lastName]
      .filter(Boolean)
      .join(' ')

    return (
      <HBox gap={layout.padding.inline.m}>
        <Avatar
          {...participant}
          source={participant.photoURL}
          size={avatarSize}
        />
        <Label bold flex>
          {name}
        </Label>
      </HBox>
    )
  }

})

const DateCell = memo('DateCell', (props: {date: DateTime | null}) => {

  const {date} = props

  const {t} = useResourceTranslation('insights')
  const {colors} = useStyling()

  function render() {
    return (
      <HBox>
        <VBox flex='shrink'>
          {renderContent()}
        </VBox>
      </HBox>
    )
  }

  function renderContent() {
    if (date != null) {
      return (
        <Label>
          {date.toFormat('yyyy-MM-dd HH:mm')}
        </Label>
      )
    } else {
      return (
        <Chip
          backgroundColor={colors.semantic.positive}
          children={t('active')}
        />
      )
    }
  }

  return render()

})

const avatarSize = {
  width:  32,
  height: 32,
}

function hasDateOut(insight: Insight): insight is Insight & {dateOut: DateTime} {
  return insight.dateOut !== undefined
}

const useStyles = createUseStyles({
  row: {
    '&:not(.active) > *': {
      opacity: 0.6,
    },
  },
})