import { omit } from 'lodash'
import { action, computed, makeObservable, observable } from 'mobx'
import { WidgetFilter } from '~/models'

export default class WidgetFilterValueSet {

  constructor(
    public readonly alotmentUUID: string,
    initialValues: Record<string, any>,
    private readonly onChange?: () => any,
  ) {
    this.values = initialValues
    makeObservable(this)
  }

  // Hooks

  public sendSetValue?: (alotmentUUID: string, name: string, value: any) => Promise<boolean>
  public sendRemove?:   (alotmentUUID: string, name: string) => Promise<boolean>

  @observable.ref
  public values: Record<string, any>

  @computed
  public get names() {
    return Object.keys(this.values)
  }

  public getValue<T = any>(name: string): T {
    return this.values[name] as any
  }

  @action
  public setValue(filter: WidgetFilter, value: any) {
    const prevValues = this.values

    this.values = {
      ...this.values,
      [filter.name]: value,
    }

    this.sendSetValue?.(this.alotmentUUID, filter.name, value)
      .then(action(ok => {
        if (!ok) {
          this.values = prevValues
        }
      }))

    this.onChange?.()
  }

  @action
  public setToDefault(filter: WidgetFilter) {
    this.setValue(filter, filter.defaultValue)
  }

  @action
  public remove(filter: WidgetFilter) {
    if (!(filter.name in this.values)) { return null }

    const prevValues = this.values
    this.values = omit(this.values, filter.name)

    this.sendRemove?.(this.alotmentUUID, filter.name)
      .then(action(ok => {
        if (!ok) {
          this.values = prevValues
        }
      }))

    this.onChange?.()
  }

}