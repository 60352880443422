import React from 'react'
import { useTimer } from 'react-timer'
import { isPlainObject } from 'lodash'
import { assignRef, useContinuousRef } from '~/ui/hooks'

export function useFocusOnActivate(ref: React.RefObject<HTMLInputElement>, active: boolean, options?: UseFocusOnActivateOptions): void
export function useFocusOnActivate(active: boolean, options?: UseFocusOnActivateOptions): React.Ref<HTMLInputElement>
export function useFocusOnActivate(...args: any[]) {
  const internalRef = React.useRef<HTMLInputElement>()

  const options = isPlainObject(args[args.length - 1]) ? args.pop() : {}
  const ref: React.RefObject<HTMLInputElement> = args.length === 1 ? internalRef : args[0]
  const active: boolean                        = args.length === 1 ? args[0] : args[1]

  const timer = useTimer()
  const [mounted, setMounted] = React.useState<boolean>(ref.current != null)
  const mountedRef = useContinuousRef(mounted)

  React.useEffect(() => {
    const element = ref.current
    if (element == null) { return }

    const hasFocus = element === document.activeElement
    if (!active || hasFocus) { return }

    if (options.timeout != null) {
      timer.setTimeout(() => {
        element.focus()
      }, options.timeout)
    } else {
      element.focus()
    }
  }, [active, options.timeout, ref, timer])

  const connect = React.useCallback((element: HTMLInputElement | null) => {
    assignRef(ref, element)
    if (!mountedRef.current && element != null) {
      setMounted(true)
    }
  }, [mountedRef, ref])

  return connect
}

export interface UseFocusOnActivateOptions {
  timeout?: number
}