import { action } from 'mobx'
import { CollectionFetchOptions, CollectionFetchResponse, Database, Endpoint } from 'mobx-document'
import socket, { SocketOperation } from 'socket.io-react'
import config from '~/config'
import { Model, ModelClass } from '~/models'
import dataStore from '../dataStore'
import { SubmitResult, submitResultForResponse } from '../support'
import ModelDocument from './ModelDocument'
import {
  ActionOptions,
  AnyPack,
  BulkSelector,
  CopyResolution,
  CopyResult,
  CustomListPack,
  Linkage,
  ListMeta,
  ListPack,
  ListParams,
  LongRunningActionOptions,
  ModelEndpointOptions,
  Pack,
} from './types'
import { resourceToModel } from './util'

export default class ModelEndpoint<M extends Model> extends Endpoint<ModelDocument<M>, ListParams, ListMeta> {

  constructor(
    database: Database<ModelDocument<M>>,
    Model:    ModelClass<M>,
    params?:  ListParams,
    options?: ModelEndpointOptions<M>,
  ) {
    super(database, params ?? {}, options)
    this.Model = Model

    if (options?.initialIDs != null) {
      this.ids = options.initialIDs
    }
  }

  public readonly Model: ModelClass<M>

  //------
  // Fetching

  @action
  public async fetchMore(options: Omit<CollectionFetchOptions, 'offset' | 'append'> = {}): Promise<void> {
    if (this.fetchStatus !== 'done') { return }
    if (this.meta?.nextOffset == null) { return }

    await this.fetch({
      ...options,
      offset: this.meta.nextOffset,
      append: true,
    })
  }

  protected async performFetch(params: ListParams, options: CollectionFetchOptions): Promise<CollectionFetchResponse<M, ListMeta> | null> {
    const listOptions = {
      ...options,
      ...params,
    }

    if (listOptions.page != null) {
      listOptions.offset = (listOptions.page - 1) * config.resource.pageSize
      delete listOptions.page
    }

    if (listOptions.limit === undefined) {
      listOptions.limit = config.resource.pageSize
    }

    const response = await this.performSocketFetch(listOptions)
    if (!response.ok) { return response }

    const pack = response.body as ListPack<M>

    if (dataStore.isAttachedEndpoint(this)) {
      dataStore.storePack(pack)
    }

    const data = pack.data.map(resource => this.Model.deserialize(resourceToModel(resource)))
    const meta = pack.meta
    return {data, meta}
  }

  protected async performSocketFetch(options: any) {
    return await socket.fetch('data:list', this.Model.resourceType, options)
  }

  //------
  // Copy & duplicate

  public async duplicate(selector: BulkSelector, options: LongRunningActionOptions = {}): Promise<SubmitResult<CopyResult[]>> {
    const {
      timeout = false,
      onStart,
      onEnd,
      ...rest
    } = options

    const operation = new SocketOperation(socket, 'data:duplicate')
    onStart?.(operation)

    const response = await socket.sendWithOptions(
      {timeout, operation},
      'data:duplicate',
      this.Model.resourceType,
      selector,
      rest,
    )
    onEnd?.(operation)

    return submitResultForResponse(response)
  }

  public async copyToProjectPreflight(selector: BulkSelector, resolutions: Array<[Linkage<any>, CopyResolution]>, options: LongRunningActionOptions = {}): Promise<Linkage<any>[]> {
    const {
      timeout = false,
      onStart,
      onEnd,
      ...rest
    } = options

    const operation = new SocketOperation(socket, 'data:copy-to-project:preflight')
    onStart?.(operation)

    const response = await socket.sendWithOptions(
      {timeout, operation}, // Because this is akin to a fetch, we don't use a timeout.
      'data:copy-to-project:preflight',
      this.Model.resourceType,
      selector,
      resolutions,
      rest,
    )
    onEnd?.(operation)

    if (response.ok) {
      const pack = response.body as CustomListPack<Linkage<any>>
      return pack.data ?? []
    } else {
      return []
    }
  }

  public async copyToProject(selector: BulkSelector, destProjectID: string, resolutions: Array<[Linkage<any>, CopyResolution]>, options: LongRunningActionOptions = {}): Promise<SubmitResult<CopyResult[]>> {
    const {timeout, onStart, onEnd, ...rest} = options

    const operation = new SocketOperation(socket, 'data:copy-to-project')
    onStart?.(operation)

    const response = await socket.sendWithOptions(
      {timeout, operation},
      'data:copy-to-project',
      this.Model.resourceType,
      selector,
      destProjectID,
      resolutions,
      rest,
    )
    if (response.ok) {
      dataStore.storePack(response.body)
    }
    onEnd?.(operation)

    return submitResultForResponse(response)
  }

  //------
  // Collection actions

  @action
  public async callCollectionAction(action: string, pack: AnyPack | null = null, options: ActionOptions = {}) {
    const {timeout} = options
    const response = await socket.sendWithOptions({timeout}, 'data:collection-action', this.Model.resourceType, action, pack, options)
    if (response.ok) {
      dataStore.storePack(response.body)
    }
    return submitResultForResponse(response)
  }

  @action
  public async callBulkAction(action: string, selector: BulkSelector, options: ActionOptions = {}) {
    return await this.callCollectionAction(action, selector, options)
  }

  //------
  // Operations

  protected store(model: M): ModelDocument<M> {
    // Use the dataStore.storeModel function to store the actual model, rather than overwriting the document, so that
    // if there is a version in the database with $hasDetail === true, it won't be overwritten, but rather merged with
    // a model retrieved from the server with $hasDetail === false.
    // Only do this for attached endpoints. Detached endpoints don't mix models with $hasDetail and without.

    if (dataStore.isAttachedEndpoint(this)) {
      return dataStore.storeModel(model) as any
    } else {
      return super.store(model)
    }
  }

  public updateOrInsert(model: M) {
    this.database.store(model)
    if (!this.ids.includes(model.id)) {
      this.ids = [...this.ids, model.id]
    }
  }

  @action
  public async delete(selector: BulkSelector) {
    const response = await socket.sendWithOptions({
      timeout: 30_000,
    }, 'data:delete', this.Model.resourceType, selector, {include: this.params.include})

    if (response.ok) {
      const pack = response.body as Pack<M>
      const ids  = pack.meta.ids ?? []
      this.remove(ids)
      this.fetch({})

      dataStore.storeIncluded(pack)
      dataStore.emitAfterRemove(this.Model, selector)
    }

    return submitResultForResponse(response)
  }

  public async exportData(selector: BulkSelector) {
    const response = await socket.fetch('data:export', this.Model.resourceType, selector)
    if (response.ok) {
      return new Blob([response.body], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    } else {
      return null
    }
  }

}