import React from 'react'
import Toast from 'react-toast'
import { makeObservable, observable } from 'mobx'
import { cleanTextValue } from 'ytil'
import { Review } from '~/models'
import { dataStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  Dimple,
  NumberField,
  Panel,
  PanelHeader,
  PushButton,
  SelectField,
  TextField,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { Form, FormContext, FormField, FormModel, SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  review:  Review
}

const ReviewActionsForm = observer('ReviewActionsForm', (props: Props) => {

  const {review} = props

  const {t, actionCaption} = useResourceTranslation('reviews')

  const formModel = React.useMemo(
    () => new ReviewActionsFormModel(review),
    [review],
  )

  const correctChoices = React.useMemo((): Choice<boolean | null>[] => [
    {value: null, caption: t('fields.correct.choices.unspecified')},
    {value: true, caption: t('fields.correct.choices.correct')},
    {value: false, caption: t('fields.correct.choices.incorrect')},
  ], [t])

  const showSuccess = React.useCallback((result: SubmitResult) => {
    if (result.status !== 'ok') { return }

    Toast.show({
      ...t('actions.update.success'),
      type: 'success',
    })
  }, [t])

  //------
  // Rendering

  function render() {
    return (
      <Panel padding={layout.padding.m} header={renderHeader()}>
        <Form model={formModel} afterSubmit={showSuccess}>
          {renderFields}
        </Form>
      </Panel>
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        small
        caption={t('actions_form.caption')}
      />
    )
  }

  function renderFields(form: FormContext<any>) {
    return (
      <VBox gap={layout.padding.m}>
        <FormField name='score'>
          {bind => <NumberField {...bind}/>}
        </FormField>
        <FormField name='correct'>
          {bind => <SelectField {...bind} choices={correctChoices}/>}
        </FormField>
        <FormField name='notes'>
          {bind => <TextField {...bind} multiline height={85}/>}
        </FormField>

        <Dimple horizontal counterPadding={layout.padding.m}/>

        <PushButton
          small
          caption={actionCaption('update')}
          enabled={formModel.maySubmit}
          working={form.submitting}
          submit
        />
      </VBox>
    )
  }

  return render()

})

export default ReviewActionsForm

export class ReviewActionsFormModel implements FormModel {

  constructor(
    public readonly review: Review,
  ) {
    makeObservable(this)
  }

  @observable
  public score: number = this.review.score

  @observable
  public correct: boolean | null = this.review.correct

  @observable
  public notes: string = this.review.notes ?? ''

  public get maySubmit() {
    return true
  }

  public async submit(): Promise<SubmitResult | undefined> {
    return await dataStore.update(Review, this.review.id, {
      score:   this.score,
      correct: this.correct,
      notes:   cleanTextValue(this.notes, true),
    })
  }

}