// @index(d:!): export { default as ${variable}} from ${relpath}
export { default as RelatedResourceList} from './RelatedResourceList'
export { default as ResourceAppLink} from './ResourceAppLink'
export { default as ResourceBar} from './ResourceBar'
export { default as ResourceChip} from './ResourceChip'
export { default as ResourceChipRow} from './ResourceChipRow'
export { default as ResourceField} from './ResourceField'
export { default as ResourceFromLinkedProjectNotice} from './ResourceFromLinkedProjectNotice'
export { default as ResourceListItem} from './ResourceListItem'
export { default as ResourcePopup} from './ResourcePopup'
// /index

// @index(d:!): export type {Props as ${variable}Props} from ${relpath}
export type {Props as RelatedResourceListProps} from './RelatedResourceList'
export type {Props as ResourceAppLinkProps} from './ResourceAppLink'
export type {Props as ResourceBarProps} from './ResourceBar'
export type {Props as ResourceChipProps} from './ResourceChip'
export type {Props as ResourceChipRowProps} from './ResourceChipRow'
export type {Props as ResourceFieldProps} from './ResourceField'
export type {Props as ResourceFromLinkedProjectNoticeProps} from './ResourceFromLinkedProjectNotice'
export type {Props as ResourceListItemProps} from './ResourceListItem'
export type {Props as ResourcePopupProps} from './ResourcePopup'
// /index