import React from 'react'
import { useSelectionManager } from 'react-selection-manager'
import { useTimer } from 'react-timer'
import { Competition } from '~/models'
import { competitionStore } from '~/stores'
import { observer } from '~/ui/component'
import {
  ConfirmBox,
  Label,
  NumberField,
  Panel,
  PushButton,
  SelectField,
  VBox,
} from '~/ui/components'
import { Choice } from '~/ui/components/fields/SelectField'
import { SubmitResult } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'
import { bulkSelectorForSelection } from '~/ui/resources/collection'
import { layout } from '~/ui/styling'

export interface Props {
  competition:  Competition
  afterSubmit?: (result: SubmitResult) => any
}

type RankingActionType = 'set' | 'increase' | 'multiply'

const RankingBulkActions = observer('RankingBulkActions', (props: Props) => {

  const {competition, afterSubmit} = props

  const [action, setAction] = React.useState<RankingActionType>('set')
  const [value, setValue] = React.useState<number | null>(null)
  const [committing, setCommitting] = React.useState<boolean>(false)

  const {t} = useResourceTranslation('competitions')
  const manager = useSelectionManager()
  const selectionCount = manager?.selectedKeys.length ?? 0
  const timer = useTimer()

  const actionChoices = React.useMemo((): Choice<RankingActionType>[] => [{
    value:   'set',
    caption: t('rankings.scores.actions.set', {count: selectionCount}),
  }, {
    value:   'increase',
    caption: t('rankings.scores.actions.increase', {count: selectionCount}),
  }, {
    value:   'multiply',
    caption: t('rankings.scores.actions.multiply', {count: selectionCount}),
  }], [selectionCount, t])

  const handleActionChange = React.useCallback((action: RankingActionType) => {
    setAction(action)
    if (action === 'multiply' && (value == null || value === 0)) {
      setValue(1)
    }
  }, [value])

  const commit = React.useCallback(async () => {
    const selector = manager == null ? null : bulkSelectorForSelection('rankings', manager)
    if (selector == null || value == null) { return null }

    const needsConfirm = action === 'set' && selectionCount > 1
    const confirmed = !needsConfirm ? true : await ConfirmBox.show({
      ...t('rankings.scores.confirm'),
    })
    if (!confirmed) { return }

    let result: SubmitResult | undefined
    try {
      setCommitting(true)
      switch (action) {
        case 'set':
          result = await competitionStore.setScores(competition.id, selector, value)
          break
        case 'increase':
          result = await competitionStore.increaseScores(competition.id, selector, value)
          break
        case 'multiply':
          result = await competitionStore.multiplyScores(competition.id, selector, value)
          break
      }
    } finally {
      if (result != null) {
        afterSubmit?.(result)
      }
      if (!timer.isDisposed) {
        setCommitting(false)
      }

      return result
    }
  }, [action, afterSubmit, competition.id, manager, selectionCount, t, timer.isDisposed, value])

  //------
  // Rendering

  function render() {
    return (
      <Panel padding={layout.padding.m}>
        {selectionCount === 0 ? (
          renderEmpty()
        ) : (
          renderBody()
        )}
      </Panel>
    )
  }

  function renderEmpty() {
    return (
      <Label small dim italic align='center'>
        {t('rankings.scores.empty')}
      </Label>
    )
  }

  function renderBody() {
    return (
      <VBox gap={layout.padding.m}>
        {renderActionSelector()}
        {renderScoreField()}
        {renderCommitButton()}
      </VBox>
    )
  }

  function renderActionSelector() {
    return (
      <SelectField
        value={action}
        onChange={handleActionChange}
        choices={actionChoices}
        enabled={!committing}
      />
    )
  }

  function renderScoreField() {
    return (
      <NumberField
        value={value}
        onChange={setValue}
        minimum={null}
        step={action === 'multiply' ? 1 : 10}
        onCommit={commit}
        enabled={!committing}
      />
    )
  }

  function renderCommitButton() {
    return (
      <PushButton
        caption={t('rankings.scores.commit', {count: selectionCount})}
        onTap={commit}
        enabled={value != null}
        working={committing}
      />
    )
  }

  return render()

})

export default RankingBulkActions