import React from 'react'
import { useTranslation } from 'react-i18next'
import { Location, LocationEnterTrigger, LocationExitTrigger } from '~/models'
import { observer } from '~/ui/component'
import { Spinner } from '~/ui/components'
import { useModelDocumentData } from '~/ui/hooks/data'
import { register } from './registry'
import TriggerNodeView from './TriggerNodeView'
import { CommonTriggerNodeViewProps } from './types'

export interface Props extends CommonTriggerNodeViewProps {
  trigger: LocationEnterTrigger | LocationExitTrigger
}

const LocationTriggerNodeView = observer('LocationTriggerNodeView', (props: Props) => {
  const {trigger} = props

  const [t] = useTranslation('flow_planner')

  const [location, {fetchStatus}] = useModelDocumentData(Location, trigger.location, {
    detail: false,
  })

  const locationDetail = location == null
    ? (fetchStatus === 'fetching' ? <Spinner size={8}/> : null)
    : `[${location.name}]`

  return (
    <TriggerNodeView
      {...props}
      detail={t(`triggers.${trigger.type}.detail`, {nsSeparator: '|', locationName: locationDetail})}
      icon={trigger.type === 'location:enter' ? 'location-enter' : 'location-exit'}
    />
  )
})

register('location:*', LocationTriggerNodeView)