import React from 'react'
import { ChallengeTask } from '~/models'
import { memo } from '~/ui/component'
import { Checkbox, ListBar, SVG } from '~/ui/components'
import { createUseStyles, layout } from '~/ui/styling'

export interface Props {
  task:           ChallengeTask
  selected:       boolean
  onToggleSelect: (uuid: string) => any
}

const ChallengeTasksBulkEditorItem = memo('ChallengeTasksBulkEditorItem', (props: Props) => {

  const {task, selected, onToggleSelect} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <ListBar
        classNames={$.task}
        caption={task.title}
        detail={task.subtitle}
        image={renderCheckbox()}
        accessory={renderAccessory()}
      />
    )
  }

  function renderCheckbox() {
    return(
      <Checkbox
        checked={selected}
        onChange={toggle}
        classNames={$.checkbox}
      />
    )
  }

  function renderAccessory() {
    return (
      <SVG
        name='sort-handle'
        size={layout.icon.m}
      />
    )
  }

  const toggle = React.useCallback(() => {
    onToggleSelect(task.uuid)
  }, [onToggleSelect, task.uuid])

  return render()

})

export default ChallengeTasksBulkEditorItem

const useStyles = createUseStyles({
  task: {
    cursor: 'grab',
  },
  checkbox: {
    cursor: 'default',
  },
})