import { ChallengeQuestion, ChallengeTask } from '~/models'
import ScoringFormModel from '~/ui/app/competitions/scoring/ScoringFormModel'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ChallengeScoringFormModel extends ScoringFormModel {

  constructor(
    private readonly saveTask: (data: AnyObject) => Promise<SubmitResult | undefined>,
    private readonly task: ChallengeTask & {question: ChallengeQuestion},
  ) {
    super(task.question, task.question?.scorings ?? [])
  }

  public async submit(): Promise<SubmitResult | undefined> {
    const {question} = this.task
    if (question == null) { return }

    const result = await this.saveTask({
      question: {
        ...question,
        scorings: this.scorings,
      },
    })
    return translateFormErrorPaths(result, path => path.replace(/^question\./, ''))
  }

}