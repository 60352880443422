import React from 'react'
import { memo } from '~/ui/component'
import { DateTimeField } from '~/ui/components'
import { BindProps as FormFieldBindProps } from '~/ui/form/FormField'
import { DateField as DateFieldModel } from '../types'

export interface Props {
  field: DateFieldModel
  bind:  FormFieldBindProps<string | null>
}

const DateAllowance = memo('DateAllowance', (props: Props) => {

  const {bind} = props

  //------
  // Rendering

  function render() {
    return (
      <DateTimeField
        {...bind as any}
      />
    )
  }

  return render()

})

export default DateAllowance