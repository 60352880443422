import React from 'react'
import { EnumFilter as EnumFilterModel } from '~/models'
import { memo } from '~/ui/component'
import { PopupMenuItem } from '~/ui/components'
import { registerDashboardFilter } from './registry'
import WidgetFilter from './WidgetFilter'

import type { WidgetFilterComponentProps } from './types'
export type Props = WidgetFilterComponentProps<EnumFilterModel, string>

const EnumFilter = memo('EnumFilter', (props: Props) => {

  const {alotment, widget, filter, value} = props

  const menuItems = React.useMemo((): PopupMenuItem[] => {
    return filter.enum.map((enumValue): PopupMenuItem => ({
      value:   enumValue,
      checked: enumValue === value,
      caption: widget.translateFilter(filter.name, `choices.${enumValue}`),
    }))
  }, [filter.enum, filter.name, value, widget])

  //------
  // Rendering

  function render() {
    return (
      <WidgetFilter
        alotment={alotment}
        widget={widget}
        filter={filter}
        value={value}
        renderValue={renderValue}
        menuItems={menuItems}
      />
    )
  }

  const renderValue = React.useCallback((value: any) => {
    return widget.translateFilter(filter.name, `choices.${value}`)
  }, [filter.name, widget])

  return render()

})

registerDashboardFilter('enum', EnumFilter)

export default EnumFilter