import { DateTime } from 'luxon'
import { serialize } from 'ymodel'
import { Model, resource } from './Model'

@resource<GroupSignup>('group-signups', {
  icon:    'signup',
  caption: signup => signup.name,
  scopedToModule: false,
})
export class GroupSignup extends Model {

  public name!:          string
  public tag!:           string | null
  public checkCapacity!: boolean
  public maxSignups!:    number

  @serialize(DateTime)
  public deadline!:         DateTime | null
  public deadlineVariable!: string | null

  public closed!:        boolean
  public translations!:  Record<string, Record<string, string>>

  public get localDeadline() {
    if (this.deadline == null) { return null }
    return this.deadline.setZone('utc').setZone('local', {keepLocalTime: true})
  }

  public isPastDeadline(currentTime: DateTime) {
    const deadline = this.localDeadline
    if (deadline == null) { return false }

    return deadline <= currentTime
  }

  public isClosed(currentTime: DateTime) {
    if (this.closed) { return true }
    return this.isPastDeadline(currentTime)
  }

}