import { NavigationItem } from '~/navigation.yml'
import { PopupMenuProps } from '~/ui/components'

export interface NavigationDetailMenuProps extends Omit<PopupMenuProps<any>, 'items'> {
  item: NavigationItem
}

const REGISTRY = new Map<string, React.ComponentType<NavigationDetailMenuProps>>()

export function registerNavigationDetailMenu(name: string, DetailMenu: React.ComponentType<NavigationDetailMenuProps>) {
  REGISTRY.set(name, DetailMenu)
}

export function getNavigationDetailMenuComponent(name: string) {
  return REGISTRY.get(name) ?? null
}