import React from 'react'
import { observer } from '~/ui/component'
import { FieldTransform, FormField, FormFieldProps } from '~/ui/form'
import { useResourceTranslation } from '~/ui/resources'

export interface Props<T> extends Omit<FormFieldProps<T>, 'transform'> {
  transform?: FieldTransform<T, string | null>
}

const _ResourceFilter = <T extends any = any>(props: Props<T>) => {

  const {name, i18nKey = name, transform} = props
  const {filterCaption, filterInstruction, filterLabel, filterPlaceholder} = useResourceTranslation()

  const caption     = props.caption === false ? false : (props.caption ?? filterCaption(i18nKey))
  const instruction = props.instruction === false ? false : (props.instruction ?? filterInstruction(i18nKey))
  const placeholder = props.placeholder === false ? false : (props.placeholder ?? filterPlaceholder(i18nKey))
  const label       = props.label === false ? false : (props.label ?? filterLabel(i18nKey))

  return (
    <FormField<T>
      {...props}
      caption={caption}
      instruction={instruction}
      placeholder={placeholder}
      label={label}
      transform={transform}
    />
  )

}

const ResourceFilter = observer('ResourceFilter', _ResourceFilter) as typeof _ResourceFilter
export default ResourceFilter