import React from 'react'
import { FlowPlan, isModuleBoundaryNode, PlanNode } from '~/models'
import { observer } from '~/ui/component'
import { VBox } from '~/ui/components'
import { createUseStyles, layout, useTheme } from '~/ui/styling'
import { borderRadiusForPlannerComponent } from '../canvas/layout'
import { useFlowPlanner } from '../FlowPlannerContext'
import TriggerableNodeView from '../triggerables/TriggerableNodeView'
import { renderTriggerNode } from '../triggers'
import ModuleBoundaryNodeView from './ModuleBoundaryNodeView'
import ModuleNodeView from './ModuleNodeView'
import OtherNodeView from './OtherNodeView'

export interface Props {
  plan: FlowPlan
  node: PlanNode

  variant?:     'canvas' | 'list'
  interactive?: boolean
}

const NodeView = observer('NodeView', (props: Props) => {

  const {node, variant = 'canvas', interactive = true} = props

  const theme        = useTheme()
  const {planner}    = useFlowPlanner()
  const focused      = planner.isNodeHighlightedForRuleResult(node.uuid)
  const level        = planner.highlightedRuleResult?.rule.level
  const borderRadius = borderRadiusForPlannerComponent(theme, node, node.bounds)

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    return (
      <VBox flex='grow' classNames={[$.nodeView, variant]}>
        {renderContent()}
        {focused && interactive && <div classNames={[$.focusedRuleBorder, level]} style={{borderRadius}}/>}
      </VBox>
    )
  }

  function renderContent() {
    if (node.type === 'trigger') {
      return renderTriggerNode(node, {variant, interactive})
    } else if (node.type === 'triggerable') {
      return (
        <TriggerableNodeView
          node={node}
          variant={variant}
          interactive={interactive}
        />
      )
    } else if (node.type === 'module') {
      return (
        <ModuleNodeView
          node={node}
          variant={variant}
          interactive={interactive}
        />
      )
    } else if (isModuleBoundaryNode(node)) {
      return (
        <ModuleBoundaryNodeView
          node={node}
          variant={variant}
          interactive={interactive}
        />
      )
    } else {
      return (
        <OtherNodeView
          node={node}
          variant={variant}
          interactive={interactive}
        />
      )
    }
  }

  return render()
})

const useStyles = createUseStyles(theme => ({
  nodeView: {
    position: 'relative',

    '&.list': {
      minHeight: layout.barHeight.s,
    },
  },

  focusedRuleBorder: {
    ...layout.overlay,
    pointerEvents: 'none',

    '&.error': {
      boxShadow: [0, 0, 0, 4, theme.semantic.negative],
    },
    '&.warning': {
      boxShadow: [0, 0, 0, 4, theme.semantic.warning],
    },
  },
}))

export default NodeView