import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { QAndA, Question } from '~/models'
import { PushButton, VBox } from '~/ui/components'
import { useBoolean } from '~/ui/hooks'
import { useModelEndpoint } from '~/ui/hooks/data'
import { useResourceTranslation } from '~/ui/resources'
import { ResourceDetailScreen, SummaryInfo } from '~/ui/resources/detail'
import { ResourceDetailParams } from '~/ui/resources/routes'
import { layout, useStyling } from '~/ui/styling'
import QAndAForm from '../QAndAForm'
import QuestionForm from '../questions/QuestionForm'
import QuestionList from '../questions/QuestionList'

export type Props = RouteComponentProps<ResourceDetailParams>

const QAndAScreen = (props: Props) => {

  const {t, actionCaption} = useResourceTranslation('q-and-as')

  const qAndAID           = props.match.params.id
  const questionsEndpoint = useModelEndpoint(Question, {
    filters: {
      qAndA: qAndAID,
    },
  })

  //------
  // Editing questions

  const [editingQuestion, setEditingQuestion] = React.useState<Question | null>(null)
  const [questionFormOpen, openQuestionForm, closeQuestionForm] = useBoolean(false)

  const editQuestion = React.useCallback((question: Question) => {
    setEditingQuestion(question)
    openQuestionForm()
  }, [openQuestionForm])

  const addQuestion = React.useCallback(() => {
    setEditingQuestion(null)
    openQuestionForm()
  }, [openQuestionForm])


  const {colors} = useStyling()

  //------
  // Rendering

  function render() {
    return (
      <ResourceDetailScreen<QAndA>
        Model={QAndA}
        id={props.match.params.id}

        BreadcrumbsActionsComponent={renderBreadcrumbsActions}
        EditFormComponent={QAndAForm}
        renderInfo={renderInfo}
        renderBody={renderBody}
      />
    )
  }

  function renderInfo(qAndA: QAndA) {
    return (
      <SummaryInfo>
        <SummaryInfo.Item markup>
          {t('created', {when: qAndA.createdAt})}
        </SummaryInfo.Item>
        <SummaryInfo.Item markup icon={qAndA.questionCount === 0 ? 'cross' : 'check'} color={qAndA.questionCount === 0 ? colors.semantic.negative : colors.semantic.positive}>
          {qAndA.questionCount === 0 ? t('question_count_zero') : t('question_count', {count: qAndA.questionCount})}
        </SummaryInfo.Item>
      </SummaryInfo>
    )
  }

  function renderBody(qAndA: QAndA) {
    return (
      <VBox flex gap={layout.padding.m}>
        {renderQuestionList(qAndA)}

        <QuestionForm
          open={questionFormOpen}
          requestClose={closeQuestionForm}
          qAndA={qAndA}
          question={editingQuestion}
        />
      </VBox>
    )
  }

  function renderQuestionList(qAndA: QAndA) {
    return (
      <QuestionList
        endpoint={questionsEndpoint}
        qAndA={qAndA}
        onItemTap={editQuestion}
        useLocation
      />
    )
  }

  const renderAddQuestion = React.useCallback((small: boolean) => {
    return (
      <PushButton
        icon='plus'
        caption={actionCaption('add_question')}
        onTap={addQuestion}
        small={small}
      />
    )
  }, [actionCaption, addQuestion])

  const renderBreadcrumbsActions = React.useCallback(() => {
    return renderAddQuestion(true)
  }, [renderAddQuestion])

  return render()

}

export default React.memo(QAndAScreen)