import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import I18next from 'i18next'
import {
  ChallengeTriggerable,
  getTriggerableID,
  Triggerable,
  TriggerableModelClass,
  TriggerableNode,
} from '~/models'
import { plannerStore } from '~/stores'
import { observer } from '~/ui/component'
import { ConfirmBox, InfoIcon, PopupMenuItem, VBox } from '~/ui/components'
import ActionTriggerableBar from '../../triggerables/ActionTriggerableBar'
import ModelTriggerableBar from '../../triggerables/ModelTriggerableBar'
import { useFlowPlanner } from '../FlowPlannerContext'
import { useNodeViewKebabMenuItems } from '../nodes/NodeViewKebabMenu'

export interface Props {
  node:         TriggerableNode
  triggerable:  Triggerable
  partOfGroup:  boolean
  small?:       boolean
  interactive?: boolean
}

const FlowPlannerTriggerableBar = observer('FlowPlannerTriggerableBar', (props: Props) => {

  const {node, triggerable, partOfGroup, small = false, interactive = true} = props
  const uuid = getTriggerableID(triggerable)

  const {planner} = useFlowPlanner()
  const focused   = planner.isTriggerableHighlightedForRuleResult(uuid)
  const level     = planner.highlightedRuleResult?.rule.level

  const [t]       = useTranslation('flow_planner')
  const singular  = triggerable.type === 'action'
    ? t('planner_action')
    : I18next.t(TriggerableModelClass(triggerable.type).resourceType + ':singular')

  const history    = useHistory()

  //------
  // Actions

  const action = triggerable.type === 'action'
    ? plannerStore.getAction(triggerable.action)
    : null

  const allowEdit = React.useMemo(() => {
    if (action != null) {
      return Object.keys(action!.paramsSchema).length > 0
    } else {
      return true
    }
  }, [action])

  const edit = React.useCallback(() => {
    if (triggerable.type === 'challenge') {
      history.push(`/challenges/-/${triggerable.model}`)
    } else {
      planner.editComponent({
        type:        'triggerable',
        nodeUUID:    node.uuid,
        uuid:        uuid,
        triggerable: triggerable,
      })
    }
  }, [history, node.uuid, planner, triggerable, uuid])

  const remove = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('triggerables.actions.remove.confirm', {type: singular}),
      destructive: true,
    })
    if (!confirmed) { return }

    await planner.removeTriggerable(node.uuid, uuid)
  }, [node.uuid, planner, singular, t, uuid])

  const detach = React.useCallback(() => {
    return planner.detachTriggerableFromGroup(node.uuid, uuid)
  }, [node.uuid, planner, uuid])

  const togglePublishAgain = React.useCallback(() => {
    if (planner == null) { return }
    if (triggerable.type !== 'challenge') { return }

    const uuid = getTriggerableID(triggerable)
    return planner.updateComponents([uuid], (it: ChallengeTriggerable) => ({...it, publishAgain: !it.publishAgain}))
  }, [planner, triggerable])

  const extraMenuItems = React.useMemo(() => {
    const items: PopupMenuItem[] = []
    if (!interactive) { return items }

    if (allowEdit) {
      items.push({
        icon:     'pencil',
        caption:  t('triggerables.actions.edit', {type: singular}),
        keyHint:  'doubleclick',
        onSelect: edit,
      })
    }

    if (triggerable.type === 'challenge') {
      items.push({
        caption:   t('triggerables.challenge.fields.publish_again.caption'),
        accessory: <InfoIcon renderTooltip={t('triggerables.challenge.fields.publish_again.instruction')}/>,
        checked:   triggerable.publishAgain,
        onSelect:  togglePublishAgain,
      })
    }

    if (partOfGroup) {
      items.push({section: '-'})
      items.push({
        icon:     'planner-ungroup',
        caption:  t('triggerables.actions.detach', {type: singular}),
        onSelect: detach,
      })
    }

    return items
  }, [allowEdit, detach, edit, interactive, partOfGroup, singular, t, togglePublishAgain, triggerable])

  const menuItems = useNodeViewKebabMenuItems(node, extraMenuItems, {
    remove,
  })

  //------
  // Rendering

  function render() {
    return (
      <VBox flex='grow'>
        {renderBar()}
      </VBox>
    )
  }

  function renderBar() {
    if (triggerable.type === 'action') {
      return (
        <ActionTriggerableBar
          triggerable={triggerable}
          nodeUUID={node.uuid}
          kebabMenuItems={menuItems}
          focused={focused}
          focusLevel={level}
          requestEdit={edit}
          small={small}
          interactive={interactive}
        />
      )
    } else {
      return (
        <ModelTriggerableBar
          triggerable={triggerable}
          nodeUUID={node.uuid}
          kebabMenuItems={menuItems}
          focused={focused}
          focusLevel={level}
          requestEdit={edit}
          small={small}
          interactive={interactive}
        />
      )
    }
  }

  return render()

})

export default FlowPlannerTriggerableBar