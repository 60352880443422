import React from 'react'
import { Group } from '~/models'
import ProjectField from '~/ui/app/projects/ProjectField'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { VBox } from '~/ui/components'
import { FormDialog, FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import { layout } from '~/ui/styling'
import AllowedProjectsFormModel from './AllowedProjectsFormModel'

export interface Props extends Omit<FormDialogProps<AllowedProjectsFormModel>, 'children'> {}

const AllowedProjectsForm = memo('AllowedProjectsForm', (props: Props) => {

  const {t} = useResourceTranslation('registration-presets')

  const {model: formModel} = props
  const preset       = formModel.model
  const organisation = preset?.organisation

  const filters = React.useMemo(() => ({
    organisation: organisation ?? null,
  }), [organisation])

  //------
  // Rendering

  function render() {
    if (organisation == null) { return null }

    return (
      <FormDialog
        icon='star'
        title={t('allowed_projects.caption')}

        {...props}
        children={renderFields()}
      />
    )
  }

  function renderFields() {
    return (
      <VBox gap={layout.padding.m}>
        <ResourceTypeProvider resourceType='registration-presets'>
          <FormField name='allowedProjects'>
            {bind => <ProjectField {...bind} multi filters={filters}/>}
          </FormField>
          <FormField name='groupTag'>
            {bind => <TagsField {...bind} Model={Group}/>}
          </FormField>
        </ResourceTypeProvider>
      </VBox>
    )
  }

  return render()

})

export default AllowedProjectsForm