import { makeObservable, observable } from 'mobx'
import { ChallengeQuestion, ChallengeTask } from '~/models'
import { FeedbackFormModel } from '~/ui/app/feedback'
import { SubmitResult, translateFormErrorPaths } from '~/ui/form'

export default class ChallengeQuestionFormModel extends FeedbackFormModel {

  constructor(
    private readonly saveTask: (data: AnyObject) => Promise<SubmitResult | undefined>,
    private readonly task: ChallengeTask,
  ) {
    super(task.question, task.question?.type ?? 'choice')

    this.variable  = this.task.question?.variable ?? null
    this.skippable = this.task.question?.skippable ?? false

    makeObservable(this)
  }

  @observable
  public variable: string | null

  @observable
  public skippable: boolean

  protected async submitFeedback(): Promise<SubmitResult | undefined> {
    const question = this.buildTaskQuestion()
    if (question == null) { return }

    const result = await this.saveTask({question})
    return translateFormErrorPaths(result, path => path.replace(/^question\./, ''))
  }

  public buildTaskQuestion(): ChallengeQuestion | null {
    const {question} = this.task
    if (this.resolvedFeedback == null) { return null }
    if (this.resolvedFeedback.type === 'group') { return null }

    return {
      ...question,
      ...this.resolvedFeedback,
      variable:  this.variable,
      skippable: this.skippable,
      scorings:  this.resolvedFeedback.type !== question?.type ? [] : (question.scorings ?? []),
    } as ChallengeQuestion
  }

}