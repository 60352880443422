import React from 'react'
import ChatBotField from '~/ui/app/chat-bots/ChatBotField'
import ModuleField from '~/ui/app/modules/ModuleField'
import { memo } from '~/ui/component'
import { TextField, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { ResourceTypeProvider } from '~/ui/resources'
import { layout } from '~/ui/styling'

export interface Props {
  moduleID?:    string
  moduleField?: boolean
}

const ScriptFields = memo('ScriptFields', (props: Props) => {

  const {moduleID, moduleField = true} = props

  return (
    <ResourceTypeProvider resourceType='scripts'>
      <VBox gap={layout.padding.m}>
        {moduleField && (
          <FormField name='module'>
            {bind => <ModuleField {...bind}/>}
          </FormField>
        )}

        <FormField name='name'>
          {bind => (
            <TextField
              {...bind}
            />
          )}
        </FormField>

        <FormField name='bot'>
          {bind => (
            <ChatBotField
              moduleID={moduleID}
              allowCreate={moduleID != null}
              {...bind}
            />
          )}
        </FormField>
      </VBox>
    </ResourceTypeProvider>
  )

})

export default ScriptFields