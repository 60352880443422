import React from 'react'
import { Profile, ProfileItemVisibility } from '~/models'
import { FeedbackForm } from '~/ui/app/feedback'
import { LocalizedTextField } from '~/ui/app/fields'
import { observer } from '~/ui/component'
import { SelectField, TextField } from '~/ui/components'
import { FormDialogProps, FormField } from '~/ui/form'
import { ResourceTypeProvider, useResourceTranslation } from '~/ui/resources'
import DefaultLanguageToggle from '../../fields/DefaultLanguageToggle'
import ProfileItemFormModel from './ProfileItemFormModel'
import { iconForProfileItemVisibility } from './util'

export interface Props extends Omit<FormDialogProps<ProfileItemFormModel>, 'model' | 'children' | 'beforeSubmit' | 'afterSubmit'> {
  profile: Profile | null
  index:   number | '$new'
}

const ProfileItemForm = observer('ProfileItemForm', (props: Props) => {

  const {profile, index, ...rest} = props
  const item = index === '$new' ? null : (profile?.items[index] ?? null)

  const {t} = useResourceTranslation('participants')

  const formModel = React.useMemo(
    () => new ProfileItemFormModel(profile, index, item),
    [index, item, profile],
  )

  const visibilityChoices = React.useMemo(() => ProfileItemVisibility.all.map(visibility => ({
    value:   visibility,
    icon:    iconForProfileItemVisibility(visibility),
    caption: t(`profile.visibility.${visibility}`),
  })), [t])

  //------
  // Rendering

  function render() {
    return (
      <FeedbackForm
        {...rest}
        model={formModel}
        includeTypes={['text', 'choice', 'group']}
        variableField={false}
        fieldOverrides={{placeholder: renderPlaceholderField}}
        renderExtraMainFields={renderExtraMainFields}
        renderExtraSideFields={renderExtraSideFields}
        headerRight={<DefaultLanguageToggle/>}
      />
    )
  }

  function renderExtraMainFields() {
    return (
      <ResourceTypeProvider resourceType='participants'>
        <FormField name='label' i18nKey='profile_item.label' required>
          {bind => <LocalizedTextField {...bind}/>}
        </FormField>
        <FormField name='prompt' i18nKey='profile_item.prompt' required>
          {bind => <LocalizedTextField {...bind} multiline/>}
        </FormField>
      </ResourceTypeProvider>
    )
  }

  function renderPlaceholderField() {
    return (
      <ResourceTypeProvider resourceType='participants'>
        <FormField name='placeholder' i18nKey='profile_item.placeholder' required>
          {bind => <LocalizedTextField {...bind}/>}
        </FormField>
      </ResourceTypeProvider>
    )
  }


  function renderExtraSideFields() {
    return (
      <ResourceTypeProvider resourceType='participants'>
        <FormField name='name' i18nKey='profile_item.name' instruction={formModel.type === 'group' ? false : undefined} required>
          {bind => <TextField {...bind} mono/>}
        </FormField>
        <FormField name='visibility' i18nKey='profile_item.visibility' required>
          {bind => <SelectField {...bind} choices={visibilityChoices}/>}
        </FormField>
      </ResourceTypeProvider>
    )
  }

  return render()

})

export default ProfileItemForm