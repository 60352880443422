import React from 'react'
import { Model, reflect } from '~/models'
import { memo } from '~/ui/component'
import { HBox, Label, LabelProps, SVG, Tappable } from '~/ui/components'
import { createUseStyles, layout, ThemeProvider } from '~/ui/styling'

export interface Props extends Omit<LabelProps, 'mono' | 'tiny' | 'children'> {
  model: Model
}

const ResourceAppLink = memo('ResourceAppLink', (props: Props) => {

  const {model, flex, ...labelProps} = props

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    const appLink = reflect<string | null>(model, 'appLink')
    if (appLink == null) { return null }

    return (
      <ThemeProvider fgNormal={t => t.fg.link}>
        <Tappable flex={flex} classNames={$.ResourceAppLink} href={appLink} noFeedback>
          <HBox gap={layout.padding.inline.xs}>
            <SVG name='iphone' size={layout.icon.xs}/>
            <Label flex mono tiny {...labelProps}>
              {appLink}
            </Label>
          </HBox>
        </Tappable>
      </ThemeProvider>
    )
  }


  return render()

})

export default ResourceAppLink

const useStyles = createUseStyles(theme => ({
  ResourceAppLink: {
    color: theme.fg.link,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))