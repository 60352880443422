import { action, observable, reaction } from 'mobx'
import { ClientApp, Extension, OAuthProvider } from '~/models'
import { dataStore } from '~/stores'
import { ResourceFormModel } from '~/ui/resources/form'

export default class ClientAppFormModel extends ResourceFormModel<ClientApp> {

  constructor(app: ClientApp | null) {
    super(ClientApp, app)

    if (app == null) {
      // Automatically fill some things based on the ID.
      reaction(() => this.getValue('id'), (id: string) => {
        this.prefillForID(`app.groundcontrol.whitelabel.${id}`)
      })
    }
  }

  @observable
  private emailAndPincode: boolean = this.model == null ? true : this.model.auth.emailAndPincode

  @observable
  private oAuthProviders: OAuthProvider[] = this.model == null ? [] : this.model.auth.oAuthProviders

  @action
  private prefillForID(id: string) {
    this.assign({
      bundleID:      id,
      applicationID: id,
      urlScheme:     deriveURLSchemeFromID(id),
    })
  }

  //------
  // oAuth providers

  public oAuthProviderEnabled(providerName: string) {
    return this.oAuthProviders.find(it => it.name === providerName)?.enabled === true
  }

  @action
  public toggleOAuthProvider(providerName: string) {
    this.ensureOAuthProvider(providerName)
    this.oAuthProviders = this.oAuthProviders.map(provider => {
      if (provider.name !== providerName) { return provider }
      return {
        ...provider,
        enabled: !provider.enabled,
      }
    })
  }

  @action
  private ensureOAuthProvider(providerName: string): OAuthProvider {
    const existing = this.oAuthProviders.find(it => it.name === providerName)
    if (existing != null) { return existing }

    const provider = {
      name:    providerName,
      enabled: false,
      config: {
        displayLogo: true,
        caption:     null,
      },
    }
    this.oAuthProviders.push(provider)

    return provider
  }

  public extensionForOAuthProvider(providerName: string) {
    return dataStore.get(Extension, providerName)
  }

  public override getValue(name: string): any {
    if (name === 'bundleID') {
      return super.getValue('ios')?.bundleID
    } else if (name === 'applicationID') {
      return super.getValue('android')?.applicationID
    } else {
      return super.getValue(name as any)
    }
  }

  public override assign(updates: AnyObject) {
    for (const [name, value] of Object.entries(updates)) {
      if (name === 'bundleID') {
        super.assign({ios: {bundleID: value}})
      } else if (name === 'applicationID') {
        super.assign({android: {applicationID: value}})
      } else {
        super.assign({[name]: value})
      }
    }
  }

  protected override buildData() {
    const data = super.buildData()

    return {
      ...data,
      id: `app.groundcontrol.whitelabel.${data.id}`,
      auth: {
        emailAndPincode: this.emailAndPincode,
        oAuthProviders:  this.oAuthProviders,
      },
    }
  }
}

function deriveURLSchemeFromID(id: string) {
  if (/^app\.groundcontrol\.whitelabel\.(.+)$/.test(id)) {
    return `gc-${RegExp.$1}`
  } else {
    return null
  }
}