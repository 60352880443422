import React from 'react'
import { useTranslation } from 'react-i18next'
import { NumericFeedbackCaption } from '~/models'
import { memo } from '~/ui/component'
import {
  CollectionField,
  CollectionFieldProps,
  HBox,
  ItemFormProps,
  NumberField,
  TextField,
  VBox,
} from '~/ui/components'
import { layout } from '~/ui/styling'

export interface Props extends Omit<CollectionFieldProps<NumericFeedbackCaption>, 'renderForm' | 'newItemTemplate'> {
  min:  number | null
  max:  number | null
  step: number | null
}

const NumericFeedbackCaptionsField = memo('NumericFeedbackCaptionsField', (props: Props) => {

  const {min, max, step, ...rest} = props
  const [t] = useTranslation('feedback')

  //------
  // Rendering

  function render() {
    return (
      <CollectionField<NumericFeedbackCaption>
        {...rest}
        renderForm={renderCaptionForm}
        newItemTemplate={(): NumericFeedbackCaption => ({value: null as any, caption: ''})}
        pasteTransform={line => [{value: null as any, caption: line}]}
      />
    )
  }

  const renderCaptionForm = React.useCallback((props: ItemFormProps<NumericFeedbackCaption>) => {
    return (
      <HBox gap={layout.padding.inline.m}>
        <VBox flex={2}>
          <NumberField
            {...props.bind('value')}
            placeholder={t('fields.buttons.value_placeholder')}
            inputStyle='light'
            minimum={min ?? undefined}
            maximum={max ?? undefined}
            step={step ?? undefined}
            showClearButton='never'
            mono
          />
        </VBox>
        <VBox flex={3}>
          <TextField
            {...props.bind('caption')}
            placeholder={t('fields.buttons.caption_placeholder')}
            inputStyle='light'
          />
        </VBox>
      </HBox>
    )
  }, [max, min, step, t])

  return render()

})

export default NumericFeedbackCaptionsField