import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from '~/ui/component'
import { TextBlock, VBox } from '~/ui/components'
import { FormSwitch, useForm } from '~/ui/form'
import { layout } from '~/ui/styling'
import SegueDelayFormModel from './SegueDelayFormModel'

export interface Props {}

const SegueDelayFormReminderStep = observer('SegueDelayFormReminderStep', (props: Props) => {

  const [t] = useTranslation('flow_planner')
  const {model: formModel} = useForm<SegueDelayFormModel>()

  //------
  // Rendering

  function render() {
    return (
      <VBox gap={layout.padding.s}>
        <TextBlock dim small markup>
          {t('delay_form.reminder_preamble')}
        </TextBlock>
        <FormSwitch name='reminder'>
          <FormSwitch.Option
            selected={!formModel.reminder}
            initialData={{reminder: false}}
            {...t('delay_form.delay')}
          />
          <FormSwitch.Option
            selected={formModel.reminder}
            initialData={{reminder: true}}
            {...t('delay_form.reminder')}
          />
        </FormSwitch>
      </VBox>
    )
  }

  return render()

})

export default SegueDelayFormReminderStep