import React from 'react'
import { useSize } from 'react-measure'
import { BrandedComponentSpec } from '~/models'
import { memo } from '~/ui/component'
import { Center, Chip, Label, Panel, PanelHeader, HBox, SVG, Tooltip } from '~/ui/components'
import { useResourceTranslation } from '~/ui/resources'
import { createUseStyles, layout, useStyling } from '~/ui/styling'
import { useComponentBranding } from './hooks'
import { renderComponentBrandingPreview } from './index'

export interface Props {
  componentName: string
  variant:       string | null
  spec?:         BrandedComponentSpec
}

const ComponentBrandingTile = memo('ComponentBrandingTile', (props: Props) => {

  const {componentName, variant, spec} = props
  const {colors} = useStyling()
  const {t}      = useResourceTranslation()

  const [component, , componentSize] = useComponentBranding(componentName, variant, spec)
  const hasVariants = component.variantNames.length > 0

  const forApp = !componentName.startsWith('web.')
  const forWeb = !componentName.startsWith('app.')

  const containerRef = React.useRef<HTMLDivElement>(null)
  const [previewContainerSize, setPreviewContainerSize] = React.useState<Size>({width: 0, height: 0})
  useSize(containerRef, setPreviewContainerSize)

  const previewScale = React.useMemo(() => {
    if (previewContainerSize.width === 0) { return 1 }

    const available = Math.max(0, previewContainerSize.width - 2 * padding)
    return Math.min(1, available / componentSize.width)
  }, [componentSize.width, previewContainerSize])

  const previewContainerStyle = React.useMemo(() => {
    return {
      height: componentSize.height * previewScale,
    }
  }, [componentSize.height, previewScale])

  const previewStyle = React.useMemo(() => {
    if (previewContainerSize.width === 0) { return {opacity: 0} }

    return {
      marginLeft: -componentSize.width / 2,
      marginTop:  -componentSize.height / 2,
      transform: `scale(${previewScale})`,
    }
  }, [componentSize.height, componentSize.width, previewContainerSize.width, previewScale])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (component == null) { return null }
    return (
      <Panel
        contentClassNames={$.brandedComponentTileContent}
        padding={padding}
        header={renderHeader()}
        children={renderPreview()}
        ref={containerRef}
      />
    )
  }

  function renderHeader() {
    return (
      <PanelHeader
        caption={renderCaption()}
        right={renderRight()}
      />
    )
  }

  function renderCaption() {
    return (
      <Label flex='shrink' mono small bold>
        {componentName}
      </Label>
    )
  }

  function renderRight() {
    return (
      <HBox gap={layout.padding.inline.xs}>
        {hasVariants && renderVariant()}
        {forApp && (
          <Tooltip renderTooltip={t('components.app')}>
            <SVG name='iphone' size={layout.icon.s} secondary/>
          </Tooltip>
        )}
        {forWeb && (
          <Tooltip renderTooltip={t('components.web')}>
            <SVG name='web' size={layout.icon.s} secondary/>
          </Tooltip>
        )}
      </HBox>
    )
  }

  function renderVariant() {
    return (
      <Chip small caption backgroundColor={variant == null ? colors.fg.dimmer : colors.semantic.secondary}>
        {variant ?? t('variants.default')}
      </Chip>
    )
  }

  function renderPreview() {
    return (
      <Center flex='grow' classNames={$.previewContainer} style={previewContainerStyle}>
        <div classNames={$.preview} style={previewStyle}>
          {renderComponentBrandingPreview(componentName, variant, spec)}
        </div>
      </Center>
    )
  }

  return render()

})

export default ComponentBrandingTile

export const padding = layout.padding.inline.l

const useStyles = createUseStyles({
  brandedComponentTileContent: {
    background:     'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAIAAAAACPTkDJAAAAR0lEQVRYCe3WsQ0AIAgEQHUN9h+NOTTO8CY2R/8JOYpndvcewVTVDOJjJeEXWQsQIECAAAECUZffOk7/CScgQIAAAQIEvgscKScH7V2VrVQAAAAASUVORK5CYII=)',
    backgroundSize: [16, 16],
    overflow:       'hidden',
  },

  previewContainer: {
    position: 'relative',
  },

  preview: {
    position: 'absolute',
    top:      '50%',
    left:     '50%',
  },
})