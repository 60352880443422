import React from 'react'
import { useTranslation } from 'react-i18next'
import { Feedback } from '~/models'
import { memo } from '~/ui/component'
import { ConfirmBox, PushButton, VBox } from '~/ui/components'
import { useForm } from '~/ui/form'
import FeedbackFormModel from '../FeedbackFormModel'

interface Props {
  availableTypes?: Array<Feedback['type']>
}

const ChoiceFeedbackActions = memo('ChoiceFeedbackActions', (props: Props) => {

  const {availableTypes} = props

  const [t]     = useTranslation('feedback')
  const {model} = useForm<FeedbackFormModel>()

  const convertToButton = React.useCallback(async () => {
    const confirmed = await ConfirmBox.show({
      ...t('choice.actions.convert_to_button.confirm'),
    })
    if (!confirmed) { return }

    model.convertFromChoiceToButton()
  }, [model, t])

  //------
  // Rendering

  function render() {
    if (!availableTypes?.includes('button')) { return null }
    return (
      <VBox align='left'>
        <PushButton
          caption={t('choice.actions.convert_to_button.caption')}
          onTap={convertToButton}
          small
        />

      </VBox>
    )
  }

  return render()

})

export default ChoiceFeedbackActions