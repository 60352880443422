import React from 'react'
import { useTranslation } from 'react-i18next'
import { memo } from '~/ui/component'
import { HBox, Label, PanelHeader, Scroller, SVG, Tappable, VBox } from '~/ui/components'
import { useAssistantFormDialog } from '~/ui/form/assistant-form'
import { createUseStyles, layout, ThemeProvider, useTheme } from '~/ui/styling'
import { Props as AssistantFormDialogProps } from './AssistantFormDialog'

export interface Props extends AssistantFormDialogProps<any, any> {
  accessories: Record<any, React.ReactNode>
}

const AssistantFormStepsPanel = memo('AssistantFormStepsPanel', (props: Props) => {

  const {steps, accessories} = props

  const [t] = useTranslation('assistant_form')

  const $ = useStyles()

  function render() {
    return (
      <VBox classNames={$.AssistantFormStepsPanel} flex>
        <PanelHeader
          caption={t('step_list.caption')}
          small
        />
        <Scroller>
          <VBox classNames={$.stepsList} gap={layout.padding.inline.s} flex='grow'>
            {steps.map(step => (
              <AssistantFormStepButton
                key={step}
                step={step}
                accessory={accessories[step]}
                {...props}
              />
            ))}
          </VBox>
        </Scroller>
      </VBox>
    )
  }

  return render()

})

interface AssistantFormStepButtonProps extends Props {
  step:      any
  accessory: React.ReactNode
}

const AssistantFormStepButton = memo('AssistantFormStepButton', (props: AssistantFormStepButtonProps) => {

  const {
    step,
    accessory,
    steps,
    iconForStep,
    captionForStep,
    currentStep,
    requestStep,
  } = props

  const {allowSelectStep} = useAssistantFormDialog()

  const current  = step === currentStep
  const previous = steps.indexOf(step) < steps.indexOf(currentStep)

  const enabled =
    allowSelectStep === 'all' ? true :
    allowSelectStep === 'previous' ? previous :
    false

  const select = React.useCallback(() => {
    requestStep(step)
  }, [requestStep, step])

  const theme = useTheme()

  const caption = captionForStep(step)

  const themeOverrides = React.useMemo(() => {
    if (current) {
      return {fg: {normal: theme.semantic.secondary}}
    } else if (enabled) {
      return {fg: {normal: theme.semantic.primary}}
    }
  }, [current, enabled, theme.semantic.primary, theme.semantic.secondary])

  const $ = useStyles()

  function render() {
    return (
      <Tappable classNames={[$.AssistantFormStepButton, {current}]} onTap={select} enabled={enabled}>
        <ThemeProvider overrides={themeOverrides}>
          <HBox gap={layout.padding.inline.m}>
            {renderIcon()}
            {renderCaption()}
            {renderAccessory()}
          </HBox>
        </ThemeProvider>
      </Tappable>
    )
  }

  function renderIcon() {
    const icon  = iconForStep?.(step)
    if (icon == null) { return null }

    return <SVG name={icon} size={layout.icon.m}/>
  }

  function renderCaption() {
    return (
      <Label h3 flex>
        {caption}
      </Label>
    )
  }

  function renderAccessory() {
    return accessory
  }

  return render()

})

export default AssistantFormStepsPanel

const useStyles = createUseStyles(theme => ({
  AssistantFormStepsPanel: {
    background: theme.bg.semi,
  },

  stepsList: {
    ...layout.responsive(size => ({
      padding: [layout.padding.s[size], layout.padding.m[size]],
    })),
  },

  AssistantFormStepButton: {
    padding:      layout.padding.inline.m,
    borderRadius: layout.radius.m,

    '&.current': {
      background: theme.bg.active,
    },
  },
}))